// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import VerificationInput from 'react-verification-input'

// components
import {
    Button,
    ErrorHelper,
    IconBlock,
    Loader,
} from 'components'

// data
import {
    api_url_auth_user_form_confirm_site,
    api_url_auth_user_form_create,
    defaultReduxState,
    reduxAuthLogin,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FormLabel,
    TextInputV2,
} from 'pages'

// services 
import {
    axiosErrorHandler,
    getApiUrlV2,
} from 'services'

// props
type AgendaDuQuartierSignupBlockProps = {
    email: string
    setPanel: React.Dispatch<React.SetStateAction<'login' | 'password-reset' | 'signup'>>
}

// main
export const AgendaDuQuartierSignupBlock: React.FC<AgendaDuQuartierSignupBlockProps> = React.memo(({
    email,
    setPanel,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    type errorsType = {
        accept?: string
        detail?: string
        email?: string
        non_field_errors?: string
        password?: string
        username?: string
        verification_code?: string
    }
    type fieldsType = {
        accept?: boolean
        password: string
        username: string
        verification_code?: string
    }
    const errorsInitial = {}
    const fieldsInitial = {
        password: '',
        username: email,
        verification_code: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [step, setStep] = useState<'signup' | 'verification_code'>('signup')

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierSignupBlock',
                'handleInputChange',
            ))
        }
    }

    function handleCodeInputChange(event: any) {
        try {
            setButtonDisabled(false)
            setFields({ ...fields, verification_code: event })
            setErrors({ ...errors, verification_code: '', non_field_errors: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierSignupBlock',
                'handleCodeInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            if (step === 'signup' && !fields.accept) {
                setErrors({ ...errors, accept: reduxText[2856] })
                return
            }
            setButtonDisabled(true)
            setIsLoading(true)
            const formData: any = new FormData()
            if (step === 'signup') {
                formData.append('email', fields.username.toLowerCase().trim())
                formData.append('password', fields.password)
                const subContentTypeDict: any = {
                    'federateur-trice-de-quartier': 90,
                    'association': 94,
                }
                formData.append('sub_content_type_id', subContentTypeDict[window.location.pathname.split('/')[3]])
                formData.append('username', fields.username.toLowerCase().trim())
                const createUrl = getApiUrlV2(api_url_auth_user_form_create, reduxAuth)
                if (process.env.NODE_ENV === 'development') console.log(createUrl)
                axios({
                    data: formData,
                    method: 'post',
                    url: createUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                        setStep('verification_code')
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        let skipSendError = false
                        if (error.response?.status === 400 && error.response?.data?.email) skipSendError = true
                        if (error.response?.status === 400 && error.response?.data?.password) skipSendError = true
                        if (error.response?.status === 400 && error.response?.data?.username) skipSendError = true
                        if (error.response?.status === 403) skipSendError = true
                        // const formFieldsToSend = Object.assign({}, fields)
                        // delete formFieldsToSend.password
                        axiosErrorHandler({
                            apiUrl: createUrl,
                            component: 'AgendaDuQuartierSignupBlock',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onSubmit',
                            skipAlert: skipSendError,
                            skipAlertStatusCode: [400],
                            skipSendError: skipSendError,
                        })
                    })
            } else if (step === 'verification_code') {
                formData.append('username', fields.username.toLowerCase().trim())
                formData.append('verification_code', fields.verification_code)
                const postUrl = getApiUrlV2(api_url_auth_user_form_confirm_site, reduxAuth)
                if (process.env.NODE_ENV === 'development') console.log(postUrl)
                axios({
                    data: formData,
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                        setFields(fieldsInitial)
                        // @ts-ignore
                        dispatch(reduxAuthLogin(
                            fields.username,
                            fields.password,
                            reduxAuth,
                            dispatch,
                            setIsLoading,
                            setFields,
                            fieldsInitial,
                            setErrors,
                        ))
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        axiosErrorHandler({
                            apiUrl: postUrl,
                            component: 'AgendaDuQuartierSignupBlock',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onSubmit',
                            skipAlertStatusCode: [400],
                            skipSendErrorStatusCode: [400],
                        })
                    })
            }
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierSignupBlock',
                'onSubmit',
            ))
        }
    }

    return (
        <div className={`template-block-806 ${deviceType}`}>
            {step === 'signup' && (
                <React.Fragment>
                    <div className={`t-806-input-wrap ${deviceType}`}>
                        <FormLabel
                            label={reduxText[8421]}
                            marginBottom
                            name='username'
                        />
                        <TextInputV2
                            disabled
                            name='username'
                            onChange={handleInputChange}
                            type='text'
                            value={fields.username}
                        />
                        <ErrorHelper error={errors.username || errors.email} />
                    </div>
                    <div className={`t-806-input-wrap ${deviceType}`}>
                        <div className={`t-806-label-wrap ${deviceType}`}>
                            <FormLabel
                                label={reduxText[8756]}
                                marginBottom
                                name='password'
                            />
                            <IconBlock
                                edit={false}
                                className={`t-806-icon ${deviceType}`}
                                iconClass={showPassword ? 'mo-new-icon-eye-slash-solid' : 'mo-new-icon-eye-solid'}
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                        <TextInputV2
                            name='password'
                            onChange={handleInputChange}
                            type={showPassword ? 'text' : 'password'}
                            value={fields.password}
                        />
                        <ErrorHelper error={errors.password} />
                    </div>
                </React.Fragment>
            )}
            {step === 'verification_code' && (
                <div className={`t-806-verification-wrap ${deviceType}`}>
                    <FormLabel
                        label={reduxText[8534]}
                        marginBottom
                        name='verification_code'
                    />
                    <div className={`t-806-verification-input ${deviceType}`}>
                        <VerificationInput
                            length={4}
                            placeholder=''
                            onChange={handleCodeInputChange}
                            validChars={'0123456789'}
                            value={fields.verification_code}
                        />
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-undo-alt-solid'
                            onClick={() => {
                                setFields({ ...fields, verification_code: '' })
                                setErrors({ ...errors, verification_code: '', non_field_errors: '' })
                            }}
                            tooltipText=''
                        />
                    </div>
                    <ErrorHelper error={errors.verification_code} />
                </div>
            )}
            <div className={`t-806-button-wrap ${deviceType}`}>
                {step === 'signup' && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                paddingBottom: '20px',
                                textAlign: 'left',
                            }}
                        >
                            <input
                                onChange={() => {
                                    setFields({ ...fields, accept: !fields.accept })
                                    setErrors({ ...errors, accept: '' })
                                }}
                                style={{
                                    marginRight: '10px',
                                }}
                                type='checkbox'
                            />
                            <div dangerouslySetInnerHTML={{ __html: reduxText[10046] }} />
                        </div>
                        <ErrorHelper error={errors?.accept} />
                    </>
                )}
                <Button
                    disabled={buttonDisabled || isLoading}
                    edit={false}
                    expand='full'
                    onClick={onSubmit}
                    text={reduxText[8424]}
                />
                <ErrorHelper error={errors?.non_field_errors || errors?.detail} />
            </div>
            {isLoading && (
                <Loader isOpen />
            )}
        </div>
    )
})
