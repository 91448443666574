// Root URL
const rootPath = 'material/'

// Action
export const api_url_material_action = `${rootPath}action/material/`

// Detail
export const api_url_material_detail = `${rootPath}detail/material/`
export const api_url_material_detail_short = `${rootPath}detail/material/short/`

// Form Material
export const api_url_material_form_active = `${rootPath}form/material/active/`
export const api_url_material_form_create = `${rootPath}form/material/create/`
export const api_url_material_form_detail = `${rootPath}form/material/detail/`
export const api_url_material_form_duplicate = `${rootPath}form/material/duplicate/`
export const api_url_material_form_update = `${rootPath}form/material/update/`

// Form Image
export const api_url_material_image_form_active = `${rootPath}form/image/active/`
export const api_url_material_image_form_create = `${rootPath}form/image/create/`
export const api_url_material_image_form_detail = `${rootPath}form/image/detail/`
export const api_url_material_image_form_list = `${rootPath}form/image/list/`
export const api_url_material_image_form_patch = `${rootPath}form/image/patch/`
export const api_url_material_image_form_update = `${rootPath}form/image/update/`

// List
export const api_url_material_image_list = `${rootPath}list/image/`
export const api_url_material_list = `${rootPath}list/material/`

// Map
export const api_url_material_map = `${rootPath}map/material/`
