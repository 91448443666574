// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    RefresherEventDetail,
} from '@ionic/core'
import {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
} from '@ionic/react'
import {
    isMobileOnly,
} from 'react-device-detect'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    FooterMobile,
    HeaderChildren,
    HeaderMobile,
    Loader,
    ProfileShortInfoWeb,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    languageObjectSite,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
} from 'data'

// pages
import {
    DetailContent,
    DetailAction,
    NotFoundPage,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
    contentType: contentTypeModelType
}

type DetailPageProps = {
    headerId?: string
}

// main
export const DetailPage: React.FC<DetailPageProps> = React.memo(({
    headerId,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [errorStatus, setErrorStatus] = useState<number>()
    const [isLoading, setIsLoading] = useState(false)
    // const [languageId, setLanguageId] = useState<number>()
    const [languageId] = useState<number>()
    const [object, setObject] = useState<any>('')

    const contentRef = useRef<HTMLIonContentElement>(null)

    const contentType = params.contentType
    const app = contentType.split('_')[0]
    const model = contentType.split('_')[1]
    const apiUrlDetail = getApiUrlV2(`${app}/detail/${model}/`, reduxAuth)

    useEffect(() => {
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
        getData(params.id, languageId)
    }, [
        languageId,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshDetail) {
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            getData(params.id, languageId)
        }
    }, [
        reduxForm.refresh.refreshDetail,
    ])

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPage',
                'scrollToTop',
            ))
        }
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        try {
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            getData(params.id, languageId, event)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPage',
                'doRefresh',
            ))
        }
    }

    async function getData(objectId: string, languageId: number | undefined, refresh?: any) {
        try {
            if (!objectId) {
                setErrorStatus(404)
                return
            } else {
                setErrorStatus(undefined)
            }
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DetailPage getData')
            setIsLoading(true)
            let getUrl = `${apiUrlDetail}${objectId}/` // Keep the last '/' for Safari !!!!!!!
            if (languageId || languageObjectSite[reduxAuth.settings?.language!]) getUrl += `?language_id=${languageId || languageObjectSite[reduxAuth.settings?.language!]}`
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    scrollToTop()
                    setIsLoading(false)
                    if (refresh) {
                        refresh.detail.complete()
                    }
                    setObject(response.data)
                    dispatch(reduxModalSeoData({
                        title: `${response.data.name || response.data.id} | ${response.data.sub_content_type?.name || response.data.content_type_string}`,
                    }))
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (error.response?.status === 403) {
                        setErrorStatus(error.response.status)
                    } else if (error.response?.status === 404 && error.response?.data?.detail) {
                        setErrorStatus(error.response.status)
                    } else {
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component: 'DetailPage',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'getData',
                        })
                    }
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPage',
                'getData',
            ))
        }
    }

    if (isMobileOnly) {

        if (errorStatus) {
            return <NotFoundPage statusCode={errorStatus} />
        }

        return (
            <IonPage>
                <HeaderMobile
                    id={headerId}
                    // componentActionSheet={componentActionSheet}
                    detailObject={object}
                    refreshData={() => getData(params.id, languageId)}
                />
                <IonContent
                    forceOverscroll
                    fullscreen
                    ref={contentRef}
                >
                    <IonRefresher
                        slot='fixed'
                        onIonRefresh={doRefresh}
                    >
                        <IonRefresherContent />
                    </IonRefresher>
                    {object && (
                        <DetailContent
                            contentType={contentType}
                            contentTypeSettings={object.content_type_settings_json}
                            // modalKeys={modalKeys}
                            object={object}
                            refreshData={() => getData(params.id, languageId)}
                        />
                    )}
                    <Loader isOpen={isLoading} />
                </IonContent>
                <FooterMobile />
            </IonPage>
        )
    }

    if (errorStatus) {
        return <NotFoundPage statusCode={errorStatus} />
    }

    return (
        <IonPage>
            <HeaderChildren>
                <div
                    className='detail-page-header'
                    style={{
                        backgroundImage: object.get_image_hq ? `url(${object.get_image_hq})` : 'none',
                    }}
                >
                    <div className='dp-header-background-image-overlay' />
                </div>
                <DetailAction
                    contentType={contentType}
                    contentTypeSettings={object.content_type_settings_json}
                    contentTypeSettingsCustom={object.profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]}
                    object={object}
                    refreshData={() => getData(params.id, languageId)}
                />
                <IonTitle className='hcw-title'>{object.name}</IonTitle>
                <div className='hcw-header-children-left right'>
                    <ProfileShortInfoWeb
                        edit={false}
                        noImage
                        object={object.parent_short || object.profile}
                        right
                    />
                </div>
            </HeaderChildren>
            <IonContent
                className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}
                ref={contentRef}
            >
                {object && (
                    <DetailContent
                        contentType={contentType}
                        contentTypeSettings={object.content_type_settings_json}
                        // languageId={languageId}
                        // modalKeys={modalKeys}
                        object={object}
                        refreshData={() => getData(params.id, languageId)}
                    // setLanguageId={setLanguageId}
                    />
                )}
                <Loader isOpen={isLoading} />
            </IonContent>
        </IonPage>
    )
})
