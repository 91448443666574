// Root URL
const rootPath = 'main/'

// Form Custom category
export const api_url_custom_category_form_create = `${rootPath}form/custom_category/create/`
export const api_url_custom_category_form_detail = `${rootPath}form/custom_category/detail/`
export const api_url_custom_category_form_list = `${rootPath}form/custom_category/list/`
export const api_url_custom_category_form_update = `${rootPath}form/custom_category/update/`

// Form Custom category 2
export const api_url_custom_category_2_form_create = `${rootPath}form/custom_category_2/create/`
export const api_url_custom_category_2_form_detail = `${rootPath}form/custom_category_2/detail/`
export const api_url_custom_category_2_form_list = `${rootPath}form/custom_category_2/list/`
export const api_url_custom_category_2_form_update = `${rootPath}form/custom_category_2/update/`

// Form Custom category 3
export const api_url_custom_category_3_form_create = `${rootPath}form/custom_category_3/create/`
export const api_url_custom_category_3_form_detail = `${rootPath}form/custom_category_3/detail/`
export const api_url_custom_category_3_form_list = `${rootPath}form/custom_category_3/list/`
export const api_url_custom_category_3_form_update = `${rootPath}form/custom_category_3/update/`

// Form Global Conversion
export const api_url_conversion_form_create = `${rootPath}form/global_conversion/create/`
export const api_url_conversion_form_detail = `${rootPath}form/global_conversion/detail/`
export const api_url_conversion_form_list = `${rootPath}form/global_conversion/list/`
export const api_url_conversion_form_update = `${rootPath}form/global_conversion/update/`

// Form Global Translation
export const api_url_translation_form_create = `${rootPath}form/global_translation/create/`
export const api_url_translation_form_detail = `${rootPath}form/global_translation/detail/`
export const api_url_translation_form_list = `${rootPath}form/global_translation/list/`
export const api_url_translation_form_update = `${rootPath}form/global_translation/update/`

// Form Custom Choice
export const api_url_custom_choice_form_create = `${rootPath}form/custom_choice/create/`
export const api_url_custom_choice_form_detail = `${rootPath}form/custom_choice/detail/`
export const api_url_custom_choice_form_list = `${rootPath}form/custom_choice/list/`
export const api_url_custom_choice_form_update = `${rootPath}form/custom_choice/update/`

// Form Media
export const api_url_media_form_youtube = `${rootPath}form/media/youtube/`

// Form Team
export const api_url_main_team_form_active = `${rootPath}form/team/active/`
export const api_url_main_team_form_create = `${rootPath}form/team/create/`
export const api_url_main_team_form_detail = `${rootPath}form/team/detail/`
export const api_url_main_team_form_list = `${rootPath}form/team/list/`
export const api_url_main_team_form_patch = `${rootPath}form/team/patch/`
export const api_url_main_team_form_update = `${rootPath}form/team/update/`

// List
export const api_url_main_term = `${rootPath}list/term/`
export const api_url_main_text = `${rootPath}detail/text/`
