// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
    listStatePropsWeb,
} from 'services'


export async function getMapList({
    addMarkers,
    apiUrl,
    axiosCancelToken,
    component,
    dispatch,
    listState,
    reduxAuth,
    setAxiosCancelToken,
    setClusterIds,
    setListState,
}: {
    addMarkers: React.Dispatch<any>
    apiUrl: string
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    dispatch: React.Dispatch<any>
    listState: listStatePropsWeb
    reduxAuth: reduxAuthState
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setClusterIds?: boolean
    setListState: React.Dispatch<listStatePropsWeb>
}) {
    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    if (!apiUrl) return

    setListState({
        ...listState,
        isLoading: true,
    })

    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getMapList`)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    if (process.env.NODE_ENV === 'development') console.log(apiUrl, component)

    axios({
        headers: refreshAxiosHeaders,
        method: 'get',
        url: apiUrl,
        cancelToken: source.token,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            addMarkers(response.data)
            if (setClusterIds) {
                const newArray: any = []
                response.data.map((val: any) => {
                    newArray.push(val.parent)
                    return false
                })
                setListState({
                    ...listState,
                    clusterIds: newArray,
                    isLoading: false,
                })
            } else {
                setListState({
                    ...listState,
                    isLoading: false,
                })
            }

        })
        .catch((error) => {
            setListState({
                ...listState,
                isLoading: false,
            })
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'getMapList',
            })
        })
}
