// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    DeleteAlert,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_portfolio_form,
} from 'data'

// serializers
import {
    PortfolioPageListFormSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
    pageSlug: string
}

type DeleteContentHelperProps = {
    object: PortfolioPageListFormSerializer
    refreshData: () => void
    setIsOpen: React.Dispatch<boolean>
    updateUrl: string
}

// main
export const DeletePageHelper: React.FC<DeleteContentHelperProps> = React.memo(({
    object,
    refreshData,
    setIsOpen,
    updateUrl,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)

    async function handleDelete() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DeletePageHelper handleDelete')
            setIsLoading(true)
            const deleteUrl = `${updateUrl}${object.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (params.pageSlug === object.absolute_site_url) {
                        history.push(`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-list/`)
                    }
                    refreshData()
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'DeletePageHelper',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'DeletePageHelper',
                'handleDelete',
            ))
        }
    }

    return (
        <React.Fragment>
            <DeleteAlert
                handleDelete={handleDelete}
                objectName={object.name}
                setIsDeleteAlertOpen={setIsOpen}
            />
            {isLoading && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </React.Fragment>
    )
})
