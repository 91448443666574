// react components
import React, {
    useState,
} from 'react'
import {
    IonImg,
} from '@ionic/react'
import {
    isMobileOnly,
} from 'react-device-detect'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LightboxCustom,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// props
type ImageHelperProps = {
    alt: string | undefined
    className?: string
    defaultHeightMobile?: boolean
    dominant_color: string | undefined
    lazyMobile?: boolean
    lightBox?: boolean
    lightBoxSrc?: string
    minHeight?: string
    onClick?: any
    realHeightMobile?: string
    src: string | undefined
}

// main
export const ImageHelper: React.FC<ImageHelperProps> = React.memo(({
    alt,
    className,
    defaultHeightMobile,
    dominant_color,
    lazyMobile = true,
    lightBox,
    lightBoxSrc,
    minHeight,
    onClick,
    realHeightMobile,
    src,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [height, setHeight] = useState<string | undefined>('100vw')
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
    const [visibility, setVisibility] = useState<any>('hidden')
    const [styles, setStyles] = useState<any>({
        backgroundColor: `rgb${dominant_color}`,
        minHeight: minHeight || undefined,
        objectPosition: '-100vw',
    })

    if (isMobileOnly) {

        if (defaultHeightMobile) {
            return (
                <img
                    alt={alt || 'Just The Web'}
                    className={`mo-image-helper ${className || ''}`}
                    onClick={onClick}
                    // onContextMenu={(e) => e.preventDefault()}
                    onLoad={() => {
                        setHeight(undefined)
                        setVisibility('visible')
                    }}
                    onError={(e: any) => e.target.src = reduxText[3619]}
                    src={src}
                    style={{
                        height: height,
                        visibility: visibility,
                    }}
                />
            )
        }
        if (realHeightMobile) {
            return (
                <img
                    alt={alt || 'Just The Web'}
                    className={`mo-image-helper ${className || ''}`}
                    onClick={onClick}
                    // onContextMenu={(e) => e.preventDefault()}
                    onError={(e: any) => e.target.src = reduxText[3619]}
                    onLoad={(e: any) => setVisibility('visible')}
                    src={src}
                    style={{
                        height: realHeightMobile,
                        visibility: visibility,
                    }}
                />
            )
        }
        if (!lazyMobile) {
            return (
                <img
                    alt={alt || 'Just The Web'}
                    className={`mo-image-helper ${className || ''}`}
                    onClick={onClick}
                    // onContextMenu={(e) => e.preventDefault()}
                    onError={(e: any) => e.target.src = reduxText[3619]}
                    onLoad={(e: any) => setVisibility('visible')}
                    src={src}
                    style={{
                        visibility: visibility,
                    }}
                />
            )
        }
        return (
            <IonImg
                alt={alt || 'Just The Web'}
                className={`mo-image-helper ${className || ''}`}
                onClick={onClick}
                onIonError={(e: any) => e.target.src = reduxText[3619]}
                onIonImgDidLoad={() => setVisibility('visible')}
                src={src}
                style={{
                    visibility: visibility,
                }}
            />
        )
    }

    return (
        <React.Fragment>
            <img
                alt={src ? (alt || 'Just The Web') : ''}
                className={`${className || ''}`}
                onClick={lightBox ? () => setIsLightBoxOpen(true) : onClick}
                // onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
                onLoad={() => setStyles(undefined)}
                onError={(e: any) => {
                    setStyles({
                        backgroundColor: `rgb${dominant_color}`,
                        minHeight: minHeight || undefined,
                        objectPosition: '-100vw',
                    })
                    e.target.alt = ''
                }}
                src={src}
                style={styles}
            />
            {lightBox && isLightBoxOpen && (
                <LightboxCustom
                    mainSrc={lightBoxSrc || src}
                    onCloseRequest={() => setIsLightBoxOpen(false)}
                />
            )}
        </React.Fragment>
    )
})
