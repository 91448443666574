// react components
import slugify from 'slugify'

// data
import {
    contentTypeModelType,
    reduxTextState,
} from 'data'

// serializers
import {
    ContentTypeFormTypes,
} from 'serializers/web'

// main
export function getInputValueV2({
    contentType,
    customError,
    event,
    formState,
    reduxText,
}: {
    contentType: contentTypeModelType
    customError?: {
        name: string
        value: string
    }
    event: any
    formState: ContentTypeFormTypes
    reduxText: reduxTextState['data']
}) {
    const name = event.name

    if (['cover_image', 'cover_image_mq_o', 'cover_image_lq_o', 'cover_image_2', 'image', 'image_svg', 'cover_image_svg'].includes(event.name)) {
        if (name.includes('svg')) {
            const nameWithoutSvg = name.replace('_svg', '')
            return {
                ...formState,
                data: {
                    ...formState.data,
                    errors: {
                        ...formState.data.errors,
                        [name]: '',
                        [`${nameWithoutSvg}_doka_output_data`]: '',
                        [`${name}_original`]: '',
                        main_error: '',
                    },
                    fields: {
                        ...formState.data.fields,
                        [`${nameWithoutSvg}_doka_output_data`]: event.newDrop ? 'to_delete_original' : (event.doka_output_data ? ((typeof event.doka_output_data === 'string' && event.doka_output_data.includes('to_delete')) ? event.doka_output_data : JSON.stringify(event.doka_output_data)) : ''),
                        [name]: event.doka_image,
                        get_image_hq: event.get_image_hq,
                        get_image_lq: event.get_image_lq,
                        get_image_mq: event.get_image_mq,
                        meta_image_url: '',
                        name: formState.data.fields.name || event.fileName,
                    },
                }
            }
        }
        return {
            ...formState,
            data: {
                ...formState.data,
                errors: {
                    ...formState.data.errors,
                    [name]: '',
                    [`${name}_doka_output_data`]: '',
                    [`${name}_original`]: '',
                    main_error: '',
                },
                fields: {
                    ...formState.data.fields,
                    [`${name}_doka_output_data`]: event.newDrop ? 'to_delete_original' : (event.doka_output_data ? ((typeof event.doka_output_data === 'string' && event.doka_output_data.includes('to_delete')) ? event.doka_output_data : JSON.stringify(event.doka_output_data)) : ''),
                    [`${name}_original`]: event.doka_output_data ? event.value : '',
                    [`${name}_square_doka_output_data`]: event.doka_output_data ? 'to_delete_square' : 'to_delete_all',
                    [`${name}_square`]: event.get_image_lq,
                    [name]: event.doka_image,
                    get_image_hq: event.get_image_hq,
                    get_image_lq: event.get_image_lq,
                    get_image_mq: event.get_image_mq,
                    meta_image_url: '',
                    name: formState.data.fields.name || event.fileName,
                },
            }
        }
    } else if (['cover_image_square', 'image_square'].includes(event.name)) {
        return {
            ...formState,
            data: {
                ...formState.data,
                errors: {
                    ...formState.data.errors,
                    [name]: '',
                    [`${name}_doka_output_data`]: '',
                    main_error: '',
                },
                fields: {
                    ...formState.data.fields,
                    [name]: event.doka_image,
                    [`${name}_doka_output_data`]: ((typeof event.doka_output_data === 'string' && event.doka_output_data.includes('to_delete')) ? event.doka_output_data : JSON.stringify(event.doka_output_data)),
                },
            }
        }
    } else if (['pdf_file'].includes(event.name)) {
        return {
            ...formState,
            data: {
                ...formState.data,
                errors: {
                    ...formState.data.errors,
                    [name]: '',
                    main_error: '',
                },
                fields: {
                    ...formState.data.fields,
                    [name]: event.value,
                    name: formState.data.fields.name || event.fileName,
                    pdf_preview: event.value?.preview,
                },
            }
        }
    } else if (['project_project'].includes(contentType) && event.name === 'link_url') {
        let newError = ''
        if (event.value.includes('spotify.com/album')) {
            if (process.env.NODE_ENV === 'development') console.log('error')
            newError = reduxText[5496]
        } else if (event.value.includes('spotify.com/intl-fr/album')) {
            if (process.env.NODE_ENV === 'development') console.log('error')
            newError = reduxText[5496]
        } else if (event.value.includes('vimeo.com/manage')) {
            if (process.env.NODE_ENV === 'development') console.log('error')
            newError = reduxText[5561]
        }
        return {
            ...formState,
            data: {
                ...formState.data,
                errors: {
                    ...formState.data.errors,
                    [name]: newError,
                    main_error: '',
                },
                fields: {
                    ...formState.data.fields,
                    [name]: event.value,
                },
            }
        }
    } else if (customError) {
        return {
            ...formState,
            data: {
                ...formState.data,
                errors: {
                    ...formState.data.errors,
                    [name]: '',
                    main_error: '',
                    [customError.name]: customError.value,
                },
                fields: {
                    ...formState.data.fields,
                    [name]: event.name === 'slug' ? slugify(event.value, { replacement: '-', lower: true }) : event.value,
                },
            }
        }
    } else {
        return {
            ...formState,
            data: {
                ...formState.data,
                errors: {
                    ...formState.data.errors,
                    [name]: '',
                    main_error: '',
                },
                fields: {
                    ...formState.data.fields,
                    [name]: event.name === 'slug' ? slugify(event.value, { replacement: '-', lower: true }) : event.value,
                },
            }
        }
    }
}
