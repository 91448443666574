// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonLabel,
    IonSegment,
    IonSegmentButton,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'

// components
import {
    ListSpinner,
} from 'components'

// data
import {
    api_url_css_property_list,
    contentTypeModelType,
    defaultReduxState,
    reduxFormGetCSSProperties,
    reduxFormHelpersState,
    reduxModalSetEditStylesSite,
    reduxFormSetNavigationModeSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    DetailMixedContentPanel,
    ListMixedMediaContentPanel,
    StyleMixedContentPanel,
} from 'pages'

// serializers
import {
    ProjectFormState,
} from 'serializers/web'

// services
import {
    autoSaveStyles,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    parseQuery,
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    panelValue: string
}

type ContainerMixedContentPanelProps = {
    contentType: contentTypeModelType
    formState: ProjectFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    setFormState: React.Dispatch<ProjectFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const ContainerMixedContentPanel: React.FC<ContainerMixedContentPanelProps> = React.memo(({
    contentType,
    formState,
    handleInputChange,
    helpers,
    newMixedMediaContent,
    newMixedMediaContentType,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mixedMediaContent = formState.mixedMediaContent

    useEffect(() => {
        if (params.panelValue) onGetData()
    }, [
        params.panelValue,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        getCSSProperties()
    }, [
        params.id,
    ])

    useEffect(() => {
        scrollToSection(params.panelValue, 0)
        const searchParams = parseQuery(window.location.search)
        const tab = searchParams.tab
        if (!params.panelValue) {
            setTabValue('blocks')
        } else {
            if (tab) {
                setTabValue(tab)
            } else if (tabValue === 'blocks') {
                setTabValue('settings')
            }
        }
    }, [
        params.panelValue,
    ])

    useEffect(() => {
        dispatch(reduxFormSetNavigationModeSite('edit'))
    }, [])

    const [isLoading, setIsLoading] = useState(false)
    const [tabValue, setTabValue] = useState<string>('settings')

    async function getCSSProperties() {
        try {
            if (!params.id) return
            if (reduxForm.cssProperties.length > 0) {
                return
            }
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ContainerMixedContentPanel getCSSProperties')
            const getUrl = getApiUrl(api_url_css_property_list, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormGetCSSProperties(response.data))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ContainerMixedContentPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getCSSProperties',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContainerMixedContentPanel',
                'getCSSProperties',
            ))
        }
    }

    async function onGetData() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ContainerMixedContentPanel onGetData')

            const getUrl = `${mixedMediaContent.detailUrl}${params.panelValue}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setFormState({
                        ...formState,
                        mixedMediaContent: {
                            ...formState.mixedMediaContent,
                            fields: response.data,
                        }
                    })
                    dispatch(reduxModalSetEditStylesSite({
                        id: response.data.id,
                        styles: response.data.styles_edit,
                    }))
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ContainerMixedContentPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onGetData',
                        skipSend404Detail: true,
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContainerMixedContentPanel',
                'onGetData',
            ))
        }
    }

    let wto: any
    function onSetTabValue(e: any) {
        setTabValue(e)
        if (e === 'blocks') {
            clearTimeout(wto)
            wto = setTimeout(() => {
                const elmnt = document.getElementById('activeNavElement')
                if (elmnt) {
                    scrollIntoView(elmnt, {
                        align: {
                            left: 0,
                            leftOffset: 0,
                            top: 0,
                            topOffset: 0,
                        },
                        time: 0,
                    })
                }
            }, 100)
        }
        if (e !== 'styles') {
            autoSaveStyles({
                component: 'ContainerMixedContentPanel',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
        }
    }

    return (
        <div className={`container-mixed-panel-web mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className='cmpw-segment-wrap'>
                <IonSegment
                    className='cmpw-segment'
                    color=''
                    onIonChange={(e) => onSetTabValue(e.detail.value)}
                    value={tabValue}
                >
                    <IonSegmentButton
                        className='pfcw-tab-button'
                        value='blocks'
                    >
                        <IonLabel className='pfcw-tab-label'>{reduxText[8147]}</IonLabel>
                    </IonSegmentButton>
                    {params.panelValue && (
                        <>
                            <IonSegmentButton
                                className='cmpw-tab-button'
                                value='styles'
                            >
                                <IonLabel className='cmpw-tab-label'>{reduxText[6411]}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton
                                className='cmpw-tab-button'
                                value='settings'
                            >
                                <IonLabel className='cmpw-tab-label'>{reduxText[5332]}</IonLabel>
                            </IonSegmentButton>
                        </>
                    )}
                </IonSegment>
            </div>
            {(tabValue !== 'blocks' && isLoading)
                ? (
                    <ListSpinner isLoading />
                ) : (
                    <React.Fragment>
                        {tabValue === 'settings' && (
                            <DetailMixedContentPanel
                                formState={formState}
                                handleInputChange={handleInputChange}
                                helpers={helpers}
                                mixedMediaContentType={contentType !== 'project' ? contentType : undefined}
                                newMixedMediaContent={newMixedMediaContent}
                                onGetData={onGetData}
                                setFormState={setFormState}
                                setHelpers={setHelpers}
                                setTabValue={onSetTabValue}
                            />
                        )}
                        {tabValue === 'blocks' && (
                            <ListMixedMediaContentPanel
                                contentType={contentType}
                                formState={formState}
                                newMixedMediaContent={newMixedMediaContent}
                                newMixedMediaContentType={newMixedMediaContentType}
                                setTabValue={onSetTabValue}
                            />
                        )}
                        {tabValue === 'styles' && (
                            <StyleMixedContentPanel
                                formState={formState}
                                handleInputChange={handleInputChange}
                                setTabValue={onSetTabValue}
                            />
                        )}
                    </React.Fragment>
                )}
        </div>
    )
})
