// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    getApiUrlV2,
    getDetailObject,
    parseQuery,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type AgendaDuQuartierPortalStripeProps = {
    stripe_invoice_id?: string
    // stripe_invoice_number: string
}

// main
export const AgendaDuQuartierPortalStripe: React.FC<AgendaDuQuartierPortalStripeProps> = React.memo(({
    stripe_invoice_id,
    // stripe_invoice_number,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    return (
        <div className='agenda-du-quartier-payment-success'>
            <div className='adgps-content'>
                <p className='adgps-title'>Moyen de paiement</p>
                <p>Accéder au portail Stripe pour modifier votre moyen de paiement</p>
                <div className='adgps-button-wrap'>
                    <Button
                        edit={false}
                        href={`https://billing.stripe.com/p/login/test_00gaGh0nK5mX5JCdQQ?prefilled_email=${encodeURIComponent(reduxAuth.settings?.user?.email || '')}`}
                        // href={`https://billing.stripe.com/p/login/fZedRm41fe056Gs5kk?prefilled_email=${encodeURIComponent(reduxAuth.settings?.user?.email || '')}`}  // TODO: when go live
                        text='Accéder au portail'
                    />
                </div>
            </div>
            {isLoading && <Loader isOpen />}
        </div>
    )
})
