// react components
import React from 'react'
import axios from 'axios'

// data
import {
    reduxFormHelpersState,
} from 'data'

// main
export function getMediaSoundcloudV2({
    formState,
    helpers,
    image_only,
    prefixImage,
    prefixLink = '',
    setFormState,
    setGetMetaIsLoading,
    setHelpers,
    urltoParse,
}: {
    formState: any
    helpers: reduxFormHelpersState
    image_only: boolean
    prefixImage: string
    prefixLink?: string
    setFormState: React.Dispatch<any>
    setGetMetaIsLoading: React.Dispatch<boolean>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    urltoParse: string
}) {

    setGetMetaIsLoading(true)

    let urltoParse1 = encodeURIComponent(urltoParse)
    const urltoParse2 = urltoParse1.includes('http')
    if (!urltoParse2) {
        urltoParse1 = `http://${urltoParse1}`
    }
    axios({
        method: 'get',
        url: `https://opengraph.io/api/1.1/site/${urltoParse1}?app_id=5bb9403dc6006e1b00c78cda&full_render=true&accept_lang=auto&cache_ok=false`,
    })
        .then((response) => { // response data form opengraph
            if (process.env.NODE_ENV === 'development') console.log(response)
            let imageFile = ''
            if (response.data.htmlInferred.image) {
                imageFile = response.data.htmlInferred.image
            } else if (response.data.htmlInferred.images[0]) {
                imageFile = response.data.htmlInferred.images[0]
            }
            if (image_only) {
                const toReturn = {
                    ...formState,
                    data: {
                        ...formState.data,
                        errors: {
                            ...formState.data.errors,
                            [`${prefixImage}image_doka_output_data`]: '',
                            [`${prefixImage}image_original`]: '',
                            [`${prefixImage}image_square_doka_output_data`]: '',
                            [`${prefixImage}image_square`]: '',
                            [`${prefixImage}image`]: '',
                            [`${prefixLink}link_url`]: '',
                            meta_image_url: '',
                        },
                        fields: {
                            ...formState.data.fields,
                            [`${prefixImage}image`]: imageFile,
                            [`${prefixLink}link_url`]: urltoParse,
                            get_image_hq: imageFile,
                            get_image_lq: imageFile,
                            get_image_mq: imageFile,
                            meta_image_url: imageFile,
                        },
                    }
                }
                setFormState(toReturn)
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            } else {
                const toReturn = {
                    ...formState,
                    data: {
                        ...formState.data,
                        errors: {
                            ...formState.data.errors,
                            [`${prefixImage}image_doka_output_data`]: '',
                            [`${prefixImage}image_original`]: '',
                            [`${prefixImage}image_square_doka_output_data`]: '',
                            [`${prefixImage}image_square`]: '',
                            [`${prefixImage}image`]: '',
                            [`${prefixLink}link_url`]: '',
                            description: '',
                            meta_image_url: '',
                            name: '',
                        },
                        fields: {
                            ...formState.data.fields,
                            [`${prefixImage}image_doka_output_data`]: 'to_delete_original',
                            [`${prefixImage}image_original`]: '',
                            [`${prefixImage}image_square_doka_output_data`]: 'to_delete_all',
                            [`${prefixImage}image_square`]: imageFile,
                            [`${prefixImage}image`]: imageFile,
                            [`${prefixLink}link_url`]: urltoParse,
                            description: response.data.htmlInferred.description,
                            get_image_hq: imageFile,
                            get_image_lq: imageFile,
                            get_image_mq: imageFile,
                            meta_image_url: imageFile,
                            name: response.data.htmlInferred.title,
                        },
                    }
                }
                setFormState(toReturn)
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
            setGetMetaIsLoading(false)
        })
        .catch((error) => {
            setFormState({
                ...formState,
                data: {
                    ...formState.data,
                    errors: {
                        ...formState.data.errors,
                        [`${prefixLink}link_url`]: error.response?.statusText || 'Error',
                    },
                    fields: {
                        ...formState.data.fields,
                        [`${prefixLink}link_url`]: urltoParse,
                    },
                },
            })
            setGetMetaIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log(error)
            if (process.env.NODE_ENV === 'development') console.log(error.response)
        })
}
