// data
import {
    contentTypeModelType,
    reduxTextState,
} from 'data'

// serializers
import {
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getReduxTextId,
} from 'services'

// main
export function getFieldTitle({
    column,
    contentType,
    contentTypeSettings,
    profile,
    reduxText,
}: {
    column: string
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    profile: ProfileDetailAdminSerializerV2 | undefined
    reduxText: reduxTextState['data']
}) {

    const contentTypeSettingsCustom = profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]

    const newColumn = column.split('__')[0]
    const fieldOptions = contentTypeSettings.field?.[newColumn]
    let valueFieldOptions = undefined
    if (['ManyToManyField', 'ManyToManyRel', 'ManyToOneRel'].includes(contentTypeSettings.field?.[newColumn]?.fieldType)) {
        const valueField = column.split('__')[1] || 'name'
        valueFieldOptions = fieldOptions.fields?.[valueField]
    }
    const title = valueFieldOptions?.verboseName ||
        contentTypeSettingsCustom?.field?.[column]?.verboseName ||
        contentTypeSettings.field?.[column]?.verboseNameForList ||
        contentTypeSettings.field?.[column]?.verboseName
    let relation_field_title = ''
    if (!title && column.split('__')[1]) {
        relation_field_title = profile?.settings_data_json?.contentTypeSettingsCustom?.[`${contentType.split('_')[0]}_${column.split('__')[0]}`]?.field?.[column.split('__')[1]]?.verboseName ||
            contentTypeSettingsCustom?.list?.related_fields_info?.[column]?.verboseName ||
            ''
    }

    return {
        columnTitle: getReduxTextId(title! || relation_field_title, reduxText) || column,
        fieldOptions: fieldOptions,
    }
}
