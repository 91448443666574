// react components
import React, {
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonReorderGroup,
    IonReorder,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    Checkbox,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// props
type ContentPanel818Props = {
    formState: PortfolioFormState
    handleInputChange: any
}

// main
export const ContentPanel818: React.FC<ContentPanel818Props> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother
    const content = formState.content

    const socialArray: {
        id: string
        checked: boolean
        name: string
    }[] = [
            {
                'id': 'email',
                'checked': false,
                'name': 'Email',
            },
            {
                'id': 'facebook',
                'checked': false,
                'name': 'Facebook',
            },
            {
                'id': 'hatena',
                'checked': false,
                'name': 'Hatena',
            },
            {
                'id': 'instapaper',
                'checked': false,
                'name': 'Instapaper',
            },
            {
                'id': 'line',
                'checked': false,
                'name': 'Line',
            },
            {
                'id': 'linkedin',
                'checked': false,
                'name': 'Linkedin',
            },
            {
                'id': 'livejournal',
                'checked': false,
                'name': 'Livejournal',
            },
            {
                'id': 'mailru',
                'checked': false,
                'name': 'Mailru',
            },
            {
                'id': 'pocket',
                'checked': false,
                'name': 'Pocket',
            },
            {
                'id': 'reddit',
                'checked': false,
                'name': 'Reddit',
            },
            {
                'id': 'telegram',
                'checked': false,
                'name': 'Telegram',
            },
            {
                'id': 'tumblr',
                'checked': false,
                'name': 'Tumblr',
            },
            {
                'id': 'twitter',
                'checked': false,
                'name': 'Twitter',
            },
            {
                'id': 'vk',
                'checked': false,
                'name': 'VK',
            },
            {
                'id': 'viber',
                'checked': false,
                'name': 'Viber',
            },
            {
                'id': 'weibo',
                'checked': false,
                'name': 'Weibo',
            },
            {
                'id': 'whatsapp',
                'checked': false,
                'name': 'Whatsapp',
            },
            {
                'id': 'workplace',
                'checked': false,
                'name': 'Workplace',
            },
        ]

    const [items, setItems] = useState<typeof socialArray>(getInitialArray())

    function getInitialArray() {
        try {
            const initialArray = content.fields?.text_short?.split(',') || []
            const newItems: typeof socialArray = []
            socialArray.map((item) => {
                if (initialArray.includes(item.id)) {
                    newItems.push({
                        ...item,
                        checked: true,
                    })
                } else {
                    newItems.push(item)
                }
            })
            return newItems
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentPanel818',
                'getInitialArray',
            ))
            return []
        }
    }

    function onHandleCheckboxChange(name: string, checked: boolean) {
        try {
            const newItems: typeof socialArray = []
            items.map((item) => {
                if (item.id === name) {
                    newItems.push({
                        ...item,
                        checked: checked,
                    })
                } else {
                    newItems.push(item)
                }
            })
            setItems(newItems)
            onHandleInputChange(newItems)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentPanel818',
                'onHandleCheckboxChange',
            ))
        }
    }

    function onHandleInputChange(newItems: typeof socialArray) {
        try {
            const newItemsChecked = newItems.filter((item) => item.checked)
            const newItemsString = newItemsChecked.map((item) => item.id).join(',')
            handleInputChange({
                name: 'text_short',
                value: newItemsString,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentPanel818',
                'onHandleInputChange',
            ))
        }
    }

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentPanel818',
                'doReorder',
            ))
        }
    }

    function onSortEnd(event: any) {
        try {
            const newItems = arrayMoveImmutable(items, event.detail.from, event.detail.to)
            setItems(newItems)
            onHandleInputChange(newItems)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentPanel818',
                'onSortEnd',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    // Content
    const fieldsInline = content.fields

    if (!fieldsMother || !fieldsInline) return null

    return (
        <React.Fragment>
            <IonReorderGroup
                disabled={false}
                onIonItemReorder={doReorder}
            >
                {items.map((item) => {
                    return (
                        <div
                            key={item.id}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    checked={item.checked}
                                    onChange={(e) => onHandleCheckboxChange(item.id, e.target.checked)}
                                />
                                <span
                                    style={{
                                        display: 'inline-block',
                                        paddingLeft: '10px',
                                    }}>
                                    {item.name}
                                </span>
                            </div>
                            <IonReorder slot='end' />
                        </div>
                    )
                })}
            </IonReorderGroup>
        </React.Fragment>
    )
})
