// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Dialog,
} from '@mui/material'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    LinkHelper,
    Loader,
    MainDetailEditBlockWeb,
} from 'components'

// data
import {
    defaultReduxState,
    languageObjectSite,
    reduxModalContentDetailHide,
    reduxModalContentDetailSetObjectId,
    reduxModalErrorEventHandler,
    reduxModalPlayerOnDismiss,
    reduxModalSliderSetData,
} from 'data'

// pages
import {
    BlockTemplateDetailBlock,
    EventDetailBlockWeb,
    EventImageDetailBlockWeb,
    HelpCenterTopicItemDetailBlock,
    MaterialDetailBlockWeb,
    OfferDetailBlockWeb,
    PageTemplateDetailBlock,
    ProductDetailWrapper,
    ProfileDetailBlock,
    ProfileDetailBlockWebOld,
    ProjectDetailBlockWeb,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// main
export const DetailContentPageModal: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModal = useSelector((state: defaultReduxState) => state.reduxModal)

    const [languageId, setLanguageId] = useState<number>()

    const apiUrl = reduxModal.contentDetail.apiUrl
    const app = reduxModal.contentDetail.app
    const component = reduxModal.contentDetail.component
    const componentActionSheet = reduxModal.contentDetail.componentActionSheet
    const contentObjectId = reduxModal.contentDetail.contentObjectId
    const isOpen = reduxModal.contentDetail.isOpen
    const items = reduxModal.contentDetail.items
    const model = reduxModal.contentDetail.model
    const nextUrl = reduxModal.contentDetail.nextUrl
    const onSearchNext = reduxModal.contentDetail.onSearchNext

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [object, setObject] = useState<any>('')
    const [isLoading, setIsLoading] = useState(false)
    const [modalKeys, setModalKeys] = useState<{
        [key: string]: number,
    }>({})

    useEffect(() => {
        if (isOpen) {
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            getData(contentObjectId!, languageId)
        } else {
            setAxiosCancelToken(undefined)
            setObject('')
            setIsLoading(false)
            setModalKeys({})
        }
    }, [
        contentObjectId,
        isOpen,
        languageId,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    const components: any = {
        BlockTemplateDetailBlock,
        EventDetailBlockWeb,
        EventImageDetailBlockWeb,
        HelpCenterTopicItemDetailBlock,
        MaterialDetailBlockWeb,
        OfferDetailBlockWeb,
        PageTemplateDetailBlock,
        ProductDetailWrapper,
        ProfileDetailBlock,
        ProfileDetailBlockWebOld,
        ProjectDetailBlockWeb,
    }
    const ComponentName = components[component!]

    async function getData(objectId: number, languageId?: number | undefined) {
        try {
            // setObject('')
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DetailContentPageModal getData')
            if (!objectId) return
            setIsLoading(true)
            let getUrl = `${apiUrl}${objectId}/` // Keep the last '/' for Safari !!!!!!!
            if (languageId || languageObjectSite[reduxAuth.settings?.language!]) getUrl += `?language_id=${languageId || languageObjectSite[reduxAuth.settings?.language!]}`
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setObject(response.data)
                    if (app === 'event') {
                        if (response.data.product_list?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.product_list))
                            setModalKeys({
                                ...modalKeys,
                                product_list: modalKey
                            })
                        }
                        if (response.data.project_list?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.project_list))
                            setModalKeys({
                                ...modalKeys,
                                project_list: modalKey
                            })
                        }
                        if (response.data.service_list?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.service_list))
                            setModalKeys({
                                ...modalKeys,
                                service_list: modalKey
                            })
                        }
                    } else if (app === 'product') {
                        if (response.data.related_projects?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.related_projects))
                            setModalKeys({
                                ...modalKeys,
                                related_projects: modalKey
                            })
                        }
                    } else if (app === 'project') {
                        if (response.data.related_products?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.related_products))
                            setModalKeys({
                                ...modalKeys,
                                related_products: modalKey
                            })
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'DetailContentPageModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailContentPageModal',
                'getData',
            ))
        }
    }

    function onNextSlide() {
        try {
            const currentIndex = items!.findIndex((obj) => obj.id === contentObjectId)
            if (currentIndex + 1 === items!.length) {
                handleClose()
            } else if (currentIndex + 3 >= items!.length) {
                if (nextUrl && onSearchNext) {
                    onSearchNext(true)
                }
                const nextObject = items![currentIndex + 1]
                dispatch(reduxModalContentDetailSetObjectId(nextObject.id))
            } else {
                const nextObject = items![currentIndex + 1]
                dispatch(reduxModalContentDetailSetObjectId(nextObject.id))
            }
            if (((reduxModal.player.currentTrack?.id === object.id) || (reduxModal.player.albumId === object.id)) && (reduxModal.player.playerPosition === 'detail')) {
                dispatch(reduxModalPlayerOnDismiss())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailContentPageModal',
                'onNextSlide',
            ))
        }

    }

    function onPrevSlide() {
        try {
            const currentIndex = items!.findIndex((obj) => obj.id === contentObjectId)
            if (currentIndex === 0) {
                handleClose()
            } else {
                const nextObject = items![currentIndex - 1]
                dispatch(reduxModalContentDetailSetObjectId(nextObject.id))
            }
            if (((reduxModal.player.currentTrack?.id === object.id) || (reduxModal.player.albumId === object.id)) && (reduxModal.player.playerPosition === 'detail')) {
                dispatch(reduxModalPlayerOnDismiss())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailContentPageModal',
                'onPrevSlide',
            ))
        }
    }

    function handleClose() {
        try {
            dispatch(reduxModalContentDetailHide())
            if (((reduxModal.player.currentTrack?.id === object.id) || (reduxModal.player.albumId === object.id)) && (reduxModal.player.playerPosition === 'detail')) {
                dispatch(reduxModalPlayerOnDismiss())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailContentPageModal',
                'handleClose',
            ))
        }
    }

    if (!isOpen) return null

    return (
        <Dialog
            className='detail-content-page-container-web'
            classes={{ paper: 'mo-dialog-wrapper wrapper' }}
            disableEnforceFocus
            fullScreen
            onClose={() => handleClose()}
            open
        >
            <LinkHelper
                className='dcpcw-backdrop'
                edit={false}
                noContentDetailClose
                onClick={() => handleClose()}
            />
            <div className='dcpcw-navigation'>
                <LinkHelper
                    className='dcpcw-previous'
                    edit={false}
                    noContentDetailClose
                    onClick={() => onPrevSlide()}
                >
                    <IconBlock
                        edit={false}
                        iconClass='dcpcw-icon mo-new-icon-chevron-left-solid'
                    />
                </LinkHelper>
                <LinkHelper
                    className='dcpcw-close'
                    edit={false}
                    noContentDetailClose
                    onClick={() => handleClose()}
                >
                    <IconBlock
                        edit={false}
                        iconClass='dcpcw-close-icon mo-new-icon-times-solid'
                    />
                </LinkHelper>
                <LinkHelper
                    className='dcpcw-next'
                    edit={false}
                    noContentDetailClose
                    onClick={() => onNextSlide()}
                >
                    <IconBlock
                        edit={false}
                        iconClass='dcpcw-icon mo-new-icon-chevron-right-solid'
                    />
                </LinkHelper>
            </div>
            <div className='dcpcw-modal-wrap'>
                <div className='dcpcw-header'>
                    <div className='dcpcw-header-left'>
                        {object.album && (
                            <div className='hcw-album-block'>
                                <ImageHelper
                                    alt={object.album.name}
                                    className='hcw-album-image'
                                    dominant_color={object.get_dominant_color}
                                    src={object.album.get_image_lq}
                                />
                                <p className='hcw-album-name mo-clamp-web1'>{object.album.name}</p>
                            </div>
                        )}
                    </div>
                    <div className='dcpcw-title-wrap'>
                        <h1 className='dcpcw-title mo-clamp-web1'>{object.name}</h1>
                    </div>
                    <MainDetailEditBlockWeb
                        componentActionSheet={componentActionSheet}
                        inModal
                        model={model}
                        object={object}
                        refreshData={() => getData(contentObjectId!, languageId)}
                    />
                </div>
                <div className='dcpcw-modal-content'>
                    {object && (
                        <ComponentName
                            inModal
                            languageId={languageId}
                            modalKeys={modalKeys}
                            object={object}
                            setLanguageId={setLanguageId}
                        />
                    )}
                </div>
            </div>
            <Loader
                backdropDismiss
                isOpen={isLoading}
            />
        </Dialog>
    )
}
