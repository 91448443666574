// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    Backdrop,
    CircularProgress,
} from '@mui/material'

// props
type LoaderProps = {
    backdropDismiss?: boolean
    isOpen: boolean
    message?: string
}

// main
export const Loader: React.FC<LoaderProps> = React.memo(({
    backdropDismiss,
    isOpen,
    message,
}) => {

    if (!isOpen) return null

    return (
        <LoaderHelper
            backdropDismiss={backdropDismiss}
            message={message}
        />
    )
})


// props
type LoaderHelperProps = {
    backdropDismiss?: boolean
    message?: string
}

// main
const LoaderHelper: React.FC<LoaderHelperProps> = React.memo(({
    backdropDismiss,
    message,
}) => {

    const timeoutRef = useRef<NodeJS.Timeout | null>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            setShowLoader(true)
        }, 1000)

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [])

    if (!showLoader) return null

    return (
        <Backdrop
            className='loader'
            onClick={backdropDismiss ? () => setShowLoader(false) : undefined}
            open
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <CircularProgress color='inherit' />
            {message && <p className='loader-message'>{message}</p>}
        </Backdrop>
    )
})
