// react components
import React, {
    useState,
} from 'react'
import {
    IonActionSheet,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
} from '@ionic/react'
import {
    Snackbar,
} from '@mui/material'
import axios from 'axios'
import {
    isMobileOnly,
} from 'react-device-detect'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    Button,
    DeleteAlert,
    Loader,
} from 'components'

// data
import {
    api_url_block_template_form_active,
    api_url_block_template_form_update,
    api_url_event_form_active,
    api_url_event_form_update,
    api_url_material_form_active,
    api_url_material_form_update,
    api_url_offer_form_active,
    api_url_offer_form_update,
    api_url_page_template_form_active,
    api_url_page_template_form_update,
    api_url_product_form_active,
    api_url_product_form_update,
    api_url_profile_form_active,
    api_url_profile_form_update,
    api_url_project_form_active,
    api_url_project_form_update,
    api_url_service_form_active,
    api_url_service_form_update,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    reduxAuthFetchSettingsHelper,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type InactiveButtonProps = {
    detail?: boolean
    edit: boolean
    model: string
    objectId: number
    objectName: string
    publishDisabled?: boolean
    setDeleted: React.Dispatch<any> | undefined
}

// main
export const InactiveButton: React.FC<InactiveButtonProps> = React.memo(({
    detail,
    edit,
    model,
    objectId,
    objectName,
    publishDisabled,
    setDeleted,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isPublished, setIsPublished] = useState(false)
    const [processMessage, setProcessMessage] = useState('')
    const [showActionSheetMobile, setShowActionSheetMobile] = useState(false)
    const [showActionSheetWeb, setShowActionSheetWeb] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })
    const [toastIsOpen, setToastIsOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')

    const modelUrls: any = {
        blocktemplate: {
            activeUrl: api_url_block_template_form_active,
            deleteUrl: api_url_block_template_form_update,
        },
        event: {
            activeUrl: api_url_event_form_active,
            deleteUrl: api_url_event_form_update,
        },
        material: {
            activeUrl: api_url_material_form_active,
            deleteUrl: api_url_material_form_update,
        },
        offer: {
            activeUrl: api_url_offer_form_active,
            deleteUrl: api_url_offer_form_update,
        },
        pagetemplate: {
            activeUrl: api_url_page_template_form_active,
            deleteUrl: api_url_page_template_form_update,
        },
        product: {
            activeUrl: api_url_product_form_active,
            deleteUrl: api_url_product_form_update,
        },
        profile: {
            activeUrl: api_url_profile_form_active,
            deleteUrl: api_url_profile_form_update,
        },
        project: {
            activeUrl: api_url_project_form_active,
            deleteUrl: api_url_project_form_update,
        },
        service: {
            activeUrl: api_url_service_form_active,
            deleteUrl: api_url_service_form_update,
        },
    }

    const actionButtons = [
        {
            handler: () => {
                setIsDeleteAlertOpen(true)
                setShowActionSheetWeb({ open: false, event: undefined })
            },
            role: 'destructive',
            text: reduxText[2983],
        }, {
            handler: () => {
                handlePublish()
                setShowActionSheetWeb({ open: false, event: undefined })
            },
            text: reduxText[3518],
        },
    ]

    const actionButtonsReduced = [
        {
            handler: () => {
                handlePublish()
                setShowActionSheetWeb({ open: false, event: undefined })
            },
            text: reduxText[3518],
        },
    ]

    async function handleDelete() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InactiveButton handleDelete')
            setProcessMessage(reduxText[1242])
            setIsProcessing(true)
            const deleteUrl = getApiUrl(`${modelUrls[model].deleteUrl}${objectId}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setToastMessage(reduxText[5899])
                    setToastIsOpen(true)
                    if (setDeleted) setDeleted(true)
                    setIsProcessing(false)
                })
                .catch((error) => {
                    setIsProcessing(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'InactiveButton',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsProcessing(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'InactiveButton',
                'handleDelete',
            ))
        }
    }

    async function handlePublish() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InactiveButton handlePublish')
            setProcessMessage(reduxText[5900])
            setIsProcessing(true)
            const formData = new FormData()
            formData.append('active', 'true')
            const activeUrl = getApiUrl(`${modelUrls[model].activeUrl}${objectId}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setToastMessage(reduxText[5901])
                    setToastIsOpen(true)
                    setIsPublished(true)
                    setIsProcessing(false)
                    if (model === 'profile') reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                })
                .catch((error) => {
                    setIsProcessing(false)
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'InactiveButton',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePublish',
                    })
                })
        } catch (error) {
            setIsProcessing(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'InactiveButton',
                'handlePublish',
            ))
        }
    }

    const buttons = setDeleted ? actionButtons : actionButtonsReduced

    if (!edit) {
        if (isMobileOnly) {
            return (
                <React.Fragment>
                    {!isPublished && (
                        <div
                            className='inactive-button-web'
                            onClick={() => setShowActionSheetMobile(true)}
                        >
                            <p>{reduxText[2674]}</p>
                        </div>
                    )}
                    <IonActionSheet
                        isOpen={showActionSheetMobile}
                        onDidDismiss={() => setShowActionSheetMobile(false)}
                        buttons={setDeleted ? actionButtons : actionButtonsReduced}
                    />
                    <Alert
                        buttons={[
                            {
                                color: 'error',
                                handler: () => handleDelete(),
                                text: reduxText[1241],
                            },
                            {
                                text: reduxText[1236],
                            },
                        ]}
                        header={reduxText[1234]}
                        isOpen={isDeleteAlertOpen}
                        message={reduxText[1235]}
                        onDidDismiss={() => setIsDeleteAlertOpen(false)}
                    />
                    <Snackbar
                        action={
                            <Button
                                edit={false}
                                fill='clear'
                                onClick={() => setToastIsOpen(false)}
                                size='small'
                                text={reduxText[4742]}
                            />
                        }
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        autoHideDuration={3000}
                        message={toastMessage}
                        onClose={() => setToastIsOpen(false)}
                        open={toastIsOpen}
                    />
                    {isProcessing && (
                        <Loader
                            isOpen
                            message={processMessage}
                        />
                    )}
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                {!isPublished && (
                    <Button
                        disabled={publishDisabled}
                        className={`inactive-button-web ${detail ? 'detail' : ''}`}
                        edit={edit}
                        onClick={(e) => setShowActionSheetWeb({ open: true, event: e.nativeEvent })}
                        size='x-small'
                        text={reduxText[2674]}
                    />
                )}
                <IonPopover
                    event={showActionSheetWeb.event}
                    isOpen={showActionSheetWeb.open}
                    onDidDismiss={() => setShowActionSheetWeb({ open: false, event: undefined })}
                >
                    <IonList>
                        {buttons.map((val, i) => (
                            <IonItem
                                key={i}
                                button
                                detail={false}
                                onClick={val.handler}
                            >
                                <IonLabel>{val.text}</IonLabel>
                            </IonItem>
                        ))}

                    </IonList>
                </IonPopover>
                {isDeleteAlertOpen && (
                    <DeleteAlert
                        handleDelete={handleDelete}
                        objectName={objectName}
                        setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                        setShowActionSheet={setShowActionSheetWeb}
                    />
                )}
                <Snackbar
                    action={
                        <Button
                            edit={false}
                            fill='clear'
                            onClick={() => setToastIsOpen(false)}
                            size='small'
                            text={reduxText[4742]}
                        />
                    }
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    message={toastMessage}
                    onClose={() => setToastIsOpen(false)}
                    open={toastIsOpen}
                />
                {isProcessing && (
                    <Loader
                        isOpen
                        message={processMessage}
                    />
                )}
            </React.Fragment>
        )
    }
    return null
})
