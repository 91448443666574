// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    getApiUrlV2,
    getDetailObject,
} from 'services'

// props
type PickAnOfferBlockStep1Props = {
    fields: any
    handleInputChange: (e: any) => void
    mainSubscriptionData: {
        id: string
        name: string
        prices: {
            monthly: {
                id: string
                price: string
            }
            yearly: {
                id: string
                price: string
            }
        }
    }[]
    onPeriodChange: (period: 'monthly' | 'yearly') => void
    period: 'monthly' | 'yearly'
}

// main
export const PickAnOfferBlockStep1: React.FC<PickAnOfferBlockStep1Props> = React.memo(({
    fields,
    handleInputChange,
    mainSubscriptionData,
    onPeriodChange,
    period,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [items, setItems] = useState<any[]>([])

    useEffect(() => {
        onGetDetailData()
    }, [])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/list/project/offre/`, reduxAuth),
                component: 'PickAnOfferBlockStep1',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PickAnOfferBlockStep1',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div className='paob-step1'>
            <div className='paob-registrations'>
                <div>
                    <div>
                        <input
                            checked={period === 'monthly'}
                            name='period'
                            onChange={() => {
                                onPeriodChange('monthly')
                            }}
                            type='radio'
                            value={period}
                        />
                        <span>Par mois</span>
                    </div>
                    <div>
                        <input
                            checked={period === 'yearly'}
                            name='period'
                            onChange={() => {
                                onPeriodChange('yearly')
                            }}
                            type='radio'
                            value={period}
                        />
                        <span>Par an</span>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th className='paob-sub-header'>
                                <div className='paob-sub-row-header'>
                                    <p className='paob-sub-header-name'>L'Agenda simplifié</p>
                                    <p className='paob-sub-header-description'>
                                        Nous éditons,<br />
                                        Vous imprimez,<br />
                                        Vous distribuez
                                    </p>
                                </div>
                            </th>
                            <th className='paob-sub-header'>
                                <div className='paob-sub-row-header'>
                                    <p className='paob-sub-header-name'>L'Agenda livré chez vous</p>
                                    <p className='paob-sub-header-description'>
                                        Nous éditons,<br />
                                        Nous imprimons,<br />
                                        Vous distribuez
                                    </p>
                                </div>
                            </th>
                            <th className='paob-sub-header'>
                                <div className='paob-sub-row-header'>
                                    <p className='paob-sub-header-name'>L'Agenda chez l'habitant</p>
                                    <p className='paob-sub-header-description'>
                                        Nous éditons,<br />
                                        Nous imprimons,<br />
                                        Nous distribuons !
                                    </p>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(val => (
                            <tr
                                key={val.id}
                                className='paob-sub-row'
                            >
                                <td>
                                    <div className='paob-sub-row-div'>
                                        <p className='paob-sub-row-name'>{val.name}</p>
                                        <p className='paob-sub-row-description'>{val.description}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='paob-sub-row-div-options'>
                                        <p className='paob-sub-row-name'>{val.custom_text_field_1}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='paob-sub-row-div-options'>
                                        <p className='paob-sub-row-name'>{val.custom_text_field_2}</p>
                                    </div>
                                </td>
                                <td>
                                    <div className='paob-sub-row-div-options'>
                                        <p className='paob-sub-row-name'>{val.custom_text_field_3}</p>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr className='paob-sub-row'>
                            <td></td>
                            {mainSubscriptionData.map(val => (
                                <td>
                                    <div className='paob-sub-row-div-options center'>
                                        <Button
                                            edit={false}
                                            onClick={() => handleInputChange({
                                                name: 'stripe_price_1',
                                                value: {
                                                    id: val.prices[period].id,
                                                    product_id: val.id,
                                                },
                                            })}
                                            text={val.prices[period].price}
                                            fill={(fields.stripe_price_1?.id === val.prices[period].id) ? 'solid' : 'outline'}
                                        />
                                    </div>
                                </td>
                            ))}
                        </tr>
                    </tfoot>
                </table>
                <p className='paob-vat'>* TVA non applicable, article 261, 7-1° CGI</p>
            </div>
        </div>
    )
})
