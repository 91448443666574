// react components
import React from 'react'
import {
    NavLink,
} from 'react-router-dom'

// data
import {
    contentTypeModelType,
} from 'data'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// props
export type AGSLAdminMenuProps = {
    userObject: {
        id: number
        menu: {
            title: string
            slug: string
            children: {
                id: number
                content_type: contentTypeModelType
                slug: string
                sub_content_type: number
                title: string
                list_params?: string
            }[]
        }[]
        profile: ProfileDetailAdminSerializerV2
    }
}

// main
export const AGSLAdminMenu: React.FC<AGSLAdminMenuProps> = React.memo(({
    userObject,
}) => {

    return (
        <div className='admin-menu'>
            {userObject.menu.map(val => (
                <div
                    key={val.slug}
                    className='admin-menu-item'
                >
                    <div className='ami-tab-item-wrap'>
                        {/* <NavLink
                            className='ami-tab-item'
                            to={`/${val.slug}/`}
                        >
                            {val.title}
                        </NavLink> */}
                        <span className='ami-tab-item nolink'>{val.title}</span>
                    </div>
                    {val.children && val.children.map(child => (
                        <div className='ami-children-wrap open'>
                            <div className='admin-menu-item-view'>
                                <NavLink
                                    className='amiv-tab-item-children'
                                    to={`/${val.slug}/${child.slug}/`}
                                >
                                    {child.title}
                                </NavLink>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
})
