// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    media_url,
} from 'data'

// services
import {
    setStaffPermission,
} from 'services'

// props
type NotFoundComponentProps = {
    model?: contentTypeModelType
    objectId?: number
    refreshData?: () => void
    statusCode?: number
}

// main
export const NotFoundComponent: React.FC<NotFoundComponentProps> = React.memo(({
    model,
    objectId,
    refreshData,
    statusCode,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [backgroundImage] = useState<string>(`${media_url}global/not-found/dark/${Math.floor(Math.random() * 13)}.jpeg`)

    const statusText: any = {
        403: reduxAuth.authenticated ? reduxText[8013] : reduxText[8014],
        404: reduxText[7928],
    }

    function onSetStaffPermission() {
        if (objectId && model) {
            setStaffPermission({
                component: 'NotFoundComponent',
                contentTypeModel: model,
                dispatch,
                objectId: objectId,
                reduxAuth,
                refreshData,
            })
        }
    }

    return (
        <div
            className='not-found-component'
            style={{
                backgroundImage: `url('${backgroundImage}')`
            }}
        >
            <div className='nfc-wrap'>
                <p className='nfc-text1'>{statusCode || 404}</p>
                <p className='nfc-text2'>{statusCode ? statusText[statusCode] : reduxText[7928]}</p>
                {reduxAuth.settings?.user?.is_staff && statusCode === 403 && objectId && model && (
                    <Button
                        color='white'
                        edit={false}
                        onClick={onSetStaffPermission}
                        text='Set staff permission'
                    />
                )}
            </div>
        </div>
    )
})
