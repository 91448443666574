// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ChildText2EventInput,
    ChildTextEventInput,
    CityTempEventInput,
    HasCustomAddressEventInput,
} from 'pages'

// serializers
import {
    EventFormState,
} from 'serializers/web'

// props
type AddressEventPanelProps = {
    formState: EventFormState
    handleInputChange: any
}

// main
export const AddressEventPanel: React.FC<AddressEventPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddressEventPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <HasCustomAddressEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CityTempEventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <ChildTextEventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <ChildText2EventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
