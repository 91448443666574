// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Popover,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventPricingListFormSerializer,
} from 'serializers/web'

// props
type EventPricingListFormBlockProps = {
    handleInlineDelete: (e: EventPricingListFormSerializer) => void
    handleInlineRetrieve: (e: EventPricingListFormSerializer) => void
    object: EventPricingListFormSerializer
}

// main
export const EventPricingListFormBlock: React.FC<EventPricingListFormBlockProps> = React.memo(({
    handleInlineDelete,
    handleInlineRetrieve,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showActionPopover, setShowActionPopover] = useState(false)

    const buttons = [
        {
            handler: () => {
                handleInlineRetrieve(object)
                setShowActionPopover(false)
            },
            text: reduxText[2105],
        },
        {
            handler: () => {
                handleInlineDelete(object)
                setShowActionPopover(false)
            },
            text: reduxText[2983],
        },
    ]

    return (
        <tr className='main-list-row EventPricingListFormBlock'>
            <td>{object.name}</td>
            <td><i className={`mlr-icon ${object.is_for_non_adherent ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
            <td>{object.adhesion_pricing?.name}</td>
            <td className='tar'>{object.unit_price} €</td>
            <td className='mlr-action'>
                <Popover
                    buttons={buttons}
                    edit={false}
                    extraZIndex
                    isOpen={showActionPopover}
                    setIsOpen={setShowActionPopover}
                />
            </td>
        </tr>
    )
})
