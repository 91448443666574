// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventPricingDetailFormSerializer,
    EventPricingDetailFormSerializerError,
} from 'serializers/web'

// props
type IsForNonAdherentEventPricingInputProps = {
    errors: EventPricingDetailFormSerializerError | undefined
    fields: EventPricingDetailFormSerializer
    onChange: any
}

// main
export const IsForNonAdherentEventPricingInput: React.FC<IsForNonAdherentEventPricingInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (fields.adhesion_pricing) return null

    return (
        <CheckboxInput
            error={errors?.is_for_non_adherent}
            label={reduxText[8571]}
            name='is_for_non_adherent'
            onChange={onChange}
            value={fields.is_for_non_adherent || ''}
        />
    )
})
