// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_stripe_tax_rate_category,
} from 'data'

// serializers
import {
    ProductDetailFormSerializer,
    ProductDetailFormSerializerError,
} from 'serializers/web'

// props
type StripeTaxRateCategoryProductInputProps = {
    errors: ProductDetailFormSerializerError | undefined
    fields: ProductDetailFormSerializer
    onChange: any
}

// main
export const StripeTaxRateCategoryProductInput: React.FC<StripeTaxRateCategoryProductInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (fields.profile?.id !== 187807) return null

    return (
        <SelectListInput
            apiUrl={`${select_url_stripe_tax_rate_category}?profile_id=${fields.profile?.id}`}
            clearable
            error={errors?.stripe_tax_rate_category}
            label={reduxText[8757]}
            name='stripe_tax_rate_category'
            onChange={onChange}
            value={fields.stripe_tax_rate_category || ''}
        />
    )
})
