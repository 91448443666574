// data
import {
    api_url_product_form_active,
    api_url_product_form_create,
    api_url_product_form_detail,
    api_url_product_form_update,
    reduxAuthState,
    view_url_product_detail,
    view_url_product_form,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function ProductMotherFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'mother',

        // Settings
        detailComponent: 'ProductDetailWrapper',
        model: 'product',

        // Urls
        activeUrl: getApiUrl(api_url_product_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_product_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_product_form_detail, reduxAuth),
        formUrl: view_url_product_form,
        updateUrl: getApiUrl(api_url_product_form_update, reduxAuth),
        viewUrl: view_url_product_detail,

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'name',
        ],
        requiredForeignKey: [
            'profile',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'accept_backorders',
            'active',
            'cover_image_alt',
            'cover_image_doka_output_data',
            'cover_image_square_doka_output_data',
            'custom_date',
            'custom_text',
            'custom_text_2',
            'custom_text_3',
            'custom_text_rich',
            'custom_text_rich_2',
            'custom_text_rich_3',
            'description',
            'external_link',
            'has_stock_management',
            'is_multiple',
            'is_private',
            'is_private_and_listed_in_portfolio',
            'is_unique',
            'is_unlisted',
            'meta_image_url',
            'name',
            'packaging_weight',
            'price_on_request',
            'product_height',
            'product_length',
            'product_width',
            'sku',
            'stock',
            'unit_price',
            'unit_price_open',
        ],
        formFile: [
            'cover_image',
            'cover_image_original',
            'cover_image_square',
        ],
        formForeignKey: [
            'medium_type',
            'portfolio_detail_page',
            'profile',
            'stock_status',
            'stripe_tax_rate_category',
        ],
        formJson: [],
        formJsonNull: [
            'address_json',
            'styles_extra',
        ],
        formManyToMany: [
            'custom_categories',
            'custom_categories_2',
            // 'custom_categories_3',
            'languages',
            'portfolio_user_groups',
            'profile_sections',
            'related_products',
            'related_projects',
            'themes',
        ],
    }
}
