// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    actionFunction,
} from 'services'

// props
type ActionButtonProps = {
    actionObject: {
        extraParams?: {
            [key: string]: string | number
        }
        disabled?: boolean
        function_name?: string
        label?: string
    }
    contentTypeId: number
    iconClass?: string
    objectId: number
    refreshData?: () => void
}

// main
export const ActionButton: React.FC<ActionButtonProps> = React.memo(({
    actionObject,
    contentTypeId,
    iconClass,
    objectId,
    refreshData,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [isLoading, setIsLoading] = useState(false)

    function handleButtonClick() {
        try {
            actionFunction({
                component: 'ActionButton handleButtonClick',
                contentTypeId,
                dispatch,
                extraParams: actionObject.extraParams,
                function_name: actionObject.function_name || '',
                objectId,
                reduxAuth,
                refreshData,
                setIsLoading,
            })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ActionButton',
                'handleButtonClick',
            ))
        }
    }

    return (
        <>
            {iconClass ? (
                <IconBlock
                    edit={false}
                    iconClass={iconClass}
                    onClick={handleButtonClick}
                    tooltipText={actionObject.label}
                />
            ) : (
                <Button
                    disabled={actionObject.disabled}
                    edit={false}
                    fill='outline'
                    onClick={handleButtonClick}
                    size='x-small'
                    text={actionObject.label || 'Error button label'}
                />
            )}
            {isLoading && (
                <Loader isOpen />
            )}
        </>
    )
})
