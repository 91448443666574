type contentTypeDataWebTypeOld = {
    adherent: number
    blocktemplate: number
    cartecategory: number
    carteitem: number
    custom_category: number
    custom_category_2: number
    custom_category_3: number
    custom_choice: number
    custom_feed_block: number
    custom_feed_section: number
    customtable: number
    event: number
    eventteam: number
    generalfeed: number
    help_center_topic_item: number
    hub: number
    mainteam: number
    material: number
    mixedmediacontent: number
    offer: number
    page: number
    pagecontent: number
    pagetemplate: number
    portfolio: number
    product: number
    productteam: number
    productvariant: number
    profile: number
    profile_setting_tab: number
    profileteam: number
    project: number
    projectteam: number
    salesorder: number
    service: number
    sportteam: number
    sportteammember: number
}

export type contentTypeModelTypeOld =
    'adherent' |
    'blocktemplate' |
    'cartecategory' |
    'carteitem' |
    'custom_category' |
    'custom_category_2' |
    'custom_category_3' |
    'custom_choice' |
    'custom_feed_block' |
    'custom_feed_section' |
    'customtable' |
    'event' |
    'eventteam' |
    'generalfeed' |
    'help_center_topic_item' |
    'hub' |
    'mainteam' |
    'material' |
    'mixedmediacontent' |
    'offer' |
    'page' |
    'pagecontent' |
    'pagetemplate' |
    'portfolio' |
    'product' |
    'productteam' |
    'productvariant' |
    'profile' |
    'profile_setting_tab' |
    'profileteam' |
    'project' |
    'projectteam' |
    'salesorder' |
    'service' |
    'sportteam' |
    'sportteammember'

export const contentTypeDataOld: contentTypeDataWebTypeOld = {
    adherent: 340,
    blocktemplate: 312,
    cartecategory: 191,
    carteitem: 192,
    custom_category: 328,
    custom_category_2: 355,
    custom_category_3: 356,
    custom_choice: 304,
    custom_feed_block: 217,
    custom_feed_section: 210,
    customtable: 341,
    event: 31,
    eventteam: 166,
    generalfeed: 180,
    help_center_topic_item: 300,
    hub: 0,
    mainteam: 319,
    material: 75,
    mixedmediacontent: 260,
    offer: 87,
    page: 90,
    pagecontent: 174,
    pagetemplate: 313,
    portfolio: 92,
    product: 119,
    productteam: 194,
    productvariant: 129,
    profile: 155,
    profile_setting_tab: 195,
    profileteam: 179,
    project: 110,
    projectteam: 107,
    salesorder: 163,
    service: 215,
    sportteam: 331,
    sportteammember: 332,
}

export const contentTypeFormUrl: any = {
    'artwork': 'project',
    'block-template': 'blocktemplate',
    'customer': 'customer',
    'equipment': 'material',
    'event': 'event',
    'member': 'profileteam',
    'opportunity': 'offer',
    'page-template': 'pagetemplate',
    'press': 'project',
    'product': 'product',
    'project': 'project',
    'resource': 'project',
    'service': 'service',
}

export const contentTypeFontUrl: any = {
    'customer': 'accounting/form/customer/font/',
    'event': 'event/form/event/font/',
    'material': 'material/form/material/font/',
    'offer': 'offer/form/offer/font/',
    'product': 'sales/form/product/font/',
    'project': 'project/form/project/font/',
    'service': 'service/form/service/font/',
}
