// react components
import React, {
    useState,
} from 'react'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
} from 'components'

// serializers
import {
    EventListSerializer,
} from 'serializers/web'

// props
type EventListSmallBlockMobileProps = {
    edit: boolean
    object: EventListSerializer
}

// main
export const EventListSmallBlockMobile: React.FC<EventListSmallBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const [deleted, setDeleted] = useState(false)

    return (
        <div
            className='event-list-small-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='event'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <div className='elsb-cover'>
                <LinkHelper
                    className='elsb-cover-wrap'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    <div className='elsb-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='elsb-image'
                            dominant_color={undefined}
                            lazyMobile={false}
                            src={object.get_image_square}
                        />
                    </div>
                </LinkHelper>
            </div>
        </div>
    )
})
