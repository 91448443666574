export function getCookie(name: string, format: 'boolean' | 'json' | 'number' | 'string', has_expiry?: boolean) {

    let matchLocalStorage
    try {
        matchLocalStorage = localStorage.getItem(name)
    } catch {
        return undefined
    }

    if (matchLocalStorage) {
        document.cookie = `${name}= ; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
        if (format === 'boolean') {
            return Boolean(matchLocalStorage === 'true')
        }
        if (format === 'json') {
            const value = matchLocalStorage
            if (value.substring(0, 1) === '{') {
                try {
                    const parsedValue = JSON.parse(value)
                    if (has_expiry) {
                        const now = new Date()
                        if (now.getTime() > parsedValue.expiry) {
                            localStorage.removeItem(name)
                            return null
                        }
                        return parsedValue.value
                    } else {
                        return JSON.parse(value)
                    }
                } catch (e) {
                    return undefined
                }
            }
            return undefined
        }
        if (format === 'string') {
            return matchLocalStorage
        }
        if (format === 'number') {
            return Number(matchLocalStorage)
        }
    }

    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
    if (match) {
        if (format === 'boolean') {
            return Boolean(match[2] === 'true')
        }
        if (format === 'json') {
            const value = match[2]
            if (value.substring(0, 1) === '{') {
                try {
                    return JSON.parse(value)
                } catch (e) {
                    return undefined
                }
            }
            return undefined
        }
        if (format === 'string') {
            return match[2]
        }
        if (format === 'number') {
            return Number(match[2])
        }
    }
    return undefined
}
