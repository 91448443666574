// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    CheckboxInput,
    IconBlock,
    Loader,
    SelectListInput,
    TextInput,
} from 'components'

// data
import {
    api_url_profile_section_form_detail,
    api_url_profile_section_form_update,
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    reduxModalMainFormHide,
    select_url_profile_section,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailObject,
} from 'services'

// main
export const ProfileSectionForm: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModal = useSelector((state: defaultReduxState) => state.reduxModal)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const objectId = reduxModal.mainForm.objectId
    const onRefresh = reduxModal.mainForm.onRefresh

    type errorsType = {
        is_parent?: string
        name_custom?: string
        parent?: string
    }
    type fieldsType = {
        id?: number
        content_source?: number
        content_type?: number
        has_children?: boolean
        has_content?: boolean
        is_main_parent?: boolean
        is_parent?: boolean
        name_custom?: string
        parent?: MainIdNameSerializer
        profile?: number
        translation_default_language?: MainIdNameSerializer
    }
    const errorsInitial = {
        is_parent: '',
        parent: '',
        name_custom: '',
    }
    const fieldsInitial = {
        is_parent: undefined,
        parent: undefined,
        name_custom: undefined,
    }

    useEffect(() => {
        if (objectId) {
            onGetDetailData()
        }
    }, [
        objectId,
        reduxAuth.apiRootUrl,
    ])

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_profile_section_form_detail}${objectId}/`, reduxAuth),
                component: 'ProfileSectionForm',
                dispatch,
                reduxAuth,
                setDetailObject: setFields,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSectionForm',
                'onGetDetailData',
            ))
        }
    }

    function onHandleInputChange(event: any) {
        try {
            const name = event.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })  // should be after setFields to avoid bug on TextArea
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSectionForm',
                'onHandleInputChange',
            ))
        }
    }

    async function onSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileSectionForm onSubmit')

            if (!fields.name_custom) {
                setErrors({ ...errors, name_custom: reduxText[2856] })
                return
            }

            const formData: any = new FormData()
            formData.append('parent', fields.parent?.id || '')
            formData.append('is_parent', fields.is_parent)
            formData.append('name_custom', fields.name_custom)
            const putUrl = getApiUrl(`${api_url_profile_section_form_update}${objectId}/`, reduxAuth)
            setIsLoading(true)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (onRefresh) onRefresh()
                    setIsLoading(false)
                    dispatch(reduxModalMainFormHide())
                    dispatch(reduxFormSetRefresh('refreshProfile', fields.profile))
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (error.response) {
                        setErrors(error.response && error.response.data)
                    }
                    if (!error.response?.data?.name_custom) {
                        axiosErrorHandler({
                            apiUrl: putUrl,
                            component: 'ProfileSectionForm',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onSubmit',
                        })
                    }
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSectionForm',
                'onSubmit',
            ))
        }
    }

    return (
        <React.Fragment>
            <DialogTitle className='mo-dialog-header'>
                {reduxText[8537]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => dispatch(reduxModalMainFormHide())}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                {fields.id && (
                    <React.Fragment>
                        <TextInput
                            error={errors?.name_custom}
                            label={reduxText[7720]}
                            name='name_custom'
                            onChange={onHandleInputChange}
                            translation={{
                                contentType: 'profile_setting_tab',
                                defaultDetailUrl: getApiUrl(api_url_profile_section_form_detail, reduxAuth),
                                defaultLanguage: fields.translation_default_language,
                                defaultUpdateUrl: getApiUrl(api_url_profile_section_form_update, reduxAuth),
                                objectId: objectId,
                                profileId: fields.profile,
                            }}
                            value={fields.name_custom || ''}
                        />
                        <SelectListInput
                            apiUrl={fields.profile ? `${select_url_profile_section}?profile_id=${fields.profile}&content_type_id=${fields.content_type}&content_source=${fields.content_source}&is_parent=1` : undefined}
                            clearable
                            disabled={fields.is_parent || fields.is_main_parent || fields.has_children}
                            error={errors?.parent}
                            inModal
                            label='parent' // TO_TEXT
                            name='parent'
                            onChange={onHandleInputChange}
                            optional
                            value={fields.parent || ''}
                        />
                        <CheckboxInput
                            disabled={fields.has_children || fields.is_main_parent || fields.has_content || Boolean(fields.parent)}
                            error={errors?.is_parent}
                            label='is_parent'  // TO_TEXT
                            name='is_parent'
                            onChange={onHandleInputChange}
                            value={fields.is_parent || ''}
                        />
                    </React.Fragment>
                )}
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => dispatch(reduxModalMainFormHide())}
                    text={reduxText[4519]}
                />
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    fill='outline'
                    onClick={onSubmit}
                    text={reduxText[4520]}
                />
            </DialogActions>
            {isLoading && (
                <Loader isOpen />
            )}
        </React.Fragment>
    )
}
