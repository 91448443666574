// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    OfferActionSheetMobile,
} from 'pages'

// serializers
import {
    OfferListSerializer,
} from 'serializers/web'

// services
import {
    getDateWithType,
} from 'services'

// props
type OfferListMediumBlockMobileProps = {
    edit: boolean
    object: OfferListSerializer
}

// main
export const OfferListMediumBlockMobile: React.FC<OfferListMediumBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [isActionSheetOpen, setActionSheetIsOpen] = useState(false)

    return (
        <div
            className='offer-list-medium-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='offer'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <div className='olmbm-cover'>
                <LinkHelper
                    className='olmbm-cover-wrap'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    <div className='olmbm-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='olmbm-image'
                            dominant_color={undefined}
                            lazyMobile={false}
                            src={object.get_image_lq}
                        />
                    </div>
                </LinkHelper>
            </div>
            <div className='olmbm-info'>
                <LinkHelper
                    className='mo-list-block-name-mobile medium'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    {object.name}
                </LinkHelper>
                <div className='olmbm-date'>
                    {object.always_open
                        ? (
                            <span className='olmbm-always_open'>{reduxText[3489]}</span>
                        ) : (
                            <React.Fragment>
                                <span className='olmbm-text'>{reduxText[5321]}</span>
                                <span className='olmbm-day'>{getDateWithType(reduxAuth, object.valid_date, 'd')}</span>
                            </React.Fragment>
                        )}
                </div>
                {((object.countries && object.countries.length > 0) || (object.cities && object.cities.length > 0)) && (
                    <div className='olmbm-countries'>
                        <MainChipListBlock>
                            {object.countries?.map((val) => (
                                <MainCountryFlag
                                    key={val.id}
                                    edit={edit}
                                    object={val}
                                />
                            ))}
                            {object.cities?.map((val) => (
                                <MainHashtag
                                    key={val.id}
                                    edit={edit}
                                    label={val.name}
                                />
                            ))}
                        </MainChipListBlock>
                    </div>
                )}
                <div className='list-block-footer'>
                    {object.profile && (
                        <ListProfileLink
                            blockSize='medium'
                            edit={edit}
                            profile={object.profile}
                        />
                    )}
                </div>
            </div>
            <div className='olmbm-actions'>
                <IconBlock
                    edit={edit}
                    iconClass='mo-new-icon-ellipsis-h-solid'
                    onClick={() => setActionSheetIsOpen(true)}
                />
            </div>
            {isActionSheetOpen && (
                <OfferActionSheetMobile
                    object={object}
                    setActivated={setActivated}
                    setIsOpen={setActionSheetIsOpen}
                />
            )}
        </div>
    )
})
