// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// props
type SearchInputProps = {
    classNameInput?: string
    classNameWrap?: string
    disabled?: boolean
    onChange: (e: string) => void
    placeholder?: string
    // value: string | number
}

// main
export const SearchInput: React.FC<SearchInputProps> = React.forwardRef((
    {
        classNameInput,
        classNameWrap,
        disabled,
        onChange,
        placeholder,
        // value,
    },
    ref: React.Ref<HTMLInputElement>
) => {

    const dispatch = useDispatch()
    const [showEmpty, setShowEmpty] = useState(false)
    const [value, setValue] = useState('')

    function handleInputChange(e: string) {
        try {
            onChange(e)
            setValue(e)
            if (e) {
                setShowEmpty(true)
            } else {
                setShowEmpty(false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SearchInput',
                'handleInputChange',
            ))
        }
    }

    return (
        <div className={`co-search-input${classNameWrap ? ` ${classNameWrap}` : ''}${disabled ? ' disabled' : ''}`}>
            <i className='mo-new-icon-search-solid' />
            <input
                ref={ref}
                className={`co-search-input-input${classNameInput ? ` ${classNameInput}` : ''}`}
                disabled={disabled}
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder={placeholder}
                type='text'
                value={value}
            />
            {showEmpty && (
                <i
                    className='mo-new-icon-circle-xmark-regular mo-cursor'
                    onClick={() => handleInputChange('')}
                />
            )}
        </div>
    )
})
