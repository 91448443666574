// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// containers
import {
    DetailListContainerMobile,
} from 'containers'

// data
import {
    api_url_offer_detail_short,
    api_url_offer_image_list,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// props
type MatchParams = {
    id: string
}

// main
export const OfferDetailListMobile: React.FC = () => {

    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const filterParamsOptions = {
        'image': {
            apiUrl: getApiUrl(`${api_url_offer_image_list}?offer_id=${params.id}`, reduxAuth),
            bigListComponent: 'ImageListBlockMobile',
            defaultThumbSize: 'big',
            mediumListComponent: 'ImageListBlockMobile',
            smallListComponent: 'ImageListBlockMobile',
            title: reduxText[1625],
        },
    }

    return (
        <DetailListContainerMobile
            filterParamsOptions={filterParamsOptions}
            model='offer'
            shortUrl={api_url_offer_detail_short}
        />
    )
}
