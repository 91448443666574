// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    view_url_portfolio_form,
} from 'data'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// props
type AdminMenuWebsiteItemProps = {
    profileObject: ProfileDetailAdminSerializerV2
}

// main
export const AdminMenuWebsiteItem: React.FC<AdminMenuWebsiteItemProps> = React.memo(({
    profileObject,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isChildOpen, setIsChildOpen] = useState<boolean>()

    if (profileObject.portfolios?.length! === 0) return null

    return (
        <div className='admin-menu-item'>
            <div className='ami-tab-item-wrap'>
                <span
                    className='ami-tab-item'
                    onClick={() => setIsChildOpen(!isChildOpen)}
                >
                    {reduxText[8776]}
                </span>
                <i
                    className={`ami-expand mo-new-icon-chevron-down-solid${isChildOpen ? ' open' : ''}${profileObject.portfolios?.length! > 0 ? '' : ' hidden'}`}
                    onClick={() => setIsChildOpen(!isChildOpen)}
                />
            </div>
            {profileObject.portfolios?.length! > 0 && (
                <div className={`ami-children-wrap${isChildOpen ? ' open' : ''}`}>
                    {profileObject.portfolios?.map((val) => (
                        <div
                            key={val.id}
                            className='admin-menu-item-view'
                        >
                            <Link
                                className='amiv-tab-item-children'
                                to={`${view_url_portfolio_form}${val.id}/`}
                            >
                                {val.hostname}
                            </Link>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
})
