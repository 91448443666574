// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
} from 'serializers/web'

// props
type DateTypeChoiceProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    onChange: any
}

// main
export const DateTypeChoiceProjectInput: React.FC<DateTypeChoiceProjectInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const dateTypeOptions = [
        {
            id: 'd',
            name: reduxText[4832],
        },
        {
            id: 'm',
            name: reduxText[4831],
        },
        {
            id: 'y',
            name: reduxText[4830],
        },
    ]

    return (
        <SelectListInput
            clearable={false}
            error={errors?.date_type_choice}
            name='date_type_choice'
            onChange={onChange}
            options={dateTypeOptions}
            value={fields.date_type_choice}
        />
    )
})
