// react components
import React, {
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useLocation,
} from 'react-router-dom'

// components
import {
    BackgroundImageHelper,
    LinkHelper,
} from 'components'

// customers
import {
    AGSLLoginBlock,
    AGSLPasswordResetBlock,
} from 'customers'

// data
import {
    defaultReduxState,
    view_url_home,
} from 'data'

// services
import {
    parseQuery,
} from 'services'

// main
export const AGSLAuthLoginPage: React.FC = () => {

    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [email, setEmail] = useState(parseQuery(location.search)?.email || '')
    const [panel, setPanel] = useState<'login' | 'password-reset' | 'signup'>('login')

    if (reduxAuth?.authenticated) {
        if (reduxAuth.afterAuthUrl) {
            return <Redirect to={reduxAuth.afterAuthUrl} />
        }
        return <Redirect to={view_url_home} />
    }

    return (
        <IonPage className='login-page-web navbar-pt'>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='lpw-content'>
                    <div className='lpw-left auth-global-block-web'>
                        {['login', 'signup'].includes(panel) && (
                            <AGSLLoginBlock
                                email={email}
                                setEmail={setEmail}
                                setPanel={setPanel}
                            />
                        )}
                        {panel === 'password-reset' && (
                            <AGSLPasswordResetBlock
                                email={email}
                                setPanel={setPanel}
                            />
                        )}
                        {panel === 'signup' && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: '20px',
                                }}
                            >
                                <p>Vous n'avez encore de compte.</p>
                                <LinkHelper
                                    edit={false}
                                    to={`/auth/inscription/federateur-trice-de-quartier/?email=${email}`}
                                >
                                    S'inscrire en tant que Fédérateur·trice de quartier
                                </LinkHelper>
                                <LinkHelper
                                    edit={false}
                                    to={`/auth/inscription/association/?email=${email}`}
                                >
                                    S'inscrire en tant qu'Association / Particulier / Autre
                                </LinkHelper>
                            </div>
                        )}
                    </div>
                    <BackgroundImageHelper
                        className='lpw-right'
                        dominant_color={undefined}
                        src='https://klhkpm8h.tinifycdn.com/resizes/2024/06/16/17/19/43/f980e089-3b99-4ca6-adce-590447352bb3.jpg'
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}
