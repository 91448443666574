// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_admin,
} from 'data'

// services
import {
    getApiUrlV2,
    getDetailObject,
    parseQuery,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type AgendaDuQuartierPaymentSuccessStripeProps = {
    stripe_invoice_id?: string
    // stripe_invoice_number: string
}

// main
export const AgendaDuQuartierPaymentSuccessStripe: React.FC<AgendaDuQuartierPaymentSuccessStripeProps> = React.memo(({
    stripe_invoice_id,
    // stripe_invoice_number,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [session, setSession] = useState<any>()

    useEffect(() => {
        onGetDetailData()
    }, [])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/stripe/session/${parseQuery(location.search)?.checkout_session_id}`, reduxAuth),
                component: 'AgendaDuQuartierPaymentSuccessStripe',
                customFunction: () => reduxAuthFetchSettingsHelper(reduxAuth, dispatch),
                dispatch,
                reduxAuth,
                setDetailObject: setSession,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierPaymentSuccessStripe',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div className='agenda-du-quartier-payment-success'>
            <div className='adgps-content'>
                <p className='adgps-title'>Complétez votre profil</p>
                <div dangerouslySetInnerHTML={{ __html: reduxText[10052] }} />
                <p><strong>compléter à 100% votre profil</strong> de {reduxAuth.settings?.federateur?.civility?.id === 1 ? 'Fédératrice' : 'Fédérateur'} de quartier !</p>
                <div className='adgps-button-wrap'>
                    <Button
                        edit={false}
                        text='Compléter mon profil'
                        to={`${view_url_admin}quartier?step=1`}
                    />
                </div>
                <p>Un courriel de confirmation pour votre commande n° {session?.invoice_number} a été envoyé à l’adresse {reduxAuth.settings?.user?.email}.</p>
                <p>Vérifiez vos courriers indésirables (spam) si vous ne le recevez pas.</p>
                <p>Vous pouvez également télécharger ici votre facture, ou la retrouver dans la rubrique rubrique “Mes commandes”.</p>
                <div className='adgps-button-wrap'>
                    <Button
                        edit={false}
                        href={session?.invoice_pdf_url}
                        text='Télécharger ma facture'
                    />
                </div>
                <hr className='adgps-hr' />
                <p>A bientôt, pour animer ensemble nos territoires,</p>
                <p>L’équipe de l’Agenda du quartier</p>
            </div>
            {isLoading && <Loader isOpen />}
        </div>
    )
})
