// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    NotFoundComponent,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    FormFieldSet,
} from 'pages'

// serializers
import {
    ContentTypeFormTypes,
    ContentTypeSettingsCustomTypes,
    ContentTypeSettingsTypes,
} from 'serializers/web'

// props
type FormContentBlockProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes
    formState: ContentTypeFormTypes
    getMetaIsLoading?: boolean
    handleInputChange: (event: any, newFormState: ContentTypeFormTypes) => void
    object: any
    onKeyPress: any
    tab: number
    tabData: any
    verticalStyle?: boolean
}

// main
export const FormContentBlock: React.FC<FormContentBlockProps> = React.memo(({
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    formState,
    getMetaIsLoading,
    handleInputChange,
    object,
    onKeyPress,
    tab,
    tabData,
    verticalStyle,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    if (tabData?.panelOptions?.hideIfNotStaff && !reduxAuth.settings?.user?.is_staff) {
        return <NotFoundComponent statusCode={403} />
    }

    if (tabData?.panelOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) {
        return <NotFoundComponent statusCode={403} />
    }

    return (
        <div className={`fc-content ${tab}`}>
            {tabData?.panelOptions?.hideIfNotStaff && reduxAuth.settings?.user?.is_staff && (
                <p className='fc-panel-description-staff'>Showing this panel only for staff</p>
            )}
            {tabData?.panelOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id === 1 && (
                <p className='fc-panel-description-staff'>Superuser</p>
            )}
            {tabData?.blockList?.map((val: any) => {
                if (tabData.blockFieldSet?.[val]) {
                    if (tabData.blockFieldSet[val].hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) return null
                    return (
                        <FormFieldSet
                            key={val}
                            blockId={val}
                            contentType={contentType}
                            contentTypeSettings={contentTypeSettings}
                            contentTypeSettingsCustom={contentTypeSettingsCustom}
                            errors={formState.data.errors}
                            fieldSetObject={tabData.blockFieldSet[val]}
                            getMetaIsLoading={getMetaIsLoading}
                            object={object}
                            onChange={handleInputChange}
                            onKeyPress={onKeyPress}
                            verticalStyle={verticalStyle}
                        />
                    )
                }
                if (reduxAuth.settings?.user?.id === 1) {
                    return <p key={val}>Error: Missing block (Superuser only)</p>
                }
            })}
        </div>
    )
})
