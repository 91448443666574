// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectAsyncInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_adherent,
} from 'data'

// serializers
import {
    EventParticipantDetailFormSerializer,
    EventParticipantDetailFormSerializerError,
} from 'serializers/web'

// props
type AdherentEventParticipantInputProps = {
    disabled?: boolean
    errors: EventParticipantDetailFormSerializerError | undefined
    fields: EventParticipantDetailFormSerializer
    onChange: any
}

// main
export const AdherentEventParticipantInput: React.FC<AdherentEventParticipantInputProps> = React.memo(({
    disabled,
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!fields.event?.profile) return null

    return (
        <SelectAsyncInput
            apiUrl={`${select_url_adherent}?profile_id=${fields.event.profile}&is_parent=false`}
            clearable
            disabled={disabled}
            error={errors?.adherent}
            label={reduxText[8552]}
            layout='adherent'
            name='adherent'
            onChange={onChange}
            optional
            value={fields.adherent || ''}
        />
    )
})
