// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
    IonTitle,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    HeaderChildren,
    InfiniteScrollHelperWeb,
} from 'components'

// data
import {
    api_url_custom_feed_block_detail_short,
    api_url_custom_feed_block_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    EventListSmallBlockWeb as eventListSmallBlockWeb,
    ImageListBlockWeb as imageListSmallBlockWeb,
    MaterialListSmallBlockWeb as materialListSmallBlockWeb,
    OfferListSmallBlockWeb as offerListSmallBlockWeb,
    PressListSmallBlockWeb as pressListSmallBlockWeb,
    ProductListSmallBlockWeb as productListSmallBlockWeb,
    ProfileListSmallBlockWeb as profileListSmallBlockWeb,
    ProjectListSmallBlockWeb as artworkListSmallBlockWeb,
    ServiceListSmallBlock as serviceListSmallBlockWeb,
} from 'pages'

// serializers
import {
    CustomFeedBlockDetailSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getInfiniteListWebNew,
    getInfiniteRandomListWebNew,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// props
type MatchParams = {
    id: string
    slug: string
}

// main
export const FeedBlockDetailWeb: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        onGetDetailData()
    }, [
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
    ])

    useEffect(() => {
        onGetListData(getApiUrl(`${api_url_custom_feed_block_detail}?block_id=${params.id}`, reduxAuth), true)
    }, [
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
    ])

    const [detailObject, setDetailObject] = useState<CustomFeedBlockDetailSerializer | undefined>(undefined)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb>({
        ...listStateInitialWeb,
        componentString: `${detailObject?.component}ListSmallBlockWeb`,
        modalKey: Math.random(),
    })

    const random = true

    const components: any = {
        artworkListSmallBlockWeb,
        eventListSmallBlockWeb,
        imageListSmallBlockWeb,
        materialListSmallBlockWeb,
        offerListSmallBlockWeb,
        pressListSmallBlockWeb,
        productListSmallBlockWeb,
        profileListSmallBlockWeb,
        serviceListSmallBlockWeb,
    }
    const ComponentName = components[`${detailObject?.component}ListSmallBlockWeb`]

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_custom_feed_block_detail_short}${params.id}/`, reduxAuth),
                component: 'FeedBlockDetailWeb',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedBlockDetailWeb',
                'onGetDetailData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            if (random) {
                getInfiniteRandomListWebNew({
                    apiUrl,
                    axiosCancelToken,
                    changingView,
                    component: 'FeedBlockDetailWeb',
                    componentName: `${detailObject?.component}ListSmallBlockWeb`,
                    dispatch,
                    listState,
                    reduxAuth,
                    setAxiosCancelToken,
                    setListState,
                })
            } else {
                getInfiniteListWebNew({
                    apiUrl,
                    axiosCancelToken,
                    changingView,
                    component: 'FeedBlockDetailWeb',
                    componentName: `${detailObject?.component}ListSmallBlockWeb`,
                    dispatch,
                    listState,
                    reduxAuth,
                    setAxiosCancelToken,
                    setListState,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedBlockDetailWeb',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                if (random) {
                    onGetListData(
                        getApiUrl(`${api_url_custom_feed_block_detail}?block_id=${params.id}`, reduxAuth),
                        false,
                    )
                } else {
                    onGetListData(listState.nextUrl, false)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedBlockDetailWeb',
                'onSearchNext',
            ))
        }
    }

    return (
        <IonPage className='feed-block-detail-web'>
            <HeaderChildren>
                <IonTitle>{detailObject?.name}</IonTitle>
            </HeaderChildren>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='fbdw-content'>
                    <div className={`fbdw-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {/* <FeedMenuBlockWeb sectionId={Number(params.id)} /> */}
                    </div>
                    <div className='fbdw-right'>
                        <div className={`fbdw-right-wrap mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                            {ComponentName && listState.items.map((item) => {
                                return (
                                    <ComponentName
                                        key={item.id}
                                        edit={false}
                                        modalKey={listState.modalKey}
                                        object={item}
                                        playerOrigin='feedBlockDetail'
                                    />
                                )
                            })}
                            <InfiniteScrollHelperWeb
                                active={!listState.disableInfiniteScroll}
                                hasMore={listState.hasMore}
                                isLoading={listState.isLoading}
                                items={listState.items}
                                onChange={onSearchNext}
                            />
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
