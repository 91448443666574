// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonSearchbar,
} from '@ionic/react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    IconBlock,
    InfiniteScrollHelperWeb,
    Loader,
    NotFoundComponent,
} from 'components'

// customers
import {
    AGSLAdminMenuProps,
} from 'customers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    TableAction,
    TableHeader,
    TableRow,
} from 'pages'

// serializers
import {
    ContentTypeFieldTypes,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getInfiniteListItem,
    listStateInitial,
    listStateProps,
    parseQuery,
} from 'services'

// props
type MatchParams = {
    pageName: string
    pageSlug: string
}

type AGSLAdminModuleProps = {
    refreshData: () => void
    userObject: AGSLAdminMenuProps['userObject']
}

// main
export const AGSLAdminModule: React.FC<AGSLAdminModuleProps> = React.memo(({
    refreshData,
    userObject,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [customColumns, setCustomColumns] = useState<{
        [key: string]: string[]
    } | undefined>(undefined)
    const [exportColumns, setExportColumns] = useState<{
        columnName: string
        columnTitle: string
        fieldOptions: ContentTypeFieldTypes | undefined
    }[]>([])
    const [exportListApiUrl, setExportListApiUrl] = useState<string>('')
    const [filterOrderingCombined, setFilterOrderingCombined] = useState<boolean>(false)
    const [filterValues, setFilterValues] = useState<any>({})
    const [isOrdering, setIsOrdering] = useState<boolean>(false)
    const [listState, setListState] = useState<listStateProps>({
        ...listStateInitial,
        clusterIds: [],
        modalKey: Math.random(),
    })
    const [checkboxArray, setCheckboxArray] = useState<number[]>([])

    // keep order
    const slugArray = userObject.menu.flatMap(menuItem => menuItem.children.map(child => child.slug))
    // const tabArray = slugArray.find((val) => val.id === viewId)?.tabs || []
    const currentView = userObject.menu.flatMap(menuItem => menuItem.children).find(child => child.slug === params.pageSlug)
    const contentType = currentView?.content_type!
    const app = contentType?.split('_')[0]
    const model = contentType?.split('_')[1]
    const contentTypeSettings = listState.contentTypeSettings
    const contentTypeSettingsCustom = userObject.profile.settings_data_json?.contentTypeSettingsCustom?.[contentType]
    const initialFilterQuery = parseQuery(window.location.search)?.filter_query?.replace(/\|/g, '=').replace(/,/g, '&')

    useEffect(() => {
        mainGet(true)
    }, [
        contentType,
        customColumns,
        userObject.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
        params.pageSlug,
    ])

    function mainGet(clearFilter?: boolean) {
        if (!currentView) return
        setListState({
            ...listState,
            clusterIds: [],
            isLoading: true,
        })
        onGetListData(
            getListApiUrl(clearFilter)!.listApiUrl,
            true,
        )
    }

    useEffect(() => {
        if (searchBarRef.current) {
            searchBarRef.current.value = ''
        }
    }, [
        params.pageSlug,
    ])

    const columns = userObject.profile.settings_data_json?.contentTypeSettingsCustom?.[listState.contentType || contentType]?.list?.fieldsSubContentType?.[currentView?.sub_content_type!] || []

    function getListApiUrl(clearFilter?: boolean) {
        try {
            const columnsNormal3: any[] | undefined = userObject.profile.settings_data_json?.contentTypeSettingsCustom?.[listState.contentType || contentType]?.list?.fieldsSubContentType?.[currentView?.sub_content_type!] || []

            let newListApiUrl = getApiUrlV2(`${app}/list/${model}/`, reduxAuth)
            newListApiUrl += `?__customer_profile_id=191736&${currentView?.list_params || ''}`
            // if (currentView?.isCustomOrdering) {
            //     newListApiUrl += `&order_by_view=${viewId}`
            // }
            // if (currentView?.isSelfFiltered) {
            //     if (!newListApiUrl.includes('?')) newListApiUrl += '?'
            //     newListApiUrl += `&module_view=${viewId}`
            // }
            // if (currentView?.isSelfFilteredMany) {
            //     if (!newListApiUrl.includes('?')) newListApiUrl += '?'
            //     newListApiUrl += `&module_views=${viewId}`
            // }
            // if (currentView?.filters?.length! > 0) {
            //     if (!newListApiUrl.includes('?')) newListApiUrl += '?'
            //     newListApiUrl += `&${currentView?.filters?.map((val) => `${val.name}=${val.value}`).join('&')}`
            // }
            // if (tabArray.length > 0) {
            //     if (!newListApiUrl.includes('?')) newListApiUrl += '?'
            //     tabArray.forEach((val) => {
            //         if (val.filters) {
            //             if (tabId && val.id === tabId) {
            //                 newListApiUrl += `&${val.filters.map((val) => `${val.name}=${val.value}`).join('&')}`
            //             } else if (!tabId && val.id === tabArray[0].id) {
            //                 newListApiUrl += `&${val.filters.map((val) => `${val.name}=${val.value}`).join('&')}`
            //             }
            //         }
            //     })
            // }
            if (!clearFilter && filterValues[contentType]) {
                Object.keys(filterValues[contentType]).forEach((key) => {
                    if (filterValues[contentType][key] && filterValues[contentType][key].length > 0) {
                        if (!newListApiUrl.includes('?')) newListApiUrl += '?'
                        if (key === 'order_by') {
                            newListApiUrl += `&${key}=${filterValues[contentType][key].map((val: any) => `${val.direction === '-' ? '-' : ''}${val.name}`).join(',')}`
                        } else {
                            newListApiUrl += `&${key}=${filterValues[contentType][key]}`
                        }
                    }
                })
            }
            if (columnsNormal3?.length! > 0) {
                newListApiUrl += `&fields=${columnsNormal3?.join(',')}`
            }
            if (initialFilterQuery) {
                newListApiUrl += `&${initialFilterQuery}`
            }
            if (clearFilter) {
                setFilterValues({})
            }
            return {
                listApiUrl: newListApiUrl,
            }

        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AGSLAdminModule',
                'getListApiUrl',
            ))
        }
    }

    function onSetFilter(e: any, f?: any) {
        try {
            setIsOrdering(false)
            if (!filterValues[contentType]) {
                filterValues[contentType] = {}
            }
            if (e.params === 'order_by') {
                const order_by = filterOrderingCombined ? filterValues[contentType].order_by || [] : []
                const existingIndex = order_by.findIndex((item: any) => item.name === e.name)
                if (e.direction) {
                    if (existingIndex !== -1) {
                        order_by[existingIndex].direction = e.direction
                    } else {
                        order_by.push({
                            direction: e.direction,
                            name: e.name,
                        })
                    }
                    filterValues[contentType].order_by = order_by
                } else {
                    filterValues[contentType].order_by = order_by.filter((item: any) => item.name !== e.name)
                }
            } else {
                if (e.value) {
                    filterValues[contentType][e.params] = e.value
                } else {
                    delete filterValues[contentType][e.params]
                }
            }
            if (f) {
                delete filterValues[contentType][f.params]
                if (f.params === 'search' && searchBarRef?.current) {
                    searchBarRef.current.value = ''
                }
            }
            mainGet()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AGSLAdminModule',
                'onFilter',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            if (changingView) {
                setExportListApiUrl(apiUrl)
            }
            getInfiniteListItem({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'AGSLAdminModule',
                dispatch,
                listState,
                noClusterIds: true,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AGSLAdminModule',
                'getListData',
            ))
        }
    }

    function onSearch(searchValue: any) {
        try {
            if (initialFilterQuery) {
                history.push(window.location.pathname)
            }
            onSetFilter({
                params: 'search',
                value: searchValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AGSLAdminModule',
                'onSearch',
            ))
        }
    }

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                onSearch(event.target.value)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AGSLAdminModule',
                'onSearchKeyPress',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AGSLAdminModule',
                'onSearchNext',
            ))
        }
    }

    function checkboxAddRemove(id: number, remove?: boolean) {
        try {
            if (remove) {
                setCheckboxArray(checkboxArray.filter((val) => val !== id))
            } else {
                setCheckboxArray([...checkboxArray, id])
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AGSLAdminModule',
                'onSearchNext',
            ))
        }
    }

    async function checkboxSelectAll(remove?: boolean) {
        try {
            if (remove) {
                setCheckboxArray([])
            } else {
                setCheckboxArray(listState.items.map((val) => val.id))
                const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AGSLAdminModule checkboxSelectAll')
                // setProcessMessage(reduxText[4525])
                // setIsProcessing(true)
                const postUrl = getApiUrlV2(`main/helper/values/`, reduxAuth)
                const formData: any = new FormData()
                formData.append('api_list_url', exportListApiUrl)
                formData.append('columns', ['id'])
                formData.append('ids', 'all')
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setCheckboxArray(response.data)
                        // setIsProcessing(false)
                    })
                    .catch((error) => {
                        // setIsProcessing(false)
                        axiosErrorHandler({
                            apiUrl: postUrl,
                            component: 'AGSLAdminModule',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'checkboxSelectAll',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AGSLAdminModule',
                'checkboxSelectAll',
            ))
        }
    }

    if (!currentView) {
        return (
            <NotFoundComponent />
        )
    }

    if (!contentTypeSettings) {
        return <Loader isOpen />
    }

    // const isCustomOrderingPossible = (!currentView?.tabs || currentView?.tabs.length === 0) && !searchBarRef.current?.value

    console.log('rendering admin module');


    return (
        <div className='admin-module'>
            <div className='am-header'>
                <div className='am-header-title'>
                    <p>{currentView?.title}</p>
                </div>
                <div className='am-search-wrap'>
                    <div className='am-action-wrap'>
                        <TableAction
                            canSendEmail
                            checkboxArray={checkboxArray}
                            columns={columns}
                            contentType={contentType}
                            contentTypeSettings={listState.contentTypeSettings!}
                            emailContentObjectId={currentView.id}
                            emailContentType={currentView?.content_type}
                            exportColumns={exportColumns}
                            exportListApiUrl={exportListApiUrl}
                            // isCustomOrdering={currentView?.isCustomOrdering}
                            // isCustomOrderingPossible={isCustomOrderingPossible}
                            isOrdering={isOrdering}
                            profile={userObject.profile}
                            refreshData={refreshData}
                            setCustomColumns={(e) => {
                                if (e?.length > 0) {
                                    const newCustomColumns: string[] = []
                                    e.map((val) => {
                                        newCustomColumns.push(val.columnName)
                                    })
                                    setCustomColumns({
                                        ...customColumns || {},
                                        [currentView!.sub_content_type!]: newCustomColumns,
                                    })
                                    setExportColumns(e)
                                } else {
                                    setCustomColumns({
                                        ...customColumns || {},
                                        [currentView!.sub_content_type!]: [],
                                    })
                                }
                            }}
                            setIsOrdering={setIsOrdering}
                            subContentTypeId={currentView?.sub_content_type}
                        />
                    </div>
                    <IonSearchbar
                        className='am-searchbar'
                        onIonClear={() => onSearch('')}
                        onKeyPress={(e) => onSearchKeyPress(e)}
                        placeholder={reduxText[8335]}
                        ref={searchBarRef}
                    />
                    <IconBlock
                        className='am-icon'
                        edit={false}
                        iconClass='mo-new-icon-search-solid'
                        onClick={() => { if (searchBarRef.current) onSearch(searchBarRef.current.value) }}
                    />
                </div>
            </div>
            <div className={`am-infinite-table mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <table>
                    <thead>
                        <TableHeader
                            addProfileColumn={false}
                            checkboxSelectAll={checkboxSelectAll}
                            checkboxShow
                            columns={columns}
                            contentType={contentType}
                            contentTypeSettings={listState.contentTypeSettings!}
                            count={listState.count}
                            filterValues={filterValues}
                            profile={userObject.profile}
                            setExportColumns={setExportColumns}
                            setFilter={onSetFilter}
                        />
                    </thead>
                    <tbody>
                        {listState.items.map((item, index) => {
                            return (
                                <TableRow
                                    key={item.id}
                                    checkboxAddRemove={checkboxAddRemove}
                                    checkboxArray={checkboxArray}
                                    checkboxShow
                                    // @ts-ignore
                                    columns={columns}
                                    componentActionSheet=''
                                    contentType={contentType}
                                    contentTypeSettings={listState.contentTypeSettings!}
                                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                                    defaultFields={{}}
                                    modalKey={listState.modalKey}
                                    object={item}
                                    profileObject={userObject.profile}
                                    setFilter={onSetFilter}
                                />
                            )
                        })}
                    </tbody>
                </table>
                <InfiniteScrollHelperWeb
                    active={!listState.disableInfiniteScroll}
                    hasMore={listState.hasMore}
                    isLoading={listState.isLoading}
                    items={listState.items}
                    onChange={onSearchNext}
                />
            </div>
        </div>
    )
})
