// react components
import React from 'react'
import axios from 'axios'
import moment from 'moment'

// data
import {
    reduxFormHelpersState,
    reduxTextState,
} from 'data'

// main
export function getMediaVimeoV2({
    formState,
    helpers,
    image_only,
    prefixImage,
    prefixLink = '',
    reduxText,
    setFormState,
    setGetMetaIsLoading,
    setHelpers,
    urltoParse,
}: {
    formState: any
    helpers: reduxFormHelpersState
    image_only: boolean
    prefixImage: string
    prefixLink?: string
    reduxText: reduxTextState['data']
    setFormState: React.Dispatch<any>
    setGetMetaIsLoading: React.Dispatch<boolean>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    urltoParse: string
}) {

    setGetMetaIsLoading(true)

    let urltoParse1 = urltoParse
    const urltoParse2 = urltoParse.includes('http')
    if (!urltoParse2) {
        urltoParse1 = `http://${urltoParse1}`
    }
    const vimeoId = urltoParse1.split('/')[urltoParse1.split('/').length - 1]
    if (!vimeoId) return
    const axiosHeaders = {
        Authorization: 'Bearer 43c3b1a8e421b60a494967121267c387',
        Accept: 'application/vnd.vimeo.*+json;version=3.4',
    }
    axios({
        headers: axiosHeaders,
        method: 'get',
        url: `https://api.vimeo.com/videos/${vimeoId}`,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            let coverImageIndex = response.data.pictures.sizes.findIndex((object: any) => object.width === 1920)
            if (!coverImageIndex) {
                coverImageIndex = response.data.pictures.sizes[response.data.pictures.sizes.length - 1]
            }
            const coverImage = response.data.pictures.sizes[coverImageIndex].link
            if (image_only) {
                const toReturn = {
                    ...formState,
                    data: {
                        ...formState.data,
                        errors: {
                            ...formState.data.errors,
                            [`${prefixImage}image`]: '',
                            [`${prefixImage}image_doka_output_data`]: '',
                            [`${prefixImage}image_original`]: '',
                            [`${prefixImage}image_square`]: '',
                            [`${prefixImage}image_square_doka_output_data`]: '',
                            [`${prefixLink}link_url`]: '',
                            meta_image_url: '',
                        },
                        fields: {
                            ...formState.data.fields,
                            [`${prefixImage}image`]: coverImage,
                            [`${prefixLink}link_url`]: urltoParse,
                            get_image_hq: coverImage,
                            get_image_lq: coverImage,
                            get_image_mq: coverImage,
                            meta_image_url: coverImage,
                        },
                    }
                }
                setFormState(toReturn)
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            } else {
                const toReturn = {
                    ...formState,
                    data: {
                        ...formState.data,
                        errors: {
                            ...formState.data.errors,
                            [`${prefixImage}image_doka_output_data`]: '',
                            [`${prefixImage}image_original`]: '',
                            [`${prefixImage}image_square_doka_output_data`]: '',
                            [`${prefixImage}image_square`]: '',
                            [`${prefixImage}image`]: '',
                            [`${prefixLink}duration`]: '',
                            [`${prefixLink}link_id`]: '',
                            [`${prefixLink}link_url`]: '',
                            date: '',
                            date_type_choice: '',
                            description: '',
                            meta_image_url: '',
                            name: '',
                        },
                        fields: {
                            ...formState.data.fields,
                            [`${prefixImage}image_doka_output_data`]: 'to_delete_original',
                            [`${prefixImage}image_original`]: '',
                            [`${prefixImage}image_square_doka_output_data`]: 'to_delete_all',
                            [`${prefixImage}image_square`]: coverImage,
                            [`${prefixImage}image`]: coverImage,
                            [`${prefixLink}duration`]: moment.utc(response.data.duration * 1000).format('HH:mm:ss'),
                            [`${prefixLink}link_id`]: response.data.link,
                            [`${prefixLink}link_url`]: urltoParse,
                            date: moment(response.data.release_time).format('YYYY-MM-DD'),
                            date_type_choice: {
                                id: 'd',
                                name: reduxText[4832],
                            },
                            description: response.data.description,
                            get_image_hq: coverImage,
                            get_image_lq: coverImage,
                            get_image_mq: coverImage,
                            meta_image_url: coverImage,
                            name: response.data.name,
                        },
                    }
                }
                setFormState(toReturn)
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
            setGetMetaIsLoading(false)
        })
        .catch((error) => {
            setFormState({
                ...formState,
                data: {
                    ...formState.data,
                    errors: {
                        ...formState.data.errors,
                        [`${prefixLink}link_url`]: error.response?.statusText || 'Error',
                    },
                    fields: {
                        ...formState.data.fields,
                        [`${prefixLink}link_url`]: urltoParse,
                    },
                },
            })
            setGetMetaIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log(error)
            if (process.env.NODE_ENV === 'development') console.log(error.response)
        })
}
