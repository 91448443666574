// react components
import React from 'react'
import {
    // useDispatch,
    // useSelector,
} from 'react-redux'
import {
    // useHistory,
} from 'react-router-dom'

// data
import {
    // defaultReduxState,
    // reduxModalContentDetailHide,
    searchTabType,
    // view_url_search,
} from 'data'

// props
type MainHashtagProps = {
    edit: boolean
    hash?: boolean
    label: string | undefined
    lowercase?: boolean
    searchTab?: searchTabType
}

// main
export const MainHashtag: React.FC<MainHashtagProps> = React.memo(({
    edit,
    hash,
    label,
    lowercase,
    searchTab,
}) => {

    // const dispatch = useDispatch()
    // const history = useHistory()
    // const reduxModalcontentDetail = useSelector((state: defaultReduxState) => state.reduxModal.contentDetail)

    // function onTagClick() {
    //     if (!edit && label && searchTab) {
    //         if (reduxModalcontentDetail.isOpen) dispatch(reduxModalContentDetailHide())
    //         history.push(`${view_url_search}?search=${label}#tab=${searchTab}`)
    //     }
    // }

    return (
        <span
            // className={`main-hashtag${lowercase ? ' lowercase' : ''}${edit ? ' edit' : ''}`}
            className={`main-hashtag${lowercase ? ' lowercase' : ''} edit`}
        // onClick={() => onTagClick()}
        >
            {hash ? '#' : ''}{label}
        </span>
    )
})
