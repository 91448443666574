// data
import {
    reduxTextState,
} from 'data'

// main
export function getMainErrorMessageV2({
    error,
    reduxText,
}: {
    error: any
    reduxText: reduxTextState['data']
}) {
    const mainError = error.message
    const errorStatus = error.response && error.response.status
    if (errorStatus === 400) {
        return error.response.data
    }
    if (errorStatus === 500) {
        return {
            main_error: reduxText[4579],
            main_error_title: reduxText[9880],
        }
    }
    return error.response ? error.response.data : { main_error: mainError }
}
