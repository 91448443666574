// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// data
import {
    api_url_css_property_list,
    defaultReduxState,
    reduxFormGetCSSProperties,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetAvailabitySite,
    reduxFormSetNavigationModeSite,
    reduxFormSetPermissionsSite,
    reduxModalErrorEventHandler,
    reduxModalSetEditSite,
    select_url_portfolio_content_source_available,
} from 'data'

// pages
import {
    ContainerPageContentPortfolioPanel,
    ContainerPagePortfolioPanel,
    ContainerPortfolioPanel,
    EshopPortfolioPanel,
    ListPagePortfolioPanel,
    MenuPortfolioPanel,
    PortfolioFormContainer,
    PortfolioMotherFormState,
    PortfolioPageContentFormState,
    PortfolioPageFormState,
    SettingsPortfolioPanel,
    UserPortfolioPanel,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    autoSaveStyles,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailData,
    getDynamicTextOptions,
    getInputValue,
} from 'services'

// props
type MatchParams = {
    id: string
    contentId: string
    panelValue: string
}

type LocationParams = {
    formRefresh?: number
}

// main
export const PortfolioForm: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolio = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<PortfolioFormState>({
        mother: PortfolioMotherFormState(reduxAuth),
        content: PortfolioPageContentFormState(reduxAuth),
        page: PortfolioPageFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [showPrivateSpaceModal, setShowPrivateSpaceModal] = useState(false)

    useEffect(() => {
        try {
            autoSaveStyles({
                component: 'PortfolioForm',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioForm',
                'useEffect',
            ))
        }
    }, [
        params.contentId,
    ])

    useEffect(() => {
        setHelpers({
            ...helpers,
            isLoading: true,
        })
        setInitialState()
    }, [
        params.id,
        reduxAuth.settings?.active_profile?.id,
    ])

    useEffect(() => {
        if (formState.mother.fields?.profile?.id) {
            getAvailabeContentSource()
        }
    }, [
        formState.mother.fields?.profile?.id,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        getDetailData({
            axiosCancelToken,
            component: 'PortfolioForm',
            dispatch,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: false,
            objectId: params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
    ])

    useEffect(() => {
        getCSSProperties()
    }, [
        params.id,
    ])

    useEffect(() => {
        dispatch(reduxModalSetEditSite(true))
        dispatch(reduxFormSetNavigationModeSite('navPage'))
        if (formState.mother.fields?.profile?.id) {
            getDynamicTextOptions({
                component: 'PortfolioForm',
                contentTypeSettingsCustom: formState.mother.fields?.profile?.settings_data_json?.contentTypeSettingsCustom,
                dispatch,
                reduxAuth,
            })
        }
    }, [
        formState.mother.fields?.profile?.id,
    ])

    useEffect(() => {
        dispatch(reduxFormSetPermissionsSite(formState.mother.fields?.permissions))
    }, [
        formState.mother.fields?.permissions,
    ])

    async function getCSSProperties() {
        try {
            if (!params.id) return
            if (reduxForm.cssProperties.length > 0) {
                return
            }
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PortfolioForm getCSSProperties')
            const getUrl = getApiUrl(api_url_css_property_list, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormGetCSSProperties(response.data))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'PortfolioForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getCSSProperties',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioForm',
                'getCSSProperties',
            ))
        }
    }

    async function getAvailabeContentSource() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PortfolioForm getAvailabeContentSource')
            const getUrl = getApiUrl(`${select_url_portfolio_content_source_available}?profile_id=${formState.mother.fields?.profile?.id}`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetAvailabitySite(response.data[0].available))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'PortfolioForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getAvailabeContentSource',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioForm',
                'getAvailabeContentSource',
            ))
        }
    }

    function handleInputChange(event: any, formInfo: any, customError?: any) {
        try {
            // if (!reduxFormSite.editingForm) dispatch(reduxFormSetEditingSite(true, event.name === 'styles_original'))
            const content = formState.content
            if (formInfo.name === 'content' && event.name === 'content_source' && content) {
                const toReturn = {
                    ...content,
                    fields: {
                        ...content.fields,
                        content_source: event.value,
                        content_source_detail_id: undefined,
                        content_source_filter_mode: { id: 'a', name: reduxText[5445] },
                        content_source_list_max: undefined,
                        custom_filters: undefined,
                    },
                    errors: {
                        ...content.errors,
                        profile: '',
                    },
                }
                setFormState({
                    ...formState,
                    content: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
            else {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        customError,
                        event,
                        formInfo,
                        reduxText,
                    })
                })
            }
            if (event.name !== 'styles_original') {
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioForm',
                'handleInputChange',
            ))
        }
    }

    function setInitialState() {
        setFormState({
            mother: PortfolioMotherFormState(reduxAuth),
            page: PortfolioPageFormState(reduxAuth),
            content: PortfolioPageContentFormState(reduxAuth),
        })
    }

    const panelValue = params.panelValue
    const fieldsMother = formState.mother.fields

    return (
        <PortfolioFormContainer
            formState={formState}
            helpers={helpers}
            setHelpers={setHelpers}
        >
            {fieldsMother?.id && (
                <React.Fragment>
                    {panelValue === 'content-container' && (
                        <ContainerPageContentPortfolioPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'eshop-settings' && (
                        <EshopPortfolioPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'global-design' && (
                        <ContainerPortfolioPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'global-settings' && (
                        <SettingsPortfolioPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'page-container' && (
                        <ContainerPagePortfolioPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'page-list' && (
                        <ListPagePortfolioPanel
                            formState={formState}
                        />
                    )}
                    {reduxCacheSiteportfolio?.has_private_space && showPrivateSpaceModal && (
                        <UserPortfolioPanel
                            helpers={helpers}
                            portfolioId={fieldsMother.id}
                            setHelpers={setHelpers}
                            setShowModal={setShowPrivateSpaceModal}
                        />
                    )}
                    {(!panelValue || (panelValue === 'menu')) && (
                        <MenuPortfolioPanel
                            formState={formState}
                            helpers={helpers}
                            setHelpers={setHelpers}
                            setShowPrivateSpaceModal={setShowPrivateSpaceModal}
                        />
                    )}
                </React.Fragment>
            )}
        </PortfolioFormContainer>
    )
}
