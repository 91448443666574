import {
    contentTypeDataNew,
    contentTypeModelTypeNew,
} from './mo_content_type_new'
import {
    contentTypeDataOld,
    contentTypeModelTypeOld,
} from './mo_content_type_old'

// main
export type contentTypeModelType = contentTypeModelTypeNew | contentTypeModelTypeOld
export const contentTypeData = {
    ...contentTypeDataNew,
    ...contentTypeDataOld,
}
