// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonSelect,
    IonSelectOption,
    IonToolbar,
    useIonViewWillEnter,
    useIonViewWillLeave,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
    media_url,
    media_version,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type SearchMenuMobileProps = {
    filterOptionValue: any
    filterOptions: any[]
    filterValue: any[]
    onCloseFilterMenuParent: any
    onSetFilterParams: any
    onSetThumbSize: any
    setFilterOptionValue: any
    setFilterValue: any
    tabNameId: number
    thumbSize: string
}

// main
export const SearchMenuMobile: React.FC<SearchMenuMobileProps> = React.memo(({
    filterOptionValue,
    filterOptions,
    filterValue,
    onCloseFilterMenuParent,
    onSetFilterParams,
    onSetThumbSize,
    setFilterOptionValue,
    setFilterValue,
    tabNameId,
    thumbSize,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    // const [artChoiceId, setArtChoiceId] = useState<any>()
    // const [level0Choices, setLevel0Choices] = useState<any[]>([])
    const [choices, setChoices] = useState<any[]>([])
    const [disabled, setDisabled] = useState(false)
    const [filterApiUrl] = useState<string>(getApiUrl(filterOptions[0].api_url, reduxAuth))

    useEffect(() => {
        getData(filterApiUrl)
    }, [])

    useIonViewWillEnter(() => {
        setDisabled(false)
    })

    useIonViewWillLeave(() => {
        setDisabled(true)
    })

    async function getData(url: string) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SearchMenuMobile getData')
            const getUrl = url
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setChoices(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'SearchMenuMobile',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SearchMenuMobile',
                'getData',
            ))
        }
    }

    function onCheckboxChange(event: any, option: any) {
        try {
            if (process.env.NODE_ENV === 'development') console.log(event.detail)
            if (process.env.NODE_ENV === 'development') console.log(option)
            let filterArray: any[] = []
            const index = filterValue.findIndex((x: any) => (x.id === event.detail.value.id && x.filterParam === filterOptionValue))
            if (process.env.NODE_ENV === 'development') console.log(index)
            if (event.detail.checked && index === -1) {
                const newObject: any = event.detail.value
                newObject.filterParam = filterOptionValue
                // setArtChoiceId(event.detail.value.id)
                filterArray = [...filterValue, newObject]
                setFilterValue(filterArray)

            } else if (!event.detail.checked && index >= 0) {
                // setArtChoiceId('')
                // setLevel0Choices([])
                filterValue.map((val: any) => {
                    const newObject: any = val
                    newObject.filterParam = filterOptionValue
                    if (val.id !== event.detail.value.id) filterArray.push(newObject)
                    return false
                })
                setFilterValue(filterArray)

            }
            onSetFilterParams(filterArray)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SearchMenuMobile',
                'onCheckboxChange',
            ))
        }
    }

    function onSelectInputChange(value: string) {
        try {
            setFilterOptionValue(value)
            const index = filterOptions.findIndex((x: any) => x.slug === value)
            getData(getApiUrl(filterOptions[index].api_url, reduxAuth))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SearchMenuMobile',
                'onSelectInputChange',
            ))
        }
    }

    function onCloseFilterMenu() {
        try {
            onCloseFilterMenuParent()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SearchMenuMobile',
                'onCloseFilterMenu',
            ))
        }
    }

    return (
        <IonMenu
            contentId='searchPage'
            disabled={disabled}
            menuId='searchPage'
            type='overlay'
            side='end'
            className='search-menu'
            onIonDidClose={onCloseFilterMenu}
        >
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons className='sm-header-button'>
                        <IonMenuToggle autoHide={false} menu='searchPage'>
                            <Button
                                edit={false}
                                fill='clear'
                                text={reduxText[2333]}
                            />
                        </IonMenuToggle>
                        <p className='sm-header-title'>{reduxText[tabNameId]}</p>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonMenuToggle autoHide={false} menu='searchPage'>
                        <div className='sm-thumb-wrap'>
                            <i
                                className={`sl-size-icon mo-new-icon-gallery${thumbSize === 'small' ? ' active' : ''}`}
                                onClick={() => onSetThumbSize('small')}
                            />
                            <i
                                className={`sl-size-icon mo-new-icon-list${thumbSize === 'medium' ? ' active' : ''}`}
                                onClick={() => onSetThumbSize('medium')}
                            />
                            <i
                                className={`sl-size-icon mo-new-icon-expand-solid${thumbSize === 'big' ? ' active' : ''}`}
                                onClick={() => onSetThumbSize('big')}
                            />
                        </div>
                    </IonMenuToggle>
                </IonToolbar>
                <IonToolbar>
                    <IonSelect
                        className='sm-filter-option-choice'
                        cancelText={reduxText[4519]}
                        interface='popover'
                        okText={reduxText[5614]}
                        value={filterOptionValue}
                        onIonChange={(e) => onSelectInputChange(e.detail.value)}
                    >
                        {filterOptions.map((val: any) => (
                            <IonSelectOption
                                key={val.slug}
                                value={val.slug}
                            >
                                {val.name}
                            </IonSelectOption>
                        ))}
                    </IonSelect>
                </IonToolbar>
            </IonHeader>
            <IonContent
                forceOverscroll
                fullscreen
            >
                <IonList className='sm-choices-list'>
                    {choices.map((val) => {
                        return (
                            <IonItem key={`${filterOptionValue}${val.id}`}>
                                <IonLabel>
                                    <p className='sm-choice-label'>{val.name}</p>
                                </IonLabel>
                                <IonCheckbox
                                    checked={filterValue.findIndex((x: any) => (x.id === val.id && x.filterParam === filterOptionValue)) >= 0}
                                    color='secondary'
                                    onIonChange={(e) => onCheckboxChange(e, filterOptionValue)}
                                    slot='start'
                                    value={val}
                                />
                                {val.country && (
                                    <ImageHelper
                                        alt={val.name}
                                        className='sm-flag'
                                        dominant_color={undefined}
                                        lazyMobile={false}
                                        src={`${media_url}main/flag/${val.country.toLowerCase()}.svg${media_version}`}
                                    />
                                )}
                            </IonItem>
                        )
                    })}
                </IonList>
            </IonContent>
        </IonMenu >
    )
})
