// react components
import React from 'react'
import Linkify from 'react-linkify'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// components
import {
    Copy,
    IconBlock,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
} from 'data'

// serializers
import {
    ContentTypeDetailBlockFieldSetFieldsOptionTypes,
    ContentTypeFieldTypes,
    ContentTypeSettingsCustomTypes,
    ContentTypeSettingsTypes,
    MainGlobalTranslationSerializerV2,
} from 'serializers/web'

// services
import {
    getDetailObject,
    getModalAbsoluteUrlV2,
    onClickIsModal,
    getApiUrlV2,
} from 'services'

// props
type DetailRowProps = {
    children?: React.ReactNode
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    field: string
    fieldOptions: ContentTypeDetailBlockFieldSetFieldsOptionTypes | undefined
    fieldSettings: ContentTypeFieldTypes | undefined
    fieldSettingsCustom: any
    fkObject?: any
    isError?: boolean
    isRich?: boolean
    object: any
    refreshData?: () => void
    rowId: string
    title: string
    value: string
}

// main
export const DetailRow: React.FC<DetailRowProps> = React.memo(({
    children,
    contentTypeSettings,
    contentTypeSettingsCustom,
    field,
    fieldOptions,
    fieldSettings,
    fieldSettingsCustom,
    fkObject,
    isError,
    isRich,
    object,
    refreshData,
    rowId,
    title,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const hideIfEmpty = fieldOptions?.hideIfEmpty
    if (!value && hideIfEmpty) return null
    if (fieldOptions?.hideIfNotStaff && !reduxAuth.settings?.user?.is_staff) return null
    if (fieldOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) return null

    const translations: MainGlobalTranslationSerializerV2[] | undefined = object.has_translation ? object.translations?.filter((val: any) => val.field_name === field) : undefined

    function onCreate() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`setup/select/contenttypefield/?profile=${object.profile.id}&content_type_settings=${contentTypeSettings.meta.id}&key=${field}`, reduxAuth),
                component: 'DetailRow',
                dispatch,
                reduxAuth,
                setDetailObject: (data: any) => {
                    const defaultFields = {
                        field: data[0],
                        content_type_settings_profile: {
                            id: contentTypeSettingsCustom?.meta?.id,
                            name: contentTypeSettingsCustom?.meta?.id,
                        }
                    }
                    console.log('defaultFields', defaultFields)
                    dispatch(reduxModalMainFormShow({
                        contentType: 'setup_contenttypefieldprofile',
                        cssClass: 'form-container-modal',
                        defaultFields,
                        formComponent: 'FormModal',
                        objectId: 'new',
                        profileObject: object.profile,
                        refreshData,
                    }))
                },
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailRow',
                'onCreate',
            ))
        }
    }

    return (
        <div className={`detail-component-row ${rowId} ${isRich ? 'RichTextField' : (fieldSettings?.fieldType || 'error')}${isError ? ' error' : ''}`}>
            <p className='dcr-title'>
                {reduxAuth.settings?.user?.is_staff
                    ? (
                        <Copy text={title} />
                    ) : (
                        <span>{title}</span>
                    )
                }
                {fieldOptions?.hideIfNotStaff && reduxAuth.settings?.user?.is_staff && (
                    <span className='dcr-title-superuser'>Staff</span>
                )}
                {fieldOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id === 1 && (
                    <span className='dcr-title-superuser'>{reduxText[9447]}</span>
                )}
            </p>
            <div className='dcr-value'>
                <div className='dcr-value-wrap'>
                    {(value !== '' && typeof value !== 'undefined') && (
                        <Copy
                            className='dcr-copy'
                            hidden={['BooleanField'].includes(fieldSettings?.fieldType!)}
                            isIcon
                            text={value}
                        />
                    )}
                    {children
                        ? <div>{children}</div>
                        : <p>{value}</p>
                    }
                    {/* {reduxAuth.settings?.user?.is_staff && fkObject?.content_type_string && (value !== '' && typeof value !== 'undefined') && ( */}
                    {/* TODO for non staff only fk with profile id matching current user */}
                    {/* {fkObject?.content_type_string && (value !== '' && typeof value !== 'undefined') && (
                        <a
                            className='dcr-link-href'
                            href={`${viewUrl}/detail/${fkObject.content_type_string}/${fkObject.id}/`}
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            <i className='main-icon mo-new-icon-arrow-up-right-from-square-solid' />
                        </a>
                    )} */}
                    {fkObject?.content_type_string && (value !== '' && typeof value !== 'undefined') && (
                        <Link
                            className='dcr-link-href'
                            onClick={() => onClickIsModal(fkObject.id!, Math.random(), dispatch)}
                            to={getModalAbsoluteUrlV2({
                                contentType: fkObject.content_type_string,
                                objectId: fkObject.id,
                                pageType: 'detail',
                            })}
                        >
                            <i className='main-icon mo-new-icon-arrow-up-right-from-square-solid' />
                        </Link>
                    )}
                </div>
                {translations?.length! > 0 && translations?.map(translation => (
                    <div className='dcr-translation'>
                        <p className='dcr-language'>{translation.language?.name}</p>
                        <div className='dcr-value-wrap'>
                            {translation.image && (
                                <p>TODO</p>
                            )}
                            {translation.is_rich_text && (
                                <>
                                    <Copy
                                        className='dcr-copy'
                                        hidden={['BooleanField'].includes(fieldSettings?.fieldType!)}
                                        isIcon
                                        text={translation.value_rich!}
                                    />
                                    <div dangerouslySetInnerHTML={{ __html: translation.value_rich! }} />
                                </>
                            )}
                            {translation.pdf_file && (
                                <>
                                    <Copy
                                        className='dcr-copy'
                                        hidden={['BooleanField'].includes(fieldSettings?.fieldType!)}
                                        isIcon
                                        text={translation.pdf_file!}
                                    />                    <IconBlock
                                        edit={false}
                                        iconClass='mo-new-icon-link-solid'
                                        href={translation.pdf_file}
                                    />
                                </>
                            )}
                            {(!translation.is_rich_text && !translation.pdf_file) && (
                                <>
                                    <Copy
                                        className='dcr-copy'
                                        hidden={['BooleanField'].includes(fieldSettings?.fieldType!)}
                                        isIcon
                                        text={translation.value!}
                                    />
                                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                                        {translation.value}
                                    </Linkify>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {reduxAuth.settings?.user?.is_staff && (
                <div className='dcr-staff'>
                    <span className='dcr-title-field'>
                        <Copy text={field} />
                    </span>
                    {reduxAuth.settings?.user?.id === 1 && fieldSettingsCustom?.id && (
                        <div
                            className='dcr-staff-icons'
                            onClick={() =>
                                dispatch(reduxModalMainFormShow({
                                    contentType: 'setup_contenttypefieldprofile',
                                    cssClass: 'form-container-modal',
                                    formComponent: 'FormModal',
                                    objectId: fieldSettingsCustom.id,
                                    profileObject: object.profile,
                                    refreshData,
                                }))
                            }
                        >
                            <i className='main-icon mo-new-icon-pencil-alt-solid' />
                        </div>
                    )}
                    {reduxAuth.settings?.user?.id === 1 && !fieldSettingsCustom?.id && (
                        <div
                            className='dcr-staff-icons'
                            onClick={onCreate}
                        >
                            <i className='main-icon mo-new-icon-plus-solid' />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
})
