export type searchTabType =
    'artworks' |
    'events' |
    'materials' |
    'offers' |
    'portfolios' |
    'press' |
    'products' |
    'profiles' |
    'resources' |
    'services'

export const searchTabValue: {
    artwork: searchTabType
    event: searchTabType
    material: searchTabType
    offer: searchTabType
    portfolio: searchTabType
    press: searchTabType
    product: searchTabType
    profile: searchTabType
    resource: searchTabType
    service: searchTabType
} = {
    artwork: 'artworks',
    event: 'events',
    material: 'materials',
    offer: 'offers',
    portfolio: 'portfolios',
    press: 'press',
    product: 'products',
    profile: 'profiles',
    resource: 'resources',
    service: 'services',
}
