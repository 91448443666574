// react components
import React from 'react'
import {
    Link,
} from 'react-router-dom'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalContentDetailHide,
} from 'data'

// props
type LinkHelperProps = {
    children?: React.ReactNode
    className?: string
    edit: boolean
    href?: string
    hrefTarget?: string
    hrefTargetSystem?: boolean
    noContentDetailClose?: boolean
    onClick?: () => void
    styles?: any
    title?: string
    to?: string | false
}

// main
export const LinkHelper: React.FC<LinkHelperProps> = React.memo(({
    children,
    className,
    edit,
    href,
    hrefTarget,
    hrefTargetSystem,
    noContentDetailClose,
    onClick,
    styles,
    title,
    to,
}) => {

    const dispatch = useDispatch()
    const reduxModalcontentDetail = useSelector((state: defaultReduxState) => state.reduxModal.contentDetail)

    function onLinkClick() {
        if (onClick) onClick()
        if (!noContentDetailClose && reduxModalcontentDetail.isOpen) dispatch(reduxModalContentDetailHide())
    }

    if (edit) {
        return (
            <span
                className={className}
                style={styles}
                title={title}
            >
                {children}
            </span>
        )
    }
    if (href) {
        return (
            <a
                className={className}
                href={href}
                rel='noopener noreferrer'
                style={styles}
                target={hrefTarget || '_blank'}
                title={title}
            >
                {children}
            </a>
        )
    }
    if (to) {
        return (
            <Link
                className={className}
                onClick={() => onLinkClick()}
                style={styles}
                title={title}
                to={to}
            >
                {children}
            </Link>
        )
    }
    return (
        <span
            className={className}
            onClick={() => onLinkClick()}
            style={styles}
            title={title}
        >
            {children}
        </span>
    )
})
