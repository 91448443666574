// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// props
type ProductListStockHeaderProps = {
    count: number | undefined
}

// main
export const ProductListStockHeader: React.FC<ProductListStockHeaderProps> = React.memo(({
    count,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <tr className='main-list-header ProductListStockHeader'>
            <th>{count !== undefined ? `${count} ${reduxText[8337]}` : ''}</th>
            <th>ID</th>
            <th>{reduxText[8573]}</th>
            <th />
            <th>{reduxText[5559]}</th>
            <th>{reduxText[8568]}</th>
            <th>{reduxText[8702]}</th>
            <th>{reduxText[8641]}</th>
            <th>{reduxText[8701]}</th>
            <th>{reduxText[4596]}</th>
        </tr>
    )
})
