// react components
import React from 'react'
import {
    IonButton,
    IonButtons,
    IonHeader,
    IonToolbar,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    IconBlock,
    ImageHelper,
    LinkHelper,
    ProfilePopover,
    RatioHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// services 
import {
    changeTheme,
} from 'services'

// props
type AGSLHeaderProps = {
    forced?: boolean
}

// main
export const AGSLHeader: React.FC<AGSLHeaderProps> = React.memo(({
    forced,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonHeader className={`mo-header-web fixed-height ${forced ? 'forced' : ''}${process.env.NODE_ENV === 'development' ? ' ddev-color' : ''}`}>
            <IonToolbar className='ech-main-toolbar'>
                <div className='ech-center-wrap'>
                    <LinkHelper
                        edit={false}
                        to='/'
                        styles={{
                            alignItems: 'center',
                            display: 'flex',
                        }}
                    >
                        <ImageHelper
                            alt='AGSL'
                            className='mo-logo'
                            dominant_color={undefined}
                            // src={`${media_url}global/logo-${reduxAuth.settings?.theme === 'light' ? 'black' : 'white'}.svg${media_version}`}
                            src='https://klhkpm8h.tinifycdn.com/resizes/2024/04/18/08/23/06/af19ac00-0edb-4ed3-91de-0adb37117c8f.jpg'
                        />
                        <p
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                paddingLeft: '10px',
                            }}
                        >
                            AGSL
                        </p>
                    </LinkHelper>
                    <a
                        href='https://www.whattheweb.org/'
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            opacity: 0.5,
                            textDecoration: 'none',
                        }}
                    >
                        Powered by What The Web
                    </a>
                </div>
                <IonButtons
                    className='ech-right-wrap'
                    slot='end'
                >
                    {/* <AupouCurrentLocationHelper /> */}
                    {reduxAuth.authenticated && (
                        <>
                            <IonButton
                                className={`ech-link ${(location.pathname === '/') ? 'active' : ''}`}
                                routerLink='/'
                                size='small'
                            >
                                <div className='ech-profile-thumb'>
                                    <RatioHelper
                                        alt={reduxAuth.settings?.entity?.name}
                                        borderRadius='50%'
                                        dominant_color={reduxAuth.settings?.entity?.get_dominant_color}
                                        edit={false}
                                        src={reduxAuth.settings?.entity?.get_image_lq}
                                        to={undefined}
                                    />
                                </div>
                            </IonButton>
                            <ProfilePopover />
                            <IconBlock
                                edit={false}
                                iconClass={reduxAuth.settings?.theme === 'light' ? 'mo-new-icon-moon-solid' : 'mo-new-icon-sunny-sharp'}
                                tooltipText={reduxAuth.settings?.theme === 'light' ? reduxText[8602] : reduxText[8601]}
                                onClick={() => changeTheme({
                                    component: 'AGSLHeader',
                                    dispatch,
                                    reduxAuth,
                                    value: reduxAuth.settings?.theme === 'light' ? 'dark' : 'light',
                                })}
                            />
                        </>
                    )}
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
})
