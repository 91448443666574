export const fieldTypeLayout: {
    [key: string]: string
} = {
    'ActionButton': 'action_button',
    'AutoSlugField': 'text',
    'BigAutoField': 'number',
    'BooleanField': 'boolean',
    'CharField': 'text',
    'ChoiceField': 'text',
    'DateField': 'datetime',
    'DateTimeField': 'datetime',
    'DecimalField': 'number',
    'EmailField': 'text',
    'FileField': 'url',
    'ForeignKey': 'text',
    'GenericForeignKey': 'text',
    'ImageField': 'image',
    'IntegerField': 'number',
    'JSONField': 'text',
    'ManyToManyField': 'array',
    'ManyToManyRel': 'array',
    'ManyToOneRel': 'array',
    'MixedField': 'mixed',
    'OneToOneField': 'text',
    'PhoneNumberField': 'phonenumber',
    'PositionField': 'number',
    'PositiveIntegerField': 'number',
    'PositiveSmallIntegerField': 'number',
    'ReduxField': 'text_redux',
    'RichTextField': 'rich',
    'SearchVectorField': 'text',
    'SlugField': 'text',
    'TextField': 'text',
    'TimeField': 'time',
    'URLField': 'url',
    'UUIDField': 'text',
    'GenericRelation': 'array',
}
