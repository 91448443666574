// react components
import React from 'react'

// components
import {
    ImageHelper,
} from 'components'

// props
type ImageListBlockWebProps = {
    object: any
}

// main
export const ImageListBlockWeb: React.FC<ImageListBlockWebProps> = React.memo(({
    object,
}) => {

    return (
        <ImageHelper
            alt={object.name}
            className='image-list-block-web'
            dominant_color={object.get_dominant_color}
            minHeight='400px'
            src={object.get_image_lq || object.get_image_mq || object.get_image_hq}
        />
    )
})
