// react components
import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalErrorShowHide,
} from 'data'

// main
export const ErrorAlert: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalerror = useSelector((state: defaultReduxState) => state.reduxModal.error)

    function onAlertDismiss() {
        try {
            dispatch(reduxModalErrorShowHide({ isOpen: false }))
        } catch (error) {
            dispatch(reduxModalErrorShowHide({ isOpen: false }))
            dispatch(reduxModalErrorEventHandler(
                error,
                'ErrorAlert',
                'onAlertDismiss',
            ))
        }
    }

    if (reduxModalerror.isOpen) {
        return (
            <Dialog
                className={`mo-dialog-class error-alert ${reduxModalerror.source}`}
                classes={{ paper: 'mo-dialog-wrapper fix-width' }}
                onClose={onAlertDismiss}
                open
            >
                <DialogTitle className='mo-dialog-header'>
                    {reduxModalerror.header}{reduxModalerror.subHeader ? ` - ${reduxModalerror.subHeader}` : ''}
                    <div className='mo-dialog-header-close-wrap'>
                        <IconBlock
                            className='mo-dialog-header-close'
                            edit={false}
                            iconClass='mo-new-icon-times-solid'
                            onClick={onAlertDismiss}
                        />
                    </div>
                </DialogTitle>
                <DialogContent className='mo-dialog-content'>
                    {reduxModalerror.message && (
                        <>
                            <p className='ea-message pre-line'>{reduxModalerror.message}</p>
                            <br />
                        </>
                    )}
                    {!reduxModalerror.noMessage && (
                        <div className='ea-message-nomessage pre-line'>
                            {reduxAuth.settings?.language === 'fr'
                                ? (
                                    <>
                                        <p>Si cette erreur persiste, essayez les solutions suivantes :</p>
                                        <ul>
                                            <li>Actualisez la page.</li>
                                            {reduxAuth.authenticated && (
                                                <li>Déconnectez-vous et reconnectez-vous.</li>
                                            )}
                                            <li>Contactez notre équipe de support.</li>
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <p>If you keep seeing this error, try:</p>
                                        <ul>
                                            <li>Refresh the page.</li>
                                            {reduxAuth.authenticated && (
                                                <li>Log out and log in again.</li>
                                            )}
                                            <li>Contact our support team.</li>
                                        </ul>
                                    </>
                                )
                            }
                        </div>
                    )}
                </DialogContent>
                <DialogActions className='mo-dialog-footer'>
                    <Button
                        edit={false}
                        fill='outline'
                        onClick={onAlertDismiss}
                        text='OK'
                    />
                </DialogActions>
            </Dialog>
        )
    }

    return null
}
