// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Gallery,
    ImageGalleryHelper,
    InfiniteScrollHelperWeb,
} from 'components'

// data
import {
    api_url_project_content_list,
    api_url_project_detail,
    defaultReduxState,
    reduxModalContentDetailSetItems,
    reduxModalContentDetailShow,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getInfiniteListWeb,
} from 'services'

// props
type WorkContentBlockWebProps = {
    edit: boolean
    object: ProjectDetailSerializer
}

export const WorkContentBlockWeb: React.FC<WorkContentBlockWebProps> = React.memo(({
    edit,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormrefreshrefreshForm = useSelector((state: defaultReduxState) => state.reduxForm.refresh.refreshForm)

    useEffect(() => {
        onGetListData(getApiUrl(`${api_url_project_content_list}?project_id=${object.id}&medium_type_id=6`, reduxAuth), true)
    }, [
        object.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
        reduxFormrefreshrefreshForm,
    ])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<any[]>([])
    const [nextUrl, setNextUrl] = useState('')

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            if (!object.id) return
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'WorkContentBlockWeb',
                customFunction: changingView ? undefined : (a: any, b: any, c: any, newItems: any[]) => dispatch(reduxModalContentDetailSetItems(newItems)),
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'WorkContentBlockWeb',
                'onGetListData',
            ))
        }
    }

    function onImageClick(obj: any) {
        try {
            dispatch(reduxModalContentDetailShow(
                getApiUrl(api_url_project_detail, reduxAuth),
                'project',
                'ProjectDetailBlockWeb',
                'ProjectActionSheetWeb',
                obj.id,
                items,
                'project',
                nextUrl,
                onSearchNext,
                'WorkContentBlockWeb',
            ))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'WorkContentBlockWeb',
                'onImageClick',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'WorkContentBlockWeb',
                'onSearchNext',
            ))
        }
    }

    return (
        <div className={`work-content-block-web mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            {items.length > 0 && (
                <Gallery
                    // @ts-ignore
                    direction='row'
                    limitNodeSearch={2}
                    margin={2}
                    photos={items}
                    // @ts-ignore
                    renderImage={(props) => (
                        <ImageGalleryHelper
                            {...props}
                            direction='row'
                            onClick={(obj: any) => onImageClick(obj)}
                        />
                    )}
                />
            )}
            <InfiniteScrollHelperWeb
                active={!disableInfiniteScroll}
                hasMore={hasMore}
                isLoading={isLoading}
                items={items}
                onChange={onSearchNext}
            />
        </div>
    )
})
