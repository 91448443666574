// react components
import React from 'react'
import {
    Helmet,
} from 'react-helmet'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    media_url,
    media_version,
} from 'data'

// main
export const SeoBlock: React.FC = () => {

    const reduxModalseoData = useSelector((state: defaultReduxState) => state.reduxModal.seoData)

    return (
        <Helmet defer={false}>
            <meta charSet='utf-8' />
            <title>{reduxModalseoData?.title || 'Just The Web'}</title>
            <link rel='shortcut icon' href={reduxModalseoData?.favicon || `${media_url}favicon/favicon.ico${media_version}`} />
            <meta name='description' content={reduxModalseoData?.description || 'Just The Web is a Web and Human Accompaniment to evolve at your pace with an inspiring website and a team that takes care of you.'} />
            <meta name='Resource-type' content={reduxModalseoData?.resource_type} />
            <meta name='keywords' content={reduxModalseoData?.keywords?.map(val => val.name).join(',')} />
            <link rel='image_src' href={reduxModalseoData?.image || `https://media.justtheweb.io/global/image-seo.png${media_version}`} />
            {/* Facebook */}
            <meta property='fb:app_id' content={reduxModalseoData?.fb_app_id} />
            <meta property='og:description' content={reduxModalseoData?.description || 'Just The Web is a Web and Human Accompaniment to evolve at your pace with an inspiring website and a team that takes care of you.'} />
            <meta property='og:image' content={reduxModalseoData?.image || `https://media.justtheweb.io/global/image-seo.png${media_version}`} />
            <meta property='og:title' content={reduxModalseoData?.title || 'Just The Web'} />
            <meta property='og:type' content='website' />
            <meta property='og:site_name' content={reduxModalseoData?.site_name} />
            {/* <meta property='og:locale' content={`${props.reduxAuth.settings.language}_FR`} /> */}
            <meta name='my:fb' content='on' />
            <meta name='my:google' content='on' />
            <meta name='my:tw' content='on' />
            <meta name='my:in' content='on' />
            {/* Twitter */}
            <meta name='twitter:card' content='summary' />
            {/* <meta name='twitter:site' content={reduxModalseoData?.twitter_site} /> */}
            <meta name='twitter:title' content={reduxModalseoData?.title || 'Just The Web'} />
            <meta name='twitter:description' content={reduxModalseoData?.description || 'Just The Web is a Web and Human Accompaniment to evolve at your pace with an inspiring website and a team that takes care of you.'} />
            <meta name='twitter:image' content={reduxModalseoData?.image || `https://media.justtheweb.io/global/image-seo.png${media_version}`} />
            {/* Google */}
            <script type='application/ld+json'>
                {`{
                        '@context': '//schema.org/',
                        '@type': 'WebSite',
                        'name': ${reduxModalseoData?.title || 'Just The Web'},
                        'image': ${reduxModalseoData?.image || `https://media.justtheweb.io/global/image-seo.png${media_version}`},
                        'description': ${reduxModalseoData?.description || 'Just The Web is a Web and Human Accompaniment to evolve at your pace with an inspiring website and a team that takes care of you.'}
                        }`}
            </script>
        </Helmet>
    )
}
