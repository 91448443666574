// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    RefresherEventDetail,
} from '@ionic/core'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuToggle,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    FooterMobile,
    IconBlock,
    InfiniteScrollHelperMobile,
    Loader,
    MainDetailEditBlockMobile,
} from 'components'

// data
import {
    api_url_profile_detail,
    api_url_profile_home_list,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_profile_about,
    view_url_profile_detail_old,
} from 'data'

// pages
import {
    NotFoundPage,
    ProfileDetailBlockMobile,
    ProfileDetailMenuMobile,
    ProfileHomeListBlockMobile,
    ProfileSwitchModalMobile,
} from 'pages'

// serializers
import {
    ProfileDetailSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getInfiniteListMobile,
} from 'services'

// props
type MatchParams = {
    slug: string
}

type LocationParams = {
    profileObject?: any
    profileRefresh?: number
    profileSlug?: string
}

// main
export const ProfileDetailMobile: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (!location.state?.profileRefresh) {
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            onGetDetailData(params.slug)
        }
    }, [
        params.slug,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
    ])

    useEffect(() => {
        if (location.state?.profileRefresh) {
            if (location.state?.profileSlug) onGetDetailData(location.state?.profileSlug)
        }
    }, [location.state?.profileRefresh])

    const contentRef = useRef<HTMLIonContentElement>(null)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<any[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [errorStatus, setErrorStatus] = useState<number>()
    const [isDetailLoading, setIsDetailLoading] = useState(true)
    const [object, setObject] = useState<ProfileDetailSerializer>()
    const [switchProfileIsOpen, setSwitchProfileIsOpen] = useState(false)

    async function onGetDetailData(objectSlug: string, refreshEvent?: CustomEvent<RefresherEventDetail>) {
        try {
            setErrorStatus(undefined)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetailMobile onGetDetailData')

            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)

            setIsDetailLoading(true)
            const getUrl = getApiUrl(`${api_url_profile_detail}${objectSlug}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    scrollToTop(0)
                    setIsDetailLoading(false)
                    setObject(response.data)
                    onGetListData(
                        getApiUrl(`${api_url_profile_home_list}${params.slug}/`, reduxAuth),
                        true,
                    )
                    if (refreshEvent && refreshEvent.detail) {
                        refreshEvent.detail.complete()
                    }
                })
                .catch((error) => {
                    setIsDetailLoading(false)
                    if (error.response?.status === 403) {
                        setErrorStatus(error.response.status)
                    } else if (error.response?.status === 404 && error.response?.data?.detail) {
                        setErrorStatus(error.response.status)
                    } else {
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component: 'ProfileDetailMobile',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onGetDetailData',
                        })
                    }
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMobile',
                'onGetDetailData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        refreshEvent?: CustomEvent<RefresherEventDetail>,
    ) {
        try {
            getInfiniteListMobile(
                apiUrl,
                reduxAuth,
                dispatch,
                items,
                setItems,
                setIsLoading,
                setNextUrl,
                axiosCancelToken,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                'ProfileDetailMobile',
                changingView,
                refreshEvent,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMobile',
                'onGteListData',
            ))
        }
    }

    function onRefresh(event: CustomEvent<RefresherEventDetail>) {
        try {
            onGetDetailData(params.slug, event)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMobile',
                'onRefresh',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMobile',
                'onSearchNext',
            ))
        }
    }

    const scrollToTop = (speed?: number) => {
        try {
            contentRef.current?.scrollToTop(speed || 500)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMobile',
                'scrollToTop',
            ))
        }
    }

    const isMyProfile = reduxAuth.settings?.active_profile?.id === object?.id

    if (errorStatus) {
        return <NotFoundPage statusCode={errorStatus} />
    }
    return (
        <React.Fragment>
            {object && (
                <ProfileDetailMenuMobile
                    object={object}
                    setSwitchProfileIsOpen={setSwitchProfileIsOpen}
                />
            )}
            <IonPage
                id='profileDetail'
                className='profile-detail'
            >
                <IonHeader
                    className='mo-header'
                    translucent
                >
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonBackButton
                                defaultHref='/'
                                text=''
                            />
                        </IonButtons>
                        <IonTitle
                            color='primary'
                            onClick={isMyProfile ? () => setSwitchProfileIsOpen(true) : undefined}
                        >
                            <span>{object && object.name}</span>
                        </IonTitle>
                        {object && (
                            <MainDetailEditBlockMobile
                                componentActionSheet='ProfileActionSheetMobile'
                                object={object}
                                refreshData={() => onGetDetailData(params.slug)}
                            />
                        )}
                        {isMyProfile && (
                            <IonButtons slot='end'>
                                <IonMenuToggle menu='profileDetail'>
                                    <IconBlock
                                        edit={false}
                                        iconClass='mo-new-icon-bars-solid'
                                    />
                                </IonMenuToggle>
                            </IonButtons>
                        )}
                    </IonToolbar>
                </IonHeader>
                <IonContent
                    forceOverscroll
                    fullscreen
                    ref={contentRef}
                >
                    <IonRefresher
                        slot='fixed'
                        onIonRefresh={onRefresh}
                    >
                        <IonRefresherContent />
                    </IonRefresher>
                    {isDetailLoading && <Loader isOpen />}
                    {object && (
                        <React.Fragment>
                            <ProfileDetailBlockMobile
                                edit={false}
                                object={object}
                            />
                            <Button
                                className='pd-section-button'
                                edit={false}
                                fill='outline'
                                size='small'
                                text={reduxText[6485]}
                                to={`${view_url_profile_detail_old}${object?.slug}/tabs/${view_url_profile_about}/`}
                            />
                            <div className='pd-list'>
                                {items?.length > 0 && items.map((item, i) => {
                                    return (
                                        <ProfileHomeListBlockMobile
                                            key={item.id}
                                            object={item}
                                        />
                                    )
                                })}
                            </div>
                        </React.Fragment>
                    )}
                    <InfiniteScrollHelperMobile
                        active={!disableInfiniteScroll}
                        hasMore={hasMore}
                        isLoading={isLoading}
                        items={items}
                        onChange={onSearchNext}
                    />
                </IonContent>
                <FooterMobile scrollToTop={() => scrollToTop(500)} />
                {isMyProfile && (
                    <ProfileSwitchModalMobile
                        isOpen={switchProfileIsOpen}
                        setIsOpen={setSwitchProfileIsOpen}
                    />
                )}
            </IonPage>
        </React.Fragment>
    )
}
