// react components
import React, {
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
} from '@ionic/react'

// components
import {
    LinkHelper,
} from 'components'

// pages
import {
    EditButtonPage,
    PageListDetailHelperModal,
} from 'pages'

// serializers
import {
    PortfolioFormState,
    PortfolioPageListFormSerializer,
} from 'serializers/web'

// props
type PageListBlockDetailProps = {
    portfolioId: number
    object: PortfolioPageListFormSerializer
    page: PortfolioFormState['page']
    profileId: number
    refreshData: () => void
}

// main
export const PageListBlockDetail: React.FC<PageListBlockDetailProps> = React.memo(({
    portfolioId,
    object,
    page,
    profileId,
    refreshData,
}) => {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <IonItem>
            <IonLabel>
                <LinkHelper
                    className='lpppw-page-name pointer'
                    edit={false}
                    onClick={() => setIsOpen(true)}
                >
                    {object.name}
                </LinkHelper>
            </IonLabel>
            <EditButtonPage
                object={object}
                page={page}
                refreshData={refreshData}
            />
            {isOpen && (
                <PageListDetailHelperModal
                    mediumType={object.medium_type}
                    page={object}
                    portfolioId={portfolioId}
                    profileId={profileId}
                    setIsOpen={setIsOpen}
                />
            )}
        </IonItem>
    )
})
