// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    IonList,
} from '@ionic/react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import debounce from 'lodash.debounce'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorHelper,
    IconBlock,
    InfiniteScrollHelperWeb,
    SearchInput,
} from 'components'

// data
import {
    api_url_profile_section_form_create,
    api_url_profile_section_form_create_new,
    api_url_profile_section_form_list_popup,
    contentSourceType,
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AddToSectionFormItem,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getInfiniteListWeb,
} from 'services'

// props
type AddToSectionFormProps = {
    contentType: contentTypeModelType
    contentSource: contentSourceType
    objectId: number | undefined
    profileSlug: string
    refreshData?: () => void
    setIsOpen: React.Dispatch<boolean>
}

// main
export const AddToSectionForm: React.FC<AddToSectionFormProps> = React.memo(({
    contentType,
    contentSource,
    objectId,
    profileSlug,
    refreshData,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [customNameError, setCustomNameError] = useState<string>()
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<any[]>([])
    const [nextUrl, setNextUrl] = useState('')
    const [value, setValue] = useState<string>('')
    const [isSearching, setIsSearching] = useState(false)

    useEffect(() => {
        onGetListData(getApiUrl(`${api_url_profile_section_form_list_popup}${profileSlug}/?content_type_id=${contentTypeData[contentType!]}${objectId ? `&object_id=${objectId}` : ''}&content_source=${contentSource}`, reduxAuth), true)
    }, [])

    function onSearch(value: string | null | undefined) {
        try {
            onGetListData(getApiUrl(`${api_url_profile_section_form_list_popup}${profileSlug}/?content_type_id=${contentTypeData[contentType!]}${objectId ? `&object_id=${objectId}` : ''}&content_source=${contentSource}&search=${value}`, reduxAuth), true)
            setIsSearching(true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToSectionForm',
                'onAddNewSection',
            ))
        }
    }

    const debouncedChangeHandler = debounce(onSearch, 300)

    async function onAddNewSection() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AddToSectionForm onAddNewSection')

            if (!value) return
            if (!reduxAuth.settings?.active_profile?.id) return

            let axiosUrl = ''
            if (!isNaN(Number(profileSlug))) {
                // profileSlug is a number
                axiosUrl = getApiUrl(`${api_url_profile_section_form_create_new}`, reduxAuth)
            } else {
                // profileSlug is not a number
                axiosUrl = getApiUrl(`${api_url_profile_section_form_create}`, reduxAuth)
            }

            const formData: any = new FormData()
            formData.append('content_type', contentTypeData[contentType!])
            formData.append('name_custom', value)
            formData.append('profile', profileSlug!)
            if (contentSource === 'press') {
                formData.append('content_source', 'press')
                formData.append('component', 'press')
            } else if (contentSource === 'resource') {
                formData.append('content_source', 'resource')
                formData.append('component', 'artwork')
            }

            if (process.env.NODE_ENV === 'development') console.log(axiosUrl)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setValue('')
                    const newItems: any[] = []
                    const newValue = response.data
                    newValue.in_profile_section_id = false
                    newItems.push(newValue)
                    setItems([...newItems, ...items])
                    setValue('')
                })
                .catch((error) => {
                    if (error.response?.data?.name_custom) {
                        setCustomNameError(error.response.data.name_custom)
                    } else {
                        axiosErrorHandler({
                            apiUrl: axiosUrl,
                            component: 'AddToSectionForm',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onAddNewSection',
                        })
                    }
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToSectionForm',
                'onAddNewSection',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'AddToSectionForm',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToSectionForm',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToSectionForm',
                'onSearchNext',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class add-to-section-form-web'
            classes={{ paper: 'mo-dialog-wrapper fix-height fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[6594]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                {(items.length === 0 && !hasMore && !isSearching)
                    ? (
                        <p className='atsfw-helper'>{reduxText[7722]}</p>
                    ) : (
                        <div className='mo-dialog-searchbar-wrap'>
                            <SearchInput
                                classNameWrap='bg1'
                                onChange={(e) => debouncedChangeHandler(e)}
                                placeholder={reduxText[282]}
                            />
                        </div>
                    )
                }
                <IonList>
                    <div>
                        {items.map((item) => {
                            return (
                                <AddToSectionFormItem
                                    key={item.id}
                                    contentSource={contentSource!}
                                    contentType={contentType!}
                                    item={item}
                                    objectId={objectId}
                                    refreshData={refreshData}
                                />
                            )
                        })}
                    </div>
                </IonList>
                <InfiniteScrollHelperWeb
                    active={!disableInfiniteScroll}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    items={items}
                    onChange={onSearchNext}
                />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <div className='atsfw-input-wrap'>
                    <input
                        className='atsfw-input'
                        onChange={(e) => {
                            setValue(e.target.value)
                            setCustomNameError('')
                        }}
                        placeholder={reduxText[6607]}
                        type='text'
                        value={value}
                    />
                    <ErrorHelper error={customNameError} />
                </div>
                {value && (
                    <Button
                        className='atsfw-button'
                        edit={false}
                        disabled={!Boolean(value)}
                        onClick={() => onAddNewSection()}
                        size='small'
                        text={reduxText[8684]}
                    />
                )}
            </DialogActions>
        </Dialog>
    )
})
