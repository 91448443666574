// react components
import React from 'react'
import {
    DropzoneComponent,
} from 'react-dropzone-component'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
    MainFormLabel,
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    InlineFormPanelProps,
    MaterialFormState,
} from 'serializers/web'

// props
type ImageMaterialPanelProps = {
    formState: MaterialFormState
    setFormState: React.Dispatch<MaterialFormState>
}

// main
export const ImageMaterialPanel: React.FC<InlineFormPanelProps & ImageMaterialPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const image = formState.image
    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, image)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageMaterialPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = image.errors
    const fieldsInline = image.fields

    if (!fieldsInline) return null

    // Extra
    let myDropzone: any
    function initCallback(dropzone: any) {
        myDropzone = dropzone
    }
    const config = {
        iconFiletypes: ['.gif', '.jpg', '.jpeg', '.png', '.webp'],
        postUrl: image?.createUrl,
        showFiletypeIcon: true,
    }
    const eventHandlers = {
        init: (e: any) => initCallback(e),
        complete: (file: any) => {
            myDropzone.removeFile(file)
            dispatch(reduxFormSetRefresh('refreshInlineList'))
        }
    }
    const djsConfig = {
        acceptedFiles: 'image/gif,image/jpeg,image/png,image/webp',
        dictDefaultMessage: reduxText[5574],
        headers: reduxAuth.axiosConfig?.headers,
        maxFilesize: 64,
        maxThumbnailFilesize: 64,
        parallelUploads: 1,
        paramName: 'image',
        timeout: null,
        params: {
            active: true,
            material: fieldsMother.id,
        },
    }

    return (
        <InlineFormContainerWeb
            formInfo={image}
            formState={formState}
            helpers={helpers}
            isDropzone
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {fieldsInline.id ? (
                <React.Fragment>
                    <ImageCropInput
                        altButton
                        altValue={fieldsInline.image_alt}
                        doka_output_data={fieldsInline.image_doka_output_data || ''}
                        error={errorsInline?.image}
                        errorAlt={errorsInline?.image_alt}
                        label={reduxText[1767]}
                        name='image'
                        onChange={onHandleInputChange}
                        original={fieldsInline.image_original || ''}
                        value={fieldsInline.image || ''}
                    />
                    <TextInput
                        error={errorsInline?.description}
                        label={reduxText[4381]}
                        name='description'
                        onChange={onHandleInputChange}
                        value={fieldsInline.description || ''}
                    />
                </React.Fragment>
            ) : (
                <div className='mo-image-input-web'>
                    <MainFormLabel
                        label={reduxText[1767]}
                        name='image'
                    />
                    <DropzoneComponent
                        config={config}
                        djsConfig={djsConfig}
                        eventHandlers={eventHandlers}
                    />
                </div>
            )}
        </InlineFormContainerWeb>
    )
})
