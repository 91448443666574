// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_portfolio_filter_related_content,
} from 'data'

// pages
import {
    ContentListInputPortfolio,
    DataJsonInputPageContent,
    FilterInputFilterFieldPageContent,
} from 'pages'

// serializers
import {
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type FilterInputPageContentProps = {
    detailUrl: string
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    onHandleInputChange: any
    optional: boolean
    updateUrl: string
}

// main
export const FilterInputPageContent: React.FC<FilterInputPageContentProps> = React.memo(({
    detailUrl,
    errorsInline,
    fieldsInline,
    fieldsMother,
    onHandleInputChange,
    optional,
    updateUrl,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!fieldsInline.content_source?.id) return null

    // Extra
    const filterOptions: any = {
        2: [  // Project list
            { name: reduxText[6553], id: 66 },  // cities
            { name: reduxText[6412], id: 61 },  // countries
            { name: reduxText[5569], id: 328 },  // custom categories
            { name: reduxText[8723], id: 355 },  // custom categories 2
            { name: reduxText[8731], id: 356 },  // custom categories 3
            { name: reduxText[2945], id: 60 },  // languages
            { name: reduxText[6402], id: 56 },  // medium types
            { name: reduxText[7683], id: 195 },  // profile section
            { name: reduxText[3786], id: 147 },  // tags
            { name: reduxText[4917], id: 107 },  // team
        ],
        6: [  // Event list
            { name: reduxText[6553], id: 66 },  // cities
            { name: reduxText[6412], id: 61 },  // countries
            { name: reduxText[5569], id: 328 },  // custom categories
            { name: reduxText[7683], id: 195 },  // profile section
            { name: reduxText[3786], id: 147 },  // tags
        ],
        8: [  // Material list
            { name: reduxText[5569], id: 328 },  // custom categories
            { name: reduxText[7683], id: 195 },  // profile section
        ],
        10: [  // Offer list
            { name: reduxText[6553], id: 66 },  // cities
            { name: reduxText[6412], id: 61 },  // countries
        ],
        12: [  // Member list
            { name: reduxText[7683], id: 195 },  // profile section
            { name: reduxText[8257], id: 286 },  // regions
        ],
        13: [  // Product list
            { name: reduxText[5569], id: 328 },  // custom categories
            { name: reduxText[8723], id: 355 },  // custom categories 2
            { name: reduxText[7683], id: 195 },  // profile section
            { name: reduxText[3786], id: 147 },  // tags
            { name: reduxText[4917], id: 194 },  // team
        ],
        16: [  // Press list
            { name: reduxText[6553], id: 66 },  // cities
            { name: reduxText[6412], id: 61 },  // countries
            { name: reduxText[5569], id: 328 },  // custom categories
            { name: reduxText[8723], id: 355 },  // custom categories 2
            { name: reduxText[8731], id: 356 },  // custom categories 3
            { name: reduxText[2945], id: 60 },  // languages
            { name: reduxText[6402], id: 56 },  // medium types
            { name: reduxText[7683], id: 195 },  // profile section
            { name: reduxText[3786], id: 147 },  // tags
        ],
        28: [  // Service list
            { name: reduxText[5569], id: 328 },  // custom categories
            { name: reduxText[7683], id: 195 },  // profile section
            { name: reduxText[3786], id: 147 },  // tags
        ],
        40: [  // Resource list
            { name: reduxText[6553], id: 66 },  // cities
            { name: reduxText[6412], id: 61 },  // countries
            { name: reduxText[5569], id: 328 },  // custom categories
            { name: reduxText[8723], id: 355 },  // custom categories 2
            { name: reduxText[8731], id: 356 },  // custom categories 3
            { name: reduxText[2945], id: 60 },  // languages
            { name: reduxText[6402], id: 56 },  // medium types
            { name: reduxText[7683], id: 195 },  // profile section
            { name: reduxText[3786], id: 147 },  // tags
            { name: 'custom_many_to_many_field_1__in', id: 409 },  // term
        ],
        43: [  // Project content list
            { name: reduxText[6553], id: 66 },  // cities
            { name: reduxText[6412], id: 61 },  // countries
            { name: reduxText[5569], id: 328 },  // custom categories
            { name: reduxText[8723], id: 355 },  // custom categories 2
            { name: reduxText[8731], id: 356 },  // custom categories 3
            { name: reduxText[2945], id: 60 },  // languages
            { name: reduxText[6402], id: 56 },  // medium types
            { name: reduxText[7683], id: 195 },  // profile section
            { name: reduxText[3786], id: 147 },  // tags
        ],
    }

    const newOption = filterOptions[fieldsInline.content_source.id]

    if (!newOption && fieldsInline.content_source.id !== 91) return null

    return (
        <React.Fragment>
            <SelectListInput
                clearable
                error={errorsInline?.filter_related_content}
                label={reduxText[8484]}
                name='filter_related_content'
                onChange={onHandleInputChange}
                optional={optional}
                apiUrl={`${select_url_portfolio_filter_related_content}?page_id=${fieldsInline.page?.id}`}
                value={fieldsInline.filter_related_content || ''}
            />
            {fieldsInline.content_source.id !== 91 && (
                <SelectListInput
                    clearable
                    error={errorsInline?.filter_content_source}
                    label={reduxText[6563]}
                    name='filter_content_source'
                    onChange={onHandleInputChange}
                    optional={optional}
                    options={newOption}
                    optionsSorted
                    value={fieldsInline.filter_content_source || ''}
                />
            )}
            {fieldsInline.content_source.id === 91 && fieldsInline.filter_related_content && (
                <FilterInputFilterFieldPageContent
                    errorsInline={errorsInline}
                    fieldsInline={fieldsInline}
                    fieldsMother={fieldsMother}
                    onHandleInputChange={onHandleInputChange}
                    optional={optional}
                />
            )}
            {(fieldsInline.filter_content_source?.id) && (
                <React.Fragment>
                    <ContentListInputPortfolio
                        contentSourceId={fieldsInline.content_source.id}
                        contentTypeId={fieldsInline.filter_content_source.id}
                        error={errorsInline?.filter_content_source_detail_id}
                        filter
                        label={reduxText[6565]}
                        name='filter_content_source_detail_id'
                        onChange={onHandleInputChange}
                        optional={optional}
                        portfolioId={fieldsMother.id}
                        profileId={fieldsMother.profile?.id}
                        value={fieldsInline.filter_content_source_detail_id || ''}
                    />
                    {fieldsInline.layout?.id === 801 && (
                        <React.Fragment>
                            <CheckboxInput
                                error={errorsInline?.filter_show_all_tab}
                                label={reduxText[8465]}
                                name='filter_show_all_tab'
                                onChange={onHandleInputChange}
                                value={fieldsInline.filter_show_all_tab}
                            />
                            <DataJsonInputPageContent
                                detailUrl={detailUrl}
                                errorsInline={errorsInline}
                                fieldsInline={fieldsInline}
                                fieldsMother={fieldsMother}
                                onHandleInputChange={onHandleInputChange}
                                updateUrl={updateUrl}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    )
})
