// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
    TextAreaInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_portfolio_page_link,
} from 'data'

// serializers
import {
    LayoutFieldHelperSerializer,
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type PageLinkInputPageContentProps = {
    disabled?: boolean
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    inputObject?: LayoutFieldHelperSerializer
    mixedMedia?: boolean
    onHandleInputChange: any
    optional: boolean
}

// main
export const PageLinkInputPageContent: React.FC<PageLinkInputPageContentProps> = React.memo(({
    disabled,
    errorsInline,
    fieldsInline,
    fieldsMother,
    inputObject,
    mixedMedia,
    onHandleInputChange,
    optional,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    // @ts-ignore
    if (mixedMedia && !fieldsMother?.portfolio_main) return null

    return (
        <React.Fragment>
            <SelectListInput
                // @ts-ignore
                apiUrl={`${select_url_portfolio_page_link}?portfolio_id=${mixedMedia ? fieldsMother?.portfolio_main?.id : fieldsMother?.id}`}
                clearable
                disabled={disabled}
                error={errorsInline?.page_link}
                helperText={inputObject?.helper_text}
                label={inputObject?.label || reduxText[6335]}
                name='page_link'
                onChange={onHandleInputChange}
                optional={optional}
                value={fieldsInline.page_link || ''}
            />
            <TextAreaInput
                disabled={disabled}
                error={errorsInline?.custom_page_link_params}
                label={reduxText[8339]}
                name='custom_page_link_params'
                onChange={onHandleInputChange}
                optional={optional}
                value={fieldsInline.custom_page_link_params || ''}
            />
        </React.Fragment>
    )
})
