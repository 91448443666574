// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    RichTextInputV2,
    SelectListInputV2,
    TextAreaInputV2,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    MainFontSerializer,
    MainIdNameSerializer,
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type TextShortInputPageContentProps = {
    detailUrl: string
    disabled?: boolean
    disabledText?: boolean
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    fonts: MainFontSerializer[] | undefined
    inputObject?: LayoutFieldHelperSerializer
    mixedMedia?: boolean
    onHandleInputChange: any
    onRefresh: () => void
    optional: boolean
    text_short_mode?: number
    updateUrl: string
}

// main
export const TextShortInputPageContent: React.FC<TextShortInputPageContentProps> = React.memo(({
    detailUrl,
    disabled,
    disabledText,
    errorsInline,
    fieldsInline,
    fieldsMother,
    fonts,
    inputObject,
    mixedMedia,
    onHandleInputChange,
    onRefresh,
    optional,
    text_short_mode,
    updateUrl,
}) => {

    const reduxFormSitedynamicTextOptions = useSelector((state: defaultReduxState) => state.reduxFormSite.dynamicTextOptions)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const modeOptions2 = [  // 'normal_or_long'
        {
            id: 1,
            name: reduxText[6416],  // Normal
        },
        {
            id: 2,
            name: reduxText[6417],  // Rich
        },
    ]
    const modeOptions3 = [  // normal_or_dynamic
        {
            id: 1,
            name: reduxText[6416],  // Normal
        },
        {
            id: 3,
            name: reduxText[6576],  // Dynamic
        },
    ]
    if (reduxAuth.settings?.user?.is_staff) {
        modeOptions3.push({
            id: 4,
            name: 'Calculated',  // Calculated  // TO_TEXT
        })
        modeOptions3.push({
            id: 5,
            name: 'Template',  // Template  // TO_TEXT
        })
        modeOptions3.push({
            id: 6,
            name: 'Template Rich',  // Template  // TO_TEXT
        })
    }
    const modeOptions4 = [  // normal_or_long_or_dynamic
        {
            id: 1,
            name: reduxText[6416],  // Normal
        },
        {
            id: 2,
            name: reduxText[6417],  // Rich
        },
        {
            id: 3,
            name: reduxText[6576],  // Dynamic
        },
    ]
    if (reduxAuth.settings?.user?.is_staff) {
        modeOptions4.push({
            id: 4,
            name: 'Calculated',  // Calculated  // TO_TEXT
        })
        modeOptions4.push({
            id: 5,
            name: 'Template',  // Template  // TO_TEXT
        })
        modeOptions4.push({
            id: 6,
            name: 'Template Rich',  // Template  // TO_TEXT
        })
    }

    // TEXT_SHORT_MODE_CHOICES = (
    // 	(1, _('normal_only')),
    // 	(2, _('normal_or_long')),
    // 	(3, _('normal_or_dynamic')),
    // 	(4, _('normal_or_long_or_dynamic')),
    // 	(5, _('dynamic_only')),
    // )

    const new_text_short_mode = text_short_mode || inputObject?.text_short_mode

    let newModeOption: MainIdNameSerializer[] = []
    let enableModeOption = false
    if (new_text_short_mode && [2, 3, 4].includes(new_text_short_mode)) {
        if (new_text_short_mode === 2) {
            newModeOption = modeOptions2
            enableModeOption = true
        }
        if (new_text_short_mode === 3) {
            newModeOption = modeOptions3
        }
        if (new_text_short_mode === 4) {
            newModeOption = modeOptions4
        }
    }

    const newDynamicOption: any = (fieldsInline.content_source?.id && reduxFormSitedynamicTextOptions) ? (reduxFormSitedynamicTextOptions[fieldsInline.content_source.id] || []) : []

    const newDynamicOptionIndex = newDynamicOption.findIndex((val: MainIdNameSerializer) => val.id === fieldsInline.text_short)

    let tinymceStylesString = ''
    const tinymceStylesObject: any = {}

    const styles = fieldsMother.styles
    if (fieldsInline.text_short_mode?.id === 2 && styles) {
        Object.getOwnPropertyNames(styles.value).map((val: any) => {
            if (val === 'fontFamily') {
                if (styles.value[val]) {
                    tinymceStylesString += `font-family: ${styles.value[val]};`
                }
            }
            if ([
                'fontFamily',
            ].includes(val) && styles.value[val]) {
                tinymceStylesObject[val] = styles.value[val]
            }
            return false
        })
        if (fieldsInline.styles_original) {
            Object.getOwnPropertyNames(fieldsInline.styles_original.value).map((val: any) => {
                if ([
                    // 'background',
                    // 'color',
                    'defaultMargin',
                    'fontFamily',
                    'fontWeight',
                    'letterSpacing',
                    'lineHeight',
                    'textAlign',
                ].includes(val) && fieldsInline.styles_original?.value[val]) {
                    tinymceStylesObject[val] = fieldsInline.styles_original.value[val]
                    if (val === 'background') {
                        // tinymceStylesString += `background: ${fieldsInline.styles_original.value[val]};`
                    } else if (val === 'color') {
                        // tinymceStylesString += `color: ${fieldsInline.styles_original.value[val]};`
                    } else if (val === 'fontFamily') {
                        tinymceStylesString += `font-family: ${fieldsInline.styles_original.value[val]};`
                    } else if (val === 'fontWeight') {
                        tinymceStylesString += `font-weight: ${fieldsInline.styles_original.value[val]};`
                    } else if (val === 'letterSpacing') {
                        tinymceStylesString += `letter-spacing: ${fieldsInline.styles_original.value[val]};`
                    } else if (val === 'lineHeight') {
                        tinymceStylesString += `line-height: ${fieldsInline.styles_original.value[val]};`
                    } else if (val === 'textAlign') {
                        tinymceStylesString += `text-align: ${fieldsInline.styles_original.value[val]};`
                    }
                }
            })

            if (!tinymceStylesObject['fontFamily']) {
                // @ts-ignore
                tinymceStylesObject['fontFamily'] = mixedMedia ? (fieldsMother.portfolio_main?.styles?.value?.fontFamily || 'FuturaPT') : 'FuturaPT'
                // @ts-ignore
                tinymceStylesString += mixedMedia ? (`font-family: ${fieldsMother.portfolio_main?.styles?.value?.fontFamily};` || `font-family: FuturaPT;`) : `font-family: FuturaPT;`
            }
            tinymceStylesObject['background'] = 'rgb(255,255,255)'
            tinymceStylesObject['color'] = 'rgb(0,0,0)'
            tinymceStylesString += `background: rgb(255,255,255);`
            tinymceStylesString += `color: rgb(0,0,0);`
        }
    }

    return (
        <React.Fragment>
            {[2, 3, 4].includes(new_text_short_mode!) && (
                <SelectListInputV2
                    clearable={false}
                    disabled={(disabled && enableModeOption) ? false : disabled}
                    error=''
                    helperText={inputObject?.helper_text}
                    label={inputObject?.label || reduxText[6333]}
                    marginBottom={5}
                    name='text_short_mode'
                    onChange={onHandleInputChange}
                    options={newModeOption}
                    value={fieldsInline.text_short_mode || ''}
                />
            )}
            {(!fieldsInline.text_short_mode || (fieldsInline.text_short_mode?.id === 1)) && (  // Normal
                <TextAreaInputV2
                    disabled={disabledText}
                    error={errorsInline?.text_short}
                    helperText={!new_text_short_mode || new_text_short_mode === 1 ? (inputObject?.helper_text) : ''}
                    label={!new_text_short_mode || new_text_short_mode === 1 ? (inputObject?.label || reduxText[6333]) : ''}
                    marginBottom={10}
                    name='text_short'
                    onChange={onHandleInputChange}
                    onRefresh={onRefresh}
                    placeholder={(!fieldsInline.text_short && fieldsInline.action?.id === 'submit_form') ? (fieldsInline.parent_info?.is_newsletter ? reduxText[8709] : reduxText[8515]) : (fieldsInline.layout?.id === 802 ? reduxText[8612] : '')}
                    // @ts-ignore
                    translation={(fieldsMother.is_multi_language || fieldsMother.portfolio_main?.is_multi_language) ? {
                        contentType: mixedMedia ? 'mixedmediacontent' : 'pagecontent',
                        defaultDetailUrl: detailUrl,
                        defaultLanguage: fieldsInline.translation_default_language,
                        defaultUpdateUrl: updateUrl,
                        objectId: fieldsInline.id,
                        profileId: fieldsMother.profile?.id,
                    } : undefined}
                    value={fieldsInline.text_short || ''}
                />
            )}
            {fieldsInline.text_short_mode?.id === 2 && (  // Rich
                <RichTextInputV2
                    disabled={disabledText}
                    error={errorsInline?.text_long}
                    fonts={fonts}
                    marginBottom={10}
                    name='text_long'
                    objectId={fieldsInline.id}
                    onChange={onHandleInputChange}
                    onRefresh={onRefresh}
                    presetColors={fieldsMother?.preset_colors}
                    tinymceStylesObject={tinymceStylesObject}
                    tinymceStylesString={tinymceStylesString}
                    // @ts-ignore
                    translation={(fieldsMother.is_multi_language || fieldsMother.portfolio_main?.is_multi_language) ? {
                        contentType: mixedMedia ? 'mixedmediacontent' : 'pagecontent',
                        defaultDetailUrl: detailUrl,
                        defaultLanguage: fieldsInline.translation_default_language,
                        defaultUpdateUrl: updateUrl,
                        objectId: fieldsInline.id,
                        profileId: fieldsMother.profile?.id,
                    } : undefined}
                    value={fieldsInline.text_long || ''}
                />
            )}
            {fieldsInline.text_short_mode?.id === 3 && ![52, 53].includes(fieldsInline.content_source?.id!) && (  // Dynamic (52, 23 are External Data)
                <SelectListInputV2
                    clearable={false}
                    disabled={disabled}
                    error={errorsInline?.text_short}
                    marginBottom={5}
                    name='text_short'
                    onChange={(e: any) => onHandleInputChange({ name: 'text_short', value: e.value.id })}
                    options={(fieldsInline.content_source?.id && reduxFormSitedynamicTextOptions) ? reduxFormSitedynamicTextOptions[fieldsInline.content_source.id] : []}
                    optionsSorted
                    value={fieldsInline.text_short ? newDynamicOption[newDynamicOptionIndex] : ''}
                />
            )}
            {(fieldsInline.text_short_mode?.id === 3 && [52, 53].includes(fieldsInline.content_source?.id!)) && (  // Dynamic (52, 23 are External Data)
                <TextAreaInputV2
                    disabled={disabled}
                    error={errorsInline?.text_short}
                    helperText={!new_text_short_mode || new_text_short_mode === 1 ? (inputObject?.helper_text) : ''}
                    label={!new_text_short_mode || new_text_short_mode === 1 ? (inputObject?.label || reduxText[6333]) : ''}
                    marginBottom={10}
                    name='text_short'
                    onChange={onHandleInputChange}
                    onRefresh={onRefresh}
                    value={fieldsInline.text_short || ''}
                />
            )}
            {(fieldsInline.text_short_mode?.id === 4 && ![52, 53].includes(fieldsInline.content_source?.id!)) && (  // Dynamic (52, 23 are External Data)
                <TextAreaInputV2
                    disabled={disabled || !reduxAuth.settings?.user?.is_staff}
                    error={errorsInline?.text_short}
                    helperText={!new_text_short_mode || new_text_short_mode === 1 ? (inputObject?.helper_text) : ''}
                    label={!new_text_short_mode || new_text_short_mode === 1 ? (inputObject?.label || reduxText[6333]) : ''}
                    marginBottom={10}
                    name='text_short'
                    onChange={onHandleInputChange}
                    onRefresh={onRefresh}
                    value={fieldsInline.text_short || ''}
                />
            )}
            {(fieldsInline.text_short_mode?.id === 5 && ![52, 53].includes(fieldsInline.content_source?.id!)) && (  // Dynamic (52, 23 are External Data)
                <TextAreaInputV2
                    disabled={disabled || !reduxAuth.settings?.user?.is_staff}
                    error={errorsInline?.text_short}
                    helperText={!new_text_short_mode || new_text_short_mode === 1 ? (inputObject?.helper_text) : ''}
                    label={!new_text_short_mode || new_text_short_mode === 1 ? (inputObject?.label || reduxText[6333]) : ''}
                    marginBottom={10}
                    name='text_short'
                    onChange={onHandleInputChange}
                    onRefresh={onRefresh}
                    value={fieldsInline.text_short || ''}
                />
            )}
            {(fieldsInline.text_short_mode?.id === 6 && ![52, 53].includes(fieldsInline.content_source?.id!)) && (  // Dynamic (52, 23 are External Data)
                <RichTextInputV2
                    disabled={disabled}
                    error={errorsInline?.text_long}
                    fonts={fonts}
                    marginBottom={10}
                    name='text_long'
                    objectId={fieldsInline.id}
                    onChange={onHandleInputChange}
                    presetColors={fieldsMother?.preset_colors}
                    onRefresh={onRefresh}
                    tinymceStylesObject={tinymceStylesObject}
                    tinymceStylesString={tinymceStylesString}
                    // @ts-ignore
                    translation={(fieldsMother.is_multi_language || fieldsMother.portfolio_main?.is_multi_language) ? {
                        contentType: mixedMedia ? 'mixedmediacontent' : 'pagecontent',
                        defaultDetailUrl: detailUrl,
                        defaultLanguage: fieldsInline.translation_default_language,
                        defaultUpdateUrl: updateUrl,
                        objectId: fieldsInline.id,
                        profileId: fieldsMother.profile?.id,
                    } : undefined}
                    value={fieldsInline.text_long || ''}
                />
            )}
        </React.Fragment>
    )
})
