// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CheckboxChangeEventDetail,
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
    IonReorder,
    IonReorderGroup,
} from '@ionic/react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    Button,
    IconBlock,
    ListSpinner,
    Loader,
    SearchInput,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    ContentTypeFieldTypes,
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
    getFieldTitle,
} from 'services'

type ItemType = {
    columnName: string
    columnTitle: string
}

// props
type FieldSectionModalProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    exportColumns: {
        columnName: string
        columnTitle: string
        fieldOptions: ContentTypeFieldTypes | undefined
    }[]
    exportListApiUrl: string
    mode: 'export' | 'colums order'
    profile: ProfileDetailAdminSerializerV2 | undefined
    setCustomColumns: (customColumns: any[]) => void
    setIsOpen: React.Dispatch<React.SetStateAction<'export' | 'colums order' | undefined>>
    subContentTypeId: number | undefined
}

// main
export const FieldSectionModal: React.FC<FieldSectionModalProps> = React.memo(({
    contentType,
    contentTypeSettings,
    exportColumns,
    exportListApiUrl,
    mode,
    profile,
    setCustomColumns,
    setIsOpen,
    subContentTypeId,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [allSelected, setAllSelected] = useState<boolean>(false)
    const [isExportSendByMailOpen, setIsExportSendByMailOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [items, setItems] = useState<ItemType[]>([])
    const [itemsAll, setItemsAll] = useState<ItemType[]>([])
    const [processMessage, setProcessMessage] = useState('')
    const [selectedOptions, setSelectedOptions] = useState<ItemType[]>([])

    useEffect(() => {
        onGetListData()
    }, [])

    useEffect(() => {
        const newSelectedOptions: ItemType[] = []
        exportColumns.map((val) => {
            const index = itemsAll.findIndex((x) => x.columnName === val.columnName)
            if (index >= 0) newSelectedOptions.push(itemsAll[index])
            return false
        })
        setSelectedOptions(newSelectedOptions)
    }, [
        exportColumns,
        itemsAll,
    ])

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSectionModal',
                'doReorder',
            ))
        }
    }

    async function handleExport() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'FieldSectionModal handleExport')
            setProcessMessage(reduxText[9732])
            setIsProcessing(true)
            const postUrl = getApiUrlV2(`main/helper/export/`, reduxAuth)
            const formData = new FormData()
            formData.append('columns', JSON.stringify(selectedOptions))
            formData.append('api_list_url', exportListApiUrl)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                responseType: 'blob',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'export.xlsx') // Set the file name
                    document.body.appendChild(link)
                    link.click()
                    // @ts-ignore
                    link.parentNode.removeChild(link)
                    setIsProcessing(false)
                    setIsOpen(undefined)
                })
                .catch((error) => {
                    if (error.response.status === 502) {
                        handleExportByMail()
                    } else {
                        setIsProcessing(false)
                        axiosErrorHandler({
                            apiUrl: postUrl,
                            component: 'FieldSectionModal',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleExport',
                        })
                    }
                })
        } catch (error) {
            setIsProcessing(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSectionModal',
                'handleExport',
            ))
        }
    }

    async function handleExportByMail() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'FieldSectionModal handleExportByMail')
            const postUrl = getApiUrlV2(`main/helper/export/send_by_mail/`, reduxAuth)
            const formData = new FormData()
            formData.append('columns', JSON.stringify(exportColumns))
            formData.append('api_list_url', exportListApiUrl)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsProcessing(false)
                    setIsExportSendByMailOpen(true)
                    setIsOpen(undefined)
                })
                .catch((error) => {
                    setIsProcessing(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'FieldSectionModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleExportByMail',
                    })
                })
        } catch (error) {
            setIsProcessing(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSectionModal',
                'handleExportByMail',
            ))
        }
    }

    function onCheckboxChange(event: CustomEvent<CheckboxChangeEventDetail>) {
        try {
            let newSelectedOptions: ItemType[] = []
            const index = selectedOptions.findIndex(x => x.columnName === event.detail.value.columnName)
            if (event.detail.checked && index === -1) {
                newSelectedOptions = [...selectedOptions, event.detail.value]
                setSelectedOptions(newSelectedOptions)
            } else if (!event.detail.checked && index >= 0) {
                selectedOptions.map(val => {
                    if (val.columnName !== event.detail.value.columnName) newSelectedOptions.push(val)
                    return false
                })
                setSelectedOptions(newSelectedOptions)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSectionModal',
                'onCheckboxChange',
            ))
        }
    }

    function onGetListData() {
        try {
            let apiUrl = ''
            if (subContentTypeId) {
                apiUrl = getApiUrlV2(`setup/list/subcontenttype/available_field/${subContentTypeId}/`, reduxAuth)
            } else if (profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]?.meta?.id) {
                apiUrl = getApiUrlV2(`setup/list/contenttypesettingsprofile/available_field/${profile.settings_data_json.contentTypeSettingsCustom[contentType]?.meta?.id}/`, reduxAuth)
            } else {
                apiUrl = getApiUrlV2(`setup/list/contenttypesettings/available_field/${contentTypeSettings.meta.id}/`, reduxAuth)
            }
            getDetailObject({
                apiUrl,
                component: 'FieldSectionModal',
                dispatch,
                reduxAuth,
                setDetailObject: (data: any) => {
                    const cleanItems: ItemType[] = []
                    data.map((val: any) => {
                        const getFieldTitleResult = getFieldTitle({
                            column: val,
                            contentType,
                            contentTypeSettings,
                            profile: profile,
                            reduxText: reduxText,
                        })
                        if (val.includes('_json')) return false
                        // const fieldOptions = contentTypeSettings.field?.[val.split('__')[0]]
                        // if (fieldOptions?.hideIfNotStaff && !reduxAuth.settings?.user?.is_staff) return null
                        // if (fieldOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) return null
                        cleanItems.push({
                            columnName: val,
                            columnTitle: getFieldTitleResult.columnTitle,
                        })
                        return false
                    })
                    const defaultColumns = [
                        {
                            'columnName': 'id',
                            'columnTitle': 'ID',
                        },
                        {
                            'columnName': 'created_on',
                            'columnTitle': reduxText[5493],
                        },
                    ]
                    if (reduxAuth.settings?.user?.id === 1) {
                        defaultColumns.push({
                            'columnName': 'sub_content_type',
                            'columnTitle': reduxText[9913],
                        })
                        defaultColumns.push({
                            'columnName': 'has_translation',
                            'columnTitle': reduxText[9387],
                        })
                        defaultColumns.push({
                            'columnName': 'module_views',
                            'columnTitle': reduxText[9968],
                        })
                    }
                    const newCleanItems = [...cleanItems, ...defaultColumns]
                    const sortedItems = newCleanItems.sort((a, b) => a.columnTitle.localeCompare(b.columnTitle))
                    setItems(sortedItems)
                    setItemsAll(sortedItems)
                },
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSectionModal',
                'onGetListData',
            ))
        }
    }

    function onSearch(searchTerm: string | undefined) {
        try {
            if (searchTerm) {
                if (reduxAuth.settings?.user?.id === 1) {
                    setItems(itemsAll.filter(item => item.columnTitle.toLowerCase().includes(searchTerm.toLowerCase()) || item.columnName.toLowerCase().includes(searchTerm.toLowerCase())))
                } else {
                    setItems(itemsAll.filter(item => item.columnTitle.toLowerCase().includes(searchTerm.toLowerCase())))
                }
            } else {
                setItems(itemsAll)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSectionModal',
                'onSearch',
            ))
        }
    }

    function onSelectAll() {
        try {
            let newSelectedOptions: ItemType[] = []
            newSelectedOptions = [...selectedOptions]
            if (allSelected) {
                newSelectedOptions = []
            } else {
                itemsAll.map((val) => {
                    const index = selectedOptions.findIndex(x => x.columnName === val.columnName)
                    if (index === -1) {
                        newSelectedOptions.push(val)
                    }
                    return false
                })
            }
            setAllSelected(!allSelected)
            setSelectedOptions(newSelectedOptions)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSectionModal',
                'onSelectAll',
            ))
        }
    }

    function onSelectRemove(columnName: string) {
        try {
            const newSelectedOptions: ItemType[] = []
            selectedOptions.map(val => {
                if (val.columnName !== columnName) newSelectedOptions.push(val)
                return false
            })
            setSelectedOptions(newSelectedOptions)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSectionModal',
                'onSelectRemove',
            ))
        }
    }

    function onSortEnd(event: any) {
        try {
            setSelectedOptions(arrayMoveImmutable(selectedOptions, event.detail.from, event.detail.to))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSectionModal',
                'onSortEnd',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class inline-form half content-list-modal-portfolio'
            classes={{ paper: 'mo-dialog-wrapper' }}
            fullWidth
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(undefined)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {mode === 'export' ? reduxText[9834] : reduxText[10044]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(undefined)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className='mo-dialog-content no-padding'>
                <div className={`mo-dialog-content-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    <div className='mo-dialog-searchbar-wrap'>
                        <SearchInput
                            classNameWrap='bg1'
                            onChange={(e) => onSearch(e)}
                            placeholder={reduxText[282]}
                        />
                    </div>
                    <div style={{
                        borderBottom: '5px solid var(--mo-color-ln)',
                    }}>
                        <IonItem>
                            <IonLabel className='clmp-label'>
                                <h3>{reduxText[10064]}</h3>
                            </IonLabel>
                            <IonCheckbox
                                checked={allSelected}
                                onIonChange={onSelectAll}
                                slot='end'
                            />
                        </IonItem>
                    </div>
                    {isLoading && <ListSpinner isLoading />}
                    <IonList>
                        {items.map((item) => {
                            return (
                                <IonItem key={item.columnName}>
                                    <IonLabel className='clmp-label'>
                                        <h3>{item.columnTitle}</h3>
                                        {reduxAuth.settings?.user?.id === 1 && (<p className='clmp-profile'>{item.columnName}</p>)}
                                    </IonLabel>
                                    <IonCheckbox
                                        checked={selectedOptions.findIndex((x: any) => x === item) >= 0}
                                        onIonChange={(e) => onCheckboxChange(e)}
                                        slot='end'
                                        value={item}
                                    />
                                </IonItem>
                            )
                        })}
                    </IonList>
                </div>
                <div className={`mo-dialog-content-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    <IonReorderGroup
                        disabled={false}
                        onIonItemReorder={doReorder}
                    >
                        {selectedOptions.map(item => {
                            return (
                                <IonItem key={item.columnName}>
                                    <IonLabel className='clmp-label'>
                                        <h3>{item.columnTitle}</h3>
                                        {reduxAuth.settings?.user?.id === 1 && (<p className='clmp-profile'>{item.columnName}</p>)}
                                    </IonLabel>
                                    <i
                                        className='clmp-label-icon mo-new-icon-circle-xmark-regular mo-cursor'
                                        onClick={() => onSelectRemove(item.columnName)}
                                    />
                                    <IonReorder slot='end' />
                                </IonItem>
                            )
                        })}
                    </IonReorderGroup>
                </div>
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen(undefined)}
                    text={reduxText[4519]}
                />
                <Button
                    edit={false}
                    fill='outline'
                    onClick={mode === 'export' ? handleExport : () => {
                        setCustomColumns(selectedOptions)
                        setIsOpen(undefined)
                    }}
                    text={mode === 'export' ? reduxText[10043] : reduxText[3563]}
                />
            </DialogActions>
            {isExportSendByMailOpen && (
                <Alert
                    buttons={[
                        {
                            text: reduxText[5614],
                        },
                    ]}
                    header={reduxText[9778]}
                    isOpen
                    message={`${reduxText[9779]} ${reduxAuth.settings?.user?.email}.`}
                    onDidDismiss={() => setIsExportSendByMailOpen(false)}
                />
            )}
            {isProcessing && (
                <Loader
                    isOpen
                    message={processMessage}
                />
            )}
        </Dialog>
    )
})
