// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    LanguageFieldInput,
    PortfolioDetailPageFieldInput,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    DescriptionMaterialInput,
    MediumTypeMaterialInput,
    PriceMaterialInput,
} from 'pages'

// serializers
import {
    MaterialFormState,
} from 'serializers/web'

// props
type AdditionalMaterialPanelProps = {
    formState: MaterialFormState
    handleInputChange: any
}

// main
export const AdditionalMaterialPanel: React.FC<AdditionalMaterialPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdditionalMaterialPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <PriceMaterialInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <DescriptionMaterialInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <MediumTypeMaterialInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <PortfolioDetailPageFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
                show={fieldsMother.medium_type?.id !== 26}
            />
            <LanguageFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
