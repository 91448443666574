// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type RadioInputProps = {
    adminOnly?: boolean
    apiUrl?: string | undefined
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label: string
    name: string
    onChange: any
    optional?: boolean
    options?: any[]
    staffOnly?: boolean
    value: any
}

// main
export const RadioInput: React.FC<RadioInputProps> = React.memo(({
    adminOnly,
    error,
    apiUrl,
    disabled,
    helperText,
    label,
    name,
    onChange,
    optional,
    options,
    staffOnly,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        getData()
    }, [])

    const [newOptions, setNewOptions] = useState<any[]>(options || [])

    async function getData() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'RadioInput getData')
            if (options) return
            if (!apiUrl) return
            const listArray: any = []
            const getUrl = getApiUrl(apiUrl, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    response.data.map((val: any) => {
                        listArray.push(val)
                        return false
                    })
                    setNewOptions(listArray)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'RadioInput',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'useEffect',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RadioInput',
                'getData',
            ))
        }
    }

    function onInputChange(value: any) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RadioInput',
                'onInputChange',
            ))
        }
    }

    return (
        <div className={`mo-radio-input-web${disabled ? ' disabled' : ''}`}>
            <MainFormLabel
                adminOnly={adminOnly}
                helperText={helperText}
                label={label}
                name={name}
                optional={optional}
                staffOnly={staffOnly}
            />
            <IonList>
                <IonRadioGroup
                    onIonChange={(e) => onInputChange(e.detail.value)}
                    value={value}
                >
                    {newOptions.map((val: any) => (
                        <IonItem
                            key={apiUrl ? val.id : val.value}
                            className='mriw-item'
                            lines='none'
                        >
                            <IonLabel>
                                {apiUrl ? val.name : val.text}
                            </IonLabel>
                            <IonRadio
                                slot='start'
                                value={apiUrl ? val : val.value}
                            />
                        </IonItem>
                    ))}
                </IonRadioGroup>
            </IonList>
            <ErrorHelper error={error} />
        </div>
    )
})
