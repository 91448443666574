// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ActionComponent,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    TableColumn,
} from 'pages'

// serializers
import {
    ContentTypeSettingsCustomTypes,
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getApiUrlV2,
    getDetailObject,
} from 'services'

// props
type TableRowProps = {
    addProfileColumn?: boolean
    checkboxAddRemove?: any
    checkboxArray?: number[]
    checkboxShow?: boolean
    columns: string[]
    columnsCustom?: string[]
    componentActionSheet?: string
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    defaultFields: any
    inModal?: boolean
    modalKey: number
    object: any
    profileObject: ProfileDetailAdminSerializerV2 | undefined
    refreshData?: () => void
    setFilter?: (e: any, f?: any) => void
}

// main
export const TableRow: React.FC<TableRowProps> = React.memo(({
    addProfileColumn,
    checkboxAddRemove,
    checkboxArray,
    checkboxShow,
    columns,
    columnsCustom,
    componentActionSheet,
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    defaultFields,
    inModal,
    modalKey,
    object,
    profileObject,
    refreshData,
    setFilter,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<any>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    const app = contentType.split('_')[0]
    const model = contentType.split('_')[1]
    const extraAutoColumns = []
    if (addProfileColumn) {
        extraAutoColumns.push('profile', 'id')
    }
    const newColumns = [...(columnsCustom || columns), ...extraAutoColumns]

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`${app}/detail/${model}/${detailObject.id}/?fields=${newColumns.join(',')}`, reduxAuth),
                component: 'TableRow',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TableRow',
                'onGetDetailData',
            ))
        }
    }

    let conditionStyles: any = {}

    if (contentTypeSettingsCustom?.list?.rowStyle) {
        // TODO field dynamic
        const conditionIsTrue = String(contentTypeSettingsCustom?.list?.rowStyle?.['is_duplicate'].rowStyleCondition) === String(detailObject['is_duplicate'])
        if (conditionIsTrue) {
            conditionStyles = contentTypeSettingsCustom?.list?.rowStyle?.['is_duplicate'].rowStyleIfConditionTrue
        }
    }

    return (
        <tr
            className='table-row'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            <td
                className='tr-action'
                style={conditionStyles}
            >
                <div className='tr-action-div'>
                    {checkboxShow && (
                        <div className='tr-action-checkbox'>
                            <input
                                checked={checkboxArray?.includes(detailObject.id)}
                                onChange={e => checkboxAddRemove(detailObject.id, !e.target.checked)}
                                type='checkbox'
                            />
                        </div>
                    )}
                    {componentActionSheet && (
                        <ActionComponent
                            conditionStyles={conditionStyles}
                            contentType={contentType}
                            contentTypeSettings={contentTypeSettings}
                            defaultFields={defaultFields}
                            edit={false}
                            inModal={inModal}
                            modalKey={modalKey}
                            object={detailObject}
                            refreshData={onGetDetailData}
                            refreshList={refreshData}
                            setDeleted={setDeleted}
                            setShowActionPopover={setShowActionPopover}
                            showActionPopover={showActionPopover}
                        />
                    )}
                </div>
            </td>
            {newColumns.map(val => (
                <TableColumn
                    key={val}
                    addProfileColumn={addProfileColumn}
                    conditionStyles={conditionStyles}
                    contentType={contentType}
                    contentTypeSettings={contentTypeSettings}
                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                    field={val}
                    modalKey={modalKey}
                    object={detailObject}
                    profileObject={profileObject}
                    refreshData={onGetDetailData}
                    setFilter={setFilter}
                />
            ))}
        </tr>
    )
})
