// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Loader,
    SelectAsyncInput,
} from 'components'

// data
import {
    contentTypeFontUrl,
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModaSetDeviceTypeSite,
    reduxModalErrorEventHandler,
    select_url_main_font,
} from 'data'

// pages
import {
    StyleInputHelperNew,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
} from 'serializers/site'
import {
    ProjectFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type StyleMixedPanelProps = {
    contentType: contentTypeModelType
    formState: ProjectFormState
    handleInputChange: any
    setFormState: React.Dispatch<ProjectFormState>
}

// main
export const StyleMixedPanel: React.FC<StyleMixedPanelProps> = React.memo(({
    formState,
    handleInputChange,
    setFormState,
    contentType,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormcssProperties = useSelector((state: defaultReduxState) => state.reduxForm.cssProperties)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const mother = formState.mother

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [isSaving, setIsSaving] = useState(false)

    function onHandleInputChange(value: any, valueRef: 'value' | 'value_MD' | 'value_SM') {
        try {
            if (mother.fields) {

                const toReturnValue = Object.assign({}, mother.fields?.styles_original || {
                    value: {},
                    value_MD: {},
                    value_SM: {},
                })

                toReturnValue[valueRef] = {
                    ...toReturnValue[valueRef],
                    [value.name]: value.value,
                }

                const forPreviewValue: MainStyleSerializer = {
                    value: {},
                    value_MD: {},
                    value_SM: {},
                }

                const styles_array: string[] = []
                Object.getOwnPropertyNames(toReturnValue.value).map((val) => {
                    styles_array.push(val)
                    return false
                })
                Object.getOwnPropertyNames(toReturnValue.value_MD).map((val) => {
                    styles_array.push(val)
                    return false
                })
                Object.getOwnPropertyNames(toReturnValue.value_SM).map((val) => {
                    styles_array.push(val)
                    return false
                })
                styles_array.map((val) => {
                    // @ts-ignore
                    const value_LG = toReturnValue.value[val]
                    // @ts-ignore
                    const value_MD = !['', 'inherit', undefined, null].includes(toReturnValue.value_MD[val]) ? toReturnValue.value_MD[val] : value_LG
                    // @ts-ignore
                    const value_SM = !['', 'inherit', undefined, null].includes(toReturnValue.value_SM[val]) ? toReturnValue.value_SM[val] : (value_MD || value_LG)
                    // @ts-ignore
                    forPreviewValue.value[val] = value_LG
                    // @ts-ignore
                    forPreviewValue.value_MD[val] = value_MD
                    // @ts-ignore
                    forPreviewValue.value_SM[val] = value_SM
                })

                // Update in preview
                handleInputChange({
                    name: 'styles_original',
                    value: toReturnValue,
                    preview: forPreviewValue,
                })

                // // Update in content
                // handleInputChange({
                // 	name: 'styles_original',
                // 	value: toReturnValue,
                // })

                // // Save to temporary object to save
                // setStylesToUpdate({
                // 	...stylesToUpdate,
                // 	[style.id]: {
                // 		...stylesToUpdate[style.id],
                // 		[valueRef]: value.value,
                // 	}
                // })
                // // Show style in preview and Update in mother
                // setFormState({
                // 	...formState,
                // 	mother: {
                // 		...mother,
                // 		fields: toReturnFields,
                // 	}
                // })
                setButtonDisabled(false)
                // if (!reduxFormSite.editingForm) dispatch(reduxFormSetEditingSite(true))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StyleMixedPanel',
                'onHandleInputChange',
            ))
        }
    }

    async function onHandleSubmit() {
        try {
            setIsSaving(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'StyleMixedPanel onHandleSubmit')

            const formData = new FormData()
            const postUrl = getApiUrl(`${contentTypeFontUrl[contentType]}${mother.fields?.id}/`, reduxAuth)
            mother.fields?.mixed_media_fonts?.map((val) => {
                formData.append('mixed_media_fonts', `${val.id}`)
            })
            formData.append('styles_original', JSON.stringify(mother.fields?.styles_original))
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsSaving(false)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    setButtonDisabled(true)
                    dispatch(reduxFormSetEditingSite(false))
                    dispatch(reduxFormSetRefresh('refreshForm'))
                })
                .catch((error) => {
                    setIsSaving(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'StyleMixedPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onHandleSubmit',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StyleMixedPanel',
                'onHandleSubmit',
            ))
        }
    }

    // Mohter
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother?.id) return null

    return (
        <div className={`style-mixed-panel-web mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className='smpw-device'>
                <div
                    className={`smpw-device-div ${deviceType === 'is-web' && 'active'}`}
                    onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-web'))}
                >
                    <i className='icon mo-new-icon-desktop-solid' />
                </div>
                <div
                    className={`smpw-device-div ${deviceType === 'is-tablet' && 'active'}`}
                    onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-tablet'))}
                >
                    <i className='icon mo-new-icon-tablet-alt-solid' />
                </div>
                <div
                    className={`smpw-device-div ${deviceType === 'is-mobile' && 'active'}`}
                    onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-mobile'))}
                >
                    <i className='icon mo-new-icon-mobile-alt-solid' />
                </div>
            </div>
            <div className={`smpw-style-wrap mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='pfp-css-style-div'>
                    {deviceType === 'is-web' && (
                        <SelectAsyncInput
                            apiUrl={`${select_url_main_font}?profile_id=${fieldsMother.profile?.id}`}
                            clearable={false}
                            error={errorsMother?.mixed_media_fonts}
                            helperText={reduxText[627610]}
                            label={reduxText[6276]}
                            multi
                            name='mixed_media_fonts'
                            onChange={(e: any) => {
                                setButtonDisabled(false)
                                handleInputChange(e)
                            }}
                            optional
                            value={fieldsMother.mixed_media_fonts || ''}
                        />
                    )}
                    {reduxFormcssProperties?.map((val) => {
                        if (deviceType === 'is-web' && (val.is_global_mixed_media || fieldsMother.styles_original?.value?.[val.css_class])) {
                            return (
                                <StyleInputHelperNew
                                    key={`${deviceType}-${val.id}-${fieldsMother.id}`}
                                    css_property={val}
                                    mixedMedia
                                    onChange={(e: any) => onHandleInputChange(e, 'value')}
                                    presetColors={fieldsMother?.preset_colors}
                                    value={fieldsMother.styles_original?.value?.[val.css_class] || ''}
                                    value_type='LG'
                                />
                            )
                        }
                        if (deviceType === 'is-tablet' && (val.is_global_mixed_media || fieldsMother.styles_original?.value_MD?.[val.css_class])) {
                            return (
                                <StyleInputHelperNew
                                    key={`${deviceType}-${val.id}-${fieldsMother.id}`}
                                    css_property={val}
                                    mixedMedia
                                    onChange={(e: any) => onHandleInputChange(e, 'value_MD')}
                                    presetColors={fieldsMother?.preset_colors}
                                    value={fieldsMother.styles_original?.value_MD?.[val.css_class] || ''}
                                    value_LG={fieldsMother.styles_original?.value?.[val.css_class] || ''}
                                    value_type='MD'
                                />
                            )
                        }
                        if (deviceType === 'is-mobile' && (val.is_global_mixed_media || fieldsMother.styles_original?.value_SM?.[val.css_class])) {
                            return (
                                <StyleInputHelperNew
                                    key={`${deviceType}-${val.id}-${fieldsMother.id}`}
                                    css_property={val}
                                    mixedMedia
                                    onChange={(e: any) => onHandleInputChange(e, 'value_SM')}
                                    presetColors={fieldsMother?.preset_colors}
                                    value={fieldsMother.styles_original?.value_SM?.[val.css_class] || ''}
                                    value_LG={fieldsMother.styles_original?.value?.[val.css_class] || ''}
                                    value_MD={fieldsMother.styles_original?.value_MD?.[val.css_class] || ''}
                                    value_type='SM'
                                />
                            )
                        }
                    })}
                </div>
            </div>
            <div className='smpw-footer'>
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    expand='full'
                    fill='outline'
                    onClick={() => onHandleSubmit()}
                    text={reduxText[4520]}
                />
            </div>
            {isSaving && (
                <Loader isOpen />
            )}
        </div>
    )
})
