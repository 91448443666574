// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Checkbox,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    MixedPanel779,
    MixedPanel780,
    MixedPanel783,
    MixedPanel799,
    MixedPanel818,
    InputHelperPageContent,
} from 'pages'

// serializers
import {
    ProjectFormState,
} from 'serializers/web'

// services
import {
    actionFunction,
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getMainErrorMessage,
} from 'services'

// props
type DetailMixedContentPanelProps = {
    formState: ProjectFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    mixedMediaContentType: contentTypeModelType | undefined
    newMixedMediaContent: boolean
    onGetData: any
    setFormState: React.Dispatch<ProjectFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    setTabValue: React.Dispatch<string>
}

// main
export const DetailMixedContentPanel: React.FC<DetailMixedContentPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    mixedMediaContentType,
    newMixedMediaContent,
    onGetData,
    setFormState,
    setHelpers,
    setTabValue,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isMarkedAsToDo, setIsMarkedAsToDo] = useState<boolean>(formState.mixedMediaContent?.fields?.is_marked_as_to_do!)

    const mother = formState.mother
    const mixedMediaContent = formState.mixedMediaContent

    useEffect(() => {
        setIsMarkedAsToDo(formState.mixedMediaContent?.fields?.is_marked_as_to_do!)
    }, [
        formState.mixedMediaContent?.fields?.is_marked_as_to_do!,
    ])

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mixedMediaContent)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailMixedContentPanel',
                'onHandleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: mixedMediaContent,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    mixedMediaContent: {
                        ...formState.mixedMediaContent,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailMixedContentPanel',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DetailMixedContentPanel handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            const axiosUrl = `${mixedMediaContent.updateUrl}${mixedMediaContent.fields?.id}/`
            const formData: any = getFormData(mixedMediaContent)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    dispatch(reduxFormSetRefresh('refreshPreview', fieldsMother?.id))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        mixedMediaContent: {
                            ...formState.mixedMediaContent,
                            errors: {},
                            fields: {
                                ...mixedMediaContent.fields,
                                id: response.data.id,
                            },
                        }
                    })
                    dispatch(reduxFormSetEditingSite(false))
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: reduxText[4798],
                    })
                    setFormState({
                        ...formState,
                        mixedMediaContent: {
                            ...formState.mixedMediaContent,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'DetailMixedContentPanel',
                        dispatch,
                        error,
                        formFields: JSON.stringify(mixedMediaContent.fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailMixedContentPanel',
                'handleSubmit',
            ))
        }
    }

    function onRefresh() {
        dispatch(reduxFormSetRefresh('refreshPreview', fieldsMother?.id))
    }

    // Mother
    const fieldsMother = mother.fields

    // Content
    const errorsInline = mixedMediaContent.errors
    const fieldsInline = mixedMediaContent.fields

    if (!fieldsMother || !fieldsInline || !fieldsInline.layout) return null

    const components: any = {
        MixedPanel779,
        MixedPanel780,
        MixedPanel783,
        MixedPanel799,
        MixedPanel818,
    }

    const CustomMixedPanelName = components[`MixedPanel${fieldsInline.layout.id}`]

    return (
        <React.Fragment>
            <div className={`cmpw-fields mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                {reduxAuth.settings?.user?.is_staff && (
                    <div className='cmpw-content-links'>
                        <div className='cmpw-inherit'>
                            <Checkbox
                                checked={isMarkedAsToDo}
                                onChange={() => actionFunction({
                                    component: 'DetailPageContentPortfolioPanel',
                                    contentType: 'project_mixedmediacontent',
                                    dispatch,
                                    function_name: isMarkedAsToDo ? 'action_function_unmark_as_to_do' : 'action_function_mark_as_to_do',
                                    objectId: fieldsInline.id!,
                                    reduxAuth,
                                    refreshData: () => setIsMarkedAsToDo(!isMarkedAsToDo),
                                })}
                            />
                            <span className='cmpw-inherit-text'>{isMarkedAsToDo ? 'Unmark TODO' : 'Mark TODO'}</span>
                        </div>
                        {reduxAuth.settings?.user?.id === 1 && (
                            <div>
                                <a
                                    className='cmpw-link'
                                    href={`https://api.justtheweb.io/en/admin/project/mixedmediacontent/${mixedMediaContent.fields?.id}/change/`}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    Prod
                                </a>
                                <a
                                    className='cmpw-link'
                                    href={`http://localhost:8000/en/admin/project/mixedmediacontent/${mixedMediaContent.fields?.id}/change/`}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    Dev
                                </a>
                            </div>
                        )}
                    </div>
                )}
                {CustomMixedPanelName
                    ? (
                        <CustomMixedPanelName
                            detailUrl={mixedMediaContent.detailUrl}
                            formState={formState}
                            handleInputChange={onHandleInputChange}
                            mixedMediaContentType={mixedMediaContentType}
                            onRefresh={onRefresh}
                            updateUrl={mixedMediaContent.updateUrl}
                        />
                    ) : (
                        fieldsInline.layout?.form_fields?.map((val) => {
                            return (
                                <InputHelperPageContent
                                    key={val.id}
                                    component={val.field!}
                                    detailUrl={mixedMediaContent.detailUrl}
                                    errorsInline={errorsInline}
                                    fieldsInline={fieldsInline}
                                    fieldsMother={fieldsMother}
                                    fonts={fieldsMother.fonts ? fieldsMother.fonts?.concat(fieldsMother.portfolio_main?.fonts || []) : fieldsMother.portfolio_main?.fonts}
                                    inputObject={val}
                                    mixedMedia
                                    mixedMediaContentType={mixedMediaContentType}
                                    onHandleInputChange={onHandleInputChange}
                                    onRefresh={onRefresh}
                                    optional={false}
                                    updateUrl={mixedMediaContent.updateUrl}
                                />
                            )
                        })
                    )
                }
            </div>
            <div className='cmpw-footer'>
                {(helpers.buttonDisabled && !helpers.isSaving)
                    ? (
                        <Button
                            edit={false}
                            expand='full'
                            fill='outline'
                            text={newMixedMediaContent ? 'Exit' : reduxText[7676]}
                            to={newMixedMediaContent ? `/detail/${mixedMediaContentType}/${fieldsMother.id}/` : `${mother.formUrl}${fieldsMother.id}/`}
                        />
                    ) : (
                        <Button
                            disabled={helpers.buttonDisabled}
                            edit={false}
                            expand='full'
                            fill='outline'
                            onClick={handleRequired}
                            text={reduxText[6378]}
                        />
                    )}
            </div>
        </React.Fragment>
    )
})
