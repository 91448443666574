// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    contentTypeModelType,
    errorMessageToSkip,
    reduxAuthState,
    reduxModalSliderSetData,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

export const listStateInitialWeb = {
    clusterIds: undefined,
    componentString: '',
    contentTypeString: undefined,
    count: undefined,
    disableInfiniteScroll: true,
    hasMore: true,
    ids_to_lahoun: '0',
    isLoading: true,
    items: [],
    nextUrl: '',
}

// types
export interface listStatePropsWeb {
    clusterIds: number[] | undefined
    componentString: string
    contentTypeString: contentTypeModelType | undefined
    count: number | undefined
    disableInfiniteScroll: boolean
    hasMore: boolean
    ids_to_lahoun: string
    isLoading: boolean
    items: any[]
    modalKey: number
    nextUrl: string
}

// main
export async function getInfiniteListWebNew({
    apiUrl,
    axiosCancelToken,
    changingView,
    component,
    componentName,
    dispatch,
    listState,
    noClusterIds,
    noEmptyItems,
    noHeaders,
    onSuccess,
    reduxAuth,
    setAxiosCancelToken,
    setComponent,
    setListState,
}: {
    apiUrl: string
    axiosCancelToken: CancelTokenSource | undefined
    changingView: boolean
    component: string
    componentName: string
    dispatch: React.Dispatch<any>
    listState: listStatePropsWeb
    noClusterIds?: boolean
    noEmptyItems?: boolean
    noHeaders?: boolean
    onSuccess?: (e: any, items: any[]) => void
    reduxAuth: reduxAuthState
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setComponent?: boolean
    setListState: React.Dispatch<listStatePropsWeb>
}) {

    if (!apiUrl) return

    if (changingView) {
        if (noEmptyItems) {
            setListState({
                ...listState,
                count: undefined,
                disableInfiniteScroll: true,
                hasMore: true,
                isLoading: true,
                nextUrl: '',
            })
        } else {
            setListState({
                ...listState,
                count: undefined,
                disableInfiniteScroll: true,
                hasMore: true,
                isLoading: true,
                items: [],
                nextUrl: '',
            })
        }
    } else {
        setListState({
            ...listState,
            disableInfiniteScroll: true,
            isLoading: true,
        })
    }

    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getInfiniteListWebNew`)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    if (process.env.NODE_ENV === 'development') console.log(apiUrl, component)

    const formData: any = new FormData()
    if (!noClusterIds && listState.clusterIds) {
        formData.append('ids_cluster', listState.clusterIds?.join(','))
    }

    axios({
        data: formData,
        headers: noHeaders ? undefined : refreshAxiosHeaders,
        method: (!noClusterIds && listState.clusterIds?.length! > 0) ? 'post' : 'get',
        url: apiUrl,
        cancelToken: source.token,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            const dataToReturn = changingView ? response.data.results : [...listState.items, ...response.data.results]
            setListState({
                ...listState,
                componentString: setComponent ? (dataToReturn[0] ? dataToReturn[0].component_name : listState.componentString) : componentName,  // TODO might be error if change sectionObject Type and return initial 0, wrong componentString will be sent
                contentTypeString: response.data.content_type,
                count: response.data.count,
                disableInfiniteScroll: response.data.next === null,
                hasMore: response.data.next !== null,
                isLoading: false,
                items: dataToReturn,
                nextUrl: response.data.next,
            })
            dispatch(reduxModalSliderSetData(listState.modalKey, dataToReturn, apiUrl))
            if (onSuccess) onSuccess(response.data, dataToReturn)
        })
        .catch((error) => {
            if (error.message === 'axios canceled') {
                if (process.env.NODE_ENV === 'development') console.log(error)
                return
            }
            if (errorMessageToSkip.includes(error.message)) {
                setListState({
                    ...listState,
                    disableInfiniteScroll: false,
                    isLoading: false,
                })
            } else if (errorMessageToSkip.includes(error)) {
                setListState({
                    ...listState,
                    disableInfiniteScroll: false,
                    isLoading: false,
                })
            } else {
                setListState({
                    ...listState,
                    disableInfiniteScroll: true,
                    isLoading: false,
                })
            }
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'getInfiniteListWebNew',
                skipSendError: (error.response?.status === 404) && (['Invalid page.', 'Page non valide.', 'Not found.', 'Pas trouv�.'].includes(error.response?.data?.detail)),
            })
        })
}
