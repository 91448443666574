// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    SketchPicker,
} from 'react-color'
import {
    GradientPickerPopover,
} from 'react-linear-gradient-picker'
import {
    useDispatch,
} from 'react-redux'

import 'react-linear-gradient-picker/dist/index.css'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

/**
 * (c) https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
 */
function addOpacityToHex(hex, a = 1) {
    var c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = `${'0x'}${c.join('')}`
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${a})`
    }
    if (/rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/.test(hex)) { /** RGB color */
        return hex.replace('rgb', 'rgba').replace(')', `,${a})`)
    }
    throw new Error('Bad Hex')
}

const WrappedSketchPicker = ({ onSelect, presetColors, ...rest }) => (
    <SketchPicker {...rest}
        color={addOpacityToHex(rest.color, rest.opacity)}
        onChange={c => {
            if (c.hex.toLowerCase() === 'transparent') {
                onSelect('#000000', 0)
            } else {
                onSelect(c.hex, c.rgb.a)
            }
        }}
        presetColors={presetColors ? ['#000000', '#FFFFFF', ...presetColors] : ['#000000']}
    />
)

const initialPallet = [
    { offset: '0.00', color: 'rgb(238,241,11)' },
    { offset: '1.00', color: 'rgb(126,32,207)' },
]

export const GradientInputPortfolio = (props) => {

    const dispatch = useDispatch()

    useEffect(() => {
        getInitialValue(props.value)
    }, [])

    const [open, setOpen] = useState(false)
    const [angle, setAngle] = useState(180)
    const [palette, setPalette] = useState(initialPallet)

    function onColorChange(e) {
        try {
            setPalette(e)
            sendValue(angle, e)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'GradientInputPortfolio',
                'onColorChange',
            ))
        }
    }

    function onSetOpen(e) {
        try {
            setOpen(e)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'GradientInputPortfolio',
                'onColorChange',
            ))
        }
    }

    function onAngleChange(e) {
        try {
            setAngle(e)
            sendValue(e, palette)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'GradientInputPortfolio',
                'onAngleChange',
            ))
        }
    }

    function sendValue(deg, colorArray) {
        try {
            props.onChange(`linear-gradient(${deg}deg,${colorArray.map((val) => (`${addOpacityToHex(val.color, val.opacity)}${(val.offset * 100).toFixed(1)}%`)).join(',')}`)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'GradientInputPortfolio',
                'sendValue',
            ))
        }
    }

    function getInitialValue(value) {
        try {
            const test2 = value.split('linear-gradient(')
            if (test2[1]) {
                const test3 = test2[1].substring(0, test2[1].length - 1).split('rgba(')
                const testArray = []
                test3.map((val) => {
                    const test4 = val.split(')')
                    if (test4[1]) {
                        const test5 = test4[0].split(',')
                        const test6 = test4[1].split(',')
                        testArray.push({
                            color: `rgb(${test5[0]},${test5[1]},${test5[2]})`,
                            offset: (Number(test6[0].split('%')[0]) / 100).toString(),
                            opacity: Number(test5[3]),
                        })
                    }
                    return false
                })
                setPalette(testArray)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'GradientInputPortfolio',
                'getInitialValue',
            ))
        }
    }

    return (
        <GradientPickerPopover {...{
            open,
            setOpen: onSetOpen,
            angle,
            setAngle: onAngleChange,
            showAnglePicker: true,
            width: 220,
            maxStops: 4,
            paletteHeight: 32,
            palette,
            onPaletteChange: onColorChange
        }}>
            <WrappedSketchPicker
                presetColors={props.presetColors}
            />
        </GradientPickerPopover>
    )
}
