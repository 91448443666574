// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    errorMessageToSkip,
    reduxAuthState,
    reduxModalSliderSetData,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
    getShuffleArray,
    listStatePropsWeb,
} from 'services'


export async function getInfiniteRandomListWebNew({
    apiUrl,
    axiosCancelToken,
    changingView,
    component,
    componentName,
    dispatch,
    listState,
    reduxAuth,
    setAxiosCancelToken,
    setListState,
}: {
    apiUrl: string
    axiosCancelToken: CancelTokenSource | undefined
    changingView: boolean
    component: string
    componentName: string
    dispatch: React.Dispatch<any>
    listState: listStatePropsWeb
    reduxAuth: reduxAuthState
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setListState: React.Dispatch<any>
}) {

    // console.log('getInfiniteRandomListWebNew 1');

    if (!apiUrl) return

    if (changingView) {
        setListState({
            ...listState,
            disableInfiniteScroll: true,
            hasMore: true,
            isLoading: true,
            items: [],
        })
    } else {
        setListState({
            ...listState,
            disableInfiniteScroll: true,
            isLoading: true,
        })
    }

    // console.log('getInfiniteRandomListWebNew 2');

    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getInfiniteRandomListWebNew`)

    // console.log('getInfiniteRandomListWebNew 3');

    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    // console.log('getInfiniteRandomListWebNew 4');

    const formData: any = new FormData()
    const newIds_to_lahoun = changingView ? '0' : listState.ids_to_lahoun
    formData.append('ids_to_lahoun', newIds_to_lahoun)
    if (listState.clusterIds) {
        formData.append('ids_cluster', listState.clusterIds?.join(','))
    }
    if ((apiUrl).includes('?')) {
        apiUrl += `&lahoun_length=${newIds_to_lahoun.length}`
    } else {
        apiUrl += `?lahoun_length=${newIds_to_lahoun.length}`
    }

    if (process.env.NODE_ENV === 'development') console.log(apiUrl)

    axios({
        data: formData,
        headers: refreshAxiosHeaders,
        method: 'post',
        url: apiUrl,
        cancelToken: source.token,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            const dataResults = getShuffleArray(response.data.results)
            let idsString = '0'
            if (changingView) {
                dataResults.map((valh: any) => {
                    idsString += `,${valh.id}`
                    return false
                })
            } else {
                idsString = listState.ids_to_lahoun
                dataResults.map((valh: any) => {
                    idsString += `,${valh.id}`
                    return false
                })
            }
            const dataToReturn = changingView ? dataResults : [...listState.items, ...dataResults]
            const hasMore = response.data.page_size ? (response.data.results.length === response.data.page_size) : (response.data.results.length > 0)
            const disableInfiniteScroll = response.data.page_size ? (response.data.results.length < response.data.page_size) : (response.data.results.length === 0)
            setListState({
                ...listState,
                componentString: componentName,
                disableInfiniteScroll: disableInfiniteScroll,
                hasMore: hasMore,
                ids_to_lahoun: idsString,
                isLoading: false,
                items: dataToReturn,
            })
            dispatch(reduxModalSliderSetData(listState.modalKey, dataToReturn, apiUrl))
        })
        .catch((error) => {
            if (error.message === 'axios canceled') {
                if (process.env.NODE_ENV === 'development') console.log(error)
                return
            }
            if (errorMessageToSkip.includes(error.message)) {
                setListState({
                    ...listState,
                    disableInfiniteScroll: false,
                    isLoading: false,
                })
            } else if (errorMessageToSkip.includes(error)) {
                setListState({
                    ...listState,
                    disableInfiniteScroll: false,
                    isLoading: false,
                })
            } else {
                setListState({
                    ...listState,
                    disableInfiniteScroll: true,
                    isLoading: false,
                })
            }
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'getInfiniteRandomListWebNew',
            })
        })
}
