// react components
import React from 'react'
import {
    IonReactRouter,
} from '@ionic/react-router'
import {
    StatusBar,
    Style,
} from '@capacitor/status-bar'
import {
    IonApp,
    IonRouterOutlet,
    isPlatform,
    setupIonicReact,
} from '@ionic/react'
import {
    ThemeProvider,
    createTheme,
} from '@mui/material'
import {
    Provider,
} from 'react-redux'
import {
    Route,
} from 'react-router-dom'

// components
import {
    EcPlayerMobile,
    ErrorAlert,
    ErrorBoundary,
    ErrorEventHandler,
    FormSuccessToast,
    GlobalHelperMobile,
    RequireAuth,
} from 'components'

// containers
import {
    MainFormModalWrapper,
} from 'containers'

// data
import {
    secondary_color,
    view_url_adherent_detail,
    view_url_adherent_form,
    view_url_auth_login,
    view_url_auth_password_reset,
    view_url_auth_password_reset_confirm,
    view_url_auth_signup,
    view_url_event_detail,
    view_url_event_form,
    view_url_event_image_detail,
    view_url_home,
    view_url_material_detail,
    view_url_material_form,
    view_url_offer_detail,
    view_url_offer_form,
    view_url_press_form,
    view_url_product_detail,
    view_url_product_form,
    view_url_profile_about,
    view_url_profile_detail_old,
    view_url_profile_form,
    view_url_project_child_form,
    view_url_project_detail,
    view_url_project_detail_old,
    view_url_project_form,
    view_url_resource_form,
    view_url_search,
} from 'data'

// pages
import {
    // Adhesion
    AdherentDetail,
    AdherentForm,
    // Auth
    LoginPage,
    PasswordResetConfirmPage,
    PasswordResetPage,
    SignupPage,
    // Event
    EventDetailMobile,
    EventDetailListMobile,
    EventForm,
    EventImageDetailMobile,
    // Extra
    HomePage,
    NotFoundPage,
    SearchPageMobile,
    // Material
    MaterialDetailMobile,
    MaterialDetailListMobile,
    MaterialForm,
    // Offer
    OfferDetailMobile,
    OfferDetailListMobile,
    OfferForm,
    // Profile
    ProfileDetailMobile,
    ProfileDetailAboutMobile,
    ProfileDetailListMobile,
    ProfileForm,
    // Project
    ProjectChildFormWeb,
    ProjectDetailListMobile,
    ProjectDetailMobile,
    ProjectForm,
    // Sales
    ProductDetailMobile,
    ProductDetailListMobile,
    ProductForm,
} from 'pages'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Theme variables */
import '../@static/web/index.scss'

import configureStore from '../data/web/mo_store'

// Redux Constants
const store = configureStore()

if (process.env.NODE_ENV === 'production') {
    if (isPlatform('ios')) {
        StatusBar.setStyle({
            // style: this.isStatusBarLight ? StatusBarStyle.Dark : StatusBarStyle.Light,
            style: Style.Dark,
        })
    }
}

// MUI style override
const darkTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    color: 'var(--mo-color-text1)',
                },
            },
        },
    },
    palette: {
        mode: 'dark',
        background: {
            default: '#181818',
        },
        secondary: {
            main: secondary_color,
        },
    },
    typography: {
        fontFamily: 'FuturaPT',
    },
})

setupIonicReact({
    mode: 'ios',
    spinner: 'crescent',
    swipeBackEnabled: false,
})

// main
export const AppMobile: React.FC = () => (
    <Provider store={store}>
        <IonApp>
            <IonReactRouter>
                <ThemeProvider theme={darkTheme}>
                    <ErrorBoundary>
                        <IonRouterOutlet>
                            {/* Extra */}
                            <Route exact path={view_url_home} component={HomePage} />
                            <Route exact path={view_url_search} render={() => <RequireAuth><SearchPageMobile /></RequireAuth>} />
                            {/* **************************************** Adhesion **************************************** */}
                            <Route exact path={`${view_url_adherent_detail}:id/:slug?/`} render={() => <RequireAuth><AdherentDetail /></RequireAuth>} />
                            <Route exact path={`${view_url_adherent_form}:id/`} render={() => <RequireAuth><AdherentForm /></RequireAuth>} />
                            <Route exact path={`${view_url_adherent_form}:id/:formType/:panelValue/`} render={() => <RequireAuth><AdherentForm /></RequireAuth>} />
                            {/* Auth TO_CHECK */}
                            <Route exact path={view_url_auth_login} component={LoginPage} />
                            <Route exact path={view_url_auth_password_reset} component={PasswordResetPage} />
                            <Route exact path={view_url_auth_password_reset_confirm} component={PasswordResetConfirmPage} />
                            <Route exact path={view_url_auth_signup} component={SignupPage} />
                            {/* Event */}
                            <Route exact path={`${view_url_event_detail}:id/:slug?/`} render={() => <RequireAuth><EventDetailMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_event_detail}:id/:slug/:listId/`} render={() => <RequireAuth><EventDetailListMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_event_form}:id/`} render={() => <RequireAuth><EventForm /></RequireAuth>} />
                            <Route exact path={`${view_url_event_form}:id/:formType/:panelValue/`} render={() => <RequireAuth><EventForm /></RequireAuth>} />
                            <Route exact path={`${view_url_event_image_detail}:id/:slug?/`} render={() => <RequireAuth><EventImageDetailMobile /></RequireAuth>} />
                            {/* Material */}
                            <Route exact path={`${view_url_material_detail}:id/:slug?/`} render={() => <RequireAuth><MaterialDetailMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_material_detail}:id/:slug/:listId/`} render={() => <RequireAuth><MaterialDetailListMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_material_form}:id/`} render={() => <RequireAuth><MaterialForm /></RequireAuth>} />
                            <Route exact path={`${view_url_material_form}:id/:formType/:panelValue/`} render={() => <RequireAuth><MaterialForm /></RequireAuth>} />
                            {/* Offer */}
                            <Route exact path={`${view_url_offer_detail}:id/:slug?/`} render={() => <RequireAuth><OfferDetailMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_offer_detail}:id/:slug/:listId/`} render={() => <RequireAuth><OfferDetailListMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_offer_form}:id/`} render={() => <RequireAuth><OfferForm /></RequireAuth>} />
                            <Route exact path={`${view_url_offer_form}:id/:formType/:panelValue/`} render={() => <RequireAuth><OfferForm /></RequireAuth>} />
                            {/* Project */}
                            <Route exact path={`${view_url_project_detail}:id/:slug?/`} render={() => <RequireAuth><ProjectDetailMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_project_detail}:id/:slug/:listId/`} render={() => <RequireAuth><ProjectDetailListMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_project_detail_old}:id/:slug?/`} render={() => <RequireAuth><ProjectDetailMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_project_detail_old}:id/:slug/:listId/`} render={() => <RequireAuth><ProjectDetailListMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_press_form}:id/`} render={() => <RequireAuth><ProjectForm /></RequireAuth>} />
                            <Route exact path={`${view_url_press_form}:id/:formType/:panelValue/`} render={() => <RequireAuth><ProjectForm /></RequireAuth>} />
                            <Route exact path={`${view_url_project_form}:id/`} render={() => <RequireAuth><ProjectForm /></RequireAuth>} />
                            <Route exact path={`${view_url_project_form}:id/:formType/:panelValue/`} render={() => <RequireAuth><ProjectForm /></RequireAuth>} />
                            <Route exact path={`${view_url_resource_form}:id/`} render={() => <RequireAuth><ProjectForm /></RequireAuth>} />
                            <Route exact path={`${view_url_resource_form}:id/:formType/:panelValue/`} render={() => <RequireAuth><ProjectForm /></RequireAuth>} />
                            <Route exact path={`${view_url_project_child_form}:id/:albumId/`} render={() => <RequireAuth><ProjectChildFormWeb /></RequireAuth>} />
                            <Route exact path={`${view_url_project_child_form}:id/:albumId/:formType/:panelValue/`} render={() => <RequireAuth><ProjectChildFormWeb /></RequireAuth>} />
                            {/* Shop */}
                            <Route exact path={`${view_url_product_detail}:id/:slug?/`} render={() => <RequireAuth><ProductDetailMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_product_detail}:id/:slug/:listId/`} render={() => <RequireAuth><ProductDetailListMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_product_form}:id/`} render={() => <RequireAuth><ProductForm /></RequireAuth>} />
                            <Route exact path={`${view_url_product_form}:id/:formType/:panelValue/`} render={() => <RequireAuth><ProductForm /></RequireAuth>} />
                            {/* Profile Should be last because of slug */}
                            <Route exact path={`${view_url_profile_detail_old}:slug/`} render={() => <RequireAuth><ProfileDetailMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_profile_detail_old}:slug/tabs/${view_url_profile_about}/`} render={() => <RequireAuth><ProfileDetailAboutMobile edit={false} /></RequireAuth>} />
                            <Route exact path={`${view_url_profile_detail_old}:slug/sections/:sectionId/:listStatus?/`} render={() => <RequireAuth><ProfileDetailListMobile /></RequireAuth>} />
                            <Route exact path={`${view_url_profile_form}:id/`} render={() => <RequireAuth><ProfileForm /></RequireAuth>} />
                            <Route exact path={`${view_url_profile_form}:id/:formType/:panelValue/:extraId?/`} render={() => <RequireAuth><ProfileForm /></RequireAuth>} />
                            <Route component={NotFoundPage} />
                        </IonRouterOutlet>
                        <EcPlayerMobile />
                        <ErrorAlert />
                        <ErrorEventHandler />
                        <FormSuccessToast />
                        <GlobalHelperMobile />
                        <MainFormModalWrapper />
                    </ErrorBoundary>
                </ThemeProvider>
            </IonReactRouter>
        </IonApp>
    </Provider>
)
