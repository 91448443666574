// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventDetailFormSerializer,
    EventDetailFormSerializerError,
} from 'serializers/web'

// props
type DateTypeChoiceEventInputProps = {
    errors: EventDetailFormSerializerError | undefined
    fields: EventDetailFormSerializer
    onChange: any
}

// main
export const DateTypeChoiceEventInput: React.FC<DateTypeChoiceEventInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const dateTypeOptions = [
        {
            id: 'd',
            name: reduxText[4832],
        },
        {
            id: 'm',
            name: reduxText[4831],
        },
        {
            id: 'y',
            name: reduxText[4830],
        },
    ]

    return (
        <SelectListInput
            clearable={false}
            error={errors?.date_type_choice}
            label={reduxText[8561]}
            name='date_type_choice'
            onChange={onChange}
            options={dateTypeOptions}
            value={fields.date_type_choice}
        />
    )
})
