// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type MainIframeBlockProps = {
    url: string
}

// main
export const MainIframeBlock: React.FC<MainIframeBlockProps> = React.memo(({
    url,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)

    function hideLoader() {
        try {
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainIframeBlock',
                'hideLoader',
            ))
        }
    }

    return (
        <div className='main-profile-website-block-web'>
            <p className='mpwb-loading'>{reduxText[3470]}</p>
            <iframe
                className='mpwb-iframe'
                onLoad={() => hideLoader()}
                src={url?.endsWith('.pdf') ? `https://klhkpm8h.tinifycdn.com/vendors/pdfjs-4.9.155-legacy-dist/web/viewer.html?file=${url}#pagemode=none` : url}
                style={{ visibility: isLoading ? 'hidden' : 'initial' }}
                title='Just The Web Iframe'
            />
            <div className='mpwb-button-wrap'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => window.open(url, '_blank')}
                    size='small'
                    text={reduxText[4019]}
                />
            </div>
        </div>
    )
})
