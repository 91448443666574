// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// containers
import {
    DetailListContainerWeb,
} from 'containers'

// data
import {
    api_url_product_detail_short,
    api_url_product_image_list,
    api_url_product_team_list,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// props
type MatchParams = {
    id: string
    listId: string
    listStatus: string
}

// main
export const ProductDetailListWeb: React.FC = () => {

    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const filterParamsOptions: any = {
        'image': {
            apiUrl: getApiUrl(`${api_url_product_image_list}?product_id=${params.id}`, reduxAuth),
            defaultThumbSize: 'medium',
            mediumListComponent: 'ImageListBlockWeb',
            smallListComponent: 'ImageListBlockWeb',
            title: reduxText[1625],
        },
        'team': {
            apiUrl: getApiUrl(`${api_url_product_team_list}?product_id=${params.id}`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'TeamListMediumBlockWeb',
            smallListComponent: 'TeamListSmallBlockWeb',
            title: reduxText[4917],
        },
    }

    return (
        <DetailListContainerWeb
            filterParamsOptions={filterParamsOptions}
            listId={params.listId}
            listStatus={params.listStatus}
            model='product'
            shortUrl={api_url_product_detail_short}
            slugOrId={params.id}
        />
    )
}
