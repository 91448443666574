// react components
import React, {
    useEffect,
} from 'react'
import {
    Elements,
} from '@stripe/react-stripe-js'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// customers
import {
    AgendaDuQuartierStripeCheckout,
} from 'customers'

// data
import {
    defaultReduxState,
    reduxModalSetStripePromiseSite,
} from 'data'

// props
type PickAnOfferBlockStep4Props = {
    fields: any
    period: 'monthly' | 'yearly'
    setInvoice: any
    setQuotation: any
}

// main
export const PickAnOfferBlockStep4: React.FC<PickAnOfferBlockStep4Props> = React.memo(({
    fields,
    period,
    setInvoice,
    setQuotation,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    useEffect(() => {
        dispatch(reduxModalSetStripePromiseSite('pk_test_51Q4mkFG1YZk9oFa1k0v1xg5OqYZGdcUJgZuGUP0B78chBV1vzoco3f0BcgvsZl03znZNt0bPfVbNZwwZ4vMNPeus00bH6dQWub', 'pk_test_51Q4mkFG1YZk9oFa1k0v1xg5OqYZGdcUJgZuGUP0B78chBV1vzoco3f0BcgvsZl03znZNt0bPfVbNZwwZ4vMNPeus00bH6dQWub'))
    }, [])

    return (
        <div className='paob-step4'>
            <Elements stripe={reduxModalSite.stripePromise}>
                <AgendaDuQuartierStripeCheckout
                    fields={fields}
                    period={period}
                    setInvoice={setInvoice}
                    setQuotation={setQuotation}
                />
            </Elements>
        </div>
    )
})
