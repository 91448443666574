// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_portfolio_filter_field,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type FilterInputFilterFieldPageContentProps = {
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    onHandleInputChange: any
    optional: boolean
}

// main
export const FilterInputFilterFieldPageContent: React.FC<FilterInputFilterFieldPageContentProps> = React.memo(({
    errorsInline,
    fieldsInline,
    onHandleInputChange,
    optional,
    fieldsMother,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [newOptions, setNewOptions] = useState<any[]>([])

    useEffect(() => {
        getData()
    }, [
        fieldsInline.filter_related_content,
        reduxAuth.apiRootUrl,
    ])

    async function getData() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SelectListInput getData')
            const getUrl = getApiUrl(`${select_url_portfolio_filter_field}?filter_related_content=${fieldsInline.filter_related_content!.id}`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const returnData: any[] = []
                    const contentTypeSettingsCustom = fieldsMother.profile?.settings_data_json?.contentTypeSettingsCustom
                    response.data.map((val: any) => {
                        if (contentTypeSettingsCustom?.[fieldsInline.filter_related_content?.content_type_label_model!]?.field?.[val.key]) {
                            returnData.push({
                                id: val.id,
                                // @ts-ignore
                                name: contentTypeSettingsCustom[fieldsInline.filter_related_content?.content_type_label_model].field[val.key].verboseName || val.key,
                            })
                        } else {
                            returnData.push(val)
                        }
                    })
                    setNewOptions(returnData)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'SelectListInput',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectListInput',
                'getData',
            ))
        }
    }

    return (
        <SelectListInput
            clearable
            error={errorsInline?.filter_field}
            label='filter_field'  // TO_TEXT
            name='filter_field'
            onChange={onHandleInputChange}
            optional={optional}
            options={newOptions}
            optionsSorted
            value={newOptions[newOptions.findIndex((val: MainIdNameSerializer) => val.id === fieldsInline.filter_field?.id)] || fieldsInline.filter_field || ''}
        />
    )
})
