// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DescriptionDetailBlock,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// data
import {
    defaultReduxState,
    searchTabType,
} from 'data'

// serializers
import {
    CityTempSerializer,
    CountryListSerializer,
    MainCitySerializer,
    MainIdNameSerializer,
} from 'serializers/web'

// props
type LocationDetailBlockProps = {
    address_json?: CityTempSerializer[]
    edit: boolean
    full?: boolean
    object?: {
        area_level_1s?: MainIdNameSerializer[]
        area_level_2s?: MainIdNameSerializer[]
        cities?: MainCitySerializer[]
        address_json?: CityTempSerializer[]
        countries?: CountryListSerializer[]
    }
    reduced?: boolean
    searchTab?: searchTabType | undefined
    title?: string
}

// main
export const LocationDetailBlock: React.FC<LocationDetailBlockProps> = React.memo(({
    address_json,
    edit,
    full,
    object,
    reduced,
    searchTab,
    title,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (reduced) {
        if (address_json?.length === 0) return null
        return (
            <DescriptionDetailBlock title={title}>
                <div className='location-detail-block-reduced-web'>
                    {address_json?.map((val, i) => (
                        <div
                            key={i}
                            className='ldbrw-wrap'
                        >
                            <MainCountryFlag
                                edit={edit}
                                object={val}
                                searchTab={undefined}
                            />
                            <p className='ldbrw-text'>{val.description}</p>
                        </div>
                    ))}
                </div>
            </DescriptionDetailBlock>
        )
    }

    if (full) {
        if (address_json?.length === 0) return null
        if (object?.area_level_1s?.length === 0 && object?.area_level_2s?.length === 0) return null
        return (
            <DescriptionDetailBlock title={title}>
                <div className='location-detail-block-full-web'>
                    <p className='ldbfw-title ddbw-title'>{reduxText[8576]}</p>
                    <div className='ldbfw-wrap'>
                        <p className='ldbfw-level'>{reduxText[6469]}</p>
                        <div className='ldbfw-value-wrap'>
                            {object?.countries?.map(val => (
                                <p key={val.id}>{val.name}</p>
                            ))}
                        </div>
                    </div>
                    <div className='ldbfw-wrap'>
                        <p className='ldbfw-level'>{reduxText[8577]}</p>
                        <div className='ldbfw-value-wrap'>
                            {object?.area_level_1s?.map(val => (
                                <p key={val.id}>{val.name}</p>
                            ))}
                        </div>
                    </div>
                    <div className='ldbfw-wrap'>
                        <p className='ldbfw-level'>{reduxText[8578]}</p>
                        <div className='ldbfw-value-wrap'>
                            {object?.area_level_2s?.map(val => (
                                <p key={val.id}>{val.name}</p>
                            ))}
                        </div>
                    </div>
                    <div className='ldbfw-wrap'>
                        <p className='ldbfw-level'>{reduxText[6468]}</p>
                        <div className='ldbfw-value-wrap'>
                            {object?.cities?.map(val => (
                                <p key={val.id}>{val.name}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </DescriptionDetailBlock>
        )
    }

    const locationObject: any = {}
    const noCountryCities: MainCitySerializer[] = []

    if (edit) {
        const countryArray: CityTempSerializer[] = []
        const countryArrayTemp: string[] = []
        object?.address_json?.map((val) => {
            if (val.countryCode && !countryArrayTemp.includes(val.countryCode)) {
                countryArrayTemp.push(val.countryCode)
                countryArray.push(val)
            }
            return false
        })
        countryArray?.map((val) => {
            locationObject[val.countryCode!] = {
                country: val,
                cities: [],
            }
        })
        const cityArray = object?.address_json || []
        cityArray?.map((val, i) => {
            if (locationObject[val.countryCode!]) {
                locationObject[val.countryCode!].cities.push({
                    id: i,
                    active: true,
                    name: val.cityName || val.description,
                })
            } else {
                noCountryCities.push({
                    id: i,
                    active: true,
                    name: val.cityName || val.description,
                })
            }
        })
    } else {
        object?.countries?.map((val) => {
            locationObject[val.id] = {
                country: val,
                cities: [],
            }
        })
        object?.cities?.map((val) => {
            if (locationObject[val.country!]) {
                locationObject[val.country!].cities.push(val)
            } else {
                noCountryCities.push(val)
            }
        })
    }

    if ((Object.getOwnPropertyNames(locationObject)?.length! > 0) || (noCountryCities?.length! > 0)) {
        return (
            <DescriptionDetailBlock title={title}>
                <div className='location-detail-block-web'>
                    {Object.getOwnPropertyNames(locationObject).map((val, i) => (
                        <MainCountryFlag
                            key={val}
                            edit={edit}
                            namePosition='end'
                            object={locationObject[val].country}
                            cities={locationObject[val].cities}
                            searchTab={searchTab}
                        />
                    ))}
                    {noCountryCities?.map((val) => {
                        if (val.active) {
                            return (
                                <MainHashtag
                                    key={val.id}
                                    edit={edit}
                                    label={val.name}
                                    searchTab={searchTab}
                                />
                            )
                        }
                        return false
                    })}
                </div>
            </DescriptionDetailBlock>
        )
    }
    return null
})
