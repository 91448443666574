// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import axios, {
    AxiosRequestConfig,
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorHelper,
    IconBlock,
    InfiniteScrollHelperWeb,
    Loader,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxModalErrorEventHandler,
    reduxModalErrorShowHide,
} from 'data'

// pages
import {
    UserPortfolioListFormBlock,
    UserPortfolioMotherFormState,
} from 'pages'

// serializers
import {
    UserPortfolioFormState,
    UserPortfolioListFormSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getInfiniteListWeb,
    getInputValue,
    getMainErrorMessage,
} from 'services'

// props
type UserPortfolioPanelProps = {
    helpers: reduxFormHelpersState
    portfolioId: number
    setHelpers: React.Dispatch<reduxFormHelpersState>
    setShowModal: React.Dispatch<boolean>
}

// main
export const UserPortfolioPanel: React.FC<UserPortfolioPanelProps> = React.memo(({
    helpers,
    portfolioId,
    setHelpers,
    setShowModal,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<UserPortfolioListFormSerializer[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [formState, setFormState] = useState<UserPortfolioFormState>({
        mother: UserPortfolioMotherFormState(reduxAuth),
    })
    const [isDeleting, setIsDeleting] = useState(false)
    const [showInlineForm, setShowInlineForm] = useState(true)

    useEffect(() => {
        onGetListData(
            getAxiosUrl(formState.mother),
            true,
            true,
        )
    }, [
        reduxForm.refresh.refreshInlineList,
    ])

    function getAxiosUrl(formInfo: any) {
        return `${formInfo.listUrl}${portfolioId}/`
    }

    let wto: any
    let wto2: any

    function resetForm() {
        setShowInlineForm(false)
        clearTimeout(wto)
        wto = setTimeout(() => {
            setShowInlineForm(true)
        }, 100)
        setHelpers(reduxFormInitialHelpers)
        setFormState({
            ...formState,
            mother: {
                ...formState.mother,
                fields: {},
                errors: {},
            }
        })
    }

    async function handleInlineActive(object: UserPortfolioListFormSerializer) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'UserPortfolioPanel handleInlineActive')
            const formData = new FormData()
            formData.append('active', (!object.active).toString())
            const activeUrl = `${formState.mother.activeUrl}${object.id}/`
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const newArray: any = []
                    items.map((val: any) => {
                        if (val.id === object.id) {
                            val.active = response.data.active
                        }
                        newArray.push(val)
                        return false
                    })
                    setItems(newArray)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'UserPortfolioPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineActive',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'UserPortfolioPanel',
                'handleInlineActive',
            ))
        }
    }

    async function handleInlineDelete(object: UserPortfolioListFormSerializer) {
        try {
            setIsDeleting(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'UserPortfolioPanel handleInlineDelete')
            const deleteUrl = `${formState.mother.updateUrl}${object.id}/`
            setShowInlineForm(false)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    const newArray: any[] = []
                    items.map((val: any) => {
                        if (val.id !== object.id) newArray.push(val)
                        return false
                    })
                    setItems(newArray)
                    resetForm()
                })
                .catch((error) => {
                    setIsDeleting(false)
                    if (error.response?.status === 423) {
                        dispatch(reduxModalErrorShowHide({
                            header: reduxText[7428],
                            isOpen: true,
                            message: reduxText[7430],
                            noMessage: true,
                            source: 'UserPortfolioPanel handleInlineDelete',
                        }))
                    } else {
                        axiosErrorHandler({
                            apiUrl: deleteUrl,
                            component: 'UserPortfolioPanel',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleInlineDelete',
                        })
                    }
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'UserPortfolioPanel',
                'handleInlineDelete',
            ))
        }
    }

    async function handleInlineRetrieve(object: UserPortfolioListFormSerializer) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'UserPortfolioPanel handleInlineRetrieve')
            const getUrl = `${formState.mother.detailUrl}${object.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    setShowInlineForm(false)
                    clearTimeout(wto2)
                    wto2 = setTimeout(() => {
                        setShowInlineForm(true)
                    }, 100)
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            fields: response.data,
                            errors: {},
                        }
                    })
                    setHelpers({
                        ...helpers,
                        init: true,
                    })
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'UserPortfolioPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineRetrieve',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'UserPortfolioPanel',
                'handleInlineRetrieve',
            ))
        }
    }

    function onHandleInputChange(event: any) {
        try {
            setFormState({
                ...formState,
                mother: getInputValue({
                    event,
                    formInfo: formState.mother,
                    reduxText,
                })
            })
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                init: true,
                mainError: '',
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'UserPortfolioPanel',
                'onHandleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: formState.mother,
                reduxText,
            })
            if (process.env.NODE_ENV === 'development') console.log(result)
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    mother: {
                        ...formState.mother,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'UserPortfolioPanel',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'UserPortfolioPanel handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
                mainError: '',
            })

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = formState.mother.createUrl
            if (formState.mother.fields?.id) {
                axiosMethod = 'put'
                axiosUrl = `${formState.mother.updateUrl}${formState.mother.fields.id}/`
            }
            const formData: any = getFormData(formState.mother)
            formData.append('portfolio', portfolioId)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    resetForm()
                    onGetListData(
                        getAxiosUrl(formState.mother),
                        true,
                    )
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'UserPortfolioPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'UserPortfolioPanel',
                'handleSubmit',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        noEmpty?: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'UserPortfolioPanel',
                dispatch,
                items,
                noEmpty,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'UserPortfolioPanel',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'UserPortfolioPanel',
                'searchNext',
            ))
        }
    }

    const errorsMother = formState.mother.errors
    const fieldsMother = formState.mother.fields

    return (
        <Dialog
            className='mo-dialog-class inline-form'
            classes={{ paper: 'mo-dialog-wrapper' }}
            disableEnforceFocus
            fullWidth
            maxWidth='xl'
            onClose={() => setShowModal(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[8591]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setShowModal(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className='mo-dialog-content no-padding'>
                <div className='mo-dialog-content-left'>
                    <div className={`mo-dialog-content-form-children mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {showInlineForm && (
                            <TextInput
                                error={errorsMother?.email}
                                label={reduxText[8562]}
                                name='email'
                                onChange={onHandleInputChange}
                                type='email'
                                value={fieldsMother?.email || ''}
                            />
                        )}
                        {/* @ts-ignore */}
                        <ErrorHelper error={errorsMother?.non_field_errors} />
                    </div>
                    <div className='mo-dialog-footer'>
                        {helpers.init
                            ? (
                                <React.Fragment>
                                    <Button
                                        edit={false}
                                        expand='full'
                                        fill='outline'
                                        onClick={resetForm}
                                        padding='0 5px'
                                        text={reduxText[4519]}
                                    />
                                    <Button
                                        disabled={helpers.buttonDisabled}
                                        edit={false}
                                        expand='full'
                                        fill='outline'
                                        onClick={handleRequired}
                                        padding='0 5px'
                                        text={reduxText[4520]}
                                    />
                                </React.Fragment>
                            ) : (
                                <Button
                                    edit={false}
                                    fill='outline'
                                    onClick={() => setShowModal(false)}
                                    text={reduxText[4742]}
                                />
                            )}
                    </div>
                </div>
                <div className={`mo-dialog-content-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {items.length > 0 && (
                        <div className='mo-dialog-content-table-container'>
                            <table>
                                <thead>
                                    <tr className='main-list-header UserPortfolioPanel'>
                                        <th>{reduxText[8562]}</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(val => {
                                        return (
                                            <UserPortfolioListFormBlock
                                                key={val.id}
                                                handleInlineActive={handleInlineActive}
                                                handleInlineDelete={handleInlineDelete}
                                                handleInlineRetrieve={handleInlineRetrieve}
                                                object={val}
                                            />
                                        )
                                    })}
                                </tbody>
                            </table>
                            <InfiniteScrollHelperWeb
                                active={!disableInfiniteScroll}
                                hasMore={hasMore}
                                isLoading={isLoading}
                                items={items}
                                onChange={onSearchNext}
                            />
                        </div>
                    )}
                </div>
            </DialogContent>
            {isDeleting && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </Dialog>
    )
})
