// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    IconBlock,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    RichTextInputV2,
    TextAreaInputV2,
    TextInputV2,
} from 'pages'

// serializers
import {
    ContentTypeFormTypes,
} from 'serializers/web'

// props
type TextNormalAndRichInputProps = {
    disabled: boolean
    field: string
    isTextArea?: boolean
    object: ContentTypeFormTypes['data']['fields']
    onChange: any
    onKeyPress: any
}

// main
export const TextNormalAndRichInput: React.FC<TextNormalAndRichInputProps> = React.memo(({
    disabled,
    field,
    isTextArea,
    object,
    onChange,
    onKeyPress,
}) => {

    const dispatch = useDispatch()

    const isRich = object[`${field}_is_rich`]

    function onIsRichChange(event: boolean) {
        try {
            onChange({
                name: `${field}_is_rich`,
                value: event,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TextNormalAndRichInput',
                'onIsRichChange',
            ))
        }
    }

    return (
        <div className='text-normal-and-rich-input'>
            <div className='tnari-input-wrap'>
                {isRich
                    ? (
                        <RichTextInputV2
                            disabled={disabled}
                            fonts={object.portfolio_main?.fonts}
                            name={`${field}_rich`}
                            objectId={object.id}
                            onChange={onChange}
                            presetColors={object?.preset_colors}
                            tinymceStylesString='todo'
                            value={object[`${field}_rich`] || ''}
                        />
                    ) : (
                        isTextArea
                            ? (
                                <TextAreaInputV2
                                    disabled={disabled}
                                    name={field}
                                    onChange={onChange}
                                    value={object[field] || ''}
                                />
                            ) : (
                                <TextInputV2
                                    disabled={disabled}
                                    name={field}
                                    onChange={onChange}
                                    onKeyPress={onKeyPress}
                                    value={object[field] || ''}
                                />
                            )
                    )
                }
            </div>
            <div className='tnari-input-action'>
                <IconBlock
                    className='tnari-icon'
                    edit={false}
                    iconClass='mo-new-icon-layout-779'
                    onClick={() => onIsRichChange(!isRich)}
                />
            </div>
        </div>
    )
})
