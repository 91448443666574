// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    Checkbox,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ContentPanel779,
    ContentPanel780,
    ContentPanel781,
    ContentPanel783,
    ContentPanel799,
    ContentPanel818,
    InputHelperPageContent,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    actionFunction,
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getMainErrorMessage,
} from 'services'

// props
type MatchParams = {
    id: string
    pageSlug: string
}

type DetailPageContentPortfolioPanelProps = {
    formState: PortfolioFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    setFormState: React.Dispatch<PortfolioFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    setTabValue: React.Dispatch<string | undefined>
}

// main
export const DetailPageContentPortfolioPanel: React.FC<DetailPageContentPortfolioPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
    setTabValue,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSitepermissions = useSelector((state: defaultReduxState) => state.reduxFormSite.permissions)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isMarkedAsToDo, setIsMarkedAsToDo] = useState<boolean>(formState.content?.fields?.is_marked_as_to_do!)

    const mother = formState.mother
    const content = formState.content

    useEffect(() => {
        setIsMarkedAsToDo(formState.content?.fields?.is_marked_as_to_do!)
    }, [
        formState.content?.fields?.is_marked_as_to_do,
    ])

    function onHandleInputChange(event: any, customError?: any) {
        try {
            handleInputChange(event, content, customError)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageContentPortfolioPanel',
                'onHandleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: content,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    content: {
                        ...formState.content,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageContentPortfolioPanel',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DetailPageContentPortfolioPanel handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            const axiosUrl = `${content.updateUrl}${content.fields?.id}/`
            const formData: any = getFormData(content)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    dispatch(reduxFormSetRefresh('refreshPreview', fieldsMother?.id))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        content: {
                            ...formState.content,
                            errors: {},
                            fields: {
                                ...content.fields,
                                id: response.data.id,
                            },
                        }
                    })
                    dispatch(reduxFormSetEditingSite(false))
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: reduxText[4798],
                    })
                    setFormState({
                        ...formState,
                        content: {
                            ...formState.content,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'DetailPageContentPortfolioPanel',
                        dispatch,
                        error,
                        formFields: JSON.stringify(content.fields),
                        formFieldsToIgnore: ['cover_image'],
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            if (process.env.NODE_ENV === 'development') console.log(error)
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageContentPortfolioPanel',
                'handleSubmit',
            ))
        }
    }

    function onRefresh() {
        dispatch(reduxFormSetRefresh('refreshPreview', fieldsMother?.id, true))
    }

    // Mother
    const fieldsMother = mother.fields

    // Content
    const errorsInline = content.errors
    const fieldsInline = content.fields

    if (!fieldsMother || !fieldsInline) return null

    const components: any = {
        ContentPanel779,
        ContentPanel780,
        ContentPanel781,
        ContentPanel783,
        ContentPanel799,
        ContentPanel818,
    }

    const CustomContentPanelName = components[`ContentPanel${fieldsInline.layout?.id}`]

    if (reduxFormSitepermissions?.permissionIsDisabledRest && ![
        779,
        781,
        783,
    ].includes(fieldsInline.layout?.id!)) {
        return (
            <div style={{
                padding: '20px',
            }}>
                <p>{reduxText[9975]}</p>
            </div>
        )
    }

    return (
        <React.Fragment>
            {reduxAuth.settings?.user?.is_staff && (
                <>
                    <div className='pfcw-content-links'>
                        <div className='pfcw-inherit'>
                            <Checkbox
                                checked={!!isMarkedAsToDo}
                                onChange={() => actionFunction({
                                    component: 'DetailPageContentPortfolioPanel',
                                    contentType: 'portfolio_portfoliopagecontent',
                                    dispatch,
                                    function_name: isMarkedAsToDo ? 'action_function_unmark_as_to_do' : 'action_function_mark_as_to_do',
                                    objectId: fieldsInline.id!,
                                    reduxAuth,
                                    refreshData: () => setIsMarkedAsToDo(!isMarkedAsToDo),
                                })}
                            />
                            <span className='pfcw-inherit-text'>{isMarkedAsToDo ? 'Unmark TODO' : 'Mark TODO'}</span>
                        </div>
                        {reduxAuth.settings?.user?.id === 1 && (
                            <div>
                                <a
                                    className='pfcw-link'
                                    href={`https://api.justtheweb.io/en/admin/portfolio/portfoliopagecontent/${content.fields?.id}/change/`}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    Prod
                                </a>
                                <a
                                    className='pfcw-link'
                                    href={`http://localhost:8000/en/admin/portfolio/portfoliopagecontent/${content.fields?.id}/change/`}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    Dev
                                </a>
                            </div>
                        )}
                    </div>
                    <div className='pfcw-button-staff aa'>
                        <Button
                            disabled={helpers.buttonDisabled}
                            edit={false}
                            expand='full'
                            onClick={handleRequired}
                            size='x-small'
                            text={reduxText[6378]}
                        />
                    </div>
                </>
            )}
            <div className={`pfcw-container-fields mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                {params.pageSlug === 'symbols-page' && !fieldsInline.parent_content && (
                    <TextInput
                        error={errorsInline?.symbol_name}
                        label={reduxText[8303]}
                        name='symbol_name'
                        onChange={onHandleInputChange}
                        value={fieldsInline.symbol_name || ''}
                    />
                )}
                {CustomContentPanelName
                    ? (
                        <CustomContentPanelName
                            detailUrl={content.detailUrl}
                            formState={formState}
                            handleInputChange={onHandleInputChange}
                            onRefresh={onRefresh}
                            updateUrl={content.updateUrl}
                        />
                    ) : (
                        fieldsInline.layout?.form_fields?.map((val) => {
                            return (
                                <InputHelperPageContent
                                    key={val.id}
                                    component={val.field!}
                                    detailUrl={content.detailUrl}
                                    errorsInline={errorsInline}
                                    fieldsInline={fieldsInline}
                                    fieldsMother={fieldsMother}
                                    fonts={fieldsMother.fonts}
                                    inputObject={val}
                                    onHandleInputChange={onHandleInputChange}
                                    onRefresh={onRefresh}
                                    optional={false}
                                    updateUrl={content.updateUrl}
                                />
                            )
                        })
                    )
                }
            </div>
            <div className='pfcw-footer2'>
                <Button
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    onClick={handleRequired}
                    text={reduxText[6378]}
                />
            </div>
        </React.Fragment>
    )
})
