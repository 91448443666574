// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    FontLoader,
    LightboxSite,
    Loader,
} from 'components'

// data
import {
    api_url_project_mixed_media_list,
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    languageObjectSite,
    reduxFormSetDataSite,
    reduxModalErrorEventHandlerSite,
    reduxModalSetLanguageIdSite,
} from 'data'

// pages
import {
    ContentBlockSite,
    SelectionHelperMixedMediaContent,
} from 'pages'

// serializers
import {
    MainFontSerializer,
    ProjectDetailSerializer,
    ProjectFormState,
} from 'serializers/web'
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getGoogleFontsSite,
    getStylesToRetrieve,
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    panelValue: string
}

type MixedContentPreviewProps = {
    contentType: contentTypeModelType
    edit: boolean | 'preview'
    languageId: number | undefined
    mother: ProjectFormState['mother']
    newMixedMediaContent?: boolean
    newMixedMediaContentType?: contentTypeModelType | undefined
    object: ProjectDetailSerializer
}

// main
export const MixedContentPreview: React.FC<MixedContentPreviewProps> = React.memo(({
    contentType,
    edit,
    languageId,
    mother,
    newMixedMediaContent,
    newMixedMediaContentType,
    object,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [refreshPreview, setRefreshPreview] = useState<number | undefined>(0)

    const [fonts, setFonts] = useState<MainFontSerializer[]>()
    const [linkColor, setLinkColor] = useState<string>()
    const [newPageStyles, setNewPageStyles] = useState<{ [key: string]: string | undefined }>({})

    const contentRef = useRef(null)

    useEffect(() => {
        getPortfolioData()
        setRefreshPreview(reduxForm.refresh.refreshPreview)
    }, [
        languageId,
        object.id,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        if ((reduxForm.refreshId.refreshPreview === object.id) && (reduxForm.refresh.refreshPreview !== refreshPreview)) {
            getPortfolioData(true, reduxForm.refreshNoEmpty)
            setRefreshPreview(reduxForm.refresh.refreshPreview)
        }
    }, [
        reduxForm.refresh.refreshPreview,
    ])

    useEffect(() => {
        const tempPageStyles: { [key: string]: string | undefined } = {}
        if (getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'backgroundImage')) {
            tempPageStyles.backgroundImage = getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'backgroundImage')
        } else {
            tempPageStyles.background = getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'background')
        }
        tempPageStyles.color = getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'color')
        tempPageStyles.fontFamily = getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'fontFamily') || getStylesToRetrieve(reduxModalSite, undefined, object.portfolio_main?.styles, '', 'fontFamily')
        setNewPageStyles(tempPageStyles)
        setLinkColor(getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'linkColor'))
        setFonts(object.fonts ? object.fonts.concat(object.portfolio_main?.fonts || []) : (object.portfolio_main?.fonts || []))
    }, [
        object.fonts,
        object.portfolio_main?.fonts,
        object.styles,
        reduxModalSite,
    ])

    async function getPortfolioData(noScollTop?: boolean, noLoading?: boolean) {
        try {
            if (!object.id) {
                dispatch(reduxFormSetDataSite('pageContentItems', []))
                return
            }
            if (!noLoading) setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MixedContentPreview getPortfolioData')
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)
            let pageContentApiUrl = getApiUrl(`${api_url_project_mixed_media_list}?detail_id=${object.id}&content_type_id=${contentTypeData[contentType]}&edit=true`, reduxAuth)
            pageContentApiUrl += `&language_id=${languageId || languageObjectSite[reduxAuth.settings?.language!]}`
            if (languageId || languageObjectSite[reduxAuth.settings?.language!]) dispatch(reduxModalSetLanguageIdSite(languageId || languageObjectSite[reduxAuth.settings?.language!]))
            if (process.env.NODE_ENV === 'development') console.log(pageContentApiUrl)
            axios({
                cancelToken: source.token,
                headers: refreshAxiosHeaders,
                method: 'get',
                url: pageContentApiUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!noScollTop && contentRef && contentRef.current) {
                        // @ts-ignore
                        contentRef.current.scrollTop = 0
                    }
                    dispatch(reduxFormSetDataSite('pageContentItems', response.data.results))
                    setIsLoading(false)
                    scrollToSection(params.panelValue, 0)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: pageContentApiUrl,
                        component: 'MixedContentPreview',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getPortfolioData',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'MixedContentPreview',
                'getPortfolioData',
            ))
        }
    }

    const activePageContentItems: PortfolioPageContentListSiteSerializer[] = []
    reduxFormSite.pageContentItems?.map((val) => {
        if (val.active) activePageContentItems.push(val)
    })

    const fontFamilies = getGoogleFontsSite(fonts)

    return (
        <div className='mixed-content-preview'>
            {fonts && (
                <>
                    {fontFamilies.custom?.length > 0 && (
                        <FontLoader
                            customUrls={fontFamilies.customUrls}
                            fontFamilies={fontFamilies.custom}
                            fontProvider='custom'
                            onActive={() => {
                                document.body.classList.add('webfonts-loaded')
                            }}
                        />
                    )}
                    {fontFamilies.google?.length > 0 && (
                        <FontLoader
                            fontProvider='google'
                            fontFamilies={fontFamilies.google}
                            onActive={() => {
                                document.body.classList.add('webfonts-loaded')
                            }}
                        />
                    )}
                </>
            )}
            <div className='slug-page-content-wrap'>
                <div
                    ref={contentRef}
                    className={`slug-page-content-div mo-hidden-vertical-scrollbar${reduxAuth.isWindows} ${isLoading ? 'opacity' : 'no-opacity'}`}
                >
                    <div
                        className='slug-page-content'
                        style={newPageStyles}
                    >
                        <div
                            className={`slug-page-content-list${languageId === 19 ? ' rtl' : ''}`}
                            style={{
                                padding: getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'padding'),
                            }}
                        >
                            {activePageContentItems.map((val) => {
                                if (edit) {
                                    return (
                                        <div
                                            key={val.id}
                                            className='hpc-selection-helper'
                                        >
                                            <SelectionHelperMixedMediaContent
                                                contentType={contentType}
                                                newMixedMediaContent={newMixedMediaContent!}
                                                newMixedMediaContentType={newMixedMediaContentType}
                                                mother={mother}
                                                object={val}
                                            />
                                            <ContentBlockSite
                                                detailId={undefined}
                                                devId={undefined}
                                                isEditHovered={reduxFormSite.hoveredBlockId === val.id}
                                                linkColor={linkColor}
                                                object={val}
                                                pageSlug={undefined}
                                                stylesEdit={reduxFormSite.editAutoSave[val.id!]?.styles || reduxFormSite.editAutoSaved[val.id!]?.styles}
                                            />
                                        </div>
                                    )
                                }
                                return (
                                    <ContentBlockSite
                                        key={val.id}
                                        detailId={undefined}
                                        devId={undefined}
                                        isEditHovered={false}
                                        linkColor={linkColor}
                                        object={val}
                                        pageSlug={undefined}
                                        stylesEdit={undefined}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <Loader isOpen />
            )}
            <LightboxSite />
        </div>
    )
})
