// react components
import React, {
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
} from '@ionic/react'

// components
import {
    LinkHelper,
} from 'components'

// pages
import {
    EditButtonPage,
    PageListDetailHelperModal,
} from 'pages'

// serializers
import {
    PortfolioFormState,
    PortfolioPageListFormSerializer,
} from 'serializers/web'

// props
type PageListBlockDetailSubContentTypeProps = {
    portfolioId: number
    object: PortfolioPageListFormSerializer
    page: PortfolioFormState['page']
    profileId: number
    refreshData: () => void
}

// main
export const PageListBlockDetailSubContentType: React.FC<PageListBlockDetailSubContentTypeProps> = React.memo(({
    portfolioId,
    object,
    page,
    profileId,
    refreshData,
}) => {

    const [isOpen, setIsOpen] = useState(false)

    if (object.child_pages?.length! > 0) {
        return (
            <>
                <IonItem>
                    <IonLabel>
                        <span
                            className='lpppw-page-name'
                        >
                            {object.name}
                        </span>
                    </IonLabel>
                    {isOpen && (
                        <PageListDetailHelperModal
                            page={object}
                            portfolioId={portfolioId}
                            profileId={profileId}
                            setIsOpen={setIsOpen}
                            subContentType={object.sub_content_type}
                        />
                    )}
                </IonItem>
                {object.child_pages?.map(val => (
                    <PageListBlockDetailSubContentType
                        key={val.id}
                        object={val}
                        page={page}
                        portfolioId={portfolioId}
                        profileId={profileId}
                        refreshData={refreshData}
                    />
                ))}
            </>
        )
    }

    return (
        <IonItem>
            <IonLabel>
                <LinkHelper
                    className='lpppw-page-name pointer'
                    edit={false}
                    onClick={() => setIsOpen(true)}
                >
                    {object.name}
                </LinkHelper>
            </IonLabel>
            <EditButtonPage
                object={object}
                page={page}
                refreshData={refreshData}
            />
            {isOpen && (
                <PageListDetailHelperModal
                    page={object}
                    portfolioId={portfolioId}
                    profileId={profileId}
                    setIsOpen={setIsOpen}
                    subContentType={object.sub_content_type}
                />
            )}
        </IonItem>
    )
})
