// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// components
import {
    Button,
    IconBlock,
} from 'components'

// props
type DetailContentPreviewProps = {
    object: {
        portfolio_page_preview_urls?: string[]
    }
}

// main
export const DetailContentPreview: React.FC<DetailContentPreviewProps> = React.memo(({
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [previewUrl, setPreviewUrl] = useState<(string)>(object.portfolio_page_preview_urls![0])

    return (
        <div className='detail-component-preview'>
            <div className='dc-iframe-tabs'>
                {object.portfolio_page_preview_urls!.sort().map(val => (
                    <React.Fragment key={val}>
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-arrow-up-right-from-square-solid'
                            href={val}
                        />
                        <Button
                            className={(val === previewUrl) ? 'dc-iframe-active' : ''}
                            edit={false}
                            fill='clear'
                            justifyText='flex-start'
                            onClick={() => setPreviewUrl(val)}
                            size='x-small'
                            text={val}
                        />
                    </React.Fragment>
                ))}
                {/* <div className='staff-helper-site'>
                    <a
                        className='main-icon-block-site'
                        href={`https://app.justtheweb.io/form/website/${reduxCacheSiteportfolio?.id}/page-container/-/${pageSlug || reduxCacheSiteportfolio?.home_page_slug}/${detailId || ''}`}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <i className='main-icon mo-new-icon-pencil-alt-solid' />
                    </a>
                    {reduxAuth.settings?.user?.id === 1 && (
                        <>
                            <a
                                className='main-icon-block-site ml'
                                href={`http://localhost:8100/form/website/${reduxCacheSiteportfolio?.id}/page-container/-/${pageSlug || reduxCacheSiteportfolio?.home_page_slug}/${detailId || ''}`}
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                <p>dev/form/website</p>
                            </a>
                            {hostOriginSite === 'prod'
                                ? (
                                    <a
                                        className='main-icon-block-site ml'
                                        href={`http://localhost:8101/${reduxCacheSiteportfolio?.id}${window.location.pathname}${window.location.search}`}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        <p>dev/{reduxCacheSiteportfolio?.id}</p>
                                    </a>
                                ) : (
                                    <a
                                        className='main-icon-block-site ml'
                                        href={`http://${reduxCacheSiteportfolio?.hostname}${window.location.pathname.replace(`${reduxCacheSiteportfolio?.id}/`, '')}${window.location.search}`}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        <p>prod/{reduxCacheSiteportfolio?.id}</p>
                                    </a>
                                )
                            }
                            {detailId && (
                                <a
                                    className='main-icon-block-site ml'
                                    href={`https://app.justtheweb.io/detail/${pageObject?.seo_data?.extra_data?.content_type_label_model}/${pageObject?.seo_data?.extra_data?.detail_instance_id}`}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <p>prod/{pageObject?.seo_data?.extra_data?.content_type_label_model}</p>
                                </a>
                            )}
                            {detailId && (
                                <a
                                    className='main-icon-block-site ml'
                                    href={`http://localhost:8100/detail/${pageObject?.seo_data?.extra_data?.content_type_label_model}/${pageObject?.seo_data?.extra_data?.detail_instance_id}`}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <p>dev/{pageObject?.seo_data?.extra_data?.content_type_label_model}</p>
                                </a>
                            )}
                        </>
                    )}
                </div> */}
            </div>
            <iframe
                className='dc-iframe'
                src={`${previewUrl}${previewUrl.includes('?') ? '&' : '?'}forcedDeviceType=is-web`}
            />
        </div>
    )
})
