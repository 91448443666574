// react components
import React from 'react'

// props
type ErrorHelperProps = {
    error: string | undefined
}

// main
export const ErrorHelper: React.FC<ErrorHelperProps> = React.memo(({
    error,
}) => {

    if (error) {
        return (
            <span className='error-helper-web'>{error}</span>
        )
    }
    return null
})
