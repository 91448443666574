// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_main_medium_type_service,
} from 'data'

// serializers
import {
    ServiceDetailFormSerializer,
    ServiceDetailFormSerializerError,
} from 'serializers/web'

// props
type MediumTypeServiceInputProps = {
    errors: ServiceDetailFormSerializerError | undefined
    fields: ServiceDetailFormSerializer
    onChange: any
}

// main
export const MediumTypeServiceInput: React.FC<MediumTypeServiceInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <SelectListInput
            apiUrl={select_url_main_medium_type_service}
            clearable={false}
            error={errors?.medium_type}
            label={reduxText[3228]}
            name='medium_type'
            onChange={onChange}
            value={fields.medium_type || ''}
        />
    )
})
