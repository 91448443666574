// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
    IonList,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
} from 'data'

// serializers
import {
    MenuFormPanelProps,
    ProfileFormState,
} from 'serializers/web'

// props
type MenuProfilePanelProps = {
    formState: ProfileFormState
}

// main
export const MenuProfilePanel: React.FC<MenuFormPanelProps & MenuProfilePanelProps> = React.memo(({
    formState,
    helpers,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [formPanels, setFormPanels] = useState<{
        formType: string
        memberId?: number
        panelValue: string
        primary: string
    }[]>([])

    const mother = formState.mother
    const fieldsMother = mother.fields

    useEffect(() => {

        const tempFormPanels: {
            formType: string
            memberId?: number
            panelValue: string
            primary: string
        }[] = []

        tempFormPanels.push(
            {
                formType: 'main',
                panelValue: 'main-panel',
                primary: reduxText[347],
            },
            {
                formType: 'main',
                panelValue: 'additional-panel',
                primary: reduxText[3234],
            },
            {
                formType: 'inline',
                panelValue: 'link-panel',
                primary: reduxText[5416],
            },
            {
                formType: 'inline',
                panelValue: 'team-panel',
                primary: reduxText[4917],
            },
            {
                formType: 'inline',
                panelValue: 'image-panel',
                primary: reduxText[4451],
            },
        )

        tempFormPanels.push(
            {
                formType: 'main',
                panelValue: 'website-related-panel',
                primary: reduxText[6415],
            },
        )

        fieldsMother?.member_info?.map(val => {
            tempFormPanels.push(
                {
                    formType: 'special',
                    memberId: val.id,
                    panelValue: 'member-panel',
                    primary: `${reduxText[8520]} ${val.profile?.name}`,
                },
            )
        })

        if (reduxAuth.settings?.active_profile?.id === 179976) {
            tempFormPanels.push(
                {
                    formType: 'inline',
                    panelValue: 'carte-category-panel',
                    primary: reduxText[6372],
                },
            )
        }

        tempFormPanels.push(
            {
                formType: 'main',
                panelValue: 'delete-panel',
                primary: fieldsMother?.active ? `${reduxText[3517]} / ${reduxText[2983]}` : reduxText[2983],
            },
        )

        setFormPanels(tempFormPanels)
    }, [
        fieldsMother?.active,
        reduxText,
    ])

    return (
        <IonList>
            {formPanels.map((val) => {
                if (val.panelValue === 'delete-panel') {
                    return (
                        <IonItem
                            key={val.panelValue}
                            button
                            disabled={!(fieldsMother?.id && !(reduxAuth.settings?.user?.is_staff && reduxAuth.settings?.main_profile?.id === fieldsMother?.id) && (fieldsMother?.id !== 177347))}
                            onClick={() => setHelpers({ ...helpers, isActionSheetOpen: true })}
                        >
                            <IonLabel className='mo-item-list-label'>
                                <h2>{val.primary}</h2>
                            </IonLabel>
                        </IonItem>
                    )
                }
                if (val.panelValue === 'member-panel') {
                    return (
                        <IonItem
                            key={`${val.panelValue} - ${val.memberId}`}
                            disabled={!(fieldsMother?.id)}
                            onClick={() => dispatch(reduxFormSetIsSuccessToastOpen(false))}
                            routerLink={`${mother.formUrl}${fieldsMother?.id}/${val.formType}/${val.panelValue}/?member_id=${val.memberId}`}
                        >
                            <IonLabel className='mo-item-list-label'>
                                <h2>{val.primary}</h2>
                            </IonLabel>
                        </IonItem>
                    )
                }
                return (
                    <IonItem
                        key={val.panelValue}
                        disabled={!(fieldsMother?.id)}
                        onClick={() => dispatch(reduxFormSetIsSuccessToastOpen(false))}
                        routerLink={`${mother.formUrl}${fieldsMother?.id}/${val.formType}/${val.panelValue}/${location.search}`}
                    >
                        <IonLabel className='mo-item-list-label'>
                            <h2>{val.primary}</h2>
                        </IonLabel>
                    </IonItem>
                )
            })}
        </IonList>
    )
})
