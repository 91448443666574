// react components
import React from 'react'
import PhoneInput from 'react-phone-number-input'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// styles
import 'react-phone-number-input/style.css'

// components
import {
    ErrorHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FormLabel,
} from 'pages'

// props
type PhoneNumberInputV2Props = {
    adminOnly?: boolean
    disabled?: boolean
    error?: string
    helperText?: string
    label?: string
    name: string
    onChange: any
    onRefresh?: () => void
    staffOnly?: boolean
    value: any
}

// main
export const PhoneNumberInputV2: React.FC<PhoneNumberInputV2Props> = React.memo(({
    adminOnly,
    disabled,
    error,
    helperText,
    label,
    name,
    onChange,
    onRefresh,
    staffOnly,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    function onInputChange(value: string) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PhoneNumberInputV2',
                'onInputChange',
            ))
        }
    }

    if (adminOnly && !reduxAuth.settings?.user?.id) return null
    if (staffOnly && !reduxAuth.settings?.user?.is_staff) return null

    if (label) {
        return (
            <div className={`input-wrap${disabled ? ' disabled' : ''}`}>
                <div className='input-label-wrap'>
                    <FormLabel
                        adminOnly={adminOnly}
                        formInput='text'
                        helperText={helperText}
                        label={label || ''}
                        name={name}
                        onRefresh={onRefresh}
                        staffOnly={staffOnly}
                        translationDefaultValue={value}
                    />
                </div>
                <div className='phone-number-input'>
                    <PhoneInput
                        defaultCountry='FR'  // TODO dynamic
                        disabled={disabled}
                        onChange={onInputChange}
                        value={value || ''}
                    />
                </div>
                <ErrorHelper error={error} />
            </div>
        )
    }

    return (
        <div className='phone-number-input'>
            <PhoneInput
                defaultCountry='FR'  // TODO dynamic
                disabled={disabled}
                onChange={onInputChange}
                value={value || ''}
            />
        </div>
    )
})
