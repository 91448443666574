// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProductSalesRecapListSerializer,
} from 'serializers/web'

// props
type ProductSalesRecapRowProps = {
    index: number
    object: ProductSalesRecapListSerializer
}

// main
export const ProductSalesRecapRow: React.FC<ProductSalesRecapRowProps> = React.memo(({
    index,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <React.Fragment>
            {(index === 0 && object.totals?.is_variant) && (
                <tr className='main-list-row total'>
                    <td>{reduxText[8638]}</td>
                    <td className='tar'>{object.totals?.total_qty}</td>
                    <td className='tar'>{object.totals?.total_amount} {object.totals?.currency}</td>
                </tr>
            )}
            <tr className='main-list-row ProductSalesRecapRow'>
                <td>{object.name}</td>
                <td className='tar'>{object.totals?.qty}</td>
                <td className='tar'>{object.totals?.amount} {object.totals?.currency}</td>
            </tr>
        </React.Fragment>
    )
})
