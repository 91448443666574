// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProductDetailFormSerializer,
    ProductDetailFormSerializerError,
} from 'serializers/web'

// props
type HasStockManagementProductInputProps = {
    errors: ProductDetailFormSerializerError | undefined
    fields: ProductDetailFormSerializer
    onChange: any
}

// main
export const HasStockManagementProductInput: React.FC<HasStockManagementProductInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <CheckboxInput
            error={errors?.has_stock_management}
            helperText={reduxText[803310]}
            label={reduxText[8033]}
            name='has_stock_management'
            onChange={onChange}
            value={fields.has_stock_management}
        />
    )
})
