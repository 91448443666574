// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonLabel,
    IonSegment,
    IonSegmentButton,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    ListSpinner,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    DetailPagePortfolioPanel,
    ListPageContentPortfolioPanel,
    StylePagePortfolioPanel,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
    detailId?: string
    pageSlug: string
}

type ContainerPagePortfolioPanelProps = {
    formState: PortfolioFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    setFormState: React.Dispatch<PortfolioFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const ContainerPagePortfolioPanel: React.FC<ContainerPagePortfolioPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const page = formState.page

    useEffect(() => {
        onGetData()
    }, [
        params.pageSlug,
        reduxAuth.apiRootUrl,
    ])

    const [isLoading, setIsLoading] = useState(false)
    const [tabValue, setTabValue] = useState<string | number | undefined>('contents')

    async function onGetData() {
        try {
            if (params.pageSlug === 'extra' && params.detailId === 'hub') return
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ContainerPagePortfolioPanel onGetData')

            const getUrl = `${page.detailUrl}${params.pageSlug || mother.fields?.home_page_slug}/?portfolio_id=${params.id}${params.detailId ? `&detail_id=${params.detailId}` : ''}`
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setFormState({
                        ...formState,
                        page: {
                            ...formState.page,
                            fields: response.data,
                        }
                    })
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ContainerPagePortfolioPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onGetData',
                        skipAlertStatusCode: [403, 404],
                        skipSend404Detail: true,
                        skipSendErrorStatusCode: [403, 404],
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContainerPagePortfolioPanel',
                'onGetData',
            ))
        }
    }

    // Mother
    const fieldsMother = page.fields

    if (!fieldsMother?.id) return null

    return (
        <div className={`pfcw-container mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className='pfcw-segment-wrap'>
                <IonSegment
                    className='pfcw-segment'
                    color=''
                    onIonChange={(e) => setTabValue(e.detail.value)}
                    value={tabValue}
                >
                    <IonSegmentButton
                        className='pfcw-tab-button'
                        value='contents'
                    >
                        <IonLabel className='pfcw-tab-label'>{reduxText[8147]}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                        className='pfcw-tab-button'
                        value='styles'
                    >
                        <IonLabel className='pfcw-tab-label'>{reduxText[6411]}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                        className='pfcw-tab-button'
                        value='settings'
                    >
                        <IonLabel className='pfcw-tab-label'>{reduxText[5332]}</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </div>
            {isLoading
                ? (
                    <ListSpinner
                        color='tertiary'
                        isLoading
                    />
                ) : (
                    <React.Fragment>
                        {tabValue === 'contents' && (
                            <ListPageContentPortfolioPanel
                                formState={formState}
                            />
                        )}
                        {tabValue === 'settings' && (
                            <DetailPagePortfolioPanel
                                formState={formState}
                                handleInputChange={handleInputChange}
                                helpers={helpers}
                                setFormState={setFormState}
                                setHelpers={setHelpers}
                            />
                        )}
                        {tabValue === 'styles' && (
                            <StylePagePortfolioPanel
                                formState={formState}
                                setFormState={setFormState}
                            />
                        )}
                    </React.Fragment>
                )
            }
        </div>
    )
})
