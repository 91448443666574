// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DeleteAlert,
    Popover,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    UserPortfolioListFormSerializer,
} from 'serializers/web'

// props
type UserPortfolioListFormBlockProps = {
    handleInlineActive: (e: UserPortfolioListFormSerializer) => void
    handleInlineDelete: (e: UserPortfolioListFormSerializer) => void
    handleInlineRetrieve: (e: UserPortfolioListFormSerializer) => void
    object: UserPortfolioListFormSerializer
}

// main
export const UserPortfolioListFormBlock: React.FC<UserPortfolioListFormBlockProps> = React.memo(({
    handleInlineActive,
    handleInlineDelete,
    handleInlineRetrieve,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [showActionPopover, setShowActionPopover] = useState(false)

    const buttons = [
        {
            text: reduxText[2105],
            handler: () => {
                handleInlineRetrieve(object)
                setShowActionPopover(false)
            }
        },
        {
            text: object?.active ? reduxText[5296] : reduxText[5297],
            handler: () => {
                handleInlineActive(object)
                setShowActionPopover(false)
            }
        },
        {
            text: reduxText[2983],
            handler: () => {
                setIsDeleteAlertOpen(true)
                setShowActionPopover(false)
            }
        },
    ]

    return (
        <tr className='main-list-row UserPortfolioListFormBlock'>
            <td>
                <div className='tfw-name-div'>
                    <p className='tfw-name'>{object.email}</p>
                    {!object.active && (
                        <p className='tfw-active-status not-active'>{reduxText[5616]}</p>
                    )}
                </div>
            </td>
            <td className='mlr-action'>
                <Popover
                    buttons={buttons}
                    extraZIndex
                    edit={false}
                    isOpen={showActionPopover}
                    setIsOpen={setShowActionPopover}
                />
            </td>
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={() => handleInlineDelete(object)}
                    objectName={object.email}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
        </tr>
    )
})
