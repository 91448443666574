// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Gallery,
    ImageGalleryHelper,
    InfiniteScrollHelperWeb,
    LightboxCustom,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AdherentListHeader,
    AdherentListSmallBlock,
    ContributionListHeader,
    ContributionListSmallBlock,
    ParticipantEventListHeader,
    ParticipantEventListRow,
    ParticipantListHeader,
    ParticipantListRow,
    PricingListHeader,
    PricingListRow,
    ProductSalesRecapRow,
    ProductSalesRecapRowHeader,
} from 'pages'

// services
import {
    getInfiniteListWebNew,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// props
type DetailListContainerNewProps = {
    isGallery?: boolean
    inModal?: boolean
    listApiUrl: string
    listComponent?: string
    tableHeaderComponent?: string
}

// main
export const DetailListContainerNew: React.FC<DetailListContainerNewProps> = React.memo(({
    isGallery,
    inModal,
    listApiUrl,
    listComponent,
    tableHeaderComponent,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [imageIndex, setImageIndex] = useState<number>(0)
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb>({
        ...listStateInitialWeb,
        componentString: listComponent!,
        modalKey: Math.random(),
    })

    useEffect(() => {
        onGetListData(listApiUrl, true)
    }, [
        listApiUrl,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    const tableHeaderComponents: any = {
        AdherentListHeader,
        ContributionListHeader,
        ParticipantEventListHeader,
        ParticipantListHeader,
        PricingListHeader,
        ProductSalesRecapRowHeader,
    }

    const TableHeaderComponentName = tableHeaderComponents[tableHeaderComponent!]

    const listComponents: any = {
        AdherentListSmallBlock,
        ContributionListSmallBlock,
        ParticipantEventListRow,
        ParticipantListRow,
        PricingListRow,
        ProductSalesRecapRow,
    }

    const ListComponentName = listComponents[listComponent!]

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWebNew({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'DetailListContainerNew',
                componentName: listComponent!,
                dispatch,
                listState,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerNew',
                'onGetListData',
            ))
        }
    }

    function onImageClick(obj: any, index: any) {
        setIsLightBoxOpen(true)
        setImageIndex(index)
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerNew',
                'onSearchNext',
            ))
        }
    }

    if (TableHeaderComponentName) {
        return (
            <div className='detail-list-container-new table mo-hidden-horizontal-scrollbar'>
                <table>
                    <thead>
                        {<TableHeaderComponentName
                            inDetailList
                            object={listState.items?.[0]}
                        />}
                    </thead>
                    <tbody>
                        {listState.items.map((item, i) => {
                            return (
                                <ListComponentName
                                    key={item.id}
                                    edit={false}
                                    inDetailList
                                    inModal={inModal}
                                    index={i}
                                    modalKey={listState.modalKey}
                                    object={item}
                                />
                            )
                        })}
                    </tbody>
                </table>
                <InfiniteScrollHelperWeb
                    active={!listState.disableInfiniteScroll}
                    hasMore={listState.hasMore}
                    isLoading={listState.isLoading}
                    items={listState.items}
                    onChange={onSearchNext}
                />
            </div>
        )
    }

    return (
        <div className='detail-list-container-new mo-hidden-vertical-scrollbar'>
            <div className={`dlcn-infinite${isGallery ? ' gallery' : ''}`}>
                {isGallery
                    ? (
                        listState.items.length > 0 && (
                            <Gallery
                                // @ts-ignore
                                direction='row'
                                limitNodeSearch={2}
                                margin={2}
                                photos={listState.items}
                                // @ts-ignore
                                renderImage={(props) => (
                                    <ImageGalleryHelper
                                        {...props}
                                        direction='row'
                                        onClick={(obj: any, index: any) => onImageClick(obj, index)}
                                    />
                                )}
                            />
                        )
                    ) : (
                        listState.items.map((item) => {
                            return (
                                <ListComponentName
                                    key={item.id}
                                    edit={false}
                                    modalKey={listState.modalKey}
                                    object={item}
                                />
                            )
                        })
                    )}
            </div>
            <InfiniteScrollHelperWeb
                active={!listState.disableInfiniteScroll}
                hasMore={listState.hasMore}
                isLoading={listState.isLoading}
                items={listState.items}
                onChange={onSearchNext}
            />
            {isLightBoxOpen && isGallery && (
                <LightboxCustom
                    mainSrc={listState.items[imageIndex].get_image_hq || ''}
                    nextSrc={listState.items[(imageIndex + 1) % listState.items.length].get_image_hq}
                    onCloseRequest={() => setIsLightBoxOpen(false)}
                    onMoveNextRequest={() => listState.items && setImageIndex((imageIndex + 1) % listState.items.length)}
                    onMovePrevRequest={() => listState.items && setImageIndex((imageIndex + listState.items.length - 1) % listState.items.length)}
                    prevSrc={listState.items[(imageIndex + listState.items.length - 1) % listState.items.length].get_image_hq}
                />
            )}
        </div>
    )
})
