// react components
import React from 'react'

// pages
import {
    AdherentDetailBlock,
    BlockTemplateDetailBlock,
    EventDetailBlockWeb,
    MaterialDetailBlockWeb,
    MemberDetailBlock,
    OfferDetailBlockWeb,
    PageTemplateDetailBlock,
    ProductDetailWrapper,
    ProfileDetailBlock,
    ProfileDetailBlockWebOld,
    ProjectDetailBlockWeb,
    SalesOrderDetailBlock,
    ServiceDetailBlock,
} from 'pages'

// props
type PreviewBlockWebProps = {
    detailComponent: string
    object: any
}

// main
export const PreviewBlockWeb: React.FC<PreviewBlockWebProps> = React.memo(({
    detailComponent,
    object,
}) => {

    const componentsDetail: any = {
        AdherentDetailBlock,
        BlockTemplateDetailBlock,
        EventDetailBlockWeb,
        MaterialDetailBlockWeb,
        MemberDetailBlock,
        OfferDetailBlockWeb,
        PageTemplateDetailBlock,
        ProductDetailWrapper,
        ProfileDetailBlock,
        ProfileDetailBlockWebOld,
        ProjectDetailBlockWeb,
        SalesOrderDetailBlock,
        ServiceDetailBlock,
    }
    const DetailComponentName = componentsDetail[detailComponent]

    if (!object) return null

    return (
        <div className='detail-view-preview-web'>
            <DetailComponentName
                edit
                modalKeys={{}}
                object={object}
            />
        </div>
    )
})
