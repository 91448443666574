// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Loader,
} from 'components'

// data
import {
    api_url_profile_admin_detail,
    api_url_profile_staff_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
} from 'data'

// pages
import {
    AdminData,
    AdminHome,
    AdminMenu,
    AdminModule,
    NotFoundPage,
} from 'pages'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getDetailObject,
    getApiUrlV2,
} from 'services'

// props
type MatchParams = {
    pageName: string
    profileId: string
}

type AdminMenuItemProps = {
    isStaff: boolean
}

// main
export const AdminWrapper: React.FC<AdminMenuItemProps> = React.memo(({
    isStaff,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)

    useEffect(() => {
        onGetDetailData()
    }, [
        params.profileId,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxAuth.settings?.user?.id,
    ])

    useEffect(() => {
        if (profileObject?.id && reduxForm.refreshId.refreshProfile === profileObject.id) {
            onGetDetailData()
        }
    }, [
        reduxForm.refresh.refreshProfile,
    ])

    const [isLoading, setIsLoading] = useState(false)

    const [errorStatus, setErrorStatus] = useState<number>()
    const [profileObject, setDetailObject] = useState<ProfileDetailAdminSerializerV2 | undefined>(undefined)

    function onGetDetailData() {
        const baseUrl = isStaff ? api_url_profile_staff_detail : api_url_profile_admin_detail
        let profileId
        if (isStaff) {
            profileId = reduxAuth.settings?.user?.id === 1
                ? params.profileId || reduxAuth.settings?.active_profile?.id
                : reduxAuth.settings?.active_profile?.id
        } else {
            profileId = params.profileId
        }
        const fullUrl = `${baseUrl}${profileId}/`
        if (!profileId) return
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(fullUrl, reduxAuth),
                component: 'AdminWrapper',
                customFunction: (data) => dispatch(reduxModalSeoData(data.seo_data)),
                dispatch,
                reduxAuth,
                setDetailObject: (data: ProfileDetailAdminSerializerV2) => {
                    if (isStaff) data.get_absolute_url = data.get_absolute_url?.replace('/admin/', '/staff/')
                    setDetailObject(data)
                },
                setErrorStatus,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminWrapper',
                'onGetDetailData',
            ))
        }
    }

    if (errorStatus || (params.pageName && !['data', 'section'].includes(params.pageName)) || params.pageName === 'data' && !reduxAuth.settings?.user?.is_staff) {
        return <NotFoundPage statusCode={errorStatus} />
    }

    if (!profileObject) {
        return (
            <IonPage className='admin-wrapper navbar-pt'>
                <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                    <Loader isOpen />
                </IonContent>
            </IonPage>
        )
    }

    return (
        <IonPage className='admin-wrapper navbar-pt'>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='aw-content'>
                    <div className={`aw-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <AdminMenu
                            isStaff={isStaff}
                            profileObject={profileObject}
                            refreshData={onGetDetailData}
                        />
                    </div>
                    <div className='aw-right'>
                        {params.pageName === 'data' && (
                            <AdminData profileObject={profileObject} />
                        )}
                        {params.pageName === 'section' && (
                            <AdminModule
                                isStaff={isStaff}
                                profileObject={profileObject}
                                refreshData={onGetDetailData}
                            />
                        )}
                        {!params.pageName && <AdminHome />}
                    </div>
                </div>
                {isLoading && <Loader isOpen />}
            </IonContent>
        </IonPage>
    )
})
