// react components
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'

// data
import {
    reduxAuthState,
} from 'data'

// main
export function getDateWithFormat(
    reduxAuth: reduxAuthState,
    date: string | undefined,
    format: string | undefined,
    isTime?: boolean,
) {

    if (!date) return ''

    if (isTime) {
        return moment(date, "HH:mm:ss").format(format)
    }

    moment.locale('en') // default the locale to English
    const localDate = moment(date)

    if (reduxAuth.settings?.language) {
        if (reduxAuth.settings.language === 'fr') localDate.locale('fr') // set this instance to use French
    }

    return `${localDate.format(format)}`
}
