// react components
import React, {
    useState,
} from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    CheckboxInputV2,
    SelectListInputV2,
    TextInputV2,
} from 'pages'

// serializers
import {
    MainIdNameSerializer,
    PortfolioFormState,
    PortfolioPageListFormSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// props
type AddPageBlankPageHelperProps = {
    detailPage?: boolean
    duplicateSource?: PortfolioPageListFormSerializer
    page: PortfolioFormState['page']
    setIsOpen: React.Dispatch<boolean>
}

// main
export const AddPageBlankPageHelper: React.FC<AddPageBlankPageHelperProps> = React.memo(({
    detailPage,
    duplicateSource,
    page,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [subContentType, setSubContentType] = useState<MainIdNameSerializer>()
    const [subContentTypeError, setSubContentTypeError] = useState('')
    const [pageName, setPageName] = useState('')
    const [pageNameError, setPageNameError] = useState('')
    const [withTranslation, setWithTranslation] = useState(false)

    async function handleSubmit() {
        try {
            if (!pageName) {
                setPageNameError(reduxText[2856])
                setButtonDisabled(true)
                return
            }
            if (detailPage && !subContentType) {
                setSubContentTypeError(reduxText[2856])
                setButtonDisabled(true)
                return
            }
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AddPageBlankPageHelper handleSubmit')

            const postUrl = page!.createUrl
            const formData: any = new FormData()
            formData.append('name', pageName)
            formData.append('portfolio', reduxFormSite.portfolioObject!.id)
            if (subContentType) {
                formData.append('medium_type', 40)
                formData.append('sub_content_type', subContentType.id)
            }
            if (duplicateSource) {
                formData.append('duplicate', 'true')  // TODO DUPLICATE
                formData.append('page_source', duplicateSource.id)
                if (withTranslation) {
                    formData.append('duplicate_with_translation', 'true')
                }
            }
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setIsOpen(false)
                    history.push(`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${response.data.slug}/`)
                })
                .catch((error) => {
                    setIsLoading(false)
                    let skipError = false
                    if (error.response?.data?.name) {
                        setPageNameError(error.response?.data?.name)
                        skipError = true
                    }
                    if (error.response?.data?.sub_content_type) {
                        setSubContentTypeError(error.response?.data?.sub_content_type)
                        skipError = true
                    }
                    if (!skipError) {
                        axiosErrorHandler({
                            apiUrl: postUrl,
                            component: 'AddPageBlankPageHelper',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleSubmit',
                        })
                    }
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddPageBlankPageHelper',
                'handleSubmit',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class AddPageBlankPageHelper'
            classes={{ paper: 'mo-dialog-wrapper fix-height-full fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {duplicateSource ? reduxText[8486] : reduxText[6448]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <TextInputV2
                    error={pageNameError}
                    label={reduxText[5559]}
                    name='name'
                    onChange={(e: any) => {
                        setPageName(e.value)
                        setButtonDisabled(false)
                    }}
                    value={pageName || ''}
                />
                {duplicateSource && (
                    <CheckboxInputV2
                        error={undefined}
                        label={reduxText[8477]}
                        name='duplicate_with_translation'
                        onChange={(e: any) => {
                            setWithTranslation(e.value)
                            setButtonDisabled(false)
                        }}
                        value={withTranslation}
                    />
                )}
                {detailPage && (
                    <SelectListInputV2
                        apiUrl={`setup/select/subcontenttype/?profile=${reduxFormSite.portfolioObject?.profile?.id}`}
                        clearable={false}
                        error={subContentTypeError}
                        label='sub_content_type'  // TO_TEXT
                        name='sub_content_type'
                        onChange={(e: any) => {
                            setSubContentType(e.value)
                            setButtonDisabled(false)
                        }}
                        value={subContentType || ''}
                    />
                )}
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen(false)}
                    text={reduxText[4519]}
                />
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    fill='outline'
                    onClick={handleSubmit}
                    text={reduxText[4520]}
                />
            </DialogActions>
            {isLoading && (
                <Loader isOpen />
            )}
        </Dialog>
    )
})
