// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    IconBlock,
    LightboxCustom,
    LinkHelper,
} from 'components'

// data
import {
    contentTypeModelType,
    fieldTypeLayout,
    viewUrl,
} from 'data'

// pages
import {
    TableColumnValue,
} from 'pages'

// serializers
import {
    ContentTypeSettingsCustomTypes,
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getModalAbsoluteUrlV2,
    onClickIsModal,
} from 'services'

// props
type TableColumnProps = {
    addProfileColumn?: boolean
    conditionStyles?: any
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom?: ContentTypeSettingsCustomTypes
    field: string
    modalKey: number
    object: any
    profileObject: ProfileDetailAdminSerializerV2 | undefined
    refreshData?: () => void
    setFilter?: (e: any, f?: any) => void
}

// main
export const TableColumn: React.FC<TableColumnProps> = React.memo(({
    addProfileColumn,
    conditionStyles,
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    field,
    modalKey,
    object,
    profileObject,
    refreshData,
    setFilter,
}) => {

    const dispatch = useDispatch()

    const [imageIndex, setImageIndex] = useState<number>(0)
    const [isLightBoxOpen, setIsLightBoxOpen] = useState<number>()

    const newColumn = field.split('__')[0]
    const fieldSettings = contentTypeSettings.field?.[newColumn]

    if (addProfileColumn && field === 'profile' && !fieldSettings) return null
    if (field === 'images_temp') return null  // TODO dynamic
    if (contentType === 'staff_staffmessage' && field === 'get_image_lq') return null  // TODO dynamic

    if (fieldSettings?.layout?.isHref) {
        return (
            <td
                className={`table-column 1 field-${fieldSettings?.fieldType || 'error'}`}
                style={conditionStyles}
            >
                {object[field] && (
                    <>
                        {!contentTypeSettings.meta.disableDetailView && (
                            <LinkHelper
                                className='tc-link'
                                edit={false}
                                onClick={() => onClickIsModal(object.id!, modalKey, dispatch)}
                                to={getModalAbsoluteUrlV2({
                                    contentType,
                                    objectId: object.id!,
                                    pageType: 'detail',
                                    tabId: fieldSettings?.layout?.panelIdToOpen,
                                })}
                            />
                        )}
                        <a
                            className='tc-link-href'
                            href={object[field]}
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            <div className='tc-link-div'>
                                <TableColumnValue
                                    contentType={contentType}
                                    contentTypeSettings={contentTypeSettings}
                                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                                    field={field}
                                    fieldSettings={fieldSettings}
                                    object={object}
                                    profileObject={profileObject}
                                    refreshData={refreshData}
                                    setFilter={setFilter}
                                />
                            </div>
                        </a>
                    </>
                )}
            </td>
        )
    }

    if (['ForeignKey', 'GenericForeignKey', 'OneToOneField'].includes(fieldSettings?.fieldType) && fieldSettings?.layout?.isRelationLinkList && object[field]) {
        return (
            <td
                className={`table-column 2 field-${fieldSettings?.fieldType || 'error'}`}
                style={conditionStyles}
            >
                {!contentTypeSettings.meta.disableDetailView && (
                    <LinkHelper
                        className='tc-link'
                        edit={false}
                        onClick={() => onClickIsModal(object.id!, modalKey, dispatch)}
                        to={getModalAbsoluteUrlV2({
                            contentType,
                            objectId: object.id!,
                            pageType: 'detail',
                            tabId: fieldSettings?.layout?.panelIdToOpen,
                        })}
                    />
                )}
                {contentType === 'staff_staffmessage' && field === 'project' && object.get_image_lq && (
                    <div
                        className='tc-background-image-wrap'
                        style={{
                            backgroundImage: `url('${object.get_image_lq}')`
                        }}
                    >
                        <div className='tc-background-image-overlay' />
                    </div>
                )}
                <a
                    className='tc-link-href'
                    href={`${viewUrl}/detail/${object[field].content_type_string}/${object[field].id}/`}
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <div className='tc-link-div tc-fk-link'>
                        <IconBlock
                            disabled
                            edit={false}
                            iconClass='mo-new-icon-arrow-up-right-from-square-solid'
                        />
                        <TableColumnValue
                            contentType={contentType}
                            contentTypeSettings={contentTypeSettings}
                            contentTypeSettingsCustom={contentTypeSettingsCustom}
                            field={field}
                            fieldSettings={fieldSettings}
                            object={object}
                            profileObject={profileObject}
                            refreshData={refreshData}
                            setFilter={setFilter}
                        />
                    </div>
                </a>
            </td>
        )
    }

    return (
        <td
            className={`table-column 3 field-${fieldTypeLayout[fieldSettings?.fieldType] || 'error'}`}
            style={conditionStyles}
        >
            {fieldTypeLayout[fieldSettings?.fieldType] !== 'action_button' && !contentTypeSettings.meta.disableDetailView && !(contentTypeSettings.meta.model_content_type_id === 265 && field === 'text' && object.images_temp) && (
                <LinkHelper
                    className='tc-link'
                    edit={false}
                    onClick={() => onClickIsModal(object.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrlV2({
                        contentType,
                        objectId: object.id!,
                        pageType: 'detail',
                        tabId: fieldSettings?.layout?.panelIdToOpen,
                    })}
                />
            )}
            <div className='tc-link-div'>
                <TableColumnValue
                    contentType={contentType}
                    contentTypeSettings={contentTypeSettings}
                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                    field={field}
                    fieldSettings={fieldSettings}
                    object={object}
                    profileObject={profileObject}
                    refreshData={refreshData}
                    setFilter={setFilter}
                />
                {contentTypeSettings.meta.model_content_type_id === 265 && ['description_for_customer', 'description_for_staff', 'text'].includes(field) && object.images_temp.length > 0 && (
                    <>
                        <div></div>
                        <div className='otlb-image-wrap'>
                            {object.images_temp.map((image: any, i: number) => (
                                <img
                                    key={image.id}
                                    alt='Just The Web'
                                    className='otlb-image'
                                    src={image.get_image_lq}
                                    onClick={() => {
                                        setImageIndex(i)
                                        setIsLightBoxOpen(object.id)
                                    }}
                                />
                            ))}
                            {isLightBoxOpen === object.id && (
                                <LightboxCustom
                                    mainSrc={object.images_temp[imageIndex].get_image_hq || ''}
                                    nextSrc={object.images_temp[(imageIndex + 1) % object.images_temp.length].get_image_hq}
                                    onCloseRequest={() => setIsLightBoxOpen(undefined)}
                                    onMoveNextRequest={() => object.images_temp && setImageIndex((imageIndex + 1) % object.images_temp.length)}
                                    onMovePrevRequest={() => object.images_temp && setImageIndex((imageIndex + object.images_temp.length - 1) % object.images_temp.length)}
                                    prevSrc={object.images_temp[(imageIndex + object.images_temp.length - 1) % object.images_temp.length].get_image_hq}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </td>
    )
})
