// npm components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    MainChipListBlock,
    MainHashtag,
} from 'components'

// data
import {
    searchTabValue,
} from 'data'

// pages
import {
    PortfolioActionSheet,
} from 'pages'

// serializers
import {
    PortfolioListSerializer,
} from 'serializers/web'

// services
import {
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type PortfolioListBlockProps = {
    edit: boolean
    modalKey: number
    object: PortfolioListSerializer
    siteName?: boolean
}

// main
export const PortfolioListBlock: React.FC<PortfolioListBlockProps> = React.memo(({
    edit,
    modalKey,
    object,
    siteName,
}) => {

    const dispatch = useDispatch()

    const [showActionPopover, setShowActionPopover] = useState(false)

    return (
        <div className='portfolio-list-block-web'>
            <div className={`plsbw-cover${showActionPopover ? ' is-open' : ''}`}>
                {!object.active && (
                    <InactiveButton
                        edit={edit}
                        model='portfolio'
                        objectId={object.id!}
                        objectName={object.name!}
                        publishDisabled
                        setDeleted={undefined}
                    />
                )}
                <LinkHelper
                    className='plsbw-cover-wrap'
                    edit={edit}
                    onClick={() => onClickIsModal(object.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrl(object.get_absolute_url!)}
                >
                    <div className='plsbw-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='plsbw-image'
                            dominant_color={object.get_dominant_color}
                            src={object.get_image_mq}
                        />
                    </div>
                </LinkHelper>
                <div className='plsbw-actions'>
                    <LinkHelper
                        className='plsbw-actions-link'
                        edit={edit}
                        onClick={() => onClickIsModal(object.id!, modalKey, dispatch)}
                        to={getModalAbsoluteUrl(object.get_absolute_url!)}
                    />
                    <IconBlock
                        edit={edit}
                        iconClass='mo-new-icon-link-solid'
                        onClick={() => window.open(`https://${object.hostname}`, '_blank')}
                    />
                    <PortfolioActionSheet
                        edit={edit}
                        object={object}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </div>
            </div>
            <div className='plsbw-info'>
                <div className='plsbw-block-header'>
                    <div className='plsbw-tags'>
                        <MainChipListBlock>
                            {object.categories?.map((val) => (
                                <MainHashtag
                                    key={val.id}
                                    edit={edit}
                                    label={val.name}
                                    searchTab={searchTabValue.portfolio}
                                />
                            ))}
                        </MainChipListBlock>
                    </div>
                </div>
                <div className='plbw-footer'>
                    {siteName
                        ? (
                            <LinkHelper
                                className='pplbw-name'
                                edit={edit}
                                onClick={() => onClickIsModal(object.id!, modalKey, dispatch)}
                                title={object.name}
                                to={getModalAbsoluteUrl(object.get_absolute_url!)}
                            >
                                {object.name}
                            </LinkHelper>
                        ) : (
                            <React.Fragment>
                                <LinkHelper
                                    className='plbw-image-wrap'
                                    edit={edit}
                                    to={object.profile?.get_absolute_url}
                                >
                                    <ImageHelper
                                        alt={object.profile?.name}
                                        className='plbw-image'
                                        dominant_color={object.profile?.get_dominant_color}
                                        src={object.profile?.get_image_xs}
                                    />
                                </LinkHelper>
                                <LinkHelper
                                    className='plbw-name'
                                    edit={edit}
                                    to={object.profile?.get_absolute_url}
                                >
                                    {object.profile?.name}
                                </LinkHelper>
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        </div>
    )

    // return (
    // 	<div className='portfolio-list-block-web'>
    // 		<RatioHelper
    // 			alt={object.profile?.name}
    // 			borderRadius='14px'
    // 			dominant_color={object.get_dominant_color}
    // 			edit={edit}
    // 			fixedRatioPercentage='62.17%'
    // 			onClick={() => onClickIsModal(object.id!, modalKey, dispatch)}
    // 			src={object.get_image_mq}
    // 			to={getModalAbsoluteUrl(object.get_absolute_url!)}
    // 		/>
    // 		<div className='plbw-footer'>
    // 			{siteName
    // 				? (
    // 					<p className='pplbw-name'>{object.name}</p>
    // 				) : (
    // 					<React.Fragment>
    // 						<LinkHelper
    // 							className='plbw-image-wrap'
    // 							edit={edit}
    // 							to={object.profile?.get_absolute_url}
    // 						>
    // 							<ImageHelper
    // 								alt={object.profile?.name}
    // 								className='plbw-image'
    // 								dominant_color={object.profile?.get_dominant_color}
    // 								src={object.profile?.get_image_xs}
    // 							/>
    // 						</LinkHelper>
    // 						<LinkHelper
    // 							className='plbw-name'
    // 							edit={edit}
    // 							to={object.profile?.get_absolute_url}
    // 						>
    // 							{object.profile?.name}
    // 						</LinkHelper>
    // 					</React.Fragment>
    // 				)
    // 			}
    // 		</div>
    // 	</div>
    // )
})
