// react components
import React, {
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    AddButton,
    Button,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
} from 'data'

// pages
import {
    EmailForm,
    ImageForm,
    SelectionForm,
    TableAction,
} from 'pages'

// serializers
import {
    ContentTypeFieldTypes,
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
} from 'services'

// props
type DetailActionProps = {
    blockId: number
    canSendEmail?: boolean
    checkboxArray?: number[]
    columns?: string[]
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    detailObject: any
    disableOrdering?: boolean
    emailContentObjectId?: number
    emailContentType?: contentTypeModelType
    exportColumns: {
        columnName: string
        columnTitle: string
        fieldOptions: ContentTypeFieldTypes | undefined
    }[]
    exportListApiUrl: string
    filterParams?: string
    hasOrdering: boolean
    inModal?: boolean
    isOrdering: boolean
    parentModelField: string
    parentObject: {
        id: number
        content_type_string: string
        content_uuid: string
        model_content_type_id: number
        profile?: ProfileDetailAdminSerializerV2
    }
    refreshData: () => void
    setCustomColumns: (customColumns: any[]) => void
    setIsOrdering: (value: boolean) => void
    subContentType?: any
    subContentTypes?: any
    subTab?: string
}

// main
export const DetailTabAction: React.FC<DetailActionProps> = React.memo(({
    blockId,
    canSendEmail,
    checkboxArray,
    columns,
    contentType,
    contentTypeSettings,
    detailObject,
    disableOrdering,
    emailContentObjectId,
    emailContentType,
    exportColumns,
    exportListApiUrl,
    filterParams,
    hasOrdering,
    inModal,
    isOrdering,
    parentModelField,
    parentObject,
    refreshData,
    setCustomColumns,
    setIsOrdering,
    subContentType,
    subContentTypes,
    subTab,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [emailToList, setEmailToList] = useState<string[] | undefined>(undefined)
    const [isImageFormOpen, setIsImageFormOpen] = useState(false)
    const [isSelectionFormOpen, setIsSelectionFormOpen] = useState(false)
    const [subContentTypesIsImageForm, setSubContentTypesIsImageForm] = useState(false)

    if (!parentObject.id) return null

    function onShowModalForm(bulk?: boolean, subContentTypeId?: number) {
        try {
            if (bulk) {
                const defaultFields = {
                    [parentModelField]: parentObject,
                }
                dispatch(reduxModalMainFormShow({
                    contentType,
                    cssClass: 'form-container-modal',
                    refreshData,
                    customformState: {
                        fieldsForm: {
                            formJson: [
                                'payload',
                            ],
                        },
                    },
                    defaultFields,
                    formComponent: 'CustomFormModal',
                    objectId: 'new',
                    profileObject: parentObject.profile,
                }))
            } else {
                const defaultFields = {
                    [parentModelField]: parentObject,
                }
                // TODO check if dynamic possible
                if (parentModelField.includes('object_id')) {
                    /// @ts-ignore
                    defaultFields[parentModelField] = parentObject.id
                    defaultFields['content_type'] = {
                        id: parentObject.model_content_type_id,
                        /// @ts-ignore
                        name: parentObject.content_type_string,
                    }
                }
                // TODO
                if (subContentTypeId) {
                    /// @ts-ignore
                    defaultFields.sub_content_type = { id: subContentTypeId, name: subContentTypeId }
                } else if (subContentType) {
                    defaultFields['sub_content_type'] = {
                        id: subContentType.id,
                        /// @ts-ignore
                        name: subContentType.id,
                    }
                }
                if (filterParams?.includes('asset_custom_category')) {
                    // @ts-ignore
                    defaultFields['asset_custom_category'] = filterParams.split('&').find((param) => param.includes('asset_custom_category')).split('=')[1]
                }
                dispatch(reduxModalMainFormShow({
                    contentType,
                    contentTypeSettings,
                    cssClass: 'form-container-modal',
                    defaultFields,
                    formComponent: 'FormModal',
                    objectId: 'new',
                    profileObject: parentObject.profile,
                    refreshData,
                }))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailTabAction',
                'onShowModalForm',
            ))
        }
    }

    async function resetList() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ListContent onMoveRow')
            const axiosUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/poster/items_position/clear/${detailObject.id}/`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(axiosUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'post',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    refreshData()
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'ListContent',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onMoveRow',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'onMoveRow',
            ))
        }
    }

    return (
        <div className='detail-tab-action DetailTabAction'>
            <TableAction
                canSendEmail={canSendEmail}
                checkboxArray={checkboxArray}
                columns={columns}
                contentType={contentType}
                contentTypeSettings={contentTypeSettings}
                emailContentObjectId={emailContentObjectId}
                emailContentType={emailContentType}
                exportColumns={exportColumns}
                exportListApiUrl={exportListApiUrl}
                inModal={inModal}
                profile={parentObject.profile}
                setCustomColumns={setCustomColumns}
                subContentTypeId={subContentType?.id}
            />
            {subTab === 'Affiche' && (
                <>
                    <Button
                        edit={false}
                        fill='outline'
                        onClick={resetList}
                        padding='0 0 0 10px'
                        size='x-small'
                        text='Reset list'
                    />
                    <Button
                        edit={false}
                        fill='outline'
                        padding='0 0 0 10px'
                        size='x-small'
                        text='PDF'
                        href={`${process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://api.justtheweb.io'}/fr/v2/1/customer/agendaduquartier/pdf/affiche/${parentObject.content_uuid}/`}
                    />
                </>
            )}
            {hasOrdering && (
                <Button
                    disabled={disableOrdering}
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOrdering(!isOrdering)}
                    padding='0 0 0 10px'
                    size='x-small'
                    text={!isOrdering ? reduxText[10173] : reduxText[8334]}
                />
            )}
            {/* {reduxAuth.settings?.user?.is_staff && [contentTypeData['staff_staffmessage']].includes(contentTypeSettings.meta.model_content_type_id) && (
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => onShowModalForm(true)}
                    size='x-small'
                    text={`Bulk ${reduxText[2138]}`}
                />
            )} */}
            {!contentTypeSettings.meta.disableFormAdd && (
                <AddButton
                    inModal={inModal}
                    onShowModalForm={(subContentTypeId?: number, isImage?: boolean) => {  // TODO dynamic
                        if (subContentTypeId) {
                            if (isImage) {
                                // @ts-ignore
                                setSubContentTypesIsImageForm({ id: subContentTypeId, name: subContentTypeId })
                            } else {
                                onShowModalForm(false, subContentTypeId)
                            }
                        } else if (subContentType) {
                            if (subContentType.isImage) {
                                setIsImageFormOpen(true)
                            } else {
                                onShowModalForm()
                            }
                        } else if ([contentTypeData['datapool_asset'], contentTypeData['event_eventimage'], contentTypeData['staff_staffmessageimage']].includes(contentTypeSettings.meta.model_content_type_id)) {
                            setIsImageFormOpen(true)
                        } else if ([contentTypeData['setup_contenttypefieldlist']].includes(contentTypeSettings.meta.model_content_type_id)) {
                            setIsSelectionFormOpen(true)
                        } else {
                            onShowModalForm()
                        }
                    }}
                    size='x-small'
                    subContentTypes={subContentTypes}
                />
            )}
            {/* <IconBlock
                    edit={false}
                    iconClass='mo-new-icon-pencil-alt-solid'
                    onClick={onShowModalForm}
                /> */}
            {/* <ActionComponent
                    edit={false}
                    inModal={inModal}
                    contentType={model}
                    // isInProfileSlug={parentObject.profile?.slug}
                    parentObject={parentObject}
                    refreshData={refreshData}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                /> */}
            {emailToList && (
                <EmailForm
                    contentType={emailContentType!}
                    emailContentObjectId={emailContentObjectId!}
                    emailToList={emailToList!}
                    onClose={() => setEmailToList(undefined)}
                    profile={parentObject.profile}
                />
            )}
            {isImageFormOpen && (
                <ImageForm
                    contentType={contentType}
                    detailObject={detailObject}
                    filterParams={filterParams}
                    refreshData={refreshData}
                    setIsOpen={setIsImageFormOpen}
                    subContentType={subContentType}
                />
            )}
            {subContentTypesIsImageForm && (
                <ImageForm
                    contentType={contentType}
                    detailObject={detailObject}
                    filterParams={filterParams}
                    refreshData={refreshData}
                    setIsOpen={setSubContentTypesIsImageForm}
                    subContentType={subContentTypesIsImageForm}
                />
            )}
            {isSelectionFormOpen && (
                <SelectionForm
                    contentType={contentType}
                    detailObject={detailObject}
                    refreshData={refreshData}
                    setIsOpen={setIsSelectionFormOpen}
                />
            )}
        </div>
    )
})
