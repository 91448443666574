// Root URL
const rootPath = 'auth/'

export const api_url_auth_password_reset = `${rootPath}form/user/password/reset/`
export const api_url_auth_password_reset_confirm = `${rootPath}form/users/reset_password_confirm/`

export const api_url_auth_token_convert = `${rootPath}form/token/convert/`
export const api_url_auth_token_obtain = `${rootPath}form/token/obtain/`

export const api_url_auth_settings_detail = `${rootPath}detail/settings/`
export const api_url_auth_settings_form_language_update = `${rootPath}form/settings/language/update/`
export const api_url_auth_settings_form_profile_update = `${rootPath}form/settings/profile/update/`
export const api_url_auth_settings_form_theme_update = `${rootPath}form/settings/theme/update/`

export const api_url_auth_user_form_activation = `${rootPath}form/users/activation/`  // TODO
export const api_url_auth_user_form_create = `${rootPath}form/user/create/`
export const api_url_auth_user_form_last_login = `${rootPath}form/user/last_login/`  // TODO
