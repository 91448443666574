// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    LinkHelper,
    Loader,
} from 'components'

// customers
import {
    AgendaDuQuartierPaymentSuccessTransfert,
    PickAnOfferBlockStep1,
    PickAnOfferBlockStep2,
    PickAnOfferBlockStep3,
    PickAnOfferBlockStep4,
} from 'customers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
} from 'data'

// pages
import {
    SelectListInputV2,
} from 'pages'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
} from 'services'

// props
type PickAnOfferBlockProps = {
    profileObject: ProfileDetailAdminSerializerV2 | undefined
}

// main
export const PickAnOfferBlock: React.FC<PickAnOfferBlockProps> = React.memo(({
    profileObject,
}) => {

    const dispatch = useDispatch()
    const scrollDivRef = useRef(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        dispatch(reduxModalSeoData({
            title: `Commande | ${reduxAuth.settings?.user?.email} | Agenda du quartier`,
        }))
    }, [
        reduxAuth.settings?.user?.email,
    ])

    useEffect(() => {
        if (reduxAuth.settings?.federateur?.id) {
            onGetDetailData()
        }
    }, [
        reduxAuth.settings?.federateur?.id,
    ])


    type errorsType = {
        address_custom_additional_address_info_1?: string
        address_custom_additional_address_info_2?: string
        address_custom_building_1?: string
        address_custom_building_2?: string
        address_custom_city_1?: string
        address_custom_city_2?: string
        address_custom_country_1?: any
        address_custom_office_1?: string
        address_custom_office_2?: string
        address_custom_postal_code_1?: string
        address_custom_postal_code_2?: string
        address_custom_recipient_name_2?: string
        address_custom_street_address_1?: string
        address_custom_street_address_2?: string
        civility?: string
        custom_date_field_1?: string
        custom_email_field_1?: string
        custom_foreign_key_5?: string
        custom_text_field_4?: string
        custom_text_field_5?: string
        custom_text_field_6?: string
        first_name?: string
        last_name?: string
        name?: string
        phone_number?: string
        stripe_price_1?: string
        stripe_price_2?: string
        stripe_price_3?: string
        stripe_price_4?: string
    }
    const errorsInitial = {}
    type fieldsType = {
        address_custom_additional_address_info_1?: string
        address_custom_additional_address_info_2?: string
        address_custom_building_1?: string
        address_custom_building_2?: string
        address_custom_city_1?: string
        address_custom_city_2?: string
        address_custom_country_1?: any
        address_custom_office_1?: string
        address_custom_office_2?: string
        address_custom_postal_code_1?: string
        address_custom_postal_code_2?: string
        address_custom_recipient_name_2?: string
        address_custom_street_address_1?: string
        address_custom_street_address_2?: string
        civility?: any
        custom_date_field_1?: any
        custom_email_field_1?: string
        custom_foreign_key_5?: any
        custom_text_field_4?: string
        custom_text_field_5?: string
        custom_text_field_6?: string
        first_name?: string
        has_shipping?: string
        last_name?: string
        name?: string
        phone_number?: string
        stripe_price_1?: any
        stripe_price_2?: any
        stripe_price_3?: any
        stripe_price_4?: any
    }
    const fieldsInitial = {}

    const [dateOptions, setDateOptions] = useState<any>([])
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [invoice, setInvoice] = useState<any>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [period, setPeriod] = useState<'monthly' | 'yearly'>('monthly')
    const [quotation, setQuotation] = useState<any>(undefined)
    const [step, setStep] = useState<number>(1)

    useEffect(() => {
        scrollToTop()
    }, [step])

    const scrollToTop = () => {
        if (scrollDivRef.current) {
            // @ts-ignore
            scrollDivRef.current.scrollTo({
                top: 0,
                // behavior: 'smooth' // Optional: for a smooth scroll effect
            })
        }
    }

    async function downloadQuotation() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PickAnOfferBlock downloadQuotation')
            const postUrl = getApiUrlV2(`customer/agendaduquartier/form/quotation/download_pdf/`, reduxAuth)
            const formData = new FormData()
            formData.append('stripe_quotation_id', quotation.stripe_quotation_id)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
                responseType: 'blob',
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${quotation.stripe_quotation_number}.pdf`)
                    document.body.appendChild(link)
                    link.click()

                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'PickAnOfferBlock',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'downloadQuotation',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PickAnOfferBlock',
                'downloadQuotation',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PickAnOfferBlock',
                'handleInputChange',
            ))
        }
    }

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/detail/federateur/${reduxAuth.settings?.federateur?.id}/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep1',
                dispatch,
                reduxAuth,
                setDetailObject: (data: any) => {

                    const newFields = {
                        ...fields,
                        ...data,
                    }

                    const monthNames = [
                        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
                    ]

                    const newDateOptions = []
                    const currentDate = new Date()
                    let startMonth = currentDate.getMonth() + 1
                    const startYear = currentDate.getFullYear()

                    // If the current day is greater than the 9th, start from the next month
                    if (currentDate.getDate() > 9) {
                        startMonth += 1
                    }

                    for (let i = 0; i < 6; i++) {
                        const month = startMonth + i
                        const year = startYear + Math.floor(month / 12) // Increment year if month exceeds 11
                        const adjustedMonth = month % 12 // Ensure month stays within 0-11 range

                        const nextMonth = new Date(Date.UTC(year, adjustedMonth, 1)) // Get the first day of the month
                        const id = nextMonth.toISOString().split('T')[0] // Format as 'YYYY-MM-DD'

                        const name = `${monthNames[adjustedMonth]} ${year}` // Month name + Year

                        newDateOptions.push({ id, name })
                    }

                    setDateOptions(newDateOptions)
                    setFields({ ...newFields, custom_date_field_1: newDateOptions[0] })

                },
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PickAnOfferBlock',
                'onGetDetailData',
            ))
        }
    }

    function onPeriodChange(newPeriod: 'monthly' | 'yearly') {
        try {
            const toReturn = fields
            if (fields.stripe_price_1) {
                toReturn.stripe_price_1 = {
                    id: mainSubscriptionData.find(val => val.id === fields.stripe_price_1?.product_id)?.prices[newPeriod].id,
                    product_id: fields.stripe_price_1.product_id,
                }
            }
            if (fields.stripe_price_2) {
                toReturn.stripe_price_2 = {
                    id: optionsPriceData.find(val => val.name_id === 'stripe_price_2')?.prices[newPeriod].id,
                    qty: fields.stripe_price_2.qty,
                }
            }
            if (fields.stripe_price_3) {
                toReturn.stripe_price_3 = {
                    id: optionsPriceData.find(val => val.name_id === 'stripe_price_3')?.prices[newPeriod].id,
                    qty: fields.stripe_price_3.qty,
                }
            }
            setFields(toReturn)
            setPeriod(newPeriod)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PickAnOfferBlock',
                'onPeriodChange',
            ))
        }
    }

    function onStepClick(step: number) {
        try {
            if (step === 2) {
                const requiredFields = [
                    'address_custom_city_1',
                    'address_custom_country_1',
                    'address_custom_postal_code_1',
                    'civility',
                    'custom_foreign_key_5',
                    'custom_text_field_5',
                    'first_name',
                    'last_name',
                    'name',
                    'phone_number',
                ]
                let canContinue = true
                const toReturnErrors = {}
                requiredFields.map((val) => {
                    // @ts-ignore
                    if (!fields[val]) {
                        // @ts-ignore
                        toReturnErrors[val] = 'Ce champ est requis'
                        canContinue = false
                    }
                })
                if (canContinue) {
                    setStep(3)
                } else {
                    setErrors(toReturnErrors)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PickAnOfferBlock',
                'onStepClick',
            ))
        }
    }

    if (invoice) {
        return <AgendaDuQuartierPaymentSuccessTransfert data={invoice} />
    }

    if (quotation) {
        return (
            <div style={{
                textAlign: 'center',
                padding: '50px',
            }}>
                <div
                    style={{
                        paddingBottom: '20px',
                    }}
                    dangerouslySetInnerHTML={{ __html: quotation.message }}
                />
                <Button
                    edit={false}
                    onClick={downloadQuotation}
                    text='Télécharger mon devis'
                />
                <p style={{
                    paddingTop: '20px',
                }}>A bientôt, pour animer ensemble nos territoires,</p>
                <br />
                <p>L’équipe de l’Agenda du quartier</p>
                {isLoading && <Loader isOpen />}
            </div>
        )
    }

    const mainSubscriptionData = [
        {
            id: 'prod_R3smtgBPTxTO5C',
            name: 'L\'Agenda simplifié',
            prices: {
                'monthly': {
                    id: 'price_1QhJT4G1YZk9oFa1aZNRmhar',
                    price: '350 € / mois',
                },
                'yearly': {
                    id: 'price_1QhJU3G1YZk9oFa17NQVnPTR',
                    price: '4000 € / an',
                },
            },
        },
        {
            id: 'prod_R3sny7FoFJsZ3X',
            name: 'L\'Agenda livré chez vous',
            prices: {
                'monthly': {
                    id: 'price_1QhJUuG1YZk9oFa1H4yBmhQR',
                    price: '550 € / mois',
                },
                'yearly': {
                    id: 'price_1QhJVxG1YZk9oFa1BLgaGLvr',
                    price: '6000 € / mois',
                },
            },
        },
        {
            id: 'prod_R3sotrIgRQoOzv',
            name: 'L\'Agenda chez l\'habitant',
            prices: {
                'monthly': {
                    id: 'price_1QhJg9G1YZk9oFa10nqs8bQd',
                    price: '750 € / mois',
                },
                'yearly': {
                    id: 'price_1QhJhJG1YZk9oFa1WFOhulFi',
                    price: '8500 € / an',
                },
            },
        },
    ]

    const optionsPriceData = [
        {
            id: 'prod_R3tCVvWCKyWkkf',
            description: 'jusqu\'à 5 affiches format A3 ou A4 - adresse postale unique',
            max_qty: 1,
            name: '5 affiches supplémentaires : impression et envoi postal',
            name_id: 'stripe_price_2',
            prices: {
                'monthly': {
                    id: 'price_1QBlQPG1YZk9oFa168XYivP3',
                    price: '9,99 € / mois',
                },
                'yearly': {
                    id: 'price_1QBlQPG1YZk9oFa1fitsar4n',
                    price: '115 € / an',
                },
            },
        },
        {
            id: 'prod_R3tDxvUgRejClc',
            description: "jusqu'à 15 affiches format A3 ou A4 - jusqu'à 3 adresses postales",
            name: '15 affiches supplémentaires : impression et envoi postal',
            name_id: 'stripe_price_3',
            prices: {
                'monthly': {
                    id: 'price_1QBlRJG1YZk9oFa1XR3rQMGo',
                    price: '29,90 € / mois',
                },
                'yearly': {
                    id: 'price_1QBlRJG1YZk9oFa1VJFdDKR8',
                    price: '350 € / an',
                },
            },
        },
        {
            id: 'prod_R3t8WYeZ5oLy1N',
            description: 'Bâche PVC opaque 600g/m2\nFormat 300x72cm\nImpression recto verso\nŒillets positionnés tous les 50 cm\nNorme anti- feu B1\nLivraison sous 15 jours ouvrés après commande',
            name: 'Impression et envoi de banderoles Agenda du quartier - format "barnum" 300 cm x 72 cm',
            name_id: 'stripe_price_4',
            prices: {
                'monthly': {
                    id: 'price_1QBlMZG1YZk9oFa1GgROR2ve',
                    price: '139,90 € / unité',
                },
                'yearly': {
                    id: 'price_1QBlMZG1YZk9oFa1GgROR2ve',
                    price: '139,90 € / unité',
                },
            },
        },
        {
            id: '',
            description: 'Demander un devis via la rubrique “Nous contacter”',
            name: 'Banderole Agenda du quartier, avec QR code et URL personnalisés, “format sur mesure” (vos dimensions)',
            name_id: '',
            prices: {
                'monthly': {
                    id: '',
                    price: 'Sur devis',
                },
                'yearly': {
                    id: '',
                    price: 'Sur devis',
                },
            },
        },
    ]

    let isNextValid = false
    if (step === 1) {
        if (fields.stripe_price_1) {
            isNextValid = true
        }
    } else if (step === 3) {
        isNextValid = true
    }

    return (
        <div
            className={`pick-an-offer-block mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}
            ref={scrollDivRef}
        >
            <div className='paob-block'>
                <div className='paob-navsteps'>
                    <button
                        className={`paob-navstep${step === 1 ? ' current' : ''}`}
                        onClick={() => setStep(1)}
                        type='button'
                    >
                        <p className='paob-navtext'>Abonnement</p>
                        <p className='paob-navtextsub'>(choix de l’offre)</p>
                    </button>
                    <button
                        className={`paob-navstep${step === 2 ? ' current' : ''}`}
                        disabled={step < 2}
                        onClick={() => setStep(2)}
                        type='button'
                    >
                        <p className='paob-navtext'>Adresse</p>
                        <p className='paob-navtextsub'>(coordonnées de facturation)</p>
                    </button>
                    <button
                        className={`paob-navstep${step === 3 ? ' current' : ''}`}
                        disabled={step < 3}
                        onClick={() => setStep(3)}
                        type='button'
                    >
                        <p className='paob-navtext'>Supplément</p>
                        <p className='paob-navtextsub'>(besoins additionnels)</p>
                    </button>
                    <button
                        className={`paob-navstep${step === 4 ? ' current' : ''}`}
                        disabled={step < 4}
                        type='button'
                    >
                        <p className='paob-navtext'>Paiement ou devis</p>
                    </button>
                </div>
                {step === 1 && (
                    <PickAnOfferBlockStep1
                        fields={fields}
                        handleInputChange={handleInputChange}
                        mainSubscriptionData={mainSubscriptionData}
                        onPeriodChange={onPeriodChange}
                        period={period}
                    />
                )}
                {step === 2 && (
                    <PickAnOfferBlockStep2
                        errors={errors}
                        fields={fields}
                        handleInputChange={handleInputChange}
                    />
                )}
                {step === 3 && (
                    <PickAnOfferBlockStep3
                        fields={fields}
                        handleInputChange={handleInputChange}
                        optionsPriceData={optionsPriceData}
                        period={period}
                    />
                )}
                {step === 4 && (
                    <PickAnOfferBlockStep4
                        fields={fields}
                        period={period}
                        setInvoice={setInvoice}
                        setQuotation={setQuotation}
                    />
                )}
            </div>
            <div className='paob-button-next-wrap'>
                {step === 1 && (
                    <div className='paok-button-message'>
                        <SelectListInputV2
                            clearable={false}
                            error={errors?.custom_date_field_1}
                            label='Confirmez le premier mois de publication souhaité'
                            name='custom_date_field_1}'
                            onChange={handleInputChange}
                            value={fields.custom_date_field_1 || ''}
                            options={dateOptions}
                        />
                        <div dangerouslySetInnerHTML={{ __html: reduxText[10048] }} />
                    </div>
                )}
                {step > 1 && (
                    <LinkHelper
                        edit={false}
                        className='paob-button-previous'
                        onClick={() => setStep(step - 1)}
                        to={undefined}
                    >
                        <i className='paob-icon mo-new-icon-chevron-left-solid' />
                        <span>Étape précédente</span>
                    </LinkHelper>
                )}
                {step < 4 && (
                    <LinkHelper
                        edit={false}
                        className={`paob-button-next${(step === 2 || isNextValid) ? '' : ' disabled'}`}
                        onClick={[2].includes(step) ? () => onStepClick(step) : () => setStep(step + 1)}
                        to={undefined}
                    >
                        <span>Étape suivante</span>
                        <i className='paob-icon mo-new-icon-chevron-right-solid' />
                    </LinkHelper>
                )}
            </div>
        </div>
    )
})
