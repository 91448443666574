// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// containers
import {
    MainFormContainerWrapper,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetAvailabitySite,
    reduxModalErrorEventHandler,
    reduxModalSetEditSite,
    select_url_portfolio_content_source_available,
} from 'data'

// pages
import {
    AdditionalOfferPanel,
    AssetFormState,
    AssetPanel,
    ContainerMixedContentPanel,
    ContainerMixedPanel,
    ImageOfferPanel,
    MainOfferPanel,
    MenuOfferPanel,
    OfferImageFormState,
    OfferMotherFormState,
    PortfolioOfferPanel,
    ProjectMixedMediaContentFormState,
} from 'pages'

// serializers
import {
    OfferFormState,
} from 'serializers/web'

// services
import {
    autoSaveStyles,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailData,
    getDynamicTextOptions,
    getInputValue,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type LocationParams = {
    formRefresh?: number
}

type OfferFormProps = {
    objectId?: number
    onRefresh?: () => void
}

// main
export const OfferForm: React.FC<OfferFormProps> = React.memo(({
    objectId,
    onRefresh,
}) => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<OfferFormState>({
        mother: OfferMotherFormState(reduxAuth),
        asset: AssetFormState(reduxAuth),
        mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
        image: OfferImageFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)

    useEffect(() => {
        try {
            autoSaveStyles({
                component: 'OfferForm',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'OfferForm',
                'useEffect',
            ))
        }
    }, [
        params.panelValue,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailData({
            axiosCancelToken,
            component: 'OfferForm',
            dispatch,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: objectId ? `${objectId}` : params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        objectId,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 27 && formState.mother.fields?.profile?.id) {
            getAvailabeContentSource()
        }
    }, [
        formState.mother.fields?.medium_type?.id,
        formState.mother.fields?.profile?.id,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 27) {
            dispatch(reduxModalSetEditSite(true))
            if (!reduxFormSite.dynamicTextOptions) {
                getDynamicTextOptions({
                    component: 'OfferForm',
                    dispatch,
                    reduxAuth,
                })
            }
        }
    }, [
        formState.mother.fields?.medium_type?.id,
    ])

    function setInitialState() {
        const mother = OfferMotherFormState(reduxAuth)
        setFormState({
            mother: {
                ...mother,
                fields: {
                    profile: !reduxAuth.settings?.user?.is_staff ? reduxAuth.settings?.active_profile : undefined,
                },
            },
            asset: AssetFormState(reduxAuth),
            image: OfferImageFormState(reduxAuth),
            mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
        })
    }

    async function getAvailabeContentSource() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'OfferForm getAvailabeContentSource')
            const getUrl = getApiUrl(`${select_url_portfolio_content_source_available}?profile_id=${formState.mother.fields?.profile?.id}`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetAvailabitySite(response.data[0].available))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'OfferForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getAvailabeContentSource',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'OfferForm',
                'getAvailabeContentSource',
            ))
        }
    }

    function handleInputChange(event: any, formInfo: any) {
        try {
            const mother = formState.mother
            if (formInfo.name === 'mother' && event.name === 'profile' && mother) {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        profile: event.value,
                    },
                    errors: {
                        ...mother.errors,
                        profile: '',
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '',
                })
            } else if (formInfo.name === 'mother' && event.name === 'mixed_media_fonts' && mother) {
                const toReturn = {
                    ...mother,
                    errors: {
                        ...mother.errors,
                        mixed_media_fonts: '',
                    },
                    fields: {
                        ...mother.fields,
                        fonts: event.value,
                        mixed_media_fonts: event.value,
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'mother' && event.name === 'styles_original' && mother) {
                const toReturn = {
                    ...mother,
                    errors: {
                        ...mother.errors,
                        styles_original: '',
                    },
                    fields: {
                        ...mother.fields,
                        styles: event.preview,
                        styles_original: event.value,
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'mixedMediaContent' && event.name === 'styles_original' && mother) {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
            } else {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'OfferForm',
                'handleInputChange',
            ))
        }
    }

    const panelValue = params.panelValue
    const panelText: any = {
        'additional-panel': reduxText[3234],
        'asset-panel': reduxText[8149],
        'delete-panel': reduxText[2983],
        'image-panel': reduxText[1767],
        'main-panel': reduxText[347],
        'website-related-panel': reduxText[6415],
    }

    const fieldsMother = formState.mother.fields

    return (
        <MainFormContainerWrapper
            formState={formState}
            headerTitle={panelValue ? panelText[panelValue] : ''}
            helpers={helpers}
            onRefresh={onRefresh}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {onRefresh && fieldsMother?.id && (
                <React.Fragment>
                    <p className='mfcmw-custom-edit-title'>{panelText['main-panel']}</p>
                    <MainOfferPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['additional-panel']}</p>
                    <AdditionalOfferPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['website-related-panel']}</p>
                    <PortfolioOfferPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                </React.Fragment>
            )}
            {!onRefresh && (
                <React.Fragment>
                    {panelValue === 'main-panel' && (
                        <MainOfferPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'additional-panel' && fieldsMother?.id && (
                        <AdditionalOfferPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'asset-panel' && fieldsMother?.id && (
                        <AssetPanel
                            contentType='offer'
                            // @ts-ignore
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            // @ts-ignore
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'image-panel' && fieldsMother?.id && (
                        <ImageOfferPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {params.formType === 'mixed-media' && fieldsMother?.id && (
                        <ContainerMixedPanel
                            contentType='offer'
                            // @ts-ignore
                            formState={formState}
                            handleInputChange={handleInputChange}
                            // @ts-ignore
                            setFormState={setFormState}
                        />
                    )}
                    {params.formType === 'mixed-media-content' && fieldsMother?.id && (
                        <ContainerMixedContentPanel
                            contentType='offer'
                            // @ts-ignore
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            newMixedMediaContent={false}
                            newMixedMediaContentType={undefined}
                            // @ts-ignore
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'website-related-panel' && fieldsMother?.id && (
                        <PortfolioOfferPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {!panelValue && (
                        <MenuOfferPanel
                            formState={formState}
                            helpers={helpers}
                            setHelpers={setHelpers}
                        />
                    )}
                </React.Fragment>
            )}
        </MainFormContainerWrapper>
    )
})
