// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Popover,
} from 'components'

// data
import {
    api_url_customer_apasp_participant_attendance_detail_pdf,
    api_url_customer_apasp_participant_confirmation_pdf,
    defaultReduxState,
} from 'data'

// serializers
import {
    ParticipantListRowSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
} from 'services'

// props
type ParticipantListRowActionSheetProps = {
    edit: boolean
    inModal?: boolean
    object: ParticipantListRowSerializer
    setShowActionPopover: React.Dispatch<boolean>
    showActionPopover: boolean
}

// main
export const ParticipantListRowActionSheet: React.FC<ParticipantListRowActionSheetProps> = React.memo(({
    edit,
    inModal,
    object,
    setShowActionPopover,
    showActionPopover,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const buttons = [
        {
            handler: () => {
                setShowActionPopover(false)
                window.open(getApiUrl(`${api_url_customer_apasp_participant_confirmation_pdf}${object.content_uuid}/`, reduxAuth), '_blank')
            },
            text: reduxText[8768],
        },
        {
            handler: () => {
                setShowActionPopover(false)
                window.open(getApiUrl(`${api_url_customer_apasp_participant_attendance_detail_pdf}${object.content_uuid}/`, reduxAuth), '_blank')
            },
            text: reduxText[8767],
        },
    ]

    return (
        <React.Fragment>
            <Popover
                buttons={buttons}
                edit={edit}
                extraZIndex={inModal}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
            />
        </React.Fragment>
    )
})
