// react components
import React from 'react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    CountViewBlock,
    ImageHelper,
    InactiveButton,
    MainCountryFlag,
    MainHashtag,
    ProfileShortInfoMobile,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_product_detail,
} from 'data'

// pages
import {
    ProjectListMicroBlockMobile,
} from 'pages'

// serializers
import {
    ProductDetailSerializer,
    ProductDetailTeamHelperSerializer,
} from 'serializers/web'

// props
type ProductDetailBlockMobileProps = {
    edit: boolean
    modalKeys: {
        related_projects: number,
    }
    object: ProductDetailSerializer
}

// main
export const ProductDetailBlockMobile: React.FC<ProductDetailBlockMobileProps> = React.memo(({
    edit,
    modalKeys,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const duplicateProfile: any = []
    let creators: ProductDetailTeamHelperSerializer[] = []
    let labels: ProductDetailTeamHelperSerializer[] = []
    if (object.team_list && object.team_list.creator && object.team_list.creator.length > 0) {
        creators = object.team_list.creator
        object.team_list.creator.map((val) => {
            if (val.member) duplicateProfile.push(val.member.id)
            return false
        })
    }
    if (object.team_list && object.team_list.label && object.team_list.label.length > 0) {
        labels = object.team_list.label
        object.team_list.label.map((val) => {
            if (val.member) duplicateProfile.push(val.member.id)
            return false
        })
    }

    return (
        <div className='product-detail-block'>
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='product'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <ImageHelper
                alt={object.name}
                className='pdb-cover-image'
                dominant_color={undefined}
                src={object.get_image_lq}
            />
            <div className='pdb-buttons'>
                {object.has_images && (
                    <Button
                        className='pdbm-section-button'
                        edit={edit}
                        fill='outline'
                        size='small'
                        text={reduxText[5593]}
                        to={`${view_url_product_detail}${object.id}/${object.slug}/image/`}
                    />
                )}
            </div>
            <div className='pdb-product-info'>
                <div className='pdb-main-info'>
                    <p className='pdb-name'>{object.name}</p>
                    <div className='pdb-tags'>
                        {object.themes?.map((val) => (
                            <MainHashtag
                                key={`themes-${val.id}`}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </div>
                    {object.profile && !duplicateProfile.includes(object.profile.id) && (
                        <ProfileShortInfoMobile
                            edit={edit}
                            object={object.profile}
                        />
                    )}
                    {creators.map((val) => (
                        <ProfileShortInfoMobile
                            key={val.id}
                            edit={edit}
                            name={val.member?.name || val.name}
                            object={val.member}
                        />
                    ))}
                    {labels.map((val) => (
                        <div
                            key={val.id}
                            className='pdb-label'
                        >
                            <ProfileShortInfoMobile
                                edit={edit}
                                name={val.member?.name || val.name}
                                object={val.member}
                            />
                        </div>
                    ))}
                    <div className='pdb-countries'>
                        {object.profile && object.profile.countries && object.profile.countries.length > 0 && object.profile.countries.map((val) => (
                            <MainCountryFlag
                                key={val.id}
                                edit={edit}
                                object={val}
                            />
                        ))}
                        {object.profile && object.profile.cities && object.profile.cities.length > 0 && object.profile.cities.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </div>
                </div>
                {object.description && (
                    <div className='pdb-section'>
                        {!object.price_on_request
                            ? (
                                <React.Fragment>
                                    <p className='pdb-price'>{object.unit_price} {object.currency?.symbol || object.currency?.iso_code}</p>
                                    {object.stock_status && (
                                        <p className='pdb-price'>{object.stock_status.name}</p>
                                    )}
                                </React.Fragment>

                            ) : (
                                <p className='pdb-price'>{reduxText[4869]}</p>
                            )}
                    </div>
                )}
                {object.description && (
                    <div className='pdb-section'>
                        <p className='pdb-section-title'>{reduxText[4596]}</p>
                        <Linkify>
                            <div className='pdb-description'>
                                {object.description}
                            </div>
                        </Linkify>
                    </div>
                )}
                {object.related_projects && object.related_projects.length > 0 && (
                    <div className='pdb-section'>
                        <p className='pdb-section-title'>{object.related_projects.length > 1 ? reduxText[2475] : reduxText[7716]}</p>
                        {object.related_projects.map((val) => (
                            <ProjectListMicroBlockMobile
                                key={val.id}
                                edit={edit}
                                modalKey={modalKeys.related_projects}
                                object={val}
                            />
                        ))}
                    </div>
                )}
            </div>
            {object.owner_info && (
                <CountViewBlock
                    object={object.owner_info}
                />
            )}
        </div>
    )
})
