// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DateTimeInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProfileDetailFormSerializer,
    ProfileDetailFormSerializerError,
} from 'serializers/web'

// props
type CustomDateProfileInputProps = {
    errors: ProfileDetailFormSerializerError | undefined
    fields: ProfileDetailFormSerializer
    onChange: any
    version?: number
}

// main
export const CustomDateProfileInput: React.FC<CustomDateProfileInputProps> = React.memo(({
    errors,
    fields,
    onChange,
    version,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const text_dict: any = {
        2: reduxText[8734],
    }

    return (
        <DateTimeInput
            // @ts-ignore
            error={errors?.[`custom_date${version ? `_${version}` : ''}`]}
            format='date'
            formatDate='dd/MM/yyyy'
            label={version ? text_dict[version] : reduxText[8732]}
            name={`custom_date${version ? `_${version}` : ''}`}
            onChange={onChange}
            // @ts-ignore
            value={fields[`custom_date${version ? `_${version}` : ''}`] || null}
        />
    )
})
