// react components
import React from 'react'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// props
type WorkContentBlockMobileProps = {
    edit: boolean
    isWeb?: boolean
    object: ProjectDetailSerializer
}

// main
export const WorkContentBlockMobile: React.FC<WorkContentBlockMobileProps> = React.memo(({
    edit,
    isWeb,
    object,
}) => {

    // if (object.content_list && object.content_list.image && object.content_list.image.length > 0) {
    //     return (
    //         <div className='work-content-block'>
    //             {object.content_list.image.map((val, i) => (
    //                 i < 3
    //                     ? (
    //                         isWeb
    //                             ? (
    //                                 <LinkHelper
    //                                     key={val.id}
    //                                     className='wcb-image-wrap'
    //                                     edit={edit}
    //                                     to={object.get_absolute_url}
    //                                 >
    //                                     <ImageHelper
    //                                         alt={val.name}
    //                                         className='wcb-image'
    //                                         dominant_color={undefined}
    //                                         src={val.get_image_lq}
    //                                     // styles={{
    //                                     // 	height: `calc(${val.cover_image_height} / ${val.cover_image_width} * 100vw)`,
    //                                     // }}
    //                                     />
    //                                 </LinkHelper>
    //                             ) : (
    //                                 <ImageHelper
    //                                     key={val.id}
    //                                     alt={val.name}
    //                                     className='wcb-image'
    //                                     dominant_color={undefined}
    //                                     src={val.get_image_lq}
    //                                 // styles={{
    //                                 // 	height: `calc(${val.cover_image_height} / ${val.cover_image_width} * 100vw)`,
    //                                 // }}
    //                                 />
    //                             )
    //                     ) : (
    //                         <Button
    //                             key={val.id}
    //                             className='pdbm-section-button'
    //                             edit={edit}
    //                             fill='outline'
    //                             size='small'
    //                             text={reduxText[5593]}
    //                             to={isWeb ? object.get_absolute_url : `${view_url_project_detail}${object.id}/${object.slug}/image/`}
    //                         />
    //                     )
    //             ))}
    //         </div>
    //     )
    // }
    return null
})
