// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
} from 'react-router-dom'

import {
    ListSpinner,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_admin,
} from 'data'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getApiUrlV2,
    getDetailObject,
} from 'services'

// props
type DataMenuItemProps = {
    profileObject: ProfileDetailAdminSerializerV2
}

// main
export const DataMenuItem: React.FC<DataMenuItemProps> = React.memo(({
    profileObject,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [isChildOpen, setIsChildOpen] = useState<boolean>()
    const [items, setItems] = useState<any[]>()

    function onGetList() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`setup/list/contenttypesettings/used_in_profile/${profileObject.id}/`, reduxAuth),
                component: 'DataMenuItem',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DataMenuItem',
                'onGetList',
            ))
        }
    }

    return (
        <div className='admin-menu-item'>
            <div className='ami-tab-item-wrap'>
                <span
                    className='ami-tab-item'
                    onClick={() => {
                        if (!items) onGetList()
                        setIsChildOpen(!isChildOpen)
                    }}
                >
                    All data
                </span>
                <i
                    className={`ami-expand mo-new-icon-chevron-down-solid${isChildOpen ? ' open' : ''}`}
                    onClick={() => {
                        if (!items) onGetList()
                        setIsChildOpen(!isChildOpen)
                    }}
                />
            </div>
            <div className={`ami-children-wrap${isChildOpen ? ' open' : ''}`}>
                {!items && <div className='admin-menu-item-view'><ListSpinner isLoading /></div>}
                {items?.map((val) => (
                    <div
                        key={val.id}
                        className='admin-menu-item-view'
                    >
                        <NavLink
                            className='amiv-tab-item-children'
                            to={`${view_url_admin}${profileObject.id}/data/${val.content_type_label_model}/`}
                        >
                            {val.content_type_label_model.split('_')[0]} | {val.content_type_label_model.split('_')[1]}
                        </NavLink>
                    </div>
                ))}
            </div>
        </div>
    )
})
