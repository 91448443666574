// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonAvatar,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonThumbnail,
} from '@ionic/react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import debounce from 'lodash.debounce'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    ImageHelper,
    InfiniteScrollHelperWeb,
    SearchInput,
    SelectListInput,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    contentSourceApiUrls,
} from 'pages'

// serializers
import {
    ContentSourceDetailHelperSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getInfiniteListWeb,
} from 'services'

// props
type MatchParams = {
    id: string
}

type ContentDetailInputPortfolioProps = {
    assetCount?: number
    contentSourceId: number
    disabled?: boolean
    label: string
    mixedMediaContentType?: contentTypeModelType
    name: string
    onChange: any
    portfolioId: number | undefined
    profileId: number | undefined
    setIsOpen: React.Dispatch<boolean>
    value: ContentSourceDetailHelperSerializer | ''
}

// main
export const ContentDetailModalPortfolio: React.FC<ContentDetailInputPortfolioProps> = React.memo(({
    assetCount,
    contentSourceId,
    disabled,
    label,
    mixedMediaContentType,
    name,
    onChange,
    portfolioId,
    profileId,
    setIsOpen,
    value,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<any[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [isProject, setIsProject] = useState<any>((assetCount! > 0) ? { id: 'assets', name: 'Assets' } : '')
    const [newValue, setNewValue] = useState<any>()
    const [searchInput, setSearchInput] = useState('')

    useEffect(() => {
        onShowModal()
    }, [])

    const listApiUrl = contentSourceApiUrls[contentSourceId].apiUrl
    const layout = contentSourceApiUrls[contentSourceId].layout
    const usePortfolioId = contentSourceApiUrls[contentSourceId].usePortfolioId

    let wto: any

    function onShowModal() {
        try {
            if (disabled) return
            let filterString = ''
            if (isProject.id === 'assets' && mixedMediaContentType) {
                filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
            } else if (isProject.id === 'assets') {
                filterString += `&album_id=${params.id}`
            } else if (isProject.id === 'is_staff') {
                filterString += '&is_staff=true'
            } else if (isProject) {
                filterString += `&is_project=${isProject.id}`
            } else if (contentSourceId === 86 && mixedMediaContentType) {
                filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
            }
            onGetListData(`${getApiUrl(listApiUrl, reduxAuth)}?${usePortfolioId ? `portfolio_id=${portfolioId}` : `profile_id=${profileId}`}${filterString}`, true)
            setNewValue(value)
            clearTimeout(wto)
            wto = setTimeout(() => {
                searchBarRef.current?.setFocus()
            }, 100)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentDetailModalPortfolio',
                'onShowModal',
            ))
        }
    }

    function onRadioSelect(radioValue: any) {
        try {
            setNewValue(radioValue)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentDetailModalPortfolio',
                'onRadioSelect',
            ))
        }
    }

    function onIsProjectChange(e: any) {
        try {
            setIsProject(e.value)
            let filterString = ''
            if (e.value.id === 'assets' && mixedMediaContentType) {
                filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
            } else if (e.value.id === 'assets') {
                filterString += `&album_id=${params.id}`
            } else if (e.value.id === 'is_staff') {
                filterString += '&is_staff=true'
            } else {
                filterString += `&is_project=${e.value.id}`
            }
            onGetListData(`${getApiUrl(listApiUrl, reduxAuth)}?${usePortfolioId ? `portfolio_id=${portfolioId}` : `profile_id=${profileId}`}&search=${searchInput}${filterString}`, true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentDetailModalPortfolio',
                'onIsProjectChange',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'ContentDetailModalPortfolio',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentDetailModalPortfolio',
                'onGteListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentDetailModalPortfolio',
                'onSearchNext',
            ))
        }
    }

    function onSearch(value: string | null | undefined) {
        try {
            try {
                setSearchInput(value!)
                let filterString = ''
                if (isProject.id === 'assets' && mixedMediaContentType) {
                    filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
                } else if (isProject.id === 'assets') {
                    filterString += `&album_id=${params.id}`
                } else if (isProject.id === 'is_staff') {
                    filterString += '&is_staff=true'
                } else if (isProject) {
                    filterString += `&is_project=${isProject.id}`
                } else if (contentSourceId === 86 && mixedMediaContentType) {
                    filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
                }
                onGetListData(`${getApiUrl(listApiUrl, reduxAuth)}?${usePortfolioId ? `portfolio_id=${portfolioId}` : `profile_id=${profileId}`}&search=${value}${filterString}`, true)
            } catch (error) {
                dispatch(reduxModalErrorEventHandler(
                    error,
                    'ContentDetailModalPortfolio',
                    'onSelectRemove',
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToSectionForm',
                'onAddNewSection',
            ))
        }
    }

    const debouncedChangeHandler = debounce(onSearch, 300)

    // Extra
    const isProjectOptions = [
        // { id: 'all', name: reduxText[5445] },
        // { id: 'true', name: reduxText[6391] },
        { id: 'true', name: reduxText[5445] },
    ]
    if (assetCount! > 0) {
        isProjectOptions.push(
            { id: 'assets', name: reduxText[8149] },
        )
    }
    // if (reduxAuth.settings?.user?.id === 1) {
    //     isProjectOptions.push(
    //         { id: 'is_staff', name: 'All EC (super user only)' },
    //     )
    // }

    return (
        <Dialog
            className='mo-dialog-class content-detail-input-portfolio-modal-web'
            classes={{ paper: 'mo-dialog-wrapper fix-height fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {label}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='mo-dialog-searchbar-wrap'>
                    <SearchInput
                        classNameWrap='bg1'
                        onChange={(e) => debouncedChangeHandler(e)}
                        placeholder={reduxText[282]}
                    />
                    {isProjectOptions.length > 1 && [1, 2, 16, 40].includes(contentSourceId) && (
                        <div className='mo-dialog-select'>
                            <SelectListInput
                                clearable={false}
                                error=''
                                greyStyle
                                name='is_project'
                                noMarginBottom
                                onChange={onIsProjectChange}
                                options={isProjectOptions}
                                value={isProject || isProjectOptions[0]}
                            />
                        </div>
                    )}
                </div>
                <IonList>
                    <IonRadioGroup onIonChange={(e) => onRadioSelect(e.detail.value)}>
                        {items.map((item) => {
                            return (
                                <IonItem key={item.id}>
                                    {['get_image_xs'].includes(layout!) && (
                                        <IonAvatar slot='start'>
                                            <ImageHelper
                                                alt={item.name}
                                                dominant_color={item.get_dominant_color}
                                                src={item.get_image_xs}
                                            />
                                        </IonAvatar>
                                    )}
                                    {['get_image_lq'].includes(layout!) && (
                                        <IonThumbnail>
                                            <ImageHelper
                                                alt={item.name}
                                                className='cdipmw-thumb'
                                                dominant_color={item.get_dominant_color}
                                                src={item.get_image_lq}
                                            />
                                        </IonThumbnail>
                                    )}
                                    <IonLabel className='cdipmw-label'>
                                        <h3>{item.name}</h3>
                                        {item.product && <p>{item.product.name}</p>}
                                        {item.profile && <p>{item.profile.name}</p>}
                                        {item.service && <p>{item.service.name}</p>}
                                        {!item.sub_content_type && item.medium_type && <p className='cdipmw-medium-type'>{item.medium_type.name}</p>}
                                        {item.sub_content_type && <p className='cdipmw-medium-type'>{item.sub_content_type.name}</p>}
                                        {!item.active && !contentSourceApiUrls[contentSourceId].hasNoActive && <p className='cdipmw-not-active'>{reduxText[5616]}</p>}
                                        {item.is_private && <p className='cdipmw-not-active'>{reduxText[8547]}</p>}
                                    </IonLabel>
                                    <IonRadio
                                        slot='end'
                                        value={item}
                                    />
                                </IonItem>
                            )
                        })}
                    </IonRadioGroup>
                </IonList>
                <InfiniteScrollHelperWeb
                    active={!disableInfiniteScroll}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    items={items}
                    onChange={onSearchNext}
                />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen(false)}
                    text={reduxText[4519]}
                />
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => {
                        onChange({
                            name: name,
                            value: newValue,
                        })
                        setIsOpen(false)
                    }}
                    text={reduxText[3563]}
                />
            </DialogActions>
        </Dialog>
    )
})
