// constants
export const REDUX_FORM_GET_CSS_PROPERTIES = 'REDUX_FORM_GET_CSS_PROPERTIES'
export const REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN = 'REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN'
export const REDUX_FORM_SET_MIXED_PROFILE_ID = 'REDUX_FORM_SET_MIXED_PROFILE_ID'
export const REDUX_FORM_SET_REFRESH = 'REDUX_FORM_SET_REFRESH'

// actions
export function reduxFormGetCSSProperties(data: any[]) {
    return {
        type: REDUX_FORM_GET_CSS_PROPERTIES,
        payload: data,
    }
}

export function reduxFormSetIsSuccessToastOpen(value: boolean, message?: string) {
    return {
        type: REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN,
        payload: {
            message: message,
            value: value,
        },
    }
}

export function reduxFormSetMixedProfileIdOpen(profileId: number, fonts: any[], mother: any) {
    return {
        type: REDUX_FORM_SET_MIXED_PROFILE_ID,
        payload: {
            fonts: fonts,
            mother: mother,
            profileId: profileId,
        },
    }
}

export function reduxFormSetRefresh(
    refreshType:
        'refreshComment' |
        'refreshCustomCategory' |
        'refreshCustomChoice' |
        'refreshDetail' |
        'refreshFeed' |
        'refreshFeedSection' |
        'refreshForm' |
        'refreshInlineList' |
        'refreshPreview' |
        'refreshProfile' |
        'refreshRoom',
    refreshId?: number,
    noEmpty?: boolean,
) {
    return {
        type: REDUX_FORM_SET_REFRESH,
        payload: {
            noEmpty: noEmpty,
            refreshId: refreshId,
            refreshType: refreshType,
            refreshValue: Math.random(),
        },
    }
}
