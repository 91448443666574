// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    RefresherEventDetail,
} from '@ionic/core'
import {
    IonAvatar,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    FooterMobile,
    ImageHelper,
    InfiniteScrollHelperMobile,
    LinkHelper,
    Loader,
} from 'components'

// data
import {
    api_url_adherent_detail,
    api_url_event_detail,
    api_url_material_detail,
    api_url_offer_detail,
    api_url_product_detail,
    api_url_profile_detail_short_slug,
    api_url_profile_id_detail,
    api_url_profile_section_detail,
    api_url_project_detail,
    api_url_salesorder_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AdherentListSmallBlock,
    EventDetailBlockMobile,
    EventListBigBlockMobile,
    EventListMediumBlockMobile,
    EventListSmallBlockMobile,
    ImageListBlockMobile,
    MaterialDetailBlockMobile,
    MaterialListBigBlockMobile,
    MaterialListMediumBlockMobile,
    MaterialListSmallBlockMobile,
    NotFoundPage,
    OfferDetailBlockMobile,
    OfferListBigBlockMobile,
    OfferListMediumBlockMobile,
    OfferListSmallBlockMobile,
    ProductDetailBlockMobile,
    ProductListBigBlockMobile,
    ProductListMediumBlockMobile,
    ProductListSmallBlockMobile,
    ProfileDetailBlockMobile,
    ProfileListBigBlockMobile,
    ProfileListMediumBlockMobile,
    ProfileListSmallBlockMobile,
    ProjectDetailBlockMobile,
    ProjectListBigBlockMobile,
    ProjectListMediumBlockMobile,
    ProjectListSmallBlockMobile,
    SalesOrderListSmallBlock,
    ServiceListMediumBlock,
    ServiceListSmallBlock,
    TeamListBigBlockMobile,
    TeamListMediumBlockMobile,
    TeamListSmallBlockMobile,
} from 'pages'

// serializers
import {
    ProfileDetailShortSlugSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getInfiniteListMobileNew,
    listStateInitialMobile,
    listStatePropsMobile,
} from 'services'

// props
type MatchParams = {
    listStatus: string
    sectionId: string
    slug: string
}

// main
export const ProfileDetailListMobile: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const contentRef = useRef<HTMLIonContentElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const listStatus = params.listStatus || 'active'
    const profileSlug = params.slug
    const sectionId = params.sectionId
    const isUncategorized = sectionId.includes('--uncategorized')
    const listApiUrl = `${api_url_profile_section_detail}?section_id=${isUncategorized ? sectionId.split('--uncategorized')[0] : sectionId}&list_status=${listStatus}${isUncategorized ? '&is_uncategorized=true' : ''}`

    const componentSettings: any = {
        AdherentListSmallBlock: {
            component: AdherentListSmallBlock,
            detailApiUrl: getApiUrl(api_url_adherent_detail, reduxAuth),
            detailComponent: AdherentListSmallBlock,
            mapListComponent: AdherentListSmallBlock,
        },
        EventListBigBlockWeb: {
            component: EventListBigBlockMobile,
            detailApiUrl: getApiUrl(api_url_event_detail, reduxAuth),
            detailComponent: EventDetailBlockMobile,
            mapListComponent: EventListBigBlockMobile,
        },
        EventListMediumBlockWeb: {
            component: EventListMediumBlockMobile,
            detailApiUrl: getApiUrl(api_url_event_detail, reduxAuth),
            detailComponent: EventDetailBlockMobile,
            mapListComponent: EventListBigBlockMobile,
        },
        EventListSmallBlockWeb: {
            component: EventListSmallBlockMobile,
            detailApiUrl: getApiUrl(api_url_event_detail, reduxAuth),
            detailComponent: EventDetailBlockMobile,
            mapListComponent: EventListBigBlockMobile,
        },
        ImageListBlockWeb: {  // TODO
            component: ImageListBlockMobile,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ImageListBlockMobile,
            mapListComponent: ImageListBlockMobile,
        },
        MaterialListBigBlockWeb: {
            component: MaterialListBigBlockMobile,
            detailApiUrl: getApiUrl(api_url_material_detail, reduxAuth),
            detailComponent: MaterialDetailBlockMobile,
            mapListComponent: MaterialListBigBlockMobile,
        },
        MaterialListMediumBlockWeb: {
            component: MaterialListMediumBlockMobile,
            detailApiUrl: getApiUrl(api_url_material_detail, reduxAuth),
            detailComponent: MaterialDetailBlockMobile,
            mapListComponent: MaterialListBigBlockMobile,
        },
        MaterialListSmallBlockWeb: {
            component: MaterialListSmallBlockMobile,
            detailApiUrl: getApiUrl(api_url_material_detail, reduxAuth),
            detailComponent: MaterialDetailBlockMobile,
            mapListComponent: MaterialListBigBlockMobile,
        },
        OfferListBigBlockWeb: {
            component: OfferListBigBlockMobile,
            detailApiUrl: getApiUrl(api_url_offer_detail, reduxAuth),
            detailComponent: OfferDetailBlockMobile,
            mapListComponent: OfferListBigBlockMobile,
        },
        OfferListMediumBlockWeb: {
            component: OfferListMediumBlockMobile,
            detailApiUrl: getApiUrl(api_url_offer_detail, reduxAuth),
            detailComponent: OfferDetailBlockMobile,
            mapListComponent: OfferListBigBlockMobile,
        },
        OfferListSmallBlockWeb: {
            component: OfferListSmallBlockMobile,
            detailApiUrl: getApiUrl(api_url_offer_detail, reduxAuth),
            detailComponent: OfferDetailBlockMobile,
            mapListComponent: OfferListBigBlockMobile,
        },
        PressListBigBlockWeb: {
            component: ProjectListBigBlockMobile,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            mapListComponent: ProjectListBigBlockMobile,
        },
        PressListMediumBlockWeb: {
            component: ProjectListMediumBlockMobile,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            mapListComponent: ProjectListBigBlockMobile,
        },
        PressListSmallBlockWeb: {
            component: ProjectListSmallBlockMobile,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            mapListComponent: ProjectListBigBlockMobile,
        },
        ProductListBigBlockWeb: {
            component: ProductListBigBlockMobile,
            detailApiUrl: getApiUrl(api_url_product_detail, reduxAuth),
            detailComponent: ProductDetailBlockMobile,
            mapListComponent: ProductListBigBlockMobile,
        },
        ProductListMediumBlockWeb: {
            component: ProductListMediumBlockMobile,
            detailApiUrl: getApiUrl(api_url_product_detail, reduxAuth),
            detailComponent: ProductDetailBlockMobile,
            mapListComponent: ProductListBigBlockMobile,
        },
        ProductListSmallBlockWeb: {
            component: ProductListSmallBlockMobile,
            detailApiUrl: getApiUrl(api_url_product_detail, reduxAuth),
            detailComponent: ProductDetailBlockMobile,
            mapListComponent: ProductListBigBlockMobile,
        },
        ProfileListBigBlockWeb: {
            component: ProfileListBigBlockMobile,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            mapListComponent: ProfileListBigBlockMobile,
        },
        ProfileListMediumBlockWeb: {
            component: ProfileListMediumBlockMobile,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            mapListComponent: ProfileListBigBlockMobile,
        },
        ProfileListSmallBlockWeb: {
            component: ProfileListSmallBlockMobile,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            mapListComponent: ProfileListBigBlockMobile,
        },
        ProjectListBigBlockWeb: {
            component: ProjectListBigBlockMobile,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            mapListComponent: ProjectListBigBlockMobile,
        },
        ProjectListMediumBlockWeb: {
            component: ProjectListMediumBlockMobile,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            mapListComponent: ProjectListBigBlockMobile,
        },
        ProjectListSmallBlockWeb: {
            component: ProjectListSmallBlockMobile,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            mapListComponent: ProjectListBigBlockMobile,
        },
        ResourceListBigBlockWeb: {
            component: ProjectListBigBlockMobile,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            mapListComponent: ProjectListBigBlockMobile,
        },
        ResourceListMediumBlockWeb: {
            component: ProjectListMediumBlockMobile,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            mapListComponent: ProjectListBigBlockMobile,
        },
        ResourceListSmallBlockWeb: {
            component: ProjectListSmallBlockMobile,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            mapListComponent: ProjectListBigBlockMobile,
        },
        SalesOrderListSmallBlock: {
            component: SalesOrderListSmallBlock,
            detailApiUrl: getApiUrl(api_url_salesorder_detail, reduxAuth),
            detailComponent: SalesOrderListSmallBlock,
            // filterSet: salesOrderFilterOptions,
            mapListComponent: SalesOrderListSmallBlock,
            // tableHeaderComponent: SalesOrderListHeader,
            // tabToSkip: ['private', 'unlisted'],
        },
        ServiceListBigBlock: {
            component: ServiceListSmallBlock,
            // detailApiUrl: getApiUrl(api_url_service_detail, reduxAuth),
            // // detailComponent: ServiceLDetailBlockMobile,
            // mapListComponent: ServiceListBigBlock,
        },
        ServiceListMediumBlock: {
            component: ServiceListMediumBlock,
            // detailApiUrl: getApiUrl(api_url_service_detail, reduxAuth),
            // detailComponent: ServiceLDetailBlockMobile,
            // mapListComponent: ServiceListBigBlock,
        },
        ServiceListSmallBlock: {
            component: ServiceListSmallBlock,
            // detailApiUrl: getApiUrl(api_url_service_detail, reduxAuth),
            // detailComponent: ServiceLDetailBlockMobile,
            // mapListComponent: ServiceListBigBlock,
        },
        TeamListBigBlockWeb: {
            component: TeamListBigBlockMobile,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            mapListComponent: TeamListBigBlockMobile,
        },
        TeamListMediumBlockWeb: {
            component: TeamListMediumBlockMobile,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            mapListComponent: TeamListBigBlockMobile,
        },
        TeamListSmallBlockWeb: {
            component: TeamListSmallBlockMobile,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            mapListComponent: TeamListBigBlockMobile,
        },
    }

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsMobile>({
        ...listStateInitialMobile,
        modalKey: Math.random(),
    })

    const [detailObject, setDetailObject] = useState<ProfileDetailShortSlugSerializer | undefined>(undefined)
    const [errorStatus, setErrorStatus] = useState<number>()
    const [thumbSize, setThumbSize] = useState<'big' | 'medium' | 'small'>('big')

    const componentObject = componentSettings[listState.componentString]

    useEffect(() => {
        onGetDetailData()
    }, [
        profileSlug,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        onGetListData(
            getApiUrl(listApiUrl, reduxAuth),
            true,
        )
    }, [
        params.listStatus,
        profileSlug,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
        params.sectionId,
        thumbSize,
    ])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_profile_detail_short_slug}${profileSlug}/?is_left=true`, reduxAuth),
                component: 'ProfileDetailListMobile',
                dispatch,
                reduxAuth,
                setDetailObject,
                setErrorStatus,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListMobile',
                'onGetDetailData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        refreshEvent?: CustomEvent<RefresherEventDetail>,
    ) {
        try {

            let axiosUrl = ''
            if (changingView) {
                if ((apiUrl).includes('?')) {
                    axiosUrl = `${apiUrl}&block_size=${thumbSize}`
                } else {
                    axiosUrl = `${apiUrl}?block_size=${thumbSize}`
                }
            } else {
                axiosUrl = apiUrl
            }

            getInfiniteListMobileNew(
                axiosUrl,
                reduxAuth,
                listState,
                setListState,
                axiosCancelToken,
                setAxiosCancelToken,
                dispatch,
                'ProfileDetailListMobile',
                changingView,
                refreshEvent,
                '',
                undefined,
                true,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListMobile',
                'getListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListMobile',
                'onSearchNext',
            ))
        }
    }

    function onSetThumbSize(value: 'big' | 'medium' | 'small') {
        try {
            setThumbSize(value)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListMobile',
                'onSetThumbSize',
            ))
        }
    }

    function onRefresh(event: CustomEvent<RefresherEventDetail>) {
        try {
            onGetListData(
                listApiUrl,
                true,
                event,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListMobile',
                'onRefresh',
            ))
        }
    }

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop(500)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListMobile',
                'scrollToTop',
            ))
        }
    }

    if (errorStatus) {
        return <NotFoundPage statusCode={errorStatus} />
    }
    if (!detailObject) {
        return (
            <Loader isOpen />
        )
    }

    const sectionsObject: any = {}
    detailObject.tabs_left?.map((val) => {
        sectionsObject[val.id!] = {
            content_source: val.content_source,
            name: val.name,
        }
        sectionsObject[`${val.id}--uncategorized`] = {
            content_source: val.content_source,
            is_other: true,
            name: `${val.name}--uncategorized`,
        }
        val.children_tabs?.map((val2) => {
            sectionsObject[val2.id!] = {
                id: val2.id,
                content_source: val2.content_source,
                is_custom: true,
                name: val2.name,
            }
        })
    })

    const tabObject = sectionsObject[sectionId]


    const ListComponentName = componentObject?.component

    // @ts-ignore
    const locationDetailObject = location.state?.profileObject

    return (
        <IonPage>
            <IonHeader
                className='mo-header'
                translucent
            >
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonBackButton
                            defaultHref='/'
                            text=''
                        />
                    </IonButtons>
                    <IonTitle
                        onClick={() => scrollToTop()}
                    >
                        {tabObject?.name}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                className='detail-list-container-mobile'
                forceOverscroll
                fullscreen
                ref={contentRef}
            >
                <IonRefresher
                    onIonRefresh={onRefresh}
                    slot='fixed'
                >
                    <IonRefresherContent />
                </IonRefresher>
                <IonHeader
                    collapse='condense'
                    translucent
                >
                    <IonToolbar>
                        <div className='dlcm-header-wrap'>
                            <LinkHelper
                                className='dlcm-profile-pic'
                                edit={false}
                                onClick={() => locationDetailObject ? history.goBack() : undefined}
                                to={locationDetailObject ? undefined : detailObject?.get_absolute_url}
                            >
                                <IonAvatar className='dlcm-image-link'>
                                    <ImageHelper
                                        alt={detailObject?.name}
                                        className='dlcm-image'
                                        dominant_color={undefined}
                                        lazyMobile={false}
                                        src={detailObject?.get_image_lq}
                                    />
                                </IonAvatar>
                            </LinkHelper>
                            <LinkHelper
                                className='dlcm-profile-name'
                                edit={false}
                                onClick={() => locationDetailObject ? history.goBack() : undefined}
                                to={locationDetailObject ? undefined : detailObject?.get_absolute_url}
                            >
                                <p className='mo-clamp-mobile1'>{detailObject?.name}</p>
                            </LinkHelper>
                            <div className='dlcm-thumb-wrap'>
                                <i
                                    className={`dlcm-size-icon mo-new-icon-gallery${thumbSize === 'small' ? ' active' : ''}`}
                                    onClick={() => onSetThumbSize('small')}
                                />
                                <i
                                    className={`dlcm-size-icon mo-new-icon-list${thumbSize === 'medium' ? ' active' : ''}`}
                                    onClick={() => onSetThumbSize('medium')}
                                />
                                <i
                                    className={`dlcm-size-icon mo-new-icon-expand-solid${thumbSize === 'big' ? ' active' : ''}`}
                                    onClick={() => onSetThumbSize('big')}
                                />
                            </div>
                        </div>
                    </IonToolbar>
                    <IonToolbar>
                        <IonTitle size='large'>{tabObject?.name}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className={`dlcm-wrap ${thumbSize}`}>
                    {listState.items?.map((item) => {
                        return (
                            <ListComponentName
                                key={item.id}
                                edit={false}
                                modalKey={listState.modalKey}
                                object={item}
                                playerOrigin='profileDetailList'
                            />
                        )
                    })}
                </div>
                <InfiniteScrollHelperMobile
                    active={!listState.disableInfiniteScroll}
                    hasMore={listState.hasMore}
                    isLoading={listState.isLoading}
                    items={listState.items}
                    onChange={onSearchNext}
                />
            </IonContent>
            <FooterMobile />
        </IonPage>
    )
}
