// react components
import React from 'react'

// pages
import {
    EventDetailBlockWeb,
    MaterialDetailBlockWeb,
    OfferDetailBlockWeb,
    ProductDetailWrapper,
    ProfileDetailBlock,
    ProfileDetailBlockWebOld,
    ProjectDetailBlockWeb,
} from 'pages'

// props
type PreviewBlockMobileProps = {
    detailComponent: string
    object: any
}

// main
export const PreviewBlockMobile: React.FC<PreviewBlockMobileProps> = React.memo(({
    detailComponent,
    object,
}) => {

    const componentsDetail: any = {
        EventDetailBlockWeb,
        MaterialDetailBlockWeb,
        OfferDetailBlockWeb,
        ProductDetailWrapper,
        ProfileDetailBlock,
        ProfileDetailBlockWebOld,
        ProjectDetailBlockWeb,
    }

    const DetailComponentName = componentsDetail[detailComponent]

    return (
        <div className='detail-view-preview'>
            <DetailComponentName
                edit
                modalKeys={{}}
                object={object}
            />
        </div>
    )
})
