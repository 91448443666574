// react components
import React from 'react'
import PhoneInput from 'react-phone-number-input'
import {
    useDispatch,
} from 'react-redux'

// styles
import 'react-phone-number-input/style.css'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// props
type PhoneNumberInputProps = {
    adminOnly?: boolean
    error: string | undefined
    helperText?: string
    label: string
    name: string
    onChange: any
    optional?: boolean
    staffOnly?: boolean
    value: any
}

// main
export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = React.memo(({
    adminOnly,
    error,
    helperText,
    label,
    name,
    onChange,
    optional,
    staffOnly,
    value,
}) => {

    const dispatch = useDispatch()

    function onInputChange(value: string) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PhoneNumberInput',
                'onInputChange',
            ))
        }
    }

    return (
        <div className='mo-phone-number-input-web'>
            <MainFormLabel
                adminOnly={adminOnly}
                helperText={helperText}
                name={name}
                label={label}
                optional={optional}
                staffOnly={staffOnly}
            />
            <PhoneInput
                // @ts-ignore
                onChange={onInputChange}
                value={value || ''}
            />
            <ErrorHelper error={error} />
        </div>
    )
})
