// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
} from 'services'

// main
export const AgendaDuQuartierQuotation: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [items, setItems] = useState<any[]>([])

    useEffect(() => {
        onGetDetailData()
    }, [])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/list/quotation/`, reduxAuth),
                component: 'AgendaDuQuartierQuotation',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuotation',
                'onGetDetailData',
            ))
        }
    }

    async function acceptQuotation(stripe_quotation_id: string) {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuotation acceptQuotation')
            const postUrl = getApiUrlV2(`customer/agendaduquartier/form/quotation/accept/`, reduxAuth)
            const formData = new FormData()
            formData.append('stripe_quotation_id', stripe_quotation_id)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    onGetDetailData()
                    setIsLoading(false)
                    window.open(response.data.hosted_invoice_url, '_blank')
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'AgendaDuQuartierQuotation',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'acceptQuotation',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuotation',
                'acceptQuotation',
            ))
        }
    }
    async function downloadQuotation(stripe_quotation_id: string, stripe_quotation_number: string) {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuotation downloadQuotation')
            const postUrl = getApiUrlV2(`customer/agendaduquartier/form/quotation/download_pdf/`, reduxAuth)
            const formData = new FormData()
            formData.append('stripe_quotation_id', stripe_quotation_id)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
                responseType: 'blob',
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${stripe_quotation_number}.pdf`)
                    document.body.appendChild(link)
                    link.click()

                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'AgendaDuQuartierQuotation',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'downloadQuotation',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuotation',
                'downloadQuotation',
            ))
        }
    }

    const count = items.length

    return (
        <div className='admin-module'>
            <div className='am-header'>
                <div className='am-header-title'>
                    <p>Mes devis</p>
                </div>
            </div>
            <div className={`am-infinite-table mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <table>
                    <thead>
                        <tr className='table-header'>
                            <th className='th-column field-action'>
                                <div className='th-div'>{count !== undefined ? `(${count})` : '()'}</div>
                            </th>
                            <th className='th-column'>
                                <div className='th-div-wrap'>
                                    <div className='th-div'>Numéro</div>
                                </div>
                            </th>
                            <th className='th-column'>
                                <div className='th-div-wrap'>
                                    <div className='th-div'>Montant</div>
                                </div>
                            </th>
                            <th className='th-column' colSpan={2}>
                                <div className='th-div-wrap'>
                                    <div className='th-div'>Action</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => {
                            return (
                                <tr
                                    key={item.id}
                                    className='table-row'
                                >
                                    <td className='tr-action' />
                                    <td className='table-column'>
                                        <div className='tc-link-div'>
                                            <span className='table-column-value'>
                                                {item.number}
                                            </span>
                                        </div>
                                    </td>
                                    <td className='table-column'>
                                        <div className='tc-link-div'>
                                            <span className='table-column-value'>
                                                {Number(item.amount_total) / 100} €
                                            </span>
                                        </div>
                                    </td>
                                    <td className='table-column'>
                                        <div className='tc-link-div'>
                                            <Button
                                                edit={false}
                                                fill='outline'
                                                onClick={() => downloadQuotation(item.id, item.number)}
                                                size='x-small'
                                                text='Télécharger'
                                            />
                                        </div>
                                    </td>
                                    <td className='table-column'>
                                        <div className='tc-link-div'>
                                            {!item.invoice && (
                                                <Button
                                                    disabled={item.status !== 'open'}
                                                    edit={false}
                                                    fill='outline'
                                                    onClick={() => acceptQuotation(item.id)}
                                                    size='x-small'
                                                    text={item.status === 'open' ? 'Valider' : 'Expiré'}
                                                />
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {isLoading && <Loader isOpen />}
        </div>
    )
}
