// react components
import React from 'react'
import axios from 'axios'

// data
import {
    reduxAuthState,
    reduxFormSetDynamicTextOptionsSite,
    reduxModalErrorEventHandler,
    select_url_portfolio_dynamic_text_option,
} from 'data'

// serializers
import {
    ContentTypeSettingsCustomTypes,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export async function getDynamicTextOptions({
    component,
    contentTypeSettingsCustom,
    dispatch,
    reduxAuth,
}: {
    component: string
    contentTypeSettingsCustom?: ContentTypeSettingsCustomTypes
    dispatch: React.Dispatch<any>
    reduxAuth: reduxAuthState
}) {
    try {
        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getDynamicTextOptions`)
        const getUrl = getApiUrl(select_url_portfolio_dynamic_text_option, reduxAuth)
        axios({
            headers: refreshAxiosHeaders,
            method: 'get',
            url: getUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                const returnData: any[] = []
                const foundObjectData: any[] = []
                if (contentTypeSettingsCustom) {
                    Object.keys(contentTypeSettingsCustom).forEach((prop) => {
                        const foundObject = response.data.find((obj: any) => obj.content_type_label_model === prop)
                        // @ts-ignore
                        if (foundObject && contentTypeSettingsCustom[prop].field) {
                            // @ts-ignore
                            Object.keys(contentTypeSettingsCustom[prop].field).forEach((prop2) => {
                                const foundOption = foundObject.options.find((obj2: any) => obj2.id === prop2)
                                if (foundOption) {
                                    // @ts-ignore
                                    foundOption.name = contentTypeSettingsCustom[prop].field[prop2].verboseName
                                } else {
                                    foundObject.options.push({
                                        id: prop2,
                                        // @ts-ignore
                                        name: contentTypeSettingsCustom[prop].field[prop2].verboseName,
                                    })
                                }
                            })
                            foundObjectData.push(foundObject)
                        }
                    })
                }
                response.data.map((obj: any) => {
                    const foundObject2 = foundObjectData.find((obj2: any) => obj2.content_type_label_model === obj.content_type_label_model)
                    if (foundObject2) {
                        obj.options = foundObject2.options
                    }
                    returnData.push(obj)
                })
                dispatch(reduxFormSetDynamicTextOptionsSite(returnData))
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: getUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'getDynamicTextOptions',
                })
            })
    } catch (error) {
        dispatch(reduxModalErrorEventHandler(
            error,
            component,
            'getDynamicTextOptions',
        ))
    }
}
