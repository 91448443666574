// react components
import React from 'react'

// pages
import {
    EventListMediumBlockWeb,
    EventListSmallBlockWeb,
    MaterialListMediumBlockWeb,
    MaterialListSmallBlockWeb,
    OfferListMediumBlockWeb,
    OfferListSmallBlockWeb,
    PressListSmallBlockWeb,
    ProductListMediumBlockWeb,
    ProductListSmallBlockWeb,
    ProjectListMediumBlockWeb,
    ProjectListSmallBlockWeb,
    ServiceListMediumBlock,
    ServiceListSmallBlock,
} from 'pages'

// serializers
import {
    GeneralFeedListSerializer,
} from 'serializers/web'

// props
type FeedListSmallBlockWebProps = {
    edit: boolean
    isInProfileSlug?: string
    modalKey: number
    object: GeneralFeedListSerializer
}

// main
export const FeedListSmallBlockWeb: React.FC<FeedListSmallBlockWebProps> = React.memo(({
    edit,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const components: any = {
        EventListMediumBlockWeb,
        EventListSmallBlockWeb,
        MaterialListMediumBlockWeb,
        MaterialListSmallBlockWeb,
        OfferListMediumBlockWeb,
        OfferListSmallBlockWeb,
        PressListSmallBlockWeb,
        ProductListMediumBlockWeb,
        ProductListSmallBlockWeb,
        ProjectListMediumBlockWeb,
        ProjectListSmallBlockWeb,
        ServiceListMediumBlock,
        ServiceListSmallBlock,
    }
    const ComponentName = components[object.content_object.component_name]

    if (ComponentName) {
        return <ComponentName
            edit={edit}
            feedId={object.id}
            isInProfileSlug={isInProfileSlug}
            modalKey={modalKey}
            object={object.content_object}
        />
    }

    return null
})
