// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetRefresh,
    reduxFormSetSectionCollapseSite,
    reduxModalErrorEventHandler,
    reduxModalLayoutInputShow,
} from 'data'

// pages
import {
    ContentBlockEditMixedMediaContent,
} from 'pages'

// serializers
import {
    BlockTemplateSelectSerializer,
    ProjectFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// props
type ListMixedMediaContentPanelProps = {
    contentType: contentTypeModelType
    formState: ProjectFormState
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    setTabValue?: React.Dispatch<string>
}

// main
export const ListMixedMediaContentPanel: React.FC<ListMixedMediaContentPanelProps> = React.memo(({
    contentType,
    formState,
    newMixedMediaContent,
    newMixedMediaContentType,
    setTabValue,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const mixedMediaContent = formState.mixedMediaContent

    const [isLoading, setIsLoading] = useState(false)

    async function handleSubmit(submitLayout: BlockTemplateSelectSerializer, special: 'symbol') {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ListMixedMediaContentPanel handleSubmit')

            const postUrl = mixedMediaContent.createUrl
            const formData: any = new FormData()
            formData.append('template_content_source', submitLayout.id)
            formData.append('object_id', mother.fields?.id)
            formData.append('content_type', contentTypeData[contentType])
            if (special === 'symbol') {
                formData.append('symbol_content', submitLayout.id)
            }
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (newMixedMediaContent) {
                        history.push(`/form-mixed-media/${contentType}/${mother.fields?.id}/${response.data.id}/`)
                    } else {
                        history.push(`${mother.formUrl}${mother.fields?.id}/mixed-media-content/${response.data.id}/`)
                    }
                    dispatch(reduxFormSetRefresh('refreshPreview', mother.fields?.id))
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'ListMixedMediaContentPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListMixedMediaContentPanel',
                'handleSubmit',
            ))
        }
    }

    return (
        <div className={`list-mixed-content-panel-web mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className={`lmcpw-content-list mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <i
                    className={`lmcpw-expand mo-new-icon-sort${reduxFormSite.sectionCollapse ? '' : '-reverse'}`}
                    onClick={() => dispatch(reduxFormSetSectionCollapseSite(!reduxFormSite.sectionCollapse))}
                />
                {reduxFormSite.pageContentItems?.map((val) => (
                    <ContentBlockEditMixedMediaContent
                        key={val.id}
                        contentType={contentType}
                        mother={mother}
                        newMixedMediaContent={newMixedMediaContent}
                        newMixedMediaContentType={newMixedMediaContentType}
                        object={val}
                        setTabValue={setTabValue}
                    />
                ))}
            </div>
            <div className='lmcpw-footer'>
                <Button
                    edit={false}
                    expand='full'
                    fill='outline'
                    onClick={() => dispatch(reduxModalLayoutInputShow(
                        handleSubmit,
                        true,
                    ))}
                    text={reduxText[6395]}
                />
            </div>
            {isLoading && (
                <Loader
                    isOpen
                    message={reduxText[6437]}
                />
            )}
        </div>
    )
})
