// react components
import React from 'react'

// serializers
import {
    EventPricingListRowSerializer,
} from 'serializers/web'

// props
type PricingListRowProps = {
    object: EventPricingListRowSerializer
}

// main
export const PricingListRow: React.FC<PricingListRowProps> = React.memo(({
    object,
}) => {

    return (
        <tr className='main-list-row PricingListRow'>
            <td>{object.name}</td>
            <td><i className={`mlr-icon ${object.is_for_non_adherent ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
            <td>{object.adhesion_pricing?.name}</td>
            <td>{object.unit_price} €</td>
        </tr>
    )
})
