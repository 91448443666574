// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// main
export const EventParticipantListFormHeader: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <tr className='main-list-header EventParticipantListFormHeader'>
            <th>{reduxText[8608]}</th>
            <th>{reduxText[8642]}</th>
            <th>{reduxText[6462]}</th>
            <th>{reduxText[6461]}</th>
            <th>{reduxText[8557]}</th>
            <th>{reduxText[8553]}</th>
            <th>{reduxText[8554]}</th>
            <th />
        </tr>
    )
}
