// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ParticipantListRowSerializer,
} from 'serializers/web'

// props
type ParticipantListHeaderProps = {
    object: ParticipantListRowSerializer | undefined
}

// main
export const ParticipantListHeader: React.FC<ParticipantListHeaderProps> = React.memo(({
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <tr className='main-list-header ParticipantListHeader'>
            {object?.event?.profile === 183302 && (
                <th>Id {reduxText[8552]}</th>
            )}
            <th>{reduxText[6462]}</th>
            <th>{reduxText[6461]}</th>
            {object?.event?.profile === 183302 && (
                <>
                    <th>Secteur d'activité</th>
                    <th>Ville</th>
                </>
            )}
            <th>{reduxText[8557]}</th>
            <th>{reduxText[8553]}</th>
            <th>{reduxText[8554]}</th>
            <th />
        </tr>
    )
})
