// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainHashtag,
    ProjectPlayButtonMobile,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    ProjectActionSheetMobile,
} from 'pages'

// serializers
import {
    ProjectListSerializer,
} from 'serializers/web'

// services
import {
    getDateWithType,
} from 'services'

// props
type ProjectListBigBlockMobileProps = {
    edit: boolean
    feedCreatedOn?: string
    modalKey: number
    object: ProjectListSerializer
    playerOrigin: string
}

// main
export const ProjectListBigBlockMobile: React.FC<ProjectListBigBlockMobileProps> = React.memo(({
    edit,
    feedCreatedOn,
    modalKey,
    object,
    playerOrigin,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [isActionSheetOpen, setActionSheetIsOpen] = useState(false)

    const count_array: any = {
        1: reduxText[4910],
        8: reduxText[2447],
        4: reduxText[4912],
        5: reduxText[1767],
    }

    return (
        <div
            className='project-list-big-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='project'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <div className='plbb-cover'>
                <LinkHelper
                    edit={edit}
                    to={object.get_absolute_url}
                    className='plbb-cover-wrap'>
                    <ImageHelper
                        alt={object.name}
                        className='plbb-image'
                        defaultHeightMobile
                        dominant_color={undefined}
                        src={object.get_image_lq}
                    />
                    {object.duration !== '00:00:00' && (
                        <p className='plbb-extra-info'>{object.duration}</p>
                    )}
                    {object.medium_type && count_array[object.medium_type] && (
                        <p className='plbb-extra-info'>{`${object.count_project} ${count_array[object.medium_type]}`}</p>
                    )}
                </LinkHelper>
                {object.is_playable && (
                    <div className='plbb-play-btn'>
                        <ProjectPlayButtonMobile
                            edit={edit}
                            modalKey={modalKey}
                            object={object}
                            playerOrigin={playerOrigin}
                        />
                    </div>
                )}
            </div>
            <div className='plbb-bottom'>
                <div className='plbb-bottom-header'>
                    <span className='plbb-feed-date'>{feedCreatedOn ? getDateWithType(reduxAuth, feedCreatedOn, 'n') : ''}</span>
                    <div className='plbb-actions'>
                        <IconBlock
                            edit={edit}
                            iconClass='mo-new-icon-ellipsis-h-solid'
                            onClick={() => setActionSheetIsOpen(true)}
                        />
                    </div>
                </div>
                <LinkHelper
                    className='mo-list-block-name-mobile'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    {object.name}
                </LinkHelper>
                <div className='plbb-tags'>
                    <MainChipListBlock>
                        {object.themes?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
                <div className='list-block-footer'>
                    {object.profile && (
                        <ListProfileLink
                            blockSize='big'
                            edit={edit}
                            other_profiles={object.other_profiles}
                            profile={object.profile}
                        />
                    )}
                </div>
                {/* {source && source.includes('profile_project') && object.credit && (
					<div className='plbb-credit-detail'>
						<div className='plbb-roles'>
							{Array.isArray(object.credit.roles) && object.credit.roles.map((val) => (
								<MainHashtag
									key={val.id}
                                    edit={edit}
									label={val.name}
								/>
							))}
						</div>
					</div>
				)} */}
            </div>
            {isActionSheetOpen && (
                <ProjectActionSheetMobile
                    object={object}
                    setActivated={setActivated}
                    setIsOpen={setActionSheetIsOpen}
                />
            )}
        </div>
    )
})
