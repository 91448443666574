// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    Loader,
    Popover,
} from 'components'

// data
import {
    api_url_portfolio_action,
    api_url_portfolio_form_duplicate,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_portfolio_form,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    setStaffPermission,
} from 'services'

// props
type PortfolioActionSheetProps = {
    edit: boolean
    inModal?: boolean
    object: any
    setShowActionPopover: React.Dispatch<boolean>
    showActionPopover: boolean
}

// main
export const PortfolioActionSheet: React.FC<PortfolioActionSheetProps> = React.memo(({
    edit,
    inModal,
    object,
    setShowActionPopover,
    showActionPopover,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (showActionPopover) getData(object.id)
    }, [
        object.id,
        showActionPopover,
    ])

    type actionObjectType = {
        active?: boolean
        has_permission?: boolean
        original_image?: string
        staff_permission?: boolean
    }

    const [buttons, setButtons] = useState<any[]>([])
    const [isDuplicateAlertOpen, setIsDuplicateAlertOpen] = useState(false)
    const [isDuplicateLoading, setIsDuplicateLoading] = useState(false)

    async function getData(objectId: string) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PortfolioActionSheet getData')

            if (!objectId) return
            const getUrl = getApiUrl(`${api_url_portfolio_action}${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!response.data) return
                    const actionObject: actionObjectType = response.data
                    const newButtons = []

                    // Permission
                    if (actionObject.has_permission) {
                        newButtons.push(
                            {
                                text: reduxText[2105],
                                role: 'edit',
                                to: `${view_url_portfolio_form}${object.id}/`,
                                handler: () => {
                                    setShowActionPopover(false)
                                }
                            },
                        )
                    }
                    // if (actionObject.has_permission) {
                    if (reduxAuth.settings?.user?.id === 1) {
                        newButtons.push(
                            {
                                text: reduxText[6435],
                                handler: () => {
                                    setIsDuplicateAlertOpen(true)
                                }
                            },
                        )
                    }

                    // Staff Permission
                    if (reduxAuth.settings?.user?.groups?.includes(8)) {
                        newButtons.push(
                            {
                                text: actionObject.staff_permission ? 'Remove staff permission' : 'Set staff permission',
                                handler: () => {
                                    setStaffPermission({
                                        component: 'PortfolioActionSheet',
                                        contentTypeModel: 'portfolio',
                                        dispatch,
                                        objectId: object.id,
                                        reduxAuth,
                                    })
                                    setShowActionPopover(false)
                                }
                            },
                        )
                    }

                    // Image
                    if (actionObject.original_image) {
                        newButtons.push(
                            {
                                text: reduxText[7855],
                                handler: () => window.open(actionObject.original_image, '_blank')
                            },
                        )
                    }

                    setButtons(newButtons)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'PortfolioActionSheet',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioActionSheet',
                'getData',
            ))
        }
    }

    async function handleDuplicate() {
        try {
            setIsDuplicateLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PortfolioActionSheet handleDuplicate')
            const formData = new FormData()
            formData.append('duplicate', 'true')
            const duplicateUrl = getApiUrl(`${api_url_portfolio_form_duplicate}${object.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: duplicateUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDuplicateLoading(false)
                    setShowActionPopover(false)
                })
                .catch((error) => {
                    setIsDuplicateLoading(false)
                    setShowActionPopover(false)
                    axiosErrorHandler({
                        apiUrl: duplicateUrl,
                        component: 'PortfolioActionSheet',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDuplicate',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioActionSheet',
                'handleDuplicate',
            ))
        }
    }

    return (
        <React.Fragment>
            <Popover
                buttons={buttons}
                edit={edit}
                extraZIndex={inModal}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
            />
            {isDuplicateAlertOpen && (
                <Alert
                    buttons={[
                        {
                            text: reduxText[4519],
                            handler: () => {
                                setShowActionPopover(false)
                            }
                        },
                        {
                            text: reduxText[6435],
                            handler: () => {
                                handleDuplicate()
                            }
                        },
                    ]}
                    header={reduxText[7680]}
                    isOpen
                    message={reduxText[7681]}
                    onDidDismiss={() => setIsDuplicateAlertOpen(false)}
                />
            )}
            {isDuplicateLoading && (
                <Loader
                    isOpen
                    message={reduxText[7712]}
                />
            )}
        </React.Fragment>
    )
})
