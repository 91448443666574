// data
import {
    api_url_contribution_form_active,
    api_url_contribution_form_create,
    api_url_contribution_form_detail,
    api_url_contribution_form_list,
    api_url_contribution_form_update,
    reduxAuthState,
    view_url_contribution_detail,
    view_url_contribution_form,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function ContributionFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'contribution',
        model: 'contribution',

        // Settings
        detailComponent: 'ContributionDetailBlock',

        // Urls
        activeUrl: getApiUrl(api_url_contribution_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_contribution_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_contribution_form_detail, reduxAuth),
        formUrl: view_url_contribution_form,
        listUrl: getApiUrl(api_url_contribution_form_list, reduxAuth),
        updateUrl: getApiUrl(api_url_contribution_form_update, reduxAuth),
        viewUrl: view_url_contribution_detail,

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [],
        requiredForeignKey: [
            'adherent',
            'pricing',
            'profile',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'payment_date',
            'year',
        ],
        formFile: [],
        formForeignKey: [
            'adherent',
            'pricing',
            'profile',
        ],
        formJson: [],
        formManyToMany: [],
    }
}
