// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// services
import {
    getReduxTextId,
} from 'services'

// props
type TableHeaderCustomProps = {
    columns: {
        'id': string
        'name': string
        'type': string
    }[]
    count: number | undefined
}

// main
export const TableHeaderCustom: React.FC<TableHeaderCustomProps> = React.memo(({
    columns,
    count,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <tr className='table-header'>
            <th className='th-column field-action'>
                <div className='th-div'>{count !== undefined ? `(${count})` : '()'}</div>
            </th>
            {columns.map(val => (
                <th
                    key={val.id}
                    className={`th-column field-${val.type}`}
                >
                    <div className='th-div'>{getReduxTextId(val.name, reduxText)}</div>
                </th>
            ))}
        </tr>
    )
})
