// react components
import React, {
    // ComponentProps,
} from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type AlertProps = {
    buttons: {
        // color?: ComponentProps<typeof Button>['color']
        color?: string
        text: string
        handler?: () => void
    }[]
    header: string
    isErrorAlert?: boolean
    isOpen: boolean
    message?: string | undefined
    onDidDismiss: () => void
}

// main
export const Alert: React.FC<AlertProps> = React.memo(({
    buttons,
    header,
    isErrorAlert,
    isOpen,
    message,
    onDidDismiss,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    function onButtonClick(e?: any) {
        try {
            if (e) e()
            onDidDismiss()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'Alert',
                'onButtonClick',
            ))
        }
    }

    function onClose() {
        try {
            onDidDismiss()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'Alert',
                'onClose',
            ))
        }
    }

    if (isOpen) {
        return (
            <Dialog
                className='mo-dialog-class Alert'
                classes={{ paper: 'mo-dialog-wrapper fix-width' }}
                onClose={onClose}
                open
            >
                <DialogTitle>{header}</DialogTitle>
                <DialogContent>
                    {isErrorAlert
                        ? (
                            reduxAuth.settings?.language === 'fr'
                                ? (
                                    <>
                                        <p>Si cette erreur persiste, essayez les solutions suivantes :</p>
                                        <ul>
                                            <li>Actualisez la page.</li>
                                            {reduxAuth.authenticated && (
                                                <li>Déconnectez-vous et reconnectez-vous.</li>
                                            )}
                                            <li>Contactez notre équipe de support.</li>
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <p>If you keep seeing this error, try:</p>
                                        <ul>
                                            <li>Refresh the page.</li>
                                            {reduxAuth.authenticated && (
                                                <li>Log out and log in again.</li>
                                            )}
                                            <li>Contact our support team.</li>
                                        </ul>
                                    </>
                                )
                        ) : (
                            <p className='pre-line'>{message}</p>
                        )
                    }
                </DialogContent>
                <DialogActions className='mo-dialog-footer'>
                    {buttons.map((val, i) => (
                        <Button
                            key={i}
                            // @ts-ignore
                            color={val.color}
                            edit={false}
                            fill='outline'
                            onClick={() => onButtonClick(val.handler)}
                            text={val.text}
                        />
                    ))}
                </DialogActions>
            </Dialog>
        )
    }

    return null
})
