// react components
import React from 'react'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// props
type TrackContentBlockMobileProps = {
    edit: boolean
    modalKey: number
    object: ProjectDetailSerializer
}

// main
export const TrackContentBlockMobile: React.FC<TrackContentBlockMobileProps> = React.memo(({
    edit,
    modalKey,
    object,
}) => {

    // if (object.content_list?.track?.length! > 0) {
    //     return (
    //         <div className='track-content-block'>
    //             {object.content_list!.track!.map((val, i) => (
    //                 i < 3
    //                     ? (
    //                         <ProjectListMediumBlockMobile
    //                             key={val.id}
    //                             edit={edit}
    //                             modalKey={modalKey}
    //                             object={val}
    //                             playerOrigin={`projectDetail-${object.id}`}
    //                         />
    //                     ) : (
    //                         <Button
    //                             key={val.id}
    //                             className='pdbm-section-button'
    //                             edit={edit}
    //                             fill='outline'
    //                             size='small'
    //                             text={reduxText[5607]}
    //                             to={`${view_url_project_detail}${object.id}/${object.slug}/track/`}
    //                         />
    //                     )
    //             ))}
    //         </div>
    //     )
    // }
    return null
})
