// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    IonActionSheet,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// data
import {
    api_url_material_action,
    api_url_material_form_active,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
    view_url_material_form,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type MaterialActionSheetMobileProps = {
    object: any
    refreshData?: () => void
    setActivated: React.Dispatch<any> | undefined
    setIsOpen: React.Dispatch<any>
}

// main
export const MaterialActionSheetMobile: React.FC<MaterialActionSheetMobileProps> = React.memo(({
    object,
    refreshData,
    setActivated,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        getData(object.id)
    }, [object.id])

    type actionObjectType = {
        active?: boolean
        has_permission?: boolean
    }

    const [buttons, setButtons] = useState<any[]>(
        [
            {
                text: reduxText[4519],
                role: 'cancel',
            },
        ]
    )

    async function getData(objectId: string) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MaterialActionSheetMobile getData')

            if (!objectId) return
            const getUrl = getApiUrl(`${api_url_material_action}${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!response.data) return
                    const actionObject: actionObjectType = response.data
                    const newButtons = []

                    // Permission
                    if (actionObject.has_permission) {
                        newButtons.push(
                            {
                                handler: () => handlePublish(actionObject.active),
                                role: actionObject.active ? 'destructive' : '',
                                text: actionObject.active ? reduxText[3517] : reduxText[3518],
                            },
                            {
                                handler: () => handleEdit(),
                                text: reduxText[2105],
                            }
                        )
                    }

                    // All
                    newButtons.push(
                        {
                            role: 'cancel',
                            text: reduxText[4519],
                        },
                    )
                    setButtons(newButtons)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'MaterialActionSheetMobile',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MaterialActionSheetMobile',
                'getData',
            ))
        }
    }

    function handleEdit() {
        try {
            if (process.env.NODE_ENV === 'development') console.log('handleEdit')
            history.push(`${view_url_material_form}${object.id}/`)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MaterialActionSheetMobile',
                'handleEdit',
            ))
        }
    }

    async function handlePublish(active?: boolean) {
        try {
            if (process.env.NODE_ENV === 'development') console.log('handlePublish')
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MaterialActionSheetMobile handlePublish')
            const formData = new FormData()
            formData.append('active', (!active).toString())
            const activeUrl = getApiUrl(`${api_url_material_form_active}${object.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (refreshData) refreshData()
                    dispatch(reduxFormSetIsSuccessToastOpen(true, active ? reduxText[6567] : reduxText[5901]))
                    if (setActivated) setActivated(!active)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'MaterialActionSheetMobile',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePublish',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MaterialActionSheetMobile',
                'handlePublish',
            ))
        }
    }

    return (
        <IonActionSheet
            buttons={buttons}
            isOpen
            onDidDismiss={() => setIsOpen(false)}
        />
    )
})
