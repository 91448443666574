// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectAsyncInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_profile,
} from 'data'

// serializers
import {
    OfferDetailFormSerializer,
    OfferDetailFormSerializerError,
} from 'serializers/web'

// props
type ProfileOfferInputProps = {
    disabled: boolean
    errors: OfferDetailFormSerializerError | undefined
    fields: OfferDetailFormSerializer
    onChange: any
}

// main
export const ProfileOfferInput: React.FC<ProfileOfferInputProps> = React.memo(({
    disabled,
    errors,
    fields,
    onChange,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (reduxAuth.settings?.user?.is_staff) {
        return (
            <SelectAsyncInput
                apiUrl={select_url_profile}
                clearable={false}
                disabled={disabled}
                error={errors?.profile}
                label={reduxText[1260]}
                layout='profile'
                name='profile'
                onChange={onChange}
                staffOnly
                value={fields.profile || ''}
            />
        )
    }

    return null
})
