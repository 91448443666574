// react components
import React from 'react'
import {
    // useSelector,
} from 'react-redux'

// components
import {
    TextAreaInput,
} from 'components'

// data
import {
    // defaultReduxState,
} from 'data'

// serializers
import {
    MaterialDetailFormSerializer,
    MaterialDetailFormSerializerError,
} from 'serializers/web'

// props
type PriceMaterialInputProps = {
    errors: MaterialDetailFormSerializerError | undefined
    fields: MaterialDetailFormSerializer
    onChange: any
}

// main
export const PriceMaterialInput: React.FC<PriceMaterialInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    // const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextAreaInput
            error={errors?.price}
            label='price'  // TO_TEXT
            name='price'
            onChange={onChange}
            optional
            value={fields.price || ''}
        />
    )
})
