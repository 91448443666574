// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// serializers
import {
    ContentTypeDetailPanelTypes,
    ContentTypeSettingsGlobalTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getReduxTextId,
} from 'services'

// props
type DetailContentTabButtonProps = {
    contentType: contentTypeModelType
    firstTab: number
    inModal?: boolean
    object: {
        id?: number
        content_type_settings_global?: ContentTypeSettingsGlobalTypes
        model_content_type_id?: number
        profile?: ProfileDetailAdminSerializerV2
    }
    panel: ContentTypeDetailPanelTypes
    setTab: React.Dispatch<React.SetStateAction<number>>
    tab: number
}

// main
export const DetailContentTabButton: React.FC<DetailContentTabButtonProps> = React.memo(({
    contentType,
    firstTab,
    inModal,
    object,
    panel,
    setTab,
    tab,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const blockListLengthIs1 = panel.blockList?.length === 1
    const firstInlineBlock = panel.blockInline?.[panel.blockList?.[0]!]

    const title = panel.title ||
        (blockListLengthIs1 && firstInlineBlock && object.profile?.settings_data_json?.contentTypeSettingsCustom?.[firstInlineBlock.contentType!]?.meta?.verboseNamePlural) ||
        object.content_type_settings_global?.[firstInlineBlock?.contentType!]?.meta?.verboseNamePlural
    if (panel.panelOptions?.hideIfNotStaff && !reduxAuth.settings?.user?.is_staff) return null
    if (panel.panelOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) return null

    return (
        <Button
            key={panel.id}
            className={(tab === panel.id) ? 'dc-active' : ''}
            color={title ? undefined : 'danger'}
            edit={false}
            fill='clear'
            justifyText='flex-start'
            onClick={inModal ? () => setTab(panel.id) : undefined}
            size='small'
            text={getReduxTextId(title || 'Error: Missing tab title', reduxText)!}
            to={inModal ? undefined : `/detail/${contentType}/${object.id}${panel.id ? `?tab=${panel.id}` : firstTab}`}
        />
    )
})
