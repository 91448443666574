// react components
import React from 'react'

// props
type MainChipListBlockProps = {
    children: React.ReactNode
}

// main
export const MainChipListBlock: React.FC<MainChipListBlockProps> = React.memo(({
    children
}) => {

    return (
        <div className='main-chip-list-block'>
            {children}
        </div>
    )
})
