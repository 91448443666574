// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DateTimeInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    OfferDetailFormSerializer,
    OfferDetailFormSerializerError,
} from 'serializers/web'

// props
type PublicationDateOfferInputProps = {
    errors: OfferDetailFormSerializerError | undefined
    fields: OfferDetailFormSerializer
    onChange: any
}

// main
export const PublicationDateOfferInput: React.FC<PublicationDateOfferInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <DateTimeInput
            defaultNow
            error={errors?.publication_date}
            format='date'
            label={reduxText[8693]}
            name='publication_date'
            onChange={onChange}
            optional
            value={fields.publication_date || null}
        />
    )
})
