// react components
import React, {
    useState,
} from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import axios from 'axios'
import Dropzone from 'react-dropzone'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorHelper,
    IconBlock,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    RichTextInputV2,
    TextInputV2,
} from 'pages'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
} from 'services'

// props
type EmailFormWeProps = {
    contentType: contentTypeModelType
    emailContentObjectId: number
    emailToList: string[]
    onClose: () => void
    profile: ProfileDetailAdminSerializerV2 | undefined
}

// main
export const EmailForm: React.FC<EmailFormWeProps> = React.memo(({
    contentType,
    emailContentObjectId,
    emailToList,
    onClose,
    profile,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type errorsType = {
        body?: string
        non_field_errors?: string
        subject?: string
    }
    type fieldsType = {
        body?: string
        subject?: string
    }
    const errorsInitial = {
        body: '',
        non_field_errors: '',
        subject: '',
    }
    const fieldsInitial = {
        body: undefined,
        subject: undefined,
    }

    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [selectedFiles, setSelectedFiles] = useState<File[]>([])

    function handleFileDrop(acceptedFiles: File[]) {
        try {
            setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(error, 'EmailForm', 'handleFileDrop'))
        }
    }

    function removeFile(index: number) {
        try {
            setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(error, 'EmailForm', 'removeFile'))
        }
    }

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })
            setHelpers({
                ...helpers,
                buttonDisabled: false,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EmailForm',
                'handleInputChange',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EmailForm handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isLoading: true,
            })

            const app = 'notification'
            const model = 'mailhistory'

            const postUrl = getApiUrlV2(`${app}/form/${model}/create/`, reduxAuth)
            const formData: any = new FormData()

            formData.append('body', fields.body || '')
            formData.append('content_object_id', emailContentObjectId)
            formData.append('content_type', contentTypeData[contentType])
            formData.append('from_email', `${profile?.name} <${profile?.email_host_user}>`)
            formData.append('profile', profile?.id)
            formData.append('reply_to_list', JSON.stringify([reduxAuth.settings?.user?.email!]))
            formData.append('subject', fields.subject || '')
            if (emailToList.length > 1) {
                formData.append('bcc_list', JSON.stringify(emailToList))
            } else {
                formData.append('to_list', JSON.stringify(emailToList))
            }
            selectedFiles.forEach((file) => {
                formData.append('attachments', file)
            })
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    onClose()
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isLoading: false,
                    })
                    setErrors(error.response.data)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'EmailForm',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipAlertStatusCode: [400, 500],
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isLoading: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'EmailForm',
                'handleSubmit',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class email-form'
            classes={{ paper: 'mo-dialog-wrapper fix-height-full fix-width-full' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={onClose}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[10117]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={onClose}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='ef-container'>
                    <div className='ef-emails'>
                        <p>{reduxText[10165]} : {[reduxAuth.settings?.user?.email!].join(', ')}</p>
                        <p>{emailToList?.length > 1 ? reduxText[10167] : reduxText[10168]} : {emailToList.join(', ')}</p>
                    </div>
                    <div className='ef-subject'>
                        <TextInputV2
                            name='subject'
                            onChange={handleInputChange}
                            placeholder={reduxText[10118]}
                            value={fields.subject || ''}
                        />
                        <ErrorHelper error={errors.subject} />
                    </div>
                    <div className='ef-input'>
                        <RichTextInputV2
                            fonts={undefined}
                            name='body'
                            objectId={undefined}
                            onChange={handleInputChange}
                            tinymceStylesString='todo'
                            value={fields.body || ''}
                        />
                        <ErrorHelper error={errors.body} />
                    </div>
                    <div className='ef-dropzone'>
                        <Dropzone
                            accept={{
                                'application/pdf': [],
                                'image/gif': [],
                                'image/jpeg': [],
                                'image/jpg': [],
                                'image/png': [],
                                'image/svg+xml': [],
                                'image/webp': [],
                            }}
                            onDrop={handleFileDrop}

                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className='dropzone'>
                                    <input {...getInputProps()} />
                                    <p>{reduxText[10174]}</p>
                                </div>
                            )}
                        </Dropzone>
                        {selectedFiles.length > 0 && (
                            <div className='file-preview'>
                                {selectedFiles.map((file, index) => (
                                    <div
                                        key={index}
                                        className='ec-file-item'
                                    >
                                        <IconBlock
                                            edit={false}
                                            iconClass='mo-new-icon-times-solid'
                                            onClick={() => removeFile(index)}
                                        />
                                        <span>{file.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    onClick={onClose}
                    size='small'
                    fill='outline'
                    text={reduxText[4519]}
                />
                <Button
                    edit={false}
                    onClick={handleSubmit}
                    size='small'
                    fill='outline'
                    text={reduxText[5622]}
                />
            </DialogActions>
        </Dialog>
    )
})
