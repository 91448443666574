// react components
import React from 'react'

// components
import {
    ImageHelper,
} from 'components'

// props
type ImageListBlockMobileProps = {
    object: any
}

// main
export const ImageListBlockMobile: React.FC<ImageListBlockMobileProps> = React.memo(({
    object,
}) => {

    return (
        <ImageHelper
            alt={object.name}
            className='image-list-block'
            defaultHeightMobile
            dominant_color={undefined}
            lazyMobile={false}
            src={object.get_image_lq}
        />
    )
})
