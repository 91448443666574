// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Editor,
} from '@tinymce/tinymce-react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    media_version,
    reduxModalErrorEventHandler,
} from 'data'

// serializer
import {
    MainFontSerializer,
    TranslationHelper,
} from 'serializers/web'

// props
type RichTextInputProps = {
    disabled?: boolean
    error: string | undefined
    fonts: MainFontSerializer[] | undefined
    helperText?: string
    label?: string
    name: string
    objectId: number | undefined
    onChange: React.Dispatch<any>
    onRefresh?: () => void
    optional?: boolean
    presetColors?: string[]
    tinymceStylesObject: any
    tinymceStylesString: string
    translation?: TranslationHelper
    value: string
}

// main
export const RichTextInput: React.FC<RichTextInputProps> = React.memo(({
    disabled,
    error,
    fonts,
    helperText,
    label,
    name,
    objectId,
    onChange,
    onRefresh,
    optional,
    presetColors,
    tinymceStylesObject,
    tinymceStylesString,
    translation,
    value,
}) => {

    const dispatch = useDispatch()
    const [initialValue, setInitialValue] = useState<string>(value)
    const [isLoading, setIsLoading] = useState(false)

    let wto: any

    useEffect(() => {
        setInitialValue(value)
        setIsLoading(true)
        clearTimeout(wto)
        wto = setTimeout(() => {
            setIsLoading(false)
        }, 100)
    }, [objectId])

    function onInputChange(value: string | null | undefined) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RichTextInput',
                'onInputChange',
            ))
        }
    }

    let fontFamilies = ''
    const contentCss: string[] = []
    if (tinymceStylesObject?.defaultMargin !== 'false') {
        // contentCss.push(`//media.justtheweb.io/global/fonts/no-margin.css${media_version}`)
        contentCss.push(`//s3.eu-west-3.amazonaws.com/justtheweb.io/global/fonts/no-margin.css${media_version}`)
    }
    contentCss.push(`//s3.eu-west-3.amazonaws.com/justtheweb.io/global/fonts/rich_text.css${media_version}`)
    const fontFamilyArray: string[] = []
    if (fonts?.length! > 0) {
        fonts!.map((val) => {
            if (!fontFamilyArray.includes(val.name)) {
                fontFamilyArray.push(val.name)
                fontFamilies += `${val.name};`
                if (val.category === 'manual') {
                    // contentCss.push(`https://media.justtheweb.io/global/fonts/${val.name}/stylesheet.css${media_version}`)
                    contentCss.push(`https://s3.eu-west-3.amazonaws.com/justtheweb.io/global/fonts/${val.name}/stylesheet.css${media_version}`)
                } else {
                    contentCss.push(`//fonts.googleapis.com/css2?family=${val.name}:wght@100;200;300;400;500;600;700;800;900`)
                }
            }
            return false
        })
    }

    const newTinymceStylesString = `.mce-content-body {${tinymceStylesString}}`

    return (
        <div className={`mo-text-input-web-input${disabled ? ' disabled' : ''}`}>
            {(label || translation) && (
                <MainFormLabel
                    fonts={fonts}
                    formInput='richText'
                    helperText={helperText}
                    label={label!}
                    name={name}
                    onRefresh={onRefresh}
                    optional={optional}
                    tinymceStylesString={tinymceStylesString}
                    tinymceStylesObject={tinymceStylesObject}
                    translation={translation}
                    translationDefaultValue={value}
                />
            )}
            <div className='mo-text-input-web-input'>
                {!isLoading && (
                    <Editor
                        disabled={disabled}
                        init={{
                            content_css: contentCss,
                            content_style: newTinymceStylesString,
                            font_family_formats: fontFamilies,
                            font_size_formats: Array.from(Array(92).keys()).map((val, i) => `${i + 8}px`).join(' '),
                            height: 200,
                            license_key: 'gpl',
                            link_assume_external_targets: 'http',
                            link_default_target: '_blank',
                            menubar: false,
                            paste_data_images: false,
                            plugins: [
                                'advlist',
                                'autolink',
                                'charmap',
                                'code',
                                'fullscreen',
                                'insertdatetime',
                                'link',
                                'lists',
                                'searchreplace',
                                'table',
                                'visualblocks',
                                'wordcount',
                            ],
                            style_formats: [
                                {
                                    title: 'Headings', items: [
                                        { title: 'Heading 1', format: 'h1' },
                                        { title: 'Heading 2', format: 'h2' },
                                        { title: 'Heading 3', format: 'h3' },
                                        { title: 'Heading 4', format: 'h4' },
                                        { title: 'Heading 5', format: 'h5' },
                                        { title: 'Heading 6', format: 'h6' }
                                    ]
                                },
                                {
                                    title: 'Font weight', items: [
                                        { title: '100', inline: 'span', styles: { fontWeight: '100' } },
                                        { title: '200', inline: 'span', styles: { fontWeight: '200' } },
                                        { title: '300', inline: 'span', styles: { fontWeight: '300' } },
                                        { title: '400', inline: 'span', styles: { fontWeight: '400' } },
                                        { title: '500', inline: 'span', styles: { fontWeight: '500' } },
                                        { title: '600', inline: 'span', styles: { fontWeight: '600' } },
                                        { title: '700', inline: 'span', styles: { fontWeight: '700' } },
                                        { title: '800', inline: 'span', styles: { fontWeight: '800' } },
                                        { title: '900', inline: 'span', styles: { fontWeight: '900' } },
                                    ]
                                },
                                {
                                    title: 'Letter spacing', items: [
                                        { title: '1px', inline: 'span', styles: { letterSpacing: '1px' } },
                                        { title: '2px', inline: 'span', styles: { letterSpacing: '2px' } },
                                        { title: '3px', inline: 'span', styles: { letterSpacing: '3px' } },
                                        { title: '4px', inline: 'span', styles: { letterSpacing: '4px' } },
                                        { title: '5px', inline: 'span', styles: { letterSpacing: '5px' } },
                                        { title: '6px', inline: 'span', styles: { letterSpacing: '6px' } },
                                        { title: '7px', inline: 'span', styles: { letterSpacing: '7px' } },
                                        { title: '8px', inline: 'span', styles: { letterSpacing: '8px' } },
                                        { title: '9px', inline: 'span', styles: { letterSpacing: '9px' } },
                                        { title: '10px', inline: 'span', styles: { letterSpacing: '10px' } },
                                        { title: '11px', inline: 'span', styles: { letterSpacing: '11px' } },
                                        { title: '12px', inline: 'span', styles: { letterSpacing: '12px' } },
                                        { title: '13px', inline: 'span', styles: { letterSpacing: '13px' } },
                                        { title: '14px', inline: 'span', styles: { letterSpacing: '14px' } },
                                        { title: '15px', inline: 'span', styles: { letterSpacing: '15px' } },
                                        { title: '16px', inline: 'span', styles: { letterSpacing: '16px' } },
                                        { title: '17px', inline: 'span', styles: { letterSpacing: '17px' } },
                                        { title: '18px', inline: 'span', styles: { letterSpacing: '18px' } },
                                        { title: '19px', inline: 'span', styles: { letterSpacing: '19px' } },
                                        { title: '20px', inline: 'span', styles: { letterSpacing: '20px' } },
                                    ]
                                },
                                {
                                    title: 'Line height', items: [
                                        { title: '0.5', inline: 'span', styles: { lineHeight: '0.5' } },
                                        { title: '1', inline: 'span', styles: { lineHeight: '1' } },
                                        { title: '1.5', inline: 'span', styles: { lineHeight: '1.5' } },
                                        { title: '2', inline: 'span', styles: { lineHeight: '2' } },
                                        { title: '2.5', inline: 'span', styles: { lineHeight: '2.5' } },
                                        { title: '3', inline: 'span', styles: { lineHeight: '3' } },
                                        { title: '3.5', inline: 'span', styles: { lineHeight: '3.5' } },
                                        { title: '4', inline: 'span', styles: { lineHeight: '4' } },
                                        { title: '4.5', inline: 'span', styles: { lineHeight: '4.5' } },
                                        { title: '5', inline: 'span', styles: { lineHeight: '5' } },
                                        { title: '5.5', inline: 'span', styles: { lineHeight: '5.5' } },
                                        { title: '6', inline: 'span', styles: { lineHeight: '6' } },
                                        { title: '6.5', inline: 'span', styles: { lineHeight: '6.5' } },
                                        { title: '7', inline: 'span', styles: { lineHeight: '7' } },
                                        { title: '7.5', inline: 'span', styles: { lineHeight: '7.5' } },
                                        { title: '8', inline: 'span', styles: { lineHeight: '8' } },
                                        { title: '8.5', inline: 'span', styles: { lineHeight: '8.5' } },
                                        { title: '9', inline: 'span', styles: { lineHeight: '9' } },
                                        { title: '9.5', inline: 'span', styles: { lineHeight: '9.5' } },
                                        { title: '10', inline: 'span', styles: { lineHeight: '10' } },
                                    ]
                                },
                                {
                                    title: 'Font styles', items: [
                                        { title: 'Italic', format: 'italic' },
                                        { title: 'Underline', format: 'underline' },
                                        { title: 'Strikethrough', format: 'strikethrough' },
                                        { title: 'Superscript', format: 'superscript' },
                                        { title: 'Subscript', format: 'subscript' },
                                        { title: 'Code', format: 'code' }
                                    ]
                                },
                                {
                                    title: 'Blocks', items: [
                                        { title: 'Paragraph', format: 'p' },
                                        { title: 'Blockquote', format: 'blockquote' },
                                        { title: 'Div', format: 'div' },
                                        { title: 'Pre', format: 'pre' }
                                    ]
                                },
                            ],
                            toolbar: 'fullscreen | fontsize fontfamily | styles bold italic underline forecolor backcolor alignleft aligncenter alignright alignjustify bullist numlist outdent indent removeformat table charmap searchreplace visualblocks code paste undo redo',
                        }}
                        initialValue={initialValue}
                        onEditorChange={(e) => onInputChange(e)}
                    />
                )}
            </div>
            <ErrorHelper error={error} />
        </div>
    )
})
