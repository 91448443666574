// react components
import React, {
    useEffect,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// containers
import {
    InlineFormModal,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AdherentEventParticipantInput,
    AdherentParentEventParticipantInput,
    EmailEventParticipantInput,
    EventParticipantFormState,
    FirstNameEventParticipantInput,
    IsNotAdherentEventParticipantInput,
    LastNameEventParticipantInput,
    PaymentDateEventParticipantInput,
} from 'pages'

// serializers
import {
    EventFormState,
    InlineFormPanelPropsNew,
} from 'serializers/web'

// props
type ParticipantEventPanelProps = {
    formState: EventFormState
    setFormState: React.Dispatch<EventFormState>
}

// main
export const ParticipantEventPanel: React.FC<InlineFormPanelPropsNew & ParticipantEventPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    modalTitle,
    setFormState,
    setHelpers,
    setInlineModalValue,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const mother = formState.mother
    const participant = formState.participant

    useEffect(() => {
        setInitialState()
    }, [])

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, participant)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ParticipantEventPanel',
                'onHandleInputChange',
            ))
        }
    }

    function setInitialState() {
        const participant = EventParticipantFormState(reduxAuth)
        setFormState({
            ...formState,
            participant: {
                ...participant,
                fields: {
                    event: {
                        id: fieldsMother!.id,
                        profile: fieldsMother?.profile?.id,
                    },
                },
            },
        })
    }

    // Mother
    const fieldsMother = mother.fields

    // Inline
    const errorsInline = participant.errors
    const fieldsInline = participant.fields

    if (!fieldsInline) return null

    return (
        <InlineFormModal
            formInfo={participant}
            formListComponent='EventParticipantListFormBlock'
            formState={formState}
            helpers={helpers}
            listUrl={`${participant.listUrl}${fieldsMother?.id}/`}
            modalTitle={modalTitle}
            parentModel='event'
            setFormState={setFormState}
            setHelpers={setHelpers}
            setInitialState={setInitialState}
            setInlineModalValue={setInlineModalValue}
            tableHeaderComponent='EventParticipantListFormHeader'
        >
            <AdherentEventParticipantInput
                disabled={Boolean(fieldsInline.id && fieldsInline.adherent)}
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            {fieldsInline.id && (
                <PaymentDateEventParticipantInput
                    errors={errorsInline}
                    fields={fieldsInline}
                    onChange={onHandleInputChange}
                />
            )}
            {((!fieldsInline.adherent && !fieldsInline.id) || (fieldsInline.is_new_adherent || fieldsInline.is_not_adherent)) && (
                <React.Fragment>
                    <LastNameEventParticipantInput
                        disabled={Boolean(fieldsInline.adherent)}
                        errors={errorsInline}
                        fields={fieldsInline}
                        onChange={onHandleInputChange}
                    />
                    <FirstNameEventParticipantInput
                        disabled={Boolean(fieldsInline.adherent)}
                        errors={errorsInline}
                        fields={fieldsInline}
                        onChange={onHandleInputChange}
                    />
                    <AdherentParentEventParticipantInput
                        disabled={Boolean(fieldsInline.adherent)}
                        errors={errorsInline}
                        fields={fieldsInline}
                        onChange={onHandleInputChange}
                    />
                    <EmailEventParticipantInput
                        disabled={Boolean(fieldsInline.adherent)}
                        errors={errorsInline}
                        fields={fieldsInline}
                        onChange={onHandleInputChange}
                    />
                    <IsNotAdherentEventParticipantInput
                        disabled={Boolean(fieldsInline.adherent)}
                        errors={errorsInline}
                        fields={fieldsInline}
                        onChange={onHandleInputChange}
                    />
                </React.Fragment>
            )}
            {!fieldsInline.id && (
                <PaymentDateEventParticipantInput
                    errors={errorsInline}
                    fields={fieldsInline}
                    onChange={onHandleInputChange}
                />
            )}
        </InlineFormModal>
    )
})
