// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
    IonList,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
} from 'data'

// serializers
import {
    MenuFormPanelProps,
    PortfolioFormState,
} from 'serializers/web'

// props
type MatchParams = {
    id: string
    contentId: string
    detailId: string
    pageSlug: string
    panelValue: string
}

type MenuPortfolioPanelProps = {
    formState: PortfolioFormState
    setShowPrivateSpaceModal: React.Dispatch<boolean>
}

// main
export const MenuPortfolioPanel: React.FC<MenuFormPanelProps & MenuPortfolioPanelProps> = React.memo(({
    formState,
    helpers,
    setHelpers,
    setShowPrivateSpaceModal,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolio = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [formPanels, setFormPanels] = useState<{
        disabled?: boolean
        panelValue: string
        primary: string
    }[]>([])

    const mother = formState.mother
    const fieldsMother = mother.fields

    useEffect(() => {

        const tempFormPanels = [
            {
                panelValue: 'page-list',
                primary: reduxText[6304],
                disabled: false,
            },
            {
                panelValue: 'global-settings',
                primary: reduxText[5332],
                disabled: false,
            },
            {
                panelValue: 'global-design',
                primary: reduxText[6303],
                disabled: false,
            },
        ]

        if (reduxCacheSiteportfolio?.is_donation || reduxCacheSiteportfolio?.is_eshop) {
            tempFormPanels.push(
                {
                    panelValue: 'eshop-settings',
                    primary: reduxCacheSiteportfolio?.is_eshop ? reduxText[8522] : reduxText[8539],
                    disabled: false,
                },
            )
        }

        if (reduxCacheSiteportfolio?.has_private_space && !fieldsMother?.profile?.extra_data_json?.adhesion?.adherent?.has_access) {
            tempFormPanels.push(
                {
                    panelValue: 'private-space-settings',
                    primary: reduxText[8538],
                    disabled: false,
                },
            )
        }

        if (reduxAuth.settings?.user?.is_staff) {
            tempFormPanels.push(
                {
                    panelValue: 'delete-panel',
                    primary: reduxText[2983],
                    disabled: false,
                },
            )
        }

        setFormPanels(tempFormPanels)
    }, [
        fieldsMother?.active,
        reduxCacheSiteportfolio?.footer?.id,
        reduxCacheSiteportfolio?.navbar?.id,
        reduxText,
    ])

    return (
        <IonList>
            {formPanels.map((val) => {
                if (val.panelValue === 'delete-panel') {
                    return (
                        <IonItem
                            key={val.panelValue}
                            button
                            disabled={!(fieldsMother?.id)}
                            onClick={() => setHelpers({ ...helpers, isActionSheetOpen: true })}
                        >
                            <IonLabel className='mo-item-list-label'>
                                <h2>{val.primary}</h2>
                            </IonLabel>
                        </IonItem>
                    )
                }
                if (val.panelValue === 'private-space-settings') {
                    return (
                        <IonItem
                            key={val.panelValue}
                            button
                            disabled={val.disabled}
                            onClick={() => setShowPrivateSpaceModal(true)}
                        >
                            <IonLabel className='mo-item-list-label'>
                                <h2>{val.primary}</h2>
                            </IonLabel>
                        </IonItem>
                    )
                }
                return (
                    <IonItem
                        key={val.panelValue}
                        disabled={val.disabled}
                        onClick={() => dispatch(reduxFormSetIsSuccessToastOpen(false))}
                        routerLink={`${mother.formUrl}${fieldsMother?.id}/${val.panelValue}/${params.pageSlug ? `${params.contentId}/${params.pageSlug}/${params.detailId ? `${params.detailId}/` : ''}` : ''}`}
                    >
                        <IonLabel className='mo-item-list-label'>
                            <h2>{val.primary}</h2>
                        </IonLabel>
                    </IonItem>
                )
            })}
        </IonList>
    )
})
