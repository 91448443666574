// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// containers
import {
    DetailListContainerMobile,
} from 'containers'

// data
import {
    api_url_project_content_list,
    api_url_project_detail_short,
    api_url_project_team_list,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// props
type MatchParams = {
    id: string
}

// main
export const ProjectDetailListMobile: React.FC = () => {

    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const filterParamsOptions = {
        'article': {
            apiUrl: getApiUrl(`${api_url_project_content_list}?project_id=${params.id}&medium_type_id=7`, reduxAuth),
            bigListComponent: 'ProjectListBigBlockMobile',
            defaultThumbSize: 'medium',
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
            title: reduxText[3554],
        },
        'image': {
            apiUrl: getApiUrl(`${api_url_project_content_list}?project_id=${params.id}&medium_type_id=6`, reduxAuth),
            bigListComponent: 'ImageListBlockMobile',
            defaultThumbSize: 'big',
            mediumListComponent: 'ImageListBlockMobile',
            smallListComponent: 'ImageListBlockMobile',
            title: reduxText[1625],
        },
        'mixed': {
            apiUrl: getApiUrl(`${api_url_project_content_list}?project_id=${params.id}`, reduxAuth),
            bigListComponent: 'ImageListBlockMobile',
            defaultThumbSize: 'big',
            mediumListComponent: 'ImageListBlockMobile',
            smallListComponent: 'ImageListBlockMobile',
            title: reduxText[3554],
        },
        'team': {
            apiUrl: getApiUrl(`${api_url_project_team_list}?project_id=${params.id}`, reduxAuth),
            bigListComponent: 'TeamListMediumBlockMobile',
            defaultThumbSize: 'medium',
            mediumListComponent: 'TeamListMediumBlockMobile',
            smallListComponent: 'TeamListSmallBlockMobile',
            title: reduxText[4917],
        },
        'track': {
            apiUrl: getApiUrl(`${api_url_project_content_list}?project_id=${params.id}&medium_type_id=2`, reduxAuth),
            bigListComponent: 'ProjectListBigBlockMobile',
            defaultThumbSize: 'medium',
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
            title: reduxText[3554],
        },
        'video': {
            apiUrl: getApiUrl(`${api_url_project_content_list}?project_id=${params.id}&medium_type_id=3`, reduxAuth),
            bigListComponent: 'ProjectListBigBlockMobile',
            defaultThumbSize: 'big',
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
            title: reduxText[3554],
        },
    }

    return (
        <DetailListContainerMobile
            filterParamsOptions={filterParamsOptions}
            model='project'
            shortUrl={api_url_project_detail_short}
        />
    )
}
