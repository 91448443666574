// react components
import React, {
    useState,
} from 'react'
import {
    IonReorder,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    DeleteAlert,
    ImageHelper,
    Loader,
    Popover,
} from 'components'

// data
import {
    api_url_selection_form_update,
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AddContentToFavoriteHelper,
} from 'pages'

// serializers
import {
    BlockTemplateSelectSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type LayoutInputListItemProps = {
    active: boolean
    contentType: contentTypeModelType
    isReordering: boolean
    object: BlockTemplateSelectSerializer
    onBookmarkRefresh: () => void
    onClick: () => void
    showBookmarks: boolean
    showLatest: boolean
}

// main
export const LayoutInputListItem: React.FC<LayoutInputListItemProps> = React.memo(({
    active,
    contentType,
    isReordering,
    object,
    onBookmarkRefresh,
    onClick,
    showBookmarks,
    showLatest,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [showActionPopover, setShowActionPopover] = useState(false)
    const [showAddContentToFavorite, setShowAddContentToFavorite] = useState(false)

    async function handleDelete() {
        try {
            if (!object?.id) return
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'LayoutInputListItem handleDelete')
            setIsDeleting(true)
            const deleteUrl = getApiUrl(`${api_url_selection_form_update}${object.texts?.id}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    onBookmarkRefresh()
                })
                .catch((error) => {
                    setIsDeleting(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'LayoutInputListItem',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputListItem',
                'handleDelete',
            ))
        }
    }

    return (
        <div className='liw-list-block'>
            <ImageHelper
                alt=''
                className={`liw-list-block-image${active ? ' active' : ''}`}
                dominant_color={showBookmarks ? undefined : object.get_dominant_color}
                onClick={onClick}
                src={showBookmarks ? object.texts?.get_image_lq : object.get_image_lq}
            />
            {showBookmarks && (
                <div className='liw-bookmark-wrap'>
                    <p className='liw-bookmark-text'>{object.texts?.name}</p>
                    <p className='liw-bookmark-description'>{object.texts?.description}</p>
                </div>
            )}
            {showLatest && (
                <div className='liw-bookmark-wrap'>
                    <p className='liw-bookmark-description'>{object.custom_categories?.map(val => val.name).join(' - ')}</p>
                </div>
            )}
            {isReordering && (
                <React.Fragment>
                    <Popover
                        color='light'
                        buttons={[
                            {
                                text: reduxText[2105],
                                handler: () => {
                                    setShowAddContentToFavorite(true)
                                    setShowActionPopover(false)
                                }
                            },
                            {
                                text: reduxText[2983],
                                handler: () => {
                                    setIsDeleteAlertOpen(true)
                                    setShowActionPopover(false)
                                }
                            },
                        ]}
                        edit={false}
                        isOpen={showActionPopover}
                        setIsOpen={setShowActionPopover}
                    />
                    <IonReorder slot='end' />
                </React.Fragment>
            )}
            {showAddContentToFavorite && (
                <AddContentToFavoriteHelper
                    contentType={contentType}
                    objectId={object.id!}
                    onRefresh={onBookmarkRefresh}
                    profileId={reduxFormSite.portfolioObject?.profile?.id!}
                    retrieve_id={object.texts?.id}
                    setIsOpen={setShowAddContentToFavorite}
                />
            )}
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={handleDelete}
                    objectName={object.texts?.name!}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
            {isDeleting && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </div>
    )
})
