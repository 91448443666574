// react components
import React from 'react'
import {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    ImageHelper,
    MainDetailEditBlockMobile,
} from 'components'

// data
import {
    defaultReduxState,
    media_url,
    media_version,
} from 'data'

// props
type HeaderMobileProps = {
    id?: string
    backText?: string
    componentActionSheet?: string
    detailObject?: {
        id?: number
        name?: string
        profile?: {
            id?: number
        }
        owner_info?: {
            permission?: boolean
        }
    }
    refreshData?: () => void
    title?: string
}

// main
export const HeaderMobile: React.FC<HeaderMobileProps> = React.memo(({
    backText,
    componentActionSheet,
    detailObject,
    id,
    refreshData,
    title,
}) => {

    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    if (!reduxAuth.authenticated) return null

    return (
        <IonHeader
            id={id}
            className='mo-header'
            translucent
        >
            {!detailObject && (
                <IonToolbar>
                    <IonButtons slot='start'>
                        {location.pathname !== '/' && (
                            <IonBackButton
                                defaultHref='/'
                                text={backText || ''}
                            />
                        )}
                    </IonButtons>
                    <IonTitle>
                        {title
                            ? (
                                title
                            ) : (
                                <ImageHelper
                                    alt=''
                                    className='logo'
                                    dominant_color={undefined}
                                    src={`${media_url}global/logo-${reduxAuth.settings?.theme === 'light' ? 'black' : 'white'}.svg${media_version}`}
                                />
                            )}
                    </IonTitle>
                </IonToolbar>
            )}
            {detailObject && (
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonBackButton
                            defaultHref='/'
                            text=''
                        />
                    </IonButtons>
                    <IonTitle color='primary'>
                        {detailObject.name
                            ? (
                                detailObject.name
                            ) : (
                                <ImageHelper
                                    alt=''
                                    className='logo'
                                    dominant_color={undefined}
                                    src={`${media_url}global/logo-${reduxAuth.settings?.theme === 'light' ? 'black' : 'white'}.svg${media_version}`}
                                />
                            )}
                    </IonTitle>
                    <MainDetailEditBlockMobile
                        componentActionSheet={componentActionSheet}
                        object={detailObject}
                        refreshData={refreshData}
                    />
                </IonToolbar>
            )}
        </IonHeader>
    )
})
