// Root URL
const rootPath = 'project/'

// Action
export const api_url_project_action = `${rootPath}action/project/`

// Detail
export const api_url_project_detail = `${rootPath}detail/project/`
export const api_url_project_detail_short = `${rootPath}detail/project/short/`

// Form Project
export const api_url_project_form_active = `${rootPath}form/project/active/`
export const api_url_project_form_create = `${rootPath}form/project/create/`
export const api_url_project_form_detail = `${rootPath}form/project/detail/`
export const api_url_project_form_duplicate = `${rootPath}form/project/duplicate/`
export const api_url_project_form_list = `${rootPath}form/project/list/`
export const api_url_project_form_update = `${rootPath}form/project/update/`

// Form Content
export const api_url_project_content_form_active = `${rootPath}form/content/active/`
export const api_url_project_content_form_create = `${rootPath}form/content/create/`
export const api_url_project_content_form_detail = `${rootPath}form/content/detail/`
export const api_url_project_content_form_list = `${rootPath}form/content/list/`
export const api_url_project_content_form_patch = `${rootPath}form/content/patch/`
export const api_url_project_content_form_update = `${rootPath}form/content/update/`

// Form Eclink
export const api_url_project_eclink_form_active = `${rootPath}form/eclink/active/`
export const api_url_project_eclink_form_create = `${rootPath}form/eclink/create/`
export const api_url_project_eclink_form_detail = `${rootPath}form/eclink/detail/`
export const api_url_project_eclink_form_list = `${rootPath}form/eclink/list/`
export const api_url_project_eclink_form_patch = `${rootPath}form/eclink/patch/`
export const api_url_project_eclink_form_update = `${rootPath}form/eclink/update/`

// Form Mixed media content
export const api_url_project_mixed_media_content_form_active = `${rootPath}form/mixed_media_content/active/`
export const api_url_project_mixed_media_content_form_create = `${rootPath}form/mixed_media_content/create/`
export const api_url_project_mixed_media_content_form_detail = `${rootPath}form/mixed_media_content/detail/`
export const api_url_project_mixed_media_content_form_patch = `${rootPath}form/mixed_media_content/patch/`
export const api_url_project_mixed_media_content_form_update = `${rootPath}form/mixed_media_content/update/`

// Form Team
export const api_url_project_team_form_accept = `${rootPath}form/team/accept/`
export const api_url_project_team_form_active = `${rootPath}form/team/active/`
export const api_url_project_team_form_create = `${rootPath}form/team/create/`
export const api_url_project_team_form_decline = `${rootPath}form/team/decline/`
export const api_url_project_team_form_detail = `${rootPath}form/team/detail/`
export const api_url_project_team_form_list = `${rootPath}form/team/list/`
export const api_url_project_team_form_patch = `${rootPath}form/team/patch/`
export const api_url_project_team_form_request = `${rootPath}form/team/request/`
export const api_url_project_team_form_update = `${rootPath}form/team/update/`

// List
export const api_url_album_children_list = `${rootPath}list/project/album_children/`
export const api_url_css_property_list = `${rootPath}list/css_property/`
export const api_url_press_list = `${rootPath}list/project/press/`
export const api_url_project_content_list = `${rootPath}list/project_content/`
export const api_url_project_list = `${rootPath}list/project/`
export const api_url_project_mixed_media_list = `${rootPath}list/mixed_media/`
export const api_url_project_team_list = `${rootPath}list/project_team/`
export const api_url_resource_list = `${rootPath}list/project/resource/`

// Map
export const api_url_press_map = `${rootPath}map/project/press/`
export const api_url_project_map = `${rootPath}map/project/`
// export const api_url_resource_map = `${rootPath}map/project/resource/`
