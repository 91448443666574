// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonReorderGroup,
    IonReorder,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    DeleteAlert,
    Loader,
    Popover,
} from 'components'

// data
import {
    api_url_profile_section_form_patch_child_child,
    api_url_profile_section_form_update,
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    view_url_profile_detail_old,
} from 'data'

// pages
import {
    ProfileDetailMenuLeftTabChildrenChildrenWeb,
} from 'pages'

// serializers
import {
    ProfileSectionHelperSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    parseQuery,
} from 'services'

// props
type MatchParams = {
    slug: string
    sectionId: string
}

type ProfileDetailMenuLeftTabChildrenProps = {
    disableReorderChildren?: boolean
    disableReorderChildrenChildren?: boolean
    mainParentObject: ProfileSectionHelperSerializer
    manageProfileSection?: boolean
    object: ProfileSectionHelperSerializer
    parentObject?: ProfileSectionHelperSerializer
}

// main
export const ProfileDetailMenuLeftTabChildren: React.FC<ProfileDetailMenuLeftTabChildrenProps> = React.memo(({
    disableReorderChildren,
    disableReorderChildrenChildren,
    mainParentObject,
    manageProfileSection,
    object,
    parentObject,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isChildOpen, setIsChildOpen] = useState<boolean>((params.sectionId === `${object.id}`) || (parseQuery(location.search)['parent'] === `${object.id}`))
    const [isInit, setIsInit] = useState(false)
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [items, setItems] = useState<ProfileSectionHelperSerializer['children_tabs']>(object.children_tabs || [])
    const [showPopover, setShowPopover] = useState(false)

    useEffect(() => {
        const shoulOpen = (params.sectionId === `${object.id}`) || (parseQuery(location.search)['parent'] === `${object.id}`)
        if (isInit && isChildOpen && !shoulOpen) {
            setIsChildOpen(shoulOpen)
        }
        setIsInit(true)
    }, [
        params.sectionId,
    ])

    useEffect(() => {
        setItems(object.children_tabs || [])
    }, [
        object.children_tabs,
    ])

    async function handleDelete() {
        try {
            setIsDeleting(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetailMenuLeftTabChildren handleDelete')

            if (!object) return
            const deleteUrl = getApiUrl(`${api_url_profile_section_form_update}${object.id}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    dispatch(reduxFormSetRefresh('refreshProfile', object.profile))
                })
                .catch((error) => {
                    setIsDeleting(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'ProfileDetailMenuLeftTabChildren',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMenuLeftTabChildren',
                'handleDelete',
            ))
        }
    }

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMenuLeftTabChildren',
                'doReorder',
            ))
        }
    }

    async function onSortEnd(event: any) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetailMenuLeftTabChildren onSortEnd')
            const item = items![event.detail.from]
            const itemTo = items![event.detail.to]
            setItems(arrayMoveImmutable(items!, event.detail.from, event.detail.to))
            const formData: any = new FormData()
            formData.append('sibling_id', itemTo.id)
            const putUrl = getApiUrl(`${api_url_profile_section_form_patch_child_child}${item.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'ProfileDetailMenuLeftTabChildren',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMenuLeftTabChildren',
                'onSortEnd',
            ))
        }
    }

    if (object.is_grouping) {
        return (
            <div>
                {items?.map((val) => (
                    <ProfileDetailMenuLeftTabChildren
                        key={val.id}
                        disableReorderChildrenChildren={true}
                        mainParentObject={mainParentObject}
                        manageProfileSection={false}
                        object={val}
                        parentObject={undefined}
                    />
                ))}
            </div>
        )
    }

    return (
        <div>
            <div className={`pdmltw-tab-item-children-wrap${object.is_parent ? ' parent' : ''}`}>
                {object.is_parent && (
                    <i
                        className={`pdmltw-expand mo-new-icon-caret-right-solid${isChildOpen ? ' open' : ''}${items?.length! > 0 ? '' : ' hidden'}`}
                        onClick={() => setIsChildOpen(!isChildOpen)}
                    />
                )}
                <NavLinkHelper
                    key={object.id}
                    isChildOpen={isChildOpen}
                    mainParentObject={mainParentObject}
                    object={object}
                    paramsSlug={params.slug}
                    parentObject={parentObject}
                    setIsChildOpen={setIsChildOpen}
                >
                    {object.name}
                    {!disableReorderChildren && (
                        <IonReorder
                            className='pdmltw-ion-reorder'
                            slot='end'
                        />
                    )}
                    {manageProfileSection && (
                        <Popover
                            className='pdlw-status-settings'
                            buttons={[
                                {
                                    text: reduxText[2105],
                                    handler: () => {
                                        dispatch(reduxModalMainFormShow({
                                            cssClass: 'modal-form-web',
                                            formComponent: 'ProfileSectionForm',
                                            objectId: object.id!,
                                        }))
                                        setShowPopover(false)
                                    }
                                },
                                {
                                    text: reduxText[2983],
                                    handler: () => {
                                        setIsDeleteAlertOpen(true)
                                    }
                                },
                            ]}
                            edit={false}
                            isOpen={showPopover}
                            setIsOpen={setShowPopover}
                        />
                    )}
                </NavLinkHelper>
                {isDeleteAlertOpen && (
                    <DeleteAlert
                        handleDelete={handleDelete}
                        objectName={object.name}
                        setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                    />
                )}
                {isDeleting && (
                    <Loader
                        isOpen
                        message={reduxText[1242]}
                    />
                )}
            </div>
            <div className={`pdmltw-children-children-wrap${isChildOpen ? ' open' : ''}`}>
                <IonReorderGroup
                    disabled={disableReorderChildrenChildren}
                    onIonItemReorder={doReorder}
                >
                    {items?.map((val) => (
                        <ProfileDetailMenuLeftTabChildrenChildrenWeb
                            key={val.id}
                            disableReorderChildrenChildren={disableReorderChildrenChildren}
                            mainParentObject={mainParentObject}
                            manageProfileSection={manageProfileSection}
                            object={val}
                            parentObject={object}
                        />
                    ))}
                </IonReorderGroup>
            </div>
        </div>
    )
})


type NavLinkHelperProps = {
    children: React.ReactNode
    isChildOpen: boolean
    mainParentObject: ProfileSectionHelperSerializer
    object: ProfileSectionHelperSerializer
    paramsSlug: string
    parentObject?: ProfileSectionHelperSerializer
    setIsChildOpen: React.Dispatch<boolean>
}

// main
const NavLinkHelper: React.FC<NavLinkHelperProps> = React.memo(({
    children,
    isChildOpen,
    mainParentObject,
    object,
    paramsSlug,
    parentObject,
    setIsChildOpen,
}) => {

    if (object.is_grouping) {
        return (
            <span
                key={object.id}
                className='pdmltw-tab-item-children'
                onClick={() => setIsChildOpen(!isChildOpen)}
            >
                {children}
            </span>
        )
    }

    return (
        <NavLink
            key={object.id}
            className='pdmltw-tab-item-children'
            to={`${view_url_profile_detail_old}${paramsSlug}/sections/${object.id}/${['- Helpers'].includes(object.name!) ? 'all/' : ''}?main-parent=${mainParentObject.id}${parentObject ? `&parent=${parentObject.id}` : ''}`}
        >
            {children}
        </NavLink>
    )
})
