// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
    DateTimeInput,
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    EventFormState,
    InlineFormPanelProps,
} from 'serializers/web'

// props
type ScheduleEventPanelProps = {
    formState: EventFormState
    setFormState: React.Dispatch<EventFormState>
}

// main
export const ScheduleEventPanel: React.FC<InlineFormPanelProps & ScheduleEventPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const schedule = formState.schedule

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, schedule)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ScheduleEventPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Inline
    const errorsInline = schedule.errors
    const fieldsInline = schedule.fields

    if (!fieldsInline) return null

    return (
        <InlineFormContainerWeb
            formInfo={schedule}
            formState={formState}
            helpers={helpers}
            noOrdering
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            <DateTimeInput
                error={errorsInline?.start_date}
                format={fieldsInline.date_only ? 'date' : 'datetime'}
                formatPreviewOnly={fieldsInline.date_only}
                label={reduxText[328]}
                name='start_date'
                onChange={onHandleInputChange}
                value={fieldsInline.start_date || null}
            />
            <DateTimeInput
                error={errorsInline?.end_date}
                format={fieldsInline.date_only ? 'date' : 'datetime'}
                formatPreviewOnly={fieldsInline.date_only}
                label={reduxText[329]}
                minDate={fieldsInline.start_date}
                name='end_date'
                onChange={onHandleInputChange}
                optional
                value={fieldsInline.end_date || null}
            />
            <CheckboxInput
                error={errorsInline?.date_only}
                label={reduxText[4724]}
                name='date_only'
                onChange={onHandleInputChange}
                value={fieldsInline.date_only || ''}
            />
            <TextInput
                error={errorsInline?.ticket_link_url}
                label={reduxText[8426]}
                name='ticket_link_url'
                onChange={onHandleInputChange}
                optional
                value={fieldsInline.ticket_link_url || ''}
            />
        </InlineFormContainerWeb>
    )
})
