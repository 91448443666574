// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonSearchbar,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    InfiniteScrollHelperWeb,
    Loader,
    NotFoundComponent,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
} from 'data'

// pages
import {
    TableAction,
    TableHeader,
    TableRow,
} from 'pages'

// serializers
import {
    ContentTypeFieldTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getApiUrlV2,
    getInfiniteListItem,
    getReduxTextId,
    listStateInitial,
    listStateProps,
} from 'services'

// props
type MatchParams = {
    moduleId: contentTypeModelType
}

type AdminDataProps = {
    profileObject: ProfileDetailAdminSerializerV2
}

// main
export const AdminData: React.FC<AdminDataProps> = React.memo(({
    profileObject,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [customColumns, setCustomColumns] = useState<{
        [key: string]: string[]
    } | undefined>(undefined)
    const [exportListApiUrl, setExportListApiUrl] = useState<string>('')
    const [exportColumns, setExportColumns] = useState<{
        columnName: string
        columnTitle: string
        fieldOptions: ContentTypeFieldTypes | undefined
    }[]>([])
    const [listState, setListState] = useState<listStateProps>({
        ...listStateInitial,
        clusterIds: [],
        modalKey: Math.random(),
    })

    // keep order
    const contentType = params.moduleId
    const app = contentType?.split('_')[0]
    const model = contentType?.split('_')[1]
    const contentTypeSettings = listState.contentTypeSettings
    const contentTypeSettingsCustom = profileObject?.settings_data_json?.contentTypeSettingsCustom?.[contentType]
    const listApiUrl = `${app}/list/${model}/?profile=${profileObject.id}`

    useEffect(() => {
        mainGet()
    }, [
        contentType,
        customColumns,
        profileObject.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    function getListApiUrl() {
        try {
            let columnsNormal3: any[] | undefined = []
            if (customColumns?.[contentType]?.length! > 0) {
                columnsNormal3 = customColumns![contentType]
            }
            let newListApiUrl = getApiUrlV2(`${app}/list/${model}/?profile=${profileObject.id}`, reduxAuth)
            if (columnsNormal3?.length! > 0) {
                newListApiUrl += `&fields=${columnsNormal3?.join(',')}`
            }
            return newListApiUrl

        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminData',
                'getListApiUrl',
            ))
        }
    }

    function mainGet() {
        setListState({
            ...listState,
            clusterIds: [],
            isLoading: true,
        })
        if (contentType) {
            onGetListData(
                getListApiUrl()!,
                true,
            )
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            if (changingView) {
                setExportListApiUrl(apiUrl)
            }
            getInfiniteListItem({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'AdminData',
                dispatch,
                listState,
                noClusterIds: true,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminData',
                'getListData',
            ))
        }
    }

    function onSearch(searchValue: any) {
        try {
            setListState({
                ...listState,
                clusterIds: [],
                isLoading: true,
            })
            let axiosUrl = ''
            if ((listApiUrl).includes('?')) {
                axiosUrl = `${listApiUrl}&search=${searchValue}`
            } else {
                axiosUrl = `${listApiUrl}?search=${searchValue}`
            }
            onGetListData(
                getApiUrlV2(axiosUrl, reduxAuth),
                true,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminData',
                'onSearch',
            ))
        }
    }

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                onSearch(event.target.value)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminData',
                'onSearchKeyPress',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminData',
                'onSearchNext',
            ))
        }
    }

    function onShowModalForm() {
        try {
            dispatch(reduxModalMainFormShow({
                contentType,
                contentTypeSettings,
                cssClass: 'form-container-modal',
                formComponent: 'FormModal',
                objectId: 'new',
                profileObject,
                refreshData: mainGet,
            }))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminData',
                'onShowModalForm',
            ))
        }
    }

    if (!contentTypeSettings && !listState.isLoading) {
        return (
            <NotFoundComponent />
        )
    }

    if (!contentTypeSettings && !contentType) {
        return (
            <NotFoundComponent />
        )
    }

    if (!contentTypeSettings) {
        return <Loader isOpen />
    }

    const columnsNormal: any[] | undefined = profileObject?.settings_data_json?.contentTypeSettingsCustom?.[listState.contentType!]?.list?.fields || listState.contentTypeSettings!.list.fields
    const columns = (customColumns?.[contentType!]?.length! > 0 ? customColumns![contentType] : undefined) || columnsNormal

    return (
        <div className='admin-module'>
            <div className='am-header'>
                <div className='am-search-wrap'>
                    <div className='am-action-wrap'>
                        <TableAction
                            contentType={listState.contentType!}
                            contentTypeSettings={listState.contentTypeSettings!}
                            exportColumns={exportColumns}
                            exportListApiUrl={exportListApiUrl}
                            profile={profileObject}
                            setCustomColumns={(e) => {
                                if (e?.length > 0) {
                                    const newCustomColumns: string[] = []
                                    e.map((val) => {
                                        newCustomColumns.push(val.columnName)
                                    })
                                    setCustomColumns({
                                        ...customColumns || {},
                                        [contentType!]: newCustomColumns,
                                    })
                                    setExportColumns(e)
                                } else {
                                    setCustomColumns({
                                        ...customColumns || {},
                                        [contentType!]: [],
                                    })
                                }
                            }}
                            subContentTypeId={undefined}
                        />
                    </div>
                    {contentTypeSettings && !contentTypeSettings.meta.disableFormAdd && (
                        <Button
                            edit={false}
                            fill='outline'
                            onClick={onShowModalForm}
                            size='x-small'
                            text={`${reduxText[2138]} ${getReduxTextId(contentTypeSettings.meta?.verboseName!, reduxText)}`}
                        />
                    )}
                    <IonSearchbar
                        className='am-searchbar'
                        onKeyPress={(e) => onSearchKeyPress(e)}
                        placeholder={reduxText[8335]}
                        ref={searchBarRef}
                    />
                    <IconBlock
                        className='am-icon'
                        edit={false}
                        iconClass='mo-new-icon-search-solid'
                        onClick={() => { if (searchBarRef.current) onSearch(searchBarRef.current.value) }}
                    />
                </div>
            </div>
            <div className={`am-infinite-table mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <table>
                    <thead>
                        <TableHeader
                            columns={columns}
                            contentType={listState.contentType!}
                            contentTypeSettings={listState.contentTypeSettings!}
                            count={listState.count}
                            filterValues={{}}
                            profile={profileObject}
                            setExportColumns={setExportColumns}
                        />
                    </thead>
                    <tbody>
                        {listState.items.map((item) => {
                            return (
                                <TableRow
                                    key={item.id}
                                    columns={columns}
                                    componentActionSheet='ActionComponent'
                                    contentType={listState.contentType!}
                                    contentTypeSettings={listState.contentTypeSettings!}
                                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                                    defaultFields={undefined}
                                    modalKey={listState.modalKey}
                                    object={item}
                                    profileObject={profileObject}
                                />
                            )
                        })}
                    </tbody>
                </table>
                <InfiniteScrollHelperWeb
                    active={!listState.disableInfiniteScroll}
                    hasMore={listState.hasMore}
                    isLoading={listState.isLoading}
                    items={listState.items}
                    onChange={onSearchNext}
                />
            </div>
        </div>
    )
})
