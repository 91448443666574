// data
import {
    REDUX_FORM_GET_CSS_PROPERTIES,
    REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN,
    REDUX_FORM_SET_MIXED_PROFILE_ID,
    REDUX_FORM_SET_REFRESH,
} from './mo_actions'
import {
    reduxFormState,
} from './mo_state'

// initial state
const initialState: reduxFormState = {
    cssProperties: [],
    isSuccessToastMessage: undefined,
    isSuccessToastOpen: false,
    mixedFonts: [],
    mixedMother: undefined,
    mixedProfileId: undefined,
    refresh: {
        refreshComment: 0,
        refreshCustomCategory: 0,
        refreshCustomChoice: 0,
        refreshDetail: 0,
        refreshFeed: 0,
        refreshFeedSection: 0,
        refreshForm: 0,
        refreshInlineList: 0,
        refreshPreview: 0,
        refreshProfile: 0,
        refreshRoom: 0,
    },
    refreshId: {
        refreshComment: undefined,
        refreshCustomCategory: undefined,
        refreshCustomChoice: undefined,
        refreshDetail: undefined,
        refreshFeed: undefined,
        refreshFeedSection: undefined,
        refreshForm: undefined,
        refreshInlineList: undefined,
        refreshPreview: undefined,
        refreshProfile: undefined,
        refreshRoom: undefined,
    },
    refreshNoEmpty: undefined,
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_FORM_GET_CSS_PROPERTIES: {
            return {
                ...state,
                cssProperties: action.payload,
            }
        }
        case REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN: {
            return {
                ...state,
                isSuccessToastMessage: action.payload.message,
                isSuccessToastOpen: action.payload.value,
            }
        }
        case REDUX_FORM_SET_MIXED_PROFILE_ID: {
            return {
                ...state,
                mixedFonts: action.payload.fonts,
                mixedMother: action.payload.mother,
                mixedProfileId: action.payload.profileId,
            }
        }
        case REDUX_FORM_SET_REFRESH: {
            return {
                ...state,
                refresh: {
                    ...state.refresh,
                    [action.payload.refreshType]: action.payload.refreshValue,
                },
                refreshId: {
                    ...state.refreshId,
                    [action.payload.refreshType]: action.payload.refreshId,
                },
                refreshNoEmpty: action.payload.noEmpty,
            }
        }
        default:
            return state
    }
}
