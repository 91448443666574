// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonSearchbar,
} from '@ionic/react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
    InfiniteScrollHelperWeb,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    // reduxModalMainFormShow,
} from 'data'

// pages
import {
    DetailTabAction,
    TableHeader,
    TableRow,
    TableRowDraggable,
} from 'pages'

// serializers
import {
    ContentTypeDetailBlockInlineTypes,
    ContentTypeFieldTypes,
    ContentTypeSettingsCustomTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getInfiniteListItem,
    getModalAbsoluteUrlV2,
    getReduxTextId,
    listStateInitial,
    listStateProps,
    onClickIsModal,
    parseQuery,
} from 'services'

// props
type ListContentProps = {
    actionSheet: string
    blockId: number
    blockInlineObject: ContentTypeDetailBlockInlineTypes
    columns: any
    contentType: contentTypeModelType
    contentTypeParent: contentTypeModelType
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    defaultFields: any
    detailObject: any
    inModal?: boolean
    listApiUrl: string
    parentObject: any
    profile: ProfileDetailAdminSerializerV2 | undefined
    refreshData: () => void
    tab: number
    title?: string
}

// main
export const ListContent: React.FC<ListContentProps> = React.memo(({
    actionSheet,
    blockId,
    blockInlineObject,
    columns,
    contentType,
    contentTypeParent,
    contentTypeSettingsCustom,
    defaultFields,
    detailObject,
    inModal,
    listApiUrl,
    parentObject,
    profile,
    refreshData,
    tab,
    title,
}) => {

    const dispatch = useDispatch()
    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    // const inlineFilters = contentTypeSettingsCustom?.detail?.blockInlineAdaptation?.[blockId]?.[`inlineFilters_${detailObject.id}`] || contentTypeSettingsCustom?.detail?.blockInlineAdaptation?.[blockId]?.inlineFilters || blockInlineObject.inlineFilters || []
    const inlineFilters = blockInlineObject.inlineFilters || []

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [customColumns, setCustomColumns] = useState<string[]>([])
    const [exportColumns, setExportColumns] = useState<{
        columnName: string
        columnTitle: string
        fieldOptions: ContentTypeFieldTypes | undefined
    }[]>([])
    const [exportListApiUrl, setExportListApiUrl] = useState<string>('')
    const [hoveredRow, setHoveredRow] = useState<number | null>(null)
    const [isOrdering, setIsOrdering] = useState<boolean>(false)
    const [listState, setListState] = useState<listStateProps>({
        ...listStateInitial,
        clusterIds: [],
        modalKey: Math.random(),
    })
    const [searchValue, setSearchValue] = useState<string | null | undefined>('')
    const [tabFilterItem, setTabFilterItem] = useState<{
        name: string
        value: string
        isCustomOrdering: number | undefined
    } | undefined>(parseQuery(window.location.search)?.subTab ? (inlineFilters?.find((obj: any) => obj.name === parseQuery(window.location.search)?.subTab) || inlineFilters?.[0]) : inlineFilters?.[0])
    const [tabFilterIsCustomOrdering, setTabFilterIsCustomOrdering] = useState<boolean>(false)
    const [tabFilterString, setTabFilterString] = useState<string>(inlineFilters?.[0]?.value?.replace('__current_user__', `${reduxAuth.settings?.main_profile?.id!}`))
    const [userFilterOrderingCombined, setUserFilterOrderingCombined] = useState<boolean>(false)
    const [userFilterValues, setUserFilterValues] = useState<any>({})
    const [checkboxArray, setCheckboxArray] = useState<number[]>([])

    const app = contentType.split('_')[0]
    const model = contentType.split('_')[1]

    useEffect(() => {
        if (tabFilterItem) {
            onTabChange(tabFilterItem.value?.replace('__current_user__', `${reduxAuth.settings?.main_profile?.id!}`), tabFilterItem)
        } else {
            onGetListData(getListApiUrl(true)!, true)
        }
    }, [
        customColumns,
        listApiUrl,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.id,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshDetail) {
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            onGetListData(getListApiUrl(true)!, true, true)
        }
    }, [
        reduxForm.refresh.refreshDetail,
    ])

    function getListApiUrl(clearUserFilter?: boolean, newUserFilterValues?: any, newTabFilterString?: string, clearFilter?: boolean) {
        try {
            let newListApiUrl = getApiUrlV2(listApiUrl, reduxAuth)
            const newUserFilterValuesCombined = newUserFilterValues || userFilterValues
            if (!clearUserFilter && newUserFilterValuesCombined) {
                Object.keys(newUserFilterValuesCombined).forEach((key) => {
                    if (newUserFilterValuesCombined[key] && newUserFilterValuesCombined[key].length > 0) {
                        if (!newListApiUrl.includes('?')) newListApiUrl += '?'
                        if (key === 'order_by') {
                            newListApiUrl += `&${key}=${newUserFilterValuesCombined[key].map((val: any) => `${val.direction === '-' ? '-' : ''}${val.name}`).join(',')}`
                        } else {
                            newListApiUrl += `&${key}=${newUserFilterValuesCombined[key]}`
                        }
                    }
                })
            }
            if (columns) {
                newListApiUrl += `&fields=${columns?.join(',')}`
            }
            const newTabFilterStringCombined = newTabFilterString || (clearFilter ? '' : tabFilterString)
            if (newTabFilterStringCombined) {
                newListApiUrl += `&${newTabFilterStringCombined}`
            }
            if (clearUserFilter) {
                setUserFilterValues({})
            }
            return newListApiUrl

        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'getListApiUrl',
            ))
        }
    }

    function onTabChange(tabFilterString: string, tabFilterItem: {
        name: string
        value: string
        isCustomOrdering: number | undefined
    } | undefined) {
        try {
            setIsOrdering(false)
            setSearchValue('')
            if (searchBarRef.current) {
                searchBarRef.current.value = ''
            }
            setTabFilterItem(tabFilterItem)
            setTabFilterIsCustomOrdering(Boolean(tabFilterItem?.isCustomOrdering || (tabFilterItem?.name === 'Affiche')))  // TODO: remove hardcoded 'Affiche'
            let newTabFilterString = tabFilterString
            if (tabFilterItem?.isCustomOrdering) {
                newTabFilterString += `&order_by_blockfilter=${tabFilterItem.isCustomOrdering}`
            }
            setTabFilterString(newTabFilterString)
            onGetListData(getListApiUrl(true, undefined, newTabFilterString, (tabFilterString ? false : true))!, true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'onTabChange',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        noEmpty?: boolean,
    ) {
        try {
            if (changingView) {
                setExportListApiUrl(apiUrl)
            }
            getInfiniteListItem({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'ListContent',
                dispatch,
                listState,
                noClusterIds: true,
                noEmptyItems: noEmpty,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'getListData',
            ))
        }
    }

    async function onMoveRow(dragIndex: number, hoverIndex: number) {
        try {
            const newRows = [...listState.items]
            const [draggedRow] = newRows.splice(dragIndex, 1)
            newRows.splice(hoverIndex, 0, draggedRow)
            setListState({
                ...listState,
                items: newRows,
            })
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ListContent onMoveRow')
            const item = listState.items[dragIndex]
            const itemTo = listState.items[hoverIndex]
            const formData: any = new FormData()
            let axiosUrl = ''
            if (tabFilterItem?.name === 'Affiche') {
                const posterItemIds = newRows.map(row => row.id)
                console.log(posterItemIds)
                formData.append('poster_item_ids', posterItemIds.join(','))
                axiosUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/poster/items_position/${detailObject.id}/`, reduxAuth)
            } else if (tabFilterItem?.isCustomOrdering) {
                formData.append('parent_id', detailObject.id)
                formData.append('item_id', item.id)
                formData.append('new_index', hoverIndex)
                axiosUrl = getApiUrlV2(`setup/form/contenttypepanelblockfilter/items_position/${tabFilterItem.isCustomOrdering}/`, reduxAuth)
            } else {
                formData.append('sibling_id', itemTo.id)
                axiosUrl = getApiUrlV2(`${app}/form/${model}/position/${item.id}/`, reduxAuth)
            }
            if (process.env.NODE_ENV === 'development') console.log(axiosUrl)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: (tabFilterItem?.isCustomOrdering || tabFilterItem?.name === 'Affiche') ? 'post' : 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'ListContent',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onMoveRow',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'onMoveRow',
            ))
        }
    }

    function onSearch(newSearchValue: any) {
        try {
            onSetFilter({
                params: 'search',
                value: newSearchValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'onSearch',
            ))
        }
    }

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                onSearch(event.target.value)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'onSearchKeyPress',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'onSearchNext',
            ))
        }
    }

    function onSetFilter(e: any) {
        try {
            setIsOrdering(false)
            if (e.params === 'order_by') {
                const order_by = userFilterOrderingCombined ? userFilterValues.order_by || [] : []
                const existingIndex = order_by.findIndex((item: any) => item.name === e.name)
                if (e.direction) {
                    if (existingIndex !== -1) {
                        order_by[existingIndex].direction = e.direction
                    } else {
                        order_by.push({
                            direction: e.direction,
                            name: e.name,
                        })
                    }
                    userFilterValues.order_by = order_by
                } else {
                    userFilterValues.order_by = order_by.filter((item: any) => item.name !== e.name)
                }
            } else {
                if (e.value) {
                    userFilterValues[e.params] = e.value
                } else {
                    delete userFilterValues[e.params]
                }
            }
            onGetListData(getListApiUrl(false, userFilterValues)!, true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'onFilter',
            ))
        }
    }

    function checkboxAddRemove(id: number, remove?: boolean) {
        try {
            if (remove) {
                setCheckboxArray(checkboxArray.filter((val) => val !== id))
            } else {
                setCheckboxArray([...checkboxArray, id])
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'onSearchNext',
            ))
        }
    }

    async function checkboxSelectAll(remove?: boolean) {
        try {
            if (remove) {
                setCheckboxArray([])
            } else {
                setCheckboxArray(listState.items.map((val) => val.id))
                const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ListContent checkboxSelectAll')
                // setProcessMessage(reduxText[4525])
                // setIsProcessing(true)
                const postUrl = getApiUrlV2(`main/helper/values/`, reduxAuth)
                const formData: any = new FormData()
                formData.append('api_list_url', exportListApiUrl)
                formData.append('columns', ['id'])
                formData.append('ids', 'all')
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setCheckboxArray(response.data)
                        // setIsProcessing(false)
                    })
                    .catch((error) => {
                        // setIsProcessing(false)
                        axiosErrorHandler({
                            apiUrl: postUrl,
                            component: 'ListContent',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'checkboxSelectAll',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListContent',
                'checkboxSelectAll',
            ))
        }
    }

    if (blockInlineObject.hiddenIf) {
        const [hiddenIfField, hiddenIfValueRaw] = blockInlineObject.hiddenIf.split('=')
        let hiddenIfValue: any
        switch (hiddenIfValueRaw) {
            case 'true':
                hiddenIfValue = true
                break
            case 'false':
                hiddenIfValue = false
                break
            default:
                hiddenIfValue = hiddenIfValueRaw
        }
        if (detailObject[hiddenIfField] === hiddenIfValue) return null
    }

    const columns2 = customColumns.length > 0 ? customColumns : columns

    return (
        <div className='list-content'>
            <div className='lc-header'>
                <div className='lc-wrap-title'>
                    {title && (
                        <p className='lc-title'>{getReduxTextId(title, reduxText)}</p>
                    )}
                    {(inlineFilters.length > 0) && (
                        <div className='lc-filters'>
                            {inlineFilters.map((filter: any) => {
                                let toUrl = `/detail/${contentTypeParent}/${detailObject.id}`
                                const tabSearch = parseQuery(window.location.search)?.tab
                                if (tabSearch) {
                                    toUrl += `?tab=${tabSearch}`
                                }
                                if (filter.value) {
                                    if (toUrl.includes('?')) {
                                        toUrl += '&'
                                    } else {
                                        toUrl += '?'
                                    }
                                    toUrl += `subTab=${filter.name}`
                                }
                                return (
                                    <Button
                                        key={filter.name}
                                        className={(tabFilterItem?.name === filter.name) ? 'lc-active' : ''}
                                        edit={false}
                                        fill='clear'
                                        justifyText='flex-start'
                                        onClick={() => onTabChange(filter.value?.replace('__current_user__', `${reduxAuth.settings?.main_profile?.id!}`), filter)}
                                        size='small'
                                        text={filter.name}
                                        to={inModal ? undefined : toUrl}
                                    />
                                )
                            })}
                        </div>
                    )}
                </div>
                <div className='lc-action'>
                    {listState.contentTypeSettings && (
                        <DetailTabAction
                            blockId={blockId}
                            canSendEmail={parentObject.profile?.id === 191736 && columns2?.some((item: any) => item.includes('email'))}
                            checkboxArray={checkboxArray}
                            contentType={contentType}
                            contentTypeSettings={listState.contentTypeSettings}
                            columns={columns2}
                            detailObject={detailObject}
                            disableOrdering={Boolean(searchValue)}
                            emailContentObjectId={parentObject?.id}
                            emailContentType={parentObject?.content_type_string}
                            exportColumns={exportColumns}
                            exportListApiUrl={exportListApiUrl}
                            filterParams={blockInlineObject.filterParams}
                            hasOrdering={typeof listState.items[0]?.position !== 'undefined' || tabFilterIsCustomOrdering}
                            inModal={inModal}
                            isOrdering={isOrdering}
                            parentModelField={blockInlineObject.parentModelField}
                            parentObject={parentObject}
                            refreshData={() => dispatch(reduxFormSetRefresh('refreshDetail'))}
                            setCustomColumns={(e) => {
                                if (e?.length > 0) {
                                    const newCustomColumns: string[] = []
                                    e.map((val) => {
                                        newCustomColumns.push(val.columnName)
                                    })
                                    setCustomColumns(newCustomColumns)
                                    setExportColumns(e)
                                } else {
                                    setCustomColumns([])
                                }
                            }}
                            setIsOrdering={setIsOrdering}
                            subContentType={blockInlineObject.subContentType}
                            subContentTypes={blockInlineObject.subContentTypes}
                            subTab={tabFilterItem?.name}
                        />
                    )}
                    <div className='lc-search-wrap'>
                        <IonSearchbar
                            className='lc-searchbar'
                            onIonClear={() => onSearch('')}
                            onIonInput={(e) => setSearchValue(e.detail.value)}
                            onKeyPress={(e) => onSearchKeyPress(e)}
                            placeholder={reduxText[8335]}
                            ref={searchBarRef}
                        />
                        <IconBlock
                            className='lc-icon'
                            edit={false}
                            iconClass='mo-new-icon-search-solid'
                            onClick={() => { if (searchBarRef.current) onSearch(searchBarRef.current.value) }}
                        />
                        {reduxAuth.settings?.user?.id === 1 && (
                            <>
                                {/* {(!contentTypeSettingsCustom?.detail?.blockFieldSetAdaptation?.[blockId]?.id && contentTypeSettingsCustom?.meta?.id) && (
                                <IconBlock
                                    edit={false}
                                    iconClass='mo-new-icon-cog-solid'
                                    onClick={() =>
                                        dispatch(reduxModalMainFormShow({
                                            contentType: 'setup_contenttypepanelblockprofile',
                                            cssClass: 'form-container-modal',
                                            defaultFields: {
                                                content_type_settings_profile: {
                                                    id: contentTypeSettingsCustom?.meta?.id,
                                                    name: contentTypeParent,
                                                },
                                                content_type_panel_block: {
                                                    id: blockId,
                                                    name: getReduxTextId(title!, reduxText),
                                                },
                                            },
                                            formComponent: 'FormModal',
                                            objectId: 'new',
                                            profileObject: profile,
                                            refreshData,
                                        }))
                                    }
                                    tooltipText={reduxText[9372]}
                                />
                            )} */}
                                <IconBlock
                                    edit={false}
                                    iconClass={contentTypeSettingsCustom?.detail?.panelAdaptation?.[tab]?.blockList?.includes(blockId) ? 'mo-new-icon-cog-solid' : 'mo-new-icon-globe-solid'}
                                    onClick={() => onClickIsModal(Number(blockId), Math.random(), dispatch)}
                                    to={getModalAbsoluteUrlV2({
                                        contentType: 'setup_contenttypepanelblock',
                                        objectId: Number(blockId),
                                        pageType: 'detail',
                                    })}
                                    tooltipText={reduxText[9372]}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className='lc-table mo-hidden-vertical-scrollbar'>
                <table className={`${tabFilterItem?.name ? `lc-sub-tab-table-${tabFilterItem.name}` : ''}`}>
                    {listState.contentTypeSettings && (
                        <>
                            <thead>
                                <TableHeader
                                    checkboxSelectAll={checkboxSelectAll}
                                    checkboxShow={profile?.id === 191736 && columns2?.some((item: any) => item.includes('email'))}
                                    columns={columns2}
                                    contentType={contentType}
                                    contentTypeSettings={listState.contentTypeSettings}
                                    count={listState.count}
                                    filterValues={userFilterValues}
                                    inModal={inModal}
                                    profile={profile}
                                    setExportColumns={setExportColumns}
                                    setFilter={onSetFilter}
                                />
                            </thead>
                            <tbody>
                                {listState.items.map((item, index) => {
                                    if (isOrdering) {
                                        return (
                                            <TableRowDraggable
                                                columns={columns2}
                                                contentType={contentType}
                                                contentTypeSettings={listState.contentTypeSettings!}
                                                index={index}
                                                isHovered={hoveredRow === index}
                                                key={item.id}
                                                modalKey={listState.modalKey}
                                                moveRow={onMoveRow}
                                                object={item}
                                                profileObject={profile}
                                                setHoveredRow={setHoveredRow}
                                            />
                                        )
                                    }
                                    return (
                                        <TableRow
                                            key={item.id}
                                            checkboxAddRemove={checkboxAddRemove}
                                            checkboxArray={checkboxArray}
                                            checkboxShow={profile?.id === 191736 && columns2?.some((item: any) => item.includes('email'))}
                                            columns={columns2}
                                            componentActionSheet={actionSheet}
                                            contentType={contentType}
                                            contentTypeSettings={listState.contentTypeSettings!}
                                            contentTypeSettingsCustom={profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]}
                                            defaultFields={defaultFields}
                                            inModal={inModal}
                                            modalKey={listState.modalKey}
                                            object={item}
                                            profileObject={profile}
                                            refreshData={() => {
                                                if (tabFilterItem) {
                                                    onTabChange(tabFilterItem.value?.replace('__current_user__', `${reduxAuth.settings?.main_profile?.id!}`), tabFilterItem)
                                                } else {
                                                    onGetListData(getListApiUrl(true)!, true)
                                                }
                                            }}
                                        />
                                    )
                                })}
                            </tbody>
                        </>
                    )}
                </table>
                <InfiniteScrollHelperWeb
                    active={!listState.disableInfiniteScroll}
                    hasMore={listState.hasMore}
                    isLoading={listState.isLoading}
                    items={listState.items}
                    onChange={onSearchNext}
                />
            </div>
        </div>
    )
})
