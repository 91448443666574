// react components
import React from 'react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    MainCountryFlag,
    PhoneNumberHelper,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    fieldTypeLayout,
} from 'data'

// pages
import {
    DetailRow,
} from 'pages'

// serializers
import {
    ContentTypeDetailBlockFieldSetTypes,
    ContentTypeSettingsTypes,
    ContentTypeSettingsCustomTypes,
} from 'serializers/web'

// services
import {
    getDateWithFormat,
    getReduxTextId,
} from 'services'

// props
type DetailFieldProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    field: string
    fieldSetObject: ContentTypeDetailBlockFieldSetTypes
    object: any
    refreshData?: () => void
}

// main
export const DetailField: React.FC<DetailFieldProps> = React.memo(({
    contentType,
    field,
    contentTypeSettings,
    contentTypeSettingsCustom,
    fieldSetObject,
    object,
    refreshData,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const newColumn = field.split('__')[0]
    const fieldSettings = contentTypeSettings.field?.[newColumn]
    const fieldSettingsCustom = (contentType === 'staff_staffmessage' && reduxAuth.settings?.user?.is_staff) ? undefined : contentTypeSettingsCustom?.field?.[field]

    if (!fieldSettings) return (
        <DetailRow
            contentTypeSettings={contentTypeSettings}
            contentTypeSettingsCustom={contentTypeSettingsCustom}
            field={field}
            fieldOptions={undefined}
            fieldSettings={fieldSettings}
            fieldSettingsCustom={fieldSettingsCustom}
            object={object}
            refreshData={refreshData}
            rowId='error 1'
            title={field}
            value='Error'
        />
    )

    const valueType = fieldTypeLayout[fieldSettings.fieldType]

    let value = object[field]

    let valueFieldOptions = undefined
    if (valueType === 'array') {
        const valueField = field.split('__')[1] || 'name'
        valueFieldOptions = fieldSettings.fields?.[valueField]
    }
    const name = fieldSettingsCustom?.verboseName ||
        valueFieldOptions?.verboseName ||
        contentTypeSettings.field?.[field]?.verboseName
    const title = getReduxTextId(name!, reduxText) || field
    const fieldOptions = fieldSetObject.fieldsOptions?.[field]

    if (valueType === 'array') {
        let valueField = field.split('__')[1] || 'name'
        if (field.startsWith('attributions_many_to_many_field_')) {
            const hasNameForForeignKey = object.attributions_list?.some((item: any) => item.hasOwnProperty('name_for_foreign_key'))
            if (valueField === 'name' && hasNameForForeignKey) {
                valueField = 'name_for_foreign_key'
            }
            return (
                <DetailRow
                    contentTypeSettings={contentTypeSettings}
                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                    field={field}
                    fieldOptions={fieldOptions}
                    fieldSettings={fieldSettings}
                    fieldSettingsCustom={fieldSettingsCustom}
                    object={object}
                    refreshData={refreshData}
                    rowId='array 1'
                    title={title}
                    value={object.attributions_list?.filter((obj: any) => obj.attribution_category === contentTypeSettingsCustom?.form?.fieldsOptions?.[field]?.selectUrlParams?.attribution_categories)?.map((val: any) => val?.[valueField]).join(', ')}
                />
            )
        }
        const hasNameForForeignKey = object[newColumn]?.some((item: any) => item.hasOwnProperty('name_for_foreign_key'))
        if (valueField === 'name' && hasNameForForeignKey) {
            valueField = 'name_for_foreign_key'
        }
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='array 2'
                title={title}
                value={object[newColumn]?.map((val: any) => val[valueField]).join(', ')}
            />
        )
    }

    if (valueType === 'boolean') {
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='boolean'
                title={title}
                value={value}
            >
                <i className={`detail-field-icon ${value ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} />
            </DetailRow>
        )
    }

    if (valueType === 'datetime') {
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='datetime'
                title={title}
                value={getDateWithFormat(
                    reduxAuth, value,
                    fieldSettingsCustom?.layout?.dateFormat ||
                    fieldSettings.layout?.dateFormat ||
                    ((fieldSettings.fieldType === 'DateField' && object.date_type_choice) && (object.date_type_choice.id === 'y' ? 'YYYY' : (object.date_type_choice.id === 'm' ? 'MM/YYYY' : 'DD/MM/YYYY'))) ||
                    ((fieldSettings.fieldType === 'DateField' || object.date_only) && 'DD/MM/YYYY') ||
                    (fieldSettings.fieldType === 'DateTimeField' && 'DD/MM/YYYY HH:mm') ||
                    undefined
                )}
            />
        )
    }

    if (valueType === 'image') {
        let imgageSrc = value
        let imgageSrcLightBox = value
        if (fieldSetObject.blockTypeOther === 'main' && !['cover_image_2'].includes(field)) {
            imgageSrc = object.get_image_lq
            imgageSrcLightBox = object.get_image_hq
        }
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='image'
                title={title}
                value={value?.name}
            >
                <ImageHelper
                    alt={object.name}
                    className={`detail-field-image${object[`${field}_svg`] ? ' svg' : ''}`}
                    dominant_color={object.get_dominant_color}
                    lightBox
                    lightBoxSrc={imgageSrcLightBox}
                    src={imgageSrc}
                />
            </DetailRow>
        )
    }

    if (valueType === 'number') {
        if (fieldSettings.layout?.isCurrency) {
            value = Number(value).toLocaleString('fr', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            return (
                <DetailRow
                    contentTypeSettings={contentTypeSettings}
                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                    field={field}
                    fieldOptions={fieldOptions}
                    fieldSettings={fieldSettings}
                    fieldSettingsCustom={fieldSettingsCustom}
                    object={object}
                    refreshData={refreshData}
                    rowId='number 1'
                    title={title}
                    // value={`${value} ${object.currency?.symbol}`}
                    value={`${value} €`}
                />
            )
        }
        // value = Number(value).toLocaleString('fr')
        value = (typeof value !== undefined && value !== null) ? Number(value) : value
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='number 2'
                title={title}
                value={value}
            />
        )
    }

    if (valueType === 'phonenumber') {
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='phonenumber'
                title={title}
                value={value}
            >
                <PhoneNumberHelper
                    showFlag
                    value={value}
                />
            </DetailRow>
        )
    }

    if (valueType === 'rich') {
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                isRich
                object={object}
                refreshData={refreshData}
                rowId='rich'
                title={title}
                value={value}
            >
                <div
                    className={field === 'template' ? 'default-margin' : ''}  // TODO dynamic
                    dangerouslySetInnerHTML={{ __html: value }}
                />
            </DetailRow>
        )
    }

    if (valueType === 'text') {
        if (['ChoiceField', 'ForeignKey', 'GenericForeignKey', 'OneToOneField'].includes(fieldSettings.fieldType)) {
            if (fieldSettings.layout?.isCountry) {
                return (
                    <DetailRow
                        contentTypeSettings={contentTypeSettings}
                        contentTypeSettingsCustom={contentTypeSettingsCustom}
                        field={field}
                        fieldOptions={fieldOptions}
                        fieldSettings={fieldSettings}
                        fieldSettingsCustom={fieldSettingsCustom}
                        object={object}
                        refreshData={refreshData}
                        rowId='text 1'
                        title={title}
                        value={value?.name}
                    >
                        <MainCountryFlag
                            edit={false}
                            object={value}
                            searchTab={undefined}
                            namePosition='end'
                        />
                    </DetailRow>
                )
            }
            value = object[field]?.name_for_foreign_key || object[field]?.name
        }
        if (fieldSettings.fieldType === 'JSONField') {
            return (
                <DetailRow
                    contentTypeSettings={contentTypeSettings}
                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                    field={field}
                    fieldOptions={fieldOptions}
                    fieldSettings={fieldSettings}
                    fieldSettingsCustom={fieldSettingsCustom}
                    object={object}
                    refreshData={refreshData}
                    rowId='text 2'
                    title={title}
                    value={JSON.stringify(value)}
                />
            )
        }
        if (object[`${field}_is_rich`]) {
            return (
                <DetailRow
                    contentTypeSettings={contentTypeSettings}
                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                    field={field}
                    fieldOptions={fieldOptions}
                    fieldSettings={fieldSettings}
                    fieldSettingsCustom={fieldSettingsCustom}
                    isRich
                    object={object}
                    refreshData={refreshData}
                    rowId='text 3'
                    title={title}
                    value={object[`${field}_rich`]}
                >
                    <div
                        className={field === 'template' ? 'default-margin' : ''}  // TODO dynamic
                        dangerouslySetInnerHTML={{ __html: object[`${field}_rich`] }}
                    />
                </DetailRow>
            )
        }
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                fkObject={object[field]}
                object={object}
                refreshData={refreshData}
                rowId='text 4'
                title={title}
                value={value}
            >
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                    {object[field]?.color ? <span style={{ color: object[field]?.color }}>{value}</span> : value}
                </Linkify>
            </DetailRow>
        )
    }

    if (valueType === 'time') {
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='time'
                title={title}
                value={getDateWithFormat(
                    reduxAuth, value,
                    fieldSettingsCustom?.layout?.dateFormat ||
                    fieldSettings.layout?.dateFormat ||
                    'HH:mm',
                    true,
                )}
            />
        )
    }

    if (valueType === 'url') {
        if (fieldSettings.layout?.isIcon && value) {
            return (
                <DetailRow
                    contentTypeSettings={contentTypeSettings}
                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                    field={field}
                    fieldOptions={fieldOptions}
                    fieldSettings={fieldSettings}
                    fieldSettingsCustom={fieldSettingsCustom}
                    object={object}
                    refreshData={refreshData}
                    rowId='url 1'
                    title={title}
                    value={value}
                >
                    <IconBlock
                        edit={false}
                        iconClass='mo-new-icon-link-solid'
                        href={fieldSettings.layout.isHref ? value : undefined}
                        to={!fieldSettings.layout.isHref ? value : undefined}
                    />
                </DetailRow>
            )
        }

        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='url 2'
                title={title}
                value={value}
            >
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                    {value}
                </Linkify>
            </DetailRow>
        )
    }

    if (valueType === 'text_redux') {
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='text_redux'
                title={title}
                value={getReduxTextId(value, reduxText)!}
            />
        )
    }

    if (valueType === 'mixed') {
        if (value?.field_type === 'BooleanField') {
            return (
                <DetailRow
                    contentTypeSettings={contentTypeSettings}
                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                    field={field}
                    fieldOptions={fieldOptions}
                    fieldSettings={fieldSettings}
                    fieldSettingsCustom={fieldSettingsCustom}
                    object={object}
                    refreshData={refreshData}
                    rowId='mixed 1'
                    title={title}
                    value={value.value}
                >
                    <i className={`detail-field-icon ${value.value ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} />
                </DetailRow>
            )
        }
        return (
            <DetailRow
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                field={field}
                fieldOptions={fieldOptions}
                fieldSettings={fieldSettings}
                fieldSettingsCustom={fieldSettingsCustom}
                object={object}
                refreshData={refreshData}
                rowId='mixed 2'
                title={title}
                value={value?.value}
            />
        )
    }

    return (
        <DetailRow
            contentTypeSettings={contentTypeSettings}
            contentTypeSettingsCustom={contentTypeSettingsCustom}
            field={field}
            fieldOptions={fieldOptions}
            fieldSettings={fieldSettings}
            fieldSettingsCustom={fieldSettingsCustom}
            object={object}
            refreshData={refreshData}
            isError
            rowId='error 2'
            title={title}
            value={`Error: Missing valueType type ${fieldSettings.fieldType}`}
        />
    )
})
