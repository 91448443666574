// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    EventActionSheetMobile,
} from 'pages'

// serializers
import {
    EventListSerializer,
} from 'serializers/web'

// services
import {
    getSchedule,
} from 'services'

// props
type EventListMediumBlockMobileProps = {
    edit: boolean
    object: EventListSerializer
}

// main
export const EventListMediumBlockMobile: React.FC<EventListMediumBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [isActionSheetOpen, setActionSheetIsOpen] = useState(false)

    return (
        <div
            className='event-list-medium-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='event'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <div className='elmb-cover'>
                <LinkHelper
                    className='elmb-cover-wrap'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    <div className='elmb-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='elmb-image'
                            dominant_color={undefined}
                            lazyMobile={false}
                            src={object.get_image_lq}
                        />
                    </div>
                </LinkHelper>
            </div>
            <div className='elmb-right'>
                <p className='elmb-date mo-clamp-mobile1'>{object.start_date && getSchedule(reduxAuth, object.start_date, object.end_date, object.date_only, object.date_type_choice)}</p>
                <LinkHelper
                    className='mo-list-block-name-mobile medium'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    {object.name}
                </LinkHelper>
                <div className='elmb-tags'>
                    <MainChipListBlock>
                        {object.themes?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
                {object.is_online_event && (
                    <div className='elmb-countries'>
                        <p>{reduxText[6389]}</p>
                    </div>
                )}
                {object.address_json?.length! > 0 && (
                    <div className='elmb-countries'>
                        {object.country && (
                            <MainCountryFlag
                                edit={edit}
                                object={object.country}
                            />
                        )}
                        {object.city && (
                            <MainHashtag
                                edit={edit}
                                label={object.city.name}
                            />
                        )}
                    </div>
                )}
                <div className='list-block-footer'>
                    {object.profile && (
                        <ListProfileLink
                            blockSize='medium'
                            edit={edit}
                            profile={object.profile}
                        />
                    )}
                </div>
                {object.id && (
                    <div className='elmb-actions'>
                        <IconBlock
                            edit={edit}
                            iconClass='mo-new-icon-ellipsis-h-solid'
                            onClick={() => setActionSheetIsOpen(true)}
                        />
                    </div>
                )}
            </div>
            {isActionSheetOpen && (
                <EventActionSheetMobile
                    object={object}
                    setActivated={setActivated}
                    setIsOpen={setActionSheetIsOpen}
                />
            )}
        </div>
    )
})
