// react components
import React from 'react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CountViewBlock,
    ImageHelper,
    InactiveButton,
    MainHashtag,
    ProfileShortInfoMobile,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    OfferDetailSerializer,
} from 'serializers/web'

// services
import {
    getDateWithType,
} from 'services'

// props
type OfferDetailBlockMobileProps = {
    edit: boolean
    object: OfferDetailSerializer
}

// main
export const OfferDetailBlockMobile: React.FC<OfferDetailBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='offer-detail-block'>
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='offer'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <ImageHelper
                alt={object.name}
                className='olb-cover-image'
                dominant_color={undefined}
                src={object.get_image_lq}
            />
            <div className='olb-material-info'>
                <div className='olb-main-info'>
                    <p className='olb-name'>{object.name}</p>
                    <div className='olb-tags'>
                        {object.metiers && (
                            <MainHashtag
                                edit={edit}
                                label={object.metiers}
                            />
                        )}
                        {object.themes?.map((val) => (
                            <MainHashtag
                                key={`themes-${val.id}`}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </div>
                    <div className='olb-date'>
                        {object.always_open
                            ? (
                                <span >{reduxText[3489]}</span>
                            ) : (
                                <React.Fragment>
                                    <span className='olb-text'>{reduxText[5321]}</span>
                                    <span>{getDateWithType(reduxAuth, object.valid_date, 'd')}</span>
                                </React.Fragment>
                            )}
                    </div>
                    {object.allowance && (
                        <p className='olb-allowance'>{object.allowance}</p>
                    )}
                    {object.profile && (
                        <ProfileShortInfoMobile
                            edit={edit}
                            object={object.profile}
                        />
                    )}
                    <div className='olb-countries'>
                        {object.address_json && object.address_json.length > 0 && object.address_json.map((val, i) => (
                            <span
                                key={i}
                                className='olb-cities'
                            >
                                {val.description}
                            </span>
                        ))}
                    </div>
                </div>
                {object.description && (
                    <div className='olb-section'>
                        <p className='olb-section-title'>{reduxText[4596]}</p>
                        <Linkify>
                            <p className='olb-description'>{object.description}</p>
                        </Linkify>
                    </div>
                )}
            </div>
            {object.owner_info && (
                <CountViewBlock
                    object={object.owner_info}
                />
            )}
        </div>
    )
})
