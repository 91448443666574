// react components
import React, {
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

// components
import {
    Button,
    ImageGalleryHelper,
    LinkHelper,
} from 'components'

// data
import {
    api_url_profile_section_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_profile_detail_old,
} from 'data'

// pages
import {
    BlockTemplateListBlock,
    EmptyListSmallBlockWeb,
    EventListMediumBlockWeb,
    EventListSmallBlockWeb,
    FeedListSmallBlockWeb,
    MaterialListMediumBlockWeb,
    MaterialListSmallBlockWeb,
    OfferListMediumBlockWeb,
    OfferListSmallBlockWeb,
    PageTemplateListBlock,
    PressListMediumBlockWeb,
    PressListSmallBlockWeb,
    ProductListMediumBlockWeb,
    ProductListSmallBlockWeb,
    ProfileListMediumBlockWeb,
    ProfileListSmallBlockWeb,
    ProjectListMediumBlockWeb,
    ProjectListSmallBlockWeb,
    ServiceListMediumBlock,
    ServiceListSmallBlock,
    TeamListMediumBlockWeb,
    TeamListSmallBlockWeb,
} from 'pages'

// services
import {
    getApiUrl,
    getInfiniteListWebNew,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// serializers
import {
    ProfileHomeListSerializer,
} from 'serializers/web'

// props
type MatchParams = {
    slug: string
}

type ProfileHomeListBlockWebProps = {
    object: ProfileHomeListSerializer
}

// main
export const ProfileHomeListBlockWeb: React.FC<ProfileHomeListBlockWebProps> = React.memo(({
    object,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const tabSettings: any = {
        artwork: {
            listTitle: reduxText[3554],
            mediumListComponent: 'ProjectListMediumBlockWeb',
            smallListComponent: 'ProjectListSmallBlockWeb',
        },
        blocktemplate: {
            listTitle: 'Block Templates',
            mediumListComponent: 'BlockTemplateListBlock',
            smallListComponent: 'BlockTemplateListBlock',
        },
        event: {
            listTitle: reduxText[2433],
            mediumListComponent: 'EventListMediumBlockWeb',
            smallListComponent: 'EventListSmallBlockWeb',
        },
        generalfeed: {
            listTitle: reduxText[301],
            mediumListComponent: 'FeedListSmallBlockWeb',
            smallListComponent: 'FeedListSmallBlockWeb',
        },
        material: {
            listTitle: reduxText[301],
            mediumListComponent: 'MaterialListMediumBlockWeb',
            smallListComponent: 'MaterialListSmallBlockWeb',
        },
        member: {
            listTitle: reduxText[3077],
            mediumListComponent: 'TeamListMediumBlockWeb',
            smallListComponent: 'TeamListSmallBlockWeb',
        },
        offer: {
            listTitle: reduxText[302],
            mediumListComponent: 'OfferListMediumBlockWeb',
            smallListComponent: 'OfferListSmallBlockWeb',
        },
        pagetemplate: {
            listTitle: 'Page Templates',
            mediumListComponent: 'PageTemplateListBlock',
            smallListComponent: 'PageTemplateListBlock',
        },
        press: {
            listTitle: reduxText[4812],
            mediumListComponent: 'PressListMediumBlockWeb',
            smallListComponent: 'PressListSmallBlockWeb',
        },
        product: {
            listTitle: reduxText[4696],
            mediumListComponent: 'ProductListMediumBlockWeb',
            smallListComponent: 'ProductListSmallBlockWeb',
        },
        project: {
            mediumListComponent: 'ProjectListMediumBlockWeb',
            smallListComponent: 'ProjectListSmallBlockWeb',
        },
        resource: {
            listTitle: reduxText[8144],
            mediumListComponent: 'ProjectListMediumBlockWeb',
            smallListComponent: 'ProjectListSmallBlockWeb',
        },
        service: {
            listTitle: reduxText[7434],
            mediumListComponent: 'ServiceListMediumBlock',
            smallListComponent: 'ServiceListSmallBlock',
        },
        team: {
            mediumListComponent: 'TeamListMediumBlockWeb',
            smallListComponent: 'TeamListSmallBlockWeb',
        },
    }

    const customSettings: any = {
        31: tabSettings.event,
        75: tabSettings.material,
        87: tabSettings.offer,
        110: tabSettings.project,
        119: tabSettings.product,
        215: tabSettings.service,
    }

    const components: any = {
        BlockTemplateListBlock,
        EventListMediumBlockWeb,
        EventListSmallBlockWeb,
        FeedListSmallBlockWeb,
        ImageGalleryHelper,
        MaterialListMediumBlockWeb,
        MaterialListSmallBlockWeb,
        OfferListMediumBlockWeb,
        OfferListSmallBlockWeb,
        PageTemplateListBlock,
        PressListMediumBlockWeb,
        PressListSmallBlockWeb,
        ProductListMediumBlockWeb,
        ProductListSmallBlockWeb,
        ProfileListMediumBlockWeb,
        ProfileListSmallBlockWeb,
        ProjectListMediumBlockWeb,
        ProjectListSmallBlockWeb,
        ServiceListMediumBlock,
        ServiceListSmallBlock,
        TeamListMediumBlockWeb,
        TeamListSmallBlockWeb,
    }

    let componentNameString: any
    if (object.name_id === 'custom') {
        if (object.component) {
            componentNameString = tabSettings[object.component]?.[`${object.thumb_size_web}ListComponent`]
        } else {
            componentNameString = customSettings[object.content_type]?.[`${object.thumb_size_web}ListComponent`]
        }
    } else {
        componentNameString = tabSettings[object.name_id]?.[`${object.thumb_size_web}ListComponent`]
    }

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb>({
        ...listStateInitialWeb,
        componentString: componentNameString,
        disableInfiniteScroll: false,
        modalKey: Math.random(),
    })
    const [initialLoad, setInitialLoad] = useState(true)

    const ComponentName = components[listState.componentString]

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWebNew({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'ProfileHomeListBlockWeb',
                componentName: componentNameString,
                dispatch,
                listState,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileHomeListBlockWeb',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                if (initialLoad) {
                    setInitialLoad(false)
                    onGetListData(getApiUrl(`${api_url_profile_section_detail}?section_id=${object.id}&origin=profilehome&page_size=10`, reduxAuth), true)
                } else {
                    onGetListData(listState.nextUrl, false)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileHomeListBlockWeb',
                'onSearchNext',
            ))
        }
    }

    let cssShowMore = false
    const windowWidth = window.innerWidth
    const itemsLength = listState.items.length
    if (object.thumb_size_web === 'medium' && itemsLength > 4) {
        cssShowMore = true
    } else if (object.thumb_size_web === 'small') {
        if ([
            'artwork',
            'event',
            'material',
            'offer',
            'press',
            'product',
            'project',
            'resource',
            'service',
        ].includes(object.name_id)) {
            if ((windowWidth < 1500) && itemsLength > 6) {
                cssShowMore = true
            } else if ((windowWidth < 1800) && itemsLength > 8) {
                cssShowMore = true
            }
        } else if ([
            'member',
        ].includes(object.name_id)) {
            if ((windowWidth < 1500) && itemsLength > 4) {
                cssShowMore = true
            } else if ((windowWidth < 1800) && itemsLength > 5) {
                cssShowMore = true
            }
        }
    }

    if (!listState.isLoading && listState.items.length === 0) return null

    return (
        <div className='profile-home-list-block-web'>
            <p className='phlbw-title'>
                <span>{object.name_custom || tabSettings[object.name_id].listTitle}</span>
                <LinkHelper
                    edit={false}
                    className='phlbw-see-all'
                    to={`${view_url_profile_detail_old}${params.slug}/sections/${object.id}/${object.main_parent ? `?main-parent=${object.main_parent}` : ''}${object.parent ? `&parent=${object.parent}` : ''}`}
                >
                    {reduxText[7679]}
                </LinkHelper>
            </p>
            <div className={`phlbw-content-wrap ${object.name_id}`}>
                <div className='phlbw-content initial'>
                    <div className='phlbw-content-flex'>
                        {listState.items.map((val: any) => {
                            return (
                                <ComponentName
                                    key={val.id}
                                    edit={false}
                                    isInProfileSlug={params.slug}
                                    modalKey={listState.modalKey}
                                    object={val}
                                    playerOrigin={`profileHomeListBlock-${object.id}`}
                                />
                            )
                        })}
                        {listState.isLoading && (
                            <VisibilitySensor
                                intervalDelay={1000}
                                onChange={onSearchNext}
                                partialVisibility
                            >
                                <React.Fragment>
                                    <EmptyListSmallBlockWeb />
                                    <EmptyListSmallBlockWeb />
                                    <EmptyListSmallBlockWeb />
                                    <EmptyListSmallBlockWeb />
                                    <EmptyListSmallBlockWeb />
                                    <EmptyListSmallBlockWeb />
                                    <EmptyListSmallBlockWeb />
                                    <EmptyListSmallBlockWeb />
                                    <EmptyListSmallBlockWeb />
                                    <EmptyListSmallBlockWeb />
                                </React.Fragment>
                            </VisibilitySensor>
                        )}
                    </div>
                    {(cssShowMore || listState.hasMore) && (
                        <div className='phlbw-show-more'>
                            <Button
                                color='grey'
                                edit={false}
                                fill='outline'
                                size='small'
                                text={`${reduxText[5460]} +`}
                                to={`${view_url_profile_detail_old}${params.slug}/sections/${object.id}/${object.main_parent ? `?main-parent=${object.main_parent}` : ''}${object.parent ? `&parent=${object.parent}` : ''}`}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
})
