// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    CheckboxInputV2,
    FormFieldsPortfolio,
    NewsletterMergeFieldsPortfolio,
    SelectListInputV2,
    TextAreaInputV2,
    TextInputV2,
} from 'pages'

// serializers
import {
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type DataJsonInputPageContentProps = {
    disabled?: boolean
    detailUrl: string
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    mixedMedia?: boolean
    onHandleInputChange: any
    updateUrl: string
}

// main
export const DataJsonInputPageContent: React.FC<DataJsonInputPageContentProps> = React.memo(({
    disabled,
    detailUrl,
    errorsInline,
    fieldsInline,
    fieldsMother,
    mixedMedia,
    onHandleInputChange,
    updateUrl,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function handleInputChange(event: any) {
        try {
            const toReturnValue: {
                [key: string]: any
            } = Object.assign({}, fieldsInline.data_json || {})
            if (!event.value) {
                delete toReturnValue[event.name]
            } else if (event.value.id) {
                toReturnValue[event.name] = event.value.id
            } else if (['connected_hidden', 'custom_profile', 'infinite_list_page_size'].includes(event.name)) {
                toReturnValue[event.name] = Math.round(event.value)
            } else {
                toReturnValue[event.name] = event.value
            }
            onHandleInputChange({
                'name': 'data_json',
                'value': toReturnValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageContentPortfolioPanel',
                'onHandleInputChange',
            ))
        }
    }

    const iconFontLibrary = [
        {
            id: 'default',
            name: 'Default',
        },
        {
            id: 'material-icons',
            name: 'material-icons',
        },
    ]

    const imageLqQualityArray = [
        {
            id: 'get_image_site_mq',
            name: 'medium quality',
        },
        {
            id: 'get_image_site_hq',
            name: 'high quality',
        },
    ]

    const newsletterProviderArray = [
        {
            id: 'malichimp',
            name: 'Malichimp',
        },
        {
            id: 'sendinblue',
            name: 'Sendinblue',
        },
    ]

    const formSubmitTypeArray = [
        {
            id: 'event_event',
            name: 'event_event',
        },
        {
            id: 'event_participant',
            name: 'event_participant',
        },
        {
            id: 'activity_registration',
            name: 'activity_registration',
        },
        {
            id: 'miscellanea_contact',
            name: 'miscellanea_contact',
        },
        {
            id: 'miscellanea_mail',
            name: 'miscellanea_mail',
        },
        {
            id: 'content_type',
            name: 'content_type',
        },
        {
            id: 'instance_create',
            name: 'instance_create',
        },
    ]

    const textTagArray = [
        {
            id: 'h1',
            name: 'h1',
        },
        {
            id: 'h2',
            name: 'h2',
        },
        {
            id: 'h3',
            name: 'h3',
        },
        {
            id: 'h4',
            name: 'h4',
        },
        {
            id: 'h5',
            name: 'h5',
        },
        {
            id: 'h6',
            name: 'h6',
        },
        {
            id: 'p',
            name: 'p',
        },
        {
            id: 'span',
            name: 'span',
        },
    ]

    return (
        <React.Fragment>
            {(fieldsInline.layout?.id === 779 && fieldsInline.text_short_mode?.id === 3 && [
                'created_on',
                'custom_date',
                'custom_date_2',
                'date',
                'date_end',
                'end_date',
                'publication_date',
                'schedule',
                'start_date',
                'valid_date',
            ].includes(fieldsInline.text_short!)) && (
                    <>
                        <TextInputV2
                            disabled={disabled}
                            error={errorsInline?.data_json}
                            label={reduxText[8274]}
                            name='date_format'
                            onChange={handleInputChange}
                            value={fieldsInline.data_json?.date_format || ''}
                        />
                        {[5, 6].includes(fieldsInline.content_source?.id!) && (
                            <>
                                <TextInputV2
                                    disabled={disabled}
                                    error={errorsInline?.data_json}
                                    label='date_format_y'  // TO_TEXT
                                    name='date_format_y'
                                    onChange={handleInputChange}
                                    value={fieldsInline.data_json?.date_format_y || ''}
                                />
                                <TextInputV2
                                    disabled={disabled}
                                    error={errorsInline?.data_json}
                                    label='date_format_m'  // TO_TEXT
                                    name='date_format_m'
                                    onChange={handleInputChange}
                                    value={fieldsInline.data_json?.date_format_m || ''}
                                />
                                <TextInputV2
                                    disabled={disabled}
                                    error={errorsInline?.data_json}
                                    label='date_format_d'  // TO_TEXT
                                    name='date_format_d'
                                    onChange={handleInputChange}
                                    value={fieldsInline.data_json?.date_format_d || ''}
                                />
                            </>
                        )}
                        <CheckboxInputV2
                            disabled={disabled}
                            error={errorsInline?.data_json}
                            label='date_only'  // TO_TEXT
                            name='date_only'
                            onChange={handleInputChange}
                            value={fieldsInline.data_json?.date_only || ''}
                        />
                        <CheckboxInputV2
                            disabled={disabled}
                            error={errorsInline?.data_json}
                            label='time_only'  // TO_TEXT
                            name='time_only'
                            onChange={handleInputChange}
                            value={fieldsInline.data_json?.time_only || ''}
                        />
                    </>
                )}
            {fieldsInline.layout?.id === 779 && !fieldsInline.action && (
                <SelectListInputV2
                    clearable
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label={reduxText[8611]}
                    name='textTag'
                    onChange={handleInputChange}
                    options={textTagArray}
                    value={textTagArray.find((obj) => obj.id === fieldsInline.data_json?.textTag) || ''}
                />
            )}
            {(fieldsInline.layout?.id === 779 && fieldsInline.text_short_mode?.id === 3 && [
                'price',
            ].includes(fieldsInline.text_short!)) && (
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label={reduxText[8749]}
                        name='hidden_if_no_price'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.hidden_if_no_price || ''}
                    />
                )}
            {(['generic_link', 'modal_link', 'href'].includes(fieldsInline.action?.name!)) && ((reduxAuth.settings?.user?.id === 1) || fieldsInline.data_json?.templateUrl) && (
                <TextInputV2
                    disabled={reduxAuth.settings?.user?.id !== 1}
                    error={errorsInline?.data_json}
                    label='templateUrl'
                    name='templateUrl'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.templateUrl || ''}
                />
            )}
            {[52].includes(fieldsInline.content_source?.id!) && (
                <React.Fragment>
                    <TextInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='API url'  // TO_TEXT
                        name='external_api_url'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.external_api_url || ''}
                    />
                    <TextInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='list_name'  // TO_TEXT
                        name='external_api_list_name'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.external_api_list_name || ''}
                    />
                    <TextInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='filter_string'  // TO_TEXT
                        name='external_api_filter_string'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.external_api_filter_string || ''}
                    />
                </React.Fragment>
            )}
            {(fieldsInline.layout?.id === 780) && (
                <React.Fragment>
                    {!fieldsInline.layout_type && (
                        <TextInputV2
                            disabled={disabled}
                            error={errorsInline?.data_json}
                            label={reduxText[8276]}
                            name='connected_hidden'
                            onChange={handleInputChange}
                            type='number'
                            value={fieldsInline.data_json?.connected_hidden || ''}
                        />
                    )}
                    {fieldsInline.layout_type === 'n' && (
                        <CheckboxInputV2
                            disabled={disabled}
                            error={errorsInline?.data_json}
                            label='navbar_is_side_menu'  // TO_TEXT
                            name='navbar_is_side_menu'
                            onChange={handleInputChange}
                            value={fieldsInline.data_json?.navbar_is_side_menu || ''}
                        />
                    )}
                </React.Fragment>
            )}
            {(fieldsInline.layout?.id === 781) && (
                <React.Fragment>
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label={reduxText[8278]}
                        name='use_thumbnail'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.use_thumbnail || ''}
                    />
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label={reduxText[10161]}
                        name='image_use_custom_image'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.image_use_custom_image || ''}
                    />
                    {[11, 12, 15].includes(fieldsInline.content_source?.id!) && (
                        <CheckboxInputV2
                            disabled={disabled}
                            error={errorsInline?.data_json}
                            label='use_background_image'
                            name='use_background_image'
                            onChange={handleInputChange}
                            value={fieldsInline.data_json?.use_background_image || ''}
                        />
                    )}
                    {[1, 2, 5, 6, 7, 8, 9, 10, 13, 14, 16, 27, 28, 40, 43, 51, 84, 85, 91].includes(fieldsInline.content_source?.id!) && (
                        <>
                            <CheckboxInputV2
                                disabled={disabled}
                                error={errorsInline?.data_json}
                                label='use_cover_image_2'
                                name='use_cover_image_2'
                                onChange={handleInputChange}
                                value={fieldsInline.data_json?.use_cover_image_2 || ''}
                            />
                            {(fieldsInline.data_json?.use_attribution_entity_image || reduxAuth.settings?.user?.is_staff) && (
                                <CheckboxInputV2
                                    disabled={!reduxAuth.settings?.user?.is_staff}
                                    error={errorsInline?.data_json}
                                    label='use_attribution_entity_image'
                                    name='use_attribution_entity_image'
                                    onChange={handleInputChange}
                                    value={fieldsInline.data_json?.use_attribution_entity_image || ''}
                                />
                            )}
                        </>
                    )}
                    <SelectListInputV2
                        clearable
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label={reduxText[8280]}
                        name='lq_quality'
                        onChange={handleInputChange}
                        options={imageLqQualityArray}
                        value={imageLqQualityArray.find((obj) => obj.id === fieldsInline.data_json?.lq_quality) || ''}
                    />
                    <SelectListInputV2
                        clearable
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label={reduxText[8631]}
                        name='mq_quality'
                        onChange={handleInputChange}
                        options={imageLqQualityArray}
                        value={imageLqQualityArray.find((obj) => obj.id === fieldsInline.data_json?.mq_quality) || ''}
                    />
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label={reduxText[8438]}
                        name='hidden_if_no_image'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.hidden_if_no_image || ''}
                    />
                </React.Fragment>
            )}
            {(fieldsInline.layout?.id === 782) && (
                <CheckboxInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label='video_no_controls'  // TO_TEXT
                    name='video_no_controls'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.video_no_controls || ''}
                />
            )}
            {(fieldsInline.layout?.id === 783) && (
                <React.Fragment>
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label={reduxText[8441]}
                        name='hidden_if_no_link'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.hidden_if_no_link || ''}
                    />
                    {[5, 6].includes(fieldsInline.content_source?.id!) && (
                        <CheckboxInputV2
                            disabled={disabled}
                            error={errorsInline?.data_json}
                            label={reduxText[8763]}
                            name='hidden_if_past_event'
                            onChange={handleInputChange}
                            value={fieldsInline.data_json?.hidden_if_past_event || ''}
                        />
                    )}
                </React.Fragment>
            )}
            {((fieldsInline.layout?.id === 784) && (fieldsInline.content_source?.id === 44)) && (
                <CheckboxInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label={reduxText[8282]}
                    name='parent_image_fallback'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.parent_image_fallback || ''}
                />
            )}
            {((fieldsInline.layout?.id === 786) && (fieldsInline.content_source?.id === 15)) && (
                <CheckboxInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label={reduxText[8284]}
                    name='exclude_main_profile'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.exclude_main_profile || ''}
                />
            )}
            {((fieldsInline.layout?.id === 789) && [11, 15, 85].includes(fieldsInline.content_source?.id!)) && (
                <>
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label={reduxText[8286]}
                        name='include_website'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.include_website || ''}
                    />
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='exclude_mail'  // TO_TEXT
                        name='exclude_mail'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.exclude_mail || ''}
                    />
                </>
            )}
            {(fieldsInline.layout?.id === 796) && (
                <React.Fragment>
                    {fieldsInline.form_fields && (
                        <FormFieldsPortfolio formFields={fieldsInline.form_fields} />
                    )}
                    {fieldsInline.layout_type !== 'e' && (
                        <React.Fragment>
                            <TextInputV2
                                disabled={disabled}
                                error={errorsInline?.data_json}
                                label='formSuccessMessage'  // TO_TEXT
                                name='formSuccessMessage'
                                onChange={handleInputChange}
                                value={fieldsInline.data_json?.formSuccessMessage || ''}
                            />
                            <TextInputV2
                                disabled={disabled}
                                error={errorsInline?.data_json}
                                label={reduxText[8443]}
                                name='formSubject'
                                onChange={handleInputChange}
                                value={fieldsInline.data_json?.formSubject || ''}
                            />
                            <TextInputV2
                                disabled={disabled}
                                error={errorsInline?.data_json}
                                label={reduxText[8445]}
                                name='formDestinationMail'
                                onChange={handleInputChange}
                                value={fieldsInline.data_json?.formDestinationMail || ''}
                            />
                            {reduxAuth.settings?.user?.id === 1 && (
                                <React.Fragment>
                                    <CheckboxInputV2
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label='form_is_subscription'  // TO_TEXT
                                        name='form_is_subscription'
                                        onChange={handleInputChange}
                                        value={fieldsInline.data_json?.form_is_subscription || ''}
                                    />
                                    {fieldsInline.data_json?.form_is_subscription && (
                                        <TextInputV2
                                            disabled={disabled}
                                            error={errorsInline?.data_json}
                                            label='form_is_subscription_add_free_text'  // TO_TEXT
                                            name='form_is_subscription_add_free_text'
                                            onChange={handleInputChange}
                                            value={fieldsInline.data_json?.form_is_subscription_add_free_text || ''}
                                        />
                                    )}
                                    <CheckboxInputV2
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label='form_is_external'  // TO_TEXT
                                        name='form_is_external'
                                        onChange={handleInputChange}
                                        value={fieldsInline.data_json?.form_is_external || ''}
                                    />
                                    <SelectListInputV2
                                        clearable
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label={reduxText[8447]}
                                        name='newsletterProvider'
                                        onChange={handleInputChange}
                                        options={newsletterProviderArray}
                                        value={newsletterProviderArray.find((obj) => obj.id === fieldsInline.data_json?.newsletterProvider) || ''}
                                    />
                                    <TextAreaInputV2
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label={reduxText[8288]}
                                        name='newsletterApiKey'
                                        onChange={handleInputChange}
                                        value={fieldsInline.data_json?.newsletterApiKey || ''}
                                    />
                                    <TextAreaInputV2
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label={reduxText[8290]}
                                        name='newsletterListId'
                                        onChange={handleInputChange}
                                        value={fieldsInline.data_json?.newsletterListId || ''}
                                    />
                                    <SelectListInputV2
                                        clearable
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label='formSubmitType'
                                        name='formSubmitType'
                                        onChange={handleInputChange}
                                        options={formSubmitTypeArray}
                                        value={formSubmitTypeArray.find((obj) => obj.id === fieldsInline.data_json?.formSubmitType) || ''}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {fieldsInline.layout_type === 'e' && (
                        <React.Fragment>
                            <SelectListInputV2
                                clearable
                                disabled={disabled}
                                error={errorsInline?.data_json}
                                label={reduxText[8447]}
                                name='newsletterProvider'
                                onChange={handleInputChange}
                                options={newsletterProviderArray}
                                value={newsletterProviderArray.find((obj) => obj.id === fieldsInline.data_json?.newsletterProvider) || ''}
                            />
                            {fieldsInline.data_json?.newsletterProvider && (
                                <>
                                    <TextAreaInputV2
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label={reduxText[8288]}
                                        name='newsletterApiKey'
                                        onChange={handleInputChange}
                                        value={fieldsInline.data_json?.newsletterApiKey || ''}
                                    />
                                    <TextAreaInputV2
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label={reduxText[8290]}
                                        name='newsletterListId'
                                        onChange={handleInputChange}
                                        value={fieldsInline.data_json?.newsletterListId || ''}
                                        translation={{
                                            contentType: mixedMedia ? 'mixedmediacontent' : 'pagecontent',
                                            defaultDetailUrl: detailUrl,
                                            defaultLanguage: fieldsInline.translation_default_language,
                                            defaultUpdateUrl: updateUrl,
                                            objectId: fieldsInline.id,
                                            profileId: fieldsMother.profile?.id,
                                        }}
                                    />
                                </>
                            )}
                            {!fieldsInline.data_json?.newsletterProvider && (
                                <>
                                    <TextInputV2
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label={reduxText[8443]}
                                        name='formSubject'
                                        onChange={handleInputChange}
                                        value={fieldsInline.data_json?.formSubject || ''}
                                    />
                                    <TextInputV2
                                        disabled={disabled}
                                        error={errorsInline?.data_json}
                                        label={reduxText[8445]}
                                        name='formDestinationMail'
                                        onChange={handleInputChange}
                                        value={fieldsInline.data_json?.formDestinationMail || ''}
                                    />
                                </>
                            )}
                            {fieldsInline.data_json?.newsletterMergeFields && (
                                <NewsletterMergeFieldsPortfolio newsletterMergeFields={fieldsInline.data_json.newsletterMergeFields} />
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            {(fieldsInline.layout?.id === 799 && fieldsInline.content_source?.id === 12) && (
                <CheckboxInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label='use_countries_2'  // TO_TEXT
                    name='use_countries_2'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.use_countries_2 || ''}
                />
            )}
            {(fieldsInline.content_source?.is_list && ![52].includes(fieldsInline.content_source?.id!) && ![799, 801].includes(fieldsInline.layout?.id!)) && (
                <React.Fragment>
                    <TextInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label={reduxText[8292]}
                        name='infinite_list_page_size'
                        onChange={handleInputChange}
                        type='number'
                        value={fieldsInline.data_json?.infinite_list_page_size || ''}
                    />
                    {(fieldsInline.data_json?.custom_profile || reduxAuth.settings?.user?.is_staff) && (
                        <TextInputV2
                            disabled={disabled}
                            error={errorsInline?.data_json}
                            label={reduxText[8294]}
                            name='custom_profile'
                            onChange={handleInputChange}
                            type='number'
                            value={fieldsInline.data_json?.custom_profile || ''}
                        />
                    )}
                    {(fieldsInline.data_json?.filter_default_value_string || reduxAuth.settings?.user?.is_staff) && (
                        <TextInputV2
                            disabled={disabled}
                            error={errorsInline?.data_json}
                            label='filter_default_value_string'  // TO_TEXT
                            name='filter_default_value_string'
                            onChange={handleInputChange}
                            value={fieldsInline.data_json?.filter_default_value_string || ''}
                        />
                    )}
                </React.Fragment>
            )}
            {([780, 781, 784, 790].includes(fieldsInline.layout?.id!) && fieldsInline.content_source?.is_list) && (
                <TextInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label={reduxText[8296]}
                    name='exclude_first'
                    onChange={handleInputChange}
                    type='number'
                    value={fieldsInline.data_json?.exclude_first || ''}
                />
            )}
            {([780, 781].includes(fieldsInline.layout?.id!) && fieldsInline.content_source?.is_list) && (
                <CheckboxInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label={reduxText[8272]}
                    name='infinite_list_button'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.infinite_list_button || ''}
                />
            )}
            {([780, 790].includes(fieldsInline.layout?.id!) && (fieldsInline.content_source?.is_list) && ![52].includes(fieldsInline.content_source?.id!)) && (
                <CheckboxInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label={reduxText[8449]}
                    name='is_random_ordering'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.is_random_ordering || ''}
                />
            )}
            {(['generic_link', 'modal_link'].includes(fieldsInline.action?.name!) && [12, 15].includes(fieldsInline.content_source?.id!) && [779, 780, 781].includes(fieldsInline.layout?.id!)) && (
                <CheckboxInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label={reduxText[8298]}
                    name='use_member_detail_url'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.use_member_detail_url || ''}
                />
            )}
            {(fieldsInline.layout?.id === 801 && (fieldsInline.data_json?.filter_default_value || reduxAuth.settings?.user?.is_staff)) && (
                <TextInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label='filter_default_value'
                    name='filter_default_value'
                    onChange={handleInputChange}
                    type='number'
                    value={fieldsInline.data_json?.filter_default_value || ''}
                />
            )}
            {fieldsInline.layout?.id === 806 && (
                <CheckboxInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label={reduxText[8610]}
                    name='hide_google_button'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.hide_google_button || ''}
                />
            )}
            {fieldsInline.layout?.id === 779 && (
                <>
                    <TextInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='number_format'  // TO_TEXT
                        name='number_format'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.number_format || ''}
                    />
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='copyAction'
                        name='copyAction'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.copyAction || ''}
                    />
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='stylesExtraInstanceColor'
                        name='stylesExtraInstanceColor'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.stylesExtraInstanceColor || ''}
                    />
                </>
            )}
            {fieldsInline.layout?.id === 787 && (
                <SelectListInputV2
                    clearable
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label='iconFontLibrary'  // TO_TEXT
                    name='iconFontLibrary'
                    onChange={handleInputChange}
                    options={iconFontLibrary}
                    value={iconFontLibrary.find((obj) => obj.id === fieldsInline.data_json?.iconFontLibrary) || ''}
                />
            )}
            {fieldsInline.layout?.id === 819 && (
                <>
                    <TextInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='stripe_recurrent_product_id'  // TO_TEXT
                        name='stripe_recurrent_product_id'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.stripe_recurrent_product_id || ''}
                    />
                    <TextInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='stripe_coupon_id'  // TO_TEXT
                        name='stripe_coupon_id'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.stripe_coupon_id || ''}
                    />
                </>
            )}
            {fieldsInline.layout?.id === 780 && reduxAuth.settings?.user?.is_staff && (
                <CheckboxInputV2
                    disabled={reduxAuth.settings?.user?.id !== 1}
                    error={errorsInline?.data_json}
                    label='allowDownload'
                    name='allowDownload'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.allowDownload || ''}
                />
            )}
            {[779, 780].includes(fieldsInline.layout?.id!) && (
                <TextAreaInputV2
                    error={errorsInline?.data_json}
                    disabled={disabled || !reduxAuth.settings?.user?.is_staff}
                    label='hidden_if'
                    name='hidden_if'
                    noLineBreak
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.hidden_if || ''}
                />
            )}
            {fieldsInline.action?.id === 'href' && (
                <TextInputV2
                    disabled={disabled}
                    error={errorsInline?.data_json}
                    label='hrefTarget'  // TO_TEXT
                    name='hrefTarget'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.hrefTarget || ''}
                />
            )}
            {fieldsInline.layout?.id === 780 && (reduxAuth.settings?.user?.is_staff || fieldsInline.data_json?.anchorId) && (
                <TextInputV2
                    error={errorsInline?.data_json}
                    label='anchorId'
                    name='anchorId'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.anchorId || ''}
                />
            )}
            {fieldsInline.layout?.id === 815 && (
                <>
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='space_after_before_text'
                        name='space_after_before_text'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.space_after_before_text || ''}
                    />
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='space_before_after_text'
                        name='space_before_after_text'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.space_before_after_text || ''}
                    />
                </>
            )}
            {fieldsInline.layout?.id === 824 && (
                <>
                    <CheckboxInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='popupAutoOpen'
                        name='popupAutoOpen'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.popupAutoOpen || ''}
                    />
                    <TextInputV2
                        disabled={disabled}
                        error={errorsInline?.data_json}
                        label='popupAutoCloseAfter'
                        name='popupAutoCloseAfter'
                        onChange={handleInputChange}
                        type='number'
                        value={fieldsInline.data_json?.popupAutoCloseAfter || ''}
                    />
                </>
            )}
            {[95].includes(fieldsInline.content_source?.id!) && (
                <CheckboxInputV2
                    disabled={reduxAuth.settings?.user?.id !== 1}
                    error={errorsInline?.data_json}
                    label='moduleviewGetItems'
                    name='moduleviewGetItems'
                    onChange={handleInputChange}
                    value={fieldsInline.data_json?.moduleviewGetItems || ''}
                />
            )}
            {(fieldsInline.layout?.id === 811) && (
                <React.Fragment>
                    <TextInputV2
                        disabled={reduxAuth.settings?.user?.id !== 1}
                        error={errorsInline?.data_json}
                        label='collapsedIsOpenId'  // TO_TEXT
                        name='collapsedIsOpenId'
                        onChange={handleInputChange}
                        value={fieldsInline.data_json?.collapsedIsOpenId || ''}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    )
})
