// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// containers
import {
    TabListContainerMobile,
} from 'containers'

// data
import {
    api_url_event_list,
    api_url_material_list,
    api_url_offer_list,
    api_url_press_list,
    api_url_product_list,
    api_url_profile_list,
    api_url_project_list,
    api_url_resource_list,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export const SearchPageMobile: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const filterParamsApiUrl: any = {
        artworks: getApiUrl(api_url_project_list, reduxAuth),
        events: getApiUrl(api_url_event_list, reduxAuth),
        materials: getApiUrl(api_url_material_list, reduxAuth),
        offers: getApiUrl(api_url_offer_list, reduxAuth),
        press: getApiUrl(api_url_press_list, reduxAuth),
        products: getApiUrl(api_url_product_list, reduxAuth),
        profiles: getApiUrl(api_url_profile_list, reduxAuth),
        resources: getApiUrl(api_url_resource_list, reduxAuth),
    }

    return (
        <TabListContainerMobile
            filterParamsApiUrl={filterParamsApiUrl}
            playerOrigin='searchList'
            titleId={0}
        />
    )
}
