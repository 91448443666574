// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Popover,
    ProfileShortInfoWeb,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
} from 'data'

// pages
import {
    AdminMenuItem,
    AdminMenuWebsiteItem,
    DataMenuItem,
    DataMenuItem2,
} from 'pages'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getModalAbsoluteUrlV2,
    onClickIsModal,
    setStaffPermission,
} from 'services'

// props
type AdminMenuProps = {
    isStaff: boolean
    profileObject: ProfileDetailAdminSerializerV2
    refreshData: () => void
}

// main
export const AdminMenu: React.FC<AdminMenuProps> = React.memo(({
    isStaff,
    profileObject,
    refreshData,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    // const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type actionObjectType = {
        has_edit_permission?: boolean
        staff_permission?: boolean
    }

    const [buttons, setButtons] = useState<any[]>([])
    const [showSettings, setShowSettings] = useState(false)
    const [showSettingsPopover, setShowSettingsPopover] = useState(false)

    useEffect(() => {
        if (showSettingsPopover) getData(profileObject.id!)
    }, [
        profileObject.id,
        showSettingsPopover,
    ])

    const app = 'userprofile'
    const model = 'profile'

    async function getData(objectId: number) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AdminMenu getData')

            if (!objectId) return
            const getUrl = getApiUrlV2(`${app}/action/${model}/${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!response.data) return
                    const actionObject: actionObjectType = response.data
                    const newButtons = []

                    // Permission
                    if (actionObject.has_edit_permission) {
                        newButtons.push(
                            {
                                text: 'Add new section',  // TO_TEXT
                                handler: () => {
                                    dispatch(reduxModalMainFormShow({
                                        contentType: 'setup_module',
                                        cssClass: 'form-container-modal',
                                        formComponent: 'FormModal',
                                        objectId: 'new',
                                        profileObject,
                                        refreshData,
                                    }))
                                    setShowSettingsPopover(false)
                                }
                            },
                            {
                                text: 'Show Settings',  // TO_TEXT
                                handler: () => {
                                    setShowSettings(!showSettings)
                                    setShowSettingsPopover(false)
                                }
                            },
                            {
                                text: 'Edit profile',  // TO_TEXT
                                handler: () => {
                                    dispatch(reduxModalMainFormShow({
                                        contentType: 'userprofile_profile',
                                        cssClass: 'form-container-modal',
                                        formComponent: 'FormModal',
                                        objectId: profileObject.id!,
                                        profileObject,
                                        refreshData,
                                    }))
                                    setShowSettingsPopover(false)
                                }
                            },
                            {
                                text: 'View profile',  // TO_TEXT
                                to: getModalAbsoluteUrlV2({
                                    contentType: 'userprofile_profile',
                                    objectId: profileObject.id!,
                                    pageType: 'detail',
                                }),
                                handler: () => {
                                    onClickIsModal(profileObject.id!, Math.random(), dispatch)
                                    setShowSettingsPopover(false)
                                }
                            },
                        )
                    }

                    // Staff Permission
                    if (reduxAuth.settings?.user?.groups?.includes(8)) {
                        newButtons.push(
                            {
                                handler: () => {
                                    setStaffPermission({
                                        component: 'AdminMenu',
                                        contentTypeModel: 'userprofile_profile',
                                        dispatch,
                                        objectId: profileObject.id!,
                                        reduxAuth,
                                    })
                                    setShowSettingsPopover(false)
                                },
                                text: actionObject.staff_permission ? 'Remove staff permission' : 'Set staff permission',
                            },
                        )
                    }

                    setButtons(newButtons)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'AdminMenu',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminMenu',
                'getData',
            ))
        }
    }

    const hasSpecificModulesPermissions = reduxAuth.settings?.user?.specific_modules_permissions?.length! > 0

    return (
        <div className='admin-menu'>
            <div className='am-profile-img'>
                <ProfileShortInfoWeb
                    edit={false}
                    object={profileObject}
                />
                {reduxAuth.settings?.user?.id === 1 && (
                    <Popover
                        className='pdlw-size-icon'
                        buttons={buttons}
                        edit={false}
                        isOpen={showSettingsPopover}
                        setIsOpen={setShowSettingsPopover}
                    />
                )}
            </div>
            {!isStaff && !hasSpecificModulesPermissions && (
                <AdminMenuWebsiteItem profileObject={profileObject} />
            )}
            {isStaff && (
                profileObject.settings_data_staff_json?.modules?.map(val => (
                    <AdminMenuItem
                        key={val.id}
                        isStaff={isStaff}
                        object={val}
                        profileObject={profileObject}
                        refreshData={refreshData}
                        showSettings={showSettings}
                    />
                ))
            )}
            {!isStaff && !hasSpecificModulesPermissions && (
                profileObject.settings_data_json?.modules?.map(val => (
                    <AdminMenuItem
                        key={val.id}
                        isStaff={isStaff}
                        object={val}
                        profileObject={profileObject}
                        refreshData={refreshData}
                        showSettings={showSettings}
                    />
                ))
            )}
            {!isStaff && hasSpecificModulesPermissions && (
                profileObject.settings_data_json?.modules?.filter(obj => reduxAuth.settings?.user?.specific_modules_permissions?.flatMap(permission =>
                    permission.modules.map(module => module.id)
                ).includes(obj.id))?.map(val => (
                    <AdminMenuItem
                        key={val.id}
                        isStaff={isStaff}
                        object={val}
                        profileObject={profileObject}
                        refreshData={refreshData}
                        showSettings={showSettings}
                    />
                ))
            )}
            {!isStaff && reduxAuth.settings?.user?.is_staff && (
                <>
                    <div className='adcmt-tab-item separator'>
                        <p>Staff</p>
                    </div>
                    <DataMenuItem
                        profileObject={profileObject}
                    />
                    {[
                        'setup_moduleview',
                        'staff_staffproject',
                    ].map((val) => (
                        <DataMenuItem2
                            key={val}
                            // @ts-ignore
                            content_type_label_model={val}
                            profileObject={profileObject}
                        />
                    ))}
                </>
            )}
            {/* <div className='adcmt-tab-item separator'>
                <p>{reduxText[8775]}</p>
            </div>
            <LinkHelper
                className='adcmt-tab-item children'
                edit={false}
                to={`${view_url_profile_detail}${profileObject.id}/`}
            >
                {reduxText[8774]}
            </LinkHelper> */}
        </div>
    )
})
