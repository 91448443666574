// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    OfferDetailFormSerializer,
    OfferDetailFormSerializerError,
    OfferFormState,
} from 'serializers/web'

// props
type NameOfferInputProps = {
    errors: OfferDetailFormSerializerError | undefined
    fields: OfferDetailFormSerializer
    mother: OfferFormState['mother']
    onChange: any
}

// main
export const NameOfferInput: React.FC<NameOfferInputProps> = React.memo(({
    errors,
    fields,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            error={errors?.name}
            label={reduxText[428]}
            name='name'
            onChange={onChange}
            translation={{
                contentType: 'offer',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            value={fields.name || ''}
        />
    )
})
