// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    getApiUrlV2,
    getDetailObject,
    parseQuery,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type AgendaDuQuartierQuartierDeactivateProps = {
    stripe_invoice_id?: string
    // stripe_invoice_number: string
}

// main
export const AgendaDuQuartierQuartierDeactivate: React.FC<AgendaDuQuartierQuartierDeactivateProps> = React.memo(({
    stripe_invoice_id,
    // stripe_invoice_number,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    return (
        <div className='agenda-du-quartier-payment-success'>
            <div className='adgps-content'>
                <p className='adgps-title'> « Désactiver l’Agenda » et « Supprimer le compte »</p>
                <div className='adgps-button-wrap'>
                    <Button
                        disabled
                        edit={false}
                        text='Désactiver l’Agenda TODO'
                    />
                </div>
                <div className='adgps-button-wrap'>
                    <Button
                        disabled
                        edit={false}
                        text='Supprimer le compte TODO'
                    />
                </div>
            </div>
            {isLoading && <Loader isOpen />}
        </div>
    )
})
