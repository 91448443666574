export function getModalAbsoluteUrl(absoluteUrl: string) {
    if (!absoluteUrl) return
    let newabsoluteUrl = window.location.pathname
    const absoluteUrlSplit = absoluteUrl.split('/')
    if (['detail', 'form'].includes(absoluteUrlSplit[1])) {
        newabsoluteUrl += `?modal_page_type=${absoluteUrlSplit[1]}`
        newabsoluteUrl += `&modal_page=${absoluteUrlSplit[2]}`
        newabsoluteUrl += `&modal_detail_id=${absoluteUrlSplit[3]}`
    } else {
        newabsoluteUrl += `?modal_page=${absoluteUrlSplit[1]}`
        newabsoluteUrl += `&modal_detail_id=${absoluteUrlSplit[2]}`
    }
    return newabsoluteUrl
}
