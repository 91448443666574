// react components
import React from 'react'

// pages
import {
    LocationInputV2,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type GeopositionInputPageContentProps = {
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    inputObject: LayoutFieldHelperSerializer
    onHandleInputChange: any
}

// main
export const GeopositionInputPageContent: React.FC<GeopositionInputPageContentProps> = React.memo(({
    errorsInline,
    fieldsInline,
    inputObject,
    onHandleInputChange,
}) => {

    return (
        <LocationInputV2
            error={errorsInline?.address_json}
            helperText={inputObject.helper_text}
            label={inputObject.label}
            multi
            name='address_json'
            onChange={onHandleInputChange}
            value={fieldsInline.address_json || ''}
        />
    )
})
