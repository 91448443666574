// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// containers
import {
    MainFormContainerWrapper,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetAvailabitySite,
    reduxModalErrorEventHandler,
    reduxModalSetEditSite,
    select_url_portfolio_content_source_available,
} from 'data'

// pages
import {
    AssetFormState,
    AssetPanel,
    ContainerMixedContentPanel,
    ContainerMixedPanel,
    MainMemberPanel,
    MemberMotherFormState,
    MenuMemberPanel,
    ProjectMixedMediaContentFormState,
} from 'pages'

// serializers
import {
    MemberFormState,
} from 'serializers/web'

// services
import {
    autoSaveStyles,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailData,
    getDynamicTextOptions,
    getInputValue,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type LocationParams = {
    formRefresh?: number
}

// main
export const MemberForm: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<MemberFormState>({
        mother: MemberMotherFormState(reduxAuth),
        asset: AssetFormState(reduxAuth),
        mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)

    useEffect(() => {
        try {
            autoSaveStyles({
                component: 'MemberForm',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MemberForm',
                'useEffect',
            ))
        }
    }, [
        params.panelValue,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailData({
            axiosCancelToken,
            component: 'MemberForm',
            dispatch,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 33 && formState.mother.fields?.profile?.id) {
            getAvailabeContentSource()
        }
    }, [
        formState.mother.fields?.medium_type?.id,
        formState.mother.fields?.profile?.id,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 33) {
            dispatch(reduxModalSetEditSite(true))
            if (!reduxFormSite.dynamicTextOptions) {
                getDynamicTextOptions({
                    component: 'MemberForm',
                    dispatch,
                    reduxAuth,
                })
            }
        }
    }, [
        formState.mother.fields?.medium_type?.id,
    ])

    function setInitialState() {
        const mother = MemberMotherFormState(reduxAuth)
        setFormState({
            mother: {
                ...mother,
                fields: {
                    credit_type: {
                        id: 1,
                        name: 'member',
                    },
                    profile: !reduxAuth.settings?.user?.is_staff ? reduxAuth.settings?.active_profile : undefined,
                },
            },
            asset: AssetFormState(reduxAuth),
            mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
        })
    }

    async function getAvailabeContentSource() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MemberForm getAvailabeContentSource')
            const getUrl = getApiUrl(`${select_url_portfolio_content_source_available}?profile_id=${formState.mother.fields?.profile?.id}`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetAvailabitySite(response.data[0].available))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'MemberForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getAvailabeContentSource',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MemberForm',
                'getAvailabeContentSource',
            ))
        }
    }

    function handleInputChange(event: any, formInfo: any) {
        try {
            const mother = formState.mother
            if (event.name === 'name') {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        member: '',
                        name: event.value,
                    },
                    errors: {
                        ...mother.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        'profile',
                    ],
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (event.name === 'member') {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        member: event.value,
                        name: '',
                    },
                    errors: {
                        ...mother.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        'member',
                        'profile',
                    ],
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MemberForm',
                'handleInputChange',
            ))
        }
    }

    const panelValue = params.panelValue
    const panelText: any = {
        'asset-panel': reduxText[8149],
        'main-panel': reduxText[347],
    }

    const fieldsMother = formState.mother.fields

    return (
        <MainFormContainerWrapper
            formState={formState}
            headerTitle={panelValue ? panelText[panelValue] : ''}
            helpers={helpers}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {panelValue === 'main-panel' && (
                <MainMemberPanel
                    formState={formState}
                    handleInputChange={handleInputChange}
                />
            )}
            {panelValue === 'asset-panel' && fieldsMother?.id && (
                <AssetPanel
                    contentType='profileteam'
                    // @ts-ignore
                    formState={formState}
                    handleInputChange={handleInputChange}
                    helpers={helpers}
                    // @ts-ignore
                    setFormState={setFormState}
                    setHelpers={setHelpers}
                />
            )}
            {params.formType === 'mixed-media' && fieldsMother?.id && (
                <ContainerMixedPanel
                    contentType='profileteam'
                    // @ts-ignore
                    formState={formState}
                    handleInputChange={handleInputChange}
                    // @ts-ignore
                    setFormState={setFormState}
                />
            )}
            {params.formType === 'mixed-media-content' && fieldsMother?.id && (
                <ContainerMixedContentPanel
                    contentType='profileteam'
                    // @ts-ignore
                    formState={formState}
                    handleInputChange={handleInputChange}
                    helpers={helpers}
                    newMixedMediaContent={false}
                    newMixedMediaContentType={undefined}
                    // @ts-ignore
                    setFormState={setFormState}
                    setHelpers={setHelpers}
                />
            )}
            {!panelValue && (
                <MenuMemberPanel
                    formState={formState}
                    helpers={helpers}
                    setHelpers={setHelpers}
                />
            )}
        </MainFormContainerWrapper>
    )
}
