// react components
import React, {
    useState,
} from 'react'
import {
    IonPopover,
} from '@ionic/react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// data
import {
    contentSourceType,
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    AddToSectionForm,
    ConversionForm,
    CustomCategoryForm,
    CustomChoiceForm,
    TranslationForm,
} from 'pages'

// serializer
import {
    ConversionHelper,
    MainFontSerializer,
    MainIdNameSerializer,
    TranslationHelper,
} from 'serializers/web'

// props
type MainFormLabelProps = {
    adminOnly?: boolean
    ccKey?: number
    contentType?: 'profileteam'
    conversion?: ConversionHelper
    conversionDefaultValue?: string | number
    currencies?: MainIdNameSerializer[]
    fonts?: MainFontSerializer[]
    formInput?: 'address_json' | 'image' | 'image_crop' | 'pdf' | 'text' | 'textArea' | 'richText'
    helperText?: string
    hiddenInput?: boolean
    isColor?: boolean
    label: string
    labelOriginal?: string
    name: string
    onRefresh?: () => void
    optional?: boolean
    profileId?: number
    profileSectionContentSource?: contentSourceType
    profileSectionContentType?: contentTypeModelType
    profileSectionObjectId?: number
    profileSectionProfileSlug?: string
    setHiddenInput?: any
    setShowPassword?: React.Dispatch<boolean>
    showPassword?: boolean
    staffOnly?: boolean
    tinymceStylesObject?: any
    tinymceStylesString?: string
    translation?: TranslationHelper
    translationDefaultValue?: string | number
    value?: string | number
    valueIsInherit?: boolean
}

// main
export const MainFormLabel: React.FC<MainFormLabelProps> = React.memo(({
    adminOnly,
    ccKey,
    contentType,
    conversion,
    conversionDefaultValue,
    currencies,
    fonts,
    formInput,
    helperText,
    hiddenInput,
    isColor,
    label,
    labelOriginal,
    name,
    onRefresh,
    optional,
    profileId,
    profileSectionContentSource,
    profileSectionContentType,
    profileSectionObjectId,
    profileSectionProfileSlug,
    setHiddenInput,
    setShowPassword,
    showPassword,
    staffOnly,
    tinymceStylesObject,
    tinymceStylesString,
    translation,
    translationDefaultValue,
    value,
    valueIsInherit,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showAddToSection, setShowAddToSection] = useState(false)
    const [showConversion, setShowConversion] = useState(false)
    const [showCustomCategory, setShowCustomCategory] = useState(false)
    const [showCustomChoice, setShowCustomChoice] = useState(false)
    const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })
    const [showTranslation, setShowTranslation] = useState(false)

    return (
        <div className='main-form-label'>
            <label
                className='main-form-label-wrap'
                htmlFor={name}
            >
                <span
                    className={setHiddenInput ? 'mflw-label' : ''}
                    onClick={() => setHiddenInput ? setHiddenInput() : undefined}
                >
                    <span>{label}</span>
                    {labelOriginal && (
                        <span className='mflw-label-original-name'>{labelOriginal}</span>
                    )}
                </span>
            </label>
            <div className='main-form-helper-wrap'>
                {['custom_categories', 'custom_categories_2', 'custom_categories_3'].includes(name) && profileId && (
                    <span
                        className='main-form-label-translation'
                        onClick={() => setShowCustomCategory(true)}
                    >
                        {' '}
                        {reduxText[6581]}
                    </span>
                )}
                {['custom_roles'].includes(name) && contentType && profileId && (
                    <span
                        className='main-form-label-translation'
                        onClick={() => setShowCustomChoice(true)}
                    >
                        {' '}
                        {reduxText[6581]}
                    </span>
                )}
                {['profile_sections'].includes(name) && profileSectionProfileSlug && profileSectionContentType && profileSectionContentSource && (
                    <span
                        className='main-form-label-translation'
                        onClick={() => setShowAddToSection(true)}
                    >
                        {' '}
                        {reduxText[8016]}
                    </span>
                )}
                {conversion?.objectId && conversionDefaultValue && currencies?.length! > 0 && (
                    <span
                        className='main-form-label-translation'
                        onClick={() => setShowConversion(true)}
                    >
                        {' '}
                        {reduxText[8580]}
                    </span>
                )}
                {translation?.objectId && translationDefaultValue && (
                    <span
                        className='main-form-label-translation'
                        onClick={() => setShowTranslation(true)}
                    >
                        {' '}
                        {reduxText[6582]}
                    </span>
                )}
                {optional && (
                    <span className='main-form-label-optional'>
                        {' '}
                        {reduxText[5611]}
                    </span>
                )}
                {adminOnly && (
                    <span className='main-form-label-optional'>
                        {' '}
                        {reduxText[5339]}
                    </span>
                )}
                {staffOnly && (
                    <span className='main-form-label-optional'>
                        {' '}
                        {reduxText[5340]}
                    </span>
                )}
                {hiddenInput && value && (
                    <>
                        {isColor && (
                            <span
                                className='main-form-label-color-point'
                                onClick={() => setHiddenInput ? setHiddenInput() : undefined}
                                style={{
                                    // @ts-ignore
                                    backgroundColor: value
                                }}
                            >
                            </span>
                        )}
                        <span
                            className='main-form-label-value mo-clamp-web1'
                            onClick={() => setHiddenInput ? setHiddenInput() : undefined}
                        >
                            {value} {valueIsInherit ? '(i)' : ''}
                        </span>
                    </>
                )}
                <IonPopover
                    // @ts-ignore
                    cssClass='main-form-label-popover'
                    event={showPopover.event}
                    isOpen={showPopover.open}
                    onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
                >
                    <div className='mflp-header'>
                        <p className='mflp-title'>{label}</p>
                        {optional && <p className='mflp-optional'>{reduxText[5611]}</p>}
                        {adminOnly && <p className='mflp-staff'>{reduxText[5339]}</p>}
                        {staffOnly && <p className='mflp-staff'>{reduxText[5340]}</p>}
                    </div>
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                        <p className='mflp-text'>{helperText}</p>
                    </Linkify>
                </IonPopover>
                {setShowPassword && (
                    <i
                        className={`main-form-label-helper mr ${showPassword ? 'mo-new-icon-eye-slash-solid' : 'mo-new-icon-eye-solid'}`}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                )}
                {(helperText || (hiddenInput && value)) && (
                    <i
                        className={`main-form-label-helper mo-new-icon-question-circle-regular${!helperText ? ' hidden' : ''}`}
                        onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}
                    />
                )}
            </div>
            {showAddToSection && (
                <AddToSectionForm
                    contentType={profileSectionContentType!}
                    contentSource={profileSectionContentSource!}
                    objectId={profileSectionObjectId}
                    profileSlug={profileSectionProfileSlug!}
                    setIsOpen={setShowAddToSection}
                />
            )}
            {showConversion && (
                <ConversionForm
                    conversion={conversion!}
                    conversionDefaultValue={conversionDefaultValue!}
                    currencies={currencies!}
                    fieldName={name}
                    onRefresh={onRefresh}
                    setIsOpen={setShowConversion}
                />
            )}
            {showCustomCategory && (
                <CustomCategoryForm
                    ccKey={ccKey!}
                    profileId={profileId!}
                    setIsOpen={setShowCustomCategory}
                />
            )}
            {showCustomChoice && (
                <CustomChoiceForm
                    contentType={contentType!}
                    fieldName={name}
                    profileId={profileId!}
                    setIsOpen={setShowCustomChoice}
                />
            )}
            {showTranslation && (
                <TranslationForm
                    fieldName={name}
                    fonts={fonts}
                    formInput={formInput!}
                    onRefresh={onRefresh}
                    setIsOpen={setShowTranslation}
                    tinymceStylesObject={tinymceStylesObject}
                    tinymceStylesString={tinymceStylesString!}
                    translation={translation!}
                    translationDefaultValue={translationDefaultValue!}
                />
            )}
        </div>
    )
})
