// react components
import React from 'react'
import Input from 'react-phone-number-input/input'
import PhoneInput from 'react-phone-number-input'

// styles
import 'react-phone-number-input/style.css'

// props
type PhoneNumberHelperProps = {
    showFlag?: boolean
    value: string | undefined
}

// main
export const PhoneNumberHelper: React.FC<PhoneNumberHelperProps> = React.memo(({
    showFlag,
    value,
}) => {

    if (!value) return null

    if (showFlag) {
        return (
            <PhoneInput
                className='phone-number-helper'
                disabled
                onChange={() => undefined}
                value={value}
            />
        )
    }

    return (
        <Input
            className='phone-number-helper'
            disabled
            onChange={() => undefined}
            value={value}
        />
    )
})
