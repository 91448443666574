// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LocationInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ServiceDetailFormSerializer,
    ServiceDetailFormSerializerError,
    ServiceFormState,
} from 'serializers/web'

// props
type CityTempServiceInputProps = {
    disabled?: boolean
    errors: ServiceDetailFormSerializerError | undefined
    fields: ServiceDetailFormSerializer
    mother: ServiceFormState['mother']
    onChange: any
}

// main
export const CityTempServiceInput: React.FC<CityTempServiceInputProps> = React.memo(({
    disabled,
    errors,
    fields,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <LocationInput
            canGetProfileAddress
            disabled={disabled}
            error={errors?.address_json}
            label={reduxText[2299]}
            mother={mother}
            name='address_json'
            onChange={onChange}
            optional
            value={fields.address_json || ''}
        />
    )
})
