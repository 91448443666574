// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    RefresherEventDetail,
} from '@ionic/core'
import {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
} from '@ionic/react'
import {
    isMobileOnly,
} from 'react-device-detect'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    FooterMobile,
    HeaderChildren,
    HeaderMobile,
    Loader,
    MainDetailEditBlockWeb,
    ProfileShortInfoWeb,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    languageObjectSite,
    reduxModalErrorEventHandler,
    reduxModalPlayerCheckPosition,
    reduxModalSeoData,
    reduxModalSliderSetData,
} from 'data'

// pages
import {
    AdherentDetailBlock,
    BlockTemplateDetailBlock,
    DetailContent,
    EventDetailBlockMobile,
    EventDetailBlockWeb,
    EventImageDetailBlockMobile,
    EventImageDetailBlockWeb,
    MaterialDetailBlockMobile,
    MaterialDetailBlockWeb,
    MemberDetailBlock,
    NotFoundPage,
    OfferDetailBlockMobile,
    OfferDetailBlockWeb,
    PageTemplateDetailBlock,
    PortfolioDetailBlockWeb,
    ProductDetailBlockMobile,
    ProductDetailWrapper,
    ProfileDetailBlock,
    ProfileDetailBlockMobile,
    ProfileDetailBlockWebOld,
    ProjectDetailBlockMobile,
    ProjectDetailBlockWeb,
    SalesOrderDetailBlock,
    ServiceDetailBlock,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
}

type DetailPageContainerProps = {
    apiUrl: string
    app: string
    component: string
    componentActionSheet: string | undefined
    headerId?: string
    model: contentTypeModelType
}

// main
export const DetailPageContainer: React.FC<DetailPageContainerProps> = React.memo(({
    apiUrl,
    app,
    component,
    componentActionSheet,
    headerId,
    model,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxModalplayer = useSelector((state: defaultReduxState) => state.reduxModal.player)

    const [languageId, setLanguageId] = useState<number>()

    useEffect(() => {
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
        getData(params.id, languageId)
    }, [
        languageId,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshDetail) {
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            getData(params.id, languageId)
        }
    }, [
        reduxForm.refresh.refreshDetail,
    ])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [errorStatus, setErrorStatus] = useState<number>()
    const [isLoading, setIsLoading] = useState(false)
    const [modalKeys, setModalKeys] = useState<{
        [key: string]: number,
    }>({})
    const [object, setObject] = useState<any>('')

    const components: any = {
        AdherentDetailBlock,
        BlockTemplateDetailBlock,
        DetailContent,
        EventDetailBlockMobile,
        EventDetailBlockWeb,
        EventImageDetailBlockMobile,
        EventImageDetailBlockWeb,
        MaterialDetailBlockMobile,
        MaterialDetailBlockWeb,
        MemberDetailBlock,
        OfferDetailBlockMobile,
        OfferDetailBlockWeb,
        PageTemplateDetailBlock,
        PortfolioDetailBlockWeb,
        ProductDetailBlockMobile,
        ProductDetailWrapper,
        ProfileDetailBlock,
        ProfileDetailBlockMobile,
        ProfileDetailBlockWebOld,
        ProjectDetailBlockMobile,
        ProjectDetailBlockWeb,
        SalesOrderDetailBlock,
        ServiceDetailBlock,
    }
    const ComponentName = components[component]

    const contentRef = useRef<HTMLIonContentElement>(null)
    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageContainer',
                'scrollToTop',
            ))
        }
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
        try {
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            getData(params.id, languageId, event)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageContainer',
                'doRefresh',
            ))
        }
    }

    async function getData(objectId: string, languageId: number | undefined, refresh?: any) {
        try {
            if (!objectId) {
                setErrorStatus(404)
                return
            } else {
                setErrorStatus(undefined)
            }
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DetailPageContainer getData')
            setIsLoading(true)
            let getUrl = `${apiUrl}${objectId}/` // Keep the last '/' for Safari !!!!!!!
            if (languageId || languageObjectSite[reduxAuth.settings?.language!]) getUrl += `?language_id=${languageId || languageObjectSite[reduxAuth.settings?.language!]}`
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: app === 'hub' ? undefined : refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    scrollToTop()
                    setIsLoading(false)
                    if (refresh) {
                        refresh.detail.complete()
                    }
                    setObject(response.data)
                    dispatch(reduxModalSeoData(response.data.seo_data))
                    if (app === 'event') {
                        if (response.data.product_list?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.product_list))
                            setModalKeys({
                                ...modalKeys,
                                product_list: modalKey
                            })
                        }
                        if (response.data.project_list?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.project_list))
                            setModalKeys({
                                ...modalKeys,
                                project_list: modalKey
                            })
                        }
                        if (response.data.service_list?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.service_list))
                            setModalKeys({
                                ...modalKeys,
                                service_list: modalKey
                            })
                        }
                    } else if (app === 'product') {
                        if (response.data.related_projects?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.related_projects))
                            setModalKeys({
                                ...modalKeys,
                                related_projects: modalKey
                            })
                        }
                    } else if (app === 'project') {
                        if (reduxModalplayer.currentTrack?.id && response.data.id !== reduxModalplayer.currentTrack.id) {
                            dispatch(reduxModalPlayerCheckPosition(object.id, 'leave'))
                        }
                        if (response.data.related_products?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.related_products))
                            setModalKeys({
                                ...modalKeys,
                                related_products: modalKey
                            })
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (error.response?.status === 403) {
                        setErrorStatus(error.response.status)
                    } else if (error.response?.status === 404 && error.response?.data?.detail) {
                        setErrorStatus(error.response.status)
                    } else {
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component: 'DetailPageContainer',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'getData',
                        })
                    }
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageContainer',
                'getData',
            ))
        }
    }

    if (isMobileOnly) {

        if (errorStatus) {
            return <NotFoundPage statusCode={errorStatus} />
        }

        return (
            <IonPage>
                <HeaderMobile
                    id={headerId}
                    componentActionSheet={componentActionSheet}
                    detailObject={object}
                    refreshData={() => getData(params.id, languageId)}
                />
                <IonContent
                    forceOverscroll
                    fullscreen
                    ref={contentRef}
                >
                    <IonRefresher
                        slot='fixed'
                        onIonRefresh={doRefresh}
                    >
                        <IonRefresherContent />
                    </IonRefresher>
                    {object && (
                        <ComponentName
                            modalKeys={modalKeys}
                            object={object}
                        />
                    )}
                    <Loader isOpen={isLoading} />
                </IonContent>
                <FooterMobile />
            </IonPage>
        )
    }

    if (errorStatus) {
        return <NotFoundPage statusCode={errorStatus} />
    }

    return (
        <IonPage>
            <HeaderChildren>
                <div className='hcw-header-children-left'>
                    <ProfileShortInfoWeb
                        edit={false}
                        object={object.return_info || object.profile}
                    />
                </div>
                <IonTitle className='hcw-title'>{object.name}</IonTitle>
                <MainDetailEditBlockWeb
                    componentActionSheet={componentActionSheet}
                    model={model}
                    object={object}
                    refreshData={() => getData(params.id, languageId)}
                />
            </HeaderChildren>
            <IonContent
                className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}
                ref={contentRef}
            >
                {object && (
                    <ComponentName
                        contentType={model}
                        languageId={languageId}
                        modalKeys={modalKeys}
                        object={object}
                        setLanguageId={setLanguageId}
                    />
                )}
                <Loader isOpen={isLoading} />
            </IonContent>
        </IonPage>
    )
})
