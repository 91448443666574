// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'

// components
import {
    ListSpinner,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// props
type InfiniteScrollHelperWebProps = {
    active: boolean
    color?: 'tertiary' | 'primary' | 'secondary'
    endText?: boolean
    hasMore: boolean
    isLoading: boolean
    items: any[]
    noEndText?: boolean
    offset?: any
    onChange: (isVisible: boolean) => void
}

// main
export const InfiniteScrollHelperWeb: React.FC<InfiniteScrollHelperWebProps> = React.memo(({
    active,
    color,
    endText,
    hasMore,
    isLoading,
    items,
    noEndText,
    offset,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (hasMore && items.length > 0) {
        return (
            <VisibilitySensor
                active={active}
                intervalDelay={1000}
                offset={offset || { bottom: -1000 }}
                onChange={onChange}
                partialVisibility
            >
                <div className='infinite-scroll-helper-web-empty'>
                    <ListSpinner isLoading color={color || 'primary'} />
                </div>
            </VisibilitySensor>
        )
    }
    if (items.length === 0 && isLoading) {
        return (
            <div className='infinite-scroll-helper-web-empty'>
                <ListSpinner isLoading color={color || 'primary'} />
            </div>
        )
    }
    if (!noEndText && !hasMore) {
        if (endText) {
            return (
                <p className={`infinite-scroll-helper-web-no-more-text ${color || 'primary'}`}>{items.length === 0 ? reduxText[5599] : reduxText[5581]}</p>
            )
        }
        if (items.length === 0) {
            return (
                <p className={`infinite-scroll-helper-web-no-more-text ${color || 'primary'}`}>{reduxText[5599]}</p>
            )
        }
        return (
            <p className={`infinite-scroll-helper-web-no-more-text ${color || 'primary'}`} />
        )
    }
    return null
})
