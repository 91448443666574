// data
import {
    api_url_project_content_form_active,
    api_url_project_content_form_create,
    api_url_project_content_form_detail,
    api_url_project_content_form_list,
    api_url_project_content_form_patch,
    api_url_project_content_form_update,
    reduxAuthState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function ProjectContentFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'content',
        model: 'project_content',

        // Urls
        activeUrl: getApiUrl(api_url_project_content_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_project_content_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_project_content_form_detail, reduxAuth),
        listUrl: getApiUrl(api_url_project_content_form_list, reduxAuth),
        patchUrl: getApiUrl(api_url_project_content_form_patch, reduxAuth),
        updateUrl: getApiUrl(api_url_project_content_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: {},

        // Required fields are dynamic depending on the medium type of the mother
        requiredAllFields: [],
        requiredAttribute: [],
        requiredForeignKey: [],
        requiredManyToMany: [],

        // Form fields are dynamic depending on the project content type
        formAttribute: [],
        formFile: [],
        formForeignKey: [],
        formJson: [],
        formManyToMany: [],
    }
}
