// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'
import {
    ArrowContainer,
    Popover,
} from 'react-tiny-popover'

// components
import {
    Loader,
} from 'components'

// data
import {
    contentTypeData,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalLayoutInputShow,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    AddContentToFavoriteHelper,
    AddToBlockTemplateHelper,
    CopyContentHelper,
    CopyStyleHelper,
    DeleteContentHelper,
    DuplicateContentHelper,
    EditContentHelper,
    MoveContentHelper,
    PublishContentHelper,
} from 'pages'

// serializers
import {
    BlockTemplateSelectSerializer,
} from 'serializers/web'
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    BuilderService,
    addBlockContent,
} from 'services'

// props
type MatchParams = {
    id: string
    contentId: string
    detailId: string
    pageSlug: string
    panelValue: string
}

type EditButtonPageContentProps = {
    allowDrop?: boolean
    children: any
    isNormal?: boolean
    isPopoverOpen: boolean
    object: PortfolioPageContentListSiteSerializer
    setIsPopoverOpen: React.Dispatch<boolean>
}

// main
export const EditButtonPageContent: React.FC<EditButtonPageContentProps> = React.memo(({
    allowDrop,
    children,
    isNormal,
    isPopoverOpen,
    object,
    setIsPopoverOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [copyContentDirection, setCopyContentDirection] = useState<'after' | 'before' | 'inside'>()
    const [isLoading, setIsLoading] = useState(false)
    const [showAddBlockToTemplate, setShowAddBlockToTemplate] = useState(false)
    const [showAddContentToFavorite, setShowAddContentToFavorite] = useState(false)
    const [showCopyContent, setShowCopyContent] = useState(false)
    const [showDeleteContent, setShowDeleteContent] = useState(false)
    const [showDuplicateContent, setShowDuplicateContent] = useState(false)

    let editUrl = `${view_url_portfolio_form}${params.id}/content-container/${object.id}/`
    if (params.pageSlug) {
        editUrl += `${params.pageSlug}/`
    } else {
        editUrl += `${reduxFormSite.portfolioObject?.home_page_slug}/`
    }
    if (params.detailId) editUrl += `${params.detailId}/`

    function onAddBlock(direction: 'after' | 'before') {
        setIsPopoverOpen(false)
        dispatch(reduxModalLayoutInputShow(
            handleSubmitNewBlock,
            false,
            true,
            direction,
        ))
    }

    function handleSubmitNewBlock(
        submitLayout: BlockTemplateSelectSerializer,
        special: 'symbol',
        direction: 'after' | 'before',
    ) {
        try {
            addBlockContent({
                child: false,
                contentType: undefined,
                direction,
                dispatch,
                formUrl: view_url_portfolio_form,
                history,
                mainParentId: undefined,
                mixedMedia: false,
                newMixedMediaContent: false,
                newMixedMediaContentType: undefined,
                object,
                paramsDetailId: params.detailId,
                paramsId: params.id,
                paramsPageSlug: params.pageSlug,
                reduxAuth,
                reduxFormSite,
                setIsLoading,
                setIsPopoverOpen,
                special,
                submitLayout,
            })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'EditButtonPageContent',
                'handleSubmitNewBlock',
            ))
        }
    }

    function PopoverContent() {
        const contentToCopyObject = BuilderService.copyGetContentObject()
        const styleToCopyObject = BuilderService.copyGetStyleObject()
        return (
            <div className='selection-helper-page-content-web-popover popover-edit-button-page-content-web'>
                <div className='shpcwp-item'>
                    <EditContentHelper
                        child={false}
                        editUrl={editUrl}
                        mixedMedia={false}
                        object={object}
                        setIsPopoverOpen={setIsPopoverOpen}
                    />
                </div>
                <div className='shpcwp-item-layout-input'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => onAddBlock('after')}
                    >
                        <i className='mo-new-icon-plus-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[7934]}</span>
                    </button>
                    <div className='shpcwp-item-button-wrap'>
                        <div className='shpcwp-item-button-wrap2'>
                            <button
                                className='shpcwp-item-button1'
                                title={reduxText[7930]}
                                onClick={() => onAddBlock('before')}
                            >
                                <i className='mo-new-icon-chevron-up-solid' />
                            </button>
                        </div>
                        <div className='shpcwp-item-button-wrap2'>
                            <button
                                className='shpcwp-item-button2'
                                title={reduxText[7931]}
                                onClick={() => onAddBlock('after')}
                            >
                                <i className='mo-new-icon-chevron-down-solid' />
                            </button>
                        </div>
                    </div>
                </div>
                <MoveContentHelper
                    allowDrop={allowDrop}
                    isNormal={isNormal}
                    mixedMedia={false}
                    object={object}
                    setIsPopoverOpen={setIsPopoverOpen}
                />
                <div className='shpcwp-item bt2'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => {
                            setIsPopoverOpen(false)
                            BuilderService.copySetContentObject(object!, contentTypeData['pagecontent'], reduxFormSite.portfolioObject?.is_multi_language!)
                        }}
                    >
                        <i className='mo-new-icon-copy-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[8491]}</span>
                    </button>
                </div>
                {contentToCopyObject && (
                    <CopyContentHelper
                        isButton
                        isNormal={(contentToCopyObject.id === object.id) || isNormal}
                        mixedMedia={false}
                        object={object}
                        setDirection={setCopyContentDirection}
                        setIsOpen={setShowCopyContent}
                        setIsPopoverOpen={setIsPopoverOpen}
                    />
                )}
                <div className='shpcwp-item multi-button bt2'>
                    <div className='shpcwp-icon-wrap'>
                        <i className='mo-new-icon-clone-solid' />
                    </div>
                    <div className='shpcwp-multi-button-wrap'>
                        <DuplicateContentHelper
                            contentType='page'
                            defaultPortfolio={reduxFormSite.portfolioObject}
                            newMixedMediaContent={false}
                            newMixedMediaContentType={undefined}
                            isButton
                            objectId={object.id!}
                            profile={reduxFormSite.portfolioObject?.profile!}
                            setIsPopoverOpen={setIsPopoverOpen}
                        />
                        <button
                            className='shpcwp-item-button'
                            onClick={() => {
                                setIsPopoverOpen(false)
                                setShowDuplicateContent(true)
                            }}
                        >
                            <span className='shpcwp-item-button-text'>{reduxText[8190]}</span>
                        </button>
                    </div>
                </div>
                <div className='shpcwp-item bt2'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => {
                            setIsPopoverOpen(false)
                            BuilderService.copySetStyleObject(object!, contentTypeData['pagecontent'])
                        }}
                    >
                        <i className='mo-new-icon-paint-roller-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[7686]}</span>
                    </button>
                </div>
                {(styleToCopyObject && (styleToCopyObject?.layout === object?.layout) && (styleToCopyObject?.id !== object?.id)) && (
                    <CopyStyleHelper
                        mixedMedia={false}
                        object={object}
                        setIsPopoverOpen={setIsPopoverOpen}
                    />
                )}
                <div className='shpcwp-item bt2'>
                    <PublishContentHelper
                        child={false}
                        mixedMedia={false}
                        object={object}
                        setIsPopoverOpen={setIsPopoverOpen}
                    />
                </div>
                <div className='shpcwp-item'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => {
                            setIsPopoverOpen(false)
                            setShowDeleteContent(true)
                        }}
                    >
                        <i className='mo-new-icon-trash-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[2983]}</span>
                    </button>
                </div>
                {/* <div className='shpcwp-item bt2'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => {
                            setIsPopoverOpen(false)
                            setShowAddContentToFavorite(true)
                        }}
                    >
                        <i className='mo-new-icon-star-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[2096]}</span>
                    </button>
                </div> */}
                {[1, 5, 68].includes(reduxAuth.settings?.user?.id!) && (
                    <React.Fragment>
                        <div className='shpcwp-item bt3'>
                            <button
                                className='shpcwp-item-button'
                                onClick={() => {
                                    setIsPopoverOpen(false)
                                    setShowAddBlockToTemplate(true)
                                }}
                            >
                                <i className='mo-new-icon-staff-pick' />
                                <span className='shpcwp-item-button-text'>{reduxText[8681]}</span>
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
            <Popover
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        arrowColor={reduxAuth.settings?.theme === 'light' ? '#202020' : '#ffffff'}
                        arrowSize={10}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        position={position}
                    >
                        <PopoverContent />
                    </ArrowContainer>
                )}
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                positions={['bottom', 'top', 'right', 'left']}
            >
                {children}
            </Popover>
            {showAddBlockToTemplate && (
                <AddToBlockTemplateHelper
                    mixedMedia={false}
                    objectId={object.id!}
                    setIsOpen={setShowAddBlockToTemplate}
                    setIsPopoverOpen={setIsPopoverOpen}
                />
            )}
            {showAddContentToFavorite && (
                <AddContentToFavoriteHelper
                    contentType='mixedmediacontent'
                    objectId={object.id!}
                    profileId={reduxFormSite.portfolioObject?.profile?.id!}
                    setIsOpen={setShowAddContentToFavorite}
                />
            )}
            {showCopyContent && (
                <CopyContentHelper
                    direction={copyContentDirection}
                    isNormal={isNormal}
                    isOpen={showCopyContent}
                    mixedMedia={false}
                    object={object}
                    setDirection={setCopyContentDirection}
                    setIsOpen={setShowCopyContent}
                    setIsPopoverOpen={setIsPopoverOpen}
                />
            )}
            {showDeleteContent && (
                <DeleteContentHelper
                    child={false}
                    formUrl={view_url_portfolio_form}
                    homePageSlug={reduxFormSite.portfolioObject?.home_page_slug}
                    mixedMedia={false}
                    newMixedMediaContent={false}
                    newMixedMediaContentType={undefined}
                    objectId={object.id!}
                    setIsOpen={setShowDeleteContent}
                />
            )}
            {showDuplicateContent && (
                <DuplicateContentHelper
                    contentType='page'
                    defaultPortfolio={reduxFormSite.portfolioObject}
                    newMixedMediaContent={false}
                    newMixedMediaContentType={undefined}
                    objectId={object.id!}
                    profile={reduxFormSite.portfolioObject?.profile!}
                    setIsOpen={setShowDuplicateContent}
                />
            )}
            {isLoading && (
                <Loader
                    isOpen
                    message={reduxText[6437]}
                />
            )}
        </React.Fragment>
    )
})
