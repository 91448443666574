// react components
import React, {
    useEffect,
    useState,
} from 'react'
import moment from 'moment'
import Linkify from 'react-linkify'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    CountViewBlock,
    DescriptionDetailBlock,
    ImageHelper,
    LightboxCustom,
    LinkHelper,
    LocationDetailBlock,
    MainCountryFlag,
    MainHashtag,
    TranslationHelper,
} from 'components'

// containers
import {
    DetailListContainerNew,
} from 'containers'

// data
import {
    api_url_profile_image_list,
    defaultReduxState,
    media_url_social_network,
    media_version,
    searchTabValue,
    view_url_profile_detail_old,
} from 'data'

// pages
import {
    MixedContentBlock,
} from 'pages'

// serializers
import {
    MainTabHelperType,
    ProfileDetailSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getCleanUrl,
    getDateWithType,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type ProfileDetailBlockProps = {
    edit: boolean
    inModal?: boolean
    languageId: number | undefined
    modalKeys: { [key: string]: number }
    object: ProfileDetailSerializer
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const ProfileDetailBlock: React.FC<ProfileDetailBlockProps> = React.memo(({
    edit,
    inModal,
    languageId,
    modalKeys,
    object,
    setLanguageId,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)

    useEffect(() => {
        setTabValue({
            id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
            title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
        })
    }, [
        object?.id,
    ])

    const [tabValue, setTabValue] = useState<MainTabHelperType>({
        id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
        title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
    })

    const tabArray: MainTabHelperType[] = []
    if (object.medium_type?.is_custom_page || object.has_images) {
        tabArray.push(
            {
                id: 'cover',
                title: reduxText[4359],
            },
        )
    }
    if (object.medium_type?.is_custom_page) {
        tabArray.push(
            {
                id: 'custom-page',
                title: reduxText[8489],
            },
        )
    }
    if (object.has_images) {
        tabArray.push(
            {
                id: 'images',
                isGallery: true,
                listApiUrl: getApiUrl(`${api_url_profile_image_list}?profile_id=${object.id}`, reduxAuth),
                title: reduxText[1625],
            },
        )
    }

    const hasTabs = !edit && tabArray.length > 0

    // custom objects
    const isStaff = reduxAuth.settings?.user?.is_staff

    const socialLinks: ProfileDetailSerializer['eclink_list'] = []
    const websiteLinks: ProfileDetailSerializer['eclink_list'] = []
    object.eclink_list?.map((val) => {
        if (val.social_network === 5) {
            websiteLinks.push(val)
        } else {
            socialLinks.push(val)
        }
    })

    return (
        <div className={`main-detail-block profile-detail-block${tabValue.id === 'custom-page' ? ' medium-type-custom' : ''}${edit ? ' edit' : ''}`}>
            <div className={`mdb-left${hasTabs ? ' tabs' : ''}${tabValue.tableHeaderComponent ? ' table' : ''}`}>
                {hasTabs && (
                    <div className='mdb-tabs'>
                        {tabArray.map(val => (
                            <div
                                key={val.id}
                                className={`mdb-buttons${val.id === tabValue.id ? ' active' : ''}`}
                                onClick={() => setTabValue(val)}
                            >
                                <span className='mdb-buttons-name'>{val.title}</span>
                            </div>
                        ))}
                    </div>
                )}
                {(tabValue.listComponent || tabValue.isGallery)
                    ? (
                        <DetailListContainerNew
                            isGallery={tabValue.isGallery}
                            inModal={inModal}
                            listApiUrl={tabValue.listApiUrl!}
                            listComponent={tabValue.listComponent}
                            tableHeaderComponent={tabValue.tableHeaderComponent}
                        />
                    ) : (
                        <React.Fragment>
                            {tabValue.id === 'custom-page'
                                ? (
                                    <MixedContentBlock
                                        contentType='profile'
                                        edit={edit}
                                        hasTabs={hasTabs}
                                        languageId={languageId}
                                        object={object}
                                    />
                                ) : (
                                    <ImageHelper
                                        alt={object.name}
                                        className='mdb-cover-image'
                                        dominant_color={object.get_dominant_color}
                                        lightBox
                                        lightBoxSrc={object.get_image_hq}
                                        src={object.get_image_hq}
                                    />
                                )
                            }
                        </React.Fragment>
                    )
                }
            </div>
            <div className={`mdb-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='pfdb-profile-image-wrap'>
                    {/* {object.get_image_lq
                        ? (
                            <ImageHelper
                                alt={object.name}
                                className='pfdb-profile-image'
                                dominant_color={object.get_dominant_color}
                                lightBox
                                lightBoxSrc={object.get_image_hq}
                                src={object.get_image_lq}
                            />
                        ) : (
                            <RatioZarmingHelper
                                borderRadius='5%'
                                dominant_color={object.get_dominant_color}
                                edit={edit}
                                fontSize={24}
                                height={97}
                                name={object.name}
                                notZarma={false}
                                onClick={setIsLightBoxOpen(true)}
                                src={object.get_image_lq}
                                to={undefined}
                            />
                        )
                    }
                    {object.cover_image_credits && object.cover_image_credits.length > 0 && (
                        <div className='pfdb-profile-image-credit-wrap'>
                            <span className='pfdb-profile-image-credit tag'>� </span>
                            {object.cover_image_credits.map((val, i) => (
                                <React.Fragment key={val.id}>
                                    <LinkHelper
                                        edit={edit}
                                        className='pfdb-profile-image-credit'
                                        to={val.member?.get_absolute_url}
                                    >
                                        {val.member?.name || val.name}
                                    </LinkHelper>
                                    {object.cover_image_credits && object.cover_image_credits.length > (i + 1) && (<span className='pfdb-profile-image-credit'> / </span>)}
                                </React.Fragment>
                            ))}
                        </div>
                    )} */}
                    <p className={`pfdb-name${languageId === 19 ? ' rtl' : ''}`}>{object.name}</p>
                    {websiteLinks.length > 0 && (
                        <div className='pfdb-website-wrap'>
                            {websiteLinks.map((val) => (
                                <LinkHelper
                                    key={val.id}
                                    edit={edit}
                                    className='pfdb-website'
                                    href={val.link_url}
                                >
                                    {getCleanUrl(val.link_url!)}
                                </LinkHelper>
                            ))}
                        </div>
                    )}
                    {object.profile_created_by && (
                        <div className='pfdb-profile-created-by'>
                            <span>{reduxText[4834]} </span>
                            <LinkHelper
                                edit={edit}
                                to={object.profile_created_by.get_absolute_url}
                            >
                                {object.profile_created_by.name}
                            </LinkHelper>
                        </div>
                    )}
                </div>
                {object.translation_languages?.length! && (
                    <TranslationHelper
                        edit={edit}
                        languageId={languageId}
                        languages={object.translation_languages}
                        setLanguageId={setLanguageId}
                    />
                )}
                <DescriptionDetailBlock text={object.metiers} />
                <LocationDetailBlock
                    address_json={object.address_json}
                    edit={edit}
                    object={object}
                    reduced
                    searchTab={searchTabValue.profile}
                />
                {object.countries_2?.length! > 0 && (
                    <DescriptionDetailBlock>
                        <div className='pfdb-countries'>
                            {object.countries_2?.map((val) => (
                                <MainCountryFlag
                                    key={val.id}
                                    edit={edit}
                                    namePosition='end'
                                    object={val}
                                />
                            ))}
                        </div>
                    </DescriptionDetailBlock>
                )}
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.description}
                />
                {socialLinks.length > 0 && (
                    <DescriptionDetailBlock>
                        <div className='pfdb-social-wrap'>
                            {socialLinks.map((val) => (
                                <div
                                    key={val.id}
                                    className='pfdb-social'
                                >
                                    {val.social_network === 2
                                        ? (
                                            <a
                                                href={`mailto:${val.link_url}`}
                                                rel='noopener noreferrer'
                                                target='_blank'
                                            >
                                                <i className='icon mo-new-icon-envelope-regular' />
                                            </a>
                                        ) : (
                                            <LinkHelper
                                                key={val.id}
                                                edit={edit}
                                                href={val.link_url}
                                                hrefTargetSystem={val.social_network !== 5}
                                            >
                                                <ImageHelper
                                                    alt=''
                                                    className='pfdb-social-image'
                                                    dominant_color={undefined}
                                                    src={`${media_url_social_network}${val.social_network}_${reduxAuth.settings?.theme === 'light' ? 'black' : 'white'}.svg${media_version}`}
                                                />
                                            </LinkHelper>
                                        )}
                                </div>
                            ))}
                        </div>
                    </DescriptionDetailBlock>
                )}
                <DescriptionDetailBlock
                    languageId={languageId}
                    text={object.phone_number}
                    title={reduxText[4605]}
                />
                {(object.birth_date && object.death_date) && (
                    <DescriptionDetailBlock
                        languageId={languageId}
                        text={`${moment(object.birth_date).format('D MMM YYYY')} - ${moment(object.death_date).format('D MMM YYYY')}`}
                        title={`${reduxText[3025]} - ${reduxText[3467]}`}
                    />
                )}
                {(object.birth_date && !object.death_date) && (
                    <DescriptionDetailBlock
                        languageId={languageId}
                        text={moment(object.birth_date).format('D MMM YYYY')}
                        title={reduxText[3025]}
                    />
                )}
                {(!object.birth_date && object.death_date) && (
                    <DescriptionDetailBlock
                        languageId={languageId}
                        text={moment(object.death_date).format('D MMM YYYY')}
                        title={reduxText[3092]}
                    />
                )}
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.biography}
                    title={reduxText[6485]}
                />
                <LocationDetailBlock
                    address_json={object.address_json}
                    edit={edit}
                    object={object}
                    full
                    searchTab={searchTabValue.profile}
                />
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.custom_text}
                    title={reduxText[6574]}
                />
                <DescriptionDetailBlock
                    languageId={languageId}
                    isRich
                    text={object.custom_text_rich}
                    title={reduxText[7685]}
                />
                {object.custom_categories?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[5569]}>
                        {object.custom_categories?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.custom_categories_2?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[8723]}>
                        {object.custom_categories_2?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.custom_categories_3?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[8731]}>
                        {object.custom_categories_3?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.custom_date && (
                    <DescriptionDetailBlock
                        text={getDateWithType(reduxAuth, object.custom_date, 'd')}
                        title={reduxText[8732]}
                    />
                )}
                {object.custom_date_2 && (
                    <DescriptionDetailBlock
                        text={getDateWithType(reduxAuth, object.custom_date_2, 'd')}
                        title={reduxText[8734]}
                    />
                )}
                {object.member_info?.length! > 0 && object.member_info?.map(val => (
                    <DescriptionDetailBlock
                        key={val.id}
                        title={`${reduxText[8520]} ${val.profile?.name}`}
                    >
                        <div className='pfdb-member-info-wrap'>
                            <div className='pfdb-member-button-wrap'>
                                <Button
                                    edit={edit}
                                    fill='outline'
                                    onClick={() => onClickIsModal(val.id!, modalKeys.member_info, dispatch)}
                                    size='x-small'
                                    text={reduxText[8489]}
                                    to={getModalAbsoluteUrl(val.get_absolute_url!)}
                                />
                            </div>
                            <p className='pfdb-member-info-title'>{reduxText[7726]}</p>
                            <LinkHelper
                                className={`main-hashtag${edit ? ' edit' : ''}`}
                                edit={edit}
                                to={`${view_url_profile_detail_old}${val.profile?.slug}/sections/${val.other_info?.profile_section}/`}
                            >
                                {reduxText[3077]}
                            </LinkHelper>
                            {val.other_info?.profile_sections?.map((val2) => (
                                <LinkHelper
                                    key={val2.id}
                                    className={`main-hashtag${edit ? ' edit' : ''}`}
                                    edit={edit}
                                    to={`${view_url_profile_detail_old}${val.profile?.slug}/sections/${val2.id}/${val2.main_parent ? `?main-parent=${val2.main_parent}` : ''}${val2.parent ? `&parent=${val2.parent}` : ''}`}
                                >
                                    {val2.name}
                                </LinkHelper>
                            ))}
                        </div>
                        {val.roles && (
                            <div className='pfdb-member-info-wrap'>
                                <p className='pfdb-member-info-title'>{reduxText[2979]}</p>
                                <MainHashtag
                                    edit={edit}
                                    label={val.roles}
                                    searchTab={searchTabValue.profile}
                                />
                            </div>
                        )}
                        {val.custom_roles?.length! > 0 && (
                            <div className='pfdb-member-info-wrap'>
                                <p className='pfdb-member-info-title'>{reduxText[8331]}</p>
                                {val.custom_roles?.map((val2) => (
                                    <MainHashtag
                                        key={`metiers-${val2.id}`}
                                        edit={edit}
                                        label={val2.name}
                                        searchTab={searchTabValue.profile}
                                    />
                                ))}
                            </div>
                        )}
                        {val.custom_categories?.length! > 0 && (
                            <div className='pfdb-member-info-wrap'>
                                <p className='pfdb-member-info-title'>{reduxText[5569]}</p>
                                {val.custom_categories?.map((val2) => (
                                    <MainHashtag
                                        key={`metiers-${val2.id}`}
                                        edit={edit}
                                        label={val2.name}
                                        searchTab={searchTabValue.profile}
                                    />
                                ))}
                            </div>
                        )}
                        {val.overwrite_custom_text && (
                            <div className='pfdb-member-info-wrap'>
                                <p className='pfdb-member-info-title'>{reduxText[4596]}</p>
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a target='blank' href={decoratedHref} key={key}>{decoratedText}</a>}>
                                    <p className='pfdb-member-description pre-line'>{val.overwrite_custom_text}</p>
                                </Linkify>
                            </div>
                        )}
                        {val.custom_text_rich && (
                            <div className='pfdb-member-info-wrap'>
                                <p className='pfdb-member-info-title'>{reduxText[7685]}</p>
                                <div dangerouslySetInnerHTML={{ __html: val.custom_text_rich }} />
                            </div>
                        )}
                    </DescriptionDetailBlock>
                ))}
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
                {!edit && isStaff && (
                    <div className='pfdb-last-login'>
                        <span>{reduxText[5398]} {getDateWithType(reduxAuth, object.owner_info?.last_login, 'n')}</span>
                        <span>{reduxText[5493]} {getDateWithType(reduxAuth, object.owner_info?.created_on, 'd')}</span>
                        <span>{object.owner_info?.user_language}</span>
                    </div>
                )}
                {isLightBoxOpen && object.get_image_hq && (
                    <LightboxCustom
                        mainSrc={object.get_image_hq}
                        onCloseRequest={() => setIsLightBoxOpen(false)}
                    />
                )}
            </div>
        </div>
    )
})
