// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    CheckboxInput,
    ImageCropInput,
    PortfolioDetailPageFieldInput,
    RichTextInput,
    SelectAsyncInput,
    SelectListInput,
    TextAreaInput,
    TextInput,
} from 'components'

// data
import {
    contentTypeData,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    // select_url_main_custom_category,
    select_url_main_custom_choice,
    select_url_main_medium_type_profile,
    select_url_profile,
    view_url_member_form,
    view_url_profile_form,
} from 'data'

// pages
import {
    AssetFormState,
    MemberMotherFormState,
    ProjectMixedMediaContentFormState,
} from 'pages'

// serializers
import {
    MemberFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getDetailData,
    getFormData,
    getInputValue,
    getMainErrorMessage,
} from 'services'

// props
type MatchParams = {
    id: string
}

type LocationParams = {
    formRefresh?: number
}

type MemberProfilePanelProps = {
    objectId: string
}

// main
export const MemberProfilePanel: React.FC<MemberProfilePanelProps> = React.memo(({
    objectId,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<MemberFormState>({
        mother: MemberMotherFormState(reduxAuth),
        asset: AssetFormState(reduxAuth),
        mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)

    useEffect(() => {
        if (!objectId) return
        getDetailData({
            axiosCancelToken,
            component: 'MemberProfilePanel',
            dispatch,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: false,
            objectId: objectId,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        objectId,
    ])

    function setInitialState() {
        const mother = MemberMotherFormState(reduxAuth)
        setFormState({
            mother: {
                ...mother,
                fields: {
                    credit_type: {
                        id: 1,
                        name: 'member',
                    },
                    profile: !reduxAuth.settings?.user?.is_staff ? reduxAuth.settings?.active_profile : undefined,
                },
            },
            asset: AssetFormState(reduxAuth),
            mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
        })
    }

    function handleInputChange(event: any) {
        try {
            const mother = formState.mother
            if (event.name === 'name') {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        member: '',
                        name: event.value,
                    },
                    errors: {
                        ...mother.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        'profile',
                    ],
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (event.name === 'member') {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        member: event.value,
                        name: '',
                    },
                    errors: {
                        ...mother.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        'member',
                        'profile',
                    ],
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else {
                setFormState({
                    ...formState,
                    mother: getInputValue({
                        event,
                        formInfo: mother,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MemberProfilePanel',
                'handleInputChange',
            ))
        }
    }

    function handleRequired(stay: boolean) {
        try {
            const result = checkRequiredFields({
                formInfoObject: mother,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit(stay)
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    mother: {
                        ...formState.mother,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MemberProfilePanel',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit(stay: boolean) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MemberProfilePanel handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            const putUrl = `${mother.updateUrl}${mother.fields?.id}/`
            const formData: any = getFormData(mother)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: false,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    if (!stay) {
                        history.push(`${view_url_profile_form}${params.id}/`)
                    }
                    // @ts-ignore
                    dispatch(reduxFormSetRefresh('refreshForm', params.id))
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'MemberProfilePanel',
                        dispatch,
                        error,
                        formFields: JSON.stringify(mother.fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'MemberProfilePanel',
                'handleSubmit',
            ))
        }
    }

    const mother = formState.mother
    const fieldsMother = mother.fields
    const errorsMother = mother.errors

    if (!fieldsMother) return null

    let tinymceStylesString = ''
    const tinymceStylesObject: any = {}
    if (fieldsMother.portfolio_main?.styles) {
        Object.getOwnPropertyNames(fieldsMother.portfolio_main.styles.value).map((val: any) => {
            if (val === 'fontFamily') {
                if (fieldsMother.portfolio_main!.styles!.value[val]) {
                    tinymceStylesString += `font-family: ${fieldsMother.portfolio_main!.styles!.value[val]};`
                }
            }
            if ([
                'fontFamily',
            ].includes(val) && fieldsMother.portfolio_main!.styles!.value[val]) {
                tinymceStylesObject[val] = fieldsMother.portfolio_main!.styles!.value[val]
            }
            return false
        })
    }

    return (
        <div className='mfcw-member-form'>
            <div className='mfcw-member-form-fields mo-hidden-horizontal-scrollbar'>
                {reduxAuth.settings?.user?.is_staff && (
                    <SelectAsyncInput
                        apiUrl={select_url_profile}
                        clearable={false}
                        disabled={Boolean(fieldsMother.id)}
                        error={errorsMother?.profile}
                        label={reduxText[1260]}
                        layout='profile'
                        name='profile'
                        onChange={handleInputChange}
                        staffOnly
                        value={fieldsMother.profile || ''}
                    />
                )}
                {fieldsMother.id
                    ? (
                        <React.Fragment>
                            {fieldsMother.member
                                ? (
                                    <SelectAsyncInput
                                        apiUrl={`${select_url_profile}?credit=${fieldsMother.profile?.id}`}
                                        clearable={false}
                                        disabled
                                        error={errorsMother?.member}
                                        label={reduxText[5559]}
                                        layout='profile'
                                        name='member'
                                        onChange={handleInputChange}
                                        team
                                        value={fieldsMother.member || ''}
                                    />
                                ) : (
                                    <TextInput
                                        error={errorsMother?.name}
                                        label={reduxText[5559]}
                                        name='name'
                                        onChange={handleInputChange}
                                        value={fieldsMother.name || ''}
                                    />
                                )
                            }
                        </React.Fragment>
                    ) : (
                        <SelectAsyncInput
                            apiUrl={`${select_url_profile}?credit=${fieldsMother.profile?.id}`}
                            clearable={false}
                            creatable
                            error={errorsMother?.member}
                            label={reduxText[5559]}
                            layout='profile'
                            name='member'
                            onChange={handleInputChange}
                            team
                            value={fieldsMother.member || ''}
                        />
                    )
                }
                <TextInput
                    error={errorsMother?.roles}
                    label={reduxText[2979]}
                    name='roles'
                    onChange={handleInputChange}
                    optional
                    translation={{
                        contentType: 'profileteam',
                        defaultDetailUrl: mother.detailUrl,
                        defaultLanguage: fieldsMother.translation_default_language,
                        defaultUpdateUrl: mother.updateUrl,
                        objectId: fieldsMother.id,
                        profileId: fieldsMother.profile?.id,
                    }}
                    value={fieldsMother.roles || ''}
                />
                <ImageCropInput
                    altButton
                    altValue={fieldsMother.image_alt}
                    clear
                    doka_output_data={fieldsMother.image_doka_output_data || ''}
                    error={errorsMother?.image}
                    errorAlt={errorsMother?.image_alt}
                    label={reduxText[4478]}
                    name='image'
                    onChange={handleInputChange}
                    optional
                    original={fieldsMother.image_original || ''}
                    translation={{
                        contentType: 'profileteam',
                        defaultDetailUrl: mother.detailUrl,
                        defaultLanguage: fieldsMother.translation_default_language,
                        defaultUpdateUrl: mother.updateUrl,
                        objectId: fieldsMother.id,
                        profileId: fieldsMother.profile?.id,
                    }}
                    value={fieldsMother.image || ''}
                />
                {fieldsMother.profile?.id && (
                    <React.Fragment>
                        <SelectListInput
                            apiUrl={`${select_url_main_custom_choice}?profile_id=${fieldsMother.profile.id}&content_type=${contentTypeData.profileteam}&field_name=custom_roles`}
                            clearable={false}
                            contentType='profileteam'
                            error={errorsMother?.custom_roles}
                            label={reduxText[8331]}
                            multi
                            name='custom_roles'
                            onChange={handleInputChange}
                            optional
                            profileId={fieldsMother.profile.id}
                            value={fieldsMother.custom_roles || ''}
                        />
                        {/* <SelectListInput
                            apiUrl={`${select_url_main_custom_category}?profile_id=${fieldsMother.profile?.id}`}
                            ccKey={1}
                            clearable={false}
                            error={errorsMother?.custom_categories}
                            label={reduxText[5569]}
                            multi
                            name='custom_categories'
                            onChange={handleInputChange}
                            optional
                            profileId={fieldsMother.profile?.id}
                            value={fieldsMother.custom_categories || ''}
                        /> */}
                    </React.Fragment>
                )}
                <SelectListInput
                    apiUrl={select_url_main_medium_type_profile}
                    clearable={false}
                    error={errorsMother?.medium_type}
                    label={reduxText[3228]}
                    name='medium_type'
                    onChange={handleInputChange}
                    value={fieldsMother.medium_type || ''}
                />
                {fieldsMother.medium_type?.id === 33
                    ? (
                        <Button
                            classNameWrap='mfcw-member-form-footer-button'
                            edit={false}
                            expand='full'
                            fill='outline'
                            padding='0 5px'
                            text='Blocks'
                            to={`${view_url_member_form}${fieldsMother.id}/`}
                        />
                    ) : (
                        <PortfolioDetailPageFieldInput
                            fields={fieldsMother}
                            errors={errorsMother}
                            onChange={handleInputChange}
                        />
                    )
                }
                {fieldsMother.id && (
                    <CheckboxInput
                        error={errorsMother?.no_detail_page}
                        label={reduxText[8724]}
                        name='no_detail_page'
                        onChange={handleInputChange}
                        value={fieldsMother.no_detail_page}
                    />
                )}
                {/* @ts-ignore */}
                {fieldsMother.member?.user && (fieldsMother.profile?.user !== fieldsMother.member?.user) && (
                    <React.Fragment>
                        <TextAreaInput
                            error={errorsMother?.overwrite_custom_text}
                            label={reduxText[3712]}
                            name='overwrite_custom_text'
                            onChange={handleInputChange}
                            optional
                            translation={{
                                contentType: 'profileteam',
                                defaultDetailUrl: mother.detailUrl,
                                defaultLanguage: fieldsMother.translation_default_language,
                                defaultUpdateUrl: mother.updateUrl,
                                objectId: fieldsMother.id,
                                profileId: fieldsMother.profile?.id,
                            }}
                            value={fieldsMother.overwrite_custom_text || ''}
                        />
                        <RichTextInput
                            error={errorsMother?.custom_text_rich}
                            fonts={fieldsMother.portfolio_main?.fonts}
                            label={reduxText[8730]}
                            name='custom_text_rich'
                            objectId={fieldsMother.id}
                            onChange={handleInputChange}
                            optional
                            presetColors={fieldsMother.preset_colors}
                            tinymceStylesObject={tinymceStylesObject}
                            tinymceStylesString={tinymceStylesString}
                            translation={{
                                contentType: 'profileteam',
                                defaultDetailUrl: mother.detailUrl,
                                defaultLanguage: fieldsMother.translation_default_language,
                                defaultUpdateUrl: mother.updateUrl,
                                objectId: fieldsMother.id,
                                profileId: fieldsMother.profile?.id,
                            }}
                            value={fieldsMother.custom_text_rich || ''}
                        />
                    </React.Fragment>
                )}
            </div>
            <div className='mfcw-member-form-footer'>
                {/* Start save & continue button */}
                <Button
                    classNameWrap='mfcw-member-form-footer-button'
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    fill='outline'
                    onClick={() => handleRequired(true)}
                    padding='0 5px'
                    size='x-small'
                    text={reduxText[6378]}
                />
                {/* End save & continue button */}
                {/* Start save button */}
                <Button
                    classNameWrap='mfcw-member-form-footer-button'
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    fill='outline'
                    onClick={() => handleRequired(false)}
                    padding='0 5px'
                    text={reduxText[4520]}
                />
                {/* End save button */}
            </div>
        </div>
    )
})
