// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LocationInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    OfferDetailFormSerializer,
    OfferDetailFormSerializerError,
} from 'serializers/web'

// props
type CityTempOfferInputProps = {
    errors: OfferDetailFormSerializerError | undefined
    fields: OfferDetailFormSerializer
    onChange: any
}

// main
export const CityTempOfferInput: React.FC<CityTempOfferInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <LocationInput
            error={errors?.address_json}
            label={reduxText[2292]}
            multi
            name='address_json'
            onChange={onChange}
            optional
            value={fields.address_json || ''}
        />
    )
})
