// react components
import slugify from 'slugify'

// data
import {
    reduxTextState,
} from 'data'

// main
export function getInputValue({
    customError,
    event,
    formInfo,
    reduxText,
}: {
    customError?: {
        name: string
        value: string
    }
    event: any
    formInfo: any
    reduxText: reduxTextState['data']
}) {
    const name = event.name
    if (['cover_image', 'cover_image_mq_o', 'cover_image_lq_o', 'image'].includes(event.name)) {
        if (formInfo.fields?.layout?.id === 781 && event.name === 'cover_image') {
            return {
                ...formInfo,
                errors: {
                    ...formInfo.errors,
                    [name]: '',
                    [`${name}_doka_output_data`]: '',
                    [`${name}_original`]: '',
                    file: '',
                    main_error: '',
                },
                fields: {
                    ...formInfo.fields,
                    [`${name}_doka_output_data`]: event.newDrop ? 'to_delete_original' : (event.doka_output_data ? ((typeof event.doka_output_data === 'string' && event.doka_output_data.includes('to_delete')) ? event.doka_output_data : JSON.stringify(event.doka_output_data)) : ''),
                    [`${name}_original`]: event.doka_output_data ? event.value : '',
                    [`${name}_square_doka_output_data`]: event.doka_output_data ? 'to_delete_square' : 'to_delete_all',
                    [`${name}_square`]: event.get_image_lq,
                    [name]: event.doka_image,
                    file: 'to_delete',
                    get_image_hq: event.get_image_hq,
                    get_image_lq: event.get_image_lq,
                    get_image_mq: event.get_image_mq,
                    meta_image_url: '',
                },
            }
        }
        return {
            ...formInfo,
            errors: {
                ...formInfo.errors,
                [name]: '',
                [`${name}_doka_output_data`]: '',
                [`${name}_original`]: '',
                main_error: '',
            },
            fields: {
                ...formInfo.fields,
                [`${name}_doka_output_data`]: event.newDrop ? 'to_delete_original' : (event.doka_output_data ? ((typeof event.doka_output_data === 'string' && event.doka_output_data.includes('to_delete')) ? event.doka_output_data : JSON.stringify(event.doka_output_data)) : ''),
                [`${name}_original`]: event.doka_output_data ? event.value : '',
                [`${name}_square_doka_output_data`]: event.doka_output_data ? 'to_delete_square' : 'to_delete_all',
                [`${name}_square`]: event.get_image_lq,
                [name]: event.doka_image,
                get_image_hq: event.get_image_hq,
                get_image_lq: event.get_image_lq,
                get_image_mq: event.get_image_mq,
                meta_image_url: '',
            },
        }
    } else if (['cover_image_square', 'image_square'].includes(event.name)) {
        return {
            ...formInfo,
            errors: {
                ...formInfo.errors,
                [name]: '',
                [`${name}_doka_output_data`]: '',
                main_error: '',
            },
            fields: {
                ...formInfo.fields,
                [name]: event.doka_image,
                [`${name}_doka_output_data`]: ((typeof event.doka_output_data === 'string' && event.doka_output_data.includes('to_delete')) ? event.doka_output_data : JSON.stringify(event.doka_output_data)),
            },
        }
    } else if (['file'].includes(event.name) && formInfo.fields?.layout?.id === 781) {
        return {
            ...formInfo,
            errors: {
                ...formInfo.errors,
                cover_image: '',
                file: '',
                main_error: '',
            },
            fields: {
                ...formInfo.fields,
                cover_image_doka_output_data: 'to_delete',
                file: event.value,
            },
        }
    } else if (['pdf_file'].includes(event.name)) {
        return {
            ...formInfo,
            errors: {
                ...formInfo.errors,
                [name]: '',
                main_error: '',
            },
            fields: {
                ...formInfo.fields,
                [name]: event.value,
                pdf_preview: event.value?.preview,
            },
        }
    } else if (['project'].includes(formInfo.model) && event.name === 'link_url') {
        let newError = ''
        if (event.value.includes('spotify.com/album')) {
            if (process.env.NODE_ENV === 'development') console.log('error')
            newError = reduxText[5496]
        } else if (event.value.includes('spotify.com/intl-fr/album')) {
            if (process.env.NODE_ENV === 'development') console.log('error')
            newError = reduxText[5496]
        } else if (event.value.includes('vimeo.com/manage')) {
            if (process.env.NODE_ENV === 'development') console.log('error')
            newError = reduxText[5561]
        }
        return {
            ...formInfo,
            errors: {
                ...formInfo.errors,
                [name]: newError,
                main_error: '',
            },
            fields: {
                ...formInfo.fields,
                [name]: event.value,
            },
        }
    } else if (customError) {
        return {
            ...formInfo,
            errors: {
                ...formInfo.errors,
                [name]: '',
                main_error: '',
                [customError.name]: customError.value,
            },
            fields: {
                ...formInfo.fields,
                [name]: event.name === 'slug' ? slugify(event.value, { replacement: '-', lower: true }) : event.value,
            },
        }
    } else {
        return {
            ...formInfo,
            errors: {
                ...formInfo.errors,
                [name]: '',
                main_error: '',
            },
            fields: {
                ...formInfo.fields,
                [name]: event.name === 'slug' ? slugify(event.value, { replacement: '-', lower: true }) : event.value,
            },
        }
    }
}
