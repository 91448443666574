// Root URL
const filterRoot = 'builder/filter/'

export const blockTemplateFilterOptions: any[] = [
    {
        api_url: `${filterRoot}block_template/?filter_model=custom_category`,
        showNone: true,
        slug: 'custom_category',
        textId: 8604,  // reduxText[8604]
    },
    {
        api_url: 'cover_image_status',
        slug: 'cover_image_status',
        textId: '',
    },
]


export const pageTemplateFilterOptions: any[] = [
    {
        api_url: `${filterRoot}page_template/?filter_model=custom_category`,
        showNone: true,
        slug: 'custom_category',
        textId: 8604,  // reduxText[8604]
    },
    {
        api_url: 'cover_image_status',
        slug: 'cover_image_status',
        textId: '',
    },
]
