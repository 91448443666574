// react components
import React from 'react'
import {
    DropzoneComponent,
} from 'react-dropzone-component'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrlV2,
    getReduxTextId,
} from 'services'

// props
type ImageFormWeProps = {
    contentType: contentTypeModelType
    detailObject: any
    filterParams?: string
    refreshData: () => void
    setIsOpen: React.Dispatch<boolean>
    subContentType?: any
}

// main
export const ImageForm: React.FC<ImageFormWeProps> = React.memo(({
    contentType,
    detailObject,
    filterParams,
    refreshData,
    setIsOpen,
    subContentType,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    let myDropzone: any
    function initCallback(dropzone: any) {
        myDropzone = dropzone
    }
    const app = contentType.split('_')[0]
    let param_app = app
    if (contentType === 'staff_staffmessageimage') param_app = 'staff_message'  // TODO dynamic

    const model = contentType.split('_')[1]
    const config = {
        iconFiletypes: ['.gif', '.jpg', '.jpeg', '.png', '.webp'],
        postUrl: getApiUrlV2(`${app}/form/${model}/create/`, reduxAuth),
        showFiletypeIcon: true,
    }
    const eventHandlers = {
        init: (e: any) => initCallback(e),
        complete: (file: any) => {
            myDropzone.removeFile(file)
            refreshData()
        }
    }
    const djsConfig = {
        acceptedFiles: 'image/gif,image/jpeg,image/png,image/webp',
        dictDefaultMessage: reduxText[9496],
        headers: reduxAuth.axiosConfig?.headers,
        maxFilesize: 64,
        maxThumbnailFilesize: 64,
        parallelUploads: 1,
        paramName: 'image',
        timeout: null,
        params: {
            [param_app]: detailObject.id,
            active: true,
            asset_custom_category: (filterParams?.split('&')?.find((param) => param.includes('asset_custom_category'))?.split('=')[1]) || '',
            content_object_id: detailObject.id,
            content_type: detailObject.model_content_type_id,
            profile: detailObject.profile?.id,
            sub_content_type: subContentType ? subContentType.id : 220,
        },
        // error: (file: any, message: any) => {  // TODO
        //     myDropzone.removeFile(file)
        //     alert(message)
        // }
    }

    return (
        <Dialog
            className='mo-dialog-class image-form'
            classes={{ paper: 'mo-dialog-wrapper fix-height fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {getReduxTextId((detailObject.profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]?.meta?.verboseNamePlural || detailObject.content_type_settings_global?.[contentType]?.meta?.verboseNamePlural), reduxText)}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='if-input'>
                    <DropzoneComponent
                        config={config}
                        djsConfig={djsConfig}
                        eventHandlers={eventHandlers}
                    />
                </div>
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    onClick={() => setIsOpen(false)}
                    size='small'
                    fill='outline'
                    text={reduxText[4742]}
                />
            </DialogActions>
        </Dialog>
    )
})
