// react components
import React from 'react'

// components
import {
    StylesExtraInput,
} from 'components'

// props
type StylesExtraFieldInputProps = {
    disabled?: boolean
    errors: { styles_extra?: string } | undefined
    fields: { styles_extra?: { [key: string]: string } }
    label?: string
    onChange: any
}

// main
export const StylesExtraFieldInput: React.FC<StylesExtraFieldInputProps> = React.memo(({
    disabled,
    errors,
    fields,
    label,
    onChange,
}) => {

    return (
        <StylesExtraInput
            disabled={disabled}
            error={errors?.styles_extra}
            label={label || 'styles_extra'}
            name='styles_extra'
            onChange={onChange}
            staffOnly={!Boolean(fields.styles_extra)}
            value={fields.styles_extra || ''}
        />
    )
})
