// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CountViewBlock,
    HeaderDetailBlock,
    LinkHelper,
    MainCountryFlag,
    PhoneNumberHelper,
} from 'components'

// containers
import {
    DetailListContainerNew,
} from 'containers'

// data
import {
    api_url_adherent_list,
    api_url_contribution_list,
    api_url_event_participant_list,
    defaultReduxState,
} from 'data'

// serializers
import {
    AdherentDetailSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getModalAbsoluteUrl,
} from 'services'

// props
type AdherentDetailBlockProps = {
    edit: boolean
    inModal?: boolean
    languageId: number | undefined
    object: AdherentDetailSerializer
    onModalClick?: (e: any) => void
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const AdherentDetailBlock: React.FC<AdherentDetailBlockProps> = React.memo(({
    edit,
    inModal,
    languageId,
    object,
    onModalClick,
    setLanguageId,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setTabValue({
            id: 'info',
            title: reduxText[4596],
        })
    }, [
        object?.id,
    ])

    const [tabValue, setTabValue] = useState<{
        id: string
        title: string
        listComponent?: string
        listApiUrl?: string
        tableHeaderComponent?: string
    }>({
        id: 'info',
        title: reduxText[4596],
    })

    return (
        <div className='adherent-detail-block-web'>
            <div className={`adbw-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                {tabValue.listComponent
                    ? (
                        <DetailListContainerNew
                            inModal={inModal}
                            listApiUrl={tabValue.listApiUrl!}
                            listComponent={tabValue.listComponent}
                            tableHeaderComponent={tabValue.tableHeaderComponent!}
                        />
                    ) : (
                        <div className='adbw-left-content'>
                            {object.profile?.id === 183405 && (  // Alize
                                <React.Fragment>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[6462]}</p>
                                        <p className='adbw-text-value'>{object.last_name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[6461]}</p>
                                        <p className='adbw-text-value'>{object.first_name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[6465]}</p>
                                        <p className='adbw-text-value'>{object.address}</p>
                                    </div>
                                    {object.address_additional && (
                                        <div className='adbw-text-wrap'>
                                            <p className='adbw-text-name'>{reduxText[8712]}</p>
                                            <p className='adbw-text-value'>{object.address_additional}</p>
                                        </div>
                                    )}
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Numéro</p>
                                        <p className='adbw-text-value'>{object.street_number}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Code postal</p>
                                        <p className='adbw-text-value'>{object.postal_code}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Code ville</p>
                                        <p className='adbw-text-value'>{object.city_code}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Ville</p>
                                        <p className='adbw-text-value'>{object.city?.name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Pays</p>
                                        <p className='adbw-text-value flex-align-center'>
                                            {object.country && (
                                                <MainCountryFlag
                                                    edit={edit}
                                                    namePosition='end'
                                                    object={object.country}
                                                    searchTab={undefined}
                                                />
                                            )}
                                        </p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8562]}</p>
                                        <p className='adbw-text-value'>{object.email}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[4605]}</p>
                                        <p className='adbw-text-value'>
                                            <PhoneNumberHelper value={object.phone_number} />
                                        </p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Type d'exercice</p>
                                        <p className='adbw-text-value'>{object.custom_categories?.map(val => val.name).join(' - ')}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Région</p>
                                        <p className='adbw-text-value'>{object.many_to_many_profile_teams?.map(val => val.name).join(' - ')}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8710]}</p>
                                        <p className='adbw-text-value'>{object.date_membership_start}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8711]}</p>
                                        <p className='adbw-text-value'>{object.date_membership_end}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8718]}</p>
                                        <p className='adbw-text-value'>{object.portfolio_user_groups?.map(val => val.name).join(' - ')}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Avenant 7</p>
                                        <p className='adbw-text-value'>{object.custom_fk_cc_1?.name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Avenant 5</p>
                                        <p className='adbw-text-value'>{object.custom_fk_cc_2?.name}</p>
                                    </div>
                                </React.Fragment>
                            )}
                            {object.profile?.id === 183474 && (  // APPFE
                                <React.Fragment>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[6462]}</p>
                                        <p className='adbw-text-value'>{object.last_name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[6461]}</p>
                                        <p className='adbw-text-value'>{object.first_name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8562]}</p>
                                        <p className='adbw-text-value'>{object.email}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8710]}</p>
                                        <p className='adbw-text-value'>{object.date_membership_start}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Status</p>
                                        <p className='adbw-text-value'>{object.custom_mtm_cc_1?.map(val => val.name).join(' - ')}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8564]}</p>
                                        <p className='adbw-text-value'>{object.adherent_number}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8711]}</p>
                                        <p className='adbw-text-value'>{object.date_membership_end}</p>
                                    </div>
                                </React.Fragment>
                            )}
                            {object.profile?.id === 187728 && (  // FCAPI
                                <React.Fragment>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[6462]}</p>
                                        <p className='adbw-text-value'>{object.last_name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[6461]}</p>
                                        <p className='adbw-text-value'>{object.first_name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8562]}</p>
                                        <p className='adbw-text-value'>{object.email}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[4605]}</p>
                                        <p className='adbw-text-value'>
                                            <PhoneNumberHelper value={object.phone_number} />
                                        </p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Chiens</p>
                                        <p className='adbw-text-value'>{object.many_to_many_profile_teams?.map(val => val.name).join(' - ')}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Validité carte handicapé</p>
                                        <p className='adbw-text-value'>{object.custom_date_1}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>Date renouvellement cotisation</p>
                                        <p className='adbw-text-value'>{object.date_membership_end}</p>
                                    </div>
                                </React.Fragment>
                            )}
                            {object.profile?.id === 187807 && (  // HEY
                                <React.Fragment>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[6462]}</p>
                                        <p className='adbw-text-value'>{object.last_name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[6461]}</p>
                                        <p className='adbw-text-value'>{object.first_name}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[8562]}</p>
                                        <p className='adbw-text-value'>{object.email}</p>
                                    </div>
                                    <div className='adbw-text-wrap'>
                                        <p className='adbw-text-name'>{reduxText[4605]}</p>
                                        <p className='adbw-text-value'>
                                            <PhoneNumberHelper value={object.phone_number} />
                                        </p>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    )}
            </div>
            <div className={`adbw-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <HeaderDetailBlock
                    contentType='adherent'
                    edit={edit}
                    languageId={languageId}
                    object={object}
                    setLanguageId={setLanguageId}
                />
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
