// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextAreaInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
    ProjectFormState,
} from 'serializers/web'

// props
type LinkUrlProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    getMetaIsLoading: boolean
    mother: ProjectFormState['mother']
    onChange: any
}

// main
export const LinkUrlProjectInput: React.FC<LinkUrlProjectInputProps> = React.memo(({
    errors,
    fields,
    getMetaIsLoading,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const requiredFields = mother.requiredAllFields

    return (
        <TextAreaInput
            canSkipDataRetrieve
            error={errors?.link_url}
            getMetaIsLoading={getMetaIsLoading}
            helperText={reduxText[323010]}
            label={reduxText[3230]}
            name='link_url'
            onChange={onChange}
            optional={requiredFields && !requiredFields.includes('link_url')}
            translation={{
                contentType: 'project',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            value={fields.link_url || ''}
        />
    )
})
