// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
    parseQuerySite,
} from 'services'

// props
type TemplateBlock814SiteProps = {
    blockId: string
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock814Site: React.FC<TemplateBlock814SiteProps> = React.memo(({
    blockId,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock814Site',
                'applyStyles',
            ))
        }
    }

    const longMessageDict: any = {
        epb_stage: "Vous venez d'effectuer en ligne votre pr�-inscription et nous vous en remercions !\r\n\r\nVous recevrez sous peu un mail de confirmation.\r\nN'h�sitez pas � v�rifier dans vos spams si vous ne recevez pas ce mail dans l'heure qui suit votre inscription.\r\n\r\nA tr�s vite dans les Baronnies !",
        epb_stage_cb: "Merci pour votre inscription !\r\n\r\nVous recevrez sous peu un mail de confirmation.\r\nN'h�sitez pas � v�rifier dans vos spams si vous ne recevez pas ce mail dans l'heure qui suit votre inscription.\r\n\r\nA tr�s vite dans les Baronnies !",
        epb_tandem_gift: "Merci pour votre achat !\r\n\r\nVous recevrez sous peu un mail contenant votre Bon cadeau.\r\nN'h�sitez pas � v�rifier dans vos spams si vous ne recevez pas ce mail dans l'heure qui suit la validation de votre commande.\r\n\r\nA tr�s vite dans les Baronnies !",
    }

    return (
        <LinkHelperSite
            id={blockId}
            action={object.action}
            className={`template-block-814${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            onClick={() => undefined}
            style={stylesNew}
            styleActive={undefined}
            textTag='span'
            to={undefined}
        >
            {parseQuerySite(location.search)?.custom_message || (parseQuerySite(location.search)?.custom_message_id && longMessageDict[parseQuerySite(location.search)?.custom_message_id]) || 'Custom message'}
            {longMessageDict['epb_stage']}
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </LinkHelperSite>
    )
})
