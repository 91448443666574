// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProfileDetailFormSerializer,
    ProfileDetailFormSerializerError,
} from 'serializers/web'

// props
type ImageProfileInputProps = {
    errors: ProfileDetailFormSerializerError | undefined
    fields: ProfileDetailFormSerializer
    onChange: any
}

// main
export const ImageProfileInput: React.FC<ImageProfileInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <ImageCropInput
            altButton
            altValue={fields.image_alt}
            clear
            doka_output_data={fields.image_doka_output_data || ''}
            error={errors?.image}
            errorAlt={errors?.image_alt}
            helperText={reduxText[183310]}
            label={reduxText[1833]}
            name='image'
            onChange={onChange}
            original={fields.image_original || ''}
            value={fields.image || ''}
        />
    )
})
