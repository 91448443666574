// data
import {
    REDUX_TEXT_FETCH_MAIN_TEXT,
} from './mo_actions'
import {
    reduxTextState,
} from './mo_state'

// services
import {
    getCookie,
    setCookie,
} from 'services'

// main
const initialState: reduxTextState = {
    data: getCookie('mo_reduxTextData', 'json') || {},
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_TEXT_FETCH_MAIN_TEXT: {
            setCookie('mo_reduxTextData', action.payload)
            return {
                ...state,
                data: action.payload,
            }
        }
        default:
            return state
    }
}
