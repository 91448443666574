// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'
import {
    ArrowContainer,
    Popover,
} from 'react-tiny-popover'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetParentActiveSite,
    reduxFormSetSectionIdSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// services
import {
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type ParentButtonMixedMediaContentChildrenProps = {
    children: any
    isPopoverOpen: boolean
    mainParentId: number
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    parentArray: number[] | undefined
    setIsPopoverOpen: React.Dispatch<boolean>
}

// main
export const ParentButtonMixedMediaContentChildren: React.FC<ParentButtonMixedMediaContentChildrenProps> = React.memo(({
    children,
    isPopoverOpen,
    mainParentId,
    newMixedMediaContent,
    newMixedMediaContentType,
    parentArray,
    setIsPopoverOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const mother = useSelector((state: defaultReduxState) => state.reduxForm.mixedMother)  // TODO check with reduxFormSetMixedProfileIdOpen
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function handleParentBlockHover(mousedirection: 'enter' | 'leave', parentId: number) {
        try {
            if (mousedirection === 'enter') {
                dispatch(reduxFormSetParentActiveSite(parentId))
            } else {
                dispatch(reduxFormSetParentActiveSite(undefined))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ParentButtonMixedMediaContentChildren',
                'handleParentBlockHover',
            ))
        }
    }

    function goToParent(parentId: number) {
        dispatch(reduxFormSetParentActiveSite(undefined))
        setIsPopoverOpen(false)
        if (newMixedMediaContent) {
            history.push(`/form-mixed-media/${newMixedMediaContentType}/${params.id}/${parentId}/`)
        } else {
            history.push(`${mother.formUrl}${params.id}/mixed-media-content/${parentId}/`)
        }
        dispatch(reduxFormSetSectionIdSite(mainParentId))
        if (Number(params.panelValue) === parentId) scrollToSection(params.panelValue, 0)
    }

    function PopoverContent() {
        return (
            <div className='selection-helper-page-content-web-popover popover-parent-button-mixed-media-content-children-web'>
                {parentArray?.map((val, i) => (
                    <div
                        key={val}
                        className='shpcwp-item'
                    >
                        <button
                            className='shpcwp-item-button'
                            onClick={() => goToParent(val)}
                            onMouseEnter={() => handleParentBlockHover('enter', val)}
                            onMouseLeave={() => handleParentBlockHover('leave', val)}
                        >
                            <span className='shpcwp-item-button-text'>{`${reduxText[8318]} ${i + 1}`}</span>
                        </button>
                    </div>
                ))}
            </div>
        )
    }

    if (reduxFormSite.navigationMode === 'edit') {
        return (
            <Popover
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        arrowColor={reduxAuth.settings?.theme === 'light' ? '#202020' : '#ffffff'}
                        arrowSize={10}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        position={position}
                    >
                        <PopoverContent />
                    </ArrowContainer>
                )}
                isOpen={isPopoverOpen}
                onClickOutside={() => {
                    setIsPopoverOpen(false)
                    dispatch(reduxFormSetParentActiveSite(undefined))
                }}
                positions={['bottom', 'left', 'top', 'right']}
            >
                {children}
            </Popover>
        )
    }
    return null
})
