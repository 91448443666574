// data
import {
    reduxTextState,
} from 'data'

// main
export function getMainErrorMessage({
    error,
    reduxText,
}: {
    error: any
    reduxText: reduxTextState['data']
}) {
    const mainError = error.message
    const errorStatus = error.response && error.response.status
    if (errorStatus === 400) {
        const tempError = error.response.data
        tempError.main_error = reduxText[4578]
        return tempError
    }
    if (errorStatus === 500) {
        return { main_error: reduxText[4579] }
    }
    return error.response ? error.response.data : { main_error: mainError }
}
