// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// containers
import {
    MainFormContainerWrapper,
} from 'containers'

// data
import {
    api_url_project_content_form_create,
    api_url_project_form_create,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetAvailabitySite,
    reduxModalErrorEventHandler,
    reduxModalSetEditSite,
    select_url_portfolio_content_source_available,
} from 'data'

// pages
import {
    AdditionalProjectPanel,
    ContainerMixedContentPanel,
    ContainerMixedPanel,
    ContentProjectPanel,
    EclinkProjectPanel,
    MainProjectPanel,
    MenuProjectPanel,
    PortfolioProjectPanel,
    ProjectContentFormState,
    ProjectEclinkFormState,
    ProjectMixedMediaContentFormState,
    ProjectMotherFormState,
    ProjectTeamFormState,
    ShopProjectPanel,
    TeamProjectPanel,
    inlineRequiredFields,
    requiredFields,
} from 'pages'

// serializers
import {
    ProjectFormState,
} from 'serializers/web'

// services
import {
    autoSaveStyles,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailData,
    getDynamicTextOptions,
    getInputValue,
    mainFormGetMediaData,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue?:
    'additional-panel' |
    'content-article-panel' |
    'content-asset-panel' |
    'content-image-panel' |
    'content-track-panel' |
    'content-video-panel' |
    'link-panel' |
    'main-panel' |
    'shop-panel' |
    'team-panel' |
    'website-related-panel'
}

type LocationParams = {
    formRefresh?: number
}

type ProjectFormProps = {
    objectId?: number
    onRefresh?: () => void
}

// main
export const ProjectForm: React.FC<ProjectFormProps> = React.memo(({
    objectId,
    onRefresh,
}) => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<ProjectFormState>({
        mother: ProjectMotherFormState(reduxAuth, location.pathname.split('/')[2]),
        content: ProjectContentFormState(reduxAuth),
        eclink: ProjectEclinkFormState(reduxAuth),
        mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
        team: ProjectTeamFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)
    const [getMetaIsLoading, setGetMetaIsLoading] = useState(false)

    useEffect(() => {
        try {
            autoSaveStyles({
                component: 'ProjectForm',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProjectForm',
                'useEffect',
            ))
        }
    }, [
        params.panelValue,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailData({
            axiosCancelToken,
            component: 'ProjectForm',
            dispatch,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: objectId ? `${objectId}` : params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        objectId,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 9 && formState.mother.fields?.profile?.id) {
            getAvailabeContentSource()
        }
    }, [
        formState.mother.fields?.medium_type?.id,
        formState.mother.fields?.profile?.id,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 9) {
            dispatch(reduxModalSetEditSite(true))
            if (!reduxFormSite.dynamicTextOptions) {
                getDynamicTextOptions({
                    component: 'ProjectForm',
                    dispatch,
                    reduxAuth,
                })
            }
        }
    }, [
        formState.mother.fields?.medium_type?.id,
    ])

    function setInitialState() {
        const projectTypeDict: any = {
            'project': {
                id: '1',
                name: reduxText[3528],
            },
            'press': {
                id: '2',
                name: reduxText[4812],
            },
            'resource': {
                id: '3',
                name: reduxText[8187],
            },
        }
        const mother = ProjectMotherFormState(reduxAuth, location.pathname.split('/')[2])
        setFormState({
            mother: {
                ...mother,
                fields: {
                    date_type_choice: {
                        id: 'd',
                        name: reduxText[4832],
                    },
                    profile: !reduxAuth.settings?.user?.is_staff ? reduxAuth.settings?.active_profile : undefined,
                    project_type: projectTypeDict[location.pathname.split('/')[2]],
                },
            },
            content: ProjectContentFormState(reduxAuth),
            eclink: ProjectEclinkFormState(reduxAuth),
            mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
            team: ProjectTeamFormState(reduxAuth),
        })
    }

    async function getAvailabeContentSource() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProjectForm getAvailabeContentSource')
            const getUrl = getApiUrl(`${select_url_portfolio_content_source_available}?profile_id=${formState.mother.fields?.profile?.id}`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetAvailabitySite(response.data[0].available))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ProjectForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getAvailabeContentSource',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProjectForm',
                'getAvailabeContentSource',
            ))
        }
    }

    let wto: any
    let wto2: any

    function handleInputChange(event: any, formInfo: any) {
        try {
            const mother = formState.mother
            const content = formState.content
            const team = formState.team
            if (formInfo.name === 'mother' && event.name === 'profile' && mother) {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        profile: event.value,
                    },
                    errors: {
                        ...mother.errors,
                        profile: '',
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '',
                })
            } else if (formInfo.name === 'mother' && event.name === 'link_url' && !mother.fields?.skip_data_retrieve && mother) {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
                clearTimeout(wto)
                wto = setTimeout(() => {
                    mainFormGetMediaData({
                        formInfo: mother,
                        formState,
                        helpers,
                        image_only: false,
                        reduxAuth,
                        reduxText,
                        setFormState,
                        setGetMetaIsLoading,
                        setHelpers,
                        urltoParse: event.value,
                    })
                }, 1000)
            } else if (formInfo.name === 'content' && event.name === 'link_url' && !content?.fields?.skip_data_retrieve && content) {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
                clearTimeout(wto2)
                wto2 = setTimeout(() => {
                    mainFormGetMediaData({
                        formInfo: content,
                        formState,
                        helpers,
                        image_only: false,
                        reduxAuth,
                        reduxText,
                        setFormState,
                        setGetMetaIsLoading,
                        setHelpers,
                        urltoParse: event.value,
                    })
                }, 1000)
            } else if (formInfo.name === 'mother' && event.name === 'styles_original' && mother) {
                const toReturn = {
                    ...mother,
                    errors: {
                        ...mother.errors,
                        styles_original: '',
                    },
                    fields: {
                        ...mother.fields,
                        styles: event.preview,
                        styles_original: event.value,
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'mixedMediaContent' && event.name === 'styles_original' && mother) {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
            } else if (formInfo.name === 'mother' && event.name === 'medium_type' && event.value.id && mother) {
                const toReturn = {
                    ...mother,
                    errors: {
                        ...mother.errors,
                        medium_type: '',
                    },
                    fields: {
                        ...mother.fields,
                        medium_type: event.value,
                    },
                    requiredAllFields: requiredFields[event.value.id].requiredAllFields,
                    requiredAttribute: requiredFields[event.value.id].requiredAttribute,
                    requiredForeignKey: requiredFields[event.value.id].requiredForeignKey,
                    requiredManyToMany: requiredFields[event.value.id].requiredManyToMany,
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                    scrollToTop: true,
                })
            } else if (formInfo.name === 'mother' && event.name === 'mixed_media_fonts' && mother) {
                const toReturn = {
                    ...mother,
                    errors: {
                        ...mother.errors,
                        mixed_media_fonts: '',
                    },
                    fields: {
                        ...mother.fields,
                        fonts: event.value,
                        mixed_media_fonts: event.value,
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'content' && event.name === 'temp_project_content_type' && event.value === 1 && mother && content) {
                if (mother.fields?.medium_type?.id) {
                    const toReturn = {
                        ...content,
                        createUrl: getApiUrl(api_url_project_form_create, reduxAuth),
                        errors: {
                            ...content.errors,
                            medium_type: '',
                            profile: '',
                            temp_project_content_parent: '',
                            temp_project_content_type: '',
                        },
                        fields: {
                            ...content.fields,
                            active: true,
                            medium_type: event.medium_type,
                            profile: mother.fields.profile,
                            temp_project_content_parent: mother.fields,
                            temp_project_content_type: event.value,
                        },
                        requiredAllFields: inlineRequiredFields[event.medium_type.id].requiredAllFields,
                        requiredAttribute: inlineRequiredFields[event.medium_type.id].requiredAttribute,
                        requiredForeignKey: inlineRequiredFields[event.medium_type.id].requiredForeignKey,
                        requiredManyToMany: inlineRequiredFields[event.medium_type.id].requiredManyToMany,
                        formAttribute: [
                            'active',
                            'cover_image_alt',
                            'cover_image_doka_output_data',
                            'cover_image_square_doka_output_data',
                            'date',
                            'description',
                            'link_id',
                            'link_url',
                            'meta_image_url',
                            'name',
                            'pdf_file',
                            'temp_project_content_type',
                        ],
                        formFile: [
                            'cover_image',
                            'cover_image_original',
                            'cover_image_square',
                        ],
                        formForeignKey: [
                            'date_type_choice',
                            'medium_type',
                            'profile',
                            'temp_project_content_parent',
                        ],
                        formJson: [],
                        formManyToMany: [
                            'themes',
                        ],
                    }
                    setFormState({
                        ...formState,
                        content: toReturn,
                    })
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: true,
                        mainError: '', // TO_CHECK
                    })
                }
            } else if (formInfo.name === 'content' && event.name === 'temp_project_content_type' && event.value === 6 && content) {
                const toReturn = {
                    ...content,
                    createUrl: getApiUrl(api_url_project_content_form_create, reduxAuth),
                    errors: {
                        ...content.errors,
                        medium_type: '',
                        profile: '',
                        temp_project_content_parent: '',
                        temp_project_content_type: '',
                    },
                    fields: {
                        ...content.fields,
                        active: '',
                        content_type: event.value,
                        profile: '',
                        temp_project_content_parent: undefined,
                        temp_project_content_type: event.value,
                    },
                    requiredAllFields: [
                        'content_type',
                        'project_child',
                    ],
                    requiredAttribute: [
                        'content_type',
                    ],
                    requiredForeignKey: [
                        'project_child',
                    ],
                    requiredManyToMany: [],
                    formAttribute: [
                        'content_type',
                    ],
                    formFile: [],
                    formForeignKey: [
                        'project_child',
                    ],
                    formJson: [],
                    formManyToMany: [],
                }
                setFormState({
                    ...formState,
                    content: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'team' && event.name === 'name' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: '',
                        name: event.value,
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'team' && event.name === 'member' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: event.value,
                        name: '',
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                        'member',
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProjectForm',
                'handleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = formState.mother.fields

    // Panels
    const panelValue = params.panelValue
    const panelText = {
        'additional-panel': reduxText[3234],
        'content-article-panel': reduxText[3746],
        'content-asset-panel': reduxText[8149],
        'content-image-panel': reduxText[1767],
        'content-track-panel': reduxText[2443],
        'content-video-panel': reduxText[2441],
        'delete-panel': reduxText[2983],
        'link-panel': reduxText[7431],
        'main-panel': reduxText[347],
        'shop-panel': reduxText[6552],
        'team-panel': reduxText[4917],
        'website-related-panel': reduxText[6415],
    }

    return (
        <MainFormContainerWrapper
            formState={formState}
            headerTitle={panelValue ? panelText[panelValue] : ''}
            helpers={helpers}
            onRefresh={onRefresh}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {onRefresh && fieldsMother?.id && (
                <React.Fragment>
                    <p className='mfcmw-custom-edit-title'>{panelText['main-panel']}</p>
                    <MainProjectPanel
                        formState={formState}
                        getMetaIsLoading={getMetaIsLoading}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['additional-panel']}</p>
                    <AdditionalProjectPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['website-related-panel']}</p>
                    <PortfolioProjectPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                </React.Fragment>
            )}
            {!onRefresh && (
                <React.Fragment>
                    {panelValue === 'additional-panel' && fieldsMother?.id && (
                        <AdditionalProjectPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'content-article-panel' && fieldsMother?.id && (
                        <ContentProjectPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            mediumType={{ id: 7, name: 'Press / Article' }}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'content-asset-panel' && fieldsMother?.id && (
                        <ContentProjectPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            mediumType={undefined}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'content-image-panel' && fieldsMother?.id && (
                        <ContentProjectPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            mediumType={{ id: 6, name: 'Image' }}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'content-track-panel' && fieldsMother?.id && (
                        <ContentProjectPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            mediumType={{ id: 2, name: 'Audio Track' }}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'content-video-panel' && fieldsMother?.id && (
                        <ContentProjectPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            mediumType={{ id: 3, name: 'Video' }}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'link-panel' && fieldsMother?.id && (
                        <EclinkProjectPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'main-panel' && (
                        <MainProjectPanel
                            formState={formState}
                            getMetaIsLoading={getMetaIsLoading}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'website-related-panel' && fieldsMother?.id && (
                        <PortfolioProjectPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'shop-panel' && fieldsMother?.id && (
                        <ShopProjectPanel
                            formState={formState}
                            helpers={helpers}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'team-panel' && fieldsMother?.id && (
                        <TeamProjectPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {params.formType === 'mixed-media' && fieldsMother?.id && (
                        <ContainerMixedPanel
                            contentType='project'
                            formState={formState}
                            handleInputChange={handleInputChange}
                            newMixedMediaContent={false}
                            newMixedMediaContentType={undefined}
                            setFormState={setFormState}
                        />
                    )}
                    {params.formType === 'mixed-media-content' && fieldsMother?.id && (
                        <ContainerMixedContentPanel
                            contentType='project'
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            newMixedMediaContent={false}
                            newMixedMediaContentType={undefined}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {!panelValue && (
                        <MenuProjectPanel
                            formState={formState}
                            helpers={helpers}
                            setHelpers={setHelpers}
                        />
                    )}
                </React.Fragment>
            )}
        </MainFormContainerWrapper>
    )
})
