// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    InlineFormPanelProps,
    ProjectChildFormState,
} from 'serializers/web'

// props
type EclinkProjectChildPanelProps = {
    formState: ProjectChildFormState
    setFormState: React.Dispatch<ProjectChildFormState>
}

// main
export const EclinkProjectChildPanel: React.FC<InlineFormPanelProps & EclinkProjectChildPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const eclink = formState.eclink

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, eclink)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EclinkProjectChildPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Inline
    const errorsInline = eclink.errors
    const fieldsInline = eclink.fields

    if (!fieldsInline) return null

    return (
        <InlineFormContainerWeb
            formInfo={eclink}
            formState={formState}
            helpers={helpers}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            <TextInput
                error={errorsInline?.link_url}
                helperText={reduxText[184210]}
                label={reduxText[1842]}
                name='link_url'
                onChange={onHandleInputChange}
                optional
                value={fieldsInline.link_url || ''}
            />
        </InlineFormContainerWeb>
    )
})
