// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_portfolio_page_detail,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
    MediumTypeSerializer,
} from 'serializers/web'

// props
type PortfolioDetailPageFieldInputProps = {
    errors: { portfolio_detail_page?: string } | undefined
    fields: { portfolio_detail_page?: MainIdNameSerializer, medium_type?: MediumTypeSerializer, profile?: MainIdNameSerializer }
    label?: string
    onChange: any
    show?: boolean
}

// main
export const PortfolioDetailPageFieldInput: React.FC<PortfolioDetailPageFieldInputProps> = React.memo(({
    errors,
    fields,
    label,
    onChange,
    show = true,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!show || !fields.medium_type?.id || !fields.profile?.id) return null

    return (
        <SelectListInput
            apiUrl={`${select_url_portfolio_page_detail}?medium_type=${fields.medium_type?.id}&profile=${fields.profile?.id}`}
            clearable
            error={errors?.portfolio_detail_page}
            label={label || reduxText[8721]}
            name='portfolio_detail_page'
            onChange={onChange}
            optional
            value={fields.portfolio_detail_page || ''}
        />
    )
})
