// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Loader,
} from 'components'

// customers
import {
    PickAnOfferBlockStep2,
} from 'customers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
} from 'services'

// main
export const AgendaDuQuartierFederateurForm: React.FC = React.memo(() => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    type errorsType = {
        address_custom_additional_address_info_1?: string
        address_custom_additional_address_info_2?: string
        address_custom_building_1?: string
        address_custom_building_2?: string
        address_custom_city_1?: string
        address_custom_city_2?: string
        address_custom_country_1?: any
        address_custom_office_1?: string
        address_custom_office_2?: string
        address_custom_postal_code_1?: string
        address_custom_postal_code_2?: string
        address_custom_recipient_name_2?: string
        address_custom_street_address_1?: string
        address_custom_street_address_2?: string
        civility?: string
        custom_date_field_1?: string
        custom_email_field_1?: string
        custom_foreign_key_5?: string
        custom_text_field_4?: string
        custom_text_field_5?: string
        custom_text_field_6?: string
        first_name?: string
        last_name?: string
        name?: string
        phone_number?: string
    }
    const errorsInitial = {}
    type fieldsType = {
        address_custom_additional_address_info_1?: string
        address_custom_additional_address_info_2?: string
        address_custom_building_1?: string
        address_custom_building_2?: string
        address_custom_city_1?: string
        address_custom_city_2?: string
        address_custom_country_1?: any
        address_custom_office_1?: string
        address_custom_office_2?: string
        address_custom_postal_code_1?: string
        address_custom_postal_code_2?: string
        address_custom_recipient_name_2?: string
        address_custom_street_address_1?: string
        address_custom_street_address_2?: string
        civility?: any
        custom_date_field_1?: any
        custom_email_field_1?: string
        custom_foreign_key_5?: any
        custom_text_field_4?: string
        custom_text_field_5?: string
        custom_text_field_6?: string
        first_name?: string
        has_shipping?: string
        last_name?: string
        name?: string
        phone_number?: string
    }
    const fieldsInitial = {}

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)

    useEffect(() => {
        if (reduxAuth.settings?.federateur?.id) {
            onGetDetailData()
        }
    }, [
        reduxAuth.settings?.federateur?.id,
    ])

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierFederateurForm',
                'handleInputChange',
            ))
        }
    }

    async function handleSubmit() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierFederateurForm handleSubmit')
            const patchUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/update/federateur/${reduxAuth.settings?.federateur?.id}/`, reduxAuth)
            const formData = new FormData()
            formData.append('address_custom_additional_address_info_1', fields.address_custom_additional_address_info_1 || '')
            formData.append('address_custom_additional_address_info_2', fields.address_custom_additional_address_info_2 || '')
            formData.append('address_custom_building_1', fields.address_custom_building_1 || '')
            formData.append('address_custom_building_2', fields.address_custom_building_2 || '')
            formData.append('address_custom_city_1', fields.address_custom_city_1 || '')
            formData.append('address_custom_city_2', fields.address_custom_city_2 || '')
            formData.append('address_custom_country_1', fields.address_custom_country_1?.id || '')
            formData.append('address_custom_office_1', fields.address_custom_office_1 || '')
            formData.append('address_custom_office_2', fields.address_custom_office_2 || '')
            formData.append('address_custom_postal_code_1', fields.address_custom_postal_code_1 || '')
            formData.append('address_custom_postal_code_2', fields.address_custom_postal_code_2 || '')
            formData.append('address_custom_recipient_name_2', fields.address_custom_recipient_name_2 || '')
            formData.append('address_custom_street_address_1', fields.address_custom_street_address_1 || '')
            formData.append('address_custom_street_address_2', fields.address_custom_street_address_2 || '')
            formData.append('civility', fields.civility?.id || '')
            formData.append('custom_date_field_1', fields.custom_date_field_1 || '')
            formData.append('custom_email_field_1', fields.custom_email_field_1 || '')
            formData.append('custom_foreign_key_5', fields.custom_foreign_key_5?.id || '')
            formData.append('custom_text_field_4', fields.custom_text_field_4 || '')
            formData.append('custom_text_field_5', fields.custom_text_field_5 || '')
            formData.append('custom_text_field_6', fields.custom_text_field_6 || '')
            formData.append('first_name', fields.first_name || '')
            formData.append('last_name', fields.last_name || '')
            formData.append('name', fields.name || '')
            formData.append('phone_number', fields.phone_number || '')
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'patch',
                url: patchUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: patchUrl,
                        component: 'AgendaDuQuartierFederateurForm',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierFederateurForm',
                'handleInputChange',
            ))
        }
    }

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/detail/federateur/${reduxAuth.settings?.federateur?.id}/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep1',
                dispatch,
                reduxAuth,
                setDetailObject: setFields,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep1',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div className='agenda-du-quartier-payment-success'>
            <div className='adgps-content'>
                <p className='adgps-title'>Mes informations administratives</p>
                <PickAnOfferBlockStep2
                    errors={errors}
                    fields={fields}
                    handleInputChange={handleInputChange}
                />
                <div
                    className='adgps-button-wrap'
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        edit={false}
                        onClick={handleSubmit}
                        text='Sauvegarder'
                    />
                </div>
            </div>
            {isLoading && <Loader isOpen />}
        </div>
    )
})
