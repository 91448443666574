// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    NotFoundComponent,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    DetailFieldSet,
    ListContent,
} from 'pages'

// serializers
import {
    ContentTypeDetailPanelTypes,
    ContentTypeSettingsCustomTypes,
    ContentTypeSettingsGlobalTypes,
    ContentTypeSettingsTypes,
    MainIdNameSerializer,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getReduxTextId,
} from 'services'

// props
type DetailContentBlockProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    inModal?: boolean
    object: {
        id?: number
        content_type_settings_global?: ContentTypeSettingsGlobalTypes
        get_image_hq?: string
        model_content_type_id?: number
        profile?: ProfileDetailAdminSerializerV2
        sub_content_type?: MainIdNameSerializer
    }
    refreshData: () => void
    tab: number
    tabData: ContentTypeDetailPanelTypes | undefined
}

// main
export const DetailContentBlock: React.FC<DetailContentBlockProps> = React.memo(({
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    inModal,
    object,
    refreshData,
    tab,
    tabData,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (tabData?.panelOptions?.hideIfNotStaff && !reduxAuth.settings?.user?.is_staff) {
        return <NotFoundComponent statusCode={403} />
    }

    if (tabData?.panelOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) {
        return <NotFoundComponent statusCode={403} />
    }

    const isJustInline = tabData?.blockList?.length! === 1 && tabData?.blockInline?.[tabData?.blockList?.[0]!]

    return (
        <div className={`dc-content${isJustInline ? ' dc-justinline' : ''} mo-hidden-vertical-scrollbar ${tab}`}>
            {tabData?.panelOptions?.hideIfNotStaff && reduxAuth.settings?.user?.is_staff && (
                <p className='dc-panel-description-staff'>Showing this panel only for staff</p>
            )}
            {tabData?.panelOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id === 1 && (
                <p className='dc-panel-description-staff'>Superuser</p>
            )}
            {tabData?.blockList?.map(val => {
                if (tabData.blockFieldSet?.[val]) {
                    return (
                        <DetailFieldSet
                            key={val}
                            blockId={val}
                            contentType={contentType}
                            contentTypeSettings={contentTypeSettings}
                            contentTypeSettingsCustom={contentTypeSettingsCustom}
                            fieldSetObject={tabData.blockFieldSet[val]}
                            object={object}
                            refreshData={refreshData}
                            tab={tab}
                        />
                    )
                }
                if (tabData?.blockInline?.[val]) {
                    const blockInlineObject = tabData.blockInline[val]
                    const blockListLength = tabData.blockList?.length! > 1
                    const title = blockListLength ? (
                        getReduxTextId(blockInlineObject.title!, reduxText) ||
                        object.profile?.settings_data_json?.contentTypeSettingsCustom?.[blockInlineObject.contentType!]?.meta?.verboseNamePlural ||
                        object.content_type_settings_global?.[blockInlineObject.contentType!]?.meta?.verboseNamePlural
                    ) : undefined
                    let columns = (object.profile?.settings_data_json?.contentTypeSettingsCustom?.[blockInlineObject.contentType!]?.list?.fields || blockInlineObject.fields).filter(obj => obj.split('__')[0] !== blockInlineObject.parentModelField)
                    if (blockInlineObject.contentType === 'staff_staffmessage' && reduxAuth.settings?.user?.is_staff) {
                        columns = blockInlineObject.fields.filter(obj => obj !== blockInlineObject.parentModelField)
                    }
                    // TODO dynamic
                    if ((contentTypeData[contentType] === object.model_content_type_id) && blockInlineObject.contentType !== 'accounting_banktransactionmatch') {
                        columns = columns.filter(obj => obj !== 'content_object')
                    }
                    let listApiUrl = `${blockInlineObject.contentType.split('_')[0]}/list/${blockInlineObject.contentType.split('_')[1]}/?${blockInlineObject.parentModelField}=${object.id}${blockInlineObject.parentModelFieldCT ? `&${blockInlineObject.parentModelFieldCT}=${object.model_content_type_id}` : ''}`
                    if (blockInlineObject.filterParams) {
                        listApiUrl += `${blockInlineObject.filterParams}`
                    }
                    if (blockInlineObject.subContentTypes) {
                        blockInlineObject.subContentTypes.map(subcontenttype => (
                            listApiUrl += `&sub_content_type=${subcontenttype.id}`
                        ))
                    } else if (blockInlineObject.subContentType && blockInlineObject.subContentType.id !== 223) {
                        listApiUrl += `&sub_content_type=${blockInlineObject.subContentType.id}`
                    }
                    if (blockInlineObject.subContentType) {
                        columns = blockInlineObject.subContentType.fields
                    }
                    return (
                        <ListContent
                            key={val}
                            actionSheet='ActionComponent'
                            blockId={val}
                            blockInlineObject={blockInlineObject}
                            columns={columns}
                            contentType={blockInlineObject.contentType}
                            contentTypeParent={contentType}
                            contentTypeSettingsCustom={contentTypeSettingsCustom}
                            defaultFields={undefined}
                            detailObject={object}
                            inModal={inModal}
                            listApiUrl={listApiUrl}
                            parentObject={object}
                            profile={object.profile}
                            refreshData={refreshData}
                            tab={tab}
                            title={title}
                        />
                    )
                }
                return <p key={val}>Error: Missing block</p>
            })}
        </div>
    )
})
