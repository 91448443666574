// react components
import React from 'react'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    HeaderWeb,
} from 'components'

// customers
import {
    AgendaDuQuartierHeader,
    AGSLHeader,
} from 'customers'

// data
import {
    site_id,
} from 'data'

// props
type HeaderWrapperProps = {
    forced?: boolean
}

// main
export const HeaderWrapper: React.FC<HeaderWrapperProps> = React.memo(({
    forced,
}) => {

    const location = useLocation()

    if (site_id === 17) {
        return <AgendaDuQuartierHeader />
    }

    if (!forced && ['form', 'form-mixed-media', 'form-multiple'].includes(location.pathname.split('/')[1])) return null

    if (site_id === 19) {
        return <AGSLHeader forced={forced} />
    }

    return (
        <HeaderWeb forced={forced} />
    )
})
