// react components
import React from 'react'

// data
import {
    reduxModalSliderGetData,
} from 'data'

// main
export function onClickIsModal(
    objectId: number,
    modalKey: number,
    dispatch: React.Dispatch<any>,
) {
    const url = new URL(window.location.href)
    const params = url.searchParams
    params.delete('modal_page_type')
    params.delete('modal_page')
    params.delete('modal_detail_id')
    params.delete('modalTab')
    const toUrl = url.pathname + '?' + params.toString()
    dispatch(reduxModalSliderGetData(
        modalKey,
        objectId,
        toUrl,
    ))
}
