// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// containers
import {
    DetailPageContainer,
} from 'containers'

// data
import {
    api_url_project_detail,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export const ProjectDetailWeb: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    return (
        <DetailPageContainer
            apiUrl={getApiUrl(api_url_project_detail, reduxAuth)}
            app='project'
            component='ProjectDetailBlockWeb'
            componentActionSheet='ProjectActionSheetWeb'
            model='project'
        />
    )
}
