// react components
import React, {
    useEffect,
} from 'react'
import {
    Device,
} from '@capacitor/device'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxAuthApiRootUrl,
    reduxAuthDeviceInfo,
    reduxAuthSetIsFirstLoaded,
    reduxTextFetchMainText,
} from 'data'

// services
import {
    reduxAuthFetchSettingsHelper,
    removeParamsFromUrl,
    setCookie,
} from 'services'

// main
export const AgendaDuQuartierGlobalHelper: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        removeParamsFromUrl('fbclid')
        const setLanguageCode = async () => {
            const deviceLanguageCode = await Device.getLanguageCode()
            if (!reduxAuth.settings?.language) {
                dispatch(reduxAuthApiRootUrl('fr', reduxAuth))
            }
        }
        if (!reduxAuth.authenticated) setLanguageCode()

        const setDeviceInfo = async () => {
            const deviceInfo = await Device.getInfo()
            dispatch(reduxAuthDeviceInfo(deviceInfo))
        }
        setDeviceInfo()
    }, [])

    useEffect(() => {
        if (reduxAuth.authenticated) {
            reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
        }
    }, [
        reduxAuth.authenticated,
        reduxAuth.settings?.active_profile?.id,
    ])

    useEffect(() => {
        if (reduxAuth.settings?.language) {
            // @ts-ignore
            dispatch(reduxTextFetchMainText(reduxAuth, dispatch, reduxAuth.settings.language))
        }
    }, [reduxAuth.settings?.language])

    useEffect(() => {
        setCookie('mo_reduxAuthAccess', reduxAuth.access)
    }, [reduxAuth.access])

    useEffect(() => {
        setCookie('mo_reduxAuthApiDynamicRootUrl', reduxAuth.apiRootUrl)
    }, [reduxAuth.apiRootUrl])

    useEffect(() => {
        setCookie('mo_reduxAuthAuthenticated', reduxAuth.authenticated)
    }, [reduxAuth.authenticated])

    useEffect(() => {
        setCookie('mo_reduxAuthAxiosConfig', reduxAuth.axiosConfig)
    }, [reduxAuth.axiosConfig])

    useEffect(() => {
        if (reduxAuth.settings?.theme === 'dark') {
            document.body.classList.remove('light')
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
            document.body.classList.add('light')
        }
    }, [reduxAuth.settings?.theme])

    useEffect(() => {
        const newReduxAuthSettings: any = {}

        const newActiveProfile: any = {}
        newActiveProfile.id = reduxAuth.settings?.active_profile?.id
        newActiveProfile.active = reduxAuth.settings?.active_profile?.active
        newActiveProfile.get_absolute_url = reduxAuth.settings?.active_profile?.get_absolute_url
        newActiveProfile.get_image_xs = reduxAuth.settings?.active_profile?.get_image_xs
        newActiveProfile.name = reduxAuth.settings?.active_profile?.name

        const newMainProfile: any = {}
        newMainProfile.id = reduxAuth.settings?.main_profile?.id

        const newUser: any = {}
        newUser.id = reduxAuth.settings?.user?.id
        newUser.is_staff = reduxAuth.settings?.user?.is_staff
        newUser.last_login = reduxAuth.settings?.user?.last_login

        newReduxAuthSettings.id = reduxAuth.settings?.id
        newReduxAuthSettings.active_profile = newActiveProfile
        newReduxAuthSettings.language = 'fr'
        newReduxAuthSettings.main_profile = newMainProfile
        newReduxAuthSettings.quartier = reduxAuth.settings?.quartier
        newReduxAuthSettings.theme = reduxAuth.settings?.theme
        newReduxAuthSettings.user = newUser
        setCookie('mo_reduxAuthSettings', newReduxAuthSettings)
    }, [reduxAuth.settings])

    useEffect(() => {
        dispatch(reduxAuthSetIsFirstLoaded())
    }, [])

    useEffect(() => {
        document.addEventListener("wheel", function (event) {
            // @ts-ignore
            if (document?.activeElement?.type === "number") {
                // @ts-ignore
                document.activeElement.blur();
            }
        })
    }, [])

    useEffect(() => {
        Object.entries(localStorage).map(
            x => x[0]
        ).filter(
            x => x.substring(0, 7) === 'persist'
        ).map(
            x => localStorage.removeItem(x)
        )
    }, [])

    return null
}
