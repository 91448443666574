// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Popover,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// props
type AddButtonProps = {
    inModal?: boolean
    onShowModalForm: (subContentTypeId?: number, isImage?: boolean) => void
    size?: any
    subContentTypes?: {
        id?: number
        name?: string
        isImage?: boolean
    }[]
}

// main
export const AddButton: React.FC<AddButtonProps> = React.memo(({
    inModal,
    onShowModalForm,
    size,
    subContentTypes,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showActionPopover, setShowActionPopover] = useState(false)

    if (subContentTypes?.length! > 0) {

        const buttons: any = []
        subContentTypes?.forEach((val) => {
            buttons.push({
                handler: () => {
                    onShowModalForm(val.id, val.isImage)
                    setShowActionPopover(false)
                },
                text: val.name,
            })
        })

        return (
            <Popover
                buttons={buttons}
                edit={false}
                extraZIndex={inModal}
                isButtonText={reduxText[2138]}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
                size={size || 'small'}
            />
        )

    }

    return (
        <Button
            edit={false}
            fill='outline'
            onClick={() => onShowModalForm()}
            padding='0 20px 0 0'
            size={size || 'small'}
            text={reduxText[2138]}
        />
    )
})
