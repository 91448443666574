// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// props
type UnlistedButtonProps = {
    detail?: boolean
    edit: boolean
}

// main
export const UnlistedButton: React.FC<UnlistedButtonProps> = React.memo(({
    detail,
    edit,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!edit) {
        return (
            <Button
                disabled
                className={`unlisted-button-web ${detail ? 'detail' : ''}`}
                edit={edit}
                size='x-small'
                text={reduxText[6579]}
            />
        )
    }
    return null
})
