// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
    IonList,
    IonSegment,
    IonSegmentButton,
} from '@ionic/react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    IconBlock,
    ListSpinner,
    RatioZarmingHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_auth_profile_switch,
    view_url_admin,
    view_url_profile_detail_old,
} from 'data'

// serializers
import {
    SwitchProfileSelectSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    switchProfile,
} from 'services'

// props
type ProfileSwitchModalWebProps = {
    setIsOpen: React.Dispatch<boolean>
}

// main
export const ProfileSwitchModalWeb: React.FC<ProfileSwitchModalWebProps> = React.memo(({
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)
    const [isNotInSwitch, setIsNotInSwitch] = useState<string | number>('inSwitch')
    const [items, setItems] = useState<SwitchProfileSelectSerializer[]>([])

    useEffect(() => {
        onGetDetailData()
    }, [
        isNotInSwitch,
    ])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${select_url_auth_profile_switch}${isNotInSwitch === 'all' ? '?all=true' : ''}`, reduxAuth),
                component: 'ProfileSwitchModalWeb',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSwitchModalWeb',
                'onGetDetailData',
            ))
        }
    }

    function onSwitchProfile(profileId: number, profileSlug: string, isNewProfileUx: boolean) {
        try {
            switchProfile({
                component: 'ProfileSwitchModalWeb',
                dispatch,
                history,
                pathTo: isNewProfileUx ? `${view_url_admin}${profileId}/` : `${view_url_profile_detail_old}${profileSlug}/`,
                profileId,
                reduxAuth,
                setSwitchProfileIsOpen: setIsOpen,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSwitchModalWeb',
                'onSwitchProfile',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class profile-switch-modal-web'
            classes={{ paper: 'mo-dialog-wrapper fix-height fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[2665]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <IonSegment
                    color=''
                    onIonChange={(e) => setIsNotInSwitch(e.detail.value!)}
                    value={isNotInSwitch}
                >
                    <IonSegmentButton value='inSwitch'>
                        <IonLabel>{reduxText[8586]}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value='all'>
                        <IonLabel>{reduxText[5445]}</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                <IonList>
                    {isLoading && (
                        <ListSpinner isLoading />
                    )}
                    {!isLoading && items?.map((val) => {
                        return (
                            <IonItem
                                key={val.id}
                                button
                                onClick={() => onSwitchProfile(val.id!, val.slug!, val.is_new_profile_ux!)}
                            >
                                <RatioZarmingHelper
                                    borderRadius='50%'
                                    dominant_color={val.get_dominant_color}
                                    edit={false}
                                    fontSize={12}
                                    height={36}
                                    name={val.name}
                                    notZarma={Boolean(val.get_image_xs)}
                                    src={val.get_image_xs}
                                    to={undefined}
                                />
                                <IonLabel>
                                    <h3>{val.name}</h3>
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonList>
            </DialogContent>
        </Dialog>
    )
})
