// react components
import React from 'react'
import {
    Dialog,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalMainFormHide,
} from 'data'

// pages
import {
    AdherentForm,
    BlockTemplateForm,
    ContributionForm,
    CustomFormModal,
    EventCreditRequestForm,
    EventForm,
    FormModal,
    MaterialForm,
    MemberForm,
    OfferForm,
    PageTemplateForm,
    ParticipantForm,
    ProductCreditRequestForm,
    ProductForm,
    ProfileCreditRequestForm,
    ProfileForm,
    ProfileSectionForm,
    ProjectCreditRequestForm,
    ProjectForm,
    ServiceForm,
} from 'pages'

// main
export const MainFormModalWrapper: React.FC = () => {

    const dispatch = useDispatch()
    const reduxModal = useSelector((state: defaultReduxState) => state.reduxModal)

    const contentType = reduxModal.mainForm.contentType
    const contentTypeSettings = reduxModal.mainForm.contentTypeSettings
    const cssClass = reduxModal.mainForm.cssClass
    const defaultFields = reduxModal.mainForm.defaultFields
    const formComponent = reduxModal.mainForm.formComponent
    const isOpen = reduxModal.mainForm.isOpen
    const objectId = reduxModal.mainForm.objectId
    const onRefresh = reduxModal.mainForm.onRefresh
    const profileObject = reduxModal.mainForm.profileObject
    const setDeleted = reduxModal.mainForm.setDeleted

    const formListComponents: any = {
        AdherentForm,
        BlockTemplateForm,
        ContributionForm,
        CustomFormModal,
        FormModal,
        EventCreditRequestForm,
        EventForm,
        MaterialForm,
        MemberForm,
        OfferForm,
        PageTemplateForm,
        ParticipantForm,
        ProductCreditRequestForm,
        ProductForm,
        ProfileCreditRequestForm,
        ProfileForm,
        ProfileSectionForm,
        ProjectCreditRequestForm,
        ProjectForm,
        ServiceForm,
    }
    const FormComponentName = formListComponents[formComponent!]

    return (
        <Dialog
            className={`mo-dialog-class ${cssClass}`}
            classes={{ paper: 'mo-dialog-wrapper wrapper' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => dispatch(reduxModalMainFormHide())}
            open={isOpen}
        >
            {FormComponentName && (
                <FormComponentName
                    contentType={contentType}
                    contentTypeSettingsN={contentTypeSettings}
                    defaultFields={defaultFields}
                    objectId={objectId}
                    onRefresh={onRefresh}
                    profileObject={profileObject}
                    setDeleted={setDeleted}
                />
            )}
        </Dialog>
    )
}
