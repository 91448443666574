// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalPlayerOnPlayPause,
    reduxModalPlayerSetCurrentTrack,
} from 'data'

// props
type ProjectPlayButtonWebProps = {
    edit: boolean
    inDetail?: boolean
    isVideo?: boolean
    modalKey: number | undefined
    object: any
    playerOrigin: string
}

// main
export const ProjectPlayButtonWeb: React.FC<ProjectPlayButtonWebProps> = React.memo(({
    edit,
    inDetail,
    isVideo,
    modalKey,
    object,
    playerOrigin,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalplayer = useSelector((state: defaultReduxState) => state.reduxModal.player)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function handlePreviewContent(object: any) {
        try {
            if (reduxModalplayer.currentTrack?.id === object.id) {
                dispatch(reduxModalPlayerOnPlayPause(!reduxModalplayer.playing))
            } else {
                // @ts-ignore
                dispatch(reduxModalPlayerSetCurrentTrack(
                    object,
                    reduxAuth,
                    dispatch,
                    inDetail ? 'detail' : 'full',
                    playerOrigin,
                    modalKey,
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProjectPlayButtonWeb',
                'handlePreviewContent',
            ))
        }
    }

    if (inDetail && isVideo) {
        return (
            <div
                className='project-player-button-web'
                onClick={() => handlePreviewContent(object)}
            >
                <IconBlock
                    edit={edit}
                    iconClass={(reduxModalplayer.playing && reduxModalplayer.currentTrack?.id === object.id) ? 'mo-new-icon-pause-circle-regular' : 'mo-new-icon-play-circle-regular'}
                    onClick={() => handlePreviewContent(object)}
                    tooltipText={(reduxModalplayer.playing && reduxModalplayer.currentTrack?.id === object.id) ? reduxText[5886] : reduxText[5887]}
                />
            </div>
        )
    }
    if (inDetail) {
        return (
            <Button
                edit={edit}
                fill='outline'
                onClick={() => handlePreviewContent(object)}
                size='small'
                text={(reduxModalplayer.playing && reduxModalplayer.currentTrack?.id === object.id) ? reduxText[5886] : reduxText[5887]}
            />
        )
    }
    return (
        <IconBlock
            edit={edit}
            iconClass={(reduxModalplayer.playing && reduxModalplayer.currentTrack?.id === object.id) ? 'mo-new-icon-pause-circle-regular' : 'mo-new-icon-play-circle-regular'}
            onClick={() => handlePreviewContent(object)}
            tooltipText={(reduxModalplayer.playing && reduxModalplayer.currentTrack?.id === object.id) ? reduxText[5886] : reduxText[5887]}
        />
    )
})
