// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_main_currency,
} from 'data'

// serializers
import {
    ProductDetailFormSerializer,
    ProductDetailFormSerializerError,
} from 'serializers/web'

// props
type CurrencyProductInputProps = {
    errors: ProductDetailFormSerializerError | undefined
    fields: ProductDetailFormSerializer
    onChange: any
}

// main
export const CurrencyProductInput: React.FC<CurrencyProductInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <SelectListInput
            apiUrl={select_url_main_currency}
            clearable={false}
            disabled
            error={errors?.currency}
            label={reduxText[4574]}
            name='currency'
            onChange={onChange}
            value={fields.currency || ''}
        />
    )
})
