// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDropzone,
} from 'react-dropzone'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    FormLabel,
} from 'pages'

// props
type FileInputProps = {
    adminOnly?: boolean
    disabled?: boolean
    error?: string
    helperText?: string
    label?: string
    name: string
    onChange: any
    pdfOk?: boolean
    pdfOnly?: boolean
    staffOnly?: boolean
    value: any
}

// main
export const FileInput: React.FC<FileInputProps> = React.memo(({
    adminOnly,
    disabled,
    error,
    helperText,
    label,
    name,
    onChange,
    pdfOk,
    pdfOnly,
    staffOnly,
    value,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [files, setFiles] = useState<any>([])
    const [uploadError, setUploadError] = useState<string>()

    let acceptObject = {}
    if (name === 'storage_file') {
        acceptObject = {
            'application/pdf': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // .docx
            'audio/x-m4a': [],
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/svg+xml': [],
            'image/webp': [],
        }
    } else if (pdfOnly) {
        acceptObject = {
            'application/pdf': [],
        }
    } else if (pdfOk) {
        acceptObject = {
            'application/pdf': [],
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/svg+xml': [],
            'image/webp': [],
        }
    } else {
        acceptObject = {
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/webp': [],
        }
    }
    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptObject,
        maxSize: 64000000,
        onDrop: (acceptedFiles, fileRejections) => {
            const files = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            if (files[0]?.preview) {
                setUploadError(undefined)
                setFiles(files)
                onChange({
                    fileName: acceptedFiles[0].name.replace(/\.[^/.]+$/, ''),
                    name: name,
                    value: acceptedFiles[0],
                })
            }
            else {
                fileRejections.map(({ file, errors }) => {
                    const formattedErrors = errors.map(error => {
                        if (error.code === 'file-too-large') {
                            return `${reduxText[10175]} ${(64000000 / (1024 * 1024)).toFixed()}MB.`
                        } else {
                            return error.message
                        }
                    }).join(' ')
                    setUploadError(formattedErrors)
                })
            }
        }
    })

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => URL.revokeObjectURL(file.preview))
    }, [
        files,
    ])

    if (adminOnly && !reduxAuth.settings?.user?.id) return null
    if (staffOnly && !reduxAuth.settings?.user?.is_staff) return null

    const isPDF = files[0]?.type === 'application/pdf' || pdfOnly || (pdfOk && value?.endsWith('.pdf'))
    const isOtherFile = (
        files[0]?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) || (
            files[0]?.type === 'audio/x-m4a'
        ) || (
            name === 'storage_file' && (
                value?.endsWith('.docx')
            )
        )

    if (label) {
        return (
            <div className={`input-wrap${disabled ? ' disabled' : ''}`}>
                <div className='input-label-wrap'>
                    <FormLabel
                        adminOnly={adminOnly}
                        formInput={pdfOnly ? 'pdf' : 'image'}
                        helperText={helperText}
                        label={label}
                        name={name}
                        staffOnly={staffOnly}
                        translationDefaultValue={value}
                    />
                </div>
                <div className='file-input'>
                    <div className='fi-input-div full'>
                        <div {...getRootProps({ className: 'fi-image-add-wrap' })}>
                            {(value !== 'to_delete' && (files[0] || value))
                                ? (
                                    (isPDF || isOtherFile)
                                        ? (
                                            <div className='fi-input'>
                                                <p className='mo-dropzone-helper-text'>{isPDF ? 'PDF' : 'File'}</p>
                                                <p className='mo-dropzone-helper-text mo-clamp-web5'>{files[0] ? files[0].preview : (value.preview || value)}</p>
                                            </div>
                                        ) : (
                                            <img
                                                alt=''
                                                className='fi-preview-img'
                                                src={files[0] ? files[0].preview : (value.preview || value)}
                                            />
                                        )
                                ) : (
                                    <>
                                        {uploadError
                                            ? (
                                                <div className='fi-input error'>
                                                    <i className='m-icon mo-new-icon-times-solid' />
                                                    <p className='mo-dropzone-helper-text'>{uploadError}</p>
                                                </div>
                                            ) : (
                                                <div className='fi-input'>
                                                    <i className='m-icon mo-new-icon-cloud-upload-alt-solid' />
                                                    <p className='mo-dropzone-helper-text'>{reduxText[7612]}</p>
                                                </div>
                                            )
                                        }
                                    </>
                                )}
                            <input {...getInputProps()} />
                        </div>
                        {(value !== 'to_delete' && (files[0] || value)) && (
                            <div className='fi-buttons-wrap'>
                                {(isPDF || isOtherFile) && (
                                    <div className='fi-buttons-div'>
                                        <Button
                                            className='fi-button'
                                            edit={false}
                                            fill='outline'
                                            href={value.preview || value}
                                            size='x-small'
                                            text={reduxText[5316]}
                                        />
                                    </div>
                                )}
                                <div className='fi-buttons-div'>
                                    <Button
                                        className='fi-button'
                                        edit={false}
                                        fill='outline'
                                        onClick={() => {
                                            onChange({
                                                doka_image: 'to_delete',
                                                doka_output_data: 'to_delete',
                                                name: name,
                                                value: 'to_delete',
                                            })
                                        }}
                                        size='x-small'
                                        text={reduxText[5316]}
                                    />
                                </div>
                                <div {...getRootProps({ className: 'fi-buttons-div fi-dropzone-input' })}>
                                    <Button
                                        className='fi-button'
                                        edit={false}
                                        fill='outline'
                                        size='x-small'
                                        text={reduxText[5878]}
                                    />
                                    <input {...getInputProps()} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <ErrorHelper error={error} />
            </div>
        )
    }

    return (
        <div className='file-input'>
            <div className='fi-input-div'>
                <div {...getRootProps({ className: 'fi-image-add-wrap' })}>
                    {(value !== 'to_delete' && (files[0] || value))
                        ? (
                            (isPDF || isOtherFile)
                                ? (
                                    <div className='fi-input'>
                                        <p className='mo-dropzone-helper-text'>{isPDF ? 'PDF' : 'File'}</p>
                                        <p className='mo-dropzone-helper-text mo-clamp-web5'>{files[0] ? files[0].preview : (value.preview || value)}</p>
                                    </div>
                                ) : (
                                    <img
                                        alt=''
                                        className='fi-preview-img'
                                        src={files[0] ? files[0].preview : (value.preview || value)}
                                    />
                                )
                        ) : (
                            <>
                                {uploadError
                                    ? (
                                        <div className='fi-input error'>
                                            <i className='m-icon mo-new-icon-times-solid' />
                                            <p className='mo-dropzone-helper-text'>{uploadError}</p>
                                        </div>
                                    ) : (
                                        <div className='fi-input'>
                                            <i className='m-icon mo-new-icon-cloud-upload-alt-solid' />
                                            <p className='mo-dropzone-helper-text'>{reduxText[7612]}</p>
                                        </div>
                                    )
                                }
                            </>
                        )}
                    <input {...getInputProps()} />
                </div>
                {(value !== 'to_delete' && (files[0] || value)) && (
                    <div className='fi-buttons-wrap'>
                        <div className='fi-buttons-div'>
                            <Button
                                className='fi-button'
                                edit={false}
                                fill='outline'
                                onClick={() => {
                                    onChange({
                                        doka_image: 'to_delete',
                                        doka_output_data: 'to_delete',
                                        name: name,
                                        value: 'to_delete',
                                    })
                                }}
                                size='x-small'
                                text={reduxText[5316]}
                            />
                        </div>
                        <div {...getRootProps({ className: 'fi-buttons-div fi-dropzone-input' })}>
                            <Button
                                className='fi-button'
                                edit={false}
                                fill='outline'
                                size='x-small'
                                text={reduxText[5878]}
                            />
                            <input {...getInputProps()} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
})
