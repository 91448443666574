// react components
import React from 'react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ActionButton,
    Copy,
    IconBlock,
    MainCountryFlag,
    PhoneNumberHelper,
    RatioZarmingHelper,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    fieldTypeLayout,
} from 'data'

// services
import {
    getDateWithFormat,
    getReduxTextId,
} from 'services'

// serializers
import {
    ContentTypeFieldTypes,
    ContentTypeSettingsCustomTypes,
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// props
type TableColumnValueProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    field: string
    fieldSettings: ContentTypeFieldTypes | undefined
    object: any
    profileObject: ProfileDetailAdminSerializerV2 | undefined
    refreshData?: () => void
    setFilter?: (e: any, f?: any) => void
}

// main
export const TableColumnValue: React.FC<TableColumnValueProps> = React.memo(({
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    field,
    fieldSettings,
    object,
    profileObject,
    refreshData,
    setFilter,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!fieldSettings) {
        return (
            <span className='mo-error-color'>Error: Missing fieldSettings</span>
        )
    }

    let fieldSettingsCustom = contentTypeSettingsCustom?.field?.[field]

    let valueType = ''
    let fieldType = fieldSettings.fieldType

    let value = object[field]

    if (object[`${field}_is_rich`]) {
        valueType = 'rich'
        value = object[`${field}_rich`]
    }

    // TODO shoudl go under relation fields
    if (field.split('__')[1]) {
        fieldSettingsCustom = fieldSettingsCustom = {
            ...contentTypeSettingsCustom?.list?.related_fields_info?.[field],
            ...profileObject?.settings_data_json?.contentTypeSettingsCustom?.[`${contentType.split('_')[0]}_${field.split('__')[0]}`]?.field?.[field.split('__')[1]]
        }
        fieldSettingsCustom.layout = {
            // ...contentTypeSettingsCustom?.field?.[field]?.layout,
            ...profileObject?.settings_data_json?.contentTypeSettingsCustom?.[`${contentType.split('_')[0]}_${field.split('__')[0]}`]?.field?.[field.split('__')[1]]?.layout
        }
        // fieldSettings.fieldType = fieldSettingsCustom?.fieldType!
        fieldType = fieldSettingsCustom?.fieldType!
        valueType = fieldTypeLayout[fieldType!]
        const splitField = field.split('__')
        if (splitField.length === 2) {
            value = object[splitField[0]]?.[splitField[1] || 'name']
        } else if (splitField.length === 3) {
            value = object[splitField[0]]?.[splitField[1]]?.[splitField[2] || 'name']
        } else {
            value = object[field]?.name
        }
    }
    if (!valueType) valueType = fieldTypeLayout[fieldType]

    if (fieldType === 'location') {
        return (
            value?.map((val: any, i: number) => (
                <span key={i}>{val.description}</span>
            ))
        )
    }

    if (fieldSettings.layout?.isIcon) {
        return (
            <div className='table-column-value zisIcon tcv-icon'>
                <IconBlock
                    edit={false}
                    iconClass='mo-new-icon-link-solid'
                />
            </div>
        )
    }

    if (valueType === 'array') {
        const fieldSplit = field.split('__')
        const newField = fieldSplit[0]
        let arrayField = fieldSplit[1] || 'name'
        const arrayFieldOptions = fieldSettings.fields?.[arrayField]
        if (arrayFieldOptions) {
            return (
                object[newField]?.map((val: any) => (
                    <TableColumnValue
                        key={val.id}
                        contentType={contentType}
                        contentTypeSettings={contentTypeSettings}
                        contentTypeSettingsCustom={contentTypeSettingsCustom}
                        field={arrayField}
                        fieldSettings={arrayFieldOptions}
                        object={val}
                        profileObject={profileObject}
                        refreshData={refreshData}
                        setFilter={setFilter}
                    />
                ))
            )
        }
        if (field.startsWith('attributions_many_to_many_field_')) {
            const hasNameForForeignKey = object.attributions_list?.some((item: any) => item.hasOwnProperty('name_for_foreign_key'))
            if (arrayField === 'name' && hasNameForForeignKey) {
                arrayField = 'name_for_foreign_key'
            }
            return <span className={`table-column-value zattributions_many_to_many_field tcv-${valueType}`}>{object.attributions_list?.filter((obj: any) => obj.attribution_category === contentTypeSettingsCustom?.form?.fieldsOptions?.[field]?.selectUrlParams?.attribution_categories)?.map((val: any) => val?.[arrayField]).join(', ')}</span>
        }
        const hasNameForForeignKey = object[newField]?.some((item: any) => item.hasOwnProperty('name_for_foreign_key'))
        if (arrayField === 'name' && hasNameForForeignKey) {
            arrayField = 'name_for_foreign_key'
        }
        return <span className={`table-column-value zattributions_many_to_many_field-2 tcv-${valueType}`}>{object[newField]?.map((val: any) => val[arrayField]).join(', ')}</span>
    }

    if (valueType === 'boolean') {
        return (
            <i className={`table-column-value zboolean tcv-${valueType} ${value ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} />
        )
    }

    if (valueType === 'image') {
        return (
            <RatioZarmingHelper
                dominant_color={object.get_dominant_color}
                edit={false}
                fontSize={16}
                height={fieldSettings.layout?.listImageSize || 30}
                lightBox
                lightBoxSrc={value}
                name={object.name}
                notZarma={Boolean(value)}
                src={value}
                to={undefined}
            />
        )
    }

    if (valueType === 'datetime') {
        return (
            <span className={`table-column-value zdatetime tcv-${valueType}`}>
                {getDateWithFormat(
                    reduxAuth,
                    value,
                    fieldSettingsCustom?.layout?.dateFormat ||
                    fieldSettings.layout?.dateFormat ||
                    ((fieldType === 'DateField' && object.date_type_choice) && (object.date_type_choice.id === 'y' ? 'YYYY' : (object.date_type_choice.id === 'm' ? 'MM/YYYY' : 'DD/MM/YYYY'))) ||
                    ((fieldType === 'DateField' || object.date_only) && 'DD/MM/YYYY') ||
                    ((fieldType === 'DateTimeField') && 'DD/MM/YYYY HH:mm') ||
                    undefined
                )}
            </span>
        )
    }

    if (valueType === 'number') {
        if (fieldSettings.layout?.isCurrency) {
            value = Number(value).toLocaleString('fr', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            // return (
            //     <span className={`table-column-value znumber-3 tcv-${valueType}`}>{value} {object.currency?.symbol}</span>
            // )€
            return (
                <span className={`table-column-value znumber tcv-${valueType}`}>{value} €</span>
            )
        }
        // value = Number(value).toLocaleString('fr')
        value = (typeof value !== undefined && value !== null) ? Number(value) : value
        return (
            <span className={`table-column-value znumber-2 tcv-${valueType}`}>{value}</span>
        )
    }

    if (valueType === 'phonenumber') {
        return (
            <span className={`table-column-value zphonenumber tcv-${valueType}`}><PhoneNumberHelper value={value} /></span>
        )
    }

    if (valueType === 'rich') {
        return (
            <div
                className={`table-column-value zrich tcv-${valueType}`}
                dangerouslySetInnerHTML={{ __html: value }}
            />
        )
    }

    if (valueType === 'text') {
        let valueColor = ''
        if (['ChoiceField', 'ForeignKey', 'GenericForeignKey', 'OneToOneField'].includes(fieldType)) {
            if (fieldSettings.layout?.isCountry) {
                return (
                    <MainCountryFlag
                        edit={false}
                        object={value}
                        searchTab={undefined}
                        namePosition='end'
                    />
                )
            }
            const splitField = field.split('__')
            let valueId = ''
            if (splitField.length === 2) {
                value = object[splitField[0]]?.[splitField[1]]?.['name_for_foreign_key'] || object[splitField[0]]?.[splitField[1]]?.['name']
                valueId = object[splitField[0]]?.id
                valueColor = object[splitField[0]]?.color
            } else if (splitField.length === 3) {
                value = object[splitField[0]]?.[splitField[1]]?.['name_for_foreign_key'] || object[splitField[0]]?.[splitField[1]]?.['name']
                valueId = object[splitField[0]]?.[splitField[1]]?.id
                valueColor = object[splitField[0]]?.[splitField[1]]?.color
            } else {
                value = object[field]?.name_for_foreign_key || object[field]?.name
                valueId = object[field]?.id
                valueColor = object[field]?.color
            }
            if (fieldSettingsCustom?.layout?.isBoolean) {
                if (fieldSettingsCustom.layout.isBooleanValueFalse === String(valueId)) {
                    return (
                        <i className={`table-column-value ztext-1 tcv-boolean mo-new-icon-times-solid`} />
                    )
                }
                if (fieldSettingsCustom.layout.isBooleanValueTrue === String(valueId)) {
                    return (
                        <i className={`table-column-value ztext-2 tcv-boolean mo-new-icon-check-circle-solid`} />
                    )
                }
            }
        }
        if (object[`${field}_is_rich`]) {
            return (
                <div
                    className={`table-column-value ztext-3 tcv-${valueType}`}
                    dangerouslySetInnerHTML={{ __html: object[`${field}_rich`] }}
                />
            )
        }
        return (
            <>
                {value && (
                    <>
                        {(fieldSettings.layout?.hasCopyButton || fieldSettingsCustom?.layout?.hasCopyButton) && (
                            <Copy
                                className='table-column-value-copy'
                                isIcon
                                text={value}
                            />
                        )}
                        {fieldSettingsCustom?.layout?.hasFilterButton && setFilter && (
                            <i
                                className='table-column-value-copy main-icon mo-new-icon-filter-solid pointer'
                                onClick={() => {
                                    setFilter(
                                        {
                                            value: value,
                                            params: field
                                        },
                                        {
                                            value: undefined,
                                            params: 'search'
                                        }
                                    )
                                }}
                            />
                        )}
                    </>
                )}
                <span
                    className={`table-column-value ztext-4 tcv-${valueType}`}
                    style={{
                        color: valueColor,
                    }}
                >
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                        {value}
                    </Linkify>
                </span>
            </>
        )
    }

    if (valueType === 'time') {
        return (
            <span className={`table-column-value ztime tcv-${valueType}`}>
                {getDateWithFormat(
                    reduxAuth,
                    value,
                    fieldSettingsCustom?.layout?.dateFormat ||
                    fieldSettings.layout?.dateFormat ||
                    'HH:mm',
                    true,
                )}
            </span>
        )
    }

    if (valueType === 'url') {
        return (
            <span className={`table-column-value zurl tcv-${valueType}`}>{value}</span>
        )
    }

    if (valueType === 'text_redux') {
        return (
            <span className={`table-column-value ztext_redux tcv-${valueType}`}>{getReduxTextId(value, reduxText)}</span>
        )
    }

    if (valueType === 'mixed') {
        if (value?.field_type === 'BooleanField') {
            return (
                <i className={`table-column-value zmixed-1 tcv-boolean ${value.value ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} />
            )
        }
        if (value?.field_type === 'JSONField') {
            return (
                <span className={`table-column-value zmixed-2 tcv-${valueType}`}>{JSON.stringify(value?.value)}</span>
            )
        }
        return (
            <span className={`table-column-value zmixed-3 tcv-${valueType}`}>{value?.value}</span>
        )
    }

    if (valueType === 'action_button' && value) {
        return (
            <ActionButton
                actionObject={value}
                contentTypeId={contentTypeSettings.meta.model_content_type_id}
                objectId={object.id}
                refreshData={refreshData}
            />
        )
    }

    return (
        <span className='mo-error-color'>Error: Missing valueType for {fieldType}</span>
    )
})
