// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonLabel,
    IonList,
    IonItem,
    IonRadio,
    IonReorder,
    IonRadioGroup,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    InfiniteScrollHelperWeb,
} from 'components'

// data
import {
    api_url_custom_feed_section_list,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_feed_list,
} from 'data'

// serializers
import {
    CustomFeedSectionListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getInfiniteListWeb,
} from 'services'

// props
type FeedMenuBlockWebProps = {
    activeProfile?: boolean
    sectionId: number
    setSection: React.Dispatch<CustomFeedSectionListSerializer>
    setSectionList: React.Dispatch<CustomFeedSectionListSerializer[]>
}

// main
export const FeedMenuBlockWeb: React.FC<FeedMenuBlockWebProps> = React.memo(({
    activeProfile,
    sectionId,
    setSection,
    setSectionList,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<CustomFeedSectionListSerializer[]>([])
    const [nextUrl, setNextUrl] = useState('')

    useEffect(() => {
        onGetListData(
            getApiUrl(
                `${api_url_custom_feed_section_list}${activeProfile ? `${reduxAuth.settings?.active_profile?.id ? `?profile_id=${reduxAuth.settings.active_profile.id}` : ''}` : ''}`,
                reduxAuth,
            ),
            true,
        )
    }, [
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshFeedSection) {
            onGetListData(
                getApiUrl(
                    `${api_url_custom_feed_section_list}${activeProfile ? `${reduxAuth.settings?.active_profile?.id ? `?profile_id=${reduxAuth.settings.active_profile.id}` : ''}` : ''}`,
                    reduxAuth,
                ),
                true,
                true,
            )
        }
    }, [
        reduxForm.refresh.refreshFeedSection,
    ])

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        noEmpty?: boolean
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'FeedMenuBlockWeb',
                customFunction: activeProfile ? undefined : setSectionList,
                dispatch,
                items,
                noEmpty,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedMenuBlockWeb',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedMenuBlockWeb',
                'onSearchNext',
            ))
        }
    }

    function onSectionClick(section: CustomFeedSectionListSerializer) {
        history.push(`${view_url_feed_list}${section.id}/${section.slug}/`)
        setSection(section)
    }

    return (
        <React.Fragment>
            <IonList>
                <IonRadioGroup value={sectionId || items[0]?.id}>
                    {items.map((val) => (
                        <IonItem
                            key={val.id}
                            className='fsdw-tab-item'
                            onClick={() => onSectionClick(val)}
                        >
                            <IonLabel>{val.name}</IonLabel>
                            <IonRadio
                                slot='end'
                                value={val.id}
                            />
                            <IonReorder slot='end' />
                        </IonItem>
                    ))}
                </IonRadioGroup>
            </IonList>
            <InfiniteScrollHelperWeb
                active={!disableInfiniteScroll}
                hasMore={hasMore}
                isLoading={isLoading}
                items={items}
                noEndText
                onChange={onSearchNext}
            />
        </React.Fragment>
    )
})
