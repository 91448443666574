// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonButton,
    IonButtons,
    IonHeader,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonToolbar,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useHistory,
    useLocation,
} from 'react-router-dom'

// components
import {
    AupouCurrentLocationHelper,
    Button,
    IconBlock,
    ImageHelper,
    LinkHelper,
    MenuFormsWeb,
    ProfilePopover,
    RatioHelper,
} from 'components'

// data
import {
    defaultReduxState,
    media_url,
    media_version,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    view_url_search,
    view_url_staff,
} from 'data'

// services 
import {
    changeLanguage,
    changeTheme,
} from 'services'

// props
type HeaderWebProps = {
    forced?: boolean
}

// main
export const HeaderWeb: React.FC<HeaderWebProps> = React.memo(({
    forced,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        const searhQuery = location.search?.split('?')[1]?.split('=')[1]
        if (searhQuery && location.search?.split('?')[1]?.split('=')[0] === 'search') {
            setSearchInput(searhQuery)
        }
    }, [
        location.search,
    ])

    const [searchInput, setSearchInput] = useState('')
    const [showMenuForm, setShowMenuForm] = useState(false)

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                history.push(`${view_url_search}?search=${event.target.value}`)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HeaderWeb',
                'onSearchKeyPress',
            ))
        }
    }

    if (!forced && ['form', 'form-mixed-media', 'form-multiple'].includes(location.pathname.split('/')[1])) return null

    return (
        <IonHeader className={`mo-header-web fixed-height ${forced ? 'forced' : ''}${process.env.NODE_ENV === 'development' ? ' dev-color' : ''}`}>
            <IonToolbar className='ech-main-toolbar'>
                <div className='ech-center-wrap'>
                    <LinkHelper
                        edit={false}
                        href='https://www.justtheweb.io/'
                    >
                        <ImageHelper
                            alt=''
                            className='mo-logo'
                            dominant_color={undefined}
                            src={`${media_url}global/logo-${reduxAuth.settings?.theme === 'light' ? 'black' : 'white'}.svg${media_version}`}
                        />
                    </LinkHelper>
                    {reduxAuth.settings?.user?.is_staff && (
                        <>
                            <IonSearchbar
                                cancelButtonText={reduxText[2334]}
                                className='ech-searchbar'
                                onIonCancel={() => {
                                    history.push(
                                        view_url_search,
                                        { clearSearch: Math.random() },
                                    )
                                }}
                                onKeyPress={(e) => onSearchKeyPress(e)}
                                placeholder={reduxText[282]}
                                ref={searchBarRef}
                                showCancelButton={[view_url_search].includes(location.pathname) ? 'always' : 'never'}
                                value={searchInput}
                            />
                            <IconBlock
                                edit={false}
                                iconClass='mo-new-icon-search-solid'
                                tooltipText={reduxText[282]}
                                onClick={() => {
                                    if (searchBarRef.current) {
                                        const searchText = searchBarRef.current.value
                                        history.push(`${view_url_search}${searchText ? `?search=${searchText}` : ''}`)
                                    }
                                }}
                            />
                        </>
                    )}
                </div>
                <IonButtons
                    className='ech-right-wrap'
                    slot='end'
                >
                    {/* {reduxAuth.settings?.active_profile?.id === 193057 && (
                        <Button
                            edit={false}
                            fill='outline'
                            onClick={() => dispatch(reduxModalMainFormShow({
                                contentType: 'staff_staffmessage',
                                cssClass: 'form-container-modal',
                                defaultFields: {
                                    is_visible_to_customer: true,
                                    profile_to: { id: 1 },
                                    project: { id: 2327 },
                                    status: { id: 7 },
                                },
                                formComponent: 'FormModal',
                                objectId: 'new',
                                profileObject: reduxAuth.settings?.main_profile,
                                refreshData: undefined,
                            }))}
                            padding='0 5px'
                            size='x-small'
                            text='Ajouter un retour'
                        />
                    )} */}
                    <AupouCurrentLocationHelper />
                    {reduxAuth.settings?.user?.is_staff && (
                        <>
                            <Button
                                edit={false}
                                fill='outline'
                                onClick={() => dispatch(reduxModalMainFormShow({
                                    contentType: 'staff_staffmessage',
                                    cssClass: 'form-container-modal',
                                    refreshData: undefined,
                                    customformState: {
                                        fieldsForm: {
                                            formJson: [
                                                'payload',
                                            ],
                                        },
                                    },
                                    formComponent: 'CustomFormModal',
                                    objectId: 'new',
                                    profileObject: undefined,
                                }))}
                                padding='0 5px'
                                size='x-small'
                                text={`Bulk ${reduxText[9817]}`}
                            />
                            <Button
                                edit={false}
                                fill='outline'
                                onClick={() => dispatch(reduxModalMainFormShow({
                                    contentType: 'staff_staffmessage',
                                    cssClass: 'form-container-modal',
                                    formComponent: 'FormModal',
                                    objectId: 'new',
                                    profileObject: reduxAuth.settings?.main_profile,
                                    refreshData: undefined,
                                }))}
                                padding='0 5px'
                                size='x-small'
                                text={reduxText[9817]}
                            />
                            <NavLink
                                activeClassName='ech-active'
                                className='ech-navlink'
                                to={view_url_staff}
                            >
                                Staff
                            </NavLink>
                        </>
                    )}
                    {/* {(reduxAuth.settings?.user?.id === 1) && (
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-layout-784'
                            tooltipText='Add new block template'
                            to={`${view_url_block_template_form}new/main/main-panel/`}
                        />
                    )} */}
                    {reduxAuth.authenticated && !reduxAuth.settings?.active_profile?.is_new_profile_ux && (
                        <Button
                            edit={false}
                            onClick={() => setShowMenuForm(true)}
                            padding='0 5px'
                            size='small'
                            text={reduxText[2138]}
                        />
                    )}
                    {reduxAuth.settings?.user?.id === 1 && (
                        <Button
                            edit={false}
                            fill='outline'
                            onClick={() => setShowMenuForm(true)}
                            padding='0 5px'
                            size='x-small'
                            text={reduxText[2138]}
                        />
                    )}
                    {reduxAuth.authenticated && reduxAuth.settings?.active_profile && (
                        <IonButton
                            className={`ech-link ${(location.pathname === reduxAuth.settings.active_profile.get_absolute_url) ? 'active' : ''}`}
                            routerLink={reduxAuth.settings?.active_profile?.get_absolute_url}
                            size='small'
                        >
                            <div className='ech-profile-thumb'>
                                <RatioHelper
                                    alt={reduxAuth.settings.active_profile.name}
                                    borderRadius='50%'
                                    dominant_color={reduxAuth.settings.active_profile.get_dominant_color}
                                    edit={false}
                                    src={reduxAuth.settings.active_profile.get_image_xs}
                                    to={undefined}
                                />
                            </div>
                        </IonButton>
                    )}
                    {reduxAuth.authenticated && (
                        <ProfilePopover />
                    )}
                    <IconBlock
                        edit={false}
                        iconClass={reduxAuth.settings?.theme === 'light' ? 'mo-new-icon-moon-solid' : 'mo-new-icon-sunny-sharp'}
                        tooltipText={reduxAuth.settings?.theme === 'light' ? reduxText[8602] : reduxText[8601]}
                        onClick={() => changeTheme({
                            component: 'HeaderWeb',
                            dispatch,
                            reduxAuth,
                            value: reduxAuth.settings?.theme === 'light' ? 'dark' : 'light',
                        })}
                    />
                    {reduxAuth.settings?.language && (
                        <IonSelect
                            interface='popover'
                            onIonChange={(e) => changeLanguage({
                                component: 'HeaderWeb',
                                dispatch,
                                reduxAuth,
                                value: e.detail.value,
                            })}
                            value={reduxAuth.settings?.language}
                        >
                            <IonSelectOption value='en'>EN</IonSelectOption>
                            <IonSelectOption value='fr'>FR</IonSelectOption>
                        </IonSelect>
                    )}
                </IonButtons>
            </IonToolbar>
            {showMenuForm && (
                <MenuFormsWeb
                    setIsOpen={setShowMenuForm}
                />
            )}
        </IonHeader>
    )
})
