// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    errorMessageToSkip,
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// main
export async function getInfiniteListWeb({
    apiUrl,
    axiosCancelToken,
    changingView,
    clusterIds,
    component,
    customFunction,
    dispatch,
    items,
    noEmpty,
    reduxAuth,
    searchValue,
    setAxiosCancelToken,
    setDisableInfiniteScroll,
    setHasMore,
    setIsLoading,
    setItems,
    setNextUrl,
}: {
    apiUrl: string
    axiosCancelToken: CancelTokenSource | undefined
    changingView: boolean
    clusterIds?: any[]
    component: string
    customFunction?: any
    dispatch: React.Dispatch<any>
    items: any[]
    noEmpty?: boolean
    reduxAuth: reduxAuthState
    searchValue?: string | number
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setDisableInfiniteScroll: React.Dispatch<any>
    setHasMore: React.Dispatch<any>
    setIsLoading?: React.Dispatch<any>
    setItems: React.Dispatch<any>
    setNextUrl: React.Dispatch<any>
}) {

    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
    setDisableInfiniteScroll(true)

    if (!apiUrl) return

    if (changingView) {
        if (!noEmpty) {
            setItems([])
        }
        setHasMore(true)
        setNextUrl('')
    }

    if (setIsLoading) setIsLoading(true)

    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getInfiniteListWeb`)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    if (process.env.NODE_ENV === 'development') console.log(apiUrl, component)

    const formData: any = new FormData()
    if (clusterIds) {
        formData.append('ids_cluster', clusterIds.join(','))
    }

    axios({
        data: formData,
        headers: refreshAxiosHeaders,
        method: clusterIds ? 'post' : 'get',
        url: apiUrl,
        cancelToken: source.token,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            let newItems = []
            if (changingView) {
                newItems = response.data.results
            } else {
                newItems = [...items, ...response.data.results]
            }
            setItems(newItems)
            setNextUrl(response.data.next)
            setHasMore(response.data.next !== null)
            setDisableInfiniteScroll(response.data.next === null)
            if (customFunction) customFunction(response.data.results, searchValue, response.data, newItems)
            if (setIsLoading) setIsLoading(false)
        })
        .catch((error) => {
            if (error.message === 'axios canceled') {
                if (process.env.NODE_ENV === 'development') console.log(error)
                return
            }
            if (setIsLoading) setIsLoading(false)
            if (errorMessageToSkip.includes(error.message)) {
                setDisableInfiniteScroll(false)
            } else if (errorMessageToSkip.includes(error)) {
                setDisableInfiniteScroll(false)
            } else {
                setDisableInfiniteScroll(true)
            }
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'getInfiniteListWeb',
                skipSendError: (error.response?.status === 404) && (['Invalid page.', 'Page non valide.'].includes(error.response?.data?.detail)),
            })
        })
}
