// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// main
export const AdherentContributionListFormHeader: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <tr className='main-list-header AdherentContributionListFormHeader'>
            <th>{reduxText[8557]}</th>
            <th>{reduxText[8613]}</th>
            <th>{reduxText[8570]}</th>
            <th>{reduxText[8553]}</th>
            <th>{reduxText[8554]}</th>
            <th />
        </tr>
    )
}
