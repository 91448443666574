// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonButton,
    IonItemDivider,
} from '@ionic/react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import axios, {
    AxiosRequestConfig,
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    DeleteAlert,
    ErrorHelper,
    IconBlock,
    ImageCropInput,
    ImageHelper,
    ImageInput,
    InfiniteScrollHelperWeb,
    Loader,
    LocationInput,
    MainCountryFlag,
    RichTextInput,
    SelectListInput,
    TextAreaInput,
    TextInput,
} from 'components'

// data
import {
    api_url_translation_form_create,
    api_url_translation_form_detail,
    api_url_translation_form_list,
    api_url_translation_form_update,
    contentTypeData,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxModalErrorEventHandler,
    select_url_main_language_short,
} from 'data'

// serializers
import {
    GlobalTranslationListFormSerializer,
    MainFontSerializer,
    MainIdNameSerializer,
    TranslationHelper,
} from 'serializers/web'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getInfiniteListWeb,
} from 'services'

// props
type TranslationFormProps = {
    fieldName: string
    fonts: MainFontSerializer[] | undefined
    formInput: 'address_json' | 'image' | 'image_crop' | 'pdf' | 'text' | 'textArea' | 'richText'
    onRefresh?: () => void
    setIsOpen: React.Dispatch<boolean>
    tinymceStylesObject: any
    tinymceStylesString: string
    translation: TranslationHelper
    translationDefaultValue: string | number
}

// main
export const TranslationForm: React.FC<TranslationFormProps> = React.memo(({
    fieldName,
    fonts,
    formInput,
    onRefresh,
    setIsOpen,
    tinymceStylesObject,
    tinymceStylesString,
    translation,
    translationDefaultValue,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const listApiUrl = getApiUrl(`${api_url_translation_form_list}${translation?.objectId}/?field_name=${fieldName}&content_type=${contentTypeData[translation?.contentType!]}`, reduxAuth)

    type errorsType = {
        image?: string
        image_doka_output_data?: any
        image_original?: string
        language?: string
        non_field_errors?: string
        pdf_file?: string
        translation_default_language?: string
        value?: string
    }
    type fieldsType = {
        id?: number
        image?: any
        image_doka_output_data?: any
        image_original?: any
        language?: MainIdNameSerializer
        pdf_file?: any
        preset_colors?: string[]
        translation_default_language?: MainIdNameSerializer
        value?: string
    }
    const errorsInitial = {
        image: '',
        language: '',
        non_field_errors: '',
        pdf_file: '',
        translation_default_language: '',
        value: '',
    }
    const fieldsInitial = {
        id: undefined,
        image: undefined,
        image_doka_output_data: undefined,
        image_original: undefined,
        language: undefined,
        pdf_file: undefined,
        translation_default_language: undefined,
        value: undefined,
    }

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<GlobalTranslationListFormSerializer[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [deleteSubMessage, setDeleteSubMessage] = useState<string>()
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [newDefaultLanguage, setNewDefaultLanguage] = useState<MainIdNameSerializer>()
    const [newFormInput, setNewFormInput] = useState<'default' | 'address_json' | 'image' | 'image_crop' | 'pdf' | 'text' | 'textArea' | 'richText' | undefined>(undefined)
    const [toDeleteId, setToDeleteId] = useState<number | undefined>(undefined)
    const [usedLanguages, setUsedLanguages] = useState<number[]>([])

    useEffect(() => {
        setNewFormInputHelper()
        onGetListData(listApiUrl, true)
    }, [])

    useEffect(() => {
        onSetUsedLanguage()
    }, [
        items,
        newDefaultLanguage,
    ])

    async function handleDelete() {
        try {
            setIsDeleting(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TranslationForm handleDelete')
            const deleteUrl = getApiUrl(`${api_url_translation_form_update}${toDeleteId}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    onGetListData(listApiUrl, true)
                    resetForm()
                })
                .catch((error) => {
                    setIsDeleting(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'TranslationForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'handleDelete',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            const name = event.name
            if (event.name === 'image_crop') {
                setFields({
                    ...fields,
                    image: event.doka_image,
                    image_doka_output_data: JSON.stringify(event.doka_output_data),
                    image_original: event.value,
                })
                setErrors({
                    ...errors,
                    image: '',
                    image_doka_output_data: '',
                    image_original: '',
                })  // should be after setFields to avoid bug on TextArea
            } else {
                setFields({ ...fields, [name]: event.value })
                setErrors({ ...errors, [name]: '' })  // should be after setFields to avoid bug on TextArea
            }
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                init: true,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'handleInputChange',
            ))
        }
    }

    async function handleInlineRetrieve(clickedObject: GlobalTranslationListFormSerializer) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TranslationForm handleInlineRetrieve')
            const getUrl = getApiUrl(`${api_url_translation_form_detail}${clickedObject.id}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setNewFormInputHelper()
                    setErrors(errorsInitial)
                    setFields(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'TranslationForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineRetrieve',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'handleInlineRetrieve',
            ))
        }
    }

    async function handleRetrieveDefault() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TranslationForm handleRetrieveDefault')
            const getUrl = `${translation!.defaultDetailUrl}${translation!.objectId}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setNewFormInput('default')
                    setErrors(errorsInitial)
                    setFields(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'TranslationForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleRetrieveDefault',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'handleRetrieveDefault',
            ))
        }
    }

    function onSetUsedLanguage() {
        try {
            const arryL: number[] = []
            arryL.push(newDefaultLanguage?.id! || translation!.defaultLanguage?.id!)
            items.map(val => {
                arryL.push(val.language?.id!)
            })
            setUsedLanguages(arryL)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'onGteListData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'TranslationForm',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,  // TODO optimise
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'onGteListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'onSearchNext',
            ))
        }
    }

    async function onSubmit() {
        try {

            if (!translation!.objectId) return

            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TranslationForm onSubmit')

            if (!fields.language?.id) {
                setErrors({ ...errors, language: reduxText[2856] })
                return
            }
            if (['text', 'textArea', 'richText'].includes(newFormInput!) && !fields.value) {
                setErrors({ ...errors, value: reduxText[2856] })
                return
            }
            if (['image_crop'].includes(newFormInput!) && !fields.image) {
                setErrors({ ...errors, image: reduxText[2856] })
                return
            }
            if (['pdf'].includes(newFormInput!) && !fields.pdf_file) {
                setErrors({ ...errors, pdf_file: reduxText[2856] })
                return
            }

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
            })

            let axiosUrl = getApiUrl(api_url_translation_form_create, reduxAuth)

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            if (fields.id) {
                axiosMethod = 'put'
                axiosUrl = getApiUrl(`${api_url_translation_form_update}${fields.id}/`, reduxAuth)
            }

            const formData: any = new FormData()
            formData.append('content_type', contentTypeData[translation!.contentType])
            formData.append('field_name', fieldName)
            formData.append('language', fields.language.id)
            formData.append('object_id', translation!.objectId)
            if (newFormInput === 'richText') {
                formData.append('is_rich_text', 'true')
                formData.append('value_rich', fields.value!)
            } else if (['address_json'].includes(formInput!)) {
                formData.append('value', JSON.stringify(fields.value))
            } else if (['image'].includes(formInput!)) {
                if (fields.image.type) {
                    const filetoUpload = fields.image
                    let filetoUploadName = fields.image.name
                    if (filetoUploadName.length > 100) {
                        filetoUploadName = filetoUploadName.slice(filetoUploadName.length - 100)
                    }
                    formData.append('image', filetoUpload, filetoUploadName)
                    formData.append('is_rich_text', 'false')
                }
            } else if (['image_crop'].includes(formInput!)) {
                if (fields.image.type) {
                    const filetoUpload = fields.image
                    let filetoUploadName = fields.image.name
                    if (filetoUploadName.length > 100) {
                        filetoUploadName = filetoUploadName.slice(filetoUploadName.length - 100)
                    }
                    formData.append('image', filetoUpload, filetoUploadName)
                    const filetoUploadOrignal = fields.image_original
                    let filetoUploadNameOrignal = fields.image_original.name
                    if (filetoUploadNameOrignal.length > 100) {
                        filetoUploadNameOrignal = filetoUploadNameOrignal.slice(filetoUploadNameOrignal.length - 100)
                    }
                    formData.append('image_original', filetoUploadOrignal, filetoUploadNameOrignal)
                    formData.append('image_doka_output_data', fields.image_doka_output_data)
                    formData.append('is_rich_text', 'false')
                }
            } else if (['pdf'].includes(formInput!)) {
                if (fields.pdf_file?.preview) {
                    const filetoUpload = fields.pdf_file
                    let filetoUploadName = fields.pdf_file.name
                    if (filetoUploadName.length > 100) {
                        filetoUploadName = filetoUploadName.slice(filetoUploadName.length - 100)
                    }
                    formData.append('pdf_file', filetoUpload, filetoUploadName)
                    formData.append('is_rich_text', 'false')
                }
            } else {
                formData.append('is_rich_text', 'false')
                formData.append('value', fields.value!)
            }

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    onGetListData(listApiUrl, true)
                    resetForm()
                    if (onRefresh) onRefresh()
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                    })
                    if (error.response) {
                        setErrors(error.response && error.response.data)
                    }
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'TranslationForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'onSubmit',
            ))
        }
    }

    async function onSubmitLanguage() {
        try {

            if (!translation!.objectId) return

            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TranslationForm onSubmitLanguage')

            if (!fields.translation_default_language?.id) {
                setErrors({ ...errors, language: reduxText[2856] })
                return
            }

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
            })

            const patchUrl = `${translation!.defaultUpdateUrl}${fields.id}/`

            const formData: any = new FormData()
            formData.append('translation_default_language', fields.translation_default_language.id)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'patch',
                url: patchUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (response.data.translation_default_language === fields.translation_default_language?.id) {
                        setNewDefaultLanguage(fields.translation_default_language)
                    }
                    onGetListData(listApiUrl, true)
                    resetForm()
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                    })
                    if (error.response) {
                        setErrors(error.response && error.response.data)
                    }
                    axiosErrorHandler({
                        apiUrl: patchUrl,
                        component: 'TranslationForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSubmitLanguage',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'onSubmitLanguage',
            ))
        }
    }

    let wto: any

    function resetForm() {
        try {
            setNewFormInputHelper()
            setHelpers(reduxFormInitialHelpers)
            setFields(fieldsInitial)
            setErrors(errorsInitial)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TranslationForm',
                'resetForm',
            ))
        }
    }

    function setNewFormInputHelper() {
        setNewFormInput(undefined)
        clearTimeout(wto)
        wto = setTimeout(() => {
            setNewFormInput(formInput)
        }, 100)
    }

    return (
        <Dialog
            className='mo-dialog-class inline-form half translation-form'
            classes={{ paper: 'mo-dialog-wrapper' }}
            disableEnforceFocus
            fullWidth
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[6582]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className='mo-dialog-content no-padding'>
                <div className='mo-dialog-content-left'>
                    <div className={`mo-dialog-content-form-children mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <SelectListInput
                            apiUrl={`${select_url_main_language_short}?profile=${translation?.profileId}`}
                            clearable={false}
                            error={newFormInput === 'default' ? errors?.translation_default_language : errors?.language}
                            excludeArray={newFormInput === 'default' ? undefined : usedLanguages}
                            inModal
                            label={reduxText[4825]}
                            name={newFormInput === 'default' ? 'translation_default_language' : 'language'}
                            onChange={handleInputChange}
                            value={(newFormInput === 'default' ? fields.translation_default_language : fields.language) || ''}
                        />
                        {newFormInput === 'address_json' && (
                            <LocationInput
                                error={errors?.value}
                                label={reduxText[7666]}
                                name='value'
                                onChange={handleInputChange}
                                multi
                                // @ts-ignore
                                value={fields.value || ''}
                            />
                        )}
                        {newFormInput === 'image' && (
                            <ImageInput
                                error={errors?.image}
                                label={reduxText[7666]}
                                name='image'
                                onChange={handleInputChange}
                                text={reduxText[3748]}
                                value={fields.image || ''}
                            />
                        )}
                        {newFormInput === 'image_crop' && (
                            <ImageCropInput
                                doka_output_data={fields.image_doka_output_data || ''}
                                error={errors?.image}
                                label={reduxText[7666]}
                                name='image_crop'
                                onChange={handleInputChange}
                                original={fields.image_original || ''}
                                value={fields.image || ''}
                            />
                        )}
                        {newFormInput === 'pdf' && (
                            <ImageInput
                                error={errors?.value}
                                label={reduxText[7666]}
                                name='pdf_file'
                                onChange={handleInputChange}
                                pdfOk
                                pdfOnly
                                text={reduxText[6602]}
                                value={fields.value || ''}
                            />
                        )}
                        {newFormInput === 'text' && (
                            <TextInput
                                directionRtl={fields.language?.id === 19}
                                error={errors?.value}
                                label={reduxText[7666]}
                                name='value'
                                onChange={handleInputChange}
                                value={fields.value || ''}
                            />
                        )}
                        {newFormInput === 'textArea' && (
                            <TextAreaInput
                                directionRtl={fields.language?.id === 19}
                                error={errors?.value}
                                label={reduxText[7666]}
                                name='value'
                                onChange={handleInputChange}
                                value={fields.value || ''}
                            />
                        )}
                        {newFormInput === 'richText' && (
                            <RichTextInput
                                error={errors?.value}
                                fonts={fonts}
                                label={reduxText[7666]}
                                name='value'
                                objectId={fields.id}
                                onChange={handleInputChange}
                                presetColors={fields.preset_colors}
                                tinymceStylesObject={tinymceStylesObject}
                                tinymceStylesString={`${tinymceStylesString}${fields.language?.id === 19 ? 'direction: rtl;' : ''}`}
                                value={fields.value || ''}
                            />
                        )}
                        <ErrorHelper error={errors.non_field_errors} />
                    </div>
                    <div className='mo-dialog-footer'>
                        {helpers.init
                            ? (
                                <React.Fragment>
                                    <Button
                                        edit={false}
                                        expand='full'
                                        fill='outline'
                                        onClick={resetForm}
                                        padding='0 5px'
                                        text={reduxText[4519]}
                                    />
                                    <Button
                                        disabled={helpers.buttonDisabled}
                                        edit={false}
                                        expand='full'
                                        fill='outline'
                                        onClick={() => (newFormInput === 'default') ? onSubmitLanguage() : onSubmit()}
                                        padding='0 5px'
                                        text={reduxText[4520]}
                                    />
                                </React.Fragment>
                            ) : (
                                <Button
                                    edit={false}
                                    fill='outline'
                                    onClick={() => setIsOpen(false)}
                                    text={reduxText[4742]}
                                />
                            )}
                    </div>
                </div>
                <div className={`mo-dialog-content-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    <IonItemDivider
                        className='tf-divider'
                        sticky
                    >
                        <div className='tf-divider-content'>
                            <span>{newDefaultLanguage?.name || translation?.defaultLanguage?.name || reduxText[7667]}</span>
                            <div className='tf-divider-buttons'>
                                <IonButton
                                    className='main-icon-block-web'
                                    color='tertiary'
                                    onClick={() => handleRetrieveDefault()}
                                    size='small'
                                >
                                    <i className='main-icon mo-new-icon-pencil-alt-solid' />
                                </IonButton>
                            </div>
                        </div>
                    </IonItemDivider>
                    {['address_json'].includes(formInput!) && (
                        <div className='tf-default-value tf-cities-temp'>
                            {/* @ts-ignore */}
                            {translationDefaultValue?.map((val, i) => (
                                <div
                                    key={i}
                                    className='tf-wrap'
                                >
                                    <MainCountryFlag
                                        edit={true}
                                        object={val}
                                        searchTab={undefined}
                                    />
                                    <p className='tf-text'>{val.description}</p>
                                </div>
                            ))}
                        </div>
                    )}
                    {['image', 'image_crop'].includes(formInput!) && (
                        <div
                            className='tf-default-value-image'
                            style={tinymceStylesObject}
                        >
                            <ImageHelper
                                alt=''
                                className='tf-image'
                                dominant_color={undefined}
                                src={`${translationDefaultValue}`}
                            />
                        </div>
                    )}
                    {['pdf'].includes(formInput!) && (
                        <span
                            className='tf-default-value'
                            style={tinymceStylesObject}
                        >
                            {translationDefaultValue}
                        </span>
                    )}
                    {['text', 'textArea'].includes(formInput!) && (
                        <span
                            className={`tf-default-value${((newDefaultLanguage?.id === 19) || (translation?.defaultLanguage?.id === 19)) ? ' rtl' : ''}`}
                            style={tinymceStylesObject}
                        >
                            {translationDefaultValue}
                        </span>
                    )}
                    {(formInput === 'richText' && typeof translationDefaultValue === 'string') && (
                        <div
                            className={`tf-default-value-rich${((newDefaultLanguage?.id === 19) || (translation?.defaultLanguage?.id === 19)) ? ' rtl' : ''}`}
                            dangerouslySetInnerHTML={{ __html: translationDefaultValue }}
                            style={tinymceStylesObject}
                        />
                    )}
                    {items.map((val) => (
                        <React.Fragment key={val.id}>
                            <IonItemDivider
                                className='tf-divider'
                                sticky
                            >
                                <div className='tf-divider-content'>
                                    <span>{val.language?.name}</span>
                                    <div className='tf-divider-buttons'>
                                        <IonButton
                                            className='main-icon-block-web'
                                            color='tertiary'
                                            onClick={() => handleInlineRetrieve(val)}
                                            size='small'
                                        >
                                            <i className='main-icon mo-new-icon-pencil-alt-solid' />
                                        </IonButton>
                                        <IonButton
                                            className='main-icon-block-web'
                                            color='tertiary'
                                            onClick={() => {
                                                setDeleteSubMessage(val.language?.name)
                                                setIsDeleteAlertOpen(true)
                                                setToDeleteId(val.id)
                                            }}
                                            size='small'
                                        >
                                            <i className='main-icon mo-new-icon-trash-solid' />
                                        </IonButton>
                                    </div>
                                </div>
                            </IonItemDivider>
                            {['address_json'].includes(formInput!) && (
                                <div className='tf-default-value tf-cities-temp'>
                                    {/* @ts-ignore */}
                                    {JSON.parse(val.value)?.map((val2, i) => (
                                        <div
                                            key={i}
                                            className='tf-wrap'
                                        >
                                            <MainCountryFlag
                                                edit={true}
                                                object={val2}
                                                searchTab={undefined}
                                            />
                                            <p className='tf-text'>{val2.description}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {['image', 'image_crop'].includes(formInput!) && (
                                <div
                                    className='tf-default-value-image'
                                    style={tinymceStylesObject}
                                >
                                    <ImageHelper
                                        alt=''
                                        className='tf-image'
                                        dominant_color={undefined}
                                        src={val.image}
                                    />
                                </div>
                            )}
                            {['pdf'].includes(formInput!) && (
                                <span
                                    className='tf-default-value'
                                    style={tinymceStylesObject}
                                >
                                    {val.pdf_file}
                                </span>
                            )}
                            {['text', 'textArea'].includes(formInput!) && (
                                <span
                                    className={`tf-default-value${val.language?.id === 19 ? ' rtl' : ''}`}
                                    style={tinymceStylesObject}
                                >
                                    {val.value}
                                </span>
                            )}
                            {/* val.is_rich_text && val.value_rich */}
                            {(formInput === 'richText' && typeof translationDefaultValue === 'string') && (
                                <div
                                    className={`tf-default-value-rich${val.language?.id === 19 ? ' rtl' : ''}`}
                                    dangerouslySetInnerHTML={{ __html: val.value_rich! }}
                                    style={tinymceStylesObject}
                                />
                            )}
                        </React.Fragment>
                    ))}
                    <InfiniteScrollHelperWeb
                        active={!disableInfiniteScroll}
                        hasMore={hasMore}
                        isLoading={isLoading}
                        items={items}
                        noEndText
                        onChange={onSearchNext}
                    />
                </div>
            </DialogContent>
            {helpers.isSaving && (
                <Loader isOpen />
            )}
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={handleDelete}
                    objectName={deleteSubMessage}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
            {isDeleting && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </Dialog>
    )
})
