// react components
import React from 'react'
import axios from 'axios'

// data
import {
    api_url_auth_user_form_last_login,
    reduxAuthFetchSettings,
    reduxAuthState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// main
export function setLastLogin({
    component,
    dispatch,
    reduxAuth,
}: {
    component: string
    dispatch: React.Dispatch<any>
    reduxAuth: reduxAuthState
}) {
    try {
        if (!reduxAuth.settings?.user?.id) return
        if (process.env.NODE_ENV === 'development') return
        const putUrl = getApiUrl(api_url_auth_user_form_last_login, reduxAuth)
        axios({
            headers: reduxAuth.axiosConfig?.headers,
            method: 'put',
            url: putUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                dispatch(reduxAuthFetchSettings(reduxAuth, reduxAuth.axiosConfig?.headers, dispatch))
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: putUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'setLastLogin',
                })
            })
    } catch (error) {
        dispatch(reduxModalErrorEventHandler(
            error,
            component,
            'setLastLogin',
        ))
    }
}
