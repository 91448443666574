// data
import {
    api_url_service_child_form_active,
    api_url_service_child_form_create,
    api_url_service_child_form_detail,
    api_url_service_child_form_list,
    api_url_service_child_form_patch,
    api_url_service_child_form_update,
    reduxAuthState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function ServiceChildFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'child',

        // Urls
        activeUrl: getApiUrl(api_url_service_child_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_service_child_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_service_child_form_detail, reduxAuth),
        listUrl: getApiUrl(api_url_service_child_form_list, reduxAuth),
        patchUrl: getApiUrl(api_url_service_child_form_patch, reduxAuth),
        updateUrl: getApiUrl(api_url_service_child_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: {},

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'time_start',
        ],
        requiredForeignKey: [
            'day',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'active',
            'child_text',
            'child_text2',
            'has_custom_address',
            'time_end',
            'time_start',
        ],
        formFile: [],
        formForeignKey: [
            'day',
        ],
        formJson: [],
        formJsonNull: [
            'address_json',
        ],
        formManyToMany: [],
    }
}
