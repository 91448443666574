// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
    ImageCropSVGInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    LayoutFieldHelperSerializer,
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type CoverImageInputPageContentProps = {
    detailUrl: string
    disabled?: boolean
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    inputObject?: LayoutFieldHelperSerializer
    mixedMedia?: boolean
    onHandleInputChange: any
    optional: boolean
    updateUrl: string
}

// main
export const CoverImageInputPageContent: React.FC<CoverImageInputPageContentProps> = React.memo(({
    detailUrl,
    disabled,
    errorsInline,
    fieldsInline,
    fieldsMother,
    inputObject,
    mixedMedia,
    onHandleInputChange,
    optional,
    updateUrl,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [responsiveImageOpen, setResponsiveImageOpen] = useState(false)

    useEffect(() => {
        if (fieldsInline.cover_image_mq_o || fieldsInline.cover_image_lq_o) {
            setResponsiveImageOpen(true)
        }
    }, [
        fieldsInline.cover_image_mq_o,
        fieldsInline.cover_image_lq_o,
    ])

    return (
        <React.Fragment>
            <ImageCropSVGInput
                altButton
                altValue={fieldsInline.cover_image_alt}
                clear
                disabled={disabled}
                doka_output_data={fieldsInline.cover_image_doka_output_data || ''}
                error={errorsInline?.cover_image}
                errorAlt={errorsInline?.cover_image_alt}
                helperText={inputObject?.helper_text}
                label={inputObject?.label || reduxText[4478]}
                name='cover_image'
                onChange={onHandleInputChange}
                optional={optional}
                original={fieldsInline.file || fieldsInline.cover_image_original || ''}
                translation={{
                    contentType: mixedMedia ? 'mixedmediacontent' : 'pagecontent',
                    defaultDetailUrl: detailUrl,
                    defaultLanguage: fieldsInline.translation_default_language,
                    defaultUpdateUrl: updateUrl,
                    objectId: fieldsInline.id,
                    profileId: fieldsMother.profile?.id,
                }}
                value={fieldsInline.file || fieldsInline.cover_image || ''}
            />
            <p
                onClick={() => setResponsiveImageOpen(!responsiveImageOpen)}
                style={{
                    color: '#898f99',
                    cursor: 'pointer',
                    marginBottom: 30,
                }}
            >
                {reduxText[8523]}
            </p>
            {responsiveImageOpen && (
                <React.Fragment>
                    <ImageCropInput
                        clear
                        doka_output_data={fieldsInline.cover_image_mq_o_doka_output_data || ''}
                        error={errorsInline?.cover_image_mq_o}
                        label='image tablet' // TO_TEXT
                        name='cover_image_mq_o'
                        onChange={onHandleInputChange}
                        optional
                        original={fieldsInline.cover_image_mq_o_original || ''}
                        value={fieldsInline.cover_image_mq_o || ''}
                    />
                    <ImageCropInput
                        clear
                        doka_output_data={fieldsInline.cover_image_lq_o_doka_output_data || ''}
                        error={errorsInline?.cover_image_lq_o}
                        label='image mobile' // TO_TEXT
                        name='cover_image_lq_o'
                        onChange={onHandleInputChange}
                        optional
                        original={fieldsInline.cover_image_lq_o_original || ''}
                        value={fieldsInline.cover_image_lq_o || ''}
                    />
                </React.Fragment>
            )}
        </React.Fragment>

    )
})
