// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    CoverImageProductInput,
    CoverImageSquareProductInput,
    DescriptionProductInput,
    NameProductInput,
    ProfileProductInput,
} from 'pages'

// serializers
import {
    ProductFormState,
} from 'serializers/web'

// props
type MainProductPanelProps = {
    formState: ProductFormState
    handleInputChange: any
}

// main
export const MainProductPanel: React.FC<MainProductPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainProductPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <ProfileProductInput
                disabled={Boolean(fieldsMother.id)}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CoverImageProductInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <NameProductInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <DescriptionProductInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CoverImageSquareProductInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
