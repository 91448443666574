// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_adhesion_pricing,
} from 'data'

// serializers
import {
    ContributionDetailFormSerializer,
    ContributionDetailFormSerializerError,
} from 'serializers/web'

// props
type PricingContributionInputProps = {
    errors: ContributionDetailFormSerializerError | undefined
    fields: ContributionDetailFormSerializer
    onChange: any
}

// main
export const PricingContributionInput: React.FC<PricingContributionInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!fields.profile?.id) return null

    return (
        <SelectListInput
            apiUrl={`${select_url_adhesion_pricing}?profile_id=${fields.profile.id}`}
            clearable={false}
            disabled={Boolean(fields.id)}
            error={errors?.pricing}
            label={reduxText[8570]}
            name='pricing'
            onChange={onChange}
            value={fields.pricing || ''}
        />
    )
})
