// react components
import React, {
    useState,
} from 'react'
import {
    IonAvatar,
} from '@ionic/react'

// components
import {
    IconBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// pages
import {
    ProfileActionSheetMobile,
} from 'pages'

// serializers
import {
    CityTempSerializer,
    ProfileListSerializer,
} from 'serializers/web'

// props
type ProfileListMediumBlockMobileProps = {
    edit: boolean
    object: ProfileListSerializer
}

// main
export const ProfileListMediumBlockMobile: React.FC<ProfileListMediumBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [isActionSheetOpen, setActionSheetIsOpen] = useState(false)

    const absolute_url = object.get_absolute_url

    // rework country to avoid duplicate flags
    const countryArray: CityTempSerializer[] = []
    if (edit && object.address_json && object.address_json.length > 0) {
        const countryArrayTemp: string[] = []
        if (object.address_json && object.address_json.length > 0) {
            object.address_json.map((val) => {
                if (val.countryCode && !countryArrayTemp.includes(val.countryCode)) {
                    countryArrayTemp.push(val.countryCode)
                    if (countryArray) countryArray.push(val)
                }
                return false
            })
        }
    }

    return (
        <div className='profile-list-medium-block'>
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='profile'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <div className='pflmb-wrap'>
                <LinkHelper
                    className='pflmb-profile-pic'
                    edit={edit}
                    to={absolute_url}
                >
                    <IonAvatar className='pflmb-image-link'>
                        <ImageHelper
                            alt={object.name}
                            className='pflmb-image'
                            dominant_color={undefined}
                            lazyMobile={false}
                            src={object.get_image_lq}
                        />
                    </IonAvatar>
                </LinkHelper>
                <div className='pflmb-right'>
                    <div className='pflmb-info'>
                        <LinkHelper
                            edit={edit}
                            to={absolute_url}
                        >
                            <p className='mo-list-block-name-mobile'>{object.name}</p>
                        </LinkHelper>
                        <div className='pflmb-chips'>
                            {object.metiers && (
                                <MainHashtag
                                    edit={edit}
                                    label={object.metiers}
                                />
                            )}
                        </div>
                        <div className='pflmb-country'>
                            {!edit && object.countries && object.countries.length > 0 && (
                                <React.Fragment>
                                    {object.countries.map((val, i) => (
                                        <MainCountryFlag
                                            key={val.id}
                                            edit={edit}
                                            object={val}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                            {edit && countryArray && countryArray.length > 0 && (
                                <React.Fragment>
                                    {countryArray.map((val, i) => (
                                        <MainCountryFlag
                                            key={i}
                                            edit={edit}
                                            object={val}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                            {!edit && object.cities && object.cities.length > 0 && (
                                <React.Fragment>
                                    {object.cities.map((val) => (
                                        <MainHashtag
                                            key={`cities-${val.id}`}
                                            edit={edit}
                                            label={val.name}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                            {edit && object.address_json && object.address_json.length > 0 && (
                                <React.Fragment>
                                    {object.address_json.map((val, i) => (
                                        <MainHashtag
                                            key={`cities-${i}`}
                                            edit={edit}
                                            label={val.cityName || val.description}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className='pflmb-action'>
                        <IconBlock
                            edit={edit}
                            iconClass='mo-new-icon-ellipsis-h-solid'
                            onClick={() => setActionSheetIsOpen(true)}
                        />
                    </div>
                </div>
            </div>
            {isActionSheetOpen && (
                <ProfileActionSheetMobile
                    object={object}
                    setActivated={setActivated}
                    setIsOpen={setActionSheetIsOpen}
                />
            )}
        </div>
    )
})
