// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    AxiosRequestConfig,
    CancelTokenSource,
} from 'axios'
import {
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    DeleteAlert,
    IconBlock,
    Loader,
    NotFoundComponent,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    contentTypeSettingsData,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    reduxModalMainFormHide,
} from 'data'

// pages
import {
    FormContent,
    FormModalAction,
} from 'pages'

// serializers
import {
    ContentTypeFormTypes,
    ContentTypeSettingsTypes,
    ContentTypeSettingsCustomTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
    getFormDataV2,
    getFormDetailData,
    getFormtMediaData,
    getInputValueV2,
    getMainErrorMessageV2,
    getReduxTextId,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type FormModalProps = {
    contentType: contentTypeModelType
    contentTypeSettingsN: ContentTypeSettingsTypes | undefined
    defaultFields?: any
    objectId: string
    onCloseHelperModal?: (e: boolean, value?: any) => void
    onRefresh?: () => void
    profileObject?: ProfileDetailAdminSerializerV2
    reduxAuthFetchSettings?: boolean
    setDeleted?: React.Dispatch<boolean>
}

// main
export const FormModal: React.FC<FormModalProps> = React.memo(({
    contentType,
    contentTypeSettingsN,
    defaultFields,
    objectId,
    onCloseHelperModal,
    onRefresh,
    profileObject,
    reduxAuthFetchSettings,
    setDeleted,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [contentTypeSettings, setContentTypeSettings] = useState(contentTypeSettingsN)

    const formStateProps = contentTypeSettings ? {
        contentTypeSettings: contentTypeSettings,
        data: {
            errors: {},
            fields: {},
        },
        fieldsForm: contentTypeSettings?.form.fieldsForm,
        fieldsRequired: contentTypeSettings?.form.fieldsRequired,
        structure: contentTypeSettings?.form.structure,
    } : {
        contentTypeSettings: undefined,
        data: {
            errors: {},
            fields: {},
        },
        fieldsForm: {
            formAttribute: [],
            formFile: [],
            formForeignKey: [],
            formJson: [],
            formManyToMany: [],
        },
        fieldsRequired: {
            requiredAttribute: [],
            requiredForeignKey: [],
            requiredManyToMany: [],
        },
        structure: {
            panels: [],
        },
    }

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<ContentTypeFormTypes>(formStateProps)
    const [getMetaIsLoading, setGetMetaIsLoading] = useState(false)
    const [helpers, setHelpers] = useState<reduxFormHelpersState>({
        ...reduxFormInitialHelpers,
        buttonDisabled: contentType === 'activity_registrationgroup' ? false : true,  // TODO dynamic
    })
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)

    const app = contentType.split('_')[0]
    const model = contentType.split('_')[1]
    const object = formState.data.fields
    const contentTypeSettingsCustom: ContentTypeSettingsCustomTypes = object?.profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType] || profileObject?.settings_data_json?.contentTypeSettingsCustom?.[contentType]

    const formActive = object?.active || ([
        'about_helpcentercategory',
        'about_helpcenterprofilecategory',
        'about_helpcentertopic',
        'about_helpcentertopicitem',
        'accounting_customer',
        'accounting_invoice',
        'accounting_package',
        'accounting_product',
        'accounting_productprice',
        'accounting_quotation',
        'accounting_quotationline',
        'accounting_vendor',
        'activity_activity',
        'activity_course',
        'activity_coursedate',
        'activity_parent',
        'activity_period',
        'activity_registrationstatus',
        'activity_student',
        'adhesion_adherent',
        'adhesion_adherentcontent',
        'adhesion_adhesionpricing',
        'adhesion_contribution',
        'builder_blocktemplate',
        'builder_pagetemplate',
        'datapool_asset',
        'datapool_attribution',
        'datapool_attributioncategory',
        'datapool_status',
        'datapool_term',
        'datapool_termcategory',
        'datapool_weblink',
        'dataset_entity',
        'event_event',
        'event_eventimage',
        'event_eventpricing',
        'event_eventteam',
        'feed_customfeedblock',
        'feed_customfeedsection',
        'food_origin',
        'food_recipe',
        'main_city',
        'main_component',
        'main_country',
        'main_currency',
        'main_customcategory',
        'main_customcategory2',
        'main_customcategory3',
        'main_customchoice',
        'main_language',
        'main_mediumtype',
        'main_team',
        'main_text',
        'material_material',
        'miscellanea_customtable',
        'miscellanea_mailcategory',
        'offer_offer',
        'offer_offerimage',
        'portfolio_page',
        'portfolio_portfolio',
        'portfolio_portfoliocategory',
        'portfolio_portfoliocontentsource',
        'portfolio_portfoliohubcontent',
        'portfolio_portfoliopagecontent',
        'portfolio_urlredirect',
        'portfolio_userportfolio',
        'project_cssproperty',
        'project_csspropertyoption',
        'project_layout',
        'project_layoutfield',
        'project_layoutvariation',
        'project_mixedmediacontent',
        'project_project',
        'project_projectcontent',
        'project_projectcontenttype',
        'project_projectpurchaselink',
        'project_projectteam',
        'sales_invoicingstatus',
        'sales_packageoption',
        'sales_packageoptionitem',
        'sales_product',
        'sales_productimage',
        'sales_productpurchaselink',
        'sales_productteam',
        'sales_productvariant',
        'sales_salesstatus',
        'sales_shippingagent',
        'sales_shippingservice',
        'sales_shippingzone',
        'sales_stripeproduct',
        'sales_stripetaxratecategory',
        'service_service',
        'service_serviceimage',
        'setup_module',
        'staff_mailingaccount',
        'userprofile_cartecategory',
        'userprofile_carteitem',
        'userprofile_profile',
        'userprofile_profileimage',
        'userprofile_profilesection',
        'userprofile_profileteam',
        'userprofile_sociallink',
        'userprofile_theme',
    ].includes(contentType) ? false : undefined)

    useEffect(() => {
        if (!contentTypeSettings) {
            getContentTypeSettingsData()
        } else {
            setContentTypeSettings(contentTypeSettings)
        }
    }, [
        contentType,
        contentTypeSettings,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        if (contentTypeSettings) {
            getFormDetailData({
                axiosCancelToken,
                component: 'FormModal',
                contentType,
                dispatch,
                formState,
                helpers,
                noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
                objectId,
                reduxAuth,
                setAxiosCancelToken,
                setFormState,
                setHelpers,
                setInitialState,
            })
        }
    }, [
        contentTypeSettings,
        objectId,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    function getContentTypeSettingsData() {
        try {
            getDetailObject({
                // @ts-ignore
                apiUrl: getApiUrlV2(`setup/detail/contenttypesettings/${contentTypeSettingsData[contentType]}`, reduxAuth),
                component: 'AdminModule',
                dispatch,
                reduxAuth,
                setDetailObject: (e) => {
                    setContentTypeSettings(e.settings_data_json)
                    setInitialState(e.settings_data_json)
                },
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'onGetDetailData',
            ))
        }
    }

    async function handleDelete() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'FormModal handleDelete')
            setIsDeleting(true)
            const deleteUrl = getApiUrlV2(`${app}/form/${model}/update/${object.id}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    if (setDeleted) setDeleted(true)
                    onCloseModal()
                })
                .catch((error) => {
                    setIsDeleting(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'FormModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormModal',
                'handleDelete',
            ))
        }
    }

    let wto2: any
    function handleInputChange(event: any) {
        try {
            if (event.name === 'link_url' && [  // TODO dynamic
                contentTypeData['project_project'],
            ].includes(contentTypeSettings?.meta?.model_content_type_id!) && !object?.skip_data_retrieve) {
                setFormState(getInputValueV2({
                    contentType,
                    event,
                    formState,
                    reduxText,
                }))
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
                clearTimeout(wto2)
                wto2 = setTimeout(() => {
                    getFormtMediaData({
                        contentType,
                        formState,
                        helpers,
                        image_only: false,
                        prefixImage: 'cover_',
                        reduxAuth,
                        reduxText,
                        setFormState,
                        setGetMetaIsLoading,
                        setHelpers,
                        urltoParse: event.value,
                    })
                }, 1000)
            } else if (event.name === 'link_url' && [  // TODO dynamic
                contentTypeData['datapool_asset'],
            ].includes(contentTypeSettings?.meta?.model_content_type_id!) && !object?.skip_data_retrieve) {
                setFormState(getInputValueV2({
                    contentType,
                    event,
                    formState,
                    reduxText,
                }))
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
                clearTimeout(wto2)
                wto2 = setTimeout(() => {
                    getFormtMediaData({
                        contentType,
                        formState,
                        helpers,
                        image_only: false,
                        prefixImage: '',
                        reduxAuth,
                        reduxText,
                        setFormState,
                        setGetMetaIsLoading,
                        setHelpers,
                        urltoParse: event.value,
                    })
                }, 1000)
            } else {
                setFormState(getInputValueV2({
                    contentType,
                    event,
                    formState,
                    reduxText,
                }))
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormModal',
                'handleInputChange',
            ))
        }
    }

    function handleKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                handleRequired(false, !formActive)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormModal',
                'handleKeyPress',
            ))
        }
    }

    function handleRequired(stay: boolean, publish?: boolean, another?: boolean) {
        try {
            const result = checkRequiredFields({
                formInfoObject: formState,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit(stay, publish, another)
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    data: {
                        ...formState.data,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormModal',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit(stay: boolean, publish?: boolean, another?: boolean) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'FormModal handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = getApiUrlV2(`${app}/form/${model}/create/`, reduxAuth)
            if (object.id) {
                axiosMethod = 'put'
                axiosUrl = getApiUrlV2(`${app}/form/${model}/update/${object.id}/`, reduxAuth)
            }
            const formData = getFormDataV2(formState, contentTypeSettingsCustom)
            if (publish) {
                formData.append('active', 'true')
                if (contentType === 'event_event' && object.profile?.id === 193057) {  // TODO dynamic
                    formData.append('active_status', '1')
                }
            } else {
                formData.append('active', object.active ? 'true' : 'false')
            }
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    dispatch(reduxFormSetEditingSite(false))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: false,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    const { id, slug, cover_image } = response.data
                    if (another) {
                        setInitialState(contentTypeSettings, object.profile)
                        if (onRefresh) onRefresh()
                        dispatch(reduxFormSetRefresh('refreshForm', id))
                    } else {
                        const baseFields = { ...object, id, slug }
                        setFormState({
                            ...formState,
                            data: {
                                ...formState.data,
                                errors: {},
                                fields: object.meta_image_url
                                    ? { ...baseFields, cover_image_original: cover_image }
                                    : baseFields
                            }
                        })
                        if (onRefresh) onRefresh()
                        onCloseModal({ id, name: response.data.name })
                        dispatch(reduxFormSetRefresh('refreshForm', id))
                        if (reduxAuthFetchSettings) {
                            reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                        }
                    }
                })
                .catch((error) => {
                    let mainErrorMessage = ''
                    let mainErrorTitle = ''
                    if (error.response?.data?.non_field_errors) {
                        mainErrorMessage += error.response.data.non_field_errors
                    }
                    const formStateErrors = getMainErrorMessageV2({
                        error,
                        reduxText,
                    })
                    if (error.response?.status === 400) {
                        Object.keys(formStateErrors).forEach((key) => {
                            const name = contentTypeSettingsCustom?.field?.[key]?.verboseName || contentTypeSettings?.field?.[key]?.verboseName!
                            const label = getReduxTextId(name!, reduxText) || key
                            mainErrorMessage += `${label}: ${formStateErrors[key]}\n`
                        })
                    } else {
                        mainErrorMessage += formStateErrors.main_error
                        mainErrorTitle += formStateErrors.main_error_title
                    }
                    const emailAndPhoneFields = Object.keys(contentTypeSettings?.field || {}).filter(key => {
                        const fieldType = contentTypeSettings?.field[key].fieldType
                        return fieldType === 'EmailField' || fieldType === 'PhoneNumberField'
                    })
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: mainErrorMessage,
                        mainErrorTitle,
                    })
                    setFormState({
                        ...formState,
                        data: {
                            ...formState.data,
                            errors: formStateErrors,
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'FormModal',
                        dispatch,
                        error,
                        formFields: JSON.stringify(object),
                        formFieldsToIgnore: emailAndPhoneFields,
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipAlertStatusCode: [400, 500],
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormModal',
                'handleSubmit',
            ))
        }
    }

    function onCloseModal(value?: any) {
        try {
            if (onCloseHelperModal) {
                onCloseHelperModal(false, value)
            } else {
                dispatch(reduxModalMainFormHide())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormModal',
                'onCloseModal',
            ))
        }
    }

    function setInitialState(newContentTypeSettings?: ContentTypeSettingsTypes, newProfileObject?: ProfileDetailAdminSerializerV2) {
        const newDefaultFields = defaultFields || {}
        if (reduxAuth.settings?.user?.is_staff) {
            newDefaultFields.profile = newProfileObject || profileObject || undefined
        } else {
            newDefaultFields.profile = newProfileObject || profileObject || reduxAuth.settings?.active_profile
        }
        if (contentType === 'userprofile_profile' && profileObject) {
            newDefaultFields.parent_profile = profileObject
        }
        if (newContentTypeSettings) {
            setFormState({
                ...formStateProps,
                contentTypeSettings: contentTypeSettings,
                data: {
                    ...formStateProps.data,
                    fields: newDefaultFields,
                },
            })
        } else {
            setFormState({
                ...formStateProps,
                data: {
                    ...formStateProps.data,
                    fields: newDefaultFields,
                },
            })
        }
    }

    return (
        <React.Fragment>
            <DialogTitle className='mo-dialog-header'>
                <div className='mo-dialog-header-close-wrap reverse'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={onCloseModal}
                    />
                </div>
                <span className='mo-dialog-header-title'>{!object?.id ? reduxText[9374] : (object?.name || reduxText[2105])}</span>
                <div className='mo-dialog-header-info-wrap reverse'>
                    <span className='mo-dialog-header-info'>{getReduxTextId(contentTypeSettings?.meta.verboseName!, reduxText)}{object?.id ? ` - ${object.id}` : ''}</span>
                </div>
            </DialogTitle>
            {!helpers.errorStatus && reduxAuth.settings?.user?.is_staff && (  // TODO dynamic
                <FormModalAction
                    buttonSize='x-small'
                    defaultFields={defaultFields}
                    formActive={formActive}
                    handleRequired={handleRequired}
                    helpers={helpers}
                    object={object}
                    onCloseModal={onCloseModal}
                    reverseButtons
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
            {!helpers.errorStatus && reduxAuth.settings?.active_profile?.id === 191231 && (  // TODO dynamic
                <FormModalAction
                    defaultFields={defaultFields}
                    formActive={formActive}
                    handleRequired={handleRequired}
                    helpers={helpers}
                    object={object}
                    onCloseModal={onCloseModal}
                    reverseButtons
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
            {helpers.errorStatus
                ? (
                    <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <NotFoundComponent
                            model={contentType}
                            objectId={Number(objectId)}
                            refreshData={() => dispatch(reduxFormSetRefresh('refreshForm', Number(objectId)))}
                            statusCode={helpers.errorStatus}
                        />
                    </DialogContent>
                ) : (
                    <>
                        <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                            {(helpers.isLoading || !contentTypeSettings)
                                ? (
                                    <Loader isOpen />
                                ) : (
                                    <FormContent
                                        contentType={contentType}
                                        contentTypeSettings={contentTypeSettings}
                                        contentTypeSettingsCustom={contentTypeSettingsCustom}
                                        formState={formState}
                                        getMetaIsLoading={getMetaIsLoading}
                                        handleInputChange={handleInputChange}
                                        object={object}
                                        onKeyPress={handleKeyPress}
                                    />
                                )}
                        </DialogContent>
                        {reduxAuth.settings?.active_profile?.id !== 191231 && (  // TODO dynamic
                            <FormModalAction
                                defaultFields={defaultFields}
                                formActive={formActive}
                                handleRequired={handleRequired}
                                helpers={helpers}
                                object={object}
                                onCloseModal={onCloseModal}
                                reverseButtons
                                setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                            />
                        )}
                    </>
                )
            }
            <Alert
                buttons={[{ text: reduxText[5614] }]}
                header={helpers.mainErrorTitle || reduxText[5585]}
                isOpen={Boolean(helpers.mainError)}
                message={`${helpers.mainError}${helpers.mainError === 'Network Error' ? reduxText[4577] : ''}`}
                onDidDismiss={() => setHelpers({
                    ...helpers,
                    mainError: '',
                })}
            />
            {(helpers.isSaving || isDeleting) && (
                <Loader
                    isOpen
                    message={helpers.isSaving ? reduxText[4525] : (isDeleting ? reduxText[1242] : '')}
                />
            )}
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={handleDelete}
                    objectName={object.fields?.name}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
        </React.Fragment>
    )

})
