// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CountViewBlock,
    DescriptionDetailBlock,
    HeaderDetailBlock,
    ImageHelper,
    MainHashtag,
} from 'components'

// containers
import {
    DetailListContainerNew,
} from 'containers'

// data
import {
    api_url_material_image_list,
    defaultReduxState,
} from 'data'

// pages
import {
    MixedContentBlock,
} from 'pages'

// serializers
import {
    MainTabHelperType,
    MaterialDetailSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
} from 'services'

// props
type MaterialDetailBlockWebProps = {
    edit: boolean
    inModal?: boolean
    languageId: number | undefined
    object: MaterialDetailSerializer
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const MaterialDetailBlockWeb: React.FC<MaterialDetailBlockWebProps> = React.memo(({
    edit,
    inModal,
    languageId,
    object,
    setLanguageId,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setTabValue({
            id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
            title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
        })
    }, [
        object?.id,
    ])

    const [tabValue, setTabValue] = useState<MainTabHelperType>({
        id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
        title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
    })

    const tabArray: MainTabHelperType[] = []
    if (object.medium_type?.is_custom_page || object.has_images) {
        tabArray.push(
            {
                id: 'cover',
                title: reduxText[4359],
            },
        )
    }
    if (object.medium_type?.is_custom_page) {
        tabArray.push(
            {
                id: 'custom-page',
                title: reduxText[8489],
            },
        )
    }
    if (object.has_images) {
        tabArray.push(
            {
                id: 'images',
                isGallery: true,
                listApiUrl: getApiUrl(`${api_url_material_image_list}?material_id=${object.id}`, reduxAuth),
                title: reduxText[1625],
            },
        )
    }

    const hasTabs = !edit && tabArray.length > 0

    return (
        <div className={`main-detail-block material-detail-block-web${tabValue.id === 'custom-page' ? ' medium-type-custom' : ''}${edit ? ' edit' : ''}`}>
            <div className={`mdb-left${hasTabs ? ' tabs' : ''}${tabValue.tableHeaderComponent ? ' table' : ''}`}>
                {hasTabs && (
                    <div className='mdb-tabs'>
                        {tabArray.map(val => (
                            <div
                                key={val.id}
                                className={`mdb-buttons${val.id === tabValue.id ? ' active' : ''}`}
                                onClick={() => setTabValue(val)}
                            >
                                <span className='mdb-buttons-name'>{val.title}</span>
                            </div>
                        ))}
                    </div>
                )}
                {(tabValue.listComponent || tabValue.isGallery)
                    ? (
                        <DetailListContainerNew
                            inModal={inModal}
                            isGallery={tabValue.isGallery}
                            listApiUrl={tabValue.listApiUrl!}
                            listComponent={tabValue.listComponent}
                            tableHeaderComponent={tabValue.tableHeaderComponent}
                        />
                    ) : (
                        <React.Fragment>
                            {tabValue.id === 'custom-page'
                                ? (
                                    <MixedContentBlock
                                        contentType='material'
                                        edit={edit}
                                        hasTabs={hasTabs}
                                        languageId={languageId}
                                        object={object}
                                    />
                                ) : (
                                    <ImageHelper
                                        alt={object.name}
                                        className='mdb-cover-image'
                                        dominant_color={object.get_dominant_color}
                                        lightBox
                                        lightBoxSrc={object.get_image_hq}
                                        src={object.get_image_hq}
                                    />
                                )
                            }
                        </React.Fragment>
                    )
                }
            </div>
            <div className={`mdb-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <HeaderDetailBlock
                    contentType='material'
                    edit={edit}
                    languageId={languageId}
                    object={object}
                    setLanguageId={setLanguageId}
                />
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.description}
                    title={reduxText[1619]}
                />
                <DescriptionDetailBlock
                    languageId={languageId}
                    text={object.price}
                    title={reduxText[5602]}
                />
                {object.languages?.length! > 0 && (
                    <DescriptionDetailBlock
                        title={reduxText[4825]}
                    >
                        {object.languages!.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
