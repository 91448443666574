// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventDetailFormSerializer,
    EventDetailFormSerializerError,
} from 'serializers/web'

// props
type TicketLinkUrlEventInputProps = {
    errors: EventDetailFormSerializerError | undefined
    fields: EventDetailFormSerializer
    onChange: any
}

// main
export const TicketLinkUrlEventInput: React.FC<TicketLinkUrlEventInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            error={errors?.ticket_link_url}
            label={reduxText[8426]}
            name='ticket_link_url'
            onChange={onChange}
            optional
            value={fields.ticket_link_url || ''}
        />
    )
})
