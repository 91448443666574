// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'
import {
    ArrowContainer,
    Popover,
} from 'react-tiny-popover'

// components
import {
    Loader,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalLayoutInputShow,
} from 'data'

// pages
import {
    AddContentToFavoriteHelper,
    AddToBlockTemplateHelper,
    CopyContentHelper,
    CopyStyleHelper,
    DeleteContentHelper,
    DuplicateContentHelper,
    EditContentHelper,
    MoveContentHelper,
    PublishContentHelper,
} from 'pages'

// serializers
import {
    BlockTemplateSelectSerializer,
    ProjectFormState,
} from 'serializers/web'
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    BuilderService,
    addBlockContent,
    getStylesToRetrieveNew,
} from 'services'

// props
type MatchParams = {
    id: string
}

type EditButtonMixedMediaContentChildrenProps = {
    allowDrop?: boolean
    children: any
    contentType: contentTypeModelType
    isInTree?: boolean
    isNormal?: boolean
    isPopoverOpen: boolean
    mainParentId: number
    mother: ProjectFormState['mother']
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    object: PortfolioPageContentListSiteSerializer
    parentStyles: any
    setIsPopoverOpen: React.Dispatch<boolean>
}

// main
export const EditButtonMixedMediaContentChildren: React.FC<EditButtonMixedMediaContentChildrenProps> = React.memo(({
    allowDrop,
    children,
    contentType,
    isInTree,
    isNormal,
    isPopoverOpen,
    mainParentId,
    mother,
    newMixedMediaContent,
    newMixedMediaContentType,
    object,
    parentStyles,
    setIsPopoverOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [copyContentDirection, setCopyContentDirection] = useState<'after' | 'before' | 'inside'>()
    const [isLoading, setIsLoading] = useState(false)
    const [showAddBlockToTemplate, setShowAddBlockToTemplate] = useState(false)
    const [showAddContentToFavorite, setShowAddContentToFavorite] = useState(false)
    const [showCopyContent, setShowCopyContent] = useState(false)
    const [showDeleteContent, setShowDeleteContent] = useState(false)
    const [showDuplicateContent, setShowDuplicateContent] = useState(false)

    function onAddBlock(direction: 'after' | 'before') {
        setIsPopoverOpen(false)
        dispatch(reduxModalLayoutInputShow(
            handleSubmitNewBlock,
            true,
            true,
            direction,
        ))
    }

    function handleSubmitNewBlock(
        submitLayout: BlockTemplateSelectSerializer,
        special: 'symbol',
        direction: 'after' | 'before',
    ) {
        try {
            addBlockContent({
                child: true,
                contentType,
                direction,
                dispatch,
                formUrl: mother.formUrl,
                history,
                mainParentId,
                mixedMedia: true,
                newMixedMediaContent,
                newMixedMediaContentType,
                object,
                paramsDetailId: undefined,
                paramsId: params.id,
                paramsPageSlug: undefined,
                reduxAuth,
                reduxFormSite: undefined,
                setIsLoading,
                setIsPopoverOpen,
                special,
                submitLayout,
            })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'EditButtonMixedMediaContentChildren',
                'handleSubmitNewBlock',
            ))
        }
    }

    function PopoverContent() {
        const contentToCopyObject = BuilderService.copyGetContentObject()
        const styleToCopyObject = BuilderService.copyGetStyleObject()
        const parentFlexDirection: any = getStylesToRetrieveNew(reduxModalSite, parentStyles, undefined, 'flexDirection') || 'row'
        const iconAfterOptions: any = {
            'column': {
                'icon': 'mo-new-icon-chevron-down-solid',
                'text': reduxText[7931],
            },
            'column-reverse': {
                'icon': 'mo-new-icon-chevron-up-solid',
                'text': reduxText[7930],
            },
            'row': {
                'icon': 'mo-new-icon-chevron-right-solid',
                'text': reduxText[7931],
            },
            'row-reverse': {
                'icon': 'mo-new-icon-chevron-left-solid',
                'text': reduxText[7930],
            },
        }
        const iconBeforeOptions: any = {
            'column': {
                'icon': 'mo-new-icon-chevron-up-solid',
                'text': reduxText[7930],
            },
            'column-reverse': {
                'icon': 'mo-new-icon-chevron-down-solid',
                'text': reduxText[7931],
            },
            'row': {
                'icon': 'mo-new-icon-chevron-left-solid',
                'text': reduxText[7930],
            },
            'row-reverse': {
                'icon': 'mo-new-icon-chevron-right-solid',
                'text': reduxText[7931],
            },
        }
        return (
            <div className='selection-helper-page-content-web-popover popover-edit-button-mixed-media-content-children-web'>
                <div className='shpcwp-item'>
                    <EditContentHelper
                        child
                        editUrl={newMixedMediaContent ? `/form-mixed-media/${newMixedMediaContentType}/${params.id}/${object.id}/` : `${mother?.formUrl}${params.id}/mixed-media-content/${object.id}/`}
                        mainParentId={mainParentId}
                        mixedMedia
                        object={object}
                        setIsPopoverOpen={setIsPopoverOpen}
                    />
                </div>
                <div className='shpcwp-item-layout-input'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => onAddBlock('after')}
                    >
                        <i className='mo-new-icon-plus-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[7934]}</span>
                    </button>
                    <div
                        className='shpcwp-item-button-wrap dynamic'
                        style={{
                            flexDirection: isInTree ? 'column' : parentFlexDirection,
                        }}
                    >
                        <button
                            className='shpcwp-item-button1'
                            title={isInTree ? reduxText[7930] : iconBeforeOptions[parentFlexDirection].text}
                            onClick={() => onAddBlock('before')}
                        >
                            <i className={isInTree ? 'mo-new-icon-chevron-up-solid' : iconBeforeOptions[parentFlexDirection].icon} />
                        </button>
                        <button
                            className='shpcwp-item-button2'
                            title={isInTree ? reduxText[7931] : iconAfterOptions[parentFlexDirection].text}
                            onClick={() => onAddBlock('after')}
                        >
                            <i className={isInTree ? 'mo-new-icon-chevron-down-solid' : iconAfterOptions[parentFlexDirection].icon} />
                        </button>
                    </div>
                </div>
                <MoveContentHelper
                    allowDrop={allowDrop}
                    isNormal={isNormal}
                    mixedMedia
                    object={object}
                    setIsPopoverOpen={setIsPopoverOpen}
                />
                <div className='shpcwp-item bt2'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => {
                            setIsPopoverOpen(false)
                            BuilderService.copySetContentObject(object!, contentTypeData['mixedmediacontent'], mother.fields?.portfolio_main?.is_multi_language!)
                        }}
                    >
                        <i className='mo-new-icon-copy-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[8491]}</span>
                    </button>
                </div>
                {contentToCopyObject && (
                    <CopyContentHelper
                        isButton
                        isNormal={(contentToCopyObject.id === object.id) || isNormal}
                        mixedMedia
                        object={object}
                        setDirection={setCopyContentDirection}
                        setIsOpen={setShowCopyContent}
                        setIsPopoverOpen={setIsPopoverOpen}
                    />
                )}
                <div className='shpcwp-item multi-button bt2'>
                    <div className='shpcwp-icon-wrap'>
                        <i className='mo-new-icon-clone-solid' />
                    </div>
                    <div className='shpcwp-multi-button-wrap'>
                        <DuplicateContentHelper
                            contentType={contentType}
                            defaultPortfolio={mother.fields?.portfolio_main}
                            isButton
                            mainParentId={mainParentId}
                            newMixedMediaContent={newMixedMediaContent}
                            newMixedMediaContentType={newMixedMediaContentType}
                            objectId={object.id!}
                            profile={mother.fields?.profile!}
                            setIsPopoverOpen={setIsPopoverOpen}
                        />
                        <button
                            className='shpcwp-item-button'
                            onClick={() => {
                                setIsPopoverOpen(false)
                                setShowDuplicateContent(true)
                            }}
                        >
                            <span className='shpcwp-item-button-text'>{reduxText[8190]}</span>
                        </button>
                    </div>
                </div>
                <div className='shpcwp-item bt2'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => {
                            setIsPopoverOpen(false)
                            BuilderService.copySetStyleObject(object!, contentTypeData['mixedmediacontent'])
                        }}
                    >
                        <i className='mo-new-icon-paint-roller-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[7686]}</span>
                    </button>
                </div>
                {(styleToCopyObject && (styleToCopyObject?.layout === object.layout) && (styleToCopyObject?.id !== object.id)) && (
                    <CopyStyleHelper
                        mixedMedia
                        object={object}
                        setIsPopoverOpen={setIsPopoverOpen}
                    />
                )}
                <div className='shpcwp-item bt2'>
                    <PublishContentHelper
                        child
                        mixedMedia
                        object={object}
                        setIsPopoverOpen={setIsPopoverOpen}
                    />
                </div>
                <div className='shpcwp-item'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => {
                            setIsPopoverOpen(false)
                            setShowDeleteContent(true)
                        }}
                    >
                        <i className='mo-new-icon-trash-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[2983]}</span>
                    </button>
                </div>
                <div className='shpcwp-item bt2'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => {
                            setIsPopoverOpen(false)
                            setShowAddContentToFavorite(true)
                        }}
                    >
                        <i className='mo-new-icon-star-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[2096]}</span>
                    </button>
                </div>
                {[1, 5, 68].includes(reduxAuth.settings?.user?.id!) && (
                    <React.Fragment>
                        <div className='shpcwp-item bt3'>
                            <button
                                className='shpcwp-item-button'
                                onClick={() => {
                                    setIsPopoverOpen(false)
                                    setShowAddBlockToTemplate(true)
                                }}
                            >
                                <i className='mo-new-icon-staff-pick' />
                                <span className='shpcwp-item-button-text'>{reduxText[8681]}</span>
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
            <Popover
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        arrowColor={reduxAuth.settings?.theme === 'light' ? '#202020' : '#ffffff'}
                        arrowSize={10}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        position={position}
                    >
                        <PopoverContent />
                    </ArrowContainer>
                )}
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                positions={['bottom', 'left', 'top', 'right']}
            >
                {children}
            </Popover>
            {showAddBlockToTemplate && (
                <AddToBlockTemplateHelper
                    mixedMedia
                    objectId={object.id!}
                    setIsOpen={setShowAddBlockToTemplate}
                    setIsPopoverOpen={setIsPopoverOpen}
                />
            )}
            {showAddContentToFavorite && (
                <AddContentToFavoriteHelper
                    contentType='mixedmediacontent'
                    objectId={object.id!}
                    profileId={mother.fields?.profile?.id!}
                    setIsOpen={setShowAddContentToFavorite}
                />
            )}
            {showCopyContent && (
                <CopyContentHelper
                    direction={copyContentDirection}
                    isNormal={isNormal}
                    isOpen={showCopyContent}
                    mixedMedia
                    object={object}
                    setDirection={setCopyContentDirection}
                    setIsOpen={setShowCopyContent}
                    setIsPopoverOpen={setIsPopoverOpen}
                />
            )}
            {showDeleteContent && (
                <DeleteContentHelper
                    child
                    formUrl={mother.formUrl}
                    mixedMedia
                    newMixedMediaContent={newMixedMediaContent}
                    newMixedMediaContentType={newMixedMediaContentType}
                    objectId={object.id!}
                    parentId={object.parent_content}
                    setIsOpen={setShowDeleteContent}
                />
            )}
            {showDuplicateContent && (
                <DuplicateContentHelper
                    contentType={contentType}
                    defaultPortfolio={mother.fields?.portfolio_main}
                    mainParentId={mainParentId}
                    newMixedMediaContent={newMixedMediaContent}
                    newMixedMediaContentType={newMixedMediaContentType}
                    objectId={object.id!}
                    profile={mother.fields?.profile!}
                    setIsOpen={setShowDuplicateContent}
                />
            )}
            {isLoading && (
                <Loader
                    isOpen
                    message={reduxText[6437]}
                />
            )}
        </React.Fragment>
    )
})
