// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_main_country,
} from 'data'

// serializers
import {
    ProfileDetailFormSerializer,
    ProfileDetailFormSerializerError,
} from 'serializers/web'

// props
type Countries2ProfileInputProps = {
    errors: ProfileDetailFormSerializerError | undefined
    fields: ProfileDetailFormSerializer
    onChange: any
}

// main
export const Countries2ProfileInput: React.FC<Countries2ProfileInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <SelectListInput
            apiUrl={select_url_main_country}
            clearable={false}
            error={errors?.countries_2}
            label={reduxText[8729]}
            multi
            name='countries_2'
            onChange={onChange}
            optional
            value={fields.countries_2 || ''}
        />
    )
})
