// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    SalesOrderListSerializer,
} from 'serializers/web'

// props
type SalesOrderListHeaderProps = {
    count: number | undefined
    object: SalesOrderListSerializer | undefined
}

// main
export const SalesOrderListHeader: React.FC<SalesOrderListHeaderProps> = React.memo(({
    count,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <tr className='main-list-header SalesOrderListHeader'>
            <th>{count !== undefined ? `${count} ${reduxText[8337]}` : ''}</th>
            <th />
            <th>ID</th>
            <th>{reduxText[8641]}</th>
            <th>{reduxText[8638]}</th>
            <th>{reduxText[5559]}</th>
            <th>{reduxText[8562]}</th>
            <th>{reduxText[6468]}</th>
            <th>{reduxText[6469]}</th>
            <th>Stripe</th>
            {object?.profile === 187807 && (
                <React.Fragment>
                    <th>{reduxText[8759]}</th>
                    <th>{reduxText[8760]}</th>
                </React.Fragment>
            )}
            <th>{reduxText[5493]}</th>
        </tr>
    )
})
