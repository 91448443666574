// react components
import React from 'react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CountViewBlock,
    ImageHelper,
    InactiveButton,
    ProfileShortInfoMobile,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    MaterialDetailSerializer,
} from 'serializers/web'

// props
type MaterialDetailBlockMobileProps = {
    edit: boolean
    object: MaterialDetailSerializer
}

// main
export const MaterialDetailBlockMobile: React.FC<MaterialDetailBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='material-detail-block'>
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='material'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <ImageHelper
                alt={object.name}
                className='mdb-cover-image'
                dominant_color={undefined}
                src={object.get_image_lq}
            />
            <div className='mdb-material-info'>
                <div className='mdb-main-info'>
                    <p className='mdb-name'>{object.name}</p>
                    {object.profile && (
                        <ProfileShortInfoMobile
                            edit={edit}
                            object={object.profile}
                        />
                    )}
                    <div className='mdb-countries'>
                        {object.profile && object.profile.address_json && object.profile.address_json.length > 0 && object.profile.address_json.map((val, i) => (
                            <span
                                key={i}
                                className='mdb-cities'
                            >
                                {val.description}
                            </span>
                        ))}
                    </div>
                    {object.profile && object.profile.phone_number && (
                        <div className='mdb-phone'>{object.profile.phone_number}</div>
                    )}
                </div>
                {object.description && (
                    <div className='mdb-section'>
                        <p className='mdb-section-title'>{reduxText[4596]}</p>
                        <Linkify>
                            <p className='mdb-description'>{object.description}</p>
                        </Linkify>
                    </div>
                )}
            </div>
            {object.owner_info && (
                <CountViewBlock
                    object={object.owner_info}
                />
            )}
        </div>
    )
})
