// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    CheckboxInputV2,
    DateTimeInputV2,
    FileInput,
    ImageCropInputV2,
    ImageCropSquareInputV2,
    LocationInputV2,
    PhoneNumberInputV2,
    RichTextInputV2,
    SelectListAsyncInputV2,
    SelectListAttributionInputV2,
    SelectListInputV2,
    StylesExtraInputV2,
    TextAreaInputV2,
    TextInputV2,
    TextNormalAndRichInput,
    UrlInputV2,
} from 'pages'

// serializers
import {
    ContentTypeFormBlockFieldSetFieldsOptionTypes,
    ContentTypeFormTypes,
    ContentTypeSettingsCustomTypes,
    ContentTypeSettingsTypes,
} from 'serializers/web'

// services
import {
    getReduxTextId,
} from 'services'

// props
type FormFieldInputProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    disabled: boolean
    errors: ContentTypeFormTypes['data']['errors']
    field: string
    fieldType: string
    fieldsOptionsSettings: ContentTypeFormBlockFieldSetFieldsOptionTypes | undefined
    getMetaIsLoading?: boolean
    label: string
    object: ContentTypeFormTypes['data']['fields']
    onChange: any
    onKeyPress: any
}

// main
export const FormFieldInput: React.FC<FormFieldInputProps> = React.memo(({
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    disabled,
    errors,
    field,
    fieldType,
    fieldsOptionsSettings,
    getMetaIsLoading,
    label,
    object,
    onChange,
    onKeyPress,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (field === 'link_url' && [  // TODO dynamic
        contentTypeData['datapool_asset'],
        contentTypeData['project_project'],
    ].includes(contentTypeSettings.meta.model_content_type_id)) {
        return (
            <UrlInputV2
                canSkipDataRetrieve
                getMetaIsLoading={getMetaIsLoading}
                disabled={disabled}
                name={field}
                onChange={onChange}
                value={object[field] || ''}
            />
        )
    }

    if (['BooleanField'].includes(fieldType)) {
        return (
            <CheckboxInputV2
                disabled={disabled}
                name={field}
                onChange={onChange}
                onKeyPress={onKeyPress}
                value={object[field]}
            />
        )
    }

    if (['CharField', 'URLField'].includes(fieldType)) {
        if ((typeof object[`${field}_is_rich`] !== 'undefined') || contentTypeSettings.field[field].hasRich) {
            return (
                <TextNormalAndRichInput
                    disabled={disabled}
                    field={field}
                    object={object}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                />
            )
        }
        return (
            <TextInputV2
                disabled={disabled}
                name={field}
                onChange={onChange}
                onKeyPress={onKeyPress}
                value={object[field] || ''}
            />
        )
    }

    if (['DateField', 'DateTimeField', 'TimeField'].includes(fieldType)) {
        const formatDict: any = {
            'DateField': 'date',
            'DateTimeField': 'datetime',
            'TimeField': 'time',
        }
        let formatDate = fieldsOptionsSettings?.dateFormat
        if (object.date_type_choice) {
            if (object.date_type_choice?.id === 'y') {
                formatDate = 'yyyy'
            } else if (object.date_type_choice?.id === 'm') {
                formatDate = 'MM/yyyy'
            }
        }
        return (
            <DateTimeInputV2
                contentType={contentType}
                disabled={disabled}
                // @ts-ignore
                format={object.date_only ? 'date' : formatDict[fieldType]}
                formatDate={formatDate}
                formatPreviewOnly={object.date_only}
                name={field}
                onChange={onChange}
                timeIntervals={fieldsOptionsSettings?.timeInterval}
                value={object[field] || ''}
            />
        )
    }

    if ([
        'DecimalField',
        'IntegerField',
        'PositiveIntegerField',
        'PositiveSmallIntegerField',
    ].includes(fieldType)) {
        return (
            <TextInputV2
                disabled={disabled}
                name={field}
                onChange={onChange}
                onKeyPress={onKeyPress}
                type='number'
                value={object[field] || ''}
            />
        )
    }

    if (['EmailField'].includes(fieldType)) {
        return (
            <TextInputV2
                disabled={disabled}
                name={field}
                onChange={onChange}
                onKeyPress={onKeyPress}
                value={object[field] || ''}
            />
        )
    }

    if (['FileField'].includes(fieldType)) {
        return (
            <FileInput
                // disabled={disabled}
                name={field}
                onChange={onChange}
                pdfOk={field !== 'storage_file'}  // TODO dynamic
                pdfOnly={field === 'pdf_file'}  // TODO dynamic
                value={object[field] || ''}
            />
        )
    }

    if (['JSONField'].includes(fieldType)) {
        if (field === 'address_json') {
            return (
                <LocationInputV2
                    disabled={disabled}
                    multi={[  // TODO dynamic
                        contentTypeData['offer_offer'],
                        contentTypeData['project_project'],
                        contentTypeData['sales_product'],
                        contentTypeData['userprofile_profile'],
                    ].includes(contentTypeSettings.meta.model_content_type_id)}
                    name={field}
                    onChange={onChange}
                    value={object[field] || ''}
                />
            )
        }
        if (field.includes('address_json')) {
            return (
                <LocationInputV2
                    disabled={disabled}
                    name={field}
                    onChange={onChange}
                    value={object[field] || ''}
                />
            )
        }
        if (field === 'styles_extra') {
            return (
                <StylesExtraInputV2
                    // disabled={disabled}
                    name={field}
                    onChange={onChange}
                    value={object[field] || ''}
                />
            )
        }
        return (
            <p className='mo-error-color'>Error: Missing JSONField input for {field}</p>
        )
    }

    if (['ImageField'].includes(fieldType)) {
        if (field.includes('square') && typeof contentTypeSettings.field[`${field.split('_square')[0]}_original`] !== 'undefined') {
            return (
                <ImageCropSquareInputV2
                    // disabled={disabled}
                    doka_output_data={object[`${field}_doka_output_data`] || ''}
                    name={field}
                    onChange={onChange}
                    original={object[`${field.split('_square')[0]}_original`] || object[field.split('_square')[0]] || ''}
                    value={object[field] || ''}
                />
            )
        }
        if (typeof contentTypeSettings.field[`${field}_original`] !== 'undefined') {
            return (
                <ImageCropInputV2
                    altButton
                    altValue={object[`${field}_alt`]}
                    clear
                    doka_output_data={object[`${field}_doka_output_data`] || ''}
                    errorAlt={errors?.[`${field}_alt`]}
                    meta_value={object.meta_image_url || ''}
                    name={field}
                    onChange={onChange}
                    original={object[`${field}_svg`] || object[`${field}_original`] || ''}
                    value={object[`${field}_svg`] || object[field] || ''}
                />
            )
        }
        return (
            <p className='mo-error-color'>Error: Missing original field for {field}</p>
        )
    }

    if (['ChoiceField', 'ForeignKey', 'GenericForeignKey', 'ManyToManyField', 'OneToOneField'].includes(fieldType)) {
        if (!fieldsOptionsSettings) return (
            <p className='mo-error-color'>Error: Missing fieldsOptionsSettings for {field}</p>
        )
        let isDisabled = false
        let selectUrl = fieldsOptionsSettings.selectUrl
        if (fieldsOptionsSettings.selectUrlVariation) {
            selectUrl += `${fieldsOptionsSettings.selectUrlVariation}/`
        }
        if (fieldsOptionsSettings?.selectUrlParams) {
            const selectUrlParams = fieldsOptionsSettings.selectUrlParams
            const selectUrlParamsArray = Object.keys(selectUrlParams).map((key) => {
                const keyParamOptions = selectUrlParams[key]
                if (keyParamOptions) {
                    return `${key}=${keyParamOptions}`
                }
                if (key === 'model_content_type_id') {  // TODO dynamic
                    if (field === 'content_type_panel' && contentTypeSettings.meta.model_content_type_id === contentTypeData['setup_contenttypepanellistprofile'] && (object.content_type_settings?.id || object.content_type_settings_profile?.content_type_settings?.id)) {
                        return `content_type=${contentTypeData['setup_contenttypesettings']}&object_id=${object.content_type_settings?.id || object.content_type_settings_profile?.content_type_settings?.id}`
                    }
                    return `content_type=${contentTypeSettings.meta.model_content_type_id}&object_id=${object.id}`
                }
                let keyParamValue = object[key]?.id
                // TODO dynamic
                if (key === 'content_type_string') {
                    keyParamValue = object[key]
                }
                // TODO dynamic
                if (key === 'content_type_settings' && contentTypeSettings.meta.model_content_type_id === contentTypeData['setup_contenttypepanellistprofile'] && object.content_type_settings_profile?.content_type_settings?.id) {
                    keyParamValue = object.content_type_settings_profile.content_type_settings.id
                }
                if (!keyParamValue) isDisabled = true
                if (key === 'profile' && contentTypeSettings.meta.model_content_type_id === contentTypeData['userprofile_profile'] && object.parent_profile?.id) {
                    return `profile=${object.parent_profile.id}`
                }
                if (key === 'profile' && reduxAuth.settings?.agenda_profile) {
                    return `profile=${reduxAuth.settings.agenda_profile.id}`
                }
                return `${key}=${keyParamValue}`
            })
            selectUrl += `?${selectUrlParamsArray.join('&')}`
        }
        if (fieldsOptionsSettings.selectAsyncDisable) {
            selectUrl += '&paginate=false'
        }
        if (fieldsOptionsSettings.selectUrlFilterString) {
            selectUrl += `${fieldsOptionsSettings.selectUrlFilterString}`
        }
        // TODO dynamic?
        if (field === 'field' && typeof fieldsOptionsSettings?.selectUrlParams?.content_type_settings_profile !== 'undefined') {
            selectUrl += `&content_type_settings=${object.content_type_settings_profile?.content_type_settings?.id}`
            if (!object.content_type_settings_profile?.content_type_settings?.id) isDisabled = true
        }
        if (field === 'inline_parent_model_field') {
            selectUrl += `content_type_settings=${object.inline_content_type_settings?.id}`
            if (!object.inline_content_type_settings) isDisabled = true
        }
        if (field === 'inline_parent_model_field_ct') {
            selectUrl += `content_type_settings=${object.inline_content_type_settings?.id}`
            if (!object.inline_content_type_settings) isDisabled = true
        }
        // TODO dynamic
        if (field === 'module_views') {
            selectUrl += `&is_selectable=true&content_type_settings=${contentTypeSettings.meta.id}`
        }
        if (fieldsOptionsSettings.selectOrderBy) {
            selectUrl += `&order_by=${fieldsOptionsSettings.selectOrderBy}`
        }
        if (!fieldsOptionsSettings.selectAsyncDisable && fieldsOptionsSettings.selectAsync) {
            return (
                <SelectListAsyncInputV2
                    apiUrl={selectUrl!}
                    clearable
                    defaultOptions={fieldsOptionsSettings.selectAsyncLoadInitial}
                    disabled={isDisabled || disabled}
                    layout={fieldsOptionsSettings.selectLayout}
                    multi={fieldType === 'ManyToManyField'}
                    name={field}
                    onChange={onChange}
                    value={object[field] || ''}
                />
            )
        }
        const formContentTypeDict = {  // TODO dynamic
            'attribution_categories': 'datapool_attributioncategory',
            'entity': 'dataset_entity',
        }
        let selectChoices = undefined
        if (fieldsOptionsSettings.selectChoices) {
            selectChoices = []
            fieldsOptionsSettings.selectChoices.map((choice) => {
                selectChoices.push({
                    id: choice.id,
                    name: getReduxTextId(choice.name!, reduxText),
                })
            })
        }
        const value = object[field]
        if (field.startsWith('attributions_many_to_many_field_')) {
            const attribution_category = contentTypeSettingsCustom?.form?.fieldsOptions?.[field]?.selectUrlParams?.attribution_categories
            return (
                <SelectListAttributionInputV2
                    apiUrl={selectUrl}
                    attribution_category={attribution_category}
                    clearable
                    disabled={isDisabled || disabled}
                    // @ts-ignore
                    formContentType={formContentTypeDict[field]}
                    label={label}
                    layout={fieldsOptionsSettings.selectLayout}
                    multi={fieldType === 'ManyToManyField'}
                    name={field}
                    onChange={onChange}
                    options={selectChoices}
                    profileObject={object.profile}
                    value={value || ''}
                />
            )
        }
        return (
            <SelectListInputV2
                apiUrl={selectUrl}
                clearable
                disabled={isDisabled || disabled}
                // @ts-ignore
                formContentType={formContentTypeDict[field]}
                labelNoOption={label}
                layout={fieldsOptionsSettings.selectLayout}
                multi={fieldType === 'ManyToManyField'}
                name={field}
                onChange={onChange}
                options={selectChoices}
                profileObject={object.profile}
                value={value || ''}
            />
        )
    }

    if (fieldType === 'PhoneNumberField') {
        return (
            <PhoneNumberInputV2
                disabled={disabled}
                name={field}
                onChange={onChange}
                value={object[field] || ''}
            />
        )
    }

    if (fieldType === 'RichTextField') {
        return (
            <RichTextInputV2
                disabled={disabled}
                fonts={object.portfolio_main?.fonts}
                name={field}
                objectId={object.id}
                onChange={onChange}
                presetColors={object?.preset_colors}
                tinymceStylesString='todo'
                value={object[field] || ''}
                tinymceStylesObject={{
                    defaultMargin: field === 'template' ? 'false' : 'true' // TODO dynamic
                }}
            />
        )
    }

    if (fieldType === 'TextField') {
        if ((typeof object[`${field}_is_rich`] !== 'undefined') || contentTypeSettings.field[field].hasRich) {
            return (
                <TextNormalAndRichInput
                    disabled={disabled}
                    field={field}
                    isTextArea
                    object={object}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                />
            )
        }
        return (
            <TextAreaInputV2
                disabled={disabled}
                name={field}
                onChange={onChange}
                value={object[field] || ''}
            />
        )
    }

    return (
        <p className='mo-error-color'>Error: Missing field type {fieldType} for {field}</p>
    )
})
