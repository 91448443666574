// react components
import React, {
    useRef,
} from 'react'
import {
    DragSourceMonitor,
    DropTargetMonitor,
    useDrag,
    useDrop,
} from 'react-dnd'

// components
import {
    IconBlock,
} from 'components'

// data
import {
    contentTypeModelType,
} from 'data'

// pages
import {
    TableColumn,
} from 'pages'

// serializers
import {
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

const ItemType = 'ROW'

// props
type TableRowDraggableProps = {
    addProfileColumn?: boolean
    columns: string[]
    columnsCustom?: string[]
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    index: number
    isHovered: boolean
    modalKey: number
    moveRow: (dragIndex: number, hoverIndex: number) => void
    object: any
    profileObject: ProfileDetailAdminSerializerV2 | undefined
    setHoveredRow: (index: number | null) => void
}

interface DragItem {
    index: number
    type: string
}

// main
export const TableRowDraggable: React.FC<TableRowDraggableProps> = React.memo(({
    addProfileColumn,
    columns,
    columnsCustom,
    contentType,
    contentTypeSettings,
    index,
    isHovered,
    modalKey,
    moveRow,
    object,
    profileObject,
    setHoveredRow,
}) => {

    const extraAutoColumns = []
    if (addProfileColumn) {
        extraAutoColumns.push('profile', 'id')
    }
    const newColumns = [...(columnsCustom || columns), ...extraAutoColumns]

    const ref = useRef<HTMLTableRowElement>(null)

    const [, drop] = useDrop({
        accept: ItemType,
        // @ts-ignore
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            setHoveredRow(hoverIndex)

            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
        },
        // @ts-ignore
        drop(item: DragItem, monitor: DropTargetMonitor) {
            moveRow(item.index, index)
            setHoveredRow(null)
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { type: ItemType, index },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(drop(ref))

    return (
        <tr
            className={`table-row draggable${isDragging ? ' is-dragging' : ''}${isHovered ? ' is-hovered' : ''}`}
            ref={ref}
        >
            <td className='tr-action'>
                <div className='tr-action-div'>
                    <IconBlock
                        edit={false}
                        iconClass='mo-new-icon-bars-solid'
                    />
                </div>
            </td>
            {newColumns.map(val => (
                <TableColumn
                    key={val}
                    addProfileColumn={addProfileColumn}
                    contentType={contentType}
                    contentTypeSettings={contentTypeSettings}
                    field={val}
                    modalKey={modalKey}
                    object={object}
                    profileObject={profileObject}
                />
            ))}
        </tr>
    )
})
