// react components
import React, {
    useEffect,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// containers
import {
    InlineFormModal,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AdhesionPricingEventPricingInput,
    EventPricingFormState,
    IsForNonAdherentEventPricingInput,
    NameEventPricingInput,
    UnitPriceEventPricingInput,
} from 'pages'

// serializers
import {
    EventFormState,
    InlineFormPanelPropsNew,
} from 'serializers/web'

// props
type PricingEventPanelProps = {
    formState: EventFormState
    setFormState: React.Dispatch<EventFormState>
}

// main
export const PricingEventPanel: React.FC<InlineFormPanelPropsNew & PricingEventPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    modalTitle,
    setFormState,
    setHelpers,
    setInlineModalValue,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const mother = formState.mother
    const pricing = formState.pricing

    useEffect(() => {
        setInitialState()
    }, [])

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, pricing)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PricingEventPanel',
                'onHandleInputChange',
            ))
        }
    }

    function setInitialState() {
        const pricing = EventPricingFormState(reduxAuth)
        setFormState({
            ...formState,
            pricing: {
                ...pricing,
                fields: {
                    event: {
                        id: fieldsMother!.id,
                        profile: fieldsMother!.profile?.id,
                    },
                },
            },
        })
    }

    // Mother
    const fieldsMother = mother.fields

    // Inline
    const errorsInline = pricing.errors
    const fieldsInline = pricing.fields

    if (!fieldsInline) return null

    return (
        <InlineFormModal
            formInfo={pricing}
            formListComponent='EventPricingListFormBlock'
            formState={formState}
            helpers={helpers}
            listUrl={`${pricing.listUrl}${fieldsMother?.id}/`}
            modalTitle={modalTitle}
            parentModel='event'
            setFormState={setFormState}
            setHelpers={setHelpers}
            setInitialState={setInitialState}
            setInlineModalValue={setInlineModalValue}
            tableHeaderComponent='EventPricingListFormHeader'
        >
            <AdhesionPricingEventPricingInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            <IsForNonAdherentEventPricingInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            <NameEventPricingInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            <UnitPriceEventPricingInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
        </InlineFormModal>
    )
})
