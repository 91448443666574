// data
import {
    api_url_product_image_form_active,
    api_url_product_image_form_create,
    api_url_product_image_form_detail,
    api_url_product_image_form_list,
    api_url_product_image_form_patch,
    api_url_product_image_form_update,
    reduxAuthState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function ProductImageFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'image',

        // Urls
        activeUrl: getApiUrl(api_url_product_image_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_product_image_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_product_image_form_detail, reduxAuth),
        listUrl: getApiUrl(api_url_product_image_form_list, reduxAuth),
        patchUrl: getApiUrl(api_url_product_image_form_patch, reduxAuth),
        updateUrl: getApiUrl(api_url_product_image_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: {},

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'image',
        ],
        requiredForeignKey: [],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'description',
            'image_alt',
            'image_doka_output_data',
        ],
        formFile: [
            'image',
            'image_original',
        ],
        formForeignKey: [],
        formJson: [],
        formManyToMany: [],
    }
}
