// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextAreaInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventDetailFormSerializer,
    EventDetailFormSerializerError,
    EventFormState,
} from 'serializers/web'

// props
type CustomTextEventInputProps = {
    ctKey: number
    errors: EventDetailFormSerializerError | undefined
    fields: EventDetailFormSerializer
    mother: EventFormState['mother']
    onChange: any
}

// main
export const CustomTextEventInput: React.FC<CustomTextEventInputProps> = React.memo(({
    ctKey,
    errors,
    fields,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const dict: any = {
        1: {
            error: errors?.custom_text,
            label: reduxText[6574],
            name: 'custom_text',
            value: fields.custom_text,
        },
        2: {
            error: errors?.custom_text_2,
            label: reduxText[8698],
            name: 'custom_text_2',
            value: fields.custom_text_2,
        },
        3: {
            error: errors?.custom_text_3,
            label: reduxText[8791],
            name: 'custom_text_3',
            value: fields.custom_text_3,
        },
    }

    return (
        <TextAreaInput
            error={dict[ctKey].error}
            label={dict[ctKey].label}
            name={dict[ctKey].name}
            onChange={onChange}
            optional
            translation={{
                contentType: 'event',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            value={(dict[ctKey].value) || ''}
        />
    )
})
