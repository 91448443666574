// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    // ItemReorderEventDetail,
} from '@ionic/core'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    // arrayMoveImmutable,
} from 'array-move'
import axios, {
    AxiosRequestConfig,
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    DeleteAlert,
    IconBlock,
    InfiniteScrollHelperWeb,
    LightboxCustom,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxModalErrorEventHandler,
    reduxModalErrorShowHide,
} from 'data'

// pages
import {
    AdherentContributionListFormBlock,
    AdherentContributionListFormHeader,
    EventParticipantListFormBlock,
    EventParticipantListFormHeader,
    EventPricingListFormBlock,
    EventPricingListFormHeader,
} from 'pages'

// serializers
import {
    FormInlineStateProps,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getInfiniteListWeb,
    getMainErrorMessage,
} from 'services'

// props
type InlineFormModalProps = {
    children: React.ReactNode
    formInfo: FormInlineStateProps & { errors: any, fields: any }
    formListComponent: string
    formState: any
    helpers: reduxFormHelpersState
    listUrl: string
    modalTitle: string
    parentModel: string
    setFormState: React.Dispatch<any>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    setInitialState: () => void
    setInlineModalValue: React.Dispatch<string>
    tableHeaderComponent?: string
}

// main
export const InlineFormModal: React.FC<InlineFormModalProps> = React.memo(({
    children,
    formInfo,
    formListComponent,
    formState,
    helpers,
    listUrl,
    modalTitle,
    parentModel,
    setFormState,
    setHelpers,
    setInitialState,
    setInlineModalValue,
    tableHeaderComponent,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<any[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [objectToDelete, setObjectToDelete] = useState<any>()
    const [showInlineForm, setShowInlineForm] = useState(true)

    const [imageSrc, setImageSrc] = useState<string>()
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)

    useEffect(() => {
        onGetListData(
            listUrl,
            true,
            true,
        )
    }, [
        reduxAuth.apiRootUrl,
    ])

    // function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    //     try {
    //         onSortEnd(event)
    //         event.detail.complete()
    //     } catch (error) {
    //         dispatch(reduxModalErrorEventHandler(
    //             error,
    //             'InlineFormModal',
    //             'doReorder',
    //         ))
    //     }
    // }

    let wto: any
    let wto2: any
    let wto3: any

    function handleCancel() {
        setShowInlineForm(false)
        clearTimeout(wto)
        wto = setTimeout(() => {
            setShowInlineForm(true)
        }, 100)
        setInitialState()
        setHelpers({
            ...helpers,
            init: false,
        })
    }

    async function handleInlineActive(object: any) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormModal handleInlineActive')
            const formData: any = new FormData()
            formData.append('active', !object.active)
            const activeUrl = `${formInfo.activeUrl}${object.id}/`
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'patch',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const newArray: any = []
                    items.map((val: any) => {
                        if (val.id === object.id) {
                            val.active = response.data.active
                        }
                        newArray.push(val)
                        return false
                    })
                    setItems(newArray)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'InlineFormModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineActive',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormModal',
                'handleInlineActive',
            ))
        }
    }

    async function handleInlineDelete(object: any) {
        try {
            setIsDeleting(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormModal handleInlineDelete')
            const deleteUrl = `${formInfo.updateUrl}${object.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    const newArray: any[] = []
                    items.map((val: any) => {
                        if (val.id !== object.id) newArray.push(val)
                        return false
                    })
                    setItems(newArray)
                })
                .catch((error) => {
                    setIsDeleting(false)
                    if (error.response?.status === 423) {
                        dispatch(reduxModalErrorShowHide({
                            header: reduxText[7428],
                            isOpen: true,
                            message: reduxText[7430],
                            noMessage: true,
                            source: 'InlineFormModal handleInlineDelete',
                        }))
                    } else {
                        axiosErrorHandler({
                            apiUrl: deleteUrl,
                            component: 'InlineFormModal',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleInlineDelete',
                        })
                    }
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormModal',
                'handleInlineDelete',
            ))
        }
    }

    async function handleInlineRetrieve(object: any) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormModal handleInlineRetrieve')
            const getUrl = `${formInfo.detailUrl}${object.id}/`
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    setShowInlineForm(false)
                    clearTimeout(wto2)
                    wto2 = setTimeout(() => {
                        setShowInlineForm(true)
                    }, 100)
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setFormState({
                        ...formState,
                        [formInfo.name]: {
                            ...formState[formInfo.name],
                            fields: response.data,
                            errors: {},
                        }
                    })
                    setHelpers({
                        ...helpers,
                        init: true,
                    })
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'InlineFormModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineRetrieve',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormModal',
                'handleInlineRetrieve',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: formInfo,
                reduxText,
            })
            if (process.env.NODE_ENV === 'development') console.log(result)
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    [formInfo.name]: {
                        ...formState[formInfo.name],
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormModal',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormModal handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
                mainError: '',
            })

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = formInfo.createUrl
            if (formInfo.fields.id) {
                axiosMethod = 'patch'
                axiosUrl = `${formInfo.updateUrl}${formInfo.fields.id}/`
            }
            const formData: any = getFormData(formInfo)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setShowInlineForm(false)
                    clearTimeout(wto3)
                    wto3 = setTimeout(() => {
                        setShowInlineForm(true)
                    }, 100)
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        init: false,
                        isSaving: false,
                        mainError: '',
                    })
                    setInitialState()
                    if (axiosMethod === 'post') {
                        onGetListData(
                            listUrl,
                            true,
                        )
                    } else {
                        const newArray: any = []
                        items.map((val: any) => {
                            if (val.id === response.data.id) {
                                val = response.data
                            }
                            newArray.push(val)
                            return false
                        })
                        setItems(newArray)
                    }
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        [formInfo.name]: {
                            ...formState[formInfo.name],
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'InlineFormModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormModal',
                'handleSubmit',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        noEmpty?: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'InlineFormModal',
                dispatch,
                items,
                noEmpty,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormModal',
                'onGetListData',
            ))
        }
    }

    function onImageClick(src: string) {
        try {
            setIsLightBoxOpen(true)
            setImageSrc(src)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormModal',
                'onImageClick',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormModal',
                'searchNext',
            ))
        }
    }

    // async function onSortEnd(event: any) {
    //     try {
    //         const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormModal onSortEnd')

    //         const item = items[event.detail.from]
    //         const itemTo = items[event.detail.to]
    //         setItems(arrayMoveImmutable(items, event.detail.from, event.detail.to))
    //         const formData: any = new FormData()
    //         formData.append('sibling_id', itemTo.id)
    //         const putUrl = `${formInfo.patchUrl}${item.id}/`
    //         axios({
    //             data: formData,
    //             headers: refreshAxiosHeaders,
    //             method: 'put',
    //             url: putUrl,
    //         })
    //             .then((response) => {
    //                 if (process.env.NODE_ENV === 'development') console.log(response)
    //             })
    //             .catch((error) => {
    //                 axiosErrorHandler({
    //                     apiUrl: putUrl,
    //                     component: 'InlineFormModal',
    //                     dispatch,
    //                     error,
    //                     reduxAuth,
    //                     reference: 'onSortEnd',
    //                 })
    //             })
    //     } catch (error) {
    //         dispatch(reduxModalErrorEventHandler(
    //             error,
    //             'InlineFormModal',
    //             'onSortEnd',
    //         ))
    //     }
    // }

    const tableHeaderComponents: any = {
        AdherentContributionListFormHeader,
        EventParticipantListFormHeader,
        EventPricingListFormHeader,
    }

    const TableHeaderComponentName = tableHeaderComponents[tableHeaderComponent!]


    const formListComponents: any = {
        AdherentContributionListFormBlock,
        EventParticipantListFormBlock,
        EventPricingListFormBlock,
    }
    const FormListComponentName = formListComponents[formListComponent]

    return (
        <Dialog
            className='mo-dialog-class inline-form'
            classes={{ paper: 'mo-dialog-wrapper' }}
            disableEnforceFocus
            fullWidth
            maxWidth='xl'
            onClose={() => setInlineModalValue('')}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {modalTitle}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setInlineModalValue('')}
                    />
                </div>
            </DialogTitle>
            <DialogContent className='mo-dialog-content no-padding'>
                <div className='mo-dialog-content-left'>
                    <div className={`mo-dialog-content-form-children mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {showInlineForm && (
                            children
                        )}
                    </div>
                    <div className='mo-dialog-footer'>
                        {helpers.init
                            ? (
                                <React.Fragment>
                                    <Button
                                        edit={false}
                                        fill='outline'
                                        onClick={handleCancel}
                                        padding='0 5px 0 0'
                                        text={reduxText[4519]}
                                    />
                                    <Button
                                        disabled={helpers.buttonDisabled}
                                        edit={false}
                                        fill='outline'
                                        onClick={handleRequired}
                                        padding='0 0 0 5px'
                                        text={reduxText[4520]}
                                    />
                                </React.Fragment>
                            ) : (
                                <Button
                                    edit={false}
                                    fill='outline'
                                    onClick={() => setInlineModalValue('')}
                                    text={reduxText[4742]}
                                />
                            )}
                    </div>
                </div>
                <div className={`mo-dialog-content-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {items.length > 0 && (
                        <div className='mo-dialog-content-table-container'>
                            <table>
                                {TableHeaderComponentName && (
                                    <thead>{<TableHeaderComponentName />}</thead>
                                )}
                                <tbody>
                                    {items.map((val: any) => {
                                        return (
                                            <FormListComponentName
                                                key={val.id}
                                                handleInlineActive={handleInlineActive}
                                                handleInlineDelete={(e: any) => {
                                                    setIsDeleteAlertOpen(true)
                                                    setObjectToDelete(e)
                                                }}
                                                handleInlineRetrieve={handleInlineRetrieve}
                                                object={val}
                                                onImageClick={onImageClick}
                                            />
                                        )
                                    })}
                                </tbody>
                            </table>
                            <InfiniteScrollHelperWeb
                                active={!disableInfiniteScroll}
                                hasMore={hasMore}
                                isLoading={isLoading}
                                items={items}
                                onChange={onSearchNext}
                            />
                        </div>
                    )}
                </div>
            </DialogContent>
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={() => handleInlineDelete(objectToDelete)}
                    objectName={objectToDelete?.name}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
            {isLightBoxOpen && (
                <LightboxCustom
                    mainSrc={imageSrc}
                    onCloseRequest={() => setIsLightBoxOpen(false)}
                />
            )}
            {isDeleting && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </Dialog>
    )
})
