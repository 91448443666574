// npm components
import React, {
    useState,
} from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    Loader,
    SelectAsyncInput,
    TextInput,
} from 'components'

// data
import {
    api_url_portfolio_form_create,
    defaultReduxState,
    select_url_profile,
    view_url_portfolio_form,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
    PortfolioDetailSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getMainErrorMessage,
} from 'services'

// props
type PortfolioCreateModalProps = {
    object?: PortfolioDetailSerializer
    profileObject?: MainIdNameSerializer
    setIsOpen: React.Dispatch<boolean>
}

// main
export const PortfolioCreateModal: React.FC<PortfolioCreateModalProps> = React.memo(({
    object,
    profileObject,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [isStarting, setIsStarting] = useState(false)
    const [nameError, setNameError] = useState('')
    const [namePortfolio, setNamePortfolio] = useState<string | undefined>(profileObject?.name || reduxAuth.settings?.active_profile?.name)
    const [profile, setProfile] = useState<MainIdNameSerializer | undefined>(profileObject || reduxAuth.settings?.active_profile)
    const [profileError, setProfileError] = useState<string | undefined>()

    async function onSubmit() {
        if (reduxAuth.settings?.active_profile?.id) {
            if (!namePortfolio) {
                setNameError(reduxText[2856])
                setButtonDisabled(true)
                return
            }
            if (!profile) {
                setProfileError(reduxText[2856])
                setButtonDisabled(true)
                return
            }
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PortfolioCreateModal onSubmit')
            setIsStarting(true)
            const createUrl = getApiUrl(api_url_portfolio_form_create, reduxAuth)
            const formData = new FormData()
            formData.append('name', namePortfolio!)
            formData.append('profile', (profile.id!).toString())
            if (object) {
                formData.append('template', object.id!.toString())
            }
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: createUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsStarting(false)
                    setIsOpen(false)
                    history.push(`${view_url_portfolio_form}${response.data.id}/`)
                })
                .catch((error) => {
                    setIsStarting(false)
                    const errors = getMainErrorMessage({
                        error,
                        reduxText,
                    })
                    if (errors.hostname) setNameError(errors.hostname)
                    if (errors.name) setNameError(errors.name)
                    if (errors.non_field_errors) {
                        setNameError(reduxText[7682])
                        return
                    }
                    axiosErrorHandler({
                        apiUrl: createUrl,
                        component: 'PortfolioCreateModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSubmit post',
                    })
                })
        }
    }

    return (
        <React.Fragment>
            <Dialog
                className='mo-dialog-class PortfolioCreateModal'
                classes={{ paper: 'mo-dialog-wrapper fix-width' }}
                disableEnforceFocus
                maxWidth='xl'
                onClose={() => setIsOpen(false)}
                open
            >
                <DialogTitle className='mo-dialog-header'>
                    {reduxText[6450]}
                    <div className='mo-dialog-header-close-wrap'>
                        <IconBlock
                            className='mo-dialog-header-close'
                            edit={false}
                            iconClass='mo-new-icon-times-solid'
                            onClick={() => setIsOpen(false)}
                        />
                    </div>
                </DialogTitle>
                <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {reduxAuth.settings?.user?.is_staff && (
                        <SelectAsyncInput
                            apiUrl={select_url_profile}
                            clearable
                            error={profileError}
                            label={reduxText[1260]}
                            layout='profile'
                            name='profile'
                            onChange={(e: any) => {
                                setButtonDisabled(false)
                                setNamePortfolio(e.value.name)
                                setProfile(e.value)
                                setProfileError('')
                            }}
                            staffOnly
                            value={profile}
                        />
                    )}
                    <TextInput
                        error={nameError}
                        label={reduxText[5559]}
                        name='name'
                        onChange={(e: any) => {
                            setButtonDisabled(false)
                            setNameError('')
                            setNamePortfolio(e.value)
                        }}
                        value={namePortfolio!}
                    />
                </DialogContent>
                <DialogActions className='mo-dialog-footer'>
                    <Button
                        disabled={buttonDisabled}
                        edit={false}
                        fill='outline'
                        onClick={onSubmit}
                        text={reduxText[6353]}
                    />
                </DialogActions>
            </Dialog>
            {isStarting && (
                <Loader
                    isOpen
                    message={reduxText[6340]}
                />
            )}
        </React.Fragment>
    )
})
