// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LocationInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    AdherentDetailFormSerializer,
    AdherentDetailFormSerializerError,
} from 'serializers/web'

// props
type CityTempAdherentInputProps = {
    errors: AdherentDetailFormSerializerError | undefined
    fields: AdherentDetailFormSerializer
    onChange: any
}

// main
export const CityTempAdherentInput: React.FC<CityTempAdherentInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (fields.parent) return null

    return (
        <LocationInput
            error={errors?.address_json}
            label={reduxText[6465]}
            name='address_json'
            onChange={onChange}
            value={fields.address_json || ''}
        />
    )
})
