// react components
import React, {
    useEffect,
    useRef,
} from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// customers
import {
    AgendaDuQuartierQuartierFormStep1,
    AgendaDuQuartierQuartierFormStep10,
    AgendaDuQuartierQuartierFormStep2,
    AgendaDuQuartierQuartierFormStep3,
    AgendaDuQuartierQuartierFormStep4,
    AgendaDuQuartierQuartierFormStep5,
    AgendaDuQuartierQuartierFormStep6,
    AgendaDuQuartierQuartierFormStep7,
    AgendaDuQuartierQuartierFormStep8,
    AgendaDuQuartierQuartierFormStep9,
    AgendaDuQuartierQuartierSettings,
} from 'customers'

// data
import {
    defaultReduxState,
} from 'data'

// services
import {
    parseQuery,
} from 'services'

// props
type MatchParams = {
    step: string
}

// main
export const AgendaDuQuartierQuartierForm: React.FC = () => {

    const history = useHistory()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const scrollDivRef = useRef(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const step = parseInt(parseQuery(window.location.search)?.step)

    useEffect(() => {
        scrollToTop()
    }, [step])

    const scrollToTop = () => {
        if (scrollDivRef.current) {
            // @ts-ignore
            scrollDivRef.current.scrollTo({
                top: 0,
                // behavior: 'smooth' // Optional: for a smooth scroll effect
            })
        }
    }

    const progress = step >= 10 ? 100 : 10 + (step - 1) * 6

    function setStep(step: number) {
        if (step === 11) {
            history.push(`/admin/quartier`)
        } else {
            history.push(`/admin/quartier?step=${step}`)
        }
    }

    // let progress = 10
    // if (step === 1) {
    //     progress = 16
    // }
    // if (step === 2) {
    //     progress = 22
    // }
    // if (step === 3) {
    //     progress = 28
    // }
    // if (step === 4) {
    //     progress = 34
    // }
    // if (step === 5) {
    //     progress = 40
    // }
    // if (step === 6) {
    //     progress = 46
    // }
    // if (step === 7) {
    //     progress = 52
    // }
    // if (step === 8) {
    //     progress = 58
    // }
    // if (step === 9) {
    //     progress = 64
    // }
    // if (step === 10) {
    //     progress = 70
    // }
    // if (step === 11) {
    //     progress = 100
    // }
    // if (step === 12) {
    //     progress = 100
    // }

    if (!step) {
        return (
            <AgendaDuQuartierQuartierSettings />
        )
    }

    return (
        <div
            className={`sub-content-type-90-form mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}
            ref={scrollDivRef}
        >
            <div className='sct90f-block'>
                <div className='sct90f-hr-wrap'>
                    <div
                        className='sct90f-hr'
                        style={{
                            width: `${progress}%`,
                        }}
                    >
                        <p>{`Étape ${step} - ${progress}%`}</p>
                    </div>
                </div>
                <div className='sct90f-block2'>
                    <hr />
                    {step === 1 && (
                        <AgendaDuQuartierQuartierFormStep1
                            setStep={setStep}
                            step={step}
                        />
                    )}
                    {step === 2 && (
                        <AgendaDuQuartierQuartierFormStep2
                            setStep={setStep}
                            step={step}
                        />
                    )}
                    {step === 3 && (
                        <AgendaDuQuartierQuartierFormStep3
                            setStep={setStep}
                            step={step}
                        />
                    )}
                    {step === 4 && (
                        <AgendaDuQuartierQuartierFormStep4
                            setStep={setStep}
                            step={step}
                        />
                    )}
                    {step === 5 && (
                        <AgendaDuQuartierQuartierFormStep5
                            setStep={setStep}
                            step={step}
                        />
                    )}
                    {step === 6 && (
                        <AgendaDuQuartierQuartierFormStep6
                            setStep={setStep}
                            step={step}
                        />
                    )}
                    {step === 7 && (
                        <AgendaDuQuartierQuartierFormStep7
                            setStep={setStep}
                            step={step}
                        />
                    )}
                    {step === 8 && (
                        <AgendaDuQuartierQuartierFormStep8
                            setStep={setStep}
                            step={step}
                        />
                    )}
                    {step === 9 && (
                        <AgendaDuQuartierQuartierFormStep9
                            setStep={setStep}
                            step={step}
                        />
                    )}
                    {step === 10 && (
                        <AgendaDuQuartierQuartierFormStep10
                            setStep={setStep}
                            step={step}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
