// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_main_medium_type_artwork,
    select_url_main_medium_type_press,
    select_url_main_medium_type_resource,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
} from 'serializers/web'

// props
type MatchParams = {
    id: string
}

type MediumTypeProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    onChange: any
}

// main
export const MediumTypeProjectInput: React.FC<MediumTypeProjectInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const params = useParams<MatchParams>()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <SelectListInput
            apiUrl={fields.project_type?.id === '1' ? select_url_main_medium_type_artwork : (fields.project_type?.id === '2' ? select_url_main_medium_type_press : select_url_main_medium_type_resource)}
            clearable={false}
            defaultMenuIsOpen={params.id === 'new'}
            error={errors?.medium_type}
            label={reduxText[3228]}
            name='medium_type'
            onChange={onChange}
            value={fields.medium_type || ''}
        />
    )
})
