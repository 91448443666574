// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainHashtag,
    ProjectPlayButtonMobile,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    ProjectActionSheetMobile,
} from 'pages'

// serializers
import {
    ProjectListSerializer,
} from 'serializers/web'

// props
type ProjectListMediumBlockMobileProps = {
    edit: boolean
    modalKey: number
    object: ProjectListSerializer
    playerOrigin: string
}

// main
export const ProjectListMediumBlockMobile: React.FC<ProjectListMediumBlockMobileProps> = React.memo(({
    edit,
    modalKey,
    object,
    playerOrigin,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [isActionSheetOpen, setActionSheetIsOpen] = useState(false)

    const count_array: any = {
        1: reduxText[4910],
        8: reduxText[2447],
        4: reduxText[4912],
        5: reduxText[1767],
    }

    return (
        <div
            className='project-list-medium-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='project'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <div className='plmb-cover'>
                <LinkHelper
                    className='plmb-cover-wrap'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    <div className='plmb-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='plmb-image'
                            dominant_color={undefined}
                            lazyMobile={false}
                            src={object.get_image_square}
                        />
                    </div>
                    {object.duration !== '00:00:00' && (
                        <p className='plmb-extra-info'>{object.duration}</p>
                    )}
                    {object.medium_type && count_array[object.medium_type] && (
                        <p className='plmb-extra-info'>{`${object.count_project} ${count_array[object.medium_type]}`}</p>
                    )}
                </LinkHelper>
                {object.is_playable && (
                    <div className='plmb-play-btn'>
                        <ProjectPlayButtonMobile
                            edit={edit}
                            modalKey={modalKey}
                            object={object}
                            playerOrigin={playerOrigin}
                        />
                    </div>
                )}
            </div>
            <div className='plmb-info'>
                <LinkHelper
                    className='mo-list-block-name-mobile medium'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    {object.name}
                </LinkHelper>
                <div className='plmb-tags'>
                    <MainChipListBlock>
                        {object.themes?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
                <div className='list-block-footer'>
                    {object.profile && (
                        <ListProfileLink
                            blockSize='medium'
                            edit={edit}
                            other_profiles={object.other_profiles}
                            profile={object.profile}
                        />
                    )}
                </div>
                {/* {source && source.includes('profile_project') && object.credit && (
					<div className='plmb-credit-detail'>
						<div className='plmb-roles'>
							{Array.isArray(object.credit.roles) && object.credit.roles.map((val) => (
								<MainHashtag
									key={val.id}
                                    edit={edit}
									label={val.name}
								/>
							))}
						</div>
					</div>
				)} */}
            </div>
            <div className='plmb-actions'>
                <IconBlock
                    edit={edit}
                    iconClass='mo-new-icon-ellipsis-h-solid'
                    onClick={() => setActionSheetIsOpen(true)}
                />
            </div>
            {isActionSheetOpen && (
                <ProjectActionSheetMobile
                    object={object}
                    setActivated={setActivated}
                    setIsOpen={setActionSheetIsOpen}
                />
            )}
        </div>
    )
})
