// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    CheckboxChangeEventDetail,
} from '@ionic/core'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
    IonReorder,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    api_url_profile_section_form_update_active_in_profile_home,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    ProfileSectionListFormSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type OrganiseSectionFormItemProps = {
    item: ProfileSectionListFormSerializer
}

// main
export const OrganiseSectionFormItem: React.FC<OrganiseSectionFormItemProps> = React.memo(({
    item,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const [isChecked, setIsChecked] = useState<boolean>(item.active_in_profile_home === false ? false : true)

    async function onCheckboxChange(event: CustomEvent<CheckboxChangeEventDetail>) {
        try {

            if (disabledCheckbox) return
            if (event.detail.checked === isChecked) return
            setDisabledCheckbox(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'OrganiseSectionFormItem onCheckboxChange')

            const formData: any = new FormData()
            const putUrl = getApiUrl(`${api_url_profile_section_form_update_active_in_profile_home}${item.id}/`, reduxAuth)
            formData.append('active_in_profile_home', event.detail.checked)

            if (process.env.NODE_ENV === 'development') console.log(putUrl)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsChecked(event.detail.checked)
                    setDisabledCheckbox(false)
                })
                .catch((error) => {
                    setDisabledCheckbox(false)
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'OrganiseSectionFormItem',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onCheckboxChange',
                    })
                })
        } catch (error) {
            setDisabledCheckbox(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'OrganiseSectionFormItem',
                'onCheckboxChange',
            ))
        }
    }

    return (
        <IonItem>
            <IonLabel>
                <h3>{item.name}</h3>
            </IonLabel>
            <IonCheckbox
                checked={isChecked}
                disabled={disabledCheckbox}
                onIonChange={(e) => onCheckboxChange(e)}
                slot='end'
                value={item}
            />
            <IonReorder slot='end' />
        </IonItem>
    )
})
