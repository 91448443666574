// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// props
type NewsletterMergeFieldsPortfolioProps = {
    newsletterMergeFields: {
        name_1?: string | undefined;
        required?: boolean | undefined;
        tag?: string | undefined;
    }[]
}

// main
export const NewsletterMergeFieldsPortfolio: React.FC<NewsletterMergeFieldsPortfolioProps> = React.memo(({
    newsletterMergeFields,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='newsletter-merge-fields-portfolio-web'>
            <MainFormLabel
                label={reduxText[8265]}
                name='newsletterMergeFields'
                optional
            />
            <table>
                <thead>
                    <tr>
                        <th>{reduxText[8267]}</th>
                        <th>{reduxText[8268]}</th>
                        <th>{reduxText[8269]}</th>
                    </tr>
                </thead>
                <tbody>
                    {newsletterMergeFields?.map((val) => (
                        <tr key={val.tag}>
                            <td>{val.name_1}</td>
                            <td>{val.tag}</td>
                            <td>{val.required ? reduxText[3696] : reduxText[1236]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
})
