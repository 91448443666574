// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventPricingDetailFormSerializer,
    EventPricingDetailFormSerializerError,
} from 'serializers/web'

// props
type NameEventPricingInputProps = {
    errors: EventPricingDetailFormSerializerError | undefined
    fields: EventPricingDetailFormSerializer
    onChange: any
}

// main
export const NameEventPricingInput: React.FC<NameEventPricingInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            error={errors?.name}
            label={reduxText[5559]}
            name='name'
            onChange={onChange}
            value={fields.name || ''}
        />
    )
})
