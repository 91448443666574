// react components
import React from 'react'
import axios from 'axios'

// data
import {
    reduxAuthState,
    reduxFormAutoSaveRemoveSite,
    reduxFormStateSite,
} from 'data'

// services
import {
    axiosErrorHandler,
} from 'services'

// main
export function autoSaveStyles({
    component,
    dispatch,
    reduxAuth,
    reduxFormSite,
}: {
    component: string
    dispatch: React.Dispatch<any>
    reduxAuth: reduxAuthState
    reduxFormSite: reduxFormStateSite
}) {
    Object.getOwnPropertyNames(reduxFormSite.editAutoSave).map((val: any) => {
        if (process.env.NODE_ENV === 'development') console.log('autoSaveStyles', val)
        const formData = new FormData()
        formData.append('styles_original', JSON.stringify(reduxFormSite.editAutoSave[val].styles_original))
        const patchUrl = `${reduxFormSite.editAutoSave[val].udpdateUrl}${val}/`
        axios({
            data: formData,
            headers: reduxAuth.axiosConfig?.headers,
            method: 'patch',
            url: patchUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                dispatch(reduxFormAutoSaveRemoveSite(val))
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: patchUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'autoSaveStyles',
                    skipSend404Detail: true,
                })
            })
    })
}
