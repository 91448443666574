// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    InlineFormPanelProps,
    ProfileFormState,
} from 'serializers/web'

// props
type CarteCategoryProfilePanelProps = {
    formState: ProfileFormState
    setFormState: React.Dispatch<ProfileFormState>
}

// main
export const CarteCategoryProfilePanel: React.FC<InlineFormPanelProps & CarteCategoryProfilePanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const carteCategory = formState.carteCategory
    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, carteCategory)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CarteCategoryProfilePanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = carteCategory.errors
    const fieldsInline = carteCategory.fields

    if (!fieldsInline) return null

    return (
        <InlineFormContainerWeb
            formInfo={carteCategory}
            formState={formState}
            helpers={helpers}
            noOrdering
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            <TextInput
                error={errorsInline?.name}
                label={reduxText[5559]}
                name='name'
                onChange={onHandleInputChange}
                translation={{
                    contentType: 'cartecategory',
                    defaultDetailUrl: carteCategory.detailUrl,
                    defaultLanguage: fieldsInline.translation_default_language,
                    defaultUpdateUrl: carteCategory.updateUrl,
                    objectId: fieldsInline.id,
                    profileId: fieldsMother.id,
                }}
                value={fieldsInline.name || ''}
            />
            <TextInput
                error={errorsInline?.description}
                label={reduxText[4381]}
                name='description'
                onChange={onHandleInputChange}
                translation={{
                    contentType: 'cartecategory',
                    defaultDetailUrl: carteCategory.detailUrl,
                    defaultLanguage: fieldsInline.translation_default_language,
                    defaultUpdateUrl: carteCategory.updateUrl,
                    objectId: fieldsInline.id,
                    profileId: fieldsMother.id,
                }}
                value={fieldsInline.description || ''}
            />
        </InlineFormContainerWeb>
    )
})
