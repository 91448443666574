// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxSalesActivityInstallmentSite,
    reduxSalesActivityPaymentMethodSite,
} from 'data'

// pages
import {
    CheckboxInput819Site,
    SelectListInput819Site,
} from 'pages'

// props
type ReviewHelper819SiteProps = {
    test?: string
}

// main
export const ReviewHelper819Site: React.FC<ReviewHelper819SiteProps> = React.memo(({
    test,
}) => {

    const dispatch = useDispatch()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    return (
        <div className={`t-819-review ${deviceType}`}>
            <span className={`t-819-review-title ${deviceType}`}>{reduxText[8738]}</span>
            <span className={`t-819-review-sub-title ${deviceType}`}>Cours</span>
            {reduxSalesSite.activityData?.registrations && Object.values(reduxSalesSite.activityData.registrations).filter(obj => obj.qty > 0).map((val) => (
                [...Array(val.qty)].map((_, index) => (
                    <div
                        key={`${val.variant.id}-${index}`}
                        className={`t-819-review-ticket-wrap ${deviceType}`}
                    >
                        <span className={`t-819-review-ticket-participant ${deviceType}`}>{val.students?.[index]?.fields?.first_name} {val.students?.[index]?.fields?.last_name}</span>
                        <div className={`t-819-review-ticket-variant ${deviceType}`}>
                            <span className={`t-819-review-ticket-variant-name ${deviceType}`}>{val.course.name} - {val.variant.name || val.variant.custom_foreign_key_1?.name}</span>
                            <span className={`t-819-review-ticket-variant-price ${deviceType}`}>{val.variant.unit_price}  {reduxCacheSite.portfolio?.currency?.symbol}</span>
                        </div>
                    </div>
                ))
            ))}
            <span className={`t-819-review-sub-title ${deviceType}`}>Choisissez votre moyen de paiement</span>
            <SelectListInput819Site
                error=''
                label=''
                name='custom_foreign_key_3'
                onChange={(e: any) => dispatch(reduxSalesActivityPaymentMethodSite(e.value))}
                options={[
                    {
                        // @ts-ignore
                        id: 'stripe',
                        name: 'En ligne',
                    },
                    {
                        // @ts-ignore
                        id: 'cheque',
                        name: 'Ch�que',
                    },
                    {
                        // @ts-ignore
                        id: 'cheque-sport',
                        name: 'Coupon Sport',
                    },
                    {
                        // @ts-ignore
                        id: 'cheque-vacances-ancv',
                        name: 'Ch�que vacances ANCV',
                    },
                ]}
                value={reduxSalesSite.activityData?.paymentMethod || {
                    // @ts-ignore
                    id: 'stripe',
                    name: 'En ligne',
                }}
            />
            {(!reduxSalesSite.activityData?.paymentMethod?.id || (reduxSalesSite.activityData?.paymentMethod?.id === 'stripe')) && (
                <div className={`t-819-muti-payment ${deviceType}`}>
                    <CheckboxInput819Site
                        error=''
                        label="Je souhaite payer en 3 fois"
                        name=''
                        onChange={(e: any) => dispatch(reduxSalesActivityInstallmentSite(e.value))}
                        value={reduxSalesSite.activityData?.isInstallment || ''}
                    />
                </div>
            )}
        </div>
    )
})
