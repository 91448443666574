// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    view_url_event_form,
    view_url_offer_form,
    view_url_press_form,
    view_url_product_form,
    view_url_profile_form,
    view_url_project_form,
    view_url_resource_form,
} from 'data'

// props
type MenuFormsMobileProps = {
    setShowModal: any
}

// main
export const MenuFormsMobile: React.FC<MenuFormsMobileProps> = React.memo(({
    setShowModal,
}) => {

    const history = useHistory()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [formPages, setFormPages] = useState<{
        icon: string
        primary: string
        secondary: string
        slug: string
        url: string
    }[]>([])

    useEffect(() => {

        const tempFormPages = [
            {
                icon: 'mo-new-icon-models-artwork',
                primary: reduxText[3528],
                secondary: reduxText[4328],
                slug: 'project',
                url: `${view_url_project_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-medium-types-7',
                primary: reduxText[4812],
                secondary: reduxText[5409],
                slug: 'project',
                url: `${view_url_press_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-models-shop',
                primary: reduxText[4356],
                secondary: reduxText[4357],
                slug: 'product',
                url: `${view_url_product_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-models-event',
                primary: reduxText[1256],
                secondary: reduxText[4330],
                slug: 'event',
                url: `${view_url_event_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-models-offer',
                primary: reduxText[1259],
                secondary: reduxText[4333],
                slug: 'offer',
                url: `${view_url_offer_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-medium-types-7',
                primary: reduxText[8187],
                secondary: reduxText[8188],
                slug: 'project',
                url: `${view_url_resource_form}new/main/main-panel/`,
            },
            // {
            // 	icon: 'mo-new-icon-models-material',
            // 	primary: reduxText[301],
            // 	secondary: reduxText[4332],
            // 	url: `${view_url_material_form}new/main/main-panel/`,
            // },
            {
                icon: 'mo-new-icon-models-profile',
                primary: reduxText[1260],
                secondary: reduxText[4329],
                slug: 'profile',
                url: `${view_url_profile_form}new/main/main-panel/`,
            },
        ]

        setFormPages(tempFormPages)
    }, [
        reduxText,
    ])

    function onGoToForm(url: string, slug: string) {
        setShowModal(false)
        history.push(
            url,
            { formRefresh: Math.random() },
        )
    }

    return (
        <React.Fragment>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>{reduxText[2138]}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                className='mo-menu-forms'
                forceOverscroll
                fullscreen
            >
                <IonList>
                    {formPages.map((val, i) => {
                        return (
                            <IonItem
                                key={i}
                                button
                                onClick={() => onGoToForm(val.url, val.slug)}
                            >
                                <i className={`mo-menu-forms-icon ${val.icon}`} />
                                <IonLabel>
                                    <h2>{val.primary}</h2>
                                    <p className='mo-menu-forms-second-label'>{val.secondary}</p>
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonList>
            </IonContent>
        </React.Fragment>
    )
})
