// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    ListSpinner,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    select_url_main_language_portfolio,
} from 'data'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// pages
import {
    CheckboxInputV2,
    FileInput,
    FormSeparator,
    SelectListInputV2,
    TextAreaInputV2,
    TextInputV2,
} from 'pages'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getMainErrorMessage,
} from 'services'

// props
type MatchParams = {
    id: string
}

type SettingsPortfolioPanelProps = {
    formState: PortfolioFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    setFormState: React.Dispatch<PortfolioFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const SettingsPortfolioPanel: React.FC<SettingsPortfolioPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSitepermissions = useSelector((state: defaultReduxState) => state.reduxFormSite.permissions)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    useEffect(() => {
        onGetData()
    }, [
        params.id,
        reduxAuth.apiRootUrl,
    ])

    const [isLoading, setIsLoading] = useState(false)

    async function onGetData() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SettingsPortfolioPanel onGetData')

            const getUrl = `${mother.detailUrl}${params.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            fields: response.data,
                        }
                    })
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'SettingsPortfolioPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onGetData',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'SettingsPortfolioPanel',
                'onGetData',
            ))
        }
    }

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SettingsPortfolioPanel',
                'onHandleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: mother,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    mother: {
                        ...formState.mother,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SettingsPortfolioPanel',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SettingsPortfolioPanel handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            const axiosUrl = `${mother.updateUrl}${mother.fields?.id}/`
            const formData: any = getFormData(mother)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    dispatch(reduxFormSetRefresh('refreshPreview', mother.fields?.id))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: {},
                            fields: {
                                ...formState.mother.fields,
                                id: response.data.id,
                                slug: response.data.slug,
                            },
                        }
                    })
                    dispatch(reduxFormSetEditingSite(false))
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: reduxText[4798],
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'SettingsPortfolioPanel',
                        dispatch,
                        error,
                        formFields: JSON.stringify(mother.fields),
                        formFieldsToIgnore: ['name'],
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'SettingsPortfolioPanel',
                'handleSubmit',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    if (isLoading) {
        return (
            <ListSpinner
                color='tertiary'
                isLoading
            />
        )
    }

    if (reduxFormSitepermissions?.permissionIsDisabledRest) {
        return (
            <div style={{
                padding: '20px',
            }}>
                <p>{reduxText[9975]}</p>
            </div>
        )
    }

    return (
        <div className={`pfcw-container mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className={`pfcw-container-fields mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <TextInputV2
                    error={errorsMother?.hostname}
                    disabled
                    label={reduxText[6282]}
                    name='hostname'
                    onChange={onHandleInputChange}
                    value={fieldsMother.hostname || ''}
                />
                <TextInputV2
                    error={errorsMother?.name}
                    helperText={reduxText[628810]}
                    label={reduxText[6288]}
                    name='name'
                    onChange={onHandleInputChange}
                    translation={fieldsMother.is_multi_language ? {
                        contentType: 'portfolio',
                        defaultDetailUrl: mother.detailUrl,
                        defaultLanguage: fieldsMother.translation_default_language,
                        defaultUpdateUrl: mother.updateUrl,
                        objectId: fieldsMother.id,
                        profileId: fieldsMother.profile?.id,
                    } : undefined}
                    value={fieldsMother.name || ''}
                />
                <FileInput
                    error={errorsMother?.favicon}
                    helperText={reduxText[627410]}
                    label={reduxText[6274]}
                    name='favicon'
                    onChange={onHandleInputChange}
                    // text={reduxText[3748]}
                    value={fieldsMother.favicon || ''}
                />
                <TextInputV2
                    error={errorsMother?.contact_email}
                    helperText={reduxText[626610]}
                    label={reduxText[6266]}
                    name='contact_email'
                    onChange={onHandleInputChange}
                    value={fieldsMother.contact_email || ''}
                />
                <FormSeparator
                    staffOnly={!fieldsMother.is_multi_language}
                    text='Languages Settings'  // TO_TEXT
                />
                <CheckboxInputV2
                    error={errorsMother?.is_multi_language}
                    label={reduxText[8321]}
                    name='is_multi_language'
                    onChange={onHandleInputChange}
                    staffOnly
                    value={fieldsMother.is_multi_language}
                />
                <SelectListInputV2
                    apiUrl={select_url_main_language_portfolio}
                    clearable={false}
                    disabled={!reduxAuth.settings?.user?.is_staff}
                    error={errorsMother?.languages}
                    label={reduxText[8323]}
                    multi
                    name='languages'
                    onChange={onHandleInputChange}
                    staffOnly={!fieldsMother.is_multi_language}
                    v1
                    value={fieldsMother.languages || ''}
                />
                <FormSeparator
                    text='SEO'  // TO_TEXT
                />
                <TextInputV2
                    error={errorsMother?.seo_title}
                    helperText={reduxText[630710]}
                    label={reduxText[6307]}
                    name='seo_title'
                    onChange={onHandleInputChange}
                    translation={fieldsMother.is_multi_language ? {
                        contentType: 'portfolio',
                        defaultDetailUrl: mother.detailUrl,
                        defaultLanguage: fieldsMother.translation_default_language,
                        defaultUpdateUrl: mother.updateUrl,
                        objectId: fieldsMother.id,
                        profileId: fieldsMother.profile?.id,
                    } : undefined}
                    value={fieldsMother.seo_title || ''}
                />
                <TextAreaInputV2
                    error={errorsMother?.description}
                    helperText={reduxText[627210]}
                    label={reduxText[6272]}
                    name='description'
                    onChange={onHandleInputChange}
                    translation={fieldsMother.is_multi_language ? {
                        contentType: 'portfolio',
                        defaultDetailUrl: mother.detailUrl,
                        defaultLanguage: fieldsMother.translation_default_language,
                        defaultUpdateUrl: mother.updateUrl,
                        objectId: fieldsMother.id,
                        profileId: fieldsMother.profile?.id,
                    } : undefined}
                    value={fieldsMother.description || ''}
                />
                <FileInput
                    error={errorsMother?.seo_image}
                    helperText={reduxText[629410]}
                    label={reduxText[6294]}
                    name='seo_image'
                    onChange={onHandleInputChange}
                    // text={reduxText[3748]}
                    value={fieldsMother.seo_image || ''}
                />
                <FormSeparator
                    text='Tracking Settings'  // TO_TEXT
                />
                <TextInputV2
                    error={errorsMother?.google_analytics}
                    helperText={reduxText[628010]}
                    label={reduxText[6280]}
                    name='google_analytics'
                    onChange={onHandleInputChange}
                    value={fieldsMother.google_analytics || ''}
                />
                <TextInputV2
                    error={errorsMother?.google_tag_manager}
                    // helperText={reduxText[628010]}
                    // label={reduxText[6280]}
                    label='google_tag_manager'  // TO_TEXT
                    name='google_tag_manager'
                    onChange={onHandleInputChange}
                    value={fieldsMother.google_tag_manager || ''}
                />
                <FormSeparator
                    staffOnly
                    text='Others'  // TO_TEXT
                />
                <CheckboxInputV2
                    error={errorsMother?.coming_soon}
                    label={reduxText[6268]}
                    name='coming_soon'
                    onChange={onHandleInputChange}
                    staffOnly
                    value={fieldsMother.coming_soon}
                />
            </div>
            <div className='pfcw-footer2'>
                <Button
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    onClick={handleRequired}
                    text={reduxText[6378]}
                />
            </div>
        </div>
    )
})
