// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelper,
    Loader,
    ProfileShortInfoWeb,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    languageObjectSite,
    reduxModalDetail2Show,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
    reduxModalSliderDetailShowHide,
    reduxModalSliderOnNext,
    reduxModalSliderOnPrev,
} from 'data'

// pages
import {
    DetailContent,
    DetailAction,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getModalAbsoluteUrlV2,
} from 'services'

// props
type DetailModalProps = {
    contentType: contentTypeModelType
    detailId: string
    isDetailModal2?: boolean
}

// main
export const DetailModal: React.FC<DetailModalProps> = React.memo(({
    contentType,
    detailId,
    isDetailModal2,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalmodalDetail = useSelector((state: defaultReduxState) => state.reduxModal.modalDetail)

    // const [languageId, setLanguageId] = useState<number>()
    const [languageId] = useState<number>()

    useEffect(() => {
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
        getData(detailId, languageId)
    }, [
        detailId,
        languageId,
    ])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [object, setObject] = useState<any>('')
    const [isLoading, setIsLoading] = useState(false)
    // const [modalKeys, setModalKeys] = useState<{
    //     [key: string]: number,
    // }>({})

    const app = contentType.split('_')[0]
    const model = contentType.split('_')[1]
    const apiUrlDetail = getApiUrlV2(`${app}/detail/${model}/`, reduxAuth)

    const scrollToTop = () => {
        try {
            // @ts-ignore
            if (document.getElementById('mo-dms-content-id-site') && document.getElementById('mo-dms-content-id-site').scrollTo) document.getElementById('mo-dms-content-id-site').scrollTo(0, 0)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailModal',
                'scrollToTop',
            ))
        }
    }

    async function getData(objectId: string, languageId: number | undefined) {
        try {
            // setObject('')
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DetailModal getData')
            if (!objectId) return
            setIsLoading(true)
            let getUrl = `${apiUrlDetail}${objectId}/` // Keep the last '/' for Safari !!!!!!!
            if (languageId || languageObjectSite[reduxAuth.settings?.language!]) getUrl += `?language_id=${languageId || languageObjectSite[reduxAuth.settings?.language!]}`
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setObject(response.data)
                    dispatch(reduxModalSeoData({
                        title: `${response.data.name || response.data.id} | ${response.data.sub_content_type?.name || response.data.content_type_string}`,
                    }))
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'DetailModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendError: Boolean(objectId) && (error.response?.status === 404) && Boolean(error.response?.data?.detail),
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailModal',
                'getData',
            ))
        }
    }

    function onNextSlide() { // next slide function
        try {
            scrollToTop()
            dispatch(reduxModalSliderOnNext())
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailModal',
                'onNextSlide',
            ))
        }
    }

    function onPrevSlide() { // prev slide function
        try {
            scrollToTop()
            dispatch(reduxModalSliderOnPrev())
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailModal',
                'onPrevSlide',
            ))
        }
    }

    function handleClose() {
        try {
            if (isDetailModal2) {
                dispatch(reduxModalDetail2Show({ isOpen: false }))
            } else {
                dispatch(reduxModalSliderDetailShowHide(false))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailModal',
                'handleClose',
            ))
        }
    }

    // if (!reduxModalmodalDetail.data?.[reduxModalmodalDetail.currentKey!]) return null

    const contentData = reduxModalmodalDetail.data?.[reduxModalmodalDetail.currentKey!]
    const contentIndex = reduxModalmodalDetail.slideIndex || 0

    const dataLength = contentData?.length
    const multiData = dataLength! > 1
    const nextIndex = contentData?.[contentIndex + 1] ? (contentIndex + 1) : (multiData ? 0 : -1)
    const prevIndex = contentData?.[contentIndex - 1] ? (reduxModalmodalDetail.slideIndex! - 1) : (multiData ? (dataLength! - 1) : -1)
    const hasNext = reduxModalmodalDetail.objectId && contentData?.[nextIndex]
    const hasPrev = reduxModalmodalDetail.objectId && contentData?.[prevIndex]
    const nextObject = contentData?.[nextIndex]
    const prevObject = contentData?.[prevIndex]
    const backUrl = reduxModalmodalDetail.listUrl

    return (
        <div className='detail-page-modal-web'>
            <div className={`dpmw-content mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <LinkHelper
                    className='dpmw-backdrop'
                    edit={false}
                    onClick={() => handleClose()}
                    to={backUrl}
                />
                <div className='dpmw-navigation'>
                    {hasPrev && (
                        <LinkHelper
                            className='dpmw-previous'
                            edit={false}
                            onClick={() => onPrevSlide()}
                            to={hasPrev ? getModalAbsoluteUrlV2({
                                contentType,
                                objectId: prevObject.id!,
                                pageType: 'detail',
                            }) : undefined}
                        >
                            <i className='dpmw-icon mo-new-icon-chevron-left-solid' />
                        </LinkHelper>
                    )}
                    <LinkHelper
                        className='dpmw-close'
                        edit={false}
                        onClick={() => handleClose()}
                        to={backUrl}
                    >
                        <i className='dpmw-close-icon mo-new-icon-times-solid' />
                    </LinkHelper>
                    {hasNext && (
                        <LinkHelper
                            className='dpmw-next'
                            edit={false}
                            onClick={() => onNextSlide()}
                            to={hasNext ? getModalAbsoluteUrlV2({
                                contentType,
                                objectId: nextObject.id!,
                                pageType: 'detail',
                            }) : undefined}
                        >
                            <i className='dpmw-icon mo-new-icon-chevron-right-solid' />
                        </LinkHelper>
                    )}
                </div>
                <div className='dpmw-modal-wrap'>
                    <div
                        className='dpmw-header'
                        style={{
                            backgroundImage: `url(${object.get_image_hq})`,
                        }}
                    >
                        <div className='dpmw-header-background-image-overlay' />
                        <DetailAction
                            contentType={contentType}
                            contentTypeSettings={object.content_type_settings_json}
                            contentTypeSettingsCustom={object.profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]}
                            inModal
                            object={object}
                            refreshData={() => getData(detailId, languageId)}
                        />
                        <div className='dpmw-title-wrap'>
                            <h1 className='dpmw-title mo-clamp-web1'>{object.name}</h1>
                        </div>
                        <div className='dpmw-header-left'>
                            <ProfileShortInfoWeb
                                edit={false}
                                noImage
                                object={object.parent_short || object.profile}
                                right={object.profile?.id === 191231}
                            />
                        </div>
                    </div>
                    <div className={`dpmw-modal-content ${contentType}`}>
                        {object && (
                            <DetailContent
                                contentType={contentType}
                                contentTypeSettings={object.content_type_settings_json}
                                inModal
                                // languageId={languageId}
                                // modalKeys={modalKeys}
                                object={object}
                                refreshData={() => getData(detailId, languageId)}
                            // setLanguageId={setLanguageId}
                            />
                        )}
                    </div>
                </div>
                <Loader
                    backdropDismiss
                    isOpen={isLoading}
                />
            </div>
        </div>
    )
})
