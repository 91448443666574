// react components
import React from 'react'

// data
import {
    contentTypeModelType,
    reduxAuthState,
    reduxFormHelpersState,
    reduxTextState,
} from 'data'

// services
import {
    getMediaAllV2,
    getMediaBandcampV2,
    getMediaSoundcloudV2,
    getMediaVimeoV2,
    getMediaYoutubeV2,
    getFormMeta,
} from 'services'

// main
export function getFormtMediaData({
    contentType,
    formState,
    helpers,
    image_only,
    prefixImage,
    prefixLink = '',
    reduxAuth,
    reduxText,
    setFormState,
    setGetMetaIsLoading,
    setHelpers,
    urltoParse,
}: {
    contentType: contentTypeModelType
    formState: any
    helpers: reduxFormHelpersState
    image_only: boolean
    prefixImage: string
    prefixLink?: string
    reduxAuth: reduxAuthState
    reduxText: reduxTextState['data']
    setFormState: React.Dispatch<any>
    setGetMetaIsLoading: React.Dispatch<boolean>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    urltoParse: string
}) {

    if (!urltoParse) return

    // Arte
    if (urltoParse.includes('arte.tv') || urltoParse.includes('arteradio.com') || urltoParse.includes('facebook.com') || urltoParse.includes('giphy.com') || urltoParse.includes('dailymotion.com')) { // Check if platform arte, vimeo, facebook, giphy, dailymotion
        getMediaAllV2({
            formState,
            helpers,
            image_only,
            prefixImage,
            prefixLink,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    // Bandcamp
    else if (urltoParse.includes('bandcamp.com')) { // Check if platform bandcamp
        getMediaBandcampV2({
            formState,
            helpers,
            image_only,
            prefixImage,
            prefixLink,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    // Soundcloud
    else if (urltoParse.includes('soundcloud.com')) { // Check if platform soundcloud
        getMediaSoundcloudV2({
            formState,
            helpers,
            image_only,
            prefixImage,
            prefixLink,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    // Youtube
    else if ((urltoParse.includes('youtu.be') || urltoParse.includes('youtube.com')) && !urltoParse.includes('/@')) { // Check if platform youtube
        getMediaYoutubeV2({
            formState,
            helpers,
            image_only,
            prefixImage,
            prefixLink,
            reduxAuth,
            reduxText,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    // Vimeo
    else if (urltoParse.includes('vimeo.com')) { // Check if platform vimeo
        getMediaVimeoV2({
            formState,
            helpers,
            image_only,
            prefixImage,
            prefixLink,
            reduxText,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    else { // Check if platform not detect
        getFormMeta({
            contentType,
            formState,
            helpers,
            noFullRender: true,
            prefixImage,
            prefixLink,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }
}
