// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxAuthAfterAuthUrlSetSite,
    view_url_auth_login,
    view_url_maintenance,
} from 'data'

// pages
import {
    NotFoundPage,
} from 'pages'

// props
type RequireStaffProps = {
    children: JSX.Element
}

// main
export const RequireStaff: React.FC<RequireStaffProps> = React.memo(({
    children,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    if (!reduxAuth.authenticated) {
        dispatch(reduxAuthAfterAuthUrlSetSite(`${window.location.pathname}${window.location.search}`))
        return (
            <Redirect
                to={view_url_auth_login}
            />
        )
    }

    if (!reduxAuth.settings?.user?.is_staff) {
        return <NotFoundPage />
    }

    if (reduxAuth.settings?.active_profile?.is_blocked) {
        return (
            <Redirect
                to={view_url_maintenance}
            />
        )
    }

    if (reduxAuth.settings?.active_profile?.is_new_profile_ux_in_progress) {
        return (
            <Redirect
                to={view_url_maintenance}
            />
        )
    }

    return children
})
