// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    IconBlock,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalMainFormShow,
    view_url_admin,
    view_url_staff,
} from 'data'

// serializers
import {
    ModuleMenuItemsViewTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getModalAbsoluteUrlV2,
    getReduxTextId,
    onClickIsModal,
} from 'services'

// props
type MatchParams = {
    moduleId?: string
    viewId?: string
}

type AdminMenuItemViewProps = {
    isStaff: boolean
    level: number
    moduleId: number
    object: ModuleMenuItemsViewTypes
    parentsArray?: number[]
    profileObject: ProfileDetailAdminSerializerV2
    refreshData: () => void
    showSettings: boolean
}

// main
export const AdminMenuItemView: React.FC<AdminMenuItemViewProps> = React.memo(({
    isStaff,
    level,
    moduleId,
    object,
    parentsArray = [],
    profileObject,
    refreshData,
    showSettings,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const parseQueryArray = (search: string, key: string): string[] => {
        const params = new URLSearchParams(search)
        return params.get(key)?.split(',') || []
    }

    const [deleted, setDeleted] = useState<boolean>()
    const [isChildOpen, setIsChildOpen] = useState<boolean>(params.viewId === `${object.id}` || parseQueryArray(location.search, 'parentsArray').includes(`${object.id}`))
    const [isInit, setIsInit] = useState(false)

    useEffect(() => {
        const shoulOpen = (params.viewId === `${object.id}` || parseQueryArray(location.search, 'parentsArray').includes(`${object.id}`))
        if (isInit && isChildOpen && !shoulOpen) {
            setIsChildOpen(shoulOpen)
        }
        setIsInit(true)
    }, [
        params.viewId,
    ])

    if (deleted) return null

    let toLink = ''
    if (isStaff) {
        toLink = view_url_staff
    } else {
        toLink = view_url_admin
    }
    toLink += `${profileObject.id}/section/${moduleId}/${object.id}/`
    if (parentsArray.length > 0) {
        toLink += `?parentsArray=${parentsArray.map((val) => val).join(',')}`
    }

    return (
        <>
            <div className={`admin-menu-item-view${object.views?.length! > 0 ? ' parent' : ''}`}>
                <NavLink
                    className='amiv-tab-item-children'
                    to={toLink}
                    onClick={() => setIsChildOpen(!isChildOpen)}
                >
                    {isStaff && (
                        getReduxTextId(object.content_type_variation
                            ? (
                                object.name ||
                                profileObject.content_type_settings_global?.[object.content_type]?.meta.variation?.[object.content_type_variation]?.verboseNamePlural ||
                                profileObject?.settings_data_staff_json?.contentTypeSettingsCustom?.[object.content_type]?.meta?.verboseNamePlural ||
                                profileObject.content_type_settings_global?.[object.content_type]?.meta.verboseNamePlural!
                            )
                            : (
                                object.name ||
                                profileObject?.settings_data_staff_json?.contentTypeSettingsCustom?.[object.content_type]?.meta?.verboseNamePlural ||
                                profileObject.content_type_settings_global?.[object.content_type]?.meta.verboseNamePlural!
                            ),
                            reduxText
                        )
                    )}
                    {!isStaff && (
                        getReduxTextId(object.content_type_variation
                            ? (
                                object.name ||
                                profileObject.content_type_settings_global?.[object.content_type]?.meta.variation?.[object.content_type_variation]?.verboseNamePlural ||
                                profileObject?.settings_data_json?.contentTypeSettingsCustom?.[object.content_type]?.meta?.verboseNamePlural ||
                                profileObject.content_type_settings_global?.[object.content_type]?.meta.verboseNamePlural!
                            )
                            : (
                                object.name ||
                                profileObject?.settings_data_json?.contentTypeSettingsCustom?.[object.content_type]?.meta?.verboseNamePlural ||
                                profileObject.content_type_settings_global?.[object.content_type]?.meta.verboseNamePlural!
                            ),
                            reduxText
                        )
                    )}
                </NavLink>
                {object.views?.length! > 0 && (
                    <i
                        className={`amiv-expand mo-new-icon-chevron-down-solid${isChildOpen ? ' open' : ''}${object.views?.length! > 0 ? '' : ' hidden'}`}
                        onClick={() => setIsChildOpen(!isChildOpen)}
                    />
                )}
                {showSettings && (
                    <>
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-pencil-alt-solid'
                            onClick={() =>
                                dispatch(reduxModalMainFormShow({
                                    contentType: 'setup_moduleview',
                                    cssClass: 'form-container-modal',
                                    formComponent: 'FormModal',
                                    objectId: object.id,
                                    refreshData,
                                    setDeleted,
                                }))
                            }
                        />
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-eye-solid'
                            onClick={() => onClickIsModal(object.id, Math.random(), dispatch)}
                            to={getModalAbsoluteUrlV2({
                                contentType: 'setup_moduleview',
                                objectId: object.id,
                                pageType: 'detail',
                            })}
                        />
                    </>
                )}
            </div>
            {object.views?.length! > 0 && (
                <div className={`ami-children-wrap${isChildOpen ? ' open' : ''}`}>
                    {object.views?.map((val) => (
                        <AdminMenuItemView
                            key={val.id}
                            isStaff={isStaff}
                            level={level + 1}
                            moduleId={moduleId}
                            object={val}
                            parentsArray={[...parentsArray!, object.id]}
                            profileObject={profileObject}
                            refreshData={refreshData}
                            showSettings={showSettings}
                        />
                    ))}
                </div>
            )}
        </>
    )
})
