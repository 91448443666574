// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    PortfolioUserGroupsFieldInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_profile_team,
} from 'data'

// pages
import {
    AddressAdditionalAdherentInput,
    AdherentNumberAdherentInput,
    CityTempAdherentInput,
    CustomCategoryAdherentInput,
    CustomDate1AdherentInput,
    CustomFKCC1AdherentInput,
    CustomMTMCC1AdherentInput,
    CustomText1AdherentInput,
    DateMembershipEndAdherentInput,
    DateMembershipStartAdherentInput,
    EmailAdherentInput,
    FirstNameAdherentInput,
    IsParentAdherentInput,
    LastNameAdherentInput,
    ManyToManyProfileTeamsAdherentInput,
    ParentAdherentInput,
    PhoneNumberAdherentInput,
    ProfileAdherentInput,
} from 'pages'

// serializers
import {
    AdherentFormType,
} from 'serializers/web'

// props
type MainAdherentPanelProps = {
    formState: AdherentFormType
    handleInputChange: any
}

// main
export const MainAdherentPanel: React.FC<MainAdherentPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.adherent

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainAdherentPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    if (fieldsMother.profile?.id === 183405) {  // Alize
        return (
            <React.Fragment>
                <ProfileAdherentInput
                    disabled={Boolean(fieldsMother.id)}
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <LastNameAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <FirstNameAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <CityTempAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <AddressAdditionalAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <EmailAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <PhoneNumberAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <CustomCategoryAdherentInput
                    ccKey={1}
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <ManyToManyProfileTeamsAdherentInput
                    apiUrl={`${select_url_profile_team}?profile_id=${fieldsMother.profile.id}&section_id=46356`}
                    errors={errorsMother}
                    fields={fieldsMother}
                    label='R�gion'  // TO_TEXT
                    onChange={onHandleInputChange}
                />
                <DateMembershipStartAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <DateMembershipEndAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <PortfolioUserGroupsFieldInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                    profileId={fieldsMother.profile?.id}
                />
            </React.Fragment>
        )
    }

    if (fieldsMother.profile?.id === 183474) {  // APPFE
        return (
            <React.Fragment>
                <ProfileAdherentInput
                    disabled={Boolean(fieldsMother.id)}
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <LastNameAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <FirstNameAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <EmailAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <DateMembershipStartAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <CustomMTMCC1AdherentInput
                    errors={errorsMother}
                    field_name='status'
                    fields={fieldsMother}
                    label='Status'
                    onChange={onHandleInputChange}
                />
                <AdherentNumberAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <DateMembershipEndAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
            </React.Fragment>
        )
    }

    if (fieldsMother.profile?.id === 187728) {  // FCAPI
        return (
            <React.Fragment>
                <ProfileAdherentInput
                    disabled={Boolean(fieldsMother.id)}
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <LastNameAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <FirstNameAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <EmailAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <PhoneNumberAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <ManyToManyProfileTeamsAdherentInput
                    apiUrl={`${select_url_profile_team}?profile_id=${fieldsMother.profile.id}&section_id=52184`}
                    errors={errorsMother}
                    fields={fieldsMother}
                    label='Chiens'
                    onChange={onHandleInputChange}
                />
                <CustomDate1AdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    label='Validit� carte handicap�'
                    onChange={onHandleInputChange}
                />
                <DateMembershipEndAdherentInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    label='Date renouvellement cotisation'
                    onChange={onHandleInputChange}
                />
            </React.Fragment>
        )
    }

    if (fieldsMother.profile?.id === 187807) {  // HEY
        return (
            <React.Fragment>
                <ProfileAdherentInput
                    disabled={Boolean(fieldsMother.id)}
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <LastNameAdherentInput
                    disabled
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <FirstNameAdherentInput
                    disabled
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
                <EmailAdherentInput
                    disabled
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
            </React.Fragment>
        )
    }

    return (
        <ProfileAdherentInput
            disabled={Boolean(fieldsMother.id)}
            errors={errorsMother}
            fields={fieldsMother}
            onChange={onHandleInputChange}
        />
    )
})
