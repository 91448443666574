// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

// components
import {
    ErrorHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FormLabel,
} from 'pages'

// serializer
import {
    TranslationHelper,
} from 'serializers/web'

// props
type TextAreaInputV2Props = {
    adminOnly?: boolean
    directionRtl?: boolean
    disabled?: boolean
    error?: string
    helperText?: string
    label?: string
    marginBottom?: number
    name: string
    noLineBreak?: boolean
    onChange: any
    onRefresh?: () => void
    placeholder?: string
    staffOnly?: boolean
    translation?: TranslationHelper
    value: string
}

// main
export const TextAreaInputV2: React.FC<TextAreaInputV2Props> = React.memo(({
    adminOnly,
    directionRtl,
    disabled,
    error,
    helperText,
    label,
    marginBottom,
    name,
    noLineBreak,
    onChange,
    onRefresh,
    placeholder,
    staffOnly,
    translation,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    function onInputChange(value: string | null | undefined) {
        try {
            if (noLineBreak && value) {
                value = value.replace(/\n/g, ' ').trim()
            }
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TextAreaInputV2',
                'onInputChange',
            ))
        }
    }

    if (adminOnly && !reduxAuth.settings?.user?.id) return null
    if (staffOnly && !reduxAuth.settings?.user?.is_staff) return null

    if (label || translation) {
        return (
            <div
                className={`input-wrap${disabled ? ' disabled' : ''}`}
                style={{
                    marginBottom: marginBottom ? `${marginBottom}px` : undefined,
                }}
            >
                <div className='input-label-wrap'>
                    <FormLabel
                        adminOnly={adminOnly}
                        formInput='textArea'
                        helperText={helperText}
                        label={label || ''}
                        name={name}
                        onRefresh={onRefresh}
                        staffOnly={staffOnly}
                        translation={translation}
                        translationDefaultValue={value}
                    />
                </div>
                <TextareaAutosize
                    className={`text-area-input${directionRtl ? ' rtl' : ''}`}
                    disabled={disabled}
                    name={name}
                    onChange={(e) => onInputChange(e.target.value)}
                    placeholder={placeholder}
                    rows={1}
                    value={value}
                />
                <ErrorHelper error={error} />
            </div>
        )
    }

    return (
        <>
            <TextareaAutosize
                className={`text-area-input${directionRtl ? ' rtl' : ''}`}
                disabled={disabled}
                name={name}
                onChange={(e) => onInputChange(e.target.value)}
                placeholder={placeholder}
                rows={1}
                value={value}
            />
            {marginBottom && (
                <div
                    style={{
                        marginBottom: `${marginBottom}px`,
                    }}
                />
            )}
        </>
    )
})
