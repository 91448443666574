// react components
import React, {
    useEffect,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// containers
import {
    InlineFormModal,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ContributionFormState,
    PaymentDateContributionInput,
    PricingContributionInput,
    YearContributionInput,
} from 'pages'

// serializers
import {
    AdherentFormType,
    InlineFormPanelPropsNew,
} from 'serializers/web'

// props
type ContributionAdherentPanelProps = {
    formState: AdherentFormType
    setFormState: React.Dispatch<AdherentFormType>
}

// main
export const ContributionAdherentPanel: React.FC<InlineFormPanelPropsNew & ContributionAdherentPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    modalTitle,
    setFormState,
    setHelpers,
    setInlineModalValue,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const mother = formState.adherent
    const contribution = formState.contribution

    useEffect(() => {
        setInitialState()
    }, [])

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, contribution)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContributionAdherentPanel',
                'onHandleInputChange',
            ))
        }
    }

    function setInitialState() {
        const contribution = ContributionFormState(reduxAuth)
        setFormState({
            ...formState,
            contribution: {
                ...contribution,
                fields: {
                    adherent: {
                        id: fieldsMother?.id
                    },
                    profile: {
                        id: fieldsMother?.profile?.id
                    },
                },
            },
        })
    }

    // Mother
    const fieldsMother = mother.fields

    // Inline
    const errorsInline = contribution.errors
    const fieldsInline = contribution.fields

    if (!fieldsInline) return null

    return (
        <InlineFormModal
            formInfo={contribution}
            formListComponent='AdherentContributionListFormBlock'
            formState={formState}
            helpers={helpers}
            listUrl={`${contribution.listUrl}${fieldsMother?.id}/`}
            modalTitle={modalTitle}
            parentModel='adherent'
            setFormState={setFormState}
            setHelpers={setHelpers}
            setInitialState={setInitialState}
            setInlineModalValue={setInlineModalValue}
            tableHeaderComponent='AdherentContributionListFormHeader'
        >
            <PricingContributionInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            <YearContributionInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            <PaymentDateContributionInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
        </InlineFormModal>
    )
})
