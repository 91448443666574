// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    LanguageFieldInput,
    PortfolioDetailPageFieldInput,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    conditionAdditionalInformationWeb,
    CityTempProjectInput,
    DateEndProjectInput,
    LyricsProjectInput,
    ProjectTypeProjectInput,
    ThemeProjectInput,
} from 'pages'

// serializers
import {
    ProjectChildFormState,
} from 'serializers/web'

// props
type AdditionalProjectChildPanelProps = {
    formState: ProjectChildFormState
    handleInputChange: any
}

// main
export const AdditionalProjectChildPanel: React.FC<AdditionalProjectChildPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdditionalProjectChildPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <ThemeProjectInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CityTempProjectInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <DateEndProjectInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <PortfolioDetailPageFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
                show={fieldsMother.medium_type?.id !== 9}
            />
            {fieldsMother.medium_type?.id && conditionAdditionalInformationWeb[fieldsMother.medium_type.id].includes('languages') && (
                <LanguageFieldInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
            )}
            {fieldsMother.medium_type?.id && conditionAdditionalInformationWeb[fieldsMother.medium_type.id].includes('lyrics') && (
                <LyricsProjectInput
                    errors={errorsMother}
                    fields={fieldsMother}
                    onChange={onHandleInputChange}
                />
            )}
            <ProjectTypeProjectInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
