// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Loader,
} from 'components'

// data
import {
    contentTypeData,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    BuilderService,
    handleMoveContent,
} from 'services'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// props
type MatchParams = {
    id: string
}

type MoveContentHelperProps = {
    allowDrop: boolean | undefined
    isNormal: boolean | undefined
    mixedMedia: boolean
    object: PortfolioPageContentListSiteSerializer
    setIsPopoverOpen: React.Dispatch<boolean>
}

// main
export const MoveContentHelper: React.FC<MoveContentHelperProps> = React.memo(({
    allowDrop,
    isNormal,
    mixedMedia,
    object,
    setIsPopoverOpen,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)

    function handleMoveDone(position: 'after' | 'before' | 'inside' | undefined) {
        try {
            handleMoveContent({
                component: 'MoveContentHelper',
                dispatch,
                mixedMedia,
                object,
                paramsId: params.id,
                position,
                reduxAuth,
                setIsLoading,
                setIsPopoverOpen,
            })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'MoveContentHelper',
                'handleMoveContent',
            ))
        }
    }

    const toMoveObject = BuilderService.moveGetContentObject()
    const isNotNormal = !isNormal && (toMoveObject?.parent_content !== object.id)

    return (
        <React.Fragment>
            <div className='shpcwp-item bt2'>
                <button
                    className='shpcwp-item-button'
                    onClick={() => {
                        BuilderService.moveSetContentObject(object, mixedMedia ? contentTypeData['mixedmediacontent'] : contentTypeData['pagecontent'])
                        setIsPopoverOpen(false)
                    }}
                >
                    <i className='mo-new-icon-arrows-alt-solid' />
                    <span className='shpcwp-item-button-text'>{reduxText[8314]}</span>
                </button>
            </div>
            {toMoveObject && allowDrop && (
                <div className={`shpcwp-item-layout-input${isNotNormal ? ' column' : ''}`}>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => {
                            setIsPopoverOpen(false)
                            handleMoveDone('after')
                        }}
                    >
                        <i className='mo-new-icon-arrows-alt-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[8498]}</span>
                    </button>
                    <div className='shpcwp-item-button-wrap'>
                        <div className='shpcwp-item-button-wrap2'>
                            <button
                                className='shpcwp-item-button1'
                                title={reduxText[8474]}
                                onClick={() => {
                                    setIsPopoverOpen(false)
                                    handleMoveDone('before')
                                }}
                            >
                                <i className='mo-new-icon-chevron-up-solid' />
                            </button>
                        </div>
                        {isNotNormal && (
                            <div className='shpcwp-item-button-wrap2'>
                                <button
                                    className='shpcwp-item-button2'
                                    title={reduxText[8473]}
                                    onClick={() => {
                                        setIsPopoverOpen(false)
                                        handleMoveDone('inside')
                                    }}
                                >
                                    <i className='mo-new-icon-expand-solid' />
                                </button>
                            </div>
                        )}
                        <div className='shpcwp-item-button-wrap2'>
                            <button
                                className='shpcwp-item-button2'
                                title={reduxText[8475]}
                                onClick={() => {
                                    setIsPopoverOpen(false)
                                    handleMoveDone('after')
                                }}
                            >
                                <i className='mo-new-icon-chevron-down-solid' />
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isLoading && (
                <Loader isOpen />
            )}
        </React.Fragment>
    )
})
