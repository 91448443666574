// react components
import React from 'react'

// components
import {
    ImageHelper,
} from 'components'

// data
import {
    media_url,
    media_version,
} from 'data'

// main
export const AGSLAdminHome: React.FC = () => {
    return (
        <div className='admin-home'>
            <ImageHelper
                alt=''
                className='ah-sphere'
                dominant_color={undefined}
                src={`${media_url}global/spheres/${Math.floor(Math.random() * 105)}.png${media_version}`}
            />
        </div>
    )
}
