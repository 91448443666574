// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ErrorHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FormLabel,
} from 'pages'

// serializer
import {
    TranslationHelper,
} from 'serializers/web'

// props
type TextInputV2Props = {
    adminOnly?: boolean
    directionRtl?: boolean
    disabled?: boolean
    error?: string
    helperText?: string
    label?: string
    max?: string
    min?: string
    name: string
    onChange: any
    onKeyPress?: any
    onRefresh?: () => void
    pattern?: string
    placeholder?: string
    staffOnly?: boolean
    step?: string
    tabIndex?: number
    translation?: TranslationHelper
    type?: 'number' | 'time' | 'text' | 'tel' | 'url' | 'email' | 'search' | 'date' | 'password'
    value: string | number
    zeroForEmpty?: boolean
}

// main
export const TextInputV2: React.FC<TextInputV2Props> = React.forwardRef((
    {
        adminOnly,
        directionRtl,
        disabled,
        error,
        helperText,
        label,
        max,
        min,
        name,
        onChange,
        onKeyPress,
        onRefresh,
        pattern,
        placeholder,
        staffOnly,
        step,
        tabIndex,
        translation,
        type,
        value,
        zeroForEmpty,
    },
    ref: React.Ref<HTMLInputElement>
) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    function onInputChange(targetValue: string | null | undefined) {
        try {
            if (zeroForEmpty && targetValue === '') {
                onChange({
                    name: name,
                    value: 0,
                })
            } else {
                onChange({
                    name: name,
                    value: targetValue,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TextInputV2',
                'onInputChange',
            ))
        }
    }

    if (adminOnly && !reduxAuth.settings?.user?.id) return null
    if (staffOnly && !reduxAuth.settings?.user?.is_staff) return null

    if (label || translation) {
        return (
            <div className={`input-wrap${disabled ? ' disabled' : ''}`}>
                <div className='input-label-wrap'>
                    <FormLabel
                        adminOnly={adminOnly}
                        formInput='text'
                        helperText={helperText}
                        label={label || ''}
                        name={name}
                        onRefresh={onRefresh}
                        staffOnly={staffOnly}
                        translation={translation}
                        translationDefaultValue={value}
                    />
                </div>
                <input
                    className={`text-input${directionRtl ? ' rtl' : ''}`}
                    disabled={disabled}
                    max={max}
                    min={min}
                    name={name}
                    onChange={(e) => onInputChange(e.target.value)}
                    onKeyPress={onKeyPress}
                    pattern={pattern}
                    placeholder={placeholder}
                    ref={ref}
                    step={step}
                    tabIndex={tabIndex}
                    type={type}
                    value={value}
                />
                <ErrorHelper error={error} />
            </div>
        )
    }

    return (
        <input
            className={`text-input${directionRtl ? ' rtl' : ''}`}
            disabled={disabled}
            max={max}
            min={min}
            name={name}
            onChange={(e) => onInputChange(e.target.value)}
            onKeyPress={onKeyPress}
            pattern={pattern}
            placeholder={placeholder}
            ref={ref}
            step={step}
            tabIndex={tabIndex}
            type={type}
            value={value}
        />
    )
})
