// react components
import React from 'react'
import {
    Dialog,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalSliderDetailShowHide,
} from 'data'

// pages
import {
    DetailModal,
} from 'pages'

// main
export const DetailModalWrapper2: React.FC = () => {

    const dispatch = useDispatch()
    const reduxModalmodalDetail2 = useSelector((state: defaultReduxState) => state.reduxModal.modalDetail2)

    return (
        <Dialog
            className='detail-modal-wrapper-web'
            classes={{ paper: 'mo-dialog-wrapper ctf-wrapper' }}
            disableEnforceFocus
            fullScreen
            onClose={() => dispatch(reduxModalSliderDetailShowHide(false))}
            open={Boolean(reduxModalmodalDetail2.isOpen && reduxModalmodalDetail2.contentType && reduxModalmodalDetail2.objectId)}
        >
            {reduxModalmodalDetail2.isOpen && reduxModalmodalDetail2.contentType && reduxModalmodalDetail2.objectId && (
                <DetailModal
                    contentType={reduxModalmodalDetail2.contentType}
                    detailId={String(reduxModalmodalDetail2.objectId)}
                    isDetailModal2
                />
            )}
        </Dialog>
    )
}
