// data
import {
    reduxTextState,
} from 'data'

// main
export function checkRequiredFields({
    formInfoObject,
    reduxText,
    fields,
}: {
    formInfoObject: any
    reduxText: reduxTextState['data']
    fields?: any
}) {
    const errorArray: any[] = []
    const errorObject: any = {}

    const newFields = fields || formInfoObject.fields

    if (['project', 'project_content'].includes(formInfoObject.model)) {
        const linkUrl = formInfoObject.fields.link_url
        let linkUrlError = ''
        if (linkUrl) {
            if (linkUrl.includes('spotify.com/album')) {
                linkUrlError = reduxText[5496]
            } else if (linkUrl.includes('spotify.com/intl-fr/album')) {
                linkUrlError = reduxText[5496]
            } else if (linkUrl.includes('vimeo.com/manage')) {
                linkUrlError = reduxText[5561]
            }
        }
        if (linkUrlError) {
            errorArray.push('link_url')
            errorObject.link_url = linkUrlError
        }
    }

    // ForeignKey
    if (formInfoObject.requiredForeignKey) { // Handle foreignKey field required
        formInfoObject.requiredForeignKey.map((field: string) => {
            if (!newFields[field]) {
                errorArray.push(field)
                errorObject[field] = reduxText[2856]
            }
            return false
        })
    }

    // ManyToMany
    if (formInfoObject.requiredManyToMany) {
        formInfoObject.requiredManyToMany.map((field: string) => {
            if (!newFields[field] || newFields[field].length === 0) {
                errorArray.push(field)
                errorObject[field] = reduxText[2856]
            }
            return false
        })
    }

    // Attributes
    if (formInfoObject.requiredAttribute) {
        formInfoObject.requiredAttribute.map((field: string) => {
            if (!newFields[field]) {
                errorArray.push(field)
                errorObject[field] = reduxText[2856]
            }
            return false
        })
    }

    if (process.env.NODE_ENV === 'development') console.log(errorArray)

    // Output
    if (errorArray.length === 0) {
        return 'ok'
    } else {
        return errorObject
    }
}
