// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios, {
    AxiosRequestConfig,
} from 'axios'
import {
    IonActionSheet,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    Alert,
    AupouCurrentLocationHelper,
    Button,
    DeleteAlert,
    EditAlert,
    IconBlock,
    Loader,
    PreviewBlockWeb,
    TranslationHelper,
} from 'components'

// data
import {
    api_url_copy_style_undo,
    api_url_move_content_undo,
    contentTypeFormUrl,
    defaultReduxState,
    mediumTypeCustomPageIds,
    reduxFormHelpersState,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetNavigationModeSite,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    reduxModalMainFormHide,
    reduxModalSeoData,
    reduxModalSiteSetSpecialSizeSite,
    view_url_admin,
    view_url_portfolio_form,
    view_url_profile_detail,
    view_url_profile_detail_old,
    view_url_profile_form,
} from 'data'

// pages
import {
    MixedContentPreview,
    NotFoundPage,
} from 'pages'

// serializers
import {
    FormMotherStatePropsNew,
} from 'serializers/web'

// services
import {
    BuilderService,
    autoSaveStyles,
    axiosErrorHandler,
    checkRequiredFields,
    getApiUrl,
    getAxiosHeaders,
    getFormData,
    getMainErrorMessage,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type MatchParams = {
    id: string
    albumId?: string
    formType: string
    panelValue: string
}

type LocationParams = {
    profileNotComplete?: boolean
    profileRefresh?: number
    profileSlug?: string
}

type MainFormContainerNewProps = {
    children: React.ReactNode
    formInfo: FormMotherStatePropsNew,
    formState: any
    headerTitle: string
    helpers: reduxFormHelpersState
    onRefresh?: () => void
    reduxAuthFetchSettings?: boolean
    setFormState: React.Dispatch<any>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const MainFormContainerNew: React.FC<MainFormContainerNewProps> = React.memo(({
    children,
    formInfo,
    formState,
    headerTitle,
    helpers,
    onRefresh,
    reduxAuthFetchSettings,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalplayerPosition = useSelector((state: defaultReduxState) => state.reduxModal.player.playerPosition)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (helpers.scrollToTop) {
            scrollToTop()
            setHelpers({ ...helpers, scrollToTop: false })
        }
    }, [helpers.scrollToTop])

    useEffect(() => {
        dispatch(reduxModalSeoData({
            title: formInfo.fields?.id ? `Form - ${headerTitle ? `${headerTitle} - ` : ''}${formInfo.fields?.name ? `${formInfo.fields.name} - ` : ''}${formInfo.fields?.profile?.name} - Just The Web` : `Form - Just The Web`,  // TO_LATER_TEXT
        }))
    }, [
        formInfo.fields?.id,
        formInfo.fields?.name,
        headerTitle,
    ])

    const contentRef = useRef<HTMLIonContentElement>(null)

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerNew',
                'scrollToTop',
            ))
        }
    }

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [languageId, setLanguageId] = useState<number>()
    const [tabValue, setTabValue] = useState<string | undefined>('formView')

    const actionSheetButtons = [
        {
            handler: () => setIsDeleteAlertOpen(true),
            role: 'destructive',
            text: reduxText[2983],
        },
        {
            handler: () => handlePublish(),
            text: reduxText[3517],
        },
        {
            role: 'cancel',
            text: reduxText[4519],
        }
    ]

    const actionSheetButtonsNotActive = [
        {
            handler: () => setIsDeleteAlertOpen(true),
            role: 'destructive',
            text: reduxText[2983],
        },
        {
            role: 'cancel',
            text: reduxText[4519],
        }
    ]

    async function handleDelete() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MainFormContainerNew handleDelete')
            setIsDeleting(true)
            const deleteUrl = `${formInfo.updateUrl}${formInfo.fields.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    if (reduxAuthFetchSettings) {
                        reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                        history.push('/')
                    } else {
                        if (!formInfo.fields?.profile?.slug) return
                        history.push(
                            `${view_url_profile_detail_old}${formInfo.fields.profile.slug}/`,
                            {
                                profileRefresh: Math.random(),
                                profileSlug: formInfo.fields.profile.slug,
                            },
                        )
                    }
                })
                .catch((error) => {
                    setIsDeleting(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'MainFormContainerNew',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerNew',
                'handleDelete',
            ))
        }
    }

    function handleFinish() {
        try {
            if (!formInfo.fields?.id) return
            dispatch(reduxFormSetIsSuccessToastOpen(false))
            autoSaveStyles({
                component: 'MainFormContainerNew',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
            if (onRefresh) {
                onRefresh()
                dispatch(reduxModalMainFormHide())
                return
            }
            if (params.albumId) {
                history.goBack()
            } else {
                if (formInfo.name === 'profile') {
                    history.push(formInfo.fields.is_new_profile_ux ? `${view_url_profile_detail}${formInfo.fields.id}/` : `${formInfo.viewUrl}${formInfo.fields.slug}/`)
                } else {
                    history.push(`${formInfo.viewUrl}${formInfo.fields.id}/`)
                }
                if (formInfo.name === 'profile') {
                    dispatch(reduxFormSetRefresh('refreshProfile', formInfo.fields.id))
                } else {
                    dispatch(reduxFormSetRefresh('refreshDetail'))
                    dispatch(reduxFormSetRefresh('refreshProfile', formInfo.fields.profile.id))
                }
                dispatch(reduxFormSetNavigationModeSite(undefined))
            }
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerNew',
                'handleFinish',
            ))
        }
    }

    async function handlePublish() {
        try {
            if (!formInfo.fields) return
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MainFormContainerNew handlePublish')
            const formData = new FormData()
            formData.append('active', (!formInfo.fields.active).toString())
            const activeUrl = `${formInfo.activeUrl}${formInfo.fields.id}/`
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    handleFinish()
                    setFormState({
                        ...formState,
                        [formInfo.name]: {
                            ...formInfo,
                            fields: {
                                ...formInfo.fields,
                                active: response.data.active,
                            }
                        }
                    })
                    if (reduxAuthFetchSettings) {
                        reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                    }
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'MainFormContainerNew',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePublish',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerNew',
                'handlePublish',
            ))
        }
    }

    function handleRequired(stay: boolean) {
        try {
            const result = checkRequiredFields({
                formInfoObject: formInfo,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit(stay)
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    [formInfo.name]: {
                        ...formInfo,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerNew',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit(stay: boolean) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MainFormContainerNew handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = formInfo.createUrl
            if (formInfo.fields.id) {
                axiosMethod = 'put'
                axiosUrl = `${formInfo.updateUrl}${formInfo.fields.id}/`
            }
            const formData: any = getFormData(formInfo)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    dispatch(reduxFormSetEditingSite(false))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: false,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    if (formInfo.fields.meta_image_url) {
                        setFormState({
                            ...formState,
                            [formInfo.name]: {
                                ...formInfo,
                                errors: {},
                                fields: {
                                    ...formInfo.fields,
                                    id: response.data.id,
                                    slug: response.data.slug,
                                    cover_image_original: response.data.cover_image,
                                }
                            }
                        })
                    } else {
                        setFormState({
                            ...formState,
                            [formInfo.name]: {
                                ...formInfo,
                                errors: {},
                                fields: {
                                    ...formInfo.fields,
                                    id: response.data.id,
                                    slug: response.data.slug,
                                }
                            }
                        })
                    }
                    if (formInfo.name === 'profileteam') {
                        history.replace(`${view_url_profile_form}${response.data.member}/main/main-panel/`)
                    } else if (onRefresh) {
                        onRefresh()
                        dispatch(reduxModalMainFormHide())
                    } else {
                        if (!stay) {
                            if (params.albumId) {
                                history.goBack()
                            } else if (formInfo.fields.id) {
                                if (location.state?.profileNotComplete) {
                                    history.push(`${formInfo.formUrl}${formInfo.fields.id}/`)
                                } else {
                                    history.push(`${formInfo.formUrl}${response.data.id}/`)
                                }
                            } else {
                                history.replace(`${formInfo.formUrl}${response.data.id}/`)
                            }
                        } else if (!formInfo.fields.id) {
                            history.replace(`${formInfo.formUrl}${response.data.id}/main/main-panel/`)
                        }
                    }
                    if (reduxAuthFetchSettings) {
                        reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                    }
                    dispatch(reduxFormSetRefresh('refreshForm', response.data.id))
                    if (response.data.medium_type?.id === 9) {
                        dispatch(reduxFormSetRefresh('refreshPreview', response.data.id))
                    }
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        [formInfo.name]: {
                            ...formInfo,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'MainFormContainerNew',
                        dispatch,
                        error,
                        formFields: JSON.stringify(formInfo.fields),
                        formFieldsToIgnore: ['email'],
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerNew',
                'handleSubmit',
            ))
        }
    }

    async function handleUndoCopyStyle() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MainFormContainerNew handleUndoCopyStyle')
            const previousStyleObject = BuilderService.copyGetPreviousStyleObject()
            const formData = new FormData()
            formData.append('content_type_id', previousStyleObject!.content_type!.toString())
            formData.append('object_id', previousStyleObject!.id!.toString())
            formData.append('styles', previousStyleObject!.layout_edit!.styles!.toString())
            formData.append('styles_original', previousStyleObject!.layout_edit!.styles_original!.toString())
            const postUrl = getApiUrl(api_url_copy_style_undo, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                    BuilderService.copyDeletePreviousStyleObject()

                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'MainFormContainerNew',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleUndoCopyStyle',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerNew',
                'handleUndoCopyStyle',
            ))
        }
    }

    async function handleUndoMove() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MainFormContainerNew handleUndoMove')
            const previousMoveObject = BuilderService.moveGetPreviousContentObject()
            const formData = new FormData()
            formData.append('content_type_id', previousMoveObject!.content_type!.toString())
            formData.append('object_id', previousMoveObject!.id!.toString())
            if (previousMoveObject!.parent_content) {
                formData.append('parent_content_id', previousMoveObject!.parent_content!.toString())
            }
            formData.append('position', previousMoveObject!.position!.toString())
            const postUrl = getApiUrl(api_url_move_content_undo, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                    BuilderService.moveDeletePreviousContentObject()

                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'MainFormContainerNew',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleUndoMove',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerNew',
                'handleUndoMove',
            ))
        }
    }

    if (onRefresh) {
        return (
            <React.Fragment>
                <DialogTitle className='mo-dialog-header'>
                    {reduxText[8023]}
                    <div className='mo-dialog-header-close-wrap'>
                        <IconBlock
                            className='mo-dialog-header-close'
                            edit={false}
                            iconClass='mo-new-icon-times-solid'
                            onClick={() => dispatch(reduxModalMainFormHide())}
                        />
                    </div>
                </DialogTitle>
                <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {helpers.isLoading
                        ? (
                            <Loader isOpen />
                        ) : (
                            children
                        )}
                </DialogContent>
                <DialogActions className='mo-dialog-footer'>
                    <Button
                        edit={false}
                        expand='full'
                        fill='outline'
                        onClick={() => dispatch(reduxModalMainFormHide())}
                        text={reduxText[4519]}
                    />
                    <Button
                        disabled={helpers.buttonDisabled}
                        edit={false}
                        expand='full'
                        fill='outline'
                        onClick={() => handleRequired(false)}
                        text={reduxText[4520]}
                    />
                </DialogActions>
                {(helpers.isSaving || isDeleting) && (
                    <Loader
                        isOpen
                        message={helpers.isSaving ? reduxText[4525] : (isDeleting ? reduxText[1242] : '')}
                    />
                )}
            </React.Fragment>
        )
    }

    if (helpers.errorStatus) {
        return <NotFoundPage
            header
            // @ts-ignore
            model={formInfo.model}
            objectId={Number(params.id)}
            statusCode={helpers.errorStatus}
        />
    }

    let headerText = ''
    if (params.panelValue) {
        headerText = headerTitle
    } else {
        headerText = formInfo.fields?.name
    }

    const formActive = formInfo.fields?.active

    return (
        <IonPage className='main-form-container-web'>
            <IonContent
                className={`mfcw-form-content mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}
                ref={contentRef}
            >
                <IonHeader className={`mo-header-web${process.env.NODE_ENV === 'development' ? ' dev-color' : ''}`}>
                    <IonToolbar>
                        {formInfo.fields?.id && (
                            <IonButtons slot='start'>
                                <Button
                                    disabled={!params.formType}
                                    edit={false}
                                    fill='clear'
                                    onClick={() => {
                                        if (params.albumId) history.goBack()
                                        if (params.formType === 'inline') {
                                            dispatch(reduxFormSetRefresh('refreshForm', formInfo.fields.id))
                                            if (mediumTypeCustomPageIds.includes(formInfo.fields.medium_type?.id)) {
                                                dispatch(reduxFormSetRefresh('refreshPreview', formInfo.fields.id))
                                            }
                                        }
                                    }}
                                    text={reduxText[5582]}
                                    to={params.albumId ? undefined : `${formInfo.formUrl}${formInfo.fields.id}/`}
                                />
                                {params.formType === 'mixed-media' && (
                                    <React.Fragment>
                                        <span>{'/'}</span>
                                        <Button
                                            disabled
                                            edit={false}
                                            fill='clear'
                                            text={reduxText[6591]}
                                        />
                                    </React.Fragment>
                                )}
                                {params.formType === 'mixed-media-content' && (
                                    <React.Fragment>
                                        <span>{'/'}</span>
                                        <Button
                                            edit={false}
                                            fill='clear'
                                            text={reduxText[6591]}
                                            to={`${formInfo.formUrl}${formInfo.fields.id}/mixed-media/-/`}
                                        />
                                        <span>{'/'}</span>
                                        <Button
                                            disabled
                                            edit={false}
                                            fill='clear'
                                            text={`${reduxText[6379]} ${params.panelValue}`}
                                        />
                                    </React.Fragment>
                                )}
                                {params.formType && !['mixed-media', 'mixed-media-content'].includes(params.formType) && (
                                    <React.Fragment>
                                        <span>{'/'}</span>
                                        <Button
                                            disabled
                                            edit={false}
                                            fill='clear'
                                            text={headerText}
                                        />
                                    </React.Fragment>
                                )}
                                {params.formType && ['mixed-media', 'mixed-media-content'].includes(params.formType) && (
                                    <React.Fragment>
                                        <span>{'/'}</span>
                                        <Button
                                            className='mfcw-name-button mo-clamp-web1'
                                            disabled
                                            edit={false}
                                            fill='clear'
                                            text={formInfo.fields?.name}
                                        />
                                    </React.Fragment>
                                )}
                                {formInfo.fields.translation_languages && (
                                    <TranslationHelper
                                        edit={false}
                                        inNav
                                        languageId={languageId}
                                        languages={formInfo.fields.translation_languages}
                                        setLanguageId={setLanguageId}
                                    />
                                )}
                            </IonButtons>
                        )}
                        <IonTitle color='primary'>
                            <div className='mfcw-nav-mode-wrap'>
                                {BuilderService.copyGetPreviousStyleObject() && (
                                    <Button
                                        edit={false}
                                        onClick={handleUndoCopyStyle}
                                        size='small'
                                        fill='clear'
                                        text={reduxText[8505]}
                                    />
                                )}
                                {BuilderService.moveGetPreviousContentObject() && (
                                    <Button
                                        edit={false}
                                        onClick={handleUndoMove}
                                        size='small'
                                        fill='clear'
                                        text={reduxText[8506]}
                                    />
                                )}
                                {params.formType !== 'inline' && (
                                    <IconBlock
                                        edit={false}
                                        iconClass={tabValue === 'fullscreen' ? 'mo-new-icon-compress-solid' : 'mo-new-icon-expand-solid'}
                                        onClick={() => {
                                            if (tabValue === 'fullscreen') {
                                                setTabValue('')
                                                dispatch(reduxModalSiteSetSpecialSizeSite(undefined))
                                            } else {
                                                setTabValue('fullscreen')
                                                dispatch(reduxModalSiteSetSpecialSizeSite('form-fullscreen'))
                                            }
                                        }}
                                        tooltipText={tabValue === 'fullscreen' ? 'Exit fullscreen' : 'Fullscreen'}
                                    />
                                )}
                                {['mixed-media', 'mixed-media-content'].includes(params.formType) && (
                                    <React.Fragment>
                                        <Button
                                            edit={false}
                                            fill={reduxFormSite.navigationMode === 'navMixed' ? undefined : 'clear'}
                                            onClick={() => dispatch(reduxFormSetNavigationModeSite('navMixed'))}
                                            size='small'
                                            text={reduxText[8191]}
                                        />
                                        <Button
                                            edit={false}
                                            fill={reduxFormSite.navigationMode === 'edit' ? undefined : 'clear'}
                                            onClick={() => dispatch(reduxFormSetNavigationModeSite('edit'))}
                                            size='small'
                                            text={reduxText[8192]}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </IonTitle>
                        {!['inline'].includes(params.formType) && (
                            <IonButtons slot='end'>
                                <AupouCurrentLocationHelper />
                                <div className='mfcw-nav-preview-link-wrap'>
                                    {formInfo.fields?.portfolio_main && (
                                        <a
                                            className='mfcw-nav-preview-link'
                                            href={`https://${formInfo.fields.portfolio_main.hostname}/${formInfo.fields.portfolio_main.detail_page}/${(formInfo.model === 'profileteam') ? formInfo.fields.member?.id : formInfo.fields.id}-${(formInfo.model === 'profileteam') ? formInfo.fields.member?.slug : formInfo.fields.slug}${formInfo.fields.active ? '' : `?force_active=${formInfo.fields.owner_info?.content_uuid}`}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            View on {formInfo.fields.portfolio_main.hostname}
                                        </a>
                                    )}
                                </div>
                                {(formInfo.fields?.portfolio_main && reduxAuth.settings?.user?.is_staff) && (
                                    <IconBlock
                                        className='mfcw-nav-go-to-builder'
                                        edit={false}
                                        iconClass='mo-new-icon-content'
                                        to={`${view_url_portfolio_form}${formInfo.fields.portfolio_main.id}/page-list/`}
                                        tooltipText='Go to builder'
                                    />
                                )}
                                {formInfo.fields?.id
                                    ? (
                                        <Button
                                            edit={false}
                                            onClick={() => handleFinish()}
                                            size='small'
                                            text={reduxText[6377]}
                                        />
                                    ) : (
                                        <Button
                                            disabled={!Boolean(reduxAuth.settings?.active_profile?.slug)}
                                            edit={false}
                                            size='small'
                                            text={reduxText[6377]}
                                            to={reduxAuth.settings?.active_profile?.is_new_profile_ux ? `${view_url_admin}${reduxAuth.settings?.active_profile?.id}/` : `${view_url_profile_detail_old}${reduxAuth.settings?.active_profile?.slug}/`}
                                        />
                                    )}
                            </IonButtons>
                        )}
                    </IonToolbar>
                </IonHeader>
                {helpers.isLoading
                    ? (
                        <Loader isOpen />
                    ) : (
                        <div className='mfcw-form-panels-wrap'>
                            <div className={`mfcw-form-panels${params.panelValue ? '' : ' menu'}${params.formType ? ` ${params.formType}` : ''} ${tabValue}`}>
                                <div className={`mfcw-form-panels-children mo-hidden-horizontal-scrollbar${params.panelValue ? '' : ' menu'}`}>
                                    {params.formType && !['inline', 'mixed-media', 'mixed-media-content', 'special'].includes(params.formType) && (
                                        <p className='mfcw-form-panels-header'>{headerText}</p>
                                    )}
                                    {children}
                                </div>
                                {!['inline', 'mixed-media', 'mixed-media-content', 'special'].includes(params.formType) && (
                                    <div
                                        className={`mfcw-footer-wrap${(reduxForm.isSuccessToastOpen || reduxModalplayerPosition === 'detail') ? ' form-is-success' : ''}`}
                                    >
                                        <div className='mfcw-footer'>
                                            {['shop-panel'].includes(params.panelValue)
                                                ? (
                                                    <Button
                                                        classNameWrap='mfcw-footer-button mfcw-finish'
                                                        edit={false}
                                                        expand='full'
                                                        fill='outline'
                                                        text={reduxText[7676]}
                                                        to={`${formInfo.formUrl}${formInfo.fields?.id}`}
                                                    />
                                                ) : (
                                                    <React.Fragment>
                                                        {params.panelValue && (
                                                            <React.Fragment>
                                                                {(helpers.buttonDisabled && !helpers.isSaving && formInfo.fields?.id)
                                                                    ? (
                                                                        <React.Fragment>
                                                                            {/* Start continue button */}
                                                                            <Button
                                                                                classNameWrap='mfcw-footer-button mfcw-finish'
                                                                                edit={false}
                                                                                expand='full'
                                                                                fill='outline'
                                                                                onClick={() => params.albumId ? history.goBack() : undefined}
                                                                                padding='0 5px'
                                                                                text={reduxText[7676]}
                                                                                to={params.albumId ? undefined : `${formInfo.formUrl}${formInfo.fields.id}/`}
                                                                            />
                                                                            {/* End continue button */}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment>
                                                                            {/* Start save & continue button */}
                                                                            {formInfo.name !== 'profileteam' && (
                                                                                <Button
                                                                                    classNameWrap='mfcw-footer-button'
                                                                                    disabled={helpers.buttonDisabled}
                                                                                    edit={false}
                                                                                    expand='full'
                                                                                    fill='outline'
                                                                                    onClick={() => handleRequired(true)}
                                                                                    padding='0 5px'
                                                                                    size='x-small'
                                                                                    text={reduxText[6378]}
                                                                                />
                                                                            )}
                                                                            {/* End save & continue button */}
                                                                            {/* Start save button */}
                                                                            <Button
                                                                                classNameWrap='mfcw-footer-button mfcw-finish'
                                                                                disabled={helpers.buttonDisabled}
                                                                                edit={false}
                                                                                expand='full'
                                                                                fill='outline'
                                                                                onClick={() => handleRequired(false)}
                                                                                padding='0 5px'
                                                                                text={reduxText[4520]}
                                                                            />
                                                                            {/* End save button */}
                                                                        </React.Fragment>
                                                                    )}
                                                            </React.Fragment>
                                                        )}
                                                        {/* Start go to detail button */}
                                                        {!params.panelValue && (
                                                            <Button
                                                                classNameWrap={`mfcw-footer-button ${(params.albumId || formActive) ? 'mfcw-finish' : ''}`}
                                                                disabled={!formInfo.fields?.id}
                                                                edit={false}
                                                                expand='full'
                                                                fill='outline'
                                                                onClick={handleFinish}
                                                                padding='0 5px'
                                                                text={(params.albumId || formActive) ? reduxText[4690] : reduxText[5895]}
                                                            />
                                                        )}
                                                        {/* End go to detail button */}
                                                        {/* Start publish button */}
                                                        {!params.albumId && !params.panelValue && !formActive && (
                                                            <Button
                                                                classNameWrap='mfcw-footer-button'
                                                                disabled={!formInfo.fields?.id}
                                                                edit={false}
                                                                expand='full'
                                                                fill='outline'
                                                                onClick={handlePublish}
                                                                padding='0 5px'
                                                                text={formActive ? reduxText[3517] : reduxText[3518]}
                                                            />
                                                        )}
                                                        {/* End publish button */}
                                                    </React.Fragment>
                                                )
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                            {!['inline', 'mixed-media', 'mixed-media-content'].includes(params.formType) && (
                                <div className='mfcw-form-preview'>
                                    {formInfo.detailComponent && (
                                        <PreviewBlockWeb
                                            detailComponent={formInfo.detailComponent}
                                            object={formInfo.fields}
                                        />
                                    )}
                                </div>
                            )}
                            {['mixed-media', 'mixed-media-content'].includes(params.formType) && formInfo.fields && (
                                <div className={`mfcw-form-preview-mixed-media-content ${tabValue}`}>
                                    <div className={`mixed-template-preview-wrap ${reduxModalSite.deviceType}`}>
                                        <div className='mixed-template-preview'>
                                            {formInfo.fields && location.pathname.split('/')[1] === 'form' && contentTypeFormUrl[location.pathname.split('/')[2]] && (
                                                <MixedContentPreview
                                                    contentType={contentTypeFormUrl[location.pathname.split('/')[2]]}
                                                    edit={'preview'}
                                                    languageId={languageId}
                                                    // @ts-ignore
                                                    mother={formInfo}
                                                    object={formInfo.fields}
                                                />
                                            )}
                                        </div>
                                    </div>

                                </div>
                            )}
                            <Alert
                                buttons={[{ text: reduxText[5614] }]}
                                header={reduxText[5585]}
                                isOpen={Boolean(helpers.mainError)}
                                message={`${helpers.mainError}${helpers.mainError === 'Network Error' ? reduxText[4577] : ''}`}
                                onDidDismiss={() => setHelpers({
                                    ...helpers,
                                    mainError: '',
                                })}
                            />
                            {isDeleteAlertOpen && (
                                <DeleteAlert
                                    handleDelete={handleDelete}
                                    objectName={formInfo.fields?.name}
                                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                                />
                            )}
                        </div>
                    )
                }
                {(helpers.isSaving || isDeleting) && (
                    <Loader
                        isOpen
                        message={helpers.isSaving ? reduxText[4525] : (isDeleting ? reduxText[1242] : '')}
                    />
                )}
            </IonContent>
            <IonActionSheet
                buttons={formInfo.fields?.active ? actionSheetButtons : actionSheetButtonsNotActive}
                isOpen={helpers.isActionSheetOpen || false}
                onDidDismiss={() => setHelpers({ ...helpers, isActionSheetOpen: false })}
            />
            <EditAlert />
        </IonPage>
    )
})
