// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    SelectListInputV2,
    TextInputV2,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type AgendaDuQuartierQuartierFormStep7Props = {
    inline?: boolean
    setStep: (step: number) => void
    step: number
    submitOnly?: boolean
}

// main
export const AgendaDuQuartierQuartierFormStep7: React.FC<AgendaDuQuartierQuartierFormStep7Props> = React.memo(({
    inline,
    setStep,
    step,
    submitOnly,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [errors, setErrors] = useState<any>({})
    const [fields, setFields] = useState<any>({})
    const [isClosed, setIsClosed] = useState<boolean>(inline || false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (reduxAuth.settings?.quartier?.id) {
            if (inline && isClosed) return
            onGetDetailData()
        }
    }, [
        isClosed,
        reduxAuth.settings?.quartier?.id,
    ])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/detail/quartier/${reduxAuth.settings?.quartier?.id}/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep7',
                dispatch,
                reduxAuth,
                setDetailObject: setFields,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep7',
                'onGetDetailData',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep7',
                'handleInputChange',
            ))
        }
    }

    async function handleSubmit() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuartierFormStep7 handleSubmit')
            const patchUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/update/quartier/${reduxAuth.settings?.quartier?.id}/`, reduxAuth)
            const formData = new FormData()
            formData.append('address_custom_additional_address_info_1', fields.address_custom_additional_address_info_1 || '')
            formData.append('address_custom_building_1', fields.address_custom_building_1 || '')
            formData.append('address_custom_city_1', fields.address_custom_city_1 || '')
            formData.append('address_custom_office_1', fields.address_custom_office_1 || '')
            formData.append('address_custom_postal_code_1', fields.address_custom_postal_code_1 || '')
            formData.append('address_custom_recipient_name_1', fields.address_custom_recipient_name_1 || '')
            formData.append('address_custom_street_address_1', fields.address_custom_street_address_1 || '')
            formData.append('custom_foreign_key_7', fields.custom_foreign_key_7?.id || '')
            formData.append('custom_text_field_6', fields.custom_text_field_6 || '')
            const isStepDone = Boolean(fields.custom_foreign_key_7?.id)
            formData.append('extra_data_json_2', JSON.stringify({
                ...fields.extra_data_json_2,
                quartier_from_steps: {
                    ...fields.extra_data_json_2?.quartier_from_steps,
                    step7: isStepDone,
                }
            }))
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'patch',
                url: patchUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setStep(step + 1)
                    reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: patchUrl,
                        component: 'AgendaDuQuartierQuartierFormStep7',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep7',
                'handleInputChange',
            ))
        }
    }

    return (
        <div className={`sct90f-input-block${inline ? (!isClosed ? ' inline open' : ' inline') : ''}`}>
            <div
                className={`sct90f-title-block${inline && isClosed ? ' closed' : ''}`}
                onClick={inline ? () => setIsClosed(!isClosed) : undefined}
            >
                <p className='sct90f-title'>Renseigner l’adresse de retour des courriers</p>
                {inline && (
                    <div className='sct90f-title-icon'>
                        <i className={`mo-new-icon-check-circle-solid sct90f-status${reduxAuth.settings?.quartier?.extra_data_json_2?.quartier_from_steps?.step7 === true ? '' : ' not-completed'}`} />
                        <div className='sct90f-arrow-wrap'>
                            <i className='mo-new-icon-chevron-down-solid sct90f-arrow' />
                        </div>
                    </div>
                )}
            </div>
            {!isClosed && (
                <div className='sct90f-content'>
                    <div
                        className='sct90f-helper'
                        dangerouslySetInnerHTML={{ __html: reduxText[10011] }}
                    />
                    <div
                        className='sct90f-helper'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <input
                            checked={fields.custom_foreign_key_7?.id === 650}
                            name='custom_foreign_key_7'
                            onChange={() => handleInputChange({ name: 'custom_foreign_key_7', value: { id: 650 } })}
                            style={{ marginRight: '10px' }}
                            type='radio'
                            value={650}
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: reduxText[10012] }}
                        />
                    </div>
                    {(reduxAuth.settings?.federateur?.address_custom_building_2 || reduxAuth.settings?.federateur?.address_custom_building_1) && (
                        <TextInputV2
                            disabled
                            label='Bâtiment ou résidence'
                            name='address_custom_building_1'
                            onChange={() => undefined}
                            type='text'
                            value={reduxAuth.settings?.federateur?.address_custom_building_2 || reduxAuth.settings?.federateur?.address_custom_building_1 || ''}
                        />
                    )}
                    {(reduxAuth.settings?.federateur?.address_custom_office_2 || reduxAuth.settings?.federateur?.address_custom_office_1) && (
                        <TextInputV2
                            disabled
                            label='Service ou direction'
                            name='address_custom_office_1'
                            onChange={() => undefined}
                            type='text'
                            value={reduxAuth.settings?.federateur?.address_custom_office_2 || reduxAuth.settings?.federateur?.address_custom_office_1 || ''}
                        />
                    )}
                    {(reduxAuth.settings?.federateur?.address_custom_recipient_name_2) && (
                        <TextInputV2
                            disabled
                            label='Nom de la personne destinataire'
                            name='address_custom_recipient_name_1'
                            onChange={() => undefined}
                            type='text'
                            value={reduxAuth.settings?.federateur?.address_custom_recipient_name_2 || ''}
                        />
                    )}
                    {(reduxAuth.settings?.federateur?.address_custom_street_address_2 || reduxAuth.settings?.federateur?.address_custom_street_address_1) && (
                        <TextInputV2
                            disabled
                            label='Numéro et voie (rue, boulevard, place…)'
                            name='address_custom_street_address_1'
                            onChange={() => undefined}
                            type='text'
                            value={reduxAuth.settings?.federateur?.address_custom_street_address_2 || reduxAuth.settings?.federateur?.address_custom_street_address_1 || ''}
                        />
                    )}
                    {(reduxAuth.settings?.federateur?.address_custom_additional_address_info_2 || reduxAuth.settings?.federateur?.address_custom_additional_address_info_1) && (
                        <TextInputV2
                            disabled
                            label='BP, CS, TSA, lieu-dit…'
                            name='address_custom_additional_address_info_1'
                            onChange={() => undefined}
                            type='text'
                            value={reduxAuth.settings?.federateur?.address_custom_additional_address_info_2 || reduxAuth.settings?.federateur?.address_custom_additional_address_info_1 || ''}
                        />
                    )}
                    <TextInputV2
                        disabled
                        label='Code postal'
                        name='address_custom_postal_code_1'
                        onChange={() => undefined}
                        type='text'
                        value={reduxAuth.settings?.federateur?.address_custom_postal_code_2 || reduxAuth.settings?.federateur?.address_custom_postal_code_1 || ''}
                    />
                    <TextInputV2
                        disabled
                        label='Ville'
                        name='address_custom_city_1'
                        onChange={() => undefined}
                        type='text'
                        value={reduxAuth.settings?.federateur?.address_custom_city_2 || reduxAuth.settings?.federateur?.address_custom_city_1 || ''}
                    />
                    <SelectListInputV2
                        clearable
                        disabled
                        label='Pays'
                        name='address_custom_country_1'
                        onChange={() => undefined}
                        profileObject={undefined}
                        value={reduxAuth.settings?.federateur?.address_custom_country_1 || ''}
                    />
                    <div
                        className='sct90f-helper'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <input
                            checked={fields.custom_foreign_key_7?.id === 649}
                            name='custom_foreign_key_7'
                            onChange={() => handleInputChange({ name: 'custom_foreign_key_7', value: { id: 649 } })}
                            style={{ marginRight: '10px' }}
                            type='radio'
                            value={649}
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: reduxText[10013] }}
                        />
                    </div>
                    {fields.custom_foreign_key_7?.id === 649 && (
                        <>
                            <TextInputV2
                                error={errors?.address_custom_building_1}
                                label='Bâtiment ou résidence de livraison'
                                name='address_custom_building_1'
                                onChange={handleInputChange}
                                type='text'
                                value={fields.address_custom_building_1 || ''}
                            />
                            <TextInputV2
                                error={errors?.address_custom_office_1}
                                label='Service ou direction'
                                name='address_custom_office_1'
                                onChange={handleInputChange}
                                type='text'
                                value={fields.address_custom_office_1 || ''}
                            />
                            <TextInputV2
                                error={errors?.address_custom_recipient_name_1}
                                label='Nom de la personne destinataire'
                                name='address_custom_recipient_name_1'
                                onChange={handleInputChange}
                                type='text'
                                value={fields.address_custom_recipient_name_1 || ''}
                            />
                            <TextInputV2
                                error={errors?.custom_text_field_6}
                                label='Fonction'
                                name='custom_text_field_6'
                                onChange={handleInputChange}
                                type='text'
                                value={fields.custom_text_field_6 || ''}
                            />
                            <TextInputV2
                                error={errors?.address_custom_street_address_1}
                                label='Numéro et voie (rue, boulevard, place…)'
                                name='address_custom_street_address_1'
                                onChange={handleInputChange}
                                type='text'
                                value={fields.address_custom_street_address_1 || ''}
                            />
                            <TextInputV2
                                error={errors?.address_custom_additional_address_info_1}
                                label="Complément d'adresse"
                                name='address_custom_additional_address_info_1'
                                onChange={handleInputChange}
                                type='text'
                                value={fields.address_custom_additional_address_info_1 || ''}
                            />
                            <TextInputV2
                                error={errors?.address_custom_postal_code_1}
                                label='Code postal'
                                name='address_custom_postal_code_1'
                                onChange={handleInputChange}
                                type='text'
                                value={fields.address_custom_postal_code_1 || ''}
                            />
                            <TextInputV2
                                error={errors?.address_custom_city_1}
                                label='Ville'
                                name='address_custom_city_1'
                                onChange={handleInputChange}
                                type='text'
                                value={fields.address_custom_city_1 || ''}
                            />
                        </>
                    )}
                    <div className='sct90f-button-block'>
                        <div>
                            {!submitOnly && step > 1 && (
                                <Button
                                    edit={false}
                                    onClick={() => setStep(step - 1)}
                                    text='Précédent'
                                />
                            )}
                        </div>
                        <div className='sct90f-button-block-end'>
                            {!submitOnly && (
                                <>
                                    <Button
                                        edit={false}
                                        fill='clear'
                                        onClick={() => setStep(step + 1)}
                                        text="Répondre plus tard"
                                    />
                                    <div className='sct90f-button-spacing' />
                                </>
                            )}
                            <Button
                                edit={false}
                                onClick={handleSubmit}
                                text={submitOnly ? 'Sauvegarder' : 'Suivant'}
                            />
                        </div>
                    </div>
                    {isLoading && <Loader isOpen />}
                </div>
            )}
        </div>
    )
})
