// react components
import React from 'react'
import {
    IonHeader,
    IonToolbar,
} from '@ionic/react'

// props
type HeaderChildrenProps = {
    children: React.ReactNode
}

// main
export const HeaderChildren: React.FC<HeaderChildrenProps> = React.memo(({
    children,
}) => {

    return (
        <IonHeader className='header-children-web'>
            <IonToolbar className='hcw-toolbar'>
                {children}
            </IonToolbar>
        </IonHeader>
    )
})
