// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ActionButton,
    IconBlock,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    DetailBrevo,
    DetailField,
    FieldSettingsModal,
} from 'pages'

// serializers
import {
    ContentTypeDetailBlockFieldSetTypes,
    ContentTypeSettingsTypes,
    ContentTypeSettingsCustomTypes,
} from 'serializers/web'

// services
import {
    getModalAbsoluteUrlV2,
    getReduxTextId,
    onClickIsModal,
} from 'services'

// props
type DetailFieldSetProps = {
    blockId: number
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    fieldSetObject: ContentTypeDetailBlockFieldSetTypes
    object: any
    refreshData: () => void
    tab: number
}

// main
export const DetailFieldSet: React.FC<DetailFieldSetProps> = React.memo(({
    blockId,
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    fieldSetObject,
    object,
    refreshData,
    tab,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isFieldSettingsModal, setIsFieldSettingsModal] = useState(false)

    if (fieldSetObject.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) return null

    let fieldSetObjectFields = fieldSetObject.fields || []
    if (contentType === 'staff_staffmessage' && reduxAuth.settings?.user?.is_staff && tab === 240) {
        fieldSetObjectFields = contentTypeSettings.detail?.panels?.find(obj => obj.id === 240)?.blockFieldSet?.[231]?.fields || []
    }

    if ([
        'extra_custom',
        'extra_main',
        'meta_helper',
        // 'meta_image',
        'meta_json',
        'meta_privacy',
        'meta_rel',
    ].includes(fieldSetObject.blockTypeOther)) {
        fieldSetObjectFields = fieldSetObjectFields.sort((a, b) => {
            const order = [
                'sub_content_type',
                'module_views',
                'module_view',
                'has_existing_content_detail',
            ]

            // Check if either value is in the predefined order
            const indexA = order.indexOf(a)
            const indexB = order.indexOf(b)

            // If both are in the order, sort based on their position in the order array
            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB
            }

            // If only one is in the order, prioritize that one
            if (indexA !== -1) return -1
            if (indexB !== -1) return 1

            // Otherwise, sort alphabetically
            return a.localeCompare(b)
        })
    }

    return (
        <div className={`detail-component-field-set ${blockId}`}>
            <div className='dcfs-fieldsets'>
                <div className='dcfs-fieldsets-header'>
                    <div className='dcfs-wrap-title'>
                        <p className='dcfs-title'>{getReduxTextId(fieldSetObject.title!, reduxText)}</p>
                        {fieldSetObject.hideIfNotSuperuser && reduxAuth.settings?.user?.id === 1 && (
                            <p className='dcfs-title-superuser'>{reduxText[9447]}</p>
                        )}
                    </div>
                    <div className='dcfs-actions'>
                        {reduxAuth.settings?.user?.id === 1 && (
                            <>
                                {(!contentTypeSettingsCustom?.detail?.panelAdaptation?.[tab]?.blockList?.includes(blockId) && contentTypeSettingsCustom?.meta?.id && !contentTypeSettingsCustom?.detail?.panelsSubContentType?.[object.sub_content_type?.id]) && (
                                    <ActionButton
                                        actionObject={{
                                            extraParams: {
                                                content_type_settings_profile_id: contentTypeSettingsCustom.meta.id,
                                            },
                                            function_name: 'duplicate_to_content_type_settings_profile',
                                        }}
                                        contentTypeId={contentTypeData['setup_contenttypepanelblock']}
                                        iconClass='mo-new-icon-cog-solid'
                                        objectId={blockId}
                                        refreshData={refreshData}
                                    />
                                )}
                                <IconBlock
                                    edit={false}
                                    iconClass='mo-new-icon-list-ul-solid'
                                    onClick={() => setIsFieldSettingsModal(true)}
                                    tooltipText='Fields list'
                                />
                                <IconBlock
                                    edit={false}
                                    iconClass={(contentTypeSettingsCustom?.detail?.panelAdaptation?.[tab]?.blockList?.includes(blockId) || contentTypeSettingsCustom?.detail?.panelsSubContentType?.[object.sub_content_type?.id]) ? 'mo-new-icon-cog-solid' : 'mo-new-icon-globe-solid'}
                                    onClick={() => onClickIsModal(Number(blockId), Math.random(), dispatch)}
                                    to={getModalAbsoluteUrlV2({
                                        contentType: 'setup_contenttypepanelblock',
                                        objectId: Number(blockId),
                                        pageType: 'detail',
                                    })}
                                    tooltipText={reduxText[9372]}
                                />
                            </>
                        )}
                    </div>
                </div>
                {blockId === 3628 && (
                    <DetailBrevo object={object} />
                )}
                {fieldSetObjectFields.map((val) => (
                    <DetailField
                        key={val}
                        contentType={contentType}
                        contentTypeSettings={contentTypeSettings}
                        contentTypeSettingsCustom={contentTypeSettingsCustom}
                        field={val}
                        fieldSetObject={fieldSetObject}
                        object={object}
                        refreshData={refreshData}
                    />
                ))}
            </div>
            {isFieldSettingsModal && (
                <FieldSettingsModal
                    blockId={blockId}
                    contentType={contentType}
                    contentTypeSettings={contentTypeSettings}
                    profile={object.profile}
                    refreshData={refreshData}
                    setIsOpen={setIsFieldSettingsModal}
                    subContentTypeId={object.sub_content_type?.id}
                    viewId={object.module_views?.[0]?.id}
                />
            )}
        </div>
    )
})
