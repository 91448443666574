// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    AxiosRequestConfig,
} from 'axios'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorHelper,
    IconBlock,
    ImageCropInput,
    Loader,
    TextAreaInput,
    TextInput,
} from 'components'

// data
import {
    api_url_selection_form_create,
    api_url_selection_form_create_staff,
    api_url_selection_form_detail,
    api_url_selection_form_update,
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
} from 'data'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type AddContentToFavoriteHelperProps = {
    contentType: contentTypeModelType
    objectId: number
    onRefresh?: () => void
    profileId: number
    retrieve_id?: number
    setIsOpen: React.Dispatch<boolean>
}

// main
export const AddContentToFavoriteHelper: React.FC<AddContentToFavoriteHelperProps> = React.memo(({
    contentType,
    objectId,
    onRefresh,
    profileId,
    retrieve_id,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type errorsType = {
        description?: string
        image?: string
        name?: string
        non_field_errors?: string
    }
    type fieldsType = {
        id?: number
        description?: string
        image?: any
        name?: string
    }
    const errorsInitial = {
        description: '',
        image: '',
        name: '',
        non_field_errors: '',
    }
    const fieldsInitial = {
        id: undefined,
        description: '',
        image: undefined,
        name: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (retrieve_id) {
            handleRetrieve()
        }
    }, [
        retrieve_id,
    ])

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setButtonDisabled(false)
            if (event.name === 'image') {
                setFields({
                    ...fields,
                    image: event.doka_image,
                })
                setErrors({
                    ...errors,
                    image: '',
                })  // should be after setFields to avoid bug on TextArea
            } else {
                setFields({ ...fields, [name]: event.value })
                setErrors({ ...errors, [name]: '' })  // should be after setFields to avoid bug on TextArea
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToFavoriteForm',
                'handleInputChange',
            ))
        }
    }

    async function handleRetrieve() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AddToFavoriteForm handleRetrieve')

            const getUrl = getApiUrl(`${api_url_selection_form_detail}${retrieve_id}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setErrors(errorsInitial)
                    setFields(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'AddToFavoriteForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleRetrieve',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToFavoriteForm',
                'handleRetrieve',
            ))
        }
    }

    async function onSubmit() {
        try {

            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AddToFavoriteForm onSubmit')

            if (!fields.image) {
                setErrors({ ...errors, image: reduxText[2856] })
                return
            }

            setIsSaving(true)
            setButtonDisabled(true)

            let axiosUrl = getApiUrl(reduxAuth.settings?.user?.is_staff ? api_url_selection_form_create_staff : api_url_selection_form_create, reduxAuth)

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            if (retrieve_id) {
                axiosMethod = 'patch'
                axiosUrl = getApiUrl(`${api_url_selection_form_update}${retrieve_id}/`, reduxAuth)
            }

            const formData: any = new FormData()
            formData.append('description', fields.description!)
            formData.append('name', fields.name!)
            if (fields.image.type) {
                const filetoUpload = fields.image
                let filetoUploadName = fields.image.name
                if (filetoUploadName.length > 100) {
                    filetoUploadName = filetoUploadName.slice(filetoUploadName.length - 100)
                }
                formData.append('image', filetoUpload, filetoUploadName)
            }
            if (!retrieve_id) {
                formData.append('content_type', contentTypeData[contentType!])
                formData.append('object_id', objectId)
                formData.append('selection_type', '1')
                if (reduxAuth.settings?.user?.is_staff) formData.append('profile', profileId)
            }

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsSaving(false)
                    setButtonDisabled(true)
                    setFields(fieldsInitial)
                    setErrors(errorsInitial)
                    if (retrieve_id) {
                        if (onRefresh) onRefresh()
                    } else {
                        dispatch(reduxFormSetIsSuccessToastOpen(true, reduxText[8429]))
                    }
                    setIsOpen(false)
                })
                .catch((error) => {
                    setIsSaving(false)
                    setButtonDisabled(false)
                    if (error.response) {
                        setErrors(error.response && error.response.data)
                    }
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'AddToFavoriteForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setIsSaving(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToFavoriteForm',
                'onSubmit',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class AddContentToFavoriteHelper'
            classes={{ paper: 'mo-dialog-wrapper fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[2096]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <TextInput
                    error={errors?.name}
                    label={reduxText[2931]}
                    name='name'
                    onChange={handleInputChange}
                    optional
                    value={fields.name || ''}
                />
                <TextAreaInput
                    error={errors?.description}
                    label={reduxText[4381]}
                    name='description'
                    onChange={handleInputChange}
                    optional
                    value={fields.description || ''}
                />
                <ImageCropInput
                    doka_output_data=''
                    error={errors?.image}
                    helperText={reduxText[177910]}
                    label={reduxText[1779]}
                    name='image'
                    onChange={handleInputChange}
                    original=''
                    value={fields.image || ''}
                />
                <ErrorHelper error={errors.non_field_errors} />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen(false)}
                    text={reduxText[4519]}
                />
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    fill='outline'
                    onClick={onSubmit}
                    text={reduxText[4520]}
                />
            </DialogActions>
            {isSaving && (
                <Loader isOpen />
            )}
        </Dialog>
    )
})
