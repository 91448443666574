// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    PaymentDateContributionInput,
    PricingContributionInput,
    YearContributionInput,
} from 'pages'

// serializers
import {
    ContributionFormType,
} from 'serializers/web'

// props
type MainContributionPanelProps = {
    formState: ContributionFormType
    handleInputChange: any
}

// main
export const MainContributionPanel: React.FC<MainContributionPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.contribution

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainContributionPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <TextInput
                disabled
                error={undefined}
                label={reduxText[6288]}
                name='adherent_number'
                onChange={undefined}
                value={fieldsMother.adherent?.name || ''}
            />
            <PricingContributionInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <YearContributionInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <PaymentDateContributionInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
