// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProfileDetailFormSerializer,
    ProfileDetailFormSerializerError,
} from 'serializers/web'

// props
type ProfileEmailProfileInputProps = {
    errors: ProfileDetailFormSerializerError | undefined
    fields: ProfileDetailFormSerializer
    onChange: any
}

// main
export const ProfileEmailProfileInput: React.FC<ProfileEmailProfileInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!reduxAuth.settings?.user?.is_staff) return null

    return (
        <React.Fragment>
            <TextInput
                disabled={!fields.has_profile_transfer_permission}
                error={errors?.profile_email}
                helperText={reduxText[540510]}
                label={reduxText[5405]}
                name='profile_email'
                onChange={onChange}
                type='email'
                value={fields.profile_email || ''}
            />
            <CheckboxInput
                error={errors?.profile_email_checkbox}
                label={reduxText[5435]}
                name='profile_email_checkbox'
                onChange={onChange}
                value={fields.profile_email_checkbox}
            />
        </React.Fragment>
    )
})
