// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_main_medium_type_material,
} from 'data'

// serializers
import {
    MaterialDetailFormSerializer,
    MaterialDetailFormSerializerError,
} from 'serializers/web'

// props
type MediumTypeMaterialInputProps = {
    errors: MaterialDetailFormSerializerError | undefined
    fields: MaterialDetailFormSerializer
    onChange: any
}

// main
export const MediumTypeMaterialInput: React.FC<MediumTypeMaterialInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <SelectListInput
            apiUrl={select_url_main_medium_type_material}
            clearable={false}
            error={errors?.medium_type}
            label={reduxText[3228]}
            name='medium_type'
            onChange={onChange}
            value={fields.medium_type || ''}
        />
    )
})
