// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    MainCountryFlag,
} from 'components'

// data
import {
    api_url_salesorder_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    SalesOrderActionSheet,
} from 'pages'

// serializers
import {
    SalesOrderListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDateWithType,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type SalesOrderListSmallBlockProps = {
    edit: boolean
    modalKey: number
    object: SalesOrderListSerializer
}

// main
export const SalesOrderListSmallBlock: React.FC<SalesOrderListSmallBlockProps> = React.memo(({
    edit,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [detailObject, setDetailObject] = useState<SalesOrderListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_salesorder_detail}${detailObject.id}/?block_size=small`, reduxAuth),
                component: 'SalesOrderListSmallBlock',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SalesOrderListSmallBlock',
                'onGetDetailData',
            ))
        }
    }

    return (
        <tr className='main-list-row SalesOrderListSmallBlock'>
            <td>
                <IconBlock
                    edit={edit}
                    iconClass='mo-new-icon-eye-solid'
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                />
                <SalesOrderActionSheet
                    edit={edit}
                    object={detailObject}
                    refreshData={onGetDetailData}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                />
            </td>
            <td><i className={`mlr-icon ${(detailObject.sales_status?.id !== 1) ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
            <td className='tar'>{detailObject.order_number}</td>
            <td>{detailObject.sales_status?.name}</td>
            <td className='tar'>{detailObject.total_amount} {detailObject.currency?.symbol}</td>
            <td>{detailObject.name}</td>
            <td>{detailObject.customer_email}</td>
            <td>{detailObject.shipping_city}</td>
            <td>
                <MainCountryFlag
                    edit={edit}
                    object={detailObject.shipping_country}
                    searchTab={undefined}
                    namePosition='end'
                />
            </td>
            <td>
                <a
                    href={`https://dashboard.stripe.com/payments/${detailObject.payment_number}`}
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <IconBlock
                        edit={edit}
                        iconClass='mo-new-icon-link-solid'
                    />
                </a>
            </td>
            {detailObject.profile === 187807 && (
                <React.Fragment>
                    <td>
                        {detailObject.shipments?.map(val => (
                            <a
                                key={val.id}
                                href={val.parcel_label_url}
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                <IconBlock
                                    edit={edit}
                                    iconClass='mo-new-icon-link-solid'
                                />
                            </a>
                        ))}
                    </td>
                    <td>
                        {detailObject.shipments?.map(val => (
                            <a
                                key={val.id}
                                href={val.tracking_url}
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                <IconBlock
                                    edit={edit}
                                    iconClass='mo-new-icon-link-solid'
                                />
                            </a>
                        ))}
                    </td>
                </React.Fragment>
            )}
            <td className='tar'>{getDateWithType(reduxAuth, detailObject.created_on, 't')}</td>
        </tr>
    )
})
