// react components
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
} from '@ionic/react'
import axios from 'axios'
import debounce from 'lodash.debounce'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import AsyncSelect from 'react-select/async'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    CityTempSerializer,
    TranslationHelper,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
} from 'services'

// props
type LocationInputProps = {
    adminOnly?: boolean
    canGetProfileAddress?: boolean
    clearable?: boolean
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label?: string
    mother?: any
    multi?: boolean
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    placeholder?: string
    staffOnly?: boolean
    translation?: TranslationHelper
    value: any
}

// main
export const LocationInput: React.FC<LocationInputProps> = React.memo(({
    adminOnly,
    canGetProfileAddress,
    clearable = true,
    disabled,
    error,
    helperText,
    label,
    mother,
    multi,
    name,
    onChange,
    optional,
    placeholder,
    staffOnly,
    translation,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setValue(value || (multi ? [] : ''))
    }, [value])

    const [newValue, setValue] = useState<CityTempSerializer[]>(value || (multi ? [] : ''))

    function onInputChange(value: any) {
        try {
            if (value === newValue) return
            setValue(value)
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LocationInput',
                'onInputChange',
            ))
        }
    }

    async function getOptions(inputValue: string) {
        const getUrl = getApiUrlV2(`main/helper/googleplace/autocomplete/?search=${inputValue}`, reduxAuth)
        if (process.env.NODE_ENV === 'development') console.log(getUrl)
        try {
            const response = await fetch(getUrl, reduxAuth?.axiosConfig)
            const json = await response.json()
            return json
        } catch (error) {
            axiosErrorHandler({
                apiUrl: getUrl,
                component: 'LocationInput',
                dispatch,
                error,
                reduxAuth,
                reference: 'getOptions',
            })
        }
    }

    const debouncedGetOptions = useCallback(
        debounce((inputValue: string, callback: (options: any) => void) => {
            getOptions(inputValue).then(callback)
        }, 500),
        []
    )

    const promiseOptions = (inputValue: string) =>
        new Promise(resolve => {
            debouncedGetOptions(inputValue, resolve)
        })

    async function getPlaceDetail(e: any) {
        try {
            if (multi) {
                if (e?.length === 0) {
                    onInputChange(e)
                    return
                }
                const placedToCheck: CityTempSerializer | undefined = e.find(
                    (place: CityTempSerializer) => !newValue.some((item: any) => item.placeId === place.placeId)
                )
                if (!placedToCheck) {
                    onInputChange(e)
                } else {
                    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'LocationInput getPlaceDetail')
                    const getUrl = getApiUrlV2(`main/helper/googleplace/detail/?place_id=${placedToCheck.placeId}&description=${placedToCheck.description}`, reduxAuth)
                    if (process.env.NODE_ENV === 'development') console.log(getUrl)
                    axios({
                        headers: refreshAxiosHeaders,
                        method: 'get',
                        url: getUrl,
                    })
                        .then((response) => {
                            onInputChange([...newValue, ...response.data])
                        })
                        .catch((error) => {
                            axiosErrorHandler({
                                apiUrl: getUrl,
                                component: 'LocationInput',
                                dispatch,
                                error,
                                reduxAuth,
                                reference: 'getPlaceDetail',
                            })
                        })
                }
            } else {
                if (!e?.placeId) {
                    onInputChange(e)
                } else {
                    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'LocationInput getPlaceDetail')
                    const getUrl = getApiUrlV2(`main/helper/googleplace/detail/?place_id=${e.placeId}&description=${e.description}`, reduxAuth)
                    if (process.env.NODE_ENV === 'development') console.log(getUrl)
                    axios({
                        headers: refreshAxiosHeaders,
                        method: 'get',
                        url: getUrl,
                    })
                        .then((response) => {
                            onInputChange(response.data)
                        })
                        .catch((error) => {
                            axiosErrorHandler({
                                apiUrl: getUrl,
                                component: 'LocationInput',
                                dispatch,
                                error,
                                reduxAuth,
                                reference: 'getPlaceDetail',
                            })
                        })
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LocationInput',
                'getPlaceDetail',
            ))
        }
    }

    function getProfileAddress() {
        try {
            const valueObject = {
                name: 'address_json',
                value: mother.fields.profile.address_json,
            }
            setValue(mother.fields.profile.address_json)
            onChange(valueObject)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LocationInput',
                'getProfileAddress',
            ))
        }
    }

    const customStyles = {
        clearIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            border: 'none',
            ':hover': {
                ...provided[':hover'],
                border: 'none',
                boxShadow: 'none',
            },
            ':focus': {
                ...provided[':focus'],
                boxShadow: '0 0 0 2px var(--mo-color-black)',
                outline: '1px solid var(--mo-color-black)',
            },
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--mo-color-text1)',
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            borderRadius: '8px',
            boxShadow: '2px 6px 21px -2px rgba(0,0,0,0.75)',
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            boxShadow: '0 0 4px 2px rgba(var(--mo-color-mo-color-rgb), 0.70)',
            border: '1px solid var(--mo-color-mo-color)',
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            borderBottom: '1px solid var(--mo-color-ln)',
            color: state.isSelected ? 'var(--mo-color-mo-color)' : 'var(--mo-color-text1)',
            ':active': {
                ...provided[':active'],
                backgroundColor: 'var(--mo-color-bg2)',
                color: 'var(--mo-color-mo-color)',
            },
            ':hover': {
                ...provided[':hover'],
                backgroundColor: 'var(--mo-color-mo-color)',
                color: 'var(--mo-color-text1)',
                cursor: 'pointer',
            },
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--mo-color-text1)',
        }),
    }

    return (
        <div className={`location-input-web${disabled ? ' disabled' : ''}`}>
            {(label || translation) && (
                <MainFormLabel
                    adminOnly={adminOnly}
                    formInput='address_json'
                    helperText={helperText}
                    label={label!}
                    name={name}
                    optional={optional}
                    staffOnly={staffOnly}
                    translation={translation}
                    // @ts-ignore
                    translationDefaultValue={value}
                />
            )}
            <AsyncSelect
                cacheOptions
                getOptionLabel={option => option.description}
                getOptionValue={(option: any) => option.placeId}
                isClearable={clearable}
                isDisabled={disabled}
                isMulti={multi}
                //@ts-ignore
                loadOptions={promiseOptions}
                menuPlacement='auto'
                noOptionsMessage={(e: { inputValue: string }) => e.inputValue ? reduxText[5599] : reduxText[6376]}
                onChange={getPlaceDetail}
                placeholder={placeholder || reduxText[6376]}
                styles={customStyles}
                value={newValue}
            />
            {canGetProfileAddress && mother?.fields?.profile?.address_json && (
                <IonList>
                    <IonItem lines='none'>
                        <IonLabel className='liw-label'>{reduxText[5484]}</IonLabel>
                        <IonCheckbox
                            className='liw-checkbox'
                            slot='start'
                            onIonChange={() => getProfileAddress()}
                        />
                    </IonItem>
                </IonList>
            )}
            <ErrorHelper error={error} />
        </div>
    )
})
