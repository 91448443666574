// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    SelectAsyncInput,
    SelectListInput,
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_profile,
} from 'data'

// serializers
import {
    InlineFormPanelProps,
    ProfileFormState,
} from 'serializers/web'

// props
type TeamProfilePanelProps = {
    formState: ProfileFormState
    setFormState: React.Dispatch<ProfileFormState>
}

// main
export const TeamProfilePanel: React.FC<InlineFormPanelProps & TeamProfilePanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const team = formState.team

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, team)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamProfilePanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = team.errors
    const fieldsInline = team.fields

    if (!fieldsInline) return null

    // Others
    const options = [
        { id: 4, name: reduxText[5539] },
    ]

    return (
        <InlineFormContainerWeb
            formInfo={team}
            formState={formState}
            hasSearch
            helpers={helpers}
            noOrdering
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            <SelectListInput
                clearable={false}
                error={errorsInline?.credit_type}
                label={reduxText[5557]}
                name='credit_type'
                onChange={onHandleInputChange}
                options={options}
                value={fieldsInline.credit_type || ''}
            />
            {fieldsInline.id
                ? (
                    <React.Fragment>
                        {fieldsInline.member
                            ? (
                                <SelectAsyncInput
                                    apiUrl={`${select_url_profile}?credit=${fieldsMother.id}`}
                                    clearable={false}
                                    disabled
                                    error={errorsInline?.member}
                                    label={reduxText[5559]}
                                    layout='profile'
                                    name='member'
                                    onChange={onHandleInputChange}
                                    team
                                    value={fieldsInline.member || ''}
                                />
                            ) : (
                                <TextInput
                                    error={errorsInline?.name}
                                    label={reduxText[5559]}
                                    name='name'
                                    onChange={onHandleInputChange}
                                    value={fieldsInline.name || ''}
                                />
                            )
                        }
                    </React.Fragment>
                ) : (
                    <SelectAsyncInput
                        apiUrl={`${select_url_profile}?credit=${fieldsMother.id}`}
                        clearable={false}
                        creatable
                        error={errorsInline?.member}
                        label={reduxText[5559]}
                        layout='profile'
                        name='member'
                        onChange={onHandleInputChange}
                        team
                        value={fieldsInline.member || ''}
                    />
                )
            }
        </InlineFormContainerWeb>
    )
})
