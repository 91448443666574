// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    Alert,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormSetEditingSite,
    reduxFormShowEditAlertSite,
} from 'data'

// main
export const EditAlert: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const editAlertButtons = [
        {
            handler: () => {
                dispatch(reduxFormSetEditingSite(false))
                if (reduxFormSite.editFunction) reduxFormSite.editFunction()
                if (reduxFormSite.editUrl) history.push(reduxFormSite.editUrl)
            },
            text: reduxText[5614],
        },
        {
            text: reduxText[4519],
        },
    ]

    return (
        <Alert
            buttons={editAlertButtons}
            header={reduxText[7460]}
            isOpen={reduxFormSite.showEditAlert}
            message={reduxText[7461]}
            onDidDismiss={() => dispatch(reduxFormShowEditAlertSite(false))}
        />
    )
}
