// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Alert,
    Loader,
} from 'components'

// data
import {
    api_url_copy_content,
    contentTypeData,
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    reduxModalErrorShowHide,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    BuilderService,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
}

type CopyContentHelperProps = {
    direction?: 'after' | 'before' | 'inside'
    isButton?: boolean
    isNormal: boolean | undefined
    isOpen?: boolean
    mixedMedia: boolean
    object: PortfolioPageContentListSiteSerializer
    setDirection: React.Dispatch<'after' | 'before' | 'inside' | undefined>
    setIsOpen: React.Dispatch<boolean>
    setIsPopoverOpen: React.Dispatch<boolean>
}

// main
export const CopyContentHelper: React.FC<CopyContentHelperProps> = React.memo(({
    direction,
    isButton,
    isNormal,
    isOpen,
    mixedMedia,
    object,
    setDirection,
    setIsOpen,
    setIsPopoverOpen,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)

    async function handlePasteContent(position: 'after' | 'before' | 'inside' | undefined, withTranslation?: boolean) {
        try {
            const contentToCopyObject = BuilderService.copyGetContentObject()
            if (isButton && contentToCopyObject?.is_multi_language) {
                setDirection(position)
                setIsOpen(true)
                return
            }
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'CopyContentHelper handlePasteContent')

            const postUrl = getApiUrl(api_url_copy_content, reduxAuth)
            const formData = new FormData()
            formData.append('from_content_type_id', contentToCopyObject!.content_type.toString())
            formData.append('from_object_id', contentToCopyObject!.id!.toString())
            formData.append('to_content_type_id', contentTypeData[mixedMedia ? 'mixedmediacontent' : 'pagecontent'].toString())
            formData.append('to_object_id', object.id!.toString())
            if (!position || (position === 'inside')) {
                formData.append('to_parent_content_id', `${object.id}`)
            } else {
                formData.append('to_position_direction', position)
            }
            if (withTranslation) {
                formData.append('duplicate_with_translation', 'true')
            }
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setIsPopoverOpen(false)
                    dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                })
                .catch((error) => {
                    if (error.response?.status === 400 && error.response.data.main_error) {
                        dispatch(reduxModalErrorShowHide({
                            header: error.response.data.main_error,
                            isOpen: true,
                            noMessage: true,
                            source: 'CopyContentHelper handlePasteContent',
                        }))
                    } else {
                        axiosErrorHandler({
                            apiUrl: postUrl,
                            component: 'CopyContentHelper',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleMoveContent',
                        })
                    }
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'CopyContentHelper',
                'handlePasteContent',
            ))
        }
    }

    if (isButton) {
        return (
            <div className={`shpcwp-item-layout-input${isNormal ? '' : ' column'}`}>
                <button
                    className='shpcwp-item-button'
                    onClick={() => handlePasteContent('after')}
                >
                    <i className='mo-new-icon-paste-solid' />
                    <span className='shpcwp-item-button-text'>{reduxText[8494]}</span>
                </button>
                <div className='shpcwp-item-button-wrap'>
                    <div className='shpcwp-item-button-wrap2'>
                        <button
                            className='shpcwp-item-button1'
                            title={reduxText[8497]}
                            onClick={() => handlePasteContent('before')}
                        >
                            <i className='mo-new-icon-chevron-up-solid' />
                        </button>
                    </div>
                    {!isNormal && (
                        <div className='shpcwp-item-button-wrap2'>
                            <button
                                className='shpcwp-item-button2'
                                title={reduxText[8495]}
                                onClick={() => handlePasteContent('inside')}
                            >
                                <i className='mo-new-icon-expand-solid' />
                            </button>
                        </div>
                    )}
                    <div className='shpcwp-item-button-wrap2'>
                        <button
                            className='shpcwp-item-button2'
                            title={reduxText[8496]}
                            onClick={() => handlePasteContent('after')}
                        >
                            <i className='mo-new-icon-chevron-down-solid' />
                        </button>
                    </div>
                </div>
                {isLoading && (
                    <Loader
                        isOpen
                        message={reduxText[8492]}
                    />
                )}
            </div>
        )
    }

    if (isOpen) {
        return (
            <React.Fragment>
                <Alert
                    buttons={[
                        {
                            handler: () => handlePasteContent(direction, true),
                            text: reduxText[3696],
                        },
                        {
                            handler: () => handlePasteContent(direction),
                            text: reduxText[1236],
                        },
                    ]}
                    header={reduxText[8493]}
                    isOpen
                    onDidDismiss={() => setIsOpen(false)}
                />
                {isLoading && (
                    <Loader
                        isOpen
                        message={reduxText[7692]}
                    />
                )}
            </React.Fragment>
        )
    }

    return null
})
