// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_main_custom_category,
} from 'data'

// serializers
import {
    PageTemplateFormState,
} from 'serializers/web'

// props
type MainPageTemplatePanelProps = {
    formState: PageTemplateFormState
    handleInputChange: any
}

// main
export const MainPageTemplatePanel: React.FC<MainPageTemplatePanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainPageTemplatePanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <ImageCropInput
                altButton
                altValue={fieldsMother.image_alt}
                doka_output_data={fieldsMother.image_doka_output_data || ''}
                error={errorsMother?.image}
                errorAlt={errorsMother?.image_alt}
                label={reduxText[1767]}
                name='image'
                onChange={onHandleInputChange}
                optional
                original={fieldsMother.image_original || ''}
                value={fieldsMother.image || ''}
            />
            {fieldsMother.profile?.id && (
                <SelectListInput
                    apiUrl={`${select_url_main_custom_category}?profile_id=${fieldsMother.profile?.id}`}
                    clearable={false}
                    error={errorsMother?.custom_categories}
                    label={reduxText[5569]}
                    multi
                    name='custom_categories'
                    onChange={onHandleInputChange}
                    optional
                    profileId={fieldsMother.profile?.id}
                    value={fieldsMother.custom_categories || ''}
                />
            )}
        </React.Fragment>
    )
})
