// react components
import React, {
    useState,
} from 'react'
import Tooltip from '@mui/material/Tooltip'

// props
type CopyProps = {
    className?: string
    hidden?: boolean
    isIcon?: boolean
    text: string
}

// main
export const Copy: React.FC<CopyProps> = React.memo(({
    className,
    hidden,
    isIcon,
    text,
}) => {

    const [tooltipText, setTooltipText] = useState<string | undefined>('Copy')

    return (
        <Tooltip
            onClose={() => setTooltipText('Copy')}
            title={tooltipText}
        >
            {isIcon
                ? (
                    <i
                        className={`${className ? `${className} ` : ''}main-icon mo-new-icon-clipboard-regular pointer${hidden ? ' hidden' : ''}`}
                        onClick={() => {
                            setTooltipText('✔ Copied')
                            navigator.clipboard.writeText(text)
                        }}
                    />
                ) : (
                    <span
                        onClick={() => {
                            setTooltipText('✔ Copied')
                            navigator.clipboard.writeText(text)
                        }}
                    >
                        {text}
                    </span>
                )
            }
        </Tooltip>
    )
})
