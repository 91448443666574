// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
} from 'services'

// main
export const AgendaDuQuartierInvoice: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [items, setItems] = useState<any[]>([])

    useEffect(() => {
        onGetDetailData()
    }, [])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/list/invoice/`, reduxAuth),
                component: 'AgendaDuQuartierInvoice',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierInvoice',
                'onGetDetailData',
            ))
        }
    }

    async function downloadInvoice(stripe_invoice_id: string, stripe_invoice_number: string) {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierInvoice downloadInvoice')
            const postUrl = getApiUrlV2(`customer/agendaduquartier/form/invoice/download_pdf/`, reduxAuth)
            const formData = new FormData()
            formData.append('stripe_invoice_id', stripe_invoice_id)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
                responseType: 'blob',
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${stripe_invoice_number}.pdf`)
                    document.body.appendChild(link)
                    link.click()

                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'AgendaDuQuartierInvoice',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'downloadInvoice',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierInvoice',
                'downloadInvoice',
            ))
        }
    }

    const count = items.length

    return (
        <div className='admin-module'>
            <div className='am-header'>
                <div className='am-header-title'>
                    <p>Mes commandes</p>
                </div>
            </div>
            <div className={`am-infinite-table mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <table>
                    <thead>
                        <tr className='table-header'>
                            <th className='th-column field-action'>
                                <div className='th-div'>{count !== undefined ? `(${count})` : '()'}</div>
                            </th>
                            <th className='th-column'>
                                <div className='th-div-wrap'>
                                    <div className='th-div'>Numéro</div>
                                </div>
                            </th>
                            <th className='th-column'>
                                <div className='th-div-wrap'>
                                    <div className='th-div'>Montant</div>
                                </div>
                            </th>
                            <th className='th-column'>
                                <div className='th-div-wrap'>
                                    <div className='th-div'>Action</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => {
                            return (
                                <tr
                                    key={item.id}
                                    className='table-row'
                                >
                                    <td className='tr-action' />
                                    <td className='table-column'>
                                        <div className='tc-link-div'>
                                            <span className='table-column-value'>
                                                {item.number}
                                            </span>
                                        </div>
                                    </td>
                                    <td className='table-column'>
                                        <div className='tc-link-div'>
                                            <span className='table-column-value'>
                                                {Number(item.amount_due) / 100} €
                                            </span>
                                        </div>
                                    </td>
                                    <td className='table-column'>
                                        <div className='tc-link-div'>
                                            <Button
                                                edit={false}
                                                fill='outline'
                                                onClick={() => downloadInvoice(item.id, item.number)}
                                                size='x-small'
                                                text='Télécharger'
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {isLoading && <Loader isOpen />}
        </div>
    )
}
