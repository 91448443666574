// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonTitle,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import L from 'leaflet'
import {
    FeatureGroup,
    Map,
    TileLayer,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import Search from 'react-leaflet-search'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    HeaderChildren,
    IconBlock,
    InfiniteScrollHelperWeb,
    ListSpinner,
} from 'components'

// data
import {
    defaultReduxState,
    eventFilterOptions,
    leaflet_attribution,
    leaflet_mapbox_url,
    materialFilterOptions,
    media_url,
    media_version,
    offerFilterOptions,
    portfolioFilterOptions,
    pressFilterOptions,
    productFilterOptions,
    profileFilterOptions,
    projectFilterOptions,
    reduxModalErrorEventHandler,
    resourceFilterOptions,
    serviceFilterOptions,
} from 'data'

// pages
import {
    EventDetailBlockMobile,
    EventListMediumBlockWeb,
    EventListSmallBlockWeb,
    MaterialDetailBlockMobile,
    MaterialListMediumBlockWeb,
    MaterialListSmallBlockWeb,
    OfferDetailBlockMobile,
    OfferListMediumBlockWeb,
    OfferListSmallBlockWeb,
    // PortfolioDetailBlockMobile,
    PortfolioTemplateListBlock,
    PortfolioListBlock,
    PressListMediumBlockWeb,
    PressListSmallBlockWeb,
    ProductDetailBlockMobile,
    ProductListMediumBlockWeb,
    ProductListSmallBlockWeb,
    ProfileDetailBlockMobile,
    ProfileListMediumBlockWeb,
    ProfileListSmallBlockWeb,
    ProjectDetailBlockMobile,
    ProjectListMediumBlockWeb,
    ProjectListSmallBlockWeb,
    // ServiceDetailBlock,
    ServiceListMediumBlock,
    ServiceListSmallBlock,
} from 'pages'

// serializers
import {
    MainMapSerializer,
} from 'serializers/web'

// services
import {
    getDetailObject,
    getInfiniteListWebNew,
    getInfiniteRandomListWebNew,
    getMapList,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// props
type LocationParams = {
    clearSearch?: number
}

type TabListContainerWebProps = {
    defaultTab: string
    endText?: boolean
    filterParamsApiUrl: any
    noThumb?: boolean
    random?: boolean
    titleId: number
}

// main
export const TabListContainerWeb: React.FC<TabListContainerWebProps> = React.memo(({
    defaultTab,
    endText,
    filterParamsApiUrl,
    noThumb,
    random,
    titleId,
}) => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const filterParamsOptions: any = {
        'artworks': {
            defaultThumbSize: 'small',
            detailComponent: 'ProjectDetailBlockMobile',
            filterSet: projectFilterOptions,
            mediumListComponent: 'ProjectListMediumBlockWeb',
            smallListComponent: 'ProjectListSmallBlockWeb',
            textId: 3554,  // reduxText[3554]
        },
        'profiles': {
            defaultThumbSize: 'small',
            detailComponent: 'ProfileDetailBlockMobile',
            filterSet: profileFilterOptions,
            mediumListComponent: 'ProfileListMediumBlockWeb',
            smallListComponent: 'ProfileListSmallBlockWeb',
            textId: 2432,  // reduxText[2432]
        },
        'press': {
            defaultThumbSize: 'small',
            detailComponent: 'ProjectDetailBlockMobile',
            filterSet: pressFilterOptions,
            mediumListComponent: 'PressListMediumBlockWeb',
            smallListComponent: 'PressListSmallBlockWeb',
            textId: 4812,  // reduxText[4812]
        },
        'products': {
            defaultThumbSize: 'small',
            detailComponent: 'ProductDetailBlockMobile',
            filterSet: productFilterOptions,
            mediumListComponent: 'ProductListMediumBlockWeb',
            smallListComponent: 'ProductListSmallBlockWeb',
            textId: 1091,  // reduxText[1091]
        },
        'events': {
            defaultThumbSize: 'medium',
            detailComponent: 'EventDetailBlockMobile',
            filterSet: eventFilterOptions,
            mediumListComponent: 'EventListMediumBlockWeb',
            smallListComponent: 'EventListSmallBlockWeb',
            textId: 2433,  // reduxText[2433]
        },
        'offers': {
            defaultThumbSize: 'medium',
            detailComponent: 'OfferDetailBlockMobile',
            filterSet: offerFilterOptions,
            mediumListComponent: 'OfferListMediumBlockWeb',
            smallListComponent: 'OfferListSmallBlockWeb',
            textId: 302,  // reduxText[302]
        },
        'services': {
            defaultThumbSize: 'medium',
            detailComponent: 'ServiceDetailBlock',
            filterSet: serviceFilterOptions,
            mediumListComponent: 'ServiceListMediumBlock',
            smallListComponent: 'ServiceListSmallBlock',
            textId: 7434,  // reduxText[7434]
        },
        'materials': {
            defaultThumbSize: 'medium',
            detailComponent: 'MaterialDetailBlockMobile',
            filterSet: materialFilterOptions,
            mediumListComponent: 'MaterialListMediumBlockWeb',
            smallListComponent: 'MaterialListSmallBlockWeb',
            textId: 301,  // reduxText[301]
        },
        'portfolios': {
            defaultThumbSize: 'small',
            detailComponent: 'PortfolioDetailBlockMobile',
            filterSet: portfolioFilterOptions,
            mediumListComponent: 'PortfolioListBlock',
            smallListComponent: 'PortfolioListBlock',
            textId: 6265,  // reduxText[6265]
        },
        'po-all': {
            defaultThumbSize: 'small',
            detailComponent: 'PortfolioDetailBlockMobile',
            filterSet: portfolioFilterOptions,
            mediumListComponent: 'PortfolioTemplateListBlock',
            smallListComponent: 'PortfolioTemplateListBlock',
            textId: 6261,  // reduxText[6261]
        },
        'po-portfolios': {
            defaultThumbSize: 'small',
            detailComponent: 'PortfolioDetailBlockMobile',
            filterSet: portfolioFilterOptions,
            mediumListComponent: 'PortfolioTemplateListBlock',
            smallListComponent: 'PortfolioTemplateListBlock',
            textId: 6259,  // reduxText[6259]
        },
        'po-eshops': {
            defaultThumbSize: 'small',
            detailComponent: 'PortfolioDetailBlockMobile',
            filterSet: portfolioFilterOptions,
            mediumListComponent: 'PortfolioTemplateListBlock',
            smallListComponent: 'PortfolioTemplateListBlock',
            textId: 6260,  // reduxText[6260]
        },
        'po-users-all': {
            defaultThumbSize: 'small',
            detailComponent: 'PortfolioDetailBlockMobile',
            filterSet: portfolioFilterOptions,
            mediumListComponent: 'PortfolioListBlock',
            smallListComponent: 'PortfolioListBlock',
            textId: 6262,  // reduxText[6262]
        },
        'po-users-portfolios': {
            defaultThumbSize: 'small',
            detailComponent: 'PortfolioDetailBlockMobile',
            filterSet: portfolioFilterOptions,
            mediumListComponent: 'PortfolioListBlock',
            smallListComponent: 'PortfolioListBlock',
            textId: 6263,  // reduxText[6263]
        },
        'po-users-eshops': {
            defaultThumbSize: 'small',
            detailComponent: 'PortfolioDetailBlockMobile',
            filterSet: portfolioFilterOptions,
            mediumListComponent: 'PortfolioListBlock',
            smallListComponent: 'PortfolioListBlock',
            textId: 6264,  // reduxText[6264]
        },
        'resources': {
            defaultThumbSize: 'small',
            detailComponent: 'ProjectDetailBlockMobile',
            filterSet: resourceFilterOptions,
            mediumListComponent: 'PressListMediumBlockWeb',
            smallListComponent: 'PressListSmallBlockWeb',
            textId: 8144,  // reduxText[8144]
        },
    }

    const [mainTabValue, setMainTabValue] = useState<string>(location.hash.split('#tab=')[1] || defaultTab)
    const [mapItemsType, setMapItemsType] = useState<'detail' | 'list'>('list')
    const [searchInput, setSearchInput] = useState('')
    const [thumbSize, setThumbSize] = useState<string>(filterParamsOptions[mainTabValue].defaultThumbSize)
    const [viewType, setViewType] = useState<'list' | 'map'>('list')
    const [mapItem, setMapItem] = useState<any>()

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb>({
        ...listStateInitialWeb,
        clusterIds: [],
        componentString: filterParamsOptions[mainTabValue][`${thumbSize}ListComponent`],
        modalKey: Math.random(),
    })

    const components: any = {
        EventDetailBlockMobile,
        EventListMediumBlockWeb,
        EventListSmallBlockWeb,
        MaterialDetailBlockMobile,
        MaterialListMediumBlockWeb,
        MaterialListSmallBlockWeb,
        OfferDetailBlockMobile,
        OfferListMediumBlockWeb,
        OfferListSmallBlockWeb,
        // PortfolioDetailBlockMobile,
        PortfolioTemplateListBlock,
        PortfolioListBlock,
        PressListMediumBlockWeb,
        PressListSmallBlockWeb,
        ProductDetailBlockMobile,
        ProductListMediumBlockWeb,
        ProductListSmallBlockWeb,
        ProfileDetailBlockMobile,
        ProfileListMediumBlockWeb,
        ProfileListSmallBlockWeb,
        ProjectDetailBlockMobile,
        ProjectListMediumBlockWeb,
        ProjectListSmallBlockWeb,
        // ServiceDetailBlock,
        ServiceListMediumBlock,
        ServiceListSmallBlock,
    }

    const MapDetailComponentName = components[filterParamsOptions[mainTabValue].detailComponent]
    const MapListComponentName = components[filterParamsOptions[mainTabValue].smallListComponent]
    const ListComponentName = components[listState.componentString]

    useEffect(() => {
        if (viewType === 'map') {
            onGetMapData(filterParamsApiUrl[mainTabValue].mapApiUrl)
        }
        onGetListData(filterParamsApiUrl[mainTabValue].listApiUrl, true)
    }, [
        mainTabValue,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
        searchInput,
        thumbSize,
        viewType,
    ])

    useEffect(() => {
        if (viewType === 'map' && listState.clusterIds?.length! > 0) {
            onGetListData(filterParamsApiUrl[mainTabValue].listApiUrl, true)
        }
    }, [
        listState.clusterIds,
    ])

    useEffect(() => {
        if (location.search) {
            const searchParam = location.search.split('?')[1].split('=')[0]
            if (!['modal_detail_id', 'modal_page'].includes(searchParam)) setSearchInput(location.search)
        }
    }, [
        location.search,
    ])

    useEffect(() => {
        if (location.state?.clearSearch) setSearchInput('')
    }, [
        location.state?.clearSearch,
    ])

    const clusterRef = useRef(null)
    const contentRef = useRef<HTMLIonContentElement>(null)
    const groupRef = useRef<FeatureGroup>(null)
    const mapRef = useRef<Map>(null)

    const redMarker = L.icon({
        iconUrl: `${media_url}global/map/marker.svg${media_version}`,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
    })

    function addMarkers(data: MainMapSerializer[]) {
        try {
            const markers = L.markerClusterGroup()
            let hasMarkers = false
            data.filter(obj => obj.lat !== undefined).map((val) => {
                hasMarkers = true
                const marker = L.marker(
                    [val.lat, val.lng],
                    {
                        icon: redMarker,
                    },
                ).on(
                    'click',
                    () => handleMarkersClick(val),
                )
                // @ts-ignore
                marker.mo_id = val.parent
                markers.addLayer(marker)
                return false
            })
            // @ts-ignore
            clusterRef.current.leafletElement.addLayers(markers)
            if (hasMarkers) handleFitBounds(data)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'addMarkers',
            ))
        }
    }

    function handleFitBounds(data: MainMapSerializer[]) {
        try {
            if (data.length > 0) {
                const map = mapRef.current?.leafletElement
                const group = groupRef.current?.leafletElement
                if (group) map?.fitBounds(group?.getBounds())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'handleFitBounds',
            ))
        }
    }

    function handleMarkersClick(marker: MainMapSerializer) {
        try {
            setMapItem(undefined)
            setMapItemsType('detail')
            getDetailObject({
                apiUrl: `${filterParamsApiUrl[mainTabValue].detailApiUrl}${marker.parent}/`,
                component: 'TabListContainerWeb',
                dispatch,
                reduxAuth,
                setDetailObject: setMapItem,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'handleMarkersClick',
            ))
        }
    }

    function handleMarkerClusterClick(cluster: any) {
        try {
            setMapItemsType('list')
            const idArray: number[] = []
            cluster.layer.getAllChildMarkers().map((val: any) => {
                idArray.push(Number(val.mo_id))
                return false
            })
            setListState({
                ...listState,
                clusterIds: idArray,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'handleMarkerClusterClick',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            let filterQuery = ''
            if (location.search && location.search.split('?')[1]) {
                const searchParam = location.search.split('?')[1].split('=')[0]
                const searchValue = location.search.split('?')[1].split('=')[1]
                if (!['modal_detail_id', 'modal_page'].includes(searchParam)) filterQuery = `${searchParam}=${searchValue}`
            }

            let axiosUrl = ''
            if (random) {
                if (changingView) {
                    scrollToTop()
                    if ((apiUrl).includes('?')) {
                        axiosUrl = `${apiUrl}&block_size=${thumbSize}&${filterQuery}`
                    } else {
                        axiosUrl = `${apiUrl}?block_size=${thumbSize}&${filterQuery}`
                    }
                } else {
                    if ((filterParamsApiUrl[mainTabValue].listApiUrl).includes('?')) {
                        axiosUrl = `${filterParamsApiUrl[mainTabValue].listApiUrl}&block_size=${thumbSize}&${filterQuery}`
                    } else {
                        axiosUrl = `${filterParamsApiUrl[mainTabValue].listApiUrl}?block_size=${thumbSize}&${filterQuery}`
                    }
                }
            } else {
                if (changingView) {
                    scrollToTop()
                    if ((apiUrl).includes('?')) {
                        axiosUrl = `${apiUrl}&block_size=${thumbSize}&${filterQuery}`
                    } else {
                        axiosUrl = `${apiUrl}?block_size=${thumbSize}&${filterQuery}`
                    }
                } else {
                    axiosUrl = apiUrl
                }
            }

            if (random) {
                getInfiniteRandomListWebNew({
                    apiUrl: axiosUrl,
                    axiosCancelToken,
                    changingView,
                    component: 'TabListContainerWeb',
                    componentName: filterParamsOptions[mainTabValue][`${thumbSize}ListComponent`],
                    dispatch,
                    listState,
                    reduxAuth,
                    setAxiosCancelToken,
                    setListState,
                })
            } else {
                getInfiniteListWebNew({
                    apiUrl: axiosUrl,
                    axiosCancelToken,
                    changingView,
                    component: 'TabListContainerWeb',
                    componentName: filterParamsOptions[mainTabValue][`${thumbSize}ListComponent`],
                    dispatch,
                    listState,
                    reduxAuth,
                    setAxiosCancelToken,
                    setListState,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'getListData',
            ))
        }
    }

    function onGetMapData(
        apiUrl: string,
    ) {
        try {
            let filterQuery = ''
            if (location.search && location.search.split('?')[1]) {
                filterQuery = location.search.split('?')[1]
            }

            let axiosUrl = ''
            if ((apiUrl).includes('?')) {
                axiosUrl = `${apiUrl}&${filterQuery}`
            } else {
                axiosUrl = `${apiUrl}?${filterQuery}`
            }

            if (clusterRef.current) {
                // @ts-ignore
                clusterRef.current.leafletElement.clearLayers()
            }
            getMapList({
                addMarkers,
                apiUrl: axiosUrl,
                axiosCancelToken,
                component: 'TabListContainerWeb',
                dispatch,
                listState,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'getListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                if (random) {
                    onGetListData('', false)
                } else {
                    onGetListData(listState.nextUrl, false)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'onSearchNext',
            ))
        }
    }

    function onSetTabValue(newTabValue: string) {
        try {
            if (mainTabValue === newTabValue) {
                setListState({
                    ...listState,
                    disableInfiniteScroll: true,
                })
                if (viewType === 'map') {
                    onGetMapData(filterParamsApiUrl[mainTabValue].mapApiUrl)
                }
                onGetListData(filterParamsApiUrl[mainTabValue].listApiUrl, true)
            } else {
                setListState({
                    ...listState,
                    clusterIds: [],
                    disableInfiniteScroll: true,
                })
                setMainTabValue(newTabValue)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'onSetTabValue',
            ))
        }
    }

    function onSetThumbSize(value: string) {
        try {
            setViewType('list')
            setThumbSize(value)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'onSetThumbSize',
            ))
        }
    }

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop(500)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerWeb',
                'scrollToTop',
            ))
        }
    }

    return (
        <IonPage className='tab-list-container-web'>
            <HeaderChildren>
                <IonTitle>{reduxText[titleId]} - {reduxText[filterParamsApiUrl[mainTabValue].textId || filterParamsOptions[mainTabValue].textId]}</IonTitle>
            </HeaderChildren>
            <IonContent
                className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}
                ref={contentRef}
            >
                <div className='tlcw-content'>
                    <div className={`tlcw-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <div className='tlcw-menu-web'>
                            {!noThumb && (
                                <div className='tlcw-thumb-wrap'>
                                    <IconBlock
                                        className={`tlcw-size-icon${thumbSize === 'small' ? ' active' : ''}`}
                                        edit={false}
                                        iconClass='mo-new-icon-gallery'
                                        onClick={() => onSetThumbSize('small')}
                                    />
                                    <IconBlock
                                        className={`tlcw-size-icon${thumbSize === 'medium' ? ' active' : ''}`}
                                        edit={false}
                                        iconClass='mo-new-icon-list'
                                        onClick={() => onSetThumbSize('medium')}
                                    />
                                    {/* <IconBlock
										className={`tlcw-size-icon${thumbSize === 'big' ? ' active' : ''}`}
										edit={false}
										iconClass='mo-new-icon-expand-solid'
										onClick={() => onSetThumbSize('big')}
									/> */}
                                    <IconBlock
                                        className={`tlcw-size-icon${thumbSize === 'map' ? ' active' : ''}`}
                                        edit={false}
                                        iconClass='mo-new-icon-map-marked-alt-solid'
                                        onClick={() => {
                                            setListState({
                                                ...listState,
                                                isLoading: true,
                                            })
                                            setThumbSize('map')
                                            setViewType('map')
                                        }}
                                    />
                                </div>
                            )}
                            <IonList>
                                <IonRadioGroup value={mainTabValue}>
                                    {Object.getOwnPropertyNames(filterParamsApiUrl).map((val) => (
                                        <IonItem
                                            key={val}
                                            button
                                            className='tlcw-tab-item'
                                            detail={false}
                                            onClick={() => onSetTabValue(val)}
                                        >
                                            <IonLabel>
                                                {reduxText[filterParamsApiUrl[val].textId || filterParamsOptions[val].textId]}
                                            </IonLabel>
                                            <IonRadio
                                                slot='end'
                                                value={val}
                                            />
                                        </IonItem>
                                    ))}
                                </IonRadioGroup>
                            </IonList>
                        </div>
                    </div>
                    <div className={`tlcw-right ${thumbSize} mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {viewType === 'list'
                            ? (
                                <React.Fragment>
                                    <div className='tlcw-infinite'>
                                        {listState.items.map((item) => {
                                            return (
                                                <ListComponentName
                                                    key={item.id}
                                                    edit={false}
                                                    modalKey={listState.modalKey}
                                                    object={item}
                                                    playerOrigin='searchPage'
                                                />
                                            )
                                        })}
                                    </div>
                                    <InfiniteScrollHelperWeb
                                        active={!listState.disableInfiniteScroll}
                                        endText={endText}
                                        hasMore={listState.hasMore}
                                        isLoading={listState.isLoading}
                                        items={listState.items}
                                        onChange={onSearchNext}
                                    />
                                </React.Fragment>
                            ) : (
                                <div className='tlcw-map-wrap'>
                                    <div className='tlcw-map'>
                                        <Map
                                            boxZoom
                                            center={{ lat: 0, lng: 0 }}
                                            minZoom={1}
                                            preferCanvas
                                            ref={mapRef}
                                            zoom={2}
                                        >
                                            <TileLayer
                                                attribution={leaflet_attribution}
                                                tileSize={512}
                                                url={leaflet_mapbox_url}
                                                zoomOffset={-1}
                                            />
                                            <FeatureGroup ref={groupRef}>
                                                <MarkerClusterGroup
                                                    chunkedLoading
                                                    maxClusterRadius={40}
                                                    onClusterClick={(cluster: any) => handleMarkerClusterClick(cluster)}
                                                    onMarkerClick={(marker: MainMapSerializer) => handleMarkersClick(marker)}
                                                    ref={clusterRef}
                                                    removeOutsideVisibleBounds
                                                />
                                            </FeatureGroup>
                                            <Search
                                                className="tlcw-map-searchbox"
                                                closeResultsOnClick={true}
                                                inputPlaceholder={reduxText[7677]}
                                                position='topleft'
                                                showMarker={false}
                                                zoom={7}
                                            />
                                        </Map>
                                    </div>
                                    <div className={`tlcw-map-items mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                                        {mapItemsType === 'list'
                                            ? (
                                                <React.Fragment>
                                                    {listState.items.map((item) => {
                                                        return (
                                                            <MapListComponentName
                                                                key={item.id}
                                                                edit={false}
                                                                modalKey={listState.modalKey}
                                                                object={item}
                                                                playerOrigin='searchPage'
                                                            />
                                                        )
                                                    })}
                                                    <InfiniteScrollHelperWeb
                                                        active={!listState.disableInfiniteScroll}
                                                        endText={endText}
                                                        hasMore={listState.hasMore}
                                                        isLoading={listState.isLoading}
                                                        items={listState.items}
                                                        onChange={onSearchNext}
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                <div className='tlcw-map-item-detail'>
                                                    {mapItem
                                                        ? (
                                                            <MapDetailComponentName
                                                                edit={false}
                                                                isWeb
                                                                modalKeys={{}}  // TODO
                                                                object={mapItem}
                                                            />
                                                        ) : (
                                                            <ListSpinner isLoading />
                                                        )}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
})
