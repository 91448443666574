// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    DetailContentBlock,
    DetailContentPreview,
    DetailContentTabButton,
} from 'pages'

// serializers
import {
    ContentTypeDetailPanelTypes,
    ContentTypeDetailTypes,
    ContentTypeSettingsGlobalTypes,
    ContentTypeSettingsTypes,
    MainIdNameSerializer,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getModalAbsoluteUrlV2,
    onClickIsModal,
    parseQuery,
} from 'services'

// props
type DetailContentProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    inModal?: boolean
    object: {
        id?: number
        active?: boolean
        content_type_settings_global?: ContentTypeSettingsGlobalTypes
        content_uuid?: string
        get_image_hq?: string
        model_content_type_id?: number
        portfolio_page_preview_urls?: string[]
        profile?: ProfileDetailAdminSerializerV2
        sub_content_type?: MainIdNameSerializer
    }
    refreshData: () => void
}

type Item = {
    id: number;
    title?: string;
    blockList?: any[];
    blockFieldSet?: object;
    blockInLine?: object;
    panelOptions?: object;
};

type IdObj = {
    id: number;
}

// main
export const DetailContent: React.FC<DetailContentProps> = React.memo(({
    contentType,
    contentTypeSettings,
    inModal,
    object,
    refreshData,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const app = contentType.split('_')[0]
    // const model = contentType.split('_')[1]
    const contentTypeSettingsCustom = object.profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]
    const panels: ContentTypeDetailTypes['panels'] = contentTypeSettings.detail.panels.concat(contentTypeSettings?.detail?.panelsOther || [])
    const panelsSubContentType: ContentTypeDetailTypes['panels'] = []
    if (object.sub_content_type && app !== 'setup') {
        if (contentTypeSettingsCustom?.detail?.panelsSubContentType?.[object.sub_content_type.id!]) {
            panelsSubContentType.push(...contentTypeSettingsCustom?.detail?.panelsSubContentType?.[object.sub_content_type.id!]?.panels || [])
        } else {
            panelsSubContentType.push(...contentTypeSettings.detail?.panelsSubContentType?.[object.sub_content_type.id!]?.panels || [])
        }
        // @ts-ignore
        panelsSubContentType.push(panels?.find(obj => obj.title === 'reduxText[9340]'))
    }
    const panelsOrdered = (excludeItemsById(((panelsSubContentType.length > 0 && panelsSubContentType) || contentTypeSettings.detail.panels), contentTypeSettingsCustom?.detail?.panelList).concat(filterItemsById(((panelsSubContentType.length > 0 && panelsSubContentType) || contentTypeSettings.detail.panels).concat(contentTypeSettings?.detail?.panelsOther || []), contentTypeSettingsCustom?.detail?.panelList)))
    const firstTab = panelsOrdered[0].id

    const [tab, setTab] = useState<(number)>(inModal ? Number(parseQuery(location.search)?.modalTab) : Number(parseQuery(location.search)?.tab) || firstTab)

    const tabData = contentTypeSettingsCustom?.detail?.panelAdaptation?.[tab] || panelsSubContentType.find(obj => obj.id === tab) || panels.find(obj => obj.id === tab)

    useEffect(() => {
        if (inModal) {
            setTab(firstTab)
        }
        const parsed = parseQuery(location.search)
        if (inModal) {
            if (parsed.modalTab) {
                setTab(Number(parsed.modalTab))
            } else {
                setTab(firstTab)
            }
        } else {
            if (parsed.tab) {
                setTab(Number(parsed.tab))
            } else {
                setTab(firstTab)
            }
        }
    }, [
        contentType,
        object.id,
        location.search,
    ])

    function filterItemsById(items: ContentTypeDetailPanelTypes[] | undefined, ids: IdObj[] | undefined): ContentTypeDetailPanelTypes[] {
        if (!items || !ids) return []
        return ids.map(idObj => items.find(item => item.id === idObj.id)).filter((item): item is ContentTypeDetailPanelTypes => item !== undefined);
    }

    function excludeItemsById(items: Item[] | undefined, ids: IdObj[] | undefined): Item[] {
        if (!items) return []
        if (!ids) return items
        const idsSet = new Set(ids.map(idObj => idObj.id));
        return items.filter(item => !idsSet.has(item.id));
    }

    return (
        <div className={`detail-component${inModal ? ' in-modal' : ''}`}>
            <div className='dc-tabs'>
                <div className='dc-buttons'>
                    {object.portfolio_page_preview_urls?.length! > 0 && (
                        <Button
                            className={(tab === 1) ? 'dc-active' : ''}
                            // color={title ? undefined : 'danger'}
                            edit={false}
                            fill='clear'
                            justifyText='flex-start'
                            onClick={inModal ? () => setTab(1) : undefined}
                            size='small'
                            text={reduxText[5584]}
                            to={inModal ? undefined : `/detail/${contentType}/${object.id}?tab=1`}
                        />
                    )}
                    {panelsOrdered.map(val => (
                        <DetailContentTabButton
                            key={val.id}
                            contentType={contentType}
                            firstTab={firstTab}
                            inModal={inModal}
                            object={object}
                            // @ts-ignore
                            panel={val}
                            setTab={setTab}
                            tab={tab}
                        />
                    ))}
                    {reduxAuth.settings?.user?.id === 1 && (
                        <>
                            <span className='dc-separator'>| Superuser only: </span>
                            {excludeItemsById(contentTypeSettings.detail.panelsOther, contentTypeSettingsCustom?.detail?.panelList).map(val => (
                                <DetailContentTabButton
                                    key={val.id}
                                    contentType={contentType}
                                    firstTab={firstTab}
                                    inModal={inModal}
                                    object={object}
                                    // @ts-ignore
                                    panel={val}
                                    setTab={setTab}
                                    tab={tab}
                                />
                            ))}
                        </>
                    )}
                </div>
                {reduxAuth.settings?.user?.id === 1 && (
                    <IconBlock
                        edit={false}
                        iconClass='mo-new-icon-globe-solid'
                        onClick={() => onClickIsModal(tab, Math.random(), dispatch)}
                        to={getModalAbsoluteUrlV2({
                            contentType: 'setup_contenttypepanel',
                            objectId: tab,
                            pageType: 'detail',
                        })}
                        tooltipText={reduxText[9373]}
                    />
                )}
            </div>
            {(tab === 1 && object.portfolio_page_preview_urls?.length! > 0)
                ? (
                    <DetailContentPreview object={object} />
                ) : (
                    <DetailContentBlock
                        contentType={contentType}
                        contentTypeSettings={contentTypeSettings}
                        contentTypeSettingsCustom={contentTypeSettingsCustom}
                        inModal={inModal}
                        object={object}
                        refreshData={refreshData}
                        tab={tab}
                        tabData={tabData}
                    />
                )
            }
        </div>
    )
})
