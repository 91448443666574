// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
    IonSearchbar,
} from '@ionic/react'
import axios, {
    CancelTokenSource,
} from 'axios'
import L from 'leaflet'
import {
    FeatureGroup,
    Map,
    TileLayer,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import Search from 'react-leaflet-search'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useLocation,
    useParams,
} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'

// components
import {
    Button,
    IconBlock,
    InfiniteScrollHelperWeb,
    LightboxCustom,
    LinkHelper,
    ListSpinner,
    Loader,
    Popover,
    RatioZarmingHelper,
} from 'components'

// data
import {
    adherentFilterOptions,
    api_url_adherent_detail,
    api_url_block_template_detail,
    api_url_event_detail,
    api_url_material_detail,
    api_url_offer_detail,
    api_url_page_template_detail,
    api_url_customer_helper_kabubu_airtable,
    api_url_customer_helper_loutilenmain_airtable,
    api_url_product_detail,
    api_url_profile_detail_short_slug,
    api_url_profile_id_detail,
    api_url_profile_section_detail,
    api_url_profile_section_map,
    api_url_project_detail,
    api_url_salesorder_detail,
    blockTemplateFilterOptions,
    contributionFilterOptions,
    defaultReduxState,
    eventFilterOptions,
    leaflet_attribution,
    leaflet_mapbox_url,
    materialFilterOptions,
    media_url,
    media_version,
    offerFilterOptions,
    pageTemplateFilterOptions,
    pressFilterOptions,
    productFilterOptions,
    profileTeamFilterOptions,
    projectFilterOptions,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
    resourceFilterOptions,
    salesOrderFilterOptions,
    serviceFilterOptions,
    view_url_adherent_form,
    view_url_event_form,
    view_url_material_form,
    view_url_offer_form,
    view_url_press_form,
    view_url_product_form,
    view_url_profile_detail_old,
    view_url_project_form,
    view_url_resource_form,
    view_url_service_form,
} from 'data'

// pages
import {
    AdherentListHeader,
    AdherentListSmallBlock,
    BlockTemplateListBlock,
    ContributionListHeader,
    ContributionListSmallBlock,
    EventDetailBlockMobile,
    EventListMediumBlockWeb,
    EventListSmallBlockWeb,
    FeedListSmallBlockWeb,
    ImageListBlockWeb,
    MaterialDetailBlockMobile,
    MaterialListMediumBlockWeb,
    MaterialListSmallBlockWeb,
    NotFoundPage,
    OfferDetailBlockMobile,
    OfferListMediumBlockWeb,
    OfferListSmallBlockWeb,
    PageTemplateListBlock,
    PressListMediumBlockWeb,
    PressListSmallBlockWeb,
    ProductDetailBlockMobile,
    ProductListMediumBlockWeb,
    ProductListSmallBlockWeb,
    ProductListStockBlockOld,
    ProductListStockHeader,
    ProfileDetailBlockMobile,
    ProfileDetailListFilter,
    ProfileDetailMenuWeb,
    ProfileListMediumBlockWeb,
    ProfileListSmallBlockWeb,
    ProfileSectionContentOrderingWeb,
    ProjectDetailBlockMobile,
    ProjectListMediumBlockWeb,
    ProjectListSmallBlockWeb,
    SalesOrderListHeader,
    SalesOrderListSmallBlock,
    ServiceListMediumBlock,
    ServiceListSmallBlock,
    TeamListMediumBlockWeb,
    TeamListSmallBlockWeb,
} from 'pages'

// serializers
import {
    MainMapSerializer,
    ProfileDetailShortSlugSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkPermissions,
    getApiUrl,
    getAxiosHeaders,
    getDetailObject,
    getInfiniteListWebNew,
    getMapList,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// props
type MatchParams = {
    listStatus: string
    sectionId: string
    slug: string
}

// main
export const ProfileDetailListWeb: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const listStatus = params.listStatus || 'active'
    const profileSlug = params.slug
    const sectionId = params.sectionId
    const isUncategorized = sectionId.includes('--uncategorized')
    const listApiUrl = `${api_url_profile_section_detail}?section_id=${isUncategorized ? sectionId.split('--uncategorized')[0] : sectionId}&list_status=${listStatus}${isUncategorized ? '&is_uncategorized=true' : ''}`
    const mapApiUrl = `${api_url_profile_section_map}?profile_slug=${profileSlug}&section_id=${isUncategorized ? sectionId.split('--uncategorized')[0] : sectionId}&list_status=active${isUncategorized ? '&is_uncategorized=true' : ''}`

    const componentSettings: any = {
        AdherentListSmallBlock: {
            addFormText: reduxText[8686],
            addFormUrl: `${view_url_adherent_form}new/main/main-panel/`,
            component: AdherentListSmallBlock,
            detailApiUrl: getApiUrl(api_url_adherent_detail, reduxAuth),
            detailComponent: AdherentListSmallBlock,
            filterSet: adherentFilterOptions,
            mapListComponent: AdherentListSmallBlock,
            tabToSkip: ['private', 'unlisted'],
            tableHeaderComponent: AdherentListHeader,
        },
        ContributionListSmallBlock: {
            component: ContributionListSmallBlock,
            detailApiUrl: getApiUrl(api_url_adherent_detail, reduxAuth),
            detailComponent: ContributionListSmallBlock,
            filterSet: contributionFilterOptions,
            mapListComponent: ContributionListSmallBlock,
            tableHeaderComponent: ContributionListHeader,
            tabToSkip: ['private', 'unlisted'],
        },
        BlockTemplateListBlock: {
            component: BlockTemplateListBlock,
            detailApiUrl: getApiUrl(api_url_block_template_detail, reduxAuth),
            detailComponent: BlockTemplateListBlock,
            filterSet: blockTemplateFilterOptions,
            mapListComponent: BlockTemplateListBlock,
        },
        EventListMediumBlockWeb: {
            addFormText: reduxText[8687],
            addFormUrl: `${view_url_event_form}new/main/main-panel/`,
            component: EventListMediumBlockWeb,
            detailApiUrl: getApiUrl(api_url_event_detail, reduxAuth),
            detailComponent: EventDetailBlockMobile,
            filterSet: eventFilterOptions,
            mapListComponent: EventListSmallBlockWeb,
        },
        EventListSmallBlockWeb: {
            addFormText: reduxText[8687],
            addFormUrl: `${view_url_event_form}new/main/main-panel/`,
            component: EventListSmallBlockWeb,
            detailApiUrl: getApiUrl(api_url_event_detail, reduxAuth),
            detailComponent: EventDetailBlockMobile,
            filterSet: eventFilterOptions,
            mapListComponent: EventListSmallBlockWeb,
        },
        FeedListSmallBlockWeb: {
            component: FeedListSmallBlockWeb,
            detailApiUrl: '',
            detailComponent: '',
            filterSet: '',
            mapListComponent: FeedListSmallBlockWeb,
        },
        ImageListBlockWeb: {  // TODO
            component: ImageListBlockWeb,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ImageListBlockWeb,
            mapListComponent: ImageListBlockWeb,
        },
        MaterialListMediumBlockWeb: {
            addFormText: reduxText[8688],
            addFormUrl: `${view_url_material_form}new/main/main-panel/`,
            component: MaterialListMediumBlockWeb,
            detailApiUrl: getApiUrl(api_url_material_detail, reduxAuth),
            detailComponent: MaterialDetailBlockMobile,
            filterSet: materialFilterOptions,
            mapListComponent: MaterialListSmallBlockWeb,
        },
        MaterialListSmallBlockWeb: {
            addFormText: reduxText[8688],
            addFormUrl: `${view_url_material_form}new/main/main-panel/`,
            component: MaterialListSmallBlockWeb,
            detailApiUrl: getApiUrl(api_url_material_detail, reduxAuth),
            detailComponent: MaterialDetailBlockMobile,
            filterSet: materialFilterOptions,
            mapListComponent: MaterialListSmallBlockWeb,
        },
        OfferListMediumBlockWeb: {
            addFormText: reduxText[8689],
            addFormUrl: `${view_url_offer_form}new/main/main-panel/`,
            component: OfferListMediumBlockWeb,
            detailApiUrl: getApiUrl(api_url_offer_detail, reduxAuth),
            detailComponent: OfferDetailBlockMobile,
            filterSet: offerFilterOptions,
            mapListComponent: OfferListSmallBlockWeb,
        },
        OfferListSmallBlockWeb: {
            addFormText: reduxText[8689],
            addFormUrl: `${view_url_offer_form}new/main/main-panel/`,
            component: OfferListSmallBlockWeb,
            detailApiUrl: getApiUrl(api_url_offer_detail, reduxAuth),
            detailComponent: OfferDetailBlockMobile,
            filterSet: offerFilterOptions,
            mapListComponent: OfferListSmallBlockWeb,
        },
        PageTemplateListBlock: {
            component: PageTemplateListBlock,
            detailApiUrl: getApiUrl(api_url_page_template_detail, reduxAuth),
            detailComponent: PageTemplateListBlock,
            filterSet: pageTemplateFilterOptions,
            mapListComponent: PageTemplateListBlock,
        },
        PressListMediumBlockWeb: {
            addFormText: reduxText[5409],
            addFormUrl: `${view_url_press_form}new/main/main-panel/`,
            component: PressListMediumBlockWeb,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            filterSet: pressFilterOptions,
            mapListComponent: PressListSmallBlockWeb,
        },
        PressListSmallBlockWeb: {
            addFormText: reduxText[5409],
            addFormUrl: `${view_url_press_form}new/main/main-panel/`,
            component: PressListSmallBlockWeb,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            filterSet: pressFilterOptions,
            mapListComponent: PressListSmallBlockWeb,
        },
        ProductListMediumBlockWeb: {
            addFormText: reduxText[8690],
            addFormUrl: `${view_url_product_form}new/main/main-panel/`,
            component: ProductListMediumBlockWeb,
            detailApiUrl: getApiUrl(api_url_product_detail, reduxAuth),
            detailComponent: ProductDetailBlockMobile,
            filterSet: productFilterOptions,
            mapListComponent: ProductListSmallBlockWeb,
        },
        ProductListSmallBlockWeb: {
            addFormText: reduxText[8690],
            addFormUrl: `${view_url_product_form}new/main/main-panel/`,
            component: ProductListSmallBlockWeb,
            detailApiUrl: getApiUrl(api_url_product_detail, reduxAuth),
            detailComponent: ProductDetailBlockMobile,
            filterSet: productFilterOptions,
            mapListComponent: ProductListSmallBlockWeb,
        },
        ProductListStockBlock: {
            addFormText: reduxText[8690],
            addFormUrl: `${view_url_product_form}new/main/main-panel/`,
            component: ProductListStockBlockOld,
            detailApiUrl: getApiUrl(api_url_product_detail, reduxAuth),
            detailComponent: ProductDetailBlockMobile,
            filterSet: productFilterOptions,
            mapListComponent: ProductListStockBlockOld,
            tableHeaderComponent: ProductListStockHeader,
        },
        ProfileListMediumBlockWeb: {
            component: ProfileListMediumBlockWeb,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            mapListComponent: ProfileListSmallBlockWeb,
        },
        ProfileListSmallBlockWeb: {
            component: ProfileListSmallBlockWeb,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            mapListComponent: ProfileListSmallBlockWeb,
        },
        ProjectListMediumBlockWeb: {
            addFormText: reduxText[8691],
            addFormUrl: `${view_url_project_form}new/main/main-panel/`,
            component: ProjectListMediumBlockWeb,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            filterSet: projectFilterOptions,
            mapListComponent: ProjectListSmallBlockWeb,
        },
        ProjectListSmallBlockWeb: {
            addFormText: reduxText[8691],
            addFormUrl: `${view_url_project_form}new/main/main-panel/`,
            component: ProjectListSmallBlockWeb,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            filterSet: projectFilterOptions,
            mapListComponent: ProjectListSmallBlockWeb,
        },
        ResourceListMediumBlockWeb: {
            addFormText: reduxText[8692],
            addFormUrl: `${view_url_resource_form}new/main/main-panel/`,
            component: ProjectListMediumBlockWeb,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            filterSet: resourceFilterOptions,
            mapListComponent: ProjectListSmallBlockWeb,
        },
        ResourceListSmallBlockWeb: {
            addFormText: reduxText[8692],
            addFormUrl: `${view_url_resource_form}new/main/main-panel/`,
            component: ProjectListSmallBlockWeb,
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            detailComponent: ProjectDetailBlockMobile,
            filterSet: resourceFilterOptions,
            mapListComponent: ProjectListSmallBlockWeb,
        },
        SalesOrderListSmallBlock: {
            component: SalesOrderListSmallBlock,
            detailApiUrl: getApiUrl(api_url_salesorder_detail, reduxAuth),
            detailComponent: SalesOrderListSmallBlock,
            filterSet: salesOrderFilterOptions,
            mapListComponent: SalesOrderListSmallBlock,
            tableHeaderComponent: SalesOrderListHeader,
            tabToSkip: ['private', 'unlisted'],
        },
        ServiceListMediumBlock: {
            addFormText: reduxText[7671],
            addFormUrl: `${view_url_service_form}new/main/main-panel/`,
            component: ServiceListMediumBlock,
            // detailApiUrl: getApiUrl(api_url_service_detail, reduxAuth),
            // detailComponent: ServiceDetailBlock,
            filterSet: serviceFilterOptions,
            mapListComponent: ServiceListSmallBlock,
        },
        ServiceListSmallBlock: {
            addFormText: reduxText[7671],
            addFormUrl: `${view_url_service_form}new/main/main-panel/`,
            component: ServiceListSmallBlock,
            // detailApiUrl: getApiUrl(api_url_service_detail, reduxAuth),
            // detailComponent: ServiceDetailBlock,
            filterSet: serviceFilterOptions,
            mapListComponent: ServiceListSmallBlock,
        },
        TeamListMediumBlockWeb: {
            component: TeamListMediumBlockWeb,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            filterSet: profileTeamFilterOptions,
            mapListComponent: TeamListSmallBlockWeb,
        },
        TeamListSmallBlockWeb: {
            component: TeamListSmallBlockWeb,
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            detailComponent: ProfileDetailBlockMobile,
            filterSet: profileTeamFilterOptions,
            mapListComponent: TeamListSmallBlockWeb,
        },
    }

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb>({
        ...listStateInitialWeb,
        clusterIds: [],
        modalKey: Math.random(),
    })

    const [detailObject, setDetailObject] = useState<ProfileDetailShortSlugSerializer | undefined>(undefined)
    const [disableReorder, setDisableReorder] = useState(true)
    const [disableReorderChildren, setDisableReorderChildren] = useState(true)
    const [disableReorderChildrenChildren, setDisableReorderChildrenChildren] = useState(true)
    const [errorStatus, setErrorStatus] = useState<number>()
    const [filterSectionSlug, setFilterSectionSlug] = useState<string>(sectionId)
    const [filterSectionsObject, setFilterSectionsObject] = useState<any>({})
    const [imageIndex, setImageIndex] = useState<number>(0)
    const [isImporting, setIsImporting] = useState(false)
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
    const [isOrdering, setIsOrdering] = useState(false)
    const [manageProfileSection, setManageProfileSection] = useState(false)
    const [mapItem, setMapItem] = useState<any>()
    const [mapItemsType, setMapItemsType] = useState<'detail' | 'list'>('list')
    const [showActionPopover, setShowActionPopover] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [showSettingsPopover, setShowSettingsPopover] = useState(false)
    const [thumbSize, setThumbSize] = useState<'big' | 'map' | 'medium' | 'small'>('small')
    const [viewType, setViewType] = useState<'list' | 'map'>('list')

    const clusterRef = useRef(null)
    const groupRef = useRef<FeatureGroup>(null)
    const mapRef = useRef<Map>(null)

    const componentObject = componentSettings[listState.componentString]
    const redMarker = L.icon({
        iconUrl: `${media_url}global/map/marker.svg${media_version}`,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
    })
    const sectionsObject: any = {}
    detailObject?.tabs_left?.map((val) => {
        sectionsObject[val.id!] = {
            content_source: val.content_source,
        }
        sectionsObject[`${val.id}--uncategorized`] = {
            content_source: val.content_source,
            is_other: true,
        }
        val.children_tabs?.map((val2) => {
            sectionsObject[val2.id!] = {
                id: val2.id,
                content_source: val2.content_source,
                is_custom: true,
            }
            val2.children_tabs?.map((val3) => {
                sectionsObject[val3.id!] = {
                    id: val3.id,
                    content_source: val3.content_source,
                    is_custom: true,
                }
            })
        })
    })

    useEffect(() => {
        onGetDetailData()
    }, [
        profileSlug,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        if (detailObject?.id && reduxForm.refreshId.refreshProfile === detailObject.id) {
            onGetDetailData()
        }
    }, [
        reduxForm.refresh.refreshProfile,
    ])

    useEffect(() => {
        setShowFilters(false)
    }, [
        profileSlug,
        reduxAuth.apiRootUrl,
        sectionId,
    ])

    useEffect(() => {
        mainGet()
    }, [
        listStatus,
        profileSlug,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
        sectionId,
        thumbSize,
        viewType,
    ])

    useEffect(() => {
        if (viewType === 'map' && listState.clusterIds?.length! > 0) {
            onGetListData(
                getApiUrl(listApiUrl, reduxAuth),
                true,
            )
        }
    }, [
        listState.clusterIds,
    ])

    useEffect(() => {
        onGetListFinsih()
    }, [
        detailObject,
    ])

    function mainGet() {
        setIsOrdering(false)
        if (viewType === 'map') {
            setListState({
                ...listState,
                items: [],
            })
            onGetMapData(getApiUrl(mapApiUrl, reduxAuth))
        } else {
            setListState({
                ...listState,
                clusterIds: [],
                isLoading: true,
            })
            onGetListData(
                getApiUrl(listApiUrl, reduxAuth),
                true,
            )
        }
    }

    function addMarkers(data: MainMapSerializer[]) {
        try {
            const markers = L.markerClusterGroup()
            let hasMarkers = false
            data.filter(obj => obj.lat !== undefined).map((val) => {
                hasMarkers = true
                const marker = L.marker(
                    [val.lat, val.lng],
                    {
                        icon: redMarker,
                    },
                ).on(
                    'click',
                    () => handleMarkersClick(val),
                )
                // @ts-ignore
                marker.mo_id = val.parent
                markers.addLayer(marker)
                return false
            })
            // @ts-ignore
            clusterRef.current.leafletElement.addLayers(markers)
            if (hasMarkers) handleFitBounds(data)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'addMarkers',
            ))
        }
    }

    function handleFitBounds(data: MainMapSerializer[]) {
        try {
            if (data.length > 0) {
                const map = mapRef.current?.leafletElement
                const group = groupRef.current?.leafletElement
                if (group) map?.fitBounds(group?.getBounds())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'handleFitBounds',
            ))
        }
    }

    function handleMarkersClick(marker: MainMapSerializer) {
        try {
            setMapItem(undefined)
            setMapItemsType('detail')
            getDetailObject({
                apiUrl: `${componentObject?.detailApiUrl}${marker.parent}/`,
                component: 'ProfileDetailListWeb',
                dispatch,
                reduxAuth,
                setDetailObject: setMapItem,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'handleMarkersClick',
            ))
        }
    }

    function handleMarkerClusterClick(cluster: any) {
        setMapItemsType('list')
        const idArray: number[] = []
        cluster.layer.getAllChildMarkers().map((val: any) => {
            idArray.push(Number(val.mo_id))
            return false
        })
        setListState({
            ...listState,
            clusterIds: idArray,
        })
    }

    function onFilterChange(filterString: string) {
        try {
            if (viewType === 'map') {
                setListState({
                    ...listState,
                    items: [],
                })
                let axiosUrl = ''
                if ((mapApiUrl).includes('?')) {
                    axiosUrl = `${mapApiUrl}&${filterString}`
                } else {
                    axiosUrl = `${mapApiUrl}?${filterString}`
                }
                onGetMapData(getApiUrl(axiosUrl, reduxAuth))
            } else {
                setListState({
                    ...listState,
                    clusterIds: [],
                    isLoading: true,
                })
                let axiosUrl = ''
                if ((listApiUrl).includes('?')) {
                    axiosUrl = `${listApiUrl}&${filterString}`
                } else {
                    axiosUrl = `${listApiUrl}?${filterString}`
                }
                onGetListData(
                    getApiUrl(axiosUrl, reduxAuth),
                    true,
                )
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'onSearch',
            ))
        }
    }

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_profile_detail_short_slug}${profileSlug}/?is_left=true`, reduxAuth),
                component: 'ProfileDetailListWeb',
                customFunction: (data) => dispatch(reduxModalSeoData(data.seo_data)),
                dispatch,
                reduxAuth,
                setDetailObject,
                setErrorStatus,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'onGetDetailData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {

            let axiosUrl = ''
            if (changingView) {
                if ((apiUrl).includes('?')) {
                    axiosUrl = `${apiUrl}&block_size=${thumbSize}`
                } else {
                    axiosUrl = `${apiUrl}?block_size=${thumbSize}`
                }
            } else {
                axiosUrl = apiUrl
            }

            getInfiniteListWebNew({
                apiUrl: axiosUrl,
                axiosCancelToken,
                changingView,
                component: 'ProfileDetailListWeb',
                componentName: '',
                dispatch,
                listState,
                noClusterIds: viewType !== 'map',
                onSuccess: onGetListFinsih,
                reduxAuth,
                setAxiosCancelToken,
                setComponent: true,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'getListData',
            ))
        }
    }

    function onGetListFinsih() {
        setFilterSectionSlug(sectionId)
        setFilterSectionsObject(sectionsObject)
    }

    function onGetMapData(
        apiUrl: string,
    ) {
        try {
            if (clusterRef.current) {
                // @ts-ignore
                clusterRef.current.leafletElement.clearLayers()
            }
            getMapList({
                addMarkers,
                apiUrl,
                axiosCancelToken,
                component: 'ProfileDetailListWeb',
                dispatch,
                listState,
                reduxAuth,
                setAxiosCancelToken,
                setClusterIds: true,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'getListData',
            ))
        }
    }

    async function onImport(apiUrl: string) {
        try {
            setIsImporting(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetailListWeb onImport')
            const getUrl = getApiUrl(apiUrl, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    onGetListData(
                        getApiUrl(listApiUrl, reduxAuth),
                        true,
                    )
                    setIsImporting(false)
                })
                .catch((error) => {
                    setIsImporting(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ProfileDetailListWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onImport',
                    })
                })
        } catch (error) {
            setIsImporting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'onImport',
            ))
        }
    }

    function onSearch(searchValue: any) {
        try {
            if (viewType === 'map') {
                setListState({
                    ...listState,
                    items: [],
                })
                let axiosUrl = ''
                if ((mapApiUrl).includes('?')) {
                    axiosUrl = `${mapApiUrl}&search=${searchValue}`
                } else {
                    axiosUrl = `${mapApiUrl}?search=${searchValue}`
                }
                onGetMapData(getApiUrl(axiosUrl, reduxAuth))
            } else {
                setListState({
                    ...listState,
                    clusterIds: [],
                    isLoading: true,
                })
                let axiosUrl = ''
                if ((listApiUrl).includes('?')) {
                    axiosUrl = `${listApiUrl}&search=${searchValue}`
                } else {
                    axiosUrl = `${listApiUrl}?search=${searchValue}`
                }
                onGetListData(
                    getApiUrl(axiosUrl, reduxAuth),
                    true,
                )
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'onSearch',
            ))
        }
    }

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                onSearch(event.target.value)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'onSearchKeyPress',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'onSearchNext',
            ))
        }
    }

    function onSetThumbSize(value: 'big' | 'map' | 'medium' | 'small') {
        try {
            setViewType('list')
            setThumbSize(value)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListWeb',
                'onSetThumbSize',
            ))
        }
    }

    let wto: any

    function onShowFilters() {
        setShowFilters(!showFilters)
        clearTimeout(wto)
        wto = setTimeout(() => {
            const elmnt = document.getElementById(`profile-detail-list-filter-${profileSlug}-${sectionId}`)
            if (process.env.NODE_ENV === 'development') console.log(elmnt)
            if (elmnt) {
                scrollIntoView(elmnt, {
                    align: {
                        left: 0,
                        leftOffset: 0,
                        top: 50,
                        topOffset: 0,
                    },
                })
            }
        }, 100)
    }

    if (errorStatus) {
        return <NotFoundPage statusCode={errorStatus} />
    }
    if (!detailObject) {
        return (
            <Loader isOpen />
        )
    }

    const tabObject = sectionsObject[sectionId]

    const MapDetailComponentName = componentObject?.detailComponent
    const MapListomponentName = componentObject?.mapListComponent
    const ListComponentName = componentObject?.component

    const TableHeaderComponentName = componentObject?.tableHeaderComponent

    const hasPermission = checkPermissions({
        profileId: detailObject.id,
        reduxAuth,
        user: detailObject.user,
    })

    const settingsButtons = []

    if (!disableReorder || !disableReorderChildren || !disableReorderChildrenChildren) {
        settingsButtons.push(
            {
                text: reduxText[8334],
                handler: () => {
                    setDisableReorder(true)
                    setDisableReorderChildren(true)
                    setDisableReorderChildrenChildren(true)
                    setManageProfileSection(false)
                    setShowSettingsPopover(false)
                }
            },
        )
    }
    if (disableReorder) {
        settingsButtons.push(
            {
                text: reduxText[8333],
                handler: () => {
                    setDisableReorder(false)
                    setDisableReorderChildren(true)
                    setDisableReorderChildrenChildren(true)
                    setManageProfileSection(false)
                    setShowSettingsPopover(false)
                }
            },
        )
    }
    if (disableReorderChildren) {
        settingsButtons.push(
            {
                text: reduxText[8481],
                handler: () => {
                    setDisableReorder(true)
                    setDisableReorderChildren(false)
                    setDisableReorderChildrenChildren(true)
                    setManageProfileSection(false)
                    setShowSettingsPopover(false)
                }
            },
        )
    }
    if (disableReorderChildrenChildren) {
        settingsButtons.push(
            {
                text: reduxText[8605],
                handler: () => {
                    setDisableReorder(true)
                    setDisableReorderChildren(true)
                    setDisableReorderChildrenChildren(false)
                    setManageProfileSection(false)
                    setShowSettingsPopover(false)
                }
            },
        )
    }
    settingsButtons.push(
        {
            text: reduxText[8016],
            handler: () => {
                setDisableReorder(true)
                setManageProfileSection(true)
                setShowSettingsPopover(false)
            }
        }
    )

    const extraButtons = [
        {
            text: reduxText[8017],
            handler: () => {
                setIsOrdering(true)
                setShowActionPopover(false)
            }
        },
    ]

    if (sectionId === '47185') {
        extraButtons.push({
            text: reduxText[8615],
            handler: () => {
                onImport(api_url_customer_helper_loutilenmain_airtable)
                setShowActionPopover(false)
            }
        })
    }

    if (sectionId === '49788') {
        extraButtons.push({
            text: reduxText[8615],
            handler: () => {
                onImport(api_url_customer_helper_kabubu_airtable)
                setShowActionPopover(false)
            }
        })
    }

    const isTable = TableHeaderComponentName && viewType === 'list'

    return (
        <IonPage className='profile-detail-list-web navbar-pt'>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className={`pdlw-content ${thumbSize} ${listState.componentString}`}>
                    <div className={`pdlw-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <div className='pdlw-header-left'>
                            <div className='pdlw-profile-wrap'>
                                <LinkHelper
                                    className='pdlw-profile-pic'
                                    edit={false}
                                    to={`${view_url_profile_detail_old}${profileSlug}/`}
                                >
                                    <div className='pdlw-image-link'>
                                        <RatioZarmingHelper
                                            borderRadius='50%'
                                            dominant_color={detailObject.get_dominant_color}
                                            edit={false}
                                            fontSize={12}
                                            height={24}
                                            name={detailObject.name}
                                            notZarma={Boolean(detailObject.get_image_lq)}
                                            src={detailObject.get_image_lq}
                                            to={undefined}
                                        />
                                    </div>
                                </LinkHelper>
                                <LinkHelper
                                    className='pdlw-profile-name mo-clamp-web1'
                                    edit={false}
                                    to={detailObject.get_absolute_url}
                                    title={detailObject.name}
                                >
                                    {detailObject.name}
                                </LinkHelper>
                            </div>
                            <div className='pdlw-thumb-wrap'>
                                <IconBlock
                                    className={`pdlw-size-icon${thumbSize === 'small' ? ' active' : ''}`}
                                    edit={false}
                                    iconClass='mo-new-icon-gallery'
                                    onClick={() => onSetThumbSize('small')}
                                />
                                <IconBlock
                                    className={`pdlw-size-icon${thumbSize === 'medium' ? ' active' : ''}`}
                                    edit={false}
                                    iconClass='mo-new-icon-list'
                                    onClick={() => onSetThumbSize('medium')}
                                />
                                {/* <IconBlock
									className={`pdlw-size-icon${thumbSize === 'big' ? ' active' : ''}`}
									edit={false}
									iconClass='mo-new-icon-expand-solid'
									onClick={() => onSetThumbSize('big')}
								/> */}
                                <IconBlock
                                    className={`pdlw-size-icon${thumbSize === 'map' ? ' active' : ''}`}
                                    edit={false}
                                    iconClass='mo-new-icon-map-marked-alt-solid'
                                    onClick={() => {
                                        setListState({
                                            ...listState,
                                            isLoading: true,
                                        })
                                        setThumbSize('map')
                                        setViewType('map')
                                    }}
                                />
                                {hasPermission && (
                                    <Popover
                                        className='pdlw-size-icon'
                                        buttons={settingsButtons}
                                        edit={false}
                                        isOpen={showSettingsPopover}
                                        setIsOpen={setShowSettingsPopover}
                                    />
                                )}
                            </div>
                        </div>
                        <ProfileDetailMenuWeb
                            disableReorder={disableReorder}
                            disableReorderChildren={disableReorderChildren}
                            disableReorderChildrenChildren={disableReorderChildrenChildren}
                            isLeftTab
                            manageProfileSection={manageProfileSection}
                            object={detailObject}
                        />
                    </div>
                    <div className={`pdlw-right${isTable ? ' table' : ''} mo-hidden-vertical-scrollbar${reduxAuth.isWindows} ${thumbSize}`}>
                        {!isOrdering && (
                            <React.Fragment>
                                {viewType === 'list' && (
                                    <div className='pdlw-header-right'>
                                        <div className='pdlw-thumb-wrap'>
                                            {hasPermission && (
                                                <React.Fragment>
                                                    <NavLink
                                                        className='pdlw-status-link'
                                                        exact
                                                        to={`${view_url_profile_detail_old}${profileSlug}/sections/${sectionId}/all/${location.search}`}
                                                    >
                                                        {reduxText[5445]}
                                                    </NavLink>
                                                    <NavLink
                                                        className='pdlw-status-link'
                                                        exact
                                                        to={`${view_url_profile_detail_old}${profileSlug}/sections/${sectionId}/${location.search}`}
                                                    >
                                                        {reduxText[6632]}
                                                    </NavLink>
                                                    <NavLink
                                                        className='pdlw-status-link'
                                                        exact
                                                        to={`${view_url_profile_detail_old}${profileSlug}/sections/${sectionId}/draft/${location.search}`}
                                                    >
                                                        {reduxText[5251]}
                                                    </NavLink>
                                                    {!componentObject?.tabToSkip?.includes('unlisted') && (
                                                        <NavLink
                                                            className='pdlw-status-link'
                                                            exact
                                                            to={`${view_url_profile_detail_old}${profileSlug}/sections/${sectionId}/unlisted/${location.search}`}
                                                        >
                                                            {reduxText[6579]}
                                                        </NavLink>
                                                    )}
                                                    {!componentObject?.tabToSkip?.includes('private') && (
                                                        <NavLink
                                                            className='pdlw-status-link'
                                                            exact
                                                            to={`${view_url_profile_detail_old}${profileSlug}/sections/${sectionId}/private/${location.search}`}
                                                        >
                                                            {reduxText[8547]}
                                                        </NavLink>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {tabObject?.is_custom && hasPermission && (
                                                <Popover
                                                    className='pdlw-status-settings'
                                                    buttons={extraButtons}
                                                    edit={false}
                                                    isOpen={showActionPopover}
                                                    setIsOpen={setShowActionPopover}
                                                />
                                            )}
                                        </div>
                                        <div className='pdlw-search-wrap'>
                                            {componentObject?.addFormUrl && (
                                                <Button
                                                    edit={false}
                                                    fill='clear'
                                                    size='small'
                                                    text={componentObject.addFormText}
                                                    to={componentObject.addFormUrl}
                                                />
                                            )}
                                            <IonSearchbar
                                                className='pdlw-searchbar'
                                                onKeyPress={(e) => onSearchKeyPress(e)}
                                                placeholder={reduxText[8335]}
                                                ref={searchBarRef}
                                            />
                                            <IconBlock
                                                className='pdlw-icon'
                                                edit={false}
                                                iconClass='mo-new-icon-search-solid'
                                                onClick={() => { if (searchBarRef.current) onSearch(searchBarRef.current.value) }}
                                            />
                                            {componentObject?.filterSet && (
                                                <IconBlock
                                                    className='pdlw-icon'
                                                    edit={false}
                                                    iconClass='mo-new-icon-filter-solid'
                                                    onClick={onShowFilters}
                                                    tooltipText={reduxText[8336]}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                {viewType === 'list' && showFilters && componentObject?.filterSet && (
                                    <div className='pdlw-filter-wrap'>
                                        <ProfileDetailListFilter
                                            filterSet={componentObject.filterSet}
                                            hasPermission={hasPermission}
                                            listStatus={listStatus}
                                            onChange={(e: string) => onFilterChange(e)}
                                            profileSlug={profileSlug}
                                            sectionId={filterSectionSlug}
                                            sectionsObject={filterSectionsObject}
                                        />
                                    </div>
                                )}
                                {!TableHeaderComponentName && viewType === 'list' && hasPermission && listState.count !== undefined && (
                                    <div className={`pdlw-count-wrap${TableHeaderComponentName ? ' table' : ''}`}>
                                        <p>{listState.count} {reduxText[8337]}</p>
                                    </div>
                                )}
                                {isTable && (
                                    <div className='pdlw-infinite-table mo-hidden-horizontal-scrollbar'>
                                        <table>
                                            <thead>
                                                {<TableHeaderComponentName
                                                    count={hasPermission ? listState.count : undefined}
                                                    object={listState.items?.[0]}
                                                />}
                                            </thead>
                                            <tbody>
                                                {listState.items.map((item) => {
                                                    return (
                                                        <ListComponentName
                                                            key={item.id}
                                                            edit={false}
                                                            modalKey={listState.modalKey}
                                                            object={item}
                                                        />
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <InfiniteScrollHelperWeb
                                            active={!listState.disableInfiniteScroll}
                                            hasMore={listState.hasMore}
                                            isLoading={listState.isLoading}
                                            items={listState.items}
                                            onChange={onSearchNext}
                                        />
                                    </div>
                                )}
                                {!TableHeaderComponentName && viewType === 'list' && (
                                    <React.Fragment>
                                        <div className='pdlw-infinite'>
                                            {listState.items.map((item) => {
                                                return (
                                                    <ListComponentName
                                                        key={item.id}
                                                        edit={false}
                                                        isInProfileSlug={profileSlug}
                                                        modalKey={listState.modalKey}
                                                        object={item}
                                                        playerOrigin='profileDetailList'
                                                    />
                                                )
                                            })}
                                        </div>
                                        <InfiniteScrollHelperWeb
                                            active={!listState.disableInfiniteScroll}
                                            hasMore={listState.hasMore}
                                            isLoading={listState.isLoading}
                                            items={listState.items}
                                            onChange={onSearchNext}
                                        />
                                    </React.Fragment>
                                )}
                                {viewType === 'map' && (
                                    <div className='pdlw-map-wrap'>
                                        <div className='pdlw-map'>
                                            <Map
                                                boxZoom
                                                center={{ lat: 0, lng: 0 }}
                                                minZoom={1}
                                                preferCanvas
                                                ref={mapRef}
                                                zoom={2}
                                            >
                                                <TileLayer
                                                    attribution={leaflet_attribution}
                                                    tileSize={512}
                                                    url={leaflet_mapbox_url}
                                                    zoomOffset={-1}
                                                />
                                                <FeatureGroup ref={groupRef}>
                                                    <MarkerClusterGroup
                                                        chunkedLoading
                                                        maxClusterRadius={40}
                                                        onClusterClick={(cluster: any) => handleMarkerClusterClick(cluster)}
                                                        onMarkerClick={(marker: MainMapSerializer) => handleMarkersClick(marker)}
                                                        ref={clusterRef}
                                                        removeOutsideVisibleBounds
                                                    />
                                                </FeatureGroup>
                                                <Search
                                                    className="pdlw-map-searchbox"
                                                    closeResultsOnClick={true}
                                                    inputPlaceholder={reduxText[7677]}
                                                    position='topleft'
                                                    showMarker={false}
                                                    zoom={7}
                                                />
                                            </Map>
                                        </div>
                                        <div className={`pdlw-map-items mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                                            {mapItemsType === 'detail' && (
                                                <div className='pdlw-map-item-detail'>
                                                    {mapItem
                                                        ? (
                                                            <MapDetailComponentName
                                                                edit={false}
                                                                isWeb
                                                                modalKeys={{}}
                                                                object={mapItem}
                                                            />
                                                        ) : (
                                                            <ListSpinner isLoading />
                                                        )}
                                                </div>
                                            )}
                                            {mapItemsType === 'list' && (
                                                <React.Fragment>
                                                    {listState.items.map((item) => {
                                                        return (
                                                            <MapListomponentName
                                                                key={item.id}
                                                                edit={false}
                                                                modalKey={listState.modalKey}
                                                                object={item}
                                                                playerOrigin='searchPage'
                                                            />
                                                        )
                                                    })}
                                                    <InfiniteScrollHelperWeb
                                                        active={!listState.disableInfiniteScroll}
                                                        hasMore={listState.hasMore}
                                                        isLoading={listState.isLoading}
                                                        items={listState.items}
                                                        onChange={onSearchNext}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        {isOrdering && (
                            <ProfileSectionContentOrderingWeb
                                content_source={tabObject?.content_source}
                                onDone={() => mainGet()}
                                profileSlug={profileSlug}
                                sectionId={sectionId}
                            />
                        )}
                    </div>
                </div>
                {isLightBoxOpen && tabObject?.is_gallery && (
                    <LightboxCustom
                        mainSrc={listState.items[imageIndex].get_image_hq || ''}
                        nextSrc={listState.items[(imageIndex + 1) % listState.items.length].get_image_hq}
                        onCloseRequest={() => setIsLightBoxOpen(false)}
                        onMoveNextRequest={() => listState.items && setImageIndex((imageIndex + 1) % listState.items.length)}
                        onMovePrevRequest={() => listState.items && setImageIndex((imageIndex + listState.items.length - 1) % listState.items.length)}
                        prevSrc={listState.items[(imageIndex + listState.items.length - 1) % listState.items.length].get_image_hq}
                    />
                )}
                {isImporting && (
                    <Loader
                        isOpen
                        message={reduxText[8616]}
                    />
                )}
            </IonContent>
        </IonPage>
    )
}
