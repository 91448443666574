// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    HeaderChildren,
    InfiniteScrollHelperWeb,
} from 'components'

// data
import {
    api_url_profile_detail_short_slug,
    api_url_profile_portfolio_list,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
} from 'data'

// pages
import {
    NotFoundPage,
    PortfolioListBlock,
    ProfileDetailMenuWeb,
} from 'pages'

// serializers
import {
    ProfileDetailShortSlugSerializer,
    PortfolioListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getInfiniteListWebNew,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// props
type MatchParams = {
    slug: string
}

// main
export const ProfileDetailPorfolioWeb: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const profileSlug = params.slug
    const listApiUrl = `${api_url_profile_portfolio_list}${profileSlug}/`

    useEffect(() => {
        onGetDetailData()
    }, [
        profileSlug,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        onGetListData(
            getApiUrl(listApiUrl, reduxAuth),
            true,
        )
    }, [
        profileSlug,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb & { items: PortfolioListSerializer[] }>({
        ...listStateInitialWeb,
        modalKey: Math.random(),
    })

    const [detailObject, setDetailObject] = useState<ProfileDetailShortSlugSerializer | undefined>(undefined)
    const [errorStatus, setErrorStatus] = useState<number>()

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_profile_detail_short_slug}${profileSlug}/`, reduxAuth),
                component: 'ProfileDetailPorfolioWeb',
                customFunction: (data) => dispatch(reduxModalSeoData(data.seo_data)),
                dispatch,
                reduxAuth,
                setDetailObject,
                setErrorStatus,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailPorfolioWeb',
                'onGetDetailData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWebNew({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'ProfileDetailPorfolioWeb',
                componentName: '',
                dispatch,
                listState,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailPorfolioWeb',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailPorfolioWeb',
                'onSearchNext',
            ))
        }
    }

    if (errorStatus) {
        return <NotFoundPage statusCode={errorStatus} />
    }
    return (
        <IonPage className='profile-detail-portfolio-web'>
            <HeaderChildren>
                <div className='hcw-header-children-left'>
                    {detailObject && (
                        <ProfileDetailMenuWeb object={detailObject} />
                    )}
                </div>
            </HeaderChildren>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='pdpw-content'>
                    <p className='pdpw-title'>{reduxText[6632]}</p>
                    <div className='pdpw-infinite'>
                        {listState.items.map((item) => {
                            if (item.active) {
                                return (
                                    <PortfolioListBlock
                                        key={item.id}
                                        edit={false}
                                        modalKey={listState.modalKey}
                                        object={item}
                                        siteName
                                    />
                                )
                            }
                            return false
                        })}
                    </div>
                    <p className='pdpw-title'>{reduxText[2674]}</p>
                    <div className='pdpw-infinite'>
                        {listState.items.map((item) => {
                            if (!item.active) {
                                return (
                                    <PortfolioListBlock
                                        key={item.id}
                                        edit={false}
                                        modalKey={listState.modalKey}
                                        object={item}
                                        siteName
                                    />
                                )
                            }
                            return false
                        })}
                    </div>
                    <InfiniteScrollHelperWeb
                        active={!listState.disableInfiniteScroll}
                        hasMore={listState.hasMore}
                        isLoading={listState.isLoading}
                        items={listState.items}
                        onChange={onSearchNext}
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}
