// react components
import React from 'react'
import {
    IonAvatar,
} from '@ionic/react'

// components
import {
    ImageHelper,
    LinkHelper,
} from 'components'

// serializers
import {
    ProfileShortInfoSerializer,
} from 'serializers/web'

// props
type ProfileShortInfoMobileProps = {
    edit: boolean
    name?: string
    object?: ProfileShortInfoSerializer | false
}

// main
export const ProfileShortInfoMobile: React.FC<ProfileShortInfoMobileProps> = React.memo(({
    edit,
    name,
    object,
}) => {
    return (
        <div className='profile-short-info'>
            <div className='psi-alfa1'>
                {object && (
                    <LinkHelper
                        edit={edit}
                        to={object.get_absolute_url}
                    >
                        <IonAvatar className='psi-bravo1'>
                            <ImageHelper
                                alt={object.name || name}
                                dominant_color={undefined}
                                src={object.get_image_xs}
                            />
                        </IonAvatar>
                    </LinkHelper>
                )}
            </div>
            <div className='psi-alfa2'>
                <LinkHelper
                    edit={edit}
                    to={undefined}
                >
                    <p className='psi-bravo1'>{(object && object.name) || name}</p>
                </LinkHelper>
            </div>
        </div>
    )
})
