// react components
import React from 'react'
import moment from 'moment'

// data
import {
    reduxAuthFetchSettings,
    reduxAuthState,
} from 'data'

// services
import {
    setLastLogin,
} from 'services'

// main
export function getAxiosHeaders(reduxAuth: reduxAuthState, dispatch: React.Dispatch<any>, getOrigin: string) {
    if (reduxAuth.authenticated && (!reduxAuth.settings?.user?.last_login || (moment(reduxAuth.settings?.user?.last_login).add(5, 'minutes').utc().format() < moment.utc().format()))) {
        setLastLogin({
            component: `getAxiosHeaders ${getOrigin}`,
            dispatch,
            reduxAuth,
        })
    }
    return reduxAuth.axiosConfig?.headers
}

export async function reduxAuthFetchSettingsHelper(reduxAuth: reduxAuthState, dispatch: React.Dispatch<any>) {
    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'reduxAuthFetchSettingsHelper')
    if (refreshAxiosHeaders) dispatch(reduxAuthFetchSettings(reduxAuth, refreshAxiosHeaders, dispatch))
}
