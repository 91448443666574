// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DateTimeInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventDetailFormSerializer,
    EventDetailFormSerializerError,
} from 'serializers/web'

// props
type EndDateEventInputProps = {
    errors: EventDetailFormSerializerError | undefined
    fields: EventDetailFormSerializer
    onChange: any
}

// main
export const EndDateEventInput: React.FC<EndDateEventInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <DateTimeInput
            error={errors?.end_date}
            format={fields.date_only ? 'date' : 'datetime'}
            formatPreviewOnly={fields.date_only}
            label={reduxText[329]}
            minDate={fields.start_date}
            name='end_date'
            onChange={onChange}
            optional
            value={fields.end_date || null}
        />
    )
})
