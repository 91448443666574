// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    contentSourceType,
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    view_url_adherent_form,
    view_url_block_template_form,
    view_url_contribution_form,
    view_url_event_form,
    view_url_material_form,
    view_url_offer_form,
    view_url_press_form,
    view_url_product_form,
    view_url_profile_form,
    view_url_project_form,
    view_url_resource_form,
    view_url_service_form,
} from 'data'

// props
type EditButtonProps = {
    model: contentTypeModelType
    object: {
        id?: number
        project_type?: { id: string, name: string }
        owner_info?: {
            permission?: boolean
        }
    }
    refreshData?: () => void
}

// main
export const EditButton: React.FC<EditButtonProps> = React.memo(({
    model,
    object,
    refreshData,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    // @ts-ignore
    let contentSource: contentSourceType = model
    const project_type = object.project_type?.id || object.project_type
    if (project_type) {
        if (project_type === '1') {
            contentSource = 'artwork'
        } else if (project_type === '2') {
            contentSource = 'press'
        } else if (project_type === '3') {
            contentSource = 'resource'
        }
    }

    const dict: any = {
        adherent: {
            formUrl: view_url_adherent_form,
            formComponent: 'AdherentForm',
        },
        artwork: {
            formUrl: view_url_project_form,
            formComponent: 'ProjectForm',
        },
        blocktemplate: {
            formUrl: view_url_block_template_form,
            formComponent: 'BlockTemplateForm',
        },
        contribution: {
            formUrl: view_url_contribution_form,
            formComponent: 'ContributionForm',
        },
        event: {
            formUrl: view_url_event_form,
            formComponent: 'EventForm',
        },
        material: {
            formUrl: view_url_material_form,
            formComponent: 'MaterialForm',
        },
        offer: {
            formUrl: view_url_offer_form,
            formComponent: 'OfferForm',
        },
        // participant: {
        //     formUrl: view_url_participant_form,
        //     formComponent: 'ParticipantForm',
        // },
        press: {
            formUrl: view_url_press_form,
            formComponent: 'ProjectForm',
        },
        product: {
            formUrl: view_url_product_form,
            formComponent: 'ProductForm',
        },
        profile: {
            formUrl: view_url_profile_form,
            formComponent: 'ProfileForm',
        },
        resource: {
            formUrl: view_url_resource_form,
            formComponent: 'ProjectForm',
        },
        service: {
            formUrl: view_url_service_form,
            formComponent: 'ServiceForm',
        },
    }

    if (!object.owner_info?.permission) return null
    if (!dict[contentSource]) return null

    function onShowModalForm() {
        try {
            dispatch(reduxModalMainFormShow({
                cssClass: 'main-form-container-modal-web',
                formComponent: dict[contentSource].formComponent,
                objectId: object.id!,
                refreshData,
            }))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EditButton',
                'onShowModalForm',
            ))
        }
    }

    return (
        <Button
            edit={false}
            fill='clear'
            size='small'
            text={refreshData ? reduxText[8326] : reduxText[5879]}
            to={refreshData ? undefined : `${dict[contentSource].formUrl}${object.id}/`}
            onClick={refreshData ? onShowModalForm : undefined}
        />
    )
})
