// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type StyleDefaultInputPortfolioProps = {
    adminOnly?: boolean
    canHideInput?: boolean
    disabled?: boolean
    error: string | undefined
    helperText: string
    label: string
    labelOriginal: string
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    staffOnly?: boolean
    value: string | number
    value_LG?: string
    value_MD?: string
    value_type: 'LG' | 'MD' | 'SM'
}

// main
export const StyleDefaultInputPortfolio: React.FC<StyleDefaultInputPortfolioProps> = React.memo(({
    adminOnly,
    canHideInput,
    disabled,
    error,
    helperText,
    label,
    labelOriginal,
    name,
    onChange,
    optional,
    staffOnly,
    value,
    value_LG,
    value_MD,
    value_type,
}) => {

    const dispatch = useDispatch()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)

    useEffect(() => {
        getValue()
    }, [value])

    useEffect(() => {
        setHiddenInput(reduxFormSite.collapseStyles!)
    }, [
        reduxFormSite.collapseStyles,
    ])

    const [hiddenInput, setHiddenInput] = useState(false)
    // const [inherit, setInherit] = useState<boolean>((value_type === 'SM' && !value) || (value_type === 'MD' && !value))
    const [formValue, setFormValue] = useState<any>(value)

    function onInputChange(newValue: string | null | undefined) {
        try {
            if (newValue !== formValue) {
                setFormValue(value)
                onChange({
                    name: name,
                    value: newValue,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StyleDefaultInputPortfolio',
                'onInputChange',
            ))
        }
    }

    function getValue() {
        if (value) {
            setFormValue(value)
        } else {
            setFormValue('')
        }
    }

    return (
        <div className={`mo-text-input-webnew ${hiddenInput ? ' hidden-input' : ''}`}>
            <MainFormLabel
                adminOnly={adminOnly}
                helperText={helperText}
                hiddenInput={hiddenInput}
                label={label}
                labelOriginal={labelOriginal}
                name={name}
                optional={optional}
                setHiddenInput={canHideInput ? () => setHiddenInput(!hiddenInput) : undefined}
                staffOnly={staffOnly}
                value={value}
            />
            {!hiddenInput && (
                <input
                    className={`mo-text-input-web-inputnew${disabled ? ' disabled' : ''}`}
                    disabled={disabled}
                    name={name}
                    onChange={(e) => onInputChange(e.target.value)}
                    type='text'
                    value={formValue}
                />
            )}
            <ErrorHelper error={error} />
        </div>
    )
})
