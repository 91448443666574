// react components
import React from 'react'

// props
type CheckboxProps = {
    checked: boolean
    onChange: (e: any) => void
}

// main
export const Checkbox: React.FC<CheckboxProps> = React.memo(({
    checked,
    onChange,
}) => {
    return (
        <input
            checked={checked}
            className='mo-checkbox'
            onChange={onChange}
            type='checkbox'
        />
    )
})
