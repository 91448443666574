// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// containers
import {
    MainFormContainerNew,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ContributionFormState,
    MainContributionPanel,
    MenuContributionPanel,
} from 'pages'

// serializers
import {
    ContributionFormType,
} from 'serializers/web'

// services
import {
    getDetailDataNew,
    getInputValue,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type LocationParams = {
    formRefresh?: number
}

type ContributionFormProps = {
    objectId?: number
    onRefresh?: () => void
}

// main
export const ContributionForm: React.FC<ContributionFormProps> = React.memo(({
    objectId,
    onRefresh,
}) => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<ContributionFormType>({
        contribution: ContributionFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailDataNew({
            axiosCancelToken,
            component: 'ContributionForm',
            dispatch,
            formInfo: formState.contribution,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: objectId ? `${objectId}` : params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        objectId,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    function setInitialState() {
        const contribution = ContributionFormState(reduxAuth)
        setFormState({
            contribution: {
                ...contribution,
                fields: {
                    profile: !reduxAuth.settings?.user?.is_staff ? reduxAuth.settings?.active_profile : undefined,
                },
            },
        })
    }

    function handleInputChange(event: any, formInfo: any) {
        try {
            setFormState({
                ...formState,
                [formInfo.name]: getInputValue({
                    event,
                    formInfo,
                    reduxText,
                })
            })
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                init: true,
                mainError: '',
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContributionForm',
                'handleInputChange',
            ))
        }
    }

    const fieldsMother = formState.contribution.fields

    const panelValue = params.panelValue
    const panelText: any = {
        'delete-panel': reduxText[2983],
        'main-panel': reduxText[347],
    }

    return (
        <MainFormContainerNew
            formInfo={formState.contribution}
            formState={formState}
            headerTitle={panelValue ? panelText[panelValue] : ''}
            helpers={helpers}
            onRefresh={onRefresh}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {onRefresh && fieldsMother?.id && (
                <React.Fragment>
                    <p className='mfcmw-custom-edit-title'>{panelText['main-panel']}</p>
                    <MainContributionPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                </React.Fragment>
            )}
            {!onRefresh && (
                <React.Fragment>
                    {panelValue === 'main-panel' && (
                        <MainContributionPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {!panelValue && (
                        <MenuContributionPanel
                            formState={formState}
                            helpers={helpers}
                            panelText={panelText}
                            setHelpers={setHelpers}
                        />
                    )}
                </React.Fragment>
            )}
        </MainFormContainerNew>
    )
})
