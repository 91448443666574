// react components
import React from 'react'
import axios from 'axios'

// data
import {
    api_url_auth_settings_form_profile_update,
    reduxAuthState,
    reduxModalErrorEventHandler,
} from 'data'


// services
import {
    axiosErrorHandler,
    reduxAuthFetchSettingsHelper,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export async function switchProfile({
    component,
    dispatch,
    history,
    pathTo,
    profileId,
    reduxAuth,
    setSwitchProfileIsOpen,
}: {
    component: string
    dispatch: React.Dispatch<any>
    history: any
    pathTo: string
    profileId: number
    reduxAuth: reduxAuthState
    setSwitchProfileIsOpen: React.Dispatch<boolean>
}) {
    try {
        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} switchProfile`)
        const patchUrl = getApiUrl(`${api_url_auth_settings_form_profile_update}${reduxAuth.settings?.id}/`, reduxAuth)
        const formData = new FormData()
        formData.append('active_profile', profileId.toString())
        axios({
            data: formData,
            headers: refreshAxiosHeaders,
            method: 'patch',
            url: patchUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                setSwitchProfileIsOpen(false)
                history.push(pathTo)
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') console.log(error)
                axiosErrorHandler({
                    apiUrl: patchUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'switchProfile',
                })
            })
    } catch (error) {
        dispatch(reduxModalErrorEventHandler(
            error,
            component,
            'switchProfile',
        ))
    }
}
