// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ErrorHelper,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    FormFieldInput,
    FormLabel,
} from 'pages'

// serializers
import {
    ContentTypeFormBlockFieldSetTypes,
    ContentTypeFormTypes,
    ContentTypeSettingsTypes,
    ContentTypeSettingsCustomTypes,
} from 'serializers/web'

// services
import {
    getApiUrlV2,
    getReduxTextId,
} from 'services'

// props
type FormFieldSetProps = {
    blockId: number
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    errors: ContentTypeFormTypes['data']['errors']
    fieldSetObject: ContentTypeFormBlockFieldSetTypes
    getMetaIsLoading?: boolean
    object: ContentTypeFormTypes['data']['fields']
    onChange: any
    onKeyPress: any
    verticalStyle?: boolean
}

// main
export const FormFieldSet: React.FC<FormFieldSetProps> = React.memo(({
    blockId,
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    errors,
    fieldSetObject,
    getMetaIsLoading,
    object,
    onChange,
    onKeyPress,
    verticalStyle,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    let fieldSetObjectFields = fieldSetObject.fields
    if (contentType === 'staff_staffmessage' && reduxAuth.settings?.user?.is_staff) {
        fieldSetObjectFields = contentTypeSettings.form?.structure?.panels?.find(obj => obj.id === 240)?.blockFieldSet?.[231]?.fields || []
    }

    if ([
        'extra_custom',
        'extra_main',
        'meta_helper',
        // 'meta_image',
        'meta_json',
        'meta_privacy',
        'meta_rel',
    ].includes(fieldSetObject.blockTypeOther)) {
        fieldSetObjectFields = fieldSetObjectFields.sort((a, b) => {
            const order = [
                'sub_content_type',
                'module_views',
                'module_view',
            ]

            // Check if either value is in the predefined order
            const indexA = order.indexOf(a)
            const indexB = order.indexOf(b)

            // If both are in the order, sort based on their position in the order array
            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB
            }

            // If only one is in the order, prioritize that one
            if (indexA !== -1) return -1
            if (indexB !== -1) return 1

            // Otherwise, sort alphabetically
            return a.localeCompare(b)
        })
    }

    const app = contentType.split('_')[0]
    const model = contentType.split('_')[1]

    const translationFormInputDict: any = {
        CharField: 'text',
        ImageField: 'image',
        JSONField: 'address_json',
        RichTextField: 'richText',
        TextField: 'textArea',
        URLField: 'text',
        // image_crop: 'image_crop',  // TODO
        // pdf: 'pdf',  // TODO
    }

    return (
        <div className={`form-component-field-set ${blockId}`}>
            <div className='fcfs-fieldsets'>
                {fieldSetObject.title && (
                    <div className='fcfs-wrap-title'>
                        <p className='fcfs-title'>{getReduxTextId(fieldSetObject.title, reduxText)}</p>
                    </div>
                )}
                <div className='fcfs-fields'>
                    {fieldSetObjectFields.map((val) => {
                        const fieldModelFieldSettings = contentTypeSettings.field?.[val]
                        let fieldsOptionsSettings = undefined
                        fieldsOptionsSettings = {
                            ...contentTypeSettings.form.fieldsOptions?.[val],
                            ...contentTypeSettingsCustom?.form?.fieldsOptions?.[val],
                        }
                        fieldsOptionsSettings.selectUrlParams = {
                            ...contentTypeSettings.form.fieldsOptions?.[val]?.selectUrlParams,
                            ...contentTypeSettingsCustom?.form?.fieldsOptions?.[val]?.selectUrlParams,
                        }
                        if (!fieldModelFieldSettings) return (
                            <p className='mo-error-color'>Error: Missing fieldModelFieldSettings for {val}</p>
                        )
                        const fieldIsDisabled = (fieldsOptionsSettings?.disableIfNotStaff && !reduxAuth.settings?.user?.is_staff)
                            || (fieldsOptionsSettings?.disableIfNotSuperuser && reduxAuth.settings?.user?.id !== 1)
                            || (fieldsOptionsSettings?.disableIfId && object.id)
                            || fieldsOptionsSettings?.disabled
                        if (fieldsOptionsSettings?.hideIfNotStaff && !reduxAuth.settings?.user?.is_staff) return null
                        if (fieldsOptionsSettings?.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) return null
                        const name = contentTypeSettingsCustom?.field?.[val]?.verboseName || contentTypeSettings.field?.[val]?.verboseName!
                        const label = getReduxTextId(name!, reduxText) || val
                        let staffHideIndication = ''
                        let staffDisableIndication = ''
                        if (reduxAuth.settings?.user?.is_staff) {
                            if (fieldsOptionsSettings?.disableIfNotStaff) {
                                staffDisableIndication = 'Enabled only for staff'
                            }
                            if (fieldsOptionsSettings?.disableIfNotSuperuser) {
                                staffDisableIndication = 'Enabled only for superuser'
                            }
                            if (fieldsOptionsSettings?.hideIfNotStaff) {
                                staffHideIndication = 'Showing only for staff'
                            }
                            if (fieldsOptionsSettings?.hideIfNotSuperuser) {
                                staffHideIndication = 'Superuser'
                            }
                        }
                        let translationFormInput = undefined
                        let translationDefaultValue = undefined
                        if (object.portfolio_is_multi_language) {
                            if (val === 'pdf_file') {
                                translationFormInput = 'pdf'
                                translationDefaultValue = object[val]
                            } else if (object[`${val}_is_rich`]) {
                                translationFormInput = 'richText'
                                translationDefaultValue = object[`${val}_rich`]
                            } else if (typeof object[`${val}_original`] !== 'undefined') {
                                translationFormInput = 'image_crop'
                                translationDefaultValue = object[val]
                            } else {
                                translationFormInput = translationFormInputDict[fieldModelFieldSettings.fieldType]
                                translationDefaultValue = object[val]
                            }
                        }
                        return (
                            <div
                                key={val}
                                className={`fcfs-input${fieldIsDisabled ? ' disabled' : ''}`}
                                style={verticalStyle ? { flexDirection: 'column' } : {}}
                            >
                                <div
                                    className='fcfs-label-wrap'
                                    style={verticalStyle ? { marginBottom: '10px', width: 'auto' } : {}}
                                >
                                    <FormLabel
                                        formInput={translationFormInput}
                                        label={label}
                                        name={val}
                                        translationDefaultValue={translationDefaultValue}
                                        translation={translationFormInput ? {
                                            contentType,
                                            defaultDetailUrl: getApiUrlV2(`${app}/form/${model}/detail/`, reduxAuth),
                                            defaultLanguage: object.translation_default_language,
                                            defaultUpdateUrl: getApiUrlV2(`${app}/form/${model}/update/`, reduxAuth),
                                            objectId: object.id,
                                            profileId: object.profile?.id,
                                        } : undefined}
                                    />
                                    {staffHideIndication && (
                                        <p className='fcfs-staff'>{staffHideIndication}</p>
                                    )}
                                    {staffDisableIndication && (
                                        <p className='fcfs-staff'>{staffDisableIndication}</p>
                                    )}
                                </div>
                                <div className='fcfs-input-wrap'>
                                    <FormFieldInput
                                        contentType={contentType}
                                        contentTypeSettings={contentTypeSettings}
                                        contentTypeSettingsCustom={contentTypeSettingsCustom}
                                        disabled={fieldIsDisabled}
                                        errors={errors}
                                        field={val}
                                        fieldType={fieldModelFieldSettings.fieldType}
                                        fieldsOptionsSettings={fieldsOptionsSettings}
                                        getMetaIsLoading={getMetaIsLoading}
                                        label={label}
                                        object={object}
                                        onChange={onChange}
                                        onKeyPress={onKeyPress}
                                    />
                                    <ErrorHelper error={errors?.[val]} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="fcfs-actions">
                {/* {reduxAuth.settings?.user?.id === 1 && (
                    <IconBlock
                        edit={false}
                        iconClass='mo-new-icon-globe-solid'
                        onClick={() => onClickIsModal(Number(blockId), Math.random(), dispatch)}
                        to={getModalAbsoluteUrlV2('setup_contenttypepanelblock', Number(blockId), 'detail')}
                        tooltipText={reduxText[9372]}
                    />
                )} */}
            </div>
        </div>
    )
})
