// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    MainHashtag,
    RatioHelper,
} from 'components'

// data
import {
    defaultReduxState,
    media_url_country_flag,
    media_version,
    reduxModalContentDetailHide,
    searchTabType,
    view_url_search,
} from 'data'

// serializers
import {
    MainCitySerializer,
} from 'serializers/web'

// props
type MainCountryFlagProps = {
    cities?: MainCitySerializer[]
    edit: boolean
    namePosition?: 'start' | 'end'
    object?: {
        id?: number
        country?: string
        countryCode?: string
        countryName?: string
        name?: string
    }
    searchTab?: searchTabType | undefined
}

// main
export const MainCountryFlag: React.FC<MainCountryFlagProps> = React.memo(({
    cities,
    edit,
    namePosition,
    object,
    searchTab,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxModalcontentDetail = useSelector((state: defaultReduxState) => state.reduxModal.contentDetail)

    function onTagClick() {
        if (!edit && object?.id && searchTab) {
            if (reduxModalcontentDetail.isOpen) dispatch(reduxModalContentDetailHide())
            history.push(`${view_url_search}?search=${object.name}#tab=${searchTab}`)
        }
    }

    if (object) {
        let countryFlag = ''
        if (object.countryCode) {
            countryFlag = `${media_url_country_flag}${object.countryCode.toLowerCase()}.svg${media_version}`
        } else if (object.country && object.country.length === 2) {
            countryFlag = `${media_url_country_flag}${object.country.toLowerCase()}.svg${media_version}`
        }
        return (
            <div
                className={`main-country-flag-web${countryFlag ? '' : ' no-flag'}${edit ? ' edit' : ''}${searchTab ? '' : ' noclick'}`}
                onClick={() => onTagClick()}
            >
                <div
                    className='mcfw-country-wrap'
                    onClick={() => onTagClick()}
                >
                    {namePosition === 'start' && <p className='mcfw-name start'>{object.name || object.countryName}</p>}
                    {countryFlag && (
                        <div className='mcfw-flag'>
                            <RatioHelper
                                alt={object.name || object.countryName}
                                borderRadius='50%'
                                dominant_color={undefined}
                                edit={edit}
                                src={countryFlag}
                                to={undefined}
                            />
                        </div>
                    )}
                    {namePosition === 'end' && <p className='mcfw-name end'>{object.name || object.countryName}</p>}
                </div>
                {cities?.filter(obj => obj.active).length! > 0 && (
                    <div className='mcfw-city-wrap'>
                        <span className='mcfw-city-pipe'>|</span>
                        {cities?.filter(obj => obj.active).map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTab}
                            />
                        ))}
                    </div>
                )}
            </div>
        )
    }
    return null
})
