// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
    IonList,
    IonThumbnail,
} from '@ionic/react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import debounce from 'lodash.debounce'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    ImageHelper,
    InfiniteScrollHelperWeb,
    SearchInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_portfolio_form,
} from 'data'

// serializers
import {
    PortfolioPageListFormSerializer,
} from 'serializers/web'

// services
import {
    getApiUrlV2,
    getInfiniteListWeb,
} from 'services'

// props
type PageListDetailHelperModalProps = {
    mediumType?: PortfolioPageListFormSerializer['medium_type']
    page: PortfolioPageListFormSerializer
    portfolioId: number
    profileId: number
    setIsOpen: React.Dispatch<boolean>
    subContentType?: PortfolioPageListFormSerializer['sub_content_type']
}

// main
export const PageListDetailHelperModal: React.FC<PageListDetailHelperModalProps> = React.memo(({
    mediumType,
    page,
    portfolioId,
    profileId,
    setIsOpen,
    subContentType,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<any[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const content_type_label_model_split = subContentType?.content_type_settings.content_type_label_model.split('_') || mediumType?.content_type_settings.content_type_label_model.split('_')
    const app = content_type_label_model_split![0]
    const model = content_type_label_model_split![1]

    let listApiUrl = ''
    if (subContentType) {
        listApiUrl = `${app}/list/${model}/?profile=${profileId}&sub_content_type=${subContentType.id}&fields=id,name,is_private,active,get_image_lq,get_dominant_color,slug`
    } else {
        if (model === 'profile') {
            listApiUrl = `${app}/list/${model}/?profile_created_by=${profileId}&fields=id,name,is_private,active,get_image_lq,get_dominant_color,slug`
        } else if (model === 'event') {
            listApiUrl = `${app}/list/${model}/?profile=${profileId}&medium_type=${mediumType?.id}&event_type=1&fields=id,name,is_private,active,get_image_lq,get_dominant_color,slug`
        } else if (model === 'project') {
            listApiUrl = `${app}/list/${model}/?profile=${profileId}&medium_type=${mediumType?.id}&is_project=true&fields=id,name,is_private,active,get_image_lq,get_dominant_color,slug`
        } else if (model === 'service') {
            listApiUrl = `${app}/list/${model}/?profile=${profileId}&medium_type=${mediumType?.id}&service_type=1&fields=id,name,is_private,active,get_image_lq,get_dominant_color,slug`
        } else {
            listApiUrl = `${app}/list/${model}/?profile=${profileId}&medium_type=${mediumType?.id}&fields=id,name,is_private,active,get_image_lq,get_dominant_color,slug`
        }
    }

    useEffect(() => {
        onGetListData(getApiUrlV2(listApiUrl, reduxAuth), true)
    }, [])

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'PageListDetailHelperModal',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PageListDetailHelperModal',
                'onGteListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PageListDetailHelperModal',
                'onSearchNext',
            ))
        }
    }

    function onSearch(value: string | null | undefined) {
        try {
            try {
                onGetListData(getApiUrlV2(`${listApiUrl}&search=${value}`, reduxAuth), true)
            } catch (error) {
                dispatch(reduxModalErrorEventHandler(
                    error,
                    'PageListDetailHelperModal',
                    'onSearch',
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PageListDetailHelperModal',
                'onSearch',
            ))
        }
    }

    const debouncedChangeHandler = debounce(onSearch, 300)

    return (
        <Dialog
            className='mo-dialog-class content-detail-input-portfolio-modal-web'
            classes={{ paper: 'mo-dialog-wrapper fix-height fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {/* TO_TEXT */}
                Click to go to the page
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='mo-dialog-searchbar-wrap'>
                    <SearchInput
                        classNameWrap='bg1'
                        onChange={(e) => debouncedChangeHandler(e)}
                        placeholder={reduxText[282]}
                    />
                </div>
                <IonList>
                    {items.map((item) => {
                        return (
                            <IonItem
                                key={item.id}
                                button
                                detail
                                onClick={() => {
                                    setIsOpen(false)
                                    history.push(`${view_url_portfolio_form}${portfolioId}/page-container/-/${page.slug}/${mediumType ? `${item.id}-${item.slug}` : item.slug}`)
                                }}
                            >
                                <IonThumbnail>
                                    <ImageHelper
                                        alt={item.name}
                                        className='cdipmw-thumb'
                                        dominant_color={item.get_dominant_color}
                                        src={item.get_image_lq}
                                    />
                                </IonThumbnail>
                                <IonLabel className='cdipmw-label'>
                                    <h3>{item.name}</h3>
                                    {!item.active && typeof item.active !== undefined && <p className='cdipmw-not-active'>{reduxText[5616]}</p>}
                                    {item.is_private && <p className='cdipmw-not-active'>{reduxText[8547]}</p>}
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonList>
                <InfiniteScrollHelperWeb
                    active={!disableInfiniteScroll}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    items={items}
                    onChange={onSearchNext}
                />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen(false)}
                    text={reduxText[4519]}
                />
            </DialogActions>
        </Dialog>
    )
})
