// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonAvatar,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    Button,
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_auth_profile_switch,
    view_url_admin,
    view_url_profile_detail_old,
} from 'data'

// serializers
import {
    SwitchProfileSelectSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    switchProfile,
} from 'services'

// props
type ProfileSwitchModalMobileProps = {
    isOpen: boolean
    setIsOpen: React.Dispatch<boolean>
}

// main
export const ProfileSwitchModalMobile: React.FC<ProfileSwitchModalMobileProps> = React.memo(({
    isOpen,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [items, setItems] = useState<SwitchProfileSelectSerializer[]>([])

    useEffect(() => {
        if (isOpen) {
            onGetDetailData()
        } else {
            setItems([])
        }
    }, [
        isOpen,
    ])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(select_url_auth_profile_switch, reduxAuth),
                component: 'ProfileDetailListMobile',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListMobile',
                'onGetDetailData',
            ))
        }
    }

    function onSwitchProfile(profileId: number, profileSlug: string, isNewProfileUx: boolean) {
        try {
            switchProfile({
                component: 'ProfileSwitchModalMobile',
                dispatch,
                history,
                pathTo: isNewProfileUx ? `${view_url_admin}${profileId}/` : `${view_url_profile_detail_old}${profileSlug}/`,
                profileId,
                reduxAuth,
                setSwitchProfileIsOpen: setIsOpen,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailListMobile',
                'onSwitchProfile',
            ))
        }
    }

    return (
        <IonModal
            isOpen={isOpen}
            onDidDismiss={() => setIsOpen(false)}
        >
            <IonHeader translucent>
                <IonTitle>{reduxText[2665]}</IonTitle>
                <IonToolbar>
                    <IonButtons slot='end'>
                        <Button
                            edit={false}
                            fill='clear'
                            onClick={() => setIsOpen(false)}
                            text={reduxText[4742]}
                        />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent
                className='profile-detail-website-iframe'
                forceOverscroll
                fullscreen
            >
                <IonList>
                    {items?.map((val) => {
                        return (
                            <IonItem
                                key={val.id}
                                button
                                onClick={() => onSwitchProfile(val.id!, val.slug!, val.is_new_profile_ux!)}
                            >
                                <IonAvatar slot='start'>
                                    <ImageHelper
                                        alt={val.name}
                                        className='eci-avatar'
                                        dominant_color={undefined}
                                        src={val.get_image_xs}
                                    />
                                </IonAvatar>
                                <IonLabel>
                                    <h3>{val.name}</h3>
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonList>
            </IonContent>
        </IonModal>
    )
})
