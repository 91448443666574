// react components
import React from 'react'

// components
import {
    ImageHelper,
    LinkHelper,
} from 'components'

// serializers
import {
    ProductListSerializer,
} from 'serializers/web'

// props
type ProductListMicroBlockMobileProps = {
    edit: boolean
    object: ProductListSerializer
}

// main
export const ProductListMicroBlockMobile: React.FC<ProductListMicroBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    return (
        <div className='product-list-micro-block-mobile'>
            <div className='plmibm-cover'>
                <LinkHelper
                    className='plmibm-cover-wrap'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    <div className='plmibm-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='plmibm-image'
                            dominant_color={undefined}
                            src={object.get_image_square}
                        />
                    </div>
                </LinkHelper>
            </div>
        </div>
    )
})
