// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// containers
import {
    MainFormContainerNew,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AdherentFormState,
    ContributionAdherentPanel,
    ContributionFormState,
    MainAdherentPanel,
    MenuAdherentPanel,
} from 'pages'

// serializers
import {
    AdherentFormType,
} from 'serializers/web'

// services
import {
    getDetailDataNew,
    getInputValue,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type LocationParams = {
    formRefresh?: number
}

type AdherentFormProps = {
    objectId?: number
    onRefresh?: () => void
}

// main
export const AdherentForm: React.FC<AdherentFormProps> = React.memo(({
    objectId,
    onRefresh,
}) => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<AdherentFormType>({
        adherent: AdherentFormState(reduxAuth),
        contribution: ContributionFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [inlineModalValue, setInlineModalValue] = useState('')
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailDataNew({
            axiosCancelToken,
            component: 'AdherentForm',
            dispatch,
            formInfo: formState.adherent,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: objectId ? `${objectId}` : params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        objectId,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    function setInitialState() {
        const adherent = AdherentFormState(reduxAuth)
        setFormState({
            adherent: {
                ...adherent,
                fields: {
                    profile: !reduxAuth.settings?.user?.is_staff ? reduxAuth.settings?.active_profile : undefined,
                },
            },
            contribution: ContributionFormState(reduxAuth),
        })
    }

    function handleInputChange(event: any, formInfo: any) {
        try {
            setFormState({
                ...formState,
                [formInfo.name]: getInputValue({
                    event,
                    formInfo,
                    reduxText,
                })
            })
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                init: true,
                mainError: '',
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdherentForm',
                'handleInputChange',
            ))
        }
    }

    const fieldsMother = formState.adherent.fields

    const panelValue = params.panelValue
    const panelText: any = {
        'contribution-panel': reduxText[8565],
        'delete-panel': fieldsMother?.active ? `${reduxText[3517]} / ${reduxText[2983]}` : reduxText[2983],
        'main-panel': reduxText[347],
    }

    return (
        <MainFormContainerNew
            formInfo={formState.adherent}
            formState={formState}
            headerTitle={panelValue ? panelText[panelValue] : ''}
            helpers={helpers}
            onRefresh={onRefresh}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {onRefresh && fieldsMother?.id && (
                <React.Fragment>
                    <p className='mfcmw-custom-edit-title'>{panelText['main-panel']}</p>
                    <MainAdherentPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                </React.Fragment>
            )}
            {!onRefresh && (
                <React.Fragment>
                    {panelValue === 'main-panel' && (
                        <MainAdherentPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {fieldsMother?.id && inlineModalValue === 'contribution-panel' && (
                        <ContributionAdherentPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            modalTitle={panelText['contribution-panel']}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                            setInlineModalValue={setInlineModalValue}
                        />
                    )}
                    {!panelValue && (
                        <MenuAdherentPanel
                            formState={formState}
                            helpers={helpers}
                            panelText={panelText}
                            setHelpers={setHelpers}
                            setInlineModalValue={setInlineModalValue}
                        />
                    )}
                </React.Fragment>
            )}
        </MainFormContainerNew>
    )
})
