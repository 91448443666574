// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    mediumTypeConstants,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    conditionMainInformationWeb,
    CoverImageProjectInput,
    CoverImageSquareProjectInput,
    DateProjectInput,
    DateTypeChoiceProjectInput,
    DescriptionProjectInput,
    LinkUrlProjectInput,
    MediumTypeProjectInput,
    NameProjectInput,
    PdfFileProjectInput,
    ProfileProjectInput,
} from 'pages'

// serializers
import {
    ProjectFormState,
} from 'serializers/web'

// props
type MainProjectPanelProps = {
    formState: ProjectFormState
    getMetaIsLoading: boolean
    handleInputChange: any
}

// main
export const MainProjectPanel: React.FC<MainProjectPanelProps> = React.memo(({
    formState,
    getMetaIsLoading,
    handleInputChange,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainProjectPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Extra
    const mediumType = fieldsMother.medium_type

    return (
        <React.Fragment>
            <MediumTypeProjectInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            {mediumType?.id && (
                <React.Fragment>
                    <ProfileProjectInput
                        disabled={Boolean(fieldsMother.id)}
                        errors={errorsMother}
                        fields={fieldsMother}
                        onChange={onHandleInputChange}
                    />
                    {conditionMainInformationWeb[mediumType.id].includes('link_url') && (
                        <LinkUrlProjectInput
                            errors={errorsMother}
                            fields={fieldsMother}
                            getMetaIsLoading={getMetaIsLoading}
                            mother={mother}
                            onChange={onHandleInputChange}
                        />
                    )}
                    {(mediumType.id === mediumTypeConstants.work) && (
                        <CoverImageProjectInput
                            errors={errorsMother}
                            fields={fieldsMother}
                            mediumType={mediumType}
                            mother={mother}
                            onChange={onHandleInputChange}
                        />
                    )}
                    {(mediumType.id === mediumTypeConstants.pdf) && (
                        <PdfFileProjectInput
                            errors={errorsMother}
                            fields={fieldsMother}
                            mother={mother}
                            onChange={onHandleInputChange}
                        />
                    )}
                    <NameProjectInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        mother={mother}
                        onChange={onHandleInputChange}
                    />
                    <DescriptionProjectInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        mother={mother}
                        onChange={onHandleInputChange}
                    />
                    <MainFormLabel
                        label={reduxText[2943]}
                        name='date'
                        optional
                    />
                    <DateProjectInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        hasLabel={false}
                        noMarginBottom
                        onChange={onHandleInputChange}
                    />
                    <DateTypeChoiceProjectInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        onChange={onHandleInputChange}
                    />
                    {(mediumType.id !== mediumTypeConstants.work) && (
                        <CoverImageProjectInput
                            errors={errorsMother}
                            fields={fieldsMother}
                            mediumType={mediumType}
                            mother={mother}
                            onChange={onHandleInputChange}
                        />
                    )}
                    <CoverImageSquareProjectInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        onChange={onHandleInputChange}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    )
})
