// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CountViewBlock,
    HeaderDetailBlock,
    DescriptionDetailBlock,
    RatioHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    SalesOrderDetailSerializer,
} from 'serializers/web'

// services
import {
    getDateWithType,
} from 'services'

// props
type SalesOrderDetailBlockProps = {
    edit: boolean
    languageId: number | undefined
    object: SalesOrderDetailSerializer
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const SalesOrderDetailBlock: React.FC<SalesOrderDetailBlockProps> = React.memo(({
    edit,
    languageId,
    object,
    setLanguageId,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const showBillingInfo = `${object.customer_first_name}${object.customer_last_name}${object.shipping_address}${object.shipping_zip_code}${object.shipping_city}${object.shipping_country?.id}` !== `${object.billing_first_name}${object.billing_last_name}${object.billing_address}${object.billing_zip_code}${object.billing_city}${object.billing_country?.id}`

    return (
        <div className='sales-order-detail-block-web'>
            <div className={`sodb-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='sodb-left-content'>
                    <div className='sodb-title-wrap'>
                        {showBillingInfo
                            ? <p className='sodb-title-name'>{reduxText[8634]}</p>
                            : <p className='sodb-title-name'>{reduxText[8635]}</p>
                        }
                    </div>
                    <div className='sodb-text-wrap'>
                        <p className='sodb-text-name'>{reduxText[5559]}</p>
                        <p className='sodb-text-value'>{object.customer_first_name} {object.customer_last_name}</p>
                    </div>
                    <div className='sodb-text-wrap'>
                        <p className='sodb-text-name'>{reduxText[8562]}</p>
                        <p className='sodb-text-value'>{object.customer_email}</p>
                    </div>
                    <div className='sodb-text-wrap'>
                        <p className='sodb-text-name'>{reduxText[4605]}</p>
                        <p className='sodb-text-value'>{object.customer_phone}</p>
                    </div>
                    <div className='sodb-text-wrap'>
                        <p className='sodb-text-name'>{reduxText[6465]}</p>
                        <p className='sodb-text-value'>{object.shipping_address}, {object.shipping_zip_code} {object.shipping_city}, {object.shipping_country?.name} </p>
                    </div>
                    {showBillingInfo && (
                        <React.Fragment>
                            <div className='sodb-title-wrap'>
                                <p className='sodb-title-name'>{reduxText[8636]}</p>
                            </div>
                            <div className='sodb-text-wrap'>
                                <p className='sodb-text-name'>{reduxText[5559]}</p>
                                <p className='sodb-text-value'>{object.billing_first_name} {object.billing_last_name}</p>
                            </div>
                            <div className='sodb-text-wrap'>
                                <p className='sodb-text-name'>{reduxText[6465]}</p>
                                <p className='sodb-text-value'>{object.billing_address}, {object.billing_zip_code} {object.billing_city}, {object.billing_country?.name} </p>
                            </div>
                        </React.Fragment>
                    )}
                    <div className='sodb-title-wrap'>
                        <p className='sodb-title-name'>{reduxText[8499]}</p>
                    </div>
                    <div className='sodb-products'>
                        <div className='sodb-product-wrap'>
                            <div className='sodb-product-name'>
                                <p>{reduxText[4381]}</p>
                            </div>
                            <div className='sodb-amount'>
                                <p>{reduxText[8637]}</p>
                            </div>
                            <div className='sodb-amount'>
                                <p>{reduxText[4383]}</p>
                            </div>
                            <div className='sodb-amount'>
                                <p>{reduxText[8553]}</p>
                            </div>
                        </div>
                        {object.sales_order_lines?.map(val => (
                            <div
                                key={val.id}
                                className='sodb-product-wrap'
                            >
                                <div className='sodb-product-image'>
                                    <RatioHelper
                                        alt={val.product?.name}
                                        dominant_color={val.product?.get_dominant_color}
                                        edit={false}
                                        src={val.product?.get_image_square}
                                        to={val.product?.get_absolute_url}
                                    />
                                </div>
                                <div className='sodb-product-name'>
                                    <p>{val.product?.name}</p>
                                    {val.product_variant && (
                                        <span className='sodb-product-variant'>{val.product_variant.name}</span>
                                    )}
                                </div>
                                <div className='sodb-amount'>
                                    <p>{Number(val.quantity)}</p>
                                </div>
                                <div className='sodb-amount'>
                                    <p>{Number(val.unit_price).toFixed(2)} {object.currency?.symbol || object.currency?.iso_code}</p>
                                </div>
                                <div className='sodb-amount'>
                                    <p className='sodb-amount-bold'>{Number(val.line_total).toFixed(2)} {object.currency?.symbol || object.currency?.iso_code}</p>
                                </div>
                            </div>
                        ))}
                        <div className='sodb-product-wrap noline'>
                            <div className='sodb-product-name' />
                            <div className='sodb-amount' />
                            <div className='sodb-amount'>
                                <p className='sodb-amount-bold'>{reduxText[6454]}</p>
                            </div>
                            <div className='sodb-amount'>
                                <p className='sodb-amount-bold'>{(Number(object.total_amount) - Number(object.shipping_cost)).toFixed(2)} {object.currency?.symbol || object.currency?.iso_code}</p>
                            </div>
                        </div>
                        <div className='sodb-product-wrap noline'>
                            <div className='sodb-amount flex1'>
                                <p>{reduxText[6464]} ({object.shipping_service?.name})</p>
                            </div>
                            <div className='sodb-amount'>
                                <p className='sodb-amount-bold'>{Number(object.shipping_cost).toFixed(2)} {object.currency?.symbol || object.currency?.iso_code}</p>
                            </div>
                        </div>
                        <div className='sodb-product-wrap noline'>
                            <div className='sodb-product-name' />
                            <div className='sodb-amount' />
                            <div className='sodb-amount'>
                                <p className='sodb-amount-bold'>{reduxText[8638]}</p>
                            </div>
                            <div className='sodb-amount'>
                                <p className='sodb-amount-bold'>{Number(object.total_amount).toFixed(2)} {object.currency?.symbol || object.currency?.iso_code}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`sodb-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <HeaderDetailBlock
                    contentType='salesorder'
                    edit={edit}
                    languageId={languageId}
                    object={object}
                    setLanguageId={setLanguageId}
                />
                <DescriptionDetailBlock
                    languageId={languageId}
                    text={object.get_status_display}
                    title={reduxText[8639]}
                />
                <DescriptionDetailBlock title={reduxText[8640]}>
                    <div className='sodb-sales-status-wrap'>
                        <i className={`sodb-icon ${(object.sales_status?.id !== 1) ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} />
                        <p className='sodb-sales-status'> {object.sales_status?.name}</p>
                    </div>
                </DescriptionDetailBlock>
                <DescriptionDetailBlock
                    languageId={languageId}
                    title={reduxText[5493]}
                    text={getDateWithType(reduxAuth, object.created_on, 't')}
                />
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
