// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorHelper,
    IconBlock,
    LinkHelperSite,
    Loader,
} from 'components'

// data
import {
    api_url_auth_autorisation_check_site,
    defaultReduxState,
    reduxAuthLogin,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FormLabel,
    TextInputV2,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    validateEmailSite,
} from 'services'

// props
type AgendaDuQuartierLoginBlockProps = {
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
    setPanel: React.Dispatch<React.SetStateAction<'login' | 'password-reset' | 'signup'>>
}

// main
export const AgendaDuQuartierLoginBlock: React.FC<AgendaDuQuartierLoginBlockProps> = React.memo(({
    email,
    setEmail,
    setPanel,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    type errorsType = {
        detail?: string
        non_field_errors?: string
        password?: string
        username?: string
    }
    type fieldsType = {
        password: string
        username: string
    }
    const errorsInitial = {}
    const fieldsInitial = {
        password: '',
        username: email,
    }

    const [buttonDisabled, setButtonDisabled] = useState(!Boolean(email))
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [isMailAutorised, setIsMailAutorised] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierLoginBlock',
                'handleInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            setButtonDisabled(true)
            setIsLoading(true)
            // @ts-ignore
            dispatch(reduxAuthLogin(
                fields.username,
                fields.password,
                reduxAuth,
                dispatch,
                setIsLoading,
                setFields,
                fieldsInitial,
                setErrors,
            ))
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierLoginBlock',
                'onSubmit',
            ))
        }
    }

    async function onCheckAutrorisedMail() {
        try {
            if (!validateEmailSite(fields.username)) {
                setErrors({ username: reduxText[7490] })
                return
            }
            setButtonDisabled(true)
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierLoginBlock onCheckAutrorisedMail')
            const formData: any = new FormData()
            formData.append('email', fields.username)
            const postUrl = getApiUrlV2(api_url_auth_autorisation_check_site, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(postUrl)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (response.data.panel === 'login') {
                        setIsMailAutorised(true)
                        setEmail(fields.username)
                    } else if (response.data.panel === 'signup') {
                        setEmail(fields.username)
                        setPanel('signup')
                    }
                })
                .catch((error) => {
                    if (process.env.NODE_ENV === 'development') console.log(error)
                    // @ts-ignore
                    setErrors(error?.response?.data)
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'AgendaDuQuartierLoginBlock',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'onCheckAutrorisedMail',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierLoginBlock',
                'onCheckAutrorisedMail',
            ))
        }
    }

    return (
        <div className={`template-block-806 ${deviceType}`}>
            <div className={`t-806-input-wrap ${deviceType}`}>
                <FormLabel
                    label='Adresse e-mail'
                    marginBottom
                    name='username'
                />
                <TextInputV2
                    disabled={isMailAutorised}
                    name='username'
                    onChange={handleInputChange}
                    tabIndex={1}
                    type='text'
                    value={fields.username}
                />
                <ErrorHelper error={errors?.username} />
            </div>
            {isMailAutorised && (
                <div className={`t-806-input-wrap ${deviceType}`}>
                    <div className={`t-806-label-wrap ${deviceType}`}>
                        <FormLabel
                            label={reduxText[8422]}
                            marginBottom
                            name='password'
                        />
                        <IconBlock
                            className={`t-806-icon ${deviceType}`}
                            edit={false}
                            iconClass={showPassword ? 'mo-new-icon-eye-slash-solid' : 'mo-new-icon-eye-solid'}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                    <TextInputV2
                        name='password'
                        onChange={handleInputChange}
                        tabIndex={2}
                        type={showPassword ? 'text' : 'password'}
                        value={fields.password}
                    />
                    <ErrorHelper error={errors?.password} />
                </div>
            )}
            <div className={`t-806-button-wrap ${deviceType}`}>
                <Button
                    disabled={buttonDisabled || isLoading}
                    edit={false}
                    expand='full'
                    onClick={isMailAutorised ? onSubmit : onCheckAutrorisedMail}
                    text={reduxText[8424]}
                />
                <ErrorHelper error={errors?.non_field_errors || errors?.detail} />
            </div>
            {isMailAutorised && (
                <div className={`t-806-link-wrap ${deviceType}`}>
                    <LinkHelperSite
                        action='page_link'
                        className={`t-806-link ${deviceType}`}
                        onClick={() => setPanel('password-reset')}
                        to={undefined}
                    >
                        {reduxText[8532]}
                    </LinkHelperSite>
                </div>
            )}
            {isLoading && (
                <Loader isOpen />
            )}
        </div>
    )
})
