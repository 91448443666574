// react components
import React from 'react'

// components
import {
    LinkHelper,
    MainHashtag,
    RatioZarmingHelper,
} from 'components'

// data
import {
    searchTabValue,
    view_url_profile_detail,
    view_url_profile_detail_old,
} from 'data'

// serializers
import {
    CreditShortInfoSerializer,
} from 'serializers/web'

// props
type CreditShortInfoProps = {
    edit: boolean
    noImage?: boolean
    object: CreditShortInfoSerializer
}

// main
export const CreditShortInfo: React.FC<CreditShortInfoProps> = React.memo(({
    edit,
    noImage,
    object,
}) => {

    const name = object.member ? object.member.name : object.name
    const member = object.member

    return (
        <div className='credit-short-info-web'>
            {!noImage && (
                <RatioZarmingHelper
                    borderRadius='50%'
                    dominant_color={member?.get_dominant_color}
                    edit={edit}
                    fontSize={12}
                    height={30}
                    name={name}
                    notZarma={Boolean(member?.get_image_xs)}
                    src={member?.get_image_xs}
                    to={member?.get_absolute_url}
                />
            )}
            <div className={`csiw-name-wrap${noImage ? ' no-image' : ''}`}>
                <LinkHelper
                    edit={edit}
                    to={member?.is_new_profile_ux ? `${view_url_profile_detail}${member?.id}/` : `${view_url_profile_detail_old}${member?.slug}/`}
                >
                    <p className='csiw-name'>{name}</p>
                </LinkHelper>
                <div className='csiw-chips'>
                    {object?.roles && (
                        <MainHashtag
                            edit={edit}
                            label={object?.roles}
                            searchTab={searchTabValue.profile}
                        />
                    )}
                </div>
            </div>
        </div>
    )
})
