// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    IonContent,
    IonPage,
    IonTitle,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Alert,
    Button,
    HeaderChildren,
    Loader,
    NotFoundComponent,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FormContent,
} from 'pages'

// serializers
import {
    ContentTypeFormTypes,
    ContentTypeSettingsTypes,
    ContentTypeSettingsCustomTypes,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
    getFormDataV2,
    getInputValueV2,
    getMainErrorMessageV2,
    getReduxTextId,
} from 'services'

// props
type MatchParams = {
    formId: string
}

// main
export const FormsFormPage: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [contentTypeSettings, setContentTypeSettings] = useState<ContentTypeSettingsTypes | undefined>(undefined)

    const formStateProps = {
        contentTypeSettings: undefined,
        data: {
            errors: {},
            fields: {},
        },
        fieldsForm: {
            formAttribute: [],
            formFile: [],
            formForeignKey: [],
            formJson: [],
            formManyToMany: [],
        },
        fieldsRequired: {
            requiredAttribute: [],
            requiredForeignKey: [],
            requiredManyToMany: [],
        },
        structure: {
            panels: [],
        },
    }

    const [formState, setFormState] = useState<ContentTypeFormTypes>(formStateProps)
    const [helpers, setHelpers] = useState<reduxFormHelpersState>({
        ...reduxFormInitialHelpers,
        buttonDisabled: false,
    })
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)

    const contentType = 'staff_staffform'
    const app = contentType.split('_')[0]
    const model = contentType.split('_')[1]
    const object = formState.data.fields
    const contentTypeSettingsCustom: ContentTypeSettingsCustomTypes = object?.profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        onGetDetailData()
    }, [
        params.formId,
        reduxAuth.apiRootUrl,
        reduxForm.refresh.refreshForm,
    ])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`${app}/form/${model}/detail/public/${params.formId}/`, reduxAuth),
                component: 'FormsFormPage',
                dispatch,
                reduxAuth,
                setDetailObject: (e) => {
                    console.log('e', e)
                    setFormState({
                        ...formState,
                        contentTypeSettings: e.content_type_settings_json,
                        data: {
                            ...formState?.data,
                            errors: {},
                            fields: e,
                        },
                        fieldsForm: e.content_type_settings_json.form.fieldsForm,
                        fieldsRequired: e.content_type_settings_json.form.fieldsRequired,
                        structure: e.content_type_settings_json.form.structure,
                    })
                    setContentTypeSettings(e.content_type_settings.settings_data_json)

                    setHelpers({
                        ...helpers,
                        init: false,
                        errorStatus: undefined,
                        isLoading: false,
                    })

                },
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormsFormPage',
                'onGetListData',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            setFormState(getInputValueV2({
                contentType,
                event,
                formState,
                reduxText,
            }))
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                init: true,
                mainError: '',
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormsFormPage',
                'handleInputChange',
            ))
        }
    }

    function handleKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                handleRequired()
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormsFormPage',
                'handleKeyPress',
            ))
        }
    }

    function handleRequired(submit?: boolean) {
        try {
            const result = checkRequiredFields({
                formInfoObject: formState,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit(submit)
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    data: {
                        ...formState.data,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormsFormPage',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit(submit?: boolean) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'FormsFormPage handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            const axiosUrl = getApiUrlV2(`${app}/form/${model}/update/public/${params.formId}/`, reduxAuth)
            const formData = getFormDataV2(formState, contentTypeSettingsCustom)
            if (submit) {
                formData.append('is_submitted', 'true')
            }
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true, submit ? 'Formulaire envoyé avec succès' : undefined))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: false,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        data: {
                            ...formState.data,
                            errors: {},
                            fields: {
                                ...object,
                                id: response.data.id,
                                slug: response.data.slug,
                            }
                        }
                    })
                    dispatch(reduxFormSetRefresh('refreshForm', response.data.id))
                })
                .catch((error) => {
                    let mainErrorMessage = ''
                    let mainErrorTitle = ''
                    if (error.response?.data?.non_field_errors) {
                        mainErrorMessage += error.response.data.non_field_errors
                    }
                    const formStateErrors = getMainErrorMessageV2({
                        error,
                        reduxText,
                    })
                    if (error.response?.status === 400) {
                        Object.keys(formStateErrors).forEach((key) => {
                            const name = contentTypeSettingsCustom?.field?.[key]?.verboseName || contentTypeSettings?.field?.[key]?.verboseName!
                            const label = getReduxTextId(name!, reduxText) || key
                            mainErrorMessage += `${label}: ${formStateErrors[key]}\n`
                        })
                    } else {
                        mainErrorMessage += formStateErrors.main_error
                        mainErrorTitle += formStateErrors.main_error_title
                    }
                    const emailAndPhoneFields = Object.keys(contentTypeSettings?.field || {}).filter(key => {
                        const fieldType = contentTypeSettings?.field[key].fieldType
                        return fieldType === 'EmailField' || fieldType === 'PhoneNumberField'
                    })
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: mainErrorMessage,
                        mainErrorTitle,
                    })
                    setFormState({
                        ...formState,
                        data: {
                            ...formState.data,
                            errors: formStateErrors,
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'FormsFormPage',
                        dispatch,
                        error,
                        formFields: JSON.stringify(object),
                        formFieldsToIgnore: emailAndPhoneFields,
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipAlertStatusCode: [400, 500],
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormsFormPage',
                'handleSubmit',
            ))
        }
    }

    return (
        <IonPage>
            <HeaderChildren>
                <IonTitle className='hcw-title'>Formulaire de {object?.sub_content_type?.name || ''} pour {object?.staff_project?.name}</IonTitle>
            </HeaderChildren>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='forms-page'>
                    {helpers.errorStatus
                        ? (
                            <NotFoundComponent
                                statusCode={helpers.errorStatus}
                            />
                        ) : (
                            <>
                                {(helpers.isLoading || !contentTypeSettings)
                                    ? (
                                        <Loader isOpen />
                                    ) : (
                                        <>
                                            <div className='forms-page-content'>
                                                <p className='form-description'>Merci de remplir ce questionnaire. Vos réponses nous aideront à créer une direction artistique qui reflète fidèlement votre vision et vos valeurs.</p>
                                                <div className={`forms-page-content2${object.is_submitted ? ' submitted' : ''}`}>
                                                    <FormContent
                                                        contentType={contentType}
                                                        contentTypeSettings={contentTypeSettings}
                                                        contentTypeSettingsCustom={contentTypeSettingsCustom}
                                                        formState={formState}
                                                        handleInputChange={handleInputChange}
                                                        noTabs
                                                        object={object}
                                                        onKeyPress={handleKeyPress}
                                                        verticalStyle
                                                    />
                                                </div>
                                                <div className='forms-page-buttons'>
                                                    <Button
                                                        disabled={helpers.buttonDisabled || object.is_submitted}
                                                        edit={false}
                                                        expand='full'
                                                        fill='outline'
                                                        onClick={() => handleRequired()}
                                                        text='Sauvergarder et terminer plus tard'
                                                    />
                                                    <Button
                                                        disabled={helpers.buttonDisabled || object.is_submitted}
                                                        edit={false}
                                                        expand='full'
                                                        onClick={() => handleRequired(true)}
                                                        text='Sauvegarder et envoyer'
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <Alert
                                    buttons={[{ text: reduxText[5614] }]}
                                    header={helpers.mainErrorTitle || reduxText[5585]}
                                    isOpen={Boolean(helpers.mainError)}
                                    message={`${helpers.mainError}${helpers.mainError === 'Network Error' ? reduxText[4577] : ''}`}
                                    onDidDismiss={() => setHelpers({
                                        ...helpers,
                                        mainError: '',
                                    })}
                                />
                                {helpers.isSaving && (
                                    <Loader
                                        isOpen
                                        message={helpers.isSaving ? reduxText[4525] : ''}
                                    />
                                )}
                            </>
                        )
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}
