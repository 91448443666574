// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    // useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useHistory,
    useLocation,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    // reduxModalSliderGetData,
} from 'data'

// services
import {
    parseQuery,
    // stringifyQuery,
} from 'services'

// main
export const ModalRedirectHelper: React.FC = () => {

    // const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    // const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxModalmodalDetail = useSelector((state: defaultReduxState) => state.reduxModal.modalDetail)

    const [locationKeys, setLocationKeys] = useState<any[]>([])

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
            }
            if (history.action === 'POP') {

                // let listUrl = `${location.pathname}`  // Keep quotes
                const parsed = parseQuery(location.search)
                delete parsed.modal_page
                delete parsed.modal_detail_id
                delete parsed.modal_page_type
                // if (parsed && Object.keys(parsed).length > 0 && parsed.constructor === Object) {
                //     listUrl += `?${stringifyQuery(parsed)}`
                // }

                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)
                    if (parseQuery(location.search)?.modal_page) {
                        //     dispatch(reduxModalSliderGetData(
                        //         Math.random(),
                        //         Number(parseQuery(location.search)?.modal_detail_id),
                        //         window.location.pathname,
                        //     ))
                    }
                } else {
                    setLocationKeys((keys) => [location.key, ...keys])
                    if (parseQuery(location.search)?.modal_page) {
                        //     dispatch(reduxModalSliderGetData(
                        //         Math.random(),
                        //         Number(parseQuery(location.search)?.modal_detail_id),
                        //         window.location.pathname,
                        //     ))
                    }
                }
            }
        })
    }, [
        locationKeys,
    ])

    const searchParams = parseQuery(location.search)
    const modal_page = searchParams.modal_page
    const modal_detail_id = searchParams.modal_detail_id
    const modal_page_type = searchParams.modal_page_type

    // if (!reduxModalmodalDetail.isOpen && !reduxModalmodalDetail.noRedirect && modal_page) {
    if (!reduxModalmodalDetail.isOpen && modal_page) {
        if (modal_page_type) {
            return <Redirect to={`/${modal_page_type}/${modal_page}/${modal_detail_id}/`} />
        }
        return <Redirect to={`/${modal_page}/${modal_detail_id}/`} />
    }
    return null
}
