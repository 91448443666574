// react components
import React, {
    useState,
} from 'react'
import {
    IonReorder,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useParams,
} from 'react-router-dom'

// components
import {
    DeleteAlert,
    Loader,
    Popover,
} from 'components'

// data
import {
    api_url_profile_section_form_update,
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    view_url_profile_detail_old,
} from 'data'

// serializers
import {
    ProfileSectionHelperSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    slug: string
    sectionId: string
}

type ProfileDetailMenuLeftTabChildrenChildrenWebProps = {
    disableReorderChildrenChildren?: boolean
    mainParentObject: ProfileSectionHelperSerializer
    manageProfileSection?: boolean
    object: ProfileSectionHelperSerializer
    parentObject?: ProfileSectionHelperSerializer
}

// main
export const ProfileDetailMenuLeftTabChildrenChildrenWeb: React.FC<ProfileDetailMenuLeftTabChildrenChildrenWebProps> = React.memo(({
    disableReorderChildrenChildren,
    mainParentObject,
    manageProfileSection,
    object,
    parentObject,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [showPopover, setShowPopover] = useState(false)


    async function handleDelete() {
        try {
            setIsDeleting(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetailMenuLeftTabChildrenChildrenWeb handleDelete')

            if (!object) return
            const deleteUrl = getApiUrl(`${api_url_profile_section_form_update}${object.id}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    dispatch(reduxFormSetRefresh('refreshProfile', object.profile))
                })
                .catch((error) => {
                    setIsDeleting(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'ProfileDetailMenuLeftTabChildrenChildrenWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMenuLeftTabChildrenChildrenWeb',
                'handleDelete',
            ))
        }
    }

    return (
        <div className='pdmltw-tab-item-children-wrap'>
            <NavLink
                key={object.id}
                className='pdmltw-tab-item-children'
                to={`${view_url_profile_detail_old}${params.slug}/sections/${object.id}/${['- Helpers'].includes(object.name!) ? 'all/' : ''}?main-parent=${mainParentObject.id}${parentObject ? `&parent=${parentObject.id}` : ''}`}
            >
                {object.name}
                {!disableReorderChildrenChildren && (
                    <IonReorder
                        className='pdmltw-ion-reorder'
                        slot='end'
                    />
                )}
                {manageProfileSection && (
                    <Popover
                        className='pdlw-status-settings'
                        buttons={[
                            {
                                text: reduxText[2105],
                                handler: () => {
                                    dispatch(reduxModalMainFormShow({
                                        cssClass: 'modal-form-web',
                                        formComponent: 'ProfileSectionForm',
                                        objectId: object.id!,
                                    }))
                                    setShowPopover(false)
                                }
                            },
                            {
                                text: reduxText[2983],
                                handler: () => {
                                    setIsDeleteAlertOpen(true)
                                }
                            },
                        ]}
                        edit={false}
                        isOpen={showPopover}
                        setIsOpen={setShowPopover}
                    />
                )}
            </NavLink>
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={handleDelete}
                    objectName={object.name}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
            {isDeleting && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </div>
    )
})
