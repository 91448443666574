// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventParticipantDetailFormSerializer,
    EventParticipantDetailFormSerializerError,
} from 'serializers/web'

// props
type IsNotAdherentEventParticipantInputProps = {
    disabled?: boolean
    errors: EventParticipantDetailFormSerializerError | undefined
    fields: EventParticipantDetailFormSerializer
    onChange: any
}

// main
export const IsNotAdherentEventParticipantInput: React.FC<IsNotAdherentEventParticipantInputProps> = React.memo(({
    disabled,
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <CheckboxInput
            disabled={disabled}
            error={errors?.is_not_adherent}
            label={reduxText[8642]}
            name='is_not_adherent'
            onChange={onChange}
            value={fields.is_not_adherent}
        />
    )
})
