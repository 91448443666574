// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ServiceDetailFormSerializer,
    ServiceDetailFormSerializerError,
} from 'serializers/web'

// props
type LinkUrlServiceInputProps = {
    errors: ServiceDetailFormSerializerError | undefined
    fields: ServiceDetailFormSerializer
    onChange: any
}

// main
export const LinkUrlServiceInput: React.FC<LinkUrlServiceInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            error={errors?.link_url}
            label={reduxText[8450]}
            name='link_url'
            onChange={onChange}
            optional
            value={fields.link_url || ''}
        />
    )
})
