// react components
import React from 'react'
import {
    isValid,
    parseISO,
} from 'date-fns'
import {
    enGB,
    fr,
} from 'date-fns/locale'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type DateTimeInputV2Props = {
    contentType?: contentTypeModelType
    defaultNow?: boolean
    disabled?: boolean
    format: 'date' | 'datetime' | 'time'
    formatDate?: 'dd/MM/yyyy' | 'MM/yyyy' | 'yyyy'
    formatPreviewOnly?: boolean
    maxDate?: any
    minDate?: any
    name: string
    onChange: any
    placeholder?: string
    timeIntervals?: number
    value: any
}

// main
export const DateTimeInputV2: React.FC<DateTimeInputV2Props> = React.memo(({
    contentType,
    defaultNow,
    disabled,
    format,
    formatDate,
    formatPreviewOnly,
    maxDate,
    minDate,
    name,
    onChange,
    placeholder,
    timeIntervals,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function onInputChange(value: any) {
        try {
            let dateFormat
            if (value) {
                const updatedDate = moment(value)
                if (['staff_prospecthistory', 'staff_staffprojecthistory'].includes(contentType!) && updatedDate.year() === 2001) {
                    updatedDate.year(moment().year())
                }
                if (format === 'time') {
                    dateFormat = updatedDate.format('HH:mm')
                } else if (format === 'datetime' || formatPreviewOnly) {
                    dateFormat = updatedDate.format()
                } else {
                    dateFormat = updatedDate.format('YYYY-MM-DD')
                }
            } else {
                dateFormat = ''
            }
            onChange({
                name: name,
                value: dateFormat,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DateTimeInputV2',
                'onInputChange',
            ))
        }
    }

    const localeDict: any = {
        en: enGB,
        fr,
    }

    if (format === 'date') {
        return (
            <DatePicker
                calendarClassName='di-calendar'
                className='datetime-input'
                dateFormat={formatDate || 'dd/MM/yyyy'}
                disabled={disabled}
                locale={localeDict[reduxAuth.settings?.language || 'en']}
                maxDate={maxDate ? parseISO(maxDate) : undefined}
                minDate={minDate ? parseISO(minDate) : undefined}
                onChange={date => onInputChange(date)}
                placeholderText={placeholder}
                popperClassName='di-popper'
                selected={isValid(parseISO(value)) ? parseISO(value) : (defaultNow ? parseISO(moment().format()) : null)}
            />
        )
    }

    if (format === 'datetime') {
        return (
            <DatePicker
                calendarClassName='di-calendar'
                className='datetime-input'
                dateFormat='dd/MM/yyyy HH:mm'
                disabled={disabled}
                locale={localeDict[reduxAuth.settings?.language || 'en']}
                maxDate={maxDate ? parseISO(maxDate) : undefined}
                minDate={minDate ? parseISO(minDate) : undefined}
                onChange={date => onInputChange(date)}
                placeholderText={placeholder}
                popperClassName='di-popper'
                selected={isValid(parseISO(value)) ? parseISO(value) : (defaultNow ? parseISO(moment().format()) : null)}
                showTimeSelect
                timeCaption={reduxText[9832]}
                timeFormat='HH:mm'
                timeIntervals={timeIntervals || 30}
            />
        )
    }

    if (format === 'time') {
        return (
            <DatePicker
                calendarClassName='di-calendar'
                className='datetime-input'
                dateFormat='HH:mm'
                disabled={disabled}
                locale={localeDict[reduxAuth.settings?.language || 'en']}
                // maxTime={maxDate ? parseISO(maxDate) : undefined}
                // minTime={minDate ? parseISO(minDate) : undefined}
                onChange={date => onInputChange(date)}
                placeholderText={placeholder}
                popperClassName='di-popper'
                selected={(value && !['Invalid date'].includes(value)) ? new Date(2024, 1, 1, value.split(':')[0], value.split(':')[1]) : (defaultNow ? parseISO(moment().format()) : null)}
                showTimeSelect
                showTimeSelectOnly
                timeCaption={reduxText[9832]}
                timeFormat='HH:mm'
                timeIntervals={timeIntervals || 30}
            />
        )
    }

    return null
})
