// react components
import React from 'react'
import axios from 'axios'

// data
import {
    api_url_profile_form_permission,
    contentTypeData,
    contentTypeModelType,
    reduxAuthState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export async function setStaffPermission({
    component,
    contentTypeModel,
    dispatch,
    objectId,
    reduxAuth,
    refreshData,
}: {
    component: string
    contentTypeModel: contentTypeModelType
    dispatch: React.Dispatch<any>
    objectId: number
    reduxAuth: reduxAuthState
    refreshData?: () => void
}) {
    try {
        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} setStaffPermission`)
        const getUrl = getApiUrl(`${api_url_profile_form_permission}${objectId}/${contentTypeData[contentTypeModel]}/`, reduxAuth)
        axios({
            headers: refreshAxiosHeaders,
            method: 'get',
            url: getUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                if (refreshData) refreshData()
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: getUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'setStaffPermission',
                })
            })
    } catch (error) {
        dispatch(reduxModalErrorEventHandler(
            error,
            component,
            'setStaffPermission',
        ))
    }
}
