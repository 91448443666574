// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'
import {
    ArrowContainer,
    Popover as TinyPopover,
} from 'react-tiny-popover'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// props
type PopoverProps = {
    buttons: {
        disabled?: boolean
        handler?: () => void
        text: string
        to?: string
    }[]
    className?: string
    color2?: string
    color?: 'light'
    edit: boolean
    extraZIndex?: boolean
    isButtonText?: string
    isOpen: boolean
    setIsOpen: React.Dispatch<boolean>
    size?: any
}

// main
export const Popover: React.FC<PopoverProps> = React.memo(({
    buttons,
    className,
    color,
    color2,
    edit,
    extraZIndex,
    isButtonText,
    isOpen,
    setIsOpen,
    size,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function PopoverContent() {
        return (
            <div className='popover-web'>
                {buttons?.map((val, i) => (
                    <div
                        key={i}
                        className='popover-web-item'
                    >
                        {val.to
                            ? (
                                <Link
                                    className='popover-web-item-button link'
                                    onClick={() => val.handler ? val.handler() : undefined}
                                    to={val.to}
                                >
                                    <span className='popover-web-item-button-text'>{val.text}</span>
                                </Link>
                            ) : (
                                <button
                                    className='popover-web-item-button'
                                    disabled={val.disabled}
                                    onClick={() => val.handler ? val.handler() : undefined}
                                >
                                    <span className='popover-web-item-button-text'>{val.text}</span>
                                </button>
                            )
                        }
                    </div>
                ))}
            </div>
        )
    }

    return (
        <TinyPopover
            containerStyle={extraZIndex ? { zIndex: '9999' } : undefined}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                    arrowColor={reduxAuth.settings?.theme === 'light' ? '#202020' : '#ffffff'}
                    arrowSize={10}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    position={position}
                >
                    <PopoverContent />
                </ArrowContainer>
            )}
            isOpen={isOpen}
            onClickOutside={() => setIsOpen(false)}
            positions={['bottom', 'left', 'top', 'right']}
        >
            {isButtonText
                ? (
                    <button
                        className={`button-web ${size || 'small'} color outline`}
                        disabled={edit}
                        onClick={edit ? undefined : () => setIsOpen(!isOpen)}
                        title={reduxText[8190]}
                    >
                        {isButtonText}

                    </button>
                ) : (
                    <button
                        className={`main-icon-block-web${className ? ` ${className}` : ''}${color ? ` ${color}` : ''}`}
                        disabled={edit}
                        onClick={edit ? undefined : () => setIsOpen(!isOpen)}
                        title={reduxText[8190]}
                    >
                        <i
                            className='main-icon mo-new-icon-ellipsis-h-solid'
                            style={{
                                color: color2,
                            }}
                        />
                    </button>
                )
            }
        </TinyPopover>
    )
})
