// Root URL
const rootPath = 'project/select/'

// Project Select
export const select_url_project_layout_block = `${rootPath}layout/block/`
export const select_url_project = `${rootPath}project/`

// Project Content Select
export const select_url_project_content_project_child = `${rootPath}content/project_child/`

// Portfolio Preview
export const select_url_project_layout_block_preview = `${rootPath}layout/block/preview/`
