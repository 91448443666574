// data
import {
    reduxTextState,
} from 'data'

// main
export function getReduxTextId(str: string, reduxText: reduxTextState['data']) {
    // Check if str is valid
    if (!str) return undefined

    // Regular expression to match "redux[" followed by digits inside square brackets
    const regex = /^reduxText\[(\d+)\]$/

    // Check if the string matches the pattern
    const match = str.match(regex)

    // If there's no match or no reduxText provided, return undefined
    if (!match) return str

    // Return the corresponding value from reduxText if it exists, otherwise return the original string
    return reduxText[Number(match[1])] ?? str
}
