// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesActivitySite,
} from 'data'

// pages
import {
    CheckboxInput819Site,
    DateTimeInput812Site,
    // ImageInput819Site,
    LocationInput819Site,
    PhoneNumberInput819Site,
    SelectListInput819Site,
    TextAreaInput819Site,
    TextInput819Site,
} from 'pages'

// serializers
import {
    CourseHelper819SiteSerializer,
    ProductVariant819HelperSiteSerializer,
} from 'serializers/site'

// props
type ParticipantHelper819SiteProps = {
    i: number
    index: number
    object: {
        activityCourseVariantId: string
        course: CourseHelper819SiteSerializer
        variant: ProductVariant819HelperSiteSerializer
    }
}

// main
export const ParticipantHelper819Site: React.FC<ParticipantHelper819SiteProps> = React.memo(({
    i,
    index,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const errors = reduxSalesSite.activityData?.registrations?.[object.activityCourseVariantId]?.students?.[index]?.errors || {}
    const fields = reduxSalesSite.activityData?.registrations?.[object.activityCourseVariantId]?.students?.[index]?.fields || {}

    function onSetField(event: any) {
        try {
            const toReturnData = {
                ...reduxSalesSite.activityData,
                registrations: {
                    ...reduxSalesSite.activityData?.registrations,
                    [object.activityCourseVariantId]: {
                        ...reduxSalesSite.activityData?.registrations?.[object.activityCourseVariantId],
                        students: {
                            ...reduxSalesSite.activityData?.registrations?.[object.activityCourseVariantId]?.students,
                            [index]: {
                                ...reduxSalesSite.activityData?.registrations?.[object.activityCourseVariantId]?.students?.[index],
                                errors: {
                                    ...reduxSalesSite.activityData?.registrations?.[object.activityCourseVariantId]?.students?.[index]?.errors,
                                    [event.name]: '',
                                },
                                fields: {
                                    ...reduxSalesSite.activityData?.registrations?.[object.activityCourseVariantId]?.students?.[index]?.fields,
                                    [event.name]: event.value,
                                }
                            },
                        },
                    },
                },
                errorMessage: '',
            }
            const studentData: any = []
            Object.values(toReturnData.registrations).map((val) => {
                [...Array(val.qty)].map((_, index) => {
                    // @ts-ignore
                    if (val.students?.[index]?.fields.last_name && val.students?.[index]?.fields.first_name) {
                        studentData.push({
                            // @ts-ignore
                            id: `${val.students?.[index]?.fields.last_name} ${val.students?.[index]?.fields.first_name}`,
                            // @ts-ignore
                            name: `${val.students?.[index]?.fields.last_name} ${val.students?.[index]?.fields.first_name}`
                        })
                    }
                })
            })
            toReturnData.studentData = studentData
            // @ts-ignore
            dispatch(reduxSalesActivitySite(toReturnData))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ParticipantHelper819Site',
                'onSetField',
            ))
        }
    }

    return (
        <div className={`t-819-participant ${deviceType}`}>
            <div className={`t-819-participant-wrap ${deviceType}`}>
                <div className={`t-819-participant-title ${deviceType}`}>
                    <i className={`t-819-icon mo-new-icon-user-solid ${deviceType}`} />
                    <span>{object.course.name} - {object.variant.name || object.variant.custom_foreign_key_1?.name} - {reduxText[8747]} {index + 1}</span>
                </div>
                {i > 0 && (
                    <div className={`t-819-prev-student-select ${deviceType}`}>
                        <SelectListInput819Site
                            error={errors.same_info_as}
                            label="S�lection de l'�l�ve pr�c�demment inscrit"
                            name='same_info_as'
                            onChange={onSetField}
                            // @ts-ignore
                            options={reduxSalesSite.activityData?.studentData?.filter(obj => obj.id !== `${fields.last_name} ${fields.first_name}`) || []}
                            value={fields.same_info_as || ''}
                        />
                    </div>
                )}
                {!fields.same_info_as && (
                    <>
                        <p className={`t-819-title ${deviceType}`}>Informations g�n�rales</p>
                        {/* <div className={`t-819-participant-inputs ${deviceType}`}>
                    <ImageInput819Site
                        error={errors.image}
                        label='Photo de profil (facultatif)'
                        name='image'
                        onChange={onSetField}
                        value={fields.image || ''}
                    />
                </div> */}
                        <div className={`t-819-participant-inputs ${deviceType}`}>
                            <TextInput819Site
                                error={errors.first_name}
                                label={reduxText[8744]}
                                name='first_name'
                                onChange={onSetField}
                                value={fields.first_name || ''}
                            />
                            <TextInput819Site
                                error={errors.last_name}
                                label={reduxText[8745]}
                                name='last_name'
                                onChange={onSetField}
                                value={fields.last_name || ''}
                            />
                        </div>
                        <div className={`t-819-participant-inputs ${deviceType}`}>
                            <TextInput819Site
                                error={errors.email}
                                label='Email'
                                name='email'
                                onChange={onSetField}
                                value={fields.email || ''}
                            />
                            <PhoneNumberInput819Site
                                error={errors.phone_number}
                                label='T�l�phone mobile'
                                name='phone_number'
                                onChange={onSetField}
                                value={fields.phone_number || ''}
                            />
                        </div>
                        <div className={`t-819-participant-inputs ${deviceType}`}>
                            <DateTimeInput812Site
                                error={errors.date_of_birth}
                                label='Date de naissance'
                                format='date'
                                name='date_of_birth'
                                onChange={onSetField}
                                value={fields.date_of_birth || ''}
                            />
                            <LocationInput819Site
                                error={errors.address_json}
                                label='Adresse'
                                name='address_json'
                                onChange={onSetField}
                                value={fields.address_json || ''}
                            />
                        </div>
                        <p className={`t-819-title ${deviceType}`}>Informations compl�mentaires</p>
                        <div className={`t-819-participant-inputs ${deviceType}`}>
                            <PhoneNumberInput819Site
                                error={errors.custom_phone_number_2}
                                label='T�l�phone n�2 (facultatif)'
                                name='custom_phone_number_2'
                                onChange={onSetField}
                                value={fields.custom_phone_number_2 || ''}
                            />
                            <TextInput819Site
                                error={errors.custom_email_field_1}
                                label='Email 2 (facultatif)'
                                name='custom_email_field_1'
                                onChange={onSetField}
                                value={fields.custom_email_field_1 || ''}
                            />
                        </div>
                        <div className={`t-819-participant-inputs ${deviceType}`}>
                            <TextAreaInput819Site
                                error={errors.custom_text_field_2}
                                label="Nom et num�ro de t�l�phone de la personne � contacter en cas d' urgence"
                                name='custom_text_field_2'
                                onChange={onSetField}
                                value={fields.custom_text_field_2 || ''}
                            />
                            <TextAreaInput819Site
                                error={errors.custom_text_field_1}
                                label="Allergies ou autre probl�me m�dical connu (facultatif)"
                                name='custom_text_field_1'
                                onChange={onSetField}
                                value={fields.custom_text_field_1 || ''}
                            />
                        </div>
                        <div className={`t-819-participant-inputs ${deviceType}`}>
                            <SelectListInput819Site
                                error={errors.custom_foreign_key_1}
                                label="Sortie seul(e) du cours (pour les mineurs) (facultatif)"
                                name='custom_foreign_key_1'
                                onChange={onSetField}
                                options={[
                                    {
                                        id: 48,
                                        name: 'Accepte',
                                    },
                                    {
                                        id: 49,
                                        name: 'Refuse',
                                    },
                                ]}
                                value={fields.custom_foreign_key_1 || ''}
                            />
                            <TextAreaInput819Site
                                error={errors.custom_text_field_3}
                                label="Nom et Num�ro de t�l�phone des personnes autoris�es � reprendre l'enfant (facultatif)"
                                name='custom_text_field_3'
                                onChange={onSetField}
                                value={fields.custom_text_field_3 || ''}
                            />
                        </div>
                        {/* <div className={`t-819-participant-inputs ${deviceType}`}>
                    <ImageInput819Site
                        error={errors.TODO}
                        label='Certificat m�dical (facultatif)'
                        name='TODO'
                        onChange={onSetField}
                        value={fields.TODO || ''}
                    />
                </div> */}
                        <div className={`t-819-participant-inputs ${deviceType}`}>
                            <SelectListInput819Site
                                error={errors.custom_foreign_key_2}
                                label="Droit � l'image"
                                name='custom_foreign_key_2'
                                onChange={onSetField}
                                options={[
                                    {
                                        id: 46,
                                        name: 'Accepte',
                                    },
                                    {
                                        id: 47,
                                        name: 'Refuse',
                                    },
                                ]}
                                value={fields.custom_foreign_key_2 || ''}
                            />
                            <div style={{
                                width: '100%',
                            }}>
                                <CheckboxInput819Site
                                    error={errors.custom_boolean_field_2}
                                    label="J'ai pris connaissance du r�glement de l'AGSL"
                                    name='custom_boolean_field_2'
                                    onChange={onSetField}
                                    value={fields.custom_boolean_field_2 || ''}
                                >
                                    <span>J'ai pris connaissance du </span>
                                    <a
                                        href={`${reduxModalSite.rootUrl || '/'}reglement-de-lassociation`}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                        style={{
                                            textDecoration: 'underline',
                                        }}

                                    >
                                        r�glement de l'AGSL
                                    </a>
                                </CheckboxInput819Site>
                            </div>
                        </div>
                        <div className={`t-819-participant-inputs ${deviceType}`}>
                            <SelectListInput819Site
                                error={errors.custom_foreign_key_3}
                                label="Je d�sire une attestation CE (facultatif)"
                                name='custom_foreign_key_3'
                                onChange={onSetField}
                                options={[
                                    {
                                        id: 45,
                                        name: 'Non',
                                    },
                                    {
                                        id: 44,
                                        name: 'Oui',
                                    },
                                ]}
                                value={fields.custom_foreign_key_3 || ''}
                            />
                            <SelectListInput819Site
                                error={errors.custom_foreign_key_4}
                                label="Je souhaite m'investir au sein de l'AGSL (facultatif)"
                                name='custom_foreign_key_4'
                                onChange={onSetField}
                                options={[
                                    {
                                        id: 43,
                                        name: 'Non',
                                    },
                                    {
                                        id: 42,
                                        name: 'Oui',
                                    },
                                ]}
                                value={fields.custom_foreign_key_4 || ''}
                            />
                        </div>
                        <div className={`t-819-participant-inputs ${deviceType}`}>
                            <CheckboxInput819Site
                                error={errors.custom_boolean_field_4}
                                label="Je suis �ligible au pass sport"
                                name='custom_boolean_field_4'
                                onChange={onSetField}
                                value={fields.custom_boolean_field_4 || ''}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
})
