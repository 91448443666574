// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ActionInputPageContent,
    ContentSourceExtraInputPageContent,
    ContentSourceInputPageContent,
    DataJsonInputPageContent,
    PageLinkInputPageContent,
    TextShort2InputPageContent,
    TextShortInputPageContent,
} from 'pages'

// serializers
import {
    ProjectFormState,
} from 'serializers/web'

// props
type MixedPanel779Props = {
    detailUrl: string
    formState: ProjectFormState
    handleInputChange: any
    mixedMediaContentType?: contentTypeModelType
    onRefresh: () => void
    updateUrl: string
}

// main
export const MixedPanel779: React.FC<MixedPanel779Props> = React.memo(({
    detailUrl,
    formState,
    handleInputChange,
    mixedMediaContentType,
    onRefresh,
    updateUrl,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const mixedMediaContent = formState.mixedMediaContent

    function onHandleInputChange(event: any) {
        try {
            let customError = undefined
            if (event.name === 'content_source' && event.value?.id === 20 && [3, 4].includes(fieldsInline?.text_short_mode?.id!)) {
                customError = {
                    name: 'text_short',
                    value: reduxText[7769],
                }
            } else if (['content_source', 'text_short_mode'].includes(event.name)) {
                customError = {
                    name: 'text_short',
                    value: '',
                }
            }
            handleInputChange(event, customError)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MixedPanel779',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    // Content
    const errorsInline = mixedMediaContent.errors
    const fieldsInline = mixedMediaContent.fields

    if (!fieldsMother || !fieldsInline || !fieldsInline.layout) return null

    return (
        <React.Fragment>
            <ContentSourceInputPageContent
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                onHandleInputChange={onHandleInputChange}
            />
            <ContentSourceExtraInputPageContent
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                mixedMedia
                mixedMediaContentType={mixedMediaContentType}
                onHandleInputChange={onHandleInputChange}
                optional={false}
            />
            <TextShortInputPageContent
                detailUrl={mixedMediaContent.detailUrl}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                fonts={fieldsMother.fonts ? fieldsMother.fonts?.concat(fieldsMother.portfolio_main?.fonts || []) : fieldsMother.portfolio_main?.fonts}
                mixedMedia
                onHandleInputChange={onHandleInputChange}
                onRefresh={onRefresh}
                optional={false}
                text_short_mode={fieldsInline.content_source?.id === 20 ? 2 : 4}
                updateUrl={mixedMediaContent.updateUrl}
            />
            <ActionInputPageContent
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                onHandleInputChange={onHandleInputChange}
                optional={false}
                options={fieldsInline.content_source?.id === 20 ? 'actionOptionsCustom' : 'actionOptionsDetail'}
            />
            {fieldsInline.action?.id === 'href' && (
                <TextShort2InputPageContent
                    detailUrl={mixedMediaContent.detailUrl}
                    errorsInline={errorsInline}
                    fieldsInline={fieldsInline}
                    fieldsMother={fieldsMother}
                    onHandleInputChange={onHandleInputChange}
                    optional={false}
                    updateUrl={mixedMediaContent.updateUrl}
                    inputObject={{
                        id: 0,
                        label: reduxText[8450],
                        field: 'text_short2',
                    }}
                />
            )}
            {['filter_param', 'page_link', 'search'].includes(fieldsInline.action?.id!) && (
                <PageLinkInputPageContent
                    errorsInline={errorsInline}
                    fieldsInline={fieldsInline}
                    fieldsMother={fieldsMother}
                    mixedMedia
                    onHandleInputChange={onHandleInputChange}
                    optional={false}
                />
            )}
            <DataJsonInputPageContent
                detailUrl={detailUrl}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                mixedMedia
                onHandleInputChange={onHandleInputChange}
                updateUrl={updateUrl}
            />
        </React.Fragment>
    )
})

////// Conditions Front

// ContentSourceInputPageContent
// * on content source custom, warning if text short mode is dynamic

// TextShortInputPageContent
// * no dynamic for custom content source
// * (to do) if normal or rich on detail page, show warning that the text will be applied on all pages

// PageLinkInputPageContent
// * not for text_mode rich

////// to do
// block layout buttons, redux size, helper on text mode and text content, helper on page link
// title on translation button

////// styles
// basic styles: color, font family, font size, font weight, text align, text transform max width (only if not rich), padding (main block), background (main block)
// text block position: full width, left, center, right (max width 600px)

// advance styles
// css class onn click styles 'popup's
