// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
    IonList,
} from '@ionic/react'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetNavigationModeSite,
} from 'data'

// serializers
import {
    ContributionFormType,
    MenuFormPanelPropsNew,
} from 'serializers/web'

// props
type MenuContributionPanelProps = {
    formState: ContributionFormType
}

// main
export const MenuContributionPanel: React.FC<MenuFormPanelPropsNew & MenuContributionPanelProps> = React.memo(({
    formState,
    helpers,
    panelText,
    setHelpers,
}) => {

    const dispatch = useDispatch()

    const [formPanels, setFormPanels] = useState<{
        formType: string
        panelValue: string
    }[]>([])

    useEffect(() => {
        dispatch(reduxFormSetNavigationModeSite(undefined))
    }, [])

    const mother = formState.contribution
    const fieldsMother = mother.fields

    useEffect(() => {

        const tempFormPanels: {
            formType: string
            panelValue: string
        }[] = []

        tempFormPanels.push(
            {
                formType: 'main',
                panelValue: 'main-panel',
            },
        )
        tempFormPanels.push(
            {
                formType: 'main',
                panelValue: 'delete-panel',
            },
        )

        setFormPanels(tempFormPanels)
    }, [])

    return (
        <IonList>
            {formPanels.map((val) => {
                if (val.panelValue === 'delete-panel') {
                    return (
                        <IonItem
                            key={val.panelValue}
                            button
                            disabled={!(fieldsMother?.id)}
                            onClick={() => setHelpers({ ...helpers, isActionSheetOpen: true })}
                        >
                            <IonLabel className='mo-item-list-label'>
                                <h2>{panelText[val.panelValue]}</h2>
                            </IonLabel>
                        </IonItem>
                    )
                }
                return (
                    <IonItem
                        key={val.panelValue}
                        disabled={!(fieldsMother?.id)}
                        onClick={() => dispatch(reduxFormSetIsSuccessToastOpen(false))}
                        routerLink={`${mother.formUrl}${fieldsMother?.id}/${val.formType}/${val.panelValue}/`}
                    >
                        <IonLabel className='mo-item-list-label'>
                            <h2>{panelText[val.panelValue]}</h2>
                        </IonLabel>
                    </IonItem>
                )
            })}
        </IonList>
    )
})
