// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    SelectListInputV2,
    TextAreaInputV2,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    MainIdNameSerializer,
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type TextShort4InputPageContentProps = {
    detailUrl: string
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    inputObject: LayoutFieldHelperSerializer
    mixedMedia?: boolean
    onHandleInputChange: any
    optional: boolean
    updateUrl: string
}

// main
export const TextShort4InputPageContent: React.FC<TextShort4InputPageContentProps> = React.memo(({
    detailUrl,
    errorsInline,
    fieldsInline,
    fieldsMother,
    inputObject,
    mixedMedia,
    onHandleInputChange,
    optional,
    updateUrl,
}) => {

    const reduxFormSitedynamicTextOptions = useSelector((state: defaultReduxState) => state.reduxFormSite.dynamicTextOptions)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const modeOptions3 = [  // normal_or_dynamic
        {
            id: 1,
            name: reduxText[6416],  // Normal
        },
        {
            id: 3,
            name: reduxText[6576],  // Dynamic
        },
    ]

    const newDynamicOption: any = (fieldsInline.content_source?.id && reduxFormSitedynamicTextOptions) ? (reduxFormSitedynamicTextOptions[fieldsInline.content_source.id] || []) : []
    const newDynamicOptionIndex = newDynamicOption.findIndex((val: MainIdNameSerializer) => val.id === fieldsInline.text_short4)

    return (
        <React.Fragment>
            {fieldsInline.content_source?.id !== 20 && inputObject.text_short_mode === 3 && (
                <SelectListInputV2
                    clearable={false}
                    error=''
                    helperText={inputObject.helper_text}
                    label={inputObject.label || reduxText[6409]}
                    marginBottom={5}
                    name='text_short_mode4'
                    onChange={onHandleInputChange}
                    options={modeOptions3}
                    value={fieldsInline.text_short_mode4 || ''}
                />
            )}
            {(!fieldsInline.text_short_mode4 || (fieldsInline.text_short_mode4?.id === 1)) && (  // Normal
                <TextAreaInputV2
                    error={errorsInline?.text_short4}
                    helperText={!inputObject.text_short_mode || inputObject.text_short_mode === 1 ? (inputObject.helper_text) : ''}
                    label={!inputObject.text_short_mode || inputObject.text_short_mode === 1 ? (inputObject.label || reduxText[6409]) : ''}
                    marginBottom={10}
                    name='text_short4'
                    onChange={onHandleInputChange}
                    // @ts-ignore
                    translation={(fieldsMother.is_multi_language || fieldsMother.portfolio_main?.is_multi_language) ? {
                        contentType: mixedMedia ? 'mixedmediacontent' : 'pagecontent',
                        defaultDetailUrl: detailUrl,
                        defaultLanguage: fieldsInline.translation_default_language,
                        defaultUpdateUrl: updateUrl,
                        objectId: fieldsInline.id,
                        profileId: fieldsMother.profile?.id,
                    } : undefined}
                    value={fieldsInline.text_short4 || ''}
                />
            )}
            {fieldsInline.text_short_mode4?.id === 3 && (  // Dynamic
                <SelectListInputV2
                    clearable={false}
                    error={errorsInline?.text_short4}
                    marginBottom={10}
                    name='text_short4'
                    onChange={(e: any) => onHandleInputChange({ name: 'text_short4', value: e.value.id })}
                    options={(fieldsInline.content_source?.id && reduxFormSitedynamicTextOptions) ? reduxFormSitedynamicTextOptions[fieldsInline.content_source.id] : []}
                    optionsSorted
                    value={fieldsInline.text_short4 ? newDynamicOption[newDynamicOptionIndex] : ''}
                />
            )}
        </React.Fragment>
    )
})
