// Root URL
const rootPath = 'adhesion/'

// Action
export const api_url_adherent_action = `${rootPath}action/adherent/`
export const api_url_contribution_action = `${rootPath}action/contribution/`

// Detail
export const api_url_adherent_detail = `${rootPath}detail/adherent/`
export const api_url_contribution_detail = `${rootPath}detail/contribution/`

// Form
export const api_url_adherent_form_active = `${rootPath}form/adherent/active/`
export const api_url_adherent_form_create = `${rootPath}form/adherent/create/`
export const api_url_adherent_form_detail = `${rootPath}form/adherent/detail/`
export const api_url_adherent_form_update = `${rootPath}form/adherent/update/`
export const api_url_contribution_form_active = `${rootPath}form/contribution/active/`
export const api_url_contribution_form_create = `${rootPath}form/contribution/create/`
export const api_url_contribution_form_detail = `${rootPath}form/contribution/detail/`
export const api_url_contribution_form_list = `${rootPath}form/contribution/list/`
export const api_url_contribution_form_update = `${rootPath}form/contribution/update/`

// Form Adherent Content
export const api_url_adherent_content_form_create = `${rootPath}form/content/create/`
export const api_url_adherent_content_form_detail = `${rootPath}form/content/detail/`
export const api_url_adherent_content_form_list = `${rootPath}form/content/list/`
export const api_url_adherent_content_form_update = `${rootPath}form/content/update/`

// List
export const api_url_adherent_list = `${rootPath}list/adherent/`
export const api_url_contribution_list = `${rootPath}list/contribution/`
