// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    CityTempEventInput,
    CoverImageEventInput,
    CoverImageSquareEventInput,
    DateOnlyEventInput,
    DescriptionEventInput,
    EndDateEventInput,
    InOnlineEventEventInput,
    NameEventInput,
    ProfileEventInput,
    StartDateEventInput,
    ThemeEventInput,
} from 'pages'

// serializers
import {
    EventFormState,
} from 'serializers/web'

// props
type MainEventPanelProps = {
    formState: EventFormState
    handleInputChange: any
}

// main
export const MainEventPanel: React.FC<MainEventPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainEventPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <ProfileEventInput
                disabled={Boolean(fieldsMother.id)}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CoverImageEventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <NameEventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <InOnlineEventEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CityTempEventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <StartDateEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <EndDateEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <DateOnlyEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <DescriptionEventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <ThemeEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CoverImageSquareEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
