// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Snackbar,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    DokaModal,
    toURL,
} from '../../../../../@static/vendors/react-doka'

// components
import {
    Button,
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    TranslationHelper,
} from 'serializers/web'

// props
type ImageCropSquareInputProps = {
    adminOnly?: boolean
    dark?: boolean
    doka_output_data: any
    error: string | undefined
    helperText?: string
    label: string
    meta_value?: string
    model?: 'press'
    name: string
    onChange: any
    optional?: boolean
    original: string
    staffOnly?: boolean
    translation?: TranslationHelper
    value: any
}

// main
export const ImageCropSquareInput: React.FC<ImageCropSquareInputProps> = React.memo(({
    adminOnly,
    dark,
    doka_output_data,
    error,
    helperText,
    label,
    meta_value,
    model,
    name,
    onChange,
    optional,
    original,
    staffOnly,
    translation,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [enabled, setEnabled] = useState(false)
    const [noEdit, setNoEdit] = useState(false)
    const [noEditMessage, setNoEditMessage] = useState(false)
    const [outputData, setOutputData] = useState<any>({})
    const [result, setResult] = useState<any>(undefined)
    const [src, setSrc] = useState<any>(value)
    const [status, setStatus] = useState<string>('add')

    useEffect(() => {
        if (original) {
            setSrc(original)
            setResult(undefined)
            try {
                setOutputData(JSON.parse(doka_output_data))
            } catch {
                setOutputData({})
            }
            setStatus('edit')
            setNoEdit(false)
        } else {
            setStatus('add')
        }
    }, [original])

    useEffect(() => {
        if (meta_value) {
            setSrc(meta_value)
            setStatus('edit')
            setNoEdit(true)
        }
    }, [meta_value])

    function handleToggle() {
        try {
            setEnabled(!enabled)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropSquareInput',
                'handleToggle',
            ))
        }
    }

    function handleDokaConfirm(output: any) {
        try {
            setEnabled(false)
            setResult(output.file)
            setOutputData(output.data)
            setStatus('edit')
            setNoEdit(false)
            // @ts-ignore
            const previewImage = output.file ? toURL(output.file) : (value.preview || value)
            onChange({
                doka_image: output.file,
                doka_output_data: output.data,
                get_image_hq: previewImage,
                get_image_lq: previewImage,
                get_image_mq: previewImage,
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropSquareInput',
                'handleDokaConfirm',
            ))
        }
    }

    function handleDokaCancel() {
        try {
            setEnabled(false)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropSquareInput',
                'handleDokaCancel',
            ))
        }
    }

    return (
        <div className='mo-image-crop-square-input-web'>
            <MainFormLabel
                adminOnly={adminOnly}
                formInput='image_crop'
                helperText={helperText}
                label={label}
                name={name}
                optional={optional}
                staffOnly={staffOnly}
                translation={translation}
                translationDefaultValue={value}
            />
            {enabled && (
                <DokaModal
                    color={outputData.color}  // TO_CHECK
                    crop={outputData.crop}
                    cropAspectRatio={model === 'press' ? 3 / 5 : 1}
                    cropMask={undefined}
                    filter={outputData.filter}  // TO_CHECK
                    markup={outputData.markup}  // TO_CHECK
                    onCancel={handleDokaCancel}
                    onConfirm={handleDokaConfirm}
                    outputData
                    src={src}
                    utils={['crop', 'filter', 'color', 'markup']}
                    markupFontFamily={['FuturaPT, Helvetica, Arial, Verdana']}
                    markupFontFamilyOptions={[
                        // ['Georgia', 'Georgia, serif'],
                        ['Palatino Linotype', '\'Palatino Linotype\', \'Book Antiqua\', Palatino, serif'],
                        // ['Times New Roman', '\'Times New Roman\', Times, serif'],
                        // ['FuturaPT', '\'FuturaPT-Book\', \'sans-serif\''],
                        ['Arial', 'Arial, Helvetica, \'sans-serif\''],
                        ['Arial Black', '\'Arial Black\', Gadget, \'sans-serif\''],
                        ['Comic Sans MS', '\'Comic Sans MS\', cursive, \'sans-serif\''],
                        ['Impact', 'Impact, Charcoal, \'sans-serif\''],
                        // ['Lucida Sans Unicode', '\'Lucida Sans Unicode\', \'Lucida Grande\', \'sans-serif\''],
                        ['Tahoma', 'Tahoma, Geneva, \'sans-serif\''],
                        // ['Tahoma', '\'Tahoma\', Helvetica, \'sans-serif\''],
                        ['Verdana', 'Verdana, Geneva, \'sans-serif\''],
                        ['Courier New MS', '\'Courier New\', Courier, monospace'],
                        ['Lucida Console', 'Monaco, monospace'],
                    ]}
                />
            )}
            {status === 'edit' && (
                <div className={`eici-image-wrap${model ? ` ${model}` : ''}`}>
                    {result
                        ? (
                            <img
                                alt=''
                                className={`eici-image`}
                                // @ts-ignore
                                src={toURL(result)}
                            />
                        ) : (
                            <img
                                alt=''
                                className={`eici-image`}
                                // @ts-ignore
                                src={(doka_output_data ? undefined : toURL(value)) || value.preview || value}
                            />
                        )}
                </div>
            )}
            {status === 'edit' && (
                <div className='eici-buttons'>
                    <div className='eici-buttons-div'>
                        <Button
                            className='eici-button'
                            edit={false}
                            fill={dark ? undefined : 'outline'}
                            onClick={noEdit ? () => setNoEditMessage(true) : handleToggle}
                            size='x-small'
                            text={reduxText[5879]}
                        />
                    </div>
                </div>
            )}
            <ErrorHelper error={error} />
            <Snackbar
                action={
                    <Button
                        edit={false}
                        fill='clear'
                        onClick={() => setNoEditMessage(false)}
                        size='small'
                        text={reduxText[4742]}
                    />
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                message={reduxText[5903]}
                onClose={() => setNoEditMessage(false)}
                open={noEditMessage}
            />
        </div>
    )
})
