// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonList,
    IonItem,
    IonLabel,
} from '@ionic/react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    IconBlock,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_adherent_form,
    view_url_block_template_form,
    view_url_event_form,
    view_url_material_form,
    view_url_member_form,
    view_url_offer_form,
    view_url_page_template_form,
    view_url_press_form,
    view_url_product_form,
    view_url_profile_form,
    view_url_project_form,
    view_url_resource_form,
    view_url_service_form,
} from 'data'

// props
type MenuFormsWebProps = {
    setIsOpen: React.Dispatch<boolean>
}

// main
export const MenuFormsWeb: React.FC<MenuFormsWebProps> = React.memo(({
    setIsOpen,
}) => {

    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [formPages, setFormPages] = useState<{
        icon: string
        primary: string
        secondary: string
        url: string
    }[]>([])

    useEffect(() => {

        const tempFormPages = [
            {
                icon: 'mo-new-icon-models-artwork',
                primary: reduxText[3528],
                secondary: reduxText[4328],
                url: `${view_url_project_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-medium-types-7',
                primary: reduxText[4812],
                secondary: reduxText[5409],
                url: `${view_url_press_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-models-shop',
                primary: reduxText[4356],
                secondary: reduxText[4357],
                url: `${view_url_product_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-models-event',
                primary: reduxText[1256],
                secondary: reduxText[4330],
                url: `${view_url_event_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-models-material',
                // icon: 'mo-new-icon-models-service',
                primary: reduxText[7670],
                secondary: reduxText[7671],
                url: `${view_url_service_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-models-offer',
                primary: reduxText[1259],
                secondary: reduxText[4333],
                url: `${view_url_offer_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-medium-types-7',
                primary: reduxText[8187],
                secondary: reduxText[8188],
                url: `${view_url_resource_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-users-solid',
                primary: reduxText[5536],
                secondary: reduxText[8435],
                url: `${view_url_member_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-models-profile',
                primary: reduxText[1260],
                secondary: reduxText[4329],
                url: `${view_url_profile_form}new/main/main-panel/`,
            },
            {
                icon: 'mo-new-icon-models-material',
                primary: reduxText[301],
                secondary: reduxText[4332],
                url: `${view_url_material_form}new/main/main-panel/`,
            },
        ]

        if (reduxAuth.settings?.user?.is_staff || (reduxAuth.settings?.active_profile?.extra_data_json?.adhesion?.adherent?.has_access)) {
            tempFormPages.push(
                {
                    icon: '',
                    primary: reduxText[8552],
                    secondary: '',
                    url: `${view_url_adherent_form}new/main/main-panel/`,
                },
            )
        }

        if (reduxAuth.settings?.user?.id === 1) {
            tempFormPages.push(
                {
                    icon: 'mo-new-icon-models-artwork',
                    primary: 'Block Templates',
                    secondary: '',
                    url: `${view_url_block_template_form}new/main/main-panel/`,
                },
                {
                    icon: 'mo-new-icon-models-artwork',
                    primary: 'Page Templates',
                    secondary: '',
                    url: `${view_url_page_template_form}new/main/main-panel/`,
                }
            )
        }

        setFormPages(tempFormPages)
    }, [
        reduxText,
    ])

    function onGoToForm(url: string) {
        setIsOpen(false)
        history.push(
            url,
            { formRefresh: Math.random() },
        )
    }

    return (
        <Dialog
            className='mo-dialog-class menu-forms-web'
            classes={{ paper: 'mo-dialog-wrapper fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[8696]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <IonList>
                    {formPages.map((val, i) => {
                        return (
                            <IonItem
                                key={i}
                                button
                                detailIcon=''
                                onClick={() => onGoToForm(val.url)}
                            >
                                <i className={`mfw-icon ${val.icon}`} />
                                <IonLabel>
                                    <h2 className='mfw-first-label'>{val.primary}</h2>
                                    <p className='mfw-second-label'>{val.secondary}</p>
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonList>
            </DialogContent>
        </Dialog>
    )
})
