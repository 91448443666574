// helpers
let mediaUrlTemp
let viewUrlTemp
if (process.env.NODE_ENV !== 'production') {
    // mediaUrlTemp = '//localhost:8887/'
    // mediaUrlTemp = 'https://media.justtheweb.io/'
    mediaUrlTemp = 'https://klhkpm8h.tinifycdn.com/'
    viewUrlTemp = 'http://localhost:8100'
} else {
    // mediaUrlTemp = 'https://media.justtheweb.io/'
    mediaUrlTemp = 'https://klhkpm8h.tinifycdn.com/'
    viewUrlTemp = 'https://app.justtheweb.io'
}

// Media
export const media_url_country_flag = `${mediaUrlTemp}main/flag/`
export const media_url_platform = `${mediaUrlTemp}main/platform/`
export const media_url_social_network = `${mediaUrlTemp}main/social_network/`
export const media_url = mediaUrlTemp
export const media_version = '?v32'


// Global
export const app_version = '2025-04-02-03-40'
let site_id_temp = 1
let secondary_color_temp = '#8ea1ff'
if (window.location.port === '8111' || ['admin.agendaduquartier.fr'].includes(window.location.hostname)) {
    site_id_temp = 17
    secondary_color_temp = '#2d80a2'
    viewUrlTemp = 'https://admin.agendaduquartier.fr'
} else if (window.location.port === '8112' || ['admin.agsl-genech.fr'].includes(window.location.hostname)) {
    site_id_temp = 19
    secondary_color_temp = '#d45500'
    viewUrlTemp = 'https://admin.agsl-genech.fr'
} else if (window.location.port === '8005' || ['forms.justtheweb.io'].includes(window.location.hostname)) {
    site_id_temp = 18
}

// View
export const viewUrl = viewUrlTemp

export const site_id = site_id_temp
export const secondary_color = secondary_color_temp

// Goolgle
export const GOOGLE_CLIENT_ID_WEB = process.env.NODE_ENV !== 'production' ? '430535249587-7b5imj3prp5ccsohvv4kgot1fa9fmuli.apps.googleusercontent.com' : '430535249587-kl3t2ga5ns9gmlmpr2n14qnp4koi8kbm.apps.googleusercontent.com'

// Leaflet
export const leaflet_attribution = '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
export const leaflet_mapbox_url = 'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXVwb3VyYmF1IiwiYSI6ImNrZjloendkcjBtNGcyeXFzbGs4Y21uejYifQ.G_99fsa5cAAm_hQ54NUNdg'
