// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CreditShortInfo,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// services
import {
    getSchedule,
} from 'services'

// serializers
import {
    EventChildHelperSerializer,
} from 'serializers/web'

// props
type ScheduleListBlockWebProps = {
    edit: boolean
    object: EventChildHelperSerializer
}

// main
export const ScheduleListBlockWeb: React.FC<ScheduleListBlockWebProps> = React.memo(({
    edit,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='schedule-list-block-web'>
            <p className='slbw-date'>{object.start_date && getSchedule(reduxAuth, object.start_date, object.end_date, object.date_only, object.date_type_choice)}{object.child_text ? ` - ${object.child_text}` : ''}{object.child_text2 ? ` - ${object.child_text2}` : ''}</p>
            <div className='slbw-extra-info'>
                {object.has_custom_address && object.address_json?.length! > 0 && (
                    <div className='slbw-address'>
                        <i className='slbw-icon mo-new-icon-map-marker-alt-solid' />
                        {object.address_json!.map((val, i) => (
                            <span key={i}>
                                {val.description}
                            </span>
                        ))}
                    </div>
                )}
                {object.team_list_grouped?.venue?.length! > 0 && (
                    <div className='slbw-venue'>
                        <p className='slbw-venue-text'>{reduxText[4905]}: </p>
                        {object.team_list_grouped!.venue!.map(val => (
                            <CreditShortInfo
                                key={val.id}
                                edit={edit}
                                noImage
                                object={val}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
})
