// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventDetailFormSerializer,
    EventDetailFormSerializerError,
} from 'serializers/web'

// props
type InOnlineEventEventInputProps = {
    errors: EventDetailFormSerializerError | undefined
    fields: EventDetailFormSerializer
    onChange: any
}

// main
export const InOnlineEventEventInput: React.FC<InOnlineEventEventInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <CheckboxInput
            error={errors?.is_online_event}
            label={reduxText[6389]}
            name='is_online_event'
            onChange={onChange}
            value={fields.is_online_event || ''}
        />
    )
})
