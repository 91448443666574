// react components
import React from 'react'

// pages
import {
    ProductDetailBlockWeb,
    ProductDetailPackageBlockWeb,
} from 'pages'

// serializers
import {
    ProductDetailSerializer,
} from 'serializers/web'

// props
type ProductDetailWrapperProps = {
    edit: boolean
    languageId: number | undefined
    modalKeys: {
        related_projects: number,
    }
    object: ProductDetailSerializer
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const ProductDetailWrapper: React.FC<ProductDetailWrapperProps> = React.memo(({
    edit,
    languageId,
    modalKeys,
    object,
    setLanguageId,
}) => {

    if (object.is_package) {
        return (
            <ProductDetailPackageBlockWeb
                edit={edit}
                modalKeys={modalKeys}
                object={object}
            />
        )
    }
    return (
        <ProductDetailBlockWeb
            edit={edit}
            languageId={languageId}
            modalKeys={modalKeys}
            object={object}
            setLanguageId={setLanguageId}
        />
    )
})
