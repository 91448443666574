// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Loader,
} from 'components'

// customers
import {
    AgendaDuQuartierAdminHome,
    AgendaDuQuartierAdminMenu,
    AgendaDuQuartierFederateurForm,
    AgendaDuQuartierInvoice,
    AgendaDuQuartierPaymentSuccessStripe,
    AgendaDuQuartierPortalStripe,
    AgendaDuQuartierQuartierDeactivate,
    AgendaDuQuartierQuartierForm,
    AgendaDuQuartierQuartierSettings,
    AgendaDuQuartierQuotation,
} from 'customers'

// data
import {
    api_url_profile_admin_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
} from 'data'

// pages
import {
    AdminModule,
    NotFoundPage,
} from 'pages'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getDetailObject,
    getApiUrlV2,
} from 'services'

// props
type MatchParams = {
    pageName: string
}

// main
export const AgendaDuQuartierAdminWrapper: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)

    useEffect(() => {
        onGetDetailData()
    }, [
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    useEffect(() => {
        if (profileObject?.id && reduxForm.refreshId.refreshProfile === profileObject.id) {
            onGetDetailData()
        }
    }, [
        reduxForm.refresh.refreshProfile,
    ])

    const [isLoading, setIsLoading] = useState(false)

    const [errorStatus, setErrorStatus] = useState<number>()
    const [profileObject, setDetailObject] = useState<ProfileDetailAdminSerializerV2 | undefined>(undefined)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`${api_url_profile_admin_detail}${reduxAuth.settings?.active_profile?.id}/`, reduxAuth),
                component: 'AgendaDuQuartierAdminWrapper',
                customFunction: !params.pageName ? undefined : (data) => dispatch(reduxModalSeoData(data.seo_data)),
                dispatch,
                reduxAuth,
                setDetailObject,
                setErrorStatus,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierAdminWrapper',
                'onGetDetailData',
            ))
        }
    }

    if (errorStatus || (params.pageName && ![
        'deactivate',
        'infos',
        'invoices',
        'payment-methods',
        'payment-success',
        'quartier',
        'quotations',
        'section',
        'settings',
    ].includes(params.pageName))) {
        return <NotFoundPage statusCode={errorStatus} />
    }

    if (!profileObject) {
        return (
            <IonPage className='admin-wrapper navbar-pt'>
                <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                    <Loader isOpen />
                </IonContent>
            </IonPage>
        )
    }

    return (
        <IonPage className='admin-wrapper navbar-pt'>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='aw-content'>
                    <div
                        className={`aw-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}
                        style={{
                            padding: '10px',
                        }}
                    >
                        <AgendaDuQuartierAdminMenu />
                    </div>
                    <div className={`aw-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {params.pageName === 'deactivate' && (
                            <AgendaDuQuartierQuartierDeactivate />
                        )}
                        {params.pageName === 'infos' && (
                            <AgendaDuQuartierFederateurForm />
                        )}
                        {params.pageName === 'invoices' && (
                            <AgendaDuQuartierInvoice />
                        )}
                        {params.pageName === 'payment-methods' && (
                            <AgendaDuQuartierPortalStripe />
                        )}
                        {params.pageName === 'payment-success' && (
                            <AgendaDuQuartierPaymentSuccessStripe />
                        )}
                        {params.pageName === 'quartier' && (
                            <AgendaDuQuartierQuartierForm />
                        )}
                        {params.pageName === 'quotations' && (
                            <AgendaDuQuartierQuotation />
                        )}
                        {params.pageName === 'section' && (
                            <AdminModule
                                isStaff={false}
                                profileObject={profileObject}
                                refreshData={onGetDetailData}
                            />
                        )}
                        {params.pageName === 'settings' && (
                            <div
                                className='mo-hidden-vertical-scrollbar'
                                style={{
                                    height: '100%',
                                }}
                            >
                                <AgendaDuQuartierQuartierSettings />
                            </div>
                        )}
                        {!params.pageName && <AgendaDuQuartierAdminHome profileObject={profileObject} />}
                    </div>
                </div>
                {isLoading && <Loader isOpen />}
            </IonContent>
        </IonPage>
    )
}
