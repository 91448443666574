export const mediumTypeConstants = {
    album: 1,
    article: 7,
    dossier: 8,
    mixed: 9,
    movie: 3,
    pdf: 20,
    post: 18,
    serie: 4,
    story: 5,
    track: 2,
    work: 6,
}

export const mediumTypeCustomPageIds = [9, 25, 26, 27, 28, 29, 33, 40]
