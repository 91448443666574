// react components
import React, {
    useState,
} from 'react'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
} from '@ionic/react'
import Linkify from 'react-linkify'
import {
    useSelector,
    useDispatch,
} from 'react-redux'

// components
import {
    ErrorHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// props
type CheckboxInputProps = {
    adminOnly?: boolean
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label: string
    name: string
    noMarginBottom?: boolean
    onChange: any
    // optional?: boolean
    staffOnly?: boolean
    value: any
}

// main
export const CheckboxInput: React.FC<CheckboxInputProps> = React.memo(({
    adminOnly,
    disabled,
    error,
    helperText,
    label,
    name,
    noMarginBottom,
    onChange,
    // optional,
    staffOnly,
    value,
}) => {

    const dispatch = useDispatch()

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })

    function onInputChange(value: boolean) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CheckboxInput',
                'onInputChange',
            ))
        }
    }

    return (
        <div className={`mo-checkbox-input-web${noMarginBottom ? ' no-mb' : ''}`}>
            {(adminOnly || staffOnly || helperText) && (
                <div className='main-form-label'>
                    <div className='main-form-helper-wrap'>
                        {/* {optional && (
                            <span className='main-form-label-optional'>
                                {' '}
                                {reduxText[5611]}
                            </span>
                        )} */}
                        {adminOnly && (
                            <span className='main-form-label-optional'>
                                {' '}
                                {reduxText[5339]}
                            </span>
                        )}
                        {staffOnly && (
                            <span className='main-form-label-optional'>
                                {' '}
                                {reduxText[5340]}
                            </span>
                        )}
                        <IonPopover
                            // @ts-ignore
                            cssClass='main-form-label-popover'
                            event={showPopover.event}
                            isOpen={showPopover.open}
                            onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
                        >
                            <div className='mflp-header'>
                                <p className='mflp-title'>{label}</p>
                                {/* {optional && <p className='mflp-optional'>{reduxText[5611]}</p>} */}
                                {adminOnly && <p className='mflp-staff'>{reduxText[5339]}</p>}
                                {staffOnly && <p className='mflp-staff'>{reduxText[5340]}</p>}
                            </div>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                                <p className='mflp-text'>{helperText}</p>
                            </Linkify>
                        </IonPopover>
                        {helperText && (
                            <i
                                className='main-form-label-helper mo-new-icon-question-circle-regular'
                                onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}
                            />
                        )}
                    </div>
                </div>
            )}
            <IonList>
                <IonItem
                    className='eciw-item'
                    lines='none'
                >
                    <IonLabel className='eciw-label'>
                        {label}
                    </IonLabel>
                    <IonCheckbox
                        className='eciw-checkbox'
                        checked={value || false}
                        disabled={disabled}
                        onIonChange={(e) => onInputChange(e.detail.checked)}
                        slot='start'
                        value={name}
                    />
                </IonItem>
            </IonList>
            <ErrorHelper error={error} />
        </div>
    )
})
