// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'
import {
    Redirect,
} from 'react-router-dom'

// components
import {
    BackgroundImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_home,
} from 'data'

// main
export const MaintenancePage: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!reduxAuth.settings?.active_profile?.is_new_profile_ux_in_progress && !reduxAuth.settings?.active_profile?.is_blocked) {
        return (
            <Redirect
                to={view_url_home}
            />
        )
    }

    return (
        <IonPage className='login-page-web navbar-pt'>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='lpw-content'>
                    <div className='lpw-left auth-global-block-web'>
                        {reduxAuth.settings?.active_profile?.is_blocked && (
                            <>
                                <h1 className='lpw-title'>{reduxText[10114]}</h1>
                                <div className='success-message pre-line'>
                                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                                        <p>{reduxText[10115]}</p>
                                        <a
                                            href={`https://billing.stripe.com/p/login/dR6dSR3fJ1UI20E8ww?prefilled_email=${reduxAuth.settings.active_profile?.stripe_email}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            https://billing.stripe.com/p/login/dR6dSR3fJ1UI20E8ww?prefilled_email={reduxAuth.settings.active_profile?.stripe_email}
                                        </a>
                                        <p>{reduxText[10116]}</p>
                                    </Linkify>
                                </div>
                            </>
                        )}
                        {reduxAuth.settings?.active_profile?.is_new_profile_ux_in_progress && (
                            <>
                                <h1 className='lpw-title'>{reduxText[10030]}</h1>
                                <div className='success-message pre-line'>
                                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                                        <p>{reduxText[10031]}</p>
                                    </Linkify>
                                </div>
                            </>
                        )}
                    </div>
                    <BackgroundImageHelper
                        className='lpw-right'
                        dominant_color={reduxText[1002900]}
                        src={reduxText[10029]}
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}
