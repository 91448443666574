// react components
import React, {
    useEffect,
} from 'react'
import {
    // useIonViewDidEnter,
    useIonViewWillLeave,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
    media_url_platform,
    media_version,
    reduxModalErrorEventHandler,
    reduxModalPlayerCheckPosition,
    reduxModalPlayerOnPlayPause,
    reduxModalPlayerSetCurrentTrack,
    reduxModalPlayerSetPosition,
} from 'data'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// props
type InPagePlayerWrapMobileProps = {
    edit: boolean
    object: ProjectDetailSerializer
}

// main
export const InPagePlayerWrapMobile: React.FC<InPagePlayerWrapMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalplayer = useSelector((state: defaultReduxState) => state.reduxModal.player)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (object.id) dispatch(reduxModalPlayerCheckPosition(object.id, 'enter'))
    }, [object.id])

    // useIonViewDidEnter(() => {
    // 	if (process.env.NODE_ENV === 'development') console.log('useIonViewDidEnter')
    // 	dispatch(reduxModalPlayerCheckPosition(object.id, 'enter'))
    // })

    useIonViewWillLeave(() => {
        if (object.id) dispatch(reduxModalPlayerCheckPosition(object.id, 'leave'))
    })

    function handlePreviewContent(object: any) {
        try {
            if (reduxModalplayer.currentTrack?.id === object.id) {
                dispatch(reduxModalPlayerOnPlayPause(!reduxModalplayer.playing))
            } else {
                // @ts-ignore
                dispatch(reduxModalPlayerSetCurrentTrack(
                    object,
                    reduxAuth,
                    dispatch,
                    'detail',
                    'detailPage',
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InPagePlayerWrapMobile',
                'handlePreviewContent',
            ))
        }
    }

    function handleFullScreen(object: any) {
        try {
            if (reduxModalplayer.currentTrack?.id !== object.id) {
                // @ts-ignore
                dispatch(reduxModalPlayerSetCurrentTrack(
                    object,
                    reduxAuth,
                    dispatch,
                    'detail',
                    'detailPage',
                ))
            }
            dispatch(reduxModalPlayerSetPosition('full'))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InPagePlayerWrapMobile',
                'handleFullScreen',
            ))
        }
    }

    return (
        <div className='rd-image-wrap'>
            <ImageHelper
                alt={object.name}
                className={`rd-image ${reduxModalplayer.playerPosition}`}
                dominant_color={undefined}
                onClick={() => handlePreviewContent(object)}
                realHeightMobile={reduxModalplayer.playerPosition === 'detail' ? `calc(100vw / 16 * 9)` : `calc(100vw / ${object.cover_image_width} * ${object.cover_image_height})`}
                src={object.get_image_lq}
            />
            {object.link_url && (
                <div className='pcb-link-url'>
                    <div
                        className='pcb-platform-wrap'
                        onClick={() => handlePreviewContent(object)}
                    >
                        <p className='pcb-powered'>{reduxText[5883]}</p>
                        <ImageHelper
                            alt={object.name}
                            className='pcb-platform'
                            dominant_color={undefined}
                            lazyMobile={false}
                            src={`${media_url_platform}${object.platform}.svg${media_version}`}
                        />
                    </div>
                    <IconBlock
                        className='pcb-fullscreen-icon'
                        edit={edit}
                        iconClass='mo-new-icon-expand-solid'
                        onClick={() => handleFullScreen(object)}
                    />
                </div>
            )}
        </div>
    )
})
