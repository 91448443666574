// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProductDetailFormSerializer,
    ProductDetailFormSerializerError,
} from 'serializers/web'

// props
type AcceptBackorderProductInputProps = {
    errors: ProductDetailFormSerializerError | undefined
    fields: ProductDetailFormSerializer
    onChange: any
}

// main
export const AcceptBackorderProductInput: React.FC<AcceptBackorderProductInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <CheckboxInput
            disabled={!fields.has_stock_management}
            error={errors?.accept_backorders}
            helperText={reduxText[803710]}
            label={reduxText[8037]}
            name='accept_backorders'
            onChange={onChange}
            value={fields.accept_backorders}
        />
    )
})
