// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelper,
    Loader,
    MainDetailEditBlockWeb,
    ProfileShortInfoWeb,
} from 'components'

// data
import {
    api_url_adherent_detail,
    api_url_block_template_detail,
    api_url_event_detail,
    api_url_material_detail,
    api_url_member_detail,
    api_url_offer_detail,
    api_url_page_template_detail,
    api_url_portfolio_detail,
    api_url_product_detail,
    api_url_profile_id_detail,
    api_url_project_detail,
    api_url_salesorder_detail,
    api_url_service_detail,
    defaultReduxState,
    languageObjectSite,
    reduxModalErrorEventHandler,
    reduxModalPlayerOnDismiss,
    reduxModalPlayerSetPosition,
    reduxModalSeoData,
    reduxModalSliderDetailShowHide,
    reduxModalSliderOnNext,
    reduxModalSliderOnPrev,
    reduxModalSliderSetData,
} from 'data'

// pages
import {
    AdherentDetailBlock,
    BlockTemplateDetailBlock,
    EventDetailBlockWeb,
    MaterialDetailBlockWeb,
    MemberDetailBlock,
    OfferDetailBlockWeb,
    PageTemplateDetailBlock,
    PortfolioDetailBlockWeb,
    ProductDetailWrapper,
    ProfileDetailBlock,
    ProjectDetailBlockWeb,
    SalesOrderDetailBlock,
    ServiceDetailBlock,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getModalAbsoluteUrl,
} from 'services'

// props
type DetailPageModalProps = {
    detailId: string
    pageSlug: string
}

// main
export const DetailPageModal: React.FC<DetailPageModalProps> = React.memo(({
    detailId,
    pageSlug,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalmodalDetail = useSelector((state: defaultReduxState) => state.reduxModal.modalDetail)
    const reduxModalplayer = useSelector((state: defaultReduxState) => state.reduxModal.player)  // TODO move to seperate component

    const [languageId, setLanguageId] = useState<number>()

    const detailPageOptions: any = {
        'adherent': {
            apiUrl: getApiUrl(api_url_adherent_detail, reduxAuth),
            app: 'adhesion',
            component: AdherentDetailBlock,
            componentActionSheet: 'AdherentActionSheet',
            model: 'adherent',
        },
        'artwork': {
            apiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            app: 'project',
            component: ProjectDetailBlockWeb,
            componentActionSheet: 'ProjectActionSheetWeb',
            model: 'project',
        },
        'block-template': {
            apiUrl: getApiUrl(api_url_block_template_detail, reduxAuth),
            app: 'builder',
            component: BlockTemplateDetailBlock,
            componentActionSheet: 'BlockTemplateActionSheet',
            model: 'blocktemplate',
        },
        'equipment': {
            apiUrl: getApiUrl(api_url_material_detail, reduxAuth),
            app: 'material',
            component: MaterialDetailBlockWeb,
            componentActionSheet: 'MaterialActionSheetWeb',
            model: 'material',
        },
        'event': {
            apiUrl: getApiUrl(api_url_event_detail, reduxAuth),
            app: 'event',
            component: EventDetailBlockWeb,
            componentActionSheet: 'EventActionSheetWeb',
            model: 'event',
        },
        'member': {
            apiUrl: getApiUrl(api_url_member_detail, reduxAuth),
            app: 'userprofile',
            component: MemberDetailBlock,
            componentActionSheet: 'MemberActionSheet',
            model: 'profileteam',
        },
        'opportunity': {
            apiUrl: getApiUrl(api_url_offer_detail, reduxAuth),
            app: 'offer',
            component: OfferDetailBlockWeb,
            componentActionSheet: 'OfferActionSheetWeb',
            model: 'offer',
        },
        'page-template': {
            apiUrl: getApiUrl(api_url_page_template_detail, reduxAuth),
            app: 'builder',
            component: PageTemplateDetailBlock,
            componentActionSheet: 'PageTemplateActionSheet',
            model: 'pagetemplate',
        },
        'product': {
            apiUrl: getApiUrl(api_url_product_detail, reduxAuth),
            app: 'sales',
            component: ProductDetailWrapper,
            componentActionSheet: 'ProductActionSheetWeb',
            model: 'product',
        },
        'profile_new': {
            apiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            app: 'userprofile',
            component: ProfileDetailBlock,
            componentActionSheet: 'ProfileActionSheetWeb',
            model: 'profileteam',
        },
        'sales-order': {
            apiUrl: getApiUrl(api_url_salesorder_detail, reduxAuth),
            app: 'sales',
            component: SalesOrderDetailBlock,
            componentActionSheet: 'SalesOrderActionSheet',
            model: 'salesorder',
        },
        'service': {
            apiUrl: getApiUrl(api_url_service_detail, reduxAuth),
            app: 'service',
            component: ServiceDetailBlock,
            componentActionSheet: 'ServiceActionSheet',
            model: 'service',
        },
        'website': {
            apiUrl: getApiUrl(api_url_portfolio_detail, reduxAuth),
            app: 'portfolio',
            component: PortfolioDetailBlockWeb,
            componentActionSheet: 'PortfolioActionSheet',
            model: 'portfolio',
        },
    }

    useEffect(() => {
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
        getData(detailId, languageId)
    }, [
        detailId,
        languageId,
    ])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [object, setObject] = useState<any>('')
    const [isLoading, setIsLoading] = useState(false)
    const [modalKeys, setModalKeys] = useState<{
        [key: string]: number,
    }>({})

    const apiUrl = detailPageOptions[pageSlug].apiUrl
    const app = detailPageOptions[pageSlug].app
    const componentActionSheet = detailPageOptions[pageSlug].componentActionSheet
    const model = detailPageOptions[pageSlug].model

    const ComponentName = detailPageOptions[pageSlug].component

    const scrollToTop = () => {
        try {
            // @ts-ignore
            if (document.getElementById('mo-dms-content-id-site') && document.getElementById('mo-dms-content-id-site').scrollTo) document.getElementById('mo-dms-content-id-site').scrollTo(0, 0)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageModal',
                'scrollToTop',
            ))
        }
    }

    async function getData(objectId: string, languageId: number | undefined) {
        try {
            // setObject('')
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DetailPageModal getData')
            if (!objectId) return
            setIsLoading(true)
            let getUrl = `${apiUrl}${objectId}/` // Keep the last '/' for Safari !!!!!!!
            if (languageId || languageObjectSite[reduxAuth.settings?.language!]) getUrl += `?language_id=${languageId || languageObjectSite[reduxAuth.settings?.language!]}`
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setObject(response.data)
                    dispatch(reduxModalSeoData(response.data.seo_data))
                    if (app === 'event') {
                        if (response.data.product_list?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.product_list))
                            setModalKeys({
                                ...modalKeys,
                                product_list: modalKey
                            })
                        }
                        if (response.data.project_list?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.project_list))
                            setModalKeys({
                                ...modalKeys,
                                project_list: modalKey
                            })
                        }
                        if (response.data.service_list?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.service_list))
                            setModalKeys({
                                ...modalKeys,
                                service_list: modalKey
                            })
                        }
                    } else if (app === 'product') {
                        if (response.data.related_projects?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.related_projects))
                            setModalKeys({
                                ...modalKeys,
                                related_projects: modalKey
                            })
                        }
                    } else if (app === 'project') {
                        if (response.data.related_products?.length > 0) {
                            const modalKey = Math.random()
                            dispatch(reduxModalSliderSetData(modalKey, response.data.related_products))
                            setModalKeys({
                                ...modalKeys,
                                related_products: modalKey
                            })
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'DetailPageModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendError: Boolean(objectId) && (error.response?.status === 404) && Boolean(error.response?.data?.detail),
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageModal',
                'getData',
            ))
        }
    }

    function onNextSlide() { // next slide function
        try {
            scrollToTop()
            dispatch(reduxModalSliderOnNext())
            if (((reduxModalplayer.currentTrack?.id === object.id) || (reduxModalplayer.albumId === object.id)) && (reduxModalplayer.playerPosition === 'detail')) {
                dispatch(reduxModalPlayerOnDismiss())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageModal',
                'onNextSlide',
            ))
        }
    }

    function onPrevSlide() { // prev slide function
        try {
            scrollToTop()
            dispatch(reduxModalSliderOnPrev())
            if (((reduxModalplayer.currentTrack?.id === object.id) || (reduxModalplayer.albumId === object.id)) && (reduxModalplayer.playerPosition === 'detail')) {
                dispatch(reduxModalPlayerOnDismiss())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageModal',
                'onPrevSlide',
            ))
        }
    }

    function handleClose() {
        try {
            dispatch(reduxModalSliderDetailShowHide(false))
            if (((reduxModalplayer.currentTrack?.id === object.id) || (reduxModalplayer.albumId === object.id)) && (reduxModalplayer.playerPosition === 'detail')) {
                dispatch(reduxModalPlayerSetPosition('full'))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageModal',
                'handleClose',
            ))
        }
    }

    if (!reduxModalmodalDetail.data?.[reduxModalmodalDetail.currentKey!]) return null

    const contentData = reduxModalmodalDetail.data[reduxModalmodalDetail.currentKey!]
    const contentIndex = reduxModalmodalDetail.slideIndex || 0

    const dataLength = contentData.length
    const multiData = dataLength > 1
    const nextIndex = contentData?.[contentIndex + 1] ? (contentIndex + 1) : (multiData ? 0 : -1)
    const prevIndex = contentData?.[contentIndex - 1] ? (reduxModalmodalDetail.slideIndex! - 1) : (multiData ? (dataLength - 1) : -1)
    const hasNext = reduxModalmodalDetail.objectId && contentData?.[nextIndex]
    const hasPrev = reduxModalmodalDetail.objectId && contentData?.[prevIndex]
    const nextObject = contentData?.[nextIndex]
    const prevObject = contentData?.[prevIndex]
    const backUrl = reduxModalmodalDetail.listUrl

    return (
        <div className='detail-page-modal-web'>
            <div className={`dpmw-content mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <LinkHelper
                    className='dpmw-backdrop'
                    edit={false}
                    onClick={() => handleClose()}
                    to={backUrl}
                />
                <div className='dpmw-navigation'>
                    {hasPrev && (
                        <LinkHelper
                            className='dpmw-previous'
                            edit={false}
                            onClick={() => onPrevSlide()}
                            to={hasPrev ? getModalAbsoluteUrl(prevObject.get_absolute_url) : undefined}
                        >
                            <i className='dpmw-icon mo-new-icon-chevron-left-solid' />
                        </LinkHelper>
                    )}
                    <LinkHelper
                        className='dpmw-close'
                        edit={false}
                        onClick={() => handleClose()}
                        to={backUrl}
                    >
                        <i className='dpmw-close-icon mo-new-icon-times-solid' />
                    </LinkHelper>
                    {hasNext && (
                        <LinkHelper
                            className='dpmw-next'
                            edit={false}
                            onClick={() => onNextSlide()}
                            to={hasNext ? getModalAbsoluteUrl(nextObject.get_absolute_url) : undefined}
                        >
                            <i className='dpmw-icon mo-new-icon-chevron-right-solid' />
                        </LinkHelper>
                    )}
                </div>
                {['website'].includes(pageSlug) && (
                    <div className='dpmw-actions'>
                        <MainDetailEditBlockWeb
                            componentActionSheet={componentActionSheet}
                            inModal
                            model={model}
                            object={object}
                            refreshData={() => getData(detailId, languageId)}
                        />
                    </div>
                )}
                <div className='dpmw-modal-wrap'>
                    {!['website'].includes(pageSlug) && (
                        <div className='dpmw-header'>
                            <div className='dpmw-header-left'>
                                <ProfileShortInfoWeb
                                    edit={false}
                                    object={object.profile}
                                />
                            </div>
                            <div className='dpmw-title-wrap'>
                                <h1 className='dpmw-title mo-clamp-web1'>{object.name}</h1>
                            </div>
                            <MainDetailEditBlockWeb
                                componentActionSheet={componentActionSheet}
                                inModal
                                model={model}
                                object={object}
                                refreshData={() => getData(detailId, languageId)}
                            />
                        </div>
                    )}
                    <div className={`dpmw-modal-content ${pageSlug}`}>
                        {object && (
                            <ComponentName
                                contentType={model}
                                inModal
                                languageId={languageId}
                                modalKeys={modalKeys}
                                object={object}
                                setLanguageId={setLanguageId}
                            />
                        )}
                    </div>
                </div>
                <Loader
                    backdropDismiss
                    isOpen={isLoading}
                />
            </div>
        </div>
    )
})
