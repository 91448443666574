// react components
import React from 'react'

// data
import {
    groupingIdsSite,
} from 'data'

// pages
import {
    TemplateBlockInceptionPageContent,
    TemplateBlockNormalPageContent,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'
import {
    PortfolioFormState,
} from 'serializers/web'

// props
type ContentBlockEditPageContentProps = {
    formState: PortfolioFormState
    object: PortfolioPageContentListSiteSerializer
    setTabValue?: React.Dispatch<string>
}

// main
export const ContentBlockEditPageContent: React.FC<ContentBlockEditPageContentProps> = React.memo(({
    formState,
    object,
    setTabValue,
}) => {

    if (groupingIdsSite.includes(object.layout!)) {
        return (
            <TemplateBlockInceptionPageContent
                formState={formState}
                isInList={false}
                object={object}
                parentStyles={object.styles}
                setTabValue={setTabValue}
            />
        )
    }
    return (
        <TemplateBlockNormalPageContent
            formState={formState}
            isInList={false}
            object={object}
            parentStyles={object.styles}
            setTabValue={setTabValue}
        />
    )
})
