// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InPagePlayerWrapMobile,
    LinkHelper,
    ProjectPlayButtonMobile,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    ArticleContentBlockMobile,
    TrackContentBlockMobile,
    VideoContentBlockMobile,
    WorkContentBlockMobile,
} from 'pages'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// props
type ProjectContentBlockMobileProps = {
    edit: boolean
    isWeb?: boolean
    modalKeys: {
        article_list: number,
        track_list: number,
        video_list: number,
    }
    object: ProjectDetailSerializer
}

// main
export const ProjectContentBlockMobile: React.FC<ProjectContentBlockMobileProps> = React.memo(({
    edit,
    isWeb,
    modalKeys,
    object,
}) => {

    const reduxModalplayerPosition = useSelector((state: defaultReduxState) => state.reduxModal.player.playerPosition)

    return (
        <div className={`project-content-block medium-type-${object.medium_type}`}>
            {!isWeb && (
                <React.Fragment>
                    {object.medium_type && [1, 2, 3, 4].includes(object.medium_type.id!) && object.is_playable && ( // Album or Track or video or serie playable
                        <InPagePlayerWrapMobile
                            edit={edit}
                            object={object}
                        />
                    )}
                    {object.medium_type && [1, 2, 3, 4].includes(object.medium_type.id!) && !object.is_playable && ( // Album or Track or video or serie not playable
                        <div className='pcb-artwork'>
                            <div className='pcb-bravo1'>
                                <ImageHelper
                                    alt={object.name}
                                    className='pcb-charlie1'
                                    dominant_color={undefined}
                                    realHeightMobile={`calc(100vw / ${object.cover_image_width} * ${object.cover_image_height})`}
                                    src={object.get_image_lq}
                                />
                            </div>
                        </div>
                    )}
                    {object.medium_type && [6].includes(object.medium_type.id!) && ( // Image
                        <div className={`pcb-artwork ${reduxModalplayerPosition}`}>
                            <div className='pcb-bravo1'>
                                <ImageHelper
                                    alt={object.name}
                                    className='pcb-charlie1'
                                    dominant_color={undefined}
                                    realHeightMobile={`calc(100vw / ${object.cover_image_width} * ${object.cover_image_height})`}
                                    src={object.get_image_lq}
                                />
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
            {isWeb && object.medium_type && [1, 2, 3, 4, 6].includes(object.medium_type.id!) && (
                <LinkHelper
                    className='pcb-artwork'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    <div className='pcb-bravo1'>
                        <ImageHelper
                            alt={object.name}
                            className='pcb-charlie1'
                            dominant_color={undefined}
                            realHeightMobile={`calc(300px / ${object.cover_image_width} * ${object.cover_image_height})`}
                            src={object.get_image_lq}
                        />
                    </div>
                </LinkHelper>
            )}
            {object.is_playable && (
                <div className='pcb-play-button-wrap'>
                    <ProjectPlayButtonMobile
                        edit={edit}
                        inDetail
                        modalKey={undefined}
                        object={object}
                        playerOrigin={`projectDetail-${object.id}`}
                    />
                </div>
            )}
            {object.medium_type?.id! === 1 && (
                <TrackContentBlockMobile
                    edit={edit}
                    modalKey={modalKeys.track_list}
                    object={object}
                />
            )}
            {object.medium_type?.id! === 4 && (
                <VideoContentBlockMobile
                    edit={edit}
                    modalKey={modalKeys.video_list}
                    object={object}
                />
            )}
            {object.medium_type?.id! === 5 && (
                <WorkContentBlockMobile
                    edit={edit}
                    isWeb={isWeb}
                    object={object}
                />
            )}
            {object.medium_type?.id! === 8 && (
                <ArticleContentBlockMobile
                    edit={edit}
                    modalKey={modalKeys.article_list}
                    object={object}
                />
            )}
        </div>
    )
})
