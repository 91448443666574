// react components
import React from 'react'
import {
    isMobileOnly,
} from 'react-device-detect'

// containers
import {
    MainFormContainerMobile,
    MainFormContainerWeb,
} from 'containers'

// data
import {
    reduxFormHelpersState,
} from 'data'

// props
type MainFormContainerWrapperProps = {
    children: React.ReactNode
    formState: any
    headerTitle: string
    helpers: reduxFormHelpersState
    onRefresh?: () => void
    reduxAuthFetchSettings?: boolean
    setFormState: React.Dispatch<any>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const MainFormContainerWrapper: React.FC<MainFormContainerWrapperProps> = React.memo(({
    children,
    formState,
    headerTitle,
    helpers,
    onRefresh,
    reduxAuthFetchSettings,
    setFormState,
    setHelpers,
}) => {

    if (isMobileOnly) {
        return (
            <MainFormContainerMobile
                children={children}
                formState={formState}
                headerTitle={headerTitle}
                helpers={helpers}
                reduxAuthFetchSettings={reduxAuthFetchSettings}
                setFormState={setFormState}
                setHelpers={setHelpers}
            />
        )
    }

    return (
        <MainFormContainerWeb
            children={children}
            formState={formState}
            headerTitle={headerTitle}
            helpers={helpers}
            onRefresh={onRefresh}
            reduxAuthFetchSettings={reduxAuthFetchSettings}
            setFormState={setFormState}
            setHelpers={setHelpers}
        />
    )
})
