// react components
import React, {
    useState,
} from 'react'

// data
import {
    contentTypeModelType,
    fieldTypeLayout,
} from 'data'

// pages
import {
    TableHeaderAction
} from 'pages'

// serializers
import {
    ContentTypeFieldTypes,
} from 'serializers/web'

// props
type TableHeaderColumnProps = {
    addProfileColumn?: boolean
    column: string
    contentType: contentTypeModelType
    fieldOptions: ContentTypeFieldTypes | undefined
    filterValues: any
    inModal?: boolean
    setFilter?: (e: any, f?: any) => void
    title: string
}

// main
export const TableHeaderColumn: React.FC<TableHeaderColumnProps> = React.memo(({
    addProfileColumn,
    column,
    contentType,
    fieldOptions,
    filterValues,
    inModal,
    setFilter,
    title,
}) => {

    const [showActionPopover, setShowActionPopover] = useState(false)

    if (column === 'images_temp') return null  // TODO: dynamic
    if (contentType === 'staff_staffmessage' && column === 'get_image_lq') return null  // TODO: dynamic
    if (!fieldOptions) {
        if (addProfileColumn && column === 'profile') return null
        return (
            <th className='th-column field-error'>
                <div className='th-div'>Error</div>
            </th>
        )
    }

    return (
        <th className={`th-column field-${fieldTypeLayout[fieldOptions.fieldType] || fieldOptions.fieldType}`}>
            <div className='th-div-wrap'>
                {setFilter && filterValues?.[contentType]?.[column] && (
                    <i
                        className='th-filter-button main-icon mo-new-icon-filter-circle-xmark-solid pointer'
                        onClick={() => {
                            setFilter({
                                value: undefined,
                                params: column
                            })
                        }}
                    />
                )}
                <div className='th-div'>{title}</div>
                <div className='th-filter-div'>
                    <TableHeaderAction
                        column={column}
                        edit={false}
                        fieldType={fieldOptions.fieldType}
                        filterValues={filterValues}
                        inModal={inModal}
                        setFilter={setFilter}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </div>
            </div>
        </th>
    )
})
