// data
import {
    mediumTypeConstants,
} from 'data'

// main
export const inlineRequiredFields = {
    [mediumTypeConstants.article]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
            'temp_project_content_parent',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
            'temp_project_content_type',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
            'temp_project_content_parent',
            'temp_project_content_type',
        ],
    },
    [mediumTypeConstants.mixed]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
            'temp_project_content_parent',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'cover_image',
            'name',
            'temp_project_content_type',
        ],
        requiredAllFields: [
            'cover_image',
            'medium_type',
            'name',
            'profile',
            'temp_project_content_parent',
            'temp_project_content_type',
        ],
    },
    [mediumTypeConstants.movie]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
            'temp_project_content_parent',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
            'temp_project_content_type',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
            'temp_project_content_parent',
            'temp_project_content_type',
        ],
    },
    [mediumTypeConstants.pdf]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
            'temp_project_content_parent',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
            'pdf_file',
            'temp_project_content_type',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
            'temp_project_content_parent',
            'temp_project_content_type',
        ],
    },
    [mediumTypeConstants.post]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
            'temp_project_content_parent',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
            'temp_project_content_type',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
            'temp_project_content_parent',
            'temp_project_content_type',
        ],
    },
    [mediumTypeConstants.track]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
            'temp_project_content_parent',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
            'temp_project_content_type',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
            'temp_project_content_parent',
            'temp_project_content_type',
        ],
    },
    [mediumTypeConstants.work]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
            'temp_project_content_parent',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'cover_image',
            'name',
            'temp_project_content_type',
        ],
        requiredAllFields: [
            'cover_image',
            'medium_type',
            'name',
            'profile',
            'temp_project_content_parent',
            'temp_project_content_type',
        ],
    },
}
