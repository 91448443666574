// react components
import React from 'react'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
    IonSpinner,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type UrlInputV2Props = {
    directionRtl?: boolean
    disabled?: boolean
    name: string
    onChange: any
    canSkipDataRetrieve?: boolean
    getMetaIsLoading?: boolean
    placeholder?: string
    value: string
}

// main
export const UrlInputV2: React.FC<UrlInputV2Props> = React.memo(({
    directionRtl,
    disabled,
    name,
    onChange,
    getMetaIsLoading,
    placeholder,
    value,
    canSkipDataRetrieve,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function onInputChange(value: string | null | undefined) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'UrlInputV2',
                'onInputChange',
            ))
        }
    }

    function onSkipDataRetrieve(e: any) {
        try {
            onChange({
                name: 'skip_data_retrieve',
                value: e,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TextAreaInput',
                'onSkipDataRetrieve',
            ))
        }
    }

    return (
        <>
            <TextareaAutosize
                className={`text-area-input${directionRtl ? ' rtl' : ''}`}
                disabled={disabled}
                name={name}
                onChange={(e) => onInputChange(e.target.value)}
                placeholder={placeholder}
                rows={1}
                value={value}
            />
            {canSkipDataRetrieve && (
                <IonList>
                    <IonItem lines='none'>
                        <IonLabel className='tiw-label'>{reduxText[8428]}</IonLabel>
                        <IonCheckbox
                            className='tiw-checkbox'
                            slot='start'
                            onIonChange={(e) => onSkipDataRetrieve(e.detail.checked)}
                        />
                    </IonItem>
                </IonList>
            )}
            {getMetaIsLoading && (
                <div className='tiw-loader'>
                    <p className='tiw-loader-text'>{reduxText[8186]}</p>
                    <IonSpinner
                        className='tiw-loader-spinner'
                    />
                </div>
            )}
        </>
    )
})
