// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    RefresherEventDetail,
} from '@ionic/core'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

import {
    IonBackButton,
    IonButtons,
    IonChip,
    IonContent,
    IonHeader,
    IonLabel,
    IonMenuToggle,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonTitle,
    IonToolbar,
} from '@ionic/react'

// components
import {
    Button,
    FooterMobile,
    InfiniteScrollHelperMobile,
} from 'components'

// data
import {
    defaultReduxState,
    eventFilterOptions,
    materialFilterOptions,
    offerFilterOptions,
    pressFilterOptions,
    productFilterOptions,
    profileFilterOptions,
    projectFilterOptions,
    reduxModalErrorEventHandler,
    resourceFilterOptions,
} from 'data'

// pages
import {
    EventListBigBlockMobile,
    EventListMediumBlockMobile,
    EventListSmallBlockMobile,
    MaterialListBigBlockMobile,
    MaterialListMediumBlockMobile,
    MaterialListSmallBlockMobile,
    OfferListBigBlockMobile,
    OfferListMediumBlockMobile,
    OfferListSmallBlockMobile,
    ProductListBigBlockMobile,
    ProductListMediumBlockMobile,
    ProductListSmallBlockMobile,
    ProfileListBigBlockMobile,
    ProfileListMediumBlockMobile,
    ProfileListSmallBlockMobile,
    ProjectListBigBlockMobile,
    ProjectListMediumBlockMobile,
    ProjectListSmallBlockMobile,
    SearchMenuMobile,
    SearchTabsMobile,
} from 'pages'

// services
import {
    getInfiniteListMobileNew,
    getInfiniteRandomListMobile,
    listStateInitialMobile,
    listStatePropsMobile,
} from 'services'

// props
type TabListContainerMobileProps = {
    filterParamsApiUrl: any
    playerOrigin: string
    titleId: number
}

// main
export const TabListContainerMobile: React.FC<TabListContainerMobileProps> = React.memo(({
    filterParamsApiUrl,
    playerOrigin,
    titleId,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const contentRef = useRef<HTMLIonContentElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const filterParamsOptions: any = {
        artworks: {
            bigListComponent: 'ProjectListBigBlockMobile',
            defaultThumbSize: 'small',
            filterSet: projectFilterOptions,
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
            textId: 3554,  // reduxText[3554]
        },
        events: {
            bigListComponent: 'EventListBigBlockMobile',
            defaultThumbSize: 'medium',
            filterSet: eventFilterOptions,
            mediumListComponent: 'EventListMediumBlockMobile',
            smallListComponent: 'EventListSmallBlockMobile',
            textId: 2433,  // reduxText[2433]
        },
        materials: {
            bigListComponent: 'MaterialListBigBlockMobile',
            defaultThumbSize: 'medium',
            filterSet: materialFilterOptions,
            mediumListComponent: 'MaterialListMediumBlockMobile',
            smallListComponent: 'MaterialListSmallBlockMobile',
            textId: 301,  // reduxText[301]
        },
        offers: {
            bigListComponent: 'OfferListBigBlockMobile',
            defaultThumbSize: 'medium',
            filterSet: offerFilterOptions,
            mediumListComponent: 'OfferListMediumBlockMobile',
            smallListComponent: 'OfferListSmallBlockMobile',
            textId: 302,  // reduxText[302]
        },
        press: {
            bigListComponent: 'ProjectListBigBlockMobile',
            defaultThumbSize: 'medium',
            filterSet: pressFilterOptions,
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
            textId: 4812,  // reduxText[4812]
        },
        products: {
            bigListComponent: 'ProductListBigBlockMobile',
            defaultThumbSize: 'small',
            filterSet: productFilterOptions,
            mediumListComponent: 'ProductListMediumBlockMobile',
            smallListComponent: 'ProductListSmallBlockMobile',
            textId: 1091,  // reduxText[1091]
        },
        profiles: {
            bigListComponent: 'ProfileListBigBlockMobile',
            defaultThumbSize: 'medium',
            filterSet: profileFilterOptions,
            mediumListComponent: 'ProfileListMediumBlockMobile',
            smallListComponent: 'ProfileListSmallBlockMobile',
            textId: 2432,  // reduxText[2432]
        },
        resources: {
            bigListComponent: 'ProjectListBigBlockMobile',
            defaultThumbSize: 'medium',
            filterSet: resourceFilterOptions,
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
            textId: 8144,  // reduxText[8144]
        },
    }

    useEffect(() => {
        onGetListData(
            `${filterParamsApiUrl[mainTabValue]}?block_size=${thumbSize}`,
            true,
        )
    }, [
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
    ])

    const [filterHasChanged, setFilterHasChanged] = useState(false)
    const [filterOptionValue, setFilterOptionValue] = useState<string>('')
    const [filterQuery, setFilterQuery] = useState('')
    const [filterValue, setFilterValue] = useState<any>([])
    const [mainTabValue, setMainTabValue] = useState<string>(location.hash ? location.hash.replace('#', '') : 'artworks')
    const [searchInput, setSearchInput] = useState('')
    const [thumbSize, setThumbSize] = useState<string>(filterParamsOptions[mainTabValue].defaultThumbSize)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsMobile>({
        ...listStateInitialMobile,
        componentString: filterParamsOptions[mainTabValue][`${thumbSize}ListComponent`],
        modalKey: Math.random(),
    })

    const components: any = {
        EventListBigBlockMobile,
        EventListMediumBlockMobile,
        EventListSmallBlockMobile,
        MaterialListBigBlockMobile,
        MaterialListMediumBlockMobile,
        MaterialListSmallBlockMobile,
        OfferListBigBlockMobile,
        OfferListMediumBlockMobile,
        OfferListSmallBlockMobile,
        ProductListBigBlockMobile,
        ProductListMediumBlockMobile,
        ProductListSmallBlockMobile,
        ProfileListBigBlockMobile,
        ProfileListMediumBlockMobile,
        ProfileListSmallBlockMobile,
        ProjectListBigBlockMobile,
        ProjectListMediumBlockMobile,
        ProjectListSmallBlockMobile,
    }

    if (!filterParamsOptions[mainTabValue]) return null

    const ComponentName = components[listState.componentString]

    const mainTabs = [
        {
            name: 'artworks',
            textId: 3554,  // reduxText[3554]
        },
        {
            name: 'profiles',
            textId: 2432,  // reduxText[2432]
        },
        {
            name: 'press',
            textId: 4812,  // reduxText[4812]
        },
        {
            name: 'products',
            textId: 1091,  // reduxText[1091]
        },
        {
            name: 'events',
            textId: 2433,  // reduxText[2433]
        },
        {
            name: 'offers',
            textId: 302,  // reduxText[302]
        },
        {
            name: 'materials',
            textId: 301,  // reduxText[301]
        },
        {
            name: 'resources',
            textId: 8144,  // reduxText[8144]
        },
    ]

    function onCloseFilterMenu() {
        try {
            if (filterHasChanged) {
                onGetListData(
                    `${filterParamsApiUrl[mainTabValue]}?block_size=${thumbSize}`,
                    true,
                )
                setFilterHasChanged(false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onCloseFilterMenu',
            ))
        }
    }

    function onFilterRemove(object: any) {
        try {
            const filterArray: any[] = []
            filterValue.map((val: any) => {
                if (val !== object) filterArray.push(val)
                return false
            })
            onSetFilterParams(filterArray, true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onFilterRemove',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        refreshEvent?: CustomEvent<RefresherEventDetail>,
        newFilterQuery2?: string,
    ) {
        try {

            let newFilterQuery = filterQuery
            if (newFilterQuery2) {
                if (newFilterQuery2 === 'empty') {
                    newFilterQuery = ''
                } else {
                    newFilterQuery = newFilterQuery2
                }
            }

            let axiosUrl = apiUrl
            if (apiUrl) {
                if ((apiUrl).includes('?')) {
                    axiosUrl += `&search=${searchInput}${newFilterQuery}`
                } else {
                    axiosUrl += `?search=${searchInput}${newFilterQuery}`
                }
            }

            if (playerOrigin === 'searchList') {
                getInfiniteRandomListMobile(
                    axiosUrl,
                    reduxAuth,
                    listState,
                    setListState,
                    axiosCancelToken,
                    setAxiosCancelToken,
                    dispatch,
                    'TabListContainerMobile',
                    changingView,
                    refreshEvent,
                    filterParamsOptions[mainTabValue][`${thumbSize}ListComponent`],
                )
            } else {
                getInfiniteListMobileNew(
                    axiosUrl,
                    reduxAuth,
                    listState,
                    setListState,
                    axiosCancelToken,
                    setAxiosCancelToken,
                    dispatch,
                    'TabListContainerMobile',
                    changingView,
                    refreshEvent,
                    filterParamsOptions[mainTabValue][`${thumbSize}ListComponent`],
                )
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onGetListData',
            ))
        }
    }

    function onRefresh(event: CustomEvent<RefresherEventDetail>) {
        try {
            onGetListData(
                `${filterParamsApiUrl[mainTabValue]}?block_size=${thumbSize}`,
                true,
                event,
            )
            setSearchInput('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onRefresh',
            ))
        }
    }

    function onSearchInputChange(value: string | null | undefined) {
        try {
            if (!value) return
            setSearchInput(value)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onSearchInputChange',
            ))
        }
    }

    let wto: any

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                event.target.blur()
                clearTimeout(wto)
                wto = setTimeout(() => {
                    onGetListData(
                        `${filterParamsApiUrl[mainTabValue]}?block_size=${thumbSize}`,
                        true,
                    )
                }, 100)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onSearchKeyPress',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onSearchNext',
            ))
        }
    }

    function onSetFilterParams(filterArray: any, getList?: boolean) {
        try {
            let newFilterQuery = ''
            filterArray.map((val: any) => {
                newFilterQuery += `&${val.filterParam}=${val.id}`
                return false
            })
            setFilterValue(filterArray)
            setFilterHasChanged(true)
            setFilterQuery(newFilterQuery)
            if (getList) {
                onGetListData(
                    `${filterParamsApiUrl[mainTabValue]}?block_size=${thumbSize}`,
                    true,
                    undefined,
                    newFilterQuery || 'empty',
                )
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onSetFilterParams',
            ))
        }
    }

    function onSetTabValue(newTabValue: string) {
        try {
            if (newTabValue) {
                setMainTabValue(newTabValue)  // Keep before getListData, else double zarming happening
                setThumbSize(`${filterParamsOptions[newTabValue].defaultThumbSize}`)
                setFilterOptionValue(newTabValue === 'materials' ? 'category_group' : '')
                onGetListData(
                    `${filterParamsApiUrl[newTabValue]}?block_size=${filterParamsOptions[newTabValue].defaultThumbSize}`,
                    true,
                )
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onSetTabValue',
            ))
        }
    }

    function onSetThumbSize(value: string) {
        try {
            setThumbSize(value)
            onGetListData(
                `${filterParamsApiUrl[mainTabValue]}?block_size=${value}`,
                true,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'onSetThumbSize',
            ))
        }
    }

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop(500)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainerMobile',
                'scrollToTop',
            ))
        }
    }

    return (
        <React.Fragment>
            {playerOrigin === 'searchList' && (
                <SearchMenuMobile
                    filterOptionValue={filterOptionValue}
                    filterOptions={filterParamsOptions[mainTabValue].filterSet}
                    filterValue={filterValue}
                    onCloseFilterMenuParent={onCloseFilterMenu}
                    onSetFilterParams={onSetFilterParams}
                    onSetThumbSize={onSetThumbSize}
                    setFilterOptionValue={setFilterOptionValue}
                    setFilterValue={setFilterValue}
                    tabNameId={filterParamsOptions[mainTabValue].textId}
                    thumbSize={thumbSize}
                />
            )}
            <IonPage id='searchPage' className='search-page'>
                <IonHeader
                    className='mo-header sp-header'
                    translucent
                >
                    <IonToolbar
                        id='sp-header-toolbar'
                        className='sp-header-toolbar'
                    >
                        {playerOrigin === 'searchList' && (
                            <IonButtons slot='start'>
                                {location.pathname !== '/' && (
                                    <IonBackButton
                                        defaultHref='/'
                                        text=''
                                    />
                                )}
                            </IonButtons>
                        )}
                        {playerOrigin === 'searchList'
                            ? (
                                <IonSearchbar
                                    className='sp-search'
                                    onIonChange={(e) => onSearchInputChange(e.detail.value)}
                                    onIonClear={() => setSearchInput('')}
                                    onKeyPress={(e) => onSearchKeyPress(e)}
                                    placeholder={reduxText[282]}
                                />
                            ) : (
                                <IonTitle color='primary'>{reduxText[titleId]}</IonTitle>
                            )
                        }
                        {playerOrigin === 'searchList' && (
                            <IonButtons slot='end'>
                                <IonMenuToggle
                                    autoHide={false}
                                    menu='searchPage'
                                >
                                    <Button
                                        className='sp-filter'
                                        edit={false}
                                        fill='clear'
                                        text={reduxText[5598]}
                                    />
                                </IonMenuToggle>
                            </IonButtons>
                        )}
                    </IonToolbar>
                    <SearchTabsMobile
                        mainTabValue={mainTabValue}
                        mainTabs={mainTabs}
                        setMainTabValue={onSetTabValue}
                    />
                </IonHeader>
                <IonContent
                    className='sp-content'
                    forceOverscroll
                    fullscreen
                    ref={contentRef}
                >
                    <IonRefresher
                        onIonRefresh={onRefresh}
                        slot='fixed'
                    >
                        <IonRefresherContent />
                    </IonRefresher>
                    <div className='sp-filter-chips'>
                        {filterValue.map((val: any) => (
                            <IonChip key={val.id}>
                                <IonLabel>{val.name}</IonLabel>
                                <i
                                    className='mo-new-icon-circle-xmark-regular mo-cursor'
                                    onClick={() => onFilterRemove(val)}
                                />
                            </IonChip>
                        ))}
                    </div>
                    <div className={`sp-infinite-div ${thumbSize}`}>
                        {ComponentName && listState.items.map((item) => (
                            <ComponentName
                                key={item.id}
                                edit={false}
                                modalKey={listState.modalKey}
                                object={item}
                                playerOrigin={playerOrigin}
                            />
                        ))}
                    </div>
                    <InfiniteScrollHelperMobile
                        active={!listState.disableInfiniteScroll}
                        hasMore={listState.hasMore}
                        isLoading={listState.isLoading}
                        items={listState.items}
                        onChange={onSearchNext}
                    />
                </IonContent>
                <FooterMobile scrollToTop={scrollToTop} />
            </IonPage>
        </React.Fragment>
    )
})
