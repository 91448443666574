// react components
import React, {
    useEffect,
} from 'react'
import {
    DropzoneComponent,
} from 'react-dropzone-component'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
    ImageCropSquareInput,
    ImageInput,
    MainFormLabel,
    RadioInput,
    SelectAsyncInput,
    SelectListInput,
    TextAreaInput,
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    mediumTypeConstants,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    select_url_main_medium_type_artwork,
    select_url_project_content_project_child,
} from 'data'

// pages
import {
    conditionMainInformationWeb,
} from 'pages'

// serializers
import {
    InlineFormPanelProps,
    MainIdNameSerializer,
    ProjectFormState,
} from 'serializers/web'

// props
type ContentProjectPanelProps = {
    formState: ProjectFormState
    mediumType: MainIdNameSerializer | undefined
    setFormState: React.Dispatch<ProjectFormState>
}

// main
export const ContentProjectPanel: React.FC<InlineFormPanelProps & ContentProjectPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    mediumType,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const content = formState.content
    const mother = formState.mother

    useEffect(() => {
        if ((mediumType || content.fields?.medium_type) && mother.fields?.medium_type?.id) {
            handleInputChange({
                name: 'temp_project_content_type',
                value: 1,
                medium_type: mediumType || content.fields?.medium_type,
            }, content)
        }
    }, [
        content.fields?.medium_type?.id,
        mother.fields?.medium_type?.id,
    ])

    function onHandleInputChange(event: any) {
        try {
            if (event.name === 'temp_project_content_type') {
                event.medium_type = mediumType || content.fields?.medium_type
            }
            handleInputChange(event, content)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentProjectPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = content.errors
    const fieldsInline = content.fields

    if (!fieldsInline) return null

    // Extra
    const requiredFields = mother.requiredAllFields
    let myDropzone: any
    function initCallback(dropzone: any) {
        myDropzone = dropzone
    }

    const config = {
        iconFiletypes: ['.gif', '.jpg', '.jpeg', '.png', '.webp'],
        postUrl: content?.createUrl,
        showFiletypeIcon: true,
    }
    const eventHandlers = {
        init: (e: any) => initCallback(e),
        complete: (file: any) => {
            myDropzone.removeFile(file)
            dispatch(reduxFormSetRefresh('refreshInlineList'))
        }
    }
    const djsConfig = {
        acceptedFiles: 'image/gif,image/jpeg,image/png,image/webp',
        dictDefaultMessage: reduxText[5574],
        headers: reduxAuth.axiosConfig?.headers,
        maxFilesize: 64,
        maxThumbnailFilesize: 64,
        parallelUploads: 1,
        paramName: 'cover_image',
        timeout: null,
        params: {
            temp_project_content_parent: fieldsMother?.id || '',
            temp_project_content_type: fieldsInline.temp_project_content_type || '',
            active: true,
            medium_type: 6,
            profile: fieldsMother?.profile?.id,
        },
    }
    const contentTypeOptions = [
        {
            text: reduxText[8140],
            value: 1,
        },
        {
            text: reduxText[8141],
            value: 6,
        },
    ]

    const newMediumType = mediumType || fieldsInline.medium_type
    const isDropzone = newMediumType?.id === 6

    return (
        <InlineFormContainerWeb
            extraListFilter={mediumType ? `?medium_types=${mediumType.id}${fieldsMother.medium_type?.id === 1 ? '&medium_types=3' : ''}${fieldsMother.medium_type?.id === 8 ? '&medium_types=9' : ''}` : ''}
            filters={!mediumType}
            formInfo={content}
            formState={formState}
            helpers={helpers}
            isDropzone={isDropzone}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {!mediumType && (
                <SelectListInput
                    apiUrl={`${select_url_main_medium_type_artwork}?single=True`}
                    clearable={false}
                    error={errorsInline?.medium_type}
                    label={reduxText[3228]}
                    name='medium_type'
                    onChange={onHandleInputChange}
                    value={fieldsInline.medium_type || ''}
                />
            )}
            {newMediumType && !fieldsInline.id && (
                <RadioInput
                    error={errorsInline?.temp_project_content_type}
                    helperText={reduxText[295310]}
                    label={reduxText[2953]}
                    name='temp_project_content_type'
                    onChange={onHandleInputChange}
                    options={contentTypeOptions}
                    value={fieldsInline.temp_project_content_type}
                />
            )}
            {newMediumType && fieldsInline.temp_project_content_type === 6 && (
                <SelectAsyncInput
                    apiUrl={`${select_url_project_content_project_child}${fieldsMother?.id}${mediumType ? '' : `?medium_type=${newMediumType.id}`}`}
                    clearable={false}
                    error={errorsInline?.project_child}
                    helperText={reduxText[375210]}
                    label={reduxText[3752]}
                    layout='project'
                    name='project_child'
                    onChange={onHandleInputChange}
                    optional
                    value={fieldsInline.project_child || ''}
                />
            )}

            {fieldsInline.temp_project_content_type && isDropzone && fieldsInline.temp_project_content_type !== 6 && (
                <div className='mo-image-input-web'>
                    <MainFormLabel
                        label={reduxText[1767]}
                        name='multiple-image'
                    />
                    <DropzoneComponent
                        config={config}
                        djsConfig={djsConfig}
                        eventHandlers={eventHandlers}
                    />
                </div>
            )}
            {(newMediumType && fieldsInline.temp_project_content_type === 1 && !isDropzone) && (
                <React.Fragment>
                    {conditionMainInformationWeb[newMediumType.id!].includes('link_url') && (
                        <TextAreaInput
                            canSkipDataRetrieve
                            error={errorsInline?.link_url}
                            helperText={reduxText[323010]}
                            label={reduxText[3230]}
                            name='link_url'
                            onChange={onHandleInputChange}
                            optional={requiredFields && !requiredFields.includes('link_url')}
                            value={fieldsInline.link_url || ''}
                        />
                    )}
                    {(newMediumType.id === mediumTypeConstants.work) && (
                        <ImageCropInput
                            altButton
                            altValue={fieldsInline.cover_image_alt}
                            doka_output_data={fieldsInline.cover_image_doka_output_data || ''}
                            error={errorsInline?.cover_image}
                            errorAlt={errorsInline?.cover_image_alt}
                            label={(newMediumType.id === mediumTypeConstants.work) ? reduxText[4478] : reduxText[6422]}
                            name='cover_image'
                            onChange={onHandleInputChange}
                            original={fieldsInline.cover_image_original || ''}
                            value={fieldsInline.cover_image || ''}
                        />
                    )}
                    {(newMediumType.id === mediumTypeConstants.pdf) && (
                        <ImageInput
                            error={errorsInline?.pdf_file}
                            label={reduxText[6602]}
                            name='pdf_file'
                            onChange={onHandleInputChange}
                            pdfOk
                            pdfOnly
                            text={reduxText[8302]}
                            translation={{
                                contentType: 'project',
                                defaultDetailUrl: content.detailUrl,
                                defaultLanguage: fieldsInline.translation_default_language,
                                defaultUpdateUrl: content.updateUrl,
                                objectId: fieldsInline.id,
                                profileId: fieldsMother.profile?.id,
                            }}
                            value={fieldsInline.pdf_file || ''}
                        />
                    )}
                    <TextInput
                        error={errorsInline?.name}
                        label={reduxText[2931]}
                        name='name'
                        onChange={onHandleInputChange}
                        translation={{
                            contentType: 'project',
                            defaultDetailUrl: content.detailUrl,
                            defaultLanguage: fieldsInline.translation_default_language,
                            defaultUpdateUrl: content.updateUrl,
                            objectId: fieldsInline.id,
                            profileId: fieldsMother.profile?.id,
                        }}
                        value={fieldsInline.name || ''}
                    />
                    {(newMediumType.id !== mediumTypeConstants.work) && (
                        <ImageCropInput
                            altButton
                            altValue={fieldsInline.cover_image_alt}
                            doka_output_data={fieldsInline.cover_image_doka_output_data || ''}
                            error={errorsInline?.cover_image}
                            errorAlt={errorsInline?.cover_image_alt}
                            label={(newMediumType.id === mediumTypeConstants.work) ? reduxText[4478] : reduxText[6422]}
                            meta_value={fieldsInline.meta_image_url || ''}
                            name='cover_image'
                            onChange={onHandleInputChange}
                            original={fieldsInline.cover_image_original || ''}
                            value={fieldsInline.cover_image || ''}
                        />
                    )}
                    <ImageCropSquareInput
                        doka_output_data={fieldsInline.cover_image_square_doka_output_data || ''}
                        error={errorsInline?.cover_image_square}
                        label={reduxText[6386]}
                        name='cover_image_square'
                        onChange={onHandleInputChange}
                        original={fieldsInline.cover_image_original || fieldsInline.cover_image || ''}
                        value={fieldsInline.cover_image_square || ''}
                    />
                </React.Fragment>
            )}
        </InlineFormContainerWeb>
    )
})
