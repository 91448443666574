// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    PortfolioUserGroupsFieldInput,
    StylesExtraFieldInput,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    CustomCategoryEventInput,
    CustomTextEventInput,
    CustomTextRichEventInput,
    IsPrivateEventInput,
    IsPrivateListedEventInput,
    IsUnlistedEventInput,
} from 'pages'

// serializers
import {
    EventFormState,
} from 'serializers/web'

// props
type PortfolioEventPanelProps = {
    formState: EventFormState
    handleInputChange: any
}

// main
export const PortfolioEventPanel: React.FC<PortfolioEventPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioEventPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <IsUnlistedEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <IsPrivateEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <IsPrivateListedEventInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <PortfolioUserGroupsFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
                profileId={fieldsMother.profile?.id}
                show={fieldsMother.is_private && fieldsMother.profile?.id === 183405}
            />
            <CustomCategoryEventInput
                ccKey={1}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            {/* <CustomCategoryEventInput
                ccKey={2}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomCategoryEventInput
                ccKey={3}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            /> */}
            <StylesExtraFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomTextEventInput
                ctKey={1}
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextEventInput
                ctKey={2}
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextEventInput
                ctKey={3}
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextRichEventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextRichEventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
                version={2}
            />
            <CustomTextRichEventInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
                version={3}
            />
        </React.Fragment>
    )
})
