// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    ContentListModalPortfolio,
} from 'pages'

// serializers
import {
    ContentSourceDetailHelperSerializer,
} from 'serializers/web'

// props
type ContentListInputPortfolioProps = {
    adminOnly?: boolean
    assetCount?: number
    contentSourceId: number
    contentTypeId?: number
    disabled?: boolean
    error: string | undefined
    filter?: boolean
    helperText?: string
    label: string
    mixedMedia?: boolean
    mixedMediaContentType?: contentTypeModelType
    name: string
    onChange: any
    optional?: boolean
    portfolioId: number | undefined
    profileId: number | undefined
    value: ContentSourceDetailHelperSerializer | ''
}

// main
export const ContentListInputPortfolio: React.FC<ContentListInputPortfolioProps> = React.memo(({
    adminOnly,
    assetCount,
    contentSourceId,
    contentTypeId,
    disabled,
    error,
    filter,
    helperText,
    label,
    mixedMedia,
    mixedMediaContentType,
    name,
    onChange,
    optional,
    portfolioId,
    profileId,
    value,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showModal, setShowModal] = useState(false)

    return (
        <div className={`mo-select-input-web${disabled ? ' disabled' : ''}`}>
            <MainFormLabel
                adminOnly={adminOnly}
                helperText={helperText}
                label={label}
                name={name}
                optional={optional}
            />
            <div
                className={`mo-text-input-web-input${disabled ? ' disabled' : ''}`}
                onClick={disabled ? undefined : () => setShowModal(true)}
            >
                {value ? reduxText[6394] : reduxText[5445]}
            </div>
            {showModal && (
                <ContentListModalPortfolio
                    assetCount={assetCount}
                    contentSourceId={contentSourceId}
                    contentTypeId={contentTypeId}
                    filter={filter}
                    initialValue={value}
                    label={label}
                    mixedMedia={mixedMedia}
                    mixedMediaContentType={mixedMediaContentType}
                    name={name}
                    onChange={onChange}
                    portfolioId={portfolioId}
                    profileId={profileId}
                    setIsOpen={setShowModal}
                />
            )}
            <ErrorHelper error={error} />
        </div>
    )
})
