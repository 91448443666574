// react components
import React from "react"
import axios from 'axios'

// data
import {
    api_url_move_content,
    contentTypeData,
    reduxAuthState,
    reduxFormSetRefresh,
    reduxModalErrorShowHide,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    BuilderService,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export async function handleMoveContent({
    component,
    dispatch,
    mixedMedia,
    object,
    paramsId,
    position,
    reduxAuth,
    setIsLoading,
    setIsPopoverOpen,
}: {
    component: string
    dispatch: React.Dispatch<any>
    mixedMedia: boolean
    object: PortfolioPageContentListSiteSerializer
    paramsId: string
    position: 'after' | 'before' | 'inside' | undefined
    reduxAuth: reduxAuthState
    setIsLoading: React.Dispatch<boolean>
    setIsPopoverOpen: React.Dispatch<boolean>
}) {
    const toMoveObject = BuilderService.moveGetContentObject()
    setIsLoading(true)
    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} handleMoveContent`)

    const postUrl = getApiUrl(api_url_move_content, reduxAuth)
    const formData = new FormData()
    formData.append('from_content_type_id', toMoveObject!.content_type.toString())
    formData.append('from_object_id', toMoveObject!.id!.toString())
    formData.append('to_content_type_id', contentTypeData[mixedMedia ? 'mixedmediacontent' : 'pagecontent'].toString())
    formData.append('to_object_id', object.id!.toString())
    if (!position || (position === 'inside')) {
        formData.append('to_parent_content_id', `${object.id}`)
    } else {
        formData.append('to_position_direction', position)
    }
    BuilderService.moveSetPreviousContentObject(toMoveObject)
    axios({
        data: formData,
        headers: refreshAxiosHeaders,
        method: 'post',
        url: postUrl,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            setIsLoading(false)
            setIsPopoverOpen(false)
            dispatch(reduxFormSetRefresh('refreshPreview', Number(paramsId)))
            BuilderService.moveDeleteContentObject()
        })
        .catch((error) => {
            if (error.response?.status === 400 && error.response.data.main_error) {
                dispatch(reduxModalErrorShowHide({
                    header: error.response.data.main_error,
                    isOpen: true,
                    noMessage: true,
                    source: `${component} handleMoveContent`,
                }))
            } else {
                axiosErrorHandler({
                    apiUrl: postUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'handleMoveContent',
                    skipSendError: Boolean(paramsId) && (error.response?.status === 404) && Boolean(error.response?.data?.detail),
                })
            }
        })
}
