// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextAreaInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ServiceDetailFormSerializer,
    ServiceDetailFormSerializerError,
    ServiceFormState,
} from 'serializers/web'

// props
type PriceServiceInputProps = {
    errors: ServiceDetailFormSerializerError | undefined
    fields: ServiceDetailFormSerializer
    mother: ServiceFormState['mother']
    onChange: any
}

// main
export const PriceServiceInput: React.FC<PriceServiceInputProps> = React.memo(({
    errors,
    fields,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextAreaInput
            error={errors?.price}
            label={reduxText[8417]}
            optional
            name='price'
            onChange={onChange}
            translation={{
                contentType: 'service',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            value={fields.price || ''}
        />
    )
})
