// react components
import React from 'react'

// components
import {
    CountViewBlock,
    InactiveButton,
} from 'components'

// pages
import {
    DescriptionContentBlockMobile,
    HeaderProjectDetailMobile,
    IframeContentBlockMobile,
    MixedContentBlock,
    ProjectContentBlockMobile,
    TeamContentBlockMobile,
    TrackContentBlockMobile,
    WorkContentBlockMobile,
    PdfContentBlockMobile,
} from 'pages'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// props
type ProjectDetailBlockMobileProps = {
    edit: boolean
    isWeb?: boolean
    languageId: number | undefined
    modalKeys: {
        article_list: number,
        track_list: number,
        video_list: number,
    }
    object: ProjectDetailSerializer
}

// main
export const ProjectDetailBlockMobile: React.FC<ProjectDetailBlockMobileProps> = React.memo(({
    edit,
    isWeb,
    languageId,
    modalKeys,
    object,
}) => {

    let component: 'iframe' | 'mixed' | 'normal' | 'pdf' = 'normal'
    if (object.medium_type) {
        if (object.medium_type.id === 7 && object.link_url) {
            component = 'iframe'
        } else if (object.medium_type.id === 9) {
            component = 'mixed'
        } else if (object.medium_type.id === 20) {
            component = 'pdf'
        }
    }

    return (
        <div className='project-detail-block-mobile'>
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='project'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            {component === 'iframe' && (
                <IframeContentBlockMobile
                    edit={edit}
                    object={object}
                />
            )}
            {component === 'mixed' && (
                <MixedContentBlock
                    contentType='project'
                    edit={edit}
                    languageId={languageId}
                    object={object}
                />
            )}
            {component === 'normal' && (
                <ProjectContentBlockMobile
                    edit={edit}
                    isWeb={isWeb}
                    modalKeys={modalKeys}
                    object={object}
                />
            )}
            {component === 'pdf' && (
                <PdfContentBlockMobile
                    edit={edit}
                    object={object}
                />
            )}
            <HeaderProjectDetailMobile
                edit={edit}
                isWeb={isWeb}
                object={object}
            />
            <DescriptionContentBlockMobile
                edit={edit}
                object={object}
            />
            {object.medium_type?.id !== 9 && (
                <React.Fragment>
                    {object.medium_type?.id !== 1 && (
                        <TrackContentBlockMobile
                            edit={edit}
                            modalKey={modalKeys.track_list}
                            object={object}
                        />
                    )}
                    {object.medium_type?.id !== 5 && (
                        <WorkContentBlockMobile
                            edit={edit}
                            object={object}
                        />
                    )}
                </React.Fragment>
            )}
            <TeamContentBlockMobile
                edit={edit}
                object={object}
            />
            {object.owner_info && (
                <CountViewBlock
                    object={object.owner_info}
                />
            )}
        </div>
    )
})
