// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProductSalesRecapListSerializer,
} from 'serializers/web'

// props
type ProductSalesRecapRowHeaderProps = {
    object: ProductSalesRecapListSerializer | undefined
}

// main
export const ProductSalesRecapRowHeader: React.FC<ProductSalesRecapRowHeaderProps> = React.memo(({
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!object) return null

    return (
        <tr className='main-list-header ProductSalesRecapRowHeader'>
            <th>{reduxText[5559]}</th>
            <th>{reduxText[8637]}</th>
            <th>{reduxText[8553]}</th>
        </tr>
    )
})
