// react components
import React, {
    useState,
} from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    CheckboxInput,
    IconBlock,
    Loader,
    SelectAsyncInput,
    SelectListInput,
} from 'components'

// data
import {
    api_url_copy_mixed_content_to_mixed_page,
    api_url_copy_mixed_content_to_portfolio_page,
    api_url_copy_portfolio_content_to_mixed_page,
    api_url_copy_portfolio_content_to_portfolio_page,
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetRefresh,
    reduxFormSetSectionIdSite,
    reduxModalErrorEventHandler,
    select_url_portfolio,
    select_url_portfolio_content_source_event,
    select_url_portfolio_content_source_member,
    select_url_portfolio_content_source_offer,
    select_url_portfolio_content_source_page,
    select_url_portfolio_content_source_product,
    select_url_portfolio_content_source_project,
    select_url_portfolio_content_source_service,
    select_url_portfolio_page_link,
    select_url_profile,
    view_url_block_template_form,
    view_url_event_form,
    view_url_member_form,
    view_url_offer_form,
    view_url_portfolio_form,
    view_url_product_form,
    view_url_project_form,
    view_url_service_form,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
    detailId?: string
    pageSlug: string
}

type DuplicateContentHelperProps = {
    contentType: contentTypeModelType
    defaultPortfolio: MainIdNameSerializer | undefined
    isButton?: boolean
    mainParentId?: number
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    objectId: number
    profile: MainIdNameSerializer
    setIsOpen?: React.Dispatch<boolean>
    setIsPopoverOpen?: React.Dispatch<boolean>
}

// main
export const DuplicateContentHelper: React.FC<DuplicateContentHelperProps> = React.memo(({
    contentType,
    defaultPortfolio,
    isButton,
    mainParentId,
    newMixedMediaContent,
    newMixedMediaContentType,
    objectId,
    profile,
    setIsOpen,
    setIsPopoverOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const contentTypeOptions: {
        id: number
        apiUrl: string
        contentSourceId: number | undefined
        formUrlOption: string
        layout?: 'get_image_lq'
        mediumType: number | undefined
        name: string
    }[] = [
            {
                id: contentTypeData.page,
                apiUrl: select_url_portfolio_content_source_page,
                contentSourceId: 35,
                formUrlOption: view_url_portfolio_form,
                mediumType: undefined,
                layout: 'get_image_lq',
                name: reduxText[6265],
            },
            {
                id: contentTypeData.project,
                apiUrl: select_url_portfolio_content_source_project,
                contentSourceId: 1,
                formUrlOption: view_url_project_form,
                layout: 'get_image_lq',
                mediumType: 9,
                name: reduxText[8476],
            },
            {
                id: contentTypeData.event,
                apiUrl: select_url_portfolio_content_source_event,
                contentSourceId: 5,
                formUrlOption: view_url_event_form,
                mediumType: 25,
                layout: 'get_image_lq',
                name: reduxText[2433],
            },
            {
                id: contentTypeData.product,
                apiUrl: select_url_portfolio_content_source_product,
                contentSourceId: 14,
                formUrlOption: view_url_product_form,
                mediumType: 28,
                layout: 'get_image_lq',
                name: reduxText[8499],
            },
            {
                id: contentTypeData.service,
                apiUrl: select_url_portfolio_content_source_service,
                contentSourceId: 27,
                formUrlOption: view_url_service_form,
                mediumType: 29,
                layout: 'get_image_lq',
                name: reduxText[7434],
            },
            {
                id: contentTypeData.offer,
                apiUrl: select_url_portfolio_content_source_offer,
                contentSourceId: 9,
                formUrlOption: view_url_offer_form,
                mediumType: 27,
                layout: 'get_image_lq',
                name: reduxText[302],
            },
            {
                id: contentTypeData.profileteam,
                apiUrl: select_url_portfolio_content_source_member,
                contentSourceId: 15,
                formUrlOption: view_url_member_form,
                mediumType: 33,
                layout: 'get_image_lq',
                name: reduxText[3077],
            },
        ]
    if (reduxAuth.settings?.user?.id === 1) {
        contentTypeOptions.push(
            {
                id: contentTypeData.blocktemplate,
                apiUrl: select_url_portfolio_content_source_project,
                contentSourceId: 1,
                formUrlOption: view_url_block_template_form,
                mediumType: 9,
                layout: 'get_image_lq',
                name: 'Block templates',
            },
        )
    }

    const projectTypeOptions = [
        { id: '1', name: reduxText[3528] },
        { id: '2', name: reduxText[4812] },
        { id: '3', name: reduxText[8187] },
    ]

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [contentTypeInstance, setContentTypeInstance] = useState<{
        id: number
        apiUrl: string
        contentSourceId: number | undefined
        formUrlOption: string
        layout?: 'get_image_lq'
        mediumType: number | undefined
        name: string
    }>(contentTypeOptions.find(obj => obj.id === contentTypeData[contentType!])!)
    const [contentObject, setContentObject] = useState<MainIdNameSerializer>()
    const [contentObjectError, setContentObjectError] = useState('')
    const [contentPortfolio, setContentPortfolio] = useState<MainIdNameSerializer | undefined>(defaultPortfolio)
    const [isLoading, setIsLoading] = useState(false)
    const [profileObject, setProfileObject] = useState<MainIdNameSerializer | undefined>(profile)
    const [projectType, setProjectType] = useState<any>(projectTypeOptions[0])
    const [withTranslation, setWithTranslation] = useState(false)

    function getContentTypeInstance() {
        if (contentTypeInstance) {
            return contentTypeOptions.find(obj => obj.id === contentTypeInstance.id)
        }
        return undefined
    }

    async function handleDuplicate() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DuplicateContentHelper handleDuplicate')

            let postUrl = ''
            const formData: any = new FormData()
            if (contentType === 'page') {
                if (contentTypeInstance!.id === contentTypeData.page) {
                    postUrl = api_url_copy_portfolio_content_to_portfolio_page
                    //@ts-ignore
                    if (contentObject?.isNew) {
                        formData.append('from_portfolio_content_id', objectId)
                        formData.append('to_name', contentObject.name!)
                        formData.append('to_portfolio_id', contentPortfolio!.id!)
                    } else {
                        formData.append('from_portfolio_content_id', objectId)
                        if (contentObject?.id) {
                            formData.append('to_portfolio_page_id', contentObject.id)
                        }
                    }
                } else {
                    postUrl = api_url_copy_portfolio_content_to_mixed_page
                    if (!contentObject) {
                        setContentObjectError(reduxText[2856])
                        setIsLoading(false)
                        return
                    }
                    //@ts-ignore
                    if (contentObject?.isNew) {
                        formData.append('from_portfolio_content_id', objectId)
                        formData.append('to_content_type_id', contentTypeInstance!.id)
                        formData.append('to_medium_type_id', contentTypeInstance!.mediumType!)
                        formData.append('to_name', contentObject.name!)
                        formData.append('to_profile_id', (profileObject?.id || profile!.id!))
                        if (contentTypeInstance!.id === contentTypeData.project) {
                            formData.append('to_project_type', projectType.id)
                        }
                    } else {
                        formData.append('from_portfolio_content_id', objectId)
                        formData.append('to_content_type_id', contentTypeInstance!.id)
                        formData.append('to_mixed_page_id', contentObject.id!)
                    }
                }
            } else {
                if (contentTypeInstance!.id === contentTypeData.page) {
                    postUrl = api_url_copy_mixed_content_to_portfolio_page
                    if (!contentObject) {
                        setContentObjectError(reduxText[2856])
                        setIsLoading(false)
                        return
                    }
                    //@ts-ignore
                    if (contentObject?.isNew) {
                        formData.append('from_mixed_content_id', objectId)
                        formData.append('to_name', contentObject.name!)
                        formData.append('to_portfolio_id', contentPortfolio!.id!)
                    } else {
                        formData.append('from_mixed_content_id', objectId)
                        formData.append('to_portfolio_page_id', contentObject.id!)
                    }
                } else {
                    postUrl = api_url_copy_mixed_content_to_mixed_page
                    //@ts-ignore
                    if (contentObject?.isNew) {
                        formData.append('from_mixed_content_id', objectId)
                        formData.append('to_content_type_id', contentTypeInstance!.id)
                        formData.append('to_medium_type_id', contentTypeInstance!.mediumType!)
                        formData.append('to_name', contentObject.name!)
                        formData.append('to_profile_id', (profileObject?.id || profile!.id!))
                        if (contentTypeInstance!.id === contentTypeData.project) {
                            formData.append('to_project_type', projectType.id)
                        }
                    } else {
                        formData.append('from_mixed_content_id', objectId)
                        formData.append('to_content_type_id', contentTypeInstance!.id)
                        formData.append('to_mixed_page_id', (contentObject?.id || params.id))
                    }
                }
            }
            if (withTranslation) {
                formData.append('duplicate_with_translation', 'true')
            }
            postUrl = getApiUrl(postUrl, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (setIsPopoverOpen) setIsPopoverOpen!(false)
                    if (setIsOpen) setIsOpen(false)
                    if (contentTypeInstance!.id === contentTypeData.page) {
                        history.push(`${view_url_portfolio_form}${response.data.portfolio}/content-container/${response.data.new_portfolio_content}/${response.data.slug}/${params.detailId ? `${params.detailId}/` : ''}`)
                        if (response.data.slug === params.pageSlug) {
                            dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                        }
                    } else {
                        if (newMixedMediaContent) {
                            history.push(`/form-mixed-media/${newMixedMediaContentType}/${response.data.id}/${response.data.new_mixed_content}/`)
                        } else {
                            history.push(`${contentTypeInstance!.formUrlOption}${response.data.id}/mixed-media-content/${response.data.new_mixed_content}/`)
                        }
                        if (response.data.id === Number(params.id)) {
                            dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                        }
                    }
                    if (mainParentId) {
                        dispatch(reduxFormSetSectionIdSite(mainParentId))
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (error.response?.data?.name) {
                        setContentObjectError(error.response.data.name)
                    } else {
                        axiosErrorHandler({
                            apiUrl: postUrl,
                            component: 'DuplicateContentHelper',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleDuplicate',
                        })
                    }
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'DuplicateContentHelper',
                'handleDuplicate',
            ))
        }
    }

    if (isButton) {
        return (
            <button
                className='shpcwp-item-button'
                onClick={handleDuplicate}
            >
                <span className='shpcwp-item-button-text'>{reduxText[6435]}</span>
                {isLoading && (
                    <Loader isOpen />
                )}
            </button>
        )
    }

    return (
        <Dialog
            className='mo-dialog-class DuplicateContentHelper'
            classes={{ paper: 'mo-dialog-wrapper fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen!(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[6436]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen!(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <SelectListInput
                    options={contentTypeOptions}
                    clearable={false}
                    error={undefined}
                    inModal
                    label={reduxText[8455]}
                    name='contentTypeInstance'
                    onChange={(e: any) => {
                        setContentTypeInstance(e.value)
                        setContentObject(undefined)
                    }}
                    value={contentTypeInstance || ''}
                />
                {(contentTypeInstance?.id !== contentTypeData.page) && getContentTypeInstance()?.apiUrl && (
                    <React.Fragment>
                        {reduxAuth.settings?.user?.is_staff && (
                            <SelectAsyncInput
                                apiUrl={select_url_profile}
                                clearable
                                error={undefined}
                                label={reduxText[1260]}
                                layout='profile'
                                name='profile'
                                onChange={(e: any) => {
                                    setProfileObject(e.value)
                                    setButtonDisabled(false)
                                    setContentObjectError('')
                                }}
                                staffOnly
                                value={profileObject}
                            />
                        )}
                        <SelectAsyncInput
                            apiUrl={`${getContentTypeInstance()!.apiUrl}?profile_id=${profileObject?.id}${(reduxAuth.settings?.user?.is_staff && !profileObject?.id) ? '&is_staff=true' : ''}${getContentTypeInstance()!.mediumType ? `&medium_type_id=${getContentTypeInstance()!.mediumType}` : ''}`}
                            cache={false}
                            clearable={false}
                            creatable
                            error={contentObjectError}
                            label={getContentTypeInstance()!.name}
                            layout='project'
                            name='contentObject'
                            onChange={(e: any) => {
                                setContentObject(e.value)
                                setButtonDisabled(false)
                            }}
                            optional
                            value={contentObject || ''}
                        />
                    </React.Fragment>
                )}
                {(contentTypeInstance?.id === contentTypeData.page) && (
                    <React.Fragment>
                        <SelectListInput
                            apiUrl={select_url_portfolio}
                            clearable={false}
                            error={undefined}
                            inModal
                            label={reduxText[7688]}
                            layout='portfolio'
                            name='contentPortfolio'
                            onChange={(e: any) => {
                                setContentPortfolio(e.value)
                                setContentObject(undefined)
                                setContentObjectError('')
                            }}
                            value={contentPortfolio || ''}
                        />
                        {contentPortfolio && (
                            <SelectListInput
                                apiUrl={`${select_url_portfolio_page_link}?portfolio_id=${contentPortfolio.id}&show_all=True`}
                                clearable={false}
                                creatable
                                error={contentObjectError}
                                inModal
                                label={reduxText[7690]}
                                name='contentObject'
                                onChange={(e: any) => {
                                    setContentObject(e.value)
                                    setButtonDisabled(false)
                                    setContentObjectError('')
                                }}
                                value={contentObject || ''}
                            />
                        )}
                    </React.Fragment>
                )}
                {/* @ts-ignore */}
                {(contentObject?.isNew && (contentTypeInstance?.id === contentTypeData.project)) && (
                    <SelectListInput
                        clearable={false}
                        error={undefined}
                        inModal
                        label={reduxText[8455]}
                        name='project_type'
                        onChange={(e: any) => {
                            setProjectType(e.value)
                            setButtonDisabled(false)
                            setContentObjectError('')
                        }}
                        options={projectTypeOptions}
                        value={projectType}
                    />
                )}
                <CheckboxInput
                    error={undefined}
                    label={reduxText[8477]}
                    name='duplicate_with_translation'
                    onChange={(e: any) => {
                        setWithTranslation(e.value)
                        setButtonDisabled(false)
                        setContentObjectError('')
                    }}
                    value={withTranslation}
                />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen!(false)}
                    text={reduxText[4519]}
                />
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    fill='outline'
                    onClick={handleDuplicate}
                    text={reduxText[4520]}
                />
            </DialogActions>
            {isLoading && (
                <Loader isOpen />
            )}
        </Dialog>
    )
})
