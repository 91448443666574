// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    FormContentBlock,
} from 'pages'

// serializers
import {
    ContentTypeFormTypes,
    ContentTypeSettingsCustomTypes,
    ContentTypeSettingsTypes,
} from 'serializers/web'

// services
import {
    getReduxTextId,
} from 'services'

// props
type FormContentProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes
    formState: ContentTypeFormTypes
    getMetaIsLoading?: boolean
    handleInputChange: (event: any, newFormState: ContentTypeFormTypes) => void
    noTabs?: boolean
    object: any
    onKeyPress: any
    verticalStyle?: boolean
}

// main
export const FormContent: React.FC<FormContentProps> = React.memo(({
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    formState,
    getMetaIsLoading,
    handleInputChange,
    noTabs,
    object,
    onKeyPress,
    verticalStyle,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setTab(panelsOrdered[0].id)
    }, [
        object.sub_content_type,
    ])

    const panels = (formState.structure.panels.length > 0 && formState.structure.panels) || contentTypeSettings.form.structure.panels
    const panelsSubContentType: any = []
    if (object.sub_content_type && contentType !== 'setup_moduleview') {
        if (contentTypeSettingsCustom?.form?.panelsSubContentType?.[object.sub_content_type.id!]) {
            panelsSubContentType.push(...contentTypeSettingsCustom?.form?.panelsSubContentType?.[object.sub_content_type.id!]?.panels || [])
        } else {
            panelsSubContentType.push(...contentTypeSettings.form?.panelsSubContentType?.[object.sub_content_type.id!]?.panels || [])
        }
        // @ts-ignore
        panelsSubContentType.push(panels?.find(obj => obj.title === 'reduxText[9340]'))
        // @ts-ignore
        panelsSubContentType.push(panels?.find(obj => obj.title === 'reduxText[9736]'))
    }
    const panelsOrdered = panelsSubContentType.length > 0 ? panelsSubContentType : panels

    const [tab, setTab] = useState<(number)>(panelsOrdered[0].id)

    const tabData = contentTypeSettingsCustom?.form?.panelAdaptation?.[tab] || panelsOrdered.find((obj: any) => obj.id === tab)

    return (
        <div className='form-component'>
            {!noTabs && (
                <div className='fc-tabs'>
                    <div className='fc-buttons'>
                        {panelsOrdered.map((val: any) => {
                            if (val.panelOptions?.hideIfNotStaff && !reduxAuth.settings?.user?.is_staff) return null
                            if (val.panelOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) return null
                            if (reduxAuth.settings?.user?.id !== 1 && ['reduxText[9340]', 'reduxText[9736]'].includes(val.title)) return null
                            return (
                                <Button
                                    key={val.id}
                                    className={(tab === val.id) ? 'fc-active' : ''}
                                    color={val.title ? undefined : 'danger'}
                                    edit={false}
                                    fill='clear'
                                    justifyText='flex-start'
                                    onClick={() => setTab(val.id)}
                                    size='small'
                                    text={getReduxTextId(val.title || 'Error: Missing tab title', reduxText)!}
                                />
                            )
                        })}
                        {object.is_custom_page && (
                            <Button
                                edit={false}
                                fill='clear'
                                href={`form-mixed-media/${contentType}/${object.id}/`}
                                justifyText='flex-start'
                                size='small'
                                text={reduxText[9978]}
                            />
                        )}
                    </div>
                    {/* {reduxAuth.settings?.user?.id === 1 && (
                    <IconBlock
                        edit={false}
                        iconClass='mo-new-icon-globe-solid'
                        onClick={() => onClickIsModal(tab, Math.random(), dispatch)}
                        to={getModalAbsoluteUrlV2('setup_contenttypepanel', tab, 'detail')}
                        tooltipText={reduxText[9373]}
                    />
                )} */}
                </div>
            )}
            <FormContentBlock
                contentType={contentType}
                contentTypeSettings={contentTypeSettings}
                contentTypeSettingsCustom={contentTypeSettingsCustom}
                formState={formState}
                getMetaIsLoading={getMetaIsLoading}
                handleInputChange={handleInputChange}
                object={object}
                onKeyPress={onKeyPress}
                tab={tab}
                tabData={tabData}
                verticalStyle={verticalStyle}
            />
        </div>
    )
})
