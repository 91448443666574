// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// main
export const EventPricingListFormHeader: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <tr className='main-list-header EventPricingListFormHeader'>
            <th>{reduxText[5559]}</th>
            <th>{reduxText[8571]}</th>
            <th>{reduxText[8570]}</th>
            <th>{reduxText[4383]}</th>
            <th />
        </tr>
    )
}
