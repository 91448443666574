// react components
import React, {
    useState,
} from 'react'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    contentTypeModelType,
} from 'data'

// pages
import {
    ContentDetailModalPortfolio,
} from 'pages'

// serializers
import {
    ContentSourceDetailHelperSerializer,
} from 'serializers/web'

// props
type ContentDetailInputPortfolioProps = {
    adminOnly?: boolean
    assetCount?: number
    contentSourceId: number
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label: string
    mixedMediaContentType?: contentTypeModelType
    name: string
    noClear?: boolean
    onChange: any
    optional?: boolean
    portfolioId: number | undefined
    profileId: number | undefined
    value: ContentSourceDetailHelperSerializer | ''
}

// main
export const ContentDetailInputPortfolio: React.FC<ContentDetailInputPortfolioProps> = React.memo(({
    adminOnly,
    assetCount,
    contentSourceId,
    disabled,
    error,
    helperText,
    label,
    mixedMediaContentType,
    name,
    noClear,
    onChange,
    optional,
    portfolioId,
    profileId,
    value,
}) => {

    const [showModal, setShowModal] = useState(false)

    return (
        <div className={`content-detail-input-portfolio-web${disabled ? ' disabled' : ''}`}>
            <MainFormLabel
                adminOnly={adminOnly}
                helperText={helperText}
                label={label}
                name={name}
                optional={optional}
            />
            <div
                className='cdipw-input-wrap'
            >
                <div
                    className='cdipw-input'
                    onClick={disabled ? undefined : () => setShowModal(true)}
                >
                    <p>{value && value?.name}</p>
                </div>
                {!disabled && !noClear && (
                    <i
                        className='mo-new-icon-circle-xmark-regular cdipw-clear'
                        onClick={() => {
                            onChange({
                                name: name,
                                value: '',
                            })
                        }}
                    />
                )}
            </div>
            <ErrorHelper error={error} />
            {showModal && (
                <ContentDetailModalPortfolio
                    assetCount={assetCount}
                    contentSourceId={contentSourceId}
                    disabled={disabled}
                    label={label}
                    mixedMediaContentType={mixedMediaContentType}
                    name={name}
                    onChange={onChange}
                    portfolioId={portfolioId}
                    profileId={profileId}
                    setIsOpen={setShowModal}
                    value={value}
                />
            )}
        </div>
    )
})
