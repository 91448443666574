// react components
import React from 'react'

// props
type InputSeparatorProps = {
    text: string
}

// main
export const InputSeparator: React.FC<InputSeparatorProps> = React.memo(({
    text,
}) => {
    return (
        <span className='input-separator'>{text}</span>
    )
})
