// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormSetDataSite,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    PortfolioPageContentFormState,
    ProjectMixedMediaContentFormState,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
}

type PublishContentHelperProps = {
    child: boolean
    mixedMedia: boolean
    object: PortfolioPageContentListSiteSerializer
    setIsPopoverOpen: React.Dispatch<boolean>
}

// main
export const PublishContentHelper: React.FC<PublishContentHelperProps> = React.memo(({
    child,
    mixedMedia,
    object,
    setIsPopoverOpen,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)

    const content = PortfolioPageContentFormState(reduxAuth)
    const mixedMediaContent = ProjectMixedMediaContentFormState(reduxAuth)

    async function handlePublish(active?: boolean) {
        try {
            if (object) {
                setIsLoading(true)
                setIsPopoverOpen(false)
                const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PublishContentHelper handlePublish')
                const formData = new FormData()
                formData.append('active', (!active).toString())
                const activeUrl = `${mixedMedia ? mixedMediaContent.activeUrl : content.activeUrl}${object.id}/`
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'put',
                    url: activeUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                        if (child) {
                            dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                        } else if (reduxFormSite.pageContentItems) {
                            const newArray: any = []
                            reduxFormSite.pageContentItems.map((val: any) => {
                                if (val.id === object.id) {
                                    val.active = response.data.active
                                }
                                newArray.push(val)
                                return false
                            })
                            dispatch(reduxFormSetDataSite('pageContentItems', newArray))
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        axiosErrorHandler({
                            apiUrl: activeUrl,
                            component: 'PublishContentHelper',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handlePublish',
                            skipSend404Detail: true,
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PublishContentHelper',
                'handlePublish',
            ))
        }
    }

    return (
        <React.Fragment>
            <button
                className='shpcwp-item-button'
                onClick={() => handlePublish(object.active)}
            >
                <i className={object.active ? 'mo-new-icon-eye-slash-solid' : 'mo-new-icon-eye-solid'} />
                <span className='shpcwp-item-button-text'>{object.active ? reduxText[3517] : reduxText[3518]}</span>
            </button>
            {isLoading && (
                <Loader isOpen />
            )}
        </React.Fragment>
    )
})
