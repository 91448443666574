// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    PortfolioUserGroupsFieldInput,
    StylesExtraFieldInput,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    CustomCategoryServiceInput,
    CustomTextRichServiceInput,
    CustomTextServiceInput,
    IsPrivateListedServiceInput,
    IsPrivateServiceInput,
    IsUnlistedServiceInput,
} from 'pages'

// serializers
import {
    ServiceFormState,
} from 'serializers/web'

// props
type PortfolioServicePanelProps = {
    formState: ServiceFormState
    handleInputChange: any
}

// main
export const PortfolioServicePanel: React.FC<PortfolioServicePanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioServicePanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <IsUnlistedServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <IsPrivateServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <IsPrivateListedServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <PortfolioUserGroupsFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
                profileId={fieldsMother.profile?.id}
                show={fieldsMother.is_private && fieldsMother.profile?.id === 183405}
            />
            <CustomCategoryServiceInput
                ccKey={1}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            {/* <CustomCategoryServiceInput
                ccKey={2}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomCategoryServiceInput
                ccKey={3}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            /> */}
            <StylesExtraFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomTextServiceInput
                ctKey={1}
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextServiceInput
                ctKey={2}
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextServiceInput
                ctKey={3}
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextRichServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextRichServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
                version={2}
            />
        </React.Fragment>
    )
})
