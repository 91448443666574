// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    ListSpinner,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    select_url_main_font,
} from 'data'

// pages
import {
    SelectListAsyncInputV2,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getMainErrorMessage,
} from 'services'

// props
type MatchParams = {
    id: string
}

type FontPortfolioPanelProps = {
    formState: PortfolioFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    setFormState: React.Dispatch<PortfolioFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const FontPortfolioPanel: React.FC<FontPortfolioPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSitepermissions = useSelector((state: defaultReduxState) => state.reduxFormSite.permissions)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    useEffect(() => {
        onGetData()
    }, [
        params.id,
        reduxAuth.apiRootUrl,
    ])

    const [isLoading, setIsLoading] = useState(false)

    async function onGetData() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'FontPortfolioPanel onGetData')

            const getUrl = `${mother.detailUrl}${params.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            fields: response.data,
                        }
                    })
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'FontPortfolioPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onGetData',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'FontPortfolioPanel',
                'onGetData',
            ))
        }
    }

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FontPortfolioPanel',
                'onHandleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: mother,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    mother: {
                        ...mother,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FontPortfolioPanel',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'FontPortfolioPanel handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            const axiosUrl = `${mother.updateUrl}${mother.fields?.id}/`
            const formData: any = getFormData(mother)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    dispatch(reduxFormSetRefresh('refreshPreview', mother.fields?.id))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: {},
                            fields: {
                                ...formState.mother.fields,
                                id: response.data.id,
                                slug: response.data.slug,
                            },
                        }
                    })
                    dispatch(reduxFormSetEditingSite(false))
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: reduxText[4798],
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'FontPortfolioPanel',
                        dispatch,
                        error,
                        formFields: JSON.stringify(mother.fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'FontPortfolioPanel',
                'handleSubmit',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    if (isLoading) {
        return (
            <ListSpinner
                color='tertiary'
                isLoading
            />
        )
    }

    if (reduxFormSitepermissions?.permissionIsDisabledRest) {
        return (
            <div style={{
                padding: '20px',
            }}>
                <p>{reduxText[9975]}</p>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className={`pfcw-container-fields mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <SelectListAsyncInputV2
                    apiUrl={`${select_url_main_font}?profile_id=${fieldsMother.profile?.id}`}
                    clearable={false}
                    error={errorsMother?.fonts}
                    helperText={reduxText[627610]}
                    label={reduxText[6276]}
                    multi
                    name='fonts'
                    onChange={onHandleInputChange}
                    v1
                    value={fieldsMother.fonts || ''}
                />
            </div>
            <div className='pfcw-footer2'>
                <Button
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    onClick={handleRequired}
                    text={reduxText[6378]}
                />
            </div>
        </React.Fragment>
    )
})
