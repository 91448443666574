// react components
import axios from 'axios'
import {
    DeviceInfo,
} from '@capacitor/device'

// data
import {
    api_url_auth_settings_detail,
    api_url_auth_token_convert,
    api_url_auth_token_obtain,
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// constants
export const REDUX_AUTH_AFTER_AUTH_URL_SET = 'REDUX_AUTH_AFTER_AUTH_URL_SET'
export const REDUX_AUTH_DEVICE_INFO = 'REDUX_AUTH_DEVICE_INFO'
export const REDUX_AUTH_FETCH_ACTIONS = 'REDUX_AUTH_FETCH_ACTIONS'
export const REDUX_AUTH_FETCH_SETTINGS = 'REDUX_AUTH_FETCH_SETTINGS'
export const REDUX_AUTH_GOOGLE_AUTH_FULFILLED = 'REDUX_AUTH_GOOGLE_AUTH_FULFILLED'
export const REDUX_AUTH_GOOGLE_AUTH_REJECTED = 'REDUX_AUTH_GOOGLE_AUTH_REJECTED'
export const REDUX_AUTH_LOGIN_FULFILLED = 'REDUX_AUTH_LOGIN_FULFILLED'
export const REDUX_AUTH_LOGIN_REJECTED = 'REDUX_AUTH_LOGIN_REJECTED'
export const REDUX_AUTH_LOGOUT = 'REDUX_AUTH_LOGOUT'
export const REDUX_AUTH_SET_API_ROOT_URL = 'REDUX_AUTH_SET_API_ROOT_URL'
export const REDUX_AUTH_SET_IS_FIRST_LOADED = 'REDUX_AUTH_SET_IS_FIRST_LOADED'

export function reduxAuthAfterAuthUrlSet(url: string) {
    return {
        type: REDUX_AUTH_AFTER_AUTH_URL_SET,
        payload: url,
    }
}

export function reduxAuthDeviceInfo(deviceInfo: DeviceInfo) {
    return {
        type: REDUX_AUTH_DEVICE_INFO,
        payload: deviceInfo,
    }
}

export function reduxAuthLogin(
    username: string,
    password: string,
    reduxAuth: reduxAuthState,
    dispatchVar: React.Dispatch<any>,
    setIsLoading: React.Dispatch<boolean>,
    setFields: any,
    fieldsInitial: any,
    setErrors?: React.Dispatch<any>,
) {
    return function (dispatch: any) {
        const postUrl = getApiUrl(api_url_auth_token_obtain, reduxAuth)
        const formData = new FormData()
        formData.append('username', username.toLowerCase().trim())
        formData.append('password', password)
        axios({
            data: formData,
            method: 'post',
            url: postUrl,
        })
            .then((response) => {
                setIsLoading(false)
                setFields(fieldsInitial)
                dispatchVar(reduxAuthApiRootUrl(response.data.settings.language, reduxAuth))
                dispatch({
                    type: REDUX_AUTH_LOGIN_FULFILLED,
                    payload: response.data,
                })
            })
            .catch((error) => {
                dispatch({
                    type: REDUX_AUTH_LOGIN_REJECTED,
                    payload: error,
                })
                if (setErrors) setErrors(error.response?.data)
                setIsLoading(false)
                axiosErrorHandler({
                    apiUrl: postUrl,
                    component: 'reduxAuthLogin',
                    dispatch: dispatchVar,
                    error,
                    reduxAuth,
                    reference: `post ${username}`,
                    skip401: true,
                    skipAlert: true,
                    skipSendError: Boolean((error.response?.status === 401) && error.response?.data?.detail),
                })
            })
    }
}

export function reduxAuthGoogle(
    idToken: string,
    refreshToken: string,
    reduxAuth: reduxAuthState,
    dispatchVar: React.Dispatch<any>,
    setIsLoading: React.Dispatch<boolean>,
) {
    return function (dispatch: any) {
        const postUrl = getApiUrl(api_url_auth_token_convert, reduxAuth)
        const formData = new FormData()
        formData.append('provider', 'google')
        formData.append('token', idToken)
        axios({
            data: formData,
            method: 'post',
            url: postUrl,
        })
            .then((response) => {
                setIsLoading(false)
                dispatchVar(reduxAuthApiRootUrl(response.data.settings.language, reduxAuth))
                dispatch({
                    type: REDUX_AUTH_GOOGLE_AUTH_FULFILLED,
                    payload: response.data,
                    refresh_token: refreshToken,
                })
            })
            .catch((error) => {
                dispatch({
                    type: REDUX_AUTH_GOOGLE_AUTH_REJECTED,
                    payload: error,
                })
                setIsLoading(false)
                axiosErrorHandler({
                    apiUrl: postUrl,
                    component: 'reduxAuthGoogle',
                    dispatch: dispatchVar,
                    error,
                    reduxAuth,
                    reference: 'post',
                    skipSendErrorStatusCode: [403],
                })
            })
    }
}

export function reduxAuthLogout(reload?: boolean) {
    return {
        type: REDUX_AUTH_LOGOUT,
        payload: reload,
    }
}

export function reduxAuthFetchSettings(reduxAuth: reduxAuthState, refreshAxiosHeaders: any, dispatch2: any) {
    return function (dispatch: any) {
        if (!reduxAuth.authenticated) return
        const getUrl = getApiUrl(api_url_auth_settings_detail, reduxAuth)
        axios({
            headers: refreshAxiosHeaders,
            method: 'get',
            url: getUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log('reduxAuthFetchSettings', response.data)
                dispatch({
                    type: REDUX_AUTH_FETCH_SETTINGS,
                    payload: response.data,
                })
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: getUrl,
                    component: 'reduxAuthFetchSettings',
                    dispatch: dispatch2,
                    error,
                    reduxAuth,
                    reference: 'get',
                })
            })
    }
}

export function reduxAuthApiRootUrl(languageCode: string, reduxAuth: reduxAuthState) {
    let languageCodeToReturn = 'en'
    if (languageCode) {
        if (languageCode.includes('fr')) {
            languageCodeToReturn = 'fr'
        }
    }
    return {
        type: REDUX_AUTH_SET_API_ROOT_URL,
        payload: languageCodeToReturn,
    }
}

export function reduxAuthSetIsFirstLoaded() {
    return {
        type: REDUX_AUTH_SET_IS_FIRST_LOADED,
    }
}
