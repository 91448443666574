// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CheckboxChangeEventDetail,
} from '@ionic/core'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonRadioGroup,
} from '@ionic/react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
    InfiniteScrollHelperWeb,
    ListSpinner,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_portfolio_filter_content_source,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getInfiniteListWeb,
} from 'services'

// props
type CustomFilterInputModalPortfolioProps = {
    contentSourceId: number
    creditTypes?: boolean
    initialValue: string | undefined
    label: string
    name: string
    onChange: any
    profileId: number | undefined
    setIsOpen: React.Dispatch<boolean>
}

// main
export const CustomFilterInputModalPortfolio: React.FC<CustomFilterInputModalPortfolioProps> = React.memo(({
    contentSourceId,
    creditTypes,
    initialValue,
    label,
    name,
    onChange,
    profileId,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<MainIdNameSerializer[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [filterOptionSlug, setFilterOptionSlug] = useState('')
    const [selectedIsLoading, setSelectedIsLoading] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState<MainIdNameSerializer[]>([])
    const [specialFilterSlug, setSpecialFilterSlug] = useState('')
    const [toReturnValue, setToReturnValue] = useState<{
        'id': string | undefined,
        'filter': {
            [key: string]: string
        },
        'ordering': string,
    }>(getInitialValue())

    useEffect(() => {
        if (filterOptionSlug) {
            setSpecialFilterSlug('')
            onGetSelectedData()
        }
    }, [
        filterOptionSlug,
    ])

    const listApiUrl = select_url_portfolio_filter_content_source

    const filterOptions: any = {
        1: {  // Project detail (credits)
            credit_type__in: {
                id: 'credit_type__in',
                name: reduxText[5299],
            },
            ordering: [],
        },
        2: {  // Project list
            cities__in: {
                id: 66,
                name: reduxText[6553],
            },
            countries__in: {
                id: 61,
                name: reduxText[6412],
            },
            custom_categories__in: {
                id: 328,
                name: reduxText[5569],
            },
            custom_categories_2__in: {
                id: 355,
                name: reduxText[8723],
            },
            custom_categories_3__in: {
                id: 356,
                name: reduxText[8731],
            },
            languages__in: {
                id: 60,
                name: reduxText[2945],
            },
            medium_type__in: {
                id: 56,
                name: reduxText[6402],
            },
            profile__in: {
                id: 155,
                name: reduxText[1260],
            },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            themes__in: {
                id: 147,
                name: reduxText[3786],
            },
            is_project: {
                id: 'is_project',
                name: reduxText[6393],
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: '-id',
                    name: reduxText[6559],
                },
                {
                    id: 'date',
                    name: reduxText[6554],
                },
                {
                    id: 'initial',
                    name: `${reduxText[6555]} ${reduxText[8765]}`,
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        5: {  // Event detail (credits)
            credit_type__in: {
                id: 'credit_type__in',
                name: reduxText[5299],
            },
            ordering: [],
        },
        6: {  // Event list
            city__in: {
                id: 66,
                name: reduxText[6553],
            },
            team__member__in: {
                id: 166,
                name: reduxText[4917],
            },
            country__in: {
                id: 61,
                name: reduxText[6412],
            },
            custom_categories__in: {
                id: 328,
                name: reduxText[5569],
            },
            // custom_categories_2__in: {
            //     id: 355,
            //     name: reduxText[8723],
            // },
            // custom_categories_3__in: {
            //     id: 356,
            //     name: reduxText[8731],
            // },
            event_status: {
                id: 'event_status',
                name: reduxText[810],
            },
            event_type__in: {
                id: 'event_type__in',
                name: 'event_type',  // TO_TEXT
            },
            has_children: {
                id: 'has_children',
                name: 'has_children',  // TO_TEXT
            },
            profile__in: {
                id: 155,
                name: reduxText[1260],
            },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            themes__in: {
                id: 147,
                name: reduxText[3786],
            },
            is_in_entity_detail: {
                id: 'is_in_entity_detail',
                name: 'is_in_entity_detail',  // TO_TEXT
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            is_in_service_detail: {
                id: 'is_in_service_detail',
                name: 'is_in_service_detail',  // TO_TEXT
            },
            is_in_adherent_detail: {
                id: 'is_in_adherent_detail',
                name: 'is_in_adherent_detail',  // TO_TEXT
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: '-id',
                    name: reduxText[6559],
                },
                {
                    id: 'start_date',
                    name: reduxText[6554],
                },
                {
                    id: 'initial',
                    name: `${reduxText[6555]} ${reduxText[8765]}`,
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        8: {  // Material list
            custom_categories__in: {
                id: 328,
                name: reduxText[5569],
            },
            // custom_categories_2__in: {
            //     id: 355,
            //     name: reduxText[8723],
            // },
            // custom_categories_3__in: {
            //     id: 356,
            //     name: reduxText[8731],
            // },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            ordering: [
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: 'initial',
                    name: reduxText[6559],
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        10: {  // Offer list
            cities__in: {
                id: 66,
                name: reduxText[6553],
            },
            countries__in: {
                id: 61,
                name: reduxText[6412],
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: 'initial',
                    name: reduxText[6559],
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        12: {  // Member list
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            is_in_member_same_section: {
                id: 'is_in_member_same_section',
                name: 'is_in_member_same_section',  // TO_TEXT
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [],
        },
        13: {  // Product list
            custom_categories__in: {
                id: 328,
                name: reduxText[5569],
            },
            custom_categories_2__in: {
                id: 355,
                name: reduxText[8723],
            },
            // custom_categories_3__in: {
            //     id: 356,
            //     name: reduxText[8731],
            // },
            themes__in: {
                id: 147,
                name: reduxText[3786],
            },
            profile__in: {
                id: 155,
                name: reduxText[1260],
            },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            stock_status__in: {
                id: "stock_status__in",
                name: reduxText[8029],
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: 'initial',
                    name: reduxText[6559],
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
                {
                    id: 'custom_date',
                    name: `${reduxText[8732]} - ${reduxText[8751]}`,
                },
                {
                    id: '-custom_date',
                    name: `${reduxText[8732]} - ${reduxText[8750]}`,
                },
            ],
        },
        14: {  // Product detail (credits)
            credit_type__in: {
                id: 'credit_type__in',
                name: reduxText[5299],
            },
            ordering: [
                {
                    id: 'initial',
                    name: 'initial',  // TO_TEXT
                },
                {
                    id: 'name',
                    name: reduxText[6556],
                },
                {
                    id: '-name',
                    name: reduxText[6557],
                },
            ],
        },
        16: {  // Press list
            cities__in: {
                id: 66,
                name: reduxText[6553],
            },
            countries__in: {
                id: 61,
                name: reduxText[6412],
            },
            custom_categories__in: {
                id: 328,
                name: reduxText[5569],
            },
            custom_categories_2__in: {
                id: 355,
                name: reduxText[8723],
            },
            custom_categories_3__in: {
                id: 356,
                name: reduxText[8731],
            },
            languages__in: {
                id: 60,
                name: reduxText[2945],
            },
            medium_type__in: {
                id: 56,
                name: reduxText[6402],
            },
            profile__in: {
                id: 155,
                name: reduxText[1260],
            },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            themes__in: {
                id: 147,
                name: reduxText[3786],
            },
            is_project: {
                id: 'is_project',
                name: reduxText[6393],
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: '-id',
                    name: reduxText[6559],
                },
                {
                    id: 'date',
                    name: reduxText[6554],
                },
                {
                    id: 'initial',
                    name: `${reduxText[6555]} ${reduxText[8765]}`,
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        17: {  // Project Custom Category List
            ordering: [
                {
                    id: 'initial',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        18: {  // Page List
            is_in_footer: {
                id: 'is_in_footer',
                name: reduxText[6347],
            },
            is_in_navbar: {
                id: 'is_in_navbar',
                name: reduxText[6345],
            },
            ordering: [
                {
                    id: 'initial',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        21: {  // Carte List
            ordering: [],  // TODO
        },
        24: {  // Event Team List
            ordering: [
                {
                    id: 'initial',
                    name: 'initial',  // TO_TEXT
                },
                {
                    id: 'name',
                    name: reduxText[6556],
                },
                {
                    id: '-name',
                    name: reduxText[6557],
                },
            ],
        },
        26: {  // Material Custom Category List
            ordering: [
                {
                    id: 'initial',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        27: {  // Service detail (credits)
            credit_type__in: {
                id: 'credit_type__in',
                name: reduxText[5299],
            },
            ordering: [],
        },
        28: {  // Service List
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            service_type__in: {
                id: 'service_type__in',
                name: 'service_type',  // TO_TEXT
            },
            has_children: {
                id: 'has_children',
                name: 'has_children',  // TO_TEXT
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: 'initial',
                    name: reduxText[6559],
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        29: {  // Profile section List
            ordering: [],  // TODO
        },
        33: {  // Carte Item List
            category__in: {
                id: 191,
                name: 'Category',  // TO_TEXT
            },
            ordering: [],  // TODO
        },
        37: {  // Image Profile - List
            custom_group__in: {
                id: 'custom_group__in',
                name: reduxText[8728],
            },
            ordering: [],  // TODO
        },
        38: {  // Language List
            ordering: [],  // TODO
        },
        40: {  // Resource list
            cities__in: {
                id: 66,
                name: reduxText[6553],
            },
            countries__in: {
                id: 61,
                name: reduxText[6412],
            },
            custom_categories__in: {
                id: 328,
                name: reduxText[5569],
            },
            custom_categories_2__in: {
                id: 355,
                name: reduxText[8723],
            },
            custom_categories_3__in: {
                id: 356,
                name: reduxText[8731],
            },
            custom_foreign_key_1__in: {
                id: 409,
                name: 'custom_foreign_key_1',
            },
            custom_foreign_key_2__in: {
                id: 409,
                name: 'custom_foreign_key_2',
            },
            custom_foreign_key_3__in: {
                id: 409,
                name: 'custom_foreign_key_3',
            },
            custom_many_to_many_field_1__in: {
                id: 409,
                name: 'custom_many_to_many_field_1',
            },
            languages__in: {
                id: 60,
                name: reduxText[2945],
            },
            medium_type__in: {
                id: 56,
                name: reduxText[6402],
            },
            profile__in: {
                id: 155,
                name: reduxText[1260],
            },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            themes__in: {
                id: 147,
                name: reduxText[3786],
            },
            is_project: {
                id: 'is_project',
                name: reduxText[6393],
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: '-id',
                    name: reduxText[6559],
                },
                {
                    id: 'date',
                    name: reduxText[6554],
                },
                {
                    id: 'initial',
                    name: `${reduxText[6555]} ${reduxText[8765]}`,
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        42: {  // Region List
            ordering: [],  // TODO
        },
        43: {  // Project content list
            cities__in: {
                id: 66,
                name: reduxText[6553],
            },
            countries__in: {
                id: 61,
                name: reduxText[6412],
            },
            custom_categories__in: {
                id: 328,
                name: reduxText[5569],
            },
            languages__in: {
                id: 60,
                name: reduxText[2945],
            },
            medium_type__in: {
                id: 56,
                name: reduxText[6402],
            },
            profile__in: {
                id: 155,
                name: reduxText[1260],
            },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            themes__in: {
                id: 147,
                name: reduxText[3786],
            },
            ordering: [
                {
                    id: 'initial',
                    name: reduxText[8258],
                },
                {
                    id: '-preserved',
                    name: reduxText[8259],
                },
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: '-id',
                    name: reduxText[6559],
                },
                {
                    id: 'date',
                    name: reduxText[6554],
                },
                {
                    id: 'date',
                    name: reduxText[6555],
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        51: {  // All Project list
            cities__in: {
                id: 66,
                name: reduxText[6553],
            },
            countries__in: {
                id: 61,
                name: reduxText[6412],
            },
            custom_categories__in: {
                id: 328,
                name: reduxText[5569],
            },
            languages__in: {
                id: 60,
                name: reduxText[2945],
            },
            medium_type__in: {
                id: 56,
                name: reduxText[6402],
            },
            profile__in: {
                id: 155,
                name: reduxText[1260],
            },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            themes__in: {
                id: 147,
                name: reduxText[3786],
            },
            is_project: {
                id: 'is_project',
                name: reduxText[6393],
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: '-id',
                    name: reduxText[6559],
                },
                {
                    id: 'date',
                    name: reduxText[6554],
                },
                {
                    id: 'initial',
                    name: `${reduxText[6555]} ${reduxText[8765]}`,
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        54: {  // Service Schedule List
            credit_type__in: {
                id: 'credit_type__in',
                name: reduxText[5299],
            },
            is_in_member_detail: {
                id: 'is_in_member_detail',
                name: reduxText[8256],
            },
            is_in_member_detail_custom: {
                id: 'is_in_member_detail_custom',
                name: reduxText[8551],
            },
            exclude_current_id: {  // TO DO, should only be visible in detail page
                id: 'exclude_current_id',
                name: reduxText[8722],
            },
            ordering: [],  // TODO
        },
        56: {  // Country List
            regions__in: {
                id: 286,
                name: reduxText[8257],
            },
            is_in_press: {
                id: 'is_in_press',
                name: 'is_in_press',  // TO_TEXT
            },
            ordering: [],  // TODO
        },
        60: {  // Service Credit list
            credit_type__in: {
                id: 'credit_type__in',
                name: reduxText[5299],
            },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
        },
        66: {  // Contribution List
            is_in_adherent_detail: {
                id: 'is_in_adherent_detail',
                name: 'is_in_adherent_detail',  // TO_TEXT
            },
            ordering: [],  // TODO
        },
        76: {  // Asset list
            cities__in: {
                id: 66,
                name: reduxText[6553],
            },
            countries__in: {
                id: 61,
                name: reduxText[6412],
            },
            custom_categories__in: {
                id: 328,
                name: reduxText[5569],
            },
            languages__in: {
                id: 60,
                name: reduxText[2945],
            },
            medium_type__in: {
                id: 56,
                name: reduxText[6402],
            },
            profile__in: {
                id: 155,
                name: reduxText[1260],
            },
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            themes__in: {
                id: 147,
                name: reduxText[3786],
            },
            ordering: [
                {
                    id: 'initial',
                    name: reduxText[8258],
                },
                {
                    id: '-preserved',
                    name: reduxText[8259],
                },
                {
                    id: 'id',
                    name: reduxText[6558],
                },
                {
                    id: '-id',
                    name: reduxText[6559],
                },
                {
                    id: 'date',
                    name: reduxText[6554],
                },
                {
                    id: 'date',
                    name: reduxText[6555],
                },
                {
                    id: 'slug',
                    name: reduxText[6556],
                },
                {
                    id: '-slug',
                    name: reduxText[6557],
                },
            ],
        },
        77: {  // General Feed List
            profile_sections__in: {
                id: 195,
                name: reduxText[7683],
            },
            ordering: [
                {
                    id: 'created_on',
                    name: reduxText[6558],
                },
                {
                    id: 'initial',
                    name: reduxText[6559],
                },
            ],
        },
        84: {  // Entity List
            attribution_categories__in: {
                id: 460,
                name: 'attribution_categories',
            },
            ordering: [
                {
                    id: 'initial',
                    name: reduxText[6559],
                },
                {
                    id: 'name',
                    name: reduxText[6556],
                },
                {
                    id: '-name',
                    name: reduxText[6557],
                },
            ],
        },
        91: {  // View
            ordering: [
                {
                    id: 'initial',
                    name: 'Default (ordering from the view)',  // TODO TO_TEXT and show wich ordering the view is using
                },
                {
                    id: 'name',
                    name: reduxText[6556],
                },
                {
                    id: '-name',
                    name: reduxText[6557],
                },
            ],
            event_status: {  // TODO show only if the view is an event list
                id: 'event_status',
                name: `${reduxText[810]} (Only for view of events)`,
            },
        },
    }

    const contentTypeId = filterOptions[contentSourceId][filterOptionSlug]?.id

    function getInitialValue() {
        const filter_values: {
            [key: string]: string
        } = {}
        let ordering_value = 'initial'
        if (initialValue) {
            const filter_array = initialValue.split('&')
            filter_array.map((filter_obj) => {
                if (filter_obj) {
                    const filter_obj_name = filter_obj.split('=')[0]
                    const filter_obj_value = filter_obj.split('=')[1]
                    if (filter_obj_name === 'ordering') {
                        ordering_value = filter_obj_value
                    } else {
                        filter_values[filter_obj_name] = filter_obj_value
                    }
                }
            })
        }
        return {
            'id': initialValue,
            'filter': filter_values,
            'ordering': ordering_value,
        }
    }

    function onCheckboxChange(event: CustomEvent<CheckboxChangeEventDetail>) {
        try {
            let newSelectedOptions: MainIdNameSerializer[] = []
            const index = selectedOptions.findIndex((x) => x.id === event.detail.value.id)
            if (event.detail.checked && index === -1) {
                newSelectedOptions = [...selectedOptions, event.detail.value]
                setSelectedOptions(newSelectedOptions)
                let newId = ''
                if (toReturnValue?.filter) {
                    Object.getOwnPropertyNames(toReturnValue.filter).map((item) => {
                        if (item === filterOptionSlug) {
                            newId += `&${item}=${newSelectedOptions.map((val) => (val.id)).join(',')}`
                        } else {
                            newId += `&${item}=${toReturnValue.filter![item]}`
                        }
                        return false
                    })
                }
                if (!toReturnValue?.filter?.[filterOptionSlug]) {
                    newId += `&${filterOptionSlug}=${newSelectedOptions.map((val) => (val.id)).join(',')}`
                }
                newId += (toReturnValue?.ordering && toReturnValue?.ordering !== 'initial') ? `&ordering=${toReturnValue?.ordering}` : ''
                setToReturnValue({
                    id: newId,
                    filter: {
                        ...toReturnValue?.filter,
                        [filterOptionSlug]: newSelectedOptions.map((val) => (val.id)).join(','),
                    },
                    ordering: toReturnValue?.ordering,
                })
            } else if (!event.detail.checked && index >= 0) {
                selectedOptions.map((val) => {
                    if (val.id !== event.detail.value.id) newSelectedOptions.push(val)
                    return false
                })
                setSelectedOptions(newSelectedOptions)
                let newId = ''
                if (toReturnValue?.filter) {
                    Object.getOwnPropertyNames(toReturnValue.filter).map((item) => {
                        if (item === filterOptionSlug) {
                            if (newSelectedOptions.length > 0) {
                                newId += `&${item}=${newSelectedOptions.map((val) => (val.id)).join(',')}`
                            }
                        } else {
                            newId += `&${item}=${toReturnValue.filter![item]}`
                        }
                        return false
                    })
                }
                if (!toReturnValue?.filter?.[filterOptionSlug] && newSelectedOptions.length > 0) {
                    newId += `&${filterOptionSlug}=${newSelectedOptions.map((val) => (val.id)).join(',')}`
                }
                newId += (toReturnValue?.ordering && toReturnValue?.ordering !== 'initial') ? `&ordering=${toReturnValue?.ordering}` : ''
                if (newSelectedOptions.length > 0) {
                    setToReturnValue({
                        id: newId,
                        filter: {
                            ...toReturnValue?.filter,
                            [filterOptionSlug]: newSelectedOptions.map((val) => (val.id)).join(','),
                        },
                        ordering: toReturnValue?.ordering,
                    })
                } else {
                    const newFilter = toReturnValue?.filter
                    delete newFilter?.[filterOptionSlug]
                    setToReturnValue({
                        id: newId,
                        filter: newFilter,
                        ordering: toReturnValue?.ordering,
                    })
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFilterInputModalPortfolio',
                'onCheckboxChange',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'CustomFilterInputModalPortfolio',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFilterInputModalPortfolio',
                'onGetListData',
            ))
        }
    }

    async function onGetSelectedData() {
        try {
            if (filterOptionSlug && toReturnValue?.filter?.[filterOptionSlug]) {
                setSelectedIsLoading(true)
                const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'CustomFilterInputModalPortfolio getInfiniteListWeb')

                const formData: any = new FormData()
                formData.append('content_source_detail_id', toReturnValue.filter[filterOptionSlug])
                const getUrl = getApiUrl(`${listApiUrl}selected/?profile_id=${profileId}&content_type_id=${contentTypeId}`, reduxAuth)
                if (process.env.NODE_ENV === 'development') console.log(getUrl)
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'post',
                    url: getUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setSelectedOptions(response.data.results)
                        setSelectedIsLoading(false)
                        onGetListData(getApiUrl(`${listApiUrl}?profile_id=${profileId}&content_source_id=${contentSourceId}&content_type_id=${contentTypeId}`, reduxAuth), true)
                    })
                    .catch((error) => {
                        setSelectedIsLoading(false)
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component: 'CustomFilterInputModalPortfolio',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onGetSelectedData',
                        })
                    })
            } else {
                onGetListData(getApiUrl(`${listApiUrl}?profile_id=${profileId}&content_source_id=${contentSourceId}&content_type_id=${contentTypeId}`, reduxAuth), true)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFilterInputModalPortfolio',
                'onGetSelectedData',
            ))
        }
    }

    function onSpecialFilterChange(event: any) {
        try {
            if (process.env.NODE_ENV === 'development') console.log(event)
            if (process.env.NODE_ENV === 'development') console.log(specialFilterSlug)
            let newId = ''
            if (toReturnValue?.filter) {
                Object.getOwnPropertyNames(toReturnValue.filter).map((item) => {
                    if (item === specialFilterSlug) {
                        if (event !== 'default') newId += `&${item}=${event}`
                    } else {
                        newId += `&${item}=${toReturnValue.filter![item]}`
                    }
                    return false
                })
            }
            if (!toReturnValue?.filter?.[specialFilterSlug]) {
                if (event !== 'default') newId += `&${specialFilterSlug}=${event}`
            }
            newId += (toReturnValue?.ordering && toReturnValue?.ordering !== 'initial') ? `&ordering=${toReturnValue?.ordering}` : ''
            if (event === 'default') {
                const newFilter = toReturnValue?.filter
                delete newFilter?.[specialFilterSlug]
                setToReturnValue({
                    id: newId,
                    filter: newFilter,
                    ordering: toReturnValue?.ordering,
                })
            } else {
                setToReturnValue({
                    id: newId,
                    filter: {
                        ...toReturnValue?.filter,
                        [specialFilterSlug]: event,
                    },
                    ordering: toReturnValue?.ordering,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFilterInputModalPortfolio',
                'onEventStatusChange',
            ))
        }
    }

    function onRadioChange(event: any) {
        try {
            let newId = ''
            Object.getOwnPropertyNames(toReturnValue?.filter).map((item) => {
                newId += `&${item}=${toReturnValue!.filter![item]}`
                return false
            })
            newId += (event !== 'initial') ? `&ordering=${event}` : ''
            setToReturnValue({
                id: newId,
                filter: toReturnValue?.filter,
                ordering: event,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFilterInputModalPortfolio',
                'onRadioChange',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFilterInputModalPortfolio',
                'onSearchNext',
            ))
        }
    }

    function onSelectRemove(objectId: any) {
        try {
            const newSelectedOptions: any[] = []
            selectedOptions.map((val: any) => {
                if (val.id !== objectId) newSelectedOptions.push(val)
                return false
            })
            setSelectedOptions(newSelectedOptions)
            let newId = ''
            Object.getOwnPropertyNames(toReturnValue?.filter).map((item) => {
                if (item === filterOptionSlug) {
                    if (newSelectedOptions.length > 0) {
                        newId += `&${item}=${newSelectedOptions.map((val) => (val.id)).join(',')}`
                    }
                } else {
                    newId += `&${item}=${toReturnValue!.filter![item]}`
                }
                return false
            })
            if (!toReturnValue?.filter?.[filterOptionSlug] && newSelectedOptions.length > 0) {
                newId += `&${filterOptionSlug}=${newSelectedOptions.map((val) => (val.id)).join(',')}`
            }
            newId += (toReturnValue?.ordering && toReturnValue?.ordering !== 'initial') ? `&ordering=${toReturnValue?.ordering}` : ''
            if (newSelectedOptions.length > 0) {
                setToReturnValue({
                    id: newId,
                    filter: {
                        ...toReturnValue?.filter,
                        [filterOptionSlug]: newSelectedOptions.map((val) => (val.id)).join(','),
                    },
                    ordering: toReturnValue?.ordering,
                })
            } else {
                const newFilter = toReturnValue?.filter
                delete newFilter?.[filterOptionSlug]
                setToReturnValue({
                    id: newId,
                    filter: newFilter,
                    ordering: toReturnValue?.ordering,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectAsyncInput',
                'onSelectRemove',
            ))
        }
    }

    const booleanOption = [
        {
            id: 'default',
            name: reduxText[8260],
        },
        {
            id: 'true',
            name: reduxText[8261],
        },
        {
            id: 'false',
            name: reduxText[8262],
        },
    ]

    const booleanShortOption = [
        {
            id: 'default',
            name: reduxText[8262],
        },
        {
            id: 'true',
            name: reduxText[8261],
        },
    ]

    const creditTypeEventOption = [
        {
            id: 'default',
            name: reduxText[8260],
        },
        {
            id: '1',
            name: reduxText[3585],
        },
        {
            id: '2',
            name: reduxText[317],
        },
        {
            id: '3',
            name: reduxText[4905],
        },
        {
            id: '4',
            name: reduxText[4680],
        },
        {
            id: '5',
            name: reduxText[5406],
        },
        {
            id: '6',
            name: reduxText[2947],
        },
    ]

    const creditTypeProductOption = [
        {
            id: 'default',
            name: reduxText[8260],
        },
        {
            id: '1',
            name: reduxText[5393],
        },
        {
            id: '3',
            name: reduxText[4805],
        },
        {
            id: '4',
            name: reduxText[3249],
        },
        {
            id: '5',
            name: reduxText[4808],
        },
        {
            id: '6',
            name: reduxText[2947],
        },
    ]

    const creditTypeProjectOption = [
        {
            id: 'default',
            name: reduxText[8260],
        },
        {
            id: '1',
            name: reduxText[5393],
        },
        {
            id: '3',
            name: reduxText[4805],
        },
        {
            id: '4',
            name: reduxText[3249],
        },
        {
            id: '5',
            name: reduxText[4808],
        },
        {
            id: '6',
            name: reduxText[2947],
        },
    ]

    const creditTypeServiceOption = [
        {
            id: 'default',
            name: reduxText[8260],
        },
        {
            id: '1',
            name: 'Main Team',  // TO_TEXT
        },
        {
            id: '2',
            name: 'Secondary Team',  // TO_TEXT
        },
        {
            id: '98',
            name: 'Venue',  // TO_TEXT
        },
    ]

    const eventStatusOption = [
        {
            id: 'default',
            name: reduxText[6560],
        },
        {
            id: 'ongoing_and_upcoming',
            name: reduxText[6561],
        },
        {
            id: 'past',
            name: reduxText[4874],
        },
    ]

    const eventTypeOption = [
        {
            id: 'default',
            name: 'default',  // TO_TEXT
        },
        {
            id: '1,2',
            name: 'Dates',  // TO_TEXT
        },
    ]

    const inAdherentDetailOption = [
        {
            id: 'default',
            name: reduxText[8260],
        },
        {
            id: 'true',
            name: reduxText[8261],
        },
    ]

    const inMemberDetailOption = [
        {
            id: 'default',
            name: reduxText[8260],
        },
        {
            id: 'true',
            name: reduxText[8261],
        },
    ]

    const isProjectOption = [
        {
            id: 'all',
            name: reduxText[5445],
        },
        {
            id: 'default',
            name: reduxText[8263],
        },
    ]

    const serviceTypeOption = [
        {
            id: 'default',
            name: 'default',  // TO_TEXT
        },
        {
            id: '1,2',
            name: 'Dates',  // TO_TEXT
        },
    ]

    const stockStatusOption = [
        {
            id: 1,
            name: reduxText[8024],
        },
        {
            id: 2,
            name: reduxText[8025],
        },
        {
            id: 3,
            name: reduxText[8027],
        },
        {
            id: 4,
            name: reduxText[4506],
        },
        {
            id: 5,
            name: reduxText[8026],
        },
    ]

    const customGroupOption = [
        {
            id: 'default',
            name: reduxText[5445],
        },
        {
            id: '1',
            name: reduxText[8726],
        },
        {
            id: '2',
            name: reduxText[8727],
        },
    ]

    const specialFilters = [
        'credit_type__in',
        'custom_group__in',
        'event_status',
        'event_type__in',
        'exclude_current_id',
        'has_children',
        'is_in_adherent_detail',
        'is_in_entity_detail',
        'is_in_footer',
        'is_in_member_detail',
        'is_in_member_detail_custom',
        'is_in_member_same_section',
        'is_in_navbar',
        'is_in_press',
        'is_in_service_detail',
        'is_project',
        'service_type__in',
        'stock_status__in',
    ]

    return (
        <Dialog
            className='mo-dialog-class inline-form custom-filter-input-portfolio-web-modal'
            classes={{ paper: 'mo-dialog-wrapper' }}
            fullWidth
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {label}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className='mo-dialog-content no-padding'>
                <div className={`mo-dialog-content-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    <IonList>
                        {Object.getOwnPropertyNames(filterOptions[contentSourceId]).map((item) => {
                            if (specialFilters.includes(item)) {
                                return (
                                    <IonItem
                                        key={item}
                                        button
                                        onClick={() => {
                                            // @ts-ignore
                                            setSpecialFilterSlug(item)
                                            setFilterOptionSlug('')
                                        }}
                                    >
                                        <IonLabel className='cfipwm-label'>
                                            <h3>{filterOptions[contentSourceId][item].name}</h3>
                                            {toReturnValue?.filter?.[item] && (
                                                <i className='cfipwm-icon mo-new-icon-filter-solid' />
                                            )}
                                        </IonLabel>
                                    </IonItem>
                                )
                            }
                            if (!['ordering'].includes(item)) {
                                return (
                                    <IonItem
                                        key={item}
                                        button
                                        onClick={() => setFilterOptionSlug(item)}
                                    >
                                        <IonLabel className='cfipwm-label'>
                                            <h3>{filterOptions[contentSourceId][item].name}</h3>
                                            {toReturnValue?.filter?.[item] && (
                                                <i className='cfipwm-icon mo-new-icon-filter-solid' />
                                            )}
                                        </IonLabel>
                                    </IonItem>
                                )
                            }
                        })}
                        <IonItem
                            button
                            onClick={() => {
                                setSpecialFilterSlug('ordering')
                                setFilterOptionSlug('')
                            }}
                        >
                            <IonLabel className='cfipwm-label'>
                                <h3>{reduxText[6562]}</h3>
                                {toReturnValue?.ordering && toReturnValue.ordering !== 'initial' && (
                                    <i className='cfipwm-icon mo-new-icon-filter-solid' />
                                )}
                            </IonLabel>
                        </IonItem>
                    </IonList>
                </div>
                <div className='mo-dialog-content-right cfipwm-select-option-wrap'>
                    <div className={`cfipwm-select-options mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <IonList>
                            <div className='cfipwm-select-list-wrap'>
                                {contentTypeId && !isLoading && items.map((item) => {
                                    return (
                                        <IonItem key={item.id}>
                                            <IonLabel>
                                                <h3>{item.name}</h3>
                                            </IonLabel>
                                            <IonCheckbox
                                                checked={selectedOptions.findIndex((x) => x.id === item.id) >= 0}
                                                onIonChange={(e) => onCheckboxChange(e)}
                                                slot='end'
                                                // @ts-ignore
                                                value={item}
                                            />
                                        </IonItem>
                                    )
                                })}
                                {(specialFilterSlug === 'credit_type__in') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.credit_type__in || 'default'}
                                    >
                                        {contentSourceId === 1 && creditTypeProjectOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                        {contentSourceId === 5 && creditTypeEventOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                        {contentSourceId === 14 && creditTypeProductOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                        {contentSourceId === 27 && creditTypeServiceOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                        {contentSourceId === 54 && creditTypeServiceOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                        {contentSourceId === 60 && creditTypeServiceOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'custom_group__in') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.custom_group__in || 'default'}
                                    >
                                        {customGroupOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'exclude_current_id') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.exclude_current_id || 'default'}
                                    >
                                        {booleanShortOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'event_status') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.event_status || 'default'}
                                    >
                                        {eventStatusOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'event_type__in') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.event_type__in || 'default'}
                                    >
                                        {eventTypeOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'service_type__in') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.service_type__in || 'default'}
                                    >
                                        {serviceTypeOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'has_children') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.has_children || 'default'}
                                    >
                                        {booleanOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_in_adherent_detail') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_in_adherent_detail || 'default'}
                                    >
                                        {inAdherentDetailOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_in_entity_detail') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_in_entity_detail || 'default'}
                                    >
                                        {inMemberDetailOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_in_footer') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_in_footer || 'default'}
                                    >
                                        {booleanOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_in_member_detail') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_in_member_detail || 'default'}
                                    >
                                        {inMemberDetailOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_in_member_detail_custom') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_in_member_detail_custom || 'default'}
                                    >
                                        {inMemberDetailOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_in_member_same_section') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_in_member_same_section || 'default'}
                                    >
                                        {inMemberDetailOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_in_press') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_in_press || 'default'}
                                    >
                                        {booleanShortOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_in_service_detail') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_in_service_detail || 'default'}
                                    >
                                        {inMemberDetailOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_in_navbar') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_in_navbar || 'default'}
                                    >
                                        {booleanOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'is_project') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.is_project || 'default'}
                                    >
                                        {isProjectOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'stock_status__in') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onSpecialFilterChange(e.detail.value)}
                                        value={toReturnValue?.filter?.stock_status__in || 'default'}
                                    >
                                        {stockStatusOption.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                                {(specialFilterSlug === 'ordering') && (
                                    <IonRadioGroup
                                        onIonChange={(e) => onRadioChange(e.detail.value)}
                                        value={toReturnValue?.ordering}
                                    >
                                        {filterOptions[contentSourceId].ordering.map((item: any) => {
                                            return (
                                                <IonItem key={item.id}>
                                                    <IonLabel>
                                                        <h3>{item.name}</h3>
                                                    </IonLabel>
                                                    <IonRadio
                                                        slot='end'
                                                        value={item.id}
                                                    />
                                                </IonItem>
                                            )
                                        })}
                                    </IonRadioGroup>
                                )}
                            </div>
                        </IonList>
                        {contentTypeId && (
                            <InfiniteScrollHelperWeb
                                active={!disableInfiniteScroll}
                                hasMore={hasMore}
                                isLoading={isLoading}
                                items={items}
                                onChange={onSearchNext}
                            />
                        )}
                    </div>
                    <div className={`cfipwm-select-selected mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {contentTypeId && selectedIsLoading && <ListSpinner isLoading />}
                        {contentTypeId && !selectedIsLoading && selectedOptions.map((item) => {
                            return (
                                <IonItem key={item.id}>
                                    <IonLabel>
                                        <h3>{item.name}</h3>
                                    </IonLabel>
                                    <i
                                        className='mo-new-icon-circle-xmark-regular mo-cursor'
                                        onClick={() => onSelectRemove(item.id)}
                                    />
                                </IonItem>
                            )
                        })}
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen(false)}
                    text={reduxText[4519]}
                />
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => {
                        onChange({
                            name: name,
                            value: toReturnValue.id,
                        })
                        setIsOpen(false)
                    }}
                    text={reduxText[3563]}
                />
            </DialogActions>
        </Dialog>
    )
})
