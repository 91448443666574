// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
    IonList,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetNavigationModeSite,
} from 'data'

// serializers
import {
    MenuFormPanelProps,
    MemberFormState,
} from 'serializers/web'

// props
type MenuMemberPanelProps = {
    formState: MemberFormState
}

// main
export const MenuMemberPanel: React.FC<MenuFormPanelProps & MenuMemberPanelProps> = React.memo(({
    formState,
    helpers,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [formPanels, setFormPanels] = useState<{
        formType: string
        panelValue: string
        primary: string
    }[]>([])

    useEffect(() => {
        dispatch(reduxFormSetNavigationModeSite(undefined))
    }, [])

    const mother = formState.mother
    const fieldsMother = mother.fields

    useEffect(() => {

        const tempFormPanels: {
            formType: string
            panelValue: string
            primary: string
        }[] = []

        // Custom page
        if ((fieldsMother?.medium_type?.id === 33) || reduxAuth.settings?.user?.id === 1) {
            tempFormPanels.push(
                {
                    formType: 'mixed-media',
                    panelValue: '-',
                    primary: reduxText[6591],
                },
            )
            tempFormPanels.push(
                {
                    formType: 'inline',
                    panelValue: 'asset-panel',
                    primary: reduxText[8149],
                },
            )
        }

        setFormPanels(tempFormPanels)
    }, [
        fieldsMother?.active,
        reduxAuth.settings?.active_profile,
        reduxText,
    ])

    return (
        <IonList>
            {formPanels.map((val) => {
                if (val.panelValue === 'delete-panel') {
                    return (
                        <IonItem
                            key={val.panelValue}
                            button
                            disabled={!(fieldsMother?.id)}
                            onClick={() => setHelpers({ ...helpers, isActionSheetOpen: true })}
                        >
                            <IonLabel className='mo-item-list-label'>
                                <h2>{val.primary}</h2>
                            </IonLabel>
                        </IonItem>
                    )
                }
                return (
                    <IonItem
                        key={val.panelValue}
                        disabled={!(fieldsMother?.id)}
                        onClick={() => dispatch(reduxFormSetIsSuccessToastOpen(false))}
                        routerLink={`${mother.formUrl}${fieldsMother?.id}/${val.formType}/${val.panelValue}/`}
                    >
                        <IonLabel className='mo-item-list-label'>
                            <h2>{val.primary}</h2>
                        </IonLabel>
                    </IonItem>
                )
            })}
        </IonList>
    )
})
