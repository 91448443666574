// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'
import {
    ArrowContainer,
    Popover,
} from 'react-tiny-popover'

// components
import {
    Alert,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    AddPageBlankPageHelper,
    DeletePageHelper,
    DuplicatePageHelper,
} from 'pages'

// serializers
import {
    PortfolioFormState,
    PortfolioPageListFormSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// props
type EditButtonPageProps = {
    object: PortfolioPageListFormSerializer
    page: PortfolioFormState['page']
    refreshData: () => void
}

// main
export const EditButtonPage: React.FC<EditButtonPageProps> = React.memo(({
    object,
    page,
    refreshData,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [showAddBlankPage, setShowAddBlankPage] = useState(false)
    const [showDeletePage, setShowDeletePage] = useState(false)
    const [showDuplicatePage, setShowDuplicatePage] = useState(false)
    const [showIsHomePageAlert, setShowIsHomePageAlert] = useState(false)

    function handleEdit() {
        setIsPopoverOpen(false)
        history.push(`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${object.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`)
    }

    async function handlePublish(active?: boolean) {
        try {
            if (object.is_home_page) {
                setIsPopoverOpen(false)
                setShowIsHomePageAlert(true)
            } else if (object) {
                setIsPopoverOpen(false)
                const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EditButtonPage handlePublish')
                const formData = new FormData()
                formData.append('active', (!active).toString())
                const activeUrl = `${page.activeUrl}${object.id}/`
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'put',
                    url: activeUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        refreshData()
                        dispatch(reduxFormSetRefresh('refreshPreview', reduxFormSite.portfolioObject?.id, true))
                    })
                    .catch((error) => {
                        axiosErrorHandler({
                            apiUrl: activeUrl,
                            component: 'EditButtonPage',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handlePublish',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EditButtonPage',
                'handlePublish',
            ))
        }
    }

    function PopoverContent() {
        return (
            <div className='selection-helper-page-content-web-popover popover-edit-button-page-content-web'>
                <div className='shpcwp-item'>
                    <button
                        className='shpcwp-item-button'
                        onClick={() => handleEdit()}
                    >
                        <i className='mo-new-icon-pencil-alt-solid' />
                        <span className='shpcwp-item-button-text'>{reduxText[2105]}</span>
                    </button>
                </div>
                {object.slug && !['checkout', 'checkout-cancel', 'checkout-success', 'checkout-upsell'].includes(object.slug) && (
                    <div className='shpcwp-item multi-button bt2'>
                        <div className='shpcwp-icon-wrap'>
                            <i className='mo-new-icon-clone-solid' />
                        </div>
                        <div className='shpcwp-multi-button-wrap'>
                            <button
                                className='shpcwp-item-button'
                                onClick={() => {
                                    setIsPopoverOpen(false)
                                    setShowAddBlankPage(true)
                                }}
                            >
                                <span className='shpcwp-item-button-text'>{reduxText[6435]}</span>
                            </button>
                            <button
                                className='shpcwp-item-button'
                                onClick={() => {
                                    setIsPopoverOpen(false)
                                    setShowDuplicatePage(true)
                                }}
                            >
                                <span className='shpcwp-item-button-text'>{reduxText[8190]}</span>
                            </button>
                        </div>
                    </div>
                )}
                {object.is_deactivable && (
                    <React.Fragment>
                        <div className='shpcwp-item'>
                            <button
                                className='shpcwp-item-button'
                                onClick={() => handlePublish(object.active)}
                            >
                                <i className={object.active ? 'mo-new-icon-eye-slash-solid' : 'mo-new-icon-eye-solid'} />
                                <span className='shpcwp-item-button-text'>{object.active ? reduxText[3517] : reduxText[3518]}</span>
                            </button>
                        </div>
                        <div className='shpcwp-item'>
                            <button
                                className='shpcwp-item-button'
                                onClick={() => {
                                    setIsPopoverOpen(false)
                                    setShowDeletePage(true)
                                }}
                            >
                                <i className='mo-new-icon-trash-solid' />
                                <span className='shpcwp-item-button-text'>{reduxText[2983]}</span>
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }

    if (reduxFormSite.permissions?.permissionIsDisabledRest) {
        return null
    }

    return (
        <React.Fragment>
            <Popover
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        arrowColor={reduxAuth.settings?.theme === 'light' ? '#202020' : '#ffffff'}
                        arrowSize={10}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        position={position}
                    >
                        <PopoverContent />
                    </ArrowContainer>
                )}
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                positions={['bottom', 'top', 'right', 'left']}
            >
                <button
                    className='main-icon-block-web'
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                    <i className='mo-new-icon-ellipsis-h-solid main-icon' />
                </button>
            </Popover>
            {showAddBlankPage && (
                <AddPageBlankPageHelper
                    duplicateSource={object}
                    page={page}
                    setIsOpen={setShowAddBlankPage}
                />
            )}
            {showDeletePage && (
                <DeletePageHelper
                    object={object}
                    refreshData={refreshData}
                    setIsOpen={setShowDeletePage}
                    updateUrl={page.updateUrl}
                />
            )}
            {showDuplicatePage && (
                <DuplicatePageHelper
                    defaulPage={object}
                    defaultPortfolio={reduxFormSite.portfolioObject!}
                    newMixedMediaContent={false}
                    newMixedMediaContentType={undefined}
                    setIsOpen={setShowDuplicatePage}
                />
            )}
            {showIsHomePageAlert && (
                <Alert
                    buttons={[{ text: 'OK' }]}
                    header={reduxText[7709]}
                    isOpen
                    message={reduxText[7710]}
                    onDidDismiss={() => setShowIsHomePageAlert(false)}
                />
            )}
        </React.Fragment>
    )
})
