// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    FormFieldSerializer,
} from 'serializers/site'

// props
type FormFieldsPortfolioProps = {
    formFields: FormFieldSerializer[]
}

// main
export const FormFieldsPortfolio: React.FC<FormFieldsPortfolioProps> = React.memo(({
    formFields,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='newsletter-merge-fields-portfolio-web'>
            <MainFormLabel
                label={reduxText[8460]}
                name='formFields'
                optional
            />
            <table>
                <thead>
                    <tr>
                        {/* <th>{reduxText[8267]}</th>
                        <th>{reduxText[8268]}</th>
                        <th>{reduxText[8461]}</th>
                        <th>{reduxText[8269]}</th> */}
                        <th>Label</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Required</th>
                        {/* <th>Stripe</th> */}
                        {reduxAuth.settings?.user?.id === 1 && (
                            <th>Admin</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {formFields?.map((val) => (
                        <tr key={val.id}>
                            <td>{val.label}</td>
                            <td>{val.name}</td>
                            <td>{val.field_type}</td>
                            <td>{val.required ? reduxText[3696] : reduxText[1236]}</td>
                            {/* <td>{val.stripe_field_type}</td> */}
                            {reduxAuth.settings?.user?.id === 1 && (
                                <td>
                                    <a
                                        className='nmfpw-link'
                                        href={`https://api.justtheweb.io/en/admin/builder/formfield/${val.id}/change/`}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        Prod
                                    </a>
                                    <a
                                        className='nmfpw-link'
                                        href={`http://localhost:8000/en/admin/builder/formfield/${val.id}/change/`}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        Dev
                                    </a>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
})
