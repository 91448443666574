// react components
import React from 'react'
import axios from 'axios'

// data
import {
    api_url_auth_settings_form_language_update,
    reduxAuthState,
    reduxModalErrorEventHandler,
    reduxAuthApiRootUrl,
} from 'data'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    reduxAuthFetchSettingsHelper,
} from 'services'

export async function changeLanguage({
    component,
    dispatch,
    reduxAuth,
    value,
}: {
    component: string
    dispatch: React.Dispatch<any>
    reduxAuth: reduxAuthState
    value: string
}) {
    try {
        if (value === reduxAuth.settings?.language) return
        dispatch(reduxAuthApiRootUrl(value, reduxAuth))
        if (!reduxAuth.settings?.id) return
        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} changeLanguage'`)
        const formData = new FormData()
        formData.append('language', value)
        const putUrl = getApiUrl(`${api_url_auth_settings_form_language_update}${reduxAuth.settings?.id}/`, reduxAuth)
        axios({
            data: formData,
            headers: refreshAxiosHeaders,
            method: 'put',
            url: putUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: putUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'changeLanguage',
                })
            })
    } catch (error) {
        dispatch(reduxModalErrorEventHandler(
            error,
            component,
            'changeLanguage',
        ))
    }
}
