// react components
import React, {
    useState,
} from 'react'
import {
    IonButton,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ProfileSwitchModalWeb,
} from 'components'

// data
import {
    defaultReduxState,
    reduxAuthLogout,
    view_url_about_help_center_topic,
} from 'data'

// main
export const ProfilePopover: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showActionSheet, setShowActionSheet] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })
    const [switchProfileIsOpen, setSwitchProfileIsOpen] = useState(false)

    let wto: any
    function logout() {
        clearTimeout(wto)
        wto = setTimeout(() => {
            dispatch(reduxAuthLogout(true))
        }, 100)
    }

    return (
        <React.Fragment>
            <IonButton
                fill='clear'
                onClick={(e) => setShowActionSheet({ open: true, event: e.nativeEvent })}
            >
                <i className='mo-new-icon-chevron-down-solid' />
            </IonButton>
            <IonPopover
                // @ts-ignore
                cssClass='profile-popover-web'
                event={showActionSheet.event}
                isOpen={showActionSheet.open}
                onDidDismiss={() => setShowActionSheet({ open: false, event: undefined })}
            >
                <IonList>
                    {reduxAuth.settings?.has_other_profiles && (
                        <IonItem
                            button
                            onClick={() => {
                                setShowActionSheet({ open: false, event: undefined })
                                setSwitchProfileIsOpen(true)
                            }}
                        >
                            <IonLabel>{reduxText[2665]}</IonLabel>
                        </IonItem>
                    )}
                    {reduxAuth.settings?.active_profile?.has_premium_help_center && (
                        <IonItem
                            button
                            onClick={() => {
                                setShowActionSheet({ open: false, event: undefined })
                            }}
                            routerLink={view_url_about_help_center_topic}
                        >
                            <IonLabel>{reduxText[6382]}</IonLabel>
                        </IonItem>
                    )}
                    <IonItem
                        button
                        onClick={() => {
                            setShowActionSheet({ open: false, event: undefined })
                            logout()
                        }}
                    >
                        <IonLabel>{reduxText[1266]}</IonLabel>
                    </IonItem>
                </IonList>
            </IonPopover>
            {switchProfileIsOpen && (
                <ProfileSwitchModalWeb
                    setIsOpen={setSwitchProfileIsOpen}
                />
            )}
        </React.Fragment>
    )
}
