// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Popover,
} from 'components'

// data
import {
    api_url_participant_action,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    // view_url_participant_form,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    setStaffPermission,
} from 'services'

// props
type ParticipantActionSheetProps = {
    edit: boolean
    inModal?: boolean
    object: any
    refreshData: () => void
    setActivated?: React.Dispatch<boolean> | undefined
    setShowActionPopover: React.Dispatch<boolean>
    showActionPopover: boolean
}

// main
export const ParticipantActionSheet: React.FC<ParticipantActionSheetProps> = React.memo(({
    edit,
    inModal,
    object,
    refreshData,
    setActivated,
    setShowActionPopover,
    showActionPopover,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (showActionPopover) getData(object.id)
    }, [
        object.id,
        showActionPopover,
    ])

    type actionObjectType = {
        event?: number
        has_permission?: boolean
        staff_permission?: boolean
    }

    const [buttons, setButtons] = useState<any[]>([])

    async function getData(objectId: string) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ParticipantActionSheet getData')

            if (!objectId) return
            const getUrl = getApiUrl(`${api_url_participant_action}${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!response.data) return
                    const actionObject: actionObjectType = response.data
                    const newButtons = []

                    // Permission
                    if (actionObject.has_permission) {
                        newButtons.push(
                            {
                                handler: () => {
                                    dispatch(reduxModalMainFormShow({
                                        cssClass: 'main-form-container-modal-web',
                                        formComponent: 'ParticipantForm',
                                        objectId: object.id,
                                        refreshData,
                                    }))
                                    setShowActionPopover(false)
                                },
                                role: 'edit',
                                text: reduxText[8326],
                            },
                            // {
                            //     handler: () => setShowActionPopover(false),
                            //     role: 'edit',
                            //     text: reduxText[2105],
                            //     to: `${view_url_participant_form}${object.id}/`,
                            // },
                        )
                    }

                    // Staff Permission
                    if (reduxAuth.settings?.user?.groups?.includes(8)) {
                        newButtons.push(
                            {
                                handler: () => {
                                    setStaffPermission({
                                        component: 'ParticipantActionSheet',
                                        contentTypeModel: 'event',
                                        dispatch,
                                        objectId: actionObject.event!,
                                        reduxAuth,
                                        refreshData,
                                    })
                                    setShowActionPopover(false)
                                },
                                text: actionObject.staff_permission ? 'Remove staff permission' : 'Set staff permission',
                            },
                        )
                    }

                    setButtons(newButtons)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ParticipantActionSheet',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ParticipantActionSheet',
                'getData',
            ))
        }
    }

    return (
        <React.Fragment>
            <Popover
                buttons={buttons}
                edit={edit}
                extraZIndex={inModal}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
            />
        </React.Fragment>
    )
})
