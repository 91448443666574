// react components
import React from 'react'

// components
import {
    LinkHelper,
    MainCountryFlag,
    MainHashtag,
    RatioHelper,
} from 'components'

// data
import {
    searchTabValue,
} from 'data'

// serializers
import {
    ProjectTeamListSerializer,
} from 'serializers/web'

// props
type TeamListMediumBlockWebProps = {
    edit: boolean
    object: ProjectTeamListSerializer
}

// main
export const TeamListMediumBlockWeb: React.FC<TeamListMediumBlockWebProps> = React.memo(({
    edit,
    object,
}) => {

    let name = object.name
    if (object.member) {
        name = object.member.name
    }

    if (!object.member) {
        const nameSplit = name?.split(' ')
        let nameZarma = ''
        if (nameSplit) {
            nameZarma += nameSplit[0].charAt(0)
            if (nameSplit[1]) nameZarma += ` ${nameSplit[1].charAt(0)}`
        }
        return (
            <div className='team-list-medium-block-web'>
                <div className='tlmbw-wrap'>
                    <div className='tlmbw-profile-pic'>
                        <span className='tlmbw-image-zarma-name'>{nameZarma}</span>
                    </div>
                    <div className='tlmbw-info'>
                        <p
                            className='mo-list-block-name-web'
                            title={name}
                        >
                            {name}
                        </p>
                        <div className='tlmbw-chips'>
                            {object.roles && (
                                <MainHashtag
                                    edit
                                    label={object.roles}
                                    searchTab={searchTabValue.profile}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (object.member) {

        const member = object.member
        const absolute_url = member.get_absolute_url

        return (
            <div className='team-list-medium-block-web'>
                <div className='tlmbw-wrap'>
                    <div className='tlmbw-profile-pic'>
                        <RatioHelper
                            alt={name}
                            borderRadius='5%'
                            dominant_color={member.get_dominant_color}
                            edit={edit}
                            src={member.get_image_lq}
                            to={absolute_url}
                        />
                    </div>
                    <div className='tlmbw-info'>
                        <LinkHelper
                            edit={edit}
                            to={absolute_url}
                        >
                            <p
                                className='mo-list-block-name-web'
                                title={name}
                            >
                                {name}
                            </p>
                        </LinkHelper>
                        <div className='tlmbw-chips'>
                            {object.roles && (
                                <MainHashtag
                                    edit
                                    label={object.roles}
                                    searchTab={searchTabValue.profile}
                                />
                            )}
                        </div>
                        <div className='tlmbw-country'>
                            {member.countries?.map((val) => (
                                <MainCountryFlag
                                    key={val.id}
                                    edit={edit}
                                    object={val}
                                    searchTab={searchTabValue.profile}
                                />
                            ))}
                            {member.cities?.map((val) => (
                                <MainHashtag
                                    key={`cities-${val.id}`}
                                    edit={edit}
                                    label={val.name}
                                    searchTab={searchTabValue.profile}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null
})
