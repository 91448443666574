// react components
import React from 'react'

// data
import {
    contentTypeModelType,
} from 'data'

// pages
import {
    ActionInputPageContent,
    ContentSourceExtraInputPageContent,
    ContentSourceInputPageContent,
    CoverImageInputPageContent,
    DataJsonInputPageContent,
    FileInputPageContent,
    FilterInputPageContent,
    GeopositionInputPageContent,
    PageLinkInputPageContent,
    TextShort2InputPageContent,
    TextShort3InputPageContent,
    TextShort4InputPageContent,
    TextShortInputPageContent,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    MainFontSerializer,
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type InputHelperPageContentProps = {
    component: string
    detailUrl: string
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    fonts: MainFontSerializer[] | undefined
    handleSubmit?: any
    inputObject?: LayoutFieldHelperSerializer
    mixedMedia?: boolean
    mixedMediaContentType?: contentTypeModelType
    onHandleInputChange: any
    onRefresh: () => void
    optional: boolean
    updateUrl: string
}

// main
export const InputHelperPageContent: React.FC<InputHelperPageContentProps> = React.memo(({
    component,
    detailUrl,
    errorsInline,
    fieldsInline,
    fieldsMother,
    fonts,
    handleSubmit,
    inputObject,
    mixedMedia,
    mixedMediaContentType,
    onHandleInputChange,
    onRefresh,
    optional,
    updateUrl,
}) => {

    const components: any = {
        ActionInputPageContent,
        ContentSourceExtraInputPageContent,
        ContentSourceInputPageContent,
        CoverImageInputPageContent,
        DataJsonInputPageContent,
        FileInputPageContent,
        FilterInputPageContent,
        GeopositionInputPageContent,
        PageLinkInputPageContent,
        TextShort2InputPageContent,
        TextShort3InputPageContent,
        TextShort4InputPageContent,
        TextShortInputPageContent,
    }

    const ComponentName = components[`${component}InputPageContent`]

    if (!ComponentName) return null

    return (
        <ComponentName
            detailUrl={detailUrl}
            errorsInline={errorsInline}
            fieldsInline={fieldsInline}
            fieldsMother={fieldsMother}
            fonts={fonts}
            handleSubmit={handleSubmit}
            inputObject={inputObject}
            mixedMedia={mixedMedia}
            mixedMediaContentType={mixedMediaContentType}
            onHandleInputChange={onHandleInputChange}
            onRefresh={onRefresh}
            optional={optional}
            updateUrl={updateUrl}
        />
    )
})
