// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    CheckboxChangeEventDetail,
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonAvatar,
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
    IonReorder,
    IonReorderGroup,
    IonThumbnail,
} from '@ionic/react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import debounce from 'lodash.debounce'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    ImageHelper,
    InfiniteScrollHelperWeb,
    ListSpinner,
    SearchInput,
    SelectListInput,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_portfolio_filter_content_source,
} from 'data'

// pages
import {
    contentSourceApiUrls,
} from 'pages'

// serializers
import {
    ContentSourceDetailHelperSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getInfiniteListWeb,
} from 'services'

// props
type MatchParams = {
    id: string
}

type ContentListModalPortfolioProps = {
    assetCount?: number
    contentSourceId: number
    contentTypeId?: number
    filter?: boolean
    initialValue: ContentSourceDetailHelperSerializer | ''
    label: string
    mixedMedia?: boolean
    mixedMediaContentType?: contentTypeModelType
    name: string
    onChange: any
    portfolioId: number | undefined
    profileId: number | undefined
    setIsOpen: React.Dispatch<boolean>
}

// main
export const ContentListModalPortfolio: React.FC<ContentListModalPortfolioProps> = React.memo(({
    assetCount,
    contentSourceId,
    contentTypeId,
    filter,
    initialValue,
    label,
    mixedMedia,
    mixedMediaContentType,
    name,
    onChange,
    portfolioId,
    profileId,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<any[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [isProject, setIsProject] = useState<any>((assetCount! > 0) ? { id: 'assets', name: 'Assets' } : '')
    const [searchInput, setSearchInput] = useState('')
    const [selectedOptions, setSelectedOptions] = useState<any>([])
    const [selectedIsLoading, setSelectedIsLoading] = useState(false)

    let wto: any

    useEffect(() => {
        onGetSelectedData()
        clearTimeout(wto)
        wto = setTimeout(() => {
            searchBarRef.current?.setFocus()
        }, 100)
    }, [])

    const listApiUrl = filter ? select_url_portfolio_filter_content_source : contentSourceApiUrls[contentSourceId].apiUrl
    const layout = filter ? '' : contentSourceApiUrls[contentSourceId].layout
    const usePortfolioId = contentSourceApiUrls[contentSourceId].usePortfolioId

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentListModalPortfolio',
                'doReorder',
            ))
        }
    }

    function onCheckboxChange(event: CustomEvent<CheckboxChangeEventDetail>) {
        try {
            let newSelectedOptions: any[] = []
            const index = selectedOptions.findIndex((x: any) => x.id === event.detail.value.id)
            if (event.detail.checked && index === -1) {
                newSelectedOptions = [...selectedOptions, event.detail.value]
                setSelectedOptions(newSelectedOptions)
            } else if (!event.detail.checked && index >= 0) {
                selectedOptions.map((val: any) => {
                    if (val.id !== event.detail.value.id) newSelectedOptions.push(val)
                    return false
                })
                setSelectedOptions(newSelectedOptions)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentListModalPortfolio',
                'onCheckboxChange',
            ))
        }
    }

    function onIsProjectChange(e: any) {
        try {
            setIsProject(e.value)
            let filterString = ''
            if (e.value.id === 'assets' && mixedMediaContentType) {
                filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
            } else if (e.value.id === 'assets') {
                filterString += `&album_id=${params.id}`
            } else if (e.value.id === 'is_staff') {
                filterString += '&is_staff=true'
            } else {
                filterString += `&is_project=${e.value.id}`
            }
            onGetListData(`${getApiUrl(listApiUrl, reduxAuth)}?${usePortfolioId ? `portfolio_id=${portfolioId}` : `profile_id=${profileId}`}${filter ? `&content_source_id=${contentSourceId}&content_type_id=${contentTypeId}` : ''}&search=${searchInput}${filterString}`, true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentListModalPortfolio',
                'onIsProjectChange',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'ContentListModalPortfolio',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentListModalPortfolio',
                'onGetListData',
            ))
        }
    }

    async function onGetSelectedData() {
        try {
            let filterString = ''
            if (isProject.id === 'assets' && mixedMediaContentType) {
                filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
            } else if (isProject.id === 'assets') {
                filterString += `&album_id=${params.id}`
            } else if (isProject.id === 'is_staff') {
                filterString += '&is_staff=true'
            } else if (isProject) {
                filterString += `&is_project=${isProject.id}`
            } else if (contentSourceId === 87 && mixedMediaContentType) {
                filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
            }
            if (initialValue && initialValue.id) {
                setSelectedIsLoading(true)
                const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ContentListModalPortfolio getInfiniteListWeb')

                const formData: any = new FormData()
                formData.append('content_source_detail_id', initialValue.id)
                const getUrl = getApiUrl(`${listApiUrl}selected/?${usePortfolioId ? `portfolio_id=${portfolioId}` : `profile_id=${profileId}`}${filter ? `&content_source_id=${contentSourceId}&content_type_id=${contentTypeId}` : ''}`, reduxAuth)
                if (process.env.NODE_ENV === 'development') console.log(getUrl)
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'post',
                    url: getUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setSelectedOptions(response.data.results)
                        setSelectedIsLoading(false)
                        onGetListData(getApiUrl(`${listApiUrl}?${usePortfolioId ? `portfolio_id=${portfolioId}` : `profile_id=${profileId}`}${filterString}${filter ? `&content_source_id=${contentSourceId}&content_type_id=${contentTypeId}` : ''}${(mixedMedia && params.id) ? `&album_id=${params.id}` : ''}`, reduxAuth), true)
                    })
                    .catch((error) => {
                        setSelectedIsLoading(false)
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component: 'ContentListModalPortfolio',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onGetSelectedData',
                        })
                    })
            } else {
                onGetListData(getApiUrl(`${listApiUrl}?${usePortfolioId ? `portfolio_id=${portfolioId}` : `profile_id=${profileId}`}${filterString}${filter ? `&content_source_id=${contentSourceId}&content_type_id=${contentTypeId}` : ''}`, reduxAuth), true)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentListModalPortfolio',
                'onGetSelectedData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentListModalPortfolio',
                'onSearchNext',
            ))
        }
    }

    function onSearch(value: string | undefined) {
        try {
            setSearchInput(value!)
            let filterString = ''
            if (isProject.id === 'assets' && mixedMediaContentType) {
                filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
            } else if (isProject.id === 'assets') {
                filterString += `&album_id=${params.id}`
            } else if (isProject.id === 'is_staff') {
                filterString += '&is_staff=true'
            } else if (isProject) {
                filterString += `&is_project=${isProject.id}`
            } else if (contentSourceId === 87 && mixedMediaContentType) {
                filterString += `&object_id=${params.id}&content_type=${contentTypeData[mixedMediaContentType]}`
            }
            onGetListData(`${getApiUrl(listApiUrl, reduxAuth)}?${usePortfolioId ? `portfolio_id=${portfolioId}` : `profile_id=${profileId}`}${filter ? `&content_source_id=${contentSourceId}&content_type_id=${contentTypeId}` : ''}&search=${value}${filterString}`, true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentListModalPortfolio',
                'onSearch',
            ))
        }
    }

    function onSelectRemove(objectId: any) {
        try {
            const newSelectedOptions: any[] = []
            selectedOptions.map((val: any) => {
                if (val.id !== objectId) newSelectedOptions.push(val)
                return false
            })
            setSelectedOptions(newSelectedOptions)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentListModalPortfolio',
                'onSelectRemove',
            ))
        }
    }

    function onSortEnd(event: any) {
        try {
            if (initialValue && initialValue.id) {
                setSelectedOptions(arrayMoveImmutable(selectedOptions, event.detail.from, event.detail.to))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentListModalPortfolio',
                'onSortEnd',
            ))
        }
    }

    const debouncedChangeHandler = debounce(onSearch, 300)

    // Extra
    const isProjectOptions = [
        // { id: 'all', name: reduxText[5445] },
        // { id: 'true', name: reduxText[6391] },
        { id: 'true', name: reduxText[5445] },
    ]
    if (assetCount! > 0) {
        isProjectOptions.push(
            { id: 'assets', name: reduxText[8149] },
        )
    }
    // if (reduxAuth.settings?.user?.id === 1) {
    //     isProjectOptions.push(
    //         { id: 'is_staff', name: 'All EC (super user only)' },
    //     )
    // }

    return (
        <Dialog
            className='mo-dialog-class inline-form half content-list-modal-portfolio'
            classes={{ paper: 'mo-dialog-wrapper' }}
            fullWidth
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {label}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className='mo-dialog-content no-padding'>
                <div className={`mo-dialog-content-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    <div className='mo-dialog-searchbar-wrap'>
                        <SearchInput
                            classNameWrap='bg1'
                            onChange={(e) => debouncedChangeHandler(e)}
                            placeholder={reduxText[282]}
                        />
                        {isProjectOptions.length > 1 && [1, 2, 16, 40].includes(contentSourceId) && (
                            <div className='mo-dialog-select'>
                                <SelectListInput
                                    clearable={false}
                                    error=''
                                    greyStyle
                                    name='is_project'
                                    noMarginBottom
                                    onChange={onIsProjectChange}
                                    options={isProjectOptions}
                                    value={isProject || isProjectOptions[0]}
                                />
                            </div>
                        )}
                    </div>
                    <IonList>
                        {items.map((item) => {
                            return (
                                <IonItem key={item.id}>
                                    {['get_image_xs'].includes(layout!) && (
                                        <IonAvatar slot='start'>
                                            <ImageHelper
                                                alt={item.name}
                                                dominant_color={item.get_dominant_color}
                                                src={item.get_image_xs}
                                            />
                                        </IonAvatar>
                                    )}
                                    {['get_image_lq'].includes(layout!) && (
                                        <IonThumbnail>
                                            <ImageHelper
                                                alt={item.name}
                                                className='clmp-thumb-web'
                                                dominant_color={item.get_dominant_color}
                                                src={item.get_image_lq}
                                            />
                                        </IonThumbnail>
                                    )}
                                    <IonLabel className='clmp-label'>
                                        <h3>{item.name}</h3>
                                        {item.product && <p className='clmp-profile'>{item.product.name}</p>}
                                        {item.profile && <p className='clmp-profile'>{item.profile.name}</p>}
                                        {item.service && <p className='clmp-profile'>{item.service.name}</p>}
                                        {item.medium_type && <p className='clmp-medium-type'>{item.medium_type.name}</p>}
                                        {item.sub_content_type && <p className='clmp-medium-type'>{item.sub_content_type.name}</p>}
                                        {!filter && !item.active && !contentSourceApiUrls[contentSourceId].hasNoActive && <p className='clmp-active'>{reduxText[5616]}</p>}
                                    </IonLabel>
                                    <IonCheckbox
                                        checked={selectedOptions.findIndex((x: any) => x.id === item.id) >= 0}
                                        onIonChange={(e) => onCheckboxChange(e)}
                                        slot='end'
                                        value={item}
                                    />
                                </IonItem>
                            )
                        })}
                    </IonList>
                    <InfiniteScrollHelperWeb
                        active={!disableInfiniteScroll}
                        hasMore={hasMore}
                        isLoading={isLoading}
                        items={items}
                        onChange={onSearchNext}
                    />
                </div>
                <div className={`mo-dialog-content-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    <IonReorderGroup
                        disabled={false}
                        onIonItemReorder={doReorder}
                    >
                        {selectedIsLoading && <ListSpinner isLoading />}
                        {!selectedIsLoading && selectedOptions.map((item: any) => {
                            return (
                                <IonItem key={item.id}>
                                    {['get_image_xs'].includes(layout!) && (
                                        <IonAvatar slot='start'>
                                            <ImageHelper
                                                alt={item.name}
                                                dominant_color={item.get_dominant_color}
                                                src={item.get_image_xs}
                                            />
                                        </IonAvatar>
                                    )}
                                    {['get_image_lq'].includes(layout!) && (
                                        <IonThumbnail>
                                            <ImageHelper
                                                alt={item.name}
                                                className='clmp-thumb-web'
                                                dominant_color={item.get_dominant_color}
                                                src={item.get_image_lq}
                                            />
                                        </IonThumbnail>
                                    )}
                                    <IonLabel className='clmp-label'>
                                        <h3>{item.name}</h3>
                                        {item.product && <p className='clmp-profile'>{item.product.name}</p>}
                                        {item.profile && <p className='clmp-profile'>{item.profile.name}</p>}
                                        {item.service && <p className='clmp-profile'>{item.service.name}</p>}
                                        {item.medium_type && <p className='clmp-medium-type'>{item.medium_type.name}</p>}
                                        {!filter && !item.active && !contentSourceApiUrls[contentSourceId].hasNoActive && <p className='clmp-active'>{reduxText[5616]}</p>}
                                    </IonLabel>
                                    <i
                                        className='mo-new-icon-circle-xmark-regular mo-cursor'
                                        onClick={() => onSelectRemove(item.id)}
                                    />
                                    <IonReorder slot='end' />
                                </IonItem>
                            )
                        })}
                    </IonReorderGroup>
                </div>
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen(false)}
                    text={reduxText[4519]}
                />
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => {
                        onChange({
                            name: name,
                            value: {
                                id: selectedOptions.map((val: any) => (val.id)).join(','),
                                name: 'Multiple contents',
                            },
                        })
                        setIsOpen(false)
                    }}
                    text={reduxText[3563]}
                />
            </DialogActions>
        </Dialog>
    )
})
