// react components
import React from 'react'
import {
    isValid,
    parseISO,
} from 'date-fns'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// props
type DateTimeInputProps = {
    defaultNow?: boolean
    disabled?: boolean
    error: string | undefined
    format: 'date' | 'datetime' | 'time'
    formatDate?: 'dd/MM/yyyy' | 'MM/yyyy' | 'yyyy'
    formatPreviewOnly?: boolean
    helperText?: string
    label?: any
    maxDate?: any
    minDate?: any
    name: string
    noMarginBottom?: boolean
    onChange: any
    optional?: boolean
    placeholder?: string
    value: any
}

// main
export const DateTimeInput: React.FC<DateTimeInputProps> = React.memo(({
    defaultNow,
    disabled,
    error,
    format,
    formatDate,
    formatPreviewOnly,
    helperText,
    label,
    maxDate,
    minDate,
    name,
    noMarginBottom,
    onChange,
    optional,
    placeholder,
    value,
}) => {

    const dispatch = useDispatch()

    function onInputChange(value: any) {
        try {
            let dateFormat
            if (value) {
                if (format === 'time') {
                    dateFormat = moment(value).format('HH:mm')
                } else if (format === 'datetime' || formatPreviewOnly) {
                    dateFormat = moment(value).format()
                } else {
                    dateFormat = moment(value).format('YYYY-MM-DD')
                }
            } else {
                dateFormat = ''
            }
            onChange({
                name: name,
                value: dateFormat,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DateTimeInput',
                'onInputChange',
            ))
        }
    }

    return (
        <div className={`date-input-web${noMarginBottom ? ' no-mb' : ''}${error ? ' error' : ''}`}>
            <MainFormLabel
                helperText={helperText}
                label={label}
                name={name}
                optional={optional}
            />
            {format === 'date' && (
                <DatePicker
                    calendarClassName='diw-calendar'
                    className='diw-input'
                    dateFormat={formatDate || 'dd/MM/yyyy'}
                    disabled={disabled}
                    maxDate={maxDate ? parseISO(maxDate) : undefined}
                    minDate={minDate ? parseISO(minDate) : undefined}
                    onChange={date => onInputChange(date)}
                    placeholderText={placeholder}
                    popperClassName='diw-popper'
                    selected={isValid(parseISO(value)) ? parseISO(value) : (defaultNow ? parseISO(moment().format()) : null)}
                />
            )}
            {format === 'datetime' && (
                <DatePicker
                    calendarClassName='diw-calendar'
                    className='diw-input'
                    dateFormat='dd/MM/yyyy HH:mm'
                    disabled={disabled}
                    maxDate={maxDate ? parseISO(maxDate) : undefined}
                    minDate={minDate ? parseISO(minDate) : undefined}
                    onChange={date => onInputChange(date)}
                    placeholderText={placeholder}
                    popperClassName='diw-popper'
                    selected={isValid(parseISO(value)) ? parseISO(value) : (defaultNow ? parseISO(moment().format()) : null)}
                    showTimeSelect
                    timeFormat='HH:mm'
                />
            )}
            {format === 'time' && (
                <DatePicker
                    calendarClassName='diw-calendar'
                    className='diw-input'
                    dateFormat='HH:mm'
                    disabled={disabled}
                    onChange={date => onInputChange(date)}
                    // maxTime={maxDate ? parseISO(maxDate) : undefined}
                    // minTime={minDate ? parseISO(minDate) : undefined}
                    placeholderText={placeholder}
                    popperClassName='diw-popper'
                    selected={(value && !['Invalid date'].includes(value)) ? new Date(2024, 1, 1, value.split(':')[0], value.split(':')[1]) : (defaultNow ? parseISO(moment().format()) : null)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeFormat='HH:mm'
                />
            )}
            {error && <ErrorHelper error={error} />}
        </div>
    )
})
