// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    SelectAsyncInput,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_profile,
} from 'data'

// serializers
import {
    MemberFormState,
} from 'serializers/web'

// props
type MainMemberPanelProps = {
    formState: MemberFormState
    handleInputChange: any
}

// main
export const MainMemberPanel: React.FC<MainMemberPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainMemberPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            {reduxAuth.settings?.user?.is_staff && (
                <SelectAsyncInput
                    apiUrl={select_url_profile}
                    clearable={false}
                    disabled={Boolean(fieldsMother.id)}
                    error={errorsMother?.profile}
                    label={reduxText[1260]}
                    layout='profile'
                    name='profile'
                    onChange={onHandleInputChange}
                    staffOnly
                    value={fieldsMother.profile || ''}
                />
            )}
            {fieldsMother.id
                ? (
                    <React.Fragment>
                        {fieldsMother.member
                            ? (
                                <SelectAsyncInput
                                    apiUrl={`${select_url_profile}?credit=${fieldsMother.profile?.id}`}
                                    clearable={false}
                                    disabled
                                    error={errorsMother?.member}
                                    label={reduxText[5559]}
                                    layout='profile'
                                    name='member'
                                    onChange={onHandleInputChange}
                                    team
                                    value={fieldsMother.member || ''}
                                />
                            ) : (
                                <TextInput
                                    error={errorsMother?.name}
                                    label={reduxText[5559]}
                                    name='name'
                                    onChange={onHandleInputChange}
                                    value={fieldsMother.name || ''}
                                />
                            )
                        }
                    </React.Fragment>
                ) : (
                    <SelectAsyncInput
                        apiUrl={`${select_url_profile}?credit=${fieldsMother.profile?.id}`}
                        clearable={false}
                        creatable
                        error={errorsMother?.member}
                        label={reduxText[5559]}
                        layout='profile'
                        name='member'
                        onChange={onHandleInputChange}
                        team
                        value={fieldsMother.member || ''}
                    />
                )
            }
        </React.Fragment>
    )
})
