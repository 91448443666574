// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
} from 'components'

// customers
import {
    PickAnOfferBlock,
    AgendaDuQuartierQuartierForm,
} from 'customers'

// data
import {
    defaultReduxState,
    media_url,
    media_version,
} from 'data'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// props
type AgendaDuQuartierAdminHomeProps = {
    profileObject: ProfileDetailAdminSerializerV2 | undefined
}

// main
export const AgendaDuQuartierAdminHome: React.FC<AgendaDuQuartierAdminHomeProps> = React.memo(({
    profileObject,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    if (reduxAuth.settings?.federateur) {
        if (!reduxAuth.settings.quartier) {
            return (
                <PickAnOfferBlock profileObject={profileObject} />
            )
        } else if (!reduxAuth.settings.quartier.is_quartier_completed) {
            return (
                <AgendaDuQuartierQuartierForm />
            )
        }
        // if (reduxAuth.settings?.quartier?.is_quartier_completed) {
        //     return (
        //         <p>Profile Ok</p>
        //     )
        // }
        // if (reduxAuth.settings?.federateur?.custom_boolean_field_1) {
        //     return (
        //         <AgendaDuQuartierQuartierForm />
        //     )
        // }
    }

    // if (reduxAuth.settings?.federateur?.sub_content_type === 94) {
    //     return (
    //         <p>Association form</p>
    //     )
    // }

    return (
        <div className='admin-home'>
            <ImageHelper
                alt=''
                className='ah-sphere'
                dominant_color={undefined}
                src={`${media_url}global/spheres/${Math.floor(Math.random() * 105)}.png${media_version}`}
            />
        </div>
    )
})
