// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonSelect,
    IonSelectOption,
} from '@ionic/react'
import {
    Slider,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Checkbox,
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type PixelInputPortfolioProps = {
    adminOnly?: boolean
    canHideInput?: boolean
    disabled?: boolean
    error: string | undefined
    helperText: string
    label: string
    labelOriginal: string
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    staffOnly?: boolean
    value: string
    value_LG?: string
    value_MD?: string
    value_type: 'LG' | 'MD' | 'SM'
}

// main
export const PixelInputPortfolio: React.FC<PixelInputPortfolioProps> = React.memo(({
    adminOnly,
    canHideInput,
    disabled,
    error,
    helperText,
    label,
    labelOriginal,
    name,
    onChange,
    optional,
    staffOnly,
    value,
    value_LG,
    value_MD,
    value_type,
}) => {

    const dispatch = useDispatch()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        getValue()
    }, [value])

    useEffect(() => {
        setHiddenInput(reduxFormSite.collapseStyles!)
    }, [
        reduxFormSite.collapseStyles,
    ])

    const speciaLabel = ['flexBasis', 'height', 'maxHeight', 'maxWidth', 'minHeight', 'minWidth', 'width']
    const specialValue = ['auto', 'minNav100Vh', 'none']

    const [hiddenInput, setHiddenInput] = useState(false)
    const [inherit, setInherit] = useState<boolean>((value_type === 'SM' && !value) || (value_type === 'MD' && !value))
    const [value0, setValue0] = useState<number | string>()
    const [uom0, setUom0] = useState<string>()
    const [range, setRange] = useState<number>(100)

    function onInputChange(newValue: string | null | undefined) {
        try {
            if (newValue === '') {
                setValue0('')
                onChange({
                    name: name,
                    value: '',
                })
                return
            }
            if (typeof newValue === 'undefined') return
            if (Number(newValue) === Number(value0)) return
            if (newValue === value0) return
            if (newValue && specialValue.includes(newValue)) {
                setValue0(newValue)
                setUom0(newValue)
            } else if ((Number(newValue) >= 0) || ['bottom'].includes(name)) {
                setValue0(Number(newValue))
                onChange({
                    name: name,
                    value: `${Number(newValue)}${uom0}`,
                })
            } else {
                return
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PixelInputPortfolio',
                'onInputChange',
            ))
        }
    }

    function onUomChange(newUomValue: string) {
        try {
            if (typeof newUomValue === 'undefined') return
            if (newUomValue === uom0) return
            if (specialValue.includes(newUomValue)) {
                setValue0(newUomValue)
                setUom0(newUomValue)
                onChange({
                    name: name,
                    value: newUomValue,
                })
            } else {
                if (specialValue.includes(`${value0}`)) {
                    let toReturnValue = 0
                    if (speciaLabel.includes(name)) {
                        toReturnValue = 100
                    }
                    if (['fontSize', 'letterSpacing', 'lineHeight'].includes(name)) {
                        toReturnValue = 16
                    }
                    setValue0(toReturnValue)
                    setUom0(newUomValue)
                    onChange({
                        name: name,
                        value: `${toReturnValue}${newUomValue}`,
                    })
                } else {
                    if (typeof value0 === 'undefined') return
                    if (Number(newUomValue) > range) setRange(Number(newUomValue))
                    setUom0(newUomValue)
                    onChange({
                        name: name,
                        value: `${value0}${newUomValue}`,
                    })
                    // getRange(Number(value0), newUomValue)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PixelInputPortfolio',
                'onInputChange',
            ))
        }
    }

    function getValue() {
        if (value) {
            if (specialValue.includes(value)) {
                setValue0(value)
                setUom0(value)
            } else {
                const toReturnValue0 = getSplitValue(value)
                const toReturnUom0 = value.split(toReturnValue0)[1]
                setValue0(Number(toReturnValue0) || 0)
                setUom0(toReturnUom0 || 'px')
            }
        } else {
            setValue0('')
            setUom0(uom0 || 'px')
        }
    }

    function getRange(fieldValue: number, uom?: string) {
        let newRange = 100
        if (speciaLabel.includes(name)) {
            if (uom === 'px') {
                newRange = 1000
            }
        }
        return (fieldValue > newRange ? fieldValue : newRange)
    }

    function getSplitValue(pxValue: string) {
        return pxValue.split('px').join(',').split('%').join(',').split('vh').join(',').split(',')[0]
    }

    const isDisabled = disabled || specialValue.includes(`${value0}`)

    if (inherit) {
        const labelValue = value_type === 'SM' ? (value_MD || value_LG) : value_LG
        return (
            <div className={`mo-text-input-webnew mo-quadruple-input-web${hiddenInput ? ' hidden-input' : ''}`}>
                <MainFormLabel
                    adminOnly={adminOnly}
                    helperText={helperText}
                    hiddenInput={hiddenInput}
                    label={label}
                    labelOriginal={labelOriginal}
                    name={name}
                    optional={optional}
                    setHiddenInput={canHideInput ? () => setHiddenInput(!hiddenInput) : undefined}
                    staffOnly={staffOnly}
                    value={labelValue}
                    valueIsInherit={inherit}
                />
                {!hiddenInput && (
                    <div
                        className='qiw-input-wrap'
                        onClick={() => {
                            setInherit(false)
                            onChange({
                                name: name,
                                value: value_MD || value_LG,
                            })
                        }}
                    >
                        <input
                            className='mo-text-input-web-inputnew disabled'
                            disabled
                            name={name}
                            type='text'
                            value={labelValue}
                        />
                        <div className='qiw-inherit'>
                            <Checkbox
                                checked={inherit}
                                onChange={() => {
                                    setInherit(false)
                                    onChange({
                                        name: name,
                                        value: value_MD || value_LG,
                                    })
                                }}
                            />
                            <span className='qiw-inherit-text'>{reduxText[6568]}</span>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    return (
        <div className={`mo-text-input-webnew mo-quadruple-input-web${hiddenInput ? ' hidden-input' : ''}`}>
            <MainFormLabel
                adminOnly={adminOnly}
                helperText={helperText}
                hiddenInput={hiddenInput}
                label={label}
                labelOriginal={labelOriginal}
                name={name}
                optional={optional}
                setHiddenInput={canHideInput ? () => setHiddenInput(!hiddenInput) : undefined}
                staffOnly={staffOnly}
                value={value}
            />
            {!hiddenInput && (
                <React.Fragment>
                    <div className='qiw-input-wrap'>
                        <input
                            className={`mo-text-input-web-inputnew${isDisabled ? ' disabled' : ''}`}
                            disabled={isDisabled}
                            name={name}
                            onChange={(e) => onInputChange(e.target.value)}
                            type={specialValue.includes(`${value0}`) ? 'text' : 'number'}
                            value={value0}
                        />
                        <IonSelect
                            disabled={disabled}
                            interface='popover'
                            onIonChange={(e) => onUomChange(e.detail.value)}
                            value={uom0}
                        >
                            <IonSelectOption value='px'>px</IonSelectOption>
                            <IonSelectOption value='%'>%</IonSelectOption>
                            <IonSelectOption value='vh'>vh</IonSelectOption>
                            {['flexBasis', 'height', 'width'].includes(name) && (
                                <IonSelectOption value='auto'>auto</IonSelectOption>
                            )}
                            {['height', 'maxHeight', 'minHeight'].includes(name) && (
                                <IonSelectOption value='minNav100Vh'>minNav100Vh</IonSelectOption>
                            )}
                            {['maxHeight', 'maxWidth'].includes(name) && (
                                <IonSelectOption value='none'>none</IonSelectOption>
                            )}
                        </IonSelect>
                        {value_type === 'LG' && (
                            <div className='qiw-close-wrap'>
                                <i
                                    className='qiw-close-icon mo-new-icon-times-solid'
                                    onClick={() => onInputChange('')}
                                />
                            </div>
                        )}
                        {value_type !== 'LG' && (
                            <div className='qiw-inherit'>
                                <Checkbox
                                    checked={inherit}
                                    onChange={() => {
                                        setInherit(true)
                                        onChange({
                                            name: name,
                                            value: '',
                                        })
                                    }}
                                />
                                <span className='qiw-inherit-text'>{reduxText[6568]}</span>
                            </div>
                        )}
                    </div>
                    <div className='qiw-range'>
                        {Number(value0) >= 0 && (
                            <Slider
                                color='secondary'
                                disabled={isDisabled}
                                max={getRange(Number(value0), uom0)}
                                // @ts-ignore
                                onChange={(e) => isNaN(Number(value0) || Number(e.target.value)) ? undefined : onInputChange(Number(e.target.value))}
                                step={['letterSpacing', 'lineHeight'].includes(name) ? 0.1 : 1}
                                value={Number(value0) || undefined}
                            />
                        )}
                    </div>
                </React.Fragment>
            )}
            <ErrorHelper error={error} />
        </div>
    )
})
