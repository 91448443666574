// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
    Link,
} from 'react-router-dom'

// components
import {
    BackgroundImageHelper,
    Button,
    ErrorHelper,
    Loader,
    TextInput,
} from 'components'

// data
import {
    api_url_auth_user_form_create,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_admin,
    view_url_auth_login,
    view_url_profile_detail_old,
    view_url_staff,
} from 'data'

// pages
import {
    GoogleLoginBlock,
} from 'pages'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export const SignupPage: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type errorsType = {
        email?: string
        non_field_errors?: string
        password2?: string
        password?: string
        username?: string
    }
    type fieldsType = {
        email: string
        password2: string
        password: string
        username: string
    }
    const errorsInitial = {
        email: '',
        non_field_errors: '',
        password2: '',
        password: '',
        username: '',
    }
    const fieldsInitial = {
        email: '',
        password2: '',
        password: '',
        username: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [showForm, setShowForm] = useState(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })  // should be after setFields to avoid bug on TextArea
            setButtonDisabled(false)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SignupPage',
                'handleInputChange',
            ))
        }
    }

    async function onSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SignupPage onSubmit')
            if (fields.username !== fields.email) {
                setButtonDisabled(true)
                setErrors({ ...errors, email: reduxText[3566] })
            } else if (fields.password !== fields.password2) {
                setButtonDisabled(true)
                setErrors({ ...errors, password2: reduxText[3565] })
            } else {
                setButtonDisabled(true)
                setIsLoading(true)

                const formData = new FormData()
                formData.append('email', fields.email.toLowerCase().trim())
                formData.append('password', fields.password)
                formData.append('username', fields.username.toLowerCase().trim())
                const createUrl = getApiUrl(api_url_auth_user_form_create, reduxAuth)
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'post',
                    url: createUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setFields(fieldsInitial)
                        setIsLoading(false)
                        setIsSuccess(true)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (error.response) {
                            setErrors(error.response && error.response.data)
                        }
                        let skipSendError = false
                        if (error.response?.status === 400 && error.response?.data?.password) skipSendError = true
                        if (error.response?.status === 400 && error.response?.data?.username) skipSendError = true
                        if (error.response?.status === 403) skipSendError = true
                        axiosErrorHandler({
                            apiUrl: createUrl,
                            component: 'SignupPage',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onSubmit',
                            skipSendError,
                        })
                    })
            }
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'SignupPage',
                'onSubmit',
            ))
        }
    }

    if (reduxAuth.authenticated && reduxAuth.settings?.active_profile) {
        if (reduxAuth.afterAuthUrl) {
            return <Redirect to={reduxAuth.afterAuthUrl} />
        }
        if (reduxAuth.settings.user?.is_staff) {
            return <Redirect to={view_url_staff} />
        }
        if (reduxAuth.settings.active_profile.is_new_profile_ux) {
            return <Redirect to={`${view_url_admin}${reduxAuth.settings?.active_profile?.id}/`} />
        }
        return <Redirect to={`${view_url_profile_detail_old}${reduxAuth.settings?.active_profile?.slug}/`} />
    }

    return (
        <IonPage className='login-page-web navbar-pt'>
            <Loader isOpen={isLoading} />
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='lpw-content'>
                    {isSuccess && (
                        <div className='lpw-left auth-global-block-web'>
                            <h1 className='lpw-title'>{reduxText[5491]}</h1>
                            <div className='success-message pre-line'>
                                <p>{reduxText[2544]}</p>
                            </div>
                        </div>
                    )}
                    {!isSuccess && (
                        <div className='lpw-left auth-global-block-web'>
                            <h1 className='lpw-title'>{reduxText[5491]}</h1>
                            <div className='section-border'>
                                <p className='auth-text'>{reduxText[37]} <Link to={view_url_auth_login} className='auth-link'>{reduxText[38]}</Link></p>
                                <GoogleLoginBlock setIsLoading={setIsLoading} />
                            </div>
                            <div className='auth-separation'>
                                <div className='auth-separation-line'>
                                    <hr />
                                </div>
                                <div className='auth-separation-text'>
                                    <p>{reduxText[5488]}</p>
                                </div>
                                <div className='auth-separation-line'>
                                    <hr />
                                </div>
                            </div>
                            {!showForm
                                ? (
                                    <Button
                                        className='asp-submit-button'
                                        edit={false}
                                        onClick={() => setShowForm(true)}
                                        text={reduxText[39]}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <TextInput
                                            error={errors.username}
                                            helperText={reduxText[253110]}
                                            label={reduxText[2531]}
                                            name='username'
                                            onChange={handleInputChange}
                                            type='email'
                                            value={fields.username}
                                        />
                                        <TextInput
                                            error={errors.email}
                                            helperText={reduxText[253310]}
                                            label={reduxText[2533]}
                                            name='email'
                                            onChange={handleInputChange}
                                            type='email'
                                            value={fields.email}
                                        />
                                        <TextInput
                                            error={errors.password}
                                            helperText={reduxText[253510]}
                                            label={reduxText[2535]}
                                            name='password'
                                            onChange={handleInputChange}
                                            passwordEye
                                            type='password'
                                            value={fields.password}
                                        />
                                        <TextInput
                                            error={errors.password2}
                                            helperText={reduxText[281410]}
                                            label={reduxText[2814]}
                                            name='password2'
                                            onChange={handleInputChange}
                                            passwordEye
                                            type='password'
                                            value={fields.password2}
                                        />
                                        {errors.non_field_errors && (
                                            <ErrorHelper error={errors.non_field_errors} />
                                        )}
                                        <Button
                                            className='asp-submit-button'
                                            disabled={buttonDisabled}
                                            edit={false}
                                            onClick={onSubmit}
                                            text={reduxText[2536]}
                                        />
                                    </React.Fragment>
                                )
                            }
                            <div className='asp-terms'>
                                <div dangerouslySetInnerHTML={{ __html: reduxText[4687] }} />
                            </div>
                        </div>
                    )}
                    <BackgroundImageHelper
                        className='lpw-right'
                        dominant_color={reduxText[620900]}
                        src={reduxText[6209]}
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}
