// react components
import React from 'react'
import {
    IonReactRouter,
} from '@ionic/react-router'
import {
    IonApp,
    IonRouterOutlet,
    setupIonicReact,
} from '@ionic/react'
import {
    ThemeProvider,
    createTheme,
} from '@mui/material'
import {
    DndProvider,
} from 'react-dnd'
import {
    HTML5Backend,
} from 'react-dnd-html5-backend'
import {
    Provider,
} from 'react-redux'
import {
    Route,
} from 'react-router-dom'

// components
import {
    EcPlayerWeb,
    ErrorAlert,
    ErrorBoundary,
    ErrorEventHandler,
    FormSuccessToast,
    GlobalHelperWeb,
    HeaderWrapper,
    ModalRedirectHelper,
    RequireAuth,
    RequireStaff,
    SeoBlock,
} from 'components'

// containers
import {
    DetailContentPageModal,
    DetailModalWrapper,
    DetailModalWrapper2,
    MainFormModalWrapper,
} from 'containers'

// data
import {
    secondary_color,
    view_url_about_help_center_topic,
    view_url_adherent_detail,
    view_url_adherent_form,
    view_url_admin,
    view_url_auth_login,
    view_url_auth_password_reset,
    view_url_auth_password_reset_confirm,
    view_url_auth_signup,
    view_url_block_template_detail,
    view_url_block_template_form,
    view_url_contribution_form,
    view_url_custom_block_detail,
    view_url_event_detail,
    view_url_event_form,
    view_url_event_image_detail,
    view_url_feed_list,
    view_url_home,
    view_url_maintenance,
    view_url_material_detail,
    view_url_material_form,
    view_url_member_detail,
    view_url_member_form,
    view_url_offer_detail,
    view_url_offer_form,
    view_url_page_template_detail,
    view_url_page_template_form,
    view_url_portfolio_detail,
    view_url_portfolio_form,
    view_url_portfolio_list,
    view_url_press_form,
    view_url_product_detail,
    view_url_product_form,
    view_url_profile_detail,
    view_url_profile_detail_old,
    view_url_profile_form,
    view_url_profile_portfolio_list,
    view_url_profile_subscription_list,
    view_url_project_child_form,
    view_url_project_detail,
    view_url_project_detail_old,
    view_url_project_form,
    view_url_resource_form,
    view_url_salesorder_detail,
    view_url_search,
    view_url_service_detail,
    view_url_service_form,
    view_url_staff,
} from 'data'

// pages
import {
    AdherentDetail,
    AdherentForm,
    AdminWrapper,
    BlockTemplateDetail,
    BlockTemplateForm,
    ContributionForm,
    DetailPage,
    EventDetailListWeb,
    EventDetailWeb,
    EventForm,
    EventImageDetailWeb,
    FeedBlockDetailWeb,
    FeedSectionDetailWeb,
    HelpCenterTopicAbout,
    HomePage,
    LoginPage,
    MaintenancePage,
    MaterialDetailWeb,
    MaterialForm,
    MemberDetailWeb,
    MemberForm,
    MixedMediaForm,
    NotFoundPage,
    OfferDetailWeb,
    OfferForm,
    PageTemplateDetail,
    PageTemplateForm,
    PasswordResetConfirmPage,
    PasswordResetPage,
    PortfolioDetailWeb,
    PortfolioForm,
    PortfolioList,
    ProductDetailListWeb,
    ProductDetailWeb,
    ProductForm,
    ProfileDetail,
    ProfileDetailListWeb,
    ProfileDetailOldWeb,
    ProfileDetailPorfolioWeb,
    ProfileForm,
    ProjectChildFormWeb,
    ProjectDetailListWeb,
    ProjectDetailWeb,
    ProjectForm,
    SalesOrderDetail,
    SearchPageWeb,
    ServiceDetail,
    ServiceForm,
    SignupPage,
    SubscriptionListWeb,
} from 'pages'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Theme variables */
import '../@static/web/index.scss'

/* Vendor css */
import 'leaflet/dist/leaflet.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-image-lightbox/style.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import configureStore from '../data/web/mo_store'

// Redux Constants
const store = configureStore()

// MUI style override
const darkTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    color: 'var(--mo-color-text1)',
                },
            },
        },
    },
    palette: {
        mode: 'dark',
        background: {
            default: '#181818',
        },
        secondary: {
            main: secondary_color,
        },
    },
    typography: {
        fontFamily: 'FuturaPT',
    },
})

setupIonicReact({
    mode: 'ios',
    spinner: 'crescent',
    swipeBackEnabled: false,
})

// main
export const AppWeb: React.FC = () => (
    <Provider store={store}>
        <IonApp>
            <IonReactRouter>
                <ThemeProvider theme={darkTheme}>
                    <DndProvider backend={HTML5Backend}>
                        <ErrorBoundary>
                            <HeaderWrapper />
                            <IonRouterOutlet animated={false}>
                                {/* **************************************** Extra **************************************** */}
                                <Route exact path={view_url_home} component={HomePage} />
                                <Route exact path={view_url_maintenance} render={() => <MaintenancePage />} />
                                <Route exact path={view_url_search} render={() => <RequireAuth><SearchPageWeb /></RequireAuth>} />
                                {/* **************************************** About **************************************** */}
                                <Route exact path={`${view_url_about_help_center_topic}:id?/`} render={() => <RequireAuth><HelpCenterTopicAbout /></RequireAuth>} />
                                {/* **************************************** Admin **************************************** */}
                                <Route exact path={`${view_url_admin}:profileId/:pageName?/:moduleId?/:viewId?/:tabId?/`} render={() => <RequireAuth><AdminWrapper isStaff={false} /></RequireAuth>} />
                                {/* **************************************** Adhesion **************************************** */}
                                <Route exact path={`${view_url_adherent_detail}:id/:slug?/`} render={() => <RequireAuth><AdherentDetail /></RequireAuth>} />
                                <Route exact path={`${view_url_adherent_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><AdherentForm /></RequireAuth>} />
                                <Route exact path={`${view_url_contribution_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><ContributionForm /></RequireAuth>} />
                                {/* **************************************** Auth TO_CHECK **************************************** */}
                                <Route exact path={view_url_auth_login} component={LoginPage} />
                                <Route exact path={view_url_auth_password_reset_confirm} component={PasswordResetConfirmPage} />
                                <Route exact path={view_url_auth_password_reset} component={PasswordResetPage} />
                                <Route exact path={view_url_auth_signup} component={SignupPage} />
                                {/* **************************************** Builder **************************************** */}
                                <Route exact path={`${view_url_block_template_detail}:id/:slug?/`} render={() => <RequireAuth><BlockTemplateDetail /></RequireAuth>} />
                                <Route exact path={`${view_url_block_template_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><BlockTemplateForm /></RequireAuth>} />
                                <Route exact path={`${view_url_page_template_detail}:id/:slug?/`} render={() => <RequireAuth><PageTemplateDetail /></RequireAuth>} />
                                <Route exact path={`${view_url_page_template_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><PageTemplateForm /></RequireAuth>} />
                                {/* **************************************** Detail **************************************** */}
                                <Route exact path='/detail/:contentType/:id/' render={() => <RequireAuth><DetailPage /></RequireAuth>} />
                                {/* **************************************** Detail **************************************** */}
                                <Route exact path='/form-mixed-media/:contentType/:id/:panelValue?/' render={() => <RequireAuth><MixedMediaForm /></RequireAuth>} />
                                {/* **************************************** Event **************************************** */}
                                <Route exact path={`${view_url_event_detail}:id/:slug?/`} render={() => <RequireAuth><EventDetailWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_event_detail}:id/:slug/:listId/`} render={() => <RequireAuth><EventDetailListWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_event_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><EventForm /></RequireAuth>} />
                                <Route exact path={`${view_url_event_image_detail}:id/:slug?/`} render={() => <RequireAuth><EventImageDetailWeb /></RequireAuth>} />
                                {/* **************************************** Feed **************************************** */}
                                <Route exact path={`${view_url_feed_list}:id?/:slug?/`} render={() => <RequireAuth><FeedSectionDetailWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_custom_block_detail}:id/:slug?/`} render={() => <RequireAuth><FeedBlockDetailWeb /></RequireAuth>} />
                                {/* **************************************** Material **************************************** */}
                                <Route exact path={`${view_url_material_detail}:id/:slug?/`} render={() => <RequireAuth><MaterialDetailWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_material_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><MaterialForm /></RequireAuth>} />
                                {/* **************************************** Offer **************************************** */}
                                <Route exact path={`${view_url_offer_detail}:id/:slug?/`} render={() => <RequireAuth><OfferDetailWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_offer_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><OfferForm /></RequireAuth>} />
                                {/* **************************************** Portfolio **************************************** */}
                                <Route exact path={`${view_url_portfolio_detail}:id/:slug?/`} render={() => <RequireAuth><PortfolioDetailWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_portfolio_form}:id/:panelValue?/:contentId?/:pageSlug?/:detailId?/:hubTabSlug?/`} render={() => <RequireAuth><PortfolioForm /></RequireAuth>} />
                                <Route exact path={`${view_url_portfolio_list}`} render={() => <RequireAuth><PortfolioList /></RequireAuth>} />
                                {/* **************************************** Profile **************************************** */}
                                <Route exact path={`${view_url_member_detail}:id/:slug?/`} render={() => <RequireAuth><MemberDetailWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_member_form}:id/:formType?/:panelValue?/:extraId?/`} render={() => <RequireAuth><MemberForm /></RequireAuth>} />
                                <Route exact path={`${view_url_profile_detail}:id/:slug?/`} render={() => <RequireAuth><ProfileDetail /></RequireAuth>} />
                                <Route exact path={`${view_url_profile_detail_old}:slug/`} render={() => <RequireAuth><ProfileDetailOldWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_profile_detail_old}:slug/sections/:sectionId/:listStatus?/`} render={() => <RequireAuth><ProfileDetailListWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_profile_detail_old}:slug/tabs/${view_url_profile_portfolio_list}/`} render={() => <RequireAuth><ProfileDetailPorfolioWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_profile_detail_old}:slug/tabs/${view_url_profile_subscription_list}/`} render={() => <RequireAuth><SubscriptionListWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_profile_form}:id/:formType?/:panelValue?/:extraId?/`} render={() => <RequireAuth><ProfileForm /></RequireAuth>} />
                                {/* **************************************** Project **************************************** */}
                                <Route exact path={`${view_url_project_detail}:id/:slug?/`} render={() => <RequireAuth><ProjectDetailWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_project_detail}:id/:slug/:listId/`} render={() => <RequireAuth><ProjectDetailListWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_project_detail_old}:id/:slug?/`} render={() => <RequireAuth><ProjectDetailWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_project_detail_old}:id/:slug/:listId/`} render={() => <RequireAuth><ProjectDetailListWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_press_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><ProjectForm /></RequireAuth>} />
                                <Route exact path={`${view_url_project_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><ProjectForm /></RequireAuth>} />
                                <Route exact path={`${view_url_project_child_form}:id/:albumId/:formType?/:panelValue?/`} render={() => <RequireAuth><ProjectChildFormWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_resource_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><ProjectForm /></RequireAuth>} />
                                {/* **************************************** Sales **************************************** */}
                                <Route exact path={`${view_url_product_detail}:id/:slug?/`} render={() => <RequireAuth><ProductDetailWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_product_detail}:id/:slug/:listId/`} render={() => <RequireAuth><ProductDetailListWeb /></RequireAuth>} />
                                <Route exact path={`${view_url_product_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><ProductForm /></RequireAuth>} />
                                <Route exact path={`${view_url_salesorder_detail}:id/`} render={() => <RequireAuth><SalesOrderDetail /></RequireAuth>} />
                                {/* **************************************** Service **************************************** */}
                                <Route exact path={`${view_url_service_detail}:id/:slug?/`} render={() => <RequireAuth><ServiceDetail /></RequireAuth>} />
                                <Route exact path={`${view_url_service_form}:id/:formType?/:panelValue?/`} render={() => <RequireAuth><ServiceForm /></RequireAuth>} />
                                {/* **************************************** Staff **************************************** */}
                                <Route exact path={`${view_url_staff}:profileId?/:pageName?/:moduleId?/:viewId?/:tabId?/`} render={() => <RequireStaff><AdminWrapper isStaff /></RequireStaff>} />
                                <Route component={NotFoundPage} />
                            </IonRouterOutlet>
                            <DetailContentPageModal />
                            <DetailModalWrapper />
                            <DetailModalWrapper2 />
                            <EcPlayerWeb />
                            <ErrorAlert />
                            <ErrorEventHandler />
                            <FormSuccessToast />
                            <GlobalHelperWeb />
                            <MainFormModalWrapper />
                            <ModalRedirectHelper />
                            <SeoBlock />
                        </ErrorBoundary>
                    </DndProvider>
                </ThemeProvider>
            </IonReactRouter>
        </IonApp>
    </Provider>
)
