// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    InactiveButton,
    LinkHelper,
    MainCountryFlag,
    MainHashtag,
    RatioHelper,
} from 'components'

// data
import {
    defaultReduxState,
    searchTabValue,
} from 'data'

// pages
import {
    MemberActionSheet,
} from 'pages'

// serializers
import {
    ProjectTeamListSerializer,
} from 'serializers/web'

// props
type TeamListSmallBlockWebProps = {
    edit: boolean
    isInProfileSlug?: string
    object: ProjectTeamListSerializer
}

// main
export const TeamListSmallBlockWeb: React.FC<TeamListSmallBlockWebProps> = React.memo(({
    edit,
    isInProfileSlug,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject] = useState<ProjectTeamListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        console.log('TODO');
        // try {
        //     getDetailObject(
        //         getApiUrl(`${api_url_project_detail}${detailObject.id}/?block_size=medium`, reduxAuth),
        //         reduxAuth,
        //         dispatch,
        //         setDetailObject,
        //         'PressListMediumBlockWeb',
        //     )
        // } catch (error) {
        //     dispatch(reduxModalErrorEventHandler(
        //         error,
        //         'PressListMediumBlockWeb',
        //         'onGetDetailData',
        //     ))
        // }
    }

    let name = detailObject.name
    if (detailObject.member) {
        name = detailObject.member.name
    }

    const has_permission = ((reduxAuth.settings?.active_profile?.slug === isInProfileSlug) || reduxAuth.settings?.user?.is_staff)

    const nameSplit = name?.split(' ')
    let nameZarma = ''
    if (nameSplit) {
        nameZarma += nameSplit[0].charAt(0)
        if (nameSplit[1]) nameZarma += ` ${nameSplit[1].charAt(0)}`
    }
    if (!detailObject.member) {
        return (
            <div
                className='team-list-small-block-web'
                style={{
                    display: deleted ? 'none' : undefined,
                }}
            >
                {!activated && (
                    <InactiveButton
                        edit={edit}
                        model='profileteam'
                        objectId={detailObject.id!}
                        objectName={detailObject.name!}
                        setDeleted={setDeleted}
                    />
                )}
                <div className='tlsbw-wrap'>
                    <div className='tlsbw-cover-wrap'>
                        <div className='tlsbw-image-wrap'>
                            <LinkHelper
                                className={`tlsbw-image-zarma ${detailObject.get_absolute_url ? 'link' : 'no-link'}`}
                                edit={edit}
                                to={detailObject.get_absolute_url}
                            >
                                {(has_permission && detailObject.get_image_lq)
                                    ? (
                                        <RatioHelper
                                            alt={detailObject.name}
                                            borderRadius='5%'
                                            dominant_color={detailObject.get_dominant_color}
                                            edit={edit}
                                            src={detailObject.get_image_lq}
                                            to={undefined}
                                        />
                                    ) : (
                                        <span className='tlsbw-image-zarma-name'>{nameZarma}</span>
                                    )}
                            </LinkHelper>
                            <div className='tlsbw-action'>
                                <LinkHelper
                                    className='tlsbw-actions-link'
                                    edit={edit}
                                    to={detailObject.get_absolute_url}
                                />
                                <MemberActionSheet
                                    edit={edit}
                                    isInProfileSlug={isInProfileSlug}
                                    object={detailObject}
                                    refreshData={onGetDetailData}
                                    setActivated={setActivated}
                                    setShowActionPopover={setShowActionPopover}
                                    showActionPopover={showActionPopover}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='tlsbw-info'>
                        <p
                            className='mo-list-block-name-web small'
                            title={name}
                        >
                            {name}
                        </p>
                        <div className='tlsbw-chips'>
                            {detailObject.roles && (
                                <MainHashtag
                                    edit
                                    label={detailObject.roles}
                                    searchTab={searchTabValue.profile}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (detailObject.member) {

        const member = detailObject.member
        const absolute_url = member.get_absolute_url

        return (
            <div
                className='team-list-small-block-web'
                style={{
                    display: deleted ? 'none' : undefined,
                }}
            >
                {!activated && (
                    <InactiveButton
                        edit={edit}
                        model='profile'
                        objectId={detailObject.member?.id!}
                        objectName={detailObject.member.name!}
                        setDeleted={setDeleted}
                    />
                )}
                <div className='tlsbw-wrap'>
                    <div className='tlsbw-cover-wrap'>
                        <div className='tlsbw-image-wrap'>
                            <LinkHelper
                                className='tlsbw-image-zarma link'
                                edit={edit}
                                to={absolute_url}
                            >
                                {(detailObject.get_image_lq || member.get_image_lq)
                                    ? (
                                        <RatioHelper
                                            alt={detailObject.name}
                                            borderRadius='5%'
                                            dominant_color={detailObject.get_dominant_color || member.get_dominant_color}
                                            edit={edit}
                                            imageContain={detailObject.member?.image_thumb_contain}
                                            src={detailObject.get_image_lq || member.get_image_lq}
                                            to={undefined}
                                        />
                                    ) : (
                                        <span className='tlsbw-image-zarma-name'>{nameZarma}</span>
                                    )}
                            </LinkHelper>
                            <div className='tlsbw-action'>
                                <LinkHelper
                                    className='tlsbw-actions-link'
                                    edit={edit}
                                    to={absolute_url}
                                />
                                <MemberActionSheet
                                    edit={edit}
                                    isInProfileSlug={isInProfileSlug}
                                    object={detailObject}
                                    refreshData={onGetDetailData}
                                    setActivated={setActivated}
                                    setShowActionPopover={setShowActionPopover}
                                    showActionPopover={showActionPopover}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='tlsbw-info'>
                        <LinkHelper
                            edit={edit}
                            to={absolute_url}
                        >
                            <p
                                className='mo-list-block-name-web small'
                                title={name}
                            >
                                {name}
                            </p>
                        </LinkHelper>
                        {detailObject.roles && (
                            <div className='tlsbw-chips'>
                                <MainHashtag
                                    edit
                                    label={detailObject.roles}
                                    searchTab={searchTabValue.profile}
                                />
                            </div>
                        )}
                        {detailObject.custom_categories?.length! > 0 && (
                            <div className='tlsbw-chips'>
                                {detailObject.custom_categories?.map((val) => (
                                    <MainHashtag
                                        key={val.id}
                                        edit
                                        label={val.name}
                                        searchTab={searchTabValue.profile}
                                    />
                                ))}
                            </div>
                        )}
                        <div className='tlsbw-country'>
                            {member.countries?.map((val) => (
                                <MainCountryFlag
                                    key={val.id}
                                    edit={edit}
                                    object={val}
                                    searchTab={searchTabValue.profile}
                                />
                            ))}
                            {member.cities?.map((val) => (
                                <MainHashtag
                                    key={`cities-${val.id}`}
                                    edit={edit}
                                    label={val.name}
                                    searchTab={searchTabValue.profile}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null
})
