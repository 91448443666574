// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    Redirect,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    view_url_auth_login,
    view_url_admin,
} from 'data'

// main
export const AgendaDuQuartierHomePage: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    if (reduxAuth.authenticated) {
        if (!reduxAuth.settings?.quartier?.is_quartier_completed) {
            return <Redirect to={`${view_url_admin}quartier/${reduxAuth.settings?.quartier?.is_quartier_first_false_step ? `?step=${reduxAuth.settings?.quartier?.is_quartier_first_false_step}` : ''}`} />
        }
        return <Redirect to={view_url_admin} />
    }
    return (
        <Redirect to={view_url_auth_login} />
    )
}
