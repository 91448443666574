// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    api_url_portfolio_page_style_form_update,
    defaultReduxState,
    reduxFormSetDataSite,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModaSetDeviceTypeSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    StyleInputHelper,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type StylePagePortfolioPanelProps = {
    formState: PortfolioFormState
    setFormState: React.Dispatch<PortfolioFormState>
}

// main
export const StylePagePortfolioPanel: React.FC<StylePagePortfolioPanelProps> = React.memo(({
    formState,
    setFormState,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const mother = formState.mother
    const page = formState.page

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [stylesToUpdate, setStylesToUpdate] = useState<any>({})

    function onHandleInputChange(value: any, style: any, valueRef: string) {
        try {
            if (reduxFormSite.pageObject && page) {
                const styles: any = reduxFormSite.pageObject.styles
                const toReturnValue = {
                    ...reduxFormSite.pageObject,
                    styles: {
                        ...styles,
                        [valueRef]: {
                            ...styles[valueRef],
                            [value.name]: value.value,
                        }
                    }
                }
                const newPageStyleArray: any[] = []
                page.fields?.styles?.map((val) => {
                    if (val.id === style.id) {
                        // @ts-ignore
                        val[valueRef] = value.value
                    }
                    newPageStyleArray.push(val)
                })
                const toReturnFields = {
                    ...page.fields,
                    styles: newPageStyleArray
                }
                // Show style in preview
                dispatch(reduxFormSetDataSite(
                    'pageObject',
                    toReturnValue,
                ))
                // Save to temporary object to save
                setStylesToUpdate({
                    ...stylesToUpdate,
                    [style.id]: {
                        ...stylesToUpdate[style.id],
                        [valueRef]: value.value,
                    }
                })
                // Update in page
                setFormState({
                    ...formState,
                    page: {
                        ...formState.page,
                        fields: toReturnFields,
                    }
                })
                setButtonDisabled(false)
                // if (!reduxFormSite.editingForm) dispatch(reduxFormSetEditingSite(true))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StylePagePortfolioPanel',
                'onHandleInputChange',
            ))
        }
    }

    async function onHandleSubmit() {
        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'StylePagePortfolioPanel onHandleSubmit')
        if (Object.getOwnPropertyNames(stylesToUpdate).length > 0) {
            Object.getOwnPropertyNames(stylesToUpdate).map((val, i) => {
                const formData = new FormData()
                if (stylesToUpdate[val].hasOwnProperty('value')) formData.append('value', stylesToUpdate[val].value)
                if (stylesToUpdate[val].hasOwnProperty('value_MD')) formData.append('value_MD', stylesToUpdate[val].value_MD)
                if (stylesToUpdate[val].hasOwnProperty('value_SM')) formData.append('value_SM', stylesToUpdate[val].value_SM)
                const putUrl = getApiUrl(`${api_url_portfolio_page_style_form_update}${val}/`, reduxAuth)
                setIsSaving(true)
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'patch',
                    url: putUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        if (i === (Object.getOwnPropertyNames(stylesToUpdate).length - 1)) {
                            dispatch(reduxFormSetRefresh('refreshPreview', reduxFormSite.portfolioObject?.id))
                            setIsSaving(false)
                            dispatch(reduxFormSetIsSuccessToastOpen(true))
                            setButtonDisabled(true)
                            setStylesToUpdate({})
                            dispatch(reduxFormSetEditingSite(false))
                        }
                    })
                    .catch((error) => {
                        setIsSaving(false)
                        axiosErrorHandler({
                            apiUrl: putUrl,
                            component: 'StylePagePortfolioPanel',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onHandleSubmit',
                        })
                    })
                return false
            })
        }
    }

    // Mother
    const fieldsMother = mother.fields

    // Inline
    const fieldsInline = page?.fields

    if (!fieldsInline) return null

    if (reduxFormSite.permissions?.permissionIsDisabledRest) {
        return (
            <div style={{
                padding: '20px',
            }}>
                <p>{reduxText[9975]}</p>
            </div>
        )
    }

    return (
        <div className={`style-page-content-portfolio-panel-web mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className='spcppw-device'>
                <div
                    className={`spcppw-device-div ${deviceType === 'is-web' && 'active'}`}
                    onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-web'))}
                >
                    <i className='icon mo-new-icon-desktop-solid' />
                </div>
                <div
                    className={`spcppw-device-div ${deviceType === 'is-tablet' && 'active'}`}
                    onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-tablet'))}
                >
                    <i className='icon mo-new-icon-tablet-alt-solid' />
                </div>
                <div
                    className={`spcppw-device-div ${deviceType === 'is-mobile' && 'active'}`}
                    onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-mobile'))}
                >
                    <i className='icon mo-new-icon-mobile-alt-solid' />
                </div>
            </div>
            <div className={`spcppw-style-wrap mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                {deviceType === 'is-web' && (
                    <div className='pfp-css-style-div'>
                        {fieldsInline.styles?.map((style) => (
                            <StyleInputHelper
                                key={`${deviceType}-${style.id}`}
                                css_property={style.css_property}
                                onChange={(e: any) => onHandleInputChange(e, style, 'value')}
                                presetColors={fieldsMother?.preset_colors}
                                value={style.value || ''}
                                value_type='LG'
                            />
                        ))}
                    </div>
                )}
                {deviceType === 'is-tablet' && (
                    <div className='pfp-css-style-div'>
                        {fieldsInline.styles?.map((style) => (
                            <StyleInputHelper
                                key={`${deviceType}-${style.id}`}
                                css_property={style.css_property}
                                onChange={(e: any) => onHandleInputChange(e, style, 'value_MD')}
                                presetColors={fieldsMother?.preset_colors}
                                value={style.value_MD || ''}
                                value_LG={style.value || ''}
                                value_type='MD'
                            />
                        ))}
                    </div>
                )}
                {deviceType === 'is-mobile' && (
                    <div className='pfp-css-style-div'>
                        {fieldsInline.styles?.map((style) => (
                            <StyleInputHelper
                                key={`${deviceType}-${style.id}`}
                                css_property={style.css_property}
                                onChange={(e: any) => onHandleInputChange(e, style, 'value_SM')}
                                presetColors={fieldsMother?.preset_colors}
                                value={style.value_SM || ''}
                                value_LG={style.value || ''}
                                value_MD={style.value_MD || ''}
                                value_type='SM'
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className='pfcw-footer2'>
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    expand='full'
                    onClick={() => onHandleSubmit()}
                    text={reduxText[4520]}
                />
            </div>
            {isSaving && (
                <Loader isOpen />
            )}
        </div>
    )
})
