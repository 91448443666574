// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonReorder,
    IonReorderGroup,
    // IonSearchbar,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    Popover,
} from 'components'

// data
import {
    api_url_profile_section_form_patch_child,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    view_url_profile_detail_old,
} from 'data'

// pages
import {
    ProfileDetailMenuLeftTabChildren,
    ProfileDetailMenuLeftTabSeparator,
} from 'pages'

// serializers
import {
    ProfileSectionLeftHelperSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    parseQuery,
} from 'services'

// props
type MatchParams = {
    slug: string
    sectionId: string
}

type ProfileDetailMenuLeftTabProps = {
    disableReorderChildren?: boolean
    disableReorderChildrenChildren?: boolean
    manageProfileSection?: boolean
    object: ProfileSectionLeftHelperSerializer
}

// main
export const ProfileDetailMenuLeftTab: React.FC<ProfileDetailMenuLeftTabProps> = React.memo(({
    disableReorderChildren,
    disableReorderChildrenChildren,
    manageProfileSection,
    object,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isChildOpen, setIsChildOpen] = useState<boolean>((params.sectionId === `${object.id}`) || (parseQuery(location.search)['main-parent'] === `${object.id}`))
    const [isInit, setIsInit] = useState(false)
    const [items, setItems] = useState<ProfileSectionLeftHelperSerializer['children_tabs']>(object.children_tabs || [])
    const [showPopover, setShowPopover] = useState(false)

    useEffect(() => {
        const shoulOpen = (params.sectionId === `${object.id}`) || (parseQuery(location.search)['main-parent'] === `${object.id}`)
        if (isInit && isChildOpen && !shoulOpen) {
            setIsChildOpen(shoulOpen)
        }
        setIsInit(true)
    }, [
        params.sectionId,
    ])

    useEffect(() => {
        setItems(object.children_tabs || [])
    }, [
        object.children_tabs,
    ])

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMenuLeftTab',
                'doReorder',
            ))
        }
    }

    async function onSortEnd(event: any) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetailMenuLeftTab onSortEnd')
            const item = items![event.detail.from]
            const itemTo = items![event.detail.to]
            setItems(arrayMoveImmutable(items!, event.detail.from, event.detail.to))
            const formData: any = new FormData()
            formData.append('sibling_id', itemTo.id)
            const putUrl = getApiUrl(`${api_url_profile_section_form_patch_child}${item.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'ProfileDetailMenuLeftTab',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMenuLeftTab',
                'onSortEnd',
            ))
        }
    }

    return (
        <div className='profile-detail-menu-left-tab-web'>
            <div className='pdmltw-tab-item-wrap'>
                <i
                    className={`pdmltw-expand mo-new-icon-caret-right-solid${isChildOpen ? ' open' : ''}${items?.length! > 0 ? '' : ' hidden'}`}
                    onClick={() => setIsChildOpen(!isChildOpen)}
                />
                <NavLink
                    className='pdmltw-tab-item'
                    to={`${view_url_profile_detail_old}${params.slug}/sections/${object.id}/${[48959, 50177].includes(object.id!) ? 'all/' : ''}`}
                    isActive={(match, location) => {
                        if (!match) {
                            return false
                        }
                        return parseQuery(location.search).other !== 'true'
                    }}
                >
                    {object.name}
                    <IonReorder
                        className='pdmltw-ion-reorder'
                        slot='end'
                    />
                    {manageProfileSection && (
                        <Popover
                            className='pdlw-status-settings'
                            buttons={[
                                {
                                    text: reduxText[2105],
                                    handler: () => {
                                        dispatch(reduxModalMainFormShow({
                                            cssClass: 'modal-form-web',
                                            formComponent: 'ProfileSectionForm',
                                            objectId: object.id!,
                                        }))
                                        setShowPopover(false)
                                    }
                                },
                            ]}
                            edit={false}
                            isOpen={showPopover}
                            setIsOpen={setShowPopover}
                        />
                    )}
                </NavLink>
            </div>
            {/* {object.children_tabs?.length! > 15 && isChildOpen && (
                <IonSearchbar
                    className='pdmltw-searchbar'
                    onIonChange={(e) => setItems(object.children_tabs?.filter(obj => obj.name?.toLowerCase().includes(e.detail.value?.toLowerCase()!)))}
                    placeholder={reduxText[282]}
                />
            )} */}
            {items?.length! > 0 && (
                <div className={`pdmltw-children-wrap${isChildOpen ? ' open' : ''}`}>
                    {object.content_source === 'product' && (
                        <React.Fragment>
                            <ProfileDetailMenuLeftTabSeparator text={reduxText[8700]} />
                            {items?.filter(obj => obj.is_grouping).map((val) => (
                                <ProfileDetailMenuLeftTabChildren
                                    key={val.id}
                                    disableReorderChildren={disableReorderChildren}
                                    disableReorderChildrenChildren={disableReorderChildrenChildren}
                                    mainParentObject={object}
                                    manageProfileSection={manageProfileSection}
                                    object={val}
                                />
                            ))}
                            <ProfileDetailMenuLeftTabSeparator text={reduxText[8499]} />
                        </React.Fragment>
                    )}
                    {disableReorderChildren
                        ? (
                            items?.filter(obj => !obj.is_grouping).map((val) => (
                                <ProfileDetailMenuLeftTabChildren
                                    key={val.id}
                                    disableReorderChildren={disableReorderChildren}
                                    disableReorderChildrenChildren={disableReorderChildrenChildren}
                                    mainParentObject={object}
                                    manageProfileSection={manageProfileSection}
                                    object={val}
                                />
                            ))
                        ) : (
                            <IonReorderGroup
                                disabled={disableReorderChildren}
                                onIonItemReorder={doReorder}
                            >
                                {items?.filter(obj => !obj.is_grouping).map((val) => (
                                    <ProfileDetailMenuLeftTabChildren
                                        key={val.id}
                                        disableReorderChildren={disableReorderChildren}
                                        disableReorderChildrenChildren={disableReorderChildrenChildren}
                                        mainParentObject={object}
                                        manageProfileSection={manageProfileSection}
                                        object={val}
                                    />
                                ))}
                            </IonReorderGroup>
                        )}
                    {items?.length! > 0 && object.has_other && (
                        <NavLink
                            className='pdmltw-tab-item-children'
                            exact
                            to={`${view_url_profile_detail_old}${params.slug}/sections/${object.id}--uncategorized/all/?main-parent=${object.id}`}
                            isActive={(match, location) => {
                                if (!match) {
                                    return false
                                }
                                return parseQuery(location.search).other === 'true'
                            }}
                        >
                            {reduxText[8008]}
                        </NavLink>
                    )}
                </div>
            )}
        </div>
    )
})
