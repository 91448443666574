// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// props
type ContributionListHeaderProps = {
    count: number | undefined
    inDetailList?: boolean
}

// main
export const ContributionListHeader: React.FC<ContributionListHeaderProps> = React.memo(({
    count,
    inDetailList,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <tr className='main-list-header ContributionListHeader'>
            {!inDetailList && (
                <th>{count !== undefined ? `${count} ${reduxText[8337]}` : ''}</th>
            )}
            <th>{reduxText[8557]}</th>
            {!inDetailList && (
                <th>{reduxText[8613]}</th>
            )}
            <th>{reduxText[5559]}</th>
            <th>{reduxText[8570]}</th>
            <th>{reduxText[8553]}</th>
            <th>{reduxText[8554]}</th>
        </tr>
    )
})
