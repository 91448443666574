// data
import {
    reduxAuthState,
} from 'data'

// main
export function checkPermissions({
    profileId,
    reduxAuth,
    user,
}: {
    profileId: number | undefined
    reduxAuth: reduxAuthState
    user: number | undefined
}) {
    if (!profileId || !user || !reduxAuth.settings?.user) return false
    return reduxAuth.settings.user.is_staff || (user === reduxAuth.settings.user.id) || (reduxAuth.settings.user.permitted_profiles?.includes(profileId)) || (reduxAuth.settings.user.permitted_users?.includes(user))
}
