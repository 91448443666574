// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventDetailFormSerializer,
    EventDetailFormSerializerError,
} from 'serializers/web'

// props
type DateOnlyEventInputProps = {
    errors: EventDetailFormSerializerError | undefined
    fields: EventDetailFormSerializer
    onChange: any
}

// main
export const DateOnlyEventInput: React.FC<DateOnlyEventInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <CheckboxInput
            error={errors?.date_only}
            label={reduxText[4724]}
            name='date_only'
            onChange={onChange}
            value={fields.date_only || ''}
        />
    )
})
