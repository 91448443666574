// data
import {
    api_url_material_form_active,
    api_url_material_form_create,
    api_url_material_form_detail,
    api_url_material_form_update,
    reduxAuthState,
    view_url_material_detail,
    view_url_material_form,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function MaterialMotherFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'mother',

        // Settings
        detailComponent: 'MaterialDetailBlockWeb',
        model: 'material',

        // Urls
        activeUrl: getApiUrl(api_url_material_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_material_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_material_form_detail, reduxAuth),
        formUrl: view_url_material_form,
        updateUrl: getApiUrl(api_url_material_form_update, reduxAuth),
        viewUrl: view_url_material_detail,

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'name',
        ],
        requiredForeignKey: [
            'profile',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'active',
            'cover_image_alt',
            'cover_image_doka_output_data',
            'cover_image_square_doka_output_data',
            'custom_text',
            'custom_text_2',
            'custom_text_3',
            'custom_text_rich',
            'custom_text_rich_2',
            'custom_text_rich_3',
            'description',
            'description2',
            'description3',
            'is_multiple',
            'is_private',
            'is_private_and_listed_in_portfolio',
            'is_unlisted',
            'name',
            'price',
        ],
        formFile: [
            'cover_image',
            'cover_image_original',
            'cover_image_square',
        ],
        formForeignKey: [
            'medium_type',
            'portfolio_detail_page',
            'profile',
            'uom',
        ],
        formJson: [],
        formJsonNull: [
            'styles_extra',
        ],
        formManyToMany: [
            'languages',
            'portfolio_user_groups',
            'profile_sections',
            'related_materials',
        ],
    }
}
