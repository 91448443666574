// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorHelper,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    SelectListInputV2,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type AgendaDuQuartierQuartierFormStep5Props = {
    inline?: boolean
    setStep: (step: number) => void
    step: number
    submitOnly?: boolean
}

// main
export const AgendaDuQuartierQuartierFormStep5: React.FC<AgendaDuQuartierQuartierFormStep5Props> = React.memo(({
    inline,
    setStep,
    step,
    submitOnly,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [errors, setErrors] = useState<any>({})
    const [fields, setFields] = useState<any>({})
    const [isClosed, setIsClosed] = useState<boolean>(inline || false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (reduxAuth.settings?.quartier?.id) {
            if (inline && isClosed) return
            onGetDetailData()
        }
    }, [
        isClosed,
        reduxAuth.settings?.quartier?.id,
    ])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/detail/quartier/${reduxAuth.settings?.quartier?.id}/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep5',
                dispatch,
                reduxAuth,
                setDetailObject: setFields,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep5',
                'onGetDetailData',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep5',
                'handleInputChange',
            ))
        }
    }

    async function handleSubmit() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuartierFormStep5 handleSubmit')
            const patchUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/update/quartier/${reduxAuth.settings?.quartier?.id}/`, reduxAuth)
            const formData = new FormData()
            formData.append('custom_foreign_key_2', fields.custom_foreign_key_2?.id || '')
            const isStepDone = Boolean(fields.custom_foreign_key_2?.id)
            formData.append('extra_data_json_2', JSON.stringify({
                ...fields.extra_data_json_2,
                quartier_from_steps: {
                    ...fields.extra_data_json_2?.quartier_from_steps,
                    step5: isStepDone,
                }
            }))
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'patch',
                url: patchUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setStep(step + 1)
                    reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: patchUrl,
                        component: 'AgendaDuQuartierQuartierFormStep5',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep5',
                'handleInputChange',
            ))
        }
    }

    return (
        <div className={`sct90f-input-block${inline ? (!isClosed ? ' inline open' : ' inline') : ''}`}>
            <div
                className={`sct90f-title-block${inline && isClosed ? ' closed' : ''}`}
                onClick={inline ? () => setIsClosed(!isClosed) : undefined}
            >
                <p className='sct90f-title'>Autoriser la publication après la date limite (web uniquement)</p>
                {inline && (
                    <div className='sct90f-title-icon'>
                        <i className={`mo-new-icon-check-circle-solid sct90f-status${reduxAuth.settings?.quartier?.extra_data_json_2?.quartier_from_steps?.step5 === true ? '' : ' not-completed'}`} />
                        <div className='sct90f-arrow-wrap'>
                            <i className='mo-new-icon-chevron-down-solid sct90f-arrow' />
                        </div>
                    </div>
                )}
            </div>
            {!isClosed && (
                <div className='sct90f-content'>
                    <div
                        className='sct90f-helper'
                        dangerouslySetInnerHTML={{ __html: reduxText[10004] }}
                    />
                    <SelectListInputV2
                        apiUrl='datapool/select/term/?profile=193057&category=51'
                        clearable
                        name='custom_foreign_key_2'
                        onChange={handleInputChange}
                        profileObject={undefined}
                        value={fields.custom_foreign_key_2 || ''}
                    />
                    <ErrorHelper error={errors?.custom_foreign_key_2} />
                    {fields.custom_foreign_key_2?.id === 380 && (
                        <>
                            <div
                                className='sct90f-helper'
                                dangerouslySetInnerHTML={{ __html: reduxText[10005] }}
                            />
                            <SelectListInputV2
                                apiUrl='datapool/select/term/?profile=193057&category=52'
                                clearable
                                name='custom_foreign_key_3'
                                onChange={handleInputChange}
                                profileObject={undefined}
                                value={fields.custom_foreign_key_3 || ''}
                            />
                            <ErrorHelper error={errors?.custom_foreign_key_3} />
                        </>
                    )}
                    <div className='sct90f-button-block'>
                        <div>
                            {!submitOnly && step > 1 && (
                                <Button
                                    edit={false}
                                    onClick={() => setStep(step - 1)}
                                    text='Précédent'
                                />
                            )}
                        </div>
                        <div className='sct90f-button-block-end'>
                            {!submitOnly && (
                                <>
                                    <Button
                                        edit={false}
                                        fill='clear'
                                        onClick={() => setStep(step + 1)}
                                        text="Répondre plus tard"
                                    />
                                    <div className='sct90f-button-spacing' />
                                </>
                            )}
                            <Button
                                edit={false}
                                onClick={handleSubmit}
                                text={submitOnly ? 'Sauvegarder' : 'Suivant'}
                            />
                        </div>
                    </div>
                    {isLoading && <Loader isOpen />}
                </div>
            )}
        </div>
    )
})
