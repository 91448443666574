// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDropzone,
} from 'react-dropzone'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    TranslationHelper,
} from 'serializers/web'

// props
type ImageInputProps = {
    adminOnly?: boolean
    clear?: boolean
    dark?: boolean
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label: string
    name: string
    onChange: any
    optional?: boolean
    pdfOk?: boolean
    pdfOnly?: boolean
    staffOnly?: boolean
    text: string
    translation?: TranslationHelper
    value: any
}

// main
export const ImageInput: React.FC<ImageInputProps> = React.memo(({
    adminOnly,
    clear,
    dark,
    disabled,
    error,
    helperText,
    label,
    name,
    onChange,
    optional,
    pdfOk,
    pdfOnly,
    staffOnly,
    text,
    translation,
    value,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [files, setFiles] = useState<any>([])
    let acceptObject = {}
    if (pdfOnly) {
        acceptObject = {
            'application/pdf': [],
        }
    } else if (pdfOk) {
        acceptObject = {
            'application/pdf': [],
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/svg+xml': [],
            'image/webp': [],
        }
    } else {
        acceptObject = {
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/webp': [],
        }
    }
    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptObject,
        maxSize: pdfOnly ? undefined : 64000000,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
            onChange({
                name: name,
                value: acceptedFiles[0],
            })
        }
    })

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => URL.revokeObjectURL(file.preview))
    }, [files])

    return (
        <div className={`mo-image-input-web${disabled ? ' disabled' : ''}`}>
            <MainFormLabel
                adminOnly={adminOnly}
                formInput={pdfOnly ? 'pdf' : 'image'}
                helperText={helperText}
                label={label}
                name={name}
                optional={optional}
                staffOnly={staffOnly}
                translation={translation}
                translationDefaultValue={value}
            />
            <div {...getRootProps({ className: 'edc-dropzone-input' })}>
                {(value !== 'to_delete' && (files[0] || value))
                    ? (
                        pdfOnly
                            ? (
                                <div className='edc-input'>
                                    <p className='mo-dropzone-helper-text'>PDF</p>
                                    <p className='mo-dropzone-helper-text mo-clamp-web5'>{files[0] ? files[0].preview : (value.preview || value)}</p>
                                </div>
                            ) : (
                                <img
                                    alt=''
                                    className='edc-preview-img'
                                    src={files[0] ? files[0].preview : (value.preview || value)}
                                />
                            )
                    ) : (
                        <div className='edc-input'>
                            <i className='m-icon mo-new-icon-cloud-upload-alt-solid' />
                            <p className='mo-dropzone-helper-text'>{text || reduxText[7612]}</p>
                        </div>
                    )}
                <input {...getInputProps()} />
            </div>
            {(value !== 'to_delete' && (files[0] || value)) && (
                <div className='eici-buttons'>
                    {(pdfOnly && (value.preview || value)) && (
                        <div className='eici-buttons-div'>
                            <Button
                                className='eici-button'
                                edit={false}
                                fill={dark ? undefined : 'outline'}
                                href={value.preview || value}
                                size='x-small'
                                text='PDF'
                            />
                        </div>
                    )}
                    <div className='eici-buttons-div'>
                        <Button
                            className='eici-button'
                            edit={false}
                            fill={dark ? undefined : 'outline'}
                            onClick={() => {
                                onChange({
                                    doka_image: 'to_delete',
                                    doka_output_data: 'to_delete',
                                    name: name,
                                    value: 'to_delete',
                                })
                            }}
                            size='x-small'
                            text={reduxText[5316]}
                        />
                    </div>
                    <div {...getRootProps({ className: 'eici-buttons-div eici-dropzone-input' })}>
                        <Button
                            className='eici-button'
                            edit={false}
                            fill={dark ? undefined : 'outline'}
                            size='x-small'
                            text={reduxText[5878]}
                        />
                        <input {...getInputProps()} />
                    </div>
                </div>
            )}
            <ErrorHelper error={error} />
        </div>
    )
})
