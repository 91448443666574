// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// containers
import {
    MainFormContainerWrapper,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetAvailabitySite,
    reduxModalErrorEventHandler,
    reduxModalSetEditSite,
    reduxModalSiteSetSpecialSizeSite,
    select_url_portfolio_content_source_available,
} from 'data'

// pages
import {
    AdditionalProductPanel,
    AssetFormState,
    AssetPanel,
    ContainerMixedContentPanel,
    ContainerMixedPanel,
    FreeContentProductPanel,
    ImageProductPanel,
    MainProductPanel,
    MenuProductPanel,
    PortfolioProductPanel,
    PriceProductPanel,
    ProductAdherentContentFormState,
    ProductImageFormState,
    ProductMotherFormState,
    ProductTeamFormState,
    ProductVariantFormState,
    ProjectMixedMediaContentFormState,
    TeamProductPanel,
    VariantProductPanel,
} from 'pages'

// serializers
import {
    ProductFormState,
} from 'serializers/web'

// services
import {
    autoSaveStyles,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailData,
    getDynamicTextOptions,
    getInputValue,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type LocationParams = {
    formRefresh?: number
}

type ProductFormProps = {
    objectId?: number
    onRefresh?: () => void
}

// main
export const ProductForm: React.FC<ProductFormProps> = React.memo(({
    objectId,
    onRefresh,
}) => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<ProductFormState>({
        mother: ProductMotherFormState(reduxAuth),
        adherentContent: ProductAdherentContentFormState(reduxAuth),
        asset: AssetFormState(reduxAuth),
        image: ProductImageFormState(reduxAuth),
        mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
        team: ProductTeamFormState(reduxAuth),
        variant: ProductVariantFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)

    useEffect(() => {
        try {
            autoSaveStyles({
                component: 'ProductForm',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProductForm',
                'useEffect',
            ))
        }
    }, [
        params.panelValue,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailData({
            axiosCancelToken,
            component: 'ProductForm',
            dispatch,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: objectId ? `${objectId}` : params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        objectId,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 28 && formState.mother.fields?.profile?.id) {
            getAvailabeContentSource()
        }
    }, [
        formState.mother.fields?.medium_type?.id,
        formState.mother.fields?.profile?.id,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 28) {
            dispatch(reduxModalSetEditSite(true))
            dispatch(reduxModalSiteSetSpecialSizeSite('mixed-media-edit'))
            if (!reduxFormSite.dynamicTextOptions) {
                getDynamicTextOptions({
                    component: 'ProductForm',
                    dispatch,
                    reduxAuth,
                })
            }
        }
    }, [
        formState.mother.fields?.medium_type?.id,
    ])

    function setInitialState() {
        const mother = ProductMotherFormState(reduxAuth)
        setFormState({
            mother: {
                ...mother,
                fields: {
                    price_on_request: true,
                    profile: !reduxAuth.settings?.user?.is_staff ? reduxAuth.settings?.active_profile : undefined,
                },
            },
            adherentContent: ProductAdherentContentFormState(reduxAuth),
            asset: AssetFormState(reduxAuth),
            image: ProductImageFormState(reduxAuth),
            mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
            team: ProductTeamFormState(reduxAuth),
            variant: ProductVariantFormState(reduxAuth),
        })
    }

    async function getAvailabeContentSource() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProductForm getAvailabeContentSource')
            const getUrl = getApiUrl(`${select_url_portfolio_content_source_available}?profile_id=${formState.mother.fields?.profile?.id}`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetAvailabitySite(response.data[0].available))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ProductForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getAvailabeContentSource',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProductForm',
                'getAvailabeContentSource',
            ))
        }
    }

    function handleInputChange(event: any, formInfo: any) {
        try {
            const mother = formState.mother
            const team = formState.team
            const variant = formState.variant
            if (formInfo.name === 'mother' && event.name === 'profile' && mother) {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        profile: event.value,
                    },
                    errors: {
                        ...mother.errors,
                        profile: '',
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '',
                })
            } else if (formInfo.name === 'mother' && mother) {
                let toReturn
                if (event.name === 'price_on_request') {
                    if (event.value) {
                        toReturn = {
                            ...mother,
                            requiredAttribute: [
                                'name',
                            ],
                        }
                    } else {
                        toReturn = {
                            ...mother,
                            requiredAttribute: [
                                'name',
                                'unit_price',
                            ],
                        }
                    }
                } else if (event.name === 'mixed_media_fonts') {
                    const toReturn = {
                        ...mother,
                        errors: {
                            ...mother.errors,
                            mixed_media_fonts: '',
                        },
                        fields: {
                            ...mother.fields,
                            fonts: event.value,
                            mixed_media_fonts: event.value,
                        },
                    }
                    setFormState({
                        ...formState,
                        mother: toReturn,
                    })
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: true,
                        mainError: '', // TO_CHECK
                    })
                } else if (event.name === 'styles_original') {
                    const toReturn = {
                        ...mother,
                        errors: {
                            ...mother.errors,
                            styles_original: '',
                        },
                        fields: {
                            ...mother.fields,
                            styles: event.preview,
                            styles_original: event.value,
                        },
                    }
                    setFormState({
                        ...formState,
                        mother: toReturn,
                    })
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: true,
                        mainError: '', // TO_CHECK
                    })
                } else if (mother.fields?.price_on_request) {
                    toReturn = {
                        ...mother,
                        requiredAttribute: [
                            'name',
                        ],
                    }
                } else {
                    toReturn = {
                        ...mother,
                        requiredAttribute: [
                            'name',
                            'unit_price',
                        ],
                    }
                }
                setFormState({
                    ...formState,
                    mother: getInputValue({
                        event,
                        formInfo: toReturn,
                        reduxText,
                    }),
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'mixedMediaContent' && event.name === 'styles_original' && mother) {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
            } else if (formInfo.name === 'variant' && variant) {
                let toReturn
                if (event.name === 'price_on_request') {
                    if (event.value) {
                        toReturn = {
                            ...variant,
                            requiredAttribute: [
                                'name',
                            ],
                        }
                    } else {
                        toReturn = {
                            ...variant,
                            requiredAttribute: [
                                'name',
                                'unit_price',
                            ],
                        }
                    }
                }
                else if (variant.fields?.price_on_request) {
                    toReturn = {
                        ...variant,
                        requiredAttribute: [
                            'name',
                        ],
                    }
                }
                else {
                    toReturn = {
                        ...variant,
                        requiredAttribute: [
                            'name',
                            'unit_price',
                        ],
                    }
                }
                setFormState({
                    ...formState,
                    variant: getInputValue({
                        event,
                        formInfo: toReturn,
                        reduxText,
                    }),
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'team' && event.name === 'name' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: '',
                        name: event.value,
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'team' && event.name === 'member' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: event.value,
                        name: '',
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                        'member',
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProductForm',
                'handleInputChange',
            ))
        }
    }

    const panelValue = params.panelValue
    const panelText: any = {
        'additional-panel': reduxText[3234],
        'asset-panel': reduxText[8149],
        'delete-panel': reduxText[2983],
        'image-panel': reduxText[1767],
        'main-panel': reduxText[347],
        'price-panel': reduxText[7717],
        'team-panel': reduxText[4917],
        'variant-panel': reduxText[4374],
        'website-related-panel': reduxText[6415],
        'free-content-panel': 'Free readers',  // TO_CHECK
    }

    const fieldsMother = formState.mother.fields

    return (
        <MainFormContainerWrapper
            formState={formState}
            headerTitle={panelValue ? panelText[panelValue] : ''}
            helpers={helpers}
            onRefresh={onRefresh}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {onRefresh && fieldsMother?.id && (
                <React.Fragment>
                    <p className='mfcmw-custom-edit-title'>{panelText['main-panel']}</p>
                    <MainProductPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['additional-panel']}</p>
                    <AdditionalProductPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['price-panel']}</p>
                    <PriceProductPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['website-related-panel']}</p>
                    <PortfolioProductPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                </React.Fragment>
            )}
            {!onRefresh && (
                <React.Fragment>
                    {panelValue === 'additional-panel' && fieldsMother?.id && (
                        <AdditionalProductPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'main-panel' && (
                        <MainProductPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'asset-panel' && fieldsMother?.id && (
                        <AssetPanel
                            contentType='product'
                            // @ts-ignore
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            // @ts-ignore
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'price-panel' && fieldsMother?.id && (
                        <PriceProductPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'image-panel' && fieldsMother?.id && (
                        <ImageProductPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {params.formType === 'mixed-media' && fieldsMother?.id && (
                        <ContainerMixedPanel
                            contentType='product'
                            // @ts-ignore
                            formState={formState}
                            handleInputChange={handleInputChange}
                            // @ts-ignore
                            setFormState={setFormState}
                        />
                    )}
                    {params.formType === 'mixed-media-content' && fieldsMother?.id && (
                        <ContainerMixedContentPanel
                            contentType='product'
                            // @ts-ignore
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            newMixedMediaContent={false}
                            newMixedMediaContentType={undefined}
                            // @ts-ignore
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'website-related-panel' && fieldsMother?.id && (
                        <PortfolioProductPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'team-panel' && fieldsMother?.id && (
                        <TeamProductPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'variant-panel' && fieldsMother?.id && (
                        <VariantProductPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'free-content-panel' && fieldsMother?.id && (
                        <FreeContentProductPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {!panelValue && (
                        <MenuProductPanel
                            formState={formState}
                            helpers={helpers}
                            setHelpers={setHelpers}
                        />
                    )}
                </React.Fragment>
            )}
        </MainFormContainerWrapper>
    )
})
