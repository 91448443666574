// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    SelectListInputV2,
    TextAreaInputV2,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    MainIdNameSerializer,
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type TextShort2InputPageContentProps = {
    detailUrl: string
    disabled?: boolean
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    inputObject?: LayoutFieldHelperSerializer
    mixedMedia?: boolean
    onHandleInputChange: any
    optional: boolean
    text_short_mode2?: number
    updateUrl: string
}

// main
export const TextShort2InputPageContent: React.FC<TextShort2InputPageContentProps> = React.memo(({
    detailUrl,
    disabled,
    errorsInline,
    fieldsInline,
    fieldsMother,
    inputObject,
    mixedMedia,
    onHandleInputChange,
    optional,
    text_short_mode2,
    updateUrl,
}) => {

    const reduxFormSitedynamicTextOptions = useSelector((state: defaultReduxState) => state.reduxFormSite.dynamicTextOptions)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const modeOptions2 = [  // 'normal_or_long'
        {
            id: 1,
            name: reduxText[6416],  // Normal
        },
        {
            id: 2,
            name: reduxText[6417],  // Rich
        },
    ]
    const modeOptions3 = [  // normal_or_dynamic
        {
            id: 1,
            name: reduxText[6416],  // Normal
        },
        {
            id: 3,
            name: reduxText[6576],  // Dynamic
        },
    ]
    const modeOptions4 = [  // normal_or_long_or_dynamic
        {
            id: 1,
            name: reduxText[6416],  // Normal
        },
        {
            id: 2,
            name: reduxText[6417],  // Rich
        },
        {
            id: 3,
            name: reduxText[6576],  // Dynamic
        },
    ]

    // TEXT_SHORT_MODE_CHOICES = (
    // 	(1, _('normal_only')),
    // 	(2, _('normal_or_long')),
    // 	(3, _('normal_or_dynamic')),
    // 	(4, _('normal_or_long_or_dynamic')),
    // 	(5, _('dynamic_only')),
    // )

    const new_text_short_mode2 = text_short_mode2 || inputObject?.text_short_mode

    let newModeOption: MainIdNameSerializer[] = []
    if (new_text_short_mode2 && [2, 3, 4].includes(new_text_short_mode2)) {
        if (new_text_short_mode2 === 2) {
            newModeOption = modeOptions2
        }
        if (new_text_short_mode2 === 3) {
            newModeOption = modeOptions3
        }
        if (new_text_short_mode2 === 4) {
            newModeOption = modeOptions4
        }
    }

    const newDynamicOption: any = (fieldsInline.content_source?.id && reduxFormSitedynamicTextOptions) ? (reduxFormSitedynamicTextOptions[fieldsInline.content_source.id] || []) : []
    const newDynamicOptionIndex = newDynamicOption.findIndex((val: MainIdNameSerializer) => val.id === fieldsInline.text_short2)

    return (
        <React.Fragment>
            {[2, 3, 4].includes(new_text_short_mode2!) && (
                <SelectListInputV2
                    clearable={false}
                    disabled={disabled}
                    error=''
                    helperText={inputObject?.helper_text}
                    label={inputObject?.label || reduxText[6329]}
                    marginBottom={5}
                    name='text_short_mode2'
                    onChange={onHandleInputChange}
                    options={newModeOption}
                    value={fieldsInline.text_short_mode2 || ''}
                />
            )}
            {(!fieldsInline.text_short_mode2 || (fieldsInline.text_short_mode2?.id === 1)) && (  // Normal
                <TextAreaInputV2
                    disabled={disabled}
                    error={errorsInline?.text_short2}
                    helperText={!new_text_short_mode2 || new_text_short_mode2 === 1 ? (inputObject?.helper_text) : ''}
                    label={!new_text_short_mode2 || new_text_short_mode2 === 1 ? (inputObject?.label || reduxText[6329]) : ''}
                    marginBottom={10}
                    name='text_short2'
                    onChange={onHandleInputChange}
                    // @ts-ignore
                    translation={(fieldsMother.is_multi_language || fieldsMother.portfolio_main?.is_multi_language) ? {
                        contentType: mixedMedia ? 'mixedmediacontent' : 'pagecontent',
                        defaultDetailUrl: detailUrl,
                        defaultLanguage: fieldsInline.translation_default_language,
                        defaultUpdateUrl: updateUrl,
                        objectId: fieldsInline.id,
                        profileId: fieldsMother.profile?.id,
                    } : undefined}
                    value={fieldsInline.text_short2 || ''}
                />
            )}
            {fieldsInline.text_short_mode2?.id === 3 && (  // Dynamic
                <SelectListInputV2
                    clearable={false}
                    disabled={disabled}
                    error={errorsInline?.text_short2}
                    marginBottom={10}
                    name='text_short2'
                    onChange={(e: any) => onHandleInputChange({ name: 'text_short2', value: e.value.id })}
                    options={(fieldsInline.content_source?.id && reduxFormSitedynamicTextOptions) ? reduxFormSitedynamicTextOptions[fieldsInline.content_source.id] : []}
                    optionsSorted
                    value={fieldsInline.text_short2 ? newDynamicOption[newDynamicOptionIndex] : ''}
                />
            )}
        </React.Fragment>
    )
})
