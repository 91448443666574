// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CountViewBlock,
    HeaderDetailBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    MixedContentBlock,
} from 'pages'

// serializers
import {
    PageTemplateDetailSerializer,
} from 'serializers/web'

// props
type PageTemplateDetailBlockProps = {
    edit: boolean
    languageId: number | undefined
    object: PageTemplateDetailSerializer
}

// main
export const PageTemplateDetailBlock: React.FC<PageTemplateDetailBlockProps> = React.memo(({
    edit,
    languageId,
    object,
}) => {

    const reduxAuthisWindows = useSelector((state: defaultReduxState) => state.reduxAuth.isWindows)

    return (
        <div className={`page-template-detail-block-web${edit ? ' edit' : ''}`}>
            <div className='ptdbw-left'>
                <MixedContentBlock
                    contentType='pagetemplate'
                    edit={edit}
                    languageId={languageId}
                    // @ts-ignore
                    object={object}
                />
            </div>
            <div className={`ptdbw-right mo-hidden-vertical-scrollbar${reduxAuthisWindows}`}>
                <HeaderDetailBlock
                    contentType='pagetemplate'
                    edit={edit}
                    languageId={undefined}
                    object={object}
                    setLanguageId={undefined}
                />
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
