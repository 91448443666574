// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_main_custom_category,
    select_url_main_custom_category_2,
    select_url_main_custom_category_3,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
} from 'serializers/web'

// props
type CustomCategoryProjectInputProps = {
    ccKey: number
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    onChange: any
}

// main
export const CustomCategoryProjectInput: React.FC<CustomCategoryProjectInputProps> = React.memo(({
    ccKey,
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const dict: any = {
        1: {
            apiUrl: select_url_main_custom_category,
            error: errors?.custom_categories,
            label: reduxText[5569],
            name: 'custom_categories',
            value: fields.custom_categories,
        },
        2: {
            apiUrl: select_url_main_custom_category_2,
            error: errors?.custom_categories_2,
            label: reduxText[8723],
            name: 'custom_categories_2',
            value: fields.custom_categories_2,
        },
        3: {
            apiUrl: select_url_main_custom_category_3,
            error: errors?.custom_categories_3,
            label: reduxText[8731],
            name: 'custom_categories_3',
            value: fields.custom_categories_3,
        },
    }

    return (
        <SelectListInput
            apiUrl={`${dict[ccKey].apiUrl}?profile_id=${fields.profile?.id}`}
            ccKey={ccKey}
            clearable={false}
            error={dict[ccKey].error}
            label={dict[ccKey].label}
            multi
            name={dict[ccKey].name}
            onChange={onChange}
            optional
            profileId={fields.profile?.id}
            value={(dict[ccKey].value) || ''}
        />
    )
})
