// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainHashtag,
    PrivateButton,
    UnlistedButton,
} from 'components'

// data
import {
    api_url_product_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    searchTabValue,
} from 'data'

// pages
import {
    ProductActionSheetWeb,
} from 'pages'

// serializers
import {
    ProductListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type ProductListSmallBlockWebProps = {
    edit: boolean
    feedId?: number
    isInProfileSlug?: string
    modalKey: number
    object: ProductListSerializer
}

// main
export const ProductListSmallBlockWeb: React.FC<ProductListSmallBlockWebProps> = React.memo(({
    edit,
    feedId,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<ProductListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_product_detail}${detailObject.id}/?block_size=small`, reduxAuth),
                component: 'ProductListSmallBlockWeb',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProductListSmallBlockWeb',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div
            className='product-list-small-block-web'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            <div className={`prlsbw-cover${showActionPopover ? ' is-open' : ''}`}>
                {!activated && (
                    <InactiveButton
                        edit={edit}
                        model='product'
                        objectId={detailObject.id!}
                        objectName={detailObject.name!}
                        setDeleted={setDeleted}
                    />
                )}
                {detailObject.is_private && (
                    <PrivateButton
                        edit={edit}
                        listed={detailObject.is_private_and_listed_in_portfolio}
                    />
                )}
                {detailObject.is_unlisted && (
                    <UnlistedButton edit={edit} />
                )}
                <LinkHelper
                    className='prlsbw-cover-wrap'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    <div className='prlsbw-image-wrap'>
                        <ImageHelper
                            alt={detailObject.name}
                            className='prlsbw-image'
                            dominant_color={detailObject.get_dominant_color}
                            src={detailObject.get_image_square}
                        />
                    </div>
                </LinkHelper>
                <div className='prlsbw-actions'>
                    <ProductActionSheetWeb
                        edit={edit}
                        feedId={feedId}
                        isInProfileSlug={isInProfileSlug}
                        object={detailObject}
                        refreshData={onGetDetailData}
                        setActivated={setActivated}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </div>
            </div>
            <div className='prlsbw-info'>
                {feedId && (
                    <p className='mo-list-feed-name-web'>{reduxText[4356]}</p>
                )}
                <LinkHelper
                    className='mo-list-block-name-web small'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title={detailObject.name}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.name}
                </LinkHelper>
                <div className='prlsbw-price-wrap'>
                    {!detailObject.price_on_request
                        ? (
                            <p className='prlsbw-price'>{detailObject.unit_price} {detailObject.currency?.symbol || detailObject.currency?.iso_code}</p>
                        ) : (
                            <p className='prlsbw-price'>{reduxText[4869]}</p>
                        )}
                </div>
                <div className='list-block-footer'>
                    {detailObject.profile && (
                        <ListProfileLink
                            blockSize='small'
                            edit={edit}
                            other_profiles={detailObject.other_profiles}
                            profile={detailObject.profile}
                        />
                    )}
                </div>
                <div className='prlsbw-tags'>
                    <MainChipListBlock>
                        {detailObject.custom_categories?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                        {detailObject.custom_categories_2?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                        {/* {detailObject.custom_categories_3?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))} */}
                        {detailObject.themes?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
            </div>
        </div>
    )
})
