// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'
import Tooltip from '@mui/material/Tooltip';

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    TranslationForm,
} from 'pages'

// serializer
import {
    MainFontSerializer,
    TranslationHelper,
} from 'serializers/web'

// props
type FormLabelProps = {
    adminOnly?: boolean
    fonts?: MainFontSerializer[]
    formInput?: 'address_json' | 'image' | 'image_crop' | 'pdf' | 'text' | 'textArea' | 'richText'
    helperText?: string
    label: string
    marginBottom?: boolean
    marginTop?: boolean
    name: string
    onRefresh?: () => void
    staffOnly?: boolean
    tinymceStylesObject?: any
    tinymceStylesString?: string
    translation?: TranslationHelper
    translationDefaultValue?: string | number
}

// main
export const FormLabel: React.FC<FormLabelProps> = React.memo(({
    adminOnly,
    fonts,
    formInput,
    helperText,
    label,
    marginBottom,
    marginTop,
    name,
    onRefresh,
    staffOnly,
    tinymceStylesObject,
    tinymceStylesString,
    translation,
    translationDefaultValue,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showTranslation, setShowTranslation] = useState(false)

    return (
        <div
            className='form-label'
            style={{
                marginBottom: marginBottom ? '10px' : '0',
                marginTop: marginTop ? '10px' : '0',
            }}
        >
            <label
                className='form-label-wrap'
                htmlFor={name}
            >
                <span>{label}</span>
            </label>
            <div className='form-label-helper-wrap'>
                {(translation?.objectId && translationDefaultValue && Object.keys(translationDefaultValue).length > 0) && (
                    <span
                        className='form-label-helper pointer'
                        onClick={() => setShowTranslation(true)}
                    >
                        {reduxText[6582]}
                    </span>
                )}
                {adminOnly && (
                    <span className='form-label-helper'>
                        {reduxText[5339]}
                    </span>
                )}
                {staffOnly && (
                    <span className='form-label-helper'>
                        {reduxText[5340]}
                    </span>
                )}
                {helperText && (
                    <>
                        <Tooltip
                            arrow
                            title={helperText}
                        >
                            <i className='form-label-helper-icon mo-new-icon-question-circle-regular' />
                        </Tooltip>
                    </>
                )}
            </div>
            {showTranslation && (
                <TranslationForm
                    fieldName={name}
                    fonts={fonts}
                    formInput={formInput!}
                    onRefresh={onRefresh}
                    setIsOpen={setShowTranslation}
                    tinymceStylesObject={tinymceStylesObject}
                    tinymceStylesString={tinymceStylesString!}
                    translation={translation!}
                    translationDefaultValue={translationDefaultValue!}
                />
            )}
        </div>
    )
})
