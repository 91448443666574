// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ServiceChildDetailFormSerializer,
    ServiceChildDetailFormSerializerError,
} from 'serializers/web'

// props
type HasCustomAddressServiceInputProps = {
    errors: ServiceChildDetailFormSerializerError | undefined
    fields: ServiceChildDetailFormSerializer
    onChange: any
}

// main
export const HasCustomAddressServiceInput: React.FC<HasCustomAddressServiceInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <CheckboxInput
            error={errors?.has_custom_address}
            label={reduxText[8525]}
            name='has_custom_address'
            onChange={onChange}
            value={fields.has_custom_address || ''}
        />
    )
})
