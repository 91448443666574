// react components
import React from 'react'
import axios from 'axios'

// data
import {
    api_url_auth_settings_form_theme_update,
    reduxAuthState,
    reduxModalErrorEventHandler,
} from 'data'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    reduxAuthFetchSettingsHelper,
} from 'services'

export async function changeTheme({
    component,
    dispatch,
    reduxAuth,
    value,
}: {
    component: string
    dispatch: React.Dispatch<any>
    reduxAuth: reduxAuthState
    value: 'dark' | 'light'
}) {
    try {
        if (value === reduxAuth.settings?.theme) return
        if (!reduxAuth.settings?.id) return
        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} changeTheme'`)
        const formData = new FormData()
        formData.append('theme', value)
        const putUrl = getApiUrl(`${api_url_auth_settings_form_theme_update}${reduxAuth.settings?.id}/`, reduxAuth)
        axios({
            data: formData,
            headers: refreshAxiosHeaders,
            method: 'put',
            url: putUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: putUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'changeTheme',
                })
            })
    } catch (error) {
        dispatch(reduxModalErrorEventHandler(
            error,
            component,
            'changeTheme',
        ))
    }
}
