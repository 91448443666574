// Root URL
const filterRoot = 'service/filter/service/'

export const serviceFilterOptions: any[] = [
    {
        api_url: `${filterRoot}country/?`,
        showNone: true,
        slug: 'country',
        textId: 6469,  // reduxText[6469]
    },
    {
        api_url: `${filterRoot}city/?`,
        showNone: true,
        slug: 'city',
        textId: 6468,  // reduxText[6468]
    },
    {
        api_url: `${filterRoot}?filter_model=custom_category`,
        showNone: true,
        slug: 'custom_category',
        textId: 8604,  // reduxText[8604]
    },
    // {
    //     api_url: `${filterRoot}?filter_model=custom_category_2`,
    //     showNone: true,
    //     slug: 'custom_category_2',
    //     textId: 8723,  // reduxText[8723]
    // },
    // {
    //     api_url: `${filterRoot}?filter_model=custom_category_3`,
    //     showNone: true,
    //     slug: 'custom_category_3',
    //     textId: 8731,  // reduxText[8731]
    // },
    {
        api_url: `${filterRoot}?filter_model=theme`,
        showNone: true,
        slug: 'theme',
        textId: 3786,  // reduxText[3786]
    },
    {
        api_url: `${filterRoot}?filter_model=medium_type`,
        slug: 'medium_type',
        textId: 3228,  // reduxText[3228]
    },
    {
        api_url: 'cover_image_status',
        slug: 'cover_image_status',
        textId: '',
    },
]
