// react components
import React from 'react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CountViewBlock,
    Button,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    MainHashtag,
    ProfileShortInfoMobile,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_event_detail,
} from 'data'

// pages
import {
    ProjectListSmallBlockMobile,
    ScheduleListBlockMobile,
    TeamListMediumBlockMobile,
} from 'pages'

// serializers
import {
    EventDetailSerializer,
} from 'serializers/web'

// services
import {
    getSchedule,
} from 'services'

// props
type EventDetailBlockMobileProps = {
    edit: boolean
    modalKeys: {
        project_list: number,
    }
    object: EventDetailSerializer
}

// main
export const EventDetailBlockMobile: React.FC<EventDetailBlockMobileProps> = React.memo(({
    edit,
    modalKeys,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='event-detail-block'>
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='event'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <ImageHelper
                alt={object.name}
                className='edb-cover-image'
                dominant_color={undefined}
                src={object.get_image_lq}
            />
            <div className='edb-event-info'>
                {object.team_list_grouped?.cover?.length! > 0 && (
                    <div className='edb-cover-credit-wrap'>
                        <span className='edb-cover-credit'>� </span>
                        {object.team_list_grouped!.cover!.map((val, i) => (
                            <React.Fragment key={val.id}>
                                <LinkHelper
                                    edit={edit}
                                    to={val.member?.get_absolute_url}
                                >
                                    <span className='edb-cover-credit'>{val.member?.name || val.name}</span>
                                </LinkHelper>
                                {(object.team_list_grouped!.cover!.length > (i + 1)) && (<span className='edb-cover-credit'> / </span>)}
                            </React.Fragment>
                        ))}
                    </div>
                )}
                <p className='edb-name'>{object.name}</p>
                <div className='edb-tags'>
                    {object.themes?.map((val) => (
                        <MainHashtag
                            key={`themes-${val.id}`}
                            edit={edit}
                            label={val.name}
                        />
                    ))}
                </div>
                <div className='edb-profiles'>
                    {object.profile && (
                        <ProfileShortInfoMobile
                            edit={edit}
                            object={object.profile}
                        />
                    )}
                </div>
                <div className='edb-quick-info-div'>
                    <div className='edb-quick-info-wrap'>
                        {object.price && (
                            <span className='edb-quick-info-text'>{reduxText[4799]}: {object.price}</span>
                        )}
                    </div>
                    <div className='edb-quick-info-wrap'>
                        <span className='edb-quick-info-text'>{object.start_date && getSchedule(reduxAuth, object.start_date, object.end_date, object.date_only, object.date_type_choice?.id)}</span>
                    </div>
                    {object.is_online_event && (
                        <div className='edb-quick-info-wrap'>
                            <span className='edb-quick-info-text'>{reduxText[6389]}</span>
                        </div>
                    )}
                    {object.address_json?.length! > 0 && (
                        <div className='edb-quick-info-wrap'>
                            <i className='edb-icon mo-new-icon-map-marker-alt-solid' />
                            {object.address_json?.map((val, i) => (
                                <span
                                    key={i}
                                    className='edb-quick-info-text'
                                >
                                    {val.description}
                                </span>
                            ))}
                        </div>
                    )}
                    <div className='edb-links-wrap'>
                        {object.ticket_link_url && (
                            <a
                                className='edb-sold-out'
                                href={object.ticket_link_url}
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                {reduxText[3530]}
                            </a>
                        )}
                    </div>
                </div>
                <div className='edb-section'>
                    <p className='edb-section-title'>{reduxText[1619]}</p>
                    <Linkify>
                        <p className='edb-description'>{object.description}</p>
                    </Linkify>
                </div>
                {object.schedule_list && object.schedule_list.length > 0 && (
                    <div className='edb-section'>
                        <p className='edb-section-title'>{reduxText[4894]}</p>
                        <div className='edb-section-content'>
                            {object.schedule_list.map((val, i) => (
                                i < 3
                                    ? (
                                        <ScheduleListBlockMobile
                                            key={val.id}
                                            object={val}
                                        />
                                    ) : (
                                        <Button
                                            key={val.id}
                                            className='edb-section-button'
                                            edit={edit}
                                            fill='outline'
                                            size='small'
                                            text={reduxText[5586]}
                                            to={`${view_url_event_detail}${object.id}/${object.slug}/schedule/`}
                                        />
                                    )
                            ))}
                        </div>
                    </div>
                )}
                {object.team_list_grouped && object.team_list_grouped.artist && object.team_list_grouped.artist.length > 0 && (
                    <div className='edb-section'>
                        <p className='edb-section-title'>{reduxText[1622]}</p>
                        <div className='edb-section-content'>
                            {object.team_list_grouped.artist.map((val, i) => (
                                i < 3
                                    ? (
                                        <TeamListMediumBlockMobile
                                            key={val.id}
                                            edit={edit}
                                            object={val}
                                        />
                                    ) : (
                                        <Button
                                            key={val.id}
                                            className='edb-section-button'
                                            edit={edit}
                                            fill='outline'
                                            size='small'
                                            text={reduxText[5588]}
                                            to={`${view_url_event_detail}${object.id}/${object.slug}/artist/`}
                                        />
                                    )
                            ))}
                        </div>
                    </div>
                )}
                {object.team_list_grouped && object.team_list_grouped.venue && object.team_list_grouped.venue.length > 0 && (
                    <div className='edb-section'>
                        <p className='edb-section-title'>{reduxText[4905]}</p>
                        <div className='edb-section-content'>
                            {object.team_list_grouped.venue.map((val, i) => (
                                i < 3
                                    ? (
                                        <TeamListMediumBlockMobile
                                            key={val.id}
                                            edit={edit}
                                            object={val}
                                        />
                                    ) : (
                                        <Button
                                            key={val.id}
                                            className='edb-section-button'
                                            edit={edit}
                                            fill='outline'
                                            size='small'
                                            text={reduxText[5589]}
                                            to={`${view_url_event_detail}${object.id}/${object.slug}/venue/`}
                                        />
                                    )
                            ))}
                        </div>
                    </div>
                )}
                {object.team_list_grouped && object.team_list_grouped.promoter && object.team_list_grouped.promoter.length > 0 && (
                    <div className='edb-section'>
                        <p className='edb-section-title'>{reduxText[1624]}</p>
                        <div className='edb-section-content'>
                            {object.team_list_grouped.promoter.map((val, i) => (
                                i < 3
                                    ? (
                                        <TeamListMediumBlockMobile
                                            key={val.id}
                                            edit={edit}
                                            object={val}
                                        />
                                    ) : (
                                        <Button
                                            key={val.id}
                                            className='edb-section-button'
                                            edit={edit}
                                            fill='outline'
                                            size='small'
                                            text={reduxText[5590]}
                                            to={`${view_url_event_detail}${object.id}/${object.slug}/promoter/`}
                                        />
                                    )
                            ))}
                        </div>
                    </div>
                )}
                {object.team_list_grouped && object.team_list_grouped.partner && object.team_list_grouped.partner.length > 0 && (
                    <div className='edb-section'>
                        <p className='edb-section-title'>{reduxText[4679]}</p>
                        <div className='edb-section-content'>
                            {object.team_list_grouped.partner.map((val, i) => (
                                i < 3
                                    ? (
                                        <TeamListMediumBlockMobile
                                            key={val.id}
                                            edit={edit}
                                            object={val}
                                        />
                                    ) : (
                                        <Button
                                            key={val.id}
                                            className='edb-section-button'
                                            edit={edit}
                                            fill='outline'
                                            size='small'
                                            text={reduxText[5591]}
                                            to={`${view_url_event_detail}${object.id}/${object.slug}/partner/`}
                                        />
                                    )
                            ))}
                        </div>
                    </div>
                )}
                {object.team_list_grouped && object.team_list_grouped.staff && object.team_list_grouped.staff.length > 0 && (
                    <div className='edb-section'>
                        <p className='edb-section-title'>{reduxText[5406]}</p>
                        <div className='edb-section-content'>
                            {object.team_list_grouped.staff.map((val, i) => (
                                i < 3
                                    ? (
                                        <TeamListMediumBlockMobile
                                            key={val.id}
                                            edit={edit}
                                            object={val}
                                        />
                                    ) : (
                                        <Button
                                            key={val.id}
                                            className='edb-section-button'
                                            edit={edit}
                                            fill='outline'
                                            size='small'
                                            text={reduxText[5592]}
                                            to={`${view_url_event_detail}${object.id}/${object.slug}/staff/`}
                                        />
                                    )
                            ))}
                        </div>
                    </div>
                )}
                {object.project_list && object.project_list.length > 0 && (
                    <div className='edb-section'>
                        <p className='edb-section-title'>{reduxText[3554]}</p>
                        <div className='edb-section-content'>
                            {object.project_list.map((val, i) => (
                                i < 4
                                    ? (
                                        <ProjectListSmallBlockMobile
                                            key={val.id}
                                            edit={edit}
                                            modalKey={modalKeys.project_list}
                                            object={val}
                                            playerOrigin={`eventDetail-${object.id}`}
                                        />
                                    ) : (
                                        <Button
                                            key={val.id}
                                            className='edb-section-button'
                                            edit={edit}
                                            fill='outline'
                                            size='small'
                                            text={reduxText[5594]}
                                            to={`${view_url_event_detail}${object.id}/${object.slug}/project/`}
                                        />
                                    )
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {object.owner_info && (
                <CountViewBlock
                    object={object.owner_info}
                />
            )}
        </div>
    )
})
