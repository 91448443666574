// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelper,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    view_url_admin,
    view_url_profile_detail_old,
} from 'data'

// serializers
import {
    ProfileSectionSerializer,
} from 'serializers/web'

// props
type ProfileSectionBlockProps = {
    contentType: contentTypeModelType
    edit: boolean
    profile?: {
        id?: number
        is_new_profile_ux?: string
        slug?: string
    }
    profile_section?: number
    profile_sections?: ProfileSectionSerializer[]
}

// main
export const ProfileSectionBlock: React.FC<ProfileSectionBlockProps> = React.memo(({
    contentType,
    edit,
    profile,
    profile_section,
    profile_sections,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mainProfileSectionsDict: {
        [key: string]: {
            name: string
            slug: string
        }
    } = {
        adherent: {
            name: reduxText[8552],
            slug: 'adherents',
        },
        blocktemplate: {
            name: reduxText[8581],
            slug: 'block-templates',
        },
        customtable: {
            name: reduxText[8583],
            slug: 'custom-table',
        },
        event: {
            name: reduxText[2433],
            slug: 'events',
        },
        material: {
            name: reduxText[301],
            slug: 'equipments',
        },
        offer: {
            name: reduxText[302],
            slug: 'opportunities',
        },
        pagetemplate: {
            name: reduxText[8582],
            slug: 'page-templates',
        },
        press: {
            name: reduxText[4812],
            slug: 'press',
        },
        product: {
            name: reduxText[4696],
            slug: 'product',
        },
        profileteam: {
            name: reduxText[3077],
            slug: 'members',
        },
        project: {
            name: reduxText[3554],
            slug: 'projects',
        },
        resource: {
            name: reduxText[8144],
            slug: 'resources',
        },
        salesorder: {
            name: reduxText[8679],
            slug: 'services',
        },
        service: {
            name: reduxText[7434],
            slug: 'services',
        },
    }

    if (!mainProfileSectionsDict[contentType]) return null

    return (
        <div className='profile-section-block'>
            <span className='profile-section-block-small-text'>{reduxText[7726]}:</span>
            {profile_section && (
                <LinkHelper
                    className={`main-hashtag${edit ? ' edit' : ''}`}
                    edit={edit}
                    to={profile?.is_new_profile_ux ? `${view_url_admin}${profile.id}/dynamic-content/${profile_section}/` : (profile?.slug ? `${view_url_profile_detail_old}${profile.slug}/sections/${profile_section}/${[48959, 50177].includes(profile_section!) ? 'all/' : ''}` : undefined)}
                >
                    {mainProfileSectionsDict[contentType].name}
                </LinkHelper>
            )}
            {profile_sections?.map((val) => (
                <LinkHelper
                    key={val.id}
                    className={`main-hashtag${edit ? ' edit' : ''}`}
                    edit={edit}
                    to={profile?.is_new_profile_ux ? `${view_url_admin}${profile.id}/dynamic-content/${val.id}/${['- Helpers'].includes(val.name!) ? 'all/' : ''}${val.main_parent ? `?main-parent=${val.main_parent}` : ''}${val.parent ? `&parent=${val.parent}` : ''}` : (profile?.slug ? `${view_url_profile_detail_old}${profile.slug}/sections/${val.id}/${['- Helpers'].includes(val.name!) ? 'all/' : ''}${val.main_parent ? `?main-parent=${val.main_parent}` : ''}${val.parent ? `&parent=${val.parent}` : ''}` : undefined)}
                >
                    {val.name}
                </LinkHelper>
            ))}
        </div>
    )
})
