// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    ImageHelper,
    MainIframeBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// services
import {
    parseQuery,
} from 'services'

// props
type IframeContentBlockWebProps = {
    edit: boolean
    newTab?: any
    object: ProjectDetailSerializer
}

// main
export const IframeContentBlockWeb: React.FC<IframeContentBlockWebProps> = React.memo(({
    edit,
    newTab,
    object,
}) => {

    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        const searchParams = parseQuery(location.search)
        const force = searchParams.force
        if (force === 'true') setIsOkForIframe(true)
    }, [
        object.id,
        object.profile?.id,
        reduxAuth.apiRootUrl,
    ])

    const [isOkForIframe, setIsOkForIframe] = useState(false)

    return (
        <React.Fragment>
            {(!newTab && object.link_url && (object.is_ok_for_iframe || isOkForIframe))
                ? (
                    <MainIframeBlock
                        url={object.link_url}
                    />
                ) : (
                    <div className={`iframe-content-block-web mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <div className='icbw-banner'>
                            <ImageHelper
                                alt={object.name}
                                className='icbw-img'
                                dominant_color={object.get_dominant_color}
                                src={object.get_image_hq}
                            />
                        </div>
                        <div className='icbw-description-wrap'>
                            <p className='icbw-name'>{object.name}</p>
                            <p className='icbw-description pre-line'>{object.description}</p>
                        </div>
                        <div className='icbw-button-wrap'>
                            <Button
                                edit={edit}
                                href={object.link_url}
                                text={reduxText[4909]}
                            />
                        </div>
                    </div>
                )}
        </React.Fragment>
    )
})
