// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    ListSpinner,
    SelectListInput,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    select_url_main_currency,
} from 'data'

// pages
import {
    ShippingModalForm,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getMainErrorMessage,
} from 'services'

// props
type MatchParams = {
    id: string
}

type EshopPortfolioPanelProps = {
    formState: PortfolioFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    setFormState: React.Dispatch<PortfolioFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const EshopPortfolioPanel: React.FC<EshopPortfolioPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    useEffect(() => {
        onGetData()
    }, [
        params.id,
        reduxAuth.apiRootUrl,
    ])

    const [isLoading, setIsLoading] = useState(false)
    const [showShippingModal, setShowShippingModal] = useState(false)

    async function onGetData() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EshopPortfolioPanel onGetData')

            const getUrl = `${mother.detailUrl}${params.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            fields: response.data,
                        }
                    })
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'EshopPortfolioPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onGetData',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'EshopPortfolioPanel',
                'onGetData',
            ))
        }
    }

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EshopPortfolioPanel',
                'onHandleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: mother,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    mother: {
                        ...formState.mother,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EshopPortfolioPanel',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EshopPortfolioPanel handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            const axiosUrl = `${mother.updateUrl}${mother.fields?.id}/`
            const formData: any = getFormData(mother)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    dispatch(reduxFormSetRefresh('refreshPreview', mother.fields?.id))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: {},
                            fields: {
                                ...formState.mother.fields,
                                id: response.data.id,
                                slug: response.data.slug,
                            },
                        }
                    })
                    dispatch(reduxFormSetEditingSite(false))
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: reduxText[4798],
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'EshopPortfolioPanel',
                        dispatch,
                        error,
                        formFields: JSON.stringify(mother.fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'EshopPortfolioPanel',
                'handleSubmit',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    if (isLoading) {
        return (
            <ListSpinner
                color='tertiary'
                isLoading
            />
        )
    }

    return (
        <div className={`pfcw-container mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className={`pfcw-container-fields mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <SelectListInput
                    apiUrl={select_url_main_currency}
                    clearable={false}
                    disabled
                    error={errorsMother?.currency}
                    label={reduxText[6270]}
                    name='currency'
                    onChange={onHandleInputChange}
                    value={fieldsMother.currency || ''}
                />
                <TextInput
                    error={errorsMother?.token_stripe_public}
                    helperText={reduxText[630010]}
                    label={reduxText[6300]}
                    name='token_stripe_public'
                    onChange={onHandleInputChange}
                    value={fieldsMother.token_stripe_public || ''}
                />
                <TextInput
                    error={errorsMother?.token_stripe_private}
                    helperText={reduxText[629810]}
                    label={reduxText[6298]}
                    name='token_stripe_private'
                    onChange={onHandleInputChange}
                    value={fieldsMother.token_stripe_private || ''}
                />
                <TextInput
                    error={errorsMother?.token_paypal_client_id}
                    label='token_paypal_client_id'  // TO_TEXT
                    name='token_paypal_client_id'
                    onChange={onHandleInputChange}
                    value={fieldsMother.token_paypal_client_id || ''}
                />
                {fieldsMother.is_shipping_activated && (
                    <Button
                        edit={false}
                        expand='full'
                        onClick={() => setShowShippingModal(true)}
                        size='small'
                        text={reduxText[8524]}
                    />
                )}
                {fieldsMother.is_shipping_activated && showShippingModal && (
                    <ShippingModalForm
                        currency={fieldsMother.currency}
                        portfolio_id={fieldsMother.id!}
                        setIsOpen={setShowShippingModal}
                    />
                )}
            </div>
            <div className='pfcw-footer2'>
                <Button
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    onClick={handleRequired}
                    text={reduxText[6378]}
                />
            </div>
        </div>
    )
})
