// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
} from 'components'

// data
import {
    api_url_block_template_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    BlockTemplateActionSheet,
} from 'pages'

// serializers
import {
    BlockTemplateListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type BlockTemplateListBlockProps = {
    edit: boolean
    isInProfileSlug?: string
    modalKey: number
    object: BlockTemplateListSerializer
}

// main
export const BlockTemplateListBlock: React.FC<BlockTemplateListBlockProps> = React.memo(({
    edit,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<BlockTemplateListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_block_template_detail}${detailObject.id}/?block_size=small`, reduxAuth),
                component: 'BlockTemplateListBlock',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'BlockTemplateListBlock',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div
            className='block-template-list-block-web'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            <div className={`btlbw-cover${showActionPopover ? ' is-open' : ''}`}>
                {!activated && (
                    <InactiveButton
                        edit={edit}
                        model='blocktemplate'
                        objectId={object.id!}
                        objectName={`${object.id}`}
                        setDeleted={setDeleted}
                    />
                )}
                <LinkHelper
                    className='btlbw-cover-wrap'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    <div className='btlbw-image-wrap'>
                        <ImageHelper
                            alt=''
                            className='btlbw-image'
                            dominant_color={detailObject.get_dominant_color}
                            src={detailObject.get_image_lq}
                        />
                    </div>
                </LinkHelper>
                <div className='btlbw-actions'>
                    <BlockTemplateActionSheet
                        edit={edit}
                        isInProfileSlug={isInProfileSlug}
                        object={detailObject}
                        refreshData={onGetDetailData}
                        setActivated={setActivated}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </div>
            </div>
            <div className='btlbw-info'>
                <LinkHelper
                    className='mo-list-block-name-web small'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title=''
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.id}
                </LinkHelper>
            </div>
        </div>
    )
})
