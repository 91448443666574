// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainCountryFlag,
    MainHashtag,
    PrivateButton,
    ProjectPlayButtonWeb,
    UnlistedButton,
} from 'components'

// data
import {
    api_url_project_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    searchTabValue,
} from 'data'

// pages
import {
    ProjectActionSheetWeb,
} from 'pages'

// serializers
import {
    ProjectListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDateWithType,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type PressListMediumBlockWebProps = {
    edit: boolean
    isInProfileSlug?: string
    modalKey: number
    object: ProjectListSerializer
    onModalClick?: (e: ProjectListSerializer) => void
    playerOrigin: string
}

// main
export const PressListMediumBlockWeb: React.FC<PressListMediumBlockWebProps> = React.memo(({
    edit,
    isInProfileSlug,
    modalKey,
    object,
    onModalClick,
    playerOrigin,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<ProjectListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    const count_array: any = {
        1: reduxText[4910],
        8: reduxText[2447],
        4: reduxText[4912],
        5: reduxText[1767],
    }

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_project_detail}${detailObject.id}/?block_size=medium`, reduxAuth),
                component: 'PressListMediumBlockWeb',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PressListMediumBlockWeb',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div
            className='press-list-medium-block-web'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='project'
                    objectId={detailObject.id!}
                    objectName={detailObject.name!}
                    setDeleted={setDeleted}
                />
            )}
            {detailObject.is_private && (
                <PrivateButton
                    edit={edit}
                    listed={detailObject.is_private_and_listed_in_portfolio}
                />
            )}
            {detailObject.is_unlisted && (
                <UnlistedButton edit={edit} />
            )}
            <div className='plmbw-cover'>
                <LinkHelper
                    className='plmbw-cover-wrap'
                    edit={edit}
                    onClick={() => onModalClick ? onModalClick(detailObject) : onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    to={onModalClick ? undefined : getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    <ImageHelper
                        alt={detailObject.name}
                        className='plmbw-image'
                        dominant_color={detailObject.get_dominant_color}
                        src={detailObject.get_image_lq}
                    />
                    {detailObject.duration !== '00:00:00' && (
                        <p className='plmbw-extra-info'>{detailObject.duration}</p>
                    )}
                    {detailObject.medium_type && count_array[detailObject.medium_type] && (
                        <p className='plmbw-extra-info'>{`${detailObject.count_project} ${count_array[detailObject.medium_type]}`}</p>
                    )}
                </LinkHelper>
                {detailObject.is_playable && (
                    <div className='plmbw-play-btn'>
                        <ProjectPlayButtonWeb
                            edit={edit}
                            modalKey={modalKey}
                            object={detailObject}
                            playerOrigin={playerOrigin}
                        />
                    </div>
                )}
            </div>
            <div className='plmbw-actions'>
                <ProjectActionSheetWeb
                    edit={edit}
                    isInProfileSlug={isInProfileSlug}
                    object={detailObject}
                    refreshData={onGetDetailData}
                    setActivated={setActivated}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                />
            </div>
            <div className='plmbw-info'>
                <div className='plmbw-date-wrap'>
                    <span className='plmbw-date'>{getDateWithType(reduxAuth, detailObject.date, 'dd')}</span>
                </div>
                <LinkHelper
                    className='mo-list-block-name-web medium'
                    edit={edit}
                    onClick={() => onModalClick ? onModalClick(detailObject) : onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title={detailObject.name}
                    to={onModalClick ? undefined : getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.name}
                </LinkHelper>
                <LinkHelper
                    className='plmbw-description mo-clamp-web1'
                    edit={edit}
                    onClick={() => onModalClick ? onModalClick(detailObject) : onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title={detailObject.description}
                    to={onModalClick ? undefined : getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.description}
                </LinkHelper>
                <div className='list-block-footer'>
                    {detailObject.profile && (
                        <ListProfileLink
                            blockSize='medium'
                            edit={edit}
                            other_profiles={detailObject.other_profiles}
                            profile={detailObject.profile}

                        />
                    )}
                </div>
                <div className='plmbw-extra'>
                    {detailObject.address_json?.map((val) => (
                        <React.Fragment>
                            {val.countryCode && (
                                <MainCountryFlag
                                    edit={edit}
                                    object={val}
                                    searchTab={searchTabValue.press}
                                />
                            )}
                            <span
                                key={val.placeId}
                                className='plmbw-extra-span'
                            >
                                {val.description}
                            </span>
                        </React.Fragment>
                    ))}
                </div>
                <div className='plmbw-tags'>
                    <MainChipListBlock>
                        {detailObject.custom_categories?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={detailObject.project_type === '1' ? searchTabValue.artwork : (detailObject.project_type === '2' ? searchTabValue.press : searchTabValue.resource)}
                            />
                        ))}
                        {detailObject.custom_categories_2?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                        {detailObject.custom_categories_3?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                        {detailObject.themes?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={detailObject.project_type === '1' ? searchTabValue.artwork : (detailObject.project_type === '2' ? searchTabValue.press : searchTabValue.resource)}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
            </div>
        </div>
    )
})
