// react components
import React from 'react'

// components
import {
    SelectAsyncInput,
} from 'components'

// serializers
import {
    AdherentDetailFormSerializer,
    AdherentDetailFormSerializerError,
} from 'serializers/web'

// props
type ManyToManyProfileTeamsAdherentInputProps = {
    apiUrl: string
    errors: AdherentDetailFormSerializerError | undefined
    fields: AdherentDetailFormSerializer
    label: string
    onChange: any
}

// main
export const ManyToManyProfileTeamsAdherentInput: React.FC<ManyToManyProfileTeamsAdherentInputProps> = React.memo(({
    apiUrl,
    errors,
    fields,
    label,
    onChange,
}) => {

    if (!fields.profile?.id) return null

    return (
        <SelectAsyncInput
            apiUrl={apiUrl}
            clearable={false}
            error={errors?.many_to_many_profile_teams}
            label={label}
            layout='profile'
            multi
            name='many_to_many_profile_teams'
            onChange={onChange}
            optional
            value={fields.many_to_many_profile_teams || ''}
        />
    )
})
