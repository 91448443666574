// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    RadioInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProfileDetailFormSerializer,
    ProfileDetailFormSerializerError,
} from 'serializers/web'

// props
type OwnProfileProfileInputProps = {
    errors: ProfileDetailFormSerializerError | undefined
    fields: ProfileDetailFormSerializer
    onChange: any
}

// main
export const OwnProfileProfileInput: React.FC<OwnProfileProfileInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const ownProfileOptions = [
        {
            text: reduxText[3696],
            value: true,
        },
        {
            text: reduxText[1236],
            value: false,
        },
    ]

    return (
        <RadioInput
            error={errors?.own_profile}
            helperText={reduxText[281110]}
            label={reduxText[2811]}
            name='own_profile'
            onChange={onChange}
            options={ownProfileOptions}
            value={typeof fields.own_profile === 'boolean' ? fields.own_profile : true}
        />
    )
})
