// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    CountViewBlock,
    CreditShortInfo,
    DescriptionDetailBlock,
    HeaderDetailBlock,
    ImageHelper,
    InPagePlayerWrapWeb,
    LinkHelper,
    LocationDetailBlock,
    MainHashtag,
} from 'components'

// containers
import {
    DetailListContainerNew,
} from 'containers'

// data
import {
    defaultReduxState,
    searchTabValue,
    view_url_project_detail,
} from 'data'

// pages
import {
    ArticleContentBlockWeb,
    IframeContentBlockWeb,
    MixedContentBlock,
    PdfContentBlockWeb,
    ProductListMicroBlockWeb,
    TrackContentBlockWeb,
    VideoContentBlockWeb,
    WorkContentBlockWeb,
} from 'pages'

// serializers
import {
    MainTabHelperType,
    ProjectDetailSerializer,
    // ProjectDetailTeamHelperSerializer,
} from 'serializers/web'

// services
import {
    getDateWithType,
} from 'services'

// props
type ProjectDetailBlockWebProps = {
    edit: boolean
    inModal?: boolean
    languageId: number | undefined
    modalKeys: {
        related_products: number,
    }
    object: ProjectDetailSerializer
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const ProjectDetailBlockWeb: React.FC<ProjectDetailBlockWebProps> = React.memo(({
    edit,
    inModal,
    languageId,
    modalKeys,
    object,
    setLanguageId,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    let component = 'cover'
    if (object.medium_type) {
        if (object.medium_type?.id === 1) {
            component = 'audio-album'
        } else if (object.medium_type?.id === 2 && object.is_playable) {
            component = 'audio'
        } else if (object.medium_type?.id === 3 && object.is_playable) {
            component = 'video'
        } else if (object.medium_type?.id === 4) {
            component = 'video-serie'
        } else if (object.medium_type?.id === 5) {
            component = 'image-album'
        } else if (object.medium_type?.id === 7 && object.link_url) {
            component = 'iframe'
        } else if (object.medium_type?.id === 8) {
            component = 'dossier'
        } else if (object.medium_type?.is_custom_page) {
            component = 'custom-page'
        } else if (object.medium_type?.id === 20) {
            component = 'pdf'
        }
    }

    const tabDefaultTitleDict: any = {
        'audio': reduxText[8755],
        'audio-album': reduxText[2443],
        'cover': reduxText[4359],
        'custom-page': reduxText[8489],
        'dossier': reduxText[2447],
        'iframe': reduxText[1842],
        'image-album': reduxText[1625],
        'pdf': reduxText[6602],
        'video': reduxText[8754],
        'video-serie': reduxText[2441],
    }

    useEffect(() => {
        setTabValue({
            id: component,
            title: tabDefaultTitleDict[component],
        })
    }, [
        object?.id,
    ])

    const [tabValue, setTabValue] = useState<MainTabHelperType>({
        id: component,
        title: tabDefaultTitleDict[component],
    })

    const tabArray: MainTabHelperType[] = []
    if (component !== 'cover') {
        tabArray.push(
            {
                id: 'cover',
                title: tabDefaultTitleDict['cover'],
            },
        )
    }
    if (object.medium_type?.is_custom_page) {
        tabArray.push(
            {
                id: 'custom-page',
                title: tabDefaultTitleDict['custom-page'],
            },
        )
    }
    if (component === 'dossier') {
        tabArray.push(
            {
                id: 'dossier',
                title: tabDefaultTitleDict['dossier'],
            },
        )
    }
    if (component === 'iframe') {
        tabArray.push(
            {
                id: 'iframe',
                title: tabDefaultTitleDict['iframe'],
            },
        )
    }
    if (component === 'pdf') {
        tabArray.push(
            {
                id: 'pdf',
                title: tabDefaultTitleDict['pdf'],
            },
        )
    }
    if (component === 'audio') {
        tabArray.push(
            {
                id: 'audio',
                title: tabDefaultTitleDict['audio'],
            },
        )
    }
    if (component === 'audio-album') {
        tabArray.push(
            {
                id: 'audio-album',
                title: tabDefaultTitleDict['audio-album'],
            },
        )
    }
    if (component === 'video') {
        tabArray.push(
            {
                id: 'video',
                title: tabDefaultTitleDict['video'],
            },
        )
    }
    if (component === 'video-serie') {
        tabArray.push(
            {
                id: 'video-serie',
                title: tabDefaultTitleDict['video-serie'],
            },
        )
    }
    if (component === 'image-album' || object.has_images) {  // Keep last
        tabArray.push(
            {
                id: 'image-album',
                title: tabDefaultTitleDict['image-album'],
            },
        )
    }

    const hasTabs = !edit && tabArray.length > 0

    // const duplicateProfile: any = []
    // let creators: ProjectDetailTeamHelperSerializer[] = []
    // let labels: ProjectDetailTeamHelperSerializer[] = []
    // if (object.team_list?.creator?.length! > 0) {
    //     creators = object.team_list!.creator!
    //     object.team_list!.creator!.map((val) => {
    //         if (val.member) duplicateProfile.push(val.member.id)
    //         return false
    //     })
    // }
    // if (object.team_list?.label?.length! > 0) {
    //     labels = object.team_list!.label!
    //     object.team_list!.label!.map((val) => {
    //         if (val.member) duplicateProfile.push(val.member.id)
    //         return false
    //     })
    // }

    return (
        <div className={`main-detail-block project-detail-block-web${tabValue.id === 'custom-page' ? ' medium-type-custom' : ''}${inModal ? ' in-modal' : ''}${edit ? ' edit' : ''}`}>
            <div className={`mdb-left${hasTabs ? ' tabs' : ''}${tabValue.tableHeaderComponent ? ' table' : ''}`}>
                {hasTabs && (
                    <div className='mdb-tabs'>
                        {tabArray.map(val => (
                            <div
                                key={val.id}
                                className={`mdb-buttons${val.id === tabValue.id ? ' active' : ''}`}
                                onClick={() => setTabValue(val)}
                            >
                                <span className='mdb-buttons-name'>{val.title}</span>
                            </div>
                        ))}
                    </div>
                )}
                {(tabValue.listComponent || tabValue.isGallery)
                    ? (
                        <DetailListContainerNew
                            isGallery={tabValue.isGallery}
                            inModal={inModal}
                            listApiUrl={tabValue.listApiUrl!}
                            listComponent={tabValue.listComponent}
                            tableHeaderComponent={tabValue.tableHeaderComponent}
                        />
                    ) : (
                        <React.Fragment>
                            {tabValue.id === 'custom-page'
                                ? (
                                    <MixedContentBlock
                                        contentType='project'
                                        edit={edit}
                                        hasTabs={hasTabs}
                                        languageId={languageId}
                                        object={object}
                                    />
                                ) : (
                                    <React.Fragment>
                                        {tabValue.id === 'cover' && (
                                            <ImageHelper
                                                alt={object.name}
                                                className='mdb-cover-image'
                                                dominant_color={object.get_dominant_color}
                                                lightBox
                                                lightBoxSrc={object.get_image_hq}
                                                src={object.get_image_hq}
                                            />
                                        )}
                                        {tabValue.id === 'audio-album' && (
                                            <TrackContentBlockWeb  // TODO fix player
                                                edit={edit}
                                                object={object}
                                            />
                                        )}
                                        {tabValue.id === 'dossier' && (
                                            <ArticleContentBlockWeb
                                                edit={edit}
                                                object={object}
                                            />
                                        )}
                                        {tabValue.id === 'iframe' && (
                                            <IframeContentBlockWeb
                                                edit={edit}
                                                object={object}
                                            />
                                        )}
                                        {tabValue.id === 'image-album' && (
                                            <WorkContentBlockWeb
                                                edit={edit}
                                                object={object}
                                            />
                                        )}
                                        {tabValue.id === 'pdf' && (
                                            <PdfContentBlockWeb
                                                edit={edit}
                                                object={object}
                                            />
                                        )}
                                        {['audio', 'video'].includes(tabValue.id) && (
                                            <InPagePlayerWrapWeb  // TODO fix on tab change
                                                edit={edit}
                                                object={object}
                                            />
                                        )}
                                        {tabValue.id === 'video-serie' && (
                                            <VideoContentBlockWeb  // TODO fix player
                                                edit={edit}
                                                object={object}
                                            />
                                        )}
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                    )
                }
                {/*  */}
            </div>
            <div className={`mdb-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <HeaderDetailBlock
                    // @ts-ignore
                    contentType={object.project_type?.id === '1' ? 'project' : (object.project_type?.id === '2' ? 'press' : 'resource')}
                    edit={edit}
                    languageId={languageId}
                    object={object}
                    setLanguageId={setLanguageId}
                />
                <DescriptionDetailBlock
                    languageId={languageId}
                    text={object.date && getDateWithType(reduxAuth, object.date, object.date_type_choice?.id)}
                    title={object.date_end && reduxText[4901]}
                />
                <DescriptionDetailBlock
                    languageId={languageId}
                    text={object.date_end && getDateWithType(reduxAuth, object.date_end, object.date_type_choice?.id)}
                    title={reduxText[8142]}
                />
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.description}
                />
                {object.album && (
                    <DescriptionDetailBlock>
                        <div className='pdbw-album-block'>
                            <LinkHelper
                                className='pdbw-album-link'
                                edit={edit}
                                to={object.album.get_absolute_url}
                            >
                                <ImageHelper
                                    alt={object.album.name}
                                    className='pdbw-image'
                                    dominant_color={object.get_dominant_color}
                                    src={object.album.get_image_lq}
                                />
                                <p className='pdbw-album-text'>{reduxText[4887]} {object.album.name}</p>
                            </LinkHelper>
                        </div>
                    </DescriptionDetailBlock>
                )}
                {object.team_list?.team?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[4917]}>
                        {object.team_list!.team!.map((val, i) => (
                            i < 3
                                ? (
                                    <CreditShortInfo
                                        key={val.id}
                                        edit={edit}
                                        object={val}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='pdbw-section-button'
                                        edit={edit}
                                        fill='clear'
                                        size='small'
                                        text={reduxText[5606]}
                                        to={`${view_url_project_detail}${object.id}/${object.slug}/team/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                <LocationDetailBlock
                    address_json={object.address_json}
                    edit={edit}
                    object={object}
                    reduced
                    searchTab={object.project_type?.id === '1' ? searchTabValue.artwork : (object.project_type?.id === '2' ? searchTabValue.press : searchTabValue.resource)}
                    title={reduxText[2292]}
                />
                {object.languages?.length! > 0 && (
                    <DescriptionDetailBlock
                        title={reduxText[4825]}
                    >
                        {object.languages!.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={object.project_type?.id === '1' ? searchTabValue.artwork : (object.project_type?.id === '2' ? searchTabValue.press : searchTabValue.resource)}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.related_products?.length! > 0 && (
                    <DescriptionDetailBlock
                        title={object.related_products!.length! > 1 ? reduxText[6598] : reduxText[6599]}
                    >
                        {object.related_products!.map((val) => (
                            <ProductListMicroBlockWeb
                                key={val.id}
                                edit={edit}
                                modalKey={modalKeys.related_products}
                                object={val}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.lyrics}
                    title={reduxText[3251]}
                />
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
