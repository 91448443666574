// data
import {
    api_url_product_variant_form_active,
    api_url_product_variant_form_create,
    api_url_product_variant_form_detail,
    api_url_product_variant_form_list,
    api_url_product_variant_form_patch,
    api_url_product_variant_form_update,
    reduxAuthState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// props
// main
export function ProductVariantFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'variant',

        // Urls
        activeUrl: getApiUrl(api_url_product_variant_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_product_variant_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_product_variant_form_detail, reduxAuth),
        listUrl: getApiUrl(api_url_product_variant_form_list, reduxAuth),
        patchUrl: getApiUrl(api_url_product_variant_form_patch, reduxAuth),
        updateUrl: getApiUrl(api_url_product_variant_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: {},

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'name',
        ],
        requiredForeignKey: [],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'accept_backorders',
            'active',
            'has_stock_management',
            'is_unique',
            'meta_image_url',
            'name',
            'packaging_weight',
            'price_on_request',
            'sku',
            'stock',
            'unit_price',
            'unit_price_open',
        ],
        formForeignKey: [
            'stock_status',
        ],
        formFile: [],
        formJson: [],
        formManyToMany: [],
    }
}
