// data
import {
    contentTypeModelType,
} from 'data'

// main
export function getModalAbsoluteUrlV2({
    contentType,
    objectId,
    pageType,
    tabId,
}: {
    contentType: contentTypeModelType
    objectId: number
    pageType?: 'detail' | 'form',
    tabId?: number
}) {
    const url = new URL(window.location.href)
    const params = url.searchParams
    params.delete('modal_page_type')
    params.delete('modal_page')
    params.delete('modal_detail_id')
    params.delete('modalTab')
    let newabsoluteUrl = url.pathname + '?' + params.toString()
    if (newabsoluteUrl.slice(-1) !== '?') {
        newabsoluteUrl += '&'
    }
    newabsoluteUrl += `modal_page_type=${pageType}`
    newabsoluteUrl += `&modal_page=${contentType}`
    newabsoluteUrl += `&modal_detail_id=${objectId}`
    if (tabId) {
        newabsoluteUrl += `&modalTab=${tabId}`
    }
    return newabsoluteUrl
}
