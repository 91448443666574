// react components
import React from 'react'

// components
import {
    ImageHelper,
    LinkHelper,
} from 'components'

// props
type RatioHelperProps = {
    alt: string | undefined
    borderRadius?: string
    dominant_color: string | undefined
    edit: boolean
    fixedRatioPercentage?: string
    href?: string
    imageContain?: boolean
    onClick?: () => void
    src: string | undefined
    to: string | undefined
}

// main
export const RatioHelper: React.FC<RatioHelperProps> = React.memo(({
    alt,
    borderRadius,
    dominant_color,
    edit,
    fixedRatioPercentage,
    href,
    imageContain,
    onClick,
    src,
    to,
}) => {

    function onLinkClick() {
        if (onClick) onClick()
    }

    return (
        <LinkHelper
            className={`ratio-helper-web${(to || onClick) ? ' link' : ' no-link'}${imageContain ? ' contain' : ''}`}
            edit={edit}
            href={href}
            onClick={() => onLinkClick()}
            to={to}
        >
            <div
                className='rhw-image-wrap'
                style={{
                    borderRadius: borderRadius,
                    paddingBottom: fixedRatioPercentage,
                }}
            >
                <ImageHelper
                    alt={alt || 'Just The Web'}
                    className='rhw-image'
                    dominant_color={dominant_color}
                    src={src}
                />
            </div>
        </LinkHelper>
    )
})
