// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextAreaInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventDetailFormSerializer,
    EventDetailFormSerializerError,
} from 'serializers/web'

// props
type PriceEventInputProps = {
    errors: EventDetailFormSerializerError | undefined
    fields: EventDetailFormSerializer
    onChange: any
}

// main
export const PriceEventInput: React.FC<PriceEventInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextAreaInput
            error={errors?.price}
            label={reduxText[330]}
            name='price'
            onChange={onChange}
            optional
            value={fields.price || ''}
        />
    )
})
