// react components
import React, {
    useState,
} from 'react'

// components
import {
    InactiveButton,
    LinkHelper,
    MainCountryFlag,
    MainHashtag,
    RatioZarmingHelper,
} from 'components'

// data
import {
    searchTabValue,
} from 'data'

// serializers
import {
    CityTempSerializer,
    ProfileListSerializer,
} from 'serializers/web'

// props
type ProfileListSmallBlockWebProps = {
    edit: boolean
    object: ProfileListSerializer
}

// main
export const ProfileListSmallBlockWeb: React.FC<ProfileListSmallBlockWebProps> = React.memo(({
    edit,
    object,
}) => {

    const [activated] = useState<boolean>(object.active || false)

    const absolute_url = object.get_absolute_url

    // rework country to avoid duplicate flags
    const countryArray: CityTempSerializer[] = []
    if (edit && object.address_json?.length! > 0) {
        const countryArrayTemp: string[] = []
        object.address_json!.map((val) => {
            if (val.countryCode && !countryArrayTemp.includes(val.countryCode)) {
                countryArrayTemp.push(val.countryCode)
                if (countryArray) countryArray.push(val)
            }
            return false
        })
    }

    return (
        <div className='profile-list-small-block-web'>
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='profile'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <div className='pflsbw-wrap'>
                <LinkHelper
                    className='pflsbw-image-wrap'
                    edit={edit}
                    to={absolute_url}
                >
                    <RatioZarmingHelper
                        borderRadius='5%'
                        dominant_color={object.get_dominant_color}
                        edit={edit}
                        fontSize={40}
                        fontWeight={700}
                        height={undefined}
                        imageContain={object.image_thumb_contain}
                        name={object.name}
                        notZarma={Boolean(object.get_image_lq)}
                        src={object.get_image_lq}
                        to={undefined}
                    />
                    <div className='pflsbw-action'>
                        <LinkHelper
                            className='pflsbw-actions-link'
                            edit={edit}
                            to={absolute_url}
                        />
                    </div>
                </LinkHelper>
                <div className='pflsbw-info'>
                    <LinkHelper
                        edit={edit}
                        to={absolute_url}
                    >
                        <p
                            className='mo-list-block-name-web small'
                            title={object.name}
                        >
                            {object.name}
                        </p>
                    </LinkHelper>
                    <div className='pflsbw-chips'>
                        {object.metiers && (
                            <MainHashtag
                                edit={edit}
                                label={object.metiers}
                                searchTab={searchTabValue.profile}
                            />
                        )}
                    </div>
                    <div className='pflsbw-country'>
                        {!edit && object.countries?.map((val) => (
                            <MainCountryFlag
                                key={val.id}
                                edit={edit}
                                object={val}
                                searchTab={searchTabValue.profile}
                            />
                        ))}
                        {edit && countryArray.map((val, i) => (
                            <MainCountryFlag
                                key={i}
                                edit={edit}
                                object={val}
                                searchTab={searchTabValue.profile}
                            />
                        ))}
                        {!edit && object.cities?.map((val) => (
                            <MainHashtag
                                key={`cities-${val.id}`}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.profile}
                            />
                        ))}
                        {edit && object.address_json?.length! > 0 && (
                            <React.Fragment>
                                {object.address_json!.map((val, i) => (
                                    <MainHashtag
                                        key={`cities-${i}`}
                                        edit={edit}
                                        label={val.cityName || val.description}
                                        searchTab={searchTabValue.profile}
                                    />
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
})
