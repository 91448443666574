// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Snackbar,
} from '@mui/material'
import {
    useDropzone,
} from 'react-dropzone'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    DokaModal,
    toURL,
} from '../../../../../@static/vendors/react-doka'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type ImageCropInputV2Props = {
    altButton?: boolean
    altValue?: string
    clear?: boolean
    doka_output_data: any
    errorAlt?: string
    meta_value?: string
    name: string
    onChange: any
    original: string
    value: any
}

// main
export const ImageCropInputV2: React.FC<ImageCropInputV2Props> = React.memo(({
    altButton,
    altValue,
    clear,
    doka_output_data,
    errorAlt,
    meta_value,
    name,
    onChange,
    original,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [actualName, setActualName] = useState<string>(name)
    const [enabled, setEnabled] = useState(false)
    const [files, setFiles] = useState<any>([])
    const [noEdit, setNoEdit] = useState(false)
    const [noEditMessage, setNoEditMessage] = useState(false)
    const [outputData, setOutputData] = useState<any>({})
    const [result, setResult] = useState<any>(undefined)
    const [showAltInput, setShowAltInput] = useState(Boolean(altValue))
    const [src, setSrc] = useState<any>(value)
    const [status, setStatus] = useState<string>('add')
    const [uploadError, setUploadError] = useState<string>()

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/gif': [],
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/svg+xml': [],
            'image/webp': [],
        },
        maxSize: 64000000,
        onDrop: (acceptedFiles, fileRejections) => {
            const files = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            const isSVG = files[0]?.type === 'image/svg+xml'
            if (isSVG) {
                setActualName(`${actualName}_svg`)
            } else {
                setActualName(actualName)
            }
            if (files[0]?.preview) {
                setUploadError(undefined)
                setFiles(files)
                setOutputData({})
                setResult(undefined)
                if (isSVG) {
                    onChange({
                        doka_image: acceptedFiles[0],
                        doka_output_data: 'to_delete',
                        fileName: acceptedFiles[0].name.replace(/\.[^/.]+$/, ''),
                        name: `${actualName}_svg`,
                    })
                } else {
                    onChange({
                        doka_image: acceptedFiles[0],
                        doka_output_data: 'to_delete_svg',
                        doka_square_image: true,
                        fileName: acceptedFiles[0].name.replace(/\.[^/.]+$/, ''),
                        get_image_hq: files[0]?.preview,
                        get_image_lq: files[0]?.preview,
                        get_image_mq: files[0]?.preview,
                        name: name,
                        newDrop: true,
                        value: acceptedFiles[0],
                    })
                }
                setStatus('edit')
                setNoEdit(false)
                setSrc(files[0]?.preview)
            } else {
                fileRejections.map(({ file, errors }) => {
                    const formattedErrors = errors.map(error => {
                        if (error.code === 'file-too-large') {
                            return `${reduxText[10175]} ${(64000000 / (1024 * 1024)).toFixed()}MB.`
                        } else {
                            return error.message
                        }
                    }).join(' ')
                    setUploadError(formattedErrors)
                })
                setNoEdit(false)
                setResult(undefined)
                setStatus('add')
            }
        }
    })

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => URL.revokeObjectURL(file.preview))
    }, [files])

    useEffect(() => {
        if (original) {
            setSrc(value === 'to_delete' ? undefined : original)
            try {
                setOutputData(JSON.parse(doka_output_data))
            } catch {
                setOutputData({})
            }
            setStatus(value === 'to_delete' ? 'add' : 'edit')
            setNoEdit(false)
        } else {
            setStatus('add')
        }
    }, [original])

    useEffect(() => {
        if (meta_value) {
            setSrc(meta_value)
            setStatus('edit')
            setNoEdit(true)
        }
    }, [meta_value])

    function handleToggle() {
        try {
            setEnabled(!enabled)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropInputV2',
                'handleToggle',
            ))
        }
    }

    function handleDokaConfirm(output: any) {
        try {
            setEnabled(false)
            setResult(output.file)
            setOutputData(output.data)
            setStatus('edit')
            setNoEdit(false)
            // @ts-ignore
            const previewImage = output.file ? toURL(output.file) : (files[0] ? files[0].preview : (value.preview || value))
            onChange({
                doka_image: output.file,
                doka_output_data: output.data,
                doka_square_image_check: true,
                get_image_hq: previewImage,
                get_image_lq: previewImage,
                get_image_mq: previewImage,
                name: name,
                value: files[0] || value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropInputV2',
                'handleDokaConfirm',
            ))
        }
    }

    function handleDokaCancel() {
        try {
            setEnabled(false)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropInputV2',
                'handleDokaCancel',
            ))
        }
    }

    return (
        <div className='image-crop-input'>
            <div className='ici-input-div'>
                {status === 'edit' && (
                    <div {...getRootProps({ className: 'ici-image-edit-wrap' })}>
                        {result
                            ? (
                                <img
                                    alt=''
                                    className='ici-image'
                                    // @ts-ignore
                                    src={toURL(result)}
                                />
                            ) : (
                                <img
                                    alt=''
                                    className='ici-image'
                                    src={files[0] ? files[0].preview : (value.preview || value)}
                                />
                            )}
                        <input {...getInputProps()} />
                    </div>
                )}
                {status === 'add' && (
                    <div {...getRootProps({ className: 'ici-image-add-wrap' })}>
                        {uploadError
                            ? (
                                <div className='ici-input error'>
                                    <i className='m-icon mo-new-icon-times-solid' />
                                    <p className='mo-dropzone-helper-text'>{uploadError}</p>
                                </div>
                            ) : (
                                <div className='ici-input'>
                                    <i className='m-icon mo-new-icon-cloud-upload-alt-solid' />
                                    <p className='mo-dropzone-helper-text'>{reduxText[5877]}</p>
                                </div>
                            )
                        }
                        <input {...getInputProps()} />
                    </div>
                )}
                {status === 'edit' && (
                    <div className={`ici-buttons-wrap${clear ? ' clear' : ''}`}>
                        {clear && (
                            <div className='ici-buttons-div'>
                                <Button
                                    className='ici-button'
                                    edit={false}
                                    onClick={() => {
                                        onChange({
                                            doka_image: 'to_delete',
                                            doka_output_data: 'to_delete',
                                            name: actualName,
                                            value: 'to_delete',
                                        })
                                    }}
                                    size='x-small'
                                    text={reduxText[5316]}
                                />
                            </div>
                        )}
                        <div {...getRootProps({ className: 'ici-buttons-div ici-dropzone-input' })}>
                            <Button
                                className='ici-button'
                                edit={false}
                                size='x-small'
                                text={reduxText[5878]}
                            />
                            <input {...getInputProps()} />
                        </div>
                        <div className='ici-buttons-div'>
                            <Button
                                className='ici-button'
                                edit={false}
                                onClick={noEdit ? () => setNoEditMessage(true) : handleToggle}
                                size='x-small'
                                text={reduxText[5879]}
                            />
                        </div>
                        {(altButton && value) && (
                            <div className='ici-buttons-div'>
                                <Button
                                    className='ici-button'
                                    edit={false}
                                    onClick={() => setShowAltInput(!showAltInput)}
                                    size='x-small'
                                    text='Alt'  // TO_TEXT
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {/* {showAltInput && (
                <div className='ici-alt-wrap'>
                    <TextInputV2
                        error={errorAlt}
                        label={reduxText[8682]}
                        name={`${name}_alt`}
                        onChange={onChange}
                        value={altValue || ''}
                    />
                </div>
            )} */}
            {enabled && (
                <DokaModal
                    color={outputData.color}  // TO_CHECK
                    crop={outputData.crop}
                    filter={outputData.filter}  // TO_CHECK
                    markup={outputData.markup}  // TO_CHECK
                    onCancel={handleDokaCancel}
                    onConfirm={handleDokaConfirm}
                    outputData
                    src={src}
                    utils={['crop', 'filter', 'color', 'markup']}
                    markupFontFamily={['FuturaPT, Helvetica, Arial, Verdana']}
                    markupFontFamilyOptions={[
                        // ['Georgia', 'Georgia, serif'],
                        ['Palatino Linotype', '\'Palatino Linotype\', \'Book Antiqua\', Palatino, serif'],
                        // ['Times New Roman', '\'Times New Roman\', Times, serif'],
                        // ['FuturaPT', '\'FuturaPT-Book\', \'sans-serif\''],
                        ['Arial', 'Arial, Helvetica, \'sans-serif\''],
                        ['Arial Black', '\'Arial Black\', Gadget, \'sans-serif\''],
                        ['Comic Sans MS', '\'Comic Sans MS\', cursive, \'sans-serif\''],
                        ['Impact', 'Impact, Charcoal, \'sans-serif\''],
                        // ['Lucida Sans Unicode', '\'Lucida Sans Unicode\', \'Lucida Grande\', \'sans-serif\''],
                        ['Tahoma', 'Tahoma, Geneva, \'sans-serif\''],
                        // ['Tahoma', '\'Tahoma\', Helvetica, \'sans-serif\''],
                        ['Verdana', 'Verdana, Geneva, \'sans-serif\''],
                        ['Courier New MS', '\'Courier New\', Courier, monospace'],
                        ['Lucida Console', 'Monaco, monospace'],
                    ]}
                    cropAspectRatioOptions={[
                        {
                            label: reduxText[5904],
                            value: null
                        },
                        {
                            label: reduxText[5905],
                            value: '1:1'
                        },
                        {
                            label: `${reduxText[5906]} 5/4`,
                            value: '5:4'
                        },
                        {
                            label: `${reduxText[5906]} 4/3`,
                            value: '4:3'
                        },
                        {
                            label: `${reduxText[5906]} 3/2`,
                            value: '3:2'
                        },
                        {
                            label: `${reduxText[5906]} 5/3`,
                            value: '5:3'
                        },
                        {
                            label: `${reduxText[5906]} 16/9`,
                            value: '16:9'
                        },
                        {
                            label: `${reduxText[5906]} 21/9`,
                            value: '21:9'
                        },
                        {
                            label: `${reduxText[5906]} 3/1`,
                            value: '3:1'
                        },
                        {
                            label: `${reduxText[5907]} 5/4`,
                            value: '4:5'
                        },
                        {
                            label: `${reduxText[5907]} 4/3`,
                            value: '3:4'
                        },
                        {
                            label: `${reduxText[5907]} 3/2`,
                            value: '2:3'
                        },
                        {
                            label: `${reduxText[5907]} 5/3`,
                            value: '3:5'
                        },
                        {
                            label: `${reduxText[5907]} 16/9`,
                            value: '9:16'
                        },
                        {
                            label: `${reduxText[5907]} 21/9`,
                            value: '9:21'
                        },
                        {
                            label: `${reduxText[5907]} 3/1`,
                            value: '1:3'
                        }
                    ]}
                />
            )}
            <Snackbar
                action={
                    <Button
                        edit={false}
                        fill='clear'
                        onClick={() => setNoEditMessage(false)}
                        size='small'
                        text={reduxText[4742]}
                    />
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                message={reduxText[5903]}
                onClose={() => setNoEditMessage(false)}
                open={noEditMessage}
            />
        </div>
    )
})
