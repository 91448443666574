// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropSquareInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    MaterialDetailFormSerializer,
    MaterialDetailFormSerializerError,
} from 'serializers/web'

// props
type CoverImageSquareMaterialInputProps = {
    errors: MaterialDetailFormSerializerError | undefined
    fields: MaterialDetailFormSerializer
    onChange: any
}

// main
export const CoverImageSquareMaterialInput: React.FC<CoverImageSquareMaterialInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <ImageCropSquareInput
            doka_output_data={fields.cover_image_square_doka_output_data || ''}
            error={errors?.cover_image_square}
            label={reduxText[6386]}
            name='cover_image_square'
            onChange={onChange}
            original={fields.cover_image_original || fields.cover_image || ''}
            value={fields.cover_image_square || ''}
        />
    )
})
