// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventDetailFormSerializer,
    EventDetailFormSerializerError,
    EventFormState,
} from 'serializers/web'

// props
type CoverImageEventInputProps = {
    errors: EventDetailFormSerializerError | undefined
    fields: EventDetailFormSerializer
    mother: EventFormState['mother']
    onChange: any
}

// main
export const CoverImageEventInput: React.FC<CoverImageEventInputProps> = React.memo(({
    errors,
    fields,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <ImageCropInput
            altButton
            altValue={fields.cover_image_alt}
            clear
            doka_output_data={fields.cover_image_doka_output_data || ''}
            error={errors?.cover_image}
            errorAlt={errors?.cover_image_alt}
            helperText={reduxText[435910]}
            label={reduxText[4359]}
            name='cover_image'
            onChange={onChange}
            original={fields.cover_image_original || ''}
            translation={{
                contentType: 'event',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            value={fields.cover_image || ''}
        />
    )
})
