// react components
import React, {
    useState,
} from 'react'
import {
    IonButtons,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ActionComponent,
    AupouCurrentLocationHelper,
    IconBlock,
} from 'components'

// data
import {
    contentTypeModelType,
    contentTypeSettingsData,
    defaultReduxState,
    reduxModalMainFormShow,
    view_url_portfolio_form,
} from 'data'

// serializers
import {
    ContentTypeSettingsTypes,
    ContentTypeSettingsCustomTypes,
    MainPortfolioSerializer,
} from 'serializers/web'

// services
import {
    getModalAbsoluteUrlV2,
    onClickIsModal,
} from 'services'

// props
type DetailActionProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    contentTypeSettingsCustom: ContentTypeSettingsCustomTypes | undefined
    inModal?: boolean
    object: {
        id?: number
        active?: boolean
        hostname?: string
        member?: {
            id?: number
            slug?: string
        }
        name?: string
        profile?: {
            id?: number
            slug?: string
        }
        owner_info?: {
            content_uuid?: string
            permission?: boolean
        }
        portfolio_main?: MainPortfolioSerializer
        slug?: string
    }
    refreshData: () => void
}

// main
export const DetailAction: React.FC<DetailActionProps> = React.memo(({
    contentType,
    contentTypeSettings,
    contentTypeSettingsCustom,
    inModal,
    object,
    refreshData,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showActionPopover, setShowActionPopover] = useState(false)

    if (!object.id) return null

    return (
        <React.Fragment>
            <IonButtons
                className='detail-action'
                slot='start'
            >
                <ActionComponent
                    contentType={contentType}
                    contentTypeSettings={contentTypeSettings}
                    defaultFields={undefined}
                    edit={false}
                    inDetail
                    inModal={inModal}
                    object={object}
                    refreshData={refreshData}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                />
                {reduxAuth.settings?.user?.id === 1 && (
                    <>
                        {contentTypeSettingsCustom?.meta?.id && (
                            <IconBlock
                                edit={false}
                                iconClass='mo-new-icon-cog-solid'
                                onClick={() => onClickIsModal(contentTypeSettingsCustom.meta!.id, Math.random(), dispatch)}
                                to={getModalAbsoluteUrlV2({
                                    contentType: 'setup_contenttypesettingsprofile',
                                    objectId: contentTypeSettingsCustom.meta.id,
                                    pageType: 'detail',
                                })}
                                tooltipText={reduxText[9354]}
                            />
                        )}
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-globe-solid'
                            onClick={() => onClickIsModal(contentTypeSettings.meta.id, Math.random(), dispatch)}
                            to={getModalAbsoluteUrlV2({
                                contentType: 'setup_contenttypesettings',
                                objectId: contentTypeSettings.meta.id,
                                pageType: 'detail',
                            })}
                            tooltipText={reduxText[9354]}
                        />
                    </>
                )}
                {reduxAuth.settings?.user?.is_staff && object.portfolio_main && (
                    <IconBlock
                        edit={false}
                        iconClass='mo-new-icon-content'
                        to={`${view_url_portfolio_form}${object.portfolio_main.id}/page-list/`}
                        tooltipText={reduxText[8585]}
                    />
                )}
                {inModal && (
                    <AupouCurrentLocationHelper />
                )}
            </IonButtons>
        </React.Fragment>
    )
})
