// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ContributionDetailFormSerializer,
    ContributionDetailFormSerializerError,
} from 'serializers/web'

// props
type YearContributionInputProps = {
    errors: ContributionDetailFormSerializerError | undefined
    fields: ContributionDetailFormSerializer
    onChange: any
}

// main
export const YearContributionInput: React.FC<YearContributionInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            error={errors?.year}
            label={reduxText[8613]}
            name='year'
            onChange={onChange}
            type='number'
            value={fields.year || ''}
        />
    )
})
