export const richTextStyles = [
    'background',
    'borderColor',
    'borderRadius',
    'borderStyle',
    'borderWidth',
    'color',
    'defaultMargin',
    'fontFamily',
    'fontWeight',
    'letterSpacing',
    'lineHeight',
    'margin',
    'maxWidth',
    'padding',
    'textAlign',
    'width',
]
