// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainCountryFlag,
    MainHashtag,
    PrivateButton,
    UnlistedButton,
} from 'components'

// data
import {
    api_url_offer_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    searchTabValue,
} from 'data'

// pages
import {
    OfferActionSheetWeb,
} from 'pages'

// serializers
import {
    OfferListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDateWithType,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type OfferListMediumBlockWebProps = {
    edit: boolean
    isInProfileSlug?: string
    modalKey: number
    object: OfferListSerializer
}

// main
export const OfferListMediumBlockWeb: React.FC<OfferListMediumBlockWebProps> = React.memo(({
    edit,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<OfferListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_offer_detail}${detailObject.id}/?block_size=medium`, reduxAuth),
                component: 'OfferListMediumBlockWeb',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'OfferListMediumBlockWeb',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div
            className='offer-list-medium-block-web'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='offer'
                    objectId={detailObject.id!}
                    objectName={detailObject.name!}
                    setDeleted={setDeleted}
                />
            )}
            {detailObject.is_private && (
                <PrivateButton
                    edit={edit}
                    listed={detailObject.is_private_and_listed_in_portfolio}
                />
            )}
            {detailObject.is_unlisted && (
                <UnlistedButton edit={edit} />
            )}
            <LinkHelper
                className='olmbw-cover'
                edit={edit}
                onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
            >
                <ImageHelper
                    alt={detailObject.name}
                    className='olmbw-image'
                    dominant_color={detailObject.get_dominant_color}
                    src={detailObject.get_image_lq}
                />
            </LinkHelper>
            <div className='olmbw-info'>
                <LinkHelper
                    className='mo-list-block-name-web medium'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title={detailObject.name}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.name}
                </LinkHelper>
                <div className='olmbw-date'>
                    {detailObject.always_open
                        ? (
                            <span className='olmbw-always_open'>{reduxText[3489]}</span>
                        ) : (
                            <React.Fragment>
                                <span className='olmbw-text'>{reduxText[5321]}</span>
                                <span className='olmbw-day'>{getDateWithType(reduxAuth, detailObject.valid_date, 'd')}</span>
                            </React.Fragment>
                        )}
                </div>
                {((detailObject.countries && detailObject.countries.length > 0) || (detailObject.cities && detailObject.cities.length > 0)) && (
                    <div className='olmbw-countries'>
                        <MainChipListBlock>
                            {detailObject.countries?.map((val) => (
                                <MainCountryFlag
                                    key={val.id}
                                    edit={edit}
                                    object={val}
                                    searchTab={searchTabValue.offer}
                                />
                            ))}
                            {detailObject.cities?.map((val) => (
                                <MainHashtag
                                    key={val.id}
                                    edit={edit}
                                    label={val.name}
                                    searchTab={searchTabValue.offer}
                                />
                            ))}
                        </MainChipListBlock>
                    </div>
                )}
                <div className='list-block-footer'>
                    {detailObject.profile && (
                        <ListProfileLink
                            blockSize='medium'
                            edit={edit}
                            profile={detailObject.profile}
                        />
                    )}
                </div>
                <div className='olmbw-tags'>
                    <MainChipListBlock>
                        {detailObject.custom_categories?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.event}
                            />
                        ))}
                        {/* {detailObject.custom_categories_2?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                        {detailObject.custom_categories_3?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))} */}
                    </MainChipListBlock>
                </div>
            </div>
            <div className='olmbw-actions'>
                <OfferActionSheetWeb
                    edit={edit}
                    isInProfileSlug={isInProfileSlug}
                    object={detailObject}
                    refreshData={onGetDetailData}
                    setActivated={setActivated}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                />
            </div>
        </div>
    )
})
