// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    BackgroundImageHelper,
    Button,
    ErrorHelper,
    Loader,
    TextInput,
} from 'components'

// data
import {
    api_url_auth_password_reset,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// main
export const PasswordResetPage: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type errorsType = {
        detail?: string
        non_field_errors?: string
        email?: string
    }
    type fieldsType = {
        email: string
    }
    const errorsInitial = {
        non_field_errors: '',
        email: '',
    }
    const fieldsInitial = {
        email: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })  // should be after setFields to avoid bug on TextArea
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PasswordResetPage',
                'handleInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            setButtonDisabled(true)
            setIsLoading(true)

            const formData = new FormData()
            formData.append('email', fields.email.toLowerCase().trim())
            const createUrl = getApiUrl(api_url_auth_password_reset, reduxAuth)
            axios({
                data: formData,
                method: 'post',
                url: createUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setFields(fieldsInitial)
                    setIsLoading(false)
                    setIsSuccess(true)
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (error.response) {
                        setErrors(error.response && error.response.data)
                    }
                    axiosErrorHandler({
                        apiUrl: createUrl,
                        component: 'PasswordResetPage',
                        dispatch,
                        error,
                        formFieldsToIgnore: ['email'],
                        reduxAuth,
                        reference: 'onSubmit',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'PasswordResetPage',
                'onSubmit',
            ))
        }

    }

    return (
        <IonPage className='login-page-web navbar-pt'>
            <Loader isOpen={isLoading} />
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='lpw-content'>
                    <div className='lpw-left auth-global-block-web'>
                        <h1 className='lpw-title'>{reduxText[4673]}</h1>
                        <TextInput
                            error={errors.email}
                            label={reduxText[4675]}
                            name='email'
                            onChange={handleInputChange}
                            value={fields.email}
                        />
                        <ErrorHelper error={errors.non_field_errors || errors.detail} />
                        <Button
                            disabled={buttonDisabled}
                            edit={false}
                            onClick={onSubmit}
                            text={reduxText[4673]}
                        />
                        {isSuccess && (
                            <div className='success-registration'>
                                <div className='success-message pre-line'>
                                    <p>{reduxText[4672]}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <BackgroundImageHelper
                        className='lpw-right'
                        dominant_color={reduxText[620900]}
                        src={reduxText[6209]}
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}
