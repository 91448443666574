// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    ImageHelper,
    LinkHelper,
} from 'components'

// serializers
import {
    PortfolioTemplateListSerializer,
} from 'serializers/web'

// services
import {
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type PortfolioTemplateListBlockProps = {
    isInForm?: boolean
    modalKey: number
    object: PortfolioTemplateListSerializer
}

// main
export const PortfolioTemplateListBlock: React.FC<PortfolioTemplateListBlockProps> = React.memo(({
    isInForm,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()

    return (
        <LinkHelper
            edit={false}
            className='portfolio-template-list-block-web'
            onClick={!isInForm ? () => onClickIsModal(object.id!, modalKey, dispatch) : undefined}
            to={!isInForm ? getModalAbsoluteUrl(object.get_absolute_url!) : undefined}
        >
            <div className='ptlbw-cover'>
                <div className='ptlbw-image-wrap'>
                    <ImageHelper
                        alt={object.name}
                        className='ptlbw-image'
                        dominant_color={object.get_dominant_color}
                        src={object.get_image_lq}
                    />
                </div>
            </div>
            <div className='ptlbw-footer'>
                <h3
                    className='mo-list-block-name-web'
                    title={object.name}
                >
                    {object.name}
                </h3>
            </div>
        </LinkHelper>
    )
})
