// react components
import React, {
    useState,
} from 'react'

// components
import {
    InactiveButton,
    LinkHelper,
    MainCountryFlag,
    MainHashtag,
    RatioHelper,
} from 'components'

// data
import {
    searchTabValue,
} from 'data'

// pages
import {
    ProfileActionSheetWeb,
} from 'pages'

// serializers
import {
    CityTempSerializer,
    ProfileListSerializer,
} from 'serializers/web'

// props
type ProfileListMediumBlockWebProps = {
    edit: boolean
    object: ProfileListSerializer
}

// main
export const ProfileListMediumBlockWeb: React.FC<ProfileListMediumBlockWebProps> = React.memo(({
    edit,
    object,
}) => {

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [showActionPopover, setShowActionPopover] = useState(false)

    const absolute_url = object.get_absolute_url

    // rework country to avoid duplicate flags
    const countryArray: CityTempSerializer[] = []
    if (edit && object.address_json && object.address_json.length > 0) {
        const countryArrayTemp: string[] = []
        if (object.address_json && object.address_json.length > 0) {
            object.address_json.map((val) => {
                if (val.countryCode && !countryArrayTemp.includes(val.countryCode)) {
                    countryArrayTemp.push(val.countryCode)
                    if (countryArray) countryArray.push(val)
                }
                return false
            })
        }
    }

    function onGetDetailData() {
        console.log('TODO');
        // try {
        //     getDetailObject(
        //         getApiUrl(`${api_url_project_detail}${detailObject.id}/?block_size=medium`, reduxAuth),
        //         reduxAuth,
        //         dispatch,
        //         setDetailObject,
        //         'PressListMediumBlockWeb',
        //     )
        // } catch (error) {
        //     dispatch(reduxModalErrorEventHandler(
        //         error,
        //         'PressListMediumBlockWeb',
        //         'onGetDetailData',
        //     ))
        // }
    }

    return (
        <div className='profile-list-medium-block-web'>
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='profile'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <div className='pflmb-wrap'>
                <div className='pflmb-profile-pic'>
                    <RatioHelper
                        alt={object.name}
                        borderRadius='5%'
                        dominant_color={object.get_dominant_color}
                        edit={edit}
                        src={object.get_image_lq}
                        to={absolute_url}
                    />
                </div>
                <div className='pflmb-right'>
                    <div className='pflmb-info'>
                        <LinkHelper
                            edit={edit}
                            to={absolute_url}
                        >
                            <p
                                className='mo-list-block-name-web'
                                title={object.name}
                            >
                                {object.name}
                            </p>
                        </LinkHelper>
                        <div className='pflmb-chips'>
                            {object.metiers && (
                                <MainHashtag
                                    edit={edit}
                                    label={object.metiers}
                                    searchTab={searchTabValue.profile}
                                />
                            )}
                        </div>
                        <div className='pflmb-country'>
                            {!edit && object.countries && object.countries.length > 0 && (
                                <React.Fragment>
                                    {object.countries.map((val) => (
                                        <MainCountryFlag
                                            key={val.id}
                                            edit={edit}
                                            object={val}
                                            searchTab={searchTabValue.profile}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                            {edit && countryArray && countryArray.length > 0 && (
                                <React.Fragment>
                                    {countryArray.map((val, i) => (
                                        <MainCountryFlag
                                            key={i}
                                            edit={edit}
                                            object={val}
                                            searchTab={searchTabValue.profile}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                            {!edit && object.cities?.map((val) => (
                                <MainHashtag
                                    key={`cities-${val.id}`}
                                    edit={edit}
                                    label={val.name}
                                    searchTab={searchTabValue.profile}
                                />
                            ))}
                            {edit && object.address_json && object.address_json.length > 0 && (
                                <React.Fragment>
                                    {object.address_json.map((val, i) => (
                                        <MainHashtag
                                            key={`cities-${i}`}
                                            edit={edit}
                                            label={val.cityName || val.description}
                                            searchTab={searchTabValue.profile}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className='pflmb-action'>
                        <ProfileActionSheetWeb
                            edit={edit}
                            object={object}
                            refreshData={onGetDetailData}
                            setActivated={setActivated}
                            setShowActionPopover={setShowActionPopover}
                            showActionPopover={showActionPopover}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})
