// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
} from 'components'

// data
import {
    api_url_product_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ProductActionSheetWeb,
} from 'pages'

// serializers
import {
    ProductListStockSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type ProductListStockBlockOldProps = {
    edit: boolean
    isInProfileSlug?: string
    modalKey: number
    object: ProductListStockSerializer
}

// main
export const ProductListStockBlockOld: React.FC<ProductListStockBlockOldProps> = React.memo(({
    edit,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [detailObject, setDetailObject] = useState<ProductListStockSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_product_detail}${detailObject.id}/?block_size=stock`, reduxAuth),
                component: 'ProductListStockBlockOld',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProductListStockBlockOld',
                'onGetDetailData',
            ))
        }
    }

    return (
        <React.Fragment>
            <tr className='main-list-row ProductListStockBlockOld'>
                <td>
                    <IconBlock
                        edit={edit}
                        iconClass='mo-new-icon-eye-solid'
                        onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                        to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                    />
                    <ProductActionSheetWeb
                        edit={edit}
                        isInProfileSlug={isInProfileSlug}
                        object={detailObject}
                        refreshData={onGetDetailData}
                        setActivated={() => undefined}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </td>
                <td className='tar'>{detailObject.id}</td>
                <td><i className={`mlr-icon ${detailObject.active ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
                <td>
                    <ImageHelper
                        alt={detailObject.name}
                        className='mlr-image'
                        dominant_color={detailObject.get_dominant_color}
                        lightBox
                        lightBoxSrc={detailObject.get_image_hq}
                        src={detailObject.get_image_lq}
                    />
                </td>
                <td>{detailObject.name}</td>
                {detailObject.variant_list?.length! > 0
                    ? (
                        <td colSpan={5} />
                    ) : (
                        <React.Fragment>
                            <td className='tar'>
                                {detailObject.price_on_request
                                    ? reduxText[4869]
                                    : (
                                        <React.Fragment>
                                            <p>{detailObject.unit_price} {detailObject.currency?.symbol || detailObject.currency?.iso_code}</p>
                                            {detailObject.unit_price_conversions?.map(val => (
                                                <p key={val.id}>{Number(val.value).toFixed(val.currency?.is_zero_decimal ? 0 : 2)} {val.currency?.symbol || val.currency?.iso_code}</p>
                                            ))}
                                        </React.Fragment>
                                    )
                                }
                            </td>
                            <td className='tar'>{detailObject.packaging_weight !== null ? `${detailObject.packaging_weight} kg` : ''}</td>
                            <td>{detailObject.stock_status?.name}</td>
                            {!detailObject.has_stock_management
                                ? (
                                    <td
                                        className='tac'
                                        colSpan={2}
                                    >
                                        {reduxText[8703]}
                                    </td>
                                ) : (
                                    <React.Fragment>
                                        <td className='tar'>{detailObject.stock}</td>
                                        <td>
                                            {detailObject.is_unique && <p>{reduxText[8031]}</p>}
                                            {detailObject.accept_backorders && <p>{reduxText[8037]}</p>}
                                        </td>
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                    )
                }
            </tr>
            {detailObject.variant_list?.map((val => (
                <tr
                    key={val.id}
                    className='main-list-row small'
                >
                    <td />
                    <td className='tar'>{val.id}</td>
                    <td><i className={`mlr-icon ${val.active ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
                    <td />
                    <td>{val.name}</td>
                    <td className='tar'>
                        {val.price_on_request
                            ? reduxText[4869]
                            : (
                                <React.Fragment>
                                    <p>{val.unit_price} {detailObject.currency?.symbol || detailObject.currency?.iso_code}</p>
                                    {val.unit_price_conversions?.map(val2 => (
                                        <p key={val2.id}>{Number(val2.value).toFixed(val2.currency?.is_zero_decimal ? 0 : 2)} {val2.currency?.symbol || val2.currency?.iso_code}</p>
                                    ))}
                                </React.Fragment>
                            )
                        }
                    </td>
                    <td className='tar'>{val.packaging_weight !== null ? `${val.packaging_weight} kg` : ''}</td>
                    <td>{val.stock_status?.name}</td>
                    {!val.has_stock_management
                        ? (
                            <td
                                className='tac'
                                colSpan={2}
                            >
                                {reduxText[8703]}
                            </td>
                        ) : (
                            <React.Fragment>
                                <td className='tar'>{val.stock}</td>
                                <td>
                                    {val.is_unique && <p>{reduxText[8031]}</p>}
                                    {val.accept_backorders && <p className='pre-line'>{reduxText[8037]}</p>}
                                </td>
                            </React.Fragment>
                        )
                    }
                </tr>
            )))}
        </React.Fragment>
    )
})

// UnitPriceOpenProductInput
