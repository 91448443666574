// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProfileDetailFormSerializer,
    ProfileDetailFormSerializerError,
} from 'serializers/web'

// props
type BackgroundImageProfileInputProps = {
    errors: ProfileDetailFormSerializerError | undefined
    fields: ProfileDetailFormSerializer
    onChange: any
}

// main
export const BackgroundImageProfileInput: React.FC<BackgroundImageProfileInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <ImageCropInput
            altButton
            altValue={fields.image_alt}
            doka_output_data={fields.background_image_doka_output_data || ''}
            error={errors?.background_image}
            errorAlt={errors?.image_alt}
            label={reduxText[6422]}
            name='background_image'
            onChange={onChange}
            original={fields.background_image_original || ''}
            value={fields.background_image || ''}
        />
    )
})
