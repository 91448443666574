// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxSalesActivitySite,
} from 'data'

// serializers
import {
    CourseHelper819SiteSerializer,
    ProductVariant819HelperSiteSerializer,
    Template819SiteSerializer,
} from 'serializers/site'

// props
type VariantHelper819SiteProps = {
    activity: Template819SiteSerializer
    course: CourseHelper819SiteSerializer
    object: ProductVariant819HelperSiteSerializer
    setTotalAmount: React.Dispatch<React.SetStateAction<number>>
    setTotalQty: React.Dispatch<React.SetStateAction<number>>
    totalAmount: number
    totalQty: number
}

// main
export const VariantHelper819Site: React.FC<VariantHelper819SiteProps> = React.memo(({
    activity,
    course,
    object,
    setTotalAmount,
    setTotalQty,
    totalAmount,
    totalQty,
}) => {

    const dispatch = useDispatch()
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxSalesSite = useSelector((state: defaultReduxState) => state.reduxSalesSite)

    const activityCourseVariantId = `${activity.id}-${course.id}-${object.id}`

    const deviceType = reduxModalSite.deviceType
    const qty = reduxSalesSite.activityData?.registrations?.[activityCourseVariantId]?.qty || 0

    function onSetQty(value: number) {
        try {
            setTotalQty(totalQty + (value - qty))
            setTotalAmount(totalAmount + ((value - qty) * Number(object.unit_price)))
            const toReturnData = {
                ...reduxSalesSite.activityData,
                registrations: {
                    ...reduxSalesSite.activityData?.registrations,
                    [activityCourseVariantId]: {
                        course,
                        activityCourseVariantId,
                        qty: value,
                        variant: object,
                    },
                },
            }
            dispatch(reduxSalesActivitySite(toReturnData))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'ActivityVariantHelper819Site',
                'onSetQty',
            ))
        }
    }

    let formattedTime = ''
    if (course.schedule_time_start_1) {
        const temppp = course.schedule_time_start_1?.split(':').slice(0, 2)
        formattedTime = temppp[0] + 'h' + temppp[1]
    }

    return (
        <div className={`variant-helper-819 ${deviceType}`}>
            <div className={`vh-819-name ${deviceType}`}>
                {course.activity === 16
                    ? <span>{course.name} <span className='vh-819-name-decription'>(le {course.schedule_day_1?.name} � {formattedTime}, {course.description})</span></span>
                    : <span>{course.name} <span className='vh-819-name-decription'>({object.name || object.custom_foreign_key_1?.name})</span></span>
                }
            </div>
            <div className={`vh-819-variant-price-wrap ${deviceType}`}>
                <div className={`vh-819-variant-price ${deviceType}`}>
                    <span>{object.unit_price} {reduxCacheSite.portfolio?.currency?.symbol}</span>
                </div>
                <div className={`vh-819-input-wrap ${deviceType}`}>
                    {!course.is_full && (
                        <input
                            min={0}
                            disabled={course.is_full}
                            name='qty'
                            onChange={(e) => onSetQty(Number(e.target.value) >= 0 ? Number(e.target.value) : 0)}
                            type='number'
                            value={qty.toString() || ''}
                        />
                    )}
                    {course.is_full && <span className='vh-819-name-full'>Complet</span>}
                </div>
            </div>
        </div>
    )
})
