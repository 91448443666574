// react components
import React from 'react'
import {
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    isMobileOnly,
} from 'react-device-detect'

// components
import {
    Alert,
    HeaderWrapper,
    ImageHelper,
} from 'components'

// data
import {
    MainReduxContainer,
    media_url,
    media_version,
    reduxAuthState,
    reduxModalState,
    view_url_home,
} from 'data'

// services
import {
    axiosErrorHandlerErrorBoundary,
} from 'services'

// props
type ErrorBoundaryProps = {
    children: React.ReactNode
    reduxAuth: reduxAuthState
    reduxModal: reduxModalState
}

type ErrorBoundaryState = {
    errorInfo: any
}

// main
class ErrorBoundaryToConnect extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    componentDidCatch(error: any, errorInfo: any) {
        if (process.env.NODE_ENV === 'development') console.log(error, errorInfo)
        // Catch errors in any components below and re-render with error message
        this.setState({
            errorInfo: errorInfo,
        })
        // You can also log error messages to an error reporting service here
        axiosErrorHandlerErrorBoundary(
            `${error.toString()} - ${errorInfo.componentStack}`,
            this.props.reduxAuth,
            'ErrorBoundaryToConnect',
            '',
        )
    }

    render() {
        if (this.state && this.state.errorInfo) {
            if (isMobileOnly) {
                return (
                    <IonPage
                        id='errorBoundary'
                        className='mo-error-boundary'
                    >
                        <IonHeader
                            className='mo-header'
                            translucent
                        >
                            <IonToolbar>
                                <IonTitle>
                                    <ImageHelper
                                        alt=''
                                        className='logo'
                                        dominant_color={undefined}
                                        src={`${media_url}global/logo-${this.props.reduxAuth.settings?.theme === 'light' ? 'black' : 'white'}.svg${media_version}`}
                                    />
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <a href={view_url_home} className='eb-go-home'>Go back to home page</a>
                        <Alert
                            buttons={[{ text: 'OK' }]} // TO_LATER_TEXT
                            header='Oops! Something went wrong' // TO_LATER_TEXT
                            isErrorAlert
                            isOpen
                            onDidDismiss={() => console.log('closing ErrorBoundary')}
                        />
                    </IonPage>
                )
            }
            return (
                <IonPage className='mo-error-boundary'>
                    <HeaderWrapper />
                    <a href={view_url_home} className='eb-go-home'>Go back to home page</a>
                    <Alert
                        buttons={[{ text: 'OK' }]}
                        header='Oops! Something went wrong'
                        isErrorAlert
                        isOpen
                        onDidDismiss={() => process.env.NODE_ENV === 'development' && console.log('closing ErrorBoundary')}
                    />
                </IonPage>
            )
        }
        return (this.props.children)
    }
}

export const ErrorBoundary = MainReduxContainer(ErrorBoundaryToConnect)
