// react components
import {
    combineReducers,
} from 'redux'
import {
    connect,
} from 'react-redux'

// data
import {
    reduxAuthState,
    reduxCacheStateSite,
    reduxFormStateSite,
    reduxFormState,
    reduxModalStateSite,
    reduxModalState,
    reduxPlayerStateSite,
    reduxSalesStateSite,
    reduxTextState,
} from 'data'

// reducers
import reduxAuth from './redux/auth/mo_reducer'
import reduxForm from './redux/form/mo_reducer'
import reduxModal from './redux/modal/mo_reducer'
import reduxText from './redux/text/mo_reducer'

import reduxCacheSite from '../site/redux/cache/mo_reducer'
import reduxFormSite from '../site/redux/form/mo_reducer'
import reduxModalSite from '../site/redux/modal/mo_reducer'
import reduxPlayerSite from '../site/redux/player/mo_reducer'
import reduxSalesSite from '../site/redux/sales/mo_reducer'

const rootReducerWeb = combineReducers({
    reduxAuth: reduxAuth,
    reduxForm: reduxForm,
    reduxModal: reduxModal,
    reduxText: reduxText,

    reduxCacheSite: reduxCacheSite,
    reduxFormSite: reduxFormSite,
    reduxModalSite: reduxModalSite,
    reduxPlayerSite: reduxPlayerSite,
    reduxSalesSite: reduxSalesSite,
})

export default rootReducerWeb

export type defaultReduxState = {
    reduxAuth: reduxAuthState
    reduxForm: reduxFormState
    reduxModal: reduxModalState
    reduxText: reduxTextState

    reduxCacheSite: reduxCacheStateSite
    reduxFormSite: reduxFormStateSite
    reduxModalSite: reduxModalStateSite
    reduxPlayerSite: reduxPlayerStateSite
    reduxSalesSite: reduxSalesStateSite
}

// containers
function mapStateToProps(state: any) {
    return {
        reduxAuth: state.reduxAuth,
        reduxForm: state.reduxForm,
        reduxModal: state.reduxModal,
        reduxText: state.reduxText.data,

        reduxCacheSite: state.reduxCacheSite,
        reduxFormSite: state.reduxFormSite,
        reduxModalSite: state.reduxModalSite,
        reduxSalesSite: state.reduxSalesSite,
    }
}

export const MainReduxContainer = connect(mapStateToProps)
