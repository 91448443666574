// npm components
import React from 'react'


// pages
import {
    ColorInputPortfolio as ColorInput,
    DecimalInputPortfolio as DecimalInput,
    DurationInputPortfolio as DurationInput,
    NumberInputPortfolio as NumberInput,
    PercentageInputPortfolio as PercentageInput,
    PixelInputPortfolio as PixelInput,
    QuadrupleInputPortfolio as QuadrupleInput,
    SelectInputPortfolio as SelectInput,
    StyleDefaultInputPortfolio as DefaultInput,
    TextAreaInputPortfolio as TextAreaInput,
} from 'pages'

// serializers
import {
    CSSPropertySerializer,
} from 'serializers/web'

// props
type StyleInputHelperProps = {
    css_property: CSSPropertySerializer
    mixedMedia?: boolean
    onChange: any
    presetColors: string[] | undefined
    value: string
    value_LG?: string
    value_MD?: string
    value_type: 'LG' | 'MD' | 'SM'
}

// main
export const StyleInputHelper: React.FC<StyleInputHelperProps> = React.memo(({
    css_property,
    mixedMedia,
    onChange,
    presetColors,
    value,
    value_LG,
    value_MD,
    value_type,
}) => {

    const components: any = {
        ColorInput,
        DecimalInput,
        DefaultInput,
        DurationInput,
        NumberInput,
        PercentageInput,
        PixelInput,
        QuadrupleInput,
        SelectInput,
        TextAreaInput,
    }

    const FormComponentName = (components[css_property.input_component]) || DefaultInput

    return (
        <FormComponentName
            canHideInput
            helperText={css_property.description}
            label={css_property.name}
            mixedMedia={mixedMedia}
            name={css_property.css_class}
            onChange={onChange}
            options={css_property.options}
            presetColors={presetColors}
            value={value}
            value_LG={value_LG}
            value_MD={value_MD}
            value_type={value_type}
        />
    )
})
