// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    CustomFilterInputModalPortfolio,
} from 'pages'

// props
type CustomFilterInputPortfolioProps = {
    adminOnly?: boolean
    contentSourceId: number
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label: string
    name: string
    noMarginBottom?: boolean
    onChange: any
    optional?: boolean
    profileId: number | undefined
    value: string | undefined
}

// main
export const CustomFilterInputPortfolio: React.FC<CustomFilterInputPortfolioProps> = React.memo(({
    adminOnly,
    contentSourceId,
    disabled,
    error,
    helperText,
    label,
    name,
    noMarginBottom,
    onChange,
    optional,
    profileId,
    value,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showModal, setShowModal] = useState(false)

    return (
        <div className={`mo-select-input-web${noMarginBottom ? ' no-mb' : ''}`}>
            <MainFormLabel
                adminOnly={adminOnly}
                helperText={helperText}
                label={label}
                name={name}
                optional={optional}
            />
            <div
                className={`mo-text-input-web-input${disabled ? ' disabled' : ''}`}
                onClick={() => setShowModal(true)}
            >
                {value ? reduxText[6394] : reduxText[5445]}
            </div>
            {showModal && (
                <CustomFilterInputModalPortfolio
                    contentSourceId={contentSourceId}
                    initialValue={value}
                    label={label}
                    name={name}
                    onChange={onChange}
                    profileId={profileId}
                    setIsOpen={setShowModal}
                />
            )}
            <ErrorHelper error={error} />
        </div>
    )
})
