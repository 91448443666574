// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// components
import {
    Copy,
} from 'components'

// main
export const AupouCurrentLocationHelper: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    if (reduxAuth.settings?.user?.id !== 1) return null

    return (
        <div className='aupou-current-location-helper'>
            <Copy
                isIcon
                text={`https://app.justtheweb.io${window.location.pathname}${window.location.search}`}
            />
            <a
                className='aclh-link'
                href={`https://app.justtheweb.io${window.location.pathname}${window.location.search}`}
                rel='noopener noreferrer'
                target='_blank'
            >
                Prod
            </a>
            <Copy
                isIcon
                text={`http://localhost:8100${window.location.pathname}${window.location.search}`}
            />
            <a
                className='aclh-link'
                href={`http://localhost:8100${window.location.pathname}${window.location.search}`}
                rel='noopener noreferrer'
                target='_blank'
            >
                Dev
            </a>
        </div>
    )
}
