// Root URL
const rootPath = 'builder/'

// Action
export const api_url_block_template_action = `${rootPath}action/block_template/`
export const api_url_page_template_action = `${rootPath}action/page_template/`

// Detail
export const api_url_block_template_detail = `${rootPath}detail/block_template/`
export const api_url_page_template_detail = `${rootPath}detail/page_template/`

// Form Block Template
export const api_url_block_template_form_active = `${rootPath}form/block_template/active/`
export const api_url_block_template_form_create = `${rootPath}form/block_template/create/`
export const api_url_block_template_form_detail = `${rootPath}form/block_template/detail/`
export const api_url_block_template_form_duplicate = `${rootPath}form/block_template/duplicate/`
export const api_url_block_template_form_update = `${rootPath}form/block_template/update/`
export const api_url_page_template_form_active = `${rootPath}form/page_template/active/`
export const api_url_page_template_form_create = `${rootPath}form/page_template/create/`
export const api_url_page_template_form_detail = `${rootPath}form/page_template/detail/`
export const api_url_page_template_form_duplicate = `${rootPath}form/page_template/duplicate/`
export const api_url_page_template_form_update = `${rootPath}form/page_template/update/`

// Form Copy
export const api_url_copy_content = `${rootPath}form/copy/content/`
export const api_url_copy_mixed_content_to_mixed_page = `${rootPath}form/copy/mixed_content_to_mixed_page/`
export const api_url_copy_mixed_content_to_portfolio_page = `${rootPath}form/copy/mixed_content_to_portfolio_page/`
export const api_url_copy_mixed_page_to_mixed_page = `${rootPath}form/copy/mixed_page_to_mixed_page/`
export const api_url_copy_mixed_page_to_portfolio_page = `${rootPath}form/copy/mixed_page_to_portfolio_page/`
export const api_url_copy_portfolio_content_to_mixed_page = `${rootPath}form/copy/portfolio_content_to_mixed_page/`
export const api_url_copy_portfolio_content_to_portfolio_page = `${rootPath}form/copy/portfolio_content_to_portfolio_page/`
export const api_url_copy_portfolio_page_to_mixed_page = `${rootPath}form/copy/portfolio_page_to_mixed_page/`
export const api_url_copy_portfolio_page_to_portfolio_page = `${rootPath}form/copy/portfolio_page_to_portfolio_page/`
export const api_url_copy_style = `${rootPath}form/copy/style/`
export const api_url_copy_style_undo = `${rootPath}form/copy/style/undo/`
export const api_url_move_content = `${rootPath}form/move/content/`
export const api_url_move_content_undo = `${rootPath}form/move/content/undo/`

// Helper
export const api_url_new_block_template_helper = `${rootPath}helper/block_template/new_block_template/`
