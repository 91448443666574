// react components
import React from 'react'
import {
    Link,
} from 'react-router-dom'

// props
type ButtonProps = {
    className?: string
    classNameWrap?: string
    color?: 'danger' | 'grey' | 'white'
    disabled?: boolean
    edit: boolean
    expand?: 'full'
    fill?: 'clear' | 'outline' | 'solid' | 'default'
    href?: string
    justifyText?: 'center' | 'flex-end' | 'flex-start'
    onClick?: (e: any) => void
    padding?: string
    size?: 'small' | 'x-small'
    text: string
    to?: string
}

// main
export const Button: React.FC<ButtonProps> = React.memo(({
    className,
    classNameWrap,
    color,
    disabled,
    edit,
    expand,
    fill,
    href,
    justifyText,
    onClick,
    padding,
    size,
    text,
    to,
}) => {

    const buttonClass = `button-web ${size || 'regular'} ${color || 'color'} ${fill || 'default'}${expand ? ` ${expand}` : ''}${className ? ` ${className}` : ''}${(disabled || edit) ? ' disabled' : ''}`
    const buttonWrapClass = `button-wrap-web${expand ? ` ${expand}` : ''}${classNameWrap ? ` ${classNameWrap}` : ''}`

    if (to && !edit) {
        if (padding) {
            return (
                <div
                    className={buttonWrapClass}
                    style={{
                        padding: padding,
                    }}
                >
                    <Link
                        className={buttonClass}
                        onClick={edit ? undefined : onClick}
                        style={{
                            justifyContent: justifyText,
                        }}
                        to={to}
                    >
                        {text}
                    </Link>
                </div>
            )
        }
        return (
            <Link
                className={buttonClass}
                onClick={edit ? undefined : onClick}
                style={{
                    justifyContent: justifyText,
                }}
                to={to}
            >
                {text}
            </Link>
        )
    }
    if (href && !edit) {
        return (
            <a
                className={buttonClass}
                href={href}
                onClick={edit ? undefined : onClick}
                rel='noopener noreferrer'
                style={{
                    justifyContent: justifyText,
                }}
                target='_blank'
            >
                {text}
            </a>
        )
    }
    if (padding) {
        return (
            <div
                className={buttonWrapClass}
                style={{
                    padding: padding,
                }}
            >
                <button
                    className={buttonClass}
                    disabled={edit || disabled}
                    onClick={edit ? undefined : onClick}
                    style={{
                        justifyContent: justifyText,
                    }}
                    type='button'
                >
                    {text}
                </button>
            </div>
        )
    }
    return (
        <button
            className={buttonClass}
            disabled={edit || disabled}
            onClick={edit ? undefined : onClick}
            style={{
                justifyContent: justifyText,
            }}
            type='button'
        >
            {text}
        </button>
    )
})
