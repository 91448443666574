// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'

// components
import {
    LinkHelper,
} from 'components'

// data
import {
    api_url_custom_feed_block_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_custom_block_detail,
} from 'data'

// pages
import {
    EmptyListSmallBlockWeb,
    EventListSmallBlockWeb as eventListSmallBlockWeb,
    ImageListBlockWeb as imageListSmallBlockWeb,
    MaterialListSmallBlockWeb as materialListSmallBlockWeb,
    OfferListSmallBlockWeb as offerListSmallBlockWeb,
    PressListSmallBlockWeb as pressListSmallBlockWeb,
    ProductListSmallBlockWeb as productListSmallBlockWeb,
    ProfileListSmallBlockWeb as profileListSmallBlockWeb,
    ProjectListSmallBlockWeb as artworkListSmallBlockWeb,
    ServiceListSmallBlock as serviceListSmallBlockWeb,
} from 'pages'

// services
import {
    getApiUrl,
    getInfiniteListWebNew,
    getInfiniteRandomListWebNew,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// serializers
import {
    CustomFeedBlockListSerializer,
} from 'serializers/web'

// props
type FeedBlockListBlockWebProps = {
    object: CustomFeedBlockListSerializer
}

// main
export const FeedBlockListBlockWeb: React.FC<FeedBlockListBlockWebProps> = React.memo(({
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        window.addEventListener('scroll', getScrollPosition, true)
        return function cleanupListener() {
            try {
                window.removeEventListener('scroll', getScrollPosition)
            } catch (error) {
                dispatch(reduxModalErrorEventHandler(
                    error,
                    'FeedBlockListBlockWeb',
                    'cleanupListener',
                ))
            }
        }
    }, [])

    const listApiUrl = getApiUrl(`${api_url_custom_feed_block_detail}?block_id=${object.id}`, reduxAuth)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb & { items: CustomFeedBlockListSerializer[] }>({
        ...listStateInitialWeb,
        componentString: `${object.component}ListSmallBlockWeb`,
        disableInfiniteScroll: false,
        modalKey: Math.random(),
        nextUrl: listApiUrl,
    })

    const [showNextArrow, setShowNextArrow] = useState(true)
    const [startPosition, setStartPosition] = useState<number>(0)

    const components: any = {
        artworkListSmallBlockWeb,
        eventListSmallBlockWeb,
        imageListSmallBlockWeb,
        materialListSmallBlockWeb,
        offerListSmallBlockWeb,
        pressListSmallBlockWeb,
        productListSmallBlockWeb,
        profileListSmallBlockWeb,
        serviceListSmallBlockWeb,
    }
    const ComponentName: any = components[listState.componentString]

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            if (object.random) {
                getInfiniteRandomListWebNew({
                    apiUrl: listApiUrl,
                    axiosCancelToken,
                    changingView,
                    component: 'FeedBlockListBlockWeb',
                    componentName: `${object.component}ListSmallBlockWeb`,
                    dispatch,
                    listState,
                    reduxAuth,
                    setAxiosCancelToken,
                    setListState,
                })
            } else {
                getInfiniteListWebNew({
                    apiUrl,
                    axiosCancelToken,
                    changingView,
                    component: 'FeedBlockListBlockWeb',
                    componentName: `${object.component}ListSmallBlockWeb`,
                    dispatch,
                    listState,
                    reduxAuth,
                    setAxiosCancelToken,
                    setListState,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedBlockListBlockWeb',
                'onGetListData',
            ))
        }
    }

    function onSearchEnd(isVisible: boolean) {
        try {
            setShowNextArrow(!isVisible)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedBlockListBlockWeb',
                'onSearchEnd',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                if (object.random) {
                    onGetListData('', false)
                } else {
                    onGetListData(listState.nextUrl, false)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedBlockListBlockWeb',
                'onSearchNext',
            ))
        }
    }

    function scroll(direction: -1 | 1) {
        if (document.getElementById(`fhlbw-content-${object.id}`)) {
            // @ts-ignore
            const far = document.getElementById(`fhlbw-content-${object.id}`).offsetWidth * direction
            // @ts-ignore
            const pos = document.getElementById(`fhlbw-content-${object.id}`).scrollLeft + far
            // @ts-ignore
            document.getElementById(`fhlbw-content-${object.id}`).scrollTo({ top: 0, left: pos, behavior: 'smooth' })
        }
    }

    function getScrollPosition() {
        if (document.getElementById(`fhlbw-content-${object.id}`)) {
            // @ts-ignore
            const far = document.getElementById(`fhlbw-content-${object.id}`).offsetWidth
            // @ts-ignore
            const pos = document.getElementById(`fhlbw-content-${object.id}`).scrollLeft + far
            setStartPosition(pos - far)
        }
    }

    if (!ComponentName) return null

    const hasItems = object.count_items > 0

    return (
        <div className='feed-home-list-block-web'>
            {hasItems && startPosition > 0 && (
                <div className='fhlbw-navigation-left'>
                    <div
                        className='fhlbw-icon-block'
                        onClick={() => scroll(-1)}
                    >
                        <i className='fhlbw-icon mo-new-icon-chevron-left-solid' />
                    </div>
                </div>
            )}
            <div className='fhlbw-title-wrap'>
                <p className='fhlbw-title'>{object.name}</p>
                <div>
                    <LinkHelper
                        edit={false}
                        className='fhlbw-see-all'
                        to={`${view_url_custom_block_detail}${object.id}/${object.slug}`}
                    >
                        {reduxText[7679]}
                    </LinkHelper>
                </div>
            </div>
            {hasItems && (
                <div id={`fhlbw-content-${object.id}`} className='fhlbw-content mo-hidden-horizontal-scrollbar'>
                    {listState.items.map((val: any) => (
                        <ComponentName
                            key={val.id}
                            edit={false}
                            modalKey={listState.modalKey}
                            object={val}
                            playerOrigin='feedBlockListBlock'
                        />
                    ))}
                    {listState.hasMore && (
                        <VisibilitySensor
                            intervalDelay={1000}
                            offset={{ right: -500 }}
                            onChange={onSearchNext}
                            partialVisibility
                        >
                            <React.Fragment>
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                                <EmptyListSmallBlockWeb />
                            </React.Fragment>
                        </VisibilitySensor>
                    )}
                    {!listState.hasMore && listState.items.length > 0 && (
                        <VisibilitySensor
                            intervalDelay={1000}
                            onChange={onSearchEnd}
                            partialVisibility
                        >
                            <div className='fhlbw-empty-end' />
                        </VisibilitySensor>
                    )}
                </div>
            )}
            {hasItems && showNextArrow && (
                <div className='fhlbw-navigation-right'>
                    <div
                        className='fhlbw-icon-block'
                        onClick={() => scroll(1)}
                    >
                        <i className='fhlbw-icon mo-new-icon-chevron-right-solid' />
                    </div>
                </div>
            )}
        </div>
    )
})
