// react components
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'

// data
import {
    reduxAuthState,
} from 'data'

// main
export function getDateWithType(
    reduxAuth: reduxAuthState,
    date: string | undefined,
    date_type_choice?: 'd' | 'dd' | 'dt' | 't' | 'm' | 'n' | 'y' | 'tt',
) {

    if (date_type_choice === 'tt' && date) {
        return moment(date, "HH:mm:ss").format('HH:mm')
    }

    moment.locale('en') // default the locale to English
    const localDate = moment(date)

    if (reduxAuth.settings?.language) {
        if (reduxAuth.settings.language === 'fr') localDate.locale('fr') // set this instance to use French
    }

    if (date_type_choice === 'y') {
        return `${localDate.format('YYYY')}`
    }
    if (date_type_choice === 'd') {
        return `${localDate.format('ddd D MMM YYYY')}`
    }
    if (date_type_choice === 'dd') {
        return `${localDate.format('MMM D, YYYY')}`
    }
    if (date_type_choice === 'dt') {
        return `${localDate.format('YYYY-MM-DD HH:mm')}`
    }
    if (date_type_choice === 't') {
        return `${localDate.format('llll')}`
    }
    if (date_type_choice === 'n') {
        return `${localDate.fromNow()}`
    }
    return `${localDate.format('MMMM YYYY')}`
}
