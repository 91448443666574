// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
} from 'serializers/web'

// props
type ProjectTypeProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    onChange: any
}

// main
export const ProjectTypeProjectInput: React.FC<ProjectTypeProjectInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const projectTypeOptions = [
        { id: '1', name: reduxText[3528] },
        { id: '2', name: reduxText[4812] },
        { id: '3', name: reduxText[8187] },
    ]

    return (
        <SelectListInput
            clearable={false}
            error={errors?.project_type}
            label={reduxText[8455]}
            name='project_type'
            onChange={onChange}
            options={projectTypeOptions}
            value={fields.project_type}
        />
    )
})
