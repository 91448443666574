// react components
import React from 'react'
import {
    NavLink,
} from 'react-router-dom'

// data
import {
    contentTypeModelType,
    view_url_admin,
} from 'data'

// serializers
import {
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// props
type DataMenuItem2Props = {
    content_type_label_model: contentTypeModelType
    profileObject: ProfileDetailAdminSerializerV2
}

// main
export const DataMenuItem2: React.FC<DataMenuItem2Props> = React.memo(({
    content_type_label_model,
    profileObject,
}) => {
    return (
        <div className='admin-menu-item'>
            <div className='ami-tab-item-wrap'>
                <NavLink
                    className='ami-tab-item'
                    to={`${view_url_admin}${profileObject.id}/data/${content_type_label_model}/`}
                >
                    {content_type_label_model.split('_')[0]} | {content_type_label_model.split('_')[1]}
                </NavLink>
                <i
                    className='ami-expand mo-new-icon-chevron-down-solid hidden'
                />
            </div>
        </div>
    )
})
