// react components
import React, {
    useEffect,
    useState,
} from 'react'

// props
type BackgroundImageHelperProps = {
    children?: React.ReactNode
    className?: string
    dominant_color: string | undefined
    src: string | undefined
}

// main
export const BackgroundImageHelper: React.FC<BackgroundImageHelperProps> = React.memo(({
    children,
    className,
    dominant_color,
    src,
}) => {

    const [backgroundImageLoaded, setBackgroundImageLoaded] = useState<any>(null)

    useEffect(() => {
        if (src) {
            const img = new Image()
            img.src = src
            img.onload = () => setBackgroundImageLoaded(src)
        }
    }, [src])

    return (
        <div
            className={className}
            style={{
                backgroundColor: backgroundImageLoaded ? undefined : `rgb${dominant_color}`,
                backgroundImage: backgroundImageLoaded ? `url('${backgroundImageLoaded}')` : undefined,
            }}
        >
            {children}
        </div>
    )
})
