// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CountViewBlock,
    HeaderDetailBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    MixedContentBlock,
} from 'pages'

// serializers
import {
    BlockTemplateDetailSerializer,
} from 'serializers/web'

// props
type BlockTemplateDetailBlockProps = {
    edit: boolean
    languageId: number | undefined
    object: BlockTemplateDetailSerializer
}

// main
export const BlockTemplateDetailBlock: React.FC<BlockTemplateDetailBlockProps> = React.memo(({
    edit,
    languageId,
    object,
}) => {

    const reduxAuthisWindows = useSelector((state: defaultReduxState) => state.reduxAuth.isWindows)

    return (
        <div className={`block-template-detail-block-web${edit ? ' edit' : ''}`}>
            <div className='btdbw-left'>
                <MixedContentBlock
                    contentType='blocktemplate'
                    edit={edit}
                    languageId={languageId}
                    // @ts-ignore
                    object={object}
                />
            </div>
            <div className={`btdbw-right mo-hidden-vertical-scrollbar${reduxAuthisWindows}`}>
                <HeaderDetailBlock
                    contentType='blocktemplate'
                    edit={edit}
                    languageId={undefined}
                    object={object}
                    setLanguageId={undefined}
                />
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
