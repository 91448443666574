// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    PortfolioUserGroupsFieldInput,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ActionInputPageContent,
    AuthTypeInputPageContent,
    ContentSourceExtraInputPageContent,
    ContentSourceInputPageContent,
    DataJsonInputPageContent,
    PageLinkInputPageContent,
    TextShort2InputPageContent,
} from 'pages'

// serializers
import {
    ProjectFormState,
} from 'serializers/web'

// props
type MixedPanel780Props = {
    detailUrl: string
    formState: ProjectFormState
    handleInputChange: any
    mixedMediaContentType?: contentTypeModelType
    updateUrl: string
}

// main
export const MixedPanel780: React.FC<MixedPanel780Props> = React.memo(({
    detailUrl,
    formState,
    handleInputChange,
    mixedMediaContentType,
    updateUrl,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const mixedMediaContent = formState.mixedMediaContent

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MixedPanel780',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    // Content
    const errorsInline = mixedMediaContent.errors
    const fieldsInline = mixedMediaContent.fields

    if (!fieldsMother || !fieldsInline || !fieldsInline.layout) return null

    return (
        <React.Fragment>
            <ContentSourceInputPageContent
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                onHandleInputChange={onHandleInputChange}
            />
            <ContentSourceExtraInputPageContent
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                mixedMedia
                mixedMediaContentType={mixedMediaContentType}
                onHandleInputChange={onHandleInputChange}
                optional={false}
            />
            <ActionInputPageContent
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                onHandleInputChange={onHandleInputChange}
                optional={false}
                options={fieldsInline.content_source?.id === 20 ? 'actionOptionsCustom' : 'actionOptionsDetail'}
            />
            {fieldsInline.action?.id === 'href' && (
                <TextShort2InputPageContent
                    detailUrl={mixedMediaContent.detailUrl}
                    errorsInline={errorsInline}
                    fieldsInline={fieldsInline}
                    fieldsMother={fieldsMother}
                    onHandleInputChange={onHandleInputChange}
                    optional={false}
                    updateUrl={mixedMediaContent.updateUrl}
                    inputObject={{
                        id: 0,
                        label: reduxText[8450],
                        field: 'text_short2',
                    }}
                />
            )}
            {['filter_param', 'page_link'].includes(fieldsInline.action?.id!) && (
                <PageLinkInputPageContent
                    errorsInline={errorsInline}
                    fieldsInline={fieldsInline}
                    fieldsMother={fieldsMother}
                    mixedMedia
                    onHandleInputChange={onHandleInputChange}
                    optional={false}
                />
            )}
            <DataJsonInputPageContent
                detailUrl={detailUrl}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                mixedMedia
                onHandleInputChange={onHandleInputChange}
                updateUrl={updateUrl}
            />
            <AuthTypeInputPageContent
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                onHandleInputChange={onHandleInputChange}
                optional={false}
            />
            <PortfolioUserGroupsFieldInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
                profileId={fieldsMother.profile?.id}
                show={fieldsInline.auth_type?.id === 'co'}
            />
        </React.Fragment>
    )
})
