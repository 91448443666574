// npm components
import React, {
    useState,
} from 'react'
import {
    IonToggle,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    Button,
    SelectAsyncInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_profile,
} from 'data'

// pages
import {
    PortfolioCreateModal,
} from 'pages'

// serializers
import {
    MainIdNameSerializer,
    PortfolioDetailSerializer,
} from 'serializers/web'

// props
type PortfolioDetailBlockWebProps = {
    object: PortfolioDetailSerializer
}

// main
export const PortfolioDetailBlockWeb: React.FC<PortfolioDetailBlockWebProps> = React.memo(({
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModaldeviceType = useSelector((state: defaultReduxState) => state.reduxModal.deviceType)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [ownContent, setOwnContent] = useState(false)
    const [profile, setProfile] = useState<MainIdNameSerializer | undefined>(undefined)
    const [showNameModal, setShowNameModal] = useState(false)
    const [isContactAlertOpen, setIsContactAlertOpen] = useState(false)

    function handleInputChange(event: any) {
        setOwnContent(false)
        setProfile(event.value)
    }

    function handleStart() {
        if (reduxAuth.settings?.user?.is_staff) {
            setShowNameModal(true)
        } else {
            setIsContactAlertOpen(true)
        }
    }

    function onHandleOwnContent(e: boolean) {
        setOwnContent(e)
        if (e) {
            setProfile(reduxAuth.settings?.active_profile)
        } else {
            setProfile(undefined)
        }
    }

    return (
        <div className={`portfolio-detail-block-web ${(object.is_template || reduxAuth.settings?.user?.is_staff) ? 'template' : ''}`}>
            {(object.is_template || reduxAuth.settings?.user?.is_staff) && (
                <div className='pdbw-header'>
                    {/* <div className='pfcw-device'>
					<i
						className={`icon mo-new-icon-desktop-solid ${reduxModalSite.deviceType === 'is-web' && 'active'}`}
						onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-web'))}
					/>
					<i
						className={`icon mo-new-icon-tablet-alt-solid ${reduxModalSite.deviceType === 'is-tablet' && 'active'}`}
						onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-tablet'))}
					/>
					<i
						className={`icon mo-new-icon-mobile-alt-solid ${reduxModalSite.deviceType === 'is-mobile' && 'active'}`}
						onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-mobile'))}
					/>
				</div> */}
                    {reduxAuth.settings?.user?.is_staff && (
                        <div className='pdbw-select'>
                            <SelectAsyncInput
                                apiUrl={select_url_profile}
                                clearable={false}
                                error={undefined}
                                layout='profile'
                                name='profile'
                                onChange={(e: any) => handleInputChange(e)}
                                staffOnly
                                value=''
                            />
                        </div>
                    )}
                    <div className='pdbw-import'>
                        <IonToggle
                            checked={ownContent}
                            color='secondary'
                            onIonChange={(e) => onHandleOwnContent(e.detail.checked)}
                        />
                        <p className='pdbw-import-text'>{reduxText[5388]}</p>
                    </div>
                    <div className='pdbw-button-wrap'>
                        <Button
                            edit={false}
                            size='small'
                            text={reduxText[5390]}
                            onClick={() => handleStart()}
                        />
                    </div>
                </div>
            )}
            <div className='pdbw-content'>
                <p className='pdbw-loading'>{reduxText[3470]}</p>
                <iframe
                    src={profile?.id ? `https://demo.easyclapweb.com/${object.id}-${profile?.id || 'None'}-web/` : `https://${object.hostname}?deviceType=${reduxModaldeviceType || 'is-web'}`}
                    title={object.name}
                />
            </div>
            {(object.is_template || reduxAuth.settings?.user?.is_staff) && showNameModal && (
                <PortfolioCreateModal
                    object={object}
                    profileObject={profile}
                    setIsOpen={setShowNameModal}
                />
            )}
            {isContactAlertOpen && (
                <Alert
                    buttons={[
                        {
                            text: reduxText[4519],
                        },
                        {
                            text: reduxText[5614],
                        },
                    ]}
                    header={reduxText[5784]}
                    isOpen
                    message={reduxText[8437]}
                    onDidDismiss={() => setIsContactAlertOpen(false)}
                />
            )}
        </div>
    )
})
