// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// containers
import {
    DetailPageContainer,
} from 'containers'

// data
import {
    api_url_event_image_detail,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export const EventImageDetailWeb: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    return (
        <DetailPageContainer
            apiUrl={getApiUrl(api_url_event_image_detail, reduxAuth)}
            app='event'  // TO_CHECK
            component='EventImageDetailBlockWeb'
            componentActionSheet='EventActionSheetWeb'  // TO_CHECK
            model='event'  // TO_CHECK
        />
    )
}
