// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProductDetailFormSerializer,
    ProductDetailFormSerializerError,
} from 'serializers/web'

// props
type StockStatusProductInputProps = {
    errors: ProductDetailFormSerializerError | undefined
    fields: ProductDetailFormSerializer
    onChange: any
}

// main
export const StockStatusProductInput: React.FC<StockStatusProductInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const optionsIsUnique = [
        { id: 1, name: reduxText[8024] },  // Available
        { id: 2, name: reduxText[8025] },  // Sold
        { id: 5, name: reduxText[8026] },  // Not available
    ]
    const optionsIsNotUnique = [
        { id: 1, name: reduxText[8024] },  // Available
        { id: 3, name: reduxText[8027] },  // Sold out
        { id: 4, name: reduxText[4506] },  // Out of stock
        { id: 5, name: reduxText[8026] },  // Not available
    ]

    return (
        <SelectListInput
            clearable={false}
            disabled={fields.has_stock_management}
            error={errors?.stock_status}
            helperText={reduxText[802910]}
            label={reduxText[8029]}
            name='stock_status'
            onChange={onChange}
            options={fields.is_unique ? optionsIsUnique : optionsIsNotUnique}
            value={fields.stock_status || ''}
        />
    )
})
