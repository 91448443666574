// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProductDetailFormSerializer,
    ProductDetailFormSerializerError,
    ProductFormState,
} from 'serializers/web'

// props
type UnitPriceOpenProductInputProps = {
    errors: ProductDetailFormSerializerError | undefined
    fields: ProductDetailFormSerializer
    mother: ProductFormState['mother']
    onChange: any
}

// main
export const UnitPriceOpenProductInput: React.FC<UnitPriceOpenProductInputProps> = React.memo(({
    errors,
    fields,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            conversion={{
                contentType: 'product',
                defaultCurrency: fields.currency,
                defaultDetailUrl: mother.detailUrl,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            conversionDefaultValue={fields.unit_price}
            currencies={fields.currencies}
            disabled={fields.price_on_request}
            error={errors?.unit_price_open}
            helperText={reduxText[788710]}
            label={reduxText[7887]}
            name='unit_price_open'
            onChange={onChange}
            optional
            type='number'
            value={fields.unit_price_open || ''}
        />
    )
})
