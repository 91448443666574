// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    FileInput,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type FileInputPageContentProps = {
    disabled?: boolean
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    inputObject?: LayoutFieldHelperSerializer
    mixedMedia: boolean
    onHandleInputChange: any
    optional?: boolean
}

// main
export const FileInputPageContent: React.FC<FileInputPageContentProps> = React.memo(({
    disabled,
    errorsInline,
    fieldsInline,
    inputObject,
    mixedMedia,
    onHandleInputChange,
    optional,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <FileInput
            disabled={disabled}
            error={errorsInline?.file}
            helperText={inputObject?.helper_text}
            label={inputObject?.label || reduxText[6323]}
            name='file'
            onChange={onHandleInputChange}
            value={fieldsInline.file || ''}
        />
    )
})
