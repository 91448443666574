export function parseQuery(queryString: string | undefined) {
    const query: {
        [key: string]: string
    } = {}
    const pairs = queryString?.substr(1).split('&')
    pairs?.map((val) => {
        const pair = val.split('=')
        if (pair[0]) {
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
        }
    })
    return query
}
