// react components
import React, {
    useState,
} from 'react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    CountViewBlock,
    ImageHelper,
    LightboxCustom,
    MainCountryFlag,
    MainHashtag,
    ProfileShortInfoWeb,
    RatioHelper,
} from 'components'

// data
import {
    defaultReduxState,
    searchTabValue,
} from 'data'

// pages
import {
    ProjectListMicroBlockWeb,
} from 'pages'

// serializers
import {
    ProductDetailSerializer,
    ProductDetailTeamHelperSerializer,
} from 'serializers/web'

// props
type ProductDetailPackageBlockWebProps = {
    edit: boolean
    modalKeys: {
        related_projects: number,
    }
    object: ProductDetailSerializer
}

// main
export const ProductDetailPackageBlockWeb: React.FC<ProductDetailPackageBlockWebProps> = React.memo(({
    edit,
    modalKeys,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [selectionLightBoxImage, setSelectionLightBoxImage] = useState<string>()

    const duplicateProfile: any = []
    let creators: ProductDetailTeamHelperSerializer[] = []
    let labels: ProductDetailTeamHelperSerializer[] = []
    if (object.team_list && object.team_list.creator && object.team_list.creator.length > 0) {
        creators = object.team_list.creator
        object.team_list.creator.map((val) => {
            if (val.member) duplicateProfile.push(val.member.id)
            return false
        })
    }
    if (object.team_list && object.team_list.label && object.team_list.label.length > 0) {
        labels = object.team_list.label
        object.team_list.label.map((val) => {
            if (val.member) duplicateProfile.push(val.member.id)
            return false
        })
    }

    return (
        <div className='product-detail-package-block-web'>
            <div className={`pdpbw-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <ImageHelper
                    alt={object.name}
                    className='pdpbw-cover-image'
                    dominant_color={object.get_dominant_color}
                    lightBox
                    lightBoxSrc={object.get_image_hq}
                    src={object.get_image_hq}
                />
                <div className='pdpbw-options'>
                    {object.package_options?.map((val) => {
                        if (val.items?.length! > 0) {
                            return (
                                <div
                                    key={val.id}
                                    className='pdpbw-options-wrap'
                                >
                                    <p className='pdpbw-options-name'>{val.description || val.name}</p>
                                    <div className='pdpbw-options-items'>
                                        {val.items?.map((val2) => {
                                            return (
                                                <div
                                                    key={val2.id}
                                                    className='pdpbw-options-item-wrap'
                                                >
                                                    <RatioHelper
                                                        alt={val2.product?.name}
                                                        dominant_color={val2.product?.get_dominant_color}
                                                        edit={edit}
                                                        onClick={() => setSelectionLightBoxImage(val2.product?.get_image_hq)}
                                                        src={val2.product?.get_image_square}
                                                        to={undefined}
                                                    />
                                                    <div className='pdpbw-options-item-button'>
                                                        <Button
                                                            color='white'
                                                            edit={edit}
                                                            fill='outline'
                                                            onClick={() => console.log('todo')}
                                                            size='small'
                                                            text={`Select`}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }
                        return false
                    })}
                </div>
            </div>
            <div className={`pdpbw-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='pdpbw-product-info'>
                    <div className='pdpbw-main-info'>
                        <p className='pdpbw-name'>{object.name}</p>
                        <div className='pdpbw-tags'>
                            {object.themes?.map((val) => (
                                <MainHashtag
                                    key={`themes-${val.id}`}
                                    edit={edit}
                                    label={val.name}
                                    searchTab={searchTabValue.product}
                                />
                            ))}
                        </div>
                        {object.profile && !duplicateProfile.includes(object.profile.id) && (
                            <ProfileShortInfoWeb
                                edit={edit}
                                object={object.profile}
                            />
                        )}
                        {creators.map((val) => (
                            <ProfileShortInfoWeb
                                key={val.id}
                                edit={edit}
                                name={val.member?.name || val.name}
                                object={val.member}
                            />
                        ))}
                        {labels.map((val) => (
                            <div
                                key={val.id}
                                className='pdpbw-label'
                            >
                                <ProfileShortInfoWeb
                                    edit={edit}
                                    name={val.member?.name || val.name}
                                    object={val.member}
                                />
                            </div>
                        ))}
                        <div className='pdpbw-countries'>
                            {object.profile?.countries?.map((val) => (
                                <MainCountryFlag
                                    key={val.id}
                                    edit={edit}
                                    object={val}
                                    searchTab={searchTabValue.product}
                                />
                            ))}
                            {object.profile?.cities?.map((val) => (
                                <MainHashtag
                                    key={val.id}
                                    edit={edit}
                                    label={val.name}
                                    searchTab={searchTabValue.product}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='pdpbw-section'>
                        <div className='pdpbw-price-info'>
                            {!object.price_on_request
                                ? (
                                    <React.Fragment>
                                        <p className='pdpbw-price'>{object.unit_price} {object.currency?.symbol || object.currency?.iso_code}</p>
                                        {object.stock_status?.id !== 1 && (
                                            <p className='pdpbw-price'>{object.stock_status?.name}</p>
                                        )}
                                    </React.Fragment>

                                ) : (
                                    <p className='pdpbw-price'>{reduxText[4869]}</p>
                                )}
                        </div>
                        {(object.product_length || object.packaging_weight) && (
                            <div className='pdpbw-technical-info'>
                                {object.product_length && (
                                    <p className='pdpbw-dimension'>{object.product_length} x {object.product_width} x {object.product_height} cm</p>
                                    // <p className='pdpbw-dimension'>{object.product_length} x {object.product_width} x {object.product_height} cm {dimension > 0 && `(${dimension} cm3)`}</p>
                                )}
                                {object.packaging_weight && (
                                    <p className='pdpbw-weight'>{object.packaging_weight} kg</p>
                                )}
                            </div>
                        )}
                    </div>
                    {object.description && (
                        <div className='pdpbw-section'>
                            <p className='pdpbw-section-title'>{reduxText[4596]}</p>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a target='blank' href={decoratedHref} key={key}>{decoratedText}</a>}>
                                <div className='pdpbw-description'>{object.description}</div>
                            </Linkify>
                        </div>
                    )}
                    {object.related_projects && object.related_projects.length > 0 && (
                        <div className='pdpbw-section'>
                            <p className='pdpbw-section-title'>{object.related_projects.length > 1 ? reduxText[2475] : reduxText[7716]}</p>
                            {object.related_projects.map((val) => (
                                <ProjectListMicroBlockWeb
                                    key={val.id}
                                    edit={edit}
                                    modalKey={modalKeys.related_projects}
                                    object={val}
                                />
                            ))}
                        </div>
                    )}
                    {object.owner_info && (
                        <CountViewBlock
                            object={object.owner_info}
                        />
                    )}
                </div>
            </div>
            {selectionLightBoxImage && (
                <LightboxCustom
                    mainSrc={selectionLightBoxImage}
                    onCloseRequest={() => setSelectionLightBoxImage(undefined)}
                />
            )}
        </div>
    )
})
