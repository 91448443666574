// react components
import React from 'react'

// data
import {
    reduxAuthState,
    reduxFormHelpersState,
    reduxTextState,
} from 'data'

// services
import {
    getMediaAll,
    getMediaBandcamp,
    getMediaSoundcloud,
    getMediaVimeo,
    getMediaYoutube,
    mainFormGetMeta,
} from 'services'

// main
export function mainFormGetMediaData({
    formInfo,
    formState,
    helpers,
    image_only,
    reduxAuth,
    reduxText,
    setFormState,
    setGetMetaIsLoading,
    setHelpers,
    urltoParse,
}: {
    formInfo: any
    formState: any
    helpers: reduxFormHelpersState
    image_only: boolean
    reduxAuth: reduxAuthState
    reduxText: reduxTextState['data']
    setFormState: React.Dispatch<any>
    setGetMetaIsLoading: React.Dispatch<boolean>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    urltoParse: string
}) {
    if (!urltoParse) return

    // setFormState({
    // 	...formState,
    // 	[formInfo.name]: {
    // 		...formInfo,
    // 		errors: {
    // 			...formInfo.errors,
    // 			link_url: 'Loading...', // TO_LATER_TEXT
    // 		},
    // 		fields: {
    // 			...formInfo.fields,
    // 			link_url: urltoParse,
    // 		},
    // 	},
    // })

    // Arte
    if (urltoParse.includes('arte.tv') || urltoParse.includes('arteradio.com') || urltoParse.includes('facebook.com') || urltoParse.includes('giphy.com') || urltoParse.includes('dailymotion.com')) { // Check if platform arte, vimeo, facebook, giphy, dailymotion
        getMediaAll({
            formInfo,
            formState,
            helpers,
            image_only,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    // Bandcamp
    else if (urltoParse.includes('bandcamp.com')) { // Check if platform bandcamp
        getMediaBandcamp({
            formInfo,
            formState,
            helpers,
            image_only,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    // Soundcloud
    else if (urltoParse.includes('soundcloud.com')) { // Check if platform soundcloud
        getMediaSoundcloud({
            formInfo,
            formState,
            helpers,
            image_only,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    // Youtube
    else if ((urltoParse.includes('youtu.be') || urltoParse.includes('youtube.com')) && !urltoParse.includes('/@')) { // Check if platform youtube
        getMediaYoutube({
            formInfo,
            formState,
            helpers,
            image_only,
            reduxAuth,
            reduxText,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    // Vimeo
    else if (urltoParse.includes('vimeo.com')) { // Check if platform vimeo
        getMediaVimeo({
            formInfo,
            formState,
            helpers,
            image_only,
            reduxText,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }

    // // Mixcloud
    // else if (urltoParse.includes('mixcloud.com')) { // Check if platform mixcloud, opengraph not working here
    // }

    // // Not handle format
    // else {
    //     etState((prevState) => ({
    //         [formInfoName]: {
    //             ...prevState[formInfoName],
    //             errors: {
    //                 ...prevState[formInfoName].errors,
    //                 link_url: "Format not supported yet on Just The Web, you can continue but the video won't be listed",
    //             },
    //         },
    //     }))
    // }

    else { // Check if platform not detect
        mainFormGetMeta({
            formInfo,
            formState,
            helpers,
            setFormState,
            setGetMetaIsLoading,
            setHelpers,
            urltoParse,
        })
    }
}
