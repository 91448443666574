// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonItem,
    IonLabel,
    IonReorder,
    IonReorderGroup,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ListSpinner,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getApiUrlV2,
    getDetailObject,
    getFieldTitle,
} from 'services'

type ItemType = {
    columnName: string
    columnTitle: string
}

// props
type FieldSettingsModalDetailFieldsProps = {
    blockId: number
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    profile: ProfileDetailAdminSerializerV2 | undefined
    selectedOptionsInDetail: ItemType[]
    setSelectedOptionsInDetail: any
}

// main
export const FieldSettingsModalDetailFields: React.FC<FieldSettingsModalDetailFieldsProps> = React.memo(({
    blockId,
    contentType,
    contentTypeSettings,
    profile,
    selectedOptionsInDetail,
    setSelectedOptionsInDetail,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        onGetListData()
    }, [])

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSettingsModalDetailFields',
                'doReorder',
            ))
        }
    }

    function onGetListData() {
        try {
            const apiUrl = getApiUrlV2(`setup/list/contenttypefieldlist/?object_id=${blockId}&content_type=${contentTypeData['setup_contenttypepanelblock']}&pagination=off&fields=field,field__key`, reduxAuth)
            getDetailObject({
                apiUrl,
                component: 'FieldSettingsModalDetailFields',
                dispatch,
                reduxAuth,
                setDetailObject: (data: any) => {
                    const cleanItems: ItemType[] = []
                    data.map((val: any) => {
                        const getFieldTitleResult = getFieldTitle({
                            column: val.field.key,
                            contentType,
                            contentTypeSettings,
                            profile: profile,
                            reduxText: reduxText,
                        })
                        // const fieldOptions = contentTypeSettings.field?.[val.split('__')[0]]
                        // if (fieldOptions?.hideIfNotStaff && !reduxAuth.settings?.user?.is_staff) return null
                        // if (fieldOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) return null
                        cleanItems.push({
                            columnName: val.field.key,
                            columnTitle: getFieldTitleResult.columnTitle,
                        })
                        return false
                    })
                    setSelectedOptionsInDetail(cleanItems)
                },
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSettingsModalDetailFields',
                'onGetListData',
            ))
        }
    }

    function onSelectRemove(columnName: string) {
        try {
            const newSelectedOptions: ItemType[] = []
            selectedOptionsInDetail.map(val => {
                if (val.columnName !== columnName) newSelectedOptions.push(val)
                return false
            })
            setSelectedOptionsInDetail(newSelectedOptions)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSettingsModalDetailFields',
                'onSelectRemove',
            ))
        }
    }

    function onSortEnd(event: any) {
        try {
            setSelectedOptionsInDetail(arrayMoveImmutable(selectedOptionsInDetail, event.detail.from, event.detail.to))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSettingsModalDetailFields',
                'onSortEnd',
            ))
        }
    }

    return (
        <>
            <div className='mo-dialog-title-wrap'>
                <p className='mo-column-title'>Detail</p>
            </div>
            {isLoading && <ListSpinner isLoading />}
            <IonReorderGroup
                disabled={false}
                onIonItemReorder={doReorder}
            >
                {selectedOptionsInDetail.map(item => {
                    return (
                        <IonItem key={item.columnName}>
                            <IonLabel className='clmp-label'>
                                <h3>{item.columnTitle}</h3>
                                {reduxAuth.settings?.user?.id === 1 && (<p className='clmp-profile'>{item.columnName}</p>)}
                            </IonLabel>
                            <i
                                className='clmp-label-icon mo-new-icon-circle-xmark-regular mo-cursor'
                                onClick={() => onSelectRemove(item.columnName)}
                            />
                            <IonReorder slot='end' />
                        </IonItem>
                    )
                })}
            </IonReorderGroup>
        </>
    )
})
