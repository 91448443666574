// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// containers
import {
    MainFormContainerWrapper,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AdditionalProfilePanel,
    CarteCategoryProfilePanel,
    CarteItemProfilePanel,
    EclinkProfilePanel,
    ImageProfilePanel,
    MainProfilePanel,
    MemberProfilePanel,
    MenuProfilePanel,
    PortfolioProfilePanel,
    ProfileCarteCategoryFormState,
    ProfileCarteItemFormState,
    ProfileEclinkFormState,
    ProfileImageFormState,
    ProfileMotherFormState,
    ProfileTeamFormState,
    TeamProfilePanel,
} from 'pages'

// serializers
import {
    ProfileFormState,
} from 'serializers/web'

// services
import {
    getDetailData,
    getInputValue,
    parseQuery,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type LocationParams = {
    formRefresh?: number
}

type ProjectFormProps = {
    objectId?: number
    onRefresh?: () => void
}

// main
export const ProfileForm: React.FC<ProjectFormProps> = React.memo(({
    objectId,
    onRefresh,
}) => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<ProfileFormState>({
        mother: ProfileMotherFormState(reduxAuth),
        carteCategory: ProfileCarteCategoryFormState(reduxAuth),
        carteItem: ProfileCarteItemFormState(reduxAuth),
        eclink: ProfileEclinkFormState(reduxAuth),
        image: ProfileImageFormState(reduxAuth),
        team: ProfileTeamFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailData({
            axiosCancelToken,
            component: 'ProfileForm',
            dispatch,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: objectId ? `${objectId}` : params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        objectId,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    function setInitialState() {
        setFormState({
            mother: ProfileMotherFormState(reduxAuth),
            carteCategory: ProfileCarteCategoryFormState(reduxAuth),
            carteItem: ProfileCarteItemFormState(reduxAuth),
            eclink: ProfileEclinkFormState(reduxAuth),
            image: ProfileImageFormState(reduxAuth),
            team: ProfileTeamFormState(reduxAuth),
        })
    }

    function handleInputChange(event: any, formInfo: any) {
        try {
            const mother = formState.mother
            const team = formState.team
            if (formInfo.name === 'mother' && event.name === 'description' && mother) {
                let toReturn
                let isbuttonDisabled = false
                if (event.value.length < 501) {
                    toReturn = {
                        ...mother,
                        errors: {
                            ...mother.errors,
                            description: '',
                        },
                        fields: {
                            ...mother.fields,
                            description: event.value,
                        },
                    }
                } else {
                    toReturn = {
                        ...mother,
                        errors: {
                            ...mother.errors,
                            description: reduxText[4120],
                        },
                        fields: {
                            ...mother.fields,
                            description: event.value,
                        },
                    }
                    isbuttonDisabled = true
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: isbuttonDisabled,
                    init: true,
                    mainError: '',
                })
            } else if (formInfo.name === 'mother' && event.name === 'own_profile' && mother) {
                let toReturn
                if (event.value === false) {
                    if (!mother.fields?.profile_created_by) {
                        toReturn = {
                            ...mother,
                            fields: {
                                ...mother.fields,
                                own_profile: false,
                                profile_created_by: reduxAuth.settings?.active_profile,
                            },
                        }
                    } else {
                        toReturn = {
                            ...mother,
                            fields: {
                                ...mother.fields,
                                own_profile: false,
                            },
                        }
                    }
                } else if (event.value === true) {
                    toReturn = {
                        ...mother,
                        fields: {
                            ...mother.fields,
                            own_profile: true,
                        },
                    }
                }
                if (toReturn) {
                    setFormState({
                        ...formState,
                        mother: toReturn,
                    })
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: true,
                        mainError: '', // TO_CHECK
                    })
                }
            } else if (formInfo.name === 'mother' && event.name === 'profile_email' && mother) {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        profile_email: event.value,
                        // profile_email_checkbox: true,  // bug fix try
                        // profile_email_sent: false,  // bug fix try
                    },
                    errors: {
                        ...mother.errors,
                        profile_email: '',
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'mother' && event.name === 'profile_email_checkbox' && mother) {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        profile_email_checkbox: event.value,
                        profile_email_sent: !event.value,
                    },
                    errors: {
                        ...mother.errors,
                        profile_email_checkbox: undefined,
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'team' && event.name === 'name' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: '',
                        name: event.value,
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                        'credit_type',
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'team' && event.name === 'member' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: event.value,
                        name: '',
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                        'credit_type',
                        'member',
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileForm',
                'handleInputChange',
            ))
        }
    }

    const panelValue = params.panelValue
    const panelText: any = {
        'additional-panel': reduxText[3234],
        'delete-panel': reduxText[2983],
        'image-panel': reduxText[4451],
        'link-panel': reduxText[5416],
        'main-panel': reduxText[347],
        'member-panel': reduxText[8520],
        'team-panel': reduxText[4917],
        'website-related-panel': reduxText[6415],
    }

    const fieldsMother = formState.mother.fields

    return (
        <MainFormContainerWrapper
            formState={formState}
            headerTitle={panelValue ? panelText[panelValue] : ''}
            helpers={helpers}
            onRefresh={onRefresh}
            reduxAuthFetchSettings
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {onRefresh && fieldsMother?.id && (
                <React.Fragment>
                    <p className='mfcmw-custom-edit-title'>{panelText['main-panel']}</p>
                    <MainProfilePanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['additional-panel']}</p>
                    <AdditionalProfilePanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['website-related-panel']}</p>
                    <PortfolioProfilePanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                </React.Fragment>
            )}
            {!onRefresh && (
                <React.Fragment>
                    {panelValue === 'main-panel' && (
                        <MainProfilePanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'carte-category-panel' && fieldsMother?.id && (
                        <CarteCategoryProfilePanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'carte-item-panel' && fieldsMother?.id && (
                        <CarteItemProfilePanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'link-panel' && fieldsMother?.id && (
                        <EclinkProfilePanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'image-panel' && fieldsMother?.id && (
                        <ImageProfilePanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'member-panel' && fieldsMother?.id && (
                        <MemberProfilePanel objectId={parseQuery(location.search).member_id} />
                    )}
                    {panelValue === 'additional-panel' && fieldsMother?.id && (
                        <AdditionalProfilePanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'website-related-panel' && fieldsMother?.id && (
                        <PortfolioProfilePanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'team-panel' && fieldsMother?.id && (
                        <TeamProfilePanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {!panelValue && (
                        <MenuProfilePanel
                            formState={formState}
                            helpers={helpers}
                            setHelpers={setHelpers}
                        />
                    )}
                </React.Fragment>
            )}
        </MainFormContainerWrapper>
    )
})
