// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'

// components
import {
    ErrorHelper,
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FormLabel,
} from 'pages'

// services
import {
    axiosErrorHandler,
    ecSlugify,
    getApiUrl,
    getApiUrlV2,
} from 'services'

// props
type SelectListAsyncInputV2Props = {
    adminOnly?: boolean
    apiUrl: string
    cache?: boolean
    clearable: boolean
    creatable?: boolean
    defaultOptions?: boolean
    disabled?: boolean
    error?: string
    helperText?: string
    label?: string
    layout?: string
    multi?: boolean
    name: string
    onChange: any
    staffOnly?: boolean
    team?: boolean
    v1?: boolean
    value: any
}

// main
export const SelectListAsyncInputV2: React.FC<SelectListAsyncInputV2Props> = React.memo(({
    adminOnly,
    apiUrl,
    cache = true,
    clearable,
    creatable,
    defaultOptions,
    disabled,
    error,
    helperText,
    label,
    layout,
    multi,
    name,
    onChange,
    staffOnly,
    team,
    v1,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setValue(value || (multi ? [] : ''))
    }, [value])

    const [newValue, setValue] = useState<any>(value || (multi ? [] : ''))

    async function getOptions(inputValue: string) {
        if (disabled) return
        let getUrl = v1 ? getApiUrl(apiUrl, reduxAuth) : getApiUrlV2(apiUrl, reduxAuth)
        getUrl += getUrl.includes('?') ? `&search=${ecSlugify(inputValue)}` : `?search=${ecSlugify(inputValue)}`
        if (process.env.NODE_ENV === 'development') console.log(getUrl)
        try {
            const response = await fetch(getUrl, reduxAuth.axiosConfig)
            const json = await response.json()
            return json.results
        } catch (error) {
            axiosErrorHandler({
                apiUrl: getUrl,
                component: 'SelectListAsyncInputV2',
                dispatch,
                error,
                reduxAuth,
                reference: 'getOptions',
            })
        }
    }

    const promiseOptions = (inputValue: string) =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(getOptions(inputValue))
            }, 100)
        })

    function onInputChange(value: any) {
        try {
            if (value === newValue) return
            setValue(value)
            onChange({
                name: name,
                value: value,
            })
            if (team && value.isNew) {
                onChange({
                    name: 'name',
                    value: value.name,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectListAsyncInputV2',
                'onInputChange',
            ))
        }
    }

    const customStyles = {
        clearIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            border: '1px solid var(--mo-color-ln)',
            borderRadius: '8px',
            ':hover': {
                ...provided[':hover'],
                border: '1px solid var(--mo-color-ln)',
                boxShadow: 'none',
            },
            ':focus': {
                ...provided[':focus'],
                boxShadow: '0 0 0 2px var(--mo-color-black)',
                outline: '1px solid var(--mo-color-black)',
            },
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--mo-color-text1)',
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            borderRadius: '8px',
            boxShadow: '2px 6px 21px -2px rgba(0,0,0,0.75)',
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            boxShadow: '0 0 4px 2px rgba(var(--mo-color-mo-color-rgb), 0.70)',
            border: '1px solid var(--mo-color-mo-color)',
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            borderBottom: '1px solid var(--mo-color-ln)',
            color: state.isSelected ? 'var(--mo-color-mo-color)' : 'var(--mo-color-text1)',
            ':active': {
                ...provided[':active'],
                backgroundColor: 'var(--mo-color-bg2)',
                color: 'var(--mo-color-mo-color)',
            },
            ':hover': {
                ...provided[':hover'],
                backgroundColor: 'var(--mo-color-mo-color)',
                color: 'var(--mo-color-text1)',
                cursor: 'pointer',
            },
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--mo-color-text1)',
        }),
    }

    function profileOptions(option: any) {
        let textToReturn = ''
        if (option.cities?.length > 0) {
            textToReturn += `${option.cities.filter((obj: any) => obj.active).map((val: any) => (val.name)).join(' - ')}`
        }
        if (option.countries?.length > 0) {
            textToReturn += `${textToReturn ? ' - ' : ''}${option.countries.map((val: any) => (val.name)).join(' - ')}`
        }
        if (option.metiers) {
            textToReturn += `${textToReturn ? ' - ' : ''}${option.metiers}`
        }
        return textToReturn
    }

    function formatOptionLabel(option: any) {
        if (option.select_description) {
            return (
                <div>
                    <p>{option.name}</p>
                    <p className='slilcl-option-select-description'>{option.select_description}</p>
                </div>
            )
        }
        if (layout) {
            return (
                <div className='select-list-input-layout-custom-label'>
                    <div
                        className={`slilcl-lcl-image-wrap${(option.get_image_xs || option.get_image_lq) ? '' : ' zarma'}`}
                        style={{
                            borderRadius: ['profile'].includes(layout) ? '50%' : '0%',
                        }}
                    >
                        {(option.get_image_xs || option.get_image_lq)
                            ? (
                                <ImageHelper
                                    alt={option.name}
                                    dominant_color={option.get_dominant_color}
                                    src={option.get_image_xs || option.get_image_lq}
                                />
                            ) : (
                                <span className='slilcl-image-zarma-name'>{option.name?.[0]}</span>
                            )}
                    </div>
                    <div className='slilcl-lcl-text-wrap'>
                        <p>{option.name}</p>
                        {layout === 'adherent' && option.parent?.name && (
                            <React.Fragment>
                                <p className='slilcl-lcl-profile-text mo-clamp-web1'>
                                    {option.parent?.name}
                                </p>
                            </React.Fragment>
                        )}
                        {layout !== 'portfolio' && (
                            option.profile && <p>{option.profile.name}</p>
                        )}
                        {layout === 'profile' && (
                            <React.Fragment>
                                <p className='slilcl-lcl-profile-text mo-clamp-web1'>
                                    {profileOptions(option)}
                                </p>
                                {option.profile_created_by && (
                                    <p className='slilcl-lcl-profile-text mo-clamp-web1'>
                                        {option.profile_created_by.name}
                                    </p>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    {option.select_description && (
                        <p className='slilcl-lcl-profile-text mo-clamp-web1'>
                            {option.select_description}
                        </p>
                    )}
                </div>
            )
        }
        return <p>{option.name}</p>
    }

    if (adminOnly && !reduxAuth.settings?.user?.id) return null
    if (staffOnly && !reduxAuth.settings?.user?.is_staff) return null

    if (label) {
        return (
            <div className={`input-wrap${disabled ? ' disabled' : ''}`}>
                <div className='input-label-wrap'>
                    <FormLabel
                        adminOnly={adminOnly}
                        helperText={helperText}
                        label={label}
                        name={name}
                        staffOnly={staffOnly}
                    />
                </div>
                <div className=''>
                    {creatable
                        ? (
                            <AsyncCreatableSelect
                                cacheOptions={cache}
                                createOptionPosition='first'
                                formatCreateLabel={(inputValue: any) => `${reduxText[2138]} "${inputValue}"`}
                                formatOptionLabel={formatOptionLabel}
                                getNewOptionData={(inputValue: any) => ({ id: `mo-created-option-${inputValue}`, name: inputValue, isNew: true })}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                isClearable={clearable}
                                isDisabled={disabled}
                                isMulti={multi}
                                isValidNewOption={() => true}
                                //@ts-ignore
                                loadOptions={promiseOptions}
                                menuPlacement='auto'
                                // @ts-ignore
                                // menuPortalTarget={inModal ? document.querySelector('.MuiDialog-root') : undefined} // need to check the value of querySelector
                                noOptionsMessage={(e: { inputValue: string }) => e.inputValue ? reduxText[5599] : reduxText[6376]}
                                onChange={onInputChange}
                                placeholder={reduxText[6376]}
                                styles={customStyles}
                                value={newValue}
                            />
                        ) : (
                            <AsyncSelect
                                cacheOptions={cache}
                                defaultOptions={defaultOptions}
                                formatOptionLabel={formatOptionLabel}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                isClearable={clearable}
                                isDisabled={disabled}
                                isMulti={multi}
                                //@ts-ignore
                                loadOptions={promiseOptions}
                                menuPlacement='auto'
                                // @ts-ignore
                                // menuPortalTarget={inModal ? document.querySelector('.MuiDialog-root') : undefined} // need to check the value of querySelector
                                noOptionsMessage={(e: { inputValue: string }) => e.inputValue ? reduxText[5599] : reduxText[6376]}
                                onChange={onInputChange}
                                placeholder={reduxText[6376]}
                                styles={customStyles}
                                value={newValue}
                            />
                        )
                    }
                </div>
                <ErrorHelper error={error} />
            </div>
        )
    }

    return (
        <div className=''>
            {creatable
                ? (
                    <AsyncCreatableSelect
                        cacheOptions={cache}
                        createOptionPosition='first'
                        formatCreateLabel={(inputValue: any) => `${reduxText[2138]} "${inputValue}"`}
                        formatOptionLabel={formatOptionLabel}
                        getNewOptionData={(inputValue: any) => ({ id: `mo-created-option-${inputValue}`, name: inputValue, isNew: true })}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        isClearable={clearable}
                        isDisabled={disabled}
                        isMulti={multi}
                        isValidNewOption={() => true}
                        //@ts-ignore
                        loadOptions={promiseOptions}
                        menuPlacement='auto'
                        // @ts-ignore
                        // menuPortalTarget={inModal ? document.querySelector('.MuiDialog-root') : undefined} // need to check the value of querySelector
                        noOptionsMessage={(e: { inputValue: string }) => e.inputValue ? reduxText[5599] : reduxText[6376]}
                        onChange={onInputChange}
                        placeholder={reduxText[6376]}
                        styles={customStyles}
                        value={newValue}
                    />
                ) : (
                    <AsyncSelect
                        cacheOptions={cache}
                        defaultOptions={defaultOptions}
                        formatOptionLabel={formatOptionLabel}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        isClearable={clearable}
                        isDisabled={disabled}
                        isMulti={multi}
                        //@ts-ignore
                        loadOptions={promiseOptions}
                        menuPlacement='auto'
                        // @ts-ignore
                        // menuPortalTarget={inModal ? document.querySelector('.MuiDialog-root') : undefined} // need to check the value of querySelector
                        noOptionsMessage={(e: { inputValue: string }) => e.inputValue ? reduxText[5599] : reduxText[6376]}
                        onChange={onInputChange}
                        placeholder={reduxText[6376]}
                        styles={customStyles}
                        value={newValue}
                    />
                )
            }
        </div>
    )
})
