// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    AdherentListSerializer,
} from 'serializers/web'

// props
type AdherentListHeaderProps = {
    count: number | undefined
    inDetailList?: boolean
    object: AdherentListSerializer | undefined
}

// main
export const AdherentListHeader: React.FC<AdherentListHeaderProps> = React.memo(({
    count,
    inDetailList,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!object) return null

    if (object.profile?.id === 183405) {  // Alize
        return (
            <tr className='main-list-header AdherentListHeader'>
                <th>{count !== undefined ? `${count} ${reduxText[8337]}` : ''}</th>
                <th>{reduxText[8573]}</th>
                <th>{reduxText[6462]}</th>
                <th>{reduxText[6461]}</th>
                <th>{reduxText[8562]}</th>
                <th>Type d'exercice</th>
                <th>Région</th>
                <th>{reduxText[8710]}</th>
                <th className='multi-line'>{reduxText[8711]}</th>
                <th>{reduxText[8718]}</th>
            </tr>
        )
    }

    if (object.profile?.id === 183474) {  // APPFE
        return (
            <tr className='main-list-header AdherentListHeader'>
                <th>{count !== undefined ? `${count} ${reduxText[8337]}` : ''}</th>
                <th>{reduxText[8573]}</th>
                <th>{reduxText[6462]}</th>
                <th>{reduxText[6461]}</th>
                <th>{reduxText[8562]}</th>
                <th>{reduxText[8710]}</th>
                <th>Status</th>
                <th>{reduxText[8564]}</th>
                <th>{reduxText[8711]}</th>
            </tr>
        )
    }

    if (object.profile?.id === 187728) {  // FCAPI
        return (
            <tr className='main-list-header AdherentListHeader'>
                <th>{count !== undefined ? `${count} ${reduxText[8337]}` : ''}</th>
                <th>{reduxText[8573]}</th>
                <th>{reduxText[6462]}</th>
                <th>{reduxText[6461]}</th>
                <th>{reduxText[8562]}</th>
                <th>Chiens</th>
                <th>Validité carte handicapé</th>
                <th className='multi-line'>Date renouvellement cotisation</th>
            </tr>
        )
    }

    if (object.profile?.id === 187807) {  // HEY
        return (
            <tr className='main-list-header AdherentListHeader'>
                <th>{count !== undefined ? `${count} ${reduxText[8337]}` : ''}</th>
                <th>{reduxText[8573]}</th>
                <th>{reduxText[6462]}</th>
                <th>{reduxText[6461]}</th>
                <th>{reduxText[8562]}</th>
            </tr>
        )
    }

    return null
})
