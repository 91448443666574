// Root URL
const filterRoot = 'adhesion/filter/contribution/'

export const contributionFilterOptions: any[] = [
    {
        api_url: 'boolean',
        slug: 'is_paid',
        textId: 8557,  // reduxText[8557]
    },
    {
        api_url: `${filterRoot}?filter_model=pricing`,
        showNone: true,
        slug: 'pricing',
        textId: 8570,  // reduxText[8570]
    },
]
