// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    Alert,
    Loader,
    Popover,
} from 'components'

// data
import {
    api_url_material_action,
    api_url_material_form_active,
    api_url_material_form_duplicate,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    view_url_material_form,
} from 'data'

// pages
import {
    AddToSectionForm,
    DuplicateObjectHelper,
} from 'pages'

// serializer
import {
    MainPortfolioSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    setStaffPermission,
} from 'services'

// props
type MaterialActionSheetWebProps = {
    edit: boolean
    feedId?: number
    inModal?: boolean
    isInProfileSlug?: string
    object: any
    refreshData: () => void
    setActivated: React.Dispatch<boolean> | undefined
    setShowActionPopover: React.Dispatch<boolean>
    showActionPopover: boolean
}

// main
export const MaterialActionSheetWeb: React.FC<MaterialActionSheetWebProps> = React.memo(({
    edit,
    feedId,
    inModal,
    isInProfileSlug,
    object,
    refreshData,
    setActivated,
    setShowActionPopover,
    showActionPopover,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (showActionPopover) getData(object.id)
    }, [
        object.id,
        showActionPopover,
    ])

    type actionObjectType = {
        active?: boolean
        has_permission?: boolean
        original_image?: string
        staff_permission?: boolean
    }

    const [buttons, setButtons] = useState<any[]>([])
    const [isDuplicateAlertOpen, setIsDuplicateAlertOpen] = useState(false)
    const [isDuplicateLoading, setIsDuplicateLoading] = useState(false)
    const [portfolio, setPorfolio] = useState<MainPortfolioSerializer>()
    const [showAddToSection, setShowAddToSection] = useState(false)
    const [showDuplicateObject, setShowDuplicateObject] = useState(false)

    async function getData(objectId: string) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MaterialActionSheetWeb getData')

            if (!objectId) return
            const getUrl = getApiUrl(`${api_url_material_action}${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!response.data) return
                    const actionObject: actionObjectType = response.data
                    const newButtons = []

                    if (response.data.portfolio_main) {
                        setPorfolio(response.data.portfolio_main)
                    }

                    // Permission
                    if (actionObject.has_permission) {
                        newButtons.push(
                            {
                                handler: () => {
                                    dispatch(reduxModalMainFormShow({
                                        cssClass: 'main-form-container-modal-web',
                                        formComponent: 'MaterialForm',
                                        objectId: object.id,
                                        refreshData,
                                    }))
                                    setShowActionPopover(false)
                                },
                                role: 'edit',
                                text: reduxText[8326],
                            },
                            {
                                handler: () => setShowActionPopover(false),
                                role: 'edit',
                                text: reduxText[2105],
                                to: `${view_url_material_form}${object.id}/`,
                            },
                        )
                    }
                    if (isInProfileSlug && actionObject.has_permission) {
                        newButtons.push(
                            {
                                handler: () => {
                                    handleAddToSection()
                                    setShowActionPopover(false)
                                },
                                text: reduxText[6594],
                            },
                        )
                    }
                    if (actionObject.has_permission) {
                        newButtons.push(
                            {
                                handler: () => {
                                    handlePublish(actionObject.active)
                                    setShowActionPopover(false)
                                },
                                role: actionObject.active ? 'destructive' : '',
                                text: actionObject.active ? reduxText[3517] : reduxText[3518],
                            },
                            {
                                handler: () => setIsDuplicateAlertOpen(true),
                                text: reduxText[6435],
                            },
                            {
                                handler: () => {
                                    setShowActionPopover(false)
                                    setShowDuplicateObject(true)
                                },
                                text: reduxText[8472],
                            },
                        )
                    }

                    // Staff Permission
                    if (reduxAuth.settings?.user?.groups?.includes(8)) {
                        newButtons.push(
                            {
                                handler: () => {
                                    setStaffPermission({
                                        component: 'MaterialActionSheetWeb',
                                        contentTypeModel: 'material',
                                        dispatch,
                                        objectId: object.id,
                                        reduxAuth,
                                        refreshData,
                                    })
                                    setShowActionPopover(false)
                                },
                                text: actionObject.staff_permission ? 'Remove staff permission' : 'Set staff permission',
                            },
                        )
                    }

                    // Image
                    if (actionObject.original_image) {
                        newButtons.push(
                            {
                                text: reduxText[7855],
                                handler: () => window.open(actionObject.original_image, '_blank')
                            },
                        )
                    }

                    setButtons(newButtons)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'MaterialActionSheetWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MaterialActionSheetWeb',
                'getData',
            ))
        }
    }

    function handleAddToSection() {
        try {
            if (isInProfileSlug) {
                setShowAddToSection(true)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MaterialActionSheetWeb',
                'handleAddToSection',
            ))
        }
    }

    async function handleDuplicate() {
        try {
            setIsDuplicateLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MaterialActionSheetWeb handleDuplicate')
            const duplicateUrl = getApiUrl(`${api_url_material_form_duplicate}?duplicate_material_id=${object.id}`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'post',
                url: duplicateUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDuplicateLoading(false)
                    setShowActionPopover(false)
                    history.push(`${view_url_material_form}${response.data.id}/main/main-panel/`)
                })
                .catch((error) => {
                    setIsDuplicateLoading(false)
                    setShowActionPopover(false)
                    axiosErrorHandler({
                        apiUrl: duplicateUrl,
                        component: 'MaterialActionSheetWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDuplicate',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MaterialActionSheetWeb',
                'handleDuplicate',
            ))
        }
    }

    async function handlePublish(active?: boolean) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MaterialActionSheetWeb handlePublish')
            const formData = new FormData()
            formData.append('active', (!active).toString())
            const activeUrl = getApiUrl(`${api_url_material_form_active}${object.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    refreshData()
                    dispatch(reduxFormSetIsSuccessToastOpen(true, active ? reduxText[6567] : reduxText[5901]))
                    if (setActivated) setActivated(!active)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'MaterialActionSheetWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePublish',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MaterialActionSheetWeb',
                'handlePublish',
            ))
        }
    }

    return (
        <React.Fragment>
            <Popover
                buttons={buttons}
                edit={edit}
                extraZIndex={inModal}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
            />
            {isDuplicateAlertOpen && (
                <Alert
                    buttons={[
                        {
                            handler: () => setShowActionPopover(false),
                            text: reduxText[4519],
                        },
                        {
                            handler: () => handleDuplicate(),
                            text: reduxText[5614],
                        },
                    ]}
                    header={reduxText[7711]}
                    isOpen
                    onDidDismiss={() => setIsDuplicateAlertOpen(false)}
                />
            )}
            {showAddToSection && (
                <AddToSectionForm
                    contentSource={feedId ? 'generalfeed' : 'material'}
                    contentType={feedId ? 'generalfeed' : 'material'}
                    objectId={feedId || object.id}
                    profileSlug={isInProfileSlug!}
                    refreshData={refreshData}
                    setIsOpen={setShowAddToSection}
                />
            )}
            {showDuplicateObject && (
                <DuplicateObjectHelper
                    contentType='material'
                    defaultPortfolio={portfolio}
                    newMixedMediaContent={false}
                    newMixedMediaContentType={undefined}
                    objectId={object.id}
                    profile={object.profile}
                    setIsOpen={setShowDuplicateObject}
                />
            )}
            {isDuplicateLoading && (
                <Loader
                    isOpen
                    message={reduxText[7712]}
                />
            )}
        </React.Fragment>
    )
})
