// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonThumbnail,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormAutoSaveAddSite,
    reduxFormAutoSaveRemoveSite,
    reduxFormSetCollapseStylesSite,
    reduxFormSetHoveredBlockIdSite,
    reduxFormSetNavigationModeSite,
    reduxModaSetDeviceTypeSite,
    reduxModalErrorEventHandler,
    richTextStyles,
} from 'data'

// pages
import {
    StyleInputHelperNew,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
} from 'serializers/site'
import {
    ProjectFormState,
} from 'serializers/web'

// services
import {
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    panelValue: string
}

type StyleMixedContentPanelProps = {
    formState: ProjectFormState
    handleInputChange: any
    setTabValue: React.Dispatch<string>
}

// main
export const StyleMixedContentPanel: React.FC<StyleMixedContentPanelProps> = React.memo(({
    formState,
    handleInputChange,
    setTabValue,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormcssProperties = useSelector((state: defaultReduxState) => state.reduxForm.cssProperties)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const mixedMediaContent = formState.mixedMediaContent
    const deviceType = reduxModalSite.deviceType

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [initialStyles, setInitialStyles] = useState<MainStyleSerializer | undefined>(mixedMediaContent.fields?.styles_original)
    const [openGroups, setOpenGroups] = useState<{
        [key: string]: boolean
    }>({
        used: true,
    })
    const [openAllGroups, setOpenAllGroups] = useState(false)

    useEffect(() => {
        setButtonDisabled(true)
        setInitialStyles(mixedMediaContent.fields?.styles_original)
    }, [
        mixedMediaContent.fields?.id,
    ])

    function onHandleInputChange(value: any, valueRef: 'value' | 'value_MD' | 'value_SM') {
        try {
            if (reduxFormSite.pageContentItems && mixedMediaContent) {

                const toReturnValue = Object.assign({}, fieldsInline?.styles_original || {
                    value: {},
                    value_MD: {},
                    value_SM: {},
                })

                toReturnValue[valueRef] = {
                    ...toReturnValue[valueRef],
                    [value.name]: value.value,
                }

                const forPreviewValue: MainStyleSerializer = {
                    value: {},
                    value_MD: {},
                    value_SM: {},
                }

                const styles_array: string[] = []
                Object.getOwnPropertyNames(toReturnValue.value).map((val) => {
                    styles_array.push(val)
                    return false
                })
                Object.getOwnPropertyNames(toReturnValue.value_MD).map((val) => {
                    styles_array.push(val)
                    return false
                })
                Object.getOwnPropertyNames(toReturnValue.value_SM).map((val) => {
                    styles_array.push(val)
                    return false
                })
                styles_array.map((val) => {
                    // @ts-ignore
                    const value_LG = toReturnValue.value[val]
                    // @ts-ignore
                    const value_MD = !['', 'inherit', undefined, null].includes(toReturnValue.value_MD[val]) ? toReturnValue.value_MD[val] : value_LG
                    // @ts-ignore
                    const value_SM = !['', 'inherit', undefined, null].includes(toReturnValue.value_SM[val]) ? toReturnValue.value_SM[val] : (value_MD || value_LG)
                    // @ts-ignore
                    forPreviewValue.value[val] = value_LG
                    // @ts-ignore
                    forPreviewValue.value_MD[val] = value_MD
                    // @ts-ignore
                    forPreviewValue.value_SM[val] = value_SM
                })

                // Update in autoSave
                dispatch(reduxFormAutoSaveAddSite(
                    mixedMediaContent.fields!.id!,
                    forPreviewValue,
                    toReturnValue,
                    mixedMediaContent.updateUrl,
                ))

                // Update in content
                handleInputChange({
                    name: 'styles_original',
                    value: toReturnValue,
                }, mixedMediaContent)

                setButtonDisabled(false)
                if (reduxFormSite.navigationMode !== 'edit') dispatch(reduxFormSetNavigationModeSite('edit'))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StyleMixedContentPanel',
                'onHandleInputChange',
            ))
        }
    }

    function handleCancel() {
        try {
            dispatch(reduxFormAutoSaveRemoveSite(mixedMediaContent?.fields?.id!, true))
            handleInputChange({
                name: 'styles_original',
                value: initialStyles,
            }, mixedMediaContent)
            setButtonDisabled(true)
            dispatch(reduxFormSetCollapseStylesSite(false))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StyleMixedContentPanel',
                'handleCancel',
            ))
        }
    }

    function handleCssClassHover(mousedirection: 'enter' | 'leave') {
        try {
            if (mousedirection === 'enter') {
                dispatch(reduxFormSetHoveredBlockIdSite(mixedMediaContent?.fields?.id))
            } else {
                dispatch(reduxFormSetHoveredBlockIdSite(undefined))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StyleMixedContentPanel',
                'handleCssClassHover',
            ))
        }
    }

    function handleOpenGroup(id: string | number) {
        setOpenGroups({
            ...openGroups,
            [id]: openGroups[id] ? false : true,
        })
    }

    function handleOpenAllGroups() {
        if (openAllGroups) {
            setOpenGroups({})
        } else {
            const openGroups: any = {}
            openGroups.used = true
            mixedMediaContent.fields?.layout?.styles_grouped?.map((val) => {
                openGroups[val.id!] = true
            })
            setOpenGroups(openGroups)
        }
        setOpenAllGroups(!openAllGroups)
    }

    // Mother
    const fieldsMother = mother.fields

    // Inline
    const fieldsInline = mixedMediaContent.fields

    if (!fieldsInline) return null

    return (
        <div className={`style-mixed-panel-new-web mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className='spcppnw-device'>
                <div
                    className={`spcppnw-device-div ${deviceType === 'is-web' && 'active'}`}
                    onClick={() => {
                        dispatch(reduxModaSetDeviceTypeSite('is-web'))
                        scrollToSection(params.panelValue, 0)
                    }}
                >
                    <i className='icon mo-new-icon-desktop-solid' />
                </div>
                <div
                    className={`spcppnw-device-div ${deviceType === 'is-tablet' && 'active'}`}
                    onClick={() => {
                        dispatch(reduxModaSetDeviceTypeSite('is-tablet'))
                        scrollToSection(params.panelValue, 0)
                    }}
                >
                    <i className='icon mo-new-icon-tablet-alt-solid' />
                </div>
                <div
                    className={`spcppnw-device-div ${deviceType === 'is-mobile' && 'active'}`}
                    onClick={() => {
                        dispatch(reduxModaSetDeviceTypeSite('is-mobile'))
                        scrollToSection(params.panelValue, 0)
                    }}
                >
                    <i className='icon mo-new-icon-mobile-alt-solid' />
                </div>
            </div>
            <div className={`spcppnw-style-wrap mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div
                    className='template-block-normal-mixed-media-content-web detail'
                    onClick={() => setTabValue('settings')}
                    onMouseEnter={() => handleCssClassHover('enter')}
                    onMouseLeave={() => handleCssClassHover('leave')}
                >
                    <div className='tbnmmcw-info'>
                        <div className='tbnmmcw-name-wrap'>
                            <span className='tbnmmcw-name'>{fieldsInline.layout_edit?.name}{fieldsInline.layout_edit?.active ? '' : '(O)'}</span>
                            {fieldsInline.layout_edit?.content_source && (
                                <span className='tbnmmcw-content-source'>{fieldsInline.layout_edit.content_source}</span>
                            )}
                            {fieldsInline.action && (
                                <span className='tbnmmcw-link'>{fieldsInline.action.name}</span>
                            )}
                        </div>
                        {fieldsInline.layout_edit?.field_text?.name && (
                            fieldsInline.layout_edit.field_text.field_key
                                ? (
                                    <p className='tbnmmcw-text mo-clamp-web1'>{fieldsMother?.profile?.settings_data_json?.contentTypeSettingsCustom?.[fieldsInline.layout_edit?.content_type_settings?.content_type_label_model!]?.field?.[fieldsInline.layout_edit.field_text.field_key]?.verboseName || fieldsInline.layout_edit.field_text.name}</p>
                                ) : (
                                    <p className='tbnmmcw-text mo-clamp-web1'>{fieldsInline.layout_edit.field_text.name}</p>
                                )
                        )}
                        {!fieldsInline.active && (
                            <p className='tbnmmcw-content-inactive'>{reduxText[5616]}</p>
                        )}
                    </div>
                    {[781].includes(fieldsInline.layout_edit?.id!) && (fieldsInline.get_image_site_lq || fieldsInline.file) && (
                        <div className='tbnmmcw-image-wrap'>
                            <IonThumbnail>
                                <ImageHelper
                                    alt=''
                                    className='tbnmmcw-image'
                                    dominant_color={fieldsInline.get_dominant_color}
                                    src={fieldsInline.get_image_site_lq || fieldsInline.file}
                                />
                            </IonThumbnail>
                        </div>
                    )}
                    <div className='tbnmmcw-actions-wrap'>
                        <div className='tbnmmcw-actions'>
                            <button
                                className='main-icon-block-web'
                                onClick={() => setTabValue('settings')}
                            >
                                <i className='main-icon mo-new-icon-pencil-alt-solid' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='spcppnw-section-header-web'>
                    <div
                        className='spcppnw-title-div'
                        onClick={handleOpenAllGroups}
                    >
                        <i className={`spcppnw-collapse mo-new-icon-sort${openAllGroups ? '-reverse' : ''}`} />
                    </div>
                </div>
                <div className='spcppnw-sep-wrap'>
                    <div
                        className='spcppnw-sep-div'
                        onClick={() => handleOpenGroup('used')}
                    >
                        <i className={`spcppnw-expand mo-new-icon-caret-right-solid${openGroups.used ? ' open' : ''}`} />
                        <p className='spcppnw-sep-title'>{reduxText[8313]}</p>
                    </div>
                    {openGroups.used && (
                        <i
                            className={`spcppnw-collapse mo-new-icon-sort${reduxFormSite.collapseStyles ? '' : '-reverse'}`}
                            onClick={() => dispatch(reduxFormSetCollapseStylesSite(!reduxFormSite.collapseStyles))}
                        />
                    )}
                </div>
                <div className={`spcppnw-style-group-wrap${openGroups.used ? ' open' : ''}`}>
                    {reduxFormcssProperties?.map((val) => {
                        if (deviceType === 'is-web' && fieldsInline.styles_edit?.value?.[val.css_class]) {
                            if (fieldsInline.layout_edit?.id === 779 && fieldsInline.text_short_mode?.id === 2 && !richTextStyles.includes(val.css_class)) return null
                            return (
                                <StyleInputHelperNew
                                    key={`${deviceType}-${val.id}-${mixedMediaContent.fields?.id}`}
                                    css_property={val}
                                    mixedMedia
                                    onChange={(e: any) => onHandleInputChange(e, 'value')}
                                    presetColors={fieldsMother?.preset_colors}
                                    value={fieldsInline.styles_original?.value?.[val.css_class] || ''}
                                    value_type='LG'
                                />
                            )
                        }
                        if (deviceType === 'is-tablet' && fieldsInline.styles_edit?.value_MD?.[val.css_class]) {
                            if (fieldsInline.layout_edit?.id === 779 && fieldsInline.text_short_mode?.id === 2 && !richTextStyles.includes(val.css_class)) return null
                            return (
                                <StyleInputHelperNew
                                    key={`${deviceType}-${val.id}-${mixedMediaContent.fields?.id}`}
                                    css_property={val}
                                    mixedMedia
                                    onChange={(e: any) => onHandleInputChange(e, 'value_MD')}
                                    presetColors={fieldsMother?.preset_colors}
                                    value={fieldsInline.styles_original?.value_MD?.[val.css_class] || ''}
                                    value_LG={fieldsInline.styles_original?.value?.[val.css_class] || ''}
                                    value_type='MD'
                                />
                            )
                        }
                        if (deviceType === 'is-mobile' && fieldsInline.styles_edit?.value_SM?.[val.css_class]) {
                            if (fieldsInline.layout_edit?.id === 779 && fieldsInline.text_short_mode?.id === 2 && !richTextStyles.includes(val.css_class)) return null
                            return (
                                <StyleInputHelperNew
                                    key={`${deviceType}-${val.id}-${mixedMediaContent.fields?.id}`}
                                    css_property={val}
                                    mixedMedia
                                    onChange={(e: any) => onHandleInputChange(e, 'value_SM')}
                                    presetColors={fieldsMother?.preset_colors}
                                    value={fieldsInline.styles_original?.value_SM?.[val.css_class] || ''}
                                    value_LG={fieldsInline.styles_original?.value?.[val.css_class] || ''}
                                    value_MD={fieldsInline.styles_original?.value_MD?.[val.css_class] || ''}
                                    value_type='SM'
                                />
                            )
                        }
                    })}
                </div>
                <div className='spcppnw-sep-wrap'>
                    <div
                        className='spcppnw-sep-div'
                        onClick={() => handleOpenGroup('favorite')}
                    >
                        <i className={`spcppnw-expand mo-new-icon-caret-right-solid${openGroups.favorite ? ' open' : ''}`} />
                        <p className='spcppnw-sep-title'>{reduxText[8500]}</p>
                    </div>
                    {openGroups.favorite && (
                        <i
                            className={`spcppnw-collapse mo-new-icon-sort${reduxFormSite.collapseStyles ? '' : '-reverse'}`}
                            onClick={() => dispatch(reduxFormSetCollapseStylesSite(!reduxFormSite.collapseStyles))}
                        />
                    )}
                </div>
                <div className={`spcppnw-style-group-wrap${openGroups.favorite ? ' open' : ''}`}>
                    {reduxFormcssProperties?.map((val) => {
                        if (deviceType === 'is-web' && fieldsInline.layout?.styles_favorite?.includes(val.css_class)) {
                            if (fieldsInline.layout_edit?.id === 779 && fieldsInline.text_short_mode?.id === 2 && !richTextStyles.includes(val.css_class)) return null
                            return (
                                <StyleInputHelperNew
                                    key={`${deviceType}-${val.id}-${mixedMediaContent.fields?.id}`}
                                    css_property={val}
                                    mixedMedia
                                    onChange={(e: any) => onHandleInputChange(e, 'value')}
                                    presetColors={fieldsMother?.preset_colors}
                                    value={fieldsInline.styles_original?.value?.[val.css_class] || ''}
                                    value_type='LG'
                                />
                            )
                        }
                        if (deviceType === 'is-tablet' && fieldsInline.layout?.styles_favorite?.includes(val.css_class)) {
                            if (fieldsInline.layout_edit?.id === 779 && fieldsInline.text_short_mode?.id === 2 && !richTextStyles.includes(val.css_class)) return null
                            return (
                                <StyleInputHelperNew
                                    key={`${deviceType}-${val.id}-${mixedMediaContent.fields?.id}`}
                                    css_property={val}
                                    mixedMedia
                                    onChange={(e: any) => onHandleInputChange(e, 'value_MD')}
                                    presetColors={fieldsMother?.preset_colors}
                                    value={fieldsInline.styles_original?.value_MD?.[val.css_class] || ''}
                                    value_LG={fieldsInline.styles_original?.value?.[val.css_class] || ''}
                                    value_type='MD'
                                />
                            )
                        }
                        if (deviceType === 'is-mobile' && fieldsInline.layout?.styles_favorite?.includes(val.css_class)) {
                            if (fieldsInline.layout_edit?.id === 779 && fieldsInline.text_short_mode?.id === 2 && !richTextStyles.includes(val.css_class)) return null
                            return (
                                <StyleInputHelperNew
                                    key={`${deviceType}-${val.id}-${mixedMediaContent.fields?.id}`}
                                    css_property={val}
                                    mixedMedia
                                    onChange={(e: any) => onHandleInputChange(e, 'value_SM')}
                                    presetColors={fieldsMother?.preset_colors}
                                    value={fieldsInline.styles_original?.value_SM?.[val.css_class] || ''}
                                    value_LG={fieldsInline.styles_original?.value?.[val.css_class] || ''}
                                    value_MD={fieldsInline.styles_original?.value_MD?.[val.css_class] || ''}
                                    value_type='SM'
                                />
                            )
                        }
                    })}
                </div>
                {fieldsInline.layout?.styles_grouped?.map((gal) => {
                    return (
                        <React.Fragment key={gal.id}>
                            <div className='spcppnw-sep-wrap'>
                                <div
                                    className='spcppnw-sep-div'
                                    onClick={() => handleOpenGroup(gal.id!)}
                                >
                                    <i className={`spcppnw-expand mo-new-icon-caret-right-solid${openGroups[gal.id!] ? ' open' : ''}`} />
                                    <p className='spcppnw-sep-title'>{gal.name}</p>
                                </div>
                                {openGroups[gal.id!] && (
                                    <i
                                        className={`spcppnw-collapse mo-new-icon-sort${reduxFormSite.collapseStyles ? '' : '-reverse'}`}
                                        onClick={() => dispatch(reduxFormSetCollapseStylesSite(!reduxFormSite.collapseStyles))}
                                    />
                                )}
                            </div>
                            <div className={`spcppnw-style-group-wrap${openGroups[gal.id!] ? ' open' : ''}`}>
                                {reduxFormcssProperties?.map((val) => {
                                    if (deviceType === 'is-web' && gal.styles?.includes(val.css_class)) {
                                        if (fieldsInline.layout_edit?.id === 779 && fieldsInline.text_short_mode?.id === 2 && !richTextStyles.includes(val.css_class)) return null
                                        return (
                                            <StyleInputHelperNew
                                                key={`${deviceType}-${val.id}-${mixedMediaContent.fields?.id}`}
                                                css_property={val}
                                                mixedMedia
                                                onChange={(e: any) => onHandleInputChange(e, 'value')}
                                                presetColors={fieldsMother?.preset_colors}
                                                value={fieldsInline.styles_original?.value?.[val.css_class] || ''}
                                                value_type='LG'
                                            />
                                        )
                                    }
                                    if (deviceType === 'is-tablet' && gal.styles?.includes(val.css_class)) {
                                        if (fieldsInline.layout_edit?.id === 779 && fieldsInline.text_short_mode?.id === 2 && !richTextStyles.includes(val.css_class)) return null
                                        return (
                                            <StyleInputHelperNew
                                                key={`${deviceType}-${val.id}-${mixedMediaContent.fields?.id}`}
                                                css_property={val}
                                                mixedMedia
                                                onChange={(e: any) => onHandleInputChange(e, 'value_MD')}
                                                presetColors={fieldsMother?.preset_colors}
                                                value={fieldsInline.styles_original?.value_MD?.[val.css_class] || ''}
                                                value_LG={fieldsInline.styles_original?.value?.[val.css_class] || ''}
                                                value_type='MD'
                                            />
                                        )
                                    }
                                    if (deviceType === 'is-mobile' && gal.styles?.includes(val.css_class)) {
                                        if (fieldsInline.layout_edit?.id === 779 && fieldsInline.text_short_mode?.id === 2 && !richTextStyles.includes(val.css_class)) return null
                                        return (
                                            <StyleInputHelperNew
                                                key={`${deviceType}-${val.id}-${mixedMediaContent.fields?.id}`}
                                                css_property={val}
                                                mixedMedia
                                                onChange={(e: any) => onHandleInputChange(e, 'value_SM')}
                                                presetColors={fieldsMother?.preset_colors}
                                                value={fieldsInline.styles_original?.value_SM?.[val.css_class] || ''}
                                                value_LG={fieldsInline.styles_original?.value?.[val.css_class] || ''}
                                                value_MD={fieldsInline.styles_original?.value_MD?.[val.css_class] || ''}
                                                value_type='SM'
                                            />
                                        )
                                    }
                                })}
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
            <div className='spcppnw-footer'>
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    size='small'
                    fill='outline'
                    onClick={handleCancel}
                    text={reduxText[4519]}
                />
                {!buttonDisabled && (
                    <p className='spcppnw-text'>{reduxText[8183]}</p>
                )}
            </div>
        </div>
    )
})
