// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    Button,
    Copy,
    ErrorHelper,
    IconBlock,
    Loader,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    reduxModalMainFormHide,
} from 'data'

// pages
import {
    FormLabel,
    SelectListAsyncInputV2,
    SelectListInputV2,
    TextAreaInputV2,
} from 'pages'

// serializers
import {
    ContentTypeFormTypes,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getApiUrlV2,
    getAxiosHeaders,
    getInputValueV2,
    getMainErrorMessage,
} from 'services'

// props
type CustomFormModalProps = {
    contentType: contentTypeModelType
    defaultFields?: any
    onCloseHelperModal?: (e: boolean, value?: any) => void
    onRefresh?: () => void
}

// main
export const CustomFormModal: React.FC<CustomFormModalProps> = React.memo(({
    contentType,
    defaultFields,
    onCloseHelperModal,
    onRefresh,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const formStateProps = {
        contentTypeSettings: undefined,
        data: {
            errors: {},
            fields: defaultFields || {},
        },
        fieldsForm: {
            formAttribute: [],
            formFile: [],
            formForeignKey: [
                'project',
                'status',
            ],
            formJson: [
                'payload',
            ],
            formManyToMany: [],
        },
        fieldsRequired: {
            requiredAttribute: [],
            requiredForeignKey: [],
            requiredManyToMany: [],
        },
        structure: {
            panels: [],
        },
    }

    const [formState, setFormState] = useState<ContentTypeFormTypes>(formStateProps)
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const app = contentType.split('_')[0]
    const model = contentType.split('_')[1]
    const object = formState.data.fields

    function handleInputChange(event: any) {
        try {
            setFormState(getInputValueV2({
                contentType,
                event,
                formState,
                reduxText,
            }))
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                init: true,
                mainError: '',
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFormModal',
                'handleInputChange',
            ))
        }
    }

    function handleRequired(another?: boolean) {
        try {
            const result = checkRequiredFields({
                formInfoObject: formState,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit(another)
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    data: {
                        ...formState.data,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFormModal',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit(another?: boolean) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'CustomFormModal handleSubmit')
            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            const postUrl = getApiUrlV2(`${app}/form/${model}/create/bulk/`, reduxAuth)
            // const formData = getFormDataV2(formState)
            const formData = new FormData()
            let parsedPayload;
            try {
                parsedPayload = JSON.parse(formState.data.fields['payload']); // Convert string to JSON
            } catch (error) {
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    isSaving: false,
                    isSuccess: false,
                    mainError: '',
                })
                setFormState({
                    ...formState,
                    data: {
                        ...formState.data,
                        errors: {
                            payload: 'Invalid JSON input',
                        },
                    }
                })
                return
            }
            formData.append('payload', JSON.stringify(parsedPayload))
            if (formState.data.fields['profile_to']) formData.append('profile_to', formState.data.fields['profile_to'].id)
            if (formState.data.fields['project']) formData.append('project', formState.data.fields['project'].id)
            if (formState.data.fields['status']) formData.append('status', formState.data.fields['status'].id)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    dispatch(reduxFormSetEditingSite(false))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: false,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    if (onRefresh) onRefresh()
                    if (another) {
                        setInitialState()
                    } else {
                        onCloseModal({
                            id: response.data.id,
                            name: response.data.name,
                        })
                    }
                    dispatch(reduxFormSetRefresh('refreshForm', response.data.id))
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        data: {
                            ...formState.data,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'CustomFormModal',
                        dispatch,
                        error,
                        formFields: JSON.stringify(object),
                        formFieldsToIgnore: ['email'],
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFormModal',
                'handleSubmit',
            ))
        }
    }

    function onCloseModal(value?: any) {
        try {
            if (onCloseHelperModal) {
                onCloseHelperModal(false, value)
            } else {
                dispatch(reduxModalMainFormHide())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomFormModal',
                'onCloseModal',
            ))
        }
    }

    function setInitialState() {
        setFormState({
            ...formStateProps,
            data: {
                ...formStateProps.data,
                fields: defaultFields,
            },
        })
    }

    return (
        <React.Fragment>
            <DialogTitle className='mo-dialog-header'>
                <div className='mo-dialog-header-info-wrap'>
                    <span className='mo-dialog-header-info'>{`Bulk creation for ${contentType}`}</span>
                </div>
                <span className='mo-dialog-header-title'>{`Bulk creation for ${contentType}`}</span>
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={onCloseModal}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <FormLabel
                    label='Project'
                    name='project'
                />
                <SelectListAsyncInputV2
                    apiUrl={'staff/select/staffproject/'}
                    clearable={false}
                    // disabled={isDisabled || disabled}
                    name={'project'}
                    onChange={handleInputChange}
                    value={object['project'] || ''}
                />
                <ErrorHelper error={formState.data.errors?.['project']} />
                <FormLabel
                    label='Status'
                    name='status'
                />
                <SelectListInputV2
                    apiUrl={'staff/select/taskstatus/'}
                    clearable
                    // disabled={isDisabled || disabled}
                    // layout={fieldsOptionsSettings.selectLayout}
                    // multi={fieldType === 'ManyToManyField'}
                    name={'status'}
                    onChange={handleInputChange}
                    // options={selectChoices}
                    profileObject={object.profile}
                    value={object['status'] || ''}
                />
                <FormLabel
                    label='Assignee'
                    name='profile_to'
                />
                <SelectListInputV2
                    apiUrl={'userprofile/select/profile/?paginate=false&id__in=1,2,3,16,191970'}
                    clearable
                    // disabled={isDisabled || disabled}
                    // layout={fieldsOptionsSettings.selectLayout}
                    // multi={fieldType === 'ManyToManyField'}
                    name={'profile_to'}
                    onChange={handleInputChange}
                    // options={selectChoices}
                    profileObject={object.profile}
                    value={object['profile_to'] || ''}
                />
                <ErrorHelper error={formState.data.errors?.['status']} />
                <FormLabel
                    label='Data'
                    name='payload'
                />
                <TextAreaInputV2
                    // disabled={disabled}
                    name='payload'
                    onChange={handleInputChange}
                    value={object['payload'] || ''}
                />
                <ErrorHelper error={formState.data.errors?.['payload']} />
                <p>PROMPTS FOR CHATGPT</p>
                <p style={{
                    whiteSpace: 'pre-line',
                }}>
                    <Copy text={`${reduxText[9818]}\n${object['text'] || ''}`} />
                </p>
                <FormLabel
                    label='Text'
                    name='text'
                />
                <TextAreaInputV2
                    // disabled={disabled}
                    name='text'
                    onChange={handleInputChange}
                    value={object['text'] || ''}
                />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                {/* Start cancel/close button */}
                <Button
                    edit={false}
                    expand='full'
                    fill='outline'
                    onClick={onCloseModal}
                    text={helpers.buttonDisabled ? reduxText[4742] : reduxText[4519]}
                />
                {/* End cancel/close button */}
                {/* Start save and add another button */}
                <Button
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    fill='outline'
                    onClick={() => handleRequired(true)}
                    text={reduxText[8824]}
                />
                {/* End save and add another button */}
                {/* Start save */}
                <Button
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    fill='outline'
                    onClick={() => handleRequired(false)}
                    text={reduxText[4520]}
                />
                {/* End save */}
            </DialogActions>
            <Alert
                buttons={[{ text: reduxText[5614] }]}
                header={reduxText[5585]}
                isOpen={Boolean(helpers.mainError)}
                message={`${helpers.mainError}${helpers.mainError === 'Network Error' ? reduxText[4577] : ''}`}
                onDidDismiss={() => setHelpers({
                    ...helpers,
                    mainError: '',
                })}
            />
            {helpers.isSaving && (
                <Loader
                    isOpen
                    message={helpers.isSaving ? reduxText[4525] : ''}
                />
            )}
        </React.Fragment>
    )
})
