// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_adhesion_pricing,
} from 'data'

// serializers
import {
    EventPricingDetailFormSerializer,
    EventPricingDetailFormSerializerError,
} from 'serializers/web'

// props
type AdhesionPricingEventPricingInputProps = {
    errors: EventPricingDetailFormSerializerError | undefined
    fields: EventPricingDetailFormSerializer
    onChange: any
}

// main
export const AdhesionPricingEventPricingInput: React.FC<AdhesionPricingEventPricingInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!fields.event?.profile) return null

    return (
        <SelectListInput
            apiUrl={`${select_url_adhesion_pricing}?profile_id=${fields.event.profile}`}
            clearable
            error={errors?.adhesion_pricing}
            label={reduxText[8570]}
            name='adhesion_pricing'
            onChange={onChange}
            optional
            value={fields.adhesion_pricing || ''}
        />
    )
})
