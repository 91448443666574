export const conditionMainInformationWeb: any = {
    1: [ // Album
        // 'link_url',
    ],
    2: [ // Track
        'link_url',
    ],
    3: [ // Video
        'link_url',
    ],
    4: [ // Serie
        // 'link_url',
    ],
    5: [ // Story
        // 'link_url',
    ],
    6: [ // Work
        // 'link_url',
    ],
    7: [ // Article
        'link_url',
    ],
    8: [ // Dossier
        // 'link_url',
    ],
    9: [ // Mixed
        // 'link_url',
    ],
    18: [ // Post
        'link_url',
    ],
    20: [ // PDF
        // 'link_url',
    ],
}
