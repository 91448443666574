// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    Button,
    IconBlock,
    Loader,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    reduxModalMainFormHide,
} from 'data'

// pages
import {
    CheckboxInputV2,
    SelectListAsyncInputV2,
    TextInputV2,
} from 'pages'

// serializers
import {
    ContentTypeFormTypes,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getApiUrlV2,
    getAxiosHeaders,
    getInputValueV2,
    getMainErrorMessage,
} from 'services'

// props
type SubContentTypeFormProps = {
    contentType: contentTypeModelType
    defaultFields?: any
    onCloseHelperModal?: (e: boolean, value?: any) => void
    onRefresh?: () => void
    viewId: number
}

// main
export const SubContentTypeForm: React.FC<SubContentTypeFormProps> = React.memo(({
    contentType,
    defaultFields,
    onCloseHelperModal,
    onRefresh,
    viewId,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const formStateProps = {
        contentTypeSettings: undefined,
        data: {
            errors: {},
            fields: defaultFields || {},
        },
        fieldsForm: {
            formAttribute: [
                'do_items_mapping',
                'sub_content_type_name',
                'update_view_sub_content_type',
                'update_items_sub_content_type',
            ],
            formFile: [],
            formForeignKey: [
                'sub_content_type_template_id',
            ],
            formJson: [],
            formManyToMany: [],
        },
        fieldsRequired: {
            requiredAttribute: [],
            requiredForeignKey: [
                'sub_content_type_template_id',
            ],
            requiredManyToMany: [],
        },
        structure: {
            panels: [],
        },
    }

    const [formState, setFormState] = useState<ContentTypeFormTypes>(formStateProps)
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const object = formState.data.fields

    function handleInputChange(event: any) {
        try {
            setFormState(getInputValueV2({
                contentType,
                event,
                formState,
                reduxText,
            }))
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                init: true,
                mainError: '',
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SubContentTypeForm',
                'handleInputChange',
            ))
        }
    }

    function handleRequired(another?: boolean) {
        try {
            const result = checkRequiredFields({
                formInfoObject: formState,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit(another)
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    data: {
                        ...formState.data,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SubContentTypeForm',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit(another?: boolean) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SubContentTypeForm handleSubmit')
            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })
            const postUrl = getApiUrlV2('setup/form/subcontenttype/create_from_view/', reduxAuth)
            const formData: any = new FormData()
            formData.append('view_id', viewId)
            if (formState.data.fields['sub_content_type_template_id']) formData.append('sub_content_type_template_id', formState.data.fields['sub_content_type_template_id'].id)
            if (formState.data.fields['sub_content_type_name']) formData.append('sub_content_type_name', formState.data.fields['sub_content_type_name'])
            if (formState.data.fields['update_view_sub_content_type']) formData.append('update_view_sub_content_type', formState.data.fields['update_view_sub_content_type'])
            if (formState.data.fields['update_items_sub_content_type']) formData.append('update_items_sub_content_type', formState.data.fields['update_items_sub_content_type'])
            if (formState.data.fields['do_items_mapping']) formData.append('do_items_mapping', formState.data.fields['do_items_mapping'])
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    dispatch(reduxFormSetEditingSite(false))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: false,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    if (onRefresh) onRefresh()
                    if (another) {
                        setInitialState()
                    } else {
                        onCloseModal({
                            id: response.data.id,
                            name: response.data.name,
                        })
                    }
                    dispatch(reduxFormSetRefresh('refreshForm', response.data.id))
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        data: {
                            ...formState.data,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'SubContentTypeForm',
                        dispatch,
                        error,
                        formFields: JSON.stringify(object),
                        formFieldsToIgnore: ['email'],
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'SubContentTypeForm',
                'handleSubmit',
            ))
        }
    }

    function onCloseModal(value?: any) {
        try {
            if (onCloseHelperModal) {
                onCloseHelperModal(false, value)
            } else {
                dispatch(reduxModalMainFormHide())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SubContentTypeForm',
                'onCloseModal',
            ))
        }
    }

    function setInitialState() {
        setFormState({
            ...formStateProps,
            data: {
                ...formStateProps.data,
                fields: defaultFields,
            },
        })
    }

    return (
        <Dialog
            className='mo-dialog-class'
            classes={{ paper: 'mo-dialog-wrapper fix-height-full fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => onCloseModal(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                <span className='mo-dialog-header-title'>Create sub content type</span>
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={onCloseModal}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <TextInputV2
                    error={formState.data.errors['sub_content_type_name']}
                    label='Name'
                    name='sub_content_type_name'
                    onChange={handleInputChange}
                    value={object['sub_content_type_name'] || ''}
                />
                <SelectListAsyncInputV2
                    apiUrl={`setup/select/subcontenttype/template/?content_type_settings__content_type=${contentTypeData[contentType]}`}
                    clearable
                    error={formState.data.errors['sub_content_type_template_id']}
                    label='Template'
                    name='sub_content_type_template_id'
                    onChange={handleInputChange}
                    value={object['sub_content_type_template_id'] || ''}
                />
                <CheckboxInputV2
                    error={formState.data.errors['update_view_sub_content_type']}
                    label='Update view sub content type'  // TO_TEXT
                    name='update_view_sub_content_type'
                    onChange={handleInputChange}
                    value={object['update_view_sub_content_type'] || false}
                />
                <CheckboxInputV2
                    error={formState.data.errors['update_items_sub_content_type']}
                    label='Update items sub content type'  // TO_TEXT
                    name='update_items_sub_content_type'
                    onChange={handleInputChange}
                    value={object['update_items_sub_content_type'] || false}
                />
                <CheckboxInputV2
                    error={formState.data.errors['do_items_mapping']}
                    label='Do items mapping'  // TO_TEXT
                    name='do_items_mapping'
                    onChange={handleInputChange}
                    value={object['do_items_mapping'] || false}
                />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                {/* Start save */}
                <Button
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    fill='outline'
                    onClick={() => handleRequired(false)}
                    text={reduxText[4520]}
                />
                {/* End save */}
            </DialogActions>
            <Alert
                buttons={[{ text: reduxText[5614] }]}
                header={reduxText[5585]}
                isOpen={Boolean(helpers.mainError)}
                message={`${helpers.mainError}${helpers.mainError === 'Network Error' ? reduxText[4577] : ''}`}
                onDidDismiss={() => setHelpers({
                    ...helpers,
                    mainError: '',
                })}
            />
            {helpers.isSaving && (
                <Loader
                    isOpen
                    message={helpers.isSaving ? reduxText[4525] : ''}
                />
            )}
        </Dialog>
    )
})
