// react components
import {
    isMobileOnly,
} from 'react-device-detect'
import ReactDOM from 'react-dom'
// import { createRoot } from 'react-dom/client'  // need update from leaflet first

import {
    AgendaDuQuartierApp,
    AGSLApp,
    AppForm,
    AppMobile,
    AppWeb,
} from './apps'
import reportWebVitals from './reportWebVitals'
import {
    site_id,
} from './data/web/mo_constant'

// const container = document.getElementById('root')
// const root = createRoot(container!)
if (isMobileOnly) {
    document.body.classList.add('body-mobile')
    // root.render(<AppMobile />)
    if (site_id === 17) {
        document.body.classList.add('agenda-du-quartier')
        ReactDOM.render(<AgendaDuQuartierApp />, document.getElementById('root'))
    } else if (site_id === 18) {
        ReactDOM.render(<AppForm />, document.getElementById('root'))
    } else if (site_id === 19) {
        document.body.classList.add('agsl')
        ReactDOM.render(<AGSLApp />, document.getElementById('root'))
    } else {
        ReactDOM.render(<AppMobile />, document.getElementById('root'))
    }
} else {
    document.body.classList.add('body-web')
    // root.render(<AppWeb />)
    if (site_id === 17) {
        document.body.classList.add('agenda-du-quartier')
        ReactDOM.render(<AgendaDuQuartierApp />, document.getElementById('root'))
    } else if (site_id === 18) {
        ReactDOM.render(<AppForm />, document.getElementById('root'))
    } else if (site_id === 19) {
        document.body.classList.add('agsl')
        ReactDOM.render(<AGSLApp />, document.getElementById('root'))
    } else {
        ReactDOM.render(<AppWeb />, document.getElementById('root'))
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
