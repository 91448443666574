// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    select_url_portfolio_content_source,
} from 'data'

// pages
import {
    SelectListInputV2,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type ContentSourceInputPageContentProps = {
    disabled?: boolean
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    inputObject?: LayoutFieldHelperSerializer
    onHandleInputChange: any
}

// main
export const ContentSourceInputPageContent: React.FC<ContentSourceInputPageContentProps> = React.memo(({
    disabled,
    errorsInline,
    fieldsInline,
    inputObject,
    onHandleInputChange,
}) => {

    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (reduxFormSite.availableContentSources.length === 0) return null

    return (
        <SelectListInputV2
            apiUrl={`${select_url_portfolio_content_source}?layout_id=${fieldsInline.layout?.id}&content_source_ids=${reduxFormSite.availableContentSources.map(val => val.id).join(',')}`}
            clearable={false}
            disabled={disabled}
            error={errorsInline?.content_source}
            helperText={inputObject?.helper_text}
            label={inputObject?.label || reduxText[6315]}
            name='content_source'
            onChange={onHandleInputChange}
            optionsSorted
            value={fieldsInline.content_source || ''}
            v1
        />
    )
})
