// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventParticipantDetailFormSerializer,
    EventParticipantDetailFormSerializerError,
} from 'serializers/web'

// props
type FirstNameEventParticipantInputProps = {
    disabled?: boolean
    errors: EventParticipantDetailFormSerializerError | undefined
    fields: EventParticipantDetailFormSerializer
    onChange: any
}

// main
export const FirstNameEventParticipantInput: React.FC<FirstNameEventParticipantInputProps> = React.memo(({
    disabled,
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            disabled={disabled}
            error={errors?.first_name}
            label={reduxText[6461]}
            name='first_name'
            onChange={onChange}
            value={fields.first_name || ''}
        />
    )
})
