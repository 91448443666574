// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// containers
import {
    DetailListContainerWeb,
} from 'containers'

// data
import {
    api_url_event_detail_short,
    api_url_event_image_list,
    api_url_event_project_list,
    api_url_event_service_list,
    api_url_event_schedule_list,
    api_url_event_team_list,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// props
type MatchParams = {
    id: string
    listId: string
    listStatus: string
}

// main
export const EventDetailListWeb: React.FC = () => {

    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const filterParamsOptions: any = {

        // Team
        'artist': {
            apiUrl: getApiUrl(`${api_url_event_team_list}?event_id=${params.id}&credit_type=1`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'TeamListMediumBlockWeb',
            smallListComponent: 'TeamListSmallBlockWeb',
            title: reduxText[1622],
        },
        'cover': {
            apiUrl: getApiUrl(`${api_url_event_team_list}?event_id=${params.id}&credit_type=6`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'TeamListMediumBlockWeb',
            smallListComponent: 'TeamListSmallBlockWeb',
            title: reduxText[3077],
        },
        'partner': {
            apiUrl: getApiUrl(`${api_url_event_team_list}?event_id=${params.id}&credit_type=4`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'TeamListMediumBlockWeb',
            smallListComponent: 'TeamListSmallBlockWeb',
            title: reduxText[4679],
        },
        'promoter': {
            apiUrl: getApiUrl(`${api_url_event_team_list}?event_id=${params.id}&credit_type=2`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'TeamListMediumBlockWeb',
            smallListComponent: 'TeamListSmallBlockWeb',
            title: reduxText[1624],
        },
        'staff': {
            apiUrl: getApiUrl(`${api_url_event_team_list}?event_id=${params.id}&credit_type=5`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'TeamListMediumBlockWeb',
            smallListComponent: 'TeamListSmallBlockWeb',
            title: reduxText[5406],
        },
        'venue': {
            apiUrl: getApiUrl(`${api_url_event_team_list}?event_id=${params.id}&credit_type=3`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'TeamListMediumBlockWeb',
            smallListComponent: 'TeamListSmallBlockWeb',
            title: reduxText[4905],
        },

        // Other
        'image': {
            apiUrl: getApiUrl(`${api_url_event_image_list}?event_id=${params.id}`, reduxAuth),
            defaultThumbSize: 'medium',
            mediumListComponent: 'ImageListBlockWeb',
            smallListComponent: 'ImageListBlockWeb',
            title: reduxText[1625],
        },
        'project': {
            apiUrl: getApiUrl(`${api_url_event_project_list}?event_id=${params.id}`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'ProjectListMediumBlockWeb',
            smallListComponent: 'ProjectListSmallBlockWeb',
            title: reduxText[3554],
        },
        'service': {
            apiUrl: getApiUrl(`${api_url_event_service_list}?event_id=${params.id}`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'ServiceListMediumBlock',
            smallListComponent: 'ServiceListSmallBlock',
            title: reduxText[7434],
        },
        'schedule': {
            apiUrl: getApiUrl(`${api_url_event_schedule_list}?event_id=${params.id}`, reduxAuth),
            defaultThumbSize: 'small',
            mediumListComponent: 'ScheduleListBlockWeb',
            smallListComponent: 'ScheduleListBlockWeb',
            title: reduxText[4894],
        },

    }

    return (
        <DetailListContainerWeb
            filterParamsOptions={filterParamsOptions}
            listId={params.listId}
            listStatus={params.listStatus}
            model='event'
            shortUrl={api_url_event_detail_short}
            slugOrId={params.id}
        />
    )
}
