// react components
import React, {
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useLocation,
} from 'react-router-dom'

// components
import {
    BackgroundImageHelper,
} from 'components'

// customers
import {
    AGSLLoginBlock,
    AGSLPasswordResetBlock,
    AGSLSignupBlock,
} from 'customers'

// data
import {
    defaultReduxState,
    view_url_admin,
} from 'data'

// services
import {
    parseQuery,
} from 'services'

// main
export const AGSLAuthSignupPage: React.FC = () => {

    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [email, setEmail] = useState(parseQuery(location.search)?.email || '')
    const [panel, setPanel] = useState<'login' | 'password-reset' | 'signup'>('login')

    if (reduxAuth?.authenticated) {
        if (reduxAuth.afterAuthUrl) {
            return <Redirect to={reduxAuth.afterAuthUrl} />
        }
        return <Redirect to={`${view_url_admin}${reduxAuth.settings?.active_profile?.id}/`} />
    }

    return (
        <IonPage className='login-page-web navbar-pt'>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='lpw-content'>
                    <div className='lpw-left auth-global-block-web'>
                        {panel === 'login' && (
                            <AGSLLoginBlock
                                email={email}
                                setEmail={setEmail}
                                setPanel={setPanel}
                            />
                        )}
                        {panel === 'password-reset' && (
                            <AGSLPasswordResetBlock
                                email={email}
                                setPanel={setPanel}
                            />
                        )}
                        {panel === 'signup' && (
                            <AGSLSignupBlock
                                email={email}
                                setPanel={setPanel}
                            />
                        )}
                    </div>
                    <BackgroundImageHelper
                        className='lpw-right'
                        dominant_color={undefined}
                        src='https://klhkpm8h.tinifycdn.com/resizes/2024/08/06/09/21/27/b29047cc-f314-41d0-b651-9b90b83c67b6.jpg'
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}
