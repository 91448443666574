// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    LinkHelper,
    Loader,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    groupingIdsSite,
    reduxFormSetHoveredBlockIdSite,
    reduxFormSetSectionIdSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    EditButtonMixedMediaContentChildren,
    EditButtonMixedMediaContent,
    TemplateBlockNormalMixedMediaContent,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'
import {
    ProjectFormState,
} from 'serializers/web'

// services
import {
    BuilderService,
    handleMoveContent,
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    panelValue: string
}

type TemplateBlockInceptionMixedMediaContentProps = {
    contentType: contentTypeModelType
    isInList: boolean
    mainParentId?: number
    mother: ProjectFormState['mother']
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    noAllowDrop?: boolean
    object: PortfolioPageContentListSiteSerializer
    parentStyles?: any
    setTabValue?: React.Dispatch<string>
}

// main
export const TemplateBlockInceptionMixedMediaContent: React.FC<TemplateBlockInceptionMixedMediaContentProps> = React.memo(({
    contentType,
    isInList,
    mainParentId,
    mother,
    newMixedMediaContent,
    newMixedMediaContentType,
    noAllowDrop,
    object,
    parentStyles,
    setTabValue,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isCollapsed, setIsCollapsed] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    useEffect(() => {
        setIsCollapsed(reduxFormSite.sectionCollapse)
    }, [
        reduxFormSite.sectionCollapse,
    ])

    function handleCssClassHover(mousedirection: 'enter' | 'leave') {
        try {
            if (mousedirection === 'enter') {
                dispatch(reduxFormSetHoveredBlockIdSite(object.id))
            } else {
                dispatch(reduxFormSetHoveredBlockIdSite(undefined))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TemplateBlockInceptionMixedMediaContent',
                'handleCssClassHover',
            ))
        }
    }

    function handleDropDone(position: 'after' | 'before' | 'inside' | undefined) {
        try {
            handleMoveContent({
                component: 'TemplateBlockInceptionMixedMediaContent',
                dispatch,
                mixedMedia: true,
                object,
                paramsId: params.id,
                position,
                reduxAuth,
                setIsLoading,
                setIsPopoverOpen,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TemplateBlockInceptionMixedMediaContent',
                'handleDropDone',
            ))
        }
    }

    function handleDropDragOver(e: any,) {
        e.stopPropagation()
        e.preventDefault()
    }

    const toMoveObject = BuilderService.moveGetContentObject()
    const isDraggable = toMoveObject && (toMoveObject.id === object.id)
    const allowDrop = !isDraggable && !noAllowDrop

    return (
        <div
            id={Number(params.panelValue) === object.id ? 'activeNavElement' : ''}
            className='template-block-inception-mixed-media-content-web'
            draggable={isDraggable}
        >
            <div
                className={`tbimmcw-info-wrap ${Number(params.panelValue) === object.id ? 'active' : ''}`}
                onDragEnd={() => BuilderService.moveDeleteContentObject()}
                onDragOver={!noAllowDrop ? (e) => handleDropDragOver(e) : undefined}
                onDragStart={() => BuilderService.moveSetContentObject(object, contentTypeData['mixedmediacontent'])}
                onDrop={!noAllowDrop ? () => handleDropDone('inside') : undefined}
                onMouseEnter={() => handleCssClassHover('enter')}
                onMouseLeave={() => handleCssClassHover('leave')}
            >
                <i
                    className={`tbimmcw-expand mo-new-icon-caret-right-solid${isCollapsed ? '' : ' open'}${(object.layout_edit?.name !== 'Symbol' && object.content?.length > 0) ? '' : ' hidden'}`}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                />
                <LinkHelper
                    className='tbimmcw-info'
                    edit={false}
                    onClick={() => {
                        if (setTabValue) setTabValue('styles')
                        dispatch(reduxFormSetSectionIdSite(mainParentId || object.id))
                        if (Number(params.panelValue) === object.id) scrollToSection(params.panelValue, 0)
                    }}
                    to={newMixedMediaContent ? `/form-mixed-media/${newMixedMediaContentType}/${mother.fields?.id}/${object.id}/` : `${mother.formUrl}${mother.fields?.id}/mixed-media-content/${object.id}/`}
                >
                    <div className='tbimmcw-name-wrap'>
                        <i className={`tbimmcw-icon mo-new-icon-layout-${object.layout_edit?.id}${mainParentId ? '' : (object.layout_edit?.id === 780 ? '-s' : '')}`} />
                        <span className='tbimmcw-name'>{object.layout_edit?.name}{object.layout_edit?.active ? '' : '(O)'}</span>
                        {!isInList && object.layout_edit?.content_source && (
                            <span className='tbimmcw-content-source'>{object.layout_edit.content_source}{object.layout_edit.module_view ? ` - ${object.layout_edit.module_view}` : ''}</span>
                        )}
                        {object.action && (
                            <span className='tbimmcw-link'>{object.action}</span>
                        )}
                    </div>
                    {object.auth_type !== 'n' && (
                        <p className='tbimmcw-auth'>{object.layout_edit?.auth_type}</p>
                    )}
                    {object.layout_edit?.field_text?.name && (
                        <p className='tbimmcw-text mo-clamp-web1'>{object.layout_edit.field_text.name}</p>
                    )}
                    {!object.active && (
                        <p className='tbimmcw-content-inactive'>{reduxText[5616]}</p>
                    )}
                </LinkHelper>
                <div className='tbimmcw-actions-wrap'>
                    <div className='tbimmcw-actions'>
                        {mainParentId && (
                            <EditButtonMixedMediaContentChildren
                                allowDrop={Boolean(allowDrop)}
                                contentType={contentType}
                                isInTree
                                isPopoverOpen={isPopoverOpen}
                                mainParentId={mainParentId}
                                mother={mother}
                                newMixedMediaContent={newMixedMediaContent}
                                newMixedMediaContentType={newMixedMediaContentType}
                                object={object}
                                parentStyles={parentStyles}
                                setIsPopoverOpen={setIsPopoverOpen}
                            >
                                <button
                                    className='main-icon-block-web'
                                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                >
                                    <i className='main-icon mo-new-icon-ellipsis-h-solid' />
                                </button>
                            </EditButtonMixedMediaContentChildren>
                        )}
                        {!mainParentId && (
                            <EditButtonMixedMediaContent
                                allowDrop
                                contentType={contentType}
                                isPopoverOpen={isPopoverOpen}
                                mother={mother}
                                newMixedMediaContent={newMixedMediaContent}
                                newMixedMediaContentType={newMixedMediaContentType}
                                object={object}
                                setIsPopoverOpen={setIsPopoverOpen}
                            >
                                <button
                                    className='main-icon-block-web'
                                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                >
                                    <i className='main-icon mo-new-icon-ellipsis-h-solid' />
                                </button>
                            </EditButtonMixedMediaContent>
                        )}
                    </div>
                </div>
            </div>
            {object.layout_edit?.name !== 'Symbol' && (
                <div className={`tbimmcw-content-wrap${isCollapsed ? '' : ' open'}`}>
                    {object.content?.map((val: any) => {
                        if (groupingIdsSite.includes(val.layout)) {
                            return (
                                <TemplateBlockInceptionMixedMediaContent
                                    key={val.id}
                                    contentType={contentType}
                                    isInList={isInList || object.is_list!}
                                    mainParentId={mainParentId || object.id}
                                    mother={mother}
                                    newMixedMediaContent={newMixedMediaContent}
                                    newMixedMediaContentType={newMixedMediaContentType}
                                    noAllowDrop={noAllowDrop || isDraggable}
                                    object={val}
                                    parentStyles={parentStyles}
                                    setTabValue={setTabValue}
                                />
                            )
                        }
                        return (
                            <TemplateBlockNormalMixedMediaContent
                                key={val.id}
                                contentType={contentType}
                                isInList={isInList || object.is_list!}
                                mainParentId={mainParentId || object.id}
                                mother={mother}
                                newMixedMediaContent={newMixedMediaContent}
                                newMixedMediaContentType={newMixedMediaContentType}
                                noAllowDrop={noAllowDrop || isDraggable}
                                object={val}
                                parentStyles={parentStyles}
                                setTabValue={setTabValue}
                            />
                        )
                    })}
                </div>
            )}
            {isLoading && (
                <Loader isOpen />
            )}
        </div>
    )
})
