// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    mediumTypeCustomPageIds,
    reduxAuthState,
    reduxFormHelpersState,
    reduxFormSetMixedProfileIdOpen,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    FormMotherStateProps,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// main
export async function getDetailData({
    axiosCancelToken,
    component,
    dispatch,
    formRefresh,
    formState,
    helpers,
    noEmpty,
    objectId,
    reduxAuth,
    setAxiosCancelToken,
    setFormState,
    setHelpers,
    setInitialState,
}: {
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    dispatch: React.Dispatch<any>
    formRefresh: number | undefined
    formState: { mother: FormMotherStateProps }
    helpers: reduxFormHelpersState
    noEmpty: boolean
    objectId: string
    reduxAuth: reduxAuthState
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setFormState: React.Dispatch<any>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    setInitialState: () => void
}) {
    try {
        if (!noEmpty) {
            setInitialState()
        }
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        setAxiosCancelToken(source)

        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getDetailData`)

        if (formRefresh) {
            setInitialState()
        } else if (reduxAuth.settings?.active_profile && objectId && (objectId !== 'new')) {
            if (!noEmpty) {
                setHelpers({
                    ...helpers,
                    errorStatus: undefined,
                    isLoading: true,
                })
            } else {
                setHelpers({
                    ...helpers,
                    errorStatus: undefined,
                })
            }
            const getUrl = `${formState.mother.detailUrl}${objectId}/` // Keep the last '/' for Safari !!!!!!!
            if (process.env.NODE_ENV === 'development') console.log(getUrl, component)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            fields: response.data,
                        }
                    })
                    setHelpers({
                        ...helpers,
                        init: false,
                        isLoading: false,
                    })
                    setAxiosCancelToken(undefined)
                    if (mediumTypeCustomPageIds.includes(response.data.medium_type?.id) || ['blocktemplate', 'pagetemplate'].includes(formState.mother.model) || response.data.is_custom_page) {
                        const fonts = response.data.mixed_media_fonts || []
                        if (response.data.portfolio_main?.fonts) {
                            fonts.push(...response.data.portfolio_main.fonts)
                        }
                        dispatch(reduxFormSetMixedProfileIdOpen(
                            response.data.profile.id,
                            fonts,
                            {
                                ...formState.mother,
                                fields: response.data,
                            },
                        ))
                    }
                })
                .catch((error) => {
                    if (error.response?.status === 403) {
                        setHelpers({
                            ...helpers,
                            errorStatus: error.response.status,
                            isLoading: false,
                        })
                    } else if (error.response?.status === 404 && error.response?.data?.detail) {
                        setHelpers({
                            ...helpers,
                            errorStatus: error.response.status,
                            isLoading: false,
                        })
                    } else {
                        setHelpers({
                            ...helpers,
                            isLoading: false,
                        })
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component,
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'getDetailData',
                        })
                    }
                })
        } else {
            setInitialState()
        }
    } catch (error) {
        setHelpers({
            ...helpers,
            isLoading: false,
        })
        dispatch(reduxModalErrorEventHandler(
            error,
            component,
            'getDetailData',
        ))
    }
}
