// react components
import React, {
    useEffect,
} from 'react'
import { GoogleLogin } from 'react-google-login'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    ErrorHelper,
} from 'components'

// data
import {
    GOOGLE_CLIENT_ID_WEB,
    defaultReduxState,
    reduxAuthGoogle,
    reduxModalErrorEventHandler,
} from 'data'

// props
type GoogleLoginBlockProps = {
    setIsLoading: React.Dispatch<boolean>
    signIn?: boolean
}

// main
export const GoogleLoginBlock: React.FC<GoogleLoginBlockProps> = React.memo(({
    setIsLoading,
    signIn,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (location.hash) {
            const param = 'id_token'
            const url = new URL(window.location.href.replace('#', '?'))
            const id_token = url.searchParams && url.searchParams.get(param)
            if (id_token) (
                // @ts-ignore
                dispatch(reduxAuthGoogle(
                    id_token,
                    id_token, // TODO
                    reduxAuth,
                    dispatch,
                    setIsLoading,
                ))
            )
        }
    }, [location.hash])

    function googleSignIn(response: any) {
        try {
            if (response.error === 'idpiframe_initialization_failed') return
            setIsLoading(true)
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'GoogleLoginBlock',
                'googleSignIn',
                true,
            ))
        }
    }

    return (
        <div className='google-login-block-web'>
            <GoogleLogin
                className='glbw-button'
                clientId={GOOGLE_CLIENT_ID_WEB}
                onFailure={(e) => googleSignIn(e)}
                onSuccess={(e) => googleSignIn(e)}
                uxMode='redirect'
            >
                {signIn ? reduxText[4763] : reduxText[4764]}
            </GoogleLogin>
            {reduxAuth.errors?.googleAuthError && (
                <ErrorHelper error={reduxText[4765]} />
            )}
        </div>
    )
})
