// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios, {
    AxiosRequestConfig,
} from 'axios'
import {
    IonActionSheet,
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonLabel,
    IonModal,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    Alert,
    Button,
    ImageHelper,
    Loader,
    PreviewBlockMobile,
} from 'components'

// data
import {
    defaultReduxState,
    media_url,
    media_version,
    reduxFormHelpersState,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    view_url_profile_detail,
    view_url_profile_detail_old,
} from 'data'

// pages
import {
    NotFoundPage,
} from 'pages'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getMainErrorMessage,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type MatchParams = {
    id: string
    albumId?: string
    panelValue: string
    formType: string
}

type LocationParams = {
    profileNotComplete?: boolean
    profileRefresh?: number
    profileSlug?: string
}

type MainFormContainerMobileProps = {
    children: React.ReactNode
    formState: any
    headerTitle: string
    helpers: reduxFormHelpersState
    reduxAuthFetchSettings?: boolean
    setFormState: React.Dispatch<any>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const MainFormContainerMobile: React.FC<MainFormContainerMobileProps> = React.memo(({
    children,
    formState,
    headerTitle,
    helpers,
    reduxAuthFetchSettings,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxModalplayerPosition = useSelector((state: defaultReduxState) => state.reduxModal.player.playerPosition)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    useEffect(() => {
        if (helpers.scrollToTop) {
            scrollToTop()
            setHelpers({ ...helpers, scrollToTop: false })
        }
    }, [helpers.scrollToTop])

    const contentRef = useRef<HTMLIonContentElement>(null)

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerMobile',
                'scrollToTop',
            ))
        }
    }

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [tabValue, setTabValue] = useState<string | number | undefined>('formView')

    const actionSheetButtons = [
        {
            handler: () => setIsDeleteAlertOpen(true),
            role: 'destructive',
            text: reduxText[2983],
        },
        {
            handler: () => handlePublish(),
            text: reduxText[3517],
        },
        {
            role: 'cancel',
            text: reduxText[4519],
        }
    ]

    const actionSheetButtonsNotActive = [
        {
            handler: () => setIsDeleteAlertOpen(true),
            role: 'destructive',
            text: reduxText[2983],
        },
        {
            role: 'cancel',
            text: reduxText[4519],
        }
    ]

    const deleteAlertButtons = [
        {
            color: 'error',
            handler: () => handleDelete(),
            text: reduxText[1241],
        },
        {
            text: reduxText[1236],
        },
    ]

    async function handleDelete() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MainFormContainerMobile handleDelete')
            setIsDeleting(true)
            const deleteUrl = `${mother.updateUrl}${mother.fields.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    if (reduxAuthFetchSettings) {
                        reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                        history.push('/')
                    } else {
                        history.push(
                            `${view_url_profile_detail_old}${mother.fields.profile.slug}/`,
                            {
                                profileRefresh: Math.random(),
                                profileSlug: mother.fields.profile.slug,
                            },
                        )
                    }
                })
                .catch((error) => {
                    setIsDeleting(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'MainFormContainerMobile',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerMobile',
                'handleDelete',
            ))
        }
    }

    function handleFinish() {
        dispatch(reduxFormSetIsSuccessToastOpen(false))
        if (params.albumId) {
            history.goBack()
        } else {
            history.push(
                mother.model === 'profile' ? (mother.fields?.is_new_profile_ux ? `${view_url_profile_detail}${mother.fields?.id}/` : `${mother.viewUrl}${mother.fields?.slug}/`) : `${mother.viewUrl}${mother.fields.id}/${mother.fields?.slug}/`,
                mother.model === 'profile' ? { profileRefresh: Math.random(), profileSlug: mother.fields?.slug } : undefined,
            )
            if (mother.model === 'profile') {
                dispatch(reduxFormSetRefresh('refreshProfile', mother.fields.id))
            } else {
                dispatch(reduxFormSetRefresh('refreshDetail'))
                dispatch(reduxFormSetRefresh('refreshProfile', mother.fields.profile.id))
            }
        }
    }

    async function handlePublish() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MainFormContainerMobile handlePublish')
            const formData = new FormData()
            formData.append('active', (!mother.fields.active).toString())
            const activeUrl = `${mother.activeUrl}${mother.fields.id}/`
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    handleFinish()
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            fields: {
                                ...formState.mother.fields,
                                active: response.data.active,
                            }
                        }
                    })
                    if (reduxAuthFetchSettings) {
                        reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                    }
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'MainFormContainerMobile',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePublish',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerMobile',
                'handlePublish',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: mother,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    mother: {
                        ...formState.mother,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerMobile',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MainFormContainerMobile handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = mother.createUrl
            if (mother.fields.id) {
                axiosMethod = 'put'
                axiosUrl = `${mother.updateUrl}${mother.fields.id}/`
            }
            const formData: any = getFormData(mother)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    if (mother.fields.meta_image_url) {
                        setFormState({
                            ...formState,
                            mother: {
                                ...formState.mother,
                                errors: {},
                                fields: {
                                    ...formState.mother.fields,
                                    id: response.data.id,
                                    slug: response.data.slug,
                                    cover_image_original: response.data.cover_image,
                                }
                            }
                        })
                    } else {
                        setFormState({
                            ...formState,
                            mother: {
                                ...formState.mother,
                                errors: {},
                                fields: {
                                    ...formState.mother.fields,
                                    id: response.data.id,
                                    slug: response.data.slug,
                                }
                            }
                        })
                    }
                    if (params.albumId) {
                        history.goBack()
                    } else if (mother.fields.id) {
                        if (location.state?.profileNotComplete) {
                            history.replace(`${mother.formUrl}${mother.fields.id}/`)
                        } else {
                            history.goBack()
                        }
                    } else {
                        history.replace(`${mother.formUrl}${response.data.id}/`)
                    }
                    if (reduxAuthFetchSettings) {
                        reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                    }
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'MainFormContainerMobile',
                        dispatch,
                        error,
                        formFields: JSON.stringify(mother.fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainFormContainerMobile',
                'handleSubmit',
            ))
        }
    }

    let headerText = ''
    if (params.panelValue) {
        headerText = headerTitle
    } else {
        headerText = mother.fields?.name || ''
    }

    const formActive = mother.fields?.active

    if (helpers.errorStatus) {
        return <NotFoundPage
            model={mother.model}
            objectId={Number(params.id)}
            statusCode={helpers.errorStatus}
        />
    }

    return (
        <IonPage
            id='formContainer'
            className='main-form-container-mobile'
        >
            <IonHeader
                className='mo-header'
                translucent
            >
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonBackButton
                            defaultHref='/'
                            text=''
                        />
                    </IonButtons>
                    <IonTitle color='primary'>
                        {headerText
                            ? (
                                headerText
                            ) : (
                                <ImageHelper
                                    alt=''
                                    className='logo'
                                    dominant_color={undefined}
                                    src={`${media_url}global/logo-${reduxAuth.settings?.theme === 'light' ? 'black' : 'white'}.svg${media_version}`}
                                />
                            )}
                    </IonTitle>
                </IonToolbar>
                <IonSegment
                    disabled={helpers.isLoading}
                    onIonChange={(e) => setTabValue(e.detail.value)}
                    scrollable
                    value={tabValue}
                >
                    <IonSegmentButton
                        className='mfcm-tab-button'
                        value='formView'
                    >
                        <IonLabel className='mfcm-tab-label'>
                            {reduxText[5583]}
                        </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                        className='mfcm-tab-button'
                        value='prevView'
                    >
                        <IonLabel className='mfcm-tab-label'>
                            {reduxText[5584]}
                        </IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </IonHeader>
            <IonContent
                className='mfcm-form-content'
                forceOverscroll
                fullscreen
                ref={contentRef}
            >
                <IonHeader
                    collapse='condense'
                    translucent
                >
                    <IonToolbar className='mfcm-content-header'>
                        <IonTitle size='large'>
                            {headerText}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                {/* tabValue === 'formView' -- Always in the DOM (bug with image blob otherwise) */}
                {helpers.isLoading
                    ? (
                        <Loader isOpen />
                    ) : (
                        <div className={`mfcm-form-panels${params.panelValue ? '' : ' menu'}${params.formType === 'inline' ? ' inline' : ''}`}>
                            {children}
                            <Alert
                                buttons={[{ text: reduxText[5614] }]}
                                isOpen={helpers.mainError ? true : false}
                                header={reduxText[5585]}
                                message={`${helpers.mainError}${helpers.mainError === 'Network Error' ? reduxText[4577] : ''}`}
                                onDidDismiss={() => setHelpers({
                                    ...helpers,
                                    mainError: '',
                                })}
                            />
                            <Alert
                                buttons={deleteAlertButtons}
                                header={reduxText[1234]}
                                isOpen={isDeleteAlertOpen}
                                message={reduxText[1235]}
                                onDidDismiss={() => setIsDeleteAlertOpen(false)}
                            />
                        </div>
                    )}
                {helpers.isSaving && (
                    <Loader
                        isOpen
                        message={reduxText[4525]}
                    />
                )}
                {isDeleting && (
                    <Loader
                        isOpen
                        message={reduxText[1242]}
                    />
                )}
                <IonModal
                    isOpen={tabValue === 'prevView'}
                    onDidDismiss={() => setTabValue('formView')}
                >
                    <IonHeader
                        className='main-form-container-modal-header'
                        translucent
                    >
                        <IonToolbar>
                            <IonTitle>{reduxText[5584]}</IonTitle>
                            <IonButtons slot='end'>
                                <IonButton
                                    color='secondary'
                                    fill='clear'
                                    onClick={() => setTabValue('formView')}
                                >
                                    {reduxText[4742]}
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    {tabValue === 'prevView' && (
                        <IonContent
                            forceOverscroll
                            fullscreen
                        >
                            <PreviewBlockMobile
                                detailComponent={mother.detailComponent}
                                object={mother.fields}
                            />
                        </IonContent>
                    )}
                </IonModal>
            </IonContent>
            <IonFooter
                className={`button-footer-mobile no-opacity ${(reduxForm.isSuccessToastOpen || reduxModalplayerPosition === 'detail') ? 'form-is-success' : ''}`}
                translucent={false} // otherwise design bug with date picker
            >
                {!helpers.isLoading && params.formType !== 'inline' && (
                    <div className='mfcm-footer'>
                        {/* Start save button */}
                        {params.panelValue && (
                            <Button
                                className='mfcm-footer-button'
                                disabled={helpers.buttonDisabled}
                                edit={false}
                                onClick={handleRequired}
                                text={reduxText[4520]}
                            />
                        )}
                        {/* End save button */}
                        {/* Start go to detail button */}
                        {!params.panelValue && (
                            <Button
                                className='mfcm-footer-button'
                                disabled={!mother.fields?.id}
                                edit={false}
                                onClick={handleFinish}
                                text={(params.albumId || formActive) ? reduxText[4690] : reduxText[5895]}
                            />
                        )}
                        {/* End go to detail button */}
                        {/* Start publish button */}
                        {!params.albumId && !params.panelValue && !formActive && (
                            <Button
                                className='mfcm-footer-button'
                                disabled={!mother.fields?.id}
                                edit={false}
                                onClick={handlePublish}
                                text={formActive ? reduxText[3517] : reduxText[3518]}
                            />
                        )}
                        {/* End publish button */}
                    </div>
                )}
                {params.formType === 'inline' && (
                    <div className='mfcm-footer'>
                        <Button
                            className='mfcm-footer-button'
                            edit={false}
                            onClick={() => setHelpers({ ...helpers, isInlineModalOpen: true })}
                            text={reduxText[2138]}
                        />
                    </div>
                )}
            </IonFooter>
            <IonActionSheet
                buttons={mother.fields?.active ? actionSheetButtons : actionSheetButtonsNotActive}
                isOpen={helpers.isActionSheetOpen || false}
                onDidDismiss={() => setHelpers({ ...helpers, isActionSheetOpen: false })}
            />
        </IonPage>
    )
})
