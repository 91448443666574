// react components
import React from 'react'

// props
type ProfileDetailMenuLeftTabSeparatorProps = {
    text: string
}

// main
export const ProfileDetailMenuLeftTabSeparator: React.FC<ProfileDetailMenuLeftTabSeparatorProps> = React.memo(({
    text,
}) => {
    return (
        <div>
            <div className='pdmltw-tab-item-children-wrap'>
                <span
                    className='pdmltw-tab-item-children separator'
                >
                    {text}
                </span>
            </div>
        </div>
    )
})
