// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DateTimeInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ServiceDetailFormSerializer,
    ServiceDetailFormSerializerError,
} from 'serializers/web'

// props
type TimeStartServiceInputProps = {
    errors: ServiceDetailFormSerializerError | undefined
    fields: ServiceDetailFormSerializer
    onChange: any
}

// main
export const TimeStartServiceInput: React.FC<TimeStartServiceInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <DateTimeInput
            error={errors?.time_start}
            format='time'
            label={reduxText[8544]}
            name='time_start'
            onChange={onChange}
            optional
            value={fields.time_start || null}
        />
    )
})
