// serializers
import {
    ContentTypeFormTypes,
    ContentTypeSettingsCustomTypes,
} from 'serializers/web'

// main
export function getFormDataV2(formSettings: ContentTypeFormTypes, contentTypeSettingsCustom: ContentTypeSettingsCustomTypes) {

    const newFields = formSettings.data.fields

    const formData = new FormData()

    // ForeignKey
    if (Array.isArray(formSettings.fieldsForm.formForeignKey)) {
        formSettings.fieldsForm.formForeignKey.map((field: string) => {
            if (newFields[field]) {
                formData.append(field, newFields[field] ? newFields[field].id : '')
            } else if (newFields.id) {
                formData.append(field, '')
            }
            return false
        })
    }

    // ManyToMany
    if (Array.isArray(formSettings.fieldsForm.formManyToMany)) {
        formSettings.fieldsForm.formManyToMany.map((field: string) => {
            if (field.startsWith('attributions_many_to_many_field_')) {
                if (Array.isArray(newFields[field]) && newFields[field]?.length > 0) {
                    newFields[field].map((value: any) => {
                        formData.append(field, JSON.stringify({
                            id: value.id,
                            attribution_category: value.attribution_category,
                        }))
                        return false
                    })
                } else {
                    formData.append(field, JSON.stringify({
                        id: '__to_delete__',
                        attribution_category: contentTypeSettingsCustom?.form?.fieldsOptions?.[field]?.selectUrlParams?.attribution_categories || '__not_found__',
                    }))
                }
            } else {
                if (Array.isArray(newFields[field])) {
                    newFields[field].map((value: any) => {
                        if (['themes'].includes(field)) {
                            if (!Number(value.id) && value.id.includes('mo-created-option')) {
                                formData.append(`${field}_new`, value.name)
                            }
                            formData.append(field, value.name)
                        } else {
                            formData.append(field, value.id)
                        }
                        return false
                    })
                }
            }
            return false
        })
    }

    // Files
    if (Array.isArray(formSettings.fieldsForm.formFile)) {
        formSettings.fieldsForm.formFile.map((field: string) => {
            if (newFields[field]) {
                if (newFields[field].type) {
                    const filetoUpload = newFields[field]
                    let filetoUploadName = newFields[field].name
                    if (filetoUploadName.length > 100) {
                        filetoUploadName = filetoUploadName.slice(filetoUploadName.length - 100)
                    }
                    formData.append(field, filetoUpload, filetoUploadName)
                } else if (newFields[field] === 'to_delete') {
                    formData.append(`${field}_to_delete`, 'true')
                }
            }
            return false
        })
    }

    // Attributes
    if (Array.isArray(formSettings.fieldsForm.formAttribute)) {
        formSettings.fieldsForm.formAttribute.map((field: string) => {
            if (newFields[field]) {
                formData.append(field, newFields[field])
            } else if (newFields[field] === 0) {
                formData.append(field, newFields[field])
            } else if (newFields[field] === false) {
                formData.append(field, newFields[field])
            } else {
                formData.append(field, '')
            }
            return false
        })
    }

    // Json
    if (Array.isArray(formSettings.fieldsForm.formJson)) {
        formSettings.fieldsForm.formJson.map((field: string) => {
            formData.append(field, newFields[field] ? JSON.stringify(newFields[field]) : JSON.stringify({}))
            return false
        })
    }

    return formData
}
