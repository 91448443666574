// react components
import React, {
    useState,
} from 'react'
import {
    DropzoneComponent,
} from 'react-dropzone-component'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
    ImageCropSquareInput,
    ImageInput,
    MainFormLabel,
    SelectListInput,
    TextAreaInput,
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    mediumTypeConstants,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    select_url_main_medium_type_artwork,
} from 'data'

// pages
import {
    assetRequiredFieldsWeb,
    conditionMainInformationWeb,
} from 'pages'

// serializers
import {
    InlineFormPanelProps,
    ProjectFormState,
} from 'serializers/web'

// services
import {
    mainFormGetMediaData,
} from 'services'

// props
type AssetPanelProps = {
    contentType: contentTypeModelType,
    formState: ProjectFormState
    setFormState: React.Dispatch<ProjectFormState>
}

// main
export const AssetPanel: React.FC<InlineFormPanelProps & AssetPanelProps> = React.memo(({
    contentType,
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [getMetaIsLoading, setGetMetaIsLoading] = useState(false)

    // @ts-ignore
    const asset = formState.asset
    const mother = formState.mother

    let wto: any
    function onHandleInputChange(event: any) {
        try {
            if (event.name === 'medium_type') {
                const toReturn = {
                    ...asset,
                    fields: {
                        ...asset.fields,
                        medium_type: event.value,
                        parent_content_type: contentTypeData[contentType],
                        parent_object_id: mother.fields?.id,
                        profile: mother.fields?.profile,
                    },
                    requiredAllFields: assetRequiredFieldsWeb[event.value.id].requiredAllFields,
                    requiredAttribute: assetRequiredFieldsWeb[event.value.id].requiredAttribute,
                    requiredForeignKey: assetRequiredFieldsWeb[event.value.id].requiredForeignKey,
                    requiredManyToMany: assetRequiredFieldsWeb[event.value.id].requiredManyToMany,
                }
                handleInputChange(event, toReturn)
            } else if (event.name === 'link_url' && !asset?.fields?.skip_data_retrieve) {
                handleInputChange(event, asset)
                clearTimeout(wto)
                wto = setTimeout(() => {
                    mainFormGetMediaData({
                        formInfo: asset,
                        formState,
                        helpers,
                        image_only: false,
                        reduxAuth,
                        reduxText,
                        setFormState,
                        setGetMetaIsLoading,
                        setHelpers,
                        urltoParse: event.value,
                    })
                }, 1000)
            } else {
                handleInputChange(event, asset)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AssetPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = asset.errors
    const fieldsInline = asset.fields

    if (!fieldsInline) return null

    // Extra
    const requiredFields = mother.requiredAllFields
    let myDropzone: any
    function initCallback(dropzone: any) {
        myDropzone = dropzone
    }
    const config = {
        iconFiletypes: ['.gif', '.jpg', '.jpeg', '.png', '.webp'],
        postUrl: asset?.createUrl,
        showFiletypeIcon: true,
    }
    const eventHandlers = {
        init: (e: any) => initCallback(e),
        complete: (file: any) => {
            myDropzone.removeFile(file)
            dispatch(reduxFormSetRefresh('refreshInlineList'))
        }
    }
    const djsConfig = {
        acceptedFiles: 'image/gif,image/jpeg,image/png,image/webp',
        dictDefaultMessage: reduxText[5574],
        headers: reduxAuth.axiosConfig?.headers,
        maxFilesize: 64,
        maxThumbnailFilesize: 64,
        parallelUploads: 1,
        paramName: 'cover_image',
        timeout: null,
        params: {
            medium_type: 6,
            profile: fieldsMother?.profile?.id,
            parent_content_type: contentTypeData[contentType],
            parent_object_id: mother.fields?.id,
        },
    }

    const isDropzone = fieldsInline.medium_type?.id === 6

    return (
        <InlineFormContainerWeb
            contentTypeId={contentTypeData[contentType]}
            filters
            formInfo={asset}
            formState={formState}
            helpers={helpers}
            isDropzone={isDropzone}
            noOrdering
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            <SelectListInput
                apiUrl={`${select_url_main_medium_type_artwork}?single=True`}
                clearable={false}
                error={errorsInline?.medium_type}
                label={reduxText[3228]}
                name='medium_type'
                onChange={onHandleInputChange}
                value={fieldsInline.medium_type || ''}
            />
            {isDropzone && (
                <div className='mo-image-input-web'>
                    <MainFormLabel
                        label={reduxText[1767]}
                        name='multiple-image'
                    />
                    <DropzoneComponent
                        config={config}
                        djsConfig={djsConfig}
                        eventHandlers={eventHandlers}
                    />
                </div>
            )}
            {fieldsInline.medium_type && !isDropzone && (
                <React.Fragment>
                    {conditionMainInformationWeb[fieldsInline.medium_type.id!].includes('link_url') && (
                        <TextAreaInput
                            canSkipDataRetrieve
                            error={errorsInline?.link_url}
                            getMetaIsLoading={getMetaIsLoading}
                            helperText={reduxText[323010]}
                            label={reduxText[3230]}
                            name='link_url'
                            onChange={onHandleInputChange}
                            optional={requiredFields && !requiredFields.includes('link_url')}
                            value={fieldsInline.link_url || ''}
                        />
                    )}
                    {(fieldsInline.medium_type.id === mediumTypeConstants.work) && (
                        <ImageCropInput
                            altButton
                            altValue={fieldsInline.image_alt}
                            doka_output_data={fieldsInline.cover_image_doka_output_data || ''}
                            error={errorsInline?.cover_image}
                            errorAlt={errorsInline?.image_alt}
                            label={(fieldsInline.medium_type.id === mediumTypeConstants.work) ? reduxText[4478] : reduxText[6422]}
                            name='cover_image'
                            onChange={onHandleInputChange}
                            original={fieldsInline.cover_image_original || ''}
                            translation={{
                                contentType: 'project',
                                defaultDetailUrl: asset.detailUrl,
                                defaultLanguage: fieldsInline.translation_default_language,
                                defaultUpdateUrl: asset.updateUrl,
                                objectId: fieldsInline.id,
                                profileId: fieldsMother.profile?.id,
                            }}
                            value={fieldsInline.cover_image || ''}
                        />
                    )}
                    {(fieldsInline.medium_type.id === mediumTypeConstants.pdf) && (
                        <ImageInput
                            error={errorsInline?.pdf_file}
                            label={reduxText[6602]}
                            name='pdf_file'
                            onChange={onHandleInputChange}
                            pdfOk
                            pdfOnly
                            text={reduxText[8302]}
                            translation={{
                                contentType: 'project',
                                defaultDetailUrl: asset.detailUrl,
                                defaultLanguage: fieldsInline.translation_default_language,
                                defaultUpdateUrl: asset.updateUrl,
                                objectId: fieldsInline.id,
                                profileId: fieldsMother.profile?.id,
                            }}
                            value={fieldsInline.pdf_file || ''}
                        />
                    )}
                    <TextInput
                        error={errorsInline?.name}
                        label={reduxText[2931]}
                        name='name'
                        onChange={onHandleInputChange}
                        translation={{
                            contentType: 'project',
                            defaultDetailUrl: asset.detailUrl,
                            defaultLanguage: fieldsInline.translation_default_language,
                            defaultUpdateUrl: asset.updateUrl,
                            objectId: fieldsInline.id,
                            profileId: fieldsMother.profile?.id,
                        }}
                        value={fieldsInline.name || ''}
                    />
                    {(fieldsInline.medium_type.id !== mediumTypeConstants.work) && (
                        <ImageCropInput
                            altButton
                            altValue={fieldsInline.cover_image_alt}
                            doka_output_data={fieldsInline.cover_image_doka_output_data || ''}
                            error={errorsInline?.cover_image}
                            errorAlt={errorsInline?.cover_image_alt}
                            label={(fieldsInline.medium_type.id === mediumTypeConstants.work) ? reduxText[4478] : reduxText[6422]}
                            meta_value={fieldsInline.meta_image_url || ''}
                            name='cover_image'
                            onChange={onHandleInputChange}
                            original={fieldsInline.cover_image_original || ''}
                            translation={{
                                contentType: 'project',
                                defaultDetailUrl: asset.detailUrl,
                                defaultLanguage: fieldsInline.translation_default_language,
                                defaultUpdateUrl: asset.updateUrl,
                                objectId: fieldsInline.id,
                                profileId: fieldsMother.profile?.id,
                            }}
                            value={fieldsInline.cover_image || ''}
                        />
                    )}
                    <ImageCropSquareInput
                        doka_output_data={fieldsInline.cover_image_square_doka_output_data || ''}
                        error={errorsInline?.cover_image_square}
                        label={reduxText[6386]}
                        name='cover_image_square'
                        onChange={onHandleInputChange}
                        original={fieldsInline.cover_image_original || fieldsInline.cover_image || ''}
                        value={fieldsInline.cover_image_square || ''}
                    />
                </React.Fragment>
            )}
        </InlineFormContainerWeb>
    )
})
