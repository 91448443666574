// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    // Link,
    useParams,
} from 'react-router-dom'

// components
import {
    InfiniteScrollHelperWeb,
    Loader,
    MainDetailEditBlockWeb,
} from 'components'

// data
import {
    api_url_profile_detail,
    api_url_profile_home_list,
    defaultReduxState,
    languageObjectSite,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
    reduxModalSliderSetData,
} from 'data'

// pages
import {
    NotFoundPage,
    ProfileDetailBlockWebOld,
    ProfileDetailMenuWeb,
    ProfileHomeListBlockWeb,
} from 'pages'

// serializers
import {
    ProfileDetailSerializer,
    ProfileHomeListSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getInfiniteListWeb,
} from 'services'

// props
type MatchParams = {
    slug: string
}

// main
export const ProfileDetailOldWeb: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxModalplayerPosition = useSelector((state: defaultReduxState) => state.reduxModal.player.playerPosition)

    const [languageId, setLanguageId] = useState<number>()

    useEffect(() => {
        onGetDetailData(true)
    }, [
        languageId,
        params.slug,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    useEffect(() => {
        if (object?.id && reduxForm.refreshId.refreshProfile === object.id) {
            onGetDetailData(true)
        }
    }, [
        reduxForm.refresh.refreshProfile,
    ])

    const contentRef = useRef<HTMLIonContentElement>(null)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<ProfileHomeListSerializer[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [errorStatus, setErrorStatus] = useState<number>()
    const [isDetailLoading, setIsDetailLoading] = useState(true)
    const [modalKeys, setModalKeys] = useState<{
        [key: string]: number,
    }>({})
    const [object, setObject] = useState<ProfileDetailSerializer | undefined>(undefined)

    const scrollToTop = (speed?: number) => {
        try {
            contentRef.current?.scrollToTop(speed || 500)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailOldWeb',
                'scrollToTop',
            ))
        }
    }

    async function onGetDetailData(reset: boolean) {
        try {
            if (!params.slug) {
                setErrorStatus(404)
                return
            } else {
                setErrorStatus(undefined)
            }
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetailOldWeb onGetDetailData')

            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)

            setIsDetailLoading(true)
            let getUrl = getApiUrl(`${api_url_profile_detail}${params.slug}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            if (languageId || languageObjectSite[reduxAuth.settings?.language!]) getUrl += `?language_id=${languageId || languageObjectSite[reduxAuth.settings?.language!]}`
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    scrollToTop(0)
                    setIsDetailLoading(false)
                    setObject(response.data)
                    onGetListData(getApiUrl(`${api_url_profile_home_list}${params.slug}/`, reduxAuth), reset)
                    dispatch(reduxModalSeoData(response.data.seo_data))
                    if (response.data.member_info?.length > 0) {
                        const modalKey = Math.random()
                        dispatch(reduxModalSliderSetData(modalKey, response.data.member_info))
                        setModalKeys({
                            ...modalKeys,
                            member_info: modalKey
                        })
                    }
                })
                .catch((error) => {
                    setIsDetailLoading(false)
                    if (error.response?.status === 403) {
                        setErrorStatus(error.response.status)
                    } else if (error.response?.status === 404) {
                        setErrorStatus(error.response.status)
                    } else {
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component: 'ProfileDetailOldWeb',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onGetDetailData',
                        })
                    }
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailOldWeb',
                'onGetDetailData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'ProfileDetailOldWeb',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailOldWeb',
                'onGteListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailOldWeb',
                'onSearchNext',
            ))
        }
    }

    if (errorStatus) {
        return <NotFoundPage statusCode={errorStatus} />
    }
    return (
        <IonPage className='profile-detail-web navbar-pt'>
            <IonContent
                className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}
                ref={contentRef}
            >
                <div className={`pdw-content ${reduxModalplayerPosition}`}>
                    <div className={`pdw-left mo-hidden-vertical-scrollbar ${reduxModalplayerPosition}${reduxAuth.isWindows}`}>
                        <div className='pdw-header-left-wrap'>
                            {object && (
                                <div className='pdw-header-menu-wrap'>
                                    <ProfileDetailMenuWeb
                                        hiddenPic
                                        object={object}
                                    />
                                </div>
                            )}
                            {object && (
                                <MainDetailEditBlockWeb
                                    componentActionSheet='ProfileActionSheetWeb'
                                    model='profile'
                                    object={object}
                                    refreshData={() => onGetDetailData(true)}
                                />
                            )}
                        </div>
                        {object && (
                            <div className='pdw-list-wrap'>
                                {items.map((item) => {
                                    return (
                                        <ProfileHomeListBlockWeb
                                            key={item.id}
                                            object={item}
                                        />
                                    )
                                })}
                                <InfiniteScrollHelperWeb
                                    active={!disableInfiniteScroll}
                                    hasMore={hasMore}
                                    isLoading={isLoading}
                                    items={items}
                                    onChange={onSearchNext}
                                />
                            </div>
                        )}
                    </div>
                    <div className={`pdw-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows} ${reduxModalplayerPosition}`}>
                        {isDetailLoading && (
                            <Loader isOpen />
                        )}
                        {object && (
                            <React.Fragment>
                                <ProfileDetailBlockWebOld
                                    edit={false}
                                    languageId={languageId}
                                    modalKeys={modalKeys}
                                    object={object}
                                    setLanguageId={setLanguageId}
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
