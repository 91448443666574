// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandlerErrorBoundary,
} from 'services'

// main
export const ErrorEventHandler: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalerrorEventHandler = useSelector((state: defaultReduxState) => state.reduxModal.errorEventHandler)

    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        if (reduxModalerrorEventHandler.error) {
            if (!reduxModalerrorEventHandler.skip) setShowAlert(true)
            if (process.env.NODE_ENV === 'development') console.log(reduxModalerrorEventHandler.error, reduxModalerrorEventHandler.info)
            if (!reduxModalerrorEventHandler.skip) {
                axiosErrorHandlerErrorBoundary(
                    reduxModalerrorEventHandler.error.toString(),
                    reduxAuth,
                    reduxModalerrorEventHandler.component || '',
                    reduxModalerrorEventHandler.info || '',
                )
            }
        }
    }, [reduxModalerrorEventHandler.error])

    function onAlertDismiss() {
        dispatch(reduxModalErrorEventHandler(undefined, '', ''))
        setShowAlert(false)
    }

    if (showAlert) {
        return (
            <Alert
                buttons={[{ text: 'OK' }]}
                header='Oops! Something went wrong' // TO_LATER_TEXT
                isOpen
                isErrorAlert
                onDidDismiss={onAlertDismiss}
            />
        )
    }
    return null
}
