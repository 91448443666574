// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// containers
import {
    DetailPageContainer,
} from 'containers'

// data
import {
    api_url_salesorder_detail,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export const SalesOrderDetail: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    return (
        <DetailPageContainer
            apiUrl={getApiUrl(api_url_salesorder_detail, reduxAuth)}
            app='sales'
            component='SalesOrderDetailBlock'
            componentActionSheet='SalesOrderActionSheet'
            model='salesorder'
        />
    )
}
