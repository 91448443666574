// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    AdherentDetailFormSerializer,
    AdherentDetailFormSerializerError,
} from 'serializers/web'

// props
type EmailAdherentInputProps = {
    disabled?: boolean
    errors: AdherentDetailFormSerializerError | undefined
    fields: AdherentDetailFormSerializer
    onChange: any
}

// main
export const EmailAdherentInput: React.FC<EmailAdherentInputProps> = React.memo(({
    disabled,
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            disabled={disabled}
            error={errors?.email}
            label={reduxText[8562]}
            name='email'
            onChange={onChange}
            type='email'
            value={fields.email || ''}
        />
    )
})
