// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import axios, {
    CancelTokenSource,
} from 'axios'
import moment from 'moment'
import Linkify from 'react-linkify'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    FooterMobile,
    Gallery,
    ImageHelper,
    LinkHelper,
    ListSpinner,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// data
import {
    api_url_profile_about_detail,
    defaultReduxState,
    media_url_social_network,
    media_version,
    reduxModalErrorEventHandler,
    view_url_profile_about,
    view_url_profile_detail_old,
} from 'data'

// serializers
import {
    CityTempSerializer,
    ProfileAboutSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getCleanUrl,
} from 'services'

// props
type MatchParams = {
    slug: string
    listId: string
}

type ProfileDetailAboutMobileProps = {
    edit: boolean
}

// main
export const ProfileDetailAboutMobile: React.FC<ProfileDetailAboutMobileProps> = React.memo(({
    edit,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        onGetAboutData()
    }, [
        params.listId,
        params.slug,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
    ])

    const contentRef = useRef<HTMLIonContentElement>(null)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [object, setObject] = useState<ProfileAboutSerializer>()
    const [isAboutLoading, setIsAboutLoading] = useState(true)

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop(500)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailAboutMobile',
                'scrollToTop',
            ))
        }
    }

    async function onGetAboutData() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetailAboutMobile onGetDetailData')

            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)

            setIsAboutLoading(true)
            const getUrl = getApiUrl(`${api_url_profile_about_detail}${params.slug}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsAboutLoading(false)
                    setObject(response.data)
                })
                .catch((error) => {
                    setIsAboutLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ProfileDetailAboutMobile',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onGetDetailData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailAboutMobile',
                'onGetDetailData',
            ))
        }
    }

    function onListGo() {
        history.push(
            `${view_url_profile_detail_old}${object?.slug}/tabs/${view_url_profile_about}/`,
            {
                profileObject: object,
            },
        )
    }

    if (!object) return null

    // rework country to avoid duplicate flags
    const countryArray: CityTempSerializer[] = []
    if (edit && object.address_json && object.address_json.length > 0) {
        const countryArrayTemp: string[] = []
        object.address_json.map((val) => {
            if (val.countryCode && !countryArrayTemp.includes(val.countryCode)) {
                countryArrayTemp.push(val.countryCode)
                countryArray.push(val)
            }
            return false
        })
    }

    // declare cities
    let cityArray: CityTempSerializer[] = []
    if (edit && object.address_json) {
        cityArray = object.address_json || []
    }

    let additionalInfoCheck = false
    if (
        object.phone_number
        || object.birth_date
        || object.death_date
        || (object.address_json && object.address_json.length)
    ) {
        additionalInfoCheck = true
    }

    let professionalInfoCheck = false
    if (
        (object.languages && object.languages.length)
    ) {
        professionalInfoCheck = true
    }

    let imageBlock = 'profile'
    if (Array.isArray(object.image_list) && object.image_list.length > 1) {
        imageBlock = 'serie'
    } else if (Array.isArray(object.image_list) && object.image_list.length === 1) {
        imageBlock = 'about'
    }

    return (
        <IonPage id='profileDetailAbout'>
            <IonHeader
                className='mo-header'
                translucent
            >
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonBackButton
                            defaultHref='/'
                            text=''
                        />
                    </IonButtons>
                    <IonTitle
                        onClick={() => scrollToTop()}
                    >
                        {reduxText[6485]}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                className='profile-detail-about'
                forceOverscroll
                fullscreen
                ref={contentRef}
            >
                <IonHeader
                    collapse='condense'
                    translucent
                >
                    <IonToolbar>
                        <IonTitle size='large'>{reduxText[6485]}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {isAboutLoading && <ListSpinner isLoading />}
                <div className='pdab-image-block'>
                    {imageBlock === 'profile' && (
                        <React.Fragment>
                            <ImageHelper
                                alt={object.name}
                                className='pdab-image-block-profile'
                                dominant_color={undefined}
                                onClick={() => onListGo()}
                                src={object.get_image_lq}
                            />
                            {object.cover_image_credits && object.cover_image_credits.length > 0 && (
                                <div className='pib-profile-image-credit-wrap'>
                                    <span className='pib-profile-image-credit'>� </span>
                                    {object.cover_image_credits.map((val, i) => (
                                        <React.Fragment key={val.id}>
                                            <LinkHelper
                                                edit={edit}
                                                to={val.member?.get_absolute_url}
                                            >
                                                <span className='pib-profile-image-credit'>{val.member?.name || val.name}</span>
                                            </LinkHelper>
                                            {object.cover_image_credits && (object.cover_image_credits.length > (i + 1)) && (<span className='pib-profile-image-credit'> / </span>)}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {Array.isArray(object.image_list) && imageBlock === 'about' && (
                        <ImageHelper
                            alt={object.name}
                            className='pdab-image-block-about'
                            dominant_color={undefined}
                            onClick={() => onListGo()}
                            src={object.image_list[0].src}
                        />
                    )}
                    {Array.isArray(object.image_list) && imageBlock === 'serie' && (
                        <React.Fragment>
                            <div className='pdab-image-block-serie'>
                                <Gallery
                                    // @ts-ignore
                                    direction='row'
                                    margin={2}
                                    onClick={() => onListGo()}
                                    photos={object.image_list}
                                    targetRowHeight={100}
                                />
                            </div>
                            <Button
                                className='pdab-section-button'
                                edit={false}
                                fill='outline'
                                onClick={() => onListGo()}
                                size='small'
                                text={reduxText[5593]}
                            />
                        </React.Fragment>
                    )}
                </div>
                <div className='pdab-top-info'>
                    <p className='pdab-name'>{object.name}</p>
                    <div className='pdab-metiers'>
                        <span>{object.metiers}</span>
                    </div>
                    <div className='pdab-country'>
                        {edit && (
                            <React.Fragment>
                                {countryArray.map((val, i) => (
                                    <MainCountryFlag
                                        key={i}
                                        edit={edit}
                                        object={val}
                                    />
                                ))}
                                {cityArray.map((val, i) => (
                                    <MainHashtag
                                        key={i}
                                        edit={edit}
                                        label={val.cityName || val.description}
                                    />
                                ))}
                            </React.Fragment>
                        )}
                        {!edit && (
                            <React.Fragment>
                                {object.countries && object.countries.length > 0 && object.countries.map((val) => (
                                    <MainCountryFlag
                                        key={val.id}
                                        edit={edit}
                                        object={val}
                                    />
                                ))}
                                {object.cities && object.cities.length > 0 && object.cities.map((val) => (
                                    <MainHashtag
                                        key={val.id}
                                        edit={edit}
                                        label={val.name}
                                    />
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                    {object.description && (
                        <p className='pdab-description pre-line'>{object.description}</p>
                    )}
                    {object.website_checked && (
                        <LinkHelper
                            edit={edit}
                            className='pdab-website'
                            href={object.website_checked}
                        >
                            {getCleanUrl(object.website_checked)}
                        </LinkHelper>
                    )}
                    {object.eclink_list && object.eclink_list.length > 0 && (
                        <div className='pdab-social-wrap'>
                            {object.eclink_list.map((val) => (
                                <div
                                    key={val.id}
                                    className='pdab-social'
                                >
                                    {val.social_network === 2
                                        ? (
                                            <a
                                                href={`mailto:${val.link_url}`}
                                                rel='noopener noreferrer'
                                                target='_blank'
                                            >
                                                <i className='icon mo-new-icon-envelope-regular' />
                                            </a>
                                        ) : (
                                            <LinkHelper
                                                key={val.id}
                                                edit={edit}
                                                href={val.link_url}
                                                hrefTargetSystem={val.social_network !== 5}
                                            >
                                                <ImageHelper
                                                    alt=''
                                                    className='pdab-social-image'
                                                    dominant_color={undefined}
                                                    src={`${media_url_social_network}${val.social_network}_white.svg${media_version}`}
                                                />
                                            </LinkHelper>
                                        )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='pdab-alfa1'>
                    <div className='pdab-bravo4'>
                        {additionalInfoCheck && (
                            <React.Fragment>
                                <div className='pdab-charlie2'>
                                    <p>{reduxText[4767]}</p>
                                </div>
                                {object.phone_number && (
                                    <div className='pdab-charlie1'>
                                        <p className='pdab-title'>{reduxText[4605]}</p>
                                        <div className='pdab-value'>
                                            <span>{object.phone_number}</span>
                                        </div>
                                    </div>
                                )}
                                {(object.birth_date || object.death_date) && (
                                    <div className='pdab-charlie1'>
                                        {object.birth_date && object.death_date ? (
                                            <p className='pdab-title'>{`${reduxText[3025]} - ${reduxText[3467]}`}</p>
                                        ) : (
                                            <React.Fragment>
                                                {object.birth_date && (
                                                    <p className='pdab-title'>{reduxText[3025]}</p>
                                                )}
                                                {object.death_date && (
                                                    <p className='pdab-title'>{reduxText[3092]}</p>
                                                )}
                                            </React.Fragment>
                                        )}
                                        <div className='pdab-value'>
                                            <span>
                                                {moment(object.birth_date).format('D MMM YYYY')}
                                            </span>
                                            {object.birth_date && object.death_date && (
                                                <span>-</span>
                                            )}
                                            <span>
                                                {object.death_date &&
                                                    moment(object.death_date).format('D MMM YYYY')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {object.address_json && object.address_json.length > 0 && (
                                    <div className='pdab-charlie1'>
                                        <p className='pdab-title'>{reduxText[2260]}</p>
                                        <div className='pdab-value'>
                                            {object.address_json &&
                                                object.address_json.map((val, i) => (
                                                    <span key={i}>{val.description}</span>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        {professionalInfoCheck && (
                            <React.Fragment>
                                <div className='pdab-charlie2'>
                                    <p>{reduxText[4768]}</p>
                                </div>
                                {object.languages && object.languages.length > 0 && (
                                    <div className='pdab-charlie1'>
                                        <p className='pdab-title'>{reduxText[4825]}</p>
                                        <div className='pdab-value'>
                                            {object.languages.map((val) => (
                                                <span key={val.id}>{val.name}</span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    {object.biography && (
                        <div className='pdab-bravo4'>
                            <div className='pdab-charlie2'>
                                <p>{reduxText[6485]}</p>
                            </div>
                            <div className='pdab-bravo3'>
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a target='blank' href={decoratedHref} key={key}>{decoratedText}</a>}>
                                    <p className='pre-line'>{object.biography}</p>
                                </Linkify>
                            </div>
                        </div>
                    )}
                </div>
            </IonContent>
            <FooterMobile />
        </IonPage>
    )
})
