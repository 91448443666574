// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    RefresherEventDetail,
} from '@ionic/core'
import {
    IonAvatar,
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    FooterMobile,
    Gallery,
    ImageHelper,
    InfiniteScrollHelperMobile,
    LinkHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    EventListBigBlockMobile,
    EventListMediumBlockMobile,
    EventListSmallBlockMobile,
    ImageListBlockMobile,
    MaterialListBigBlockMobile,
    MaterialListMediumBlockMobile,
    MaterialListSmallBlockMobile,
    OfferListBigBlockMobile,
    OfferListMediumBlockMobile,
    OfferListSmallBlockMobile,
    ProductListBigBlockMobile,
    ProductListMediumBlockMobile,
    ProductListSmallBlockMobile,
    ProfileListBigBlockMobile,
    ProfileListMediumBlockMobile,
    ProfileListSmallBlockMobile,
    ProjectListBigBlockMobile,
    ProjectListMediumBlockMobile,
    ProjectListSmallBlockMobile,
    ScheduleListBlockMobile,
    TeamListBigBlockMobile,
    TeamListMediumBlockMobile,
    TeamListSmallBlockMobile,
} from 'pages'

// services
import {
    getApiUrl,
    getDetailObject,
    getInfiniteListMobileNew,
    listStateInitialMobile,
    listStatePropsMobile,
} from 'services'

// props
type MatchParams = {
    id: string
    listStatus: string
    slug: string
}

type DetailListContainerMobileProps = {
    filterParamsOptions: any
    model: string
    shortUrl: string
}

// main
export const DetailListContainerMobile: React.FC<DetailListContainerMobileProps> = React.memo(({
    filterParamsOptions,
    model,
    shortUrl,
}) => {

    const history = useHistory()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const contentRef = useRef<HTMLIonContentElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    let listId = location.pathname.split('/')[4]
    let slugOrId = params.id
    if (model === 'profile') {
        listId = location.pathname.split('/')[3]
        slugOrId = params.slug
    }

    const dispatch = useDispatch()

    useEffect(() => {
        onGetDetailData()
    }, [
        params.id,
        listId,
        reduxAuth.apiRootUrl,
        params.slug,
    ])

    useEffect(() => {
        if (filterParamsOptions[listId]) {
            let getUrl = ''
            if (model === 'profile') {
                getUrl = `${filterParamsOptions[listId].apiUrl}${slugOrId}/?block_size=${thumbSize}&list_status=${params.listStatus || 'active'}`
            } else {
                getUrl = `${filterParamsOptions[listId].apiUrl}&block_size=${thumbSize}&list_status=${params.listStatus || 'active'}`
            }
            onGetListData(getUrl, true)
        }
    }, [
        params.listStatus,
        params.id,
        params.slug,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
    ])

    const [detailObject, setDetailObject] = useState<any | undefined>(undefined)
    const [thumbSize, setThumbSize] = useState<string>(filterParamsOptions[listId]?.defaultThumbSize)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsMobile>({
        ...listStateInitialMobile,
        componentString: filterParamsOptions[listId]?.[`${thumbSize}ListComponent`],
        modalKey: Math.random(),
    })

    if (!listId) return null

    // @ts-ignore
    const locationDetailObject = location.state?.[`${model}Object`]

    const components: any = {
        EventListBigBlockMobile,
        EventListMediumBlockMobile,
        EventListSmallBlockMobile,
        ImageListBlockMobile,
        MaterialListBigBlockMobile,
        MaterialListMediumBlockMobile,
        MaterialListSmallBlockMobile,
        OfferListBigBlockMobile,
        OfferListMediumBlockMobile,
        OfferListSmallBlockMobile,
        ProductListBigBlockMobile,
        ProductListMediumBlockMobile,
        ProductListSmallBlockMobile,
        ProfileListBigBlockMobile,
        ProfileListMediumBlockMobile,
        ProfileListSmallBlockMobile,
        ProjectListBigBlockMobile,
        ProjectListMediumBlockMobile,
        ProjectListSmallBlockMobile,
        ScheduleListBlockMobile,
        TeamListBigBlockMobile,
        TeamListMediumBlockMobile,
        TeamListSmallBlockMobile,
    }

    if (!filterParamsOptions[listId]) return null

    const ComponentName = components[listState.componentString]

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${shortUrl}${slugOrId}/`, reduxAuth),
                component: 'DetailListContainerMobile',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerMobile',
                'onGetListData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        refreshEvent?: CustomEvent<RefresherEventDetail>,
    ) {
        try {
            getInfiniteListMobileNew(
                apiUrl,
                reduxAuth,
                listState,
                setListState,
                axiosCancelToken,
                setAxiosCancelToken,
                dispatch,
                'DetailListContainerMobile',
                changingView,
                refreshEvent,
                filterParamsOptions[listId][`${thumbSize}ListComponent`],
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerMobile',
                'onGetListData',
            ))
        }
    }

    function onImageClick(obj: any) {
        history.push(obj.photo.get_absolute_url)
    }

    function onRefresh(event: CustomEvent<RefresherEventDetail>) {
        try {
            let getUrl = ''
            if (model === 'profile') {
                getUrl = `${filterParamsOptions[listId].apiUrl}${slugOrId}/?block_size=${thumbSize}&list_status=${params.listStatus || 'active'}`
            } else {
                getUrl = `${filterParamsOptions[listId].apiUrl}&block_size=${thumbSize}&list_status=${params.listStatus || 'active'}`
            }
            onGetListData(
                getUrl,
                true,
                event,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerMobile',
                'onRefresh',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerMobile',
                'onSearchNext',
            ))
        }
    }

    function onSetThumbSize(value: string) {
        try {
            setThumbSize(value)
            if (!['images', 'event_images'].includes(listId)) {
                let getUrl = ''
                if (model === 'profile') {
                    getUrl = `${filterParamsOptions[listId].apiUrl}${slugOrId}/?block_size=${value}&list_status=${params.listStatus || 'active'}`
                } else {
                    getUrl = `${filterParamsOptions[listId].apiUrl}&block_size=${value}&list_status=${params.listStatus || 'active'}`
                }
                onGetListData(
                    getUrl,
                    true,
                )
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerMobile',
                'onSetThumbSize',
            ))
        }
    }

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop(500)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerMobile',
                'scrollToTop',
            ))
        }
    }

    return (
        <IonPage>
            <IonHeader
                className='mo-header'
                translucent
            >
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonBackButton
                            defaultHref='/'
                            text=''
                        />
                    </IonButtons>
                    <IonTitle
                        onClick={() => scrollToTop()}
                    >
                        {filterParamsOptions[listId].title}
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                className='detail-list-container-mobile'
                forceOverscroll
                fullscreen
                ref={contentRef}
            >
                <IonRefresher
                    onIonRefresh={onRefresh}
                    slot='fixed'
                >
                    <IonRefresherContent />
                </IonRefresher>
                <IonHeader
                    collapse='condense'
                    translucent
                >
                    <IonToolbar>
                        <div className='dlcm-header-wrap'>
                            <LinkHelper
                                className='dlcm-profile-pic'
                                edit={false}
                                onClick={() => locationDetailObject ? history.goBack() : undefined}
                                to={locationDetailObject ? undefined : detailObject?.get_absolute_url}
                            >
                                <IonAvatar className='dlcm-image-link'>
                                    <ImageHelper
                                        alt={detailObject?.name}
                                        className='dlcm-image'
                                        dominant_color={undefined}
                                        lazyMobile={false}
                                        src={detailObject?.get_image_lq}
                                    />
                                </IonAvatar>
                            </LinkHelper>
                            <LinkHelper
                                className='dlcm-profile-name'
                                edit={false}
                                onClick={() => locationDetailObject ? history.goBack() : undefined}
                                to={locationDetailObject ? undefined : detailObject?.get_absolute_url}
                            >
                                <p className='mo-clamp-mobile1'>{detailObject?.name}</p>
                            </LinkHelper>
                            <div className='dlcm-thumb-wrap'>
                                <i
                                    className={`dlcm-size-icon mo-new-icon-gallery${thumbSize === 'small' ? ' active' : ''}`}
                                    onClick={() => onSetThumbSize('small')}
                                />
                                <i
                                    className={`dlcm-size-icon mo-new-icon-list${thumbSize === 'medium' ? ' active' : ''}`}
                                    onClick={() => onSetThumbSize('medium')}
                                />
                                <i
                                    className={`dlcm-size-icon mo-new-icon-expand-solid${thumbSize === 'big' ? ' active' : ''}`}
                                    onClick={() => onSetThumbSize('big')}
                                />
                            </div>
                        </div>
                    </IonToolbar>
                    <IonToolbar>
                        <IonTitle size='large'>{filterParamsOptions[listId].title}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className={`dlcm-wrap ${listId}`}>
                    {(['images', 'event_images'].includes(listId) && listState.items?.length > 0)
                        ? (
                            <Gallery
                                // @ts-ignore
                                columns={thumbSize === 'big' ? 1 : 2}
                                // @ts-ignore
                                onClick={(e, obj) => onImageClick(obj)}
                                direction={thumbSize === 'medium' ? 'row' : 'column'}
                                limitNodeSearch={thumbSize === 'medium' ? 2 : undefined}
                                margin={2}
                                photos={listState.items}
                            />
                        ) : (
                            listState.items?.map((item) => {
                                return (
                                    <ComponentName
                                        key={item.id}
                                        edit={false}
                                        modalKey={listState.modalKey}
                                        object={item}
                                        playerOrigin={`${model}DetailList-${slugOrId}`}
                                    />
                                )
                            })
                        )}
                </div>
                <InfiniteScrollHelperMobile
                    active={!listState.disableInfiniteScroll}
                    hasMore={listState.hasMore}
                    isLoading={listState.isLoading}
                    items={listState.items}
                    onChange={onSearchNext}
                />
            </IonContent>
            <FooterMobile />
        </IonPage>
    )
})
