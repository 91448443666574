// react components
import React from 'react'

// pages
import {
    CheckboxInputV2,
    PhoneNumberInputV2,
    SelectListInputV2,
    TextInputV2,
} from 'pages'

// props
type PickAnOfferBlockStep2Props = {
    errors: any
    fields: any
    handleInputChange: (e: any) => void
}

// main
export const PickAnOfferBlockStep2: React.FC<PickAnOfferBlockStep2Props> = React.memo(({
    errors,
    fields,
    handleInputChange,
}) => {

    return (
        <div className='paob-step2'>
            <TextInputV2
                error={errors?.name}
                label='Nom de votre organisme ou collectivité'
                name='name'
                onChange={handleInputChange}
                type='text'
                value={fields.name ? (fields.name === '-' ? '' : fields.name) : ''}
            />
            <SelectListInputV2
                apiUrl='datapool/select/term/?profile=193057&category=24'
                clearable
                error={errors?.custom_foreign_key_5}
                label='Type d’organisme'
                name='custom_foreign_key_5'
                onChange={handleInputChange}
                profileObject={undefined}
                value={fields.custom_foreign_key_5 || ''}
            />
            {fields.custom_foreign_key_5?.id === 212 && (
                <TextInputV2
                    error={errors?.custom_text_field_4}
                    label='Type d’organisme (autre)'
                    name='custom_text_field_4'
                    onChange={handleInputChange}
                    type='text'
                    value={fields.custom_text_field_4 || ''}
                />
            )}
            <TextInputV2
                error={errors?.address_custom_office_1}
                label='Service ou direction'
                name='address_custom_office_1'
                onChange={handleInputChange}
                type='text'
                value={fields.address_custom_office_1 || ''}
            />
            <SelectListInputV2
                apiUrl='main/select/civility/?profile=193057'
                clearable
                error={errors?.civility}
                label='Civilité'
                name='civility'
                onChange={handleInputChange}
                profileObject={undefined}
                value={fields.civility || ''}
            />
            <TextInputV2
                error={errors?.first_name}
                label='Prénom'
                name='first_name'
                onChange={handleInputChange}
                type='text'
                value={fields.first_name || ''}
            />
            <TextInputV2
                error={errors?.last_name}
                label='Nom'
                name='last_name'
                onChange={handleInputChange}
                type='text'
                value={fields.last_name || ''}
            />
            <TextInputV2
                error={errors?.custom_text_field_5}
                label='Fonction'
                name='custom_text_field_5'
                onChange={handleInputChange}
                type='text'
                value={fields.custom_text_field_5 || ''}
            />
            <TextInputV2
                error={errors?.custom_email_field_1}
                label='Email de facturation'
                name='custom_email_field_1'
                onChange={handleInputChange}
                type='email'
                value={fields.custom_email_field_1 || ''}
            />
            <PhoneNumberInputV2
                error={errors?.phone_number}
                label='Téléphone'
                name='phone_number'
                onChange={handleInputChange}
                value={fields.phone_number || ''}
            />
            <TextInputV2
                error={errors?.address_custom_building_1}
                label='Bâtiment ou résidence, le cas échéant'
                name='address_custom_building_1'
                onChange={handleInputChange}
                type='text'
                value={fields.address_custom_building_1 || ''}
            />
            <TextInputV2
                error={errors?.address_custom_street_address_1}
                label='Numéro et voie (rue, boulevard, place…)'
                name='address_custom_street_address_1'
                onChange={handleInputChange}
                type='text'
                value={fields.address_custom_street_address_1 || ''}
            />
            <TextInputV2
                error={errors?.address_custom_additional_address_info_1}
                label='BP, CS, TSA, lieu-dit… (obligatoire si pas de voie)'
                name='address_custom_additional_address_info_1'
                onChange={handleInputChange}
                type='text'
                value={fields.address_custom_additional_address_info_1 || ''}
            />
            <TextInputV2
                error={errors?.address_custom_postal_code_1}
                label='Code postal'
                name='address_custom_postal_code_1'
                onChange={handleInputChange}
                type='text'
                value={fields.address_custom_postal_code_1 || ''}
            />
            <TextInputV2
                error={errors?.address_custom_city_1}
                label='Ville'
                name='address_custom_city_1'
                onChange={handleInputChange}
                type='text'
                value={fields.address_custom_city_1 || ''}
            />
            <SelectListInputV2
                apiUrl='main/select/country/?profile=193057'
                clearable
                error={errors?.address_custom_country_1}
                label='Pays'
                name='address_custom_country_1'
                onChange={handleInputChange}
                profileObject={undefined}
                value={fields.address_custom_country_1 || ''}
            />
            <CheckboxInputV2
                error={errors?.has_shipping}
                label='En cas de livraison, l’adresse est différente'
                name='has_shipping'
                onChange={handleInputChange}
                value={fields.has_shipping || ''}
            />
            {fields.has_shipping && (
                <>
                    <TextInputV2
                        error={errors?.address_custom_building_2}
                        label='Bâtiment ou résidence de livraison'
                        name='address_custom_building_2'
                        onChange={handleInputChange}
                        type='text'
                        value={fields.address_custom_building_2 || ''}
                    />
                    <TextInputV2
                        error={errors?.address_custom_office_2}
                        label='Service ou direction'
                        name='address_custom_office_2'
                        onChange={handleInputChange}
                        type='text'
                        value={fields.address_custom_office_2 || ''}
                    />
                    <TextInputV2
                        error={errors?.address_custom_recipient_name_2}
                        label='Nom de la personne destinataire'
                        name='address_custom_recipient_name_2'
                        onChange={handleInputChange}
                        type='text'
                        value={fields.address_custom_recipient_name_2 || ''}
                    />
                    <TextInputV2
                        error={errors?.custom_text_field_6}
                        label='Fonction'
                        name='custom_text_field_6'
                        onChange={handleInputChange}
                        type='text'
                        value={fields.custom_text_field_6 || ''}
                    />
                    <TextInputV2
                        error={errors?.address_custom_street_address_2}
                        label='Numéro et voie (rue, boulevard, place…)'
                        name='address_custom_street_address_2'
                        onChange={handleInputChange}
                        type='text'
                        value={fields.address_custom_street_address_2 || ''}
                    />
                    <TextInputV2
                        error={errors?.address_custom_additional_address_info_2}
                        label="Complément d'adresse"
                        name='address_custom_additional_address_info_2'
                        onChange={handleInputChange}
                        type='text'
                        value={fields.address_custom_additional_address_info_2 || ''}
                    />
                    <TextInputV2
                        error={errors?.address_custom_postal_code_2}
                        label='Code postal'
                        name='address_custom_postal_code_2'
                        onChange={handleInputChange}
                        type='text'
                        value={fields.address_custom_postal_code_2 || ''}
                    />
                    <TextInputV2
                        error={errors?.address_custom_city_2}
                        label='Ville'
                        name='address_custom_city_2'
                        onChange={handleInputChange}
                        type='text'
                        value={fields.address_custom_city_2 || ''}
                    />
                </>
            )}
        </div>
    )
})
