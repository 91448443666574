// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    setCookie,
    deleteCookie,
    getCookie,
} from 'services'

// main
export class BuilderService {

    // copy content
    static copyGetContentObject(): PortfolioPageContentListSiteSerializer & { content_type: number, is_multi_language: boolean } | undefined {
        return getCookie('mo_builderContentObject', 'json', true)
    }
    static copySetContentObject(content: PortfolioPageContentListSiteSerializer, contentType: number, isMultiLanguage: boolean) {
        setCookie(
            'mo_builderContentObject',
            {
                ...content,
                content_type: contentType,
                is_multi_language: isMultiLanguage,
            },
            600000,
        )
    }

    // copy style
    static copyGetStyleObject(): PortfolioPageContentListSiteSerializer & { content_type: number } | undefined {
        return getCookie('mo_builderStyleObject', 'json', true)
    }
    static copySetStyleObject(content: PortfolioPageContentListSiteSerializer, contentType: number) {
        setCookie(
            'mo_builderStyleObject',
            {
                ...content,
                content_type: contentType,
            },
            600000,
        )
    }

    // copy style previous
    static copyDeletePreviousStyleObject() {
        deleteCookie('mo_builderStylePreviousObject')
    }
    static copyGetPreviousStyleObject(): PortfolioPageContentListSiteSerializer & { content_type: number } | undefined {
        return getCookie('mo_builderStylePreviousObject', 'json', true)
    }
    static copySetPreviousStyleObject(content: PortfolioPageContentListSiteSerializer, contentType: number) {
        setCookie(
            'mo_builderStylePreviousObject',
            {
                ...content,
                content_type: contentType,
            },
            600000,
        )
    }

    // move content
    static moveDeleteContentObject() {
        deleteCookie('mo_builderMoveObject')
    }
    static moveGetContentObject(): PortfolioPageContentListSiteSerializer & { content_type: number } | undefined {
        return getCookie('mo_builderMoveObject', 'json', true)
    }
    static moveSetContentObject(content: PortfolioPageContentListSiteSerializer, contentType: number) {
        setCookie(
            'mo_builderMoveObject',
            {
                ...content,
                content_type: contentType,
            },
            600000,
        )
    }

    // move content previous
    static moveDeletePreviousContentObject() {
        deleteCookie('mo_builderMovePreviousObject')
    }
    static moveGetPreviousContentObject(): PortfolioPageContentListSiteSerializer & { content_type: number } | undefined {
        return getCookie('mo_builderMovePreviousObject', 'json', true)
    }
    static moveSetPreviousContentObject(content: PortfolioPageContentListSiteSerializer & { content_type: number } | undefined) {
        setCookie(
            'mo_builderMovePreviousObject',
            content,
            600000,
        )
    }
}
