// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    DeleteAlert,
    RatioZarmingHelper,
    Popover,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    MainTeamListFormSerializer,
} from 'serializers/web'

// props
type TeamListFormBlockProps = {
    handleInlineActive: (e: MainTeamListFormSerializer) => void
    handleInlineDelete: (e: MainTeamListFormSerializer) => void
    handleInlineRetrieve: (e: MainTeamListFormSerializer) => void
    object: MainTeamListFormSerializer
}

// main
export const TeamListFormBlock: React.FC<TeamListFormBlockProps> = React.memo(({
    handleInlineActive,
    handleInlineDelete,
    handleInlineRetrieve,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function getMemberStatus(teamObject: any) {
        try {
            let textToReturn = reduxText[5615]
            if (!teamObject.active) {
                textToReturn = reduxText[5616]
            } else if (teamObject.member) {
                if (!teamObject.member.active) {
                    textToReturn = reduxText[5618]
                } else if (teamObject.accepted) {
                    textToReturn = reduxText[5619]
                }
            } else if (!teamObject.member) {
                textToReturn = reduxText[5620]
            }
            return textToReturn
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamListFormBlock',
                'getMemberStatus',
            ))
        }
    }

    const buttons = [
        {
            handler: () => {
                handleInlineRetrieve(object)
                setShowActionPopover(false)
            },
            text: reduxText[2105],
        },
        {
            handler: () => {
                handleInlineActive(object)
                setShowActionPopover(false)
            },
            text: object?.active ? reduxText[5296] : reduxText[5297],
        },
        {
            handler: () => {
                setIsDeleteAlertOpen(true)
                setShowActionPopover(false)
            },
            text: reduxText[2983],
        },
    ]

    return (
        <tr className='main-list-row team-list-form-block'>
            <td>
                <div className='tlfb-name-wrap'>
                    <RatioZarmingHelper
                        borderRadius='50%'
                        className='tlfb-avatar'
                        dominant_color={object.member?.get_dominant_color}
                        edit={false}
                        fontSize={12}
                        height={36}
                        name={object.member?.name || object.name}
                        notZarma={Boolean(object.member?.get_image_xs)}
                        src={object.member?.get_image_xs}
                        to={undefined}
                    />
                    <div className='tlfb-name-div'>
                        <p className='tlfb-name'>{object.member?.name || object.name}</p>
                        <p className={`tlfb-active-status ${!object.active ? 'not-active' : ''}`}>{getMemberStatus(object)}</p>
                    </div>
                </div>
            </td>
            <td>{object.credit_type?.name}</td>
            <td>{object.roles}</td>
            <td className='mlr-action'>
                <Popover
                    buttons={buttons}
                    extraZIndex
                    edit={false}
                    isOpen={showActionPopover}
                    setIsOpen={setShowActionPopover}
                />
            </td>
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={() => handleInlineDelete(object)}
                    objectName={object.member?.name || object.name}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
        </tr>
    )
})
