// react components
import React, {
    useState,
} from 'react'
import {
    SketchPicker,
} from 'react-color'
import {
    useDispatch,
} from 'react-redux'
import reactCSS from 'reactcss'

// styles
import 'react-phone-number-input/style.css'

// components
import {
    IconBlock,
    SelectListInput,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// props
type ColorInputHelperProps = {
    onChange: any
    value: any
}

// helpers
const ColorInputHelper: React.FC<ColorInputHelperProps> = React.memo(({
    onChange,
    value,
}) => {

    const dispatch = useDispatch()

    const [color, setColor] = useState<any>(getColorFormatted(value))
    const [displayColorPicker, setDisplayColorPicker] = useState(false)

    function getColorFormatted(cssFormat: string) {
        const [r, g, b, a] = cssFormat.slice(5, -1).split(',').map(Number)
        return { r, g, b, a }
    }

    function onColorChange(newColor: any) {
        try {
            setColor(newColor.rgb)
            onChange(`rgba(${newColor.rgb.r},${newColor.rgb.g},${newColor.rgb.b},${newColor.rgb.a})`)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StylesExtraInputV2',
                'onInputChange',
            ))
        }
    }

    const styles = reactCSS({
        default: {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: color ? `rgba(${color.r},${color.g},${color.b},${color.a})` : undefined,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: 2,
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    })

    return (
        <div className='styles-extra-input-color'>
            <div
                onClick={() => setDisplayColorPicker(!displayColorPicker)}
                style={styles.swatch}
            >
                <div style={styles.color} />
            </div>
            {displayColorPicker && (
                <div className='seic-picker'>
                    <div
                        className='seic-colorbox'
                        onClick={() => setDisplayColorPicker(false)}
                    />
                    <SketchPicker
                        color={color}
                        onChange={onColorChange}
                    />
                </div>
            )}
        </div>
    )
})

// props
type StylesExtraInputV2Props = {
    name: string
    onChange: any
    value: any
}

// main
export const StylesExtraInputV2: React.FC<StylesExtraInputV2Props> = React.memo(({
    name,
    onChange,
    value,
}) => {

    const dispatch = useDispatch()
    const [newPropString, setNewPropString] = useState('')
    const [showNewInput, setShowNewInput] = useState(false)

    function onInputChange(propertyName: string, propertyValue: any) {
        try {
            onChange({
                name: name,
                value: { ...value, [propertyName]: propertyValue },
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StylesExtraInputV2',
                'onInputChange',
            ))
        }
    }

    function onRemove(propertyName: string) {
        try {
            onChange({
                name: name,
                value: value && Object.entries(value).length > 0 ? (({ [propertyName]: _, ...rest }) => rest)(value) : null,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StylesExtraInputV2',
                'onInputChange',
            ))
        }
    }

    const selectOptions = [
        { id: 'background', name: 'background' },
        { id: 'color', name: 'color' },
    ]

    return (
        <div className=''>
            {value && Object.entries(value).map(val => (
                <div
                    key={val[0]}
                    className='sei-input-item'
                >
                    <div className='sei-input-values'>
                        <p>{val[0]}</p>
                        <ColorInputHelper
                            onChange={(e: any) => onInputChange(val[0], e)}
                            value={val[1]}
                        />
                    </div>
                    <IconBlock
                        edit={false}
                        iconClass='mo-new-icon-minus-circle-solid'
                        onClick={() => onRemove(val[0])}
                    />
                </div>
            ))}
            {showNewInput
                ? (
                    <div className='sei-input-item'>
                        <div className='sei-input-values'>
                            <SelectListInput
                                clearable={false}
                                error={undefined}
                                name=''
                                noMarginBottom
                                onChange={(e: any) => setNewPropString(e.value.id)}
                                options={selectOptions.filter(option => !value?.hasOwnProperty(option.id))}
                                value={undefined}
                            />
                            {newPropString && (
                                <ColorInputHelper
                                    onChange={(e: any) => {
                                        onInputChange(newPropString, e)
                                        setShowNewInput(false)
                                    }}
                                    value=''
                                />
                            )}
                        </div>
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-minus-circle-solid'
                            onClick={() => setShowNewInput(false)}
                        />
                    </div>
                ) : (
                    <div>
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-plus-circle-solid'
                            onClick={() => setShowNewInput(true)}
                        />
                    </div>
                )
            }
        </div>
    )
})
