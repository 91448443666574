// Root URL
// const filterRoot = 'adhesion/filter/adherent/'

export const adherentFilterOptions: any[] = [
    {
        api_url: 'boolean',
        slug: 'is_parent',
        textId: 8614,  // reduxText[8614]
    },
]
