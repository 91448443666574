// react components
import React, {
    useState,
} from 'react'
import {
    IonContent,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    useIonViewWillEnter,
    useIonViewWillLeave,
    IonHeader,
    IonToolbar,
    IonTitle,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxAuthLogout,
    reduxModalErrorEventHandler,
    view_url_about_home,
    view_url_profile_about,
    view_url_profile_detail_old,
} from 'data'

// serializers
import {
    ProfileDetailSerializer,
} from 'serializers/web'

// props
type ProfileDetailMenuMobileProps = {
    object: ProfileDetailSerializer
    setSwitchProfileIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

// main
export const ProfileDetailMenuMobile: React.FC<ProfileDetailMenuMobileProps> = React.memo(({
    object,
    setSwitchProfileIsOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [disabled, setDisabled] = useState(false)
    const isMyProfile = (reduxAuth.settings?.active_profile && reduxAuth.settings.active_profile.id) === object.id

    useIonViewWillEnter(() => {
        setDisabled(false)
    })

    useIonViewWillLeave(() => {
        setDisabled(true)
    })

    function onLogout() {
        try {
            history.push(view_url_about_home)
            dispatch(reduxAuthLogout())
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMenuMobile',
                'onLogout',
            ))
        }
    }

    return (
        <IonMenu
            className='profile-menu'
            contentId='profileDetail'
            disabled={disabled}
            menuId='profileDetail'
            side='end'
            type='overlay'
        >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{reduxText[5912]}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent
                forceOverscroll
                fullscreen
            >
                <IonMenuToggle menu='profileDetail'>
                    <IonList className='pm-choices-list'>
                        {isMyProfile && (
                            <React.Fragment>
                                <IonItemDivider sticky>
                                    <IonLabel>{reduxText[5628]}</IonLabel>
                                </IonItemDivider>
                                <IonItem
                                    button
                                    onClick={() => setSwitchProfileIsOpen(true)}
                                >
                                    <i className='pm-icon mo-new-icon-users-solid' />
                                    <IonLabel>
                                        {reduxText[2665]}
                                    </IonLabel>
                                </IonItem>
                                <IonItem
                                    button
                                    onClick={() => onLogout()}
                                >
                                    <i className='pm-icon mo-new-icon-sign-out-alt-solid' />
                                    <IonLabel>{reduxText[1266]}</IonLabel>
                                </IonItem>
                            </React.Fragment>
                        )}
                        <IonItemDivider sticky>
                            <IonLabel>{reduxText[5629]}</IonLabel>
                        </IonItemDivider>
                        <IonItem
                            button
                            routerLink={`${view_url_profile_detail_old}${object.slug}/tabs/${view_url_profile_about}/`}
                        >
                            <IonLabel>{reduxText[6485]}</IonLabel>
                        </IonItem>
                        {object.tabs?.map((val) => (
                            <IonItem
                                key={val.id}
                                button
                                routerLink={`${view_url_profile_detail_old}${object.slug}/sections/${val.id}/`}
                            >
                                <IonLabel>{val.name}</IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                </IonMenuToggle>
            </IonContent>
        </IonMenu >
    )
})
