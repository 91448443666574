// react components
import React from 'react'
import {
    RefresherEventDetail,
} from '@ionic/core'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    errorMessageToSkip,
    reduxAuthState,
    reduxModalSliderSetData,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

export const listStateInitialMobile = {
    clusterIds: undefined,
    componentString: '',
    disableInfiniteScroll: true,
    hasMore: true,
    ids_to_lahoun: [0],
    isLoading: true,
    items: [],
    nextUrl: '',
}

// types
export interface listStatePropsMobile {
    clusterIds: number[] | undefined
    componentString: string
    disableInfiniteScroll: boolean
    hasMore: boolean
    ids_to_lahoun: number[]
    isLoading: boolean
    items: any[]
    modalKey: number
    nextUrl: string
}

// main
export async function getInfiniteListMobileNew(
    apiUrl: string,
    reduxAuth: reduxAuthState,
    listState: listStatePropsMobile,
    setListState: React.Dispatch<listStatePropsMobile>,
    axiosCancelToken: CancelTokenSource | undefined,
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>,
    dispatch: React.Dispatch<any>,
    component: string,
    changingView: boolean,
    refreshEvent: CustomEvent<RefresherEventDetail> | undefined,
    componentName: string,
    noEmptyItems?: boolean,
    setComponent?: boolean,
) {

    if (!apiUrl) return

    if (changingView) {
        if (noEmptyItems) {
            setListState({
                ...listState,
                disableInfiniteScroll: true,
                hasMore: true,
                isLoading: true,
                nextUrl: '',
            })
        } else {
            setListState({
                ...listState,
                disableInfiniteScroll: true,
                hasMore: true,
                isLoading: true,
                items: [],
                nextUrl: '',
            })
        }
    } else {
        setListState({
            ...listState,
            disableInfiniteScroll: true,
            isLoading: true,
        })
    }

    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getInfiniteListMobileNew`)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    if (process.env.NODE_ENV === 'development') console.log(apiUrl, component)

    const formData: any = new FormData()
    if (listState.clusterIds) {
        formData.append('ids_cluster', listState.clusterIds.join(','))
    }

    axios({
        data: formData,
        headers: refreshAxiosHeaders,
        method: listState.clusterIds ? 'post' : 'get',
        url: apiUrl,
        cancelToken: source.token,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            if (refreshEvent && refreshEvent.detail) {
                refreshEvent.detail.complete()
            }
            const dataToReturn = changingView ? response.data.results : [...listState.items, ...response.data.results]
            setListState({
                ...listState,
                componentString: setComponent ? dataToReturn?.[0]?.component_name : componentName,
                disableInfiniteScroll: response.data.next === null,
                hasMore: response.data.next !== null,
                isLoading: false,
                items: dataToReturn,
                nextUrl: response.data.next,
            })
            dispatch(reduxModalSliderSetData(listState.modalKey, dataToReturn, apiUrl))
            // if (customFunction) customFunction(response.data.results, searchValue, response.data)
        })
        .catch((error) => {
            if (error.message === 'axios canceled') {
                if (process.env.NODE_ENV === 'development') console.log(error)
                return
            }
            if (refreshEvent && refreshEvent.detail) {
                refreshEvent.detail.complete()
            }
            if (errorMessageToSkip.includes(error.message)) {
                setListState({
                    ...listState,
                    disableInfiniteScroll: false,
                    isLoading: false,
                })
            } else if (errorMessageToSkip.includes(error)) {
                setListState({
                    ...listState,
                    disableInfiniteScroll: false,
                    isLoading: false,
                })
            } else {
                setListState({
                    ...listState,
                    disableInfiniteScroll: true,
                    isLoading: false,
                })
            }
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'getInfiniteListMobileNew',
            })
        })
}
