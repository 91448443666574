// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// main
export const ParticipantEventListHeader: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <tr className='main-list-header ParticipantEventListHeader'>
            <th>{reduxText[810]}</th>
            <th>{reduxText[1256]}</th>
            <th>{reduxText[8557]}</th>
            <th>{reduxText[8553]}</th>
            <th>{reduxText[8554]}</th>
        </tr>
    )
}
