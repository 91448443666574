// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    api_url_portfolio_content_list_site,
    api_url_portfolio_detail_id_site,
    api_url_portfolio_page_detail_site,
    reduxAuthState,
    reduxCacheSetPortfolioSite,
    reduxFormSetDataSite,
    reduxModalSetRootUrlSite,
    reduxModalStateSite,
    view_url_portfolio_form,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export async function getPortfolioHubData({  // TODO
    axiosCancelToken,
    component,
    detailId,
    dispatch,
    pageSlug,
    portfolioId,
    reduxAuth,
    reduxModalSite,
    scrollToTop,
    setAxiosCancelToken,
    setIsLoading,
}: {
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    detailId: string | undefined
    dispatch: React.Dispatch<any>
    pageSlug: string | undefined
    portfolioId: number
    reduxAuth: reduxAuthState
    reduxModalSite: reduxModalStateSite
    scrollToTop: any | undefined
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setIsLoading: React.Dispatch<any> | undefined
}) {

    if (setIsLoading) setIsLoading(true)
    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getInfiniteListWebNew`)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    const portfolioApiUrl = getApiUrl(`${api_url_portfolio_detail_id_site}${portfolioId}/?language_id=${reduxModalSite.languageId}`, reduxAuth)
    dispatch(reduxModalSetRootUrlSite(`${view_url_portfolio_form}${portfolioId}/page-container/-/`))
    if (process.env.NODE_ENV === 'development') console.log(portfolioApiUrl, component)

    axios({  // Get Portfolio Data
        cancelToken: source.token,
        headers: refreshAxiosHeaders,
        method: 'get',
        url: portfolioApiUrl,
    })
        .then((portfolioResponse) => {
            if (process.env.NODE_ENV === 'development') console.log(portfolioResponse)
            dispatch(reduxCacheSetPortfolioSite(portfolioApiUrl, portfolioResponse.data))
            dispatch(reduxFormSetDataSite('portfolioObject', portfolioResponse.data))
            if (pageSlug || portfolioResponse.data.home_page_slug) {

                // Get Page Data
                let pageApiUrl = getApiUrl(`${api_url_portfolio_page_detail_site}${pageSlug || portfolioResponse.data.home_page_slug}/?portfolio_id=${portfolioResponse.data.id}&edit=true`, reduxAuth)
                if (detailId) pageApiUrl += `&detail_id=${!isNaN(Number(detailId?.split('-')[0])) ? detailId?.split('-')[0] : detailId}`
                if (portfolioResponse.data.is_multi_language) pageApiUrl += `&language_id=${reduxModalSite.languageId}`
                if (process.env.NODE_ENV === 'development') console.log(pageApiUrl)
                axios({
                    cancelToken: source.token,
                    headers: refreshAxiosHeaders,
                    method: 'get',
                    url: pageApiUrl,
                })
                    .then((pageResponse) => {
                        if (process.env.NODE_ENV === 'development') console.log(pageResponse)
                        dispatch(reduxFormSetDataSite('pageObject', pageResponse.data))
                    })
                    .catch((pageError) => {
                        if (setIsLoading) setIsLoading(false)
                        axiosErrorHandler({
                            apiUrl: pageApiUrl,
                            component,
                            dispatch,
                            error: pageError,
                            reduxAuth,
                            reference: 'getPortfolioHubData1',
                            skipSendError: (pageError.response?.status === 404) && Boolean(pageError.response?.data?.detail),
                        })
                    })

                // Get Page Content Data
                let tempPageContentApiUrl = `${api_url_portfolio_content_list_site}?portfolio_id=${portfolioResponse.data.id}&page_slug=${pageSlug || portfolioResponse.data.home_page_slug}&edit=true`
                // if (['hub-components', 'symbols-page'].includes(pageSlug!)) tempPageContentApiUrl += '&profile_id=182824'
                if (detailId) tempPageContentApiUrl += `&detail_id=${!isNaN(Number(detailId?.split('-')[0])) ? detailId?.split('-')[0] : detailId}`

                let pageContentApiUrl = getApiUrl(tempPageContentApiUrl, reduxAuth)
                if (portfolioResponse.data.is_multi_language) pageContentApiUrl += `&language_id=${reduxModalSite.languageId}`
                if (process.env.NODE_ENV === 'development') console.log(pageContentApiUrl)
                axios({
                    cancelToken: source.token,
                    headers: refreshAxiosHeaders,
                    method: 'get',
                    url: pageContentApiUrl,
                })
                    .then((pageContentResponse) => {
                        if (process.env.NODE_ENV === 'development') console.log(pageContentResponse)
                        dispatch(reduxFormSetDataSite('pageContentItems', pageContentResponse.data.results))
                        if (setIsLoading) setIsLoading(false)
                        if (scrollToTop) scrollToTop()
                    })
                    .catch((pageContentError) => {
                        dispatch(reduxFormSetDataSite('pageContentItems', []))
                        if (setIsLoading) setIsLoading(false)
                        axiosErrorHandler({
                            apiUrl: pageContentApiUrl,
                            component,
                            dispatch,
                            error: pageContentError,
                            reduxAuth,
                            reference: 'getPortfolioHubData2',
                            skipSendError: (pageContentError.response?.status === 404) && Boolean(pageContentError.response?.data?.detail),
                        })
                    })
            } else {
                if (setIsLoading) setIsLoading(false)
            }
        })
        .catch((portfolioError) => {
            if (setIsLoading) setIsLoading(false)
            axiosErrorHandler({
                apiUrl: portfolioApiUrl,
                component,
                dispatch,
                error: portfolioError,
                reduxAuth,
                reference: 'getPortfolioHubData3',
                skipSendError: (portfolioError.response?.status === 404) && Boolean(portfolioError.response?.data?.detail),
            })
        })
}
