// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    SelectAsyncInput,
    SelectListInput,
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_profile,
} from 'data'

// serializers
import {
    EventFormState,
    InlineFormPanelProps,
} from 'serializers/web'

// props
type TeamEventPanelProps = {
    formState: EventFormState
    setFormState: React.Dispatch<EventFormState>
}

// main
export const TeamEventPanel: React.FC<InlineFormPanelProps & TeamEventPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const team = formState.team

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, team)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamEventPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = team.errors
    const fieldsInline = team.fields

    if (!fieldsInline) return null

    // Extra
    const options = [
        { id: 1, name: reduxText[3585] },
        { id: 2, name: reduxText[317] },
        { id: 3, name: reduxText[4905] },
        { id: 4, name: reduxText[4680] },
        { id: 5, name: reduxText[5406] },
        { id: 6, name: reduxText[2947] },
    ]

    return (
        <InlineFormContainerWeb
            formInfo={team}
            formState={formState}
            helpers={helpers}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {fieldsInline.id
                ? (
                    <React.Fragment>
                        {fieldsInline.member
                            ? (
                                <SelectAsyncInput
                                    apiUrl={`${select_url_profile}?credit=${fieldsMother.profile?.id}`}
                                    clearable={false}
                                    disabled
                                    error={errorsInline?.member}
                                    label={reduxText[2977]}
                                    layout='profile'
                                    name='member'
                                    onChange={onHandleInputChange}
                                    team
                                    value={fieldsInline.member || ''}
                                />
                            ) : (
                                <TextInput
                                    error={errorsInline?.name}
                                    label={reduxText[2977]}
                                    name='name'
                                    onChange={onHandleInputChange}
                                    value={fieldsInline.name || ''}
                                />
                            )
                        }
                    </React.Fragment>
                ) : (
                    <SelectAsyncInput
                        apiUrl={`${select_url_profile}?credit=${fieldsMother.profile?.id}`}
                        clearable={false}
                        creatable
                        error={errorsInline?.member}
                        label={reduxText[2977]}
                        layout='profile'
                        name='member'
                        onChange={onHandleInputChange}
                        team
                        value={fieldsInline.member || ''}
                    />
                )
            }
            <SelectListInput
                clearable={false}
                error={errorsInline?.credit_type}
                label={reduxText[5299]}
                name='credit_type'
                onChange={onHandleInputChange}
                optional
                options={options}
                value={fieldsInline.credit_type || ''}
            />
            <TextInput
                error={errorsInline?.roles}
                label={reduxText[2979]}
                name='roles'
                onChange={onHandleInputChange}
                optional
                value={fieldsInline.roles || ''}
            />
        </InlineFormContainerWeb>
    )
})
