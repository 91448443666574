// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
} from 'components'

// data
import {
    defaultReduxState,
    mediumTypeConstants,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
    ProjectFormState,
} from 'serializers/web'

// props
type CoverImageProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    mediumType: MainIdNameSerializer | undefined
    mother: ProjectFormState['mother']
    onChange: any
}

// main
export const CoverImageProjectInput: React.FC<CoverImageProjectInputProps> = React.memo(({
    errors,
    fields,
    mediumType,
    mother,
    onChange,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <ImageCropInput
            altButton
            altValue={fields.cover_image_alt}
            clear={reduxAuth.settings?.user?.id === 1}
            doka_output_data={fields.cover_image_doka_output_data || ''}
            error={errors?.cover_image}
            errorAlt={errors?.cover_image_alt}
            label={(mediumType?.id === mediumTypeConstants.work) ? reduxText[4478] : reduxText[6422]}
            meta_value={fields.meta_image_url || ''}
            name='cover_image'
            onChange={onChange}
            original={fields.cover_image_original || ''}
            translation={{
                contentType: 'project',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            value={fields.cover_image || ''}
        />
    )
})
