// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'

// components
import {
    EcPlayerSite,
    FontLoader,
    LightboxSite,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxModalSetNavbarHeightSite,
    reduxModalSetNavbarWidthSite,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    CartPopUpBlockSite,
    CheckoutResponseSite,
    ContentBlockSite,
    FooterBlockSite,
    NavbarBlockSite,
    SelectionHelperPageContent,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    getGoogleFontsSite,
    getPortfolioHubData,
    getStylesToRetrieve,
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    contentId: string
    detailId: string
    pageSlug: string
    hubTabSlug: string
}

// main
export const PortfolioPreviewHub: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [refreshPreview, setRefreshPreview] = useState<number | undefined>(0)

    const [fontFamily, setFontFamily] = useState<string>()
    const [linkColor, setLinkColor] = useState<string>()
    const [newPageStyles, setNewPageStyles] = useState<{ [key: string]: string | undefined }>({})

    useEffect(() => {
        if (!window.location.pathname.includes(view_url_portfolio_form)) return
        onGetPortfolioData()
        setRefreshPreview(reduxForm.refresh.refreshPreview)
    }, [
        params.detailId,
        params.id,
        params.pageSlug,
        reduxModalSite.apiRootUrl,
    ])

    useEffect(() => {
        if (!window.location.pathname.includes(view_url_portfolio_form)) return
        if ((reduxForm.refreshId.refreshPreview === Number(params.id)) && (reduxForm.refresh.refreshPreview !== refreshPreview)) {
            onGetPortfolioData(true, reduxForm.refreshNoEmpty)
            setRefreshPreview(reduxForm.refresh.refreshPreview)
        }
    }, [
        reduxForm.refresh.refreshPreview,
    ])

    let wto: any

    useEffect(() => {
        setHeight()
        clearTimeout(wto)
        wto = setTimeout(() => {
            setHeight()
        }, 100)
    }, [
        reduxFormSite.pageContentItems,
        reduxModalSite.navbarType,
    ])

    useEffect(() => {
        const tempPageStyles: { [key: string]: string | undefined } = {}
        if (getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.pageObject?.styles, '', 'backgroundImage')) {
            tempPageStyles.backgroundImage = getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.pageObject?.styles, '', 'backgroundImage')
        } else {
            tempPageStyles.background = getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.pageObject?.styles, '', 'background')
        }
        tempPageStyles.color = getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.pageObject?.styles, '', 'color')
        tempPageStyles.padding = getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.pageObject?.styles, '', 'padding')
        setFontFamily(getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.portfolioObject?.styles, '', 'fontFamily'))
        setNewPageStyles(tempPageStyles)
        setLinkColor(getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.pageObject?.styles, '', 'linkColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.portfolioObject?.styles, '', 'linkColor'))
    }, [
        reduxFormSite.pageObject?.styles,
        reduxFormSite.portfolioObject?.styles,
        reduxModalSite,
    ])

    const contentRef = useRef<HTMLDivElement>(null)
    const scrollToTop = () => {
        try {
            const contentNewChild = document.querySelector(`[class^="hpc-selection-helper"]`)
            if (contentNewChild) {
                // @ts-ignore
                scrollIntoView(contentNewChild, {
                    align: {
                        left: 0,
                        leftOffset: 0,
                        top: 0,
                        topOffset: reduxModalSite.navbarHeight,
                    },
                })
                return
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PortfolioPreviewHub',
                'scrollToTop',
            ))
        }
    }

    function setHeight() {
        if (reduxModalSite.navbarType === 'top') {
            // @ts-ignore
            const newNavbarSectionHeight = document.querySelector(`[id^="navbar-section"]`)?.offsetHeight || 0
            if (newNavbarSectionHeight && newNavbarSectionHeight !== reduxModalSite.navbarHeight) {
                dispatch(reduxModalSetNavbarHeightSite(newNavbarSectionHeight))
                return
            }
        } else {
            // @ts-ignore
            const newNavbarSectionWidth = document.querySelector(`[id^="navbar-section"]`)?.offsetWidth || 0
            if (newNavbarSectionWidth && newNavbarSectionWidth !== reduxModalSite.navbarWidth) {
                dispatch(reduxModalSetNavbarWidthSite(newNavbarSectionWidth))
                return
            }
        }
        const newNavbarHeight = document.getElementById('mo-navbar-site-id') ? document.getElementById('mo-navbar-site-id')?.offsetHeight : 0
        if (newNavbarHeight && newNavbarHeight !== reduxModalSite.navbarHeight) {
            dispatch(reduxModalSetNavbarHeightSite(newNavbarHeight))
        }
    }

    function onGetPortfolioData(noScollTop?: boolean, noLoading?: boolean) {
        try {
            if (!params.id) return  // because of strange bug similar to https://api.justtheweb.io/fr/v1/1/portfolio/site/detail/portfolio/id/NaN/?language_id=2
            getPortfolioHubData({
                axiosCancelToken,
                component: 'PortfolioPreviewHub',
                detailId: params.detailId,
                dispatch,
                pageSlug: params.pageSlug,
                portfolioId: Number(params.id),
                reduxAuth,
                reduxModalSite,
                scrollToTop: noScollTop ? undefined : ((params.contentId && params.contentId !== '-') ? () => scrollToSection(params.contentId, (reduxModalSite.navbarType === 'top' ? reduxModalSite.navbarHeight : 0)) : scrollToTop),
                setAxiosCancelToken,
                setIsLoading: noLoading ? undefined : setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'PortfolioPreviewHub',
                'onGetPortfolioData',
            ))
        }
    }

    const activePageContentItems: PortfolioPageContentListSiteSerializer[] = []
    reduxFormSite.pageContentItems?.map((val) => {
        if (val.active) activePageContentItems.push(val)
    })

    const fontFamilies = getGoogleFontsSite(reduxFormSite.portfolioObject?.fonts)

    return (
        <div className={`pfcw-template-preview-wrap hub ${reduxModalSite.deviceType}`}>
            <div className='pfcw-template-preview'>
                <div
                    className='slug-page-site'
                    style={{
                        direction: reduxModalSite.languageId === 19 ? 'rtl' : undefined,
                        fontFamily: fontFamily,
                    }}
                >
                    {reduxFormSite.portfolioObject?.fonts && (
                        <>
                            {fontFamilies.custom?.length > 0 && (
                                <FontLoader
                                    customUrls={fontFamilies.customUrls}
                                    fontFamilies={fontFamilies.custom}
                                    fontProvider='custom'
                                    onActive={() => {
                                        document.body.classList.add('webfonts-loaded')
                                    }}
                                />
                            )}
                            {fontFamilies.google?.length > 0 && (
                                <FontLoader
                                    fontProvider='google'
                                    fontFamilies={fontFamilies.google}
                                    onActive={() => {
                                        document.body.classList.add('webfonts-loaded')
                                    }}
                                />
                            )}
                        </>
                    )}
                    {reduxFormSite.portfolioObject?.navbar && (
                        <NavbarBlockSite
                            linkColor={linkColor}
                            stylesEdit={reduxFormSite.editAutoSave[reduxFormSite.portfolioObject.navbar.id!]?.styles || reduxFormSite.editAutoSaved[reduxFormSite.portfolioObject.navbar.id!]?.styles}
                        />
                    )}
                    <div className='slug-page-content-wrap'>
                        <div
                            ref={contentRef}
                            className={`slug-page-content-div mo-hidden-vertical-scrollbar${reduxAuth.isWindows} ${isLoading ? 'opacity' : 'no-opacity'}`}
                            style={{
                                paddingLeft: `${reduxModalSite.navbarType === 'side' ? reduxModalSite.navbarWidth : 0}px`,
                                paddingTop: `${reduxModalSite.navbarType === 'top' ? (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight) : 0}px`,
                            }}
                        >
                            <div
                                className='slug-page-content'
                                style={{
                                    background: getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.portfolioObject?.styles, '', 'background'),
                                    color: getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.portfolioObject?.styles, '', 'color'),
                                    fontFamily: fontFamily,
                                    // margin: reduxModalSite.isOpen ? '0px' : getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.portfolioObject?.styles, '', 'margin'),
                                    minHeight: `calc(100vh - 45px - 60px - ${reduxModalSite.navbarType === 'top' ? (reduxModalSite.navbarIsTransparent ? 0 : reduxModalSite.navbarHeight) : 0}px)`
                                }}
                            >
                                <div
                                    className='slug-page-content-list'
                                    style={newPageStyles}
                                >
                                    {params.pageSlug !== 'components-page' && activePageContentItems.map((val) => {
                                        return (
                                            <div
                                                key={val.id}
                                                className='hpc-selection-helper'
                                            >
                                                <SelectionHelperPageContent object={val} />
                                                <div
                                                    style={{
                                                        display: ['hub-components', 'symbols-page'].includes(params.pageSlug) ? 'flex' : undefined,
                                                        flexWrap: ['hub-components', 'symbols-page'].includes(params.pageSlug) ? 'wrap' : undefined,
                                                        margin: getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.portfolioObject?.styles, '', 'margin'),
                                                    }}
                                                >
                                                    <ContentBlockSite
                                                        detailId={params.detailId}
                                                        devId={undefined}
                                                        isEditHovered={reduxFormSite.hoveredBlockId === val.id}
                                                        linkColor={linkColor}
                                                        object={val}
                                                        pageSlug={params.pageSlug}
                                                        stylesEdit={reduxFormSite.editAutoSave[val.id!]?.styles || reduxFormSite.editAutoSaved[val.id!]?.styles}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {reduxFormSite.portfolioObject?.is_eshop && ['checkout-cancel', 'checkout-success'].includes(params.pageSlug) && (
                                        <CheckoutResponseSite
                                            hasContent={Boolean(activePageContentItems?.[0])}
                                            pageSlug={params.pageSlug}
                                            pageStyles={newPageStyles}
                                        />
                                    )}
                                </div>
                                {reduxFormSite.portfolioObject?.footer && reduxFormSite.pageObject && reduxFormSite.pageContentItems && (
                                    <FooterBlockSite
                                        linkColor={linkColor}
                                        stylesEdit={reduxFormSite.editAutoSave[reduxFormSite.portfolioObject.footer.id!]?.styles || reduxFormSite.editAutoSaved[reduxFormSite.portfolioObject.footer.id!]?.styles}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <Loader isOpen={isLoading} />
                    {reduxFormSite.portfolioObject?.is_eshop && (
                        <CartPopUpBlockSite />
                    )}
                    <EcPlayerSite />
                    <LightboxSite />
                </div>
            </div>
        </div>
    )
}
