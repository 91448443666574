// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_project_detail,
} from 'data'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// pages
import {
    TeamListMediumBlockMobile,
} from 'pages'

// props
type TeamContentBlockMobileProps = {
    edit: boolean
    object: ProjectDetailSerializer
}

// main
export const TeamContentBlockMobile: React.FC<TeamContentBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (object.team_list && object.team_list.team && object.team_list.team.length > 0) {
        return (
            <div className='team-content-block'>
                <p className='tcb-title'>{reduxText[4917]}</p>
                {object.team_list.team.map((val, i) => (
                    i < 3
                        ? (
                            <TeamListMediumBlockMobile
                                key={val.id}
                                edit={edit}
                                object={val}
                            />
                        ) : (
                            <Button
                                key={val.id}
                                className='pdbm-section-button'
                                edit={edit}
                                fill='outline'
                                size='small'
                                text={reduxText[5606]}
                                to={`${view_url_project_detail}${object.id}/${object.slug}/team/`}
                            />
                        )

                ))}
            </div>
        )
    }
    return null
})
