// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// serializer
import {
    ConversionHelper,
    MainIdNameSerializer,
    TranslationHelper,
} from 'serializers/web'

// props
type TextInputProps = {
    adminOnly?: boolean
    conversion?: ConversionHelper
    conversionDefaultValue?: string | number
    currencies?: MainIdNameSerializer[]
    directionRtl?: boolean
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label?: string
    max?: string
    min?: string
    name: string
    onChange: any
    optional?: boolean
    passwordEye?: boolean
    pattern?: string
    placeholder?: string
    staffOnly?: boolean
    step?: string
    translation?: TranslationHelper
    type?: 'number' | 'time' | 'text' | 'tel' | 'url' | 'email' | 'search' | 'date' | 'password'
    value: string | number
    zeroForEmpty?: boolean
}

// main
export const TextInput: React.FC<TextInputProps> = React.forwardRef((
    {
        adminOnly,
        conversion,
        conversionDefaultValue,
        currencies,
        directionRtl,
        disabled,
        error,
        helperText,
        label,
        max,
        min,
        name,
        onChange,
        optional,
        passwordEye,
        pattern,
        placeholder,
        staffOnly,
        step,
        translation,
        type,
        value,
        zeroForEmpty,
    },
    ref: React.Ref<HTMLInputElement>
) => {

    const dispatch = useDispatch()

    const [showPassword, setShowPassword] = useState(false)

    function onInputChange(targetValue: string | null | undefined) {
        try {
            if (zeroForEmpty && targetValue === '') {
                onChange({
                    name: name,
                    value: 0,
                })
            } else {
                onChange({
                    name: name,
                    value: targetValue,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TextInput',
                'onInputChange',
            ))
        }
    }

    return (
        <div className={`mo-text-input-webnew${disabled ? ' disabled' : ''}`}>
            {(label || translation) && (
                <MainFormLabel
                    adminOnly={adminOnly}
                    conversion={conversion}
                    conversionDefaultValue={conversionDefaultValue}
                    currencies={currencies}
                    formInput='text'
                    helperText={helperText}
                    label={label!}
                    name={name}
                    optional={optional}
                    setShowPassword={passwordEye ? setShowPassword : undefined}
                    showPassword={passwordEye ? showPassword : undefined}
                    staffOnly={staffOnly}
                    translation={translation}
                    translationDefaultValue={value}
                />
            )}
            <input
                ref={ref}
                className={`mo-text-input-web-inputnew${directionRtl ? ' rtl' : ''}`}
                disabled={disabled}
                max={max}
                min={min}
                name={name}
                onChange={(e) => onInputChange(e.target.value)}
                pattern={pattern}
                placeholder={placeholder}
                step={step}
                type={((passwordEye && showPassword) ? 'text' : type) || 'text'}
                value={value}
            />
            <ErrorHelper error={error} />
        </div>
    )
})
