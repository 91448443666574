// react components
import React, {
    useState,
} from 'react'
import {
    IonAvatar,
    IonButton,
    IonFooter,
    IonModal,
    IonToolbar,
} from '@ionic/react'
import {
    isMobileOnly,
} from 'react-device-detect'
import {
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    view_url_about_home,
    view_url_search,
} from 'data'

// components
import {
    ImageHelper,
    MenuFormsMobile,
} from 'components'

// props
type FooterMobileProps = {
    children?: React.ReactNode
    scrollToTop?: any
}

// main
export const FooterMobile: React.FC<FooterMobileProps> = React.memo(({
    children,
    scrollToTop,
}) => {

    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showModal, setShowModal] = useState(false)

    if (!isMobileOnly) return null
    if (!reduxAuth.authenticated) return null

    const profileUrl = reduxAuth.settings?.active_profile?.get_absolute_url
    let dynamicProfileUrl = undefined
    if (location.pathname !== profileUrl) {
        dynamicProfileUrl = profileUrl
    }

    return (
        <IonFooter
            className='mo-footer'
            translucent
        >
            {children && (
                <IonToolbar>{children}</IonToolbar>
            )}
            <IonToolbar>
                <div className='ecf-navigation-footer'>
                    <IonButton
                        className={`lf-link ${(location.pathname === view_url_about_home) ? 'active' : ''}`}
                        onClick={[view_url_about_home].includes(location.pathname) ? scrollToTop : undefined}
                        routerDirection='root'
                        routerLink={[view_url_about_home].includes(location.pathname) ? undefined : view_url_about_home}
                        size='small'
                    >
                        <i className='mo-footer-icon mo-new-icon-home-solid' />
                    </IonButton>
                    <IonButton
                        className={`lf-link ${(location.pathname === view_url_search) ? 'active' : ''}`}
                        onClick={location.pathname === view_url_search ? scrollToTop : undefined}
                        routerLink={location.pathname === view_url_search ? undefined : view_url_search}
                        size='small'
                    >
                        <i className='mo-footer-icon mo-new-icon-search-solid' />
                    </IonButton>
                    <IonButton
                        className={`lf-link ${showModal ? 'active' : ''}`}
                        onClick={() => setShowModal(true)}
                        size='small'
                    >
                        <i className='mo-footer-icon mo-new-icon-plus-circle-solid' />
                    </IonButton>
                    {reduxAuth.settings?.active_profile && (
                        <IonButton
                            className={`lf-link ${(location.pathname === reduxAuth.settings.active_profile.get_absolute_url) ? 'active' : ''}`}
                            onClick={!dynamicProfileUrl && scrollToTop}
                            routerLink={dynamicProfileUrl}
                            size='small'
                        >
                            <IonAvatar className='ecf-profile-thumb'>
                                <ImageHelper
                                    alt={reduxAuth.settings.active_profile.name}
                                    dominant_color={undefined}
                                    src={reduxAuth.settings.active_profile.get_image_xs}
                                />
                            </IonAvatar>
                        </IonButton>
                    )}
                </div>
            </IonToolbar>
            <IonModal
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
            >
                <MenuFormsMobile setShowModal={setShowModal} />
                <div className='button-footer-mobile'>
                    <IonButton
                        color='secondary'
                        expand='block'
                        fill='outline'
                        onClick={() => setShowModal(false)}
                    >
                        {reduxText[4742]}
                    </IonButton>
                </div>
            </IonModal>
        </IonFooter>
    )
})
