// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Popover,
} from 'components'

// data
import {
    api_url_salesorder_action,
    api_url_salesorder_form_sales_shipment,
    api_url_salesorder_form_sales_status,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type SalesOrderActionSheetProps = {
    edit: boolean
    inModal?: boolean
    object: {
        id?: number
    }
    refreshData: () => void
    setShowActionPopover: React.Dispatch<boolean>
    showActionPopover: boolean
}

// main
export const SalesOrderActionSheet: React.FC<SalesOrderActionSheetProps> = React.memo(({
    edit,
    inModal,
    object,
    refreshData,
    setShowActionPopover,
    showActionPopover,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (showActionPopover) getData(object.id!)
    }, [
        object.id,
        showActionPopover,
    ])

    type actionObjectType = {
        can_make_shipment?: boolean
        has_permission?: boolean
        sales_status?: number
    }

    const [buttons, setButtons] = useState<any[]>([])

    async function getData(objectId: number) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SalesOrderActionSheet getData')

            if (!objectId) return
            const getUrl = getApiUrl(`${api_url_salesorder_action}${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!response.data) return
                    const actionObject: actionObjectType = response.data
                    const newButtons = []

                    // Permission
                    if (actionObject.has_permission) {
                        if ([1, 2, 12, 13].includes(actionObject.sales_status!)) {
                            newButtons.push(
                                {
                                    handler: () => {
                                        handleStatusChange(1)
                                        setShowActionPopover(false)
                                    },
                                    text: reduxText[8633],
                                },
                            )
                            newButtons.push(
                                {
                                    handler: () => {
                                        handleStatusChange(13)
                                        setShowActionPopover(false)
                                    },
                                    text: reduxText[8789],
                                },
                            )
                            newButtons.push(
                                {
                                    handler: () => {
                                        handleStatusChange(2)
                                        setShowActionPopover(false)
                                    },
                                    text: reduxText[8632],
                                },
                            )
                            newButtons.push(
                                {
                                    handler: () => {
                                        handleStatusChange(12)
                                        setShowActionPopover(false)
                                    },
                                    text: reduxText[8790],
                                },
                            )
                        }
                        if (actionObject.can_make_shipment) {
                            newButtons.push(
                                {
                                    handler: () => {
                                        handleGenerateLabel()
                                        setShowActionPopover(false)
                                    },
                                    text: reduxText[8758],
                                },
                            )
                        }
                    }

                    setButtons(newButtons)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'SalesOrderActionSheet',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SalesOrderActionSheet',
                'getData',
            ))
        }
    }

    async function handleGenerateLabel() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SalesOrderActionSheet handleGenerateLabel')
            const formData: any = new FormData()
            const postUrl = getApiUrl(`${api_url_salesorder_form_sales_shipment}${object.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    refreshData()
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'SalesOrderActionSheet',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleGenerateLabel',
                        skipSendNonFieldErrors: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SalesOrderActionSheet',
                'handleGenerateLabel',
            ))
        }
    }

    async function handleStatusChange(sales_status_id: number) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SalesOrderActionSheet handleStatusChange')
            const formData: any = new FormData()
            formData.append('sales_status', sales_status_id)
            const putUrl = getApiUrl(`${api_url_salesorder_form_sales_status}${object.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    refreshData()
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'SalesOrderActionSheet',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleStatusChange',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SalesOrderActionSheet',
                'handleStatusChange',
            ))
        }
    }

    return (
        <React.Fragment>
            <Popover
                buttons={buttons}
                edit={edit}
                extraZIndex={inModal}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
            />
        </React.Fragment>
    )
})
