// react components
import React from 'react'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    select_url_main_custom_choice,
} from 'data'

// serializers
import {
    AdherentDetailFormSerializer,
    AdherentDetailFormSerializerError,
} from 'serializers/web'

// props
type CustomMTMCC1AdherentInputProps = {
    errors: AdherentDetailFormSerializerError | undefined
    field_name: string
    fields: AdherentDetailFormSerializer
    label: string
    onChange: any
}

// main
export const CustomMTMCC1AdherentInput: React.FC<CustomMTMCC1AdherentInputProps> = React.memo(({
    errors,
    field_name,
    fields,
    label,
    onChange,
}) => {

    if (!fields.profile?.id) return null

    return (
        <SelectListInput
            apiUrl={`${select_url_main_custom_choice}?profile_id=${fields.profile.id}&field_name=${field_name}`}
            clearable
            multi
            error={errors?.custom_mtm_cc_1}
            label={label}
            name='custom_mtm_cc_1'
            onChange={onChange}
            value={fields.custom_mtm_cc_1 || ''}
        />
    )
})
