// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
    IonButtons,
    IonTitle,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

// components
import {
    Gallery,
    HeaderChildren,
    IconBlock,
    ImageGalleryHelper,
    InfiniteScrollHelperWeb,
    LightboxCustom,
    LinkHelper,
    RatioZarmingHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    EventListMediumBlockWeb,
    EventListSmallBlockWeb,
    ImageListBlockWeb,
    MaterialListMediumBlockWeb,
    MaterialListSmallBlockWeb,
    NotFoundPage,
    OfferListMediumBlockWeb,
    OfferListSmallBlockWeb,
    PortfolioListBlock,
    PressListMediumBlockWeb,
    PressListSmallBlockWeb,
    ProductListMediumBlockWeb,
    ProductListSmallBlockWeb,
    ProfileDetailBlock,
    ProfileDetailBlockWebOld,
    ProfileListMediumBlockWeb,
    ProfileListSmallBlockWeb,
    ProjectListMediumBlockWeb,
    ProjectListSmallBlockWeb,
    ScheduleListBlockWeb,
    ServiceListMediumBlock,
    ServiceListSmallBlock,
    TeamListMediumBlockWeb,
    TeamListSmallBlockWeb,
} from 'pages'

// services
import {
    getApiUrl,
    getDetailObject,
    getInfiniteListWebNew,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// props
type DetailListContainerWebProps = {
    filterParamsOptions: any
    listId: string
    listStatus: string
    model: string
    shortUrl: string
    slugOrId: string
}

// main
export const DetailListContainerWeb: React.FC<DetailListContainerWebProps> = React.memo(({
    filterParamsOptions,
    listId,
    listStatus,
    model,
    shortUrl,
    slugOrId,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        onGetDetailData()
    }, [
        listId,
        slugOrId,
        reduxAuth.apiRootUrl,
    ])

    const [detailObject, setDetailObject] = useState<any | undefined>(undefined)
    const [imageIndex, setImageIndex] = useState<number>(0)
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
    const [thumbSize, setThumbSize] = useState<string>(filterParamsOptions[listId]?.defaultThumbSize)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb>({
        ...listStateInitialWeb,
        componentString: filterParamsOptions[listId]?.[`${thumbSize}ListComponent`],
        modalKey: Math.random(),
    })

    useEffect(() => {
        if (filterParamsOptions[listId]) {
            let getUrl = ''
            getUrl = `${filterParamsOptions[listId].apiUrl}&block_size=${thumbSize}&list_status=${listStatus || 'active'}`
            onGetListData(getUrl, true)
        }
    }, [
        listId,
        listStatus,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
        slugOrId,
        thumbSize,
    ])

    if (!listId) return null

    // @ts-ignore
    const locationDetailObject = location.state?.[`${model}Object`]

    const components: any = {
        EventListMediumBlockWeb,
        EventListSmallBlockWeb,
        ImageListBlockWeb,
        MaterialListMediumBlockWeb,
        MaterialListSmallBlockWeb,
        OfferListMediumBlockWeb,
        OfferListSmallBlockWeb,
        PortfolioListBlock,
        PressListMediumBlockWeb,
        PressListSmallBlockWeb,
        ProductListMediumBlockWeb,
        ProductListSmallBlockWeb,
        ProfileDetailBlock,
        ProfileDetailBlockWebOld,
        ProfileListMediumBlockWeb,
        ProfileListSmallBlockWeb,
        ProjectListMediumBlockWeb,
        ProjectListSmallBlockWeb,
        ScheduleListBlockWeb,
        ServiceListMediumBlock,
        ServiceListSmallBlock,
        TeamListMediumBlockWeb,
        TeamListSmallBlockWeb,
    }

    if (!filterParamsOptions[listId]) {
        return <NotFoundPage />
    }

    const ComponentName = components[listState.componentString]

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${shortUrl}${slugOrId}/`, reduxAuth),
                component: 'DetailListContainerWeb',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerWeb',
                'onGetDetailData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWebNew({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'DetailListContainerWeb',
                componentName: filterParamsOptions[listId][`${thumbSize}ListComponent`],
                dispatch,
                listState,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerWeb',
                'onGetListData',
            ))
        }
    }

    function onImageClick(obj: any, index: any) {
        setIsLightBoxOpen(true)
        setImageIndex(index)
    }

    function onSetThumbSize(value: string) {
        try {
            setThumbSize(value)
            // if (!['images', 'event_images'].includes(listId)) {
            // 	let getUrl = ''
            // 	getUrl = `${filterParamsOptions[listId].apiUrl}&block_size=${value}&list_status=${listStatus || 'active'}`
            // 	onGetListData(
            // 		getUrl,
            // 		true,
            // 	)
            // }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerWeb',
                'onSetThumbSize',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailListContainerWeb',
                'onSearchNext',
            ))
        }
    }

    return (
        <IonPage className='detail-list-container-web'>
            <HeaderChildren>
                <div className='hcw-header-children-left'>
                    <IonButtons slot='start'>
                        <LinkHelper
                            className='hcw-profile-pic'
                            edit={false}
                            onClick={() => locationDetailObject ? history.goBack() : undefined}
                            to={locationDetailObject ? undefined : detailObject?.get_absolute_url}
                        >
                            <div className='hcw-image-link'>
                                <RatioZarmingHelper
                                    borderRadius='50%'
                                    dominant_color={detailObject?.get_dominant_color}
                                    edit={false}
                                    fontSize={12}
                                    height={30}
                                    name={detailObject?.name}
                                    notZarma={Boolean(detailObject?.get_image_lq)}
                                    src={detailObject?.get_image_lq}
                                    to={undefined}
                                />
                            </div>
                        </LinkHelper>
                        <LinkHelper
                            className='hcw-profile-name'
                            edit={false}
                            onClick={() => locationDetailObject ? history.goBack() : undefined}
                            to={locationDetailObject ? undefined : detailObject?.get_absolute_url}
                        >
                            <p
                                className='hcw-name mo-clamp-web1'
                                title={detailObject?.name}
                            >
                                {detailObject?.name}
                            </p>
                        </LinkHelper>
                    </IonButtons>
                </div>
                <IonTitle className='hcw-title'>{filterParamsOptions[listId].title}</IonTitle>
                <IonButtons
                    className='hcw-thumb-wrap'
                    slot='end'
                >
                    <IconBlock
                        className={`hcw-size-icon${thumbSize === 'small' ? ' active' : ''}`}
                        edit={false}
                        iconClass='mo-new-icon-gallery'
                        onClick={() => onSetThumbSize('small')}
                    />
                    <IconBlock
                        className={`hcw-size-icon${thumbSize === 'medium' ? ' active' : ''}`}
                        edit={false}
                        iconClass='mo-new-icon-list'
                        onClick={() => onSetThumbSize('medium')}
                    />
                    {/* <IconBlock
						className={`hcw-size-icon${thumbSize === 'big' ? ' active' : ''}`}
						edit={false}
						iconClass='mo-new-icon-expand-solid'
						onClick={() => onSetThumbSize('big')}
					/> */}
                </IonButtons>
            </HeaderChildren>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='dlcw-content'>
                    <div className={`dlcw-infinite ${listId} ${thumbSize}`}>
                        {['image'].includes(listId)
                            ? (
                                listState.items.length > 0 && (
                                    <Gallery
                                        // @ts-ignore
                                        columns={thumbSize === 'big' ? 1 : 4}
                                        direction={thumbSize === 'medium' ? 'row' : 'column'}
                                        limitNodeSearch={thumbSize === 'medium' ? 4 : undefined}
                                        margin={2}
                                        photos={listState.items}
                                        // @ts-ignore
                                        renderImage={(props) => (
                                            <ImageGalleryHelper
                                                {...props}
                                                direction={thumbSize === 'medium' ? 'row' : 'column'}
                                                onClick={(obj: any, index: any) => onImageClick(obj, index)}
                                            />
                                        )}
                                    />
                                )
                            ) : (
                                listState.items.map((item) => {
                                    return (
                                        <ComponentName
                                            key={item.id}
                                            edit={false}
                                            modalKey={listState.modalKey}
                                            object={item}
                                            playerOrigin={`${model}DetailList-${slugOrId}`}
                                        />
                                    )
                                })
                            )
                        }
                    </div>
                    <InfiniteScrollHelperWeb
                        active={!listState.disableInfiniteScroll}
                        hasMore={listState.hasMore}
                        isLoading={listState.isLoading}
                        items={listState.items}
                        onChange={onSearchNext}
                    />
                </div>
                {isLightBoxOpen && ['image'].includes(listId) && (
                    <LightboxCustom
                        mainSrc={listState.items[imageIndex].get_image_hq || ''}
                        nextSrc={listState.items[(imageIndex + 1) % listState.items.length].get_image_hq}
                        onCloseRequest={() => setIsLightBoxOpen(false)}
                        onMoveNextRequest={() => listState.items && setImageIndex((imageIndex + 1) % listState.items.length)}
                        onMovePrevRequest={() => listState.items && setImageIndex((imageIndex + listState.items.length - 1) % listState.items.length)}
                        prevSrc={listState.items[(imageIndex + listState.items.length - 1) % listState.items.length].get_image_hq}
                    />
                )}
            </IonContent>
        </IonPage>
    )
})
