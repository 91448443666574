// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonButton,
    IonButtons,
    IonItem,
    IonList,
    IonPopover,
    IonReorderGroup,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useParams,
} from 'react-router-dom'

// components
import {
    LinkHelper,
    RatioZarmingHelper,
} from 'components'

// data
import {
    api_url_profile_section_form_patch_child,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_profile_detail_old,
    view_url_profile_portfolio_list,
    view_url_profile_subscription_list,
} from 'data'

// pages
import {
    ProfileDetailMenuLeftTab,
} from 'pages'

// serializers
import {
    ProfileDetailShortSlugSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    slug: string
}

type ProfileDetailMenuWebProps = {
    disableReorder?: boolean
    disableReorderChildren?: boolean
    disableReorderChildrenChildren?: boolean
    hiddenPic?: boolean
    isLeftTab?: boolean
    manageProfileSection?: boolean
    object: ProfileDetailShortSlugSerializer
}

// main
export const ProfileDetailMenuWeb: React.FC<ProfileDetailMenuWebProps> = React.memo(({
    disableReorder,
    disableReorderChildren,
    disableReorderChildrenChildren,
    hiddenPic,
    isLeftTab,
    manageProfileSection,
    object,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [items, setItems] = useState<ProfileDetailShortSlugSerializer['tabs_left']>(object.tabs_left || [])
    const [showActionSheet, setShowActionSheet] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })

    const isMyProfile = (reduxAuth.settings?.active_profile && reduxAuth.settings.active_profile.id) === object.id

    useEffect(() => {
        setItems(object.tabs_left || [])
    }, [
        object.tabs_left,
    ])

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMenuWeb',
                'doReorder',
            ))
        }
    }

    async function onSortEnd(event: any) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetailMenuWeb onSortEnd')
            const item = items![event.detail.from]
            const itemTo = items![event.detail.to]
            setItems(arrayMoveImmutable(items!, event.detail.from, event.detail.to))
            const formData: any = new FormData()
            formData.append('sibling_id', itemTo.id)
            const putUrl = getApiUrl(`${api_url_profile_section_form_patch_child}${item.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'ProfileDetailMenuWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                        skipSend404Detail: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetailMenuWeb',
                'onSortEnd',
            ))
        }
    }

    if (isLeftTab) {
        return (
            <div className='profile-detail-menu-left-web'>
                {(isMyProfile || reduxAuth.settings?.user?.is_staff) && (
                    <NavLink
                        className='pdmlw-tab-item'
                        to={`${view_url_profile_detail_old}${params.slug}/tabs/${view_url_profile_portfolio_list}/`}
                    >
                        {reduxText[3079]}
                    </NavLink>
                )}
                {disableReorder
                    ? (
                        items?.map((val) => (
                            <ProfileDetailMenuLeftTab
                                key={val.id}
                                disableReorderChildren={disableReorderChildren}
                                disableReorderChildrenChildren={disableReorderChildrenChildren}
                                manageProfileSection={manageProfileSection}
                                object={val}
                            />
                        ))
                    ) : (
                        <IonReorderGroup
                            disabled={disableReorder}
                            onIonItemReorder={doReorder}
                        >
                            {items?.map((val) => (
                                <ProfileDetailMenuLeftTab
                                    key={val.id}
                                    disableReorderChildren={disableReorderChildren}
                                    disableReorderChildrenChildren={disableReorderChildrenChildren}
                                    object={val}
                                />
                            ))}
                        </IonReorderGroup>
                    )}
                {isMyProfile && (
                    <React.Fragment>
                        <NavLink
                            className='pdmlw-tab-item'
                            to={`${view_url_profile_detail_old}${params.slug}/tabs/${view_url_profile_subscription_list}/`}
                        >
                            {reduxText[6470]}
                        </NavLink>
                    </React.Fragment>
                )}
            </div>
        )
    }

    return (
        <IonButtons
            className='profile-detail-menu-web'
            slot='start'
        >
            {!hiddenPic && (
                <LinkHelper
                    className='hcw-profile-pic'
                    edit={false}
                    to={`${view_url_profile_detail_old}${params.slug}/`}
                >
                    <div className='hcw-image-link'>
                        <RatioZarmingHelper
                            borderRadius='50%'
                            dominant_color={object.get_dominant_color}
                            edit={false}
                            fontSize={12}
                            height={30}
                            name={object.name}
                            notZarma={Boolean(object.get_image_lq)}
                            src={object.get_image_lq}
                            to={undefined}
                        />
                    </div>
                </LinkHelper>
            )}
            {(isMyProfile || reduxAuth.settings?.user?.is_staff) && (
                <NavLink
                    className='pdmw-tab-button'
                    to={`${view_url_profile_detail_old}${params.slug}/tabs/${view_url_profile_portfolio_list}/`}
                >
                    {reduxText[3079]}
                </NavLink>
            )}
            {object.tabs?.slice(0, 6).map((val) => (
                <NavLink
                    key={val.id}
                    className='pdmw-tab-button'
                    exact
                    to={`${view_url_profile_detail_old}${params.slug}/sections/${val.id}/${[48959, 50177].includes(val.id!) ? 'all/' : ''}`}
                >
                    {val.name}
                </NavLink>
            ))}
            {((object.tabs?.slice(6).length! > 0) || isMyProfile) && (
                <IonButton
                    className='pdmw-button'
                    onClick={(e) => setShowActionSheet({ open: true, event: e.nativeEvent })}
                >
                    <i className='main-icon mo-new-icon-chevron-down-solid' />
                </IonButton>
            )}
            <IonPopover
                event={showActionSheet.event}
                isOpen={showActionSheet.open}
                onDidDismiss={() => setShowActionSheet({ open: false, event: undefined })}
            >
                <IonList>
                    {object.tabs?.slice(6).length! > 0 && object.tabs?.filter(obj => (obj.is_custom === false) && (obj.is_grouping === false)).slice(6).map((val) => (
                        <IonItem
                            key={val.id}
                            onClick={(e) => setShowActionSheet({ open: false, event: undefined })}
                            routerLink={`${view_url_profile_detail_old}${params.slug}/sections/${val.id}/${[48959, 50177].includes(val.id!) ? 'all/' : ''}`}
                        >
                            {val.name}
                        </IonItem>
                    ))}
                    {isMyProfile && (
                        <React.Fragment>
                            <IonItem
                                onClick={(e) => setShowActionSheet({ open: false, event: undefined })}
                                routerLink={`${view_url_profile_detail_old}${params.slug}/tabs/${view_url_profile_subscription_list}/`}
                            >
                                {reduxText[6470]}
                            </IonItem>
                        </React.Fragment>
                    )}
                </IonList>
            </IonPopover>
        </IonButtons>
    )
})
