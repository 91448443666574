// main
export function getFormData(formInfoObject: any) {

    const newFields = formInfoObject.fields

    const formData = new FormData()

    // ForeignKey
    if (Array.isArray(formInfoObject.formForeignKey)) {
        formInfoObject.formForeignKey.map((field: string) => {
            if (newFields[field]) {
                formData.append(field, newFields[field] ? newFields[field].id : '')
            } else if (newFields.id) {
                formData.append(field, '')
            }
            return false
        })
    }

    // ManyToMany
    if (Array.isArray(formInfoObject.formManyToMany)) {
        formInfoObject.formManyToMany.map((field: string) => {
            if (Array.isArray(newFields[field])) {
                newFields[field].map((value: any) => {
                    if (['themes'].includes(field)) {
                        if (!Number(value.id) && value.id.includes('mo-created-option')) {
                            formData.append(`${field}_new`, value.name)
                        }
                        formData.append(field, value.name)
                    } else {
                        formData.append(field, value.id)
                    }
                    return false
                })
            }
            return false
        })
    }

    // Files
    if (Array.isArray(formInfoObject.formFile)) {
        formInfoObject.formFile.map((field: string) => {
            if (newFields[field]) {
                if (newFields[field].type) {
                    const filetoUpload = newFields[field]
                    let filetoUploadName = newFields[field].name
                    if (filetoUploadName.length > 100) {
                        filetoUploadName = filetoUploadName.slice(filetoUploadName.length - 100)
                    }
                    formData.append(field, filetoUpload, filetoUploadName)
                } else if (newFields[field] === 'to_delete') {
                    formData.append(`${field}_to_delete`, 'true')
                }
            }
            return false
        })
    }

    // Attributes
    if (Array.isArray(formInfoObject.formAttribute)) {
        formInfoObject.formAttribute.map((field: string) => {
            if (newFields[field]) {
                formData.append(field, newFields[field])
            } else if (newFields[field] === 0) {
                formData.append(field, newFields[field])
            } else if (newFields[field] === false) {
                formData.append(field, newFields[field])
            } else {
                formData.append(field, '')
            }
            return false
        })
    }

    // Json
    if (Array.isArray(formInfoObject.formJson)) {
        formInfoObject.formJson.map((field: string) => {
            formData.append(field, newFields[field] ? JSON.stringify(newFields[field]) : '')
            return false
        })
    }

    // JsonNew
    if (Array.isArray(formInfoObject.formJsonNull)) {
        formInfoObject.formJsonNull.map((field: string) => {
            formData.append(field, newFields[field] ? JSON.stringify(newFields[field]) : JSON.stringify(null))
            return false
        })
    }

    return formData
}
