// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    Redirect,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    view_url_auth_login,
    view_url_profile_detail_old,
    view_url_staff,
} from 'data'

// main
export const HomePage: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    if (reduxAuth.authenticated) {
        if (reduxAuth.settings?.user?.is_staff) {
            return <Redirect to={view_url_staff} />
        }
        if (reduxAuth.settings?.active_profile?.get_absolute_url) {
            return (
                <Redirect to={reduxAuth.settings.active_profile.get_absolute_url} />
            )
        }
        return (
            <Redirect to={`${view_url_profile_detail_old}${reduxAuth.settings?.active_profile?.slug}/`} />
        )
    }
    return (
        <Redirect to={view_url_auth_login} />
    )
}
