// react components
import React from 'react'
import {
    DialogActions,
} from '@mui/material'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
} from 'data'

// props
type FormModalActionProps = {
    buttonSize?: any
    defaultFields: any
    formActive: boolean
    handleRequired: (stay: boolean, publish?: boolean, another?: boolean) => void
    helpers: reduxFormHelpersState
    object: any
    onCloseModal: (value?: any) => void
    reverseButtons?: boolean
    setIsDeleteAlertOpen: React.Dispatch<React.SetStateAction<boolean>>
}

// main
export const FormModalAction: React.FC<FormModalActionProps> = React.memo(({
    buttonSize,
    defaultFields,
    formActive,
    handleRequired,
    helpers,
    object,
    onCloseModal,
    reverseButtons,
    setIsDeleteAlertOpen,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <DialogActions className={`mo-dialog-footer${reverseButtons ? ' reverse' : ''}`}>
            {/* Start delete button */}
            <Button
                disabled={!object?.id}
                color='danger'
                edit={false}
                expand='full'
                fill='outline'
                onClick={() => setIsDeleteAlertOpen(true)}
                size={buttonSize}
                text={reduxText[2983]}
            />
            {/* End delete button */}
            {/* Start cancel/close button */}
            <Button
                edit={false}
                expand='full'
                fill='outline'
                onClick={onCloseModal}
                size={buttonSize}
                text={helpers.buttonDisabled ? reduxText[4742] : reduxText[4519]}
            />
            {/* End cancel/close button */}
            {formActive === undefined
                ? (
                    <>
                        {/* Start save and add another button */}
                        {defaultFields && (
                            <Button
                                disabled={helpers.buttonDisabled}
                                edit={false}
                                expand='full'
                                fill='outline'
                                onClick={() => handleRequired(false, !formActive, true)}
                                size={buttonSize}
                                text={reduxText[8824]}
                            />
                        )}
                        {/* End save and add another button */}
                        {/* Start save and add another button */}
                        <Button
                            disabled={helpers.buttonDisabled}
                            edit={false}
                            expand='full'
                            fill='outline'
                            onClick={() => handleRequired(false, !formActive)}
                            size={buttonSize}
                            text={reduxText[4520]}
                        />
                        {/* End save and add another button */}
                    </>
                ) : (
                    <>
                        {!formActive && (
                            <Button
                                disabled={helpers.buttonDisabled}
                                edit={false}
                                expand='full'
                                fill='outline'
                                onClick={() => handleRequired(false)}
                                size={buttonSize}
                                text={reduxText[5895]}
                            />
                        )}
                        {/* Start save and add another button */}
                        {defaultFields && (
                            <Button
                                disabled={helpers.buttonDisabled}
                                edit={false}
                                expand='full'
                                fill='outline'
                                onClick={() => handleRequired(false, !formActive, true)}
                                size={buttonSize}
                                text={reduxText[8824]}
                            />
                        )}
                        {/* End save and add another button */}
                        {/* Start save and add another button */}
                        <Button
                            disabled={helpers.buttonDisabled}
                            edit={false}
                            expand='full'
                            fill='outline'
                            onClick={() => handleRequired(false, !formActive)}
                            size={buttonSize}
                            text={formActive ? reduxText[4520] : reduxText[8795]}
                        />
                        {/* End save and add another button */}
                    </>
                )}
        </DialogActions>
    )

})
