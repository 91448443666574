// react components
import slugify from 'slugify'

// main
export function ecSlugify(text: string) {
    // let slugifiedText = text.toLowerCase()
    // slugifiedText = slugifiedText.replace(/[^a-zA-Z0-9 ]/g, ' ')
    const slugifiedText = slugify(text, { replacement: ' ', lower: true })
    return slugifiedText
}
