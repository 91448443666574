export const errorMessageToSkip = [
    "AxiosError: Network Error",
    "AxiosError: Request aborted",
    "AxiosError: timeout exceeded",
    "Error: Network Error",
    "Error: Request aborted",
    "Error: timeout exceeded",
    "Network Error",
    "NotFoundError: Failed",
    "NotFoundError: Node",
    "ReferenceError: Can't find variable: IntersectionObserver",
    "ReferenceError: elementDescriptor is not defined",
    "Request aborted",
    "SecurityError",
    "SyntaxError",
    "TypeError: Failed to fetch",
    "timeout of 0ms exceeded",
]
