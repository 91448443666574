// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextAreaInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
} from 'serializers/web'

// props
type LyricsProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    onChange: any
}

// main
export const LyricsProjectInput: React.FC<LyricsProjectInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextAreaInput
            error={errors?.lyrics}
            label={reduxText[3251]}
            name='lyrics'
            onChange={onChange}
            optional
            value={fields.lyrics || ''}
        />
    )
})
