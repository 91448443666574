// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetSectionIdSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    EditButtonMixedMediaContentChildren,
    ParentButtonMixedMediaContentChildren,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type EditBlockMixedMediaContentBlockProps = {
    contentType: contentTypeModelType
    customPosition: any
    mainParentId: number
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    object: PortfolioPageContentListSiteSerializer
    parentArray: number[] | undefined
    parentStyles: any
    resetCustomPosition?: () => void
}

// main
export const EditBlockMixedMediaContentBlock: React.FC<EditBlockMixedMediaContentBlockProps> = React.memo(({
    contentType,
    customPosition,
    mainParentId,
    newMixedMediaContent,
    newMixedMediaContentType,
    object,
    parentArray,
    parentStyles,
    resetCustomPosition,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const mother = useSelector((state: defaultReduxState) => state.reduxForm.mixedMother)  // TODO check with reduxFormSetMixedProfileIdOpen

    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [isPopoverOpen2, setIsPopoverOpen2] = useState(false)

    function handleEdit() {
        try {
            if (newMixedMediaContent) {
                history.push(`/form-mixed-media/${newMixedMediaContentType}/${params.id}/${object.id}/`)
            } else {
                history.push(`${mother.formUrl}${params.id}/mixed-media-content/${object.id}/`)
            }
            dispatch(reduxFormSetSectionIdSite(mainParentId))
            if (Number(params.panelValue) === object.id) scrollToSection(params.panelValue, 0)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EditBlockMixedMediaContentBlock',
                'handleEdit',
            ))
        }
    }

    let customPositionStyles = {}
    if (customPosition) {
        if (customPosition === 'leave') {
            customPositionStyles = {
                display: 'none',
            }
        } else {
            customPositionStyles = {
                height: `${customPosition.height}px`,
                left: `${customPosition.left}px`,
                position: 'absolute',
                top: `${customPosition.top}px`,
                width: `${customPosition.width}px`,
            }
        }
    }

    return (
        <div
            className={`edit-block-site${(isPopoverOpen || isPopoverOpen2) ? ' is-open' : ''}${reduxFormSitenavigationMode === 'preview' ? ' hidden' : ''}`}
            onMouseLeave={resetCustomPosition ? () => resetCustomPosition() : undefined}
            style={customPositionStyles}
        >
            <div
                className='ebs-link'
                onClick={() => handleEdit()}
            />
            {object?.id !== mainParentId && (
                <div className='ebs-actions'>
                    <ParentButtonMixedMediaContentChildren
                        isPopoverOpen={isPopoverOpen}
                        mainParentId={mainParentId}
                        newMixedMediaContent={newMixedMediaContent}
                        newMixedMediaContentType={newMixedMediaContentType}
                        parentArray={parentArray}
                        setIsPopoverOpen={setIsPopoverOpen}
                    >
                        <button
                            className='ebs-button'
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                            <i className='mo-new-icon-table-cells-large-solid' />
                        </button>
                    </ParentButtonMixedMediaContentChildren>
                    <EditButtonMixedMediaContentChildren
                        allowDrop
                        contentType={contentType}
                        isPopoverOpen={isPopoverOpen2}
                        mainParentId={mainParentId}
                        mother={mother}
                        newMixedMediaContent={newMixedMediaContent}
                        newMixedMediaContentType={newMixedMediaContentType}
                        object={object}
                        parentStyles={parentStyles}
                        setIsPopoverOpen={setIsPopoverOpen2}
                    >
                        <button
                            className='ebs-button'
                            onClick={() => setIsPopoverOpen2(!isPopoverOpen2)}
                        >
                            <i className='mo-new-icon-ellipsis-h-solid' />
                        </button>
                    </EditButtonMixedMediaContentChildren>
                </div>
            )}
        </div>
    )
})
