// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    contentTypeModelType,
    defaultReduxState,
} from 'data'

// pages
import {
    TableHeaderColumn,
} from 'pages'

// serializers
import {
    ContentTypeFieldTypes,
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getFieldTitle,
} from 'services'

// props
type TableHeaderProps = {
    addProfileColumn?: boolean
    checkboxSelectAll?: any
    checkboxShow?: boolean
    columns: string[]
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    count: number | undefined
    filterValues: any
    inModal?: boolean
    isLoading?: boolean
    profile: ProfileDetailAdminSerializerV2 | undefined
    setExportColumns: React.Dispatch<React.SetStateAction<{
        columnName: string
        columnTitle: string
        fieldOptions: ContentTypeFieldTypes | undefined
    }[]>> | undefined
    setFilter?: (e: any, f?: any) => void
}

// main
export const TableHeader: React.FC<TableHeaderProps> = React.memo(({
    addProfileColumn,
    checkboxSelectAll,
    checkboxShow,
    columns,
    contentType,
    contentTypeSettings,
    count,
    filterValues,
    inModal,
    isLoading,
    profile,
    setExportColumns,
    setFilter,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [newColumns, setNewColumns] = useState<{
        columnName: string
        columnTitle: string
        fieldOptions: ContentTypeFieldTypes | undefined
    }[]>([])

    useEffect(() => {
        const extraAutoColumns: {
            columnName: string
            columnTitle: string
            fieldOptions: ContentTypeFieldTypes | undefined
        }[] = []
        // if (addProfileColumn) {
        //     extraAutoColumns.push('profile', 'id')
        // }
        columns.map(column => {
            const getFieldTitleResult = getFieldTitle({
                column: column,
                contentType: contentType,
                contentTypeSettings: contentTypeSettings,
                profile: profile,
                reduxText: reduxText,
            })
            extraAutoColumns.push({
                columnName: column,
                columnTitle: getFieldTitleResult.columnTitle,
                fieldOptions: getFieldTitleResult.fieldOptions,
            })
        })
        if (setExportColumns) setExportColumns(extraAutoColumns)
        setNewColumns(extraAutoColumns)
    }, [
        addProfileColumn,
        columns,
        contentType,
    ])

    if (!contentType || isLoading) return null

    return (
        <tr className='table-header'>
            <th className='th-column field-action'>
                <div className={`th-div${checkboxShow ? ' checkbox' : ''}`}>
                    {checkboxShow && (
                        <input
                            onChange={e => checkboxSelectAll(!e.target.checked)}
                            type='checkbox'
                        />
                    )}
                    {count !== undefined ? `(${count})` : '()'}
                </div>
            </th>
            {newColumns.map(val => (
                <TableHeaderColumn
                    key={val.columnName}
                    addProfileColumn={addProfileColumn}
                    column={val.columnName}
                    contentType={contentType}
                    fieldOptions={val.fieldOptions}
                    filterValues={filterValues}
                    inModal={inModal}
                    setFilter={setFilter}
                    title={val.columnTitle}
                />
            ))}
        </tr>
    )
})
