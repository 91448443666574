// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventPricingDetailFormSerializer,
    EventPricingDetailFormSerializerError,
} from 'serializers/web'

// props
type UnitPriceEventPricingInputProps = {
    errors: EventPricingDetailFormSerializerError | undefined
    fields: EventPricingDetailFormSerializer
    onChange: any
}

// main
export const UnitPriceEventPricingInput: React.FC<UnitPriceEventPricingInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            error={errors?.unit_price}
            label={reduxText[4383]}
            name='unit_price'
            onChange={onChange}
            type='number'
            value={fields.unit_price || ''}
        />
    )
})
