// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    CountViewBlock,
    CreditShortInfo,
    DescriptionDetailBlock,
    HeaderDetailBlock,
    ImageHelper,
    LocationDetailBlock,
    MainHashtag,
    ProfileShortInfoWeb,
} from 'components'

// containers
import {
    DetailListContainerNew,
} from 'containers'

// data
import {
    api_url_product_sales_recap_list,
    api_url_product_image_list,
    defaultReduxState,
    searchTabValue,
    view_url_product_detail,
} from 'data'

// pages
import {
    MixedContentBlock,
    ProjectListMicroBlockWeb,
} from 'pages'

// serializers
import {
    MainTabHelperType,
    ProductDetailSerializer,
    ProductDetailTeamHelperSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDateWithType,
} from 'services'

// props
type ProductDetailBlockWebProps = {
    edit: boolean
    inModal?: boolean
    languageId: number | undefined
    modalKeys: {
        related_projects: number,
    }
    object: ProductDetailSerializer
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const ProductDetailBlockWeb: React.FC<ProductDetailBlockWebProps> = React.memo(({
    edit,
    inModal,
    languageId,
    modalKeys,
    object,
    setLanguageId,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setTabValue({
            id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
            title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
        })
    }, [
        object?.id,
    ])

    const [tabValue, setTabValue] = useState<MainTabHelperType>({
        id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
        title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
    })

    const tabArray: MainTabHelperType[] = []
    if (object.medium_type?.is_custom_page || object.has_images || object.owner_info?.has_sales_order_line) {
        tabArray.push(
            {
                id: 'cover',
                title: reduxText[4359],
            },
        )
    }
    if (object.medium_type?.is_custom_page) {
        tabArray.push(
            {
                id: 'custom-page',
                title: reduxText[8489],
            },
        )
    }
    if (object.has_images) {
        tabArray.push(
            {
                id: 'images',
                isGallery: true,
                listApiUrl: getApiUrl(`${api_url_product_image_list}?product_id=${object.id}`, reduxAuth),
                title: reduxText[1625],
            },
        )
    }
    if (object.owner_info?.has_sales_order_line) {
        tabArray.push(
            {
                id: 'sales',
                listApiUrl: getApiUrl(`${api_url_product_sales_recap_list}${object.id}/`, reduxAuth),
                listComponent: 'ProductSalesRecapRow',
                tableHeaderComponent: 'ProductSalesRecapRowHeader',
                title: reduxText[8753],
            },
        )
    }

    const hasTabs = !edit && tabArray.length > 0

    const duplicateProfile: any = []
    let creators: ProductDetailTeamHelperSerializer[] = []
    let labels: ProductDetailTeamHelperSerializer[] = []
    if (object.team_list && object.team_list.creator && object.team_list.creator.length > 0) {
        creators = object.team_list.creator
        object.team_list.creator.map((val) => {
            if (val.member) duplicateProfile.push(val.member.id)
            return false
        })
    }
    if (object.team_list && object.team_list.label && object.team_list.label.length > 0) {
        labels = object.team_list.label
        object.team_list.label.map((val) => {
            if (val.member) duplicateProfile.push(val.member.id)
            return false
        })
    }

    return (
        <div className={`main-detail-block product-detail-block-web${tabValue.id === 'custom-page' ? ' medium-type-custom' : ''}${edit ? ' edit' : ''}`}>
            <div className={`mdb-left${hasTabs ? ' tabs' : ''}${tabValue.tableHeaderComponent ? ' table' : ''}`}>
                {hasTabs && (
                    <div className='mdb-tabs'>
                        {tabArray.map(val => (
                            <div
                                key={val.id}
                                className={`mdb-buttons${val.id === tabValue.id ? ' active' : ''}`}
                                onClick={() => setTabValue(val)}
                            >
                                <span className='mdb-buttons-name'>{val.title}</span>
                            </div>
                        ))}
                    </div>
                )}
                {(tabValue.listComponent || tabValue.isGallery)
                    ? (
                        <DetailListContainerNew
                            isGallery={tabValue.isGallery}
                            inModal={inModal}
                            listApiUrl={tabValue.listApiUrl!}
                            listComponent={tabValue.listComponent}
                            tableHeaderComponent={tabValue.tableHeaderComponent}
                        />
                    ) : (
                        <React.Fragment>
                            {tabValue.id === 'custom-page'
                                ? (
                                    <MixedContentBlock
                                        contentType='product'
                                        edit={edit}
                                        hasTabs={hasTabs}
                                        languageId={languageId}
                                        object={object}
                                    />
                                ) : (
                                    <ImageHelper
                                        alt={object.name}
                                        className='mdb-cover-image'
                                        dominant_color={object.get_dominant_color}
                                        lightBox
                                        lightBoxSrc={object.get_image_hq}
                                        src={object.get_image_hq}
                                    />
                                )
                            }
                        </React.Fragment>
                    )
                }
            </div>
            <div className={`mdb-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <HeaderDetailBlock
                    contentType='product'
                    edit={edit}
                    languageId={languageId}
                    object={object}
                    setLanguageId={setLanguageId}
                >
                    {object.profile && !duplicateProfile.includes(object.profile.id) && (
                        <ProfileShortInfoWeb
                            edit={edit}
                            object={object.profile}
                        />
                    )}
                    {creators.map((val) => (
                        <CreditShortInfo
                            key={val.id}
                            edit={edit}
                            object={val}
                        />
                    ))}
                    {labels.map((val) => (
                        <div
                            key={val.id}
                            className='pdbw-label'
                        >
                            <CreditShortInfo
                                edit={edit}
                                object={val}
                            />
                        </div>
                    ))}
                </HeaderDetailBlock>
                <DescriptionDetailBlock
                    languageId={languageId}
                    text={object.sku}
                    title={reduxText[8769]}
                />
                <LocationDetailBlock
                    edit={edit}
                    object={object.profile}
                    searchTab={searchTabValue.product}
                />
                <DescriptionDetailBlock>
                    {object.variant_list?.length! > 0
                        ? (
                            object.variant_list?.map((val) => (
                                <div
                                    key={val.id}
                                    className='pdbw-price-wrap'
                                >
                                    {!val.price_on_request
                                        ? (
                                            <React.Fragment>
                                                <p className='pdbw-price'>{val.name}{val.sku ? ` (${val.sku})` : ''}</p>
                                                <p className='pdbw-price'>{val.unit_price} {object.currency?.symbol || object.currency?.iso_code}</p>
                                                {/* {val.unit_price_conversions?.map(val => (
                                            <p
                                                key={val.id}
                                                className='pdbw-price'
                                            >
                                                {val.value} {val.currency?.symbol || val.currency?.iso_code}
                                            </p>
                                        ))} */}
                                                {val.stock_status && (
                                                    <p className='pdbw-price'>{val.stock_status.name}</p>
                                                )}
                                            </React.Fragment>

                                        ) : (
                                            <p className='pdbw-price'>{reduxText[4869]}</p>
                                        )
                                    }
                                </div>
                            ))
                        ) : (
                            !object.price_on_request
                                ? (
                                    <React.Fragment>
                                        <p className='pdbw-price'>{object.unit_price} {object.currency?.symbol || object.currency?.iso_code}</p>
                                        {object.unit_price_conversions?.map(val => (
                                            <p
                                                key={val.id}
                                                className='pdbw-price'
                                            >
                                                {val.value} {val.currency?.symbol || val.currency?.iso_code}
                                            </p>
                                        ))}
                                        {object.stock_status && (
                                            <p className='pdbw-price'>{object.stock_status.name}</p>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <p className='pdbw-price'>{reduxText[4869]}</p>
                                )
                        )
                    }
                </DescriptionDetailBlock>
                {(object.product_length || object.packaging_weight) && (
                    <DescriptionDetailBlock>
                        {object.product_length && (
                            <p className='pdbw-dimension'>{object.product_length} x {object.product_width} x {object.product_height} cm</p>
                        )}
                        {object.packaging_weight && (
                            <p className='pdbw-weight'>{object.packaging_weight} kg</p>
                        )}
                    </DescriptionDetailBlock>
                )}
                {object.custom_date && (
                    <DescriptionDetailBlock
                        languageId={languageId}
                        text={getDateWithType(reduxAuth, object.custom_date, 'd')}
                    />
                )}
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.description}
                />
                {object.languages?.length! > 0 && (
                    <DescriptionDetailBlock
                        title={reduxText[4825]}
                    >
                        {object.languages!.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.team_list?.team?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[4917]}>
                        {object.team_list!.team!.map((val, i) => (
                            i < 3
                                ? (
                                    <CreditShortInfo
                                        key={val.id}
                                        edit={edit}
                                        object={val}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='pdbw-section-button'
                                        edit={edit}
                                        fill='clear'
                                        size='small'
                                        text={reduxText[5606]}
                                        to={`${view_url_product_detail}${object.id}/${object.slug}/team/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.related_projects?.length! > 0 && (
                    <DescriptionDetailBlock
                        title={object.related_projects?.length! > 1 ? reduxText[2475] : reduxText[7716]}
                    >
                        {object.related_projects!.map((val) => (
                            <ProjectListMicroBlockWeb
                                key={val.id}
                                edit={edit}
                                modalKey={modalKeys.related_projects}
                                object={val}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
