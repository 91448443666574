// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonLabel,
    IonSegment,
    IonSegmentButton,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    api_url_css_property_list,
    contentTypeModelType,
    defaultReduxState,
    reduxFormGetCSSProperties,
    reduxFormSetNavigationModeSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ListMixedMediaContentPanel,
    StyleMixedPanel,
} from 'pages'

// serializers
import {
    ProjectFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type ContainerMixedPanelProps = {
    contentType: contentTypeModelType
    handleInputChange: any
    formState: ProjectFormState
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    setFormState: React.Dispatch<ProjectFormState>
}

// main
export const ContainerMixedPanel: React.FC<ContainerMixedPanelProps> = React.memo(({
    contentType,
    formState,
    handleInputChange,
    newMixedMediaContent,
    newMixedMediaContentType,
    setFormState,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxAuthisWindows = useSelector((state: defaultReduxState) => state.reduxAuth.isWindows)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    useEffect(() => {
        getCSSProperties()
    }, [
        mother.fields?.id,
    ])

    useEffect(() => {
        dispatch(reduxFormSetNavigationModeSite('edit'))
    }, [])

    const [tabValue, setTabValue] = useState<string | number | undefined>('contents')

    async function getCSSProperties() {
        try {
            if (!mother.fields?.id) return
            if (reduxForm.cssProperties.length > 0) {
                return
            }
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ContainerMixedPanel getCSSProperties')
            const getUrl = getApiUrl(api_url_css_property_list, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormGetCSSProperties(response.data))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ContainerMixedPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getCSSProperties',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContainerMixedPanel',
                'getCSSProperties',
            ))
        }
    }

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainProjectPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother?.id) return null

    return (
        <div className={`container-mixed-panel-web mo-hidden-vertical-scrollbar${reduxAuthisWindows}`}>
            <div className='cmpw-segment-wrap'>
                <IonSegment
                    className='cmpw-segment'
                    color=''
                    onIonChange={(e) => setTabValue(e.detail.value)}
                    value={tabValue}
                >
                    <IonSegmentButton
                        className='cmpw-tab-button'
                        value='contents'
                    >
                        <IonLabel className='cmpw-tab-label'>{reduxText[8147]}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                        className='cmpw-tab-button'
                        value='styles'
                    >
                        <IonLabel className='cmpw-tab-label'>{reduxText[6411]}</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </div>
            {tabValue === 'contents' && (
                <ListMixedMediaContentPanel
                    contentType={contentType}
                    formState={formState}
                    newMixedMediaContent={newMixedMediaContent}
                    newMixedMediaContentType={newMixedMediaContentType}
                />
            )}
            {tabValue === 'styles' && (
                <StyleMixedPanel
                    contentType={contentType}
                    formState={formState}
                    handleInputChange={onHandleInputChange}
                    setFormState={setFormState}
                />
            )}
        </div>
    )
})
