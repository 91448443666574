// react components
import React, {
    useState,
} from 'react'
import {
    IonThumbnail,
} from '@ionic/react'
import {
    useParams,
} from 'react-router-dom'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    LinkHelper,
    Loader,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetHoveredBlockIdSite,
    reduxFormSetSectionIdSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    EditButtonMixedMediaContent,
    EditButtonMixedMediaContentChildren,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'
import {
    ProjectFormState,
} from 'serializers/web'

// services
import {
    BuilderService,
    handleMoveContent,
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    panelValue: string
}

type TemplateBlockNormalMixedMediaContentProps = {
    contentType: contentTypeModelType
    isInList: boolean
    mainParentId?: number
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    noAllowDrop?: boolean
    mother: ProjectFormState['mother']
    object: PortfolioPageContentListSiteSerializer
    parentStyles: any
    setTabValue?: React.Dispatch<string>
}

// main
export const TemplateBlockNormalMixedMediaContent: React.FC<TemplateBlockNormalMixedMediaContentProps> = React.memo(({
    contentType,
    isInList,
    mainParentId,
    newMixedMediaContent,
    newMixedMediaContentType,
    noAllowDrop,
    mother,
    object,
    parentStyles,
    setTabValue,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    function handleCssClassHover(mousedirection: 'enter' | 'leave') {
        try {
            if (mousedirection === 'enter') {
                dispatch(reduxFormSetHoveredBlockIdSite(object.id))
            } else {
                dispatch(reduxFormSetHoveredBlockIdSite(undefined))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TemplateBlockNormalMixedMediaContent',
                'handleCssClassHover',
            ))
        }
    }

    function handleDropDone(position: 'after' | 'before' | 'inside' | undefined) {
        try {
            handleMoveContent({
                component: 'TemplateBlockNormalMixedMediaContent',
                dispatch,
                mixedMedia: true,
                object,
                paramsId: params.id,
                position,
                reduxAuth,
                setIsLoading,
                setIsPopoverOpen,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TemplateBlockNormalMixedMediaContent',
                'handleDropDone',
            ))
        }
    }

    function handleDropDragOver(e: any,) {
        e.stopPropagation()
        e.preventDefault()
    }

    const toMoveObject = BuilderService.moveGetContentObject()
    const isDraggable = toMoveObject && (toMoveObject.id === object.id)

    return (
        <div
            id={Number(params.panelValue) === object.id ? 'activeNavElement' : ''}
            className={`template-block-normal-mixed-media-content-web${Number(params.panelValue) === object.id ? ' active' : ''}`}
            draggable={isDraggable}
            onDragEnd={() => BuilderService.moveDeleteContentObject()}
            onDragOver={!noAllowDrop ? (e) => handleDropDragOver(e) : undefined}
            onDragStart={() => BuilderService.moveSetContentObject(object, contentTypeData['mixedmediacontent'])}
            onDrop={!noAllowDrop ? () => handleDropDone('after') : undefined}
            onMouseEnter={() => handleCssClassHover('enter')}
            onMouseLeave={() => handleCssClassHover('leave')}
        >
            <LinkHelper
                className='tbnmmcw-info'
                edit={false}
                onClick={() => {
                    if (setTabValue) setTabValue('styles')
                    dispatch(reduxFormSetSectionIdSite(mainParentId || object.id))
                    if (Number(params.panelValue) === object.id) scrollToSection(params.panelValue, 0)
                }}
                to={newMixedMediaContent ? `/form-mixed-media/${newMixedMediaContentType}/${mother.fields?.id}/${object.id}/` : `${mother.formUrl}${mother.fields?.id}/mixed-media-content/${object.id}/`}
            >
                <div className='tbnmmcw-info-wrap'>
                    <div className='tbnmmcw-name-wrap'>
                        <i className={`tbnmmcw-icon mo-new-icon-layout-${object.layout_edit?.id}`} />
                        <span className='tbnmmcw-name'>{object.layout_edit?.name}{object.layout_edit?.active ? '' : '(O)'}</span>
                        {!isInList && object.layout_edit?.content_source && (
                            <span className='tbnmmcw-content-source'>{object.layout_edit.content_source}{object.layout_edit.module_view ? ` - ${object.layout_edit.module_view}` : ''}</span>
                        )}
                        {object.action && (
                            <span className='tbnmmcw-link'>{object.action}</span>
                        )}
                    </div>
                    {object.auth_type !== 'n' && (
                        <p className='tbnmmcw-auth'>{object.layout_edit?.auth_type}</p>
                    )}
                    {object.layout_edit?.field_text?.name && (
                        object.layout_edit.field_text.field_key
                            ? (
                                <p className='tbnmmcw-text mo-clamp-web1'>{mother?.fields?.profile?.settings_data_json?.contentTypeSettingsCustom?.[object.layout_edit?.content_type_settings?.content_type_label_model!]?.field?.[object.layout_edit.field_text.field_key]?.verboseName || object.layout_edit.field_text.name}</p>
                            ) : (
                                <p className='tbnmmcw-text mo-clamp-web1'>{object.layout_edit.field_text.name}</p>
                            )
                    )}
                    {!object.active && (
                        <p className='tbnmmcw-content-inactive'>{reduxText[5616]}</p>
                    )}
                </div>
                {[781].includes(object.layout_edit?.id!) && (object.content?.get_image_site_lq || object.content?.file) && (
                    <div className='tbnmmcw-image-wrap'>
                        <IonThumbnail>
                            <ImageHelper
                                alt=''
                                className='tbnmmcw-image'
                                dominant_color={object.content?.get_dominant_color}
                                src={object.content?.get_image_site_lq || object.content?.file}
                            />
                        </IonThumbnail>
                    </div>
                )}
            </LinkHelper>
            <div className='tbnmmcw-actions-wrap'>
                <div className='tbnmmcw-actions'>
                    {mainParentId && (
                        <EditButtonMixedMediaContentChildren
                            allowDrop={!noAllowDrop}
                            contentType={contentType}
                            isInTree
                            isNormal
                            isPopoverOpen={isPopoverOpen}
                            mainParentId={mainParentId}
                            mother={mother}
                            newMixedMediaContent={newMixedMediaContent}
                            newMixedMediaContentType={newMixedMediaContentType}
                            object={object}
                            parentStyles={parentStyles}
                            setIsPopoverOpen={setIsPopoverOpen}
                        >
                            <button
                                className='main-icon-block-web'
                                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                            >
                                <i className='main-icon mo-new-icon-ellipsis-h-solid' />
                            </button>
                        </EditButtonMixedMediaContentChildren>
                    )}
                    {!mainParentId && (
                        <EditButtonMixedMediaContent
                            allowDrop={!noAllowDrop}
                            contentType={contentType}
                            isNormal
                            isPopoverOpen={isPopoverOpen}
                            mother={mother}
                            newMixedMediaContent={newMixedMediaContent}
                            newMixedMediaContentType={newMixedMediaContentType}
                            object={object}
                            setIsPopoverOpen={setIsPopoverOpen}
                        >
                            <button
                                className='main-icon-block-web'
                                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                            >
                                <i className='main-icon mo-new-icon-ellipsis-h-solid' />
                            </button>
                        </EditButtonMixedMediaContent>
                    )}
                </div>
            </div>
            {isLoading && (
                <Loader isOpen />
            )}
        </div>
    )
})
