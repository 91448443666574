// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    FontLoader,
    LightboxSite,
    ListSpinner,
} from 'components'

// data
import {
    api_url_project_mixed_media_list,
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    languageObjectSite,
    reduxModalErrorEventHandlerSite,
    reduxModalSetLanguageIdSite,
    reduxModalSiteSetSpecialSizeSite,
} from 'data'

// pages
import {
    ContentBlockSite,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'
import {
    MainFontSerializer,
    MainPortfolioSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getGoogleFontsSite,
    getStylesToRetrieve,
} from 'services'

// props
type MixedContentBlockProps = {
    contentType: contentTypeModelType
    edit: boolean
    hasTabs?: boolean
    languageId: number | undefined
    object: {
        id?: number
        fonts?: MainFontSerializer[]
        portfolio_main?: MainPortfolioSerializer
        styles?: any
    }
}

// main
export const MixedContentBlock: React.FC<MixedContentBlockProps> = React.memo(({
    contentType,
    edit,
    hasTabs,
    languageId,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<PortfolioPageContentListSiteSerializer[]>([])
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)
    const [refreshPreview, setRefreshPreview] = useState<number | undefined>(0)

    const [fonts, setFonts] = useState<MainFontSerializer[]>()
    const [linkColor, setLinkColor] = useState<string>()
    const [newPageStyles, setNewPageStyles] = useState<{ [key: string]: string | undefined }>({})

    const contentRef = useRef(null)

    useEffect(() => {
        getData()
        setRefreshFormNumber(reduxForm.refresh.refreshForm)
        setRefreshPreview(reduxForm.refresh.refreshPreview)
    }, [
        languageId,
        object.id,
        reduxAuth.apiRootUrl,
        reduxForm.refresh.refreshDetail,
    ])

    useEffect(() => {
        if (((reduxForm.refreshId.refreshPreview === object.id) || (reduxForm.refreshId.refreshForm === object.id)) && ((reduxForm.refresh.refreshPreview !== refreshPreview) || (reduxForm.refresh.refreshForm !== refreshFormNumber))) {
            getData()
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
            setRefreshPreview(reduxForm.refresh.refreshPreview)
        }
    }, [
        reduxForm.refresh.refreshForm,
        reduxForm.refresh.refreshPreview,
    ])

    useEffect(() => {
        const tempPageStyles: { [key: string]: string | undefined } = {}
        if (getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'backgroundImage')) {
            tempPageStyles.backgroundImage = getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'backgroundImage')
        } else {
            tempPageStyles.background = getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'background')
        }
        tempPageStyles.color = getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'color')
        tempPageStyles.fontFamily = getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'fontFamily') || getStylesToRetrieve(reduxModalSite, undefined, object.portfolio_main?.styles, '', 'fontFamily')
        setNewPageStyles(tempPageStyles)
        setLinkColor(getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'linkColor'))
        setFonts(object.fonts ? object.fonts.concat(object.portfolio_main?.fonts || []) : (object.portfolio_main?.fonts || []))
    }, [
        object.fonts,
        object.portfolio_main?.fonts,
        object.styles,
        reduxModalSite,
    ])

    async function getData() {
        try {
            if (!object.id) {
                setItems([])
                return
            }
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MixedContentPreview getPortfolioData')
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)
            let pageContentApiUrl = getApiUrl(`${api_url_project_mixed_media_list}?detail_id=${object.id}&content_type_id=${contentTypeData[contentType]}${edit ? '&edit=true' : ''}`, reduxAuth)
            if (languageId || languageObjectSite[reduxAuth.settings?.language!]) {
                pageContentApiUrl += `&language_id=${languageId || languageObjectSite[reduxAuth.settings?.language!]}`
                dispatch(reduxModalSetLanguageIdSite(languageId || languageObjectSite[reduxAuth.settings?.language!]))
            }
            if (process.env.NODE_ENV === 'development') console.log(pageContentApiUrl)
            axios({
                cancelToken: source.token,
                headers: refreshAxiosHeaders,
                method: 'get',
                url: pageContentApiUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (contentRef && contentRef.current) {
                        // @ts-ignore
                        contentRef.current.scrollTop = 0
                    }
                    if (edit) {
                        dispatch(reduxModalSiteSetSpecialSizeSite('mixed-media-edit'))
                    } else {
                        dispatch(reduxModalSiteSetSpecialSizeSite('mixed-media'))
                    }
                    setItems(response.data.results)
                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: pageContentApiUrl,
                        component: 'MixedContentBlock',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'MixedContentBlock',
                'getData',
            ))
        }
    }

    const fontFamilies = getGoogleFontsSite(fonts)

    return (
        <div className={`mixed-content-block-web slug-page-site${(!edit && hasTabs) ? ' tabs' : ''}${edit ? ' edit' : ''}`}>
            {fonts && (
                <>
                    {fontFamilies.custom?.length > 0 && (
                        <FontLoader
                            customUrls={fontFamilies.customUrls}
                            fontFamilies={fontFamilies.custom}
                            fontProvider='custom'
                            onActive={() => {
                                document.body.classList.add('webfonts-loaded')
                            }}
                        />
                    )}
                    {fontFamilies.google?.length > 0 && (
                        <FontLoader
                            fontProvider='google'
                            fontFamilies={fontFamilies.google}
                            onActive={() => {
                                document.body.classList.add('webfonts-loaded')
                            }}
                        />
                    )}
                </>
            )}
            <div className='mcbw-loader'>
                <ListSpinner isLoading={isLoading} />
            </div>
            <div
                className={`slug-page-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows} ${isLoading ? 'opacity' : 'no-opacity'}`}
                ref={contentRef}
                style={newPageStyles}
            >
                <div
                    className={`slug-page-content-list${languageId === 19 ? ' rtl' : ''}`}
                    style={{
                        padding: getStylesToRetrieve(reduxModalSite, undefined, object.styles, '', 'padding'),
                    }}
                >
                    {items.filter(obj => obj.active === true).map((val) => (
                        <ContentBlockSite
                            key={val.id}
                            detailId={undefined}
                            devId={undefined}
                            isEditHovered={false}
                            linkColor={linkColor}
                            object={val}
                            pageSlug={undefined}
                            stylesEdit={undefined}
                        />
                    ))}
                </div>
            </div>
            <LightboxSite />
        </div>
    )
})
