type contentTypeDataWebTypeNew = {
    about_helpcentercategory: number
    about_helpcentercategorytopic: number
    about_helpcenterprofilecategory: number
    about_helpcenterprofilecategorytopic: number
    about_helpcentertopic: number
    about_helpcentertopicitem: number
    accounting_bank: number
    accounting_banktransaction: number
    accounting_banktransactionmatch: number
    accounting_banktransfer: number
    accounting_chartofaccount: number
    accounting_customer: number
    accounting_customercontact: number
    accounting_expense: number
    accounting_expenseline: number
    accounting_idtype: number
    accounting_invoice: number
    accounting_invoiceline: number
    accounting_journalentry: number
    accounting_package: number
    accounting_payment: number
    accounting_paymentterm: number
    accounting_product: number
    accounting_productcategory: number
    accounting_productprice: number
    accounting_quotation: number
    accounting_quotationline: number
    accounting_saleslocation: number
    accounting_status: number
    accounting_stripecustomernew: number
    accounting_vendor: number
    activity_activity: number
    activity_attendance: number
    activity_course: number
    activity_coursedate: number
    activity_parent: number
    activity_period: number
    activity_registration: number
    activity_registrationgroup: number
    activity_registrationstatus: number
    activity_student: number
    adhesion_adherent: number
    adhesion_adherentcontent: number
    adhesion_adhesionpricing: number
    adhesion_contribution: number
    admin_logentry: number
    auth_group: number
    auth_permission: number
    auth_user: number
    authapi_newuser: number
    authapi_settings: number
    authapi_socialauth: number
    authapi_userpermissions: number
    builder_blocktemplate: number
    builder_blocktemplatecategory: number
    builder_contentdetail: number
    builder_formfield: number
    builder_formfieldoption: number
    builder_pagetemplate: number
    builder_pagetemplatecategory: number
    builder_privatespace: number
    builder_privatespacepage: number
    builder_tablefield: number
    contenttypes_contenttype: number
    datapool_asset: number
    datapool_attribution: number
    datapool_attributioncategory: number
    datapool_status: number
    datapool_term: number
    datapool_termcategory: number
    datapool_weblink: number
    dataset_entity: number
    event_event: number
    event_eventimage: number
    event_eventpricing: number
    event_eventprojects: number
    event_eventservices: number
    event_eventteam: number
    event_eventthemes: number
    event_participant: number
    feed_customfeedblock: number
    feed_customfeedsection: number
    feed_generalfeed: number
    food_cuisine: number
    food_foodtype: number
    food_ingredient: number
    food_note: number
    food_nutritionfact: number
    food_origin: number
    food_origincategory: number
    food_recipe: number
    food_recipeingredient: number
    food_recipenutrition: number
    food_recipestep: number
    food_selection: number
    food_shoppinglist: number
    food_shoppinglistitem: number
    food_tag: number
    food_unitofmeasure: number
    food_video: number
    main_administrativearea: number
    main_city: number
    main_civility: number
    main_component: number
    main_contentrelation: number
    main_continent: number
    main_country: number
    main_cronhelpertable: number
    main_currency: number
    main_customcategory: number
    main_customcategory2: number
    main_customcategory3: number
    main_customchoice: number
    main_day: number
    main_errorhistory: number
    main_font: number
    main_gender: number
    main_globalconversion: number
    main_globaltranslation: number
    main_language: number
    main_linkokforiframe: number
    main_mainteam: number
    main_mediumtype: number
    main_platform: number
    main_region: number
    main_socialnetwork: number
    main_text: number
    main_webhook: number
    material_material: number
    miscellanea_comment: number
    miscellanea_contactformentry: number
    miscellanea_customtable: number
    miscellanea_mail: number
    miscellanea_mailcategory: number
    miscellanea_mailconnection: number
    miscellanea_pool: number
    miscellanea_pooltransaction: number
    miscellanea_valuechangerequest: number
    miscellanea_vote: number
    miscellanea_votegroup: number
    miscellanea_voteselection: number
    notification_mailhistory: number
    notification_mailhistoryattachment: number
    notification_mailtemplate: number
    offer_offer: number
    offer_offercities: number
    offer_offercountries: number
    offer_offerimage: number
    offer_offerthemes: number
    portfolio_domain: number
    portfolio_filteroption: number
    portfolio_page: number
    portfolio_pagegroup: number
    portfolio_pagestyle: number
    portfolio_plantype: number
    portfolio_portfolio: number
    portfolio_portfoliocategory: number
    portfolio_portfoliocontentsource: number
    portfolio_portfoliohubcontent: number
    portfolio_portfoliohubcontentfilter: number
    portfolio_portfoliopagecontent: number
    portfolio_portfoliostyle: number
    portfolio_predefinedstyle: number
    portfolio_urlredirect: number
    portfolio_usergroupportfolio: number
    portfolio_userportfolio: number
    project_cssproperty: number
    project_csspropertyoption: number
    project_layout: number
    project_layoutfield: number
    project_layoutstyle: number
    project_layoutstylegroup: number
    project_layoutvariation: number
    project_mixedmediacontent: number
    project_project: number
    project_projectcities: number
    project_projectcontent: number
    project_projectcontenttype: number
    project_projectcountries: number
    project_projectlanguages: number
    project_projectpurchaselink: number
    project_projectteam: number
    project_projectthemes: number
    push_notifications_apnsdevice: number
    push_notifications_gcmdevice: number
    push_notifications_webpushdevice: number
    push_notifications_wnsdevice: number
    sales_invoicingstatus: number
    sales_packageoption: number
    sales_packageoptionitem: number
    sales_paymentmethod: number
    sales_product: number
    sales_productcities: number
    sales_productcountries: number
    sales_productimage: number
    sales_productpurchaselink: number
    sales_productteam: number
    sales_productthemes: number
    sales_productvariant: number
    sales_salesorder: number
    sales_salesorderline: number
    sales_salesorderlineoption: number
    sales_salesshipment: number
    sales_salesstatus: number
    sales_shippingagent: number
    sales_shippingcost: number
    sales_shippingservice: number
    sales_shippingzone: number
    sales_shop: number
    sales_shoporderingslot: number
    sales_shopschedule: number
    sales_stripecustomer: number
    sales_stripedispute: number
    sales_stripeinvoice: number
    sales_stripeproduct: number
    sales_stripesubscription: number
    sales_stripesubscriptionitem: number
    sales_stripetaxrate: number
    sales_stripetaxratecategory: number
    service_service: number
    service_serviceimage: number
    service_servicethemes: number
    setup_contenttypefield: number
    setup_contenttypefieldchoice: number
    setup_contenttypefieldlist: number
    setup_contenttypefieldprofile: number
    setup_contenttypefieldsubcontenttype: number
    setup_contenttypepanel: number
    setup_contenttypepanelblock: number
    setup_contenttypepanelblockfilter: number
    setup_contenttypepanellistprofile: number
    setup_contenttypesettings: number
    setup_contenttypesettingsprofile: number
    setup_contenttypesettingsvariation: number
    setup_contenttypesettingsvariationfield: number
    setup_module: number
    setup_modulefilter: number
    setup_moduletab: number
    setup_moduleview: number
    setup_subcontenttype: number
    sites_site: number
    staff_mail: number
    staff_mailblacklist: number
    staff_mailcampaign: number
    staff_mailcheck: number
    staff_mailcheck2: number
    staff_mailclicker: number
    staff_mailingaccount: number
    staff_mailreader: number
    staff_mailsent: number
    staff_prospect: number
    staff_prospectcategory: number
    staff_prospectextramail: number
    staff_prospecthistory: number
    staff_prospectproject: number
    staff_prospectsource: number
    staff_prospectstatus: number
    staff_staffbusinessunit: number
    staff_staffform: number
    staff_staffmessage: number
    staff_staffmessagecategory: number
    staff_staffmessageimage: number
    staff_staffproject: number
    staff_staffprojecthistory: number
    staff_staffstatus: number
    staff_staffwebsiteplatform: number
    staff_taskgroup: number
    staff_taskpriority: number
    staff_taskstatus: number
    staff_tasktiming: number
    userprofile_cartecategory: number
    userprofile_carteitem: number
    userprofile_profile: number
    userprofile_profilecities: number
    userprofile_profilecolors: number
    userprofile_profilecountries: number
    userprofile_profileimage: number
    userprofile_profilepermissions: number
    userprofile_profilesection: number
    userprofile_profilesectioncontent: number
    userprofile_profileselection: number
    userprofile_profilesites: number
    userprofile_profilestatus: number
    userprofile_profileteam: number
    userprofile_sociallink: number
    userprofile_theme: number
}

export type contentTypeModelTypeNew =
    'about_helpcentercategory' |
    'about_helpcentercategorytopic' |
    'about_helpcenterprofilecategory' |
    'about_helpcenterprofilecategorytopic' |
    'about_helpcentertopic' |
    'about_helpcentertopicitem' |
    'accounting_bank' |
    'accounting_banktransaction' |
    'accounting_banktransactionmatch' |
    'accounting_banktransfer' |
    'accounting_chartofaccount' |
    'accounting_customer' |
    'accounting_customercontact' |
    'accounting_expense' |
    'accounting_expenseline' |
    'accounting_idtype' |
    'accounting_invoice' |
    'accounting_invoiceline' |
    'accounting_journalentry' |
    'accounting_package' |
    'accounting_payment' |
    'accounting_paymentterm' |
    'accounting_product' |
    'accounting_productcategory' |
    'accounting_productprice' |
    'accounting_quotation' |
    'accounting_quotationline' |
    'accounting_saleslocation' |
    'accounting_status' |
    'accounting_stripecustomernew' |
    'accounting_vendor' |
    'activity_activity' |
    'activity_attendance' |
    'activity_course' |
    'activity_coursedate' |
    'activity_parent' |
    'activity_period' |
    'activity_registration' |
    'activity_registrationgroup' |
    'activity_registrationstatus' |
    'activity_student' |
    'adhesion_adherent' |
    'adhesion_adherentcontent' |
    'adhesion_adhesionpricing' |
    'adhesion_contribution' |
    'admin_logentry' |
    'auth_group' |
    'auth_permission' |
    'auth_user' |
    'authapi_newuser' |
    'authapi_settings' |
    'authapi_socialauth' |
    'authapi_userpermissions' |
    'builder_blocktemplate' |
    'builder_blocktemplatecategory' |
    'builder_contentdetail' |
    'builder_formfield' |
    'builder_formfieldoption' |
    'builder_pagetemplate' |
    'builder_pagetemplatecategory' |
    'builder_privatespace' |
    'builder_privatespacepage' |
    'builder_tablefield' |
    'contenttypes_contenttype' |
    'datapool_asset' |
    'datapool_attribution' |
    'datapool_attributioncategory' |
    'datapool_status' |
    'datapool_term' |
    'datapool_termcategory' |
    'datapool_weblink' |
    'dataset_entity' |
    'event_event' |
    'event_eventimage' |
    'event_eventpricing' |
    'event_eventprojects' |
    'event_eventservices' |
    'event_eventteam' |
    'event_eventthemes' |
    'event_participant' |
    'feed_customfeedblock' |
    'feed_customfeedsection' |
    'feed_generalfeed' |
    'food_cuisine' |
    'food_foodtype' |
    'food_ingredient' |
    'food_note' |
    'food_nutritionfact' |
    'food_origin' |
    'food_origincategory' |
    'food_recipe' |
    'food_recipeingredient' |
    'food_recipenutrition' |
    'food_recipestep' |
    'food_selection' |
    'food_shoppinglist' |
    'food_shoppinglistitem' |
    'food_tag' |
    'food_unitofmeasure' |
    'food_video' |
    'main_administrativearea' |
    'main_city' |
    'main_civility' |
    'main_component' |
    'main_contentrelation' |
    'main_continent' |
    'main_country' |
    'main_cronhelpertable' |
    'main_currency' |
    'main_customcategory' |
    'main_customcategory2' |
    'main_customcategory3' |
    'main_customchoice' |
    'main_day' |
    'main_errorhistory' |
    'main_font' |
    'main_gender' |
    'main_globalconversion' |
    'main_globaltranslation' |
    'main_language' |
    'main_linkokforiframe' |
    'main_mainteam' |
    'main_mediumtype' |
    'main_platform' |
    'main_region' |
    'main_socialnetwork' |
    'main_text' |
    'main_webhook' |
    'material_material' |
    'miscellanea_comment' |
    'miscellanea_contactformentry' |
    'miscellanea_customtable' |
    'miscellanea_mail' |
    'miscellanea_mailcategory' |
    'miscellanea_mailconnection' |
    'miscellanea_pool' |
    'miscellanea_pooltransaction' |
    'miscellanea_valuechangerequest' |
    'miscellanea_vote' |
    'miscellanea_votegroup' |
    'miscellanea_voteselection' |
    'notification_mailhistory' |
    'notification_mailhistoryattachment' |
    'notification_mailtemplate' |
    'offer_offer' |
    'offer_offercities' |
    'offer_offercountries' |
    'offer_offerimage' |
    'offer_offerthemes' |
    'portfolio_domain' |
    'portfolio_filteroption' |
    'portfolio_page' |
    'portfolio_pagegroup' |
    'portfolio_pagestyle' |
    'portfolio_plantype' |
    'portfolio_portfolio' |
    'portfolio_portfoliocategory' |
    'portfolio_portfoliocontentsource' |
    'portfolio_portfoliohubcontent' |
    'portfolio_portfoliohubcontentfilter' |
    'portfolio_portfoliopagecontent' |
    'portfolio_portfoliostyle' |
    'portfolio_predefinedstyle' |
    'portfolio_urlredirect' |
    'portfolio_usergroupportfolio' |
    'portfolio_userportfolio' |
    'project_cssproperty' |
    'project_csspropertyoption' |
    'project_layout' |
    'project_layoutfield' |
    'project_layoutstyle' |
    'project_layoutstylegroup' |
    'project_layoutvariation' |
    'project_mixedmediacontent' |
    'project_project' |
    'project_projectcities' |
    'project_projectcontent' |
    'project_projectcontenttype' |
    'project_projectcountries' |
    'project_projectlanguages' |
    'project_projectpurchaselink' |
    'project_projectteam' |
    'project_projectthemes' |
    'push_notifications_apnsdevice' |
    'push_notifications_gcmdevice' |
    'push_notifications_webpushdevice' |
    'push_notifications_wnsdevice' |
    'sales_invoicingstatus' |
    'sales_packageoption' |
    'sales_packageoptionitem' |
    'sales_paymentmethod' |
    'sales_product' |
    'sales_productcities' |
    'sales_productcountries' |
    'sales_productimage' |
    'sales_productpurchaselink' |
    'sales_productteam' |
    'sales_productthemes' |
    'sales_productvariant' |
    'sales_salesorder' |
    'sales_salesorderline' |
    'sales_salesorderlineoption' |
    'sales_salesshipment' |
    'sales_salesstatus' |
    'sales_shippingagent' |
    'sales_shippingcost' |
    'sales_shippingservice' |
    'sales_shippingzone' |
    'sales_shop' |
    'sales_shoporderingslot' |
    'sales_shopschedule' |
    'sales_stripecustomer' |
    'sales_stripedispute' |
    'sales_stripeinvoice' |
    'sales_stripeproduct' |
    'sales_stripesubscription' |
    'sales_stripesubscriptionitem' |
    'sales_stripetaxrate' |
    'sales_stripetaxratecategory' |
    'service_service' |
    'service_serviceimage' |
    'service_servicethemes' |
    'setup_contenttypefield' |
    'setup_contenttypefieldchoice' |
    'setup_contenttypefieldlist' |
    'setup_contenttypefieldprofile' |
    'setup_contenttypefieldsubcontenttype' |
    'setup_contenttypepanel' |
    'setup_contenttypepanelblock' |
    'setup_contenttypepanelblockfilter' |
    'setup_contenttypepanellistprofile' |
    'setup_contenttypesettings' |
    'setup_contenttypesettingsprofile' |
    'setup_contenttypesettingsvariation' |
    'setup_contenttypesettingsvariationfield' |
    'setup_module' |
    'setup_modulefilter' |
    'setup_moduletab' |
    'setup_moduleview' |
    'setup_subcontenttype' |
    'sites_site' |
    'staff_mail' |
    'staff_mailblacklist' |
    'staff_mailcampaign' |
    'staff_mailcheck' |
    'staff_mailcheck2' |
    'staff_mailclicker' |
    'staff_mailingaccount' |
    'staff_mailreader' |
    'staff_mailsent' |
    'staff_prospect' |
    'staff_prospectcategory' |
    'staff_prospectextramail' |
    'staff_prospecthistory' |
    'staff_prospectproject' |
    'staff_prospectsource' |
    'staff_prospectstatus' |
    'staff_staffbusinessunit' |
    'staff_staffform' |
    'staff_staffmessage' |
    'staff_staffmessagecategory' |
    'staff_staffmessageimage' |
    'staff_staffproject' |
    'staff_staffprojecthistory' |
    'staff_staffstatus' |
    'staff_staffwebsiteplatform' |
    'staff_taskgroup' |
    'staff_taskpriority' |
    'staff_taskstatus' |
    'staff_tasktiming' |
    'userprofile_cartecategory' |
    'userprofile_carteitem' |
    'userprofile_profile' |
    'userprofile_profilecities' |
    'userprofile_profilecolors' |
    'userprofile_profilecountries' |
    'userprofile_profileimage' |
    'userprofile_profilepermissions' |
    'userprofile_profilesection' |
    'userprofile_profilesectioncontent' |
    'userprofile_profileselection' |
    'userprofile_profilesites' |
    'userprofile_profilestatus' |
    'userprofile_profileteam' |
    'userprofile_sociallink' |
    'userprofile_theme'

export const contentTypeDataNew: contentTypeDataWebTypeNew = {
    about_helpcentercategory: 209,
    about_helpcentercategorytopic: 292,
    about_helpcenterprofilecategory: 295,
    about_helpcenterprofilecategorytopic: 296,
    about_helpcentertopic: 208,
    about_helpcentertopicitem: 300,
    accounting_bank: 378,
    accounting_banktransaction: 379,
    accounting_banktransactionmatch: 390,
    accounting_banktransfer: 391,
    accounting_chartofaccount: 380,
    accounting_customer: 368,
    accounting_customercontact: 389,
    accounting_expense: 381,
    accounting_expenseline: 383,
    accounting_idtype: 371,
    accounting_invoice: 376,
    accounting_invoiceline: 377,
    accounting_journalentry: 521,
    accounting_package: 386,
    accounting_payment: 392,
    accounting_paymentterm: 375,
    accounting_product: 372,
    accounting_productcategory: 387,
    accounting_productprice: 373,
    accounting_quotation: 369,
    accounting_quotationline: 374,
    accounting_saleslocation: 367,
    accounting_status: 370,
    accounting_stripecustomernew: 514,
    accounting_vendor: 382,
    activity_activity: 397,
    activity_attendance: 413,
    activity_course: 400,
    activity_coursedate: 412,
    activity_parent: 416,
    activity_period: 398,
    activity_registration: 410,
    activity_registrationgroup: 411,
    activity_registrationstatus: 469,
    activity_student: 402,
    adhesion_adherent: 340,
    adhesion_adherentcontent: 358,
    adhesion_adhesionpricing: 345,
    adhesion_contribution: 342,
    admin_logentry: 1,
    auth_group: 4,
    auth_permission: 3,
    auth_user: 2,
    authapi_newuser: 352,
    authapi_settings: 21,
    authapi_socialauth: 267,
    authapi_userpermissions: 294,
    builder_blocktemplate: 312,
    builder_blocktemplatecategory: 311,
    builder_contentdetail: 516,
    builder_formfield: 325,
    builder_formfieldoption: 324,
    builder_pagetemplate: 313,
    builder_pagetemplatecategory: 314,
    builder_privatespace: 507,
    builder_privatespacepage: 506,
    builder_tablefield: 505,
    contenttypes_contenttype: 5,
    datapool_asset: 463,
    datapool_attribution: 461,
    datapool_attributioncategory: 460,
    datapool_status: 508,
    datapool_term: 409,
    datapool_termcategory: 408,
    datapool_weblink: 472,
    dataset_entity: 462,
    event_event: 31,
    event_eventimage: 36,
    event_eventpricing: 346,
    event_eventprojects: 241,
    event_eventservices: 327,
    event_eventteam: 166,
    event_eventthemes: 239,
    event_participant: 343,
    feed_customfeedblock: 217,
    feed_customfeedsection: 210,
    feed_generalfeed: 180,
    food_cuisine: 417,
    food_foodtype: 418,
    food_ingredient: 419,
    food_note: 432,
    food_nutritionfact: 420,
    food_origin: 421,
    food_origincategory: 422,
    food_recipe: 423,
    food_recipeingredient: 424,
    food_recipenutrition: 431,
    food_recipestep: 430,
    food_selection: 433,
    food_shoppinglist: 425,
    food_shoppinglistitem: 429,
    food_tag: 428,
    food_unitofmeasure: 427,
    food_video: 426,
    main_administrativearea: 344,
    main_city: 66,
    main_civility: 517,
    main_component: 158,
    main_contentrelation: 357,
    main_continent: 67,
    main_country: 61,
    main_cronhelpertable: 193,
    main_currency: 52,
    main_customcategory: 328,
    main_customcategory2: 355,
    main_customcategory3: 356,
    main_customchoice: 304,
    main_day: 323,
    main_errorhistory: 46,
    main_font: 51,
    main_gender: 403,
    main_globalconversion: 322,
    main_globaltranslation: 257,
    main_language: 60,
    main_linkokforiframe: 297,
    main_mainteam: 319,
    main_mediumtype: 56,
    main_platform: 38,
    main_region: 286,
    main_socialnetwork: 41,
    main_text: 54,
    main_webhook: 351,
    material_material: 75,
    miscellanea_comment: 509,
    miscellanea_contactformentry: 474,
    miscellanea_customtable: 438,
    miscellanea_mail: 473,
    miscellanea_mailcategory: 501,
    miscellanea_mailconnection: 502,
    miscellanea_pool: 512,
    miscellanea_pooltransaction: 513,
    miscellanea_valuechangerequest: 504,
    miscellanea_vote: 406,
    miscellanea_votegroup: 405,
    miscellanea_voteselection: 407,
    notification_mailhistory: 470,
    notification_mailhistoryattachment: 519,
    notification_mailtemplate: 471,
    offer_offer: 87,
    offer_offercities: 245,
    offer_offercountries: 242,
    offer_offerimage: 86,
    offer_offerthemes: 243,
    portfolio_domain: 349,
    portfolio_filteroption: 302,
    portfolio_page: 90,
    portfolio_pagegroup: 385,
    portfolio_pagestyle: 95,
    portfolio_plantype: 316,
    portfolio_portfolio: 92,
    portfolio_portfoliocategory: 177,
    portfolio_portfoliocontentsource: 172,
    portfolio_portfoliohubcontent: 301,
    portfolio_portfoliohubcontentfilter: 303,
    portfolio_portfoliopagecontent: 174,
    portfolio_portfoliostyle: 89,
    portfolio_predefinedstyle: 384,
    portfolio_urlredirect: 321,
    portfolio_usergroupportfolio: 354,
    portfolio_userportfolio: 326,
    project_cssproperty: 99,
    project_csspropertyoption: 353,
    project_layout: 96,
    project_layoutfield: 202,
    project_layoutstyle: 105,
    project_layoutstylegroup: 298,
    project_layoutvariation: 203,
    project_mixedmediacontent: 260,
    project_project: 110,
    project_projectcities: 224,
    project_projectcontent: 113,
    project_projectcontenttype: 108,
    project_projectcountries: 222,
    project_projectlanguages: 219,
    project_projectpurchaselink: 100,
    project_projectteam: 107,
    project_projectthemes: 220,
    push_notifications_apnsdevice: 189,
    push_notifications_gcmdevice: 187,
    push_notifications_webpushdevice: 188,
    push_notifications_wnsdevice: 190,
    sales_invoicingstatus: 515,
    sales_packageoption: 284,
    sales_packageoptionitem: 283,
    sales_paymentmethod: 120,
    sales_product: 119,
    sales_productcities: 309,
    sales_productcountries: 308,
    sales_productimage: 130,
    sales_productpurchaselink: 124,
    sales_productteam: 194,
    sales_productthemes: 248,
    sales_productvariant: 129,
    sales_salesorder: 163,
    sales_salesorderline: 162,
    sales_salesorderlineoption: 366,
    sales_salesshipment: 361,
    sales_salesstatus: 348,
    sales_shippingagent: 207,
    sales_shippingcost: 204,
    sales_shippingservice: 205,
    sales_shippingzone: 206,
    sales_shop: 363,
    sales_shoporderingslot: 365,
    sales_shopschedule: 364,
    sales_stripecustomer: 122,
    sales_stripedispute: 337,
    sales_stripeinvoice: 338,
    sales_stripeproduct: 123,
    sales_stripesubscription: 350,
    sales_stripesubscriptionitem: 388,
    sales_stripetaxrate: 360,
    sales_stripetaxratecategory: 359,
    service_service: 215,
    service_serviceimage: 212,
    service_servicethemes: 276,
    setup_contenttypefield: 449,
    setup_contenttypefieldchoice: 459,
    setup_contenttypefieldlist: 454,
    setup_contenttypefieldprofile: 451,
    setup_contenttypefieldsubcontenttype: 522,
    setup_contenttypepanel: 452,
    setup_contenttypepanelblock: 453,
    setup_contenttypepanelblockfilter: 499,
    setup_contenttypepanellistprofile: 503,
    setup_contenttypesettings: 446,
    setup_contenttypesettingsprofile: 450,
    setup_contenttypesettingsvariation: 447,
    setup_contenttypesettingsvariationfield: 448,
    setup_module: 440,
    setup_modulefilter: 444,
    setup_moduletab: 443,
    setup_moduleview: 441,
    setup_subcontenttype: 510,
    sites_site: 8,
    staff_mail: 477,
    staff_mailblacklist: 489,
    staff_mailcampaign: 478,
    staff_mailcheck: 488,
    staff_mailcheck2: 487,
    staff_mailclicker: 486,
    staff_mailingaccount: 479,
    staff_mailreader: 485,
    staff_mailsent: 484,
    staff_prospect: 476,
    staff_prospectcategory: 495,
    staff_prospectextramail: 491,
    staff_prospecthistory: 490,
    staff_prospectproject: 492,
    staff_prospectsource: 482,
    staff_prospectstatus: 494,
    staff_staffbusinessunit: 362,
    staff_staffform: 518,
    staff_staffmessage: 265,
    staff_staffmessagecategory: 315,
    staff_staffmessageimage: 266,
    staff_staffproject: 262,
    staff_staffprojecthistory: 520,
    staff_staffstatus: 318,
    staff_staffwebsiteplatform: 317,
    staff_taskgroup: 498,
    staff_taskpriority: 497,
    staff_taskstatus: 496,
    staff_tasktiming: 500,
    userprofile_cartecategory: 191,
    userprofile_carteitem: 192,
    userprofile_profile: 155,
    userprofile_profilecities: 227,
    userprofile_profilecolors: 293,
    userprofile_profilecountries: 235,
    userprofile_profileimage: 139,
    userprofile_profilepermissions: 282,
    userprofile_profilesection: 195,
    userprofile_profilesectioncontent: 258,
    userprofile_profileselection: 290,
    userprofile_profilesites: 279,
    userprofile_profilestatus: 152,
    userprofile_profileteam: 179,
    userprofile_sociallink: 142,
    userprofile_theme: 147
}

export const contentTypeSettingsData: contentTypeDataWebTypeNew = {
    about_helpcentercategory: 22,
    about_helpcentercategorytopic: 23,
    about_helpcenterprofilecategory: 24,
    about_helpcenterprofilecategorytopic: 25,
    about_helpcentertopic: 26,
    about_helpcentertopicitem: 27,
    accounting_bank: 28,
    accounting_banktransaction: 29,
    accounting_banktransactionmatch: 30,
    accounting_banktransfer: 31,
    accounting_chartofaccount: 32,
    accounting_customer: 1,
    accounting_customercontact: 33,
    accounting_expense: 34,
    accounting_expenseline: 35,
    accounting_idtype: 36,
    accounting_invoice: 2,
    accounting_invoiceline: 37,
    accounting_journalentry: 332,
    accounting_package: 38,
    accounting_payment: 39,
    accounting_paymentterm: 40,
    accounting_product: 41,
    accounting_productcategory: 42,
    accounting_productprice: 3,
    accounting_quotation: 43,
    accounting_quotationline: 44,
    accounting_saleslocation: 45,
    accounting_status: 46,
    accounting_stripecustomernew: 323,
    accounting_vendor: 47,
    activity_activity: 4,
    activity_attendance: 5,
    activity_course: 6,
    activity_coursedate: 7,
    activity_parent: 8,
    activity_period: 9,
    activity_registration: 10,
    activity_registrationgroup: 48,
    activity_registrationstatus: 277,
    activity_student: 12,
    adhesion_adherent: 13,
    adhesion_adherentcontent: 49,
    adhesion_adhesionpricing: 50,
    adhesion_contribution: 14,
    admin_logentry: 51,
    auth_group: 52,
    auth_permission: 53,
    auth_user: 54,
    authapi_newuser: 55,
    authapi_settings: 56,
    authapi_socialauth: 57,
    authapi_userpermissions: 58,
    builder_blocktemplate: 61,
    builder_blocktemplatecategory: 62,
    builder_contentdetail: 325,
    builder_formfield: 63,
    builder_formfieldoption: 64,
    builder_pagetemplate: 65,
    builder_pagetemplatecategory: 66,
    builder_privatespace: 314,
    builder_privatespacepage: 315,
    builder_tablefield: 313,
    contenttypes_contenttype: 69,
    datapool_asset: 271,
    datapool_attribution: 268,
    datapool_attributioncategory: 269,
    datapool_status: 316,
    datapool_term: 15,
    datapool_termcategory: 16,
    datapool_weblink: 280,
    dataset_entity: 270,
    event_event: 17,
    event_eventimage: 71,
    event_eventpricing: 72,
    event_eventprojects: 73,
    event_eventservices: 74,
    event_eventteam: 75,
    event_eventthemes: 76,
    event_participant: 77,
    feed_customfeedblock: 78,
    feed_customfeedsection: 79,
    feed_generalfeed: 80,
    food_cuisine: 81,
    food_foodtype: 82,
    food_ingredient: 83,
    food_note: 84,
    food_nutritionfact: 85,
    food_origin: 86,
    food_origincategory: 87,
    food_recipe: 88,
    food_recipeingredient: 89,
    food_recipenutrition: 90,
    food_recipestep: 91,
    food_selection: 92,
    food_shoppinglist: 93,
    food_shoppinglistitem: 94,
    food_tag: 95,
    food_unitofmeasure: 96,
    food_video: 97,
    main_administrativearea: 98,
    main_city: 99,
    main_civility: 326,
    main_component: 100,
    main_contentrelation: 101,
    main_continent: 102,
    main_country: 103,
    main_cronhelpertable: 104,
    main_currency: 105,
    main_customcategory: 106,
    main_customcategory2: 107,
    main_customcategory3: 108,
    main_customchoice: 109,
    main_day: 110,
    main_errorhistory: 111,
    main_font: 112,
    main_gender: 113,
    main_globalconversion: 114,
    main_globaltranslation: 115,
    main_language: 116,
    main_linkokforiframe: 117,
    main_mainteam: 119,
    main_mediumtype: 120,
    main_platform: 121,
    main_region: 122,
    main_socialnetwork: 123,
    main_text: 124,
    main_webhook: 125,
    material_material: 18,
    miscellanea_comment: 317,
    miscellanea_contactformentry: 282,
    miscellanea_customtable: 129,
    miscellanea_mail: 281,
    miscellanea_mailcategory: 309,
    miscellanea_mailconnection: 310,
    miscellanea_pool: 321,
    miscellanea_pooltransaction: 322,
    miscellanea_valuechangerequest: 312,
    miscellanea_vote: 130,
    miscellanea_votegroup: 131,
    miscellanea_voteselection: 132,
    notification_mailhistory: 278,
    notification_mailhistoryattachment: 330,
    notification_mailtemplate: 279,
    offer_offer: 134,
    offer_offercities: 135,
    offer_offercountries: 136,
    offer_offerimage: 137,
    offer_offerthemes: 138,
    portfolio_domain: 139,
    portfolio_filteroption: 141,
    portfolio_page: 142,
    portfolio_pagegroup: 143,
    portfolio_pagestyle: 144,
    portfolio_plantype: 145,
    portfolio_portfolio: 146,
    portfolio_portfoliocategory: 147,
    portfolio_portfoliocontentsource: 148,
    portfolio_portfoliohubcontent: 149,
    portfolio_portfoliohubcontentfilter: 150,
    portfolio_portfoliopagecontent: 151,
    portfolio_portfoliostyle: 152,
    portfolio_predefinedstyle: 153,
    portfolio_urlredirect: 154,
    portfolio_usergroupportfolio: 155,
    portfolio_userportfolio: 156,
    project_cssproperty: 157,
    project_csspropertyoption: 158,
    project_layout: 159,
    project_layoutfield: 160,
    project_layoutstyle: 161,
    project_layoutstylegroup: 162,
    project_layoutvariation: 163,
    project_mixedmediacontent: 164,
    project_project: 165,
    project_projectcities: 166,
    project_projectcontent: 167,
    project_projectcontenttype: 168,
    project_projectcountries: 169,
    project_projectlanguages: 170,
    project_projectpurchaselink: 171,
    project_projectteam: 172,
    project_projectthemes: 173,
    push_notifications_apnsdevice: 174,
    push_notifications_gcmdevice: 175,
    push_notifications_webpushdevice: 176,
    push_notifications_wnsdevice: 177,
    sales_invoicingstatus: 324,
    sales_packageoption: 178,
    sales_packageoptionitem: 179,
    sales_paymentmethod: 180,
    sales_product: 19,
    sales_productcities: 181,
    sales_productcountries: 182,
    sales_productimage: 183,
    sales_productpurchaselink: 184,
    sales_productteam: 185,
    sales_productthemes: 186,
    sales_productvariant: 187,
    sales_salesorder: 20,
    sales_salesorderline: 188,
    sales_salesorderlineoption: 189,
    sales_salesshipment: 190,
    sales_salesstatus: 191,
    sales_shippingagent: 192,
    sales_shippingcost: 193,
    sales_shippingservice: 194,
    sales_shippingzone: 195,
    sales_shop: 196,
    sales_shoporderingslot: 197,
    sales_shopschedule: 198,
    sales_stripecustomer: 199,
    sales_stripedispute: 201,
    sales_stripeinvoice: 202,
    sales_stripeproduct: 203,
    sales_stripesubscription: 204,
    sales_stripesubscriptionitem: 205,
    sales_stripetaxrate: 206,
    sales_stripetaxratecategory: 207,
    service_service: 208,
    service_serviceimage: 209,
    service_servicethemes: 210,
    setup_contenttypefield: 255,
    setup_contenttypefieldchoice: 267,
    setup_contenttypefieldlist: 260,
    setup_contenttypefieldprofile: 258,
    setup_contenttypefieldsubcontenttype: 333,
    setup_contenttypepanel: 264,
    setup_contenttypepanelblock: 265,
    setup_contenttypepanelblockfilter: 307,
    setup_contenttypepanellistprofile: 311,
    setup_contenttypesettings: 212,
    setup_contenttypesettingsprofile: 259,
    setup_contenttypesettingsvariation: 253,
    setup_contenttypesettingsvariationfield: 254,
    setup_module: 215,
    setup_modulefilter: 216,
    setup_moduletab: 217,
    setup_moduleview: 218,
    setup_subcontenttype: 319,
    sites_site: 219,
    staff_mail: 287,
    staff_mailblacklist: 288,
    staff_mailcampaign: 289,
    staff_mailcheck: 290,
    staff_mailcheck2: 291,
    staff_mailclicker: 292,
    staff_mailingaccount: 293,
    staff_mailreader: 294,
    staff_mailsent: 295,
    staff_prospect: 284,
    staff_prospectcategory: 302,
    staff_prospectextramail: 285,
    staff_prospecthistory: 286,
    staff_prospectproject: 300,
    staff_prospectsource: 298,
    staff_prospectstatus: 303,
    staff_staffbusinessunit: 230,
    staff_staffform: 327,
    staff_staffmessage: 231,
    staff_staffmessagecategory: 232,
    staff_staffmessageimage: 233,
    staff_staffproject: 234,
    staff_staffprojecthistory: 331,
    staff_staffstatus: 235,
    staff_staffwebsiteplatform: 236,
    staff_taskgroup: 306,
    staff_taskpriority: 304,
    staff_taskstatus: 305,
    staff_tasktiming: 308,
    userprofile_cartecategory: 237,
    userprofile_carteitem: 238,
    userprofile_profile: 239,
    userprofile_profilecities: 240,
    userprofile_profilecolors: 241,
    userprofile_profilecountries: 242,
    userprofile_profileimage: 243,
    userprofile_profilepermissions: 244,
    userprofile_profilesection: 245,
    userprofile_profilesectioncontent: 246,
    userprofile_profileselection: 247,
    userprofile_profilesites: 249,
    userprofile_profilestatus: 250,
    userprofile_profileteam: 21,
    userprofile_sociallink: 251,
    userprofile_theme: 252
}
