// react components
import React from 'react'
import {
    IonAvatar,
} from '@ionic/react'

// components
import {
    ImageHelper,
    LinkHelper,
    MainHashtag,
    MainCountryFlag,
} from 'components'

// serializers
import {
    ProjectTeamListSerializer,
} from 'serializers/web'

// props
type TeamListBigBlockMobileProps = {
    edit: boolean
    object: ProjectTeamListSerializer
}

// main
export const TeamListBigBlockMobile: React.FC<TeamListBigBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    let name = object.name
    if (object.member) {
        name = object.member.name
    }

    if (!object.member) {
        return (
            <div className='team-list-big-block'>
                <p className='mo-list-block-name-mobile'>{name}</p>
                <div className='tlbb-chips'>
                    {object.roles && (
                        <MainHashtag
                            edit={edit}
                            label={object.roles}
                        />
                    )}
                </div>
            </div>
        )
    } else if (object.member) {

        const member = object.member
        const absolute_url = member.get_absolute_url

        return (
            <div className='team-list-big-block'>
                <div className='tlbb-wrap'>
                    <LinkHelper
                        className='tlbb-profile-pic'
                        edit={edit}
                        to={absolute_url}
                    >
                        <IonAvatar className='tlbb-image-link'>
                            <ImageHelper
                                alt={name}
                                className='tlbb-image'
                                dominant_color={undefined}
                                lazyMobile={false}
                                src={member.get_image_lq}
                            />
                        </IonAvatar>
                    </LinkHelper>
                    <div className='tlbb-info'>
                        <LinkHelper
                            edit={edit}
                            to={absolute_url}
                        >
                            <p className='mo-list-block-name-mobile'>{name}</p>
                        </LinkHelper>
                        <div className='tlbb-chips'>
                            {object.roles && (
                                <MainHashtag
                                    edit={edit}
                                    label={object.roles}
                                />
                            )}
                        </div>
                        <div className='tlbb-country'>
                            {member.countries && member.countries.length > 0 && member.countries.map((val) => (
                                <MainCountryFlag
                                    key={val.id}
                                    edit={edit}
                                    object={val}
                                />
                            ))}
                            {member.cities && member.cities.length > 0 && member.cities.map((val) => (
                                <MainHashtag
                                    key={`cities-${val.id}`}
                                    edit={edit}
                                    label={val.name}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null
})
