// react components
import scrollIntoView from 'scroll-into-view'

// main
export function scrollToSection(
    id: number | string,
    navbarHeight: number,
) {
    const contentNewChild = document.querySelector(`[id^="content-new-child-${id}"]`)
    if (contentNewChild) {
        // @ts-ignore
        scrollIntoView(contentNewChild, {
            align: {
                left: 0,
                leftOffset: 0,
                top: 0,
                topOffset: navbarHeight + 50,
            },
        })
        return
    }
    const contentNewSection = document.getElementById(`content-new-section-${id}`)
    if (contentNewSection) {
        scrollIntoView(contentNewSection, {
            align: {
                left: 0,
                leftOffset: 0,
                top: 0,
                topOffset: 0,
            },
        })
        return
    }
    const contentSection = document.getElementById(`content-section-${id}`)
    if (contentSection) {
        scrollIntoView(contentSection, {
            align: {
                left: 0,
                leftOffset: 0,
                top: 0,
                topOffset: 0,
            },
        })
    }
}
