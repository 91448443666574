// react components
import React from 'react'
import {
    RefresherEventDetail,
} from '@ionic/core'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    errorMessageToSkip,
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'


export async function getInfiniteListMobile(
    apiUrl: string,
    reduxAuth: reduxAuthState,
    dispatch: React.Dispatch<any>,
    items: any[],
    setItems: React.Dispatch<any>,
    setIsLoading: React.Dispatch<boolean>,
    setNextUrl: React.Dispatch<any>,
    axiosCancelToken: CancelTokenSource | undefined,
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>,
    setDisableInfiniteScroll: React.Dispatch<any>,
    setHasMore: React.Dispatch<any>,
    component: string,
    changingView: boolean,
    refreshEvent: CustomEvent<RefresherEventDetail> | undefined,
    customFunction?: any,
    searchValue?: any,
    noEmptyItems?: boolean,
) {

    if (!apiUrl) return

    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    setDisableInfiniteScroll(true)

    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getInfiniteListMobile`)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    if (changingView) {
        if (!noEmptyItems) {
            setItems([])
        }
        setHasMore(true)
        setNextUrl('')
    }

    if (process.env.NODE_ENV === 'development') console.log(apiUrl, component)
    setIsLoading(true)

    let wto: any

    axios({
        headers: refreshAxiosHeaders,
        method: 'get',
        url: apiUrl,
        cancelToken: source.token,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            if (refreshEvent && refreshEvent.detail) {
                refreshEvent.detail.complete()
            }
            if (changingView) {
                setItems(response.data.results)
            } else {
                setItems([...items, ...response.data.results])
            }
            clearTimeout(wto)
            wto = setTimeout(() => {
                setNextUrl(response.data.next)
                setHasMore(response.data.next !== null)
                setDisableInfiniteScroll(response.data.next === null)
                setIsLoading(false)
            }, 100)
            if (customFunction) customFunction(response.data.results, searchValue)
        })
        .catch((error) => {
            if (error.message === 'axios canceled') {
                if (process.env.NODE_ENV === 'development') console.log(error)
                return
            }
            setIsLoading(false)
            if (errorMessageToSkip.includes(error.message)) {
                setDisableInfiniteScroll(false)
            } else if (errorMessageToSkip.includes(error)) {
                setDisableInfiniteScroll(false)
            } else {
                setDisableInfiniteScroll(true)
            }
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'getInfiniteListMobile',
            })
        })
}
