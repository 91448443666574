// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    IonReorderGroup,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    Loader,
} from 'components'

// data
import {
    api_url_profile_section_form_list,
    api_url_profile_section_form_patch,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    OrganiseSectionFormItem,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailObject,
} from 'services'

// props
type OrganiseSectionFormProps = {
    profileId: string
    refreshData: () => void
    setIsOpen: React.Dispatch<boolean>
}

// main
export const OrganiseSectionForm: React.FC<OrganiseSectionFormProps> = React.memo(({
    profileId,
    refreshData,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<any[]>([])

    useEffect(() => {
        onGetListData()
    }, [])

    function onGetListData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_profile_section_form_list}${profileId}/`, reduxAuth),
                component: 'OrganiseSectionForm',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'OrganiseSectionForm',
                'onGetListData',
            ))
        }
    }

    function onReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'OrganiseSectionForm',
                'onReorder',
            ))
        }
    }

    async function onSortEnd(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'OrganiseSectionForm onSortEnd')
            const item = items[event.detail.from]
            const itemTo = items![event.detail.to]
            setItems(arrayMoveImmutable(items, event.detail.from, event.detail.to))
            const formData: any = new FormData()
            formData.append('sibling_id', itemTo.id)
            const putUrl = getApiUrl(`${api_url_profile_section_form_patch}${item.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'OrganiseSectionForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'OrganiseSectionForm',
                'onSortEnd',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class OrganiseSectionForm'
            classes={{ paper: 'mo-dialog-wrapper fix-height fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => {
                setIsOpen(false)
                refreshData()
            }}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[8683]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => {
                            setIsOpen(false)
                            refreshData()
                        }}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <IonReorderGroup
                    disabled={false}
                    onIonItemReorder={onReorder}
                >
                    {items.map((val) => (
                        <OrganiseSectionFormItem
                            key={val.id}
                            item={val}
                        />
                    ))}
                </IonReorderGroup>
            </DialogContent>
            {isLoading && (
                <Loader isOpen />
            )}
        </Dialog>
    )
})
