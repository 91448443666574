// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    CountViewBlock,
    CreditShortInfo,
    DescriptionDetailBlock,
    HeaderDetailBlock,
    ImageHelper,
    LocationDetailBlock,
    MainHashtag,
} from 'components'

// containers
import {
    DetailListContainerNew,
} from 'containers'

// data
import {
    api_url_service_image_list,
    defaultReduxState,
    view_url_service_detail,
} from 'data'

// pages
import {
    MixedContentBlock,
} from 'pages'

// serializers
import {
    MainTabHelperType,
    ServiceDetailSerializer,
    // ServiceDetailTeamHelperSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDateWithType,
} from 'services'

// props
type ServiceDetailBlockProps = {
    edit: boolean
    inModal?: boolean
    languageId: number | undefined
    object: ServiceDetailSerializer
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const ServiceDetailBlock: React.FC<ServiceDetailBlockProps> = React.memo(({
    edit,
    inModal,
    languageId,
    object,
    setLanguageId,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setTabValue({
            id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
            title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
        })
    }, [
        object?.id,
    ])

    const [tabValue, setTabValue] = useState<MainTabHelperType>({
        id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
        title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
    })

    const tabArray: MainTabHelperType[] = []
    if (object.medium_type?.is_custom_page || object.has_images) {
        tabArray.push(
            {
                id: 'cover',
                title: reduxText[4359],
            },
        )
    }
    if (object.medium_type?.is_custom_page) {
        tabArray.push(
            {
                id: 'custom-page',
                title: reduxText[8489],
            },
        )
    }
    if (object.has_images) {
        tabArray.push(
            {
                id: 'images',
                isGallery: true,
                listApiUrl: getApiUrl(`${api_url_service_image_list}?service_id=${object.id}`, reduxAuth),
                title: reduxText[1625],
            },
        )
    }

    const hasTabs = !edit && tabArray.length > 0

    // const duplicateProfile: any = []
    // let creators: ServiceDetailTeamHelperSerializer[] = []
    // let labels: ServiceDetailTeamHelperSerializer[] = []
    // if (object.team_list && object.team_list.creator && object.team_list.creator.length > 0) {
    //     creators = object.team_list.creator
    //     object.team_list.creator.map((val) => {
    //         if (val.member) duplicateProfile.push(val.member.id)
    //         return false
    //     })
    // }
    // if (object.team_list && object.team_list.label && object.team_list.label.length > 0) {
    //     labels = object.team_list.label
    //     object.team_list.label.map((val) => {
    //         if (val.member) duplicateProfile.push(val.member.id)
    //         return false
    //     })
    // }

    return (
        <div className={`main-detail-block service-detail-block-web${tabValue.id === 'custom-page' ? ' medium-type-custom' : ''}${edit ? ' edit' : ''}`}>
            <div className={`mdb-left${hasTabs ? ' tabs' : ''}${tabValue.tableHeaderComponent ? ' table' : ''}`}>
                {hasTabs && (
                    <div className='mdb-tabs'>
                        {tabArray.map(val => (
                            <div
                                key={val.id}
                                className={`mdb-buttons${val.id === tabValue.id ? ' active' : ''}`}
                                onClick={() => setTabValue(val)}
                            >
                                <span className='mdb-buttons-name'>{val.title}</span>
                            </div>
                        ))}
                    </div>
                )}
                {(tabValue.listComponent || tabValue.isGallery)
                    ? (
                        <DetailListContainerNew
                            isGallery={tabValue.isGallery}
                            inModal={inModal}
                            listApiUrl={tabValue.listApiUrl!}
                            listComponent={tabValue.listComponent}
                            tableHeaderComponent={tabValue.tableHeaderComponent}
                        />
                    ) : (
                        <React.Fragment>
                            {tabValue.id === 'custom-page'
                                ? (
                                    <MixedContentBlock
                                        contentType='service'
                                        edit={edit}
                                        hasTabs={hasTabs}
                                        languageId={languageId}
                                        object={object}
                                    />
                                ) : (
                                    <ImageHelper
                                        alt={object.name}
                                        className='mdb-cover-image'
                                        dominant_color={object.get_dominant_color}
                                        lightBox
                                        lightBoxSrc={object.get_image_hq}
                                        src={object.get_image_hq}
                                    />
                                )
                            }
                        </React.Fragment>
                    )
                }
            </div>
            <div className={`mdb-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <HeaderDetailBlock
                    contentType='service'
                    edit={edit}
                    languageId={languageId}
                    object={object}
                    setLanguageId={setLanguageId}
                />
                {/* <HeaderDetailBlock
                    contentType='service'
                    edit={edit}
                    languageId={languageId}
                    object={object}
                    setLanguageId={setLanguageId}
                >
                    {object.profile && !duplicateProfile.includes(object.profile.id) && (
                        <ProfileShortInfoWeb
                            edit={edit}
                            object={object.profile}
                        />
                    )}
                    {creators.map((val) => (
                        <CreditShortInfo
                            key={val.id}
                            edit={edit}
                            object={val}
                        />
                    ))}
                    {labels.map((val) => (
                        <div
                            key={val.id}
                            className='sdbw-label'
                        >
                            <CreditShortInfo
                                edit={edit}
                                object={val}
                            />
                        </div>
                    ))}
                </HeaderDetailBlock> */}
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.description}
                />
                <DescriptionDetailBlock
                    languageId={languageId}
                    text={object.price}
                />
                {object.day && (
                    <DescriptionDetailBlock
                        languageId={languageId}
                        text={`${object.day?.name} - ${getDateWithType(reduxAuth, object.time_start, 'tt')}${object.time_end ? ` - ${getDateWithType(reduxAuth, object.time_end, 'tt')}` : ''}`}
                    />
                )}
                <LocationDetailBlock
                    address_json={object.address_json}
                    edit={edit}
                    // @ts-ignore
                    object={object}
                    reduced
                />
                {object.child_list?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[8546]}>
                        <div className='sdbw-schedule'>
                            {object.child_list?.map(val => (
                                <div
                                    key={val.id}
                                    className='sdbw-schedule-wrap'
                                >
                                    <p>{`${val.day?.name} - ${getDateWithType(reduxAuth, val.time_start, 'tt')}${val.time_end ? ` - ${getDateWithType(reduxAuth, val.time_end, 'tt')}` : ''}`}</p>
                                    {val.child_text && <p>{val.child_text}</p>}
                                    {val.child_text2 && <p>{val.child_text2}</p>}
                                    {val.address_json && val.address_json.length > 0 && val.address_json?.map((val, i) => (
                                        <span key={i}>
                                            {val.description}
                                        </span>
                                    ))}
                                    {val.team?.length! > 0 && <p>{val.team!.map((val2: any) => val2.name).join(' - ')}</p>}
                                </div>
                            ))}
                        </div>
                    </DescriptionDetailBlock>
                )}
                {object.link_url && (
                    <DescriptionDetailBlock title={reduxText[8450]}>
                        <a
                            href={object.link_url}
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            {object.link_url}
                        </a>
                    </DescriptionDetailBlock>
                )}
                {object.languages?.length! > 0 && (
                    <DescriptionDetailBlock
                        title={reduxText[4825]}
                    >
                        {object.languages!.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.team_list?.team?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[4917]}>
                        {object.team_list!.team!.map((val, i) => (
                            i < 3
                                ? (
                                    <CreditShortInfo
                                        key={val.id}
                                        edit={edit}
                                        object={val}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='sdbw-section-button'
                                        edit={edit}
                                        fill='clear'
                                        size='small'
                                        text={reduxText[5606]}
                                        to={`${view_url_service_detail}${object.id}/${object.slug}/team/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
