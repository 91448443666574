// data
import {
    select_url_portfolio_content_source_activity,
    select_url_portfolio_content_source_adherent,
    select_url_portfolio_content_source_artwork,
    select_url_portfolio_content_source_carte,
    select_url_portfolio_content_source_carte_category,
    select_url_portfolio_content_source_carte_item,
    select_url_portfolio_content_source_contribution,
    select_url_portfolio_content_source_currency,
    select_url_portfolio_content_source_datapool_asset,
    select_url_portfolio_content_source_entity,
    select_url_portfolio_content_source_event,
    select_url_portfolio_content_source_event_image,
    select_url_portfolio_content_source_event_team,
    select_url_portfolio_content_source_general_feed,
    select_url_portfolio_content_source_hub_content,
    select_url_portfolio_content_source_language,
    select_url_portfolio_content_source_material,
    select_url_portfolio_content_source_member,
    select_url_portfolio_content_source_moduleview,
    select_url_portfolio_content_source_offer,
    select_url_portfolio_content_source_page,
    select_url_portfolio_content_source_press,
    select_url_portfolio_content_source_product,
    select_url_portfolio_content_source_product_image,
    select_url_portfolio_content_source_profile,
    select_url_portfolio_content_source_profile_image,
    select_url_portfolio_content_source_profile_section,
    select_url_portfolio_content_source_project,
    select_url_portfolio_content_source_project_team,
    select_url_portfolio_content_source_region,
    select_url_portfolio_content_source_resource,
    select_url_portfolio_content_source_service,
    select_url_portfolio_content_source_service_child,
    select_url_portfolio_content_source_service_image,
} from 'data'

export const contentSourceApiUrls: {
    [key: number]: {
        apiUrl: string,
        hasNoActive?: boolean,
        layout?: 'get_image_lq' | 'get_image_xs',
        usePortfolioId?: boolean,
    }
} = {
    1: {
        apiUrl: select_url_portfolio_content_source_project,
        layout: 'get_image_lq',
    },
    2: {
        apiUrl: select_url_portfolio_content_source_artwork,
        layout: 'get_image_lq',
    },
    5: {
        apiUrl: select_url_portfolio_content_source_event,
        layout: 'get_image_lq',
    },
    6: {
        apiUrl: select_url_portfolio_content_source_event,
        layout: 'get_image_lq',
    },
    7: {
        apiUrl: select_url_portfolio_content_source_material,
        layout: 'get_image_lq',
    },
    8: {
        apiUrl: select_url_portfolio_content_source_material,
        layout: 'get_image_lq',
    },
    9: {
        apiUrl: select_url_portfolio_content_source_offer,
        layout: 'get_image_lq',
    },
    10: {
        apiUrl: select_url_portfolio_content_source_offer,
        layout: 'get_image_lq',
    },
    11: {
        apiUrl: select_url_portfolio_content_source_profile,
        layout: 'get_image_xs',
    },
    12: {
        apiUrl: select_url_portfolio_content_source_member,
        layout: 'get_image_xs',
    },
    13: {
        apiUrl: select_url_portfolio_content_source_product,
        layout: 'get_image_lq',
    },
    14: {
        apiUrl: select_url_portfolio_content_source_product,
        layout: 'get_image_lq',
    },
    15: {
        apiUrl: select_url_portfolio_content_source_member,
        layout: 'get_image_xs',
    },
    16: {
        apiUrl: select_url_portfolio_content_source_press,
        layout: 'get_image_lq',
    },
    18: {
        apiUrl: select_url_portfolio_content_source_page,
        usePortfolioId: true,
    },
    21: {
        apiUrl: select_url_portfolio_content_source_carte,
    },
    23: {
        apiUrl: select_url_portfolio_content_source_event_image,
        layout: 'get_image_lq',
    },
    24: {
        apiUrl: select_url_portfolio_content_source_event_team,
        layout: 'get_image_xs',
    },
    25: {
        apiUrl: select_url_portfolio_content_source_event_team,
        layout: 'get_image_xs',
    },
    27: {
        apiUrl: select_url_portfolio_content_source_service,
        layout: 'get_image_lq',
    },
    28: {
        apiUrl: select_url_portfolio_content_source_service,
        layout: 'get_image_lq',
    },
    29: {
        apiUrl: select_url_portfolio_content_source_profile_section,
    },
    30: {
        apiUrl: select_url_portfolio_content_source_profile_section,
    },
    32: {
        apiUrl: select_url_portfolio_content_source_carte_category,
    },
    34: {
        apiUrl: select_url_portfolio_content_source_carte_item,
    },
    35: {
        apiUrl: select_url_portfolio_content_source_page,
        usePortfolioId: true,
    },
    36: {
        apiUrl: select_url_portfolio_content_source_project_team,
        layout: 'get_image_xs',
    },
    37: {
        apiUrl: select_url_portfolio_content_source_profile_image,
        layout: 'get_image_lq',
    },
    38: {
        apiUrl: select_url_portfolio_content_source_language,
        hasNoActive: true,
    },
    39: {
        apiUrl: select_url_portfolio_content_source_language,
        hasNoActive: true,
    },
    40: {
        apiUrl: select_url_portfolio_content_source_resource,
        layout: 'get_image_lq',
    },
    41: {
        apiUrl: select_url_portfolio_content_source_region,
        hasNoActive: true,
    },
    42: {
        apiUrl: select_url_portfolio_content_source_region,
        hasNoActive: true,
    },
    43: {
        apiUrl: select_url_portfolio_content_source_project,
        layout: 'get_image_lq',
    },
    44: {
        apiUrl: select_url_portfolio_content_source_product_image,
        layout: 'get_image_lq',
    },
    45: {
        apiUrl: select_url_portfolio_content_source_profile_image,
        layout: 'get_image_lq',
    },
    46: {
        apiUrl: select_url_portfolio_content_source_product_image,
        layout: 'get_image_lq',
    },
    47: {
        apiUrl: select_url_portfolio_content_source_event_image,
        layout: 'get_image_lq',
    },
    48: {
        apiUrl: select_url_portfolio_content_source_service_image,
        layout: 'get_image_lq',
    },
    49: {
        apiUrl: select_url_portfolio_content_source_service_image,
        layout: 'get_image_lq',
    },
    51: {
        apiUrl: select_url_portfolio_content_source_project,
        layout: 'get_image_lq',
    },
    54: {
        apiUrl: select_url_portfolio_content_source_service_child,
        hasNoActive: true,
    },
    55: {
        apiUrl: select_url_portfolio_content_source_service_child,
        hasNoActive: true,
    },
    58: {
        apiUrl: select_url_portfolio_content_source_hub_content,
        usePortfolioId: true,
    },
    59: {
        apiUrl: select_url_portfolio_content_source_hub_content,
        usePortfolioId: true,
    },
    61: {
        apiUrl: select_url_portfolio_content_source_currency,
    },
    63: {
        apiUrl: select_url_portfolio_content_source_adherent,
    },
    64: {
        apiUrl: select_url_portfolio_content_source_adherent,
    },
    65: {
        apiUrl: select_url_portfolio_content_source_contribution,
    },
    66: {
        apiUrl: select_url_portfolio_content_source_contribution,
    },
    71: {
        apiUrl: select_url_portfolio_content_source_region,
        hasNoActive: true,
    },
    77: {
        apiUrl: select_url_portfolio_content_source_general_feed,
        layout: 'get_image_lq',
    },
    80: {
        apiUrl: select_url_portfolio_content_source_activity,
        layout: 'get_image_lq',
    },
    81: {
        apiUrl: select_url_portfolio_content_source_activity,
        layout: 'get_image_lq',
    },
    84: {
        apiUrl: select_url_portfolio_content_source_entity,
        layout: 'get_image_lq',
    },
    85: {
        apiUrl: select_url_portfolio_content_source_entity,
        layout: 'get_image_lq',
    },
    86: {
        apiUrl: select_url_portfolio_content_source_datapool_asset,
        layout: 'get_image_lq',
    },
    87: {
        apiUrl: select_url_portfolio_content_source_datapool_asset,
        layout: 'get_image_lq',
    },
    91: {
        apiUrl: select_url_portfolio_content_source_moduleview,
        layout: 'get_image_lq',
    },
}
