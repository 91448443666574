// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ActionInputPageContent,
    ContentSourceExtraInputPageContent,
    ContentSourceInputPageContent,
    CoverImageInputPageContent,
    DataJsonInputPageContent,
    PageLinkInputPageContent,
    TextShortInputPageContent,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// props
type ContentPanel781Props = {
    detailUrl: string
    formState: PortfolioFormState
    handleInputChange: any
    onRefresh: () => void
    updateUrl: string
}

// main
export const ContentPanel781: React.FC<ContentPanel781Props> = React.memo(({
    detailUrl,
    formState,
    handleInputChange,
    onRefresh,
    updateUrl,
}) => {

    const dispatch = useDispatch()
    const reduxFormSitepermissions = useSelector((state: defaultReduxState) => state.reduxFormSite.permissions)
    const reduxFormSitepermissionspageObjectcover_image = useSelector((state: defaultReduxState) => state.reduxFormSite.pageObject?.cover_image)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const content = formState.content

    function onHandleInputChange(event: any) {
        try {
            let customError = undefined
            if (event.name === 'content_source' && event.value?.id === 20 && [3, 4].includes(fieldsInline?.text_short_mode?.id!)) {
                customError = {
                    name: 'text_short',
                    value: reduxText[7769],
                }
            } else if (['content_source', 'text_short_mode'].includes(event.name)) {
                customError = {
                    name: 'text_short',
                    value: '',
                }
            }
            handleInputChange(event, customError)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentPanel781',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    // Content
    const errorsInline = content.errors
    const fieldsInline = content.fields

    if (!fieldsMother || !fieldsInline) return null

    const permissionIsDisabledRest = reduxFormSitepermissions?.permissionIsDisabledRest
    const permissionIsDisabledText = reduxFormSitepermissions?.permissionIsDisabledText

    const permissionIsDisabledImagePageDetail = Boolean(permissionIsDisabledRest && fieldsInline.content_source?.id === 35 && reduxFormSitepermissionspageObjectcover_image)
    // console.log('permissionIsDisabledImagePageDetail', permissionIsDisabledImagePageDetail);
    // console.log('permissionIsDisabledRest', permissionIsDisabledRest);
    // console.log('fieldsInline.content_source?.id', fieldsInline.content_source?.id);
    // console.log('reduxFormSitepermissionspageObjectcover_image', reduxFormSitepermissionspageObjectcover_image);


    return (
        <React.Fragment>
            <ContentSourceInputPageContent
                disabled={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                inputObject={fieldsInline.layout?.form_fields?.find(val => val.field === 'ContentSource')}
                onHandleInputChange={onHandleInputChange}
            />
            <ContentSourceExtraInputPageContent
                disabled={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                inputObject={fieldsInline.layout?.form_fields?.find(val => val.field === 'ContentSourceExtra')}
                onHandleInputChange={onHandleInputChange}
                optional={false}
            />
            <CoverImageInputPageContent
                detailUrl={detailUrl}
                disabled={permissionIsDisabledText || permissionIsDisabledImagePageDetail}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                inputObject={fieldsInline.layout?.form_fields?.find(val => val.field === 'CoverImage')}
                onHandleInputChange={onHandleInputChange}
                optional={false}
                updateUrl={updateUrl}
            />
            <ActionInputPageContent
                disabled={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                inputObject={fieldsInline.layout?.form_fields?.find(val => val.field === 'Action')}
                onHandleInputChange={onHandleInputChange}
                optional={false}
            />
            <PageLinkInputPageContent
                disabled={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                inputObject={fieldsInline.layout?.form_fields?.find(val => val.field === 'PageLink')}
                onHandleInputChange={onHandleInputChange}
                optional={false}
            />
            {/* <FileInputPageContent
                disabled={permissionIsDisabledText || permissionIsDisabledImagePageDetail}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                inputObject={fieldsInline.layout?.form_fields?.find(val => val.field === 'File')}
                mixedMedia={false}
                onHandleInputChange={onHandleInputChange}
                optional={false}
            /> */}
            <TextShortInputPageContent
                detailUrl={detailUrl}
                disabled={permissionIsDisabledRest}
                disabledText={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                fonts={undefined}
                inputObject={fieldsInline.layout?.form_fields?.find(val => val.field === 'TextShort')}
                onHandleInputChange={onHandleInputChange}
                onRefresh={onRefresh}
                optional={false}
                text_short_mode={undefined}
                updateUrl={updateUrl}
            />
            <DataJsonInputPageContent
                detailUrl={detailUrl}
                disabled={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                onHandleInputChange={onHandleInputChange}
                updateUrl={updateUrl}
            />
        </React.Fragment>
    )
})

////// Conditions Front
