// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
    IonTitle,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    HeaderChildren,
    InfiniteScrollHelperWeb,
} from 'components'

// data
import {
    api_url_custom_feed_block_list,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FeedBlockListBlockWeb,
    FeedMenuBlockWeb,
} from 'pages'

// serializers
import {
    CustomFeedBlockListSerializer,
    CustomFeedSectionListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getInfiniteListWeb,
} from 'services'

// props
type MatchParams = {
    id: string
    slug: string
}

// main
export const FeedSectionDetailWeb: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<CustomFeedBlockListSerializer[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [section, setSection] = useState<CustomFeedSectionListSerializer>()
    const [sectionList, setSectionList] = useState<CustomFeedSectionListSerializer[]>()

    useEffect(() => {
        if ((params.id || sectionList?.[0]?.id)) {
            onGetListData(getApiUrl(`${api_url_custom_feed_block_list}?section_id=${params.id || sectionList?.[0]?.id}`, reduxAuth), true)
        }
    }, [
        sectionList,
        params.id,
        reduxAuth.apiRootUrl,
        reduxForm.refresh.refreshFeed,
    ])

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        noEmpty?: boolean
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'FeedSectionDetailWeb',
                dispatch,
                items,
                noEmpty,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedSectionDetailWeb',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FeedSectionDetailWeb',
                'onSearchNext',
            ))
        }
    }

    return (
        <IonPage className='feed-section-detail-web'>
            <HeaderChildren>
                <IonTitle className='hcw-title'>{section?.name || sectionList?.[0]?.name}</IonTitle>
            </HeaderChildren>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='fsdw-content'>
                    <div className={`fsdw-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <FeedMenuBlockWeb
                            sectionId={Number(params.id)}
                            setSection={setSection}
                            setSectionList={setSectionList}
                        />
                    </div>
                    <div className='fsdw-right'>
                        <div className={`fsdw-right-wrap mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                            {items.map((item) => {
                                return (
                                    <FeedBlockListBlockWeb
                                        key={item.id}
                                        object={item}
                                    />
                                )
                            })}
                            <InfiniteScrollHelperWeb
                                active={!disableInfiniteScroll}
                                hasMore={hasMore}
                                isLoading={isLoading}
                                items={items}
                                onChange={onSearchNext}
                            />
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
