// react components
import React, {
    useState,
} from 'react'
import {
    useStripe,
} from '@stripe/react-stripe-js'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
} from 'services'

// props
type AgendaDuQuartierStripeCheckoutProps = {
    fields: any
    period: 'monthly' | 'yearly'
    setInvoice: React.Dispatch<React.SetStateAction<any>>
    setQuotation: React.Dispatch<React.SetStateAction<any>>
}

// main
export const AgendaDuQuartierStripeCheckout: React.FC<AgendaDuQuartierStripeCheckoutProps> = React.memo(({
    fields,
    period,
    setInvoice,
    setQuotation,
}) => {

    const dispatch = useDispatch()
    const stripe = useStripe()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)

    function handleStripe(isOther?: 'invoice' | 'quotation') {
        try {
            setIsLoading(true)
            const formData: any = new FormData()
            formData.append('fields', JSON.stringify(fields))
            if (isOther === 'invoice') {
                formData.append('is_invoice', true)
            }
            if (isOther === 'quotation') {
                formData.append('is_quotation', true)
            }
            formData.append('current_path', window.location.pathname)
            const postUrl = getApiUrlV2('customer/agendaduquartier/form/stripe/sales/', reduxAuth)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierStripeCheckout handleStripe'),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (isOther === 'quotation') {
                        setQuotation(response.data)
                        return
                    }
                    if (isOther === 'invoice') {
                        setInvoice(response.data)
                        return
                    }
                    stripe!.redirectToCheckout({
                        sessionId: response.data.stripe_session_id,
                    }).then((result: any) => {
                        if (process.env.NODE_ENV === 'development') console.log(result)
                    })
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'AgendaDuQuartierStripeCheckout',
                        dispatch,
                        error,
                        formFields: JSON.stringify(formData),
                        reduxAuth,
                        reference: 'handleStripe',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierStripeCheckout',
                'handleStripe',
            ))
        }
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    paddingBottom: '20px',
                    textAlign: 'left',
                }}
            >
                <input
                    onChange={() => setButtonDisabled(!buttonDisabled)}
                    style={{
                        marginRight: '10px',
                    }}
                    type='checkbox'
                />
                <div dangerouslySetInnerHTML={{ __html: reduxText[10047] }} />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    text='Recevoir un devis'
                    onClick={() => handleStripe('quotation')}
                    padding='10px 0'
                />
                <Button
                    disabled={buttonDisabled || (period === 'monthly')}
                    edit={false}
                    text='Payer par virement bancaire'
                    onClick={() => handleStripe('invoice')}
                    padding='10px 0'
                />
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    onClick={() => handleStripe()}
                    text='Payer maintenant en ligne (Carte ou prélèvement SEPA)'
                    padding='10px 0'
                />
            </div>
            {isLoading && (
                <Loader isOpen />
            )}
        </div>
    )
})
