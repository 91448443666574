// react components
import React from 'react'

// components
import {
    LinkHelper,
    RatioHelper,
} from 'components'

// helpers
type ProfileProps = {
    id?: number
    get_absolute_url?: string
    get_dominant_color?: string
    name?: string
    get_image_xs?: string
}

type MemberProps = {
    id?: number
    get_absolute_url?: string
    get_dominant_color?: string
    get_image_xs?: string
    name?: string
}

type ProjectTeamProps = {
    id: number
    credit_type: number
    member?: MemberProps
    name: string
}

// props
type ListProfileLinkProps = {
    blockSize: 'big' | 'medium' | 'small'
    edit: boolean
    other_profiles?: ProjectTeamProps[]
    profile: ProfileProps
}

// main
export const ListProfileLink: React.FC<ListProfileLinkProps> = React.memo(({
    blockSize,
    edit,
    other_profiles,
    profile,
}) => {

    const duplicateProfile: number[] = []
    const newProjectTeams: ProjectTeamProps[] = []
    const newLabels: ProjectTeamProps[] = []
    if (other_profiles && other_profiles.length > 0) {
        other_profiles.map((val) => {
            if ([1, 2].includes(val.credit_type)) newProjectTeams.push(val)
            if (val.credit_type === 4) newLabels.push(val)
            if (val.member?.id) duplicateProfile.push(val.member.id)
            return false
        })
    }
    if (['big', 'medium'].includes(blockSize)) {
        return (
            <div className={`list-profile-link-web ${blockSize}`}>
                {profile.id && !duplicateProfile.includes(profile.id) && (
                    <LinkHelper
                        className='lplw-profile-name-wrap'
                        edit={edit}
                        to={profile.get_absolute_url}
                    >
                        <div className={`lplw-profile-image ${blockSize}`}>
                            <RatioHelper
                                alt={profile.name}
                                borderRadius='50%'
                                dominant_color={profile.get_dominant_color}
                                edit={edit}
                                src={profile.get_image_xs}
                                to={undefined}
                            />
                        </div>
                        <p className='lplw-profile-name'>{profile.name}</p>
                    </LinkHelper>
                )}
                {newProjectTeams.map((val, i) => (
                    <React.Fragment key={val.id}>
                        {(i !== 0 || (profile.id && !duplicateProfile.includes(profile.id))) && <span className='lplw-sep'>{(val.member) ? '' : '/'}</span>}
                        <LinkHelper
                            className='lplw-profile-name-wrap'
                            edit={edit}
                            to={val.member?.get_absolute_url}
                        >
                            {val.member && (
                                <div className={`lplw-profile-image ${blockSize}`}>
                                    <RatioHelper
                                        alt={val.member.name}
                                        borderRadius='50%'
                                        dominant_color={val.member.get_dominant_color}
                                        edit={edit}
                                        src={val.member.get_image_xs}
                                        to={undefined}
                                    />
                                </div>
                            )}
                            <p className='lplw-profile-name'>{val.member?.name || val.name}</p>
                        </LinkHelper>
                    </React.Fragment>
                ))}
                {newLabels.map((val, i) => {
                    return (
                        <React.Fragment key={val.id}>
                            {(i === 0 && (val.member?.id === profile.id) && (newProjectTeams.length === 0))
                                ? (
                                    <span />
                                ) : (
                                    <span className='lplw-sep'>{val.member ? '' : '|'}</span>
                                )
                            }
                            <LinkHelper
                                className='lplw-profile-name-wrap'
                                edit={edit}
                                to={val.member?.get_absolute_url}
                            >
                                {val.member && (
                                    <div className={`lplw-profile-image ${blockSize}`}>
                                        <RatioHelper
                                            alt={val.member.name}
                                            borderRadius='50%'
                                            dominant_color={val.member.get_dominant_color}
                                            edit={edit}
                                            src={val.member.get_image_xs}
                                            to={undefined}
                                        />
                                    </div>
                                )}
                                <p className='lplw-profile-name'>{val.member?.name || val.name}</p>
                            </LinkHelper>
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
    return (
        <div className='list-profile-link-web mo-clamp-web1'>
            {profile.id && !duplicateProfile.includes(profile.id) && (
                <React.Fragment>
                    <LinkHelper
                        edit={edit}
                        to={profile.get_absolute_url}
                    >
                        {profile.name}
                    </LinkHelper>
                </React.Fragment>
            )}
            {newProjectTeams.map((val, i) => (
                <React.Fragment key={val.id}>
                    {(i !== 0 || (profile.id && !duplicateProfile.includes(profile.id))) && <span className='lplw-sep'>|</span>}
                    <LinkHelper
                        edit={edit}
                        to={val.member?.get_absolute_url}
                    >
                        {val.member?.name || val.name}
                    </LinkHelper>
                </React.Fragment>
            ))}
            {newLabels.map((val, i) => {
                return (
                    <React.Fragment key={val.id}>
                        {(i === 0 && (val.member?.id === profile.id) && (newProjectTeams.length === 0))
                            ? (
                                <span />
                            ) : (
                                <span className='lplw-sep'>|</span>
                            )
                        }
                        <LinkHelper
                            edit={edit}
                            to={val.member?.get_absolute_url}
                        >
                            {val.member?.name || val.name}
                        </LinkHelper>
                    </React.Fragment>
                )
            })}
        </div>
    )
})
