// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import debounce from 'lodash.debounce'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
    InfiniteScrollHelperWeb,
    Loader,
    SearchInput,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    SelectionFormItem,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getInfiniteListWeb,
    getReduxTextId,
} from 'services'

// props
type SelectionFormProps = {
    contentType: contentTypeModelType
    detailObject: any
    refreshData: () => void
    setIsOpen: React.Dispatch<boolean>
}

// main
export const SelectionForm: React.FC<SelectionFormProps> = React.memo(({
    contentType,
    detailObject,
    refreshData,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [items, setItems] = useState<any[]>([])
    const [nextUrl, setNextUrl] = useState('')
    const [idsToAdd, setIdsToAdd] = useState<number[]>([])
    const [idsToRemove, setIdsToRemove] = useState<number[]>([])

    let listApiUrl = getApiUrlV2(`${contentType.split('_')[0]}/list/${contentType.split('_')[1]}/selection/?content_type_settings=${detailObject.content_type_settings.id}&content_type=${detailObject.model_content_type_id}&object_id=${detailObject.id}`, reduxAuth)
    if (detailObject.content_type_string === 'setup_contenttypesettingsprofile') { // TODO dynamic
        listApiUrl += `&content_type_settings_profile=${detailObject.id}`
    }
    if (detailObject.content_type_string === 'setup_contenttypepanelblockprofile') { // TODO dynamic
        listApiUrl += `&content_type_settings_profile=${detailObject.content_type_settings_profile.id}`
    }

    useEffect(() => {
        onGetListData(listApiUrl, true)
    }, [])

    const debouncedChangeHandler = debounce(onSearch, 300)

    function onCheckboxChange(event: {
        id: number;
        checked: boolean;
        selection_id: number | false;
    }) {
        try {
            if (event.checked) {
                if (event.selection_id === false) {
                    setIdsToAdd((prevIdsToAdd: number[]) => {
                        const newIdsToAdd = [...prevIdsToAdd, event.id];
                        return newIdsToAdd;
                    });
                } else {
                    setIdsToRemove((prevIdsToRemove: number[]) => {
                        return prevIdsToRemove.filter(id => id !== event.selection_id);
                    });
                }
            } else {
                if (event.selection_id === false) {
                    setIdsToAdd((prevIdsToAdd: number[]) => {
                        return prevIdsToAdd.filter(id => id !== event.id);
                    });
                } else {
                    // @ts-ignore
                    setIdsToRemove((prevIdsToRemove: number[]) => {
                        return [...prevIdsToRemove, event.selection_id];
                    });
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectionForm',
                'onSubmit',
            ));
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'SelectionForm',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectionForm',
                'onGetListData',
            ))
        }
    }

    function onSearch(value: string | null | undefined) {
        try {
            onGetListData(`${listApiUrl}&search=${value}`, true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectionForm',
                'onSearch',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectionForm',
                'onSearchNext',
            ))
        }
    }

    async function onSubmit() {
        try {
            setIsSaving(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SelectionForm onSubmit')

            const postUrl = getApiUrlV2(`${contentType.split('_')[0]}/form/${contentType.split('_')[1]}/selection/`, reduxAuth)

            const formData: any = new FormData()
            if (detailObject.content_type_string === 'setup_contenttypesettingsprofile') { // TODO dynamic
                formData.append('content_type_settings_profile', detailObject.id)
            }
            if (detailObject.content_type_string === 'setup_contenttypepanelblockprofile') { // TODO dynamic
                formData.append('content_type_settings_profile', detailObject.content_type_settings_profile.id)
            }
            formData.append('content_type', detailObject.model_content_type_id)
            formData.append('object_id', detailObject.id)
            formData.append('ids_to_add', idsToAdd.map((id) => id.toString()).join(','))
            formData.append('ids_to_remove', idsToRemove.map((id) => id.toString()).join(','))

            if (process.env.NODE_ENV === 'development') console.log(postUrl)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    refreshData()
                    setIsSaving(false)
                    setIsOpen(false)
                })
                .catch((error) => {
                    setIsSaving(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'SelectionForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSubmit',
                    })
                })
        } catch (error) {
            setIsSaving(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectionForm',
                'onSubmit',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class selection-form'
            classes={{ paper: 'mo-dialog-wrapper fix-height fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {getReduxTextId((detailObject.profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]?.meta?.verboseNamePlural || detailObject.content_type_settings_global?.[contentType]?.meta?.verboseNamePlural), reduxText)}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='mo-dialog-searchbar-wrap'>
                    <SearchInput
                        classNameWrap='bg1'
                        onChange={(e) => debouncedChangeHandler(e)}
                        placeholder={reduxText[282]}
                    />
                </div>
                <div className='sf-items'>
                    {items.map((item) => {
                        return (
                            <SelectionFormItem
                                key={item.id}
                                item={item}
                                onChange={onCheckboxChange}
                            />
                        )
                    })}
                </div>
                <InfiniteScrollHelperWeb
                    active={!disableInfiniteScroll}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    items={items}
                    onChange={onSearchNext}
                />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    onClick={() => setIsOpen(false)}
                    size='small'
                    fill='outline'
                    text={reduxText[4519]}
                />
                <Button
                    edit={false}
                    // disabled={!Boolean(value)}
                    onClick={() => onSubmit()}
                    size='small'
                    text={reduxText[5257]}
                />
            </DialogActions>
            {isSaving && (
                <Loader isOpen />
            )}
        </Dialog>
    )
})
