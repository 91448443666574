// data
import {
    api_url_portfolio_form_active,
    api_url_portfolio_form_create,
    api_url_portfolio_form_detail,
    api_url_portfolio_form_update,
    reduxAuthState,
    view_url_portfolio_detail,
    view_url_portfolio_form,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function PortfolioMotherFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'mother',

        // Settings
        detailComponent: 'PortfolioDetailBlockWeb',
        model: 'portfolio',

        // Urls
        activeUrl: getApiUrl(api_url_portfolio_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_portfolio_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_portfolio_form_detail, reduxAuth),
        formUrl: view_url_portfolio_form,
        updateUrl: getApiUrl(api_url_portfolio_form_update, reduxAuth),
        viewUrl: view_url_portfolio_detail,

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'name',
            'hostname',
        ],
        requiredForeignKey: [
            'profile',
        ],
        requiredManyToMany: [
            'languages',
        ],

        // Form fields
        formAttribute: [
            'coming_soon',
            'contact_email',
            'description',
            'google_analytics',
            'google_tag_manager',
            'hostname',
            'is_multi_language',
            'name',
            'seo_title',
            'token_paypal_client_id',
            'token_stripe_private',
            'token_stripe_public',
        ],
        formFile: [
            'favicon',
            'seo_image',
            // 'seo_image_original',
        ],
        formForeignKey: [
            'currency',
            'profile',
        ],
        formJson: [],
        formManyToMany: [
            'fonts',
            'languages',
        ],
    }
}
