// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DateTimeInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
} from 'serializers/web'

// props
type DateEndProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    onChange: any
}

// main
export const DateEndProjectInput: React.FC<DateEndProjectInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <DateTimeInput
            error={errors?.date_end}
            format='date'
            formatDate={fields.date_type_choice?.id === 'y' ? 'yyyy' : (fields.date_type_choice?.id === 'd' ? 'dd/MM/yyyy' : 'MM/yyyy')}
            label={reduxText[8142]}
            name='date_end'
            onChange={onChange}
            optional
            value={fields.date_end || null}
        />
    )
})
