// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
} from 'components'

// data
import {
    api_url_page_template_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    PageTemplateActionSheet,
} from 'pages'

// serializers
import {
    PageTemplateListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type PageTemplateListBlockProps = {
    edit: boolean
    isInProfileSlug?: string
    modalKey: number
    object: PageTemplateListSerializer
}

// main
export const PageTemplateListBlock: React.FC<PageTemplateListBlockProps> = React.memo(({
    edit,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<PageTemplateListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_page_template_detail}${detailObject.id}/?block_size=small`, reduxAuth),
                component: 'PageTemplateListBlock',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PageTemplateListBlock',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div
            className='page-template-list-block-web'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            <div className={`ptlbw-cover${showActionPopover ? ' is-open' : ''}`}>
                {!activated && (
                    <InactiveButton
                        edit={edit}
                        model='pagetemplate'
                        objectId={detailObject.id!}
                        objectName={`${detailObject.id}`}
                        setDeleted={setDeleted}
                    />
                )}
                <LinkHelper
                    className='ptlbw-cover-wrap'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    <div className='ptlbw-image-wrap'>
                        <ImageHelper
                            alt=''
                            className='ptlbw-image'
                            dominant_color={detailObject.get_dominant_color}
                            src={detailObject.get_image_lq}
                        />
                    </div>
                </LinkHelper>
                <div className='ptlbw-actions'>
                    <PageTemplateActionSheet
                        edit={edit}
                        isInProfileSlug={isInProfileSlug}
                        object={detailObject}
                        refreshData={onGetDetailData}
                        setActivated={setActivated}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </div>
            </div>
            <div className='ptlbw-info'>
                <LinkHelper
                    className='mo-list-block-name-web small'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title=''
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.id}
                </LinkHelper>
            </div>
        </div>
    )
})
