// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainHashtag,
    PrivateButton,
    UnlistedButton,
} from 'components'

// data
import {
    api_url_material_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    searchTabValue,
} from 'data'

// pages
import {
    MaterialActionSheetWeb,
} from 'pages'

// serializers
import {
    MaterialListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type MaterialListMediumBlockWebProps = {
    edit: boolean
    isInProfileSlug?: string
    modalKey: number
    object: MaterialListSerializer
}

// main
export const MaterialListMediumBlockWeb: React.FC<MaterialListMediumBlockWebProps> = React.memo(({
    edit,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<MaterialListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_material_detail}${detailObject.id}/?block_size=medium`, reduxAuth),
                component: 'MaterialListMediumBlockWeb',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MaterialListMediumBlockWeb',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div
            className='material-list-medium-block-web'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='material'
                    objectId={detailObject.id!}
                    objectName={detailObject.name!}
                    setDeleted={setDeleted}
                />
            )}
            {detailObject.is_private && (
                <PrivateButton
                    edit={edit}
                    listed={detailObject.is_private_and_listed_in_portfolio}
                />
            )}
            {detailObject.is_unlisted && (
                <UnlistedButton edit={edit} />
            )}
            <LinkHelper
                className='mlmb-cover'
                edit={edit}
                onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
            >
                <ImageHelper
                    alt={detailObject.name}
                    className='mlmb-image'
                    dominant_color={detailObject.get_dominant_color}
                    src={detailObject.get_image_lq}
                />
            </LinkHelper>
            <div className='mlmb-info'>
                <LinkHelper
                    className='mo-list-block-name-web medium'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title={detailObject.name}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.name}
                </LinkHelper>
                <div className='mlmb-price-wrap'>
                    {detailObject.price
                        ? (
                            <p className='mlmb-price'>{`${detailObject.price} ${detailObject.currency?.symbol || detailObject.currency?.iso_code} / ${detailObject.uom}`}</p>
                        ) : (
                            <p className='mlmb-price'>{reduxText[4869]}</p>
                        )}
                </div>
                <div className='list-block-footer'>
                    {detailObject.profile && (
                        <ListProfileLink
                            blockSize='medium'
                            edit={edit}
                            profile={detailObject.profile}
                        />
                    )}
                </div>
                <div className='mlmb-tags'>
                    <MainChipListBlock>
                        {detailObject.custom_categories?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.material}
                            />
                        ))}
                        {/* {detailObject.custom_categories_2?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                        {detailObject.custom_categories_3?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))} */}
                    </MainChipListBlock>
                </div>
            </div>
            <div className='mlmb-actions'>
                <MaterialActionSheetWeb
                    edit={edit}
                    isInProfileSlug={isInProfileSlug}
                    object={detailObject}
                    refreshData={onGetDetailData}
                    setActivated={setActivated}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                />
            </div>
        </div>
    )
})
