// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonItem,
    IonLabel,
    IonList,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetNavigationModeSite,
} from 'data'

// serializers
import {
    MenuFormPanelProps,
    ProjectChildFormState,
} from 'serializers/web'

// props
type MatchParams = {
    albumId?: string
}

type MenuProjectChildPanelProps = {
    formState: ProjectChildFormState
}

// main
export const MenuProjectChildPanel: React.FC<MenuFormPanelProps & MenuProjectChildPanelProps> = React.memo(({
    formState,
    helpers,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [formPanels, setFormPanels] = useState<{
        formType: string
        panelValue: string
        primary: string
    }[]>([])

    useEffect(() => {
        dispatch(reduxFormSetNavigationModeSite(undefined))
    }, [])

    const mother = formState.mother
    const fieldsMother = mother.fields

    useEffect(() => {

        const tempFormPanels: {
            formType: string
            panelValue: string
            primary: string
        }[] = []

        tempFormPanels.push(
            {
                formType: 'main',
                panelValue: 'main-panel',
                primary: reduxText[347],
            },
            {
                formType: 'main',
                panelValue: 'additional-panel',
                primary: reduxText[3234],
            },
            {
                formType: 'inline',
                panelValue: 'team-panel',
                primary: reduxText[4917],
            },
            {
                formType: 'main',
                panelValue: 'website-related-panel',
                primary: reduxText[6415],
            },
            {
                formType: 'inline',
                panelValue: 'link-panel',
                primary: reduxText[7432],
            },
        )

        if (!params.albumId) {
            tempFormPanels.push(
                {
                    formType: 'main',
                    panelValue: 'delete-panel',
                    primary: fieldsMother?.active ? `${reduxText[3517]} / ${reduxText[2983]}` : reduxText[2983],
                },
            )
        }

        setFormPanels(tempFormPanels)
    }, [
        fieldsMother?.active,
        params.albumId,
        reduxAuth.settings?.active_profile,
        reduxText,
    ])

    return (
        <IonList>
            {formPanels.map((val) => {
                return (
                    <IonItem
                        key={val.panelValue}
                        button={val.panelValue === 'delete-panel'}
                        disabled={!(fieldsMother?.id)}
                        onClick={() => val.panelValue === 'delete-panel' ? setHelpers({ ...helpers, isActionSheetOpen: true }) : dispatch(reduxFormSetIsSuccessToastOpen(false))}
                        routerLink={val.panelValue === 'delete-panel' ? undefined : `${mother.formUrl}${fieldsMother?.id}/${params.albumId}/${val.formType}/${val.panelValue}/`}
                    >
                        <IonLabel className='mo-item-list-label'>
                            <h2>{val.primary}</h2>
                        </IonLabel>
                    </IonItem>
                )
            })}
        </IonList>
    )
})
