// react components
import React from 'react'
import axios from 'axios'

// data
import {
    api_url_album_children_list,
    contentTypeModelType,
    reduxAuthState,
} from 'data'

// serializers
import {
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
    SeoSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// constants
export const REDUX_MODAL_CONTENT_DETAIL_HIDE = 'REDUX_MODAL_CONTENT_DETAIL_HIDE'
export const REDUX_MODAL_CONTENT_DETAIL_SET_ITEMS = 'REDUX_MODAL_CONTENT_DETAIL_SET_ITEMS'
export const REDUX_MODAL_CONTENT_DETAIL_SET_OBJECT_ID = 'REDUX_MODAL_CONTENT_DETAIL_SET_OBJECT_ID'
export const REDUX_MODAL_CONTENT_DETAIL_SHOW = 'REDUX_MODAL_CONTENT_DETAIL_SHOW'
export const REDUX_MODAL_DETAIL2_SHOW = 'REDUX_MODAL_DETAIL2_SHOW'
export const REDUX_MODAL_ERROR_EVENT_HANDLER = 'REDUX_MODAL_ERROR_EVENT_HANDLER'
export const REDUX_MODAL_ERROR_SHOW_HIDE = 'REDUX_MODAL_ERROR_SHOW_HIDE'
export const REDUX_MODAL_LAYOUT_INPUT_HIDE = 'REDUX_MODAL_LAYOUT_INPUT_HIDE'
export const REDUX_MODAL_LAYOUT_INPUT_SHOW = 'REDUX_MODAL_LAYOUT_INPUT_SHOW'
export const REDUX_MODAL_MAIN_FORM_HIDE = 'REDUX_MODAL_MAIN_FORM_HIDE'
export const REDUX_MODAL_MAIN_FORM_SHOW = 'REDUX_MODAL_MAIN_FORM_SHOW'
export const REDUX_MODAL_PLAYER_CHECK_POSITION = 'REDUX_MODAL_PLAYER_CHECK_POSITION'
export const REDUX_MODAL_PLAYER_ON_DISMISS = 'REDUX_MODAL_PLAYER_ON_DISMISS'
export const REDUX_MODAL_PLAYER_ON_PLAY_PAUSE = 'REDUX_MODAL_PLAYER_ON_PLAY_PAUSE'
export const REDUX_MODAL_PLAYER_ON_READY = 'REDUX_MODAL_PLAYER_ON_READY'
export const REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_ALBUM = 'REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_ALBUM'
export const REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_TRACK = 'REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_TRACK'
export const REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM = 'REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM'
export const REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_TRACK = 'REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_TRACK'
export const REDUX_MODAL_PLAYER_SET_POSITION = 'REDUX_MODAL_PLAYER_SET_POSITION'
export const REDUX_MODAL_SEO_DATA = 'REDUX_MODAL_SEO_DATA'
export const REDUX_MODAL_SLIDER_GET_DATA = 'REDUX_MODAL_SLIDER_GET_DATA'
export const REDUX_MODAL_SLIDER_ON_NEXT = 'REDUX_MODAL_SLIDER_ON_NEXT'
export const REDUX_MODAL_SLIDER_ON_PREV = 'REDUX_MODAL_SLIDER_ON_PREV'
export const REDUX_MODAL_SLIDER_SET_DATA = 'REDUX_MODAL_SLIDER_SET_DATA'
export const REDUX_MODAL_SLIDER_SHOW_HIDE = 'REDUX_MODAL_SLIDER_SHOW_HIDE'

// actions
export function reduxModalContentDetailHide() {
    return {
        type: REDUX_MODAL_CONTENT_DETAIL_HIDE,
    }
}

export function reduxModalContentDetailSetItems(
    contentItems: any[],
) {
    return {
        type: REDUX_MODAL_CONTENT_DETAIL_SET_ITEMS,
        payload: contentItems,
    }
}

export function reduxModalContentDetailSetObjectId(
    contentObjectId: number,
) {
    return {
        type: REDUX_MODAL_CONTENT_DETAIL_SET_OBJECT_ID,
        payload: contentObjectId,
    }
}

export function reduxModalContentDetailShow(
    apiUrl: string,
    app: string,
    component: string,
    componentActionSheet: string | undefined,
    contentObjectId: number,
    items: any[],
    model: contentTypeModelType,
    nextUrl: string | undefined,
    onSearchNext: ((e: boolean) => void) | undefined,
    headerId?: string,
) {
    return {
        type: REDUX_MODAL_CONTENT_DETAIL_SHOW,
        payload: {
            apiUrl: apiUrl,
            app: app,
            component: component,
            componentActionSheet: componentActionSheet,
            contentObjectId: contentObjectId,
            items: items,
            model: model,
            nextUrl: nextUrl,
            onSearchNext: onSearchNext,
            headerId: headerId,
        },
    }
}

export function reduxModalDetail2Show({
    contentType,
    isOpen,
    objectId,
}: {
    contentType?: contentTypeModelType
    isOpen: boolean
    objectId?: number
}) {
    return {
        type: REDUX_MODAL_DETAIL2_SHOW,
        payload: {
            contentType,
            isOpen,
            objectId,
        },
    }
}

export function reduxModalErrorEventHandler(error: any, component: string, info: string, skip?: boolean) {
    return {
        type: REDUX_MODAL_ERROR_EVENT_HANDLER,
        payload: {
            component: component,
            error: error,
            info: info,
            skip: skip,
        },
    }
}

export function reduxModalErrorShowHide({
    header,
    isOpen,
    message,
    noMessage,
    source,
    subHeader,
}: {
    header?: string
    isOpen: boolean
    message?: string
    noMessage?: boolean
    source?: string
    subHeader?: string
}) {
    return {
        type: REDUX_MODAL_ERROR_SHOW_HIDE,
        payload: {
            header,
            isOpen,
            message,
            noMessage,
            source,
            subHeader,
        },
    }
}

export function reduxModalLayoutInputHide() {
    return {
        type: REDUX_MODAL_LAYOUT_INPUT_HIDE,
    }
}

export function reduxModalLayoutInputShow(
    handleSubmit: any,
    mixedMedia?: boolean,
    forceMixedMediaTemp?: boolean,
    direction?: 'after' | 'before',
) {
    return {
        type: REDUX_MODAL_LAYOUT_INPUT_SHOW,
        payload: {
            direction: direction,
            forceMixedMediaTemp: forceMixedMediaTemp,
            handleSubmit: handleSubmit,
            mixedMedia: mixedMedia,
        },
    }
}

export function reduxModalMainFormHide() {
    return {
        type: REDUX_MODAL_MAIN_FORM_HIDE,
    }
}

export function reduxModalMainFormShow({
    contentType,
    contentTypeSettings,
    cssClass,
    customformState,
    defaultFields,
    formComponent,
    objectId,
    profileObject,
    refreshData,
    setDeleted,
}: {
    contentType?: contentTypeModelType
    contentTypeSettings?: ContentTypeSettingsTypes
    cssClass?: string
    customformState?: any
    defaultFields?: any
    formComponent: string
    objectId: number | 'new'
    profileObject?: ProfileDetailAdminSerializerV2
    refreshData?: (e: boolean) => void
    setDeleted?: React.Dispatch<boolean>
}) {
    return {
        type: REDUX_MODAL_MAIN_FORM_SHOW,
        payload: {
            contentType,
            contentTypeSettings,
            cssClass,
            customformState,
            defaultFields,
            formComponent,
            objectId,
            onRefresh: refreshData,
            profileObject,
            setDeleted,
        },
    }
}

export function reduxModalPlayerCheckPosition(objectId: number, direction: string) {
    return {
        type: REDUX_MODAL_PLAYER_CHECK_POSITION,
        payload: objectId,
        direction: direction,
    }
}

export function reduxModalPlayerOnDismiss() {
    return {
        type: REDUX_MODAL_PLAYER_ON_DISMISS,
    }
}

export function reduxModalPlayerOnPlayPause(playOrPause?: any) {
    return {
        type: REDUX_MODAL_PLAYER_ON_PLAY_PAUSE,
        playOrPause: playOrPause,
    }
}

export function reduxModalPlayerOnReady() {
    return {
        type: REDUX_MODAL_PLAYER_ON_READY,
    }
}

export function reduxModalPlayerSetCurrentTrack(
    object: any,
    reduxAuth: reduxAuthState,
    dispatch2: React.Dispatch<any>,
    playerPosition?: string,
    playerOrigin?: string,
    modelKey?: number,
) {
    return function (dispatch: React.Dispatch<any>) {
        if ([1, 4].includes(object?.medium_type)) {  // For album and series
            const getUrl = getApiUrl(`${api_url_album_children_list}${object?.id}`, reduxAuth)
            axios({
                headers: reduxAuth.axiosConfig?.headers,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    dispatch({
                        type: REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_ALBUM,
                        albumId: object.id,
                        albumNextUrl: response.data.next,
                        albumObject: object,
                        albumTrackArray: response.data.results,
                        currentTrack: response.data.results[0],
                        dispatch2: dispatch2,
                        modelKey: modelKey,
                        playerOrigin: playerOrigin,
                        playerPosition: playerPosition,
                        reduxAuth: reduxAuth,
                    })
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'reduxModalPlayerSetCurrentTrack',
                        dispatch: dispatch2,
                        error,
                        reduxAuth,
                        reference: 'PLAYER_SET_CURRENT_TRACK',
                    })
                })
        } else {
            dispatch({
                type: REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_TRACK,
                currentTrack: object,
                dispatch2: dispatch2,
                modelKey: modelKey,
                playerOrigin: playerOrigin,
                playerPosition: playerPosition,
                reduxAuth: reduxAuth,
            })
        }
    }
}

export function reduxModalPlayerSetNextTrackArray(
    getUrl: string,
    playerOrigin: string,
    originalTrackArray: any[],
    trackArray: any[],
    reduxAuth: reduxAuthState,
    dispatch2: React.Dispatch<any>,
    albumId?: number,
    albumTrackArray?: any[],
) {
    return function (dispatch: React.Dispatch<any>) {
        const formData: any = new FormData()
        const ids_to_skip: any[] = []
        originalTrackArray.map((val: any) => {
            ids_to_skip.push(val.id)
            return false
        })
        formData.append('player_origin', playerOrigin)
        formData.append('ids_to_skip', ids_to_skip.join(','))
        axios({
            data: formData,
            headers: reduxAuth.axiosConfig?.headers,
            method: 'post',
            url: getUrl,
        })
            .then((response) => {
                const playableItemsArray: any[] = []
                const newAlbumTrackArray: any[] = []
                if (albumId && albumTrackArray) {
                    originalTrackArray.map((val: any) => {
                        if (val.id === albumId) { // Inject album tracks inside trackArray
                            albumTrackArray.map((val: any) => {
                                playableItemsArray.push(val)
                                newAlbumTrackArray.push(val)
                                return false
                            })
                            response.data.results.map((val: any) => {
                                playableItemsArray.push(val)
                                newAlbumTrackArray.push(val)
                                return false
                            })
                        } else if (val.is_playable) { // Inject other tracks from trackArray
                            playableItemsArray.push(val)
                        }
                        return false
                    })
                    dispatch({
                        type: REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM,
                        newAlbumNextUrl: response.data.next,
                        newAlbumTrackArray: newAlbumTrackArray,
                        newTrackArray: playableItemsArray,
                        nextType: 'album',
                    })
                } else {
                    const playableItemsArray: any[] = []
                    response.data.results.map((val: any) => {
                        if (playerOrigin.includes('feedList')) {
                            if (val.content_object && val.content_object.is_playable) playableItemsArray.push(val.content_object)
                        } else {
                            if (val.is_playable) playableItemsArray.push(val)
                        }
                        return false
                    })
                    dispatch({
                        type: REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_TRACK,
                        newOriginalTrackArray: [...originalTrackArray, ...playableItemsArray],
                        newTrackArray: [...trackArray, ...playableItemsArray],
                        nextType: 'normal',
                    })
                }
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: getUrl,
                    component: 'reduxModalPlayerSetNextTrackArray',
                    dispatch: dispatch2,
                    error,
                    reduxAuth,
                    reference: 'PLAYER_SET_NEXT_TRACK_ARRAY',
                })
            })
    }
}

export function reduxModalPlayerSetPosition(val: string) {
    return {
        type: REDUX_MODAL_PLAYER_SET_POSITION,
        payload: val,
    }
}

export function reduxModalSeoData(data: SeoSerializer | undefined) {
    return {
        type: REDUX_MODAL_SEO_DATA,
        payload: data,
    }
}

export function reduxModalSliderGetData(
    modalKey: number,
    objectId: number,
    listUrl: string
) {
    return {
        type: REDUX_MODAL_SLIDER_GET_DATA,
        payload: {
            listUrl: listUrl,
            modalKey: modalKey,
            objectId: objectId,
        }
    }
}

export function reduxModalSliderOnNext() {
    return {
        type: REDUX_MODAL_SLIDER_ON_NEXT,
    }
}

export function reduxModalSliderOnPrev() {
    return {
        type: REDUX_MODAL_SLIDER_ON_PREV,
    }
}

export function reduxModalSliderSetData(
    key: number,
    results: any[],
    dataUrl?: string,
) {
    return {
        type: REDUX_MODAL_SLIDER_SET_DATA,
        payload: {
            key: key,
            dataUrl: dataUrl,
            results: results,
        }
    }
}

export function reduxModalSliderDetailShowHide(boolean: any) {
    return {
        type: REDUX_MODAL_SLIDER_SHOW_HIDE,
        boolean: boolean,
    }
}
