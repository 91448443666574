// react components
import React, {
    useState,
} from 'react'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
} from 'components'

// serializers
import {
    OfferListSerializer,
} from 'serializers/web'

// props
type OfferListSmallBlockMobileProps = {
    edit: boolean
    object: OfferListSerializer
}

// main
export const OfferListSmallBlockMobile: React.FC<OfferListSmallBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const [deleted, setDeleted] = useState(false)

    return (
        <div
            className='offer-list-small-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='offer'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <div className='olsb-cover'>
                <LinkHelper
                    className='olsb-cover-wrap'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    <div className='olsb-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='olsb-image'
                            dominant_color={undefined}
                            lazyMobile={false}
                            src={object.get_image_square}
                        />
                    </div>
                </LinkHelper>
            </div>
        </div>
    )
})
