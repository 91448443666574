// Root URL
const filterRoot = 'userprofile/filter/profile_team/'

export const profileTeamFilterOptions: any[] = [
    // {
    // 	api_url: `${filterRoot}?filter_model=category`,
    // 	showNone: true,
    // 	slug: 'category',
    //  textId: 2575,  // reduxText[2575]
    // },
    {
        api_url: `${filterRoot}country/?`,
        showNone: true,
        slug: 'country',
        textId: 6469,  // reduxText[6469]
    },
    {
        api_url: `${filterRoot}city/?`,
        showNone: true,
        slug: 'city',
        textId: 6468,  // reduxText[6468]
    },
    {
        api_url: 'cover_image_status',
        slug: 'cover_image_status',
        textId: '',
    },
]
