// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainHashtag,
    PrivateButton,
    UnlistedButton,
} from 'components'

// data
import {
    api_url_product_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    searchTabValue,
} from 'data'

// pages
import {
    ProductActionSheetWeb,
} from 'pages'

// serializers
import {
    ProductListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type ProductListMediumBlockWebProps = {
    edit: boolean
    isInProfileSlug?: string
    modalKey: number
    object: ProductListSerializer
}

// main
export const ProductListMediumBlockWeb: React.FC<ProductListMediumBlockWebProps> = React.memo(({
    edit,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<ProductListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_product_detail}${detailObject.id}/?block_size=medium`, reduxAuth),
                component: 'ProductListMediumBlockWeb',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProductListMediumBlockWeb',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div
            className='product-list-medium-block-web'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='product'
                    objectId={detailObject.id!}
                    objectName={detailObject.name!}
                    setDeleted={setDeleted}
                />
            )}
            {detailObject.is_private && (
                <PrivateButton
                    edit={edit}
                    listed={detailObject.is_private_and_listed_in_portfolio}
                />
            )}
            {detailObject.is_unlisted && (
                <UnlistedButton edit={edit} />
            )}
            <LinkHelper
                className='prlmb-cover'
                edit={edit}
                onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
            >
                <ImageHelper
                    alt={detailObject.name}
                    className='prlmb-image'
                    dominant_color={detailObject.get_dominant_color}
                    src={detailObject.get_image_lq}
                />
            </LinkHelper>
            <div className='prlmb-info'>
                <LinkHelper
                    className='mo-list-block-name-web medium'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title={detailObject.name}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.name}
                </LinkHelper>
                <div className='prlmb-price-wrap'>
                    {!detailObject.price_on_request
                        ? (
                            <p className='prlmb-price'>{detailObject.unit_price} {detailObject.currency?.symbol || detailObject.currency?.iso_code}</p>
                        ) : (
                            <p className='prlmb-price'>{reduxText[4869]}</p>
                        )}
                </div>
                <div className='list-block-footer'>
                    {detailObject.profile && (
                        <ListProfileLink
                            blockSize='medium'
                            edit={edit}
                            other_profiles={detailObject.other_profiles}
                            profile={detailObject.profile}
                        />
                    )}
                </div>
                <div className='prlmb-tags'>
                    <MainChipListBlock>
                        {detailObject.themes?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
            </div>
            <div className='prlmb-actions'>
                <ProductActionSheetWeb
                    edit={edit}
                    isInProfileSlug={isInProfileSlug}
                    object={detailObject}
                    refreshData={onGetDetailData}
                    setActivated={setActivated}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                />
            </div>
        </div>
    )
})
