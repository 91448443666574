// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    LinkHelper,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// data
import {
    api_url_adherent_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AdherentActionSheet,
} from 'pages'

// serializers
import {
    AdherentListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type AdherentListSmallBlockProps = {
    edit: boolean
    inDetailList?: boolean
    isInProfileSlug?: string
    modalKey: number
    object: AdherentListSerializer
}

// main
export const AdherentListSmallBlock: React.FC<AdherentListSmallBlockProps> = React.memo(({
    edit,
    inDetailList,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [detailObject, setDetailObject] = useState<AdherentListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_adherent_detail}${detailObject.id}/?block_size=small`, reduxAuth),
                component: 'AdherentListSmallBlock',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdherentListSmallBlock',
                'onGetDetailData',
            ))
        }
    }

    if (object?.profile?.id === 183405) {  // Alize
        return (
            <tr className='main-list-row AdherentListSmallBlock'>
                <td>
                    <IconBlock
                        edit={edit}
                        iconClass='mo-new-icon-eye-solid'
                        onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                        to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                    />
                    <AdherentActionSheet
                        edit={edit}
                        isInProfileSlug={isInProfileSlug}
                        object={detailObject}
                        refreshData={onGetDetailData}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </td>
                <td><i className={`mlr-icon ${detailObject.active ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
                <td>
                    <LinkHelper
                        edit={edit}
                        to={detailObject.get_absolute_url!}
                    >
                        {detailObject.last_name}
                    </LinkHelper>
                </td>
                <td>
                    <LinkHelper
                        edit={edit}
                        to={detailObject.get_absolute_url!}
                    >
                        {detailObject.first_name}
                    </LinkHelper>
                </td>
                <td>{detailObject.email}</td>
                <td>{detailObject.custom_categories?.map(val => val.name).join(' - ')}</td>
                <td>{detailObject.many_to_many_profile_teams?.map(val => val.name).join(' - ')}</td>
                <td>{detailObject.date_membership_start}</td>
                <td>{detailObject.date_membership_end}</td>
                <td>{detailObject.portfolio_user_groups?.map(val => val.name).join(' - ')}</td>
            </tr>
        )
    }

    if (object?.profile?.id === 183474) {  // APPFE
        return (
            <tr className='main-list-row AdherentListSmallBlock'>
                <td>
                    <IconBlock
                        edit={edit}
                        iconClass='mo-new-icon-eye-solid'
                        onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                        to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                    />
                    <AdherentActionSheet
                        edit={edit}
                        isInProfileSlug={isInProfileSlug}
                        object={detailObject}
                        refreshData={onGetDetailData}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </td>
                <td><i className={`mlr-icon ${detailObject.active ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
                <td>
                    <LinkHelper
                        edit={edit}
                        to={detailObject.get_absolute_url!}
                    >
                        {detailObject.last_name}
                    </LinkHelper>
                </td>
                <td>
                    <LinkHelper
                        edit={edit}
                        to={detailObject.get_absolute_url!}
                    >
                        {detailObject.first_name}
                    </LinkHelper>
                </td>
                <td>{detailObject.email}</td>
                <td>{detailObject.date_membership_start}</td>
                <td>{detailObject.custom_mtm_cc_1?.map(val => val.name).join(' - ')}</td>
                <td>{detailObject.adherent_number}</td>
                <td>{detailObject.date_membership_end}</td>
            </tr>
        )
    }

    if (object?.profile?.id === 187728) {  // FCAPI
        return (
            <tr className='main-list-row AdherentListSmallBlock'>
                <td>
                    <IconBlock
                        edit={edit}
                        iconClass='mo-new-icon-eye-solid'
                        onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                        to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                    />
                    <AdherentActionSheet
                        edit={edit}
                        isInProfileSlug={isInProfileSlug}
                        object={detailObject}
                        refreshData={onGetDetailData}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </td>
                <td><i className={`mlr-icon ${detailObject.active ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
                <td>
                    <LinkHelper
                        edit={edit}
                        to={detailObject.get_absolute_url!}
                    >
                        {detailObject.last_name}
                    </LinkHelper>
                </td>
                <td>
                    <LinkHelper
                        edit={edit}
                        to={detailObject.get_absolute_url!}
                    >
                        {detailObject.first_name}
                    </LinkHelper>
                </td>
                <td>{detailObject.email}</td>
                <td>{detailObject.many_to_many_profile_teams?.map(val => val.name).join(' - ')}</td>
                <td>{detailObject.custom_date_1}</td>
                <td>{detailObject.date_membership_end}</td>
            </tr>
        )
    }

    if (object?.profile?.id === 187807) {  // HEY
        return (
            <tr className='main-list-row AdherentListSmallBlock'>
                <td>
                    <IconBlock
                        edit={edit}
                        iconClass='mo-new-icon-eye-solid'
                        onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                        to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                    />
                    <AdherentActionSheet
                        edit={edit}
                        isInProfileSlug={isInProfileSlug}
                        object={detailObject}
                        refreshData={onGetDetailData}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </td>
                <td><i className={`mlr-icon ${detailObject.active ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
                <td>
                    <LinkHelper
                        edit={edit}
                        to={detailObject.get_absolute_url!}
                    >
                        {detailObject.last_name}
                    </LinkHelper>
                </td>
                <td>
                    <LinkHelper
                        edit={edit}
                        to={detailObject.get_absolute_url!}
                    >
                        {detailObject.first_name}
                    </LinkHelper>
                </td>
                <td>{detailObject.email}</td>
            </tr>
        )
    }

    return null
})
