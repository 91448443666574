// react components
import React, {
    useEffect,
} from 'react'
import {
    useIonViewWillLeave,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    ProjectPlayButtonWeb,
} from 'components'

// data
import {
    defaultReduxState,
    media_url_platform,
    media_version,
    reduxModalErrorEventHandler,
    reduxModalPlayerCheckPosition,
    reduxModalPlayerOnPlayPause,
    reduxModalPlayerSetCurrentTrack,
    reduxModalPlayerSetPosition,
} from 'data'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// props
type InPagePlayerWrapWebProps = {
    edit: boolean
    object: ProjectDetailSerializer
}

// main
export const InPagePlayerWrapWeb: React.FC<InPagePlayerWrapWebProps> = React.memo(({
    edit,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalplayer = useSelector((state: defaultReduxState) => state.reduxModal.player)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (object.id) dispatch(reduxModalPlayerCheckPosition(object.id, 'enter'))
    }, [object.id])

    useIonViewWillLeave(() => {
        if (object.id) dispatch(reduxModalPlayerCheckPosition(object.id, 'leave'))
    })

    function handlePreviewContent(object: any) {
        try {
            if (reduxModalplayer.currentTrack?.id === object.id) {
                dispatch(reduxModalPlayerOnPlayPause(!reduxModalplayer.playing))
            } else {
                // @ts-ignore
                dispatch(reduxModalPlayerSetCurrentTrack(
                    object,
                    reduxAuth,
                    dispatch,
                    'detail',
                    'detailPage',
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InPagePlayerWrapWeb',
                'handlePreviewContent',
            ))
        }
    }

    function handleFullScreen(object: any) {
        try {
            if (reduxModalplayer.currentTrack?.id !== object.id) {
                // @ts-ignore
                dispatch(reduxModalPlayerSetCurrentTrack(
                    object,
                    reduxAuth,
                    dispatch,
                    'detail',
                    'detailPage',
                ))
            }
            dispatch(reduxModalPlayerSetPosition('full'))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InPagePlayerWrapWeb',
                'handleFullScreen',
            ))
        }
    }

    return (
        <div className='pcbw-image-wrap playable'>
            <div className='pcbw-image-div'>
                <ImageHelper
                    alt={object.name}
                    className='pcbw-image'
                    dominant_color={object.get_dominant_color}
                    onClick={() => handlePreviewContent(object)}
                    src={object.get_image_hq}
                />
            </div>
            {object.is_playable && (
                <div className='pcb-link-url'>
                    {object.medium_type?.id === 3
                        ? (
                            <ProjectPlayButtonWeb
                                edit={edit}
                                inDetail
                                isVideo
                                modalKey={undefined}
                                object={object}
                                playerOrigin={`projectDetail-${object.id}`}
                            />
                        ) : (
                            <React.Fragment>
                                <div
                                    className='pcb-platform-wrap'
                                    onClick={() => object.platform ? handlePreviewContent(object) : undefined}
                                >
                                    {object.platform && (
                                        <React.Fragment>
                                            <p className='pcb-powered'>{reduxText[5883]}</p>
                                            <ImageHelper
                                                alt={object.name}
                                                className='pcb-platform'
                                                dominant_color={object.get_dominant_color}
                                                src={`${media_url_platform}${object.platform}.svg${media_version}`}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className='pcb-play-button-wrap'>
                                    <ProjectPlayButtonWeb
                                        edit={edit}
                                        inDetail
                                        modalKey={undefined}
                                        object={object}
                                        playerOrigin={`projectDetail-${object.id}`}
                                    />
                                </div>
                                <IconBlock
                                    className='pcb-fullscreen-icon'
                                    edit={edit}
                                    iconClass='mo-new-icon-expand-solid'
                                    onClick={() => handleFullScreen(object)}
                                    tooltipText={reduxText[4019]}
                                />
                            </React.Fragment>
                        )
                    }
                </div>
            )}
        </div>
    )
})
