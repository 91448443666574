// react components
import React from 'react'
import {
    NavLink,
} from 'react-router-dom'

// main
export const AgendaDuQuartierAdminMenu: React.FC = () => {

    const menuItems = [
        {
            title: 'Mes informations',
            slug: 'infos',
        },
        {
            title: 'Profil quartier',
            slug: 'settings',
        },
        // {
        //     title: 'Mes commandes',
        //     slug: 'orders',
        // },
        {
            title: 'Mes factures',
            slug: 'invoices',
        },
        {
            title: 'Mes devis',
            slug: 'quotations',
        },
        {
            title: 'Modes de paiement',
            slug: 'payment-methods',
        },
        // {
        //     title: 'Commander des banderoles ou des affiches supplémentaires',
        //     slug: 'order-items',
        // },
        // {
        //     title: 'Nous contacter',
        //     slug: 'contact',
        // },
        // {
        //     title: 'Données disponibles et téléchargeables',
        //     slug: 'data-download',
        // },
        // {
        //     title: 'Désactiver l’Agenda',
        //     slug: 'deactivate',
        // },
        // {
        //     title: 'Supprimer le compte',
        //     slug: 'delete',
        // },
    ]

    return (
        <div className='admin-menu'>
            {menuItems.map(val => (
                <div
                    key={val.slug}
                    className='admin-menu-item'
                >
                    <div className='ami-tab-item-wrap'>
                        <NavLink
                            className='ami-tab-item'
                            to={`/admin/${val.slug}/`}
                        >
                            {val.title}
                        </NavLink>
                    </div>
                </div>
            ))}
        </div>
    )
}
