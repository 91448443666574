import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'

// data
import {
    reduxAuthState,
} from 'data'

// services
import {
    getDateWithType,
} from 'services'

// main
export function getSchedule(
    reduxAuth: reduxAuthState,
    startDate: string,
    endDate?: string,
    dateOnly?: boolean,
    date_type_choice?: 'd' | 'm' | 'y',
) {
    if (date_type_choice && date_type_choice !== 'd') {
        return getDateWithType(reduxAuth, startDate, date_type_choice)
    }
    moment.locale('en') // default the locale to English
    const localStartDate = moment(startDate)
    const localEndDate = moment(endDate)

    if (reduxAuth.settings?.language) {
        if (reduxAuth.settings.language === 'fr') {
            localStartDate.locale('fr')
            localEndDate.locale('fr')
        } // set this instance to use French
    }

    // const todayYear = moment().format('YYYY')
    // const startYear = (localStartDate.format('YYYY'))
    // const startYearCheck = todayYear === startYear

    if (endDate && (localStartDate.format('D MMM YYYY')) === (localEndDate.format('D MMM YYYY'))) {
        if (dateOnly) {
            // if (startYearCheck) {
            //     return `${localStartDate.format('ddd D MMM')}`
            // }
            return `${localStartDate.format('ddd D MMM YYYY')}`
        }
        // if (startYearCheck) {
        //     return `${localStartDate.format('ddd D MMM - HH:mm')} - ${localEndDate.format('HH:mm')}`
        // }
        return `${localStartDate.format('ddd D MMM YYYY - HH:mm')} - ${localEndDate.format('HH:mm')}`
    }
    if (!dateOnly && endDate && (localEndDate.diff(localStartDate)) < 86700000) {
        // if (dateOnly) {
        // 	if (startYearCheck) {
        // 		return `${localStartDate.format('ddd D MMM')}`
        // 	}
        //     return `${localStartDate.format('ddd D MMM YYYY')}`
        // }
        // if (startYearCheck) {
        //     return `${localStartDate.format('ddd D MMM - HH:mm')} - ${localEndDate.format('HH:mm')}`
        // }
        return `${localStartDate.format('ddd D MMM YYYY - HH:mm')} - ${localEndDate.format('HH:mm')}`
    }
    if (endDate) {
        if (dateOnly) {
            // if (startYearCheck) {
            //     return `${localStartDate.format('ddd D MMM')} - ${localEndDate.format('ddd D MMM')}`
            // }
            return `${localStartDate.format('ddd D MMM YYYY')} - ${localEndDate.format('ddd D MMM YYYY')}`
        }
        // if (startYearCheck) {
        //     return `${localStartDate.format('ddd D MMM - HH:mm')} - ${localEndDate.format('ddd D MMM - HH:mm')}`
        // }
        return `${localStartDate.format('ddd D MMM YYYY - HH:mm')} - ${localEndDate.format('ddd D MMM YYYY - HH:mm')}`
    }
    if (dateOnly) {
        // if (startYearCheck) {
        //     return localStartDate.format('ddd D MMM')
        // }
        return localStartDate.format('ddd D MMM YYYY')
    }
    // if (startYearCheck) {
    //     return localStartDate.format('ddd D MMM - HH:mm')
    // }
    return localStartDate.format('ddd D MMM YYYY - HH:mm')
}
