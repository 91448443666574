// data
import {
    api_url_project_form_create,
    api_url_project_form_detail,
    api_url_project_form_list,
    api_url_project_form_update,
    reduxAuthState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function AssetFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'asset',

        // Urls
        activeUrl: '',
        createUrl: getApiUrl(api_url_project_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_project_form_detail, reduxAuth),
        listUrl: getApiUrl(api_url_project_form_list, reduxAuth),
        patchUrl: '',
        updateUrl: getApiUrl(api_url_project_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: {},

        // Required fields are dynamic depending on the medium type of the mother
        requiredAllFields: [],
        requiredAttribute: [],
        requiredForeignKey: [],
        requiredManyToMany: [],

        formAttribute: [
            'active',
            'cover_image_alt',
            'cover_image_doka_output_data',
            'cover_image_square_doka_output_data',
            'custom_text',
            'custom_text_2',
            'custom_text_3',
            'custom_text_rich',
            'custom_text_rich_2',
            'custom_text_rich_3',
            'date',
            'date_end',
            'description',
            'duration', // Automatic from getMediaData
            'is_private',
            'is_private_and_listed_in_portfolio',
            'is_unlisted',
            'link_id', // Can be automatic from getMediaData
            'link_url',
            'lyrics',
            'member_name',
            'meta_image_url',
            'name',
            'parent_content_type',
            'parent_object_id',
        ],
        formFile: [
            'cover_image',
            'cover_image_original',
            'cover_image_square',
            'pdf_file',
        ],
        formForeignKey: [
            'date_type_choice',
            'medium_type',
            'profile',
        ],
        formJson: [],
        formJsonNull: [
            'address_json',
            'styles_extra',
        ],
        formManyToMany: [
            'custom_categories',
            'custom_categories_2',
            'custom_categories_3',
            'languages',
            'mixed_media_fonts',
            'themes',
        ],
    }
}
