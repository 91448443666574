// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// containers
import {
    TabListContainerWeb,
} from 'containers'

// data
import {
    api_url_portfolio_template_list,
    api_url_portfolio_template_map,
    api_url_portfolio_user_list,
    api_url_portfolio_user_map,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export const PortfolioList: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const filterParamsApiUrl: any = {
        'po-all': {
            listApiUrl: getApiUrl(api_url_portfolio_template_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_portfolio_template_map, reduxAuth),
        },
        'po-portfolios': {
            listApiUrl: getApiUrl(`${api_url_portfolio_template_list}?is_eshop=False`, reduxAuth),
            mapApiUrl: getApiUrl(`${api_url_portfolio_template_map}?is_eshop=False`, reduxAuth),
        },
        'po-eshops': {
            listApiUrl: getApiUrl(`${api_url_portfolio_template_list}?is_eshop=True`, reduxAuth),
            mapApiUrl: getApiUrl(`${api_url_portfolio_template_map}?is_eshop=True`, reduxAuth),
        },
        'po-users-all': {
            listApiUrl: getApiUrl(api_url_portfolio_user_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_portfolio_user_map, reduxAuth),
        },
        'po-users-portfolios': {
            listApiUrl: getApiUrl(`${api_url_portfolio_user_list}?is_eshop=False`, reduxAuth),
            mapApiUrl: getApiUrl(`${api_url_portfolio_user_map}?is_eshop=False`, reduxAuth),
        },
        'po-users-eshops': {
            listApiUrl: getApiUrl(`${api_url_portfolio_user_list}?is_eshop=True`, reduxAuth),
            mapApiUrl: getApiUrl(`${api_url_portfolio_user_map}?is_eshop=True`, reduxAuth),
        },
    }

    return (
        <TabListContainerWeb
            defaultTab='po-all'
            filterParamsApiUrl={filterParamsApiUrl}
            noThumb
            random
            titleId={6265}
        />
    )
}
