// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DateTimeInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    OfferDetailFormSerializer,
    OfferDetailFormSerializerError,
} from 'serializers/web'

// props
type ValidDateOfferInputProps = {
    errors: OfferDetailFormSerializerError | undefined
    fields: OfferDetailFormSerializer
    onChange: any
}

// main
export const ValidDateOfferInput: React.FC<ValidDateOfferInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <DateTimeInput
            error={errors?.valid_date}
            format='date'
            helperText={reduxText[43410]}
            label={reduxText[434]}
            name='valid_date'
            onChange={onChange}
            optional
            value={fields.valid_date || null}
        />
    )
})
