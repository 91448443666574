// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ServiceDetailFormSerializer,
    ServiceDetailFormSerializerError,
} from 'serializers/web'

// props
type IsPrivateListedServiceInputProps = {
    errors: ServiceDetailFormSerializerError | undefined
    fields: ServiceDetailFormSerializer
    onChange: any
}

// main
export const IsPrivateListedServiceInput: React.FC<IsPrivateListedServiceInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!fields.is_private) return null

    return (
        <CheckboxInput
            error={errors?.is_private_and_listed_in_portfolio}
            label={reduxText[8574]}
            name='is_private_and_listed_in_portfolio'
            onChange={onChange}
            value={fields.is_private_and_listed_in_portfolio}
        />
    )
})
