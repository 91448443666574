// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// services
import {
    getSchedule,
} from 'services'

// serializers
import {
    EventChildHelperSerializer,
} from 'serializers/web'

// props
type ScheduleListBlockMobileProps = {
    object: EventChildHelperSerializer
}

// main
export const ScheduleListBlockMobile: React.FC<ScheduleListBlockMobileProps> = React.memo(({
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    return (
        <div className='schedule-list-block'>
            <p className='slb-date'>{object.start_date && getSchedule(reduxAuth, object.start_date, object.end_date)}</p>
        </div>
    )
})
