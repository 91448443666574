// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    RichTextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    MaterialDetailFormSerializer,
    MaterialDetailFormSerializerError,
    MaterialFormState,
} from 'serializers/web'

// props
type CustomTextRichMaterialInputProps = {
    errors: MaterialDetailFormSerializerError | undefined
    fields: MaterialDetailFormSerializer
    mother: MaterialFormState['mother']
    onChange: any
    version?: number
}

// main
export const CustomTextRichMaterialInput: React.FC<CustomTextRichMaterialInputProps> = React.memo(({
    errors,
    fields,
    mother,
    onChange,
    version,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    let tinymceStylesString = ''
    const tinymceStylesObject: any = {}
    if (fields.portfolio_main?.styles) {
        Object.getOwnPropertyNames(fields.portfolio_main.styles.value).map((val: any) => {
            if (val === 'fontFamily') {
                if (fields.portfolio_main!.styles!.value[val]) {
                    tinymceStylesString += `font-family: ${fields.portfolio_main!.styles!.value[val]};`
                }
            }
            if ([
                'fontFamily',
            ].includes(val) && fields.portfolio_main!.styles!.value[val]) {
                tinymceStylesObject[val] = fields.portfolio_main!.styles!.value[val]
            }
            return false
        })
    }

    const text_dict: any = {
        2: reduxText[8677],
        3: reduxText[8733],
    }

    return (
        <RichTextInput
            // @ts-ignore
            error={errors?.[`custom_text_rich${version ? `_${version}` : ''}`]}
            fonts={fields.portfolio_main?.fonts}
            label={version ? text_dict[version] : reduxText[7685]}
            name={`custom_text_rich${version ? `_${version}` : ''}`}
            objectId={fields.id}
            onChange={onChange}
            optional
            presetColors={fields.preset_colors}
            tinymceStylesObject={tinymceStylesObject}
            tinymceStylesString={tinymceStylesString}
            translation={{
                contentType: 'material',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            // @ts-ignore
            value={fields[`custom_text_rich${version ? `_${version}` : ''}`] || ''}
        />
    )
})
