// react components
import React from 'react'
import {
    DropzoneComponent,
} from 'react-dropzone-component'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
    MainFormLabel,
    SelectListInput,
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    InlineFormPanelProps,
    ProfileFormState,
} from 'serializers/web'

// props
type ImageProfilePanelProps = {
    formState: ProfileFormState
    setFormState: React.Dispatch<ProfileFormState>
}

// main
export const ImageProfilePanel: React.FC<InlineFormPanelProps & ImageProfilePanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const image = formState.image
    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, image)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageProfilePanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = image.errors
    const fieldsInline = image.fields

    if (!fieldsInline) return null

    // Extra
    let myDropzone: any
    function initCallback(dropzone: any) {
        myDropzone = dropzone
    }
    const config = {
        iconFiletypes: ['.gif', '.jpg', '.jpeg', '.png', '.webp'],
        postUrl: image.createUrl,
        showFiletypeIcon: true,
    }
    const eventHandlers = {
        init: (e: any) => initCallback(e),
        complete: (file: any) => {
            myDropzone.removeFile(file)
            dispatch(reduxFormSetRefresh('refreshInlineList'))
        }
    }
    const djsConfig = {
        acceptedFiles: 'image/gif,image/jpeg,image/png,image/webp',
        dictDefaultMessage: reduxText[5574],
        headers: reduxAuth.axiosConfig?.headers,
        maxFilesize: 64,
        maxThumbnailFilesize: 64,
        parallelUploads: 1,
        paramName: 'image',
        timeout: null,
        params: {
            active: true,
            profile: fieldsMother.id,
        },
    }
    const customGroupOptions = [
        { id: '1', name: reduxText[8726] },
        { id: '2', name: reduxText[8727] },
    ]

    return (
        <InlineFormContainerWeb
            formInfo={image}
            formState={formState}
            helpers={helpers}
            isDropzone
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {fieldsInline.id ? (
                <React.Fragment>
                    <ImageCropInput
                        altButton
                        altValue={fieldsInline.image_alt}
                        doka_output_data={fieldsInline.image_doka_output_data || ''}
                        error={errorsInline?.image}
                        errorAlt={errorsInline?.image_alt}
                        label={fieldsInline.id ? reduxText[4478] : reduxText[1767]}
                        name='image'
                        onChange={onHandleInputChange}
                        original={fieldsInline.image_original || ''}
                        value={fieldsInline.image || ''}
                    />
                    <TextInput
                        error={errorsInline?.description}
                        label={reduxText[4381]}
                        name='description'
                        onChange={onHandleInputChange}
                        value={fieldsInline.description || ''}
                    />
                    <SelectListInput
                        clearable
                        error={errorsInline?.custom_group}
                        label={reduxText[8725]}
                        name='custom_group'
                        onChange={onHandleInputChange}
                        optional
                        options={customGroupOptions}
                        value={fieldsInline.custom_group || ''}
                    />
                </React.Fragment>
            ) : (
                <div className='mo-image-input-web'>
                    <MainFormLabel
                        label={reduxText[1767]}
                        name='image'
                    />
                    <DropzoneComponent
                        config={config}
                        djsConfig={djsConfig}
                        eventHandlers={eventHandlers}
                    />
                </div>
            )}
        </InlineFormContainerWeb>
    )
})
