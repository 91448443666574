// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// containers
import {
    MainFormContainerWrapper,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetAvailabitySite,
    reduxModalErrorEventHandler,
    reduxModalSetEditSite,
    select_url_portfolio_content_source_available,
} from 'data'

// pages
import {
    AdditionalEventPanel,
    AddressEventPanel,
    AssetFormState,
    AssetPanel,
    ContainerMixedContentPanel,
    ContainerMixedPanel,
    EventImageFormState,
    EventMotherFormState,
    EventParticipantFormState,
    EventPricingFormState,
    EventScheduleFormState,
    EventTeamFormState,
    ImageEventPanel,
    MainEventPanel,
    MenuEventPanel,
    ParticipantEventPanel,
    PricingEventPanel,
    PortfolioEventPanel,
    ProjectMixedMediaContentFormState,
    ScheduleEventPanel,
    TeamEventPanel,
} from 'pages'

// serializers
import {
    EventFormState,
} from 'serializers/web'

// services
import {
    autoSaveStyles,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailData,
    getDynamicTextOptions,
    getInputValue,
} from 'services'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type LocationParams = {
    formRefresh?: number
}

type EventFormProps = {
    objectId?: number
    onRefresh?: () => void
}

// main
export const EventForm: React.FC<EventFormProps> = React.memo(({
    objectId,
    onRefresh,
}) => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<EventFormState>({
        mother: EventMotherFormState(reduxAuth),
        asset: AssetFormState(reduxAuth),
        image: EventImageFormState(reduxAuth),
        mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
        participant: EventParticipantFormState(reduxAuth),
        pricing: EventPricingFormState(reduxAuth),
        schedule: EventScheduleFormState(reduxAuth),
        team: EventTeamFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [inlineModalValue, setInlineModalValue] = useState('')
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)

    useEffect(() => {
        try {
            autoSaveStyles({
                component: 'EventForm',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventForm',
                'useEffect',
            ))
        }
    }, [
        params.panelValue,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailData({
            axiosCancelToken,
            component: 'EventForm',
            dispatch,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: objectId ? `${objectId}` : params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        objectId,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 25 && formState.mother.fields?.profile?.id) {
            getAvailabeContentSource()
        }
    }, [
        formState.mother.fields?.medium_type?.id,
        formState.mother.fields?.profile?.id,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        if (formState.mother.fields?.medium_type?.id === 25) {
            dispatch(reduxModalSetEditSite(true))
            if (!reduxFormSite.dynamicTextOptions) {
                getDynamicTextOptions({
                    component: 'EventForm',
                    dispatch,
                    reduxAuth,
                })
            }
        }
    }, [
        formState.mother.fields?.medium_type?.id,
    ])

    function setInitialState() {
        const mother = EventMotherFormState(reduxAuth)
        setFormState({
            mother: {
                ...mother,
                fields: {
                    date_type_choice: {
                        id: 'd',
                        name: reduxText[4832],
                    },
                    profile: !reduxAuth.settings?.user?.is_staff ? reduxAuth.settings?.active_profile : undefined,
                },
            },
            asset: AssetFormState(reduxAuth),
            image: EventImageFormState(reduxAuth),
            mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
            participant: EventParticipantFormState(reduxAuth),
            pricing: EventPricingFormState(reduxAuth),
            schedule: EventScheduleFormState(reduxAuth),
            team: EventTeamFormState(reduxAuth),
        })
    }

    async function getAvailabeContentSource() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EventForm getAvailabeContentSource')
            const getUrl = getApiUrl(`${select_url_portfolio_content_source_available}?profile_id=${formState.mother.fields?.profile?.id}`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetAvailabitySite(response.data[0].available))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'EventForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getAvailabeContentSource',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventForm',
                'getAvailabeContentSource',
            ))
        }
    }

    function handleInputChange(event: any, formInfo: any) {
        try {
            const mother = formState.mother
            const team = formState.team
            if (formInfo.name === 'mother' && event.name === 'profile' && mother) {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        profile: event.value,
                    },
                    errors: {
                        ...mother.errors,
                        profile: '',
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '',
                })
            } else if (formInfo.name === 'mother' && mother) {
                if (event.name === 'mixed_media_fonts') {
                    const toReturn = {
                        ...mother,
                        errors: {
                            ...mother.errors,
                            mixed_media_fonts: '',
                        },
                        fields: {
                            ...mother.fields,
                            fonts: event.value,
                            mixed_media_fonts: event.value,
                        },
                    }
                    setFormState({
                        ...formState,
                        mother: toReturn,
                    })
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: true,
                        mainError: '', // TO_CHECK
                    })
                } else if (event.name === 'styles_original') {
                    const toReturn = {
                        ...mother,
                        errors: {
                            ...mother.errors,
                            styles_original: '',
                        },
                        fields: {
                            ...mother.fields,
                            styles: event.preview,
                            styles_original: event.value,
                        },
                    }
                    setFormState({
                        ...formState,
                        mother: toReturn,
                    })
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: true,
                        mainError: '', // TO_CHECK
                    })
                } else {
                    setFormState({
                        ...formState,
                        mother: getInputValue({
                            event,
                            formInfo,
                            reduxText,
                        })
                    })
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: true,
                        mainError: '',
                    })
                }
            } else if (formInfo.name === 'mixedMediaContent' && event.name === 'styles_original' && mother) {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
            } else if (formInfo.name === 'team' && event.name === 'name' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: '',
                        name: event.value,
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'team' && event.name === 'member' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: event.value,
                        name: '',
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                        'member',
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventForm',
                'handleInputChange',
            ))
        }
    }

    const panelValue = params.panelValue
    const panelText: any = {
        'additional-panel': reduxText[3234],
        'address-panel': reduxText[2299],
        'asset-panel': reduxText[8149],
        'delete-panel': reduxText[2983],
        'image-panel': reduxText[1767],
        'main-panel': reduxText[347],
        'participant-panel': reduxText[8555],
        'pricing-panel': reduxText[8417],
        'schedule-panel': reduxText[4894],
        'team-panel': reduxText[5407],
        'website-related-panel': reduxText[6415],
    }

    const fieldsMother = formState.mother.fields

    return (
        <MainFormContainerWrapper
            formState={formState}
            headerTitle={panelValue ? panelText[panelValue] : ''}
            helpers={helpers}
            onRefresh={onRefresh}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {onRefresh && fieldsMother?.id && (
                <React.Fragment>
                    <p className='mfcmw-custom-edit-title'>{panelText['main-panel']}</p>
                    <MainEventPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['additional-panel']}</p>
                    <AdditionalEventPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                    <p className='mfcmw-custom-edit-title'>{panelText['website-related-panel']}</p>
                    <PortfolioEventPanel
                        formState={formState}
                        handleInputChange={handleInputChange}
                    />
                </React.Fragment>
            )}
            {!onRefresh && (
                <React.Fragment>
                    {panelValue === 'main-panel' && (
                        <MainEventPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'team-panel' && fieldsMother?.id && (
                        <TeamEventPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'address-panel' && fieldsMother?.id && (
                        <AddressEventPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'additional-panel' && fieldsMother?.id && (
                        <AdditionalEventPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'asset-panel' && fieldsMother?.id && (
                        <AssetPanel
                            contentType='event'
                            // @ts-ignore
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            // @ts-ignore
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'image-panel' && fieldsMother?.id && (
                        <ImageEventPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {params.formType === 'mixed-media' && fieldsMother?.id && (
                        <ContainerMixedPanel
                            contentType='event'
                            // @ts-ignore
                            formState={formState}
                            handleInputChange={handleInputChange}
                            // @ts-ignore
                            setFormState={setFormState}
                        />
                    )}
                    {params.formType === 'mixed-media-content' && fieldsMother?.id && (
                        <ContainerMixedContentPanel
                            contentType='event'
                            // @ts-ignore
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            newMixedMediaContent={false}
                            newMixedMediaContentType={undefined}
                            // @ts-ignore
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {panelValue === 'website-related-panel' && fieldsMother?.id && (
                        <PortfolioEventPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                        />
                    )}
                    {panelValue === 'schedule-panel' && fieldsMother?.id && (
                        <ScheduleEventPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                        />
                    )}
                    {fieldsMother?.id && inlineModalValue === 'pricing-panel' && (
                        <PricingEventPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            modalTitle={panelText['pricing-panel']}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                            setInlineModalValue={setInlineModalValue}
                        />
                    )}
                    {fieldsMother?.id && inlineModalValue === 'participant-panel' && (
                        <ParticipantEventPanel
                            formState={formState}
                            handleInputChange={handleInputChange}
                            helpers={helpers}
                            modalTitle={panelText['participant-panel']}
                            setFormState={setFormState}
                            setHelpers={setHelpers}
                            setInlineModalValue={setInlineModalValue}
                        />
                    )}
                    {!panelValue && (
                        <MenuEventPanel
                            formState={formState}
                            helpers={helpers}
                            panelText={panelText}
                            setHelpers={setHelpers}
                            setInlineModalValue={setInlineModalValue}
                        />
                    )}
                </React.Fragment>
            )}
        </MainFormContainerWrapper>
    )
})
