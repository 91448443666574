// react components
import React, {
    useState,
} from 'react'
import {
    IonButtons,
} from '@ionic/react'

// components
import {
    IconBlock,
} from 'components'

// pages
import {
    EventActionSheetMobile,
    MaterialActionSheetMobile,
    OfferActionSheetMobile,
    ProductActionSheetMobile,
    ProfileActionSheetMobile,
    ProjectActionSheetMobile,
} from 'pages'

// props
type MainDetailEditBlockMobileProps = {
    componentActionSheet?: string
    object: {
        id?: number
        profile?: {
            id?: number
        }
        owner_info?: {
            permission?: boolean
        }
    }
    refreshData?: () => void
}

// main
export const MainDetailEditBlockMobile: React.FC<MainDetailEditBlockMobileProps> = React.memo(({
    componentActionSheet,
    object,
    refreshData,
}) => {

    const [isActionSheetOpen, setActionSheetIsOpen] = useState(false)

    if (!object.id) return null
    if (!componentActionSheet) return null

    const actionComponents: any = {
        EventActionSheetMobile,
        MaterialActionSheetMobile,
        OfferActionSheetMobile,
        ProductActionSheetMobile,
        ProfileActionSheetMobile,
        ProjectActionSheetMobile,
    }
    const ActionComponentName = actionComponents[componentActionSheet]

    return (
        <React.Fragment>
            <IonButtons slot='end'>
                <IconBlock
                    edit={false}
                    iconClass='mo-new-icon-ellipsis-h-solid'
                    onClick={() => setActionSheetIsOpen(true)}
                />
            </IonButtons>
            {isActionSheetOpen && (
                <ActionComponentName
                    object={object}
                    refreshData={refreshData}
                    setActivated={undefined}
                    setIsOpen={setActionSheetIsOpen}
                />
            )}
        </React.Fragment>
    )
})
