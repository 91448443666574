// Root URL
const rootPath = 'portfolio/'

// Action
export const api_url_portfolio_action = `${rootPath}action/portfolio/`

// Detail
export const api_url_portfolio_detail = `${rootPath}detail/portfolio/`

// Form Portfolio
export const api_url_portfolio_form_active = `${rootPath}form/portfolio/active/`
export const api_url_portfolio_form_create = `${rootPath}form/portfolio/create/`
export const api_url_portfolio_form_detail = `${rootPath}form/portfolio/detail/`
export const api_url_portfolio_form_duplicate = `${rootPath}form/portfolio/duplicate/`
export const api_url_portfolio_form_update = `${rootPath}form/portfolio/update/`

// Form Portfolio Style
export const api_url_portfolio_style_form_list = `${rootPath}form/portfolio_style/list/`
export const api_url_portfolio_style_form_update = `${rootPath}form/portfolio_style/update/`

// Form Page
export const api_url_portfolio_page_form_active = `${rootPath}form/page/active/`
export const api_url_portfolio_page_form_create = `${rootPath}form/page/create/`
export const api_url_portfolio_page_form_detail = `${rootPath}form/page/detail/`
export const api_url_portfolio_page_form_detail_id = `${rootPath}form/page/detail_id/`
export const api_url_portfolio_page_form_list = `${rootPath}form/page/list/`
export const api_url_portfolio_page_form_patch = `${rootPath}form/page/patch/`
export const api_url_portfolio_page_form_update = `${rootPath}form/page/update/`

// Form Page Group
export const api_url_portfolio_page_group_form_create = `${rootPath}form/page_group/create/`
export const api_url_portfolio_page_group_form_detail = `${rootPath}form/page_group/detail/`
export const api_url_portfolio_page_group_form_list = `${rootPath}form/page_group/list/`
export const api_url_portfolio_page_group_form_patch = `${rootPath}form/page_group/patch/`
export const api_url_portfolio_page_group_form_update = `${rootPath}form/page_group/update/`

// Form Page Style
export const api_url_portfolio_page_style_form_update = `${rootPath}form/page_style/update/`

// Form Page Content
export const api_url_portfolio_page_content_form_active = `${rootPath}form/page_content/active/`
export const api_url_portfolio_page_content_form_create = `${rootPath}form/page_content/create/`
export const api_url_portfolio_page_content_form_detail = `${rootPath}form/page_content/detail/`
export const api_url_portfolio_page_content_form_list = `${rootPath}form/page_content/list/`
export const api_url_portfolio_page_content_form_patch = `${rootPath}form/page_content/patch/`
export const api_url_portfolio_page_content_form_update = `${rootPath}form/page_content/update/`

// Form Image
export const api_url_portfolio_user_portfolio_form_active = `${rootPath}form/user_portfolio/active/`
export const api_url_portfolio_user_portfolio_form_create = `${rootPath}form/user_portfolio/create/`
export const api_url_portfolio_user_portfolio_form_detail = `${rootPath}form/user_portfolio/detail/`
export const api_url_portfolio_user_portfolio_form_list = `${rootPath}form/user_portfolio/list/`
export const api_url_portfolio_user_portfolio_form_update = `${rootPath}form/user_portfolio/update/`

// List
export const api_url_portfolio_list = `${rootPath}list/portfolio/`
export const api_url_portfolio_template_list = `${rootPath}list/portfolio/?is_template=true`
export const api_url_portfolio_user_list = `${rootPath}list/portfolio/user/`

// Map
export const api_url_portfolio_map = `${rootPath}map/portfolio/`
export const api_url_portfolio_template_map = `${rootPath}map/portfolio/template/`
export const api_url_portfolio_user_map = `${rootPath}map/portfolio/user/`
