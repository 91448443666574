// react components
import React from 'react'

// components
import {
    LinkHelper,
    RatioZarmingHelper,
} from 'components'

// serializers
import {
    ProfileShortInfoSerializer,
} from 'serializers/web'

// props
type ProfileShortInfoWebProps = {
    edit: boolean
    name?: string
    noImage?: boolean
    object?: ProfileShortInfoSerializer | undefined
    right?: boolean
}

// main
export const ProfileShortInfoWeb: React.FC<ProfileShortInfoWebProps> = React.memo(({
    edit,
    name,
    noImage,
    object,
    right,
}) => {
    return (
        <div className={`profile-short-info-web${right ? ' right' : ''}`}>
            {!noImage && (
                <div className='psiw-image'>
                    {object && (
                        <RatioZarmingHelper
                            borderRadius='50%'
                            dominant_color={object.get_dominant_color}
                            edit={edit}
                            fontSize={12}
                            height={30}
                            name={object.name}
                            notZarma={Boolean(object.get_image_xs)}
                            src={object.get_image_xs}
                            to={object.get_absolute_url}
                        />
                    )}
                </div>
            )}
            <div className='psiw-name-wrap'>
                <LinkHelper
                    edit={edit}
                    to={object?.get_absolute_url}
                >
                    <p className='psiw-name'>{(object && object.name) || name}</p>
                </LinkHelper>
            </div>
        </div>
    )
})
