// react components
import React, {
    useState,
} from 'react'
import {
    IonThumbnail,
} from '@ionic/react'
import {
    useParams,
} from 'react-router-dom'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    LinkHelper,
    Loader,
} from 'components'

// data
import {
    contentTypeData,
    defaultReduxState,
    reduxFormSetHoveredBlockIdSite,
    reduxFormSetSectionIdSite,
    reduxModalErrorEventHandler,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    EditButtonPageContent,
    EditButtonPageContentChildren,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    BuilderService,
    handleMoveContent,
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    contentId: string
    pageSlug: string
    detailId: string
}

type TemplateBlockNormalPageContentProps = {
    formState: PortfolioFormState
    isInList: boolean
    mainParentId?: number
    noAllowDrop?: boolean
    object: PortfolioPageContentListSiteSerializer
    parentStyles: any
    setTabValue?: React.Dispatch<string>
}

// main
export const TemplateBlockNormalPageContent: React.FC<TemplateBlockNormalPageContentProps> = React.memo(({
    formState,
    isInList,
    mainParentId,
    noAllowDrop,
    object,
    parentStyles,
    setTabValue,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSitenavbarHeight = useSelector((state: defaultReduxState) => state.reduxModalSite.navbarHeight)
    const reduxModalSitenavbarType = useSelector((state: defaultReduxState) => state.reduxModalSite.navbarType)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    function handleCssClassHover(mousedirection: 'enter' | 'leave') {
        try {
            if (mousedirection === 'enter') {
                dispatch(reduxFormSetHoveredBlockIdSite(object.id))
            } else {
                dispatch(reduxFormSetHoveredBlockIdSite(undefined))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TemplateBlockNormalPageContent',
                'handleCssClassHover',
            ))
        }
    }

    function handleDropDone(position: 'after' | 'before' | 'inside' | undefined) {
        try {
            handleMoveContent({
                component: 'TemplateBlockNormalPageContent',
                dispatch,
                mixedMedia: false,
                object,
                paramsId: params.id,
                position,
                reduxAuth,
                setIsLoading,
                setIsPopoverOpen,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TemplateBlockNormalPageContent',
                'handleDropDone',
            ))
        }
    }

    function handleDropDragOver(e: any,) {
        e.stopPropagation()
        e.preventDefault()
    }

    const toMoveObject = BuilderService.moveGetContentObject()
    const isDraggable = toMoveObject && (toMoveObject.id === object.id)

    if (object.id === 0) return null

    const permissionIsDisabledRest = reduxFormSite.permissions?.permissionIsDisabledRest
    // const permissionIsDisabledText = reduxFormSite.permissions?.permissionIsDisabledText

    return (
        <div
            id={Number(params.contentId) === object.id ? 'activeNavElement' : ''}
            className={`template-block-normal-mixed-media-content-web${Number(params.contentId) === object.id ? ' active' : ''}`}
            draggable={!permissionIsDisabledRest ? isDraggable : undefined}
            onDragEnd={!permissionIsDisabledRest ? () => BuilderService.moveDeleteContentObject() : undefined}
            onDragOver={!permissionIsDisabledRest ? (!noAllowDrop ? (e) => handleDropDragOver(e) : undefined) : undefined}
            onDragStart={!permissionIsDisabledRest ? () => BuilderService.moveSetContentObject(object, contentTypeData['pagecontent']) : undefined}
            onDrop={!permissionIsDisabledRest ? (!noAllowDrop ? () => handleDropDone('after') : undefined) : undefined}
            onMouseEnter={() => handleCssClassHover('enter')}
            onMouseLeave={() => handleCssClassHover('leave')}
        >
            <LinkHelper
                className='tbnmmcw-info'
                edit={false}
                onClick={() => {
                    if (setTabValue) setTabValue(!permissionIsDisabledRest ? 'styles' : 'settings')
                    dispatch(reduxFormSetSectionIdSite(mainParentId || object.id))
                    if (Number(params.contentId) === object.id) scrollToSection(params.contentId, (reduxModalSitenavbarType === 'top' ? reduxModalSitenavbarHeight : 0))
                }}
                to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/content-container/${object.id}/${params.pageSlug}/${params.detailId ? `${params.detailId}/` : ''}`}
            >
                <div className='tbnmmcw-info-wrap'>
                    <div className='tbnmmcw-name-wrap'>
                        <i className={`tbnmmcw-icon mo-new-icon-layout-${object.layout_edit?.id}`} />
                        <span className='tbnmmcw-name'>{object.layout_edit?.name}{object.layout_edit?.active ? '' : '(O)'}</span>
                        {!isInList && object.layout_edit?.content_source && (
                            <span className='tbnmmcw-content-source'>{object.layout_edit.content_source}{object.layout_edit.module_view ? ` - ${object.layout_edit.module_view}` : ''}</span>
                        )}
                        {object.action && (
                            <span className='tbnmmcw-link'>{object.action}</span>
                        )}
                    </div>
                    {object.auth_type !== 'n' && (
                        <p className='tbnmmcw-auth'>{object.layout_edit?.auth_type}</p>
                    )}
                    {object.layout_edit?.field_text?.name && (
                        object.layout_edit.field_text.field_key
                            ? (
                                <p className='tbnmmcw-text mo-clamp-web1'>{formState?.mother?.fields?.profile?.settings_data_json?.contentTypeSettingsCustom?.[object.layout_edit?.content_type_settings?.content_type_label_model!]?.field?.[object.layout_edit.field_text.field_key]?.verboseName || object.layout_edit.field_text.name}</p>
                            ) : (
                                <p className='tbnmmcw-text mo-clamp-web1'>{object.layout_edit.field_text.name}</p>
                            )
                    )}
                    {!object.active && (
                        <p className='tbnmmcw-content-inactive'>{reduxText[5616]}</p>
                    )}
                </div>
                {[781].includes(object.layout_edit?.id!) && (object.content?.get_image_site_lq || object.content?.get_image_site_hq || object.content?.file) && (
                    <div className='tbnmmcw-image-wrap'>
                        <IonThumbnail>
                            <ImageHelper
                                alt=''
                                className='tbnmmcw-image'
                                dominant_color={object.content?.get_dominant_color}
                                src={object.content?.get_image_site_lq || object.content?.get_image_site_hq || object.content?.file}
                            />
                        </IonThumbnail>
                    </div>
                )}
            </LinkHelper>
            {!permissionIsDisabledRest && (
                <div className='tbnmmcw-actions-wrap'>
                    <div className='tbnmmcw-actions'>
                        {mainParentId && (
                            <EditButtonPageContentChildren
                                allowDrop={!noAllowDrop}
                                isInTree
                                isNormal
                                isPopoverOpen={isPopoverOpen}
                                mainParentId={mainParentId}
                                object={object}
                                parentStyles={parentStyles}
                                setIsPopoverOpen={setIsPopoverOpen}
                            >
                                <button
                                    className='main-icon-block-web light'
                                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                >
                                    <i className='main-icon mo-new-icon-ellipsis-h-solid' />
                                </button>
                            </EditButtonPageContentChildren>
                        )}
                        {!mainParentId && (
                            <EditButtonPageContent
                                allowDrop={!noAllowDrop}
                                isNormal
                                isPopoverOpen={isPopoverOpen}
                                object={object}
                                setIsPopoverOpen={setIsPopoverOpen}
                            >
                                <button
                                    className='main-icon-block-web light'
                                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                >
                                    <i className='main-icon mo-new-icon-ellipsis-h-solid' />
                                </button>
                            </EditButtonPageContent>
                        )}
                    </div>
                </div>
            )}
            {isLoading && (
                <Loader isOpen />
            )}
        </div>
    )
})
