// react components
import React from 'react'
import axios from 'axios'

// data
import {
    reduxFormHelpersState,
} from 'data'

// main
export function getMediaSoundcloud({
    formInfo,
    formState,
    helpers,
    image_only,
    setFormState,
    setGetMetaIsLoading,
    setHelpers,
    urltoParse,
}: {
    formInfo: any
    formState: any
    helpers: reduxFormHelpersState
    image_only: boolean
    setFormState: React.Dispatch<any>
    setGetMetaIsLoading: React.Dispatch<boolean>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    urltoParse: string
}) {

    setGetMetaIsLoading(true)

    let urltoParse1 = encodeURIComponent(urltoParse)
    const urltoParse2 = urltoParse1.includes('http')
    if (!urltoParse2) {
        urltoParse1 = `http://${urltoParse1}`
    }
    axios({
        method: 'get',
        url: `https://opengraph.io/api/1.1/site/${urltoParse1}?app_id=5bb9403dc6006e1b00c78cda&full_render=true&accept_lang=auto&cache_ok=false`,
    })
        .then((response) => { // response data form opengraph
            if (process.env.NODE_ENV === 'development') console.log(response)
            let imageFile = ''
            if (response.data.htmlInferred.image) {
                imageFile = response.data.htmlInferred.image
            } else if (response.data.htmlInferred.images[0]) {
                imageFile = response.data.htmlInferred.images[0]
            }
            if (image_only) {
                const toReturn = {
                    ...formInfo,
                    errors: {
                        ...formInfo.errors,
                        cover_image: '',
                        cover_image_doka_output_data: '',
                        cover_image_original: '',
                        cover_image_square: '',
                        cover_image_square_doka_output_data: '',
                        image: '',
                        link_url: '',
                        meta_image_url: '',
                    },
                    fields: {
                        ...formInfo.fields,
                        cover_image: imageFile,
                        get_image_hq: imageFile,
                        get_image_lq: imageFile,
                        get_image_mq: imageFile,
                        image: imageFile,
                        link_url: urltoParse,
                        meta_image_url: imageFile,
                    },
                }
                setFormState({
                    ...formState,
                    [formInfo.name]: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '', // TO_CHECK
                })
            } else {
                const toReturn = {
                    ...formInfo,
                    errors: {
                        ...formInfo.errors,
                        cover_image: '',
                        cover_image_doka_output_data: '',
                        cover_image_original: '',
                        cover_image_square: '',
                        cover_image_square_doka_output_data: '',
                        description: '',
                        image: '',
                        link_url: '',
                        meta_image_url: '',
                        name: '',
                    },
                    fields: {
                        ...formInfo.fields,
                        cover_image: imageFile,
                        cover_image_doka_output_data: 'to_delete_original',
                        cover_image_original: '',
                        cover_image_square: imageFile,
                        cover_image_square_doka_output_data: 'to_delete_all',
                        description: response.data.htmlInferred.description,
                        get_image_hq: imageFile,
                        get_image_lq: imageFile,
                        get_image_mq: imageFile,
                        image: imageFile,
                        link_url: urltoParse,
                        meta_image_url: imageFile,
                        name: response.data.htmlInferred.title,
                    },
                }
                setFormState({
                    ...formState,
                    [formInfo.name]: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '', // TO_CHECK
                })
            }
            setGetMetaIsLoading(false)
        })
        .catch((error) => {
            setFormState({
                ...formState,
                [formInfo.name]: {
                    ...formInfo,
                    errors: {
                        ...formInfo.errors,
                        link_url: error.response?.statusText || 'Error',
                    },
                    fields: {
                        ...formInfo.fields,
                        link_url: urltoParse,
                    },
                },
            })
            setGetMetaIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log(error)
            if (process.env.NODE_ENV === 'development') console.log(error.response)
        })
}
