// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonItem,
    IonItemDivider,
    IonLabel,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    Button,
    LinkHelper,
} from 'components'

// data
import {
    api_url_portfolio_page_form_list,
    api_url_portfolio_page_form_patch,
    defaultReduxState,
    reduxFormSetPageListSite,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    AddPageBlankPageHelper,
    EditButtonPage,
    PageListBlockDetail,
    PageListBlockDetailSubContentType,
} from 'pages'

// serializers
import {
    PortfolioFormState,
    PortfolioPageListFormSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailObject,
} from 'services'

// props
type ListPagePortfolioPanelProps = {
    formState: PortfolioFormState
}

// main
export const ListPagePortfolioPanel: React.FC<ListPagePortfolioPanelProps> = React.memo(({
    formState,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const page = formState.page

    const [pages, setPages] = useState<PortfolioPageListFormSerializer[]>()
    const [showAddBlankPage, setShowAddBlankPage] = useState(false)
    const [showAddDetailPage, setShowAddDetailPage] = useState(false)
    const [showIsHomePageAlert, setShowIsHomePageAlert] = useState(false)

    useEffect(() => {
        onGetListData()
    }, [
        reduxFormSite.portfolioObject?.id,
    ])

    function onGetListData() {
        try {
            if (!reduxFormSite.portfolioObject?.id) return
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_portfolio_page_form_list}?portfolio_id=${reduxFormSite.portfolioObject.id}`, reduxAuth),
                component: 'ListPagePortfolioPanel',
                dispatch,
                reduxAuth,
                setDetailObject: setPages,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListPagePortfolioPanel',
                'onGetDetailData',
            ))
        }
    }

    function onReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListPagePortfolioPanel',
                'onReorder',
            ))
        }
    }

    async function onSortEnd(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            if (!pages) return
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ListPagePortfolioPanel onSortEnd')
            const sortablePageArray = pages.filter(obj => obj.page_type === 'n')
            const item = sortablePageArray[event.detail.from]
            const itemTo = sortablePageArray[event.detail.to]
            dispatch(reduxFormSetPageListSite(
                // @ts-ignore
                arrayMoveImmutable(sortablePageArray, event.detail.from, event.detail.to)
            ))
            const formData: any = new FormData()
            formData.append('sibling_id', itemTo.id)
            const putUrl = getApiUrl(`${api_url_portfolio_page_form_patch}${item.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetRefresh('refreshPreview', reduxFormSite.portfolioObject?.id, true))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'ListPagePortfolioPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ListPagePortfolioPanel',
                'onSortEnd',
            ))
        }
    }

    if (!reduxFormSite.portfolioObject) return null

    const nPages = pages?.filter(obj => obj.page_type === 'n').sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const dPagesOldSuperUser = pages?.filter(obj => obj.page_type === 'd' && obj.medium_type?.id !== 40).sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const dPagesOld = pages?.filter(obj => obj.page_type === 'd' && obj.medium_type?.id !== 40 && reduxFormSite.availableDetailPageMediumTypes?.includes(obj.medium_type?.id!)).sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const dPages = pages?.filter(obj => obj.page_type === 'd' && obj.medium_type?.id === 40).sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const d2Pages = pages?.filter(obj => obj.page_type === 'd2').sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const cPages = pages?.filter(obj => obj.page_type === 'c').sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const rPages = pages?.filter(obj => obj.page_type === 'r').sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const hPages = pages?.filter(obj => obj.page_type === 'h').sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const eShopPages = pages?.filter(obj => ['checkout', 'checkout-cancel', 'checkout-success'].includes(obj.slug!)).sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const donationPages = pages?.filter(obj => ['payment-success'].includes(obj.slug!)).sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const aPages = pages?.filter(obj => obj.page_type === 'a').sort((a: any, b: any) => a.name?.localeCompare(b.name))
    const pPages = pages?.filter(obj => obj.page_type === 'p').sort((a: any, b: any) => a.name?.localeCompare(b.name))

    return (
        <div className='list-page-portfolio-panel-web'>
            <div className={`lpppw-page-list mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='lpppw-pages-wrap'>
                    <IonItemDivider
                        className='lpppw-divider'
                        sticky
                    >
                        {reduxText[6443]}
                    </IonItemDivider>
                    {nPages?.map((val) => {
                        return (
                            <IonItem key={val.id}>
                                <IonLabel>
                                    <LinkHelper
                                        className='lpppw-page-name'
                                        edit={false}
                                        to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${val.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`}
                                    >
                                        {val.name}
                                    </LinkHelper>
                                    {val.is_home_page && (
                                        <p className='lpppw-page-home'>{reduxText[6343]}</p>
                                    )}
                                    {!val.active && (
                                        <p className='lpppw-page-status'>{reduxText[5616]}</p>
                                    )}
                                    {val.is_private && (
                                        <p className='lpppw-page-private'>{reduxText[8547]}{val.portfolio_user_groups?.length! > 0 ? ` (${val.portfolio_user_groups?.map((val2) => val2.name).join(' - ')})` : ''}</p>
                                    )}
                                </IonLabel>
                                <EditButtonPage
                                    object={val}
                                    page={page}
                                    refreshData={onGetListData}
                                />
                            </IonItem>
                        )
                    })}
                </div>
                {reduxAuth.settings?.user?.id === 1 && dPagesOldSuperUser?.length! > 0 && (
                    <div className='lpppw-pages-wrap'>
                        <IonItemDivider
                            className='lpppw-divider'
                            sticky
                        >
                            {reduxText[6445]}
                        </IonItemDivider>
                        {dPagesOldSuperUser?.map((val) => (
                            <PageListBlockDetail
                                key={val.id}
                                object={val}
                                page={page}
                                portfolioId={reduxFormSite.portfolioObject?.id!}
                                profileId={reduxFormSite.portfolioObject?.profile?.id!}
                                refreshData={onGetListData}
                            />
                        ))}
                    </div>
                )}
                {reduxAuth.settings?.user?.id !== 1 && dPagesOld?.length! > 0 && (
                    <div className='lpppw-pages-wrap'>
                        <IonItemDivider
                            className='lpppw-divider'
                            sticky
                        >
                            {reduxText[6445]}
                        </IonItemDivider>
                        {dPagesOld?.map((val) => (
                            <PageListBlockDetail
                                key={val.id}
                                object={val}
                                page={page}
                                portfolioId={reduxFormSite.portfolioObject?.id!}
                                profileId={reduxFormSite.portfolioObject?.profile?.id!}
                                refreshData={onGetListData}
                            />
                        ))}
                    </div>
                )}
                {dPages?.length! > 0 && (
                    <div className='lpppw-pages-wrap'>
                        <IonItemDivider
                            className='lpppw-divider'
                            sticky
                        >
                            {reduxText[6445]}
                        </IonItemDivider>
                        {dPages?.map((val) => (
                            <PageListBlockDetailSubContentType
                                key={val.id}
                                object={val}
                                page={page}
                                portfolioId={reduxFormSite.portfolioObject?.id!}
                                profileId={reduxFormSite.portfolioObject?.profile?.id!}
                                refreshData={onGetListData}
                            />
                        ))}
                        {reduxAuth.settings?.user?.is_staff && (
                            <div className='lpppw-footer'>
                                <Button
                                    disabled={reduxFormSite.permissions?.permissionIsDisabledRest}
                                    edit={false}
                                    expand='full'
                                    fill='outline'
                                    onClick={() => setShowAddDetailPage(true)}
                                    size='x-small'
                                    text={reduxText[8720]}
                                />
                            </div>
                        )}
                    </div>
                )}
                {d2Pages?.length! > 0 && (
                    <div className='lpppw-pages-wrap'>
                        <IonItemDivider
                            className='lpppw-divider'
                            sticky
                        >
                            {reduxText[8719]}
                        </IonItemDivider>
                        {d2Pages?.map((val) => {
                            return (
                                <IonItem key={val.id}>
                                    <IonLabel>
                                        <LinkHelper
                                            className='lpppw-page-name'
                                            edit={false}
                                            to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${val.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`}
                                        >
                                            {`${val.name} (${val.medium_type?.name})`}
                                        </LinkHelper>
                                    </IonLabel>
                                    <EditButtonPage
                                        object={val}
                                        page={page}
                                        refreshData={onGetListData}
                                    />
                                </IonItem>
                            )
                        })}
                    </div>
                )}
                <div className='lpppw-pages-wrap'>
                    <IonItemDivider
                        className='lpppw-divider'
                        sticky
                    >
                        {reduxText[8319]}
                    </IonItemDivider>
                    {cPages?.map((val) => {
                        return (
                            <IonItem key={val.id}>
                                <IonLabel>
                                    <LinkHelper
                                        className='lpppw-page-name'
                                        edit={false}
                                        to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${val.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`}
                                    >
                                        {val.name}
                                    </LinkHelper>
                                    {!val.active && (
                                        <p className='lpppw-page-status'>{reduxText[5616]}</p>
                                    )}
                                </IonLabel>
                                <EditButtonPage
                                    object={val}
                                    page={page}
                                    refreshData={onGetListData}
                                />
                            </IonItem>
                        )
                    })}
                </div>
                {reduxAuth.settings?.user?.id === 1 && (
                    <React.Fragment>
                        {rPages?.length! > 0 && (
                            <div className='lpppw-pages-wrap'>
                                <IonItemDivider
                                    className='lpppw-divider'
                                    sticky
                                >
                                    {reduxText[8264]}
                                </IonItemDivider>
                                {rPages?.map((val) => {
                                    return (
                                        <IonItem key={val.id}>
                                            <IonLabel>
                                                <LinkHelper
                                                    className='lpppw-page-name'
                                                    edit={false}
                                                    to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${val.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`}
                                                >
                                                    {val.name}
                                                </LinkHelper>
                                                {!val.active && (
                                                    <p className='lpppw-page-status'>{reduxText[5616]}</p>
                                                )}
                                            </IonLabel>
                                            <EditButtonPage
                                                object={val}
                                                page={page}
                                                refreshData={onGetListData}
                                            />
                                        </IonItem>
                                    )
                                })}
                            </div>
                        )}
                        {hPages?.length! > 0 && (
                            <div className='lpppw-pages-wrap'>
                                <IonItemDivider
                                    className='lpppw-divider'
                                    sticky
                                >
                                    {reduxText[8320]}
                                </IonItemDivider>
                                {hPages?.map((val) => {
                                    return (
                                        <IonItem key={val.id}>
                                            <IonLabel>
                                                <LinkHelper
                                                    className='lpppw-page-name'
                                                    edit={false}
                                                    to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${val.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`}
                                                >
                                                    {val.name}
                                                </LinkHelper>
                                                {!val.active && (
                                                    <p className='lpppw-page-status'>{reduxText[5616]}</p>
                                                )}
                                            </IonLabel>
                                            <EditButtonPage
                                                object={val}
                                                page={page}
                                                refreshData={onGetListData}
                                            />
                                        </IonItem>
                                    )
                                })}
                            </div>
                        )}
                    </React.Fragment>
                )}
                {reduxFormSite.portfolioObject.is_eshop && (
                    <div className='lpppw-pages-wrap'>
                        <IonItemDivider
                            className='lpppw-divider'
                            sticky
                        >
                            {reduxText[6446]}
                        </IonItemDivider>
                        {eShopPages?.map((val) => {
                            return (
                                <IonItem key={val.id}>
                                    <IonLabel>
                                        <LinkHelper
                                            className='lpppw-page-name'
                                            edit={false}
                                            to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${val.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`}
                                        >
                                            {val.name}
                                        </LinkHelper>
                                        {!val.active && (
                                            <p className='lpppw-page-status'>{reduxText[5616]}</p>
                                        )}
                                    </IonLabel>
                                    <EditButtonPage
                                        object={val}
                                        page={page}
                                        refreshData={onGetListData}
                                    />
                                </IonItem>
                            )
                        })}
                    </div>
                )}
                {reduxFormSite.portfolioObject.is_donation && (
                    <div className='lpppw-pages-wrap'>
                        <IonItemDivider
                            className='lpppw-divider'
                            sticky
                        >
                            {reduxText[8592]}
                        </IonItemDivider>
                        {donationPages?.map((val) => {
                            return (
                                <IonItem key={val.id}>
                                    <IonLabel>
                                        <LinkHelper
                                            className='lpppw-page-name'
                                            edit={false}
                                            to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${val.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`}
                                        >
                                            {val.name}
                                        </LinkHelper>
                                        {!val.active && (
                                            <p className='lpppw-page-status'>{reduxText[5616]}</p>
                                        )}
                                    </IonLabel>
                                    <EditButtonPage
                                        object={val}
                                        page={page}
                                        refreshData={onGetListData}
                                    />
                                </IonItem>
                            )
                        })}
                    </div>
                )}
                {aPages?.length! > 0 && (
                    <div className='lpppw-pages-wrap'>
                        <IonItemDivider
                            className='lpppw-divider'
                            sticky
                        >
                            {reduxText[8569]}
                        </IonItemDivider>
                        {aPages?.map((val) => {
                            return (
                                <IonItem key={val.id}>
                                    <IonLabel>
                                        <LinkHelper
                                            className='lpppw-page-name'
                                            edit={false}
                                            to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${val.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`}
                                        >
                                            {val.name}
                                        </LinkHelper>
                                        {!val.active && (
                                            <p className='lpppw-page-status'>{reduxText[5616]}</p>
                                        )}
                                    </IonLabel>
                                    <EditButtonPage
                                        object={val}
                                        page={page}
                                        refreshData={onGetListData}
                                    />
                                </IonItem>
                            )
                        })}
                    </div>
                )}
                {pPages?.length! > 0 && (
                    <div className='lpppw-pages-wrap'>
                        <IonItemDivider
                            className='lpppw-divider'
                            sticky
                        >
                            {/* // TO_TEXT */}
                            Private Pages
                        </IonItemDivider>
                        {pPages?.map((val) => {
                            return (
                                <IonItem key={val.id}>
                                    <IonLabel>
                                        <LinkHelper
                                            className='lpppw-page-name'
                                            edit={false}
                                            to={`${view_url_portfolio_form}${reduxFormSite.portfolioObject?.id}/page-container/-/${val.absolute_site_url || reduxFormSite.portfolioObject?.home_page_slug}/`}
                                        >
                                            {val.name}
                                        </LinkHelper>
                                        {!val.active && (
                                            <p className='lpppw-page-status'>{reduxText[5616]}</p>
                                        )}
                                    </IonLabel>
                                    <EditButtonPage
                                        object={val}
                                        page={page}
                                        refreshData={onGetListData}
                                    />
                                </IonItem>
                            )
                        })}
                    </div>
                )}
            </div>
            <div className='lpppw-footer'>
                <Button
                    className={reduxAuth.settings?.user?.is_staff ? 'lpppw-button-margin' : ''}
                    disabled={reduxFormSite.permissions?.permissionIsDisabledRest}
                    edit={false}
                    expand='full'
                    onClick={() => setShowAddBlankPage(true)}
                    text={reduxText[6447]}
                />
            </div>
            {showAddBlankPage && (
                <AddPageBlankPageHelper
                    page={page}
                    setIsOpen={setShowAddBlankPage}
                />
            )}
            {showAddDetailPage && (
                <AddPageBlankPageHelper
                    detailPage
                    page={page}
                    setIsOpen={setShowAddDetailPage}
                />
            )}
            {showIsHomePageAlert && (
                <Alert
                    buttons={[{ text: 'OK' }]}
                    header={reduxText[7709]}
                    isOpen
                    message={reduxText[7710]}
                    onDidDismiss={() => setShowIsHomePageAlert(false)}
                />
            )}
        </div>
    )
})
