// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ServiceDetailFormSerializer,
    ServiceDetailFormSerializerError,
} from 'serializers/web'

// props
type IsPrivateServiceInputProps = {
    errors: ServiceDetailFormSerializerError | undefined
    fields: ServiceDetailFormSerializer
    onChange: any
}

// main
export const IsPrivateServiceInput: React.FC<IsPrivateServiceInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <CheckboxInput
            error={errors?.is_private}
            label={reduxText[8547]}
            name='is_private'
            noMarginBottom={fields.is_private}
            onChange={onChange}
            value={fields.is_private}
        />
    )
})
