// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    TextInputV2,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type AgendaDuQuartierQuartierFormStep10Props = {
    inline?: boolean
    setStep: (step: number) => void
    step: number
    submitOnly?: boolean
}

// main
export const AgendaDuQuartierQuartierFormStep10: React.FC<AgendaDuQuartierQuartierFormStep10Props> = React.memo(({
    inline,
    setStep,
    step,
    submitOnly,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [errorsInline, setErrorsInline] = useState<any>({})
    const [fields, setFields] = useState<any>({})
    const [fieldsInline, setFieldsInline] = useState<any>({})
    const [isClosed, setIsClosed] = useState<boolean>(inline || false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [items, setItems] = useState<any[]>([])
    const [showForm, setShowForm] = useState<boolean>(false)

    useEffect(() => {
        if (reduxAuth.settings?.quartier?.id) {
            if (inline && isClosed) return
            onGetDetailData()
        }
    }, [
        isClosed,
        reduxAuth.settings?.quartier?.id,
    ])

    useEffect(() => {
        if (reduxAuth.settings?.quartier?.id) {
            onGetDetailDataInline()
        }
    }, [
        reduxAuth.settings?.quartier?.id,
    ])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/detail/quartier/${reduxAuth.settings?.quartier?.id}/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep10',
                dispatch,
                reduxAuth,
                setDetailObject: setFields,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep10',
                'onGetDetailData',
            ))
        }
    }

    function onGetDetailDataInline() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/list/quartier/asset/${reduxAuth.settings?.quartier?.id}/92/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep10',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep10',
                'onGetDetailDataInline',
            ))
        }
    }

    async function handleInlineDelete(itemId: number) {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuartierFormStep9 handleInlineDelete')
            const deleteUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/update/quartier/asset/${itemId}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setShowForm(false)
                    onGetDetailDataInline()
                    setFieldsInline({})
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'AgendaDuQuartierQuartierFormStep9',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'handleInlineDelete',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep9',
                'handleInlineDelete',
            ))
        }
    }

    function handleInlineRetrieve(itemId: number) {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/detail/quartier/asset/${itemId}/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep9',
                dispatch,
                reduxAuth,
                setDetailObject: setFieldsInline,
                setIsLoading,
            })
            setShowForm(true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep9',
                'handleInlineRetrieve',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFieldsInline({ ...fieldsInline, [name]: event.value })
            setErrorsInline({ ...errorsInline, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep10',
                'handleInputChange',
            ))
        }
    }

    async function handleSubmit() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuartierFormStep10 handleSubmit')
            const patchUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/update/quartier/${reduxAuth.settings?.quartier?.id}/`, reduxAuth)
            const formData = new FormData()
            const isStepDone = true
            formData.append('extra_data_json_2', JSON.stringify({
                ...fields.extra_data_json_2,
                quartier_from_steps: {
                    ...fields.extra_data_json_2?.quartier_from_steps,
                    step10: isStepDone,
                }
            }))
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'patch',
                url: patchUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setStep(step + 1)
                    reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: patchUrl,
                        component: 'AgendaDuQuartierQuartierFormStep10',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep10',
                'handleInputChange',
            ))
        }
    }

    async function handleSubmitInline() {
        try {
            setErrorsInline({})
            setIsLoading(true)

            const maxAffiche = reduxAuth.settings?.quartier?.extra_data_json_2?.stripe?.affiche || 0
            const thisLineAffiche = Number(fieldsInline.custom_number_field_1 || 0) + Number(fieldsInline.custom_number_field_2 || 0)
            const totalOtherAffiche = items
                .filter(obj => obj.id != fieldsInline.id)
                .reduce((acc, item) => {
                    const n1 = parseFloat(item.custom_number_field_1) || 0
                    const n2 = parseFloat(item.custom_number_field_2) || 0
                    return acc + n1 + n2
                }, 0)
            const totalAffiche = thisLineAffiche + totalOtherAffiche
            if (totalAffiche > maxAffiche) {
                setIsLoading(false)
                setErrorsInline({ main_error: `Le nombre total d’affiches dépasse votre commande : ${maxAffiche} affiches maximum` })
                return
            }

            const maxAdresses = reduxAuth.settings?.quartier?.extra_data_json_2?.stripe?.adresse || 0
            const totalAdresses = items.filter(obj => obj.id != fieldsInline.id).length
            if (totalAdresses >= maxAdresses) {
                setIsLoading(false)
                setErrorsInline({ main_error: `Le nombre total d’adresses postales dépasse votre commande : ${maxAdresses} adresses maximum` })
                return
            }


            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuartierFormStep10 handleSubmitInline')
            let axiostUrl = getApiUrlV2('customer/agendaduquartier/form/entity/create/quartier/asset/', reduxAuth)
            let axiosMethod = 'post'
            if (fieldsInline.id) {
                axiostUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/update/quartier/asset/${fieldsInline.id}/`, reduxAuth)
                axiosMethod = 'put'
            }
            const formData: any = new FormData()
            formData.append('address_custom_additional_address_info_1', fieldsInline.address_custom_additional_address_info_1 || '')
            formData.append('address_custom_building_1', fieldsInline.address_custom_building_1 || '')
            formData.append('address_custom_city_1', fieldsInline.address_custom_city_1 || '')
            formData.append('address_custom_company_1', fieldsInline.address_custom_company_1 || '')
            formData.append('address_custom_office_1', fieldsInline.address_custom_office_1 || '')
            formData.append('address_custom_postal_code_1', fieldsInline.address_custom_postal_code_1 || '')
            formData.append('address_custom_recipient_name_1', fieldsInline.address_custom_recipient_name_1 || '')
            formData.append('address_custom_street_address_1', fieldsInline.address_custom_street_address_1 || '')
            formData.append('content_object_id', reduxAuth.settings?.quartier?.id)
            formData.append('custom_number_field_1', fieldsInline.custom_number_field_1 || 0)
            formData.append('custom_number_field_2', fieldsInline.custom_number_field_2 || 0)
            formData.append('sub_content_type', 92)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiostUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setShowForm(false)
                    onGetDetailDataInline()
                    setFieldsInline({})
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (error.response?.status === 400) {
                        setErrorsInline(error.response.data)
                    } else {
                        axiosErrorHandler({
                            apiUrl: axiostUrl,
                            component: 'AgendaDuQuartierQuartierFormStep10',
                            dispatch,
                            error,
                            formFields: JSON.stringify(fieldsInline),
                            reduxAuth,
                            reference: 'handleSubmitInline',
                        })
                    }
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep10',
                'handleInputChange',
            ))
        }
    }

    if (Number(reduxAuth.settings?.quartier?.extra_data_json_2?.stripe?.adresse) === 0) return null

    return (
        <div className={`sct90f-input-block${inline ? (!isClosed ? ' inline open' : ' inline') : ''}`}>
            <div
                className={`sct90f-title-block${inline && isClosed ? ' closed' : ''}`}
                onClick={inline ? () => setIsClosed(!isClosed) : undefined}
            >
                <p className='sct90f-title'>Renseigner les adresses de livraison</p>
                {inline && (
                    <div className='sct90f-title-icon'>
                        <i className={`mo-new-icon-check-circle-solid sct90f-status${reduxAuth.settings?.quartier?.extra_data_json_2?.quartier_from_steps?.step10 === true ? '' : ' not-completed'}`} />
                        <div className='sct90f-arrow-wrap'>
                            <i className='mo-new-icon-chevron-down-solid sct90f-arrow' />
                        </div>
                    </div>
                )}
            </div>
            {!isClosed && (
                <div className='sct90f-content'>
                    <div
                        className='sct90f-helper'
                        dangerouslySetInnerHTML={{ __html: reduxText[10055] }}
                    />
                    <div>
                        <div className='sct90f-add-button-block'>
                            <Button
                                edit={false}
                                fill='clear'
                                onClick={() => setShowForm(!showForm)}
                                text='Ajouter une adresse de livraison'
                            />
                        </div>
                        {showForm && (
                            <div className='sct90f-form'>
                                <TextInputV2
                                    error={errorsInline?.address_custom_company_1}
                                    label='Organisme'
                                    name='address_custom_company_1'
                                    onChange={handleInputChange}
                                    type='text'
                                    value={fieldsInline.address_custom_company_1 || ''}
                                />
                                <TextInputV2
                                    error={errorsInline?.address_custom_office_1}
                                    label='Service ou direction (ou "Madame la gardienne / Monsieur le gardien")'
                                    name='address_custom_office_1'
                                    onChange={handleInputChange}
                                    type='text'
                                    value={fieldsInline.address_custom_office_1 || ''}
                                />
                                <TextInputV2
                                    error={errorsInline?.address_custom_recipient_name_1}
                                    label='Nom du destinataire'
                                    name='address_custom_recipient_name_1'
                                    onChange={handleInputChange}
                                    type='text'
                                    value={fieldsInline.address_custom_recipient_name_1 || ''}
                                />
                                <TextInputV2
                                    error={errorsInline?.address_custom_building_1}
                                    label="Bâtiment ou résidence (ou écrire 'Loge accueil', s'il s'agit d'un·e gardien·ne)"
                                    name='address_custom_building_1'
                                    onChange={handleInputChange}
                                    type='text'
                                    value={fieldsInline.address_custom_building_1 || ''}
                                />
                                <TextInputV2
                                    error={errorsInline?.address_custom_street_address_1}
                                    label='Numéro et voie (rue, boulevard, place...)'
                                    name='address_custom_street_address_1'
                                    onChange={handleInputChange}
                                    type='text'
                                    value={fieldsInline.address_custom_street_address_1 || ''}
                                />
                                <TextInputV2
                                    error={errorsInline?.address_custom_additional_address_info_1}
                                    label='BP, CS, TSA, lieu-dit… (obligatoire si pas de voie)'
                                    name='address_custom_additional_address_info_1'
                                    onChange={handleInputChange}
                                    type='text'
                                    value={fieldsInline.address_custom_additional_address_info_1 || ''}
                                />
                                <TextInputV2
                                    error={errorsInline?.address_custom_postal_code_1}
                                    label='Code postal'
                                    name='address_custom_postal_code_1'
                                    onChange={handleInputChange}
                                    type='text'
                                    value={fieldsInline.address_custom_postal_code_1 || ''}
                                />
                                <TextInputV2
                                    error={errorsInline?.address_custom_city_1}
                                    label='Ville'
                                    name='address_custom_city_1'
                                    onChange={handleInputChange}
                                    type='text'
                                    value={fieldsInline.address_custom_city_1 || ''}
                                />
                                <TextInputV2
                                    error={errorsInline?.custom_number_field_1}
                                    label="Nombre d'affiches A3 (grand format, recommandé)"
                                    min='0'
                                    name='custom_number_field_1'
                                    onChange={handleInputChange}
                                    type='number'
                                    value={fieldsInline.custom_number_field_1 || 0}
                                />
                                <TextInputV2
                                    error={errorsInline?.custom_number_field_2}
                                    label="Nombre d'affiches A4 (petit format)"
                                    min='0'
                                    name='custom_number_field_2'
                                    onChange={handleInputChange}
                                    type='number'
                                    value={fieldsInline.custom_number_field_2 || 0}
                                />
                                <Button
                                    edit={false}
                                    onClick={handleSubmitInline}
                                    text='Sauvegarder'
                                />
                                {errorsInline.main_error && (
                                    <div className='sct90f-error'>{errorsInline.main_error}</div>
                                )}
                            </div>
                        )}
                    </div>
                    {items.length > 0 && (
                        <div className='sct90f-table-wrap'>
                            <table className='sct90f-table'>
                                <thead>
                                    <tr>
                                        <th>Organisme</th>
                                        <th>Service ou direction</th>
                                        <th>Nom du destinataire</th>
                                        <th>Bâtiment ou résidence</th>
                                        <th>Numéro et voie</th>
                                        <th>BP, CS, TSA, lieu-dit…</th>
                                        <th>Code postal</th>
                                        <th>Ville</th>
                                        <th>Nombre d’affiches A3</th>
                                        <th>Nombre d’affiches A4</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.address_custom_company_1}</td>
                                            <td>{item.address_custom_office_1}</td>
                                            <td>{item.address_custom_recipient_name_1}</td>
                                            <td>{item.address_custom_building_1}</td>
                                            <td>{item.address_custom_street_address_1}</td>
                                            <td>{item.address_custom_additional_address_info_1}</td>
                                            <td>{item.address_custom_postal_code_1}</td>
                                            <td>{item.address_custom_city_1}</td>
                                            <td>{Number(item.custom_number_field_1)}</td>
                                            <td>{Number(item.custom_number_field_2)}</td>
                                            <td>
                                                <div className='sct90f-action-wrap'>
                                                    <div
                                                        className='sct90f-action'
                                                        onClick={() => handleInlineRetrieve(item.id)}
                                                    >
                                                        <i className='mo-new-icon-pencil-alt-solid sct90f-action-icon' />
                                                    </div>
                                                    <div
                                                        className='sct90f-action'
                                                        onClick={() => handleInlineDelete(item.id)}
                                                    >
                                                        <i className='mo-new-icon-trash-solid sct90f-action-icon' />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <div className='sct90f-button-block'>
                        <div>
                            {!submitOnly && step > 1 && (
                                <Button
                                    edit={false}
                                    onClick={() => setStep(step - 1)}
                                    text='Précédent'
                                />
                            )}
                        </div>
                        <div className='sct90f-button-block-end'>
                            {!submitOnly && (
                                <>
                                    <Button
                                        edit={false}
                                        fill='clear'
                                        onClick={() => setStep(step + 1)}
                                        text="Répondre plus tard"
                                    />
                                    <div className='sct90f-button-spacing' />
                                </>
                            )}
                            <Button
                                edit={false}
                                onClick={handleSubmit}
                                text={submitOnly ? 'Sauvegarder' : 'Suivant'}
                            />
                        </div>
                    </div>
                    {isLoading && <Loader isOpen />}
                </div>
            )}
        </div>
    )
})
