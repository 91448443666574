// data
import {
    api_url_project_eclink_form_active,
    api_url_project_eclink_form_create,
    api_url_project_eclink_form_detail,
    api_url_project_eclink_form_list,
    api_url_project_eclink_form_patch,
    api_url_project_eclink_form_update,
    reduxAuthState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function ProjectEclinkFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'eclink',

        // Urls
        activeUrl: getApiUrl(api_url_project_eclink_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_project_eclink_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_project_eclink_form_detail, reduxAuth),
        listUrl: getApiUrl(api_url_project_eclink_form_list, reduxAuth),
        patchUrl: getApiUrl(api_url_project_eclink_form_patch, reduxAuth),
        updateUrl: getApiUrl(api_url_project_eclink_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: {},

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'link_url',
        ],
        requiredForeignKey: [
            // 'project', Using mother.fields.id
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'link_url',
        ],
        formFile: [],
        formForeignKey: [
            // 'project', Using mother.fields.id
        ],
        formJson: [],
        formManyToMany: [],
    }
}
