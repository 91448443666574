// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectAsyncInput,
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_auth_profile_switch,
    select_url_profile,
} from 'data'

// serializers
import {
    ProfileDetailFormSerializer,
    ProfileDetailFormSerializerError,
} from 'serializers/web'

// props
type ProfileCreatedByProfileInputProps = {
    disabled: boolean
    errors: ProfileDetailFormSerializerError | undefined
    fields: ProfileDetailFormSerializer
    onChange: any
}

// main
export const ProfileCreatedByProfileInput: React.FC<ProfileCreatedByProfileInputProps> = React.memo(({
    disabled,
    errors,
    fields,
    onChange,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (reduxAuth.settings?.user?.is_staff) {
        return (
            <SelectAsyncInput
                apiUrl={select_url_profile}
                clearable={false}
                disabled={disabled}
                error={errors?.profile_created_by}
                helperText={reduxText[483510]}
                label={reduxText[4835]}
                layout='profile'
                name='profile_created_by'
                onChange={onChange}
                value={fields.profile_created_by || ''}
            />
        )
    }

    return (
        <SelectListInput
            apiUrl={select_url_auth_profile_switch}
            clearable={false}
            disabled={disabled}
            error={errors?.profile_created_by}
            helperText={reduxText[483510]}
            label={reduxText[4835]}
            name='profile_created_by'
            onChange={onChange}
            value={fields.profile_created_by || ''}
        />
    )
})
