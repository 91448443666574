// Root URL
const filterRoot = 'portfolio/filter/portfolio/'

export const portfolioFilterOptions: any[] = [
    {
        api_url: `${filterRoot}?filter_model=category`,
        showNone: true,
        slug: 'category',
        textId: 2575,  // reduxText[2575]
    },
    {
        api_url: 'cover_image_status',
        slug: 'cover_image_status',
        textId: '',
    },
]
