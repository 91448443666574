// npm omponents
import React from 'react'
import {
    IonAvatar,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// data
import {
    defaultReduxState,
    media_url_social_network,
    media_version,
    view_url_profile_about,
    view_url_profile_detail_old,
} from 'data'

// serializers
import {
    CityTempSerializer,
    ProfileDetailSerializer,
} from 'serializers/web'

// services
import {
    getCleanUrl,
    getDateWithType,
} from 'services'

// props
type ProfileDetailBlockMobileProps = {
    edit: boolean
    object: ProfileDetailSerializer
}

// main
export const ProfileDetailBlockMobile: React.FC<ProfileDetailBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    // custom objects
    const isStaff = reduxAuth.settings?.user?.is_staff

    // rework country to avoid duplicate flags
    const countryArray: CityTempSerializer[] = []
    if (edit && object.address_json && object.address_json.length > 0) {
        const countryArrayTemp: string[] = []
        object.address_json.map((val) => {
            if (val.countryCode && !countryArrayTemp.includes(val.countryCode)) {
                countryArrayTemp.push(val.countryCode)
                countryArray.push(val)
            }
            return false
        })
    }

    // declare cities
    let cityArray: CityTempSerializer[] = []
    if (edit && object.address_json) {
        cityArray = object.address_json || []
    }

    const socialLinks: ProfileDetailSerializer['eclink_list'] = []
    const websiteLinks: ProfileDetailSerializer['eclink_list'] = []
    object.eclink_list?.map((val) => {
        if (val.social_network === 5) {
            websiteLinks.push(val)
        } else {
            socialLinks.push(val)
        }
    })

    return (
        <div id='profileInfoBlock' className='profile-info-block'>
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='profile'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <div className='pib-profile-image-wrap'>
                <div className='pib-profile-header'>
                    <LinkHelper
                        className='pib-profile-header-link'
                        edit={edit}
                        to={`${view_url_profile_detail_old}${object?.slug}/tabs/${view_url_profile_about}/`}
                    >
                        <IonAvatar className='pib-profile-image'>
                            <ImageHelper
                                alt={object.name}
                                dominant_color={undefined}
                                src={object.get_image_lq}
                            />
                        </IonAvatar>
                    </LinkHelper>
                </div>
                {object.cover_image_credits && object.cover_image_credits.length > 0 && (
                    <React.Fragment>
                        <span className='pib-profile-image-credit'>� </span>
                        {object.cover_image_credits.map((val, i) => (
                            <React.Fragment key={val.id}>
                                <LinkHelper
                                    edit={edit}
                                    to={val.member?.get_absolute_url}
                                >
                                    <span className='pib-profile-image-credit'>{val.member?.name || val.name}</span>
                                </LinkHelper>
                                {object.cover_image_credits && (object.cover_image_credits.length > (i + 1)) && (<span className='pib-profile-image-credit'> / </span>)}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                )}
                {object.profile_created_by && (
                    <div className='pib-profile-created-by'>
                        <span>{reduxText[4834]} </span>
                        <LinkHelper
                            edit={edit}
                            to={object.profile_created_by.get_absolute_url}
                        >
                            {object.profile_created_by.name}
                        </LinkHelper>
                    </div>
                )}
                {!edit && isStaff && (
                    <div className='pib-last-login'>
                        <span>{reduxText[5398]} {getDateWithType(reduxAuth, object.owner_info?.last_login, 'n')}</span>
                        <span>{reduxText[5493]} {getDateWithType(reduxAuth, object.owner_info?.created_on, 'd')}</span>
                        <span>{object.owner_info?.user_language}</span>
                    </div>
                )}
            </div>
            <p className='pib-name'>{object.name}</p>
            {object.metiers && (
                <div className='pib-metiers'>
                    <span>{object.metiers}</span>
                </div>
            )}
            <div className='pib-country'>
                {edit && (
                    <React.Fragment>
                        {countryArray.map((val, i) => (
                            <MainCountryFlag
                                key={i}
                                edit={edit}
                                object={val}
                            />
                        ))}
                        {cityArray.map((val, i) => (
                            <MainHashtag
                                key={i}
                                edit={edit}
                                label={val.cityName || val.description}
                            />
                        ))}
                    </React.Fragment>
                )}
                {!edit && (
                    <React.Fragment>
                        {object.countries && object.countries.length > 0 && object.countries.map((val) => (
                            <MainCountryFlag
                                key={val.id}
                                edit={edit}
                                object={val}
                            />
                        ))}
                        {object.cities && object.cities.length > 0 && object.cities.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </React.Fragment>
                )}
            </div>
            {object.description && (
                <p className='pib-description pre-line'>{object.description}</p>
            )}
            {websiteLinks.map((val) => (
                <LinkHelper
                    key={val.id}
                    edit={edit}
                    className='pib-website'
                    href={val.link_url}
                >
                    {getCleanUrl(val.link_url!)}
                </LinkHelper>
            ))}
            {socialLinks.length > 0 && (
                <div className='pib-social-wrap'>
                    {socialLinks.map((val) => (
                        <div
                            key={val.id}
                            className='pib-social'
                        >
                            {val.social_network === 2
                                ? (
                                    <a
                                        href={`mailto:${val.link_url}`}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        <i className='icon mo-new-icon-envelope-regular' />
                                    </a>
                                ) : (
                                    <LinkHelper
                                        key={val.id}
                                        edit={edit}
                                        href={val.link_url}
                                        hrefTargetSystem={val.social_network !== 5}
                                    >
                                        <ImageHelper
                                            alt=''
                                            className='pib-social-image'
                                            dominant_color={undefined}
                                            src={`${media_url_social_network}${val.social_network}_white.svg${media_version}`}
                                        />
                                    </LinkHelper>
                                )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
})
