export const conditionAdditionalInformationWeb: any = {
    1: [ // Album
        'languages',
    ],
    2: [ // Track
        'languages',
        'lyrics',
    ],
    3: [ // Video
        'languages',
        'lyrics',
    ],
    4: [ // Serie
        'languages',
    ],
    5: [ // Story
        'languages',
        'related_projects',
    ],
    6: [ // Work
        'languages',
    ],
    7: [ // Article
        'languages',
    ],
    8: [ // Dossier
        'languages',
    ],
    9: [ // Mixed
        'languages',
    ],
    18: [ // Post
        'languages',
    ],
    20: [ // Pdf
        'languages',
    ],
}
