// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    // ItemReorderEventDetail,
} from '@ionic/core'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    // arrayMoveImmutable,
} from 'array-move'
import axios, {
    AxiosRequestConfig,
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
    InfiniteScrollHelperWeb,
    Loader,
    RichTextInput,
    SelectAsyncInput,
    SelectListInput,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxModalErrorEventHandler,
    reduxModalErrorShowHide,
    select_url_profile,
} from 'data'

// pages
import {
    MainTeamMotherFormState,
    TeamListFormBlock,
} from 'pages'

// serializers
import {
    MainTeamFormState,
    MainTeamListFormSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getInfiniteListWeb,
    getInputValue,
    getMainErrorMessage,
} from 'services'

// props
type TeamFormProps = {
    contentTypeId: number
    objectId: number
    profileId: number
    setIsOpen: React.Dispatch<boolean>
}

// main
export const TeamForm: React.FC<TeamFormProps> = React.memo(({
    contentTypeId,
    objectId,
    profileId,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<MainTeamListFormSerializer[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [formState, setFormState] = useState<MainTeamFormState>({
        mother: MainTeamMotherFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [isDeleting, setIsDeleting] = useState(false)
    const [showInlineForm, setShowInlineForm] = useState(true)

    useEffect(() => {
        onGetListData(
            getAxiosUrl(formState.mother),
            true,
            true,
        )
    }, [
        reduxForm.refresh.refreshInlineList,
    ])

    // function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    //     try {
    //         onSortEnd(event)
    //         event.detail.complete()
    //     } catch (error) {
    //         dispatch(reduxModalErrorEventHandler(
    //             error,
    //             'TeamForm',
    //             'doReorder',
    //         ))
    //     }
    // }

    function getAxiosUrl(formInfo: any) {
        let axiosUrl = `${formInfo.listUrl}${objectId}/`
        if (contentTypeId) axiosUrl += `${contentTypeId}/`
        return axiosUrl
    }

    let wto: any
    let wto2: any
    let wto3: any

    function handleCancel() {
        setShowInlineForm(false)
        clearTimeout(wto)
        wto = setTimeout(() => {
            setShowInlineForm(true)
        }, 100)
        setFormState({
            ...formState,
            mother: {
                ...formState.mother,
                fields: {},
                errors: {},
                requiredAllFields: [],
                requiredAttribute: [],
                requiredForeignKey: [
                    'member',
                ],
                requiredManyToMany: [],
            }
        })
        setHelpers({
            ...helpers,
            init: false,
        })
    }

    async function handleInlineActive(object: MainTeamListFormSerializer) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TeamForm handleInlineActive')
            const formData = new FormData()
            formData.append('active', (!object.active).toString())
            const activeUrl = `${formState.mother.activeUrl}${object.id}/`
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const newArray: any = []
                    items.map((val: any) => {
                        if (val.id === object.id) {
                            val.active = response.data.active
                        }
                        newArray.push(val)
                        return false
                    })
                    setItems(newArray)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'TeamForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineActive',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamForm',
                'handleInlineActive',
            ))
        }
    }

    async function handleInlineDelete(object: MainTeamListFormSerializer) {
        try {
            setIsDeleting(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TeamForm handleInlineDelete')
            const deleteUrl = `${formState.mother.updateUrl}${object.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    const newArray: any[] = []
                    items.map((val: any) => {
                        if (val.id !== object.id) newArray.push(val)
                        return false
                    })
                    setItems(newArray)
                    setShowInlineForm(false)
                    clearTimeout(wto3)
                    wto3 = setTimeout(() => {
                        setShowInlineForm(true)
                    }, 100)
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        init: false,
                        isSaving: false,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: {},
                            fields: {},
                        }
                    })
                })
                .catch((error) => {
                    setIsDeleting(false)
                    if (error.response?.status === 423) {
                        dispatch(reduxModalErrorShowHide({
                            header: reduxText[7428],
                            isOpen: true,
                            message: reduxText[7430],
                            noMessage: true,
                            source: 'TeamForm handleInlineDelete',
                        }))
                    } else {
                        axiosErrorHandler({
                            apiUrl: deleteUrl,
                            component: 'TeamForm',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleInlineDelete',
                        })
                    }
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamForm',
                'handleInlineDelete',
            ))
        }
    }

    async function handleInlineRetrieve(object: MainTeamListFormSerializer) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TeamForm handleInlineRetrieve')
            const getUrl = `${formState.mother.detailUrl}${object.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    setShowInlineForm(false)
                    clearTimeout(wto2)
                    wto2 = setTimeout(() => {
                        setShowInlineForm(true)
                    }, 100)
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    let newRequiredAttribute: string[] = []
                    let newRequiredForeignKey: string[] = []
                    if (response.data.member) {
                        newRequiredAttribute = []
                        newRequiredForeignKey = [
                            'member',
                        ]
                    } else {
                        newRequiredAttribute = [
                            'name',
                        ]
                        newRequiredForeignKey = []
                    }
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            requiredAttribute: newRequiredAttribute,
                            requiredForeignKey: newRequiredForeignKey,
                            fields: response.data,
                            errors: {},
                        }
                    })
                    setHelpers({
                        ...helpers,
                        init: true,
                    })
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'TeamForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineRetrieve',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamForm',
                'handleInlineRetrieve',
            ))
        }
    }

    function onHandleInputChange(event: any) {
        try {
            const mother = formState.mother
            if (event.name === 'name') {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        member: '',
                        name: event.value,
                    },
                    errors: {
                        ...mother.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                    ],
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (event.name === 'member') {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        member: event.value,
                        name: '',
                    },
                    errors: {
                        ...mother.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                        'member',
                    ],
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else {
                setFormState({
                    ...formState,
                    mother: getInputValue({
                        event,
                        formInfo: formState.mother,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamForm',
                'onHandleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: formState.mother,
                reduxText,
            })
            if (process.env.NODE_ENV === 'development') console.log(result)
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    mother: {
                        ...formState.mother,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamForm',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TeamForm handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
                mainError: '',
            })

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = formState.mother.createUrl
            if (formState.mother.fields?.id) {
                axiosMethod = 'put'
                axiosUrl = `${formState.mother.updateUrl}${formState.mother.fields.id}/`
            }
            const formData: any = getFormData(formState.mother)
            formData.append('object_id', objectId)
            formData.append('content_type', contentTypeId)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setShowInlineForm(false)
                    clearTimeout(wto3)
                    wto3 = setTimeout(() => {
                        setShowInlineForm(true)
                    }, 100)
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        init: false,
                        isSaving: false,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: {},
                            fields: {},
                        }
                    })
                    onGetListData(
                        getAxiosUrl(formState.mother),
                        true,
                    )
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'TeamForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamForm',
                'handleSubmit',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        noEmpty?: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'TeamForm',
                dispatch,
                items,
                noEmpty,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamForm',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TeamForm',
                'searchNext',
            ))
        }
    }

    // async function onSortEnd(event: any) {
    //     try {
    //         const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TeamForm onSortEnd')

    //         const item = items[event.detail.from]
    //         const itemTo = items[event.detail.to]
    //         setItems(arrayMoveImmutable(items, event.detail.from, event.detail.to))
    //         const formData: any = new FormData()
    //         formData.append('sibling_id', itemTo.id)
    //         const putUrl = `${formState.mother.patchUrl}${item.id}/`
    //         axios({
    //             data: formData,
    //             headers: refreshAxiosHeaders,
    //             method: 'put',
    //             url: putUrl,
    //         })
    //             .then((response) => {
    //                 if (process.env.NODE_ENV === 'development') console.log(response)
    //             })
    //             .catch((error) => {
    //                 axiosErrorHandler({
    //                     apiUrl: putUrl,
    //                     component: 'TeamForm',
    //                     dispatch,
    //                     error,
    //                     reduxAuth,
    //                     reference: 'onSortEnd',
    //                 })
    //             })
    //     } catch (error) {
    //         dispatch(reduxModalErrorEventHandler(
    //             error,
    //             'TeamForm',
    //             'onSortEnd',
    //         ))
    //     }
    // }

    const errorsMother = formState.mother.errors
    const fieldsMother = formState.mother.fields

    let tinymceStylesString = ''
    const tinymceStylesObject: any = {}
    if (fieldsMother?.portfolio_main?.styles) {
        Object.getOwnPropertyNames(fieldsMother.portfolio_main.styles.value).map((val: any) => {
            if (val === 'fontFamily') {
                if (fieldsMother.portfolio_main!.styles!.value[val]) {
                    tinymceStylesString += `font-family: ${fieldsMother.portfolio_main!.styles!.value[val]};`
                }
            }
            if ([
                'fontFamily',
            ].includes(val) && fieldsMother.portfolio_main!.styles!.value[val]) {
                tinymceStylesObject[val] = fieldsMother.portfolio_main!.styles!.value[val]
            }
            return false
        })
    }

    // Extra
    const options = [
        { id: 1, name: reduxText[8549] },
        { id: 2, name: reduxText[8550] },
        { id: 98, name: reduxText[4905] },
        { id: 99, name: reduxText[6441] },
    ]

    return (
        <Dialog
            className='mo-dialog-class inline-form'
            classes={{ paper: 'mo-dialog-wrapper' }}
            disableEnforceFocus
            fullWidth
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[4917]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className='mo-dialog-content no-padding'>
                <div className='mo-dialog-content-left'>
                    <div className={`mo-dialog-content-form-children mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {showInlineForm && (
                            <React.Fragment>
                                {fieldsMother?.id
                                    ? (
                                        <React.Fragment>
                                            {fieldsMother?.member
                                                ? (
                                                    <SelectAsyncInput
                                                        apiUrl={`${select_url_profile}?credit=${profileId}`}
                                                        clearable={false}
                                                        disabled
                                                        error={errorsMother?.member}
                                                        label={reduxText[2977]}
                                                        layout='profile'
                                                        name='member'
                                                        onChange={onHandleInputChange}
                                                        team
                                                        value={fieldsMother?.member || ''}
                                                    />
                                                ) : (
                                                    <TextInput
                                                        error={errorsMother?.name}
                                                        label={reduxText[2977]}
                                                        name='name'
                                                        onChange={onHandleInputChange}
                                                        value={fieldsMother?.name || ''}
                                                    />
                                                )
                                            }
                                        </React.Fragment>
                                    ) : (
                                        <SelectAsyncInput
                                            apiUrl={`${select_url_profile}?credit=${profileId}`}
                                            clearable={false}
                                            creatable
                                            error={errorsMother?.member}
                                            label={reduxText[2977]}
                                            layout='profile'
                                            name='member'
                                            onChange={onHandleInputChange}
                                            team
                                            value={fieldsMother?.member || ''}
                                        />
                                    )
                                }
                                <SelectListInput
                                    clearable={false}
                                    error={errorsMother?.credit_type}
                                    label={reduxText[5299]}
                                    name='credit_type'
                                    onChange={onHandleInputChange}
                                    optional
                                    options={options}
                                    value={fieldsMother?.credit_type || ''}
                                />
                                <TextInput
                                    error={errorsMother?.roles}
                                    label={reduxText[2979]}
                                    name='roles'
                                    onChange={onHandleInputChange}
                                    optional
                                    value={fieldsMother?.roles || ''}
                                />
                                <RichTextInput
                                    error={errorsMother?.custom_text_rich}
                                    fonts={fieldsMother?.portfolio_main?.fonts}
                                    label={reduxText[7685]}
                                    name='custom_text_rich'
                                    objectId={fieldsMother?.id}
                                    onChange={onHandleInputChange}
                                    optional
                                    presetColors={fieldsMother?.preset_colors}
                                    tinymceStylesObject={tinymceStylesObject}
                                    tinymceStylesString={tinymceStylesString}
                                    translation={{
                                        contentType: 'mainteam',
                                        defaultDetailUrl: formState.mother.detailUrl,
                                        defaultLanguage: fieldsMother?.translation_default_language,
                                        defaultUpdateUrl: formState.mother.updateUrl,
                                        objectId: fieldsMother?.id,
                                        profileId: fieldsMother?.profile,
                                    }}
                                    value={fieldsMother?.custom_text_rich || ''}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div className='mo-dialog-footer'>
                        {helpers.init
                            ? (
                                <React.Fragment>
                                    <Button
                                        edit={false}
                                        expand='full'
                                        fill='outline'
                                        onClick={handleCancel}
                                        padding='0 5px'
                                        text={reduxText[4519]}
                                    />
                                    <Button
                                        disabled={helpers.buttonDisabled}
                                        edit={false}
                                        expand='full'
                                        fill='outline'
                                        onClick={handleRequired}
                                        padding='0 5px'
                                        text={reduxText[4520]}
                                    />
                                </React.Fragment>
                            ) : (
                                <Button
                                    edit={false}
                                    fill='outline'
                                    onClick={() => setIsOpen(false)}
                                    text={reduxText[4742]}
                                />
                            )}
                    </div>
                </div>
                <div className={`mo-dialog-content-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {items.length > 0 && (
                        <div className='mo-dialog-content-table-container'>
                            <table>
                                <thead>
                                    <tr className='main-list-header TeamForm'>
                                        <th>{reduxText[2977]}</th>
                                        <th>{reduxText[5299]}</th>
                                        <th>{reduxText[2979]}</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(val => {
                                        return (
                                            <TeamListFormBlock
                                                key={val.id}
                                                handleInlineActive={handleInlineActive}
                                                handleInlineDelete={handleInlineDelete}
                                                handleInlineRetrieve={handleInlineRetrieve}
                                                object={val}
                                            />
                                        )
                                    })}
                                </tbody>
                            </table>
                            <InfiniteScrollHelperWeb
                                active={!disableInfiniteScroll}
                                hasMore={hasMore}
                                isLoading={isLoading}
                                items={items}
                                onChange={onSearchNext}
                            />
                        </div>
                    )}
                </div>
            </DialogContent>
            {isDeleting && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </Dialog>
    )
})
