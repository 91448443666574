// Root URL
const rootPath = 'main/select/'

// main
export const select_url_main_country = `${rootPath}country/`
export const select_url_main_currency = `${rootPath}currency/`
export const select_url_main_custom_category = `${rootPath}custom_category/`
export const select_url_main_custom_category_2 = `${rootPath}custom_category_2/`
export const select_url_main_custom_category_3 = `${rootPath}custom_category_3/`
export const select_url_main_custom_choice = `${rootPath}custom_choice/`
export const select_url_main_day = `${rootPath}day/`
export const select_url_main_font = `${rootPath}font/`
export const select_url_main_gender = `${rootPath}gender/`
export const select_url_main_language_portfolio = `${rootPath}language/portfolio/`
export const select_url_main_language_short = `${rootPath}language/short/`
export const select_url_main_medium_type_artwork = `${rootPath}medium_type/artwork/`
export const select_url_main_medium_type_event = `${rootPath}medium_type/event/`
export const select_url_main_medium_type_material = `${rootPath}medium_type/material/`
export const select_url_main_medium_type_offer = `${rootPath}medium_type/offer/`
export const select_url_main_medium_type_page = `${rootPath}medium_type/page/`
export const select_url_main_medium_type_press = `${rootPath}medium_type/press/`
export const select_url_main_medium_type_product = `${rootPath}medium_type/product/`
export const select_url_main_medium_type_profile = `${rootPath}medium_type/profile/`
export const select_url_main_medium_type_resource = `${rootPath}medium_type/resource/`
export const select_url_main_medium_type_service = `${rootPath}medium_type/service/`
