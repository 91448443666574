// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    AdherentDetailFormSerializer,
    AdherentDetailFormSerializerError,
} from 'serializers/web'

// props
type AdherentNumberAdherentInputProps = {
    errors: AdherentDetailFormSerializerError | undefined
    fields: AdherentDetailFormSerializer
    onChange: any
}

// main
export const AdherentNumberAdherentInput: React.FC<AdherentNumberAdherentInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            error={errors?.adherent_number}
            label={reduxText[8564]}
            name='adherent_number'
            onChange={onChange}
            value={fields.adherent_number || ''}
        />
    )
})
