// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    ImageHelper,
    LinkHelper,
} from 'components'

// serializers
import {
    ProductListSerializer,
} from 'serializers/web'

// services
import {
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type ProductListMicroBlockWebProps = {
    edit: boolean
    modalKey: number
    object: ProductListSerializer
}

// main
export const ProductListMicroBlockWeb: React.FC<ProductListMicroBlockWebProps> = React.memo(({
    edit,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()

    return (
        <div className='product-list-micro-block-web'>
            <div className='plmibw-cover'>
                <LinkHelper
                    className='plmibw-cover-wrap'
                    edit={edit}
                    onClick={() => onClickIsModal(object.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrl(object.get_absolute_url!)}
                >
                    <div className='plmibw-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='plmibw-image'
                            dominant_color={object.get_dominant_color}
                            src={object.get_image_square}
                        />
                    </div>
                </LinkHelper>
            </div>
        </div>
    )
})
