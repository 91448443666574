// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    // IconBlock,
    LinkHelper,
} from 'components'

// data
import {
    api_url_contribution_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ContributionActionSheet,
} from 'pages'

// serializers
import {
    ContributionListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type ContributionListSmallBlockProps = {
    edit: boolean
    inDetailList?: boolean
    isInProfileSlug?: string
    modalKey: number
    object: ContributionListSerializer
}

// main
export const ContributionListSmallBlock: React.FC<ContributionListSmallBlockProps> = React.memo(({
    edit,
    inDetailList,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [detailObject, setDetailObject] = useState<ContributionListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_contribution_detail}${detailObject.id}/?block_size=small`, reduxAuth),
                component: 'ContributionListSmallBlock',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContributionListSmallBlock',
                'onGetDetailData',
            ))
        }
    }

    return (
        <tr className='main-list-row ContributionListSmallBlock'>
            <td>
                {/* <IconBlock
                    edit={edit}
                    iconClass='mo-new-icon-eye-solid'
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrl(detailObject.adherent?.get_absolute_url!)}
                /> */}
                <ContributionActionSheet
                    edit={edit}
                    object={detailObject}
                    refreshData={onGetDetailData}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                    inModal={inDetailList}
                />
            </td>
            <td><i className={`mlr-icon ${detailObject.is_paid ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
            <td>{detailObject.year}</td>
            {!inDetailList && (
                <td>
                    <LinkHelper
                        edit={edit}
                        onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                        to={getModalAbsoluteUrl(detailObject.adherent?.get_absolute_url!)}
                    >
                        {detailObject.name}
                    </LinkHelper>
                </td>
            )}
            <td>{detailObject.pricing?.name}</td>
            <td className='tar'>{(detailObject.amount !== null && detailObject.amount! >= 0) ? `${detailObject.amount} €` : '-'}</td>
            <td>{detailObject.payment_date}</td>
        </tr>
    )
})
