// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
    Loader,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FieldSettingsModalAllFields,
    FieldSettingsModalDetailFields,
    FieldSettingsModalListFields,
    FieldSettingsModalNotEmptyFields,
} from 'pages'

// serializers
import {
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
} from 'services'

type ItemType = {
    columnName: string
    columnTitle: string
}

// props
type FieldSettingsModalProps = {
    blockId?: number
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    profile: ProfileDetailAdminSerializerV2 | undefined
    refreshData?: () => void
    setIsOpen: React.Dispatch<boolean>
    subContentTypeId: number | undefined
    viewId: number | undefined
}

// main
export const FieldSettingsModal: React.FC<FieldSettingsModalProps> = React.memo(({
    blockId,
    contentType,
    contentTypeSettings,
    profile,
    refreshData,
    setIsOpen,
    subContentTypeId,
    viewId,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isSaving, setIsSaving] = useState(false)
    const [selectedOptionsInDetail, setSelectedOptionsInDetail] = useState<ItemType[]>([])
    const [selectedOptionsInList, setSelectedOptionsInList] = useState<ItemType[]>([])

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'FieldSettingsModal handleSubmit')
            setIsSaving(true)
            const postUrl = getApiUrlV2(`setup/form/contenttypefieldlist/selection_detail_list/`, reduxAuth)
            const formData: any = new FormData()
            formData.append('sub_content_type', subContentTypeId)
            formData.append('fields_in_detail', JSON.stringify(selectedOptionsInDetail))
            formData.append('fields_in_list', JSON.stringify(selectedOptionsInList))
            if (blockId) formData.append('block_id', blockId)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsSaving(false)
                    setIsOpen(false)
                    if (refreshData) refreshData()
                })
                .catch((error) => {
                    setIsSaving(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'FieldSettingsModal',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setIsSaving(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSettingsModal',
                'handleSubmit',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class inline-form quarter content-list-modal-portfolio'
            classes={{ paper: 'mo-dialog-wrapper' }}
            fullWidth
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {/* TO_TEXT */}
                Field Settings
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className='mo-dialog-content no-padding'>
                <div className={`mo-dialog-content-quarter mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    <FieldSettingsModalAllFields
                        contentType={contentType}
                        contentTypeSettings={contentTypeSettings}
                        profile={profile}
                        selectedOptionsInDetail={selectedOptionsInDetail}
                        selectedOptionsInList={selectedOptionsInList}
                        setSelectedOptionsInDetail={setSelectedOptionsInDetail}
                        setSelectedOptionsInList={setSelectedOptionsInList}
                    />
                </div>
                {viewId && (
                    <div className={`mo-dialog-content-quarter mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <FieldSettingsModalNotEmptyFields
                            contentType={contentType}
                            contentTypeSettings={contentTypeSettings}
                            profile={profile}
                            selectedOptionsInDetail={selectedOptionsInDetail}
                            selectedOptionsInList={selectedOptionsInList}
                            setSelectedOptionsInDetail={setSelectedOptionsInDetail}
                            setSelectedOptionsInList={setSelectedOptionsInList}
                            viewId={viewId}
                        />
                    </div>
                )}
                {blockId && (
                    <div className={`mo-dialog-content-quarter mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <FieldSettingsModalDetailFields
                            blockId={blockId}
                            contentType={contentType}
                            contentTypeSettings={contentTypeSettings}
                            profile={profile}
                            selectedOptionsInDetail={selectedOptionsInDetail}
                            setSelectedOptionsInDetail={setSelectedOptionsInDetail}
                        />
                    </div>
                )}
                {subContentTypeId && (
                    <div className={`mo-dialog-content-quarter mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <FieldSettingsModalListFields
                            contentType={contentType}
                            contentTypeSettings={contentTypeSettings}
                            profile={profile}
                            selectedOptionsInList={selectedOptionsInList}
                            setSelectedOptionsInList={setSelectedOptionsInList}
                            subContentTypeId={subContentTypeId}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen(false)}
                    text={reduxText[4519]}
                />
                <Button
                    edit={false}
                    fill='outline'
                    onClick={handleSubmit}
                    text={reduxText[3563]}
                />
            </DialogActions>
            {isSaving && (
                <Loader isOpen />
            )}
        </Dialog>
    )
})
