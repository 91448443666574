// react components
import React from 'react'
import axios from 'axios'

// data
import {
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

export async function getDetailObject({
    apiUrl,
    component,
    customFunction,
    dispatch,
    noHeaders,
    reduxAuth,
    setDetailObject,
    setErrorStatus,
    setIsLoading,
    skipErorrHandling,
}: {
    apiUrl: string
    component: string
    customFunction?: (e: any) => void
    dispatch: React.Dispatch<any>
    noHeaders?: boolean
    reduxAuth: reduxAuthState
    setDetailObject: React.Dispatch<any>
    setErrorStatus?: React.Dispatch<any>
    setIsLoading?: React.Dispatch<boolean>
    skipErorrHandling?: boolean
}) {

    if (!apiUrl) return

    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getDetailObject`)

    if (setIsLoading) setIsLoading(true)

    if (process.env.NODE_ENV === 'development') console.log(apiUrl)

    axios({
        headers: noHeaders ? undefined : refreshAxiosHeaders,
        method: 'get',
        url: apiUrl,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            setDetailObject(response.data)
            if (setIsLoading) setIsLoading(false)
            if (customFunction) customFunction(response.data)
        })
        .catch((error) => {
            if (setIsLoading) setIsLoading(false)
            if (skipErorrHandling) return
            if (setErrorStatus && error.response?.status === 403) {
                setErrorStatus(error.response.status)
            } else if (setErrorStatus && error.response?.status === 404 && error.response?.data?.detail) {
                setErrorStatus(error.response.status)
            } else {
                axiosErrorHandler({
                    apiUrl,
                    component,
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'getDetailObject',
                })
            }
        })
}
