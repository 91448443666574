// Root URL
const rootPath = 'feed/'

// Detail
export const api_url_custom_feed_block_detail = `${rootPath}detail/custom_feed_block/`
export const api_url_custom_feed_block_detail_short = `${rootPath}detail/custom_feed_block/short/`

// List
export const api_url_custom_feed_block_list = `${rootPath}list/custom_feed_block/`
export const api_url_custom_feed_section_list = `${rootPath}list/custom_feed_section/`
