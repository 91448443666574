// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// services
import {
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type AgendaDuQuartierPaymentSuccessTransfertProps = {
    data: any
}

// main
export const AgendaDuQuartierPaymentSuccessTransfert: React.FC<AgendaDuQuartierPaymentSuccessTransfertProps> = React.memo(({
    data,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='agenda-du-quartier-payment-success'>
            <div className='adgps-content'>
                <p className='adgps-title'>Informations pour le virement bancaire</p>
                <p className='adgps-ref'>Précisez la référence suivante lors du virement svp : {data.invoice_number}</p>
                <p className='adgps-subtitle'>Identifiant national de compte bancaire - RIB</p>
                <table className='adgps-table'>
                    <thead>
                        <tr>
                            <th>Banque</th>
                            <th>Guichet</th>
                            <th>N° compte</th>
                            <th>Clé</th>
                            <th>Devise</th>
                            <th>Domiciliation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>10278</td>
                            <td>06042</td>
                            <td>00020244401</td>
                            <td>82</td>
                            <td>EUR</td>
                            <td>CCM PARIS 12/20 ST MANDE-MARAICH</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <p className='adgps-subtitle'>Identifiant international de compte bancaire</p>
                <table className='adgps-table'>
                    <thead>
                        <tr>
                            <th colSpan={7}>IBAN (International Bank Account Number)</th>
                            <th>BIC (Bank Identifier Code)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>FR76</td>
                            <td>1027</td>
                            <td>8060</td>
                            <td>4200</td>
                            <td>0202</td>
                            <td>4440</td>
                            <td>182</td>
                            <td>CMCIFR2A</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <p className='adgps-title'>Complétez votre profil</p>
                <div dangerouslySetInnerHTML={{ __html: reduxText[10052] }} />
                <p><strong>compléter à 100% votre profil</strong> de {reduxAuth.settings?.federateur?.civility?.id === 1 ? 'Fédératrice' : 'Fédérateur'} de quartier !</p>
                <div className='adgps-button-wrap'>
                    <Button
                        edit={false}
                        text='Compléter mon profil'
                        // to={reduxAuth.settings?.active_profile?.get_absolute_url}
                        onClick={() => reduxAuthFetchSettingsHelper(reduxAuth, dispatch)}
                    />
                </div>
                <p>Un courriel de confirmation pour votre commande n° {data.invoice_number} a été envoyé à l’adresse {reduxAuth.settings?.user?.email}.</p>
                <p>Vérifiez vos courriers indésirables (spam) si vous ne le recevez pas.</p>
                <p>Vous pouvez également télécharger ici votre facture, ou la retrouver dans la rubrique rubrique “Mes commandes”.</p>
                <div className='adgps-button-wrap'>
                    <Button
                        edit={false}
                        href={data.invoice_pdf_url}
                        text='Télécharger ma facture'
                    />
                </div>
                <hr className='adgps-hr' />
                <p>A bientôt, pour animer ensemble nos territoires,</p>
                <p>L’équipe de l’Agenda du quartier</p>
            </div>
        </div>
    )
})
