// react components
import React from 'react'
import {
    IonButton,
} from '@ionic/react'
import {
    Link,
} from 'react-router-dom'
import {
    isMobileOnly,
} from 'react-device-detect'

// props
type IconBlockProps = {
    active?: boolean
    className?: string
    color2?: string
    color?: 'light'
    disabled?: boolean
    edit: boolean
    href?: string
    iconClass: string
    onClick?: (e: any) => void
    to?: string
    tooltipText?: string
}

// main
export const IconBlock: React.FC<IconBlockProps> = React.memo(({
    active,
    className,
    color,
    color2,
    disabled,
    edit,
    href,
    iconClass,
    onClick,
    to,
    tooltipText,
}) => {

    if (isMobileOnly) {
        return (
            <IonButton
                className='icon-block-mobile'
                color='tertiary'
                disabled={edit || disabled}
                onClick={edit ? undefined : onClick}
                size='small'
                title={tooltipText}
            >
                <i
                    className={`main-icon ${iconClass}${active ? ' color' : ''}`}
                    style={{
                        color: color2,
                    }}
                />
            </IonButton>
        )
    }

    if (href) {
        return (
            <a
                className={`main-icon-block-web${color ? ` ${color}` : ''}${className ? ` ${className}` : ''}`}
                // disabled={edit || disabled}  TODO
                href={href}
                rel='noopener noreferrer'
                onClick={edit ? undefined : onClick}
                title={tooltipText}
                target='_blank'
            >
                <i
                    className={`main-icon ${iconClass}${active ? ' color' : ''}`}
                    style={{
                        color: color2,
                    }}
                />
            </a>
        )
    }

    if (to) {
        return (
            <Link
                className={`main-icon-block-web${color ? ` ${color}` : ''}${className ? ` ${className}` : ''}`}
                // disabled={edit || disabled}  TODO
                onClick={edit ? undefined : onClick}
                title={tooltipText}
                to={to}
            >
                <i
                    className={`main-icon ${iconClass}${active ? ' color' : ''}`}
                    style={{
                        color: color2,
                    }}
                />
            </Link>
        )
    }

    return (
        <button
            className={`main-icon-block-web${color ? ` ${color}` : ''}${className ? ` ${className}` : ''}`}
            disabled={edit || disabled}
            onClick={edit ? undefined : onClick}
            title={tooltipText}
        >
            <i
                className={`main-icon ${iconClass}${active ? ' color' : ''}`}
                style={{
                    color: color2,
                }}
            />
        </button>
    )
})
