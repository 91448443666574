// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    InfiniteScrollHelperWeb,
} from 'components'

// data
import {
    api_url_project_content_list,
    api_url_project_detail,
    defaultReduxState,
    reduxModalContentDetailSetItems,
    reduxModalContentDetailShow,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    PressListMediumBlockWeb,
} from 'pages'

// serializers
import {
    ProjectDetailSerializer,
    ProjectListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getInfiniteListWebNew,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// props
type ArticleContentBlockWebProps = {
    edit: boolean
    object: ProjectDetailSerializer
}

// main
export const ArticleContentBlockWeb: React.FC<ArticleContentBlockWebProps> = React.memo(({
    edit,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormrefreshrefreshForm = useSelector((state: defaultReduxState) => state.reduxForm.refresh.refreshForm)

    useEffect(() => {
        onGetListData(getApiUrl(`${api_url_project_content_list}?project_id=${object.id}&medium_type_id=7&medium_type_id=9&block_size=medium`, reduxAuth), true)
    }, [
        object.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
        reduxFormrefreshrefreshForm,
    ])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb & { items: ProjectListSerializer[] }>({
        ...listStateInitialWeb,
        modalKey: Math.random(),
    })

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            if (!object.id) return
            getInfiniteListWebNew({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'ArticleContentBlockWeb',
                componentName: '',
                dispatch,
                listState,
                onSuccess: changingView ? undefined : (e: any, newItems: any[]) => dispatch(reduxModalContentDetailSetItems(newItems)),
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ArticleContentBlockWeb',
                'onGetListData',
            ))
        }
    }

    function onImageClick(obj: any) {
        try {
            dispatch(reduxModalContentDetailShow(
                getApiUrl(api_url_project_detail, reduxAuth),
                'project',
                'ProjectDetailBlockWeb',
                'ProjectActionSheetWeb',
                obj.id,
                listState.items,
                'project',
                listState.nextUrl,
                onSearchNext,
                'ArticleContentBlockWeb',
            ))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ArticleContentBlockWeb',
                'onImageClick',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ArticleContentBlockWeb',
                'onSearchNext',
            ))
        }
    }

    return (
        <div className={`article-content-block-web mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            {listState.items.map((item) => {
                return (
                    <PressListMediumBlockWeb
                        key={item.id}
                        edit={edit}
                        modalKey={listState.modalKey}
                        object={item}
                        onModalClick={onImageClick}
                        playerOrigin={`projectDetail-${object.id}`}
                    />
                )
            })}
            <InfiniteScrollHelperWeb
                active={!listState.disableInfiniteScroll}
                hasMore={listState.hasMore}
                isLoading={listState.isLoading}
                items={listState.items}
                onChange={onSearchNext}
            />
        </div>
    )
})
