// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    ImageHelper,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    EventImageListSerializer,
} from 'serializers/web'

// props
type ImageGalleryHelperProps = {
    direction: string
    left?: number
    margin?: string
    onClick?: any
    photo: EventImageListSerializer
    top?: number
}

export const ImageGalleryHelper: React.FC<ImageGalleryHelperProps> = React.memo(({
    direction,
    left,
    margin,
    onClick,
    photo,
    top,
    ...props
}) => {

    const dispatch = useDispatch()

    function onImageClick(object: any, index: number) {
        try {
            if (onClick) onClick(object, index)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageGalleryHelper',
                'onImageClick',
            ))
        }
    }

    if (direction === 'column') {
        // @ts-ignore
        props.position = 'absolute'
        // @ts-ignore
        props.left = left
        // @ts-ignore
        props.top = top
    }

    if (direction === 'row') {
        // @ts-ignore
        props.position = 'relative'
    }

    return (
        <div
            className={`image-gallery-helper-web${onClick ? ' cursor' : ''}`}
            // @ts-ignore
            onClick={() => onImageClick(photo, props.index)}
            style={{
                height: photo.height,
                margin: margin,
                width: photo.width,
                ...props,
            }}
        >
            <ImageHelper
                alt=''
                className='ighw-image'
                dominant_color={photo.get_dominant_color}
                src={photo.src}
            />
        </div>
    )
})
