// data
import {
    api_url_block_template_form_active,
    api_url_block_template_form_create,
    api_url_block_template_form_detail,
    api_url_block_template_form_update,
    reduxAuthState,
    view_url_block_template_detail,
    view_url_block_template_form,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function BlockTemplateMotherFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'mother',

        // Settings
        detailComponent: 'BlockTemplateDetailBlock',
        model: 'blocktemplate',

        // Urls
        activeUrl: getApiUrl(api_url_block_template_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_block_template_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_block_template_form_detail, reduxAuth),
        formUrl: view_url_block_template_form,
        updateUrl: getApiUrl(api_url_block_template_form_update, reduxAuth),
        viewUrl: view_url_block_template_detail,

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [],
        requiredForeignKey: [],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'active',
            'image_alt',
            'image_doka_output_data',
        ],
        formFile: [
            'image',
            'image_original',
        ],
        formForeignKey: [],
        formJson: [],
        formManyToMany: [
            'custom_categories',
        ],
    }
}
