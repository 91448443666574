// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// props
type TranslationHelperProps = {
    edit: boolean
    inNav?: boolean
    languageId: number | undefined
    languages: MainIdNameSerializer[]
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const TranslationHelper: React.FC<TranslationHelperProps> = React.memo(({
    edit,
    inNav,
    languageId,
    languages,
    setLanguageId,
}) => {

    const reduxAuthsettingslanguage = useSelector((state: defaultReduxState) => state.reduxAuth.settings?.language)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const languageObject: any = {
        'en': 1,
        'fr': 2,
    }

    const newLanguageId = languageId || languageObject[reduxAuthsettingslanguage!]

    if (inNav) {
        return (
            <div className='translation-helper-web in-nav'>
                {languages.map((val) => (
                    <LinkHelper
                        key={val.id}
                        className={`thw-language ${newLanguageId === val.id ? 'active' : ''}`}
                        edit={edit}
                        onClick={() => setLanguageId ? setLanguageId(val.id!) : undefined}
                    >
                        {val.name}
                    </LinkHelper>
                ))}
            </div>
        )
    }
    return (
        <div className='profile-section-block'>
            <span className='profile-section-block-small-text'>{reduxText[6582]}:</span>
            {languages.map((val) => (
                <LinkHelper
                    key={val.id}
                    className={`main-hashtag${newLanguageId === val.id ? ' active' : ''}`}
                    edit={edit}
                    onClick={() => setLanguageId ? setLanguageId(val.id!) : undefined}
                >
                    {val.name}
                </LinkHelper>
            ))}
        </div>
    )
})
