// react components
import React from 'react'
import {
    Dialog,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// containers
import {
    DetailPageModal,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalSliderDetailShowHide,
} from 'data'

// pages
import {
    DetailModal,
} from 'pages'

// services
import {
    parseQuery,
} from 'services'

// main
export const DetailModalWrapper: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxModalmodalDetail = useSelector((state: defaultReduxState) => state.reduxModal.modalDetail)

    const searchParams = parseQuery(location.search)
    const modal_page = searchParams.modal_page
    const modal_detail_id = searchParams.modal_detail_id
    const modal_page_type = searchParams.modal_page_type

    return (
        <Dialog
            className='detail-modal-wrapper-web'
            classes={{ paper: 'mo-dialog-wrapper ctf-wrapper' }}
            disableEnforceFocus
            fullScreen
            onClose={() => dispatch(reduxModalSliderDetailShowHide(false))}
            open={Boolean(reduxModalmodalDetail.isOpen && modal_page && modal_detail_id)}
        >
            {reduxModalmodalDetail.isOpen && modal_page && modal_detail_id && (
                modal_page_type
                    ? (
                        <DetailModal
                            // @ts-ignore  // TODO return 404 in contentType does not exist
                            contentType={modal_page}
                            detailId={modal_detail_id}
                        />
                    ) : (
                        <DetailPageModal
                            detailId={modal_detail_id}
                            pageSlug={modal_page}
                        />
                    )
            )}
        </Dialog>
    )
}
