// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectAsyncInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_service,
} from 'data'

// serializers
import {
    EventDetailFormSerializer,
    EventDetailFormSerializerError,
} from 'serializers/web'

// props
type ServiceEventInputProps = {
    errors: EventDetailFormSerializerError | undefined
    fields: EventDetailFormSerializer
    onChange: any
}

// main
export const ServiceEventInput: React.FC<ServiceEventInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <SelectAsyncInput
            apiUrl={select_url_service}
            clearable={false}
            error={errors?.services}
            label={reduxText[7434]}
            layout='project'
            multi
            name='services'
            onChange={onChange}
            optional
            value={fields.services || ''}
        />
    )
})
