// Root URL
const rootPath = 'about/'

// Detail
export const api_url_about_help_center_topic_detail = `${rootPath}detail/help_center/topic/`
export const api_url_about_help_center_topic_item_detail = `${rootPath}detail/help_center/topic_item/`

// List
export const api_url_about_help_center_category_list = `${rootPath}list/help_center/category/`
export const api_url_about_help_center_category_profile_list = `${rootPath}list/help_center/category_profile/`
