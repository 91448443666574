// react components
import React from 'react'
import {
    IonSegment,
    IonSegmentButton,
    IonLabel,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// props
type SearchTabsMobileProps = {
    mainTabValue: string
    mainTabs: any
    setMainTabValue: any
}

// main
export const SearchTabsMobile: React.FC<SearchTabsMobileProps> = React.memo(({
    mainTabValue,
    mainTabs,
    setMainTabValue,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='search-tabs'>
            <IonSegment
                onIonChange={(e) => setMainTabValue(e.detail.value)}
                scrollable
                value={mainTabValue}
            >

                {mainTabs.map((val: any, i: number) => (
                    <div key={i}>
                        <IonSegmentButton
                            className='st-tab-button'
                            value={val.name}
                        >
                            <IonLabel className='st-tab-label'>
                                {reduxText[val.textId]}
                            </IonLabel>
                        </IonSegmentButton>
                    </div>
                ))}
            </IonSegment>
        </div>
    )
})
