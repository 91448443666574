// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetHoveredBlockIdSite,
    reduxFormSetSectionIdSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    EditButtonMixedMediaContent,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    scrollToSection,
} from 'services'

// serializers
import {
    ProjectFormState,
} from 'serializers/web'

// props
type MatchParams = {
    id: string
    formType: string
    panelValue: string
}

type SelectionHelperMixedMediaContentProps = {
    contentType: contentTypeModelType
    mother: ProjectFormState['mother']
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    object: PortfolioPageContentListSiteSerializer
}

// main
export const SelectionHelperMixedMediaContent: React.FC<SelectionHelperMixedMediaContentProps> = React.memo(({
    contentType,
    mother,
    newMixedMediaContent,
    newMixedMediaContentType,
    object,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const active = [Number(params.panelValue), Number(reduxFormSite.sectionId)].includes(object.id!)

    function handleCssClassHover(mousedirection: 'enter' | 'leave') {
        try {
            if (mousedirection === 'enter') {
                dispatch(reduxFormSetHoveredBlockIdSite(object.id))
            } else {
                dispatch(reduxFormSetHoveredBlockIdSite(undefined))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectionHelperMixedMediaContent',
                'handleCssClassHover',
            ))
        }
    }

    function handleEdit() {
        if (newMixedMediaContent) {
            history.push(`/form-mixed-media/${newMixedMediaContentType}/${params.id}/${object.id}/`)
        } else {
            history.push(`${mother.formUrl}${params.id}/mixed-media-content/${object.id}/`)
        }
        dispatch(reduxFormSetSectionIdSite(object.id))
        if (Number(params.panelValue) === object.id) scrollToSection(params.panelValue, 0)
    }

    return (
        <React.Fragment>
            <div
                className={`selection-helper-page-content-web-left${active ? ' active' : ''}`}
                onMouseEnter={() => handleCssClassHover('enter')}
                onMouseLeave={() => handleCssClassHover('leave')}
            >
                <div className='shpcwl-line-actions'>
                    <EditButtonMixedMediaContent
                        allowDrop
                        contentType={contentType}
                        isPopoverOpen={isPopoverOpen}
                        mother={mother}
                        newMixedMediaContent={newMixedMediaContent}
                        newMixedMediaContentType={newMixedMediaContentType}
                        object={object}
                        setIsPopoverOpen={setIsPopoverOpen}
                    >
                        <button
                            className='shpcwl-line-icon-link'
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                            <i className='mo-new-icon-ellipsis-h-solid shpcwl-line-icon' />
                        </button>
                    </EditButtonMixedMediaContent>
                    <button
                        className='shpcwl-line-icon-link'
                        onClick={() => handleEdit()}
                    >
                        <i className='mo-new-icon-pencil-alt-solid shpcwl-line-icon' />
                    </button>
                </div>
                <div className='shpcwl-line' />
            </div>
            <div className={`selection-helper-page-content-web-right${active ? ' active' : ''}`} />
        </React.Fragment>
    )
})
