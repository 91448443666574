// react components
import React, {
    useState,
} from 'react'
import ReactPlayer from 'react-player'
import moment from 'moment'
import {
    IonContent,
    IonFooter,
    IonHeader,
    IonItem,
    IonLabel,
    IonModal,
    IonThumbnail,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    Snackbar,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    ImageHelper,
    LinkHelper,
} from 'components'

// data
import {
    defaultReduxState,
    media_url_platform,
    media_version,
    reduxModalErrorEventHandler,
    reduxModalPlayerOnDismiss,
    reduxModalPlayerOnPlayPause,
    reduxModalPlayerOnReady,
    reduxModalPlayerSetCurrentTrack,
    reduxModalPlayerSetPosition,
} from 'data'

// main
export const EcPlayerWeb: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalmodalDetail = useSelector((state: defaultReduxState) => state.reduxModal.modalDetail)
    const reduxModalplayer = useSelector((state: defaultReduxState) => state.reduxModal.player)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [duration, setDuration] = useState<number>(0)
    const [errorMessage, showErrorMessage] = useState(false)
    const [fullScreen] = useState(false)
    const [loop] = useState(false)
    const [played, setPlayed] = useState<number>(0)
    const [showTrackList, setShowTrackList] = useState(false)

    function onDuration(duration: number) {
        try {
            setDuration(duration)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerWeb',
                'onDuration',
            ))
        }
    }

    function onError(error: any) {
        console.log(error)
    }

    function onNext(customTrack?: any) {
        try {
            // @ts-ignore
            dispatch(reduxModalPlayerSetCurrentTrack(
                customTrack || nextTrack,
                reduxAuth,
                dispatch,
            ))
            if (customTrack) setShowTrackList(false)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerWeb',
                'onNext',
            ))
        }
    }

    function onNextAlbumSkip() {
        try {
            if (nextAlbumSkipTrack) {
                // @ts-ignore
                dispatch(reduxModalPlayerSetCurrentTrack(
                    nextAlbumSkipTrack,
                    reduxAuth,
                    dispatch,
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerWeb',
                'onNextAlbumSkip',
            ))
        }
    }

    function onPlayPause() { // Play, Pause audio and video
        try {
            if (reactPlayerActionDisabled) {
                showErrorMessage(true)
            } else {
                dispatch(reduxModalPlayerOnPlayPause(!reduxModalplayer.playing))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerWeb',
                'onPlayPause',
            ))
        }
    }

    function onPrev() {
        try {
            if (!prevTrack) return
            // @ts-ignore
            dispatch(reduxModalPlayerSetCurrentTrack(
                prevTrack,
                reduxAuth,
                dispatch,
            ))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerWeb',
                'onPrev',
            ))
        }
    }

    function onPrevAlbumSkip() {
        try {
            if (prevAlbumSkipTrack) {
                // @ts-ignore
                dispatch(reduxModalPlayerSetCurrentTrack(
                    prevAlbumSkipTrack,
                    reduxAuth,
                    dispatch,
                ))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerWeb',
                'onPrevAlbumSkip',
            ))
        }
    }

    function onProgress(value: any) { // Player progress data send to redux
        try {
            setPlayed(value.playedSeconds)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerWeb',
                'onProgress',
            ))
        }
    }

    function onReady() { // Player ready send to redux
        try {
            dispatch(reduxModalPlayerOnReady())
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerWeb',
                'onReady',
            ))
        }
    }

    function onDismissPlayer() {
        try {
            dispatch(reduxModalPlayerOnDismiss())
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerWeb',
                'onDismissPlayer',
            ))
        }
    }

    // soundcloud config options
    const soundcloudConfig = {
        options: {
            auto_play: true,
        },
    }

    // vimeo config options
    const vimeoConfig = {
        playerOptions: { // https://help.vimeo.com/hc/en-us/articles/360001494447-Using-Player-Parameters
            autoplay: 1,
        },
    }

    // youtube config
    const youtubeConfig = {
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            color: 'white',
            rel: 0,
            showinfo: 0,
        },
    }

    const otherPlayers = [
        3,
        7,
        8,
        9,
        10,
        11,
        13,
        14,
        15,
        16,
        18,
        19,
        21,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
    ]

    const coverNeededBottomPlatform = [
        2,
        3,
        6,
        8,
        9,
        11,
        18,
        21,
    ]

    if (!reduxModalplayer.currentTrack?.id) return null

    const nextTrack = reduxModalplayer.trackArray && reduxModalplayer.trackArray[(reduxModalplayer.currentTrackIndex || 0) + 1]
    const prevTrack = reduxModalplayer.trackArray && reduxModalplayer.trackArray[(reduxModalplayer.currentTrackIndex || 0) - 1]
    const prevAlbumSkipTrack = reduxModalplayer.originalTrackArray && reduxModalplayer.originalTrackArray[(reduxModalplayer.originalTrackIndex || 0) - 1]
    const nextAlbumSkipTrack = reduxModalplayer.originalTrackArray && reduxModalplayer.originalTrackArray[(reduxModalplayer.originalTrackIndex || 0) + 1]
    const reactPlayerActionDisabled = Boolean(reduxModalplayer.currentTrack.platform && otherPlayers.includes(reduxModalplayer.currentTrack.platform))

    return (
        <div
            className={`mo-player-web ${fullScreen ? 'landscape' : reduxModalplayer.playerPosition}${reduxModalmodalDetail.isOpen ? ' in-modal' : ''}`}
            style={{
                bottom: (location.pathname.split('/')[1] === 'form') ? '0px' : undefined,
            }}
        >
            {reduxModalplayer.playerPosition && (
                <div
                    className={`mo-player-web-block ${reduxModalplayer.playerPosition}`}
                    onClick={() => reduxModalplayer.playerPosition === 'bottom' ? dispatch(reduxModalPlayerSetPosition('full')) : undefined}
                >
                    {/* Start iframe for Deezer player */}
                    {reduxModalplayer.currentTrack?.platform === 3 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={`https://www.deezer.com/plugins/player?format=classic&autoplay=false&playlist=false&color=ff0000&layout=dark&size=medium&type=tracks&id=${reduxModalplayer.currentTrack?.link_id}&app_id=1`}
                            title='Deezer Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Deezer player */}
                    {/* Start iframe for Giphy player */}
                    {reduxModalplayer.currentTrack?.platform === 7 && (
                        <iframe
                            allowFullScreen
                            className='giphy-embed'
                            frameBorder='0'
                            src={`https://giphy.com/embed/${reduxModalplayer.currentTrack?.link_id}`}
                            title='Giphy Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Giphy player */}
                    {/* Start iframe for Spotify player */}
                    {reduxModalplayer.currentTrack?.platform === 8 && (
                        <iframe
                            allow='encrypted-media'
                            scrolling='no'
                            frameBorder='no'
                            src={(reduxModalplayer.currentTrack?.link_id?.includes('episode') || reduxModalplayer.currentTrack?.link_id?.includes('playlist') || reduxModalplayer.currentTrack?.link_id?.includes('track')) ? `https://open.spotify.com/embed/${reduxModalplayer.currentTrack?.link_id}` : `https://open.spotify.com/embed/track/${reduxModalplayer.currentTrack?.link_id}`}
                            title='Spotify Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Spotify player */}
                    {/* Start iframe for Bandcamp player */}
                    {reduxModalplayer.currentTrack?.platform === 9 && (
                        <iframe
                            id='zarmaaa'
                            seamless
                            src={reduxModalplayer.currentTrack?.link_id && reduxModalplayer.currentTrack.link_id.replace('artwork=small', 'artwork=small')}
                            title='Bandcamp Iframe'
                            style={{
                                border: '0',
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Bandcamp player */}
                    {/* Check */}
                    {reduxModalplayer.currentTrack?.platform === 10 && (
                        <iframe
                            allowFullScreen
                            frameBorder='0'
                            scrolling='no'
                            src={`https://www.arte.tv/player/v3/index.php?json_url=https%3A%2F%2Fapi.arte.tv%2Fapi%2Fplayer%2Fv1%2Fconfig%2Ffr%2F${reduxModalplayer.currentTrack?.link_id}%3Fautostart%3D1%26lifeCycle%3D1&amplang=fr_FR&ampembed=1&ampmute=0`}
                            title='To check Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* Check */}
                    {/* Start iframe for Pippa player */}
                    {reduxModalplayer.currentTrack?.platform === 11 && (
                        <iframe
                            allow='autoplay'
                            frameBorder='0'
                            src={`https://player.pippa.io/${reduxModalplayer.currentTrack?.link_id}?theme=default&cover=1&latest=1`}
                            title='Pippa player Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Pippa player */}
                    {/* Start iframe for Arte Audioblog player */}
                    {reduxModalplayer.currentTrack?.platform === 13 && (
                        <iframe
                            src={`https://audioblog.arteradio.com/embed/${reduxModalplayer.currentTrack?.link_id}`}
                            title='Arte Audioblog Iframe'
                            style={{
                                border: 'none',
                                height: '100%',
                                margin: '0',
                                padding: '0',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Arte Audioblog player */}
                    {/* Start iframe for Arte Radio player */}
                    {reduxModalplayer.currentTrack?.platform === 14 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={`${reduxModalplayer.currentTrack?.link_url}?embed`}
                            title='Arte Radio Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Arte Radio player */}
                    {/* Start iframe for France Culture player */}
                    {reduxModalplayer.currentTrack?.platform === 15 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='France Culture Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for France Culture player */}
                    {/* Start iframe for Apple player */}
                    {reduxModalplayer.currentTrack?.platform === 16 && (
                        <iframe
                            allow='autoplay * encrypted-media *'
                            height='150'
                            sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation'
                            src={`https://embed.${reduxModalplayer.currentTrack?.link_id}`}
                            title='Apple Iframe'
                            style={{
                                background: 'transparent',
                                height: '100%',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Apple player */}
                    {/* Start iframe for France Inter player */}
                    {reduxModalplayer.currentTrack?.platform === 18 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='France Inter Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for France Inter player */}
                    {/* Start iframe for France TV player */}
                    {reduxModalplayer.currentTrack?.platform === 19 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='France TV Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for France TV player */}
                    {/* Start iframe for Anchor player */}
                    {reduxModalplayer.currentTrack?.platform === 21 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='Anchor Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Anchor player */}
                    {/* Start iframe for Imagotv player */}
                    {reduxModalplayer.currentTrack?.platform === 23 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='Imagotv Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Imagotv player */}
                    {/* Start iframe for Rumble player */}
                    {reduxModalplayer.currentTrack?.platform === 24 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='Rumble Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Rumble player */}
                    {/* Start iframe for Hearthis player */}
                    {reduxModalplayer.currentTrack?.platform === 25 && (
                        <iframe
                            allow='autoplay'
                            frameBorder='0'
                            scrolling='no'
                            src={`${reduxModalplayer.currentTrack?.link_id}transparent_black/?hcolor=&color=&style=2&block_size=2&block_space=1&background=1&waveform=0&cover=0&autoplay=0&css=%C2%AB`}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='Hearthis Iframe'
                        />
                    )}
                    {/* End iframe for Hearthis player */}
                    {/* Start iframe for TF1 player */}
                    {reduxModalplayer.currentTrack?.platform === 26 && (
                        <iframe
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                            frameBorder='0'
                            src={reduxModalplayer.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='TF1 Iframe'
                        />
                    )}
                    {/* End iframe for TF1 player */}
                    {/* Start iframe for Kuula player */}
                    {reduxModalplayer.currentTrack?.platform === 27 && (
                        <iframe
                            allow='xr-spatial-tracking; gyroscope; accelerometer'
                            allowFullScreen
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='Kuula Iframe'
                        />
                    )}
                    {/* End iframe for Kuula player */}
                    {/* Start iframe for Matterport player */}
                    {reduxModalplayer.currentTrack?.platform === 28 && (
                        <iframe
                            frameBorder='0'
                            src={reduxModalplayer.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='Matterport Iframe'
                        />
                    )}
                    {/* End iframe for Matterport player */}
                    {/* Start iframe for VideoAsk player */}
                    {reduxModalplayer.currentTrack?.platform === 29 && (
                        <iframe
                            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                            frameBorder='0'
                            src={reduxModalplayer.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='VideoAsk Iframe'
                        />
                    )}
                    {/* End iframe for VideoAsk player */}
                    {/* Start iframe for others */}
                    {!otherPlayers.includes(reduxModalplayer.currentTrack?.platform || 0) && reduxModalplayer.currentTrack?.link_url && (
                        <ReactPlayer
                            controls
                            height='100%'
                            loop={loop}
                            onDuration={onDuration}
                            onError={(e: any) => onError(e)}
                            onEnded={nextTrack ? () => onNext() : undefined}
                            onPause={() => dispatch(reduxModalPlayerOnPlayPause(false))}
                            onPlay={() => dispatch(reduxModalPlayerOnPlayPause(true))}
                            onProgress={onProgress}
                            onReady={onReady}
                            // onStart={onStart}
                            playing={reduxModalplayer.playing}
                            url={(reduxModalplayer.currentTrack?.platform && [17, 20].includes(reduxModalplayer.currentTrack?.platform)) ? reduxModalplayer.currentTrack?.link_id : reduxModalplayer.currentTrack?.link_url}
                            width='100%'
                            config={{
                                // facebook: { facebookConfig },
                                soundcloud: soundcloudConfig,
                                vimeo: vimeoConfig,
                                youtube: youtubeConfig,
                            }}
                        />
                    )}
                    {/* End iframe for others */}
                    {reduxModalplayer.playerPosition === 'bottom' && reduxModalplayer.currentTrack.platform && coverNeededBottomPlatform.includes(reduxModalplayer.currentTrack.platform) && (
                        <div className='mo-player-web-cover'>
                            <ImageHelper
                                alt=''
                                dominant_color={reduxModalplayer.currentTrack.get_dominant_color}
                                src={reduxModalplayer.currentTrack?.get_image_lq || reduxModalplayer.currentTrack?.get_image_lq}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className='mo-player-web-info'>
                <LinkHelper
                    className='mo-player-web-info-name mo-clamp-web1'
                    edit={false}
                    title={reduxModalplayer.currentTrack?.name}
                    to={undefined}
                >
                    {reduxModalplayer.currentTrack?.name}
                </LinkHelper>
            </div>
            <div className='mo-player-web-action'>
                <div className='mo-player-web-action-full'>
                    <IconBlock
                        className='mo-player-web-action-icon-full album'
                        edit={false}
                        disabled={!Boolean(prevAlbumSkipTrack && reduxModalplayer.albumTrackArray && reduxModalplayer.albumTrackArray[(reduxModalplayer.albumTrackIndex || 0) - 1])}
                        iconClass='mo-new-icon-fast-backward-solid'
                        onClick={() => onPrevAlbumSkip()}
                        tooltipText={reduxText[6608]}
                    />
                    <IconBlock
                        className='mo-player-web-action-icon-full'
                        edit={false}
                        disabled={!Boolean(prevTrack)}
                        iconClass='mo-new-icon-step-backward-solid'
                        onClick={() => onPrev()}
                        tooltipText={reduxText[6610]}
                    />
                    <IconBlock
                        className='mo-player-web-action-icon-full mo-player-web-action-icon-full-main'
                        edit={false}
                        iconClass={reduxModalplayer.playing ? 'mo-new-icon-pause-circle-regular' : 'mo-new-icon-play-circle-solid'}
                        onClick={onPlayPause}
                        tooltipText={reduxModalplayer.playing ? reduxText[5886] : reduxText[5887]}
                    />
                    <IconBlock
                        className='mo-player-web-action-icon-full'
                        disabled={!Boolean(nextTrack)}
                        edit={false}
                        iconClass='mo-new-icon-step-forward-solid'
                        onClick={() => onNext()}
                        tooltipText={reduxText[6611]}
                    />
                    <IconBlock
                        className='mo-player-web-action-icon-full album'
                        disabled={!Boolean(nextAlbumSkipTrack && reduxModalplayer.albumTrackArray && reduxModalplayer.albumTrackArray[(reduxModalplayer.albumTrackIndex || 0) + 1])}
                        edit={false}
                        iconClass='mo-new-icon-fast-forward-solid'
                        onClick={() => onNextAlbumSkip()}
                        tooltipText={reduxText[6612]}
                    />
                </div>
            </div>
            <div className='mo-player-web-right'>
                <div className='mo-player-web-range-duration'>
                    <span>{moment.utc(played * 1000).format('HH:mm:ss')}</span>
                    <span> / </span>
                    <span>{moment.utc(duration * 1000).format('HH:mm:ss')}</span>
                </div>
                <div className='mo-player-web-platform-wrap'>
                    <p className='mo-player-web-powered'>{reduxText[5883]}</p>
                    <ImageHelper
                        alt={reduxModalplayer.currentTrack?.name}
                        className='mo-player-web-platform'
                        dominant_color={reduxModalplayer.currentTrack?.get_dominant_color}
                        src={`${media_url_platform}${reduxModalplayer.currentTrack?.platform}.svg${media_version}`}
                    />
                </div>
                <div className='mo-player-web-block-header'>
                    <IconBlock
                        className='mo-player-web-action-icon'
                        edit={false}
                        iconClass={`mo-new-icon-chevron-up-solid ${(reduxModalplayer.playerPosition === 'bottom' ? '' : 'rotate')}`}
                        onClick={() => dispatch(reduxModalPlayerSetPosition(reduxModalplayer.playerPosition === 'bottom' ? 'full' : 'bottom'))}
                        tooltipText={reduxModalplayer.playerPosition === 'bottom' ? reduxText[6609] : reduxText[5646]}
                    />
                    <IconBlock
                        className='mo-player-web-action-icon'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => onDismissPlayer()}
                        tooltipText={reduxText[4742]}
                    />
                </div>
            </div>
            <div className='mo-player-minimize'>
                <IconBlock
                    className='mo-player-web-action-icon'
                    edit={false}
                    iconClass={`mo-new-icon-chevron-up-solid ${(reduxModalplayer.playerPosition === 'bottom' ? '' : 'rotate')}`}
                    onClick={() => dispatch(reduxModalPlayerSetPosition(reduxModalplayer.playerPosition === 'bottom' ? 'full' : 'bottom'))}
                    tooltipText={reduxModalplayer.playerPosition === 'bottom' ? reduxText[6609] : reduxText[5646]}
                />
            </div>
            <div className='mo-player-close'>
                <IconBlock
                    className='mo-player-web-action-icon'
                    edit={false}
                    iconClass='mo-new-icon-times-solid'
                    onClick={() => onDismissPlayer()}
                    tooltipText={reduxText[4742]}
                />
            </div>
            {/* {nextTrack && (
				<div
							className={`XXX ${deviceType}`}
					className='mo-player-web-next'
					onClick={() => setShowTrackList(true)}
				>
					<span
							className={`XXX ${deviceType}`}
					className='mo-player-web-next-name mo-clamp-web1'
					title={nextTrack.name}
					>
					{`Next: ${nextTrack.name}`}
					</span>
					<IconBlock
							className={`XXX ${deviceType}`}
						className='mo-player-web-action-icon-full'
						disabled={false}
						edit={false}
						iconClass='mo-new-icon-chevron-up-solid'
					/>
				</div>
			)} */}
            <IonModal  // TODO IonModal
                isOpen={showTrackList}
                onDidDismiss={() => setShowTrackList(false)}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{`${reduxText[5885]} (${reduxModalplayer.trackArray?.length})`}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                    {reduxModalplayer.trackArray?.map((val) => {
                        return (
                            <IonItem
                                key={val.id}
                                button
                                onClick={() => onNext(val)}
                            >
                                <IonThumbnail>
                                    <ImageHelper
                                        alt={val.name}
                                        className='eci-thumb-web'
                                        dominant_color={val.get_dominant_color}
                                        src={val.get_image_lq}
                                    />
                                </IonThumbnail>
                                <IonLabel>
                                    <h3
                                        className={`${reduxModalplayer.currentTrack?.id === val.id ? 'mo-color' : ''}`}
                                    >
                                        {val.name}
                                    </h3>
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonContent>
                <IonFooter className='button-footer-web'>
                    <Button
                        edit={false}
                        fill='outline'
                        onClick={() => setShowTrackList(false)}
                        text={reduxText[4742]}
                    />
                </IonFooter>
            </IonModal>
            <Snackbar
                action={
                    <Button
                        edit={false}
                        fill='clear'
                        onClick={() => showErrorMessage(false)}
                        size='small'
                        text={reduxText[4742]}
                    />
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                message={reduxText[4775]}
                onClose={() => showErrorMessage(false)}
                open={errorMessage}
            />
        </div>
    )
}
