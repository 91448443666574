// Root URL
const rootPath = 'service/'

// Action
export const api_url_service_action = `${rootPath}action/service/`

// Detail
export const api_url_service_detail = `${rootPath}detail/service/`

// Form Service
export const api_url_service_form_active = `${rootPath}form/service/active/`
export const api_url_service_form_create = `${rootPath}form/service/create/`
export const api_url_service_form_detail = `${rootPath}form/service/detail/`
export const api_url_service_form_duplicate = `${rootPath}form/service/duplicate/`
export const api_url_service_form_update = `${rootPath}form/service/update/`

// Form Child
export const api_url_service_child_form_active = `${rootPath}form/child/active/`
export const api_url_service_child_form_create = `${rootPath}form/child/create/`
export const api_url_service_child_form_detail = `${rootPath}form/child/detail/`
export const api_url_service_child_form_list = `${rootPath}form/child/list/`
export const api_url_service_child_form_patch = `${rootPath}form/child/patch/`
export const api_url_service_child_form_update = `${rootPath}form/child/update/`

// Form Image
export const api_url_service_image_form_active = `${rootPath}form/image/active/`
export const api_url_service_image_form_create = `${rootPath}form/image/create/`
export const api_url_service_image_form_detail = `${rootPath}form/image/detail/`
export const api_url_service_image_form_list = `${rootPath}form/image/list/`
export const api_url_service_image_form_patch = `${rootPath}form/image/patch/`
export const api_url_service_image_form_update = `${rootPath}form/image/update/`

// List
export const api_url_service_image_list = `${rootPath}list/image/`
export const api_url_service_list = `${rootPath}list/service/`

// Map
export const api_url_service_map = `${rootPath}map/service/`
