// react components
import thunk from 'redux-thunk'
import {
    applyMiddleware,
    createStore,
} from 'redux'
import {
    createLogger,
} from 'redux-logger'

// reducers
import createRootReducer from './mo_reducer'

// actions
import * as reduxAuthActionWeb from './redux/auth/mo_actions'
import * as reduxFormActionSite from '../site/redux/form/mo_actions'
import * as reduxFormActionWeb from './redux/form/mo_actions'
import * as reduxModalActionWeb from './redux/modal/mo_actions'
import * as reduxTextActionWeb from './redux/text/mo_actions'

const ignored_actions_type_log = [
    reduxAuthActionWeb.REDUX_AUTH_DEVICE_INFO,
    reduxAuthActionWeb.REDUX_AUTH_FETCH_ACTIONS,
    reduxAuthActionWeb.REDUX_AUTH_FETCH_SETTINGS,
    reduxAuthActionWeb.REDUX_AUTH_SET_API_ROOT_URL,
    reduxFormActionSite.REDUX_FORM_SET_DATA_SITE,
    reduxFormActionSite.REDUX_FORM_SET_HOVERED_BLOCK_ID_SITE,
    reduxFormActionWeb.REDUX_FORM_SET_REFRESH,
    reduxModalActionWeb.REDUX_MODAL_ERROR_SHOW_HIDE,
    reduxModalActionWeb.REDUX_MODAL_SLIDER_SET_DATA,
    reduxTextActionWeb.REDUX_TEXT_FETCH_MAIN_TEXT,
]

export default () => {

    let store
    // if (process.env.NODE_ENV === 'development') {
    if (process.env.NODE_ENV === 'test') {
        store = createStore(
            createRootReducer, {},
            applyMiddleware(
                thunk,
                createLogger({
                    predicate: (getState, action) => !ignored_actions_type_log.includes(action.type),
                }),
            ),
        )
    } else {
        store = createStore(
            createRootReducer, {},
            applyMiddleware(
                thunk,
            ),
        )
    }

    return store
}
