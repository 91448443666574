// react components
import React from 'react'

// props
type PickAnOfferBlockStep3Props = {
    fields: any
    handleInputChange: (e: any) => void
    optionsPriceData: {
        id: string
        description: string
        max_qty?: number
        name: string
        name_id: string
        prices: {
            monthly: {
                id: string
                price: string
            }
            yearly: {
                id: string
                price: string
            }
        }
    }[]
    period: 'monthly' | 'yearly'
}

// main
export const PickAnOfferBlockStep3: React.FC<PickAnOfferBlockStep3Props> = React.memo(({
    fields,
    handleInputChange,
    optionsPriceData,
    period,
}) => {

    return (
        <div className='paob-step3'>
            {optionsPriceData.map(val => (
                <div
                    key={val.id}
                    className='paob-variant-helper'
                >
                    <div className='paob-vh-name'>
                        <span>{val.name}</span>
                        <span className='paob-vh-name-decription pre-line'>{val.description}</span>
                    </div>
                    <div className='paob-vh-variant-price-wrap'>
                        <div className='paob-vh-variant-price'>
                            <span>{val.prices[period].price}{val.id ? ' *' : ''}</span>
                        </div>
                        <div
                            className='paob-vh-input-wrap'
                            style={{
                                visibility: val.id ? 'visible' : 'hidden',
                            }}
                        >
                            <input
                                min={0}
                                max={val.max_qty}
                                name={val.name_id}
                                onChange={(e) => handleInputChange({
                                    name: val.name_id,
                                    value: {
                                        id: val.prices[period].id,
                                        qty: Number(e.target.value) > 0 ? (val.max_qty || Number(e.target.value)) : 0,
                                    },
                                })}
                                type='number'
                                value={fields[val.name_id]?.qty?.toString() || ''}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <p className='paob-vat'>* TVA non applicable, article 261, 7-1° CGI</p>
        </div>
    )
})
