// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    AxiosRequestConfig,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
    IconBlock,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ShippingCostMotherFormState,
} from 'pages'

// serializers
import {
    PortfolioDetailFormSerializer,
    ShippingCostFormState,
    ShippingCostListFormSerializer,
    ShippingServiceHelperSerializer,
    ShippingZoneHelperSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
    getMainErrorMessage,
    getInputValue,
    checkRequiredFields,
    getFormData,
} from 'services'

// props
type ShippingCostPanelProps = {
    currency: PortfolioDetailFormSerializer['currency']
    index: number
    object: ShippingCostListFormSerializer
    service: ShippingServiceHelperSerializer
    zone: ShippingZoneHelperSerializer
}

// main
export const ShippingCostPanel: React.FC<ShippingCostPanelProps> = React.memo(({
    currency,
    index,
    object,
    service,
    zone,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [formMode, setFormMode] = useState(false)
    const [formState, setFormState] = useState<ShippingCostFormState>({
        mother: ShippingCostMotherFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)

    useEffect(() => {
        setInitialState()
    }, [])

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: formState.mother,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    mother: {
                        ...formState.mother,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ShippingCostPanel',
                'handleRequired',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            setFormState({
                ...formState,
                mother: getInputValue({
                    event,
                    formInfo: formState.mother,
                    reduxText,
                })
            })
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                mainError: '',
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ShippingCostPanel',
                'onHandleInputChange',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ShippingCostPanel handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
            })

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = formState.mother.createUrl
            if (formState.mother.fields?.id) {
                axiosMethod = 'put'
                axiosUrl = `${formState.mother.updateUrl}${formState.mother.fields.id}/`
            }
            const formData: any = getFormData(formState.mother)
            formData.append('service', service.id)
            formData.append('zone', zone.id)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setFormMode(false)
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        init: false,
                        isSaving: false,
                        mainError: '',
                    })
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        mother: {
                            ...formState.mother,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'ShippingCostPanel',
                        dispatch,
                        error,
                        formFields: JSON.stringify(formState.mother.fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'ShippingCostPanel',
                'handleSubmit',
            ))
        }
    }

    function handleCancel() {
        try {
            setFormMode(false)
            setInitialState()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ShippingCostPanel',
                'handleCancel',
            ))
        }
    }

    function setInitialState() {
        try {
            const mother = ShippingCostMotherFormState(reduxAuth)
            setFormState({
                mother: {
                    ...mother,
                    fields: object,
                },
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ShippingCostPanel',
                'setInitialState',
            ))
        }
    }

    // Mother
    const errorsMother = formState.mother.errors
    const fieldsMother = formState.mother.fields

    return (
        <div className='shipping-cost-panel-web'>
            <TextInput
                disabled
                error={errorsMother?.min_weight}
                name='min_weight'
                onChange={handleInputChange}
                label={index === 0 ? `${reduxText[8566]} (${service.is_by_price ? currency?.symbol : 'kg'})` : undefined}
                value={fieldsMother?.min_weight || ''}
            />
            <TextInput
                disabled
                error={errorsMother?.max_weight}
                name='max_weight'
                onChange={handleInputChange}
                label={index === 0 ? `${reduxText[8567]} (${service.is_by_price ? currency?.symbol : 'kg'})` : undefined}
                value={fieldsMother?.max_weight || ''}
            />
            <TextInput
                disabled={!formMode}
                error={errorsMother?.price}
                name='price'
                onChange={handleInputChange}
                label={index === 0 ? `${reduxText[8568]} (${currency?.symbol})` : undefined}
                value={fieldsMother?.price || ''}
            />
            <div className={`scpw-action-wrap index-${index}`}>
                {!formMode && (
                    <React.Fragment>
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-pencil-alt-solid'
                            onClick={() => setFormMode(true)}
                            tooltipText={reduxText[5879]}
                        />
                        {/* <IconBlock
                    edit={false}
                    iconClass='mo-new-icon-trash-solid'
                    tooltipText={reduxText[2983]}
                /> */}
                    </React.Fragment>
                )}
                {formMode && (
                    <React.Fragment>
                        <IconBlock
                            disabled={helpers.buttonDisabled}
                            edit={false}
                            iconClass='mo-new-icon-check-solid'
                            onClick={handleRequired}
                            tooltipText={reduxText[4520]}
                        />
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-times-solid'
                            onClick={handleCancel}
                            tooltipText={reduxText[4519]}
                        />
                    </React.Fragment>
                )}
            </div>
            {object.price_conversions?.map(val => (
                <TextInput
                    key={val.id}
                    disabled
                    error={undefined}
                    name=''
                    onChange={handleInputChange}
                    label={index === 0 ? `${reduxText[8568]} (${val.currency?.symbol})` : undefined}
                    value={val.value || ''}
                />
            ))}
        </div>
    )
})
