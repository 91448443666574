// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ProjectPlayButtonMobile,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectListSerializer,
} from 'serializers/web'

// props
type ProjectListSmallBlockMobileProps = {
    edit: boolean
    modalKey: number
    object: ProjectListSerializer
    playerOrigin: string
}

// main
export const ProjectListSmallBlockMobile: React.FC<ProjectListSmallBlockMobileProps> = React.memo(({
    edit,
    modalKey,
    object,
    playerOrigin,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [deleted, setDeleted] = useState(false)

    const count_array: any = {
        1: reduxText[4910],
        8: reduxText[2447],
        4: reduxText[4912],
        5: reduxText[1767],
    }

    return (
        <div
            className='project-list-small-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='project'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <div className='plsb-cover'>
                <LinkHelper
                    className='plsb-cover-wrap'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    <div className='plsb-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='plsb-image'
                            dominant_color={undefined}
                            lazyMobile={false}
                            src={object.get_image_square}
                        />
                    </div>
                    {object.duration !== '00:00:00' && (
                        <p className='plsb-extra-info'>{object.duration}</p>
                    )}
                    {count_array[object.medium_type!] && (
                        <p className='plsb-extra-info'>{`${object.count_project} ${count_array[object.medium_type!]}`}</p>
                    )}
                </LinkHelper>
                {object.is_playable && (
                    <div className='plsb-play-btn'>
                        <ProjectPlayButtonMobile
                            edit={edit}
                            modalKey={modalKey}
                            object={object}
                            playerOrigin={playerOrigin}
                        />
                    </div>
                )}
            </div>
        </div>
    )
})
