// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Loader,
} from 'components'

// customers
import {
    AGSLAdminHome,
    AGSLAdminMenu,
    AGSLAdminModule,
    AGSLAdminMenuProps,
} from 'customers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
} from 'data'

// pages
import {
    NotFoundPage,
} from 'pages'

// services
import {
    getDetailObject,
    getApiUrlV2,
} from 'services'

// props
type MatchParams = {
    pageName: string
    profileId: string
}

// main
export const AGSLAdminWrapper: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        onGetDetailData()
    }, [
        params.profileId,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    const [isLoading, setIsLoading] = useState(false)

    const [errorStatus, setErrorStatus] = useState<number>()
    const [userObject, setUserObject] = useState<AGSLAdminMenuProps['userObject'] | undefined>(undefined)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agsl/detail/user/${reduxAuth.settings?.user?.id}/`, reduxAuth),
                component: 'AGSLAdminWrapper',
                customFunction: !params.pageName ? undefined : (data) => dispatch(reduxModalSeoData(data.seo_data)),
                dispatch,
                reduxAuth,
                setDetailObject: setUserObject,
                setErrorStatus,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AGSLAdminWrapper',
                'onGetDetailData',
            ))
        }
    }

    if (!userObject) {
        return (
            <IonPage className='admin-wrapper navbar-pt'>
                <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                    <Loader isOpen />
                </IonContent>
            </IonPage>
        )
    }

    if (errorStatus || (params.pageName && !userObject.menu.map(item => item.slug).includes(params.pageName))) {
        return <NotFoundPage statusCode={errorStatus} />
    }

    return (
        <IonPage className='admin-wrapper navbar-pt'>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='aw-content'>
                    <div
                        className={`aw-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}
                        style={{
                            padding: '10px',
                        }}
                    >
                        <AGSLAdminMenu
                            userObject={userObject}
                        />
                    </div>
                    <div className='aw-right'>
                        {params.pageName && (
                            <AGSLAdminModule
                                refreshData={onGetDetailData}
                                userObject={userObject}
                            />
                        )}
                        {!params.pageName && <AGSLAdminHome />}
                    </div>
                </div>
                {isLoading && <Loader isOpen />}
            </IonContent>
        </IonPage>
    )
}
