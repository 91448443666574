// react components
import React from 'react'
import Linkify from 'react-linkify'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    LinkHelper,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    ProductListMicroBlockMobile,
} from 'pages'

// serializers
import {
    CityTempSerializer,
    ProjectDetailSerializer,
} from 'serializers/web'

// services
import {
    getDateWithType,
} from 'services'

// props
type DescriptionContentBlockMobileProps = {
    edit: boolean
    object: ProjectDetailSerializer
}

// main
export const DescriptionContentBlockMobile: React.FC<DescriptionContentBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    // rework country to avoid duplicate flags
    const countryArray: CityTempSerializer[] = []
    if (edit && object.address_json && object.address_json.length > 0) {
        const countryArrayTemp: string[] = []
        object.address_json.map((val) => {
            if (val.countryCode && !countryArrayTemp.includes(val.countryCode)) {
                countryArrayTemp.push(val.countryCode)
                countryArray.push(val)
            }
            return false
        })
    }

    // declare cities
    let cityArray: CityTempSerializer[] = []
    if (edit && object.address_json) {
        cityArray = object.address_json || []
    }

    return (
        <div className='description-content-block'>
            <div className='dcb-wrap'>
                <div className='dcb-main-info'>
                    <p className='dcb-date'>{object.date && getDateWithType(reduxAuth, object.date, object.date_type_choice?.id)}</p>
                    {object.date_end && (
                        <p className='dcb-date'>{getDateWithType(reduxAuth, object.date_end, object.date_type_choice?.id)}</p>
                    )}
                </div>
                {object.description && (
                    <div className='dcb-description'>
                        <Linkify>
                            <div className='pre-line'>
                                {object.description}
                            </div>
                        </Linkify>
                    </div>
                )}
                {object.album && (
                    <div className='album-block'>
                        <div className='ab-alfa1'>
                            <LinkHelper
                                className='ab-bravo1'
                                edit={edit}
                                to={object.album.get_absolute_url}
                            >
                                <ImageHelper
                                    alt={object.album.name}
                                    className='ab-charlie1'
                                    dominant_color={undefined}
                                    src={object.album.get_image_lq}
                                />
                            </LinkHelper>
                            <p className='ab-bravo2'>{reduxText[4887]} {object.album.name}</p>
                        </div>
                    </div>
                )}
                {edit && ((countryArray.length > 0) || (cityArray.length > 0)) && (
                    <div className='dcb-bravo1'>
                        <p className='dcb-charlie1'>{reduxText[2292]}</p>
                        <div className='dcb-charlie2'>
                            <div className='dcb-country'>
                                {cityArray.map((val, i) => (
                                    <MainHashtag
                                        key={i}
                                        edit={edit}
                                        label={val.cityName || val.description}
                                    />
                                ))}
                                {countryArray.map((val, i) => (
                                    <MainCountryFlag
                                        key={i}
                                        edit={edit}
                                        namePosition='end'
                                        object={val}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {!edit && ((object.countries && object.countries.length > 0) || (object.cities && object.cities.length > 0)) && (
                    <div className='dcb-bravo1'>
                        <p className='dcb-charlie1'>{reduxText[2292]}</p>
                        <div className='dcb-charlie2'>
                            <div className='dcb-country'>
                                {object.cities?.map((val) => (
                                    <MainHashtag
                                        key={val.id}
                                        edit={edit}
                                        label={val.name}
                                    />
                                ))}
                                {object.countries?.map((val) => (
                                    <MainCountryFlag
                                        key={val.id}
                                        edit={edit}
                                        namePosition='end'
                                        object={val}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {object.languages && object.languages.length > 0 && (
                    <div className='dcb-bravo1'>
                        <p className='dcb-charlie1'>{reduxText[4825]}</p>
                        <div className='dcb-charlie2'>
                            {object.languages.map((val) => <span key={val.id}>{val.name}</span>)}
                        </div>
                    </div>
                )}
                {object.related_products && object.related_products.length > 0 && (
                    <div className='dcb-bravo1'>
                        <p className='dcb-charlie1'>{object.related_products.length > 1 ? reduxText[6598] : reduxText[6599]}</p>
                        <div className='dcb-charlie5'>
                            {object.related_products.map((val) => (
                                <ProductListMicroBlockMobile
                                    key={val.id}
                                    edit={edit}
                                    object={val}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {object.lyrics && (
                    <div className='dcb-bravo1'>
                        <p className='dcb-charlie1'>{reduxText[3251]}</p>
                        <div className='dcb-charlie2'>
                            <span>{object.lyrics}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
})
