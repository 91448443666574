// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonSpinner,
} from '@ionic/react'

// props
type ListSpinnerProps = {
    color?: 'tertiary' | 'primary' | 'secondary'
    isLoading: boolean
}

// main
export const ListSpinner: React.FC<ListSpinnerProps> = React.memo(({
    color,
    isLoading,
}) => {

    const [showSpinner, setShowSpinner] = useState(false)

    let wto: any

    useEffect(() => {
        clearTimeout(wto)
        wto = setTimeout(() => {
            setShowSpinner(true)
        }, 1000)
        return () => {
            clearTimeout(wto)
        }
    }, [])

    if (!showSpinner) return null

    return (
        <div
            className='list-spinner-web'
            style={{
                padding: isLoading ? '10px' : '0px',
            }}
        >
            {isLoading && (
                <IonSpinner
                    color={color || 'primary'}
                />
            )}
        </div>
    )
})
