export function globalHandleInputChange({
    event,
    formInfo,
}: {
    event: any
    formInfo: any
}) {
    const name = event.name
    return {
        ...formInfo,
        errors: {
            ...formInfo.errors,
            [name]: '',
            non_field_errors: '',
        },
        fields: {
            ...formInfo.fields,
            [name]: event.value,
        },
    }
}
