// react components
import React from 'react'

// components
import {
    LinkHelper,
    MainHashtag,
    ProfileShortInfoMobile,
} from 'components'

// serializers
import {
    ProjectDetailSerializer,
    ProjectDetailTeamHelperSerializer,
} from 'serializers/web'

// props
type HeaderProjectDetailMobileProps = {
    edit: boolean
    isWeb?: boolean
    object: ProjectDetailSerializer
}

// main
export const HeaderProjectDetailMobile: React.FC<HeaderProjectDetailMobileProps> = React.memo(({
    edit,
    isWeb,
    object,
}) => {

    const duplicateProfile: any = []
    let creators: ProjectDetailTeamHelperSerializer[] = []
    let labels: ProjectDetailTeamHelperSerializer[] = []
    if (object.team_list?.creator?.length! > 0) {
        creators = object.team_list!.creator!
        object.team_list!.creator!.map((val) => {
            if (val.member) duplicateProfile.push(val.member.id)
            return false
        })
    }
    if (object.team_list?.label?.length! > 0) {
        labels = object.team_list!.label!
        object.team_list!.label!.map((val) => {
            if (val.member) duplicateProfile.push(val.member.id)
            return false
        })
    }

    return (
        <React.Fragment>
            <div className='header-project-detail'>
                <div className='header-project-detail-left'>
                    <LinkHelper
                        className='header-project-detail-title'
                        edit={edit}
                        to={isWeb ? object.get_absolute_url : undefined}
                    >
                        {object.name}
                    </LinkHelper>
                    <div className='header-project-detail-right'>
                        {object.profile && !duplicateProfile.includes(object.profile.id) && (
                            <ProfileShortInfoMobile
                                edit={edit}
                                object={object.profile}
                            />
                        )}
                        {creators.map((val) => (
                            <ProfileShortInfoMobile
                                key={val.id}
                                edit={edit}
                                name={val.member?.name || val.name}
                                object={val.member}
                            />
                        ))}
                        {labels.map((val) => (
                            <div
                                key={val.id}
                                className='pcb-label'
                            >
                                <ProfileShortInfoMobile
                                    edit={edit}
                                    name={val.member?.name || val.name}
                                    object={val.member}
                                />
                            </div>
                        ))}
                    </div>
                    <div className='pcb-tag-section'>
                        {object.themes?.length! > 0 && (
                            <div className='pcb-tag-sub-section'>
                                <p className='pcb-tag-name'>#</p>
                                {object.themes?.map((val) => (
                                    <MainHashtag
                                        key={val.id}
                                        edit={edit}
                                        label={val.name}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})
