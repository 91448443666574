// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// data
import {
    contentTypeFormUrl,
    defaultReduxState,
    mediumTypeCustomPageIds,
    view_url_event_form,
    view_url_material_form,
    view_url_member_form,
    view_url_offer_form,
    view_url_product_form,
    view_url_project_form,
    view_url_service_form,
} from 'data'

// pages
import {
    EditBlockMixedMediaContentBlock,
    EditBlockPageContentPortfolio,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// props
type MatchParams = {
    id: string
    detailId: string
    formType: string
    pageSlug: string
}

type EditBlockSiteProps = {
    customPosition?: any
    hideButtons?: boolean
    isInComponent?: boolean
    mainParentId: number
    object: PortfolioPageContentListSiteSerializer
    parentArray: number[] | undefined
    parentStyles: any
    resetCustomPosition?: any
}

// main
export const EditBlockSite: React.FC<EditBlockSiteProps> = React.memo(({
    customPosition,
    hideButtons,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    resetCustomPosition,
}) => {

    const location = useLocation()
    const params = useParams<MatchParams>()
    const reduxFormpageObjectseo_data = useSelector((state: defaultReduxState) => state.reduxFormSite.pageObject?.seo_data)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)

    if (!mainParentId) return null
    if (!reduxFormSitenavigationMode || ['navPage', 'navMixed'].includes(reduxFormSitenavigationMode!)) return null

    const newMixedMediaContent = reduxFormpageObjectseo_data?.is_detail_page_url_slug_only
    const newMixedMediaContentType = reduxFormpageObjectseo_data?.is_custom_page_content_type_string

    if (['mixed-media', 'mixed-media-content'].includes(params.formType)) {
        return (
            <EditBlockMixedMediaContentBlock
                contentType={contentTypeFormUrl[location.pathname.split('/')[2]]}
                customPosition={customPosition}
                // hideButtons={hideButtons}
                mainParentId={mainParentId}
                newMixedMediaContent={false}
                newMixedMediaContentType={undefined}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
                resetCustomPosition={resetCustomPosition}
            />
        )
    }
    if (location.pathname.split('/')[1] === 'form-mixed-media') {
        return (
            <EditBlockMixedMediaContentBlock
                contentType={contentTypeFormUrl[location.pathname.split('/')[2]]}
                customPosition={customPosition}
                // hideButtons={hideButtons}
                mainParentId={mainParentId}
                newMixedMediaContent
                // @ts-ignore
                newMixedMediaContentType={location.pathname.split('/')[2]}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
                resetCustomPosition={resetCustomPosition}
            />
        )
    }
    if ((mediumTypeCustomPageIds.includes(reduxFormpageObjectseo_data?.medium_type!) || (newMixedMediaContent && newMixedMediaContentType)) && !isInComponent) {
        const custom_page_dict: any = {
            9: view_url_project_form,
            25: view_url_event_form,
            26: view_url_material_form,
            27: view_url_offer_form,
            28: view_url_product_form,
            29: view_url_service_form,
            33: view_url_member_form,
        }
        let mixedMediaFormUrl = ''
        if (newMixedMediaContent) {
            mixedMediaFormUrl = `/form-mixed-media/${newMixedMediaContentType}/${reduxFormpageObjectseo_data.extra_data?.detail_instance_id}/${object.id}/`
        } else {
            mixedMediaFormUrl = `${custom_page_dict[reduxFormpageObjectseo_data!.medium_type!]}${reduxFormpageObjectseo_data?.medium_type_detail_id || params.detailId?.split('-')[0]}/mixed-media-content/${object.id}/`
        }
        return (
            <EditBlockPageContentPortfolio
                customPosition={customPosition}
                hideButtons={hideButtons}
                isInComponent={isInComponent}
                mainParentId={mainParentId}
                mixedMediaFormUrl={mixedMediaFormUrl}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
                resetCustomPosition={resetCustomPosition}
            />
        )
    }
    return (
        <EditBlockPageContentPortfolio
            customPosition={customPosition}
            hideButtons={hideButtons}
            isInComponent={isInComponent}
            mainParentId={mainParentId}
            object={object}
            parentArray={parentArray}
            parentStyles={parentStyles}
            resetCustomPosition={resetCustomPosition}
        />
    )
})
