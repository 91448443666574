// data
import {
    api_url_portfolio_page_content_form_active,
    api_url_portfolio_page_content_form_create,
    api_url_portfolio_page_content_form_detail,
    api_url_portfolio_page_content_form_list,
    api_url_portfolio_page_content_form_patch,
    api_url_portfolio_page_content_form_update,
    reduxAuthState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function PortfolioPageContentFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'content',

        // Urls
        activeUrl: getApiUrl(api_url_portfolio_page_content_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_portfolio_page_content_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_portfolio_page_content_form_detail, reduxAuth),
        listUrl: getApiUrl(api_url_portfolio_page_content_form_list, reduxAuth),
        patchUrl: getApiUrl(api_url_portfolio_page_content_form_patch, reduxAuth),
        updateUrl: getApiUrl(api_url_portfolio_page_content_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: {},

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [],
        requiredForeignKey: [
            'page',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'content_source_detail_id_for_template_only',
            'content_source_list_max',
            'cover_image_alt',
            'cover_image_doka_output_data',
            'cover_image_lq_o_doka_output_data',
            'cover_image_mq_o_doka_output_data',
            'custom_filters',
            'custom_page_link_params',
            'filter_show_all_tab',
            'force_language_filter_cancel',
            'symbol_name',
            'text_long',
            'text_short',
            'text_short2',
            'text_short3',
            'text_short4',
        ],
        formFile: [
            'cover_image',
            'cover_image_lq_o',
            'cover_image_lq_o_original',
            'cover_image_mq_o',
            'cover_image_mq_o_original',
            'cover_image_original',
            'file',
        ],
        formForeignKey: [
            'action',
            'auth_type',
            'content_source',
            'content_source_detail_id',
            'content_source_filter_mode',
            'filter_content_source',
            'filter_content_source_detail_id',
            'filter_field',
            'filter_related_content',
            'module_view',
            'page',
            'page_link',
            'text_short_mode',
            'text_short_mode2',
            'text_short_mode3',
            'text_short_mode4',
        ],
        formJson: [
            'data_json',
            'styles_original',
        ],
        formJsonNull: [
            'address_json',
        ],
        formManyToMany: [
            'portfolio_user_groups',
        ],
    }
}
