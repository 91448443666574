// data
import {
    api_url_shipping_cost_form_create,
    api_url_shipping_cost_form_detail,
    api_url_shipping_cost_form_update,
    reduxAuthState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function ShippingCostMotherFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'mother',

        // Settings
        formComponent: 'ShippingCostPanel',
        model: 'shipping_cost',

        // Urls
        createUrl: getApiUrl(api_url_shipping_cost_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_shipping_cost_form_detail, reduxAuth),
        updateUrl: getApiUrl(api_url_shipping_cost_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'max_weight',
            'min_weight',
            'price',
        ],
        requiredForeignKey: [
            // 'service',
            // 'zone',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'max_weight',
            'min_weight',
            'price',
        ],
        formFile: [],
        formForeignKey: [
            // 'service',
            // 'zone',
        ],
        formJson: [],
        formManyToMany: [],
    }
}
