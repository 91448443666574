// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonContent,
    IonModal,
    IonReorder,
    IonReorderGroup,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    Button,
    ImageHelper,
    InfiniteScrollHelperWeb,
    ListSpinner,
    SelectListInput,
} from 'components'

// data
import {
    api_url_profile_section_content_form_patch_block_template,
    api_url_selection_form_patch,
    defaultReduxState,
    groupingIdsSite,
    reduxFormSetNavigationModeSite,
    reduxModalErrorEventHandler,
    reduxModalLayoutInputHide,
    select_url_block_template_category,
    select_url_main_custom_category,
    select_url_project_layout_block,
    select_url_project_layout_block_preview,
} from 'data'

// pages
import {
    ContentBlockSite,
    LayoutInputListItem,
} from 'pages'

// serializers
import {
    BlockTemplateSelectSerializer,
    BlockTemplateCategorySelectSerializer,
    MainIdNameSerializer,
    PortfolioLayoutBlockPreviewSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailObject,
    getInfiniteListWeb,
    getStylesToRetrieve,
} from 'services'

// main
export const LayoutInputPortfolio: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModal = useSelector((state: defaultReduxState) => state.reduxModal)
    const reduxFormmixedProfileId = useSelector((state: defaultReduxState) => state.reduxForm.mixedProfileId)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const direction = reduxModal.layoutInput.direction
    const forceMixedMediaTemp = reduxModal.layoutInput.forceMixedMediaTemp
    const handleSubmit = reduxModal.layoutInput.handleSubmit
    const isOpen = reduxModal.layoutInput.isOpen
    const mixedMedia = reduxModal.layoutInput.mixedMedia

    const profileId = mixedMedia ? reduxFormmixedProfileId : reduxFormSite.portfolioObject?.profile?.id

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<BlockTemplateSelectSerializer[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [axiosCancelToken2, setAxiosCancelToken2] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll2, setDisableInfiniteScroll2] = useState(true)
    const [hasMore2, setHasMore2] = useState(true)
    const [isLoading2, setIsLoading2] = useState(true)
    const [items2, setItems2] = useState<BlockTemplateSelectSerializer[]>([])
    const [nextUrl2, setNextUrl2] = useState('')

    const [newTemplateContentSource, setNewTemplateContentSource] = useState<BlockTemplateSelectSerializer | undefined>()

    const [availableForSelect, setAvailableForSelect] = useState(false)
    const [blockLevel0Id, setBlockLevel0Id] = useState<'bookmark' | 'latest' | number>('bookmark')
    const [blockLevel1Id, setBlockLevel1Id] = useState<number>()
    const [blockLevel2Id, setBlockLevel2Id] = useState<number>()
    const [blockLevel3Id, setBlockLevel3Id] = useState<number>()
    const [contentCategories, setContentCategories] = useState<BlockTemplateCategorySelectSerializer[]>([])
    const [isReordering, setIsReordering] = useState(false)
    const [previewIsLoading, setPreviewIsLoading] = useState(false)
    const [previewObject, setPreviewObject] = useState<PortfolioLayoutBlockPreviewSerializer | undefined>(undefined)
    const [showBookmarks, setShowBookmarks] = useState(true)
    const [showLatest, setShowLatest] = useState(false)
    const [showRelated, setShowRelated] = useState(false)
    const [submitAlertIsOpen, setSubmitAlertIsOpen] = useState(false)
    const [customCatagoriesValue, setCustomCatagoriesValue] = useState<any>()

    const [fontFamily, setFontFamily] = useState<string>()
    const [linkColor, setLinkColor] = useState<string>()
    const [newPageStyles, setNewPageStyles] = useState<{ [key: string]: string | undefined }>({})

    useEffect(() => {
        if (isOpen) {
            onShowModal()
        } else {
            setAxiosCancelToken(undefined)
            setDisableInfiniteScroll(true)
            setHasMore(true)
            setIsLoading(false)
            setItems([])
            setNextUrl('')
        }
    }, [
        isOpen,
    ])

    useEffect(() => {
        const tempPageStyles: { [key: string]: string | undefined } = {}
        tempPageStyles.fontFamily = fontFamily
        tempPageStyles.margin = reduxModalSite.isOpen ? '0px' : getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.portfolioObject?.styles, '', 'margin')
        setFontFamily(getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.portfolioObject?.styles, '', 'fontFamily'))
        setNewPageStyles(tempPageStyles)
        setLinkColor(getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.pageObject?.styles, '', 'linkColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.portfolioObject?.styles, '', 'linkColor'))
    }, [
        reduxFormSite.pageObject?.styles,
        reduxFormSite.portfolioObject?.styles,
        reduxModalSite,
    ])

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'doReorder',
            ))
        }
    }

    function onCancel() {
        try {
            dispatch(reduxFormSetNavigationModeSite('edit'))
            dispatch(reduxModalLayoutInputHide())
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onCancel',
            ))
        }
    }

    function onCustomCategoryChange(e: { name: string, value: MainIdNameSerializer[] | undefined }) {
        try {
            setShowBookmarks(false)
            setShowLatest(true)
            scroll(1)
            setCustomCatagoriesValue(e.value)
            onGetListData(  // Always call first to avoid render infinite scroll
                getApiUrl(e.value ? `${select_url_project_layout_block}?custom_categories=${e.value.map(item => item.id).join(',')}` : select_url_project_layout_block, reduxAuth),
                true,
                'latest',
            )
            setPreviewObject(undefined)
            setBlockLevel0Id('latest')
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onCustomCategoryChange',
            ))
        }
    }

    function onFilterInputChange(e: any, blockLevel0Id: 'bookmark' | 'latest' | number) {
        try {
            if (e.name === 'level0') {
                setShowBookmarks(false)
                setShowLatest(false)
                setPreviewObject(undefined)
            } else if (e.name === 'bookmark') {
                setShowBookmarks(true)
                setShowLatest(false)
                scroll(1)
                onGetListData(  // Always call first to avoid render infinite scroll
                    getApiUrl(select_url_project_layout_block, reduxAuth),
                    true,
                    'bookmark',
                )
                setPreviewObject(undefined)
            } else if (e.name === 'latest') {
                setShowBookmarks(false)
                setShowLatest(true)
                setCustomCatagoriesValue(undefined)
                scroll(1)
                onGetListData(  // Always call first to avoid render infinite scroll
                    getApiUrl(select_url_project_layout_block, reduxAuth),
                    true,
                    'latest',
                )
                setPreviewObject(undefined)
            }
            setBlockLevel0Id(blockLevel0Id)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onFilterInputChange',
            ))
        }
    }

    function onGetListData(
        getApiUrl: string,
        changingView: boolean,
        special?: 'bookmark' | 'latest',
    ) {
        try {
            let getUrl = getApiUrl
            if (getUrl.includes('?')) {
                getUrl += '&'
            } else {
                getUrl += '?'
            }
            if (special === 'bookmark') {
                getUrl += `bookmark_profile=${profileId}&`
            }
            if (special === 'latest') {
                getUrl += 'latest=true&'
            }
            getInfiniteListWeb({
                apiUrl: getUrl,
                axiosCancelToken,
                changingView,
                component: 'LayoutInputPortfolio',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onGetListData',
            ))
        }
    }

    function onGetListData2(
        getApiUrl: string,
        changingView: boolean,
        sectionId?: number,
    ) {
        try {
            let getUrl = getApiUrl
            if (getUrl.includes('?')) {
                getUrl += '&'
            } else {
                getUrl += '?'
            }
            if (sectionId) getUrl += `section_id=${sectionId}`
            getInfiniteListWeb({
                apiUrl: getUrl,
                axiosCancelToken: axiosCancelToken2,
                changingView,
                component: 'LayoutInputPortfolio',
                customFunction: changingView ? (e: any) => {
                    if (e[0]) {
                        onLayoutSelect(e[0])
                        setBlockLevel3Id(e[0].id)
                    }
                } : undefined,
                dispatch,
                items: items2,
                reduxAuth,
                setAxiosCancelToken: setAxiosCancelToken2,
                setDisableInfiniteScroll: setDisableInfiniteScroll2,
                setHasMore: setHasMore2,
                setIsLoading: setIsLoading2,
                setItems: setItems2,
                setNextUrl: setNextUrl2,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onGetListData2',
            ))
        }
    }

    async function onGetPreviewObject(getLayout: BlockTemplateSelectSerializer | undefined) {
        try {
            if (!getLayout?.id) return
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'LayoutInputPortfolio onGetPreviewObject')
            setPreviewIsLoading(true)

            // let tempGetUrl = ''
            // tempGetUrl = `${select_url_project_layout_block_preview}${getLayout.id}/?object_id=${496}`  // TODO
            // if (profileId) tempGetUrl += `&profile_id=${profileId}`
            const getUrl = getApiUrl(`${select_url_project_layout_block_preview}${getLayout.id}/`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            if (!reduxFormSite.availableContentSources.find((obj) => obj.id === getLayout.content_source)) {
                setAvailableForSelect(false)
            }
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setPreviewObject(response.data)
                    setPreviewIsLoading(false)
                    if (profileId || forceMixedMediaTemp) {
                        const content = response.data.content
                        if (groupingIdsSite.includes(response.data.layout)) {
                            setAvailableForSelect(true)
                        } else if (!content) {
                            setAvailableForSelect(false)
                        } else if (Array.isArray(content) && content.length === 0) {
                            setAvailableForSelect(false)
                        } else {
                            setAvailableForSelect(true)
                        }
                    }
                })
                .catch((error) => {
                    setPreviewIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'LayoutInputPortfolio',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onGetPreviewObject',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onGetPreviewObject',
            ))
        }
    }

    function onGetContentCategory() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(select_url_block_template_category, reduxAuth),
                component: 'LayoutInputPortfolio',
                dispatch,
                reduxAuth,
                setDetailObject: setContentCategories,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onGetContentCategory',
            ))
        }
    }

    function onLayoutSelect(radioValue: any) {
        try {
            setNewTemplateContentSource(radioValue)
            onGetPreviewObject(radioValue)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onLayoutSelect',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onSearchNext',
            ))
        }
    }

    function onSearchNext2(isVisible: boolean) {
        try {
            if (disableInfiniteScroll2) return
            if (isVisible) {
                onGetListData2(nextUrl2, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onSearchNext',
            ))
        }
    }

    function onShowModal() {
        try {
            dispatch(reduxFormSetNavigationModeSite('preview'))
            onGetListData(  // Always call first to avoid render infinite scroll
                getApiUrl(select_url_project_layout_block, reduxAuth),
                true,
                'bookmark',
            )
            onGetContentCategory()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onShowModal',
            ))
        }
    }

    async function onSortEnd(event: any) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'LayoutInputPortfolio onSortEnd')
            const formData: any = new FormData()
            let putUrl = ''
            if (showBookmarks) {
                const item = items[event.detail.from]
                const itemTo = items[event.detail.to]
                setItems(arrayMoveImmutable(items, event.detail.from, event.detail.to))
                formData.append('sibling_id', itemTo.id)
                putUrl = getApiUrl(`${api_url_selection_form_patch}${item.texts?.id}`, reduxAuth)
            } else {
                const item = items2[event.detail.from]
                const itemTo = items2[event.detail.to]
                setItems2(arrayMoveImmutable(items2, event.detail.from, event.detail.to))
                formData.append('sibling_id', itemTo.id)
                putUrl = getApiUrl(`${api_url_profile_section_content_form_patch_block_template}${item.object_id}/`, reduxAuth)
            }
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'LayoutInputPortfolio',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onSortEnd',
            ))
        }
    }

    function onSubmit() {
        try {
            if (availableForSelect) {
                handleSubmit(newTemplateContentSource, showBookmarks, direction)
                onCancel()
            } else {
                setSubmitAlertIsOpen(true)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LayoutInputPortfolio',
                'onSubmit',
            ))
        }
    }

    function scroll(direction: -1 | 1) {
        if (direction === -1) {
            setShowRelated(false)
        } else {
            setShowRelated(true)
        }
        if (document.getElementById('liw-selection-container')) {
            // @ts-ignore
            const far = document.getElementById('liw-selection-container').offsetWidth * direction
            // @ts-ignore
            const pos = document.getElementById('liw-selection-container').scrollLeft + far
            // @ts-ignore
            document.getElementById('liw-selection-container').scrollTo({ top: 0, left: pos, behavior: 'smooth' })
        }
    }

    return (
        <IonModal  // TODO IonModal
            // @ts-ignore
            cssClass={isOpen ? 'layout-form-modal' : ''}
            isOpen={isOpen}
            onDidDismiss={() => onCancel()}
        >
            {isOpen && (
                <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                    <div className='layout-form-modal-wrap'>
                        <div className='liw-left-block'>
                            <div className='liw-left-header'>
                                <div className='liw-left-header-top'>
                                    <p
                                        className={`liw-left-header-text${(showRelated || showBookmarks || showLatest) ? ' pointer' : ''}`}
                                        onClick={(showRelated || showBookmarks || showLatest) ? () => scroll(-1) : undefined}
                                    >
                                        {(showRelated || showBookmarks || showLatest)
                                            ? (
                                                <span className='liw-icon-wrap'><i className='liw-icon mo-new-icon-chevron-left-solid' />{reduxText[8007]}</span>
                                            ) : (
                                                <span>{reduxText[8006]}</span>
                                            )
                                        }
                                    </p>
                                    {(showBookmarks || ([1, 5, 68].includes(reduxAuth.settings?.user?.id!))) && (
                                        <Button
                                            edit={false}
                                            onClick={() => {
                                                setIsReordering(!isReordering)
                                                scroll(1)
                                            }}
                                            size='x-small'
                                            text={isReordering ? reduxText[3563] : reduxText[7762]}
                                        />
                                    )}
                                </div>
                                <div className='liw-left-header-bottom'>
                                    <SelectListInput
                                        apiUrl={`${select_url_main_custom_category}?profile_id=181639`}
                                        clearable={false}
                                        error={undefined}
                                        multi
                                        name='custom_category'
                                        noMarginBottom
                                        onChange={onCustomCategoryChange}
                                        optionsSorted
                                        placeholder={reduxText[3786]}
                                        value={customCatagoriesValue}
                                    />
                                </div>
                            </div>
                            <div
                                id='liw-selection-container'
                                className='liw-select-list-container mo-hidden-horizontal-scrollbar'
                            >
                                <div className={`liw-category-list-wrap mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                                    <div className='liw-category-list-section'>
                                        <span
                                            className={`liw-category-list-title pointer${(blockLevel0Id === 'bookmark') ? ' active' : ''}`}
                                            onClick={() => onFilterInputChange(
                                                {
                                                    name: 'bookmark',
                                                    value: true,
                                                },
                                                'bookmark',
                                            )}
                                        >
                                            {reduxText[2861]}
                                        </span>
                                    </div>
                                    <div className='liw-category-list-section'>
                                        <span
                                            className={`liw-category-list-title pointer${(blockLevel0Id === 'latest') ? ' active' : ''}`}
                                            onClick={() => onFilterInputChange(
                                                {
                                                    name: 'latest',
                                                    value: true,
                                                },
                                                'latest',
                                            )}
                                        >
                                            {reduxText[8464]}
                                        </span>
                                    </div>
                                    {contentCategories.map(val => {
                                        if (!reduxAuth.settings?.user?.is_staff && val.content_source && !reduxFormSite.availableContentSources.find((obj) => obj.id === val.content_source)) return null
                                        return (
                                            <div
                                                key={val.id}
                                                className='liw-category-list-section'
                                            >
                                                <span className={`liw-category-list-title${(blockLevel0Id === val.id) ? ' active' : ''}`}>{val.name}</span>
                                                {val.id === 7
                                                    ? (
                                                        <div className='liw-category-dltype-wrap'>
                                                            <p className='liw-category-name noclick'>{reduxText[8488]}</p>
                                                            {val.children?.filter(obj => obj.dltype === 'l').map(val2 => {
                                                                if (!reduxAuth.settings?.user?.is_staff && val2.medium_type && !reduxFormSite.availableMediumTypes.find((obj) => obj.id === val2.medium_type)) return null
                                                                return (
                                                                    <p
                                                                        key={val2.id}
                                                                        className={`liw-category-name-sub${blockLevel1Id === val2.id ? ' active' : ''}`}
                                                                        onClick={() => {
                                                                            setDisableInfiniteScroll(true)
                                                                            setHasMore(false)
                                                                            setNextUrl('')
                                                                            setItems(val2.children || [])
                                                                            setBlockLevel1Id(val2.id)
                                                                            onFilterInputChange(
                                                                                {
                                                                                    name: 'level0',
                                                                                    value: true,
                                                                                },
                                                                                val.id!,
                                                                            )
                                                                        }}
                                                                    >
                                                                        {val2.name}
                                                                    </p>
                                                                )
                                                            })}
                                                            <p className='liw-category-name noclick'>{reduxText[8489]}</p>
                                                            {val.children?.filter(obj => obj.dltype === 'd').map(val2 => {
                                                                if (!reduxAuth.settings?.user?.is_staff && val2.medium_type && !reduxFormSite.availableMediumTypes.find((obj) => obj.id === val2.medium_type)) return null
                                                                return (
                                                                    <p
                                                                        key={val2.id}
                                                                        className={`liw-category-name-sub${blockLevel1Id === val2.id ? ' active' : ''}`}
                                                                        onClick={() => {
                                                                            setDisableInfiniteScroll(true)
                                                                            setHasMore(false)
                                                                            setNextUrl('')
                                                                            setItems(val2.children || [])
                                                                            setBlockLevel1Id(val2.id)
                                                                            onFilterInputChange(
                                                                                {
                                                                                    name: 'level0',
                                                                                    value: true,
                                                                                },
                                                                                val.id!,
                                                                            )
                                                                        }}
                                                                    >
                                                                        {val2.name}
                                                                    </p>
                                                                )
                                                            })}
                                                        </div>
                                                    ) : (
                                                        val.children?.map(val2 => (
                                                            <p
                                                                key={val2.id}
                                                                className={`liw-category-name${blockLevel1Id === val2.id ? ' active' : ''}`}
                                                                onClick={() => {
                                                                    setDisableInfiniteScroll(true)
                                                                    setHasMore(false)
                                                                    setNextUrl('')
                                                                    setItems(val2.children || [])
                                                                    setBlockLevel1Id(val2.id)
                                                                    onFilterInputChange(
                                                                        {
                                                                            name: 'level0',
                                                                            value: true,
                                                                        },
                                                                        val.id!,
                                                                    )
                                                                }}
                                                            >
                                                                {val2.name}
                                                            </p>
                                                        ))
                                                    )
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className={`liw-select-list-wrap${(showBookmarks || showLatest) ? ' bookmark' : ''} mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                                    <IonReorderGroup
                                        disabled={!isReordering}
                                        onIonItemReorder={doReorder}
                                    >
                                        {items.map((item) => (
                                            <LayoutInputListItem
                                                key={item.id}
                                                active={blockLevel2Id === item.id}
                                                contentType={mixedMedia ? 'mixedmediacontent' : 'pagecontent'}
                                                isReordering={isReordering && showBookmarks}
                                                object={item}
                                                onBookmarkRefresh={() => onGetListData(getApiUrl(select_url_project_layout_block, reduxAuth), true, 'bookmark')}
                                                onClick={() => {
                                                    // @ts-ignore
                                                    if (['bookmark', 'latest'].includes(blockLevel0Id)) {
                                                        onLayoutSelect(item)
                                                    } else {
                                                        onGetListData2(getApiUrl(select_url_project_layout_block, reduxAuth), true, item.section)
                                                        scroll(1)
                                                    }
                                                    setBlockLevel2Id(item.id)
                                                }}
                                                showBookmarks={showBookmarks}
                                                showLatest={showLatest}
                                            />
                                        ))}
                                    </IonReorderGroup>
                                    <InfiniteScrollHelperWeb
                                        active={!disableInfiniteScroll}
                                        color='tertiary'
                                        endText
                                        hasMore={hasMore}
                                        isLoading={isLoading}
                                        items={items}
                                        noEndText
                                        onChange={onSearchNext}
                                    />
                                </div>
                                {(!showBookmarks && !showLatest) && (
                                    <div className={`liw-select-list-wrap mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                                        {showRelated && (
                                            <React.Fragment>
                                                <IonReorderGroup
                                                    disabled={!isReordering}
                                                    onIonItemReorder={doReorder}
                                                >
                                                    {items2.map((item) => {
                                                        return (
                                                            <div
                                                                key={item.id}
                                                                className={`liw-list-block${isReordering ? ' reorder' : ''}`}
                                                                onClick={() => {
                                                                    onLayoutSelect(item)
                                                                    setBlockLevel3Id(item.id)
                                                                }}
                                                            >
                                                                {item.get_image_lq && (
                                                                    <ImageHelper
                                                                        alt=''
                                                                        className={`liw-list-block-image${blockLevel3Id === item.id ? ' active' : ''}`}
                                                                        dominant_color={item.get_dominant_color}
                                                                        src={item.get_image_lq}
                                                                    />
                                                                )}
                                                                {reduxAuth.settings?.user?.id === 1 && !isReordering && (
                                                                    <a
                                                                        className='liw-link'
                                                                        href={`https://demo.justtheweb.io/form/block-template/${item.object_id}/mixed-media-content/${item.id}/`}
                                                                        rel='noopener noreferrer'
                                                                        target='_blank'
                                                                    >
                                                                        Edit
                                                                    </a>
                                                                )}
                                                                <IonReorder slot='end' />
                                                            </div>
                                                        )
                                                    })}
                                                </IonReorderGroup>
                                                <InfiniteScrollHelperWeb
                                                    active={!disableInfiniteScroll2}
                                                    color='tertiary'
                                                    endText
                                                    hasMore={hasMore2}
                                                    isLoading={isLoading2}
                                                    items={items2}
                                                    noEndText
                                                    onChange={onSearchNext2}
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className='liw-left-footer'>
                                <Button
                                    className='liw-footer-button'
                                    edit={false}
                                    onClick={() => onCancel()}
                                    text={reduxText[4519]}
                                />
                                <Button
                                    className='liw-footer-button'
                                    edit={false}
                                    onClick={() => onSubmit()}
                                    text={reduxText[3563]}
                                />
                            </div>
                        </div>
                        <div className='liw-right-block'>
                            <div className='liw-block-preview-wrap'>
                                <div className='liw-block-preview'>
                                    <div
                                        className={`slug-page-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}
                                        style={newPageStyles}
                                    >
                                        <div
                                            className='slug-page-content-list'
                                            style={{
                                                padding: getStylesToRetrieve(reduxModalSite, undefined, reduxFormSite.pageObject?.styles, '', 'padding'),
                                            }}
                                        >
                                            {previewIsLoading
                                                ? (
                                                    <div className='liw-block-preview-spinner'>
                                                        <ListSpinner isLoading color='tertiary' />
                                                    </div>
                                                ) : (
                                                    <React.Fragment>
                                                        {!previewObject && (
                                                            <div className='liw-block-preview-spinner'>
                                                                <p className='liw-select-preview-text'>{reduxText[7684]}</p>
                                                            </div>
                                                        )}
                                                        {(reduxFormSite.portfolioObject) && previewObject && (
                                                            <React.Fragment>
                                                                {((!profileId || availableForSelect) && previewObject.content)
                                                                    ? (
                                                                        <ContentBlockSite
                                                                            detailId={undefined}
                                                                            devId={undefined}
                                                                            isEditHovered={false}
                                                                            linkColor={linkColor}
                                                                            object={previewObject}
                                                                            pageSlug={reduxFormSite.pageObject?.slug!}
                                                                            stylesEdit={undefined}
                                                                        />
                                                                    ) : (
                                                                        <div className='liw-block-preview-spinner'>
                                                                            <p>{reduxText[6587]}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                        )}
                                                        {mixedMedia && previewObject && (
                                                            <React.Fragment>
                                                                {((!profileId || availableForSelect) && previewObject.content)
                                                                    ? (
                                                                        <ContentBlockSite
                                                                            detailId={undefined}
                                                                            devId={undefined}
                                                                            isEditHovered={false}
                                                                            linkColor={linkColor}
                                                                            object={previewObject}
                                                                            pageSlug={reduxFormSite.pageObject?.slug!}
                                                                            stylesEdit={undefined}
                                                                        />
                                                                    ) : (
                                                                        <div className='liw-block-preview-spinner'>
                                                                            <p>{reduxText[6587]}</p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
            )}
            {submitAlertIsOpen && (
                <Alert
                    buttons={[{ text: reduxText[5614] }]}
                    header={profileId ? reduxText[6588] : reduxText[6589]}
                    isOpen
                    onDidDismiss={() => setSubmitAlertIsOpen(false)}
                />
            )}
        </IonModal>
    )
}
