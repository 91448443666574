// react components
import React, {
    useState,
} from 'react'

// components
import {
    LightboxCustom,
    RatioHelper,
} from 'components'

// props
type RatioZarmingHelperProps = {
    borderRadius?: string
    className?: string
    dominant_color: string | undefined
    edit: boolean
    fixedRatioPercentage?: string
    fontSize: number
    fontWeight?: number
    height: number | undefined
    href?: string
    imageContain?: boolean
    lightBox?: boolean
    lightBoxSrc?: string
    name: string | undefined
    notZarma: boolean
    onClick?: () => void
    src: string | undefined
    to: string | undefined
}

// main
export const RatioZarmingHelper: React.FC<RatioZarmingHelperProps> = React.memo(({
    borderRadius,
    className,
    dominant_color,
    edit,
    fixedRatioPercentage,
    fontSize,
    fontWeight,
    height,
    href,
    imageContain,
    lightBox,
    lightBoxSrc,
    name,
    notZarma,
    onClick,
    src,
    to,
}) => {

    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)

    if (notZarma) {
        return (
            <div
                className={`ratio-zarming-helper${className ? ` ${className}` : ''}`}
                style={{
                    height: `${height}px`,
                    width: `${height}px`,
                }}
            >
                <RatioHelper
                    alt={name}
                    borderRadius={borderRadius}
                    dominant_color={dominant_color}
                    edit={edit}
                    fixedRatioPercentage={fixedRatioPercentage}
                    href={href}
                    imageContain={imageContain}
                    onClick={lightBox ? () => setIsLightBoxOpen(true) : onClick}
                    src={src}
                    to={to}
                />
                {lightBox && isLightBoxOpen && (
                    <LightboxCustom
                        mainSrc={lightBoxSrc || src}
                        onCloseRequest={() => setIsLightBoxOpen(false)}
                    />
                )}
            </div>
        )
    }

    const nameSplit = name?.split(' ')
    let nameZarma = ''
    if (nameSplit) {
        nameZarma += nameSplit[0].charAt(0)
        if (nameSplit[1]) nameZarma += ` ${nameSplit[1].charAt(0)}`
    }
    if (height === undefined) {
        return (
            <div
                className={`ratio-zarming-helper no-height${className ? ` ${className}` : ''}`}
                style={{
                    borderRadius: borderRadius,
                }}
            >
                <div className='rzhnh-image-wrap'>
                    <div className='rzhnh-image'>
                        <span
                            className='rzhnh-image-zarma-name'
                            style={{
                                fontSize: `${fontSize}px`,
                                fontWeight: fontWeight,
                            }}
                        >
                            {nameZarma}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div
            className={`ratio-zarming-helper is-zarma${className ? ` ${className}` : ''}`}
            style={{
                borderRadius: borderRadius,
                height: `${height}px`,
                width: `${height}px`,
            }}
        >
            <span
                className='rzh-image-zarma-name'
                style={{
                    fontSize: `${fontSize}px`,
                    fontWeight: fontWeight,
                }}
            >
                {nameZarma}
            </span>
        </div>
    )
})
