// data
import {
    api_url_event_form_active,
    api_url_event_form_create,
    api_url_event_form_detail,
    api_url_event_form_update,
    reduxAuthState,
    view_url_event_detail,
    view_url_event_form,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function EventMotherFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'mother',

        // Settings
        detailComponent: 'EventDetailBlockWeb',
        model: 'event',

        // Urls
        activeUrl: getApiUrl(api_url_event_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_event_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_event_form_detail, reduxAuth),
        formUrl: view_url_event_form,
        updateUrl: getApiUrl(api_url_event_form_update, reduxAuth),
        viewUrl: view_url_event_detail,

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'name',
            'start_date',
        ],
        requiredForeignKey: [
            'profile',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'active',
            'child_text',
            'child_text2',
            'cover_image_alt',
            'cover_image_doka_output_data',
            'cover_image_square_doka_output_data',
            'custom_text',
            'custom_text_2',
            'custom_text_3',
            'custom_text_rich',
            'custom_text_rich_2',
            'custom_text_rich_3',
            'date_only',
            'description',
            'end_date',
            'has_custom_address',
            'is_multiple',
            'is_online_event',
            'is_private',
            'is_private_and_listed_in_portfolio',
            'is_unlisted',
            'meta_image_url',
            'minimum_age',
            'name',
            'price',
            'start_date',
            'ticket_link_url',
        ],
        formFile: [
            'cover_image',
            'cover_image_original',
            'cover_image_square',
        ],
        formForeignKey: [
            'date_type_choice',
            'medium_type',
            'portfolio_detail_page',
            'profile',
        ],
        formJson: [],
        formJsonNull: [
            'address_json',
            'styles_extra',
        ],
        formManyToMany: [
            'custom_categories',
            // 'custom_categories_2',
            // 'custom_categories_3',
            'languages',
            'portfolio_user_groups',
            'profile_sections',
            'projects',
            'services',
            'themes',
        ],
    }
}
