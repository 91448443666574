// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// services
import {
    getDateWithType,
} from 'services'

// props
type CountViewBlockProps = {
    object: {
        created_by?: string
        created_on?: string
        custom_categories?: MainIdNameSerializer[]
        custom_categories_2?: MainIdNameSerializer[]
        custom_categories_3?: MainIdNameSerializer[]
        custom_text?: string
        custom_text_2?: string
        custom_text_3?: string
        custom_text_rich?: string
        custom_text_rich_2?: string
        custom_text_rich_3?: string
        import_data_json?: { [key: string]: any }
        is_private?: boolean
        is_private_and_listed_in_portfolio?: boolean
        is_unlisted?: boolean
        portfolio_user_groups?: MainIdNameSerializer[]
    }
}

// main
export const CountViewBlock: React.FC<CountViewBlockProps> = React.memo(({
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='count-view-block-web'>
            {/* <p className='cvbw-text'>{reduxText[5876]}</p> */}
            <div className='cvbw-created-wrap'>
                {object.created_by
                    ? (
                        <React.Fragment>
                            <span className='cvbw-added'>{reduxText[260]}</span>
                            <span className='cvbw-added'>{object.created_by}</span>
                        </React.Fragment>
                    ) : (
                        <span className='cvbw-added'>{reduxText[1472]}</span>
                    )}
                <span className='cvbw-added'>{getDateWithType(reduxAuth, object.created_on, 'n')}</span>
            </div>
            {object.is_private && (
                <p className='cvbw-small-text'>{reduxText[8547]}{object.is_private_and_listed_in_portfolio ? ` ${reduxText[8575]}` : ''}{object.portfolio_user_groups?.length! > 0 ? ` (${object.portfolio_user_groups?.map((val) => val.name).join(' - ')})` : ''}</p>
            )}
            {object.is_unlisted && (
                <p className='cvbw-small-text'>{reduxText[6579]}</p>
            )}
            {object.custom_categories?.length! > 0 && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[5569]}</p>
                    {object.custom_categories!.map((val) => (
                        <span
                            key={val.id}
                            className='main-hashtag edit'
                        >
                            {val.name}
                        </span>
                    ))}
                </React.Fragment>
            )}
            {object.custom_categories_2?.length! > 0 && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[8723]}</p>
                    {object.custom_categories_2!.map((val) => (
                        <span
                            key={val.id}
                            className='main-hashtag edit'
                        >
                            {val.name}
                        </span>
                    ))}
                </React.Fragment>
            )}
            {object.custom_categories_3?.length! > 0 && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[8731]}</p>
                    {object.custom_categories_3!.map((val) => (
                        <span
                            key={val.id}
                            className='main-hashtag edit'
                        >
                            {val.name}
                        </span>
                    ))}
                </React.Fragment>
            )}
            {object.custom_text && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[6574]}</p>
                    <span className='cvbw-tags'>{object.custom_text}</span>
                </React.Fragment>
            )}
            {object.custom_text_2 && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[8698]}</p>
                    <span className='cvbw-tags'>{object.custom_text_2}</span>
                </React.Fragment>
            )}
            {object.custom_text_3 && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[8791]}</p>
                    <span className='cvbw-tags'>{object.custom_text_3}</span>
                </React.Fragment>
            )}
            {object.custom_text_rich && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[7685]}</p>
                    <div dangerouslySetInnerHTML={{ __html: object.custom_text_rich }} />
                </React.Fragment>
            )}
            {object.custom_text_rich_2 && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[8677]}</p>
                    <div dangerouslySetInnerHTML={{ __html: object.custom_text_rich_2 }} />
                </React.Fragment>
            )}
            {object.custom_text_rich_3 && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[8733]}</p>
                    <div dangerouslySetInnerHTML={{ __html: object.custom_text_rich_3 }} />
                </React.Fragment>
            )}
            {object.import_data_json && Object.keys(object.import_data_json).length > 0 && reduxAuth.settings?.user?.id === 1 && (
                <React.Fragment>
                    <p className='cvbw-small-text'>{reduxText[8189]}</p>
                    <p className='cvbw-tags'>{JSON.stringify(object.import_data_json)}</p>
                </React.Fragment>
            )}
        </div>
    )
})
