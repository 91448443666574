// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DateTimeInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    AdherentDetailFormSerializer,
    AdherentDetailFormSerializerError,
} from 'serializers/web'

// props
type DateMembershipStartAdherentInputProps = {
    errors: AdherentDetailFormSerializerError | undefined
    fields: AdherentDetailFormSerializer
    onChange: any
}

// main
export const DateMembershipStartAdherentInput: React.FC<DateMembershipStartAdherentInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <DateTimeInput
            error={errors?.date_membership_start}
            format='date'
            formatDate='dd/MM/yyyy'
            label={reduxText[8710]}
            name='date_membership_start'
            onChange={onChange}
            value={fields.date_membership_start || null}
        />
    )
})
