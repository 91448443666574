// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorHelper,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    SelectListInputV2,
    TextInputV2,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type AgendaDuQuartierQuartierFormStep4Props = {
    inline?: boolean
    setStep: (step: number) => void
    step: number
    submitOnly?: boolean
}

// main
export const AgendaDuQuartierQuartierFormStep4: React.FC<AgendaDuQuartierQuartierFormStep4Props> = React.memo(({
    inline,
    setStep,
    step,
    submitOnly,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [errors, setErrors] = useState<any>({})
    const [errorsInline, setErrorsInline] = useState<any>({})
    const [fields, setFields] = useState<any>({})
    const [fieldsInline, setFieldsInline] = useState<any>({})
    const [isClosed, setIsClosed] = useState<boolean>(inline || false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [items, setItems] = useState<any[]>([])
    const [showForm, setShowForm] = useState<boolean>(false)

    useEffect(() => {
        if (reduxAuth.settings?.quartier?.id) {
            if (inline && isClosed) return
            onGetDetailData()
        }
    }, [
        isClosed,
        reduxAuth.settings?.quartier?.id,
    ])

    useEffect(() => {
        if (reduxAuth.settings?.quartier?.id) {
            onGetDetailDataInline()
        }
    }, [
        reduxAuth.settings?.quartier?.id,
    ])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/detail/quartier/${reduxAuth.settings?.quartier?.id}/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep4',
                dispatch,
                reduxAuth,
                setDetailObject: setFields,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep4',
                'onGetDetailData',
            ))
        }
    }

    function onGetDetailDataInline() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/list/quartier/asset/${reduxAuth.settings?.quartier?.id}/562/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep4',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep4',
                'onGetDetailDataInline',
            ))
        }
    }

    async function handleInlineDelete(itemId: number) {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuartierFormStep4 handleInlineDelete')
            const deleteUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/update/quartier/asset/${itemId}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setShowForm(false)
                    onGetDetailDataInline()
                    setFieldsInline({})
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'AgendaDuQuartierQuartierFormStep4',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'handleInlineDelete',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep4',
                'handleInlineDelete',
            ))
        }
    }

    function handleInlineRetrieve(itemId: number) {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/detail/quartier/asset/${itemId}/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormStep4',
                dispatch,
                reduxAuth,
                setDetailObject: setFieldsInline,
                setIsLoading,
            })
            setShowForm(true)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep4',
                'handleInlineRetrieve',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep4',
                'handleInputChange',
            ))
        }
    }

    function handleInlneInputChange(event: any) {
        try {
            const name = event.name
            setFieldsInline({ ...fieldsInline, [name]: event.value })
            setErrorsInline({ ...errorsInline, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep4',
                'handleInlneInputChange',
            ))
        }
    }

    async function handleSubmit() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuartierFormStep4 handleSubmit')
            const patchUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/update/quartier/${reduxAuth.settings?.quartier?.id}/`, reduxAuth)
            const formData = new FormData()
            formData.append('custom_foreign_key_1', fields.custom_foreign_key_1?.id || '')
            // if (fields.custom_foreign_key_1?.id === 360) {
            //     formData.append('custom_text_field_7', fields.custom_text_field_7)
            // } else {
            //     formData.append('custom_text_field_7', '')
            // }
            const isStepDone = Boolean(fields.custom_foreign_key_1?.id)
            formData.append('extra_data_json_2', JSON.stringify({
                ...fields.extra_data_json_2,
                quartier_from_steps: {
                    ...fields.extra_data_json_2?.quartier_from_steps,
                    step4: isStepDone,
                }
            }))
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'patch',
                url: patchUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setStep(step + 1)
                    reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: patchUrl,
                        component: 'AgendaDuQuartierQuartierFormStep4',
                        dispatch,
                        error,
                        formFields: JSON.stringify(fields),
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep4',
                'handleInputChange',
            ))
        }
    }

    async function handleSubmitInline() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AgendaDuQuartierQuartierFormStep4 handleSubmitInline')
            let axiostUrl = getApiUrlV2('customer/agendaduquartier/form/entity/create/quartier/asset/', reduxAuth)
            let axiosMethod = 'post'
            if (fieldsInline.id) {
                axiostUrl = getApiUrlV2(`customer/agendaduquartier/form/entity/update/quartier/asset/${fieldsInline.id}/`, reduxAuth)
                axiosMethod = 'put'
            }
            const formData: any = new FormData()
            if (!fieldsInline.name) {
                setErrorsInline({ ...errorsInline, name: 'Ce champ est obligatoire' })
                setIsLoading(false)
                return
            }
            formData.append('name', fieldsInline.name)
            formData.append('content_object_id', reduxAuth.settings?.quartier?.id)
            formData.append('sub_content_type', 562)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiostUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setShowForm(false)
                    onGetDetailDataInline()
                    setFieldsInline({})
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (error.response?.status === 400) {
                        setErrorsInline(error.response.data)
                    } else {
                        axiosErrorHandler({
                            apiUrl: axiostUrl,
                            component: 'AgendaDuQuartierQuartierFormStep4',
                            dispatch,
                            error,
                            formFields: JSON.stringify(fieldsInline),
                            reduxAuth,
                            reference: 'handleSubmitInline',
                        })
                    }
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormStep4',
                'handleInputChange',
            ))
        }
    }
    return (
        <div className={`sct90f-input-block${inline ? (!isClosed ? ' inline open' : ' inline') : ''}`}>
            <div
                className={`sct90f-title-block${inline && isClosed ? ' closed' : ''}`}
                onClick={inline ? () => setIsClosed(!isClosed) : undefined}
            >
                <p className='sct90f-title'>Autoriser la publication de permanences</p>
                {inline && (
                    <div className='sct90f-title-icon'>
                        <i className={`mo-new-icon-check-circle-solid sct90f-status${reduxAuth.settings?.quartier?.extra_data_json_2?.quartier_from_steps?.step4 === true ? '' : ' not-completed'}`} />
                        <div className='sct90f-arrow-wrap'>
                            <i className='mo-new-icon-chevron-down-solid sct90f-arrow' />
                        </div>
                    </div>
                )}
            </div>
            {!isClosed && (
                <div className='sct90f-content'>
                    <div
                        className='sct90f-helper'
                        dangerouslySetInnerHTML={{ __html: reduxText[10010] }}
                    />
                    <SelectListInputV2
                        apiUrl='datapool/select/term/?profile=193057&category=49'
                        clearable
                        name='custom_foreign_key_1'
                        onChange={handleInputChange}
                        profileObject={undefined}
                        value={fields.custom_foreign_key_1 || ''}
                    />
                    <ErrorHelper error={errors?.custom_foreign_key_1} />
                    {fields.custom_foreign_key_1?.id === 360 && (
                        <>
                            <div
                                className='sct90f-helper'
                                dangerouslySetInnerHTML={{ __html: reduxText[10003] }}
                            />
                            {/* <TextInputV2
                                name='custom_text_field_7'
                                onChange={handleInputChange}
                                value={fields.custom_text_field_7 || ''}
                            />
                            <ErrorHelper error={errors?.custom_text_field_7} /> */}
                            <div>
                                <div className='sct90f-add-button-block'>
                                    <Button
                                        edit={false}
                                        fill='clear'
                                        onClick={() => setShowForm(!showForm)}
                                        text='Ajouter une catégorie de permanence'
                                    />
                                </div>
                                {showForm && (
                                    <div className='sct90f-form'>
                                        <TextInputV2
                                            error={errorsInline?.name}
                                            label='Nom'
                                            name='name'
                                            onChange={handleInlneInputChange}
                                            type='text'
                                            value={fieldsInline.name || ''}
                                        />
                                        <Button
                                            edit={false}
                                            onClick={handleSubmitInline}
                                            text='Sauvegarder'
                                        />
                                    </div>
                                )}
                            </div>
                            {items.length > 0 && (
                                <div className='sct90f-table-wrap'>
                                    <table className='sct90f-table'>
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <div className='sct90f-action-wrap'>
                                                            <div
                                                                className='sct90f-action'
                                                                onClick={() => handleInlineRetrieve(item.id)}
                                                            >
                                                                <i className='mo-new-icon-pencil-alt-solid sct90f-action-icon' />
                                                            </div>
                                                            <div
                                                                className='sct90f-action'
                                                                onClick={() => handleInlineDelete(item.id)}
                                                            >
                                                                <i className='mo-new-icon-trash-solid sct90f-action-icon' />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </>
                    )}
                    <div className='sct90f-button-block'>
                        <div>
                            {!submitOnly && step > 1 && (
                                <Button
                                    edit={false}
                                    onClick={() => setStep(step - 1)}
                                    text='Précédent'
                                />
                            )}
                        </div>
                        <div className='sct90f-button-block-end'>
                            {!submitOnly && (
                                <>
                                    <Button
                                        edit={false}
                                        fill='clear'
                                        onClick={() => setStep(step + 1)}
                                        text="Répondre plus tard"
                                    />
                                    <div className='sct90f-button-spacing' />
                                </>
                            )}
                            <Button
                                edit={false}
                                onClick={handleSubmit}
                                text={submitOnly ? 'Sauvegarder' : 'Suivant'}
                            />
                        </div>
                    </div>
                    {isLoading && <Loader isOpen />}
                </div>
            )}
        </div>
    )
})
