// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    LanguageFieldInput,
    PortfolioDetailPageFieldInput,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    CityTempServiceInput,
    DayServiceInput,
    LinkUrlServiceInput,
    MediumTypeServiceInput,
    ProjectServiceInput,
    TimeEndServiceInput,
    TimeStartServiceInput,
} from 'pages'

// serializers
import {
    ServiceFormState,
} from 'serializers/web'

// props
type AdditionalServicePanelProps = {
    formState: ServiceFormState
    handleInputChange: any
}

// main
export const AdditionalServicePanel: React.FC<AdditionalServicePanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdditionalServicePanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <DayServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <TimeStartServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <TimeEndServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CityTempServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <LinkUrlServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <ProjectServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <MediumTypeServiceInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <PortfolioDetailPageFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
                show={fieldsMother.medium_type?.id !== 29}
            />
            <LanguageFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
