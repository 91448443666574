// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    contentTypeModelType,
    reduxAuthState,
    reduxFormHelpersState,
    reduxFormSetMixedProfileIdOpen,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    ContentTypeFormTypes,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
} from 'services'

// main
export async function getFormDetailData({
    axiosCancelToken,
    component,
    contentType,
    dispatch,
    formState,
    helpers,
    noEmpty,
    objectId,
    reduxAuth,
    setAxiosCancelToken,
    setFormState,
    setHelpers,
    setInitialState,
}: {
    axiosCancelToken: CancelTokenSource | undefined
    component: string
    contentType: contentTypeModelType
    dispatch: React.Dispatch<any>
    formState: ContentTypeFormTypes | undefined
    helpers: reduxFormHelpersState
    noEmpty: boolean
    objectId: string
    reduxAuth: reduxAuthState
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>
    setFormState: React.Dispatch<any>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    setInitialState: () => void
}) {
    try {
        if (!noEmpty) {
            setInitialState()
        }
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        setAxiosCancelToken(source)

        const app = contentType.split('_')[0]
        const model = contentType.split('_')[1]
        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getFormDetailData`)

        if (reduxAuth.settings?.active_profile && objectId && (objectId !== 'new')) {
            if (!noEmpty) {
                setHelpers({
                    ...helpers,
                    isLoading: true,
                })
            }
            const getUrl = getApiUrlV2(`${app}/form/${model}/detail/${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            if (process.env.NODE_ENV === 'development') console.log(getUrl, component)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const fieldsToReturn = response.data
                    if (fieldsToReturn.attributions_list?.length > 0) {
                        const attributionsDataArray = response.data.profile?.settings_data_json?.contentTypeSettingsCustom?.[contentType]?.form?.fieldsOptions
                        const attributionsArray: any = []
                        Object.keys(attributionsDataArray).forEach(key => {
                            // Check if the key starts with 'attributions_many_to_many_field_'
                            if (key.startsWith('attributions_many_to_many_field_')) {
                                // Push the value associated with the key into the array
                                attributionsArray.push({
                                    key: key,
                                    attribution_category: attributionsDataArray[key]?.selectUrlParams?.attribution_categories,
                                })
                            }
                        })
                        attributionsArray.map((attribution: any) => {
                            const attributions_list = fieldsToReturn.attributions_list
                            fieldsToReturn[`${attribution.key}`] = attributions_list.filter((obj: any) => obj.attribution_category === attribution.attribution_category)
                        })
                    }
                    setFormState({
                        ...formState,
                        contentTypeSettings: response.data.content_type_settings_json,
                        data: {
                            ...formState?.data,
                            errors: {},
                            fields: response.data,
                        },
                        fieldsForm: response.data.content_type_settings_json.form.fieldsForm,
                        fieldsRequired: response.data.content_type_settings_json.form.fieldsRequired,
                        structure: response.data.content_type_settings_json.form.structure,
                    })
                    setHelpers({
                        ...helpers,
                        init: false,
                        errorStatus: undefined,
                        isLoading: false,
                    })
                    setAxiosCancelToken(undefined)
                    if (response.data.profile) {
                        dispatch(reduxFormSetMixedProfileIdOpen(
                            response.data.profile.id,
                            response.data.mixed_media_fonts ? response.data.mixed_media_fonts.concat(response.data.portfolio_main?.fonts || []) : response.data.mixed_media_fonts,
                            {
                                ...formState,
                                data: {
                                    ...formState?.data,
                                    errors: {},
                                    fields: response.data,
                                },
                            },
                        ))
                    }
                })
                .catch((error) => {
                    if (error.response?.status === 403) {
                        setHelpers({
                            ...helpers,
                            errorStatus: error.response.status,
                            isLoading: false,
                        })
                    } else if (error.response?.status === 404 && error.response?.data?.detail) {
                        setHelpers({
                            ...helpers,
                            errorStatus: error.response.status,
                            isLoading: false,
                        })
                    } else {
                        setHelpers({
                            ...helpers,
                            errorStatus: undefined,
                            isLoading: false,
                        })
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component,
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'getFormDetailData',
                        })
                    }
                })
        } else {
            setInitialState()
        }
    } catch (error) {
        setHelpers({
            ...helpers,
            isLoading: false,
        })
        dispatch(reduxModalErrorEventHandler(
            error,
            component,
            'getFormDetailData',
        ))
    }
}
