// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// props
type FormSeparatorProps = {
    adminOnly?: boolean
    staffOnly?: boolean
    text: string
}

// main
export const FormSeparator: React.FC<FormSeparatorProps> = React.memo(({
    adminOnly,
    staffOnly,
    text,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    if (adminOnly && !reduxAuth.settings?.user?.id) return null
    if (staffOnly && !reduxAuth.settings?.user?.is_staff) return null

    return (
        <div className='form-separator'>
            <p>{text}</p>
        </div>
    )
})
