// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProductDetailFormSerializer,
    ProductDetailFormSerializerError,
} from 'serializers/web'

// props
type ProductLengthProductInputProps = {
    errors: ProductDetailFormSerializerError | undefined
    fields: ProductDetailFormSerializer
    onChange: any
}

// main
export const ProductLengthProductInput: React.FC<ProductLengthProductInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            error={errors?.product_length}
            helperText={reduxText[760410]}
            label={reduxText[7604]}
            name='product_length'
            onChange={onChange}
            optional
            type='number'
            value={fields.product_length || ''}
        />
    )
})
