// react components
import React, {
    useState,
} from 'react'
import {
    IonButtons,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ActionComponent,
    AupouCurrentLocationHelper,
    EditButton,
    IconBlock,
    InactiveButton,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    AdherentActionSheet,
    BlockTemplateActionSheet,
    EventActionSheetWeb,
    MaterialActionSheetWeb,
    MemberActionSheet,
    OfferActionSheetWeb,
    PageTemplateActionSheet,
    PortfolioActionSheet,
    ProductActionSheetWeb,
    ProfileActionSheetWeb,
    ProjectActionSheetWeb,
    SalesOrderActionSheet,
    ServiceActionSheet,
} from 'pages'

import {
    MainPortfolioSerializer,
} from 'serializers/web'

// props
type MainDetailEditBlockWebProps = {
    componentActionSheet?: string
    inModal?: boolean
    model: any
    object: {
        id?: number
        active?: boolean
        get_absolute_url?: string
        hostname?: string
        member?: {
            id?: number
            slug?: string
        }
        name?: string
        project_type?: { id: string, name: string }
        profile?: {
            id?: number
            slug?: string
        }
        owner_info?: {
            content_uuid?: string
            permission?: boolean
        }
        portfolio_main?: MainPortfolioSerializer
        slug?: string
    }
    refreshData: () => void
}

// main
export const MainDetailEditBlockWeb: React.FC<MainDetailEditBlockWebProps> = React.memo(({
    componentActionSheet,
    inModal,
    model,
    object,
    refreshData,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showActionPopover, setShowActionPopover] = useState(false)

    if (!object.id) return null
    if (!componentActionSheet) return null

    const actionComponents: any = {
        ActionComponent,
        AdherentActionSheet,
        BlockTemplateActionSheet,
        EventActionSheetWeb,
        MaterialActionSheetWeb,
        MemberActionSheet,
        OfferActionSheetWeb,
        PageTemplateActionSheet,
        PortfolioActionSheet,
        ProductActionSheetWeb,
        ProfileActionSheetWeb,
        ProjectActionSheetWeb,
        SalesOrderActionSheet,
        ServiceActionSheet,
    }
    const ActionComponentName = actionComponents[componentActionSheet]

    return (
        <React.Fragment>
            <IonButtons slot='end' className='main-detail-edit-block-web'>
                {inModal && (
                    <AupouCurrentLocationHelper />
                )}
                <div className='hcw-nav-preview-link-wrap'>
                    {model !== 'profile' && object.portfolio_main && (
                        <React.Fragment>
                            <a
                                className='hcw-nav-preview-link'
                                href={`https://${object.portfolio_main.hostname}/${object.portfolio_main.detail_page}/${(model === 'profileteam') ? object.member?.id : object.id}-${(model === 'profileteam') ? object.member?.slug : object.slug}${object.active ? '' : `?force_active=${object.owner_info?.content_uuid}`}`}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {reduxText[8434]} {object.portfolio_main.hostname}
                            </a>
                        </React.Fragment>
                    )}
                </div>
                {reduxAuth.settings?.user?.is_staff && (
                    <React.Fragment>
                        <EditButton
                            model={model}
                            object={object}
                            refreshData={refreshData}
                        />
                        <EditButton
                            model={model}
                            object={object}
                        />
                    </React.Fragment>
                )}
                {!object.active && (model !== 'salesorder') && (
                    <InactiveButton
                        detail
                        edit={false}
                        model={model}
                        objectId={object.id}
                        objectName={object.name!}
                        publishDisabled={model === 'portfolio'}
                        setDeleted={undefined}
                    />
                )}
                {reduxAuth.settings?.user?.is_staff && object.portfolio_main && (
                    <IconBlock
                        edit={false}
                        iconClass='mo-new-icon-content'
                        to={`${view_url_portfolio_form}${object.portfolio_main.id}/page-list/`}
                        tooltipText={reduxText[8585]}
                    />
                )}
                {model === 'portfolio' && (
                    <IconBlock
                        edit={false}
                        iconClass='mo-new-icon-link-solid'
                        onClick={() => window.open(`https://${object.hostname}`, '_blank')}
                    />
                )}
                <ActionComponentName
                    edit={false}
                    inModal
                    contentType={model}
                    isInProfileSlug={object.profile?.slug}
                    object={object}
                    refreshData={refreshData}
                    setActivated={undefined}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                />
            </IonButtons>
        </React.Fragment>
    )
})
