// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    Button,
} from 'components'

// data
import {
    api_url_product_form_create_from_project,
    defaultReduxState,
    reduxFormHelpersState,
    reduxModalErrorEventHandler,
    view_url_product_form,
} from 'data'

// serializers
import {
    ProjectFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type ShopProjectPanelProps = {
    formState: ProjectFormState
    helpers: reduxFormHelpersState
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const ShopProjectPanel: React.FC<ShopProjectPanelProps> = React.memo(({
    formState,
    helpers,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    const [buttonDisabled, setButtonDisabled] = useState(false)

    async function handleSubmit() {
        try {
            setButtonDisabled(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ShopProjectPanel handleSubmit')

            setHelpers({
                ...helpers,
                isSaving: true,
            })
            const createUrl = getApiUrl(api_url_product_form_create_from_project, reduxAuth)
            const formData = new FormData()
            formData.append('name', `${mother.fields?.name}`)
            formData.append('profile', `${mother.fields?.profile?.id}`)
            formData.append('related_projects', `${mother.fields?.id}`)
            mother.fields?.themes?.map((val: any) => {
                formData.append('themes', val.id)
                return false
            })
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: createUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setButtonDisabled(false)
                    setHelpers({
                        ...helpers,
                        isSaving: false,
                        mainError: '',
                    })
                    history.push(`${view_url_product_form}${response.data.id}/main/main-panel/`)
                })
                .catch((error) => {
                    setButtonDisabled(false)
                    setHelpers({
                        ...helpers,
                        isSaving: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    axiosErrorHandler({
                        apiUrl: createUrl,
                        component: 'ShopProjectPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                isSaving: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'ShopProjectPanel',
                'handleSubmit',
            ))
        }
    }

    return (
        <React.Fragment>
            <p>{reduxText[6592]}</p>
            <Button
                edit={false}
                disabled={buttonDisabled}
                fill='outline'
                onClick={() => handleSubmit()}
                text={reduxText[2138]}
            />
        </React.Fragment>
    )
})
