// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ImageHelper,
    LinkHelper,
    MainIframeBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// services
import {
    getDateWithType,
} from 'services'

// props
type IframeContentBlockMobileProps = {
    edit: boolean
    newTab?: any
    object: any
}

// main
export const IframeContentBlockMobile: React.FC<IframeContentBlockMobileProps> = React.memo(({
    edit,
    newTab,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isSpecial] = useState(false)

    const cover_image = object.cover_image_mime_type === 'image/gif' ? object.cover_image : object.get_image_lq

    return (
        <React.Fragment>
            {!newTab && (object.is_ok_for_iframe || isSpecial)
                ? (
                    <MainIframeBlock
                        url={object.link_url}
                    />
                ) : (
                    <LinkHelper
                        edit={edit}
                        className='iframe-content-block'
                        href={object.link_url}
                    >
                        <p className='icb-name'>{object.name}</p>
                        <p className='icb-description'>{object.description}</p>
                        <p className='icb-date'>{object.date && getDateWithType(reduxAuth, object.date, object.date_type_choice)}</p>
                        <ImageHelper
                            alt={object.name}
                            className='icb-img'
                            dominant_color={undefined}
                            src={cover_image}
                        />
                        <Button
                            edit={edit}
                            text={reduxText[4909]}
                        />
                    </LinkHelper>
                )}
        </React.Fragment>
    )
})
