// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonTitle,
} from '@ionic/react'
import {
    isMobileOnly,
} from 'react-device-detect'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    FooterMobile,
    HeaderChildren,
    ImageHelper,
    ListSpinner,
} from 'components'

// data
import {
    api_url_about_help_center_category_list,
    api_url_about_help_center_category_profile_list,
    api_url_about_help_center_topic_detail,
    api_url_about_help_center_topic_item_detail,
    defaultReduxState,
    reduxModalContentDetailShow,
    reduxModalErrorEventHandler,
    view_url_about_help_center_topic,
} from 'data'

// serializers
import {
    HelpCenterCategoryListSerializer,
    HelpCenterTopicDetailSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
}

// main
export const HelpCenterTopicAbout: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        getCategoryList()
    }, [
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        if (reduxAuth.settings?.active_profile?.has_premium_help_center) {
            getCategoryProfileList()
        }
    }, [
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile,
    ])

    useEffect(() => {
        getTopicDetail()
    }, [
        params.id,
        reduxAuth.apiRootUrl,
    ])

    const [categories, setCategories] = useState<HelpCenterCategoryListSerializer[]>([])
    const [categoriesProfile, setCategoriesProfile] = useState<HelpCenterCategoryListSerializer[]>([])
    const [categoryOpenId, setCategoryOpenId] = useState<number>(0)
    const [categoryProfileOpenId, setCategoryProfileOpenId] = useState<number>(0)
    const [childrenOpenId, setChildrenOpenId] = useState<number>(0)
    const [childrenProfileOpenId, setChildrenProfileOpenId] = useState<number>(0)
    const [isLoading, setIsLoading] = useState(false)
    const [topicObject, setTopicObject] = useState<HelpCenterTopicDetailSerializer | undefined>(undefined)

    async function getCategoryList() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'HelpCenterTopicAbout getCategoryList')
            const getUrl = getApiUrl(api_url_about_help_center_category_list, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setCategories(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'HelpCenterTopicAbout',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getCategoryList',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HelpCenterTopicAbout',
                'getCategoryList',
            ))
        }
    }

    async function getCategoryProfileList() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'HelpCenterTopicAbout getCategoryProfileList')
            const getUrl = getApiUrl(api_url_about_help_center_category_profile_list, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setCategoriesProfile(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'HelpCenterTopicAbout',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getCategoryList',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HelpCenterTopicAbout',
                'getCategoryList',
            ))
        }
    }

    async function getTopicDetail() {
        try {
            if (params.id) {
                setIsLoading(true)
                const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'HelpCenterTopicAbout getCategoryList')
                const getUrl = getApiUrl(`${api_url_about_help_center_topic_detail}${params.id}`, reduxAuth)
                if (process.env.NODE_ENV === 'development') console.log(getUrl)
                axios({
                    headers: refreshAxiosHeaders,
                    method: 'get',
                    url: getUrl,
                })
                    .then((response) => {
                        setIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setTopicObject(response.data)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component: 'HelpCenterTopicAbout',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'getTopicDetail',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HelpCenterTopicAbout',
                'getTopicDetail',
            ))
        }
    }

    function onCategoryClick(categoryId: number) {
        if (categoryOpenId === categoryId) {
            setCategoryOpenId(0)
            setChildrenOpenId(0)
        } else {
            setCategoryOpenId(categoryId)
            setChildrenOpenId(0)
        }
    }

    function onCategoryProfileClick(categoryId: number) {
        if (categoryProfileOpenId === categoryId) {
            setCategoryProfileOpenId(0)
            setChildrenProfileOpenId(0)
        } else {
            setCategoryProfileOpenId(categoryId)
            setChildrenProfileOpenId(0)
        }
    }

    function onImageClick(obj: any) {
        try {
            dispatch(reduxModalContentDetailShow(
                getApiUrl(api_url_about_help_center_topic_item_detail, reduxAuth),
                'about',
                'HelpCenterTopicItemDetailBlock',
                undefined,
                obj.id,
                topicObject?.items || [],
                'help_center_topic_item',
                undefined,
                undefined,
            ))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'WorkContentBlockWeb',
                'onImageClick',
            ))
        }
    }

    return (
        <IonPage>
            {!isMobileOnly && (
                <HeaderChildren>
                    <IonTitle className='hcw-title'>{reduxText[6382]}</IonTitle>
                </HeaderChildren>
            )}
            <IonContent
                className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}
                forceOverscroll={isMobileOnly}
                fullscreen={isMobileOnly}
            >
                <div className='help-center-topic-about-web'>
                    <div className={`hctaw-menu mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {categoriesProfile.length > 0 && (
                            <React.Fragment>
                                <p className='hctaw-title'>{reduxText[8193]}</p>
                                <IonList>
                                    {categoriesProfile.map((category) => (
                                        <React.Fragment key={category.id}>
                                            <IonItem
                                                button
                                                className={`hctaw-category-item ${categoryProfileOpenId === category.id ? 'open' : ''}`}
                                                detail
                                                onClick={() => onCategoryProfileClick(category.id)}
                                            >
                                                <IonLabel className={`hctaw-category-label ${categoryProfileOpenId === category.id ? 'open' : ''}`}>{category.name}</IonLabel>
                                            </IonItem>
                                            {category.children.map((children) => (
                                                <React.Fragment key={children.id}>
                                                    <IonItem
                                                        button
                                                        className={`hctaw-children-item ${childrenProfileOpenId === children.id ? 'open' : ''}`}
                                                        onClick={() => childrenProfileOpenId === children.id ? setChildrenProfileOpenId(0) : setChildrenProfileOpenId(children.id)}
                                                        style={{
                                                            display: categoryProfileOpenId === category.id ? undefined : 'none',
                                                        }}
                                                    >
                                                        <IonLabel className={`hctaw-children-label ${childrenProfileOpenId === children.id ? 'open' : ''}`}>{children.name}</IonLabel>
                                                    </IonItem>
                                                    {children.topics.map((topic) => (
                                                        <IonItem
                                                            key={topic.id}
                                                            button
                                                            className='hctaw-topic-item'
                                                            routerLink={`${view_url_about_help_center_topic}${topic.id}/`}
                                                            style={{
                                                                display: childrenProfileOpenId === children.id ? undefined : 'none',
                                                            }}
                                                        >
                                                            <IonLabel className={`hctaw-topic-label ${topic.id === topicObject?.id ? 'active' : ''}`}>{topic.name}</IonLabel>
                                                        </IonItem>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </IonList>
                                <p className='hctaw-title'>{reduxText[8194]}</p>
                            </React.Fragment>
                        )}
                        <IonList>
                            {categories.map((category) => (
                                <React.Fragment key={category.id}>
                                    <IonItem
                                        button
                                        className={`hctaw-category-item ${categoryOpenId === category.id ? 'open' : ''}`}
                                        detail
                                        onClick={() => onCategoryClick(category.id)}
                                    >
                                        <IonLabel className={`hctaw-category-label ${categoryOpenId === category.id ? 'open' : ''}`}>{category.name}</IonLabel>
                                    </IonItem>
                                    {category.children.map((children) => (
                                        <React.Fragment key={children.id}>
                                            <IonItem
                                                button
                                                className={`hctaw-children-item ${childrenOpenId === children.id ? 'open' : ''}`}
                                                onClick={() => childrenOpenId === children.id ? setChildrenOpenId(0) : setChildrenOpenId(children.id)}
                                                style={{
                                                    display: categoryOpenId === category.id ? undefined : 'none',
                                                }}
                                            >
                                                <IonLabel className={`hctaw-children-label ${childrenOpenId === children.id ? 'open' : ''}`}>{children.name}</IonLabel>
                                            </IonItem>
                                            {children.topics.map((topic) => (
                                                <IonItem
                                                    key={topic.id}
                                                    button
                                                    className='hctaw-topic-item'
                                                    routerLink={`${view_url_about_help_center_topic}${topic.id}/`}
                                                    style={{
                                                        display: childrenOpenId === children.id ? undefined : 'none',
                                                    }}
                                                >
                                                    <IonLabel className={`hctaw-topic-label ${topic.id === topicObject?.id ? 'active' : ''}`}>{topic.name}</IonLabel>
                                                </IonItem>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </IonList>
                    </div>
                    <div className={`hctaw-detail mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {isLoading && (<ListSpinner isLoading />)}
                        {topicObject && (
                            <div className='hctaw-topic-wrap'>
                                <h1 className='hctaw-topic-name'>{topicObject.name}</h1>
                                <div
                                    className='hctaw-topic-description'
                                    dangerouslySetInnerHTML={{ __html: topicObject.description }}
                                />
                                {topicObject.items.map((val, i) => (
                                    <div
                                        key={val.id}
                                        className='hctaw-topic-item-wrap'
                                    >
                                        <div className='hctaw-topic-item-div'>
                                            <div className='hctaw-description-wrap'>
                                                <span className='hctaw-topic-item-number'>{i + 1}.</span>
                                                <div
                                                    className='hctaw-topic-item-description'
                                                    dangerouslySetInnerHTML={{ __html: val.description }}
                                                />
                                            </div>
                                            <ImageHelper
                                                alt=''
                                                className='hctaw-topic-item-image'
                                                dominant_color={undefined}
                                                onClick={() => onImageClick(val)}
                                                src={val.get_image_lq}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </IonContent>
            <FooterMobile />
        </IonPage>
    )
}
