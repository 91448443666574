// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_portfolio_user_group_portfolio,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// props
type PortfolioUserGroupsFieldInputProps = {
    disabled?: boolean
    errors: { portfolio_user_groups?: string } | undefined
    fields: { is_private?: boolean, portfolio_user_groups?: MainIdNameSerializer[] }
    label?: string
    onChange: any
    profileId: number | undefined
    show?: boolean
}

// main
export const PortfolioUserGroupsFieldInput: React.FC<PortfolioUserGroupsFieldInputProps> = React.memo(({
    disabled,
    errors,
    fields,
    show = true,
    label,
    onChange,
    profileId,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!show || !profileId) return null

    return (
        <SelectListInput
            apiUrl={`${select_url_portfolio_user_group_portfolio}?profile=${profileId}`}
            clearable
            disabled={disabled}
            error={errors?.portfolio_user_groups}
            label={label || reduxText[8718]}
            multi
            name='portfolio_user_groups'
            onChange={onChange}
            optional
            value={fields.portfolio_user_groups || ''}
        />
    )
})
