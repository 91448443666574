// react components
import React from 'react'
import axios from 'axios'

// data
import {
    contentTypeData,
    contentTypeModelType,
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
} from 'services'

// main
export async function actionFunction({
    component,
    contentType,
    contentTypeId,
    dispatch,
    extraParams,
    function_name,
    objectId,
    reduxAuth,
    refreshData,
    setIsLoading,
}: {
    component: string
    contentType?: contentTypeModelType
    contentTypeId?: number
    dispatch: React.Dispatch<any>
    extraParams?: {
        [key: string]: string | number
    }
    function_name: string
    objectId: number
    reduxAuth: reduxAuthState
    refreshData?: () => void
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
}) {

    if (setIsLoading) setIsLoading(true)
    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} actionFunction`)
    const formData: any = new FormData()
    formData.append('content_type_id', contentTypeId || contentTypeData[contentType!])
    formData.append('function_name', function_name)
    formData.append('object_id', String(objectId))
    if (extraParams) {
        formData.append('extra_params', JSON.stringify(extraParams))
    }
    const postUrl = getApiUrlV2(`main/helper/action_function/`, reduxAuth)
    axios({
        data: formData,
        headers: refreshAxiosHeaders,
        method: 'post',
        url: postUrl,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            if (refreshData) refreshData()
            if (setIsLoading) setIsLoading(false)
        })
        .catch((error) => {
            if (setIsLoading) setIsLoading(false)
            axiosErrorHandler({
                apiUrl: postUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'actionFunction',
            })
        })
}
