// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useLocation,
} from 'react-router-dom'

// components
import {
    BackgroundImageHelper,
    Button,
    ErrorHelper,
    Loader,
    TextInput,
} from 'components'

// data
import {
    api_url_auth_password_reset_confirm,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_auth_login,
    view_url_profile_detail_old,
} from 'data'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export const PasswordResetConfirmPage: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type errorsType = {
        non_field_errors?: string
        password2?: string
        password?: string
    }
    type fieldsType = {
        password2: string
        password: string
    }
    const errorsInitial = {
        non_field_errors: '',
        password2: '',
        password: '',
    }
    const fieldsInitial = {
        password2: '',
        password: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })  // should be after setFields to avoid bug on TextArea
            setButtonDisabled(false)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PasswordResetConfirmPage',
                'handleInputChange',
            ))
        }
    }

    async function onSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PasswordResetConfirmPage onSubmit')
            if (fields.password !== fields.password2) {
                setButtonDisabled(true)
                setErrors({ ...errors, password2: reduxText[3565] })
            } else {
                setButtonDisabled(true)
                setIsLoading(true)

                const formData = new FormData()
                formData.append('uid', location.hash.split('/')[2])
                formData.append('token', location.hash.split('/')[3])
                formData.append('new_password', fields.password)
                const createUrl = getApiUrl(api_url_auth_password_reset_confirm, reduxAuth)
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'post',
                    url: createUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setFields(fieldsInitial)
                        setIsLoading(false)
                        setIsSuccess(true)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (error.response) {
                            setErrors(error.response && error.response.data)
                        }
                        axiosErrorHandler({
                            apiUrl: createUrl,
                            component: 'PasswordResetConfirmPage',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onSubmit',
                        })
                    })
            }
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'PasswordResetConfirmPage',
                'onSubmit',
            ))
        }
    }

    if (reduxAuth.authenticated && reduxAuth.settings?.active_profile) {
        if (reduxAuth.afterAuthUrl) {
            return <Redirect to={reduxAuth.afterAuthUrl} />
        }
        return <Redirect to={`${view_url_profile_detail_old}${reduxAuth.settings?.active_profile?.slug}/`} />
    }

    return (
        <IonPage className='login-page-web navbar-pt'>
            <Loader isOpen={isLoading} />
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='lpw-content'>
                    {isSuccess && (
                        <div className='lpw-left auth-global-block-web'>
                            <h1 className='lpw-title'>{reduxText[3568]}</h1>
                            <div className='success-message2 pre-line'>
                                <p>{reduxText[5893]}</p>
                            </div>
                            <Button
                                edit={false}
                                fill='outline'
                                text={reduxText[5894]}
                                to={view_url_auth_login}
                            />
                        </div>
                    )}
                    {!isSuccess && (
                        <div className='lpw-left auth-global-block-web'>
                            <h1 className='lpw-title'>{reduxText[3568]}</h1>
                            <TextInput
                                error={errors.password}
                                helperText={reduxText[253510]}
                                label={reduxText[2535]}
                                name='password'
                                onChange={handleInputChange}
                                passwordEye
                                type='password'
                                value={fields.password}
                            />
                            <TextInput
                                error={errors.password2}
                                helperText={reduxText[281410]}
                                label={reduxText[2814]}
                                name='password2'
                                onChange={handleInputChange}
                                passwordEye
                                type='password'
                                value={fields.password2}
                            />
                            <ErrorHelper error={errors.non_field_errors} />
                            <Button
                                disabled={buttonDisabled}
                                edit={false}
                                onClick={onSubmit}
                                text={reduxText[3568]}
                            />
                        </div>
                    )}
                    <BackgroundImageHelper
                        className='lpw-right'
                        dominant_color={reduxText[620900]}
                        src={reduxText[6209]}
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}
