// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
    useParams,
} from 'react-router-dom'

// containers
import {
    MainFormContainerWrapper,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AdditionalProjectChildPanel,
    EclinkProjectChildPanel,
    MainProjectChildPanel,
    MenuProjectChildPanel,
    PortfolioProjectChildPanel,
    ProjectChildEclinkFormState,
    ProjectChildMotherFormState,
    ProjectChildTeamFormState,
    TeamProjectChildPanel,
} from 'pages'

// serializers
import {
    ProjectChildFormState,
} from 'serializers/web'

// services
import {
    getDetailData,
    getInputValue,
    mainFormGetMediaData,
} from 'services'

// consts
import {
    requiredFields,
} from './consts'

// props
type MatchParams = {
    id: string
    albumId?: string
    formType: string
    panelValue?:
    'additional-panel' |
    'link-panel' |
    'main-panel' |
    'team-panel' |
    'website-related-panel'
}

type LocationParams = {
    formRefresh?: number
}

// main
export const ProjectChildFormWeb: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<ProjectChildFormState>({
        mother: ProjectChildMotherFormState(reduxAuth),
        eclink: ProjectChildEclinkFormState(reduxAuth),
        team: ProjectChildTeamFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)
    const [getMetaIsLoading, setGetMetaIsLoading] = useState(false)

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailData({
            axiosCancelToken,
            component: 'ProjectChildFormWeb',
            dispatch,
            formRefresh: location.state?.formRefresh,
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    function setInitialState() {
        const mother = ProjectChildMotherFormState(reduxAuth)
        setFormState({
            mother: {
                ...mother,
                fields: {
                    date_type_choice: {
                        id: 'd',
                        name: reduxText[4832],
                    },
                    profile: !reduxAuth.settings?.user?.is_staff ? reduxAuth.settings?.active_profile : undefined,
                },
            },
            eclink: ProjectChildEclinkFormState(reduxAuth),
            team: ProjectChildTeamFormState(reduxAuth),
        })
    }

    let wto: any

    function handleInputChange(event: any, formInfo: any) {
        try {
            const mother = formState.mother
            const team = formState.team
            if (formInfo.name === 'mother' && event.name === 'profile' && mother) {
                const toReturn = {
                    ...mother,
                    fields: {
                        ...mother.fields,
                        profile: event.value,
                    },
                    errors: {
                        ...mother.errors,
                        profile: '',
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '',
                })
            } else if (formInfo.name === 'mother' && event.name === 'link_url' && !mother.fields?.skip_data_retrieve && mother) {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
                clearTimeout(wto)
                wto = setTimeout(() => {
                    mainFormGetMediaData({
                        formInfo: mother,
                        formState,
                        helpers,
                        image_only: false,
                        reduxAuth,
                        reduxText,
                        setFormState,
                        setGetMetaIsLoading,
                        setHelpers,
                        urltoParse: event.value,
                    })
                }, 1000)
            } else if (formInfo.name === 'mother' && event.name === 'styles_original' && mother) {
                const toReturn = {
                    ...mother,
                    errors: {
                        ...mother.errors,
                        styles_original: '',
                    },
                    fields: {
                        ...mother.fields,
                        styles: event.preview,
                        styles_original: event.value,
                    },
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'mixedMediaContent' && event.name === 'styles_original' && mother) {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
            } else if (formInfo.name === 'mother' && event.name === 'medium_type' && event.value.id && mother) {
                const toReturn = {
                    ...mother,
                    errors: {
                        ...mother.errors,
                        medium_type: '',
                    },
                    fields: {
                        ...mother.fields,
                        medium_type: event.value,
                    },
                    requiredAllFields: requiredFields[event.value.id].requiredAllFields,
                    requiredAttribute: requiredFields[event.value.id].requiredAttribute,
                    requiredForeignKey: requiredFields[event.value.id].requiredForeignKey,
                    requiredManyToMany: requiredFields[event.value.id].requiredManyToMany,
                }
                setFormState({
                    ...formState,
                    mother: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                    scrollToTop: true,
                })
            } else if (formInfo.name === 'team' && event.name === 'name' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: '',
                        name: event.value,
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else if (formInfo.name === 'team' && event.name === 'member' && team) {
                const toReturn = {
                    ...team,
                    fields: {
                        ...team.fields,
                        member: event.value,
                        name: '',
                    },
                    errors: {
                        ...team.errors,
                        member: '',
                        name: '',
                    },
                    requiredForeignKey: [
                        // 'profile', Using mother.fields.id
                        'member',
                    ],
                }
                setFormState({
                    ...formState,
                    team: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '', // TO_CHECK
                })
            } else {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProjectChildFormWeb',
                'handleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = formState.mother.fields

    // Panels
    const panelValue = params.panelValue
    const panelText: any = {
        'additional-panel': reduxText[3234],
        'delete-panel': reduxText[2983],
        'link-panel': reduxText[7431],
        'main-panel': reduxText[347],
        'team-panel': reduxText[4917],
        'website-related-panel': reduxText[6415],
    }

    return (
        <MainFormContainerWrapper
            formState={formState}
            headerTitle={panelValue ? panelText[panelValue] : ''}
            helpers={helpers}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            {panelValue === 'main-panel' && (
                <MainProjectChildPanel
                    formState={formState}
                    getMetaIsLoading={getMetaIsLoading}
                    handleInputChange={handleInputChange}
                />
            )}
            {panelValue === 'additional-panel' && fieldsMother?.id && (
                <AdditionalProjectChildPanel
                    formState={formState}
                    handleInputChange={handleInputChange}
                />
            )}
            {panelValue === 'link-panel' && fieldsMother?.id && (
                <EclinkProjectChildPanel
                    formState={formState}
                    handleInputChange={handleInputChange}
                    helpers={helpers}
                    setFormState={setFormState}
                    setHelpers={setHelpers}
                />
            )}
            {panelValue === 'website-related-panel' && fieldsMother?.id && (
                <PortfolioProjectChildPanel
                    formState={formState}
                    handleInputChange={handleInputChange}
                />
            )}
            {panelValue === 'team-panel' && fieldsMother?.id && (
                <TeamProjectChildPanel
                    formState={formState}
                    handleInputChange={handleInputChange}
                    helpers={helpers}
                    setFormState={setFormState}
                    setHelpers={setHelpers}
                />
            )}
            {!panelValue && (
                <MenuProjectChildPanel
                    formState={formState}
                    helpers={helpers}
                    setHelpers={setHelpers}
                />
            )}
        </MainFormContainerWrapper>
    )
}
