// Root URL
const rootPath = 'userprofile/'

// Action
export const api_url_member_action = `${rootPath}action/team/`
export const api_url_profile_action = `${rootPath}action/profile/`

// Detail
export const api_url_member_detail = `${rootPath}detail/team/`
export const api_url_profile_about_detail = `${rootPath}detail/tab/about/`
export const api_url_profile_admin_detail = `${rootPath}detail/profile/admin/`
export const api_url_profile_staff_detail = `${rootPath}detail/profile/staff/`
export const api_url_profile_detail = `${rootPath}detail/profile/profile/`
export const api_url_userprofile_profile_detail_short = `${rootPath}detail/profile/short/`
export const api_url_profile_detail_short_slug = `${rootPath}detail/profile/short/`
export const api_url_profile_id_detail = `${rootPath}detail/profile/profile_id/`
export const api_url_profile_section_detail = `${rootPath}detail/section/`

// Form Profile
export const api_url_profile_form_active = `${rootPath}form/profile/active/`
export const api_url_profile_form_create = `${rootPath}form/profile/create/`
export const api_url_profile_form_detail = `${rootPath}form/profile/detail/`
export const api_url_profile_form_duplicate = `${rootPath}form/profile/duplicate/`
export const api_url_profile_form_permission = `${rootPath}form/profile/permission/`
export const api_url_profile_form_update = `${rootPath}form/profile/update/`

// Form Carte category
export const api_url_profile_carte_category_form_active = `${rootPath}form/carte_category/active/`
export const api_url_profile_carte_category_form_create = `${rootPath}form/carte_category/create/`
export const api_url_profile_carte_category_form_detail = `${rootPath}form/carte_category/detail/`
export const api_url_profile_carte_category_form_list = `${rootPath}form/carte_category/list/`
export const api_url_profile_carte_category_form_patch = `${rootPath}form/carte_category/patch/`
export const api_url_profile_carte_category_form_update = `${rootPath}form/carte_category/update/`

// Form Carte item
export const api_url_profile_carte_item_form_active = `${rootPath}form/carte_item/active/`
export const api_url_profile_carte_item_form_create = `${rootPath}form/carte_item/create/`
export const api_url_profile_carte_item_form_detail = `${rootPath}form/carte_item/detail/`
export const api_url_profile_carte_item_form_list = `${rootPath}form/carte_item/list/`
export const api_url_profile_carte_item_form_patch = `${rootPath}form/carte_item/patch/`
export const api_url_profile_carte_item_form_update = `${rootPath}form/carte_item/update/`

// Form Eclink
export const api_url_profile_eclink_form_active = `${rootPath}form/eclink/active/`
export const api_url_profile_eclink_form_create = `${rootPath}form/eclink/create/`
export const api_url_profile_eclink_form_detail = `${rootPath}form/eclink/detail/`
export const api_url_profile_eclink_form_list = `${rootPath}form/eclink/list/`
export const api_url_profile_eclink_form_patch = `${rootPath}form/eclink/patch/`
export const api_url_profile_eclink_form_update = `${rootPath}form/eclink/update/`

// Form Image
export const api_url_profile_image_form_active = `${rootPath}form/image/active/`
export const api_url_profile_image_form_create = `${rootPath}form/image/create/`
export const api_url_profile_image_form_detail = `${rootPath}form/image/detail/`
export const api_url_profile_image_form_list = `${rootPath}form/image/list/`
export const api_url_profile_image_form_patch = `${rootPath}form/image/patch/`
export const api_url_profile_image_form_update = `${rootPath}form/image/update/`

// Form Section
export const api_url_profile_section_form_create = `${rootPath}form/profile_section/create/`
export const api_url_profile_section_form_create_new = `${rootPath}form/profile_section/create_new/`
export const api_url_profile_section_form_detail = `${rootPath}form/profile_section/detail/`
export const api_url_profile_section_form_detail_ordering = `${rootPath}form/profile_section/detail/ordering/`
export const api_url_profile_section_form_list = `${rootPath}form/profile_section/list/`
export const api_url_profile_section_form_list_popup = `${rootPath}form/profile_section/list_popup/`
export const api_url_profile_section_form_patch = `${rootPath}form/profile_section/patch/`
export const api_url_profile_section_form_patch_child = `${rootPath}form/profile_section/patch/child/`
export const api_url_profile_section_form_patch_child_child = `${rootPath}form/profile_section/patch/child_child/`
export const api_url_profile_section_form_update = `${rootPath}form/profile_section/update/`
export const api_url_profile_section_form_update_active_in_profile_home = `${rootPath}form/profile_section/update/active_in_profile_home/`
export const api_url_profile_section_form_update_ordering = `${rootPath}form/profile_section/update/ordering/`

// Form Section Content
export const api_url_profile_section_content_form_create = `${rootPath}form/profile_section_content/create/`
export const api_url_profile_section_content_form_list = `${rootPath}form/profile_section_content/list_new/`
export const api_url_profile_section_content_form_list_old = `${rootPath}form/profile_section_content/list/`
export const api_url_profile_section_content_form_patch = `${rootPath}form/profile_section_content/patch/`
export const api_url_profile_section_content_form_patch_block_template = `${rootPath}form/profile_section_content/patch_block_template/`
export const api_url_profile_section_content_form_update = `${rootPath}form/profile_section_content/update/`

// Form Selection
export const api_url_selection_form_create_staff = `${rootPath}form/selection/create_staff/`
export const api_url_selection_form_create = `${rootPath}form/selection/create/`
export const api_url_selection_form_detail = `${rootPath}form/selection/detail/`
export const api_url_selection_form_patch = `${rootPath}form/selection/patch/`
export const api_url_selection_form_update = `${rootPath}form/selection/update/`

// Form Team
export const api_url_profile_team_form_accept = `${rootPath}form/team/accept/`
export const api_url_profile_team_form_active = `${rootPath}form/team/active/`
export const api_url_profile_team_form_create = `${rootPath}form/team/create/`
export const api_url_profile_team_form_create_profile = `${rootPath}form/team/create_profile/`
export const api_url_profile_team_form_decline = `${rootPath}form/team/decline/`
export const api_url_profile_team_form_detail = `${rootPath}form/team/detail/`
export const api_url_profile_team_form_list = `${rootPath}form/team/list/`
export const api_url_profile_team_form_patch = `${rootPath}form/team/patch/`
export const api_url_profile_team_form_request = `${rootPath}form/team/request/`
export const api_url_profile_team_form_update = `${rootPath}form/team/update/`

// Form Member
export const api_url_member_form_active = `${rootPath}form/team/active/`
export const api_url_member_form_create = `${rootPath}form/team/create/`
export const api_url_member_form_detail = `${rootPath}form/team/detail/`
export const api_url_member_form_update = `${rootPath}form/team/update/`

// List
export const api_url_profile_home_list = `${rootPath}list/tab/home/`
export const api_url_profile_image_list = `${rootPath}list/image/`
export const api_url_profile_list = `${rootPath}list/profile/`
export const api_url_profile_portfolio_list = `${rootPath}list/tab/portfolio/`

// List Map
export const api_url_profile_map = `${rootPath}map/profile/`
export const api_url_profile_section_map = `${rootPath}map/section/`
