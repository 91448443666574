// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CheckboxChangeEventDetail,
} from '@ionic/core'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ListSpinner,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getApiUrlV2,
    getDetailObject,
    getFieldTitle,
} from 'services'

type ItemType = {
    columnName: string
    columnTitle: string
    columnValues?: string[]
}

// props
type FieldSettingsModalNotEmptyFieldsProps = {
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    profile: ProfileDetailAdminSerializerV2 | undefined
    selectedOptionsInDetail: ItemType[]
    selectedOptionsInList: ItemType[]
    setSelectedOptionsInDetail: any
    setSelectedOptionsInList: any
    viewId: number
}

// main
export const FieldSettingsModalNotEmptyFields: React.FC<FieldSettingsModalNotEmptyFieldsProps> = React.memo(({
    contentType,
    contentTypeSettings,
    profile,
    selectedOptionsInDetail,
    selectedOptionsInList,
    setSelectedOptionsInDetail,
    setSelectedOptionsInList,
    viewId,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<ItemType[]>([])

    useEffect(() => {
        onGetListData()
    }, [])

    function onCheckboxChangeDetail(event: CustomEvent<CheckboxChangeEventDetail>) {
        try {
            let newSelectedOptions: ItemType[] = []
            const index = selectedOptionsInDetail.findIndex(x => x.columnName === event.detail.value.columnName)
            if (event.detail.checked && index === -1) {
                newSelectedOptions = [...selectedOptionsInDetail, event.detail.value]
                setSelectedOptionsInDetail(newSelectedOptions)
            } else if (!event.detail.checked && index >= 0) {
                selectedOptionsInDetail.map(val => {
                    if (val.columnName !== event.detail.value.columnName) newSelectedOptions.push(val)
                    return false
                })
                setSelectedOptionsInDetail(newSelectedOptions)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSettingsModalNotEmptyFields',
                'onCheckboxChangeDetail',
            ))
        }
    }

    function onCheckboxChangeList(event: CustomEvent<CheckboxChangeEventDetail>) {
        try {
            let newSelectedOptions: ItemType[] = []
            const index = selectedOptionsInList.findIndex(x => x.columnName === event.detail.value.columnName)
            if (event.detail.checked && index === -1) {
                newSelectedOptions = [...selectedOptionsInList, event.detail.value]
                setSelectedOptionsInList(newSelectedOptions)
            } else if (!event.detail.checked && index >= 0) {
                selectedOptionsInList.map(val => {
                    if (val.columnName !== event.detail.value.columnName) newSelectedOptions.push(val)
                    return false
                })
                setSelectedOptionsInList(newSelectedOptions)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSettingsModalNotEmptyFields',
                'onCheckboxChangeList',
            ))
        }
    }

    function onGetListData() {
        try {
            const apiUrl = getApiUrlV2(`setup/list/contenttypefield/not_empty/${viewId}`, reduxAuth)
            getDetailObject({
                apiUrl,
                component: 'FieldSettingsModalNotEmptyFields',
                dispatch,
                reduxAuth,
                setDetailObject: (data: any) => {
                    const cleanItems: ItemType[] = []
                    data.map((val: any) => {
                        const getFieldTitleResult = getFieldTitle({
                            column: val.field_name,
                            contentType,
                            contentTypeSettings,
                            profile: profile,
                            reduxText: reduxText,
                        })
                        if ([
                            'GenericRel',
                            'GenericRelation',
                            'ManyToOneRel',
                            'ManyToManyRel',
                            'OneToOneRel',
                        ].includes(val.field_type)) return false
                        // const fieldOptions = contentTypeSettings.field?.[val.split('__')[0]]
                        // if (fieldOptions?.hideIfNotStaff && !reduxAuth.settings?.user?.is_staff) return null
                        // if (fieldOptions?.hideIfNotSuperuser && reduxAuth.settings?.user?.id !== 1) return null
                        cleanItems.push({
                            columnName: val.field_name,
                            columnTitle: getFieldTitleResult.columnTitle,
                            columnValues: val.field_values,
                        })
                        return false
                    })
                    const sortedItems = cleanItems.sort((a, b) => a.columnTitle.localeCompare(b.columnTitle))
                    setItems(sortedItems)
                },
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FieldSettingsModalNotEmptyFields',
                'onGetListData',
            ))
        }
    }

    return (
        <>
            <div className='mo-dialog-title-wrap'>
                <p className='mo-column-title'>Not empty fields</p>
            </div>
            {isLoading && <ListSpinner isLoading />}
            <IonList>
                {items.map((item) => {
                    return (
                        <IonItem key={item.columnName}>
                            <IonLabel className='clmp-label'>
                                <h3>{item.columnTitle}</h3>
                                <p className='clmp-profile'>{item.columnName}</p>
                                <p className='clmp-long-description mo-clamp-web5'>{item.columnValues?.map(obj => obj).join(', ')}</p>
                            </IonLabel>
                            <IonCheckbox
                                checked={selectedOptionsInDetail.findIndex((x: any) => x.columnName === item.columnName) >= 0}
                                onIonChange={(e) => onCheckboxChangeDetail(e)}
                                slot='end'
                                value={item}
                            />
                            <IonCheckbox
                                checked={selectedOptionsInList.findIndex((x: any) => x.columnName === item.columnName) >= 0}
                                onIonChange={(e) => onCheckboxChangeList(e)}
                                slot='end'
                                value={item}
                            />
                        </IonItem>
                    )
                })}
            </IonList>
        </>
    )
})
