// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    MainChipListBlock,
    MainCountryFlag,
    MainHashtag,
    PrivateButton,
    UnlistedButton,
} from 'components'

// data
import {
    api_url_service_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    searchTabValue,
} from 'data'

// pages
import {
    ServiceActionSheet,
} from 'pages'

// serializers
import {
    ServiceListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type ServiceListMediumBlockProps = {
    edit: boolean
    isInProfileSlug?: string
    modalKey: number
    object: ServiceListSerializer
}

// main
export const ServiceListMediumBlock: React.FC<ServiceListMediumBlockProps> = React.memo(({
    edit,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<ServiceListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_service_detail}${detailObject.id}/?block_size=medium`, reduxAuth),
                component: 'ServiceListMediumBlock',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ServiceListMediumBlock',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div
            className='service-list-medium-block-web'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='service'
                    objectId={detailObject.id!}
                    objectName={detailObject.name!}
                    setDeleted={setDeleted}
                />
            )}
            {detailObject.is_private && (
                <PrivateButton
                    edit={edit}
                    listed={detailObject.is_private_and_listed_in_portfolio}
                />
            )}
            {detailObject.is_unlisted && (
                <UnlistedButton edit={edit} />
            )}
            <LinkHelper
                className='slmbw-cover'
                edit={edit}
                onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
            >
                <ImageHelper
                    alt={detailObject.name}
                    className='slmbw-image'
                    dominant_color={detailObject.profile?.get_dominant_color}
                    src={detailObject.profile?.get_image_mq}
                />
            </LinkHelper>
            <div className='slmbw-info'>
                <LinkHelper
                    className='mo-list-block-name-web mo-clamp-web1 medium'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title={detailObject.name}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.name}
                </LinkHelper>
                <LinkHelper
                    className='slmbw-profile-name mo-clamp-web1'
                    edit={edit}
                    title={detailObject.profile?.name}
                    to={detailObject.profile?.get_absolute_url}
                >
                    {detailObject.profile?.name}
                </LinkHelper>
                <div className='slmbw-country'>
                    {detailObject.profile?.countries?.map((val) => (
                        <MainCountryFlag
                            key={val.id}
                            edit={edit}
                            object={val}
                            searchTab={searchTabValue.profile}
                        />
                    ))}
                    {detailObject.profile?.cities?.map((val) => (
                        <MainHashtag
                            key={`cities-${val.id}`}
                            edit={edit}
                            label={val.name}
                            searchTab={searchTabValue.profile}
                        />
                    ))}
                </div>
                <div className='slmbw-price-wrap'>
                    <Button
                        edit={edit}
                        fill='outline'
                        onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                        size='small'
                        text={reduxText[7670]}
                        to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                    />
                    <p className='slmbw-price mo-clamp-web1'>{detailObject.price}</p>
                </div>
                <div className='slmbw-tags'>
                    <MainChipListBlock>
                        {detailObject.custom_categories?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.event}
                            />
                        ))}
                        {/* {detailObject.custom_categories_2?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                        {detailObject.custom_categories_3?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))} */}
                        {detailObject.themes?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.service}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
            </div>
            <div className='slmbw-image-wrap'>
                {detailObject.image_list && detailObject.image_list.map((val) => (
                    <ImageHelper
                        key={val.id}
                        alt=''
                        className='slmbw-image'
                        dominant_color={val.get_dominant_color}
                        src={val.get_image_lq}
                    />
                ))}
            </div>
            <div className='slmbw-actions'>
                <ServiceActionSheet
                    edit={edit}
                    isInProfileSlug={isInProfileSlug}
                    object={detailObject}
                    refreshData={onGetDetailData}
                    setActivated={setActivated}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                />
            </div>
        </div>
    )
})
