// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router'

// components
import {
    Alert,
    Button,
    DeleteAlert,
    IconBlock,
    Loader,
    Popover,
    RatioZarmingHelper,
} from 'components'

// data
import {
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
} from 'data'

// pages
import {
    DuplicateObjectHelper,
} from 'pages'

// serializer
import {
    ContentTypeSettingsTypes,
    MainPortfolioSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getModalAbsoluteUrlV2,
    onClickIsModal,
    setStaffPermission,
} from 'services'

// props
type ActionComponentProps = {
    conditionStyles?: any
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    defaultFields: any | undefined
    edit: boolean
    inDetail?: boolean
    inModal?: boolean
    modalKey?: number
    object: any
    refreshData: () => void
    refreshList?: () => void
    setActivated?: React.Dispatch<boolean>
    setDeleted?: React.Dispatch<boolean>
    setShowActionPopover: React.Dispatch<boolean>
    showActionPopover: boolean
}

// main
export const ActionComponent: React.FC<ActionComponentProps> = React.memo(({
    conditionStyles,
    contentType,
    contentTypeSettings,
    defaultFields,
    edit,
    inDetail,
    inModal,
    modalKey,
    object,
    refreshData,
    refreshList,
    setActivated,
    setDeleted,
    setShowActionPopover,
    showActionPopover,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (showActionPopover) getData(object.id)
    }, [
        object.id,
        showActionPopover,
    ])

    type actionObjectType = {
        active?: boolean
        has_delete_permission?: boolean
        has_edit_permission?: boolean
        has_website?: boolean
        name?: string
        original_image?: string
        staff_permission?: boolean
    }

    const [actionObjectState, setActionObjectState] = useState<actionObjectType>()
    const [buttons, setButtons] = useState<any[]>([])
    const [confirmationObject, setConfirmationObject] = useState<{
        functionName?: string
        header?: string
        isOpen: boolean
    }>({
        isOpen: false,
    })
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDuplicateAlertOpen, setIsDuplicateAlertOpen] = useState(false)
    const [isDuplicateLoading, setIsDuplicateLoading] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [processMessage, setProcessMessage] = useState('')
    // const [showAddToSection, setShowAddToSection] = useState(false)
    const [showDuplicateObject, setShowDuplicateObject] = useState(false)
    const [portfolio, setPorfolio] = useState<MainPortfolioSerializer>()

    const app = contentType.split('_')[0]
    const model = contentType.split('_')[1]
    const settingsMeta = contentTypeSettings.meta

    if (!settingsMeta) return <p className='mo-error-color'>Error</p>

    async function getData(objectId: string) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ActionComponent getData')

            if (!objectId) return
            const getUrl = getApiUrlV2(`${app}/action/${model}/${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!response.data) return
                    const actionObject: actionObjectType = response.data
                    setActionObjectState(actionObject)
                    const newButtons = []

                    if (response.data.portfolio_main) {
                        setPorfolio(response.data.portfolio_main)
                    }

                    // Permission
                    if (actionObject.has_edit_permission) {
                        newButtons.push(
                            {
                                handler: () => {
                                    dispatch(reduxModalMainFormShow({
                                        contentType,
                                        contentTypeSettings,
                                        cssClass: 'form-container-modal',
                                        defaultFields,
                                        formComponent: 'FormModal',
                                        objectId: object.id,
                                        refreshData,
                                        setDeleted,
                                    }))
                                    setShowActionPopover(false)
                                },
                                role: 'edit',
                                text: reduxText[2105],
                            },
                        )
                        if (typeof actionObject.active !== 'undefined') {
                            newButtons.push(
                                {
                                    handler: () => {
                                        handlePublish(actionObject.active)
                                        setShowActionPopover(false)
                                    },
                                    role: actionObject.active ? 'destructive' : '',
                                    text: actionObject.active ? reduxText[3517] : reduxText[3518],
                                },
                            )
                        }
                        if ([
                            'accounting_expense',
                            'accounting_invoice',
                            'activity_course',
                            'builder_blocktemplate',
                            'builder_pagetemplate',
                            'event_event',
                            'material_material',
                            'offer_offer',
                            'project_project',
                            'sales_product',
                            'service_service',
                        ].includes(contentType)) {
                            newButtons.push(
                                {
                                    handler: () => setIsDuplicateAlertOpen(true),
                                    text: reduxText[6435],
                                },
                                {
                                    handler: () => {
                                        setShowActionPopover(false)
                                        setShowDuplicateObject(true)
                                    },
                                    text: reduxText[8472],
                                },
                            )
                        }
                        if (actionObject.original_image) {
                            newButtons.push(
                                {
                                    text: reduxText[7855],
                                    handler: () => window.open(actionObject.original_image, '_blank')
                                },
                            )
                        }
                    }
                    if (actionObject.has_delete_permission) {
                        newButtons.push(
                            {
                                handler: () => {
                                    setIsDeleteAlertOpen(true)
                                    setShowActionPopover(false)
                                },
                                role: 'destructive',
                                text: reduxText[2983],
                            },
                        )
                    }

                    if (contentType === 'sales_salesorder' && object.profile?.id === 191736) {
                        newButtons.push(
                            {
                                handler: () => {
                                    setConfirmationObject({
                                        functionName: 'action_function_generate_invoice',
                                        header: 'Voulez-vous générer une facture ?',
                                        isOpen: true,
                                    })
                                    setShowActionPopover(false)
                                },
                                text: 'Générer une facture',
                            },
                        )
                    }

                    if (contentType === 'adhesion_contribution') {
                        newButtons.push(
                            {
                                handler: () => {
                                    setConfirmationObject({
                                        functionName: 'action_function_generate_invoice',
                                        header: 'Voulez-vous générer une facture ?',
                                        isOpen: true,
                                    })
                                    setShowActionPopover(false)
                                },
                                text: 'Générer une facture',
                            },
                        )
                    }

                    // Staff Permission
                    if (reduxAuth.settings?.user?.groups?.includes(8)) {
                        newButtons.push(
                            {
                                handler: () => {
                                    setStaffPermission({
                                        component: 'ActionComponent',
                                        contentTypeModel: contentType,
                                        dispatch,
                                        objectId: object.id,
                                        reduxAuth,
                                        refreshData,
                                    })
                                    setShowActionPopover(false)
                                },
                                text: actionObject.staff_permission ? 'Remove staff permission' : 'Set staff permission',
                            },
                        )
                        if (contentType === 'staff_staffproject') {
                            if (!actionObject.has_website || reduxAuth.settings.user.id === 1) {
                                newButtons.push(
                                    {
                                        handler: () => {
                                            setConfirmationObject({
                                                functionName: 'create_website',
                                                header: 'Do you want to proceed with the creation of a website?',
                                                isOpen: true,
                                            })
                                            setShowActionPopover(false)
                                        },
                                        text: actionObject.has_website ? 'Already a website, create a new' : 'Create a website',
                                    },
                                )
                            }
                        }
                    }

                    if (newButtons.length > 0) {
                        setButtons(newButtons)
                    } else {
                        setButtons([{
                            disabled: true,
                            text: reduxText[9780],
                        }])
                    }
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ActionComponent',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ActionComponent',
                'getData',
            ))
        }
    }

    async function handleDelete() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ActionComponent handleDelete')
            setProcessMessage(reduxText[1242])
            setIsProcessing(true)
            const deleteUrl = getApiUrlV2(`${app}/form/${model}/update/${object.id}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true, reduxText[5899]))
                    if (setDeleted) setDeleted(true)
                    setIsProcessing(false)
                })
                .catch((error) => {
                    setIsProcessing(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'ActionComponent',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsProcessing(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ActionComponent',
                'handleDelete',
            ))
        }
    }

    async function handleDuplicate() {
        try {
            setIsDuplicateLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ActionComponent handleDuplicate')
            const duplicateUrl = getApiUrlV2(`${app}/form/${model}/duplicate/${object.id}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'post',
                url: duplicateUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDuplicateLoading(false)
                    setShowActionPopover(false)
                    dispatch(reduxModalMainFormShow({
                        contentType,
                        contentTypeSettings,
                        cssClass: 'form-container-modal',
                        formComponent: 'FormModal',
                        objectId: response.data.id,
                        refreshData: refreshList,
                    }))
                    if (refreshList) refreshList()
                })
                .catch((error) => {
                    setIsDuplicateLoading(false)
                    setShowActionPopover(false)
                    axiosErrorHandler({
                        apiUrl: duplicateUrl,
                        component: 'ActionComponent',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDuplicate',
                    })
                })
        } catch (error) {
            setIsDuplicateLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ActionComponent',
                'handleDuplicate',
            ))
        }
    }

    async function handlePublish(active?: boolean) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ActionComponent handlePublish')
            const formData = new FormData()
            formData.append('active', (!active).toString())
            if (contentType === 'event_event' && object.profile?.id === 193057) {  // TODO dynamic
                if (active) {
                    formData.append('active_status', '2')
                } else {
                    formData.append('active_status', '1')
                }
            }
            const patchUrl = getApiUrlV2(`${app}/form/${model}/update/${object.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'patch',
                url: patchUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    refreshData()
                    dispatch(reduxFormSetIsSuccessToastOpen(true, active ? reduxText[6567] : reduxText[5901]))
                    if (setActivated) setActivated(!active)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: patchUrl,
                        component: 'ActionComponent',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePublish',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ActionComponent',
                'handlePublish',
            ))
        }
    }

    async function handleConfirmation() {
        try {
            setIsProcessing(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ActionComponent handleButtonClick')
            const formData: any = new FormData()
            formData.append('content_type_id', contentTypeData[contentType])
            formData.append('function_name', confirmationObject.functionName)
            formData.append('object_id', object.id)
            const postUrl = getApiUrlV2(`main/helper/action_function/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    // @ts-ignore
                    refreshData()
                    setIsProcessing(false)
                    if (response.data.success_url) {
                        history.push(response.data.success_url)
                    }
                })
                .catch((error) => {
                    setIsProcessing(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'ActionComponent',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleButtonClick',
                    })
                })
        } catch (error) {
            setIsProcessing(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ActionComponent',
                'handleButtonClick',
            ))
        }
    }

    return (
        <React.Fragment>
            {inDetail && (
                <div className='action-image'>
                    {object?.profile && (
                        <RatioZarmingHelper
                            borderRadius='50%'
                            dominant_color={object.profile.get_dominant_color}
                            edit={edit}
                            fontSize={12}
                            height={30}
                            name={object.profile.name}
                            notZarma={Boolean(object.profile.get_image_xs)}
                            src={object.profile.get_image_xs}
                            to={object.profile.get_absolute_url}
                        />
                    )}
                </div>
            )}
            {!inDetail && !settingsMeta.disableDetailView && object?.id && (
                <IconBlock
                    color2={!inDetail && conditionStyles?.color}
                    edit={false}
                    iconClass='mo-new-icon-eye-solid'
                    onClick={() => onClickIsModal(object.id!, modalKey!, dispatch)}
                    to={getModalAbsoluteUrlV2({
                        contentType,
                        objectId: object.id!,
                        pageType: 'detail',
                    })}
                />
            )}
            {contentType === 'activity_registration' && object?.profile?.id === 191231 && object.student?.id && (  // TODO dynamic
                <IconBlock
                    color2={!inDetail && conditionStyles?.color}
                    edit={false}
                    iconClass='mo-new-icon-user-solid'
                    onClick={() => onClickIsModal(object.id!, modalKey!, dispatch)}
                    to={getModalAbsoluteUrlV2({
                        contentType: 'activity_student',
                        objectId: object.student.id!,
                        pageType: 'detail',
                    })}
                />
            )}
            <Popover
                buttons={buttons}
                color2={!inDetail && conditionStyles?.color}
                edit={edit}
                extraZIndex={inModal}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
            />
            {!settingsMeta.disableFormEdit && (
                inDetail ? (
                    <Button
                        edit={false}
                        fill='outline'
                        onClick={() =>
                            dispatch(reduxModalMainFormShow({
                                contentType,
                                contentTypeSettings,
                                cssClass: 'form-container-modal',
                                defaultFields,
                                formComponent: 'FormModal',
                                objectId: object.id,
                                refreshData,
                                setDeleted,
                            }))
                        }
                        padding='0 10px 0 10px'
                        size='x-small'
                        text={reduxText[2105]}
                    />
                ) : (
                    <IconBlock
                        color2={!inDetail && conditionStyles?.color}
                        edit={false}
                        iconClass='mo-new-icon-pencil-alt-solid'
                        onClick={() =>
                            dispatch(reduxModalMainFormShow({
                                contentType,
                                contentTypeSettings,
                                cssClass: 'form-container-modal',
                                defaultFields,
                                formComponent: 'FormModal',
                                objectId: object.id,
                                refreshData,
                                setDeleted,
                            }))
                        }
                    />
                )
            )}
            {confirmationObject.isOpen && (
                <Alert
                    buttons={[
                        {
                            handler: () => setShowActionPopover(false),
                            text: reduxText[4519],
                        },
                        {
                            handler: () => handleConfirmation(),
                            text: reduxText[5614],
                        },
                    ]}
                    header={object.name}
                    message={confirmationObject.header}
                    isOpen
                    onDidDismiss={() => setConfirmationObject({ isOpen: false })}
                />
            )}
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={handleDelete}
                    objectName={actionObjectState?.name}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
            {isDuplicateAlertOpen && (
                <Alert
                    buttons={[
                        {
                            handler: () => setShowActionPopover(false),
                            text: reduxText[4519],
                        },
                        {
                            handler: () => handleDuplicate(),
                            text: reduxText[5614],
                        },
                    ]}
                    header={reduxText[7711]}
                    isOpen
                    onDidDismiss={() => setIsDuplicateAlertOpen(false)}
                />
            )}
            {isProcessing && (
                <Loader
                    isOpen
                    message={processMessage}
                />
            )}
            {showDuplicateObject && (
                <DuplicateObjectHelper
                    contentType={contentType}
                    defaultPortfolio={portfolio}
                    newMixedMediaContent
                    newMixedMediaContentType={contentType}
                    objectId={object.id}
                    profile={object.profile}
                    setIsOpen={setShowDuplicateObject}
                />
            )}
            {isDuplicateLoading && (
                <Loader
                    isOpen
                    message={reduxText[7712]}
                />
            )}
        </React.Fragment>
    )
})
