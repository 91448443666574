// react components
import axios from 'axios'

// data
import {
    api_url_main_text,
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
} from 'services'

// constants
export const REDUX_TEXT_FETCH_MAIN_TEXT = 'REDUX_TEXT_FETCH_MAIN_TEXT'

// actions
export function reduxTextFetchMainText(
    reduxAuth: reduxAuthState,
    dispatchVar: React.Dispatch<any>,
    languadeCode: string,
) {
    return function (dispatch: any) {
        let getUrl = ''
        // getUrl = `http://localhost:8000/${languadeCode}/${reduxAuth.apiPrefix}/${reduxAuth.siteId}/${api_url_main_text}${reduxAuth.siteId}/`
        getUrl = `https://api.justtheweb.io/${languadeCode}/${reduxAuth.apiPrefix}/${reduxAuth.siteId}/${api_url_main_text}${reduxAuth.siteId}/`
        axios({
            method: 'get',
            url: getUrl,
        })
            .then((response) => {
                dispatch({
                    type: REDUX_TEXT_FETCH_MAIN_TEXT,
                    payload: response.data,
                })
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: getUrl,
                    component: 'reduxTextFetchMainText',
                    dispatch: dispatchVar,
                    error,
                    reduxAuth,
                    reference: 'get',
                })
            })
    }
}
