// react components
import React from 'react'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
    IonList,
    IonSpinner,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

// components
import {
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializer
import {
    TranslationHelper,
} from 'serializers/web'

// props
type TextAreaInputProps = {
    adminOnly?: boolean
    canSkipDataRetrieve?: boolean
    directionRtl?: boolean
    disabled?: boolean
    error: string | undefined
    getMetaIsLoading?: boolean
    helperText?: string
    label: string
    name: string
    onChange: any
    onRefresh?: () => void
    optional?: boolean
    placeholder?: string
    staffOnly?: boolean
    translation?: TranslationHelper
    value: string
}

// main
export const TextAreaInput: React.FC<TextAreaInputProps> = React.memo(({
    adminOnly,
    canSkipDataRetrieve,
    directionRtl,
    disabled,
    error,
    getMetaIsLoading,
    helperText,
    label,
    name,
    onChange,
    onRefresh,
    optional,
    placeholder,
    staffOnly,
    translation,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function onInputChange(value: string | null | undefined) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TextAreaInput',
                'onInputChange',
            ))
        }
    }

    function onSkipDataRetrieve(e: any) {
        try {
            onChange({
                name: 'skip_data_retrieve',
                value: e,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TextAreaInput',
                'onSkipDataRetrieve',
            ))
        }
    }

    return (
        <div className={`mo-text-input-web${disabled ? ' disabled' : ''}`}>
            <MainFormLabel
                adminOnly={adminOnly}
                formInput='textArea'
                helperText={helperText}
                label={label}
                name={name}
                onRefresh={onRefresh}
                optional={optional}
                staffOnly={staffOnly}
                translation={translation}
                translationDefaultValue={value}
            />
            <TextareaAutosize
                className={`mo-text-input-web-input tiw-input-textarea${directionRtl ? ' rtl' : ''}`}
                disabled={disabled}
                name={name}
                onChange={(e) => onInputChange(e.target.value)}
                placeholder={placeholder}
                rows={1}
                value={value}
            />
            {canSkipDataRetrieve && (
                <IonList>
                    <IonItem lines='none'>
                        <IonLabel className='tiw-label'>{reduxText[8428]}</IonLabel>
                        <IonCheckbox
                            className='tiw-checkbox'
                            slot='start'
                            onIonChange={(e) => onSkipDataRetrieve(e.detail.checked)}
                        />
                    </IonItem>
                </IonList>
            )}
            {getMetaIsLoading && (
                <div className='tiw-loader'>
                    <p className='tiw-loader-text'>{reduxText[8186]}</p>
                    <IonSpinner
                        className='tiw-loader-spinner'
                    />
                </div>
            )}
            <ErrorHelper error={error} />
        </div>
    )
})
