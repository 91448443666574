// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    DeleteAlert,
    Loader,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxFormAutoSaveRemoveSite,
    reduxFormSetDataSite,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    PortfolioPageContentFormState,
    ProjectMixedMediaContentFormState,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
    detailId: string
    formType: string
    pageSlug: string
    panelValue: string
}

type DeleteContentHelperProps = {
    child: boolean
    formUrl: string
    homePageSlug?: string
    mixedMedia: boolean
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    objectId: number
    parentId?: number
    setIsOpen: React.Dispatch<boolean>
}

// main
export const DeleteContentHelper: React.FC<DeleteContentHelperProps> = React.memo(({
    child,
    formUrl,
    homePageSlug,
    mixedMedia,
    newMixedMediaContent,
    newMixedMediaContentType,
    objectId,
    parentId,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)

    const content = PortfolioPageContentFormState(reduxAuth)
    const mixedMediaContent = ProjectMixedMediaContentFormState(reduxAuth)

    async function handleDelete() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DeleteContentHelper handleDelete')
            setIsLoading(true)
            const deleteUrl = `${mixedMedia ? mixedMediaContent.updateUrl : content.updateUrl}${objectId}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormAutoSaveRemoveSite(objectId, true))
                    setIsLoading(false)
                    if (mixedMedia) {
                        if (child) {
                            dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                            if (objectId.toString() === params.panelValue) {
                                if (newMixedMediaContent) {
                                    history.push(`/form-mixed-media/${newMixedMediaContentType}/${params.id}/${parentId}/?tab=blocks`)
                                } else {
                                    history.push(`${formUrl}${params.id}/mixed-media-content/${parentId}/?tab=blocks`)
                                }
                            }
                        } else if (reduxFormSite.pageContentItems) {
                            const newArray: any = []
                            reduxFormSite.pageContentItems.map((val: any) => {
                                if (val.id !== objectId) newArray.push(val)
                                return false
                            })
                            dispatch(reduxFormSetDataSite('pageContentItems', newArray))
                            if (params.formType === 'mixed-media-content') history.push(`${formUrl}${params.id}/mixed-media/-/`)
                        }
                    } else {
                        if (child) {
                            dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                            if (objectId === parentId) {
                                history.push(`${formUrl}${params.id}/page-container/-/${params.pageSlug || homePageSlug}/${params.detailId ? `${params.detailId}/` : ''}?tab=blocks`)
                            } else {
                                history.push(`${formUrl}${params.id}/content-container/${parentId}/${params.pageSlug || homePageSlug}/${params.detailId ? `${params.detailId}/` : ''}?tab=blocks`)
                            }
                        } else {
                            if (reduxFormSite.pageContentItems) {
                                const newArray: any = []
                                reduxFormSite.pageContentItems.map((val: any) => {
                                    if (val.id !== objectId) newArray.push(val)
                                    return false
                                })
                                dispatch(reduxFormSetDataSite('pageContentItems', newArray))
                                if (params.panelValue === 'content-container') history.push(`${formUrl}${params.id}/page-container/-/${params.pageSlug || homePageSlug}/${params.detailId ? `${params.detailId}/` : ''}`)
                            }
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'DeleteContentHelper',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'DeleteContentHelper',
                'handleDelete',
            ))
        }
    }

    return (
        <React.Fragment>
            <DeleteAlert
                handleDelete={handleDelete}
                setIsDeleteAlertOpen={setIsOpen}
            />
            {isLoading && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </React.Fragment>
    )
})
