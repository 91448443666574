// react components
import React, {
    useEffect,
    useState,
} from 'react'

// components
import {
    Popover,
} from 'components'

// props
type TableHeaderActionProps = {
    column: string
    edit: boolean
    fieldType: string
    filterValues: any
    inModal?: boolean
    setFilter?: (e: any, f?: any) => void
    setShowActionPopover: React.Dispatch<boolean>
    showActionPopover: boolean
}

// main
export const TableHeaderAction: React.FC<TableHeaderActionProps> = React.memo(({
    column,
    edit,
    fieldType,
    filterValues,
    inModal,
    setFilter,
    setShowActionPopover,
    showActionPopover,
}) => {

    const [buttons, setButtons] = useState<any[]>([])

    useEffect(() => {
        const newButtons = []
        if (setFilter) {

            const cleanColumn = ['ForeignKey', 'OneToOneField'].includes(fieldType) ? `${column.split('__')[0]}__${column.split('__')[1] || 'name'}` : column
            newButtons.push(
                {
                    disabled: filterValues.order_by?.find((val: any) => val.name === cleanColumn) ? false : true,
                    handler: () => {
                        setFilter({
                            direction: '',
                            name: cleanColumn,
                            params: 'order_by',
                        })
                        setShowActionPopover(false)
                    },
                    text: 'Clear ordering',
                },
                {
                    disabled: filterValues.order_by?.find((val: any) => val.name === cleanColumn && val.direction === '+') ? true : false,
                    handler: () => {
                        setFilter({
                            direction: '+',
                            name: cleanColumn,
                            params: 'order_by',
                        })
                        setShowActionPopover(false)
                    },
                    text: 'Sort ascending',
                },
                {
                    disabled: filterValues.order_by?.find((val: any) => val.name === cleanColumn && val.direction === '-') ? true : false,
                    handler: () => {
                        setFilter({
                            direction: '-',
                            name: cleanColumn,
                            params: 'order_by',
                        })
                        setShowActionPopover(false)
                    },
                    text: 'Sort descending',
                },
            )
        }
        setButtons(newButtons)
    }, [
        filterValues,
        showActionPopover,
    ])

    return (
        <React.Fragment>
            <Popover
                buttons={buttons}
                edit={edit}
                extraZIndex={inModal}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
            />
        </React.Fragment>
    )
})
