// Root URL
const rootPath = 'event/'

// Action
export const api_url_event_action = `${rootPath}action/event/`
export const api_url_participant_action = `${rootPath}action/participant/`

// Detail
export const api_url_event_detail = `${rootPath}detail/event/`
export const api_url_event_detail_short = `${rootPath}detail/event/short/`
export const api_url_event_image_detail = `${rootPath}detail/event_image/`
export const api_url_participant_detail = `${rootPath}detail/participant/`

// Form Event
export const api_url_event_form_active = `${rootPath}form/event/active/`
export const api_url_event_form_create = `${rootPath}form/event/create/`
export const api_url_event_form_detail = `${rootPath}form/event/detail/`
export const api_url_event_form_duplicate = `${rootPath}form/event/duplicate/`
export const api_url_event_form_update = `${rootPath}form/event/update/`

// Form Image
export const api_url_event_image_form_active = `${rootPath}form/image/active/`
export const api_url_event_image_form_create = `${rootPath}form/image/create/`
export const api_url_event_image_form_detail = `${rootPath}form/image/detail/`
export const api_url_event_image_form_list = `${rootPath}form/image/list/`
export const api_url_event_image_form_patch = `${rootPath}form/image/patch/`
export const api_url_event_image_form_update = `${rootPath}form/image/update/`

// Form Participant
export const api_url_event_participant_form_create = `${rootPath}form/participant/create/`
export const api_url_event_participant_form_detail = `${rootPath}form/participant/detail/`
export const api_url_event_participant_form_list = `${rootPath}form/participant/list/`
export const api_url_event_participant_form_update = `${rootPath}form/participant/update/`

// Form Pricing
export const api_url_event_pricing_form_create = `${rootPath}form/pricing/create/`
export const api_url_event_pricing_form_detail = `${rootPath}form/pricing/detail/`
export const api_url_event_pricing_form_list = `${rootPath}form/pricing/list/`
export const api_url_event_pricing_form_update = `${rootPath}form/pricing/update/`

// Form Schedule
export const api_url_event_schedule_form_active = `${rootPath}form/schedule/active/`
export const api_url_event_schedule_form_create = `${rootPath}form/schedule/create/`
export const api_url_event_schedule_form_detail = `${rootPath}form/schedule/detail/`
export const api_url_event_schedule_form_list = `${rootPath}form/schedule/list/`
export const api_url_event_schedule_form_patch = `${rootPath}form/schedule/patch/`
export const api_url_event_schedule_form_update = `${rootPath}form/schedule/update/`

// Form Team
export const api_url_event_team_form_accept = `${rootPath}form/team/accept/`
export const api_url_event_team_form_active = `${rootPath}form/team/active/`
export const api_url_event_team_form_create = `${rootPath}form/team/create/`
export const api_url_event_team_form_decline = `${rootPath}form/team/decline/`
export const api_url_event_team_form_detail = `${rootPath}form/team/detail/`
export const api_url_event_team_form_list = `${rootPath}form/team/list/`
export const api_url_event_team_form_patch = `${rootPath}form/team/patch/`
export const api_url_event_team_form_request = `${rootPath}form/team/request/`
export const api_url_event_team_form_update = `${rootPath}form/team/update/`

// Helper
export const api_url_event_excel_participant_list_helper = `${rootPath}helper/excel/participant_list/`

// List
export const api_url_event_image_list = `${rootPath}list/image/`
export const api_url_event_list = `${rootPath}list/event/`
export const api_url_event_participant_list = `${rootPath}list/participant/`
export const api_url_event_pricing_list = `${rootPath}list/pricing/`
export const api_url_event_project_list = `${rootPath}list/project/`
export const api_url_event_schedule_list = `${rootPath}list/schedule/`
export const api_url_event_service_list = `${rootPath}list/service/`
export const api_url_event_team_list = `${rootPath}list/team/`

// Map
export const api_url_event_map = `${rootPath}map/event/`
