// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_main_medium_type_offer,
} from 'data'

// serializers
import {
    OfferDetailFormSerializer,
    OfferDetailFormSerializerError,
} from 'serializers/web'

// props
type MediumTypeOfferInputProps = {
    errors: OfferDetailFormSerializerError | undefined
    fields: OfferDetailFormSerializer
    onChange: any
}

// main
export const MediumTypeOfferInput: React.FC<MediumTypeOfferInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <SelectListInput
            apiUrl={select_url_main_medium_type_offer}
            clearable={false}
            error={errors?.medium_type}
            label={reduxText[3228]}
            name='medium_type'
            onChange={onChange}
            value={fields.medium_type || ''}
        />
    )
})
