// react components
import {
    isMobileOnly,
} from 'react-device-detect'

// data
import {
    REDUX_MODAL_CONTENT_DETAIL_HIDE,
    REDUX_MODAL_CONTENT_DETAIL_SET_ITEMS,
    REDUX_MODAL_CONTENT_DETAIL_SET_OBJECT_ID,
    REDUX_MODAL_CONTENT_DETAIL_SHOW,
    REDUX_MODAL_DETAIL2_SHOW,
    REDUX_MODAL_ERROR_EVENT_HANDLER,
    REDUX_MODAL_ERROR_SHOW_HIDE,
    REDUX_MODAL_LAYOUT_INPUT_HIDE,
    REDUX_MODAL_LAYOUT_INPUT_SHOW,
    REDUX_MODAL_MAIN_FORM_HIDE,
    REDUX_MODAL_MAIN_FORM_SHOW,
    REDUX_MODAL_PLAYER_CHECK_POSITION,
    REDUX_MODAL_PLAYER_ON_DISMISS,
    REDUX_MODAL_PLAYER_ON_PLAY_PAUSE,
    REDUX_MODAL_PLAYER_ON_READY,
    REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_ALBUM,
    REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_TRACK,
    REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM,
    REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_TRACK,
    REDUX_MODAL_PLAYER_SET_POSITION,
    REDUX_MODAL_SEO_DATA,
    REDUX_MODAL_SLIDER_GET_DATA,
    REDUX_MODAL_SLIDER_ON_NEXT,
    REDUX_MODAL_SLIDER_ON_PREV,
    REDUX_MODAL_SLIDER_SET_DATA,
    REDUX_MODAL_SLIDER_SHOW_HIDE,
    reduxModalPlayerSetNextTrackArray,
} from './mo_actions'
import {
    reduxModalState,
} from './mo_state'

// main
const initialState: reduxModalState = {
    contentDetail: {
        isOpen: false,
    },
    deviceType: isMobileOnly ? 'is-mobile' : (window.innerWidth <= 767.99 ? 'is-mobile' : (window.innerWidth <= 991.99 ? 'is-tablet' : 'is-web')),
    error: {
        header: '',
        isOpen: false,
        message: '',
        noMessage: false,
        source: '',
        subHeader: '',
    },
    errorEventHandler: {
        component: '',
        error: undefined,
        info: '',
    },
    layoutInput: {
        isOpen: false,
    },
    mainForm: {
        isOpen: false,
    },
    modalDetail: {
        currentKey: 0,
        data: {},
        dataUrl: {},
        isOpen: false,
        listUrl: undefined,
        objectId: undefined,
        slideIndex: 0,
        noRedirect: false,
    },
    modalDetail2: {
        isOpen: false,
    },
    player: {
        albumId: undefined,
        albumNextUrl: '',
        albumTrackArray: [],
        albumTrackIndex: undefined,
        currentTrack: {},
        currentTrackIndex: undefined,
        originalTrackArray: [],
        originalTrackIndex: undefined,
        playerOrigin: '',
        playerPosition: '',
        playing: true,
        trackArray: [],
    },
    seoData: undefined,
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_MODAL_CONTENT_DETAIL_HIDE: {
            return {
                ...state,
                contentDetail: {
                    isOpen: false,
                },
            }
        }
        case REDUX_MODAL_CONTENT_DETAIL_SET_ITEMS: {
            return {
                ...state,
                contentDetail: {
                    ...state.contentDetail,
                    items: action.payload,
                },
            }
        }
        case REDUX_MODAL_CONTENT_DETAIL_SET_OBJECT_ID: {
            return {
                ...state,
                contentDetail: {
                    ...state.contentDetail,
                    contentObjectId: action.payload,
                },
            }
        }
        case REDUX_MODAL_CONTENT_DETAIL_SHOW: {
            return {
                ...state,
                contentDetail: {
                    ...state.contentDetail,
                    apiUrl: action.payload.apiUrl,
                    app: action.payload.app,
                    component: action.payload.component,
                    componentActionSheet: action.payload.componentActionSheet,
                    contentObjectId: action.payload.contentObjectId,
                    headerId: action.payload.headerId,
                    isOpen: true,
                    items: action.payload.items,
                    model: action.payload.model,
                    nextUrl: action.payload.nextUrl,
                    onSearchNext: action.payload.onSearchNext,
                },
            }
        }
        case REDUX_MODAL_DETAIL2_SHOW: {
            return {
                ...state,
                modalDetail2: action.payload,
            }
        }
        case REDUX_MODAL_ERROR_EVENT_HANDLER: {
            return {
                ...state,
                errorEventHandler: action.payload,
            }
        }
        case REDUX_MODAL_ERROR_SHOW_HIDE: {
            return {
                ...state,
                error: action.payload,
            }
        }
        case REDUX_MODAL_LAYOUT_INPUT_HIDE: {
            return {
                ...state,
                layoutInput: {
                    isOpen: false,
                },
            }
        }
        case REDUX_MODAL_LAYOUT_INPUT_SHOW: {
            return {
                ...state,
                layoutInput: {
                    direction: action.payload.direction,
                    forceMixedMediaTemp: action.payload.forceMixedMediaTemp,
                    handleSubmit: action.payload.handleSubmit,
                    isOpen: true,
                    mixedMedia: action.payload.mixedMedia,
                },
            }
        }
        case REDUX_MODAL_MAIN_FORM_HIDE: {
            return {
                ...state,
                mainForm: {
                    isOpen: false,
                },
            }
        }
        case REDUX_MODAL_MAIN_FORM_SHOW: {
            return {
                ...state,
                mainForm: {
                    contentType: action.payload.contentType,
                    contentTypeSettings: action.payload.contentTypeSettings,
                    cssClass: action.payload.cssClass,
                    defaultFields: action.payload.defaultFields,
                    formComponent: action.payload.formComponent,
                    isOpen: true,
                    objectId: action.payload.objectId,
                    onRefresh: action.payload.onRefresh,
                    profileObject: action.payload.profileObject,
                    setDeleted: action.payload.setDeleted,
                },
            }
        }
        case REDUX_MODAL_PLAYER_CHECK_POSITION: {
            if (action.direction === 'enter') {
                if (action.payload === state.player.currentTrack?.id) {
                    return {
                        ...state,
                        player: {
                            ...state.player,
                            playerPosition: 'detail',
                        }
                    }
                }
            }
            if (action.direction === 'leave') {
                return {
                    ...state,
                    player: {
                        ...state.player,
                        playerPosition: 'bottom',
                    }
                }
            }
            return state
        }
        case REDUX_MODAL_PLAYER_ON_DISMISS: {
            return {
                ...state,
                player: {
                    albumId: undefined,
                    albumNextUrl: '',
                    albumTrackArray: [],
                    albumTrackIndex: undefined,
                    currentTrack: {},
                    currentTrackIndex: undefined,
                    originalTrackArray: [],
                    originalTrackIndex: undefined,
                    playerOrigin: '',
                    playerPosition: '',
                    playing: true,
                    trackArray: [],
                }
            }
        }
        case REDUX_MODAL_PLAYER_ON_PLAY_PAUSE: {
            return {
                ...state,
                player: {
                    ...state.player,
                    playing: action.playOrPause,
                }
            }
        }
        case REDUX_MODAL_PLAYER_ON_READY: {
            return {
                ...state,
                player: {
                    ...state.player,
                    playing: true,
                }
            }
        }
        case REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_ALBUM:
        case REDUX_MODAL_PLAYER_SET_CURRENT_TRACK_TRACK: {

            const items: any[] = state.modalDetail.data?.[action.modalKey] || []
            const dataUrl = state.modalDetail.dataUrl?.[action.modalKey] || ''
            const playableItemsArray: any[] = []
            let new2OriginalTrackArray: any[] | undefined = undefined
            if (action.albumId) {
                new2OriginalTrackArray = []
                items.map((val: any) => {
                    if (val.id === action.albumId) { // Inject album tracks inside trackArray
                        action.albumTrackArray.map((val: any) => {
                            playableItemsArray.push(val)
                            return false
                        })
                    } else if (val.is_playable) { // Inject other tracks from trackArray
                        playableItemsArray.push(val)
                    }
                    if (val.is_playable) {
                        new2OriginalTrackArray!.push(val)
                    }
                    return false
                })
            } else {
                items.map((val: any) => {
                    if (val.is_playable) playableItemsArray.push(val)
                    return false
                })
            }
            const currentTrackIndex = playableItemsArray.findIndex((obj) => obj.id === action.currentTrack.id)

            // Album checks
            const checkIfInAlbum = state.player.albumTrackArray && state.player.albumTrackArray.findIndex((obj) => obj.id === action.currentTrack.id)
            let newAlbumTrackArray = []
            if (action.albumTrackArray && action.albumId === state.player.albumId) {
                if (state.player.albumTrackArray) newAlbumTrackArray = [...state.player.albumTrackArray, ...action.albumTrackArray]
            } else if (action.albumTrackArray && action.albumId !== state.player.albumId) {
                newAlbumTrackArray = action.albumTrackArray
            } else if (checkIfInAlbum && checkIfInAlbum > -1) {
                if (state.player.albumTrackArray) newAlbumTrackArray = state.player.albumTrackArray
            }
            const newAlbumTrackIndex: number = newAlbumTrackArray.findIndex((obj: any) => obj.id === action.currentTrack.id)

            // search next
            const newOriginalTrackArray = new2OriginalTrackArray || ((!state.player.originalTrackArray || state.player.originalTrackArray.length === 0) ? playableItemsArray : state.player.originalTrackArray)
            const newOriginalTrackIndex: number = newOriginalTrackArray.findIndex((obj: any) => obj.id === (action.albumId ? action.albumId : action.currentTrack.id))
            if (dataUrl && ((newOriginalTrackIndex + 1) >= (newOriginalTrackArray.length - 1))) {
                action.dispatch2(reduxModalPlayerSetNextTrackArray(
                    dataUrl,
                    action.playerOrigin || state.player.playerOrigin,
                    newOriginalTrackArray,
                    playableItemsArray,
                    action.reduxAuth,
                    action.dispatch2,
                ))
            }

            if (action.albumTrackArray) {
                const checkIfEmptyTrackArray = playableItemsArray.length === 0
                return {
                    ...state,
                    player: {
                        albumId: action.albumId,
                        albumNextUrl: action.albumNextUrl,
                        albumTrackArray: action.albumTrackArray,
                        albumTrackIndex: newAlbumTrackIndex,
                        currentTrack: action.currentTrack,
                        currentTrackIndex: checkIfEmptyTrackArray ? newAlbumTrackIndex : currentTrackIndex,
                        originalTrackArray: checkIfEmptyTrackArray ? [action.albumObject] : newOriginalTrackArray,
                        originalTrackIndex: checkIfEmptyTrackArray ? 0 : newOriginalTrackIndex,
                        playerOrigin: action.playerOrigin || state.player.playerOrigin,
                        playerPosition: action.playerPosition || state.player.playerPosition,
                        trackArray: checkIfEmptyTrackArray ? action.albumTrackArray : playableItemsArray,
                    }
                }
            }
            if (checkIfInAlbum && checkIfInAlbum > -1) {
                if (state.player.albumTrackArray && state.player.albumNextUrl && ((newAlbumTrackIndex + 1) >= (state.player.albumTrackArray.length - 1))) {
                    action.dispatch2(reduxModalPlayerSetNextTrackArray(
                        state.player.albumNextUrl,
                        action.playerOrigin || state.player.playerOrigin,
                        newOriginalTrackArray,
                        playableItemsArray,
                        action.reduxAuth,
                        action.dispatch2,
                        state.player.albumId,
                        state.player.albumTrackArray,
                    ))
                }
                return {
                    ...state,
                    player: {
                        ...state.player,
                        albumTrackIndex: newAlbumTrackIndex,
                        currentTrack: action.currentTrack,
                        currentTrackIndex: currentTrackIndex,
                        originalTrackArray: newOriginalTrackArray,
                        originalTrackIndex: action.originalTrackIndex || state.player.originalTrackIndex,
                        playerOrigin: action.playerOrigin || state.player.playerOrigin,
                        playerPosition: action.playerPosition || state.player.playerPosition,
                        trackArray: playableItemsArray,
                    }
                }
            }
            return {
                ...state,
                player: {
                    ...state.player,
                    albumId: undefined,
                    albumNextUrl: '',
                    albumTrackArray: [],
                    albumTrackIndex: undefined,
                    currentTrack: action.currentTrack,
                    currentTrackIndex: currentTrackIndex,
                    originalTrackArray: newOriginalTrackArray,
                    originalTrackIndex: newOriginalTrackIndex,
                    playerOrigin: action.playerOrigin,
                    playerPosition: action.playerPosition,
                    trackArray: playableItemsArray,
                }
            }
        }
        case REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_ALBUM:
        case REDUX_MODAL_PLAYER_SET_NEXT_TRACK_ARRAY_TRACK: {
            if (action.nextType === 'album') {
                return {
                    ...state,
                    player: {
                        ...state.player,
                        albumNextUrl: action.newAlbumNextUrl,
                        albumTrackArray: action.newAlbumTrackArray,
                        trackArray: action.newTrackArray,
                    }
                }
            } else {
                return {
                    ...state,
                    player: {
                        ...state.player,
                        originalTrackArray: action.newOriginalTrackArray,
                        trackArray: action.newTrackArray,
                    }
                }
            }
        }
        case REDUX_MODAL_PLAYER_SET_POSITION: {
            return {
                ...state,
                player: {
                    ...state.player,
                    playerPosition: action.payload,
                }
            }
        }
        case REDUX_MODAL_SEO_DATA: {
            return {
                ...state,
                seoData: action.payload,
            }
        }
        case REDUX_MODAL_SLIDER_GET_DATA: {
            let slideIndex = state.modalDetail.slideIndex || 0
            if (action.payload.objectId) {
                slideIndex = state.modalDetail.data?.[action.payload.modalKey]?.findIndex((object: any) => object.id === parseInt(action.payload.objectId, 10)) || 0
            }
            return {
                ...state,
                modalDetail: {
                    ...state.modalDetail,
                    currentKey: action.payload.modalKey,
                    isOpen: true,
                    listUrl: action.payload.listUrl,
                    objectId: action.payload.objectId,
                    slideIndex: slideIndex,
                    noRedirect: true,
                }
            }
        }
        case REDUX_MODAL_SLIDER_ON_NEXT: {
            return {
                ...state,
                modalDetail: {
                    ...state.modalDetail,
                    objectId: state.modalDetail.data?.[state.modalDetail!.currentKey!][(state.modalDetail.slideIndex! + 1) % state.modalDetail.data?.[state.modalDetail!.currentKey!]?.length!].id,
                    slideIndex: (state.modalDetail.slideIndex! + 1) % state.modalDetail!.data?.[state.modalDetail!.currentKey!].length!,
                }
            }
        }
        case REDUX_MODAL_SLIDER_ON_PREV: {
            return {
                ...state,
                modalDetail: {
                    ...state.modalDetail,
                    objectId: state.modalDetail.data?.[state.modalDetail!.currentKey!][(state.modalDetail.slideIndex! + state.modalDetail.data?.[state.modalDetail!.currentKey!].length - 1) % state.modalDetail.data?.[state.modalDetail!.currentKey!].length].id,
                    slideIndex: (state.modalDetail!.slideIndex! + state.modalDetail!.data?.[state.modalDetail!.currentKey!].length! - 1) % state.modalDetail!.data?.[state.modalDetail!.currentKey!].length!,
                }
            }
        }
        case REDUX_MODAL_SLIDER_SET_DATA: {
            return {
                ...state,
                modalDetail: {
                    ...state.modalDetail,
                    data: {
                        ...state.modalDetail.data,
                        [action.payload.key]: action.payload.results,
                    },
                    dataUrl: {
                        ...state.modalDetail.dataUrl,
                        [action.payload.key]: action.payload.dataUrl,
                    },
                }
            }
        }
        case REDUX_MODAL_SLIDER_SHOW_HIDE: {
            return {
                ...state,
                modalDetail: {
                    ...state.modalDetail,
                    isOpen: action.boolean,
                }
            }
        }
        default:
            return state
    }
}
