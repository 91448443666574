// react components
import React, {
    useState,
} from 'react'
import axios, {
    AxiosRequestConfig,
} from 'axios'
import {
    CheckboxChangeEventDetail,
} from '@ionic/core'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    api_url_profile_section_content_form_create,
    api_url_profile_section_content_form_update,
    contentSourceType,
    contentTypeData,
    contentTypeModelType,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type AddToSectionFormItemProps = {
    contentType: contentTypeModelType
    contentSource: contentSourceType
    objectId: number | undefined
    refreshData?: () => void
    item: any
}

// main
export const AddToSectionFormItem: React.FC<AddToSectionFormItemProps> = React.memo(({
    contentType,
    objectId,
    refreshData,
    item,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const [inProfileSectionId, setInProfileSectionId] = useState<boolean>(item.in_profile_section_id)
    const [isChecked, setIsChecked] = useState<boolean>(item.in_profile_section_id === false ? false : true)

    async function onCheckboxChange(event: CustomEvent<CheckboxChangeEventDetail>) {
        try {

            if (disabledCheckbox || !objectId) return
            if (event.detail.checked === isChecked) return
            setDisabledCheckbox(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AddToSectionFormItem onCheckboxChange')

            let axiosMethod: AxiosRequestConfig['method']
            let axiosUrl: string

            const formData = new FormData()
            if (!inProfileSectionId) {
                axiosMethod = 'post'
                axiosUrl = getApiUrl(api_url_profile_section_content_form_create, reduxAuth)
                formData.append('content_type', contentTypeData[contentType].toString())
                formData.append('object_id', objectId.toString())
                formData.append('section', item.id)
            } else {
                axiosMethod = 'delete'
                axiosUrl = getApiUrl(`${api_url_profile_section_content_form_update}${inProfileSectionId}/`, reduxAuth)
            }

            if (process.env.NODE_ENV === 'development') console.log(axiosUrl)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setInProfileSectionId(axiosMethod === 'post' ? response.data.id : false)
                    setIsChecked(axiosMethod === 'post' ? true : false)
                    setDisabledCheckbox(false)
                    if (refreshData) refreshData()
                    dispatch(reduxFormSetIsSuccessToastOpen(true, axiosMethod === 'post' ? `${reduxText[8714]} ${item.name}` : `${reduxText[8715]} ${item.name}`))
                })
                .catch((error) => {
                    setDisabledCheckbox(false)
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'AddToSectionFormItem',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onCheckboxChange',
                    })
                })
        } catch (error) {
            setDisabledCheckbox(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToSectionFormItem',
                'onCheckboxChange',
            ))
        }
    }

    return (
        <IonItem>
            <IonLabel>
                <h3>{item.name}</h3>
                {item.profile && <p>{item.profile.name}</p>}
            </IonLabel>
            <IonCheckbox
                checked={isChecked}
                disabled={Boolean(!objectId) || disabledCheckbox}
                onIonChange={(e) => onCheckboxChange(e)}
                slot='end'
                value={item}
            />
        </IonItem>
    )
})
