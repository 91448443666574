// data
import {
    mediumTypeConstants,
} from 'data'

// main
export const requiredFields: any = {
    [mediumTypeConstants.album]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.article]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.dossier]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.mixed]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.movie]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.pdf]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
            'pdf_file',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.post]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.serie]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.story]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.track]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.post]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'name',
        ],
        requiredAllFields: [
            'medium_type',
            'name',
            'profile',
        ],
    },
    [mediumTypeConstants.work]: {
        // Required fields
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],
        requiredAttribute: [
            'cover_image',
            'name',
        ],
        requiredAllFields: [
            'cover_image',
            'medium_type',
            'name',
            'profile',
        ],
    },
}
