// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    ParticipantListRowActionSheet,
} from 'pages'

// serializers
import {
    ParticipantListRowSerializer,
} from 'serializers/web'

// props
type ParticipantListRowProps = {
    edit: boolean
    inModal?: boolean
    object: ParticipantListRowSerializer
}

// main
export const ParticipantListRow: React.FC<ParticipantListRowProps> = React.memo(({
    edit,
    inModal,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showActionPopover, setShowActionPopover] = useState(false)

    return (
        <tr className='main-list-row ParticipantListRow'>
            {object.event?.profile === 183302 && (
                <td>{object.adherent?.id}</td>
            )}
            <td>
                <LinkHelper
                    edit={edit}
                    to={object.adherent?.get_absolute_url!}
                >
                    {object.adherent?.last_name || object.last_name}
                </LinkHelper>
            </td>
            <td>
                <LinkHelper
                    edit={edit}
                    to={object.adherent?.get_absolute_url!}
                >
                    {object.adherent?.first_name || object.first_name}
                </LinkHelper>
            </td>
            {object.event?.profile === 183302 && (
                <>
                    <td>{object.adherent?.custom_fk_cc_1?.name}</td>
                    <td>{object.adherent?.city?.name}</td>
                </>
            )}
            <td><i className={`mlr-icon ${object.is_paid ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
            {object.event?.profile === 183302
                ? (
                    <td className='tar'>{object.pricing ? `${object.amount} €` : reduxText[8761]}</td>
                ) : (
                    <td className='tar'>{object.amount} €</td>
                )}
            <td>{object.payment_date}</td>
            <td className='mlr-action'>
                <ParticipantListRowActionSheet
                    edit={edit}
                    inModal={inModal}
                    object={object}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                />
            </td>
        </tr>
    )
})
