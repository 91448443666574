// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonReorder,
    IonReorderGroup,
    IonThumbnail,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ImageHelper,
    InfiniteScrollHelperWeb,
} from 'components'

// data
import {
    api_url_profile_section_content_form_list,
    api_url_profile_section_content_form_list_old,
    api_url_profile_section_content_form_patch,
    api_url_profile_section_detail,
    api_url_profile_section_form_detail_ordering,
    api_url_profile_section_form_update_ordering,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    ProfileSectionOrderingUpdateFormSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailObject,
    getInfiniteListWebNew,
    listStateInitialWeb,
    listStatePropsWeb,
} from 'services'

// props
type ProfileSectionContentOrderingWebProps = {
    content_source: string | undefined
    onDone: () => void
    profileSlug?: string
    profileId?: string
    sectionId: string
}

// main
export const ProfileSectionContentOrderingWeb: React.FC<ProfileSectionContentOrderingWebProps> = React.memo(({
    content_source,
    onDone,
    profileSlug,
    profileId,
    sectionId,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const listApiUrl = `${api_url_profile_section_detail}?section_id=${sectionId}&list_status=all`

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsWeb>({
        ...listStateInitialWeb,
        clusterIds: [],
        modalKey: Math.random(),
    })

    const [sectionDetailObject, setSectionDetailObject] = useState<ProfileSectionOrderingUpdateFormSerializer | undefined>(undefined)
    const [viewType, setViewType] = useState<'list' | 'ordering'>()

    useEffect(() => {
        onGetSectionDetailData()
    }, [
        profileId,
        profileSlug,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
    ])

    useEffect(() => {
        if (viewType === 'ordering') {
            setListState({
                ...listState,
                clusterIds: [],
                isLoading: true,
            })
            onGetListData(
                getApiUrl(profileSlug ? `${api_url_profile_section_content_form_list_old}${profileSlug}/${sectionId}/` : `${api_url_profile_section_content_form_list}${profileId}/${sectionId}/`, reduxAuth),
                true,
            )
        } else if (viewType === 'list') {
            setListState({
                ...listState,
                clusterIds: [],
                isLoading: true,
            })
            onGetListData(
                getApiUrl(listApiUrl, reduxAuth),
                true,
            )
        }
    }, [
        viewType,
    ])

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSectionContentOrderingWeb',
                'doReorder',
            ))
        }
    }

    function onGetSectionDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_profile_section_form_detail_ordering}${sectionId}/${profileSlug || profileId}/`, reduxAuth),
                component: 'ProfileSectionContentOrderingWeb',
                customFunction: (e: any) => e.is_custom_ordering ? setViewType('ordering') : setViewType('list'),
                dispatch,
                reduxAuth,
                setDetailObject: setSectionDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSectionContentOrderingWeb',
                'onGetSectionDetailData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWebNew({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'ProfileSectionContentOrderingWeb',
                componentName: '',
                dispatch,
                listState,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSectionContentOrderingWeb',
                'getListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSectionContentOrderingWeb',
                'onSearchNext',
            ))
        }
    }

    async function onOrderingChange(orderingValue: 'ascending' | 'custom' | 'default') {
        try {
            // if (boolean === sectionDetailObject?.is_custom_ordering) return
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileSectionContentOrderingWeb onOrderingChange')

            const formData: any = new FormData()
            if (orderingValue === 'custom') {
                formData.append('is_custom_ordering', true)
                formData.append('is_name_ascending_ordering', false)
            } else if (orderingValue === 'ascending') {
                formData.append('is_custom_ordering', false)
                formData.append('is_name_ascending_ordering', true)
            } else {
                formData.append('is_custom_ordering', false)
                formData.append('is_name_ascending_ordering', false)
            }
            const putUrl = getApiUrl(`${api_url_profile_section_form_update_ordering}${sectionDetailObject!.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    onGetSectionDetailData()
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'ProfileSectionContentOrderingWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onOrderingChange',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSectionContentOrderingWeb',
                'onOrderingChange',
            ))
        }
    }

    async function onSortEnd(event: any) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileSectionContentOrderingWeb onSortEnd')
            const item = listState.items[event.detail.from]
            const itemTo = listState.items[event.detail.to]
            setListState({
                ...listState,
                items: arrayMoveImmutable(listState.items, event.detail.from, event.detail.to),
            })
            const formData: any = new FormData()
            formData.append('sibling_id', itemTo.id)
            const putUrl = getApiUrl(`${api_url_profile_section_content_form_patch}${item.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'ProfileSectionContentOrderingWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileSectionContentOrderingWeb',
                'onSortEnd',
            ))
        }
    }

    if (!sectionDetailObject) return null

    return (
        <div className='profile-section-content-ordering-web'>
            <div className='pscow-ordering-action'>
                <IonList>
                    <IonRadioGroup
                        className='pscow-radio-group'
                        onIonChange={(e) => onOrderingChange(e.detail.value)}
                        value={sectionDetailObject.is_custom_ordering ? 'custom' : (sectionDetailObject.is_name_ascending_ordering ? 'ascending' : 'default')}
                    >
                        <IonItem lines='none'>
                            <IonLabel>
                                <h3 className='pscow-label-title'>{reduxText[8019]}</h3>
                            </IonLabel>
                            <IonRadio
                                slot='start'
                                value='default'
                            />
                        </IonItem>
                        <IonItem lines='none'>
                            <IonLabel>
                                <h3 className='pscow-label-title'>{reduxText[6556]}</h3>
                            </IonLabel>
                            <IonRadio
                                slot='start'
                                value='ascending'
                            />
                        </IonItem>
                        <IonItem lines='none'>
                            <IonLabel>
                                <h3 className='pscow-label-title'>{reduxText[8018]}</h3>
                            </IonLabel>
                            <IonRadio
                                slot='start'
                                value='custom'
                            />
                        </IonItem>
                    </IonRadioGroup>
                </IonList>
                <Button
                    className='pscow-button'
                    edit={false}
                    fill='outline'
                    onClick={() => onDone()}
                    size='small'
                    text={reduxText[3563]}
                />
            </div>
            <div className='pscow-infinite'>
                <IonReorderGroup
                    disabled={!sectionDetailObject.is_custom_ordering}
                    onIonItemReorder={doReorder}
                >
                    {listState.items.map((item) => {
                        return (
                            <IonReorder
                                key={item.id}
                                className={sectionDetailObject.is_custom_ordering ? 'pscow-reorder-item' : ''}
                            >
                                <IonItem
                                    button={sectionDetailObject.is_custom_ordering}
                                    className='pscow-item'
                                    detail={false}
                                >
                                    {content_source === 'member' && (
                                        <React.Fragment>
                                            <IonThumbnail>
                                                <ImageHelper
                                                    alt=''
                                                    dominant_color={item.content_object?.member?.get_dominant_color || item.member?.get_dominant_color}
                                                    src={item.content_object?.member?.get_image_lq || item.member?.get_image_lq}
                                                />
                                            </IonThumbnail>
                                            <IonLabel className='pscow-label'>
                                                <h3>{item.content_object?.member?.name || item.content_object?.name || item.member?.name || item.name}</h3>
                                                {(!item.content_object?.active && !item.active) && (
                                                    <p className='pscow-draft'>{reduxText[2674]}</p>
                                                )}
                                            </IonLabel>
                                        </React.Fragment>
                                    )}
                                    {!['member'].includes(content_source!) && (
                                        <React.Fragment>
                                            <IonThumbnail>
                                                <ImageHelper
                                                    alt=''
                                                    dominant_color={item.content_object?.get_dominant_color || item.get_dominant_color}
                                                    src={item.content_object?.get_image_lq || item.get_image_square}
                                                />
                                            </IonThumbnail>
                                            <IonLabel className='pscow-label'>
                                                <h3>{item.content_object?.name || item.name}</h3>
                                                {item.content_object?.service_type === '2' && (
                                                    <p className='pscow-text'>{reduxText[8546]}</p>
                                                )}
                                                {(!item.content_object?.active && !item.active) && (
                                                    <p className='pscow-draft'>{reduxText[2674]}</p>
                                                )}
                                            </IonLabel>
                                        </React.Fragment>
                                    )}
                                </IonItem>
                            </IonReorder>
                        )
                    })}
                </IonReorderGroup>
            </div>
            <InfiniteScrollHelperWeb
                active={!listState.disableInfiniteScroll}
                hasMore={listState.hasMore}
                isLoading={listState.isLoading}
                items={listState.items}
                onChange={onSearchNext}
            />
        </div>
    )
})
