// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    site_id,
} from 'data'

// pages
import {
    CheckboxInputV2,
    ContentDetailInputPortfolio,
    ContentListInputPortfolio,
    CustomFilterInputPortfolio,
    SelectListInputV2,
    TextAreaInputV2,
    TextInputV2,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    PortfolioDetailFormSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type ContentSourceExtraInputPageContentProps = {
    disabled?: boolean
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    fieldsMother: PortfolioDetailFormSerializer
    inputObject?: LayoutFieldHelperSerializer
    mixedMedia?: boolean
    mixedMediaContentType?: contentTypeModelType
    onHandleInputChange: any
    optional?: boolean
}

// main
export const ContentSourceExtraInputPageContent: React.FC<ContentSourceExtraInputPageContentProps> = React.memo(({
    disabled,
    errorsInline,
    fieldsInline,
    fieldsMother,
    inputObject,
    mixedMedia,
    mixedMediaContentType,
    onHandleInputChange,
    optional,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const options = [
        { id: 'a', name: reduxText[5445] },
        { id: 'f', name: reduxText[6584] },
        { id: 's', name: reduxText[6585] },
    ]

    const options786 = [
        { id: 'a', name: reduxText[5445] },
        { id: 'f', name: reduxText[6584] },
    ]

    if ([52, 53].includes(fieldsInline.content_source?.id!)) return null

    return (
        <React.Fragment>
            {fieldsInline.content_source?.is_detail && fieldsInline.layout?.id !== 786 && !fieldsInline.is_in_detail && !fieldsInline.is_in_list && (
                <React.Fragment>
                    <ContentDetailInputPortfolio
                        // @ts-ignore
                        assetCount={fieldsMother.count_project || fieldsMother.count_asset}
                        contentSourceId={fieldsInline.content_source.id}
                        disabled={disabled}
                        error={errorsInline?.content_source_detail_id}
                        helperText={reduxText[631310]}
                        label={reduxText[6313]}
                        mixedMediaContentType={mixedMediaContentType}
                        name='content_source_detail_id'
                        onChange={onHandleInputChange}
                        optional={optional}
                        // @ts-ignore
                        portfolioId={mixedMedia ? fieldsMother.portfolio_main?.id : fieldsMother.id}
                        profileId={fieldsMother.profile?.id}
                        value={fieldsInline.content_source_detail_id || ''}
                    />
                    {/* @ts-ignore */}
                    {((reduxAuth.settings?.user?.id === 1) && (site_id === 12)) && (
                        <CheckboxInputV2
                            disabled={disabled}
                            error={errorsInline?.content_source_detail_id_for_template_only}
                            label='content_source_detail_id_for_template_only'  // TO_TEXT
                            name='content_source_detail_id_for_template_only'
                            onChange={onHandleInputChange}
                            value={fieldsInline.content_source_detail_id_for_template_only || ''}
                        />
                    )}
                </React.Fragment>
            )}
            {fieldsInline.content_source?.is_list && (
                <React.Fragment>
                    {fieldsInline.content_source?.id === 91 && (
                        <SelectListInputV2
                            apiUrl={`setup/select/moduleview/?profile=${fieldsMother.profile?.id}`}
                            clearable={false}
                            disabled={disabled}
                            error={errorsInline?.module_view}
                            label='Section'  // TO_TEXT
                            name='module_view'
                            onChange={onHandleInputChange}
                            optionsSorted
                            value={fieldsInline.module_view || ''}
                        />
                    )}
                    <SelectListInputV2
                        clearable={false}
                        disabled={disabled}
                        error={errorsInline?.content_source_filter_mode}
                        helperText={inputObject?.helper_text}
                        label={inputObject?.label || reduxText[6570]}
                        name='content_source_filter_mode'
                        onChange={onHandleInputChange}
                        options={options}
                        value={fieldsInline.content_source_filter_mode || ''}
                        v1
                    />
                    {fieldsInline.content_source_filter_mode?.id === 'f' && (
                        <>
                            <CustomFilterInputPortfolio
                                contentSourceId={fieldsInline.content_source.id}
                                disabled={disabled}
                                error={errorsInline?.custom_filters}
                                label={reduxText[6572]}
                                name='custom_filters'
                                noMarginBottom={reduxAuth.settings?.user?.id === 1}
                                onChange={onHandleInputChange}
                                optional={optional}
                                profileId={fieldsMother.profile?.id}
                                value={fieldsInline.custom_filters || undefined}

                            />
                            {reduxAuth.settings?.user?.id === 1 && (
                                <TextAreaInputV2
                                    disabled={disabled}
                                    error={errorsInline?.custom_filters}
                                    label=''
                                    name='custom_filters'
                                    onChange={onHandleInputChange}
                                    value={fieldsInline.custom_filters || ''}
                                />
                            )}
                        </>
                    )}
                    {fieldsInline.content_source_filter_mode?.id === 's' && (
                        <ContentListInputPortfolio
                            // @ts-ignore
                            assetCount={fieldsMother.count_project || fieldsMother.count_asset}
                            contentSourceId={fieldsInline.content_source.id}
                            disabled={disabled}
                            error={errorsInline?.content_source_detail_id}
                            label={reduxText[6406]}
                            mixedMedia={mixedMedia}
                            mixedMediaContentType={mixedMediaContentType}
                            name='content_source_detail_id'
                            onChange={onHandleInputChange}
                            optional={optional}
                            portfolioId={fieldsMother.id}
                            profileId={fieldsMother.profile?.id}
                            value={fieldsInline.content_source_detail_id || ''}
                        />
                    )}
                    {fieldsMother.force_language_filter && [2, 16, 40, 51, 91].includes(fieldsInline.content_source.id) && (
                        <CheckboxInputV2
                            disabled={disabled}
                            error={errorsInline?.force_language_filter_cancel}
                            label={reduxText[8609]}
                            name='force_language_filter_cancel'
                            onChange={onHandleInputChange}
                            value={fieldsInline.force_language_filter_cancel}
                        />
                    )}
                    {['a', 'f'].includes(fieldsInline.content_source_filter_mode?.id!) && (
                        <React.Fragment>
                            <TextInputV2
                                disabled={disabled}
                                error={errorsInline?.content_source_list_max}
                                label={reduxText[6317]}
                                name='content_source_list_max'
                                type='number'
                                onChange={onHandleInputChange}
                                value={fieldsInline.content_source_list_max || ''}
                                zeroForEmpty
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
            {fieldsInline.layout?.id === 786 && (
                <React.Fragment>
                    <SelectListInputV2
                        clearable={false}
                        disabled={disabled}
                        error={errorsInline?.content_source_filter_mode}
                        helperText={inputObject?.helper_text}
                        label={inputObject?.label || reduxText[6570]}
                        name='content_source_filter_mode'
                        onChange={onHandleInputChange}
                        options={options786}
                        value={fieldsInline.content_source_filter_mode || ''}
                        v1
                    />
                    {fieldsInline.content_source_filter_mode?.id === 'f' && (
                        <>
                            <CustomFilterInputPortfolio
                                contentSourceId={fieldsInline.content_source!.id}
                                disabled={disabled}
                                error={errorsInline?.custom_filters}
                                label={reduxText[6572]}
                                name='custom_filters'
                                noMarginBottom={reduxAuth.settings?.user?.id === 1}
                                onChange={onHandleInputChange}
                                optional={optional}
                                profileId={fieldsMother.profile?.id}
                                value={fieldsInline.custom_filters || undefined}
                            />
                            {reduxAuth.settings?.user?.id === 1 && (
                                <TextAreaInputV2
                                    disabled={disabled}
                                    error={errorsInline?.custom_filters}
                                    label=''
                                    name='custom_filters'
                                    onChange={onHandleInputChange}
                                    value={fieldsInline.custom_filters || ''}
                                />
                            )}
                        </>
                    )}
                </React.Fragment>
            )}
            {fieldsInline.layout?.id === 789 && (
                <React.Fragment>
                    <SelectListInputV2
                        clearable={false}
                        disabled={disabled}
                        error={errorsInline?.content_source_filter_mode}
                        helperText={inputObject?.helper_text}
                        label={inputObject?.label || reduxText[6570]}
                        name='content_source_filter_mode'
                        onChange={onHandleInputChange}
                        options={options}
                        value={fieldsInline.content_source_filter_mode || ''}
                        v1
                    />
                    {fieldsInline.content_source_filter_mode?.id === 'f' && (
                        <>
                            <CustomFilterInputPortfolio
                                contentSourceId={fieldsInline.content_source!.id}
                                disabled={disabled}
                                error={errorsInline?.custom_filters}
                                label={reduxText[6572]}
                                name='custom_filters'
                                noMarginBottom={reduxAuth.settings?.user?.id === 1}
                                onChange={onHandleInputChange}
                                optional={optional}
                                profileId={fieldsMother.profile?.id}
                                value={fieldsInline.custom_filters || undefined}

                            />
                            {reduxAuth.settings?.user?.id === 1 && (
                                <TextAreaInputV2
                                    disabled={disabled}
                                    error={errorsInline?.custom_filters}
                                    label=''
                                    name='custom_filters'
                                    onChange={onHandleInputChange}
                                    value={fieldsInline.custom_filters || ''}
                                />
                            )}
                        </>
                    )}
                    {fieldsInline.content_source_filter_mode?.id === 's' && (
                        <ContentListInputPortfolio
                            // @ts-ignore
                            assetCount={fieldsMother.count_project || fieldsMother.count_asset}
                            contentSourceId={fieldsInline.content_source!.id}
                            disabled={disabled}
                            error={errorsInline?.content_source_detail_id}
                            label={reduxText[6406]}
                            mixedMedia={mixedMedia}
                            mixedMediaContentType={mixedMediaContentType}
                            name='content_source_detail_id'
                            onChange={onHandleInputChange}
                            optional={optional}
                            portfolioId={fieldsMother.id}
                            profileId={fieldsMother.profile?.id}
                            value={fieldsInline.content_source_detail_id || ''}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    )
})
