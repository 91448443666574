// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonSearchbar,
} from '@ionic/react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    AddButton,
    IconBlock,
    InfiniteScrollHelperWeb,
    Loader,
    NotFoundComponent,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    reduxModalSeoData,
    view_url_admin,
    view_url_staff,
} from 'data'

// pages
import {
    TableAction,
    TableHeader,
    TableHeaderCustom,
    TableRow,
    TableRowDraggable,
} from 'pages'

// serializers
import {
    ContentTypeFieldTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getInfiniteListItem,
    getReduxTextId,
    listStateInitial,
    listStateProps,
    parseQuery,
} from 'services'

// props
type MatchParams = {
    moduleId: string
    tabId: string
    viewId: string
}

type AdminModuleProps = {
    isStaff: boolean
    profileObject: ProfileDetailAdminSerializerV2
    refreshData: () => void
}

// main
export const AdminModule: React.FC<AdminModuleProps> = React.memo(({
    isStaff,
    profileObject,
    refreshData,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [customColumns, setCustomColumns] = useState<{
        [key: string]: string[]
    } | undefined>(undefined)
    const [exportColumns, setExportColumns] = useState<{
        columnName: string
        columnTitle: string
        fieldOptions: ContentTypeFieldTypes | undefined
    }[]>([])
    const [exportListApiUrl, setExportListApiUrl] = useState<string>('')
    const [filterOrderingCombined, setFilterOrderingCombined] = useState<boolean>(false)
    const [filterValues, setFilterValues] = useState<any>({})
    const [hoveredRow, setHoveredRow] = useState<number | null>(null)
    const [isOrdering, setIsOrdering] = useState<boolean>(false)
    const [listState, setListState] = useState<listStateProps>({
        ...listStateInitial,
        clusterIds: [],
        modalKey: Math.random(),
    })
    const [checkboxArray, setCheckboxArray] = useState<number[]>([])

    const tabId = Number(params.tabId)

    // keep order
    const moduleId = Number(params.moduleId)
    const viewArray = isStaff ? (profileObject?.settings_data_staff_json?.modules?.find((val) => val.id === moduleId)?.viewsAll || []) : (profileObject?.settings_data_json?.modules?.find((val) => val.id === moduleId)?.viewsAll || [])
    const viewId = Number(params.viewId || viewArray[0]?.id)
    const tabArray = viewArray.find((val) => val.id === viewId)?.tabs || []
    const currentView = viewArray.find((val) => val.id === viewId)
    const contentType = currentView?.content_type!
    const app = contentType?.split('_')[0]
    const model = contentType?.split('_')[1]
    const modelVariation = currentView?.content_type_variation
    const contentTypeSettings = listState.contentTypeSettings
    const contentTypeSettingsCustom = isStaff ? profileObject?.settings_data_staff_json?.contentTypeSettingsCustom?.[contentType] : profileObject?.settings_data_json?.contentTypeSettingsCustom?.[contentType]
    const initialFilterQuery = parseQuery(window.location.search)?.filter_query?.replace(/\|/g, '=').replace(/,/g, '&')

    useEffect(() => {
        mainGet(true)
    }, [
        contentType,
        currentView?.isCustomOrdering,
        customColumns,
        moduleId,
        profileObject.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.user?.id,
        tabId,
        viewId,
    ])

    useEffect(() => {
        if (searchBarRef.current) {
            searchBarRef.current.value = ''
        }
        let title = ''
        if (tabId) {
            title = tabArray.find((val) => val.id === tabId)?.name || ''
        } else if (viewId) {
            title = viewArray.find((val) => val.id === viewId)?.name || ''
        } else {
            title = profileObject?.settings_data_json?.modules?.find((val) => val.id === moduleId)?.name || ''
        }
        if (parseQuery(window.location.search)?.parent) {
            title += ' | '
            title += viewArray.find((val) => val.id === Number(parseQuery(window.location.search)?.parent))?.name || ''
        }
        title += ' | '
        title += profileObject.name
        dispatch(reduxModalSeoData({ title: title }))
    }, [
        moduleId,
        tabId,
        viewId,
    ])

    let columnsNormal: any[] | undefined = []
    const columnsStaff = profileObject?.settings_data_staff_json?.contentTypeSettingsCustom?.[listState.contentType || contentType]?.list?.fields
    if (currentView?.subContentType && profileObject?.settings_data_json?.contentTypeSettingsCustom?.[listState.contentType || contentType]?.list?.fieldsSubContentType?.[currentView.subContentType]) {
        columnsNormal = profileObject?.settings_data_json?.contentTypeSettingsCustom?.[listState.contentType || contentType]?.list?.fieldsSubContentType?.[currentView.subContentType]
    } else if (profileObject?.settings_data_json?.contentTypeSettingsCustom?.[listState.contentType || contentType]?.list?.fields) {
        columnsNormal = profileObject?.settings_data_json?.contentTypeSettingsCustom?.[listState.contentType || contentType]?.list?.fields
    } else if (currentView?.subContentType && listState?.contentTypeSettings?.list?.fieldsSubContentType?.[currentView.subContentType]) {
        columnsNormal = listState?.contentTypeSettings?.list?.fieldsSubContentType?.[currentView.subContentType]
    } else {
        columnsNormal = listState.contentTypeSettings?.list.fields!
    }
    const columns = (customColumns?.[currentView?.subContentType!]?.length! > 0 ? customColumns![currentView!.subContentType!] : undefined) || (isStaff ? (columnsStaff || columnsNormal) : columnsNormal)

    function checkboxAddRemove(id: number, remove?: boolean) {
        try {
            if (remove) {
                setCheckboxArray(checkboxArray.filter((val) => val !== id))
            } else {
                setCheckboxArray([...checkboxArray, id])
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'checkboxAddRemove',
            ))
        }
    }

    async function checkboxSelectAll(remove?: boolean) {
        try {
            if (remove) {
                setCheckboxArray([])
            } else {
                setCheckboxArray(listState.items.map((val) => val.id))
                const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AdminModule checkboxSelectAll')
                // setProcessMessage(reduxText[4525])
                // setIsProcessing(true)
                const postUrl = getApiUrlV2(`main/helper/values/`, reduxAuth)
                const formData: any = new FormData()
                formData.append('api_list_url', exportListApiUrl)
                formData.append('columns', ['id'])
                formData.append('ids', 'all')
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setCheckboxArray(response.data)
                        // setIsProcessing(false)
                    })
                    .catch((error) => {
                        // setIsProcessing(false)
                        axiosErrorHandler({
                            apiUrl: postUrl,
                            component: 'AdminModule',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'checkboxSelectAll',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'checkboxSelectAll',
            ))
        }
    }

    function getDefaultFields(subContentTypeId?: number) {
        try {
            const defaultFields: any = {}
            if (contentType === 'staff_staffmessage' && reduxAuth.settings?.active_profile?.id === 193057) {  // TODO dynamic
                defaultFields['is_visible_to_customer'] = true
                defaultFields['profile_to'] = { id: 1 }
                defaultFields['project'] = { id: 2327 }
                defaultFields['status'] = { id: 7 }
            }
            if (contentType === 'staff_staffmessage' && reduxAuth.settings?.active_profile?.id === 191231) {
                defaultFields['is_visible_to_customer'] = true
                defaultFields['profile_to'] = { id: 1 }
                defaultFields['project'] = { id: 2298 }
                defaultFields['status'] = { id: 7 }
            }
            if (contentType === 'staff_staffmessage' && reduxAuth.settings?.active_profile?.id === 191736) {
                defaultFields['is_visible_to_customer'] = true
                defaultFields['profile_to'] = { id: 1 }
                defaultFields['project'] = { id: 2306 }
                defaultFields['status'] = { id: 7 }
            }
            if (subContentTypeId) {
                defaultFields.sub_content_type = { id: subContentTypeId, name: subContentTypeId }
            } else if (currentView?.subContentType) {
                defaultFields.sub_content_type = { id: currentView.subContentType, name: currentView.subContentType }
            }
            if (currentView?.isSelfFiltered) {
                defaultFields.module_view = { id: viewId, name: profileObject?.settings_data_json?.modules?.find((val) => val.id === moduleId)?.viewsAll?.find(val => val.id === viewId)?.name || viewId }
            }
            if (currentView?.isSelfFilteredMany) {
                defaultFields.module_views = [{ id: viewId, name: profileObject?.settings_data_json?.modules?.find((val) => val.id === moduleId)?.viewsAll?.find(val => val.id === viewId)?.name || viewId }]
            }
            if (currentView?.useFilterAsDefaultFields) {
                currentView?.filters?.forEach((val) => {
                    if (val.name === 'attribution_categories') {
                        if (defaultFields[val.name]) {
                            defaultFields[val.name].push({
                                id: val.value,
                                name: val.value,
                            })
                        } else {
                            defaultFields[val.name] = [{
                                id: val.value,
                                name: val.value,
                            }]
                        }
                    } else if (val.name.startsWith('custom_foreign_key_')) {
                        defaultFields[val.name] = {
                            id: val.value,
                            name: val.value,
                        }
                    } else if (val.name === 'module_views') {
                        if (defaultFields[val.name]) {
                            defaultFields[val.name].push({
                                id: val.value,
                                name: val.value,
                            })
                        } else {
                            defaultFields[val.name] = [{
                                id: val.value,
                                name: val.value,
                            }]
                        }
                    } else if (val.name !== 'ordering') {
                        defaultFields[val.name] = val.value
                    }
                })
            }
            return defaultFields
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'getdefaultFields',
            ))
        }
    }

    function getListApiUrl(clearFilter?: boolean) {
        try {
            let columnsNormal3: any[] | undefined = []
            const columnsStaff3 = profileObject?.settings_data_staff_json?.contentTypeSettingsCustom?.[contentType]?.list?.fields
            if (customColumns?.[currentView?.subContentType!]?.length! > 0) {
                columnsNormal3 = customColumns![currentView!.subContentType!]
            } else if (currentView?.subContentType && profileObject?.settings_data_json?.contentTypeSettingsCustom?.[contentType]?.list?.fieldsSubContentType?.[currentView.subContentType]) {
                columnsNormal3 = profileObject?.settings_data_json?.contentTypeSettingsCustom?.[contentType]?.list?.fieldsSubContentType?.[currentView.subContentType]
            } else if (profileObject?.settings_data_json?.contentTypeSettingsCustom?.[contentType]?.list?.fields) {
                columnsNormal3 = profileObject?.settings_data_json?.contentTypeSettingsCustom?.[contentType]?.list?.fields
                // } else if (currentView?.subContentType && listState?.contentTypeSettings?.list?.fieldsSubContentType?.[currentView.subContentType]) {
                //     columnsNormal3 = listState?.contentTypeSettings?.list?.fieldsSubContentType?.[currentView.subContentType]
                // } else {
                //     columnsNormal3 = listState.contentTypeSettings?.list.fields!
            }

            let newListApiUrl = getApiUrlV2(`${app}/list/${model}/`, reduxAuth)
            if (modelVariation) {
                newListApiUrl += `${modelVariation}/`
            }
            if (isStaff && currentView?.profiles?.length! > 0) {
                newListApiUrl += `?profile_ids=${currentView?.profiles?.join(',')}`
            } else if (isStaff && currentView?.profilesAll) {
                newListApiUrl += ''
            } else {
                newListApiUrl += `?profile=${profileObject.id}`
            }
            if (currentView?.isCustomOrdering) {
                newListApiUrl += `&order_by_view=${viewId}`
            }
            if (currentView?.isSelfFiltered) {
                if (!newListApiUrl.includes('?')) newListApiUrl += '?'
                newListApiUrl += `&module_view=${viewId}`
            }
            if (currentView?.isSelfFilteredMany) {
                if (!newListApiUrl.includes('?')) newListApiUrl += '?'
                newListApiUrl += `&module_views=${viewId}`
            }
            if (currentView?.filters?.length! > 0) {
                if (!newListApiUrl.includes('?')) newListApiUrl += '?'
                newListApiUrl += `&${currentView?.filters?.map((val) => `${val.name}=${val.value}`).join('&')}`
            }
            if (tabArray.length > 0) {
                if (!newListApiUrl.includes('?')) newListApiUrl += '?'
                tabArray.forEach((val) => {
                    if (val.filters) {
                        if (tabId && val.id === tabId) {
                            newListApiUrl += `&${val.filters.map((val) => `${val.name}=${val.value}`).join('&')}`
                        } else if (!tabId && val.id === tabArray[0].id) {
                            newListApiUrl += `&${val.filters.map((val) => `${val.name}=${val.value}`).join('&')}`
                        }
                    }
                })
            }
            if (!clearFilter && filterValues[contentType]) {
                Object.keys(filterValues[contentType]).forEach((key) => {
                    if (filterValues[contentType][key] && filterValues[contentType][key].length > 0) {
                        if (!newListApiUrl.includes('?')) newListApiUrl += '?'
                        if (key === 'order_by') {
                            newListApiUrl += `&${key}=${filterValues[contentType][key].map((val: any) => `${val.direction === '-' ? '-' : ''}${val.name}`).join(',')}`
                        } else {
                            newListApiUrl += `&${key}=${filterValues[contentType][key]}`
                        }
                    }
                })
            }
            if (isStaff && columnsStaff3?.length! > 0) {
                newListApiUrl += `&fields=${columnsStaff3?.join(',')}`
            }
            if (!isStaff && columnsNormal3?.length! > 0) {
                newListApiUrl += `&fields=${columnsNormal3?.join(',')}`
            }
            newListApiUrl = newListApiUrl.replace('__current_profile__', `${profileObject.id!}`)
            if (initialFilterQuery) {
                newListApiUrl += `&${initialFilterQuery}`
            }
            if (clearFilter) {
                setFilterValues({})
            }
            return newListApiUrl

        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'getListApiUrl',
            ))
        }
    }

    function mainGet(clearFilter?: boolean) {
        setListState({
            ...listState,
            clusterIds: [],
            isLoading: true,
        })
        if (contentType) {
            onGetListData(
                getListApiUrl(clearFilter)!,
                true,
            )
        } else {
            if (process.env.NODE_ENV === 'development') {
                console.log('contentType is undefined')
            }
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            if (changingView) {
                setExportListApiUrl(apiUrl)
            }
            getInfiniteListItem({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'AdminModule',
                dispatch,
                listState,
                noClusterIds: true,
                reduxAuth,
                setAxiosCancelToken,
                setListState,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'getListData',
            ))
        }
    }

    async function onMoveRow(dragIndex: number, hoverIndex: number) {
        try {
            const newRows = [...listState.items]
            const [draggedRow] = newRows.splice(dragIndex, 1)
            newRows.splice(hoverIndex, 0, draggedRow)
            setListState({
                ...listState,
                items: newRows,
            })
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AdminModule onMoveRow')
            const item = listState.items[dragIndex]
            const formData: any = new FormData()
            formData.append('item_id', item.id)
            formData.append('new_index', hoverIndex)
            const postUrl = getApiUrlV2(`setup/form/moduleview/items_position/${viewId}/`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(postUrl)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'AdminModule',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onMoveRow',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'onMoveRow',
            ))
        }
    }

    function onSearch(searchValue: any) {
        try {
            if (initialFilterQuery) {
                history.push(window.location.pathname)
            }
            onSetFilter({
                params: 'search',
                value: searchValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'onSearch',
            ))
        }
    }

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                onSearch(event.target.value)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'onSearchKeyPress',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(listState.nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'onSearchNext',
            ))
        }
    }

    function onSetFilter(e: any, f?: any) {
        try {
            setIsOrdering(false)
            if (!filterValues[contentType]) {
                filterValues[contentType] = {}
            }
            if (e.params === 'order_by') {
                const order_by = filterOrderingCombined ? filterValues[contentType].order_by || [] : []
                const existingIndex = order_by.findIndex((item: any) => item.name === e.name)
                if (e.direction) {
                    if (existingIndex !== -1) {
                        order_by[existingIndex].direction = e.direction
                    } else {
                        order_by.push({
                            direction: e.direction,
                            name: e.name,
                        })
                    }
                    filterValues[contentType].order_by = order_by
                } else {
                    filterValues[contentType].order_by = order_by.filter((item: any) => item.name !== e.name)
                }
            } else {
                if (e.value) {
                    filterValues[contentType][e.params] = e.value
                } else {
                    delete filterValues[contentType][e.params]
                }
            }
            if (f) {
                delete filterValues[contentType][f.params]
                if (f.params === 'search' && searchBarRef?.current) {
                    searchBarRef.current.value = ''
                }
            }
            mainGet()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'onFilter',
            ))
        }
    }

    function onShowModalForm(subContentTypeId?: number) {
        try {
            dispatch(reduxModalMainFormShow({
                contentType,
                contentTypeSettings,
                cssClass: 'form-container-modal',
                defaultFields: getDefaultFields(subContentTypeId),
                formComponent: 'FormModal',
                objectId: 'new',
                profileObject,
                refreshData: mainGet,
            }))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminModule',
                'onShowModalForm',
            ))
        }
    }

    if (!contentTypeSettings && !listState.isLoading) {
        return (
            <NotFoundComponent />
        )
    }

    if (!contentTypeSettings && !contentType) {
        return (
            <NotFoundComponent />
        )
    }

    if (!contentTypeSettings) {
        return <Loader isOpen />
    }

    let CustomTableRow: any = undefined
    let customHeaderFields: any = undefined
    if (modelVariation) {
        CustomTableRow = contentTypeSettings.meta?.variation?.[modelVariation]?.componentTableRow
        customHeaderFields = contentTypeSettings.meta?.variation?.[modelVariation]?.fieldsList
    }

    const isCustomOrderingPossible = (!currentView?.tabs || currentView?.tabs.length === 0) && !searchBarRef.current?.value

    const curerntViewName = getReduxTextId(
        currentView?.name ||
        profileObject?.settings_data_json?.contentTypeSettingsCustom?.[currentView?.content_type!]?.meta?.verboseNamePlural ||
        profileObject.content_type_settings_global?.[currentView?.content_type!]?.meta.verboseNamePlural!
        ,
        reduxText
    )

    return (
        <div className='admin-module'>
            <div className='am-header'>
                <div className='am-header-title'>
                    <p>{curerntViewName}</p>
                </div>
                <div className='am-search-wrap'>
                    <div className='am-action-wrap'>
                        <TableAction
                            canSendEmail={profileObject.id === 191736 && columns?.some(item => item.includes('email'))}
                            checkboxArray={checkboxArray}
                            columns={columns}
                            contentType={contentType}
                            contentTypeSettings={listState.contentTypeSettings!}
                            emailContentObjectId={currentView?.id}
                            emailContentType={currentView?.content_type}
                            exportColumns={exportColumns}
                            exportListApiUrl={exportListApiUrl}
                            isCustomOrdering={currentView?.isCustomOrdering}
                            isCustomOrderingPossible={isCustomOrderingPossible}
                            isOrdering={isOrdering}
                            profile={profileObject}
                            refreshData={refreshData}
                            setCustomColumns={(e) => {
                                if (e?.length > 0) {
                                    const newCustomColumns: string[] = []
                                    e.map((val) => {
                                        newCustomColumns.push(val.columnName)
                                    })
                                    setCustomColumns({
                                        ...customColumns || {},
                                        [currentView!.subContentType!]: newCustomColumns,
                                    })
                                    setExportColumns(e)
                                } else {
                                    setCustomColumns({
                                        ...customColumns || {},
                                        [currentView!.subContentType!]: [],
                                    })
                                }
                            }}
                            setIsOrdering={setIsOrdering}
                            subContentTypeId={currentView?.subContentType}
                            viewId={viewId}
                        />
                    </div>
                    {contentTypeSettings && !contentTypeSettings.meta.disableFormAdd && !currentView?.disableFormAdd && (
                        <AddButton
                            onShowModalForm={onShowModalForm}
                            size='x-small'
                            subContentTypes={currentView?.subContentTypes}
                        />
                    )}
                    <IonSearchbar
                        className='am-searchbar'
                        onIonClear={() => onSearch('')}
                        onKeyPress={(e) => onSearchKeyPress(e)}
                        placeholder={`${reduxText[10172]} "${curerntViewName}"`}
                        ref={searchBarRef}
                    />
                    <IconBlock
                        className='am-icon'
                        edit={false}
                        iconClass='mo-new-icon-search-solid'
                        onClick={() => { if (searchBarRef.current) onSearch(searchBarRef.current.value) }}
                    />
                </div>
                {tabArray.length > 0 && (
                    <div className='am-header-bottom'>
                        <div className='am-tab-wrap'>
                            {tabArray.map(val => (
                                <NavLink
                                    key={val.id}
                                    className='am-status-link'
                                    exact
                                    to={`${isStaff ? view_url_staff : view_url_admin}${profileObject.id}/section/${moduleId}/${viewId}/${val.id === tabArray[0].id ? '' : `${val.id}/`}`}
                                >
                                    {val.name}
                                </NavLink>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className={`am-infinite-table mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <table>
                    <thead>
                        {customHeaderFields
                            ? (
                                <TableHeaderCustom
                                    columns={customHeaderFields}
                                    count={listState.count}
                                />
                            ) : (
                                <TableHeader
                                    // addProfileColumn={getListApiUrl()!.addProfileColumn}
                                    checkboxSelectAll={checkboxSelectAll}
                                    checkboxShow={profileObject.id === 191736 && columns?.some(item => item.includes('email'))}
                                    // @ts-ignore
                                    columns={columns}
                                    contentType={contentType}
                                    contentTypeSettings={listState.contentTypeSettings!}
                                    count={listState.count}
                                    filterValues={filterValues}
                                    profile={profileObject}
                                    setExportColumns={setExportColumns}
                                    setFilter={onSetFilter}
                                />
                            )
                        }
                    </thead>
                    <tbody>
                        {listState.items.map((item, index) => {
                            if (CustomTableRow) {
                                return (
                                    <CustomTableRow
                                        key={item.id}
                                        columns={columns}
                                        componentActionSheet='ActionComponent'
                                        contentType={contentType}
                                        modalKey={listState.modalKey}
                                        object={item}
                                    />
                                )
                            }
                            if (isOrdering && isCustomOrderingPossible) {
                                return (
                                    <TableRowDraggable
                                        key={item.id}
                                        // addProfileColumn={getListApiUrl()!.addProfileColumn}
                                        // @ts-ignore
                                        columns={columns}
                                        contentType={contentType}
                                        contentTypeSettings={listState.contentTypeSettings!}
                                        index={index}
                                        isHovered={hoveredRow === index}
                                        modalKey={listState.modalKey}
                                        moveRow={onMoveRow}
                                        object={item}
                                        profileObject={profileObject}
                                        setHoveredRow={setHoveredRow}
                                    />
                                )
                            }
                            return (
                                <TableRow
                                    key={item.id}
                                    // addProfileColumn={getListApiUrl()!.addProfileColumn}
                                    checkboxAddRemove={checkboxAddRemove}
                                    checkboxArray={checkboxArray}
                                    checkboxShow={profileObject.id === 191736 && columns?.some(item => item.includes('email'))}
                                    // @ts-ignore
                                    columns={columns}
                                    componentActionSheet='ActionComponent'
                                    contentType={contentType}
                                    contentTypeSettings={listState.contentTypeSettings!}
                                    contentTypeSettingsCustom={contentTypeSettingsCustom}
                                    defaultFields={getDefaultFields()}
                                    modalKey={listState.modalKey}
                                    object={item}
                                    profileObject={profileObject}
                                    setFilter={onSetFilter}
                                />
                            )
                        })}
                    </tbody>
                </table>
                <InfiniteScrollHelperWeb
                    active={!listState.disableInfiniteScroll}
                    hasMore={listState.hasMore}
                    isLoading={listState.isLoading}
                    items={listState.items}
                    onChange={onSearchNext}
                />
            </div>
        </div>
    )
})
