// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios from 'axios'
import {
    IonActionSheet,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    Alert,
    AupouCurrentLocationHelper,
    Button,
    DeleteAlert,
    EditAlert,
    IconBlock,
    Loader,
} from 'components'

// data
import {
    api_url_copy_style_undo,
    api_url_move_content_undo,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetNavigationModeSite,
    reduxFormSetRefresh,
    reduxModaSetDeviceTypeSite,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    reduxModalSetLanguageIdSite,
    reduxModalSiteSetSpecialSizeSite,
    view_url_admin,
    view_url_portfolio_form,
    view_url_profile_detail_old,
    view_url_profile_portfolio_list,
} from 'data'

// pages
import {
    LayoutInputPortfolio,
    NotFoundPage,
    PortfolioPreview,
    PortfolioPreviewHub,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    BuilderService,
    autoSaveStyles,
    axiosErrorHandler,
    getAxiosHeaders,
    getApiUrl,
} from 'services'

// props
type MatchParams = {
    id: string
    contentId: string
    detailId: string
    pageSlug: string
    panelValue: string
}

type PortfolioFormContainerProps = {
    children: React.ReactNode
    formState: PortfolioFormState
    helpers: reduxFormHelpersState
    setHelpers: React.Dispatch<reduxFormHelpersState>
}


// main
export const PortfolioFormContainer: React.FC<PortfolioFormContainerProps> = React.memo(({
    children,
    formState,
    helpers,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    useEffect(() => {
        if (helpers.scrollToTop) {
            scrollToTop()
            setHelpers({ ...helpers, scrollToTop: false })
        }
    }, [helpers.scrollToTop])

    useEffect(() => {
        const languageOptions: any = {
            'en': 1,
            'fr': 2,
        }
        const languageId = languageOptions[reduxAuth.settings?.language!]
        if (languageId) dispatch(reduxModalSetLanguageIdSite(languageId))
    }, [reduxAuth.settings?.language])

    const contentRef = useRef<HTMLIonContentElement>(null)

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioFormContainer',
                'scrollToTop',
            ))
        }
    }

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const actionSheetButtonsNotActive = [
        {
            text: reduxText[2983],
            role: 'destructive',
            handler: () => {
                setIsDeleteAlertOpen(true)
            }
        }, {
            text: reduxText[4519],
            role: 'cancel',
        }
    ]

    async function handleDelete() {
        try {

            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PortfolioFormContainer handleDelete')
            setIsDeleting(true)
            const deleteUrl = `${mother.updateUrl}${mother.fields?.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    history.push(
                        `${view_url_profile_detail_old}${mother.fields?.profile?.slug}/`,
                    )
                    dispatch(reduxFormSetRefresh('refreshProfile', mother.fields?.profile?.id))
                })
                .catch((error) => {
                    setIsDeleting(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'PortfolioFormContainer',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioFormContainer',
                'handleDelete',
            ))
        }
    }

    function handleFinish() {
        dispatch(reduxFormSetIsSuccessToastOpen(false))
        autoSaveStyles({
            component: 'PortfolioFormContainer',
            dispatch,
            reduxAuth,
            reduxFormSite,
        })
        if (mother.fields?.profile?.is_new_profile_ux) {
            history.push(`${view_url_admin}${mother.fields?.profile?.id}/`)
        } else {
            history.push(`${view_url_profile_detail_old}${mother.fields?.profile?.slug}/tabs/${view_url_profile_portfolio_list}/`)
        }
        dispatch(reduxFormSetRefresh('refreshDetail'))
        dispatch(reduxFormSetNavigationModeSite(undefined))
    }

    async function handleUndoCopyStyle() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PortfolioFormContainer handleUndoCopyStyle')
            const previousStyleObject = BuilderService.copyGetPreviousStyleObject()
            const formData = new FormData()
            formData.append('content_type_id', previousStyleObject!.content_type!.toString())
            formData.append('object_id', previousStyleObject!.id!.toString())
            formData.append('styles', previousStyleObject!.layout_edit!.styles!.toString())
            formData.append('styles_original', previousStyleObject!.layout_edit!.styles_original!.toString())
            const postUrl = getApiUrl(api_url_copy_style_undo, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                    BuilderService.copyDeletePreviousStyleObject()

                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'PortfolioFormContainer',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleUndoCopyStyle',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioFormContainer',
                'handleUndoCopyStyle',
            ))
        }
    }

    async function handleUndoMove() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'PortfolioFormContainer handleUndoMove')
            const previousMoveObject = BuilderService.moveGetPreviousContentObject()
            const formData = new FormData()
            formData.append('content_type_id', previousMoveObject!.content_type!.toString())
            formData.append('object_id', previousMoveObject!.id!.toString())
            if (previousMoveObject!.parent_content) {
                formData.append('parent_content_id', previousMoveObject!.parent_content!.toString())
            }
            formData.append('position', previousMoveObject!.position!.toString())
            const postUrl = getApiUrl(api_url_move_content_undo, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                    BuilderService.moveDeletePreviousContentObject()

                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'PortfolioFormContainer',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleUndoMove',
                        skipSend404Detail: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioFormContainer',
                'handleUndoMove',
            ))
        }
    }

    if (helpers.errorStatus) {
        return <NotFoundPage
            header
            model='portfolio'
            objectId={Number(params.id)}
            statusCode={helpers.errorStatus}
        />
    }

    const previewUrlPath = `${params.pageSlug ? `/${params.pageSlug}` : ''}${params.detailId ? `/${params.detailId}` : ''}`

    return (
        <IonPage className='portfolio-form-container-web'>
            <IonContent
                className={`pfcw-form-content mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}
                ref={contentRef}
            >
                <IonHeader className={`pfcw-header${process.env.NODE_ENV === 'development' ? ' dev-color' : ''}`}>
                    <IonToolbar className='pfcw-toolbar'>
                        <IonButtons slot='start'>
                            <Button
                                edit={false}
                                fill='clear'
                                text={reduxText[5582]}
                                to={`${view_url_portfolio_form}${params.id}/`}
                            />
                            {(params.panelValue === 'page-list' || params.pageSlug) && (
                                <React.Fragment>
                                    <span>{'/'}</span>
                                    <Button
                                        disabled={params.panelValue === 'page-list'}
                                        edit={false}
                                        fill='clear'
                                        text={reduxText[6304]}
                                        to={`${view_url_portfolio_form}${params.id}/page-list/${params.contentId}/${params.pageSlug}/${params.detailId ? `${params.detailId}/` : ''}`}
                                    />
                                </React.Fragment>
                            )}
                            {['content-container', 'page-container'].includes(params.panelValue) && (
                                <React.Fragment>
                                    <span>{'/'}</span>
                                    <Button
                                        className='pfcw-button'
                                        disabled={params.panelValue === 'page-container'}
                                        edit={false}
                                        fill='clear'
                                        text={params.pageSlug ? `${reduxFormSite.pageObject?.seo_data?.extra_data?.portfolio_detail_page || ''}${reduxFormSite.pageObject?.seo_data?.extra_data?.portfolio_detail_page ? ` (${params.pageSlug[0].toUpperCase()}${params.pageSlug.slice(1)})` : `${params.pageSlug[0].toUpperCase()}${params.pageSlug.slice(1)}`}` : ''}
                                        to={`${view_url_portfolio_form}${params.id}/page-container/-/${params.pageSlug}/${params.detailId ? `${params.detailId}/` : ''}`}
                                    />
                                </React.Fragment>
                            )}
                            {['content-container'].includes(params.panelValue) && (
                                <React.Fragment>
                                    <span>{'/'}</span>
                                    <Button
                                        disabled
                                        edit={false}
                                        fill='clear'
                                        text={`${reduxText[6379]} ${params.contentId}`}
                                    />
                                </React.Fragment>
                            )}
                            {['global-design'].includes(params.panelValue) && (
                                <React.Fragment>
                                    <span>{'/'}</span>
                                    <Button
                                        disabled
                                        edit={false}
                                        fill='clear'
                                        text={reduxText[6303]}
                                    />
                                </React.Fragment>
                            )}
                            {['global-settings'].includes(params.panelValue) && (
                                <React.Fragment>
                                    <span>{'/'}</span>
                                    <Button
                                        disabled
                                        edit={false}
                                        fill='clear'
                                        text={reduxText[5332]}
                                    />
                                </React.Fragment>
                            )}
                            {['header'].includes(params.panelValue) && (
                                <React.Fragment>
                                    <span>{'/'}</span>
                                    <Button
                                        disabled
                                        edit={false}
                                        fill='clear'
                                        text={reduxText[7938]}
                                    />
                                </React.Fragment>
                            )}
                        </IonButtons>
                        <IonTitle color='primary'>
                            <div className='pfcw-nav-mode-wrap'>
                                {BuilderService.copyGetPreviousStyleObject() && (
                                    <Button
                                        edit={false}
                                        onClick={handleUndoCopyStyle}
                                        size='small'
                                        fill='clear'
                                        text={reduxText[8505]}
                                    />
                                )}
                                {BuilderService.moveGetPreviousContentObject() && (
                                    <Button
                                        edit={false}
                                        onClick={handleUndoMove}
                                        size='small'
                                        fill='clear'
                                        text={reduxText[8506]}
                                    />
                                )}
                                <IconBlock
                                    edit={false}
                                    iconClass={reduxModalSite.specialSize === 'form-fullscreen' ? 'mo-new-icon-compress-solid' : 'mo-new-icon-expand-solid'}
                                    onClick={() => {
                                        if (reduxModalSite.specialSize === 'form-fullscreen') {
                                            dispatch(reduxModalSiteSetSpecialSizeSite(undefined))
                                        } else {
                                            dispatch(reduxModalSiteSetSpecialSizeSite('form-fullscreen'))
                                        }
                                    }}
                                    tooltipText={reduxModalSite.specialSize === 'form-fullscreen' ? 'Exit fullscreen' : 'Fullscreen'}
                                />
                                <Button
                                    edit={false}
                                    fill={reduxFormSite.navigationMode === 'navPage' ? undefined : 'clear'}
                                    onClick={() => dispatch(reduxFormSetNavigationModeSite('navPage'))}
                                    size='small'
                                    text={reduxText[8191]}
                                />
                                <Button
                                    edit={false}
                                    fill={reduxFormSite.navigationMode === 'edit' ? undefined : 'clear'}
                                    onClick={() => dispatch(reduxFormSetNavigationModeSite('edit'))}
                                    size='small'
                                    text={reduxText[8192]}
                                />
                            </div>
                        </IonTitle>
                        <IonButtons slot='end'>
                            <div className='pfcw-device'>
                                <AupouCurrentLocationHelper />
                                {reduxAuth.settings?.user?.is_staff && (
                                    <Button
                                        edit={false}
                                        fill='outline'
                                        onClick={() => dispatch(reduxModalMainFormShow({
                                            contentType: 'staff_staffmessage',
                                            cssClass: 'form-container-modal',
                                            defaultFields: {
                                                project: mother.fields?.staff_project,
                                                text: `${window.location.href}\n\n`,
                                            },
                                            formComponent: 'FormModal',
                                            objectId: 'new',
                                            profileObject: reduxAuth.settings?.main_profile,
                                            refreshData: undefined,
                                        }))}
                                        padding='0 5px'
                                        size='x-small'
                                        text={reduxText[9817]}
                                    />
                                )}
                                {mother.fields?.id && (
                                    <a
                                        href={`${process.env.NODE_ENV === 'development' ? `http://localhost:8101/${params.id}` : `https://${mother.fields.hostname}`}${previewUrlPath}`}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        <i className='icon mo-new-icon-link-solid' />
                                    </a>
                                )}
                                <i
                                    className={`icon mo-new-icon-desktop-solid ${reduxModalSite.deviceType === 'is-web' && 'active'}`}
                                    onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-web'))}
                                />
                                <i
                                    className={`icon mo-new-icon-tablet-alt-solid ${reduxModalSite.deviceType === 'is-tablet' && 'active'}`}
                                    onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-tablet'))}
                                />
                                <i
                                    className={`icon mo-new-icon-mobile-alt-solid ${reduxModalSite.deviceType === 'is-mobile' && 'active'}`}
                                    onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-mobile'))}
                                />
                            </div>
                            <Button
                                edit={false}
                                size='small'
                                text={reduxText[6377]}
                                to={mother.fields?.profile?.is_new_profile_ux ? `${view_url_admin}${mother.fields?.profile?.id}/` : `${view_url_profile_detail_old}${mother.fields?.profile?.slug}/tabs/${view_url_profile_portfolio_list}/`}
                            />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {helpers.isLoading
                    ? (
                        <Loader isOpen />
                    ) : (
                        <div className={`pfcw-form-panels-wrap${reduxModalSite.specialSize === 'form-fullscreen' ? ' fullscreen' : ''}`}>
                            <div className={`pfcw-form-panels mo-hidden-vertical-scrollbar${reduxAuth.isWindows} ${params.panelValue}`}>
                                <div className={`pfcw-form-panels-children ${params.panelValue || 'menu'}`}>
                                    {children}
                                </div>
                                {!params.panelValue && (
                                    <IonFooter className='button-footer-web'>
                                        {!helpers.isLoading && (
                                            <Button
                                                disabled={!mother.fields?.id}
                                                edit={false}
                                                expand='full'
                                                onClick={handleFinish}
                                                text={reduxText[4690]}
                                            />
                                        )}
                                    </IonFooter>
                                )}
                                <Alert
                                    buttons={[{ text: reduxText[5614] }]}
                                    header={reduxText[5585]}
                                    isOpen={Boolean(helpers.mainError)}
                                    message={`${helpers.mainError}${helpers.mainError === 'Network Error' ? reduxText[4577] : ''}`}
                                    onDidDismiss={() => setHelpers({
                                        ...helpers,
                                        mainError: '',
                                    })}
                                />
                                {isDeleteAlertOpen && (
                                    <DeleteAlert
                                        handleDelete={handleDelete}
                                        objectName={mother.fields?.name}
                                        setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                                    />
                                )}
                            </div>
                            <div className='pfcw-form-preview'>
                                {params.detailId === 'hub'
                                    ? <PortfolioPreviewHub />
                                    : <PortfolioPreview />
                                }
                            </div>
                        </div>
                    )
                }
                {helpers.isSaving && (
                    <Loader
                        isOpen
                        message={reduxText[4525]}
                    />
                )}
                {isDeleting && (
                    <Loader
                        isOpen
                        message={reduxText[1242]}
                    />
                )}
            </IonContent>
            <IonActionSheet
                buttons={actionSheetButtonsNotActive}
                isOpen={helpers.isActionSheetOpen || false}
                onDidDismiss={() => setHelpers({ ...helpers, isActionSheetOpen: false })}
            />
            <EditAlert />
            <LayoutInputPortfolio />
        </IonPage>
    )
})
