// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    MaterialActionSheetMobile,
} from 'pages'

// serializers
import {
    MaterialListSerializer,
} from 'serializers/web'

// services
import {
    getDateWithType,
} from 'services'

// props
type MaterialListBigBlockMobileProps = {
    edit: boolean
    feedCreatedOn?: string
    object: MaterialListSerializer
}

// main
export const MaterialListBigBlockMobile: React.FC<MaterialListBigBlockMobileProps> = React.memo(({
    edit,
    feedCreatedOn,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [isActionSheetOpen, setActionSheetIsOpen] = useState(false)

    return (
        <div
            className='material-list-big-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='material'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <LinkHelper
                edit={edit}
                to={object.get_absolute_url}
                className='mlbb-cover'>
                <ImageHelper
                    alt={object.name}
                    className='mlbb-image'
                    defaultHeightMobile
                    dominant_color={undefined}
                    src={object.get_image_lq}
                />
            </LinkHelper>
            <div className='mlbb-bottom'>
                <div className='mlbb-bottom-header'>
                    <span className='mlbb-feed-date'>{feedCreatedOn ? getDateWithType(reduxAuth, feedCreatedOn, 'n') : ''}</span>
                    <div className='mlbb-actions'>
                        <IconBlock
                            edit={edit}
                            iconClass='mo-new-icon-ellipsis-h-solid'
                            onClick={() => setActionSheetIsOpen(true)}
                        />
                    </div>
                </div>
                <LinkHelper
                    className='mo-list-block-name-mobile'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    {object.name}
                </LinkHelper>
                <div className='mlbb-price-wrap'>
                    {object.price && (
                        <p className='mlbb-price'>{`${object.price} ${object.currency?.symbol || object.currency?.iso_code} / ${object.uom}`}</p>
                    )}
                    {!object.price && <p className='mlbb-price'>{reduxText[4869]}</p>}
                </div>
                <div className='mlbb-countries'>
                    <MainChipListBlock>
                        {object.profile?.countries?.map((val) => (
                            <MainCountryFlag
                                key={val.id}
                                edit={edit}
                                object={val}
                            />
                        ))}
                        {object.profile?.cities?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
                <div className='list-block-footer'>
                    {object.profile && (
                        <ListProfileLink
                            blockSize='big'
                            edit={edit}
                            profile={object.profile}
                        />
                    )}
                </div>
            </div>
            {isActionSheetOpen && (
                <MaterialActionSheetMobile
                    object={object}
                    setActivated={setActivated}
                    setIsOpen={setActionSheetIsOpen}
                />
            )}
        </div>
    )
})
