// react components
import React from 'react'
import {
    RefresherEventDetail,
} from '@ionic/core'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    errorMessageToSkip,
    reduxAuthState,
    reduxModalSliderSetData,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
    getShuffleArray,
    listStatePropsMobile,
} from 'services'


export async function getInfiniteRandomListMobile(
    apiUrl: string,
    reduxAuth: reduxAuthState,
    listState: listStatePropsMobile,
    setListState: React.Dispatch<any>,
    axiosCancelToken: CancelTokenSource | undefined,
    setAxiosCancelToken: React.Dispatch<CancelTokenSource | undefined>,
    dispatch: React.Dispatch<any>,
    component: string,
    changingView: boolean,
    refreshEvent: CustomEvent<RefresherEventDetail> | undefined,
    componentName: string,
) {

    if (!apiUrl) return

    if (changingView) {
        setListState({
            ...listState,
            disableInfiniteScroll: true,
            hasMore: true,
            isLoading: true,
            items: [],
        })
    } else {
        setListState({
            ...listState,
            disableInfiniteScroll: true,
            isLoading: true,
        })
    }

    const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, `${component} getInfiniteRandomListMobile`)

    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    const formData: any = new FormData()
    const newIds_to_lahoun = changingView ? [0] : listState.ids_to_lahoun
    formData.append('ids_to_lahoun', newIds_to_lahoun.join(','))
    if (listState.clusterIds) {
        formData.append('ids_cluster', listState.clusterIds.join(','))
    }
    if ((apiUrl).includes('?')) {
        apiUrl += `&lahoun_length=${newIds_to_lahoun.length}`
    } else {
        apiUrl += `?lahoun_length=${newIds_to_lahoun.length}`
    }

    if (process.env.NODE_ENV === 'development') console.log(apiUrl)

    axios({
        data: formData,
        headers: refreshAxiosHeaders,
        method: 'post',
        url: apiUrl,
        cancelToken: source.token,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            const dataResults = getShuffleArray(response.data.results)
            let idsArray: any[] = []
            if (changingView) {
                dataResults.map((valh: any) => {
                    idsArray.push(valh.id)
                    return false
                })
            } else {
                idsArray = listState.ids_to_lahoun
                dataResults.map((valh: any) => {
                    idsArray.push(valh.id)
                    return false
                })
            }
            const dataToReturn = changingView ? dataResults : [...listState.items, ...dataResults]
            setListState({
                ...listState,
                componentString: componentName,
                disableInfiniteScroll: response.data.results.length === 0,
                hasMore: response.data.results.length > 0,
                ids_to_lahoun: idsArray,
                isLoading: false,
                items: dataToReturn,
            })
            dispatch(reduxModalSliderSetData(listState.modalKey, dataToReturn, apiUrl))
            if (refreshEvent && refreshEvent.detail) {
                refreshEvent.detail.complete()
            }
        })
        .catch((error) => {
            if (error.message === 'axios canceled') {
                if (process.env.NODE_ENV === 'development') console.log(error)
                return
            }
            if (refreshEvent && refreshEvent.detail) {
                refreshEvent.detail.complete()
            }
            if (errorMessageToSkip.includes(error.message)) {
                setListState({
                    ...listState,
                    disableInfiniteScroll: false,
                    isLoading: false,
                })
            } else if (errorMessageToSkip.includes(error)) {
                setListState({
                    ...listState,
                    disableInfiniteScroll: false,
                    isLoading: false,
                })
            } else {
                setListState({
                    ...listState,
                    disableInfiniteScroll: true,
                    isLoading: false,
                })
            }
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'getInfiniteRandomListMobile',
            })
        })
}
