// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextAreaInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
    ProjectFormState,
} from 'serializers/web'

// props
type DescriptionProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    mother: ProjectFormState['mother']
    onChange: any
}

// main
export const DescriptionProjectInput: React.FC<DescriptionProjectInputProps> = React.memo(({
    errors,
    fields,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextAreaInput
            error={errors?.description}
            label={reduxText[4381]}
            name='description'
            onChange={onChange}
            optional
            translation={{
                contentType: 'project',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            value={fields.description || ''}
        />
    )
})
