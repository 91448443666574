// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    MaterialActionSheetMobile,
} from 'pages'

// serializers
import {
    MaterialListSerializer,
} from 'serializers/web'

// props
type MaterialListMediumBlockMobileProps = {
    edit: boolean
    object: MaterialListSerializer
}

// main
export const MaterialListMediumBlockMobile: React.FC<MaterialListMediumBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [isActionSheetOpen, setActionSheetIsOpen] = useState(false)

    return (
        <div
            className='material-list-medium-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='material'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <div className='mlmb-cover'>
                <LinkHelper
                    className='mlmb-cover-wrap'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    <div className='mlmb-image-wrap'>
                        <ImageHelper
                            alt={object.name}
                            className='mlmb-image'
                            dominant_color={undefined}
                            lazyMobile={false}
                            src={object.get_image_lq}
                        />
                    </div>
                </LinkHelper>
            </div>
            <div className='mlmb-info'>
                <LinkHelper
                    className='mo-list-block-name-mobile medium'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    {object.name}
                </LinkHelper>
                <div className='mlmb-price-wrap'>
                    {object.price && (
                        <p className='mlmb-price'>{`${object.price} ${object.currency?.symbol || object.currency?.iso_code} / ${object.uom}`}</p>
                    )}
                    {!object.price && <p className='mlmb-price'>{reduxText[4869]}</p>}
                </div>
                <div className='mlmb-countries'>
                    <MainChipListBlock>
                        {object.profile?.countries?.map((val) => (
                            <MainCountryFlag
                                key={val.id}
                                edit={edit}
                                object={val}
                            />
                        ))}
                        {object.profile?.cities?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
                <div className='list-block-footer'>
                    {object.profile && (
                        <ListProfileLink
                            blockSize='medium'
                            edit={edit}
                            profile={object.profile}
                        />
                    )}
                </div>
            </div>
            <div className='mlmb-actions'>
                <IconBlock
                    edit={edit}
                    iconClass='mo-new-icon-ellipsis-h-solid'
                    onClick={() => setActionSheetIsOpen(true)}
                />
            </div>
            {isActionSheetOpen && (
                <MaterialActionSheetMobile
                    object={object}
                    setActivated={setActivated}
                    setIsOpen={setActionSheetIsOpen}
                />
            )}
        </div>
    )
})
