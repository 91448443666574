// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ActionInputPageContent,
    CheckboxInputV2,
    ContentSourceExtraInputPageContent,
    ContentSourceInputPageContent,
    FileInputPageContent,
    FormSeparator,
    GeopositionInputPageContent,
    TextShortInputPageContent,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// props
type ContentPanel799Props = {
    formState: PortfolioFormState
    handleInputChange: any
    onRefresh: () => void
}

// main
export const ContentPanel799: React.FC<ContentPanel799Props> = React.memo(({
    formState,
    handleInputChange,
    onRefresh,
}) => {

    const dispatch = useDispatch()
    const reduxFormSitepermissions = useSelector((state: defaultReduxState) => state.reduxFormSite.permissions)

    const mother = formState.mother
    const content = formState.content

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentPanel799',
                'onHandleInputChange',
            ))
        }
    }

    function handleInputChangeDataJson(event: any) {
        try {
            const toReturnValue: {
                [key: string]: any
            } = Object.assign({}, content.fields?.data_json || {})
            if (!event.value) {
                delete toReturnValue[event.name]
            } else if (event.value.id) {
                toReturnValue[event.name] = event.value.id
            } else {
                toReturnValue[event.name] = event.value
            }
            onHandleInputChange({
                'name': 'data_json',
                'value': toReturnValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContentPanel799',
                'handleInputChangeDataJson',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    // Content
    const errorsInline = content.errors
    const fieldsInline = content.fields

    if (!fieldsMother || !fieldsInline) return null

    const permissionIsDisabledRest = reduxFormSitepermissions?.permissionIsDisabledRest
    const permissionIsDisabledText = reduxFormSitepermissions?.permissionIsDisabledText

    return (
        <React.Fragment>
            <FormSeparator
                text='Content'  // TO_TEXT
            />
            <ContentSourceInputPageContent
                disabled={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                onHandleInputChange={onHandleInputChange}
            />
            <ContentSourceExtraInputPageContent
                disabled={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                onHandleInputChange={onHandleInputChange}
            />
            {fieldsInline.content_source?.id === 20 && (
                <GeopositionInputPageContent
                    errorsInline={errorsInline}
                    fieldsInline={fieldsInline}
                    inputObject={{
                        id: 0,
                        label: 'Address', // TO_TEXT
                    }}
                    onHandleInputChange={onHandleInputChange}
                />
            )}
            <FormSeparator
                text='Actions'  // TO_TEXT
            />
            <ActionInputPageContent
                disabled={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                onHandleInputChange={onHandleInputChange}
                options={fieldsInline.content_source?.id === 20 ? 'actionOptionsCustom' : 'actionOptionsDetail'}
            />
            <FormSeparator
                text='Design'  // TO_TEXT
            />
            <TextShortInputPageContent
                detailUrl={content.detailUrl}
                disabled={permissionIsDisabledRest}
                disabledText={permissionIsDisabledText}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                fieldsMother={fieldsMother}
                fonts={fieldsMother.fonts}
                inputObject={{
                    id: 0,
                    label: 'Tooltip text', // TO_TEXT
                }}
                onHandleInputChange={onHandleInputChange}
                onRefresh={onRefresh}
                optional={false}
                text_short_mode={fieldsInline.content_source?.id === 20 ? 2 : 4}
                updateUrl={content.updateUrl}
            />
            <FileInputPageContent  // TODO check autorized file types
                disabled={permissionIsDisabledRest}
                errorsInline={errorsInline}
                fieldsInline={fieldsInline}
                inputObject={{
                    id: 0,
                    label: 'Custom marker icon', // TO_TEXT
                }}
                mixedMedia={false}
                onHandleInputChange={onHandleInputChange}
            />
            {fieldsInline.content_source?.id === 12 && (
                <>
                    <FormSeparator
                        text='Advanced settings'  // TO_TEXT
                    />
                    <CheckboxInputV2
                        disabled={permissionIsDisabledRest}
                        error={errorsInline?.data_json}
                        label='use_countries_2'  // TO_TEXT
                        name='use_countries_2'
                        onChange={handleInputChangeDataJson}
                        value={fieldsInline.data_json?.use_countries_2 || ''}
                    />
                </>
            )}
        </React.Fragment>
    )
})
