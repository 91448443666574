// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    CheckboxInput,
    InputSeparator,
    SelectListInput,
    TextInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    InlineFormPanelProps,
    ProductFormState,
} from 'serializers/web'

// props
type VariantProductPanelProps = {
    formState: ProductFormState
    setFormState: React.Dispatch<ProductFormState>
}

// main
export const VariantProductPanel: React.FC<InlineFormPanelProps & VariantProductPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const variant = formState.variant

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, variant)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'VariantProductPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = variant.errors
    const fieldsInline = variant.fields

    if (!fieldsInline) return null

    // Extra
    const optionsIsUnique = [
        { id: 1, name: reduxText[8024] },  // Available
        { id: 2, name: reduxText[8025] },  // Sold
        { id: 5, name: reduxText[8026] },  // Not available
    ]
    const optionsIsNotUnique = [
        { id: 1, name: reduxText[8024] },  // Available
        { id: 3, name: reduxText[8027] },  // Sold out
        { id: 4, name: reduxText[4506] },  // Out of stock
        { id: 5, name: reduxText[8026] },  // Not available
    ]

    return (
        <InlineFormContainerWeb
            formInfo={variant}
            formState={formState}
            helpers={helpers}
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            <InputSeparator text={reduxText[4596]} />
            <TextInput
                error={errorsInline?.name}
                label={reduxText[5559]}
                name='name'
                onChange={onHandleInputChange}
                value={fieldsInline.name || ''}
            />
            <TextInput
                error={errorsInline?.sku}
                label={reduxText[8769]}
                name='sku'
                onChange={onHandleInputChange}
                optional
                value={fieldsInline.sku || ''}
            />
            <InputSeparator text={reduxText[8705]} />
            <CheckboxInput
                error={errorsInline?.price_on_request}
                label={reduxText[4869]}
                name='price_on_request'
                onChange={onHandleInputChange}
                value={fieldsInline.price_on_request}
            />
            <TextInput
                conversion={{
                    contentType: 'productvariant',
                    defaultCurrency: fieldsMother.currency,
                    defaultDetailUrl: mother.detailUrl,
                    defaultUpdateUrl: mother.updateUrl,
                    objectId: fieldsInline.id,
                    profileId: fieldsMother.profile?.id,
                }}
                conversionDefaultValue={fieldsInline.unit_price}
                currencies={fieldsMother.currencies}
                disabled={fieldsInline.price_on_request}
                error={errorsInline?.unit_price}
                label={reduxText[4383]}
                name='unit_price'
                onChange={onHandleInputChange}
                type='number'
                value={fieldsInline.unit_price || ''}
            />
            <TextInput
                conversion={{
                    contentType: 'productvariant',
                    defaultCurrency: fieldsMother.currency,
                    defaultDetailUrl: mother.detailUrl,
                    defaultUpdateUrl: mother.updateUrl,
                    objectId: fieldsInline.id,
                    profileId: fieldsMother.profile?.id,
                }}
                conversionDefaultValue={fieldsInline.unit_price}
                currencies={fieldsMother.currencies}
                disabled={fieldsInline.price_on_request}
                error={errorsInline?.unit_price_open}
                helperText={reduxText[788710]}
                label={reduxText[7887]}
                name='unit_price_open'
                onChange={onHandleInputChange}
                type='number'
                value={fieldsInline.unit_price_open || ''}
            />
            <TextInput
                disabled={fieldsInline.price_on_request}
                error={errorsInline?.packaging_weight}
                helperText={reduxText[784510]}
                label={reduxText[7845]}
                name='packaging_weight'
                onChange={onHandleInputChange}
                type='number'
                value={fieldsInline.packaging_weight || ''}
            />
            <InputSeparator text={reduxText[8706]} />
            <SelectListInput
                clearable={false}
                disabled={fieldsInline.has_stock_management}
                error={errorsInline?.stock_status}
                helperText={reduxText[802910]}
                label={reduxText[8029]}
                name='stock_status'
                onChange={onHandleInputChange}
                options={fieldsInline.is_unique ? optionsIsUnique : optionsIsNotUnique}
                value={fieldsInline.stock_status || ''}
            />
            <CheckboxInput
                error={errorsInline?.is_unique}
                label={reduxText[8031]}
                name='is_unique'
                onChange={onHandleInputChange}
                value={fieldsInline.is_unique}
            />
            <InputSeparator text={reduxText[8707]} />
            <CheckboxInput
                error={errorsInline?.has_stock_management}
                helperText={reduxText[803310]}
                label={reduxText[8033]}
                name='has_stock_management'
                onChange={onHandleInputChange}
                value={fieldsInline.has_stock_management}
            />
            <TextInput
                disabled={!fieldsInline.has_stock_management}
                error={errorsInline?.stock}
                helperText={reduxText[803510]}
                label={reduxText[8035]}
                name='stock'
                onChange={onHandleInputChange}
                type='number'
                value={fieldsInline.stock || ''}
            />
            <CheckboxInput
                disabled={!fieldsInline.has_stock_management}
                error={errorsInline?.accept_backorders}
                helperText={reduxText[803710]}
                label={reduxText[8037]}
                name='accept_backorders'
                onChange={onHandleInputChange}
                value={fieldsInline.accept_backorders}
            />
        </InlineFormContainerWeb>
    )
})
