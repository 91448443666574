// data
import {
    api_url_project_form_active,
    api_url_project_form_create,
    api_url_project_form_detail,
    api_url_project_form_update,
    reduxAuthState,
    view_url_press_form,
    view_url_project_detail,
    view_url_project_form,
    view_url_resource_form,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function ProjectMotherFormState(reduxAuth: reduxAuthState, projectType: string) {
    const projectTypeDict: any = {
        'project': view_url_project_form,
        'press': view_url_press_form,
        'resource': view_url_resource_form,
    }
    return {
        name: 'mother',

        // Settings
        detailComponent: 'ProjectDetailBlockWeb',
        model: 'project',

        // Urls
        activeUrl: getApiUrl(api_url_project_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_project_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_project_form_detail, reduxAuth),
        formUrl: projectTypeDict[projectType] || view_url_project_form,
        updateUrl: getApiUrl(api_url_project_form_update, reduxAuth),
        viewUrl: view_url_project_detail,

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'name',
        ],
        requiredForeignKey: [
            'medium_type',
            'profile',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'active',
            'cover_image_2_alt',
            'cover_image_2_doka_output_data',
            'cover_image_alt',
            'cover_image_doka_output_data',
            'cover_image_square_doka_output_data',
            'custom_text',
            'custom_text_2',
            'custom_text_3',
            'custom_text_rich',
            'custom_text_rich_2',
            'custom_text_rich_3',
            'date',
            'date_end',
            'description',
            'duration', // Automatic from getMediaData
            'is_multiple',
            'is_private',
            'is_private_and_listed_in_portfolio',
            'is_unlisted',
            'link_id', // Can be automatic from getMediaData
            'link_url',
            'lyrics',
            'member_name',
            'meta_image_url',
            'name',
        ],
        formFile: [
            'cover_image',
            'cover_image_2',
            'cover_image_2_original',
            'cover_image_original',
            'cover_image_square',
            'pdf_file',
        ],
        formForeignKey: [
            'credit_type',
            'date_type_choice',
            'label',
            'medium_type',
            'member',
            'portfolio_detail_page',
            'profile',
            'project_type',
        ],
        formJson: [],
        formJsonNull: [
            'address_json',
            'styles_extra',
        ],
        formManyToMany: [
            'custom_categories',
            'custom_categories_2',
            'custom_categories_3',
            'languages',
            'mixed_media_fonts',
            'portfolio_user_groups',
            'profile_sections',
            // 'related_projects',
            'themes',
        ],
    }
}
