// Root URL
const rootPath = 'offer/'

// Action
export const api_url_offer_action = `${rootPath}action/offer/`

// Detail
export const api_url_offer_detail = `${rootPath}detail/offer/`
export const api_url_offer_detail_short = `${rootPath}detail/offer/short/`

// Form Offer
export const api_url_offer_form_active = `${rootPath}form/offer/active/`
export const api_url_offer_form_create = `${rootPath}form/offer/create/`
export const api_url_offer_form_detail = `${rootPath}form/offer/detail/`
export const api_url_offer_form_duplicate = `${rootPath}form/offer/duplicate/`
export const api_url_offer_form_update = `${rootPath}form/offer/update/`

// Form Image
export const api_url_offer_image_form_active = `${rootPath}form/image/active/`
export const api_url_offer_image_form_create = `${rootPath}form/image/create/`
export const api_url_offer_image_form_detail = `${rootPath}form/image/detail/`
export const api_url_offer_image_form_list = `${rootPath}form/image/list/`
export const api_url_offer_image_form_patch = `${rootPath}form/image/patch/`
export const api_url_offer_image_form_update = `${rootPath}form/image/update/`

// List
export const api_url_offer_image_list = `${rootPath}list/image/`
export const api_url_offer_list = `${rootPath}list/offer/`

// Map
export const api_url_offer_map = `${rootPath}map/offer/`
