// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    SelectListInputV2,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type AuthTypeInputPageContentProps = {
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    inputObject?: LayoutFieldHelperSerializer
    onHandleInputChange: any
    optional: boolean
}

// main
export const AuthTypeInputPageContent: React.FC<AuthTypeInputPageContentProps> = React.memo(({
    errorsInline,
    fieldsInline,
    inputObject,
    onHandleInputChange,
    optional,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const options = [
        { id: 'n', name: reduxText[6416] },
        { id: 'co', name: reduxText[8588] },
        { id: 'con', name: reduxText[8589] },
    ]

    return (
        <React.Fragment>
            <SelectListInputV2
                clearable={false}
                error={errorsInline?.auth_type}
                helperText={inputObject?.helper_text}
                label={inputObject?.label || reduxText[8590]}
                name='auth_type'
                onChange={onHandleInputChange}
                options={options}
                value={fieldsInline.auth_type || ''}
            />
        </React.Fragment>
    )
})
