// react components
import React from 'react'

// data
import {
    contentTypeModelType,
    groupingIdsSite,
} from 'data'

// pages
import {
    TemplateBlockInceptionMixedMediaContent,
    TemplateBlockNormalMixedMediaContent,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'
import {
    ProjectFormState,
} from 'serializers/web'

// props
type ContentBlockEditMixedMediaContentProps = {
    contentType: contentTypeModelType
    mother: ProjectFormState['mother']
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    object: PortfolioPageContentListSiteSerializer
    setTabValue?: React.Dispatch<string>
}

// main
export const ContentBlockEditMixedMediaContent: React.FC<ContentBlockEditMixedMediaContentProps> = React.memo(({
    contentType,
    mother,
    newMixedMediaContent,
    newMixedMediaContentType,
    object,
    setTabValue,
}) => {

    if (groupingIdsSite.includes(object.layout!)) {
        return (
            <TemplateBlockInceptionMixedMediaContent
                contentType={contentType}
                isInList={false}
                mother={mother}
                newMixedMediaContent={newMixedMediaContent}
                newMixedMediaContentType={newMixedMediaContentType}
                object={object}
                parentStyles={object.styles}
                setTabValue={setTabValue}
            />
        )
    }
    return (
        <TemplateBlockNormalMixedMediaContent
            contentType={contentType}
            isInList={false}
            mother={mother}
            newMixedMediaContent={newMixedMediaContent}
            newMixedMediaContentType={newMixedMediaContentType}
            object={object}
            parentStyles={object.styles}
            setTabValue={setTabValue}
        />
    )
})
