export const view_url_member_detail = '/member/'
export const view_url_member_form = '/form/member/'
export const view_url_profile_detail = '/profile_new/'
export const view_url_profile_detail_old = '/profile/'
export const view_url_profile_form = '/form/profile/'

export const view_url_profile_portfolio_list = 'websites'
export const view_url_profile_subscription_list = 'subscription'

export const view_url_profile_about = 'about'
