// react components
import React from 'react'

// components
import {
    // CountViewBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
} from 'components'

// serializers
import {
    EventImageDetailSerializer,
} from 'serializers/web'

// props
type EventImageDetailBlockMobileProps = {
    edit: boolean
    object: EventImageDetailSerializer
}

// main
export const EventImageDetailBlockMobile: React.FC<EventImageDetailBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    return (
        <div className='event-image-detail-block'>
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    // @ts-ignore
                    model='eventimage'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <ImageHelper
                alt={object.name}
                className='eidb-cover-image'
                dominant_color={undefined}
                src={object.get_image_lq}
            />
            <LinkHelper
                className='eidb-event-info'
                edit={edit}
                to={object.event?.get_absolute_url}
            >
                <p className='eidb-name'>Image from {object.event?.name}</p>

                <ImageHelper
                    alt={object.event?.name}
                    className='eidb-cover-image'
                    dominant_color={undefined}
                    src={object.event?.get_image_lq}
                />
            </LinkHelper>
            {/* {object.owner_info && (
                <CountViewBlock
                    object={object.owner_info}
                />
            )} */}
        </div>
    )
})
