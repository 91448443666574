// react components
import React from 'react'
import {
    IonReactRouter,
} from '@ionic/react-router'
import {
    IonApp,
    IonRouterOutlet,
    setupIonicReact,
} from '@ionic/react'
import {
    ThemeProvider,
    createTheme,
} from '@mui/material'
import {
    DndProvider,
} from 'react-dnd'
import {
    HTML5Backend,
} from 'react-dnd-html5-backend'
import {
    Provider,
} from 'react-redux'
import {
    Route,
} from 'react-router-dom'

// components
import {
    ErrorAlert,
    ErrorBoundary,
    ErrorEventHandler,
    FormSuccessToast,
    GlobalHelperWeb,
    HeaderWrapper,
} from 'components'

// customers
import {
    FormsFormPage,
} from 'customers'

// data
import {
    secondary_color,
} from 'data'

// pages
import {
    DetailPage,
    NotFoundPage,
} from 'pages'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Theme variables */
import '../@static/web/index.scss'

/* Vendor css */
import 'leaflet/dist/leaflet.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-image-lightbox/style.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import configureStore from '../data/web/mo_store'

// Redux Constants
const store = configureStore()

// MUI style override
const darkTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    color: 'var(--mo-color-text1)',
                },
            },
        },
    },
    palette: {
        mode: 'dark',
        background: {
            default: '#181818',
        },
        secondary: {
            main: secondary_color,
        },
    },
    typography: {
        fontFamily: 'FuturaPT',
    },
})

setupIonicReact({
    mode: 'ios',
    spinner: 'crescent',
    swipeBackEnabled: false,
})

// main
export const AppForm: React.FC = () => (
    <Provider store={store}>
        <IonApp>
            <IonReactRouter>
                <ThemeProvider theme={darkTheme}>
                    <DndProvider backend={HTML5Backend}>
                        <ErrorBoundary>
                            <HeaderWrapper />
                            <IonRouterOutlet animated={false}>
                                {/* **************************************** Detail **************************************** */}
                                <Route exact path='/:customerSlug/:formId/' render={() => <FormsFormPage />} />
                                <Route component={NotFoundPage} />
                            </IonRouterOutlet>
                            <ErrorAlert />
                            <ErrorEventHandler />
                            <FormSuccessToast />
                            <GlobalHelperWeb />
                        </ErrorBoundary>
                    </DndProvider>
                </ThemeProvider>
            </IonReactRouter>
        </IonApp>
    </Provider>
)
