// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// containers
import {
    TabListContainerWeb,
} from 'containers'

// data
import {
    api_url_event_detail,
    api_url_event_list,
    api_url_event_map,
    api_url_material_detail,
    api_url_material_list,
    api_url_material_map,
    api_url_offer_detail,
    api_url_offer_list,
    api_url_offer_map,
    api_url_portfolio_detail,
    api_url_portfolio_list,
    api_url_portfolio_map,
    api_url_press_list,
    api_url_press_map,
    api_url_product_detail,
    api_url_product_list,
    api_url_product_map,
    api_url_profile_id_detail,
    api_url_profile_list,
    api_url_profile_map,
    api_url_project_detail,
    api_url_project_list,
    api_url_project_map,
    // api_url_resource_list,
    // api_url_resource_map,
    // api_url_service_detail,
    api_url_service_list,
    api_url_service_map,
    defaultReduxState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export const SearchPageWeb: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const filterParamsApiUrl = {
        profiles: {
            detailApiUrl: getApiUrl(api_url_profile_id_detail, reduxAuth),
            listApiUrl: getApiUrl(api_url_profile_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_profile_map, reduxAuth),
        },
        artworks: {
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            listApiUrl: getApiUrl(api_url_project_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_project_map, reduxAuth),
        },
        press: {
            detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
            listApiUrl: getApiUrl(api_url_press_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_press_map, reduxAuth),
        },
        products: {
            detailApiUrl: getApiUrl(api_url_product_detail, reduxAuth),
            listApiUrl: getApiUrl(api_url_product_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_product_map, reduxAuth),
        },
        events: {
            detailApiUrl: getApiUrl(api_url_event_detail, reduxAuth),
            listApiUrl: getApiUrl(api_url_event_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_event_map, reduxAuth),
        },
        offers: {
            detailApiUrl: getApiUrl(api_url_offer_detail, reduxAuth),
            listApiUrl: getApiUrl(api_url_offer_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_offer_map, reduxAuth),
        },
        materials: {
            detailApiUrl: getApiUrl(api_url_material_detail, reduxAuth),
            listApiUrl: getApiUrl(api_url_material_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_material_map, reduxAuth),
        },
        portfolios: {
            detailApiUrl: getApiUrl(api_url_portfolio_detail, reduxAuth),
            listApiUrl: getApiUrl(api_url_portfolio_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_portfolio_map, reduxAuth),
        },
        // resources: {
        //     detailApiUrl: getApiUrl(api_url_project_detail, reduxAuth),
        //     listApiUrl: getApiUrl(api_url_resource_list, reduxAuth),
        //     mapApiUrl: getApiUrl(api_url_resource_map, reduxAuth),
        // },
        services: {
            // detailApiUrl: getApiUrl(api_url_service_detail, reduxAuth),
            listApiUrl: getApiUrl(api_url_service_list, reduxAuth),
            mapApiUrl: getApiUrl(api_url_service_map, reduxAuth),
        },
    }

    return (
        <TabListContainerWeb
            defaultTab='profiles'
            endText
            filterParamsApiUrl={filterParamsApiUrl}
            random
            titleId={6227}
        />
    )
}
