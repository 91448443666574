// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    contentTypeData,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ChildText2ServiceInput,
    ChildTextServiceInput,
    CityTempServiceInput,
    DayServiceInput,
    HasCustomAddressServiceInput,
    TeamForm,
    TimeEndServiceInput,
    TimeStartServiceInput,
} from 'pages'

// serializers
import {
    InlineFormPanelProps,
    ServiceFormState,
} from 'serializers/web'

// props
type ChildServicePanelProps = {
    formState: ServiceFormState
    setFormState: React.Dispatch<ServiceFormState>
}

// main
export const ChildServicePanel: React.FC<InlineFormPanelProps & ChildServicePanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showTeamForm, setShowTeamForm] = useState(false)

    const mother = formState.mother
    const child = formState.child

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, child)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ChildServicePanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = child.errors
    const fieldsInline = child.fields

    if (!fieldsInline) return null

    return (
        <InlineFormContainerWeb
            formInfo={child}
            formState={formState}
            helpers={helpers}
            noOrdering
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            <DayServiceInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            <TimeStartServiceInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            <TimeEndServiceInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            <ChildTextServiceInput
                errors={errorsInline}
                fields={fieldsInline}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <ChildText2ServiceInput
                errors={errorsInline}
                fields={fieldsInline}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <HasCustomAddressServiceInput
                errors={errorsInline}
                fields={fieldsInline}
                onChange={onHandleInputChange}
            />
            {fieldsInline.has_custom_address && (
                <CityTempServiceInput
                    errors={errorsInline}
                    fields={fieldsInline}
                    mother={mother}
                    onChange={onHandleInputChange}
                />
            )}
            {fieldsInline?.id && (
                <Button
                    className='tfw-footer-button-full'
                    edit={false}
                    expand='full'
                    fill='outline'
                    onClick={() => setShowTeamForm(true)}
                    text={reduxText[8540]}
                />
            )}
            {showTeamForm && (
                <TeamForm
                    contentTypeId={contentTypeData['service']}
                    objectId={fieldsInline?.id!}
                    profileId={fieldsMother?.profile?.id!}
                    setIsOpen={setShowTeamForm}
                />
            )}
        </InlineFormContainerWeb>
    )
})
