// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_main_language_short,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// props
type LanguageFieldInputProps = {
    errors: { languages?: string } | undefined
    fields: { languages?: MainIdNameSerializer[], profile?: MainIdNameSerializer }
    label?: string
    onChange: any
}

// main
export const LanguageFieldInput: React.FC<LanguageFieldInputProps> = React.memo(({
    errors,
    fields,
    label,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <SelectListInput
            apiUrl={`${select_url_main_language_short}?profile=${fields.profile?.id}`}
            clearable={false}
            error={errors?.languages}
            label={label || reduxText[2945]}
            multi
            name='languages'
            onChange={onChange}
            optional
            value={fields.languages || ''}
        />
    )
})
