// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    IonActionSheet,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// data
import {
    api_url_event_action,
    api_url_event_form_active,
    api_url_event_team_form_accept,
    api_url_event_team_form_update,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
    view_url_event_form,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type EventActionSheetMobileProps = {
    object: any
    refreshData?: () => void
    setActivated: React.Dispatch<any> | undefined
    setIsOpen: React.Dispatch<any>
}

// main
export const EventActionSheetMobile: React.FC<EventActionSheetMobileProps> = React.memo(({
    object,
    refreshData,
    setActivated,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        getData(object.id)
    }, [object.id])

    type actionObjectType = {
        active?: boolean
        credit_status?: {
            id?: number
            accepted?: boolean
            active?: boolean
            not_in_profile?: boolean
        }
        has_permission?: boolean
    }

    const [buttons, setButtons] = useState<any[]>(
        [
            {
                role: 'cancel',
                text: reduxText[4519],
            },
        ]
    )

    async function getData(objectId: string) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EventActionSheetMobile getData')
            if (!objectId) return
            const getUrl = getApiUrl(`${api_url_event_action}${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!response.data) return
                    const actionObject: actionObjectType = response.data
                    const newButtons = []

                    // Permission
                    if (actionObject.has_permission) {
                        newButtons.push(
                            {
                                handler: () => handlePublish(actionObject.active),
                                role: actionObject.active ? 'destructive' : '',
                                text: actionObject.active ? reduxText[3517] : reduxText[3518],
                            },
                            {
                                handler: () => handleEdit(),
                                text: reduxText[2105],
                            }
                        )
                    }

                    // Credit
                    if (actionObject.credit_status?.id) {
                        newButtons.push(
                            {
                                handler: () => handleCreditAccept(actionObject.credit_status?.id, actionObject.credit_status?.accepted, actionObject.credit_status?.not_in_profile),
                                text: actionObject.credit_status.accepted ? (actionObject.credit_status.not_in_profile ? reduxText[5290] : reduxText[5289]) : reduxText[5661],
                            },
                            {
                                handler: () => handleCreditDelete(actionObject.credit_status?.id),
                                role: 'destructive',
                                text: actionObject.credit_status.active ? reduxText[5662] : reduxText[5663],
                            }
                        )
                    }

                    // All
                    newButtons.push(
                        {
                            role: 'cancel',
                            text: reduxText[4519],
                        },
                    )
                    setButtons(newButtons)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'EventActionSheetMobile',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventActionSheetMobile',
                'getData',
            ))
        }
    }

    async function handleCreditAccept(creditId?: number, accepted?: boolean, not_in_profile?: boolean) {
        try {
            if (process.env.NODE_ENV === 'development') console.log('handleCreditAccept')
            if (!creditId) return
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EventActionSheetMobile handleAccept')
            const putUrl = getApiUrl(`${api_url_event_team_form_accept}${creditId}/`, reduxAuth)
            if (putUrl) {
                const formData = new FormData()
                if (!accepted) {
                    formData.append('not_in_profile', 'false')
                } else {
                    formData.append('not_in_profile', not_in_profile ? 'false' : 'true')
                }

                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'put',
                    url: putUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        if (refreshData) refreshData()
                    })
                    .catch((error) => {
                        axiosErrorHandler({
                            apiUrl: putUrl,
                            component: 'EventActionSheetMobile',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleAccept',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventActionSheetMobile',
                'handleCreditAccept',
            ))
        }
    }

    async function handleCreditDelete(creditId?: number) {
        try {
            if (process.env.NODE_ENV === 'development') console.log('handleCreditDelete')
            if (!creditId) return
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EventActionSheetMobile handleDelete')
            const deleteUrl = getApiUrl(`${api_url_event_team_form_update}${creditId}/`, reduxAuth)
            if (deleteUrl) {
                axios({
                    headers: refreshAxiosHeaders,
                    method: 'delete',
                    url: deleteUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        if (refreshData) refreshData()
                    })
                    .catch((error) => {
                        axiosErrorHandler({
                            apiUrl: deleteUrl,
                            component: 'EventActionSheetMobile',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleDelete',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventActionSheetMobile',
                'handleCreditDelete',
            ))
        }
    }

    function handleEdit() {
        try {
            if (process.env.NODE_ENV === 'development') console.log('handleEdit')
            history.push(`${view_url_event_form}${object.id}/`)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventActionSheetMobile',
                'handleEdit',
            ))
        }
    }

    async function handlePublish(active?: boolean) {
        try {
            if (process.env.NODE_ENV === 'development') console.log('handlePublish')
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EventActionSheetMobile handlePublish')
            const formData = new FormData()
            formData.append('active', (!active).toString())
            const activeUrl = getApiUrl(`${api_url_event_form_active}${object.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (refreshData) refreshData()
                    dispatch(reduxFormSetIsSuccessToastOpen(true, active ? reduxText[6567] : reduxText[5901]))
                    if (setActivated) setActivated(!active)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'EventActionSheetMobile',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePublish',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventActionSheetMobile',
                'handlePublish',
            ))
        }
    }

    return (
        <IonActionSheet
            buttons={buttons}
            isOpen
            onDidDismiss={() => setIsOpen(false)}
        />
    )
})
