// react components
import React from 'react'

// components
import {
    MainHashtag,
    ProfileSectionBlock,
    ProfileShortInfoWeb,
    TranslationHelper,
} from 'components'

// data
import {
    contentTypeModelType,
} from 'data'

// serializers
import {
    AdminReturnSerializer,
    MainIdNameSerializer,
    ProfileSectionSerializer,
    ProfileShortInfoSerializer,
} from 'serializers/web'

// props
type HeaderDetailBlockProps = {
    children?: React.ReactNode
    contentType: contentTypeModelType
    edit: boolean
    languageId: number | undefined
    object: {
        name?: string
        owner_info?: {
            profile_section?: number
            profile_sections?: ProfileSectionSerializer[]
        }
        profile?: ProfileShortInfoSerializer
        project_type?: { id?: string, name?: string }
        return_info?: AdminReturnSerializer
        themes?: MainIdNameSerializer[]
        translation_languages?: MainIdNameSerializer[]

    }
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const HeaderDetailBlock: React.FC<HeaderDetailBlockProps> = React.memo(({
    children,
    contentType,
    edit,
    languageId,
    object,
    setLanguageId,
}) => {

    return (
        <div className='header-detail-block-web'>
            {object.translation_languages && (
                <TranslationHelper
                    edit={edit}
                    languageId={languageId}
                    languages={object.translation_languages}
                    setLanguageId={setLanguageId}
                />
            )}
            <ProfileSectionBlock
                contentType={contentType}
                edit={edit}
                profile_section={object.owner_info?.profile_section}
                profile_sections={object.owner_info?.profile_sections}
                profile={object.profile}
            />
            {object.name && (
                <div className='hdbw-name-block'>
                    <p className={`hdbw-name${languageId === 19 ? ' rtl' : ''}`}>{object.name}</p>
                </div>
            )}
            {object.themes?.length! > 0 && (
                <div className='hdbw-tags'>
                    {object.themes?.map((val) => (
                        <MainHashtag
                            key={val.id}
                            edit={edit}
                            label={val.name}
                        />
                    ))}
                </div>
            )}
            <div className='hdbw-profile-block'>
                {children
                    ? (
                        children
                    ) : (
                        <ProfileShortInfoWeb
                            edit={edit}
                            object={object.return_info || object.profile}
                        />
                    )
                }
            </div>
        </div>
    )
})
