// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxFormSetSectionIdSite,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    EditButtonPageContentChildren,
    ParentButtonPageContentChildren,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    contentId: string
    detailId: string
    pageSlug: string
    panelValue: string
}

type EditBlockPageContentPortfolioProps = {
    customPosition?: any
    hideButtons?: boolean
    isInComponent?: boolean
    mainParentId: number
    mixedMediaFormUrl?: string
    object: PortfolioPageContentListSiteSerializer
    parentArray: number[] | undefined
    parentStyles: any
    resetCustomPosition?: () => void
}

// main
export const EditBlockPageContentPortfolio: React.FC<EditBlockPageContentPortfolioProps> = React.memo(({
    customPosition,
    hideButtons,
    isInComponent,
    mainParentId,
    mixedMediaFormUrl,
    object,
    parentArray,
    parentStyles,
    resetCustomPosition,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSitenavbarHeight = useSelector((state: defaultReduxState) => state.reduxModalSite.navbarHeight)
    const reduxModalSitenavbarType = useSelector((state: defaultReduxState) => state.reduxModalSite.navbarType)

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [isPopoverOpen2, setIsPopoverOpen2] = useState(false)

    let editUrl = `${view_url_portfolio_form}${params.id}/content-container/${object.id}/`
    if (isInComponent) {
        editUrl += 'components-page/'
    } else if (params.pageSlug) {
        editUrl += `${params.pageSlug}/`
    } else {
        editUrl += `${reduxFormSite.portfolioObject?.home_page_slug}/`
    }
    if (params.detailId) editUrl += `${params.detailId}/`

    function handleEdit() {
        history.push(editUrl)
        dispatch(reduxFormSetSectionIdSite(mainParentId))
        if (Number(params.contentId) === object.id) scrollToSection(params.contentId, (reduxModalSitenavbarType === 'top' ? reduxModalSitenavbarHeight : 0))
    }

    let customPositionStyles = {}
    if (customPosition) {
        if (customPosition === 'leave') {
            customPositionStyles = {
                display: 'none',
            }
        } else {
            customPositionStyles = {
                height: `${customPosition.height}px`,
                left: `${customPosition.left}px`,
                position: 'absolute',
                top: `${customPosition.top}px`,
                width: `${customPosition.width}px`,
            }
        }
    }

    return (
        <div
            className={`edit-block-site${(isPopoverOpen || isPopoverOpen2) ? ' is-open' : ''}${reduxFormSite.navigationMode === 'preview' ? ' hidden' : ''}`}
            onMouseLeave={resetCustomPosition ? () => resetCustomPosition() : undefined}
            style={customPositionStyles}
        >
            <div
                className='ebs-link'
                onClick={() => mixedMediaFormUrl ? window.open(mixedMediaFormUrl, '_blank') : handleEdit()}
            />
            {!reduxFormSite.permissions?.permissionIsDisabledRest && !hideButtons && !mixedMediaFormUrl && (object?.id !== mainParentId) && (
                <div className='ebs-actions'>
                    <ParentButtonPageContentChildren
                        isPopoverOpen={isPopoverOpen}
                        mainParentId={mainParentId}
                        parentArray={parentArray}
                        setIsPopoverOpen={setIsPopoverOpen}
                    >
                        <button
                            className='ebs-button'
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                            <i className='mo-new-icon-table-cells-large-solid' />
                        </button>
                    </ParentButtonPageContentChildren>
                    <EditButtonPageContentChildren
                        allowDrop
                        isInComponent={isInComponent}
                        isPopoverOpen={isPopoverOpen2}
                        mainParentId={mainParentId}
                        object={object}
                        parentStyles={parentStyles}
                        setIsPopoverOpen={setIsPopoverOpen2}
                    >
                        <button
                            className='ebs-button'
                            onClick={() => setIsPopoverOpen2(!isPopoverOpen2)}
                        >
                            <i className='mo-new-icon-ellipsis-h-solid' />
                        </button>
                    </EditButtonPageContentChildren>
                </div>
            )}
        </div>
    )
})
