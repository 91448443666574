// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    SelectListInputV2,
    TextAreaInputV2,
    TextInputV2,
} from 'pages'

// serializers
import {
    LayoutFieldHelperSerializer,
    PortfolioPageContentDetailFormSerializer,
    PortfolioPageContentDetailFormSerializerError,
} from 'serializers/web'

// props
type ActionInputPageContentProps = {
    disabled?: boolean
    errorsInline: PortfolioPageContentDetailFormSerializerError | undefined
    fieldsInline: PortfolioPageContentDetailFormSerializer
    inputObject?: LayoutFieldHelperSerializer
    onHandleInputChange: any
    optional?: boolean
    options?: 'actionOptionsCustom' | 'actionOptionsDetail'
}

// main
export const ActionInputPageContent: React.FC<ActionInputPageContentProps> = React.memo(({
    disabled,
    errorsInline,
    fieldsInline,
    inputObject,
    onHandleInputChange,
    optional,
    options,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    // TO_LATER_TEXT
    const actionOptions = [
        { id: 'generic_link', name: 'generic_link' },
        { id: 'modal_link', name: 'modal_link' },
        { id: 'page_link', name: 'Page link' },
        { id: 'play', name: 'Play / Slider' },
        { id: 'href', name: 'href' },
        // { id: 'download', name: 'download' },
        // { id: 'filter_param', name: 'filter_param' },
        { id: 'language_switch', name: 'language_switch' },
        { id: 'dropdown', name: 'dropdown' },
        { id: 'submit_form', name: 'submit_form' },
        { id: 'tabs', name: 'tabs' },
        { id: 'search', name: 'search' },
        { id: 'logout', name: 'logout' },
        { id: 'currency_switch', name: 'currency_switch' },
        { id: 'hover_animation_only', name: 'hover_animation_only' },
    ]

    const actionOptionsAdmin = [
        { id: 'generic_link', name: 'generic_link' },
        { id: 'modal_link', name: 'modal_link' },
        { id: 'page_link', name: 'Page link' },
        { id: 'play', name: 'Play / Slider' },
        { id: 'href', name: 'href' },
        { id: 'download', name: 'download' },
        { id: 'filter_param', name: 'filter_param' },
        { id: 'language_switch', name: 'language_switch' },
        { id: 'dropdown', name: 'dropdown' },
        { id: 'submit_form', name: 'submit_form' },
        { id: 'tabs', name: 'tabs' },
        { id: 'search', name: 'search' },
        { id: 'logout', name: 'logout' },
        { id: 'currency_switch', name: 'currency_switch' },
        { id: 'hover_animation_only', name: 'hover_animation_only' },
    ]

    const actionOptionsCustom = [
        { id: 'page_link', name: 'Page link' },
        { id: 'href', name: 'href' },
    ]

    const actionOptionsCustomAdmin = [
        { id: 'page_link', name: 'Page link' },
        { id: 'page_link_content_object', name: 'Page link content object' },
        { id: 'href', name: 'href' },
        { id: 'close_menu', name: 'close_menu' },
        { id: 'close_popup', name: 'close_popup' },
        { id: 'logout', name: 'logout' },
    ]

    const actionOptionsDetail = [
        { id: 'generic_link', name: 'generic_link' },
        { id: 'modal_link', name: 'modal_link' },
        { id: 'page_link', name: 'Page link' },
        { id: 'play', name: 'Play / Slider' },
        { id: 'href', name: 'href' },
        // { id: 'filter_param', name: 'filter_param' },
        { id: 'language_switch', name: 'language_switch' },
        { id: 'search', name: 'search' },
        { id: 'currency_switch', name: 'currency_switch' },
        { id: 'hover_animation_only', name: 'hover_animation_only' },
    ]

    const actionOptionsDetailAdmin = [
        { id: 'generic_link', name: 'generic_link' },
        { id: 'modal_link', name: 'modal_link' },
        { id: 'page_link', name: 'Page link' },
        { id: 'play', name: 'Play / Slider' },
        { id: 'href', name: 'href' },
        { id: 'filter_param', name: 'filter_param' },
        { id: 'language_switch', name: 'language_switch' },
        { id: 'search', name: 'search' },
        { id: 'currency_switch', name: 'currency_switch' },
        { id: 'hover_animation_only', name: 'hover_animation_only' },
    ]

    let newActions = reduxAuth.settings?.user?.id === 1 ? actionOptionsAdmin : actionOptions
    if (options === 'actionOptionsCustom') newActions = reduxAuth.settings?.user?.id === 1 ? actionOptionsCustomAdmin : actionOptionsCustom
    if (options === 'actionOptionsDetail') newActions = reduxAuth.settings?.user?.id === 1 ? actionOptionsDetailAdmin : actionOptionsDetail

    return (
        <React.Fragment>
            <SelectListInputV2
                clearable
                disabled={disabled}
                error={errorsInline?.action}
                helperText={inputObject?.helper_text}
                label={inputObject?.label || reduxText[6311]}
                name='action'
                onChange={onHandleInputChange}
                options={newActions}
                value={fieldsInline.action || ''}
            />
            {([58, 59].includes(fieldsInline.content_source?.id!) || (fieldsInline.action?.id !== 'page_link' && fieldsInline.custom_page_link_params)) && (
                <TextAreaInputV2
                    disabled={disabled}
                    error={errorsInline?.custom_page_link_params}
                    label={reduxText[8339]}
                    name='custom_page_link_params'
                    onChange={onHandleInputChange}
                    value={fieldsInline.custom_page_link_params || ''}
                />
            )}
            {fieldsInline.action_special && (
                <TextInputV2
                    disabled
                    error={errorsInline?.action_special}
                    label='action_special'  // TO_TEXT
                    name='action_special'
                    onChange={onHandleInputChange}
                    value={fieldsInline.action_special || ''}
                />
            )}
        </React.Fragment>
    )
})
