// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
    Button,
    IconBlock,
    Loader,
    SelectAsyncInput,
    SelectListInput,
    TextInput,
} from 'components'

// data
import {
    api_url_event_team_form_request,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormHide,
    select_url_profile,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export const EventCreditRequestForm: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModal = useSelector((state: defaultReduxState) => state.reduxModal)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const objectId = reduxModal.mainForm.objectId

    type errorsType = {
        credit_type?: string
        member?: string
        roles?: string
    }
    type fieldsType = {
        credit_type?: MainIdNameSerializer
        member?: MainIdNameSerializer
        roles?: string
    }
    const errorsInitial = {
        credit_type: '',
        member: '',
        roles: '',
    }
    const fieldsInitial = {
        credit_type: undefined,
        member: reduxAuth.settings?.active_profile,
        roles: undefined,
    }

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })  // should be after setFields to avoid bug on TextArea
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventCreditRequestForm',
                'handleInputChange',
            ))
        }
    }

    async function onSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'EventCreditRequestForm onSubmit')

            const formData: any = new FormData()
            if (fields.credit_type?.id) {
                formData.append('credit_type', fields.credit_type.id)
            }
            formData.append('event', objectId)
            const createUrl = getApiUrl(api_url_event_team_form_request, reduxAuth)
            setIsLoading(true)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: createUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsSuccess(true)
                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    if (error.response) {
                        setErrors(error.response && error.response.data)
                    }
                    axiosErrorHandler({
                        apiUrl: createUrl,
                        component: 'EventCreditRequestForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSubmit',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EventCreditRequestForm',
                'onSubmit',
            ))
        }
    }

    // Extra
    const options = [
        { id: 1, name: reduxText[3585] },
        { id: 2, name: reduxText[317] },
        { id: 3, name: reduxText[4905] },
        { id: 4, name: reduxText[4680] },
        { id: 5, name: reduxText[5406] },
        { id: 6, name: reduxText[2947] },
    ]

    return (
        <React.Fragment>
            <DialogTitle className='mo-dialog-header'>
                {reduxText[5658]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => dispatch(reduxModalMainFormHide())}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <SelectAsyncInput
                    apiUrl={select_url_profile}
                    clearable={false}
                    creatable
                    disabled
                    error={errors?.member}
                    label={reduxText[2977]}
                    layout='profile'
                    name='member'
                    onChange={handleInputChange}
                    value={fields.member || ''}
                />
                <SelectListInput
                    clearable={false}
                    error={errors.credit_type}
                    inModal
                    label={reduxText[5299]}
                    name='credit_type'
                    onChange={handleInputChange}
                    optional
                    options={options}
                    value={fields.credit_type || ''}
                />
                <TextInput
                    error={errors.roles}
                    label={reduxText[2979]}
                    name='roles'
                    onChange={handleInputChange}
                    optional
                    value={fields.roles || ''}
                />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => dispatch(reduxModalMainFormHide())}
                    text={reduxText[4519]}
                />
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    fill='outline'
                    onClick={onSubmit}
                    text={reduxText[4520]}
                />
            </DialogActions>
            <Alert
                buttons={[{ text: reduxText[4742] }]}
                header={reduxText[5659]}
                isOpen={isSuccess}
                message={reduxText[5660]}
                onDidDismiss={() => dispatch(reduxModalMainFormHide())}
            />
            {isLoading && (
                <Loader isOpen />
            )}
        </React.Fragment>
    )
}
