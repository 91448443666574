// react components
import React, {
    useState,
} from 'react'
import {
    IonLabel,
    IonSegment,
    IonSegmentButton,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
} from 'data'

// pages
import {
    FontPortfolioPanel,
    StylePortfolioPanel,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// props
type ContainerPortfolioPanelProps = {
    formState: PortfolioFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    setFormState: React.Dispatch<PortfolioFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const ContainerPortfolioPanel: React.FC<ContainerPortfolioPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const reduxAuthisWindows = useSelector((state: defaultReduxState) => state.reduxAuth.isWindows)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    const [tabValue, setTabValue] = useState<string | number | undefined>('styles')

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <div className={`pfcw-container mo-hidden-vertical-scrollbar${reduxAuthisWindows}`}>
            <div className='pfcw-segment-wrap'>
                <IonSegment
                    className='pfcw-segment'
                    color=''
                    onIonChange={(e) => setTabValue(e.detail.value)}
                    value={tabValue}
                >
                    <IonSegmentButton
                        className='pfcw-tab-button'
                        value='settings'
                    >
                        <IonLabel className='pfcw-tab-label'>{reduxText[5332]}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                        className='pfcw-tab-button'
                        value='styles'
                    >
                        <IonLabel className='pfcw-tab-label'>{reduxText[6411]}</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </div>
            {tabValue === 'settings' && (
                <FontPortfolioPanel
                    formState={formState}
                    handleInputChange={handleInputChange}
                    helpers={helpers}
                    setFormState={setFormState}
                    setHelpers={setHelpers}
                />
            )}
            {tabValue === 'styles' && (
                <StylePortfolioPanel
                    formState={formState}
                />
            )}
        </div>
    )
})
