// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Alert,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// props
type DeleteAlertProps = {
    handleDelete: () => void
    objectName?: string
    setIsDeleteAlertOpen: React.Dispatch<boolean>
    setShowActionSheet?: any
}

// main
export const DeleteAlert: React.FC<DeleteAlertProps> = React.memo(({
    handleDelete,
    objectName,
    setIsDeleteAlertOpen,
    setShowActionSheet,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deleteAlertButtons = [
        {
            color: 'danger',
            handler: () => {
                handleDelete()
                if (setShowActionSheet) setShowActionSheet({ open: false, event: undefined })
            },
            text: reduxText[1241],
        },
        {
            text: reduxText[1236],
        },
    ]

    return (
        <Alert
            buttons={deleteAlertButtons}
            header={objectName ? `${reduxText[8708]}: "${objectName}" ?` : reduxText[1234]}
            isOpen
            message={reduxText[1235]}
            onDidDismiss={() => setIsDeleteAlertOpen(false)}
        />
    )
})
