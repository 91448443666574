// react components
import React, {
    useState,
} from 'react'
import {
    RefresherEventDetail,
} from '@ionic/core'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

// components
import {
    EmptyListSmallBlockMobile,
    LinkHelper,
} from 'components'

// data
import {
    api_url_profile_section_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_profile_detail_old,
} from 'data'

// pages
import {
    EventListBigBlockMobile,
    EventListMediumBlockMobile,
    EventListSmallBlockMobile,
    ImageListBlockMobile,
    MaterialListBigBlockMobile,
    MaterialListMediumBlockMobile,
    MaterialListSmallBlockMobile,
    OfferListBigBlockMobile,
    OfferListMediumBlockMobile,
    OfferListSmallBlockMobile,
    ProductListBigBlockMobile,
    ProductListMediumBlockMobile,
    ProductListSmallBlockMobile,
    ProfileListBigBlockMobile,
    ProfileListMediumBlockMobile,
    ProfileListSmallBlockMobile,
    ProjectListBigBlockMobile,
    ProjectListMediumBlockMobile,
    ProjectListSmallBlockMobile,
    TeamListBigBlockMobile,
    TeamListMediumBlockMobile,
    TeamListSmallBlockMobile,
} from 'pages'

// services
import {
    getApiUrl,
    getInfiniteListMobileNew,
    listStateInitialMobile,
    listStatePropsMobile,
} from 'services'

// serializers
import {
    ProfileHomeListSerializer,
} from 'serializers/web'

// props
type MatchParams = {
    slug: string
}

type ProfileHomeListBlockMobileProps = {
    object: ProfileHomeListSerializer
}

// main
export const ProfileHomeListBlockMobile: React.FC<ProfileHomeListBlockMobileProps> = React.memo(({
    object,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const tabSettings: any = {
        artwork: {
            bigListComponent: 'ProjectListBigBlockMobile',
            listTitle: reduxText[3554],
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
        },
        event: {
            bigListComponent: 'EventListBigBlockMobile',
            listTitle: reduxText[2433],
            mediumListComponent: 'EventListMediumBlockMobile',
            smallListComponent: 'EventListSmallBlockMobile',
        },
        material: {
            bigListComponent: 'MaterialListBigBlockMobile',
            listTitle: reduxText[301],
            mediumListComponent: 'MaterialListMediumBlockMobile',
            smallListComponent: 'MaterialListSmallBlockMobile',
        },
        member: {
            bigListComponent: 'TeamListBigBlockMobile',
            listTitle: reduxText[3077],
            mediumListComponent: 'TeamListMediumBlockMobile',
            smallListComponent: 'TeamListSmallBlockMobile',
        },
        offer: {
            bigListComponent: 'OfferListBigBlockMobile',
            listTitle: reduxText[302],
            mediumListComponent: 'OfferListMediumBlockMobile',
            smallListComponent: 'OfferListSmallBlockMobile',
        },
        press: {
            bigListComponent: 'ProjectListBigBlockMobile',
            listTitle: reduxText[4812],
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
        },
        product: {
            bigListComponent: 'ProductListBigBlockMobile',
            listTitle: reduxText[4696],
            mediumListComponent: 'ProductListMediumBlockMobile',
            smallListComponent: 'ProductListSmallBlockMobile',
        },
        project: {
            bigListComponent: 'ProjectListBigBlockMobile',
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
        },
        resouece: {
            bigListComponent: 'ProjectListBigBlockMobile',
            listTitle: reduxText[8144],
            mediumListComponent: 'ProjectListMediumBlockMobile',
            smallListComponent: 'ProjectListSmallBlockMobile',
        },
        service: {
            bigListComponent: 'ServiceListBigBlock',
            listTitle: reduxText[7434],
            mediumListComponent: 'ServiceListMediumBlock',
            smallListComponent: 'ServiceListSmallBlock',
        },
        team: {
            bigListComponent: 'TeamListBigBlockMobile',
            mediumListComponent: 'TeamListMediumBlockMobile',
            smallListComponent: 'TeamListSmallBlockMobile',
        },
    }

    const customSettings: any = {
        110: tabSettings.project,
        119: tabSettings.product,
        215: tabSettings.service,
        31: tabSettings.event,
        75: tabSettings.material,
        87: tabSettings.offer,
    }

    const components: any = {
        EventListBigBlockMobile,
        EventListMediumBlockMobile,
        EventListSmallBlockMobile,
        ImageListBlockMobile,
        MaterialListBigBlockMobile,
        MaterialListMediumBlockMobile,
        MaterialListSmallBlockMobile,
        OfferListBigBlockMobile,
        OfferListMediumBlockMobile,
        OfferListSmallBlockMobile,
        ProductListBigBlockMobile,
        ProductListMediumBlockMobile,
        ProductListSmallBlockMobile,
        ProfileListBigBlockMobile,
        ProfileListMediumBlockMobile,
        ProfileListSmallBlockMobile,
        ProjectListBigBlockMobile,
        ProjectListMediumBlockMobile,
        ProjectListSmallBlockMobile,
        TeamListBigBlockMobile,
        TeamListMediumBlockMobile,
        TeamListSmallBlockMobile,
    }

    let componentNameString: any
    if (object.name_id === 'custom') {
        if (object.component) {
            componentNameString = tabSettings[object.component]?.[`${object.thumb_size}ListComponent`]
        } else {
            componentNameString = customSettings[object.content_type]?.[`${object.thumb_size}ListComponent`]
        }
    } else {
        componentNameString = tabSettings[object.name_id]?.[`${object.thumb_size}ListComponent`]
    }

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [listState, setListState] = useState<listStatePropsMobile>({
        ...listStateInitialMobile,
        componentString: componentNameString,
        disableInfiniteScroll: false,
        modalKey: Math.random(),
    })

    const [initialLoad, setInitialLoad] = useState(true)

    const ComponentName = components[listState.componentString]

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        refreshEvent?: CustomEvent<RefresherEventDetail>,
    ) {
        try {
            getInfiniteListMobileNew(
                apiUrl,
                reduxAuth,
                listState,
                setListState,
                axiosCancelToken,
                setAxiosCancelToken,
                dispatch,
                'ProfileHomeListBlockMobile',
                changingView,
                refreshEvent,
                componentNameString,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileHomeListBlockMobile',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (listState.disableInfiniteScroll) return
            if (isVisible) {
                if (initialLoad) {
                    setInitialLoad(false)
                    onGetListData(getApiUrl(`${api_url_profile_section_detail}?section_id=${object.id}&page_size=4`, reduxAuth), true)
                } else {
                    onGetListData(listState.nextUrl, false)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileHomeListBlockMobile',
                'onSearchNext',
            ))
        }
    }

    if (!ComponentName && process.env.NODE_ENV !== 'development') return null

    if (!listState.isLoading && listState.items?.length === 0) return null

    return (
        <div className='profile-home-list-block-mobile'>
            <p className='phlbm-title'>
                <span>{object.name}</span>
                <LinkHelper
                    edit={false}
                    className='phlbm-see-all'
                    to={`${view_url_profile_detail_old}${params.slug}/sections/${object.id}/${object.main_parent ? `?main-parent=${object.main_parent}` : ''}${object.parent ? `&parent=${object.parent}` : ''}`}
                >
                    {reduxText[5913]}
                </LinkHelper>
            </p>
            <div className='phlbm-content'>
                {listState.items?.map((val: any) => (
                    <ComponentName
                        key={val.id}
                        edit={false}
                        modalKey={listState.modalKey}
                        object={val}
                        playerOrigin={`profileHomeListBlock-${object.id}`}
                    />
                ))}
                {listState.isLoading && (
                    <VisibilitySensor
                        intervalDelay={1000}
                        onChange={onSearchNext}
                        partialVisibility
                    >
                        <React.Fragment>
                            <EmptyListSmallBlockMobile />
                            <EmptyListSmallBlockMobile />
                            <EmptyListSmallBlockMobile />
                            <EmptyListSmallBlockMobile />
                        </React.Fragment>
                    </VisibilitySensor>
                )}
                {listState.hasMore && (
                    <div className='phlbm-show-more'>
                        <LinkHelper
                            edit={false}
                            className='phlbm-show-wrap'
                            to={`${view_url_profile_detail_old}${params.slug}/sections/${object.id}/${object.main_parent ? `?main-parent=${object.main_parent}` : ''}${object.parent ? `&parent=${object.parent}` : ''}`}
                        >
                            {reduxText[7435]}
                        </LinkHelper>
                    </div>
                )}
            </div>
        </div>
    )
})
