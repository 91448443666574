// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// components
import {
    Button,
    Loader,
    Popover,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
} from 'data'

// pages
import {
    EmailForm,
    FieldSectionModal,
    FieldSettingsModal,
    SubContentTypeForm,
} from 'pages'

// serializers
import {
    ContentTypeFieldTypes,
    ContentTypeSettingsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getModalAbsoluteUrlV2,
    onClickIsModal,
} from 'services'

// props
type TableActionProps = {
    canSendEmail?: boolean
    checkboxArray?: number[]
    columns?: string[]
    contentType: contentTypeModelType
    contentTypeSettings: ContentTypeSettingsTypes
    emailContentObjectId?: number
    emailContentType?: contentTypeModelType
    exportColumns: {
        columnName: string
        columnTitle: string
        fieldOptions: ContentTypeFieldTypes | undefined
    }[]
    exportListApiUrl: string
    inModal?: boolean
    isCustomOrdering?: boolean
    isCustomOrderingPossible?: boolean
    isOrdering?: boolean
    profile: ProfileDetailAdminSerializerV2 | undefined
    refreshData?: () => void
    setCustomColumns: (customColumns: any[]) => void
    setIsOrdering?: (isOrdering: boolean) => void
    subContentTypeId: number | undefined
    viewId?: number
}

// main
export const TableAction: React.FC<TableActionProps> = React.memo(({
    canSendEmail,
    checkboxArray,
    columns,
    contentType,
    contentTypeSettings,
    emailContentObjectId,
    emailContentType,
    exportColumns,
    exportListApiUrl,
    inModal,
    isCustomOrdering,
    isCustomOrderingPossible,
    isOrdering,
    profile,
    refreshData,
    setCustomColumns,
    setIsOrdering,
    subContentTypeId,
    viewId,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [buttons, setButtons] = useState<any[]>([])
    const [emailToList, setEmailToList] = useState<string[] | undefined>(undefined)
    const [fieldSectionIsOpen, setFieldSectionIsOpen] = useState<'export' | 'colums order' | undefined>(undefined)
    const [isFieldSettingsModal, setIsFieldSettingsModal] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [processMessage, setProcessMessage] = useState('')
    const [showActionPopover, setShowActionPopover] = useState(false)
    const [showSubContentTypeForm, setShowSubContentTypeForm] = useState(false)

    useEffect(() => {
        const newButtons = []
        newButtons.push(
            {
                handler: () => {
                    setFieldSectionIsOpen('colums order')
                    setShowActionPopover(false)
                },
                text: reduxText[10044],
            },
            {
                handler: () => {
                    setFieldSectionIsOpen('export')
                    setShowActionPopover(false)
                },
                text: reduxText[9834],
            },
        )
        if (canSendEmail) {
            newButtons.push(
                {
                    handler: () => {
                        handleSetEmails()
                        setShowActionPopover(false)
                    },
                    text: reduxText[10117],
                },
            )
        }
        if (reduxAuth.settings?.user?.id === 1 && isCustomOrderingPossible) {
            newButtons.push(
                {
                    handler: () => {
                        handleCustomOrdering()
                        setShowActionPopover(false)
                    },
                    text: `${isCustomOrdering ? reduxText[9835] : reduxText[9836]} - Superuser only`,
                },
            )
        }
        if (reduxAuth.settings?.user?.id === 1) {
            newButtons.push(
                {
                    handler: () => {
                        dispatch(reduxModalMainFormShow({
                            contentType: 'setup_moduleview',
                            cssClass: 'form-container-modal',
                            formComponent: 'FormModal',
                            // @ts-ignore
                            objectId: viewId,
                            refreshData,
                        }))
                        setShowActionPopover(false)
                    },
                    text: 'Edit - Superuser only',
                },
            )
            newButtons.push(
                {
                    handler: () => {
                        // @ts-ignore
                        onClickIsModal(viewId, Math.random(), dispatch)
                        history.push(getModalAbsoluteUrlV2({
                            contentType: 'setup_moduleview',
                            // @ts-ignore
                            objectId: viewId,
                            pageType: 'detail',
                        }))
                        setShowActionPopover(false)
                    },
                    text: 'Detail - Superuser only',
                },
            )
            if (viewId && !subContentTypeId) {
                newButtons.push(
                    {
                        handler: () => {
                            setShowSubContentTypeForm(true)
                            setShowActionPopover(false)
                        },
                        text: 'Create Sub Content Type - Superuser only',
                    },
                )
            }
            if (subContentTypeId) {
                newButtons.push(
                    {
                        handler: () => {
                            setIsFieldSettingsModal(true)
                            setShowActionPopover(false)
                        },
                        text: 'Edit List fields - Superuser only',
                    },
                )
            }
        }
        setButtons(newButtons)
    }, [
        checkboxArray,
        exportColumns,
        exportListApiUrl,
        isCustomOrdering,
        isCustomOrderingPossible,
        isOrdering,
    ])

    useEffect(() => {
        if (setIsOrdering) {
            setIsOrdering(false)
        }
    }, [
        viewId,
    ])

    async function handleCustomOrdering() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TableAction handleCustomOrdering')
            setProcessMessage(reduxText[4525])
            setIsProcessing(true)
            const patchUrl = getApiUrlV2(`setup/form/moduleview/update/${viewId}/`, reduxAuth)
            const formData: any = new FormData()
            formData.append('is_custom_ordering', !isCustomOrdering)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'patch',
                url: patchUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsProcessing(false)
                    if (refreshData) refreshData()
                })
                .catch((error) => {
                    setIsProcessing(false)
                    axiosErrorHandler({
                        apiUrl: patchUrl,
                        component: 'TableAction',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleCustomOrdering',
                    })
                })
        } catch (error) {
            setIsProcessing(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'TableAction',
                'handleCustomOrdering',
            ))
        }
    }

    async function handleSetEmails() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'TableAction handleSetEmails')
            setProcessMessage(reduxText[4525])
            setIsProcessing(true)
            const postUrl = getApiUrlV2(`main/helper/values/`, reduxAuth)
            const formData: any = new FormData()
            formData.append('api_list_url', exportListApiUrl)
            formData.append('columns', columns!.filter(item => item.includes('email')))
            formData.append('ids', checkboxArray!.join(','))
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setEmailToList(response.data)
                    setIsProcessing(false)
                })
                .catch((error) => {
                    setIsProcessing(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'TableAction',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleSetEmails',
                    })
                })
        } catch (error) {
            setIsProcessing(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'TableAction',
                'handleSetEmails',
            ))
        }
    }

    return (
        <div className='detail-tab-action TableAction'>
            <Popover
                buttons={buttons}
                edit={false}
                extraZIndex={inModal}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
            />
            {isCustomOrderingPossible && isCustomOrdering && setIsOrdering && (
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOrdering(!isOrdering)}
                    padding='0 0 0 10px'
                    size='x-small'
                    text={isOrdering ? reduxText[8334] : reduxText[8017]}
                />
            )}
            {isProcessing && (
                <Loader
                    isOpen
                    message={processMessage}
                />
            )}
            {fieldSectionIsOpen && (
                <FieldSectionModal
                    contentType={contentType}
                    contentTypeSettings={contentTypeSettings}
                    exportColumns={exportColumns}
                    exportListApiUrl={exportListApiUrl}
                    mode={fieldSectionIsOpen}
                    profile={profile}
                    setCustomColumns={setCustomColumns}
                    setIsOpen={setFieldSectionIsOpen}
                    subContentTypeId={subContentTypeId}
                />
            )}
            {emailToList && (
                <EmailForm
                    contentType={emailContentType!}
                    emailContentObjectId={emailContentObjectId!}
                    emailToList={emailToList!}
                    onClose={() => setEmailToList(undefined)}
                    profile={profile}
                />
            )}
            {showSubContentTypeForm && (
                <SubContentTypeForm
                    contentType={contentType}
                    onCloseHelperModal={setShowSubContentTypeForm}
                    onRefresh={refreshData}
                    viewId={viewId!}
                />
            )}
            {isFieldSettingsModal && (
                <FieldSettingsModal
                    contentType={contentType}
                    contentTypeSettings={contentTypeSettings}
                    profile={profile}
                    refreshData={refreshData}
                    setIsOpen={setIsFieldSettingsModal}
                    subContentTypeId={subContentTypeId}
                    viewId={viewId}
                />
            )}
        </div>
    )
})
