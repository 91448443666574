// react components
import React from 'react'

// components
import {
    DateTimeInput,
} from 'components'

// serializers
import {
    AdherentDetailFormSerializer,
    AdherentDetailFormSerializerError,
} from 'serializers/web'

// props
type CustomDate1AdherentInputProps = {
    errors: AdherentDetailFormSerializerError | undefined
    fields: AdherentDetailFormSerializer
    label: string
    onChange: any
}

// main
export const CustomDate1AdherentInput: React.FC<CustomDate1AdherentInputProps> = React.memo(({
    errors,
    fields,
    label,
    onChange,
}) => {
    return (
        <DateTimeInput
            error={errors?.custom_date_1}
            format='date'
            formatDate='dd/MM/yyyy'
            label={label}
            name='custom_date_1'
            onChange={onChange}
            value={fields.custom_date_1 || null}
        />
    )
})
