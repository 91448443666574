// react components
import axios from 'axios'

// data
import {
    contentTypeData,
    contentTypeModelType,
    reduxAuthState,
    reduxFormSetRefresh,
    reduxFormSetSectionIdSite,
    reduxFormStateSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    PortfolioPageContentFormState,
    ProjectMixedMediaContentFormState,
} from 'pages'

// serializers
import {
    BlockTemplateSelectSerializer,
} from 'serializers/web'
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// main
export async function addBlockContent({
    child,
    contentType,
    direction,
    dispatch,
    formUrl,
    history,
    mainParentId,
    mixedMedia,
    newMixedMediaContent,
    newMixedMediaContentType,
    object,
    paramsDetailId,
    paramsId,
    paramsPageSlug,
    reduxAuth,
    reduxFormSite,
    setIsLoading,
    setIsPopoverOpen,
    special,
    submitLayout,
}: {
    child: boolean
    contentType: contentTypeModelType | undefined
    direction: 'after' | 'before' | 'normal'
    dispatch: React.Dispatch<any>
    formUrl: string
    history: any
    mainParentId: number | undefined
    mixedMedia: boolean
    newMixedMediaContent: boolean
    newMixedMediaContentType: contentTypeModelType | undefined
    object: PortfolioPageContentListSiteSerializer
    paramsDetailId: string | undefined
    paramsId: string
    paramsPageSlug: string | undefined
    reduxAuth: reduxAuthState
    reduxFormSite: reduxFormStateSite | undefined
    setIsLoading: React.Dispatch<boolean>
    setIsPopoverOpen: React.Dispatch<boolean>
    special: 'symbol'
    submitLayout: BlockTemplateSelectSerializer
}) {
    try {

        const content = PortfolioPageContentFormState(reduxAuth)
        const mixedMediaContent = ProjectMixedMediaContentFormState(reduxAuth)

        setIsPopoverOpen(false)
        setIsLoading(true)
        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'addBlockContent handleSubmit')

        const formData: any = new FormData()
        const postUrl = mixedMedia ? mixedMediaContent.createUrl : content.createUrl
        if (child) {
            formData.append('parent_content', object.parent_content)
        }
        if (mixedMedia || (special === 'symbol')) {
            formData.append('template_content_source', submitLayout.id)
        } else {
            formData.append('template_content_source_mixed', submitLayout.id)
        }
        if (mixedMedia && contentType) {
            formData.append('object_id', paramsId)
            formData.append('content_type', contentTypeData[contentType])
        } else {
            formData.append('page', reduxFormSite!.pageObject?.id)
        }
        if (direction?.includes('before')) {
            formData.append('position', object.position)
        } else {
            formData.append('position', object.position! + 1)
        }
        if (special === 'symbol') {
            formData.append('symbol_content', submitLayout.id)
        }
        axios({
            data: formData,
            headers: refreshAxiosHeaders,
            method: 'post',
            url: postUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                setIsLoading(false)
                if (mixedMedia) {
                    if (newMixedMediaContent) {
                        history.push(`/form-mixed-media/${newMixedMediaContentType}/${paramsId}/${response.data.id}/`)
                    } else {
                        history.push(`${formUrl}${paramsId}/mixed-media-content/${response.data.id}/`)
                    }
                } else {
                    history.push(
                        `${formUrl}${reduxFormSite!.portfolioObject?.id}/content-container/${response.data.id}/${paramsPageSlug || reduxFormSite!.portfolioObject?.home_page_slug}/${paramsDetailId ? `${paramsDetailId}/` : ''}`,
                    )
                }
                dispatch(reduxFormSetRefresh('refreshPreview', Number(paramsId)))
                if (mainParentId) {
                    dispatch(reduxFormSetSectionIdSite(mainParentId))
                }
            })
            .catch((error) => {
                setIsLoading(false)
                axiosErrorHandler({
                    apiUrl: postUrl,
                    component: 'addBlockContent',
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'handleSubmit',
                })
            })
    } catch (error) {
        setIsLoading(false)
        dispatch(reduxModalErrorEventHandler(
            error,
            'addBlockContent',
            'handleSubmit',
        ))
    }
}
