// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Dialog,
} from '@mui/material'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

// components
import {
    ErrorHelper,
    IconBlock,
    ImageHelper,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    FormModal,
    FormLabel,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getApiUrlV2,
    getAxiosHeaders,
} from 'services'

// props
type SelectListInputV2Props = {
    adminOnly?: boolean
    apiUrl?: string | undefined
    clearable: boolean
    closeMenuOnSelect?: boolean
    creatable?: boolean
    defaultMenuIsOpen?: boolean
    disabled?: boolean
    disabledInputOnly?: boolean
    error?: string
    excludeArray?: number[]
    formContentType?: contentTypeModelType
    helperText?: string
    hideIfNone?: boolean
    label?: string
    labelNoOption?: string
    layout?: string
    marginBottom?: number
    multi?: boolean
    name: string
    newInterface?: any
    onChange: any
    openOnLoad?: boolean
    options?: any
    optionsSorted?: boolean
    placeholder?: string
    profileObject?: any
    showNone?: boolean
    staffOnly?: boolean
    v1?: boolean
    value: any
}

// main
export const SelectListInputV2: React.FC<SelectListInputV2Props> = React.memo(({
    adminOnly,
    apiUrl,
    clearable,
    closeMenuOnSelect = true,
    creatable,
    defaultMenuIsOpen,
    disabled,
    disabledInputOnly,
    error,
    excludeArray,
    formContentType,
    helperText,
    hideIfNone,
    label,
    labelNoOption,
    layout,
    marginBottom,
    multi,
    name,
    onChange,
    options,
    optionsSorted,
    placeholder,
    profileObject,
    showNone,
    staffOnly,
    v1,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [newOptions, setNewOptions] = useState<any[]>(options || [])
    const [newValue, setValue] = useState<any>(value)
    const [showHelperForm, setShowHelperForm] = useState(false)

    useEffect(() => {
        getData()
    }, [
        apiUrl,
        disabled,
        reduxForm.refresh.refreshCustomCategory,
        reduxForm.refresh.refreshCustomChoice,
    ])

    useEffect(() => {
        setValue(value)
    }, [
        value,
    ])

    useEffect(() => {
        if (options) {
            if (optionsSorted) {
                setNewOptions(options.sort((a: any, b: any) => a.name?.localeCompare(b.name)))
            } else {
                setNewOptions(options)
            }
        }
    }, [
        options,
    ])

    function onInputChange(value: any) {
        try {
            if (value === newValue) return
            setValue(value)
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectListInputV2',
                'onInputChange',
            ))
        }
    }

    async function getData() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SelectListInputV2 getData')
            if (options) return
            if (!apiUrl) return
            if (disabled) return
            if (adminOnly && !reduxAuth.settings?.user?.id) return
            if (staffOnly && !reduxAuth.settings?.user?.is_staff) return
            setNewOptions([])
            const listArray: any = []
            let getUrl = ''
            if (v1) {
                getUrl = getApiUrl(apiUrl, reduxAuth)
            } else {
                getUrl = getApiUrlV2(apiUrl, reduxAuth)
            }
            if (process.env.NODE_ENV === 'development') console.log(name, getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (Array.isArray(response.data)) {
                        response.data.map((val: any) => {
                            listArray.push(val)
                            return false
                        })
                        if (showNone) {
                            listArray.push({
                                id: 'None',
                                name: `No ${labelNoOption} || 'options`,  // TO_LATER_TEXT
                                slug: 'None',
                            })
                        }
                        if (optionsSorted) {
                            setNewOptions(listArray.sort((a: any, b: any) => a.name?.localeCompare(b.name)))
                        } else {
                            setNewOptions(listArray)
                        }
                    }
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'SelectListInputV2',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectListInputV2',
                'getData',
            ))
        }
    }

    const customStyles = {
        clearIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            border: '1px solid var(--mo-color-ln)',
            borderRadius: '8px',
            ':hover': {
                ...provided[':hover'],
                border: '1px solid var(--mo-color-ln)',
                boxShadow: 'none',
            },
            ':focus': {
                ...provided[':focus'],
                boxShadow: '0 0 0 2px var(--mo-color-black)',
                outline: '1px solid var(--mo-color-black)',
            },
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--mo-color-text1)',
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            borderRadius: '8px',
            boxShadow: '2px 6px 21px -2px rgba(0,0,0,0.75)',
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            boxShadow: '0 0 4px 2px rgba(var(--mo-color-mo-color-rgb), 0.70)',
            border: '1px solid var(--mo-color-mo-color)',
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            borderBottom: '1px solid var(--mo-color-ln)',
            color: state.isSelected ? 'var(--mo-color-mo-color)' : 'var(--mo-color-text1)',
            ':active': {
                ...provided[':active'],
                backgroundColor: 'var(--mo-color-bg2)',
                color: 'var(--mo-color-mo-color)',
            },
            ':hover': {
                ...provided[':hover'],
                backgroundColor: 'var(--mo-color-mo-color)',
                color: 'var(--mo-color-text1)',
                cursor: 'pointer',
            },
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--mo-color-text1)',
        }),
    }

    function profileOptions(option: any) {
        let textToReturn = ''
        if (option.cities?.length > 0) {
            textToReturn += `${option.cities.filter((obj: any) => obj.active).map((val: any) => (val.name)).join(' - ')}`
        }
        if (option.countries?.length > 0) {
            textToReturn += `${textToReturn ? ' - ' : ''}${option.countries.map((val: any) => (val.name)).join(' - ')}`
        }
        if (option.metiers) {
            textToReturn += `${textToReturn ? ' - ' : ''}${option.metiers}`
        }
        return textToReturn
    }

    function formatOptionLabel(option: any) {
        if (layout) {
            return (
                <div className='select-list-input-layout-custom-label'>
                    <div
                        className={`slilcl-lcl-image-wrap${(option.get_image_xs || option.get_image_lq) ? '' : ' zarma'}`}
                        style={{
                            borderRadius: ['profile'].includes(layout) ? '50%' : '0%',
                        }}
                    >
                        {(option.get_image_xs || option.get_image_lq)
                            ? (
                                <ImageHelper
                                    alt={option.name}
                                    className='slilcl-lcl-image'
                                    dominant_color={option.get_dominant_color}
                                    src={option.get_image_xs || option.get_image_lq}
                                />
                            ) : (
                                <span className='slilcl-image-zarma-name'>{option.name?.[0]}</span>
                            )}
                    </div>
                    <div className='slilcl-lcl-text-wrap'>
                        <p>{option.name}</p>
                        {layout !== 'portfolio' && (
                            option.profile && <p>{option.profile.name}</p>
                        )}
                        {layout === 'profile' && (
                            <React.Fragment>
                                <p className='slilcl-lcl-profile-text mo-clamp-web1'>
                                    {profileOptions(option)}
                                </p>
                                {option.profile_created_by && (
                                    <p className='slilcl-lcl-profile-text mo-clamp-web1'>
                                        {option.profile_created_by.name}
                                    </p>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )
        }
        return <p>{option.select_name || option.name}</p>
    }

    if (hideIfNone && newOptions.length === 0) return null
    if (adminOnly && !reduxAuth.settings?.user?.id) return null
    if (staffOnly && !reduxAuth.settings?.user?.is_staff) return null

    if (label) {
        return (
            <div
                className={`input-wrap${disabled ? ' disabled' : ''}`}
                style={{
                    marginBottom: marginBottom ? `${marginBottom}px` : undefined,
                }}
            >
                <div className='input-label-wrap'>
                    <FormLabel
                        adminOnly={adminOnly}
                        helperText={helperText}
                        label={label}
                        name={name}
                        staffOnly={staffOnly}
                    />
                </div>
                {creatable
                    ? (
                        <CreatableSelect
                            className={disabledInputOnly ? 'select-list-input-list-disabled' : ''}
                            closeMenuOnSelect={closeMenuOnSelect}
                            createOptionPosition='first'
                            defaultMenuIsOpen={defaultMenuIsOpen}
                            formatCreateLabel={(inputValue: any) => `${reduxText[2138]} "${inputValue}"`}
                            formatOptionLabel={formatOptionLabel}
                            getNewOptionData={(inputValue: any) => ({ id: `mo-created-option-${inputValue}`, name: inputValue, isNew: true })}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            isClearable={clearable}
                            isDisabled={disabled || disabledInputOnly}
                            isMulti={multi}
                            isValidNewOption={() => true}
                            menuPlacement='auto'
                            name={name}
                            onChange={onInputChange}
                            options={newOptions}
                            placeholder={placeholder || ''}
                            styles={customStyles}
                            value={newValue}
                        />
                    ) : (
                        <Select
                            className={disabledInputOnly ? 'select-list-input-list-disabled' : ''}
                            closeMenuOnSelect={closeMenuOnSelect}
                            defaultMenuIsOpen={defaultMenuIsOpen}
                            formatOptionLabel={formatOptionLabel}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            isClearable={clearable}
                            isDisabled={disabled || disabledInputOnly}
                            isMulti={multi}
                            menuPlacement='auto'
                            name={name}
                            onChange={onInputChange}
                            options={excludeArray ? newOptions.filter(obj => !excludeArray.includes(obj.id)) : newOptions}
                            placeholder={placeholder || ''}
                            styles={customStyles}
                            value={newValue}
                        />
                    )
                }
                <ErrorHelper error={error} />
            </div>
        )
    }

    return (
        <div className='text-normal-and-rich-input'>
            <div className='tnari-input-wrap'>
                {creatable
                    ? (
                        <CreatableSelect
                            className={disabledInputOnly ? 'select-list-input-list-disabled' : ''}
                            closeMenuOnSelect={closeMenuOnSelect}
                            createOptionPosition='first'
                            defaultMenuIsOpen={defaultMenuIsOpen}
                            formatCreateLabel={(inputValue: any) => `${reduxText[2138]} "${inputValue}"`}
                            formatOptionLabel={formatOptionLabel}
                            getNewOptionData={(inputValue: any) => ({ id: `mo-created-option-${inputValue}`, name: inputValue, isNew: true })}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            isClearable={clearable}
                            isDisabled={disabled || disabledInputOnly}
                            isMulti={multi}
                            isValidNewOption={() => true}
                            menuPlacement='auto'
                            name={name}
                            onChange={onInputChange}
                            options={newOptions}
                            placeholder={placeholder || ''}
                            styles={customStyles}
                            value={newValue}
                        />
                    ) : (
                        <Select
                            className={disabledInputOnly ? 'select-list-input-list-disabled' : ''}
                            closeMenuOnSelect={closeMenuOnSelect}
                            defaultMenuIsOpen={defaultMenuIsOpen}
                            formatOptionLabel={formatOptionLabel}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            isClearable={clearable}
                            isDisabled={disabled || disabledInputOnly}
                            isMulti={multi}
                            menuPlacement='auto'
                            name={name}
                            onChange={onInputChange}
                            options={excludeArray ? newOptions.filter(obj => !excludeArray.includes(obj.id)) : newOptions}
                            placeholder={placeholder || ''}
                            styles={customStyles}
                            value={newValue}
                        />
                    )
                }
            </div>
            {formContentType && (
                <div className='tnari-input-action'>
                    <IconBlock
                        className='tnari-icon'
                        edit={false}
                        iconClass='mo-new-icon-plus-solid'
                        onClick={() => setShowHelperForm(true)}
                    />
                </div>
            )}
            {showHelperForm && formContentType && (
                <Dialog
                    className={`mo-dialog-class form-container-modal`}
                    classes={{ paper: 'mo-dialog-wrapper wrapper' }}
                    disableEnforceFocus
                    maxWidth='xl'
                    onClose={() => setShowHelperForm(false)}
                    open
                >
                    <FormModal
                        contentType={formContentType}
                        contentTypeSettingsN={undefined}
                        objectId='new'
                        onCloseHelperModal={(e: boolean, bvalue: any) => {
                            setShowHelperForm(false)
                        }}
                        onRefresh={getData}
                        profileObject={profileObject}
                    />
                </Dialog>
            )}
        </div>
    )
})
