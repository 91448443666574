// Root URL
const rootPath = 'portfolio/select/'

// Portfolio Select
export const select_url_portfolio = `${rootPath}portfolio/`
export const select_url_portfolio_content_source = `${rootPath}content_source/`
export const select_url_portfolio_content_source_activity = `${rootPath}content_source/activity/`
export const select_url_portfolio_content_source_adherent = `${rootPath}content_source/adherent/`
export const select_url_portfolio_content_source_artwork = `${rootPath}content_source/artwork/`
export const select_url_portfolio_content_source_asset = `${rootPath}content_source/asset/`
export const select_url_portfolio_content_source_available = `${rootPath}content_source/available/`
export const select_url_portfolio_content_source_carte = `${rootPath}content_source/carte/`
export const select_url_portfolio_content_source_carte_category = `${rootPath}content_source/carte_category/`
export const select_url_portfolio_content_source_carte_item = `${rootPath}content_source/carte_item/`
export const select_url_portfolio_content_source_contribution = `${rootPath}content_source/contribution/`
export const select_url_portfolio_content_source_currency = `${rootPath}content_source/currency/`
export const select_url_portfolio_content_source_datapool_asset = `${rootPath}content_source/datapool_asset/`
export const select_url_portfolio_content_source_entity = `${rootPath}content_source/entity/`
export const select_url_portfolio_content_source_event = `${rootPath}content_source/event/`
export const select_url_portfolio_content_source_event_image = `${rootPath}content_source/event_image/`
export const select_url_portfolio_content_source_event_team = `${rootPath}content_source/event_team/`
export const select_url_portfolio_content_source_general_feed = `${rootPath}content_source/general_feed/`
export const select_url_portfolio_content_source_hub_content = `${rootPath}content_source/hub_content/`
export const select_url_portfolio_content_source_language = `${rootPath}content_source/language/`
export const select_url_portfolio_content_source_material = `${rootPath}content_source/material/`
export const select_url_portfolio_content_source_member = `${rootPath}content_source/member/`
export const select_url_portfolio_content_source_moduleview = `${rootPath}content_source/moduleview/`
export const select_url_portfolio_content_source_offer = `${rootPath}content_source/offer/`
export const select_url_portfolio_content_source_page = `${rootPath}content_source/page/`
export const select_url_portfolio_content_source_press = `${rootPath}content_source/press/`
export const select_url_portfolio_content_source_product = `${rootPath}content_source/product/`
export const select_url_portfolio_content_source_product_image = `${rootPath}content_source/product_image/`
export const select_url_portfolio_content_source_profile = `${rootPath}content_source/profile/`
export const select_url_portfolio_content_source_profile_image = `${rootPath}content_source/profile_image/`
export const select_url_portfolio_content_source_profile_section = `${rootPath}content_source/profile_section/`
export const select_url_portfolio_content_source_project = `${rootPath}content_source/project/`
export const select_url_portfolio_content_source_project_team = `${rootPath}content_source/project_team/`
export const select_url_portfolio_content_source_region = `${rootPath}content_source/region/`
export const select_url_portfolio_content_source_resource = `${rootPath}content_source/resource/`
export const select_url_portfolio_content_source_service = `${rootPath}content_source/service/`
export const select_url_portfolio_content_source_service_child = `${rootPath}content_source/service_child/`
export const select_url_portfolio_content_source_service_image = `${rootPath}content_source/service_image/`
export const select_url_portfolio_dynamic_text_option = `${rootPath}dynamic_text_option/`
export const select_url_portfolio_page_detail = `${rootPath}page/detail/`
export const select_url_portfolio_page_link = `${rootPath}page/link/`
export const select_url_portfolio_user_group_portfolio = `${rootPath}user_group_portfolio/`

// Portfolio Content Filter
export const select_url_portfolio_filter_content_source = `${rootPath}filter_content_source/`
export const select_url_portfolio_filter_field = `${rootPath}filter_field/`
export const select_url_portfolio_filter_related_content = `${rootPath}filter_related_content/`
