// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Popover,
} from 'components'

// data
import {
    api_url_profile_action,
    api_url_profile_form_active,
    api_url_profile_team_form_accept,
    api_url_profile_team_form_update,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
    reduxModalMainFormShow,
    view_url_profile_form,
} from 'data'

// pages
import {
    AddToSectionForm,
    OrganiseSectionForm,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    setStaffPermission,
} from 'services'

// props
type ProfileActionSheetWebProps = {
    edit: boolean
    inModal?: boolean
    object: any
    refreshData: () => void
    setActivated: React.Dispatch<boolean> | undefined
    setShowActionPopover: React.Dispatch<boolean>
    showActionPopover: boolean
}

// main
export const ProfileActionSheetWeb: React.FC<ProfileActionSheetWebProps> = React.memo(({
    edit,
    inModal,
    object,
    refreshData,
    setActivated,
    setShowActionPopover,
    showActionPopover,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (showActionPopover) getData(object.id)
    }, [
        object.id,
        showActionPopover,
    ])

    type actionObjectType = {
        active?: boolean
        credit_status?: {
            id?: number
            accepted?: boolean
            active?: boolean
            not_in_profile?: boolean
        }
        has_permission?: boolean
        original_image?: string
        staff_permission?: boolean
    }

    const [buttons, setButtons] = useState<any[]>([])
    const [showAddToSection, setShowAddToSection] = useState(false)
    const [showOrganiseForm, setShowOrganiseForm] = useState(false)

    async function getData(objectId: string) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileActionSheetWeb getData')

            if (!objectId) return
            const getUrl = getApiUrl(`${api_url_profile_action}${objectId}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    if (!response.data) return
                    const actionObject: actionObjectType = response.data
                    const newButtons = []

                    // Permission
                    if (actionObject.has_permission) {
                        newButtons.push(
                            {
                                text: reduxText[8326],
                                role: 'edit',
                                handler: () => {
                                    dispatch(reduxModalMainFormShow({
                                        cssClass: 'main-form-container-modal-web',
                                        formComponent: 'ProfileForm',
                                        objectId: object.id,
                                        refreshData,
                                    }))
                                    setShowActionPopover(false)
                                }
                            },
                            {
                                text: reduxText[2105],
                                role: 'edit',
                                to: `${view_url_profile_form}${object.id}/`,
                                handler: () => {
                                    setShowActionPopover(false)
                                }
                            },
                            {
                                text: actionObject.active ? reduxText[3517] : reduxText[3518],
                                role: actionObject.active ? 'destructive' : '',
                                handler: () => {
                                    handlePublish(actionObject.active)
                                    setShowActionPopover(false)
                                }
                            },
                            // {
                            //     text: reduxText[8683],
                            //     handler: () => {
                            //         setShowOrganiseForm(true)
                            //         setShowActionPopover(false)
                            //     }
                            // },
                        )
                    }
                    if (actionObject.has_permission && object?.owner_info?.member_id && object.profile_created_by?.slug) {
                        newButtons.push(
                            {
                                text: reduxText[6594],
                                handler: () => {
                                    handleAddToSection()
                                    setShowActionPopover(false)
                                }
                            },
                        )
                    }

                    // Credit
                    if (actionObject.credit_status?.id) {
                        newButtons.push(
                            {
                                text: actionObject.credit_status.accepted ? (actionObject.credit_status.not_in_profile ? reduxText[5290] : reduxText[5289]) : reduxText[5661],
                                handler: () => {
                                    handleCreditAccept(actionObject.credit_status?.id, actionObject.credit_status?.accepted, actionObject.credit_status?.not_in_profile)
                                    setShowActionPopover(false)
                                }
                            },
                            {
                                text: actionObject.credit_status.active ? reduxText[5662] : reduxText[5663],
                                role: 'destructive',
                                handler: () => {
                                    handleCreditDelete(actionObject.credit_status?.id)
                                    setShowActionPopover(false)
                                }
                            }
                        )
                        // } else {
                        //     newButtons.push(
                        //         {
                        //             text: reduxText[3934],
                        //             handler: () => {
                        //                 handleCreditJoin()
                        //                 setShowActionPopover(false)
                        //             }
                        //         }
                        //     )
                    }

                    // Staff Permission
                    if (reduxAuth.settings?.user?.groups?.includes(8)) {
                        newButtons.push(
                            {
                                text: actionObject.staff_permission ? 'Remove staff permission' : 'Set staff permission',
                                handler: () => {
                                    setStaffPermission({
                                        component: 'ProfileActionSheetWeb',
                                        contentTypeModel: 'profile',
                                        dispatch,
                                        objectId: object.id,
                                        reduxAuth,
                                        refreshData,
                                    })
                                    setShowActionPopover(false)
                                }
                            },
                        )
                    }

                    // Image
                    if (actionObject.original_image) {
                        newButtons.push(
                            {
                                text: reduxText[7855],
                                handler: () => window.open(actionObject.original_image, '_blank')
                            },
                        )
                    }

                    setButtons(newButtons)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ProfileActionSheetWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileActionSheetWeb',
                'getData',
            ))
        }
    }

    function handleAddToSection() {
        try {
            if (object.profile_created_by?.slug) {
                setShowAddToSection(true)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileActionSheetWeb',
                'handleAddToSection',
            ))
        }
    }

    async function handleCreditAccept(creditId?: number, accepted?: boolean, not_in_profile?: boolean) {
        try {
            if (!creditId) return
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileActionSheetWeb handleAccept')
            const putUrl = getApiUrl(`${api_url_profile_team_form_accept}${creditId}/`, reduxAuth)
            if (putUrl) {
                const formData = new FormData()
                if (!accepted) {
                    formData.append('not_in_profile', 'false')
                } else {
                    formData.append('not_in_profile', not_in_profile ? 'false' : 'true')
                }

                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'put',
                    url: putUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        refreshData()
                    })
                    .catch((error) => {
                        axiosErrorHandler({
                            apiUrl: putUrl,
                            component: 'ProfileActionSheetWeb',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleAccept',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileActionSheetWeb',
                'handleCreditAccept',
            ))
        }
    }

    async function handleCreditDelete(creditId?: number) {
        try {
            if (!creditId) return
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileActionSheetWeb handleDelete')
            const deleteUrl = getApiUrl(`${api_url_profile_team_form_update}${creditId}/`, reduxAuth)
            if (deleteUrl) {
                axios({
                    headers: refreshAxiosHeaders,
                    method: 'delete',
                    url: deleteUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        refreshData()
                    })
                    .catch((error) => {
                        axiosErrorHandler({
                            apiUrl: deleteUrl,
                            component: 'ProfileActionSheetWeb',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleDelete',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileActionSheetWeb',
                'handleCreditDelete',
            ))
        }
    }

    async function handlePublish(active?: boolean) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ProfileActionSheetWeb handlePublish')
            const formData = new FormData()
            formData.append('active', (!active).toString())
            const activeUrl = getApiUrl(`${api_url_profile_form_active}${object.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    refreshData()
                    dispatch(reduxFormSetIsSuccessToastOpen(true, active ? reduxText[6567] : reduxText[5901]))
                    if (setActivated) setActivated(!active)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'ProfileActionSheetWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePublish',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileActionSheetWeb',
                'handlePublish',
            ))
        }
    }

    return (
        <React.Fragment>
            <Popover
                buttons={buttons}
                edit={edit}
                extraZIndex={inModal}
                isOpen={showActionPopover}
                setIsOpen={setShowActionPopover}
            />
            {showAddToSection && (
                <AddToSectionForm
                    contentSource='member'
                    contentType='profileteam'
                    objectId={object?.owner_info?.member_id}
                    profileSlug={object.profile_created_by.slug}
                    refreshData={refreshData}
                    setIsOpen={setShowAddToSection}
                />
            )}
            {showOrganiseForm && (
                <OrganiseSectionForm
                    profileId={object.id}
                    refreshData={refreshData}
                    setIsOpen={setShowOrganiseForm}
                />
            )}
        </React.Fragment>
    )
})
