// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    ListSpinner,
    Loader,
} from 'components'

// data
import {
    api_url_portfolio_style_form_list,
    api_url_portfolio_style_form_update,
    defaultReduxState,
    reduxCacheSetPortfolioSite,
    reduxFormSetDataSite,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModaSetDeviceTypeSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    StyleInputHelper,
} from 'pages'

// serializers
import {
    PortfolioFormState,
    PortfolioGlobalStyleListFormSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDetailObject,
} from 'services'

// props
type MatchParams = {
    id: string
}

type StylePortfolioPanelProps = {
    formState: PortfolioFormState
}

// main
export const StylePortfolioPanel: React.FC<StylePortfolioPanelProps> = React.memo(({
    formState,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const mother = formState.mother

    useEffect(() => {
        onGetListData()
    }, [
        params.id
    ])

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [globalStyles, setGlobalStyles] = useState<PortfolioGlobalStyleListFormSerializer[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [stylesToUpdate, setStylesToUpdate] = useState<any>({})

    function onGetListData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_portfolio_style_form_list}${params.id}/?component=g`, reduxAuth),
                component: 'StylePortfolioPanel',
                dispatch,
                reduxAuth,
                setDetailObject: setGlobalStyles,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StylePortfolioPanel',
                'onGetListData',
            ))
        }
    }

    function onHandleInputChange(value: any, style: any, valueRef: string) {
        try {

            if (reduxFormSite.portfolioObject) {
                const toReturnValue = {
                    ...reduxFormSite.portfolioObject,
                    styles: {
                        ...reduxFormSite.portfolioObject.styles,
                        [valueRef]: {
                            // @ts-ignore
                            ...reduxFormSite.portfolioObject.styles[valueRef],
                            [value.name]: value.value,
                        }
                    }
                }
                const newGlobalStyleArray: any[] = []
                globalStyles.map((val) => {
                    if (val.id === style.id) {
                        // @ts-ignore
                        val[valueRef] = value.value
                    }
                    newGlobalStyleArray.push(val)
                    return false
                })
                setGlobalStyles(newGlobalStyleArray)
                if (toReturnValue) {
                    // Show style in preview
                    dispatch(reduxFormSetDataSite(
                        'portfolioObject',
                        toReturnValue,
                    ))
                    // @ts-ignore
                    dispatch(reduxCacheSetPortfolioSite('zarming', toReturnValue))
                    // Save to temporary object to save
                    setStylesToUpdate({
                        ...stylesToUpdate,
                        [style.id]: {
                            ...stylesToUpdate[style.id],
                            [valueRef]: value.value,
                        }
                    })
                    setButtonDisabled(false)
                    // if (!reduxFormSite.editingForm) dispatch(reduxFormSetEditingSite(true))
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'StylePortfolioPanel',
                'onHandleInputChange',
            ))
        }
    }

    async function onHandleSubmit() {
        const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'StylePortfolioPanel onHandleSubmit')
        if (Object.getOwnPropertyNames(stylesToUpdate).length > 0) {
            Object.getOwnPropertyNames(stylesToUpdate).map((val, i) => {
                const formData = new FormData()
                if (stylesToUpdate[val].hasOwnProperty('image')) {
                    if (stylesToUpdate[val].image.type) {
                        const filetoUpload = stylesToUpdate[val].image
                        let filetoUploadName = stylesToUpdate[val].image.name
                        if (filetoUploadName.length > 100) {
                            filetoUploadName = filetoUploadName.slice(filetoUploadName.length - 100)
                        }
                        formData.append('image', filetoUpload, filetoUploadName)
                    }
                }
                if (stylesToUpdate[val].hasOwnProperty('value')) formData.append('value', stylesToUpdate[val].value)
                if (stylesToUpdate[val].hasOwnProperty('value_MD')) formData.append('value_MD', stylesToUpdate[val].value_MD)
                if (stylesToUpdate[val].hasOwnProperty('value_SM')) formData.append('value_SM', stylesToUpdate[val].value_SM)
                const putUrl = getApiUrl(`${api_url_portfolio_style_form_update}${val}/`, reduxAuth)
                setIsSaving(true)
                axios({
                    data: formData,
                    headers: refreshAxiosHeaders,
                    method: 'patch',
                    url: putUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        if (i === (Object.getOwnPropertyNames(stylesToUpdate).length - 1)) {
                            dispatch(reduxFormSetRefresh('refreshPreview', reduxFormSite.portfolioObject?.id))
                            setIsSaving(false)
                            dispatch(reduxFormSetIsSuccessToastOpen(true))
                            setButtonDisabled(true)
                            setStylesToUpdate({})
                            dispatch(reduxFormSetEditingSite(false))
                        }
                    })
                    .catch((error) => {
                        setIsSaving(false)
                        axiosErrorHandler({
                            apiUrl: putUrl,
                            component: 'StylePortfolioPanel',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onHandleSubmit',
                        })
                    })
                return false
            })
        }
    }

    if (isLoading) {
        return (
            <ListSpinner
                color='tertiary'
                isLoading
            />
        )
    }

    // Mother
    const fieldsMother = mother.fields

    if (reduxFormSite.permissions?.permissionIsDisabledRest) {
        return (
            <div style={{
                padding: '20px',
            }}>
                <p>{reduxText[9975]}</p>
            </div>
        )
    }

    return (
        <div className={`pfcw-container mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className={`style-page-content-portfolio-panel-web mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className='spcppw-device'>
                    <div
                        className={`spcppw-device-div ${deviceType === 'is-web' && 'active'}`}
                        onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-web'))}
                    >
                        <i className='icon mo-new-icon-desktop-solid' />
                    </div>
                    <div
                        className={`spcppw-device-div ${deviceType === 'is-tablet' && 'active'}`}
                        onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-tablet'))}
                    >
                        <i className='icon mo-new-icon-tablet-alt-solid' />
                    </div>
                    <div
                        className={`spcppw-device-div ${deviceType === 'is-mobile' && 'active'}`}
                        onClick={() => dispatch(reduxModaSetDeviceTypeSite('is-mobile'))}
                    >
                        <i className='icon mo-new-icon-mobile-alt-solid' />
                    </div>
                </div>
                <div className={`spcppw-style-wrap mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {deviceType === 'is-web' && (
                        <div className='pfp-css-style-div'>
                            {globalStyles.map((style) => {
                                return (
                                    <StyleInputHelper
                                        key={`${deviceType}-${style.id}`}
                                        css_property={style.css_property}
                                        onChange={(e: any) => onHandleInputChange(e, style, 'value')}
                                        presetColors={fieldsMother?.preset_colors}
                                        value={style.value || ''}
                                        value_type='LG'
                                    />
                                )
                            })}
                        </div>
                    )}
                    {deviceType === 'is-tablet' && (
                        <div className='pfp-css-style-div'>
                            {globalStyles.map((style) => {
                                return (
                                    <StyleInputHelper
                                        key={`${deviceType}-${style.id}`}
                                        css_property={style.css_property}
                                        onChange={(e: any) => onHandleInputChange(e, style, 'value_MD')}
                                        presetColors={fieldsMother?.preset_colors}
                                        value={style.value_MD || ''}
                                        value_LG={style.value || ''}
                                        value_type='MD'
                                    />
                                )
                            })}
                        </div>
                    )}
                    {deviceType === 'is-mobile' && (
                        <div className='pfp-css-style-div'>
                            {globalStyles.map((style) => {
                                return (
                                    <StyleInputHelper
                                        key={`${deviceType}-${style.id}`}
                                        css_property={style.css_property}
                                        onChange={(e: any) => onHandleInputChange(e, style, 'value_SM')}
                                        presetColors={fieldsMother?.preset_colors}
                                        value={style.value_SM || ''}
                                        value_LG={style.value || ''}
                                        value_MD={style.value_MD || ''}
                                        value_type='SM'
                                    />
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
            <div className='pfcw-footer2'>
                <Button
                    disabled={buttonDisabled}
                    edit={false}
                    expand='full'
                    onClick={onHandleSubmit}
                    text={reduxText[4520]}
                />
            </div>
            {isSaving && (
                <Loader isOpen />
            )}
        </div>
    )
})
