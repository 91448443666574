// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
    ProjectFormState,
} from 'serializers/web'

// props
type PdfFileProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    mother: ProjectFormState['mother']
    onChange: any
}

// main
export const PdfFileProjectInput: React.FC<PdfFileProjectInputProps> = React.memo(({
    errors,
    fields,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <ImageInput
            error={errors?.pdf_file}
            label={reduxText[6602]}
            name='pdf_file'
            onChange={onChange}
            pdfOk
            pdfOnly
            text={reduxText[8302]}
            translation={{
                contentType: 'project',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            value={fields.pdf_file || ''}
        />
    )
})
