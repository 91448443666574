// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    CityTempProfileInput,
    ImageProfileInput,
    ImageSquareProfileInput,
    MetierProfileInput,
    NameProfileInput,
    OwnProfileProfileInput,
    ProfileCreatedByProfileInput,
    ProfileEmailProfileInput,
} from 'pages'

// serializers
import {
    ProfileFormState,
} from 'serializers/web'

// props
type MainProfilePanelProps = {
    formState: ProfileFormState
    handleInputChange: any
}

// main
export const MainProfilePanel: React.FC<MainProfilePanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainProfilePanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <ImageProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <NameProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <MetierProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CityTempProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <OwnProfileProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            {fieldsMother.own_profile === false && (
                <React.Fragment>
                    <ProfileCreatedByProfileInput
                        disabled={false}
                        errors={errorsMother}
                        fields={fieldsMother}
                        onChange={onHandleInputChange}
                    />
                    <ProfileEmailProfileInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        onChange={onHandleInputChange}
                    />
                </React.Fragment>
            )}
            <ImageSquareProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
