// Root URL
const rootPath = 'sales/'

// Action
export const api_url_product_action = `${rootPath}action/product/`
export const api_url_salesorder_action = `${rootPath}action/salesorder/`

// Detail
export const api_url_product_detail = `${rootPath}detail/product/`
export const api_url_product_detail_short = `${rootPath}detail/product/short/`
export const api_url_profile_subscription_detail = `${rootPath}stripe/profile_subscription/detail/`
export const api_url_salesorder_detail = `${rootPath}detail/salesorder/`
export const api_url_stripe_portal_create = `${rootPath}stripe/portal/create/`

// Form Product
export const api_url_product_form_active = `${rootPath}form/product/active/`
export const api_url_product_form_create_from_project = `${rootPath}form/product/create_from_project/`
export const api_url_product_form_create = `${rootPath}form/product/create/`
export const api_url_product_form_detail = `${rootPath}form/product/detail/`
export const api_url_product_form_duplicate = `${rootPath}form/product/duplicate/`
export const api_url_product_form_update = `${rootPath}form/product/update/`

// Form Image
export const api_url_product_image_form_active = `${rootPath}form/image/active/`
export const api_url_product_image_form_create = `${rootPath}form/image/create/`
export const api_url_product_image_form_detail = `${rootPath}form/image/detail/`
export const api_url_product_image_form_list = `${rootPath}form/image/list/`
export const api_url_product_image_form_patch = `${rootPath}form/image/patch/`
export const api_url_product_image_form_update = `${rootPath}form/image/update/`

// Form Sales Order
export const api_url_salesorder_form_sales_shipment = `${rootPath}form/salesorder/sales_shipment/`
export const api_url_salesorder_form_sales_status = `${rootPath}form/salesorder/sales_status/`

// Form Shipping agent
// export const api_url_shipping_agent_form_active = `${rootPath}form/shipping_agent/active/`
// export const api_url_shipping_agent_form_create = `${rootPath}form/shipping_agent/create/`
// export const api_url_shipping_agent_form_detail = `${rootPath}form/shipping_agent/detail/`
// export const api_url_shipping_agent_form_list = `${rootPath}form/shipping_agent/list/`
// export const api_url_shipping_agent_form_update = `${rootPath}form/shipping_agent/update/`

// Form Shipping cost
export const api_url_shipping_cost_form_create = `${rootPath}form/shipping_cost/create/`
export const api_url_shipping_cost_form_detail = `${rootPath}form/shipping_cost/detail/`
export const api_url_shipping_cost_form_list = `${rootPath}form/shipping_cost/list/`
export const api_url_shipping_cost_form_update = `${rootPath}form/shipping_cost/update/`

// Form Shipping service
// export const api_url_shipping_service_form_active = `${rootPath}form/shipping_service/active/`
// export const api_url_shipping_service_form_create = `${rootPath}form/shipping_service/create/`
// export const api_url_shipping_service_form_detail = `${rootPath}form/shipping_service/detail/`
// export const api_url_shipping_service_form_list = `${rootPath}form/shipping_service/list/`
// export const api_url_shipping_service_form_update = `${rootPath}form/shipping_service/update/`

// Form Shipping zone
// export const api_url_shipping_zone_form_active = `${rootPath}form/shipping_zone/active/`
// export const api_url_shipping_zone_form_create = `${rootPath}form/shipping_zone/create/`
// export const api_url_shipping_zone_form_detail = `${rootPath}form/shipping_zone/detail/`
// export const api_url_shipping_zone_form_list = `${rootPath}form/shipping_zone/list/`
// export const api_url_shipping_zone_form_update = `${rootPath}form/shipping_zone/update/`

// Form Team
export const api_url_product_team_form_accept = `${rootPath}form/team/accept/`
export const api_url_product_team_form_active = `${rootPath}form/team/active/`
export const api_url_product_team_form_create = `${rootPath}form/team/create/`
export const api_url_product_team_form_decline = `${rootPath}form/team/decline/`
export const api_url_product_team_form_detail = `${rootPath}form/team/detail/`
export const api_url_product_team_form_list = `${rootPath}form/team/list/`
export const api_url_product_team_form_patch = `${rootPath}form/team/patch/`
export const api_url_product_team_form_request = `${rootPath}form/team/request/`
export const api_url_product_team_form_update = `${rootPath}form/team/update/`

// Form Variant
export const api_url_product_variant_form_active = `${rootPath}form/variant/active/`
export const api_url_product_variant_form_create = `${rootPath}form/variant/create/`
export const api_url_product_variant_form_detail = `${rootPath}form/variant/detail/`
export const api_url_product_variant_form_list = `${rootPath}form/variant/list/`
export const api_url_product_variant_form_patch = `${rootPath}form/variant/patch/`
export const api_url_product_variant_form_update = `${rootPath}form/variant/update/`

// List
export const api_url_product_image_list = `${rootPath}list/image/`
export const api_url_product_list = `${rootPath}list/product/`
export const api_url_product_sales_recap_list = `${rootPath}list/product/sales_recap/`
export const api_url_product_stock_list = `${rootPath}list/product/stock/`
export const api_url_product_team_list = `${rootPath}list/product_team/`
export const api_url_salesorder_list = `${rootPath}list/salesorder/`
export const api_url_shipping_agent_list = `${rootPath}list/shipping_agent/`

// Map
export const api_url_product_map = `${rootPath}map/product/`
