// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainHashtag,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    EventActionSheetMobile,
} from 'pages'

// serializers
import {
    EventListSerializer,
} from 'serializers/web'

// services
import {
    getDateWithType,
    getSchedule,
} from 'services'

// props
type EventListBigBlockMobileProps = {
    edit: boolean
    feedCreatedOn?: string
    object: EventListSerializer
}

// main
export const EventListBigBlockMobile: React.FC<EventListBigBlockMobileProps> = React.memo(({
    edit,
    feedCreatedOn,
    object,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [isActionSheetOpen, setActionSheetIsOpen] = useState(false)

    return (
        <div
            className='event-list-big-block'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            {!activated && (
                <InactiveButton
                    edit={edit}
                    model='event'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={setDeleted}
                />
            )}
            <LinkHelper
                edit={edit}
                to={object.get_absolute_url}
                className='elbb-cover'>
                <ImageHelper
                    alt={object.name}
                    className='elbb-image'
                    dominant_color={undefined}
                    defaultHeightMobile
                    src={object.get_image_lq}
                />
            </LinkHelper>
            <div className='elbb-bottom'>
                {object.id && (
                    <div className='elbb-bottom-header'>
                        <span className='elbb-feed-date'>{feedCreatedOn ? getDateWithType(reduxAuth, feedCreatedOn, 'n') : ''}</span>
                        <div className='elbb-actions'>
                            <IconBlock
                                edit={edit}
                                iconClass='mo-new-icon-ellipsis-h-solid'
                                onClick={() => setActionSheetIsOpen(true)}
                            />
                        </div>
                    </div>
                )}
                <p className='elbb-date'>{object.start_date && getSchedule(reduxAuth, object.start_date, object.end_date, object.date_only, object.date_type_choice)}</p>
                <LinkHelper
                    className='mo-list-block-name-mobile'
                    edit={edit}
                    to={object.get_absolute_url}
                >
                    {object.name}
                </LinkHelper>
                <div className='elbb-tags'>
                    <MainChipListBlock>
                        {object.themes?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </MainChipListBlock>
                </div>
                {object.is_online_event && (
                    <p className='elbb-address'>{reduxText[6389]}</p>
                )}
                {object.address_json?.length! > 0 && (
                    <p className='elbb-address'>
                        <i className='elbb-icon mo-new-icon-map-marker-alt-solid' />
                        {object.address_json?.[0].description}
                    </p>
                )}
                <div className='list-block-footer'>
                    {object.profile && (
                        <ListProfileLink
                            blockSize='big'
                            edit={edit}
                            profile={object.profile}
                        />
                    )}
                </div>
            </div>
            {isActionSheetOpen && (
                <EventActionSheetMobile
                    object={object}
                    setActivated={setActivated}
                    setIsOpen={setActionSheetIsOpen}
                />
            )}
        </div>
    )
})
