// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
    MainCountryFlag,
} from 'components'

// data
import {
    api_url_shipping_agent_list,
    api_url_shipping_cost_form_list,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ShippingCostPanel,
} from 'pages'

// serializers
import {
    PortfolioDetailFormSerializer,
    ShippingAgentListSerializer,
    ShippingServiceHelperSerializer,
    ShippingZoneHelperSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
} from 'services'

// props
type ShippingModalFormProps = {
    currency: PortfolioDetailFormSerializer['currency']
    portfolio_id: number
    setIsOpen: React.Dispatch<boolean>
}

// main
export const ShippingModalForm: React.FC<ShippingModalFormProps> = React.memo(({
    currency,
    portfolio_id,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [items, setItems] = useState<ShippingAgentListSerializer[]>([])
    const [items2, setItems2] = useState<any[]>([])
    const [tab, setTab] = useState<'agent' | 'cost' | 'service' | 'zone'>()
    const [zone, setZone] = useState<ShippingZoneHelperSerializer>()
    const [service, setService] = useState<ShippingServiceHelperSerializer>()

    useEffect(() => {
        onGetDetailData()
    }, [])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_shipping_agent_list}?portfolio=${portfolio_id}`, reduxAuth),
                component: 'ShippingModalForm',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ShippingModalForm',
                'onGetDetailData',
            ))
        }
    }

    function onGetCostData(service: ShippingServiceHelperSerializer, zone: ShippingZoneHelperSerializer) {
        try {
            setService(service)
            setZone(zone)
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_shipping_cost_form_list}${service.id}/${zone.id}/`, reduxAuth),
                component: 'ShippingModalForm',
                dispatch,
                reduxAuth,
                setDetailObject: setItems2,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ShippingModalForm',
                'onGetCostData',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class shipping-modal-form-web-modal'
            classes={{ paper: 'mo-dialog-wrapper wrapper' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[8524]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content no-padding smfwm-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <div className={`smfwm-left mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {items.map(val => (
                        <div
                            key={val.id}
                            className='smfwm-agent-wrap'
                        >
                            <span className='smfwm-agent'>{val.name}</span>
                            {val.services?.map(val2 => (
                                <div
                                    key={val2.id}
                                    className='smfwm-service-wrap'
                                >
                                    <span className='smfwm-service'>{val2.name}</span>
                                    {val2.zones?.map(val3 => (
                                        <div
                                            key={val3.id}
                                            className='smfwm-zone-wrap'
                                        >
                                            <span
                                                className='smfwm-zone'
                                                onClick={() => {
                                                    setTab('cost')
                                                    onGetCostData(val2, val3)
                                                }}
                                            >
                                                {val3.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                {tab === 'cost' && (
                    <div className={`smfwm-middle mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        <p className='smfwm-zone-name'>{zone?.name}</p>
                        <div className='smfwm-zone-countries'>
                            {zone?.countries?.map((val: any) => (
                                <MainCountryFlag
                                    key={val.id}
                                    edit={false}
                                    namePosition='end'
                                    object={val}
                                    searchTab={undefined}
                                />
                            ))}
                        </div>
                    </div>
                )}
                <div className={`smfwm-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {tab === 'cost' && (
                        <React.Fragment>
                            <p className='smfwm-service-name'>{service?.name}</p>
                            {items2.map((val, i) => (
                                <ShippingCostPanel
                                    key={val.id}
                                    currency={currency}
                                    index={i}
                                    object={val}
                                    service={service!}
                                    zone={zone!}
                                />
                            ))}
                        </React.Fragment>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    )
})
