// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    getApiUrlV2,
    getDetailObject,
} from 'services'

// main
export const AgendaDuQuartierQuartierFormEnd: React.FC = React.memo(() => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [fields, setFields] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (reduxAuth.settings?.quartier?.id) {
            onGetDetailData()
        }
    }, [
        reduxAuth.settings?.quartier?.id,
    ])

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`customer/agendaduquartier/form/entity/detail/quartier/${reduxAuth.settings?.quartier?.id}/`, reduxAuth),
                component: 'AgendaDuQuartierQuartierFormEnd',
                dispatch,
                reduxAuth,
                setDetailObject: setFields,
                setIsLoading,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AgendaDuQuartierQuartierFormEnd',
                'onGetDetailData',
            ))
        }
    }

    const starting_month = reduxAuth.settings?.federateur?.custom_date_field_1
    let frenchMonth = ''

    if (starting_month) {
        const date = new Date(starting_month)
        date.setMonth(date.getMonth() - 1) // Subtract one month
        frenchMonth = new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(date)
    }

    const isQuartierCompleted = reduxAuth.settings?.quartier?.is_quartier_completed

    return (
        <div
            className='sub-content-type-90-form'
            style={{
                padding: '0 0 40px 0',
            }}
        >
            <div className='sct90f-block'>
                <div className='sct90f-hr-wrap'>
                    <div
                        className='sct90f-hr'
                        style={{
                            width: `${100}%`,
                        }}
                    >
                        <p>{isQuartierCompleted === false ? 'Profil Incomplet' : 'Profil Complet'}</p>
                    </div>
                </div>
                <div className='sct90f-block2'>
                    <hr />
                    <div className='sct90f-input-block'>
                        {isQuartierCompleted && (
                            <>
                                <div className='sct90f-title-block'>
                                    <p className='sct90f-title tac' style={{ width: '100%' }}>Profil complet ! Et la suite ?</p>
                                </div>
                                <div className='sct90f-content'>
                                    <div className='sct90f-helper'>
                                        <p style={{
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                        }}>Bravo, vous avez fait votre part. A nous de jouer d&eacute;sormais !</p>
                                        <br />
                                        <p>Voici les prochaines &eacute;tapes, compl&egrave;tement automatis&eacute;es, de votre Agenda du quartier :&nbsp;</p>
                                        <ul>
                                            <li>le 10 {frenchMonth}, les associations de votre liste recevront un courriel les appelant &agrave; publier leurs activit&eacute;s avant le 20. Vous recevrez une copie de ce courriel que nous vous inviterons &agrave; transf&eacute;rer &agrave; ces m&ecirc;mes associations, afin de s&rsquo;assurer qu&rsquo;elles aient bien identifi&eacute; ce tout premier appel ;&nbsp;</li>
                                            <li>le 16 {frenchMonth}, l&rsquo;imprimeur recevra les adresses postales auxquelles envoyer les affiches. Si vous avez command&eacute; des envois d&rsquo;affiches, il est donc essentiel que les adresses postales soient &agrave; jour avant cette date (le 15 donc) ;</li>
                                            <li>le 20 {frenchMonth} (avant minuit) sera la date limite pour les associations souhaitant publier des activit&eacute;s sur l&rsquo;Agenda du mois suivant, via le formulaire re&ccedil;u le 10 ;&nbsp;</li>
                                            <li>du 21 au 23 {frenchMonth}, vous disposerez de 3 jours pour &eacute;ventuellement v&eacute;rifier et modifier les propositions d'activit&eacute;s pour l'Agenda du mois suivant, ainsi que la pr&eacute;s&eacute;lection des activit&eacute;s pour l&rsquo;affiche. Si vous ne souhaitez pas effectuer cette v&eacute;rification, aucun probl&egrave;me, le processus suivra son cours et l&rsquo;Agenda sera publi&eacute; en l&rsquo;&eacute;tat !</li>
                                            <li>D&egrave;s le lendemain, la version web de l&rsquo;Agenda du quartier sera publi&eacute;e et le travail d&rsquo;impression et d&rsquo;acheminement des affiches commencera (prendra quelques jours).</li>
                                        </ul>
                                        <br />
                                        <p>Ces &eacute;tapes se r&eacute;p&eacute;teront chaque mois : un v&eacute;ritable rituel d&rsquo;animation locale 😄</p>
                                        <br />
                                        <p>Un courriel reprenant toutes ces informations a &eacute;t&eacute; envoy&eacute; &agrave; l&rsquo;adresse {fields?.email}.&nbsp;</p>
                                        <p>V&eacute;rifiez vos courriers ind&eacute;sirables (spam) si vous ne le recevez pas.&nbsp;</p>
                                        <br />
                                        <p>A bient&ocirc;t, pour animer ensemble nos territoires,&nbsp;</p>
                                        <br />
                                        <p>L&rsquo;&eacute;quipe de l&rsquo;Agenda du quartier</p>
                                    </div>
                                </div>
                            </>
                        )}
                        {isQuartierCompleted === false && (
                            <>
                                <div className='sct90f-title-block'>
                                    <p className='sct90f-title'>Complétez les informations manquantes pour finaliser votre profil</p>
                                </div>
                                <div className='sct90f-content'>
                                    <div className='sct90f-helper'>
                                        <p>Vérifiez chaque section et remplissez les champs nécessaires, en particulier ceux encore incomplets.</p>
                                    </div>
                                </div>
                            </>
                        )}
                        {isLoading && <Loader isOpen />}
                    </div >
                </div>
            </div>
        </div>

    )
})
