// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    PhoneNumberInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProfileDetailFormSerializer,
    ProfileDetailFormSerializerError,
} from 'serializers/web'

// props
type PhoneNumberProfileInputProps = {
    errors: ProfileDetailFormSerializerError | undefined
    fields: ProfileDetailFormSerializer
    onChange: any
}

// main
export const PhoneNumberProfileInput: React.FC<PhoneNumberProfileInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <PhoneNumberInput
            error={errors?.phone_number}
            label={reduxText[4605]}
            name='phone_number'
            onChange={onChange}
            value={fields.phone_number || ''}
        />
    )
})
