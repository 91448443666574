// react components
import React from 'react'
import axios from 'axios'
import moment from 'moment'

// data
import {
    api_url_media_form_youtube,
    reduxAuthState,
    reduxFormHelpersState,
    reduxTextState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function getMediaYoutube({
    formInfo,
    formState,
    helpers,
    image_only,
    reduxAuth,
    reduxText,
    setFormState,
    setGetMetaIsLoading,
    setHelpers,
    urltoParse,
}: {
    formInfo: any
    formState: any
    helpers: reduxFormHelpersState
    image_only: boolean
    reduxAuth: reduxAuthState
    reduxText: reduxTextState['data']
    setFormState: React.Dispatch<any>
    setGetMetaIsLoading: React.Dispatch<boolean>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    urltoParse: string
}) {

    setGetMetaIsLoading(true)

    /* eslint-disable */
    const youtubeRegex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g
    /* eslint-enable */
    const str = urltoParse
    let m

    while ((m = youtubeRegex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === youtubeRegex.lastIndex) {
            youtubeRegex.lastIndex++
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
            if (groupIndex === 1) {
                axios({
                    headers: reduxAuth.axiosConfig?.headers,
                    method: 'get',
                    url: `${getApiUrl(api_url_media_form_youtube, reduxAuth)}?youtube_id=${match}`,
                })
                    .then((response) => { // response data form google api youtube
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        const thumbnail = response.data.thumbnail
                        if (image_only) {
                            const toReturn = {
                                ...formInfo,
                                errors: {
                                    ...formInfo.errors,
                                    cover_image: '',
                                    cover_image_doka_output_data: '',
                                    cover_image_original: '',
                                    cover_image_square: '',
                                    cover_image_square_doka_output_data: '',
                                    image: '',
                                    link_url: '',
                                    meta_image_url: '',
                                },
                                fields: {
                                    ...formInfo.fields,
                                    cover_image: thumbnail,
                                    get_image_hq: thumbnail,
                                    get_image_lq: thumbnail,
                                    get_image_mq: thumbnail,
                                    image: thumbnail,
                                    link_url: urltoParse,
                                    meta_image_url: thumbnail,
                                },
                            }
                            setFormState({
                                ...formState,
                                [formInfo.name]: toReturn,
                            })
                            setHelpers({
                                ...helpers,
                                buttonDisabled: false,
                                mainError: '', // TO_CHECK
                            })
                        } else {
                            const toReturn = {
                                ...formInfo,
                                errors: {
                                    ...formInfo.errors,
                                    cover_image: '',
                                    cover_image_doka_output_data: '',
                                    cover_image_original: '',
                                    cover_image_square: '',
                                    cover_image_square_doka_output_data: '',
                                    date: '',
                                    date_type_choice: '',
                                    description: '',
                                    duration: '',
                                    image: '',
                                    link_id: '',
                                    link_url: '',
                                    meta_image_url: '',
                                    name: '',
                                },
                                fields: {
                                    ...formInfo.fields,
                                    cover_image: thumbnail,
                                    cover_image_doka_output_data: 'to_delete_original',
                                    cover_image_original: '',
                                    cover_image_square: thumbnail,
                                    cover_image_square_doka_output_data: 'to_delete_all',
                                    date: moment(response.data.date).format('YYYY-MM-DD'),
                                    date_type_choice: {
                                        id: 'd',
                                        name: reduxText[4832],
                                    },
                                    description: response.data.description,
                                    duration: moment.utc(moment.duration(response.data.duration).asMilliseconds()).format('HH:mm:ss'),
                                    get_image_hq: thumbnail,
                                    get_image_lq: thumbnail,
                                    get_image_mq: thumbnail,
                                    image: thumbnail,
                                    link_id: response.data.link_id,
                                    link_url: urltoParse,
                                    meta_image_url: thumbnail,
                                    name: response.data.name,
                                },
                            }
                            setFormState({
                                ...formState,
                                [formInfo.name]: toReturn,
                            })
                            setHelpers({
                                ...helpers,
                                buttonDisabled: false,
                                mainError: '', // TO_CHECK
                            })
                        }
                        setGetMetaIsLoading(false)
                    })
                    .catch((error) => {
                        setFormState({
                            ...formState,
                            [formInfo.name]: {
                                ...formInfo,
                                errors: {
                                    ...formInfo.errors,
                                    link_url: error.response?.statusText || 'Error',
                                },
                                fields: {
                                    ...formInfo.fields,
                                    link_url: urltoParse,
                                },
                            },
                        })
                        setGetMetaIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log(error)
                        if (process.env.NODE_ENV === 'development') console.log(error.response)
                    })
            }
        })
    }
}
