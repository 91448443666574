// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
    media_url,
    media_version,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    getDetailObject,
} from 'services'

// main
export const AdminHome: React.FC = React.memo(() => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        onGetQuote()
    }, [])

    const [quote, setQuote] = useState<any>()

    function onGetQuote() {
        try {
            getDetailObject({
                apiUrl: 'https://api.quotable.io/random',
                component: 'AdminHome',
                dispatch,
                reduxAuth,
                setDetailObject: setQuote,
                skipErorrHandling: true,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'AdminHome',
                'onGetQuote',
            ))
        }
    }

    return (
        <div className='admin-home'>
            {/* <p className='ad-cm-title'>{reduxText[10027]}</p>
            <p className='ad-cm-text'>{reduxText[10028]}</p> */}
            <ImageHelper
                alt=''
                className='ah-sphere'
                dominant_color={undefined}
                src={`${media_url}global/spheres/${Math.floor(Math.random() * 105)}.png${media_version}`}
            />
            {quote && (
                <div className='ah-quote-block'>
                    <p className='ah-quote-text'>{quote.content}</p>
                    <p className='ah-quote-author'>&mdash; {quote.author}</p>
                </div>
            )}
        </div>
    )
})
