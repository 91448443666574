// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonLabel,
    IonSegment,
    IonSegmentButton,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'

// components
import {
    ListSpinner,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxModalSetEditStylesSite,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    DetailPageContentPortfolioPanel,
    ListPageContentPortfolioPanel,
    StylePageContentPortfolioPanelNew,
} from 'pages'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    autoSaveStyles,
    axiosErrorHandler,
    getAxiosHeaders,
    parseQuery,
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    contentId: string
}

type ContainerPageContentPortfolioPanelProps = {
    formState: PortfolioFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    setFormState: React.Dispatch<PortfolioFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const ContainerPageContentPortfolioPanel: React.FC<ContainerPageContentPortfolioPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSitenavbarHeight = useSelector((state: defaultReduxState) => state.reduxModalSite.navbarHeight)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const content = formState.content

    useEffect(() => {
        if (params.contentId && params.contentId !== '-') onGetData()
    }, [
        params.contentId,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        scrollToSection(params.contentId, reduxModalSitenavbarHeight)
        const searchParams = parseQuery(window.location.search)
        const tab = searchParams.tab
        if (tab) {
            setTabValue(tab)
        } else if (tabValue === 'blocks') {
            setTabValue('settings')
        }
    }, [
        params.contentId,
    ])

    const [isLoading, setIsLoading] = useState(false)
    const [tabValue, setTabValue] = useState<string>('settings')

    async function onGetData() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'ContainerPageContentPortfolioPanel onGetData')

            const getUrl = `${content.detailUrl}${params.contentId}/`
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setFormState({
                        ...formState,
                        content: {
                            ...formState.content,
                            fields: response.data,
                        }
                    })
                    dispatch(reduxModalSetEditStylesSite({
                        id: response.data.id,
                        styles: response.data.styles_edit,
                    }))
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ContainerPageContentPortfolioPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onGetData',
                        skipSendError: Boolean(params.contentId) && (error.response?.status === 404) && Boolean(error.response?.data?.detail),
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContainerPageContentPortfolioPanel',
                'onGetData',
            ))
        }
    }

    let wto: any
    function onSetTabValue(e: any) {
        setTabValue(e)
        if (e === 'blocks') {
            clearTimeout(wto)
            wto = setTimeout(() => {
                const elmnt = document.getElementById('activeNavElement')
                if (elmnt) {
                    scrollIntoView(elmnt, {
                        align: {
                            left: 0,
                            leftOffset: 0,
                            top: 0,
                            topOffset: 0,
                        },
                        time: 0,
                    })
                }
            }, 100)
        }
        if (e !== 'styles') {
            autoSaveStyles({
                component: 'ContainerPageContentPortfolioPanel',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
        }
    }

    return (
        <div className={`pfcw-container mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
            <div className='pfcw-segment-wrap'>
                <IonSegment
                    className='pfcw-segment'
                    color=''
                    onIonChange={(e) => onSetTabValue(e.detail.value)}
                    value={tabValue}
                >
                    <IonSegmentButton
                        className='pfcw-tab-button'
                        value='blocks'
                    >
                        <IonLabel className='pfcw-tab-label'>{reduxText[8147]}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                        className='pfcw-tab-button'
                        value='styles'
                    >
                        <IonLabel className='pfcw-tab-label'>{reduxText[6411]}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                        className='pfcw-tab-button'
                        value='settings'
                    >
                        <IonLabel className='pfcw-tab-label'>{reduxText[5332]}</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
            </div>
            {(tabValue !== 'blocks' && isLoading)
                ? (
                    <ListSpinner
                        color='tertiary'
                        isLoading
                    />
                ) : (
                    <React.Fragment>
                        {tabValue === 'blocks' && (
                            <ListPageContentPortfolioPanel
                                formState={formState}
                                setTabValue={onSetTabValue}
                            />
                        )}
                        {tabValue === 'settings' && (
                            <DetailPageContentPortfolioPanel
                                formState={formState}
                                handleInputChange={handleInputChange}
                                helpers={helpers}
                                setFormState={setFormState}
                                setHelpers={setHelpers}
                                setTabValue={onSetTabValue}
                            />
                        )}
                        {tabValue === 'styles' && (
                            <StylePageContentPortfolioPanelNew
                                formState={formState}
                                handleInputChange={handleInputChange}
                                setTabValue={onSetTabValue}
                            />
                        )}
                    </React.Fragment>
                )}
        </div>
    )
})
