// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxFormSetSectionIdSite,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    contentId: string
    panelValue: string
}

type EditContentHelperProps = {
    child: boolean
    editUrl: string
    mainParentId?: number
    mixedMedia: boolean
    object: PortfolioPageContentListSiteSerializer
    setIsPopoverOpen: React.Dispatch<boolean>
}

// main
export const EditContentHelper: React.FC<EditContentHelperProps> = React.memo(({
    child,
    editUrl,
    mainParentId,
    mixedMedia,
    object,
    setIsPopoverOpen,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function handleEdit() {
        history.push(editUrl)
        if (child) {
            dispatch(reduxFormSetSectionIdSite(mainParentId))
        } else {
            dispatch(reduxFormSetSectionIdSite(object.id))
        }
        if (mixedMedia) {
            if (Number(params.panelValue) === object.id) scrollToSection(params.panelValue, 0)
        } else {
            if (Number(params.contentId) === object.id) scrollToSection(params.contentId, (reduxModalSite.navbarType === 'top' ? reduxModalSite.navbarHeight : 0))
        }
        setIsPopoverOpen(false)
    }

    return (
        <button
            className='shpcwp-item-button'
            onClick={() => handleEdit()}
        >
            <i className='mo-new-icon-pencil-alt-solid' />
            <span className='shpcwp-item-button-text'>{reduxText[2105]}</span>
        </button>
    )
})
