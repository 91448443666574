// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    BackgroundImageProfileInput,
    CustomCategoryProfileInput,
    CustomDateProfileInput,
    CustomTextProfileInput,
    CustomTextRichProfileInput,
} from 'pages'

// serializers
import {
    ProfileFormState,
} from 'serializers/web'

// props
type PortfolioProfilePanelProps = {
    formState: ProfileFormState
    handleInputChange: any
}

// main
export const PortfolioProfilePanel: React.FC<PortfolioProfilePanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioProfilePanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <BackgroundImageProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomCategoryProfileInput
                ccKey={1}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomCategoryProfileInput
                ccKey={2}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomCategoryProfileInput
                ccKey={3}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomDateProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomDateProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
                version={2}
            />
            <CustomTextProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextRichProfileInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
