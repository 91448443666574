// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonSelect,
    IonSelectOption,
} from '@ionic/react'
import {
    Slider,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Checkbox,
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type QuadrupleInputPortfolioProps = {
    adminOnly?: boolean
    canHideInput?: boolean
    disabled?: boolean
    error: string | undefined
    helperText: string
    label: string
    labelOriginal: string
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    staffOnly?: boolean
    value: string
    value_LG?: string
    value_MD?: string
    value_type: 'LG' | 'MD' | 'SM'
}

// main
export const QuadrupleInputPortfolio: React.FC<QuadrupleInputPortfolioProps> = React.memo(({
    adminOnly,
    canHideInput,
    disabled,
    error,
    helperText,
    label,
    labelOriginal,
    name,
    onChange,
    optional,
    staffOnly,
    value,
    value_LG,
    value_MD,
    value_type,
}) => {

    const dispatch = useDispatch()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        getValue()
    }, [value])

    useEffect(() => {
        setHiddenInput(reduxFormSite.collapseStyles!)
    }, [
        reduxFormSite.collapseStyles,
    ])

    const [hiddenInput, setHiddenInput] = useState(false)
    const [inherit, setInherit] = useState<boolean>((value_type === 'SM' && !value) || (value_type === 'MD' && !value))
    const [showChildren, setShowChildren] = useState(false)
    const [value0, setValue0] = useState<number | string>(0)
    const [uom0, setUom0] = useState<string>('px')
    const [value1, setValue1] = useState<number | string>(0)
    const [uom1, setUom1] = useState<string>('px')
    const [value2, setValue2] = useState<number | string>(0)
    const [uom2, setUom2] = useState<string>('px')
    const [value3, setValue3] = useState<number | string>(0)
    const [uom3, setUom3] = useState<string>('px')
    const [value4, setValue4] = useState<number | string>(0)
    const [uom4, setUom4] = useState<string>('px')
    const [range, setRange] = useState<number>(100)

    function onInputChange(inputValue: string | null | undefined, position?: number) {
        try {
            if (inputValue === '') {
                setValue0('')
                onChange({
                    name: name,
                    value: '',
                })
                return
            }
            const newValue = inputValue || 0
            if (Number(newValue) > range) setRange(Number(newValue))
            let toReturnValue = ''
            if (position === 0) {
                if (Number(newValue) === value1) return
                toReturnValue = `${newValue}${['auto'].includes(uom1) ? '' : uom1} ${value2}${['auto'].includes(uom2) ? '' : uom2} ${value3}${['auto'].includes(uom3) ? '' : uom3} ${value4}${['auto'].includes(uom4) ? '' : uom4}`
                setValue1(['auto'].includes(`${newValue}`) ? `${newValue}` : Number(newValue))
                setValue0(toReturnValue)
            } else if (position === 1) {
                if (Number(newValue) === value2) return
                toReturnValue = `${value1}${['auto'].includes(uom1) ? '' : uom1} ${newValue}${['auto'].includes(uom2) ? '' : uom2} ${value3}${['auto'].includes(uom3) ? '' : uom3} ${value4}${['auto'].includes(uom4) ? '' : uom4}`
                setValue2(['auto'].includes(`${newValue}`) ? `${newValue}` : Number(newValue))
                setValue0(toReturnValue)
            } else if (position === 2) {
                if (Number(newValue) === value3) return
                toReturnValue = `${value1}${['auto'].includes(uom1) ? '' : uom1} ${value2}${['auto'].includes(uom2) ? '' : uom2} ${newValue}${['auto'].includes(uom3) ? '' : uom3} ${value4}${['auto'].includes(uom4) ? '' : uom4}`
                setValue3(['auto'].includes(`${newValue}`) ? `${newValue}` : Number(newValue))
                setValue0(toReturnValue)
            } else if (position === 3) {
                if (Number(newValue) === value4) return
                toReturnValue = `${value1}${['auto'].includes(uom1) ? '' : uom1} ${value2}${['auto'].includes(uom2) ? '' : uom2} ${value3}${['auto'].includes(uom3) ? '' : uom3} ${newValue}${['auto'].includes(uom4) ? '' : uom4}`
                setValue4(['auto'].includes(`${newValue}`) ? `${newValue}` : Number(newValue))
                setValue0(toReturnValue)
            } else {
                if (Number(newValue) === Number(value0)) return
                if (['auto'].includes(`${newValue}`)) {
                    toReturnValue = 'auto'
                    setValue0('auto')
                } else if (!isNaN(Number(newValue))) {
                    toReturnValue = `${newValue}${uom0}`
                    setValue0(Number(newValue))
                } else {
                    return
                }
            }

            onChange({
                name: name,
                value: toReturnValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'QuadrupleInputPortfolio',
                'onInputChange',
            ))
        }
    }

    function onUomChange(newUomValue: string, position?: number) {
        try {
            let toReturnValue = ''
            if (position === 0) {
                if (newUomValue === uom1) return
                let newValue1 = value1
                setUom1(newUomValue)
                if (['auto'].includes(newUomValue)) {
                    setValue1(newUomValue)
                    return
                } else if (['auto'].includes(`${value1}`)) {
                    newValue1 = 0
                    setValue1(0)
                }
                toReturnValue = `${newValue1}${['auto'].includes(newUomValue) ? '' : newUomValue} ${value2}${['auto'].includes(uom2) ? '' : uom2} ${value3}${['auto'].includes(uom3) ? '' : uom3} ${value4}${['auto'].includes(uom4) ? '' : uom4}`
                setValue0(toReturnValue)
            } else if (position === 1) {
                if (newUomValue === uom2) return
                let newValue2 = value2
                setUom2(newUomValue)
                if (['auto'].includes(newUomValue)) {
                    setValue2(newUomValue)
                    return
                } else if (['auto'].includes(`${value2}`)) {
                    newValue2 = 0
                    setValue2(0)
                }
                toReturnValue = `${value1}${uom1} ${newValue2}${['auto'].includes(newUomValue) ? '' : newUomValue} ${value3}${['auto'].includes(uom3) ? '' : uom3} ${value4}${['auto'].includes(uom4) ? '' : uom4}`
                setValue0(toReturnValue)
            } else if (position === 2) {
                if (newUomValue === uom3) return
                let newValue3 = value3
                setUom3(newUomValue)
                if (['auto'].includes(newUomValue)) {
                    setValue3(newUomValue)
                    return
                } else if (['auto'].includes(`${value3}`)) {
                    newValue3 = 0
                    setValue3(0)
                }
                toReturnValue = `${value1}${uom1} ${value2}${['auto'].includes(uom2) ? '' : uom2} ${newValue3}${['auto'].includes(newUomValue) ? '' : newUomValue} ${value4}${['auto'].includes(uom4) ? '' : uom4}`
                setValue0(toReturnValue)
            } else if (position === 3) {
                if (newUomValue === uom4) return
                let newValue4 = value4
                setUom4(newUomValue)
                if (['auto'].includes(newUomValue)) {
                    setValue4(newUomValue)
                    return
                } else if (['auto'].includes(`${value4}`)) {
                    newValue4 = 0
                    setValue4(0)
                }
                toReturnValue = `${value1}${uom1} ${value2}${['auto'].includes(uom2) ? '' : uom2} ${value3}${['auto'].includes(uom3) ? '' : uom3} ${newValue4}${['auto'].includes(newUomValue) ? '' : newUomValue}`
                setValue0(toReturnValue)
            } else {
                if (newUomValue === uom0) return
                setUom0(newUomValue)
                if (['auto'].includes(newUomValue)) {
                    setValue0(newUomValue)
                    return
                } else if (['auto'].includes(`${value0}`)) {
                    setValue0(0)
                }
                toReturnValue = `${value0}${['auto'].includes(newUomValue) ? '' : newUomValue}`
            }
            onChange({
                name: name,
                value: toReturnValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'QuadrupleInputPortfolio',
                'onInputChange',
            ))
        }
    }

    function getValue() {
        if (value) {
            const valueArray = value.split(' ')
            if (valueArray.length <= 1) {
                let toReturnValue0: any = ''
                let toReturnUom0 = ''
                if (['auto'].includes(valueArray[0])) {
                    toReturnValue0 = valueArray[0]
                    toReturnUom0 = valueArray[0]
                } else {
                    toReturnValue0 = Number(valueArray[0].split('px').join(',').split('%').join(',').split('vh').join(',').split(',')[0])
                    toReturnUom0 = valueArray[0].split(toReturnValue0)[1] || 'px'

                }
                setValue0(toReturnValue0)
                setUom0(toReturnUom0)
                setValue1(toReturnValue0)
                setUom1(toReturnUom0)
                setValue2(toReturnValue0)
                setUom2(toReturnUom0)
                setValue3(toReturnValue0)
                setUom3(toReturnUom0)
                setValue4(toReturnValue0)
                setUom4(toReturnUom0)
            } else {

                setValue0(value)
                setUom0('')

                if (valueArray[0]) {
                    const toReturnValue1 = valueArray[0].split('px').join(',').split('%').join(',').split('vh').join(',').split(',')[0]
                    const toReturnUom1 = valueArray[0].split(toReturnValue1)[1]
                    setValue1(['auto'].includes(`${toReturnValue1}`) ? toReturnValue1 : Number(toReturnValue1))
                    setUom1(['auto'].includes(`${toReturnValue1}`) ? toReturnValue1 : toReturnUom1)
                }

                if (valueArray[1]) {
                    const toReturnValue2 = valueArray[1].split('px').join(',').split('%').join(',').split('vh').join(',').split(',')[0]
                    const toReturnUom2 = valueArray[1].split(toReturnValue2)[1]
                    setValue2(['auto'].includes(`${toReturnValue2}`) ? toReturnValue2 : Number(toReturnValue2))
                    setUom2(['auto'].includes(`${toReturnValue2}`) ? toReturnValue2 : toReturnUom2)
                }

                if (valueArray[2]) {
                    const toReturnValue3 = valueArray[2].split('px').join(',').split('%').join(',').split('vh').join(',').split(',')[0]
                    const toReturnUom3 = valueArray[2].split(toReturnValue3)[1]
                    setValue3(['auto'].includes(`${toReturnValue3}`) ? toReturnValue3 : Number(toReturnValue3))
                    setUom3(['auto'].includes(`${toReturnValue3}`) ? toReturnValue3 : toReturnUom3)
                }

                if (valueArray[3]) {
                    const toReturnValue4 = valueArray[3].split('px').join(',').split('%').join(',').split('vh').join(',').split(',')[0]
                    const toReturnUom4 = valueArray[3].split(toReturnValue4)[1]
                    setValue4(['auto'].includes(`${toReturnValue4}`) ? toReturnValue4 : Number(toReturnValue4))
                    setUom4(['auto'].includes(`${toReturnValue4}`) ? toReturnValue4 : toReturnUom4)
                }
            }
        } else {
            setValue0('')
            setUom0(uom0 || 'px')
        }
    }

    function setSingle() {
        setShowChildren(false)
        setValue0('0')
        setUom0('px')
        onChange({
            name: name,
            value: '0px',
        })
    }

    const hasChildren = `${value0}`.split(' ').length > 1

    const main0isDisabled = disabled || hasChildren || showChildren

    if (inherit) {
        const labelValue = value_type === 'SM' ? (value_MD || value_LG) : value_LG
        return (
            <div className={`mo-text-input-webnew mo-quadruple-input-web${hiddenInput ? ' hidden-input' : ''}`}>
                <MainFormLabel
                    adminOnly={adminOnly}
                    helperText={helperText}
                    hiddenInput={hiddenInput}
                    label={label}
                    labelOriginal={labelOriginal}
                    name={name}
                    optional={optional}
                    setHiddenInput={canHideInput ? () => setHiddenInput(!hiddenInput) : undefined}
                    staffOnly={staffOnly}
                    value={labelValue}
                    valueIsInherit={inherit}
                />
                {!hiddenInput && (
                    <div
                        className='qiw-input-wrap'
                        onClick={() => {
                            setInherit(false)
                            onChange({
                                name: name,
                                value: value_MD || value_LG,
                            })
                        }}
                    >
                        <input
                            className='mo-text-input-web-inputnew disabled'
                            disabled
                            name={name}
                            type='text'
                            value={labelValue}
                        />
                        <div className='qiw-inherit'>
                            <Checkbox
                                checked={inherit}
                                onChange={() => {
                                    setInherit(false)
                                    onChange({
                                        name: name,
                                        value: value_MD || value_LG,
                                    })
                                }}
                            />
                            <span className='qiw-inherit-text'>{reduxText[6568]}</span>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    return (
        <div className={`mo-text-input-webnew mo-quadruple-input-web${hiddenInput ? ' hidden-input' : ''}`}>
            <MainFormLabel
                adminOnly={adminOnly}
                helperText={helperText}
                hiddenInput={hiddenInput}
                label={label}
                labelOriginal={labelOriginal}
                name={name}
                optional={optional}
                setHiddenInput={canHideInput ? () => setHiddenInput(!hiddenInput) : undefined}
                staffOnly={staffOnly}
                value={value}
            />
            {!hiddenInput && (
                <React.Fragment>
                    <div className='qiw-input-wrap'>
                        <input
                            className={`mo-text-input-web-inputnew${(main0isDisabled || ['auto'].includes(`${value0}`)) ? ' disabled' : ''}`}
                            disabled={main0isDisabled || ['auto'].includes(`${value0}`)}
                            name={name}
                            onChange={(e) => showChildren ? undefined : onInputChange(e.target.value)}
                            type={(main0isDisabled || ['auto'].includes(`${value0}`)) ? 'text' : 'number'}
                            value={value0}
                        />
                        {!hasChildren && (
                            <IonSelect
                                disabled={main0isDisabled}
                                interface='popover'
                                onIonChange={(e) => onUomChange(e.detail.value)}
                                value={uom0}
                            >
                                <IonSelectOption value='px'>px</IonSelectOption>
                                <IonSelectOption value='%'>%</IonSelectOption>
                                <IonSelectOption value='vh'>vh</IonSelectOption>
                                {name === 'margin' && (
                                    <IonSelectOption value='auto'>auto</IonSelectOption>
                                )}
                            </IonSelect>
                        )}
                        {value_type === 'LG' && (
                            <div className='qiw-close-wrap'>
                                <i
                                    className='qiw-close-icon mo-new-icon-times-solid'
                                    onClick={() => onInputChange('')}
                                />
                            </div>
                        )}
                        {value_type !== 'LG' && (
                            <div className='qiw-inherit'>
                                <Checkbox
                                    checked={inherit}
                                    onChange={() => {
                                        setInherit(true)
                                        onChange({
                                            name: name,
                                            value: '',
                                        })
                                    }}
                                />
                                <span className='qiw-inherit-text'>{reduxText[6568]}</span>
                            </div>
                        )}
                    </div>
                    <div className='qiw-range'>
                        <Slider
                            color='secondary'
                            disabled={main0isDisabled || ['auto'].includes(`${value0}`)}
                            max={Number(value0) > range ? Number(value0) : range}
                            // @ts-ignore
                            onChange={(e) => isNaN(Number(value0) || Number(e.target.value)) ? undefined : onInputChange(Number(e.target.value))}
                            value={Number(value0) || undefined}
                        />
                    </div>
                    <p
                        className='qiw-show'
                    >
                        {hasChildren && (
                            <span
                                onClick={() => setSingle()}
                            >
                                {reduxText[6399]}
                            </span>
                        )}
                        <span
                            onClick={() => setShowChildren(!showChildren)}
                        >
                            {showChildren ? reduxText[6400] : reduxText[6401]}
                        </span>
                    </p>
                </React.Fragment>
            )}
            <ErrorHelper error={error} />
            {showChildren && (
                <div className='qiw-children'>
                    <div className='qiw-child'>
                        <div className='qiw-input-wrap'>
                            <div className='qiw-position-wrap'>
                                <i className='qiw-position top mo-new-icon-chevron-left-solid' />
                            </div>
                            <input
                                className={`mo-text-input-web-inputnew${(disabled || ['auto'].includes(uom1)) ? ' disabled' : ''}`}
                                disabled={disabled || ['auto'].includes(uom1)}
                                name={`${name}Top`}
                                onChange={(e) => onInputChange(e.target.value, 0)}
                                type={['auto'].includes(`${value1}`) ? 'text' : 'number'}
                                value={value1}
                            />
                            <IonSelect
                                interface='popover'
                                onIonChange={(e) => onUomChange(e.detail.value, 0)}
                                value={uom1}
                            >
                                <IonSelectOption value='px'>px</IonSelectOption>
                                <IonSelectOption value='%'>%</IonSelectOption>
                                <IonSelectOption value='vh'>vh</IonSelectOption>
                                {name === 'margin' && (
                                    <IonSelectOption value='auto'>auto</IonSelectOption>
                                )}
                            </IonSelect>
                        </div>
                        <div className='qiw-range'>
                            <Slider
                                color='secondary'
                                disabled={disabled || ['auto'].includes(uom1)}
                                max={Number(value1) > 100 ? Number(value1) : 100}
                                // @ts-ignore
                                onChange={(e) => isNaN(Number(value1) || Number(e.target.value)) ? undefined : onInputChange(Number(e.target.value), 0)}
                                value={Number(value1) || undefined}
                            />
                        </div>
                    </div>
                    <div className='qiw-child'>
                        <div className='qiw-input-wrap'>
                            <div className='qiw-position-wrap'>
                                <i className='qiw-position right mo-new-icon-chevron-left-solid' />
                            </div>
                            <input
                                className={`mo-text-input-web-inputnew${(disabled || ['auto'].includes(uom2)) ? ' disabled' : ''}`}
                                disabled={disabled || ['auto'].includes(uom2)}
                                name={`${name}Right`}
                                onChange={(e) => onInputChange(e.target.value, 1)}
                                type={['auto'].includes(`${value2}`) ? 'text' : 'number'}
                                value={value2}
                            />
                            <IonSelect
                                interface='popover'
                                onIonChange={(e) => onUomChange(e.detail.value, 1)}
                                value={uom2}
                            >
                                <IonSelectOption value='px'>px</IonSelectOption>
                                <IonSelectOption value='%'>%</IonSelectOption>
                                <IonSelectOption value='vh'>vh</IonSelectOption>
                                {name === 'margin' && (
                                    <IonSelectOption value='auto'>auto</IonSelectOption>
                                )}
                            </IonSelect>
                        </div>
                        <div className='qiw-range'>
                            <Slider
                                color='secondary'
                                disabled={disabled || ['auto'].includes(uom2)}
                                max={Number(value2) > 100 ? Number(value2) : 100}
                                // @ts-ignore
                                onChange={(e) => isNaN(Number(value2) || Number(e.target.value)) ? undefined : onInputChange(Number(e.target.value), 1)}
                                value={Number(value2) || undefined}
                            />
                        </div>
                    </div>
                    <div className='qiw-child'>
                        <div className='qiw-input-wrap'>
                            <div className='qiw-position-wrap'>
                                <i className='qiw-position bottom mo-new-icon-chevron-left-solid' />
                            </div>
                            <input
                                className={`mo-text-input-web-inputnew${(disabled || ['auto'].includes(uom3)) ? ' disabled' : ''}`}
                                disabled={disabled || ['auto'].includes(uom3)}
                                name={`${name}Bottom`}
                                onChange={(e) => onInputChange(e.target.value, 2)}
                                type={['auto'].includes(`${value3}`) ? 'text' : 'number'}
                                value={value3}
                            />
                            <IonSelect
                                interface='popover'
                                onIonChange={(e) => onUomChange(e.detail.value, 2)}
                                value={uom3}
                            >
                                <IonSelectOption value='px'>px</IonSelectOption>
                                <IonSelectOption value='%'>%</IonSelectOption>
                                <IonSelectOption value='vh'>vh</IonSelectOption>
                                {name === 'margin' && (
                                    <IonSelectOption value='auto'>auto</IonSelectOption>
                                )}
                            </IonSelect>
                        </div>
                        <div className='qiw-range'>
                            <Slider
                                color='secondary'
                                disabled={disabled || ['auto'].includes(uom3)}
                                max={Number(value3) > 100 ? Number(value3) : 100}
                                // @ts-ignore
                                onChange={(e) => isNaN(Number(value3) || Number(e.target.value)) ? undefined : onInputChange(Number(e.target.value), 2)}
                                value={Number(value3) || undefined}
                            />
                        </div>
                    </div>
                    <div className='qiw-child'>
                        <div className='qiw-input-wrap'>
                            <div className='qiw-position-wrap'>
                                <i className='qiw-position left mo-new-icon-chevron-left-solid' />
                            </div>
                            <input
                                className={`mo-text-input-web-inputnew${(disabled || ['auto'].includes(uom4)) ? ' disabled' : ''}`}
                                disabled={disabled || ['auto'].includes(uom4)}
                                name={`${name}Left`}
                                onChange={(e) => onInputChange(e.target.value, 3)}
                                type={['auto'].includes(`${value4}`) ? 'text' : 'number'}
                                value={value4}
                            />
                            <IonSelect
                                interface='popover'
                                onIonChange={(e) => onUomChange(e.detail.value, 3)}
                                value={uom4}
                            >
                                <IonSelectOption value='px'>px</IonSelectOption>
                                <IonSelectOption value='%'>%</IonSelectOption>
                                <IonSelectOption value='vh'>vh</IonSelectOption>
                                {name === 'margin' && (
                                    <IonSelectOption value='auto'>auto</IonSelectOption>
                                )}
                            </IonSelect>
                        </div>
                        <div className='qiw-range'>
                            <Slider
                                color='secondary'
                                disabled={disabled || ['auto'].includes(uom4)}
                                max={Number(value4) > 100 ? Number(value4) : 100}
                                // @ts-ignore
                                onChange={(e) => isNaN(Number(value4) || Number(e.target.value)) ? undefined : onInputChange(Number(e.target.value), 3)}
                                value={Number(value4) || undefined}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
})
