// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonButton,
    IonItem,
    IonLabel,
    IonReorder,
    IonReorderGroup,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonThumbnail,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios, {
    AxiosRequestConfig,
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    DeleteAlert,
    IconBlock,
    ImageHelper,
    InfiniteScrollHelperWeb,
    InlineActionSheetWeb,
    LightboxCustom,
    Loader,
    RatioZarmingHelper,
} from 'components'

// data
import {
    defaultReduxState,
    mediumTypeCustomPageIds,
    reduxFormHelpersState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    reduxModalErrorShowHide,
    select_url_main_medium_type_artwork,
    view_url_profile_form,
    view_url_project_child_form,
} from 'data'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getApiUrl,
    getAxiosHeaders,
    getDateWithType,
    getDetailObject,
    getFormData,
    getInfiniteListWeb,
    getMainErrorMessage,
    getSchedule,
} from 'services'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// props
type MatchParams = {
    id: string
    albumId?: string
    extraId?: string
}

type InlineFormContainerWebProps = {
    children?: React.ReactNode
    contentTypeId?: number
    extraListFilter?: string
    filters?: boolean
    formInfo: any
    formState: any
    hasSearch?: boolean
    helpers: reduxFormHelpersState
    isDropzone?: boolean
    noOrdering?: boolean
    setFormState: React.Dispatch<any>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const InlineFormContainerWeb: React.FC<InlineFormContainerWebProps> = React.memo(({
    children,
    contentTypeId,
    extraListFilter,
    filters,
    formInfo,
    formState,
    hasSearch,
    helpers,
    isDropzone,
    noOrdering,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const searchBarRef = useRef<HTMLIonSearchbarElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<any[]>([])
    const [nextUrl, setNextUrl] = useState('')
    const [searchValue, setSearchValue] = useState('')

    const [filterMediumType, setFilterMediumType] = useState<number | 'all'>('all')
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [mediumTypeArray, setMediumTypeArray] = useState<MainIdNameSerializer[]>([])
    const [object, setObject] = useState<any>(undefined)
    const [showActionSheet, setShowActionSheet] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })
    const [showInlineForm, setShowInlineForm] = useState(true)

    const [imageSrc, setImageSrc] = useState<string>()
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)

    useEffect(() => {
        onGetListData(
            getAxiosUrl(formInfo),
            true,
            true, // TODO
        )
    }, [
        filterMediumType,
        reduxAuth.apiRootUrl,
        reduxForm.refresh.refreshInlineList,
        searchValue,
    ])

    useEffect(() => {
        if (filters) {
            onGetMediumTypes()
        }
    }, [
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        handleCancel()
    }, [])

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'doReorder',
            ))
        }
    }

    function getAxiosUrl(formInfo: any) {
        let axiosUrl = `${formInfo.listUrl}${params.id}/${extraListFilter || ''}`
        if (contentTypeId) axiosUrl += `${contentTypeId}/`
        if (params.extraId) axiosUrl += `${params.extraId}/`
        if (filterMediumType && filterMediumType !== 'all') axiosUrl += `?medium_type=${filterMediumType}`
        if (searchValue) axiosUrl += `?search=${searchValue}`
        return axiosUrl
    }

    function handleActionSheet(object: any, e: any) {
        try {
            setObject(object)
            setShowActionSheet({ open: true, event: e.nativeEvent })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'handleActionSheet',
            ))
        }
    }

    let wto: any
    let wto2: any
    let wto3: any

    function handleCancel() {
        setShowInlineForm(false)
        clearTimeout(wto)
        wto = setTimeout(() => {
            setShowInlineForm(true)
        }, 100)
        setObject(undefined)
        if (formInfo.name === 'team') {
            setFormState({
                ...formState,
                [formInfo.name]: {
                    ...formState[formInfo.name],
                    fields: {},
                    errors: {},
                    requiredAllFields: [],
                    requiredAttribute: [],
                    requiredForeignKey: [
                        'member',
                    ],
                    requiredManyToMany: [],
                }
            })
        } else {
            setFormState({
                ...formState,
                [formInfo.name]: {
                    ...formState[formInfo.name],
                    fields: {},
                    errors: {},
                }
            })
        }
        setHelpers({
            ...helpers,
            init: false,
        })
    }

    async function handleInlineActive() {
        try {
            setShowActionSheet({ open: false, event: undefined })
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormContainerWeb handleInlineActive')

            if (!object) return
            const formData = new FormData()
            formData.append('active', (!object.active).toString())
            const activeUrl = `${formInfo.activeUrl}${object.id}/`
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: activeUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    const newArray: any = []
                    items.map((val: any) => {
                        if (val.id === object.id) {
                            val.active = response.data.active
                        }
                        newArray.push(val)
                        return false
                    })
                    setItems(newArray)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: activeUrl,
                        component: 'InlineFormContainerWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineActive',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'handleInlineActive',
            ))
        }
    }

    async function handleInlineDelete() {
        try {
            setIsDeleting(true)
            setShowActionSheet({ open: false, event: undefined })
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormContainerWeb handleInlineDelete')

            if (!object) return
            const deleteUrl = `${formInfo.updateUrl}${object.id}/`
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    const newArray: any[] = []
                    items.map((val: any) => {
                        if (val.id !== object.id) newArray.push(val)
                        return false
                    })
                    setItems(newArray)
                })
                .catch((error) => {
                    setIsDeleting(false)
                    if (error.response?.status === 423) {
                        dispatch(reduxModalErrorShowHide({
                            header: reduxText[7428],
                            isOpen: true,
                            message: formInfo.name === 'variant' ? reduxText[7429] : reduxText[7430],
                            noMessage: true,
                            source: 'InlineFormContainerWeb handleInlineDelete',
                        }))
                    } else {
                        axiosErrorHandler({
                            apiUrl: deleteUrl,
                            component: 'InlineFormContainerWeb',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleInlineDelete',
                        })
                    }
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'handleInlineDelete',
            ))
        }
    }

    function handleInlineGoToChild() {
        try {
            setShowActionSheet({ open: false, event: undefined })
            history.push(`${view_url_profile_form}${params.id}/inline/carte-item-panel/${object.id}/`)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'handleInlineGoToChild',
            ))
        }
    }

    async function handleInlineRetrieve(clickedObject: any) {
        try {
            setShowActionSheet({ open: false, event: undefined })
            setObject(clickedObject)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormContainerWeb handleInlineRetrieve')

            if (!clickedObject) return
            if (clickedObject.has_edit_permission) { // has_edit_permission only available for project content
                history.push(`${view_url_project_child_form}${clickedObject.project_child.id}/${clickedObject.project}/`)
            } else {
                const getUrl = `${formInfo.detailUrl}${clickedObject.id}/`
                axios({
                    headers: refreshAxiosHeaders,
                    method: 'get',
                    url: getUrl,
                })
                    .then((response) => {
                        setShowInlineForm(false)
                        clearTimeout(wto2)
                        wto2 = setTimeout(() => {
                            setShowInlineForm(true)
                        }, 100)
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        if (formInfo.name === 'team') {
                            let newRequiredAttribute: string[] = []
                            let newRequiredForeignKey: string[] = []
                            if (response.data.member) {
                                newRequiredAttribute = []
                                newRequiredForeignKey = [
                                    'member',
                                ]
                            } else {
                                newRequiredAttribute = [
                                    'name',
                                ]
                                newRequiredForeignKey = []
                            }
                            setFormState({
                                ...formState,
                                [formInfo.name]: {
                                    ...formState[formInfo.name],
                                    requiredAttribute: newRequiredAttribute,
                                    requiredForeignKey: newRequiredForeignKey,
                                    fields: response.data,
                                    errors: {},
                                }
                            })
                        } else {
                            setFormState({
                                ...formState,
                                [formInfo.name]: {
                                    ...formState[formInfo.name],
                                    fields: response.data,
                                    errors: {},
                                }
                            })
                        }
                        setHelpers({
                            ...helpers,
                            init: true,
                        })
                    })
                    .catch((error) => {
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component: 'InlineFormContainerWeb',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handleInlineRetrieve',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'handleInlineRetrieve',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: formInfo,
                reduxText,
            })
            if (process.env.NODE_ENV === 'development') console.log(result)
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    [formInfo.name]: {
                        ...formState[formInfo.name],
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormContainerWeb handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
                mainError: '',
            })

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = formInfo.createUrl
            if (formInfo.fields.id) {
                axiosMethod = 'put'
                axiosUrl = `${formInfo.updateUrl}${formInfo.fields.id}/`
            }
            const formData: any = getFormData(formInfo)
            if (mother.model === 'event' && formInfo.name === 'schedule') {
                formData.append('parent_event', params.id)
            } else if (mother.model === 'service' && formInfo.name === 'child') {
                formData.append('parent_service', params.id)
            } else if (formInfo.name === 'team' && ['offer', 'service'].includes(mother.model)) {
                formData.append('object_id', params.id)
                formData.append('content_type', contentTypeId)
            } else {
                formData.append(mother.model, params.id)
            }
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setShowInlineForm(false)
                    clearTimeout(wto3)
                    wto3 = setTimeout(() => {
                        setShowInlineForm(true)
                    }, 100)
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        init: false,
                        isSaving: false,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        [formInfo.name]: {
                            ...formState[formInfo.name],
                            errors: {},
                            fields: {},
                        }
                    })
                    if (axiosMethod === 'post') {
                        onGetListData(
                            getAxiosUrl(formInfo),
                            true,
                        )
                    } else {
                        if (formInfo.name === 'team') {
                            onGetListData(
                                getAxiosUrl(formInfo),
                                true,
                            )
                        } else {
                            const newArray: any = []
                            items.map((val: any) => {
                                if (val.id === object.id) {
                                    val = response.data
                                }
                                newArray.push(val)
                                return false
                            })
                            setItems(newArray)
                        }
                    }
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: error.response?.data?.non_field_errors || '',
                    })
                    setFormState({
                        ...formState,
                        [formInfo.name]: {
                            ...formState[formInfo.name],
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'InlineFormContainerWeb',
                        dispatch,
                        error,
                        formFieldsToIgnore: ['end_date'],
                        reduxAuth,
                        reference: 'handleSubmit',
                        skipNonFieldErrors: true,
                        skipSend404Detail: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'handleSubmit',
            ))
        }
    }

    function onGetMediumTypes() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${select_url_main_medium_type_artwork}?single=True`, reduxAuth),
                component: 'InlineFormContainerWeb',
                dispatch,
                reduxAuth,
                setDetailObject: setMediumTypeArray,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'onGetDetailData',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
        noEmpty?: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'InlineFormContainerWeb',
                dispatch,
                items,
                noEmpty,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'onGetListData',
            ))
        }
    }

    function onImageClick(src: string) {
        try {
            setIsLightBoxOpen(true)
            setImageSrc(src)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'onImageClick',
            ))
        }
    }

    function onSearch(value: any) {
        try {
            setSearchValue(value)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'onSearch',
            ))
        }
    }

    function onSearchKeyPress(event: any) {
        try {
            if (event.key === 'Enter') {
                onSearch(event.target.value)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'onSearchKeyPress',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'searchNext',
            ))
        }
    }

    async function onSortEnd(event: any) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'InlineFormContainerWeb onSortEnd')

            const item = items[event.detail.from]
            const itemTo = items[event.detail.to]
            setItems(arrayMoveImmutable(items, event.detail.from, event.detail.to))
            const formData: any = new FormData()
            formData.append('sibling_id', itemTo.id)
            const putUrl = `${formInfo.patchUrl}${item.id}/`
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'InlineFormContainerWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'onSortEnd',
            ))
        }
    }

    function getMemberStatus(teamObject: any) {
        try {
            let textToReturn = reduxText[5615]
            if (!teamObject.active) {
                textToReturn = reduxText[5616]
            } else if (teamObject.member) {
                if (!teamObject.member.active) {
                    textToReturn = reduxText[5618]
                } else if (teamObject.accepted) {
                    textToReturn = reduxText[5619]
                }
            } else if (!teamObject.member) {
                textToReturn = reduxText[5620]
            }
            return textToReturn
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'InlineFormContainerWeb',
                'getMemberStatus',
            ))
        }
    }

    return (
        <div className='inline-form-container-web'>
            <div className='ifcw-left'>
                <div className={`ifcw-form-children mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {showInlineForm && (
                        children
                    )}
                </div>
                {(!isDropzone || (isDropzone && formInfo.fields?.id) || (isDropzone && formInfo.fields?.temp_project_content_type === 6))
                    ? (
                        <div className='ifcw-form-button'>
                            {helpers.init
                                ? (
                                    <React.Fragment>
                                        <Button
                                            classNameWrap='ifcw-footer-button'
                                            edit={false}
                                            expand='full'
                                            fill='outline'
                                            onClick={handleCancel}
                                            padding='0 5px'
                                            text={reduxText[4519]}
                                        />
                                        <Button
                                            classNameWrap='ifcw-footer-button'
                                            disabled={helpers.buttonDisabled}
                                            edit={false}
                                            expand='full'
                                            fill='outline'
                                            onClick={handleRequired}
                                            padding='0 5px'
                                            text={reduxText[4520]}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <Button
                                        className='ifcw-footer-button-full'
                                        edit={false}
                                        fill='outline'
                                        onClick={() => {
                                            if (params.albumId) history.goBack()
                                            dispatch(reduxFormSetRefresh('refreshForm', mother.fields?.id))
                                            if (mediumTypeCustomPageIds.includes(mother.fields?.medium_type?.id)) {
                                                dispatch(reduxFormSetRefresh('refreshPreview', mother.fields?.id))
                                            }
                                        }}
                                        text={reduxText[7676]}
                                        to={params.albumId ? undefined : `${mother.formUrl}${mother.fields?.id}/${location.search}`}
                                    />
                                )}
                        </div>
                    ) : (
                        <div className='ifcw-form-button'>
                            <Button
                                className='ifcw-footer-button-full'
                                edit={false}
                                fill='outline'
                                onClick={() => {
                                    if (params.albumId) history.goBack()
                                    dispatch(reduxFormSetRefresh('refreshForm', mother.fields?.id))
                                    if (mediumTypeCustomPageIds.includes(mother.fields?.medium_type?.id)) {
                                        dispatch(reduxFormSetRefresh('refreshPreview', mother.fields?.id))
                                    }
                                }}
                                text={reduxText[7676]}
                                to={params.albumId ? undefined : `${mother.formUrl}${mother.fields?.id}/${location.search}`}
                            />
                        </div>
                    )}
            </div>
            <div className={`ifcw-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                {filters && (
                    <div className='ifcw-segment-wrap'>
                        <IonSegment
                            className='ifcw-segment'
                            color=''
                            onIonChange={(e) => setFilterMediumType(e.detail.value ? Number(e.detail.value) : 'all')}
                            value={`${filterMediumType}`}
                        >
                            <IonSegmentButton
                                className='ifcw-tab-button'
                                value='all'
                            >
                                <IonLabel className='ifcw-tab-label'>{reduxText[5445]}</IonLabel>
                            </IonSegmentButton>
                            {mediumTypeArray.map((val) => (
                                <IonSegmentButton
                                    key={val.id}
                                    className='ifcw-tab-button'
                                    value={`${val.id}`}
                                >
                                    <IonLabel className='ifcw-tab-label'>{val.name}</IonLabel>
                                </IonSegmentButton>
                            ))}
                        </IonSegment>
                    </div>
                )}
                {hasSearch && (
                    <div className='ifcw-search-wrap'>
                        <IonSearchbar
                            className='ifcw-searchbar'
                            onIonClear={() => setSearchValue('')}
                            onKeyPress={(e) => onSearchKeyPress(e)}
                            placeholder={reduxText[6227]}
                            ref={searchBarRef}
                        />
                        <IconBlock
                            className='ifcw-icon'
                            edit={false}
                            iconClass='mo-new-icon-search-solid'
                            onClick={() => { if (searchBarRef.current) onSearch(searchBarRef.current.value) }}
                        />
                    </div>
                )}
                {/* TODO */}
                {items.length > 0 && (
                    <div className='ifcw-infinite'>
                        <IonReorderGroup
                            disabled={noOrdering || Boolean(searchValue)}
                            onIonItemReorder={doReorder}
                        >
                            {items.map((val: any) => {
                                return (
                                    <IonItem
                                        key={val.id}
                                        button
                                        detail={false}
                                        style={{
                                            border: formInfo.fields?.id === val.id ? '1px solid #8ea1ff' : '1px solid transparent',
                                        }}
                                    >
                                        {formInfo.name === 'asset' && (
                                            <IonThumbnail>
                                                <ImageHelper
                                                    alt=''
                                                    className='cursor-zoom-in'
                                                    dominant_color={val.get_dominant_color}
                                                    src={val.get_image_lq}
                                                    onClick={() => onImageClick(val.get_image_hq)}
                                                />
                                            </IonThumbnail>
                                        )}
                                        {formInfo.name === 'content' && (
                                            <IonThumbnail>
                                                <ImageHelper
                                                    alt=''
                                                    className='cursor-zoom-in'
                                                    dominant_color={val.project_child.get_dominant_color}
                                                    src={val.project_child.get_image_lq}
                                                    onClick={() => onImageClick(val.project_child.get_image_hq)}
                                                />
                                            </IonThumbnail>
                                        )}
                                        {formInfo.name === 'image' && (
                                            <IonThumbnail>
                                                <ImageHelper
                                                    alt=''
                                                    className='cursor-zoom-in'
                                                    dominant_color={val.get_dominant_color}
                                                    src={val.get_image_lq}
                                                    onClick={() => onImageClick(val.get_image_hq)}
                                                />
                                            </IonThumbnail>
                                        )}
                                        {formInfo.name === 'team' && (
                                            <RatioZarmingHelper
                                                borderRadius='50%'
                                                className='tfw-avatar'
                                                dominant_color={val.get_dominant_color || val.member?.get_dominant_color}
                                                edit={false}
                                                fontSize={12}
                                                height={36}
                                                name={val.member?.name || val.name}
                                                notZarma={Boolean(val.get_image_xs || val.member?.get_image_xs)}
                                                onClick={() => onImageClick(val.get_image_hq || val.member?.get_image_hq)}
                                                src={val.get_image_xs || val.member?.get_image_xs}
                                                to={undefined}
                                            />
                                        )}
                                        <IonLabel
                                            className='ifcw-label'
                                            onClick={() => handleInlineRetrieve(val)}
                                        >
                                            {formInfo.name === 'adherentContent' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name image'>{val.adherent?.name} - {val.adherent?.email}</h3>
                                                </React.Fragment>
                                            )}
                                            {formInfo.name === 'asset' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name image'>{val.name}</h3>
                                                    {!val.active && <p className='ifcw-active-status not-active image'>{reduxText[5616]}</p>}
                                                </React.Fragment>
                                            )}
                                            {formInfo.name === 'content' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name image'>{val.project_child.name}</h3>
                                                    {!val.active && <p className='ifcw-active-status not-active image'>{reduxText[5616]}</p>}
                                                </React.Fragment>
                                            )}
                                            {formInfo.name === 'carteCategory' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name'>{val.name}</h3>
                                                    {!val.active && <p className='ifcw-active-status not-active'>{reduxText[5616]}</p>}
                                                </React.Fragment>
                                            )}
                                            {formInfo.name === 'carteItem' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name'>{val.name}</h3>
                                                    <p className='ifcw-active-status'>{val.price}</p>
                                                    {!val.active && <p className='ifcw-active-status not-active'>{reduxText[5616]}</p>}
                                                </React.Fragment>
                                            )}
                                            {formInfo.name === 'eclink' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name'>{val.link_url}</h3>
                                                    {!val.active && <p className='ifcw-active-status not-active'>{reduxText[5616]}</p>}
                                                </React.Fragment>
                                            )}
                                            {formInfo.name === 'image' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name image'>{val.description}</h3>
                                                    {val.custom_group?.id && (
                                                        <h3 className='ifcw-name image'>{val.custom_group.name}</h3>
                                                    )}
                                                    {!val.active && <p className='ifcw-active-status not-active image'>{reduxText[5616]}</p>}
                                                </React.Fragment>
                                            )}
                                            {formInfo.name === 'team' && (
                                                <div className='ifcw-team-wrap'>
                                                    <div className='ifcw-team-info'>
                                                        <h3 className='ifcw-name'>{val.member?.name || val.name}</h3>
                                                        <p className={`ifcw-active-status ${!val.active ? 'not-active' : ''}`}>{getMemberStatus(val)}</p>
                                                    </div>
                                                    <div className='ifcw-team-credit-type'>
                                                        <p className='ifcw-team-text'>{val.credit_type?.name}</p>
                                                    </div>
                                                    <div className='ifcw-team-roles'>
                                                        <p className='ifcw-team-text'>{val.roles}</p>
                                                        {val.custom_roles?.length > 0 && (
                                                            <p className='ifcw-team-text'>{reduxText[8331]}: {val.custom_roles?.map((val: any) => val.name).join(' / ')}</p>
                                                        )}
                                                        {val.custom_categories?.length > 0 && (
                                                            <p className='ifcw-team-text'>{reduxText[5569]}: {val.custom_categories?.map((val: any) => val.name).join(' / ')}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {formInfo.name === 'section' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name'>{val.name || val.category?.name}</h3>
                                                </React.Fragment>
                                            )}
                                            {mother.model === 'event' && formInfo.name === 'schedule' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name'>{getSchedule(reduxAuth, val.start_date, val.end_date, val.date_only, val.date_type_choice)}{val.child_text ? ` - ${val.child_text}` : ''}{val.child_text2 ? ` - ${val.child_text2}` : ''}</h3>
                                                    {val.name && <p className='ifcw-active-status'>{val.name}</p>}
                                                    {val.has_custom_address && val.address_json?.length! > 0 && (
                                                        <div className='ifcw-address'>
                                                            <i className='ifcw-icon mo-new-icon-map-marker-alt-solid' />
                                                            {val.address_json!.map((val2: any, i: number) => (
                                                                <span key={i}>
                                                                    {val2.description}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {val.team_list_grouped?.venue?.length! > 0 && (
                                                        <div className='ifcw-venue'>
                                                            <p className='ifcw-venue-text'>{reduxText[4905]}: </p>
                                                            {val.team_list_grouped!.venue!.map((val2: any) => (
                                                                <span>{val2.name}</span>
                                                            ))}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {mother.model === 'service' && formInfo.name === 'child' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name'>{`${val.day?.name} - ${getDateWithType(reduxAuth, val.time_start, 'tt')}${val.time_end ? ` - ${getDateWithType(reduxAuth, val.time_end, 'tt')}` : ''}`}</h3>
                                                    {val.child_text && <p className='ifcw-active-status'>{val.child_text}</p>}
                                                    {val.child_text2 && <p className='ifcw-active-status'>{val.child_text2}</p>}
                                                    {val.has_custom_address && val.address_json?.length! > 0 && (
                                                        <div className='ifcw-address'>
                                                            <i className='ifcw-icon mo-new-icon-map-marker-alt-solid' />
                                                            {val.address_json!.map((val2: any, i: number) => (
                                                                <span key={i}>
                                                                    {val2.description}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {val.team?.length > 0 && <p className='ifcw-active-status'>{val.team.map((val2: any) => val2.name).join(' - ')}</p>}
                                                </React.Fragment>
                                            )}
                                            {formInfo.name === 'variant' && (
                                                <React.Fragment>
                                                    <h3 className='ifcw-name'>{val.name}{val.sku ? ` (${val.sku})` : ''}</h3>
                                                    {!val.active && <p className='ifcw-active-status not-active'>{reduxText[5616]}</p>}
                                                </React.Fragment>
                                            )}
                                            {formInfo.name === 'website' && <h3 className='ifcw-name'>{val.website}</h3>}
                                        </IonLabel>
                                        <IonButton
                                            className='main-icon-block-web'
                                            color='tertiary'
                                            onClick={(e) => handleActionSheet(val, e)}
                                            size='small'
                                        >
                                            <i className='main-icon mo-new-icon-ellipsis-h-solid' />
                                        </IonButton>
                                        <IonReorder slot='end' />
                                    </IonItem>
                                )
                            })}
                        </IonReorderGroup>
                    </div>
                )}
                <InfiniteScrollHelperWeb
                    active={!disableInfiniteScroll}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    items={items}
                    onChange={onSearchNext}
                />
            </div>
            <InlineActionSheetWeb
                formInfo={formInfo}
                handleInlineActive={handleInlineActive}
                handleInlineDelete={() => setIsDeleteAlertOpen(true)}
                handleInlineGoToChild={handleInlineGoToChild}
                handleInlineRetrieve={handleInlineRetrieve}
                mother={mother}
                object={object}
                setShowActionSheet={setShowActionSheet}
                showActionSheet={showActionSheet}
            />
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={handleInlineDelete}
                    objectName={object?.name}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
            {isLightBoxOpen && (
                <LightboxCustom
                    mainSrc={imageSrc}
                    onCloseRequest={() => setIsLightBoxOpen(false)}
                />
            )}
            {isDeleting && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </div>
    )
})
