// react components
import React from 'react'
import {
    Snackbar,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
} from 'data'

// main
export const FormSuccessToast: React.FC = () => {

    const dispatch = useDispatch()
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <Snackbar
            action={
                <Button
                    edit={false}
                    fill='clear'
                    onClick={() => dispatch(reduxFormSetIsSuccessToastOpen(false))}
                    size='small'
                    text={reduxText[4742]}
                />
            }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={3000}
            message={reduxForm.isSuccessToastMessage || reduxText[5897]}
            onClose={() => dispatch(reduxFormSetIsSuccessToastOpen(false))}
            open={reduxForm.isSuccessToastOpen || false}
        />
    )
}
