// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelper,
} from 'components'

// data
import {
    api_url_participant_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ParticipantActionSheet,
} from 'pages'

// serializers
import {
    ParticipantEventListRowSerializer,
} from 'serializers/web'

// services
import {
    getSchedule,
    getApiUrl,
    getDetailObject,
} from 'services'

// props
type ParticipantListRowProps = {
    edit: boolean
    inDetailList?: boolean
    isInProfileSlug?: string
    modalKey: number
    object: ParticipantEventListRowSerializer
}

// main
export const ParticipantEventListRow: React.FC<ParticipantListRowProps> = React.memo(({
    edit,
    inDetailList,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [detailObject, setDetailObject] = useState<ParticipantEventListRowSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_participant_detail}${detailObject.id}/?block_size=small`, reduxAuth),
                component: 'ParticipantListSmallBlock',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ParticipantListSmallBlock',
                'onGetDetailData',
            ))
        }
    }

    return (
        <tr className='main-list-row ParticipantEventListRow'>
            <td>
                {/* <IconBlock
                    edit={edit}
                    iconClass='mo-new-icon-eye-solid'
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrl(detailObject.adherent?.get_absolute_url!)}
                /> */}
                <ParticipantActionSheet
                    edit={edit}
                    object={detailObject}
                    refreshData={onGetDetailData}
                    setShowActionPopover={setShowActionPopover}
                    showActionPopover={showActionPopover}
                    inModal={inDetailList}
                />
            </td>
            <td>{detailObject.event?.start_date && getSchedule(reduxAuth, detailObject.event?.start_date, detailObject.event?.end_date, detailObject.event?.date_only, detailObject.event?.date_type_choice)}</td>
            <td>
                <LinkHelper
                    edit={edit}
                    to={detailObject.event?.get_absolute_url!}
                >
                    {detailObject.event?.name}
                </LinkHelper>
            </td>
            <td><i className={`mlr-icon ${detailObject.is_paid ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'}`} /></td>
            <td className='tar'>{detailObject.amount}</td>
            <td>{detailObject.payment_date}</td>
        </tr>
    )
})
