// react components
import React, {
    useState,
} from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    IconBlock,
    Loader,
    SelectListInput,
} from 'components'

// data
import {
    api_url_new_block_template_helper,
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
    select_url_main_custom_category,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getFormData,
    globalHandleInputChange,
} from 'services'

// props
type AddToBlockTemplateHelperProps = {
    mixedMedia: boolean
    objectId: number
    setIsOpen: React.Dispatch<boolean>
    setIsPopoverOpen: React.Dispatch<boolean>
}

// main
export const AddToBlockTemplateHelper: React.FC<AddToBlockTemplateHelperProps> = React.memo(({
    mixedMedia,
    objectId,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type errorsType = {
        custom_categories?: string
    }
    type fieldsType = {
        custom_categories?: MainIdNameSerializer[]
    }

    const [formInfo, setFormInfo] = useState<{
        fields: fieldsType
        errors: errorsType
        formManyToMany: string[]
    }>({
        fields: {},
        errors: {},
        formManyToMany: [
            'custom_categories',
        ]
    })
    const [isLoading, setIsLoading] = useState(false)

    async function handleAddToTemplate() {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'AddToBlockTemplateHelper handleAddToTemplate')

            const postUrl = getApiUrl(`${api_url_new_block_template_helper}${objectId}/${mixedMedia ? '' : '?is_portfolio=true'}`, reduxAuth)
            const formData: any = getFormData(formInfo)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsOpen(false)
                    setIsLoading(false)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'AddToBlockTemplateHelper',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleAddToTemplate',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToBlockTemplateHelper',
                'handleAddToTemplate',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            setFormInfo(globalHandleInputChange({
                event,
                formInfo,
            }))
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'AddToBlockTemplateHelper',
                'handleInputChange',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class AddToBlockTemplateHelper'
            classes={{ paper: 'mo-dialog-wrapper fix-height fix-width' }}
            disableEnforceFocus
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[8681]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className={`mo-dialog-content mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <SelectListInput
                    apiUrl={`${select_url_main_custom_category}?profile_id=181639`}  // Z-Neo
                    clearable={false}
                    error={formInfo.errors?.custom_categories}
                    label={reduxText[5569]}
                    multi
                    name='custom_categories'
                    onChange={handleInputChange}
                    optional
                    profileId={181639}  // Z-Neo
                    value={formInfo.fields?.custom_categories || ''}
                />
            </DialogContent>
            <DialogActions className='mo-dialog-footer'>
                <Button
                    edit={false}
                    fill='outline'
                    onClick={() => setIsOpen(false)}
                    text={reduxText[4519]}
                />
                <Button
                    edit={false}
                    fill='outline'
                    onClick={handleAddToTemplate}
                    text={reduxText[4520]}
                />
            </DialogActions>
            {isLoading && (
                <Loader isOpen />
            )}
        </Dialog>
    )
})
