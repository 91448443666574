// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    CountViewBlock,
    CreditShortInfo,
    DescriptionDetailBlock,
    HeaderDetailBlock,
    ImageHelper,
    MainHashtag,
} from 'components'

// containers
import {
    DetailListContainerNew,
} from 'containers'

// data
import {
    api_url_event_image_list,
    api_url_event_participant_list,
    api_url_event_pricing_list,
    defaultReduxState,
    view_url_event_detail,
} from 'data'

// pages
import {
    EventParticipantListHeaderActionSheet,
    MixedContentBlock,
    ProjectListSmallBlockWeb,
    ScheduleListBlockWeb,
    ServiceListSmallBlock,
} from 'pages'

// serializers
import {
    EventDetailSerializer,
    MainTabHelperType,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getSchedule,
} from 'services'

// props
type EventDetailBlockWebProps = {
    edit: boolean
    inModal?: boolean
    languageId: number | undefined
    modalKeys: {
        project_list: number,
        service_list: number,
    }
    object: EventDetailSerializer
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const EventDetailBlockWeb: React.FC<EventDetailBlockWebProps> = React.memo(({
    edit,
    inModal,
    languageId,
    modalKeys,
    object,
    setLanguageId,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showActionPopover, setShowActionPopover] = useState(false)

    useEffect(() => {
        setTabValue({
            id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
            title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
        })
    }, [
        object?.id,
    ])

    const [tabValue, setTabValue] = useState<MainTabHelperType>({
        id: object.medium_type?.is_custom_page ? 'custom-page' : 'cover',
        title: object.medium_type?.is_custom_page ? reduxText[8489] : reduxText[4359],
    })

    const tabArray: MainTabHelperType[] = []
    if (object.medium_type?.is_custom_page || object.has_images || (object.owner_info && object.count_participant! > 0) || (object.owner_info && object.has_pricing)) {
        tabArray.push(
            {
                id: 'cover',
                title: reduxText[4359],
            },
        )
    }
    if (object.medium_type?.is_custom_page) {
        tabArray.push(
            {
                id: 'custom-page',
                title: reduxText[8489],
            },
        )
    }
    if (object.has_images) {
        tabArray.push(
            {
                id: 'images',
                isGallery: true,
                listApiUrl: getApiUrl(`${api_url_event_image_list}?event_id=${object.id}`, reduxAuth),
                title: reduxText[1625],
            },
        )
    }

    if (object.owner_info) {
        if (object.count_participant! > 0) {
            tabArray.push({
                id: 'participant',
                title: `${reduxText[8555]} (${object.count_participant})`,
                listComponent: 'ParticipantListRow',
                listApiUrl: getApiUrl(`${api_url_event_participant_list}?event_id=${object.id}`, reduxAuth),
                tableHeaderComponent: 'ParticipantListHeader',
            })
        }
        if (object.has_pricing) {
            tabArray.push({
                id: 'pricing',
                title: reduxText[8417],
                listComponent: 'PricingListRow',
                listApiUrl: getApiUrl(`${api_url_event_pricing_list}?event_id=${object.id}`, reduxAuth),
                tableHeaderComponent: 'PricingListHeader',
            })
        }
    }

    const hasTabs = !edit && tabArray.length > 1

    return (
        <div className={`main-detail-block event-detail-block-web${tabValue.id === 'custom-page' ? ' medium-type-custom' : ''}${edit ? ' edit' : ''}`}>
            <div className={`mdb-left${hasTabs ? ' tabs' : ''}${tabValue.tableHeaderComponent ? ' table' : ''}`}>
                {hasTabs && (
                    <div className='mdb-tabs'>
                        {tabArray.map(val => (
                            <div
                                key={val.id}
                                className={`mdb-buttons${val.id === tabValue.id ? ' active' : ''}`}
                                onClick={() => setTabValue(val)}
                            >
                                <span className='mdb-buttons-name'>{val.title}</span>
                                {val.id === 'participant' && (
                                    <EventParticipantListHeaderActionSheet
                                        edit={edit}
                                        inModal={inModal}
                                        object={object}
                                        setShowActionPopover={setShowActionPopover}
                                        showActionPopover={showActionPopover}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {(tabValue.listComponent || tabValue.isGallery)
                    ? (
                        <DetailListContainerNew
                            isGallery={tabValue.isGallery}
                            inModal={inModal}
                            listApiUrl={tabValue.listApiUrl!}
                            listComponent={tabValue.listComponent}
                            tableHeaderComponent={tabValue.tableHeaderComponent}
                        />
                    ) : (
                        <React.Fragment>
                            {tabValue.id === 'custom-page'
                                ? (
                                    <MixedContentBlock
                                        contentType='event'
                                        edit={edit}
                                        hasTabs={hasTabs}
                                        languageId={languageId}
                                        object={object}
                                    />
                                ) : (
                                    <ImageHelper
                                        alt={object.name}
                                        className='mdb-cover-image'
                                        dominant_color={object.get_dominant_color}
                                        lightBox
                                        lightBoxSrc={object.get_image_hq}
                                        src={object.get_image_hq}
                                    />
                                )
                            }
                        </React.Fragment>
                    )
                }
            </div>
            <div className={`mdb-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <HeaderDetailBlock
                    contentType='event'
                    edit={edit}
                    languageId={languageId}
                    object={object}
                    setLanguageId={setLanguageId}
                />
                <DescriptionDetailBlock>
                    {object.price && (
                        <div className='edbw-quick-info-wrap'>
                            <span className='edbw-quick-info-text'>{reduxText[4799]}: {object.price}</span>
                        </div>
                    )}
                    <div className='edbw-quick-info-wrap'>
                        <span className='edbw-quick-info-text'>{object.start_date && getSchedule(reduxAuth, object.start_date, object.end_date, object.date_only, object.date_type_choice?.id)}</span>
                    </div>
                    {object.is_online_event && (
                        <div className='edbw-quick-info-wrap'>
                            <span className='edbw-quick-info-text'>{reduxText[6389]}</span>
                        </div>
                    )}
                    {object.address_json?.length! > 0 && (
                        <div className='edbw-quick-info-wrap flex'>
                            <i className='edbw-icon mo-new-icon-map-marker-alt-solid' />
                            {object.address_json?.map((val, i) => (
                                <span
                                    key={i}
                                    className='edbw-quick-info-text'
                                >
                                    {val.description}
                                </span>
                            ))}
                        </div>
                    )}
                </DescriptionDetailBlock>
                {object.ticket_link_url && (
                    <DescriptionDetailBlock title={reduxText[3530]}>
                        <a
                            className='edbw-sold-out'
                            href={object.ticket_link_url}
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            {object.ticket_link_url}
                        </a>
                    </DescriptionDetailBlock>
                )}
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.description}
                    title={reduxText[1619]}
                />
                {object.schedule_list?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[4894]}>
                        {object.schedule_list!.map((val) => (
                            <ScheduleListBlockWeb
                                key={val.id}
                                edit={edit}
                                object={val}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.languages?.length! > 0 && (
                    <DescriptionDetailBlock
                        title={reduxText[4825]}
                    >
                        {object.languages!.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.team_list_grouped?.artist?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[1622]}>
                        {object.team_list_grouped!.artist!.map((val, i) => (
                            i < 10
                                ? (
                                    <CreditShortInfo
                                        key={val.id}
                                        edit={edit}
                                        object={val}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='edbw-section-button'
                                        edit={edit}
                                        fill='outline'
                                        size='small'
                                        text={reduxText[5588]}
                                        to={`${view_url_event_detail}${object.id}/${object.slug}/artist/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.team_list_grouped?.venue?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[4905]}>
                        {object.team_list_grouped!.venue!.map((val, i) => (
                            i < 10
                                ? (
                                    <CreditShortInfo
                                        key={val.id}
                                        edit={edit}
                                        object={val}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='edbw-section-button'
                                        edit={edit}
                                        fill='outline'
                                        size='small'
                                        text={reduxText[5589]}
                                        to={`${view_url_event_detail}${object.id}/${object.slug}/venue/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.team_list_grouped?.promoter?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[1624]}>
                        {object.team_list_grouped!.promoter!.map((val, i) => (
                            i < 10
                                ? (
                                    <CreditShortInfo
                                        key={val.id}
                                        edit={edit}
                                        object={val}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='edbw-section-button'
                                        edit={edit}
                                        fill='outline'
                                        size='small'
                                        text={reduxText[5590]}
                                        to={`${view_url_event_detail}${object.id}/${object.slug}/promoter/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.team_list_grouped?.partner?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[4679]}>
                        {object.team_list_grouped!.partner!.map((val, i) => (
                            i < 10
                                ? (
                                    <CreditShortInfo
                                        key={val.id}
                                        edit={edit}
                                        object={val}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='edbw-section-button'
                                        edit={edit}
                                        fill='outline'
                                        size='small'
                                        text={reduxText[5591]}
                                        to={`${view_url_event_detail}${object.id}/${object.slug}/partner/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.team_list_grouped?.staff?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[5406]}>
                        {object.team_list_grouped!.staff!.map((val, i) => (
                            i < 10
                                ? (
                                    <CreditShortInfo
                                        key={val.id}
                                        edit={edit}
                                        object={val}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='edbw-section-button'
                                        edit={edit}
                                        fill='outline'
                                        size='small'
                                        text={reduxText[5591]}
                                        to={`${view_url_event_detail}${object.id}/${object.slug}/partner/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.project_list?.length! > 0 && (
                    <DescriptionDetailBlock title={reduxText[3554]}>
                        {object.project_list!.map((val, i) => (
                            i < 4
                                ? (
                                    <ProjectListSmallBlockWeb
                                        key={val.id}
                                        edit={edit}
                                        modalKey={modalKeys.project_list}
                                        object={val}
                                        playerOrigin={`eventDetail-${object.id}`}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='edbw-section-button'
                                        edit={edit}
                                        fill='outline'
                                        size='small'
                                        text={reduxText[5594]}
                                        to={`${view_url_event_detail}${object.id}/${object.slug}/project/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.service_list?.length! > 0 && (
                    <DescriptionDetailBlock title='Services'>
                        {object.service_list!.map((val, i) => (
                            i < 4
                                ? (
                                    <ServiceListSmallBlock
                                        key={val.id}
                                        edit={edit}
                                        modalKey={modalKeys.service_list}
                                        object={val}
                                    />
                                ) : (
                                    <Button
                                        key={val.id}
                                        className='edbw-section-button'
                                        edit={edit}
                                        fill='outline'
                                        size='small'
                                        text={reduxText[8556]}
                                        to={`${view_url_event_detail}${object.id}/${object.slug}/service/`}
                                    />
                                )
                        ))}
                    </DescriptionDetailBlock>
                )}
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
