// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios from 'axios'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Link,
    Redirect,
    useLocation,
} from 'react-router-dom'

// components
import {
    BackgroundImageHelper,
    Button,
    ErrorHelper,
    Loader,
    TextInput,
} from 'components'

// data
import {
    api_url_auth_user_form_activation,
    defaultReduxState,
    reduxAuthLogin,
    reduxModalErrorEventHandler,
    view_url_admin,
    view_url_auth_password_reset,
    view_url_auth_signup,
    view_url_profile_detail_old,
    view_url_staff,
} from 'data'

// pages
import {
    GoogleLoginBlock,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// main
export const LoginPage: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const refPassword = useRef<HTMLIonInputElement>(null)
    const refUsername = useRef<HTMLIonInputElement>(null)

    useEffect(() => {
        if (location.hash && location.hash.split('/')[1] === 'activate') {
            const formData = new FormData()

            // Fields
            formData.append('uid', location.hash.split('/')[2])
            formData.append('token', location.hash.split('/')[3])

            // Submit Method
            const postUrl = getApiUrl(api_url_auth_user_form_activation, reduxAuth)
            axios({
                data: formData,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'LoginPage',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'useEffect',
                        skip401: true,
                        skipAlertStatusCode: [403],
                        skipSendError: Boolean(error.response?.data?.token),
                        skipSendErrorStatusCode: [403],
                    })
                })
        }
    }, [location.hash])

    type fieldsType = {
        password: string
        username: string
    }
    const fieldsInitial = {
        password: '',
        username: '',
    }
    const loginAuthError = reduxAuth.errors?.loginAuthError

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.value })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LoginPage',
                'handleInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            const username = refUsername.current?.value || fields.username
            const password = refPassword.current?.value || fields.password
            setButtonDisabled(true)
            setIsLoading(true)
            // @ts-ignore
            dispatch(reduxAuthLogin(
                // @ts-ignore
                username,
                password,
                reduxAuth,
                dispatch,
                setIsLoading,
                setFields,
                fieldsInitial,
            ))
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'LoginPage',
                'onSubmit',
            ))
        }
    }

    if (reduxAuth.authenticated && reduxAuth.settings?.active_profile) {
        if (reduxAuth.afterAuthUrl) {
            return <Redirect to={reduxAuth.afterAuthUrl} />
        }
        if (reduxAuth.settings.user?.is_staff) {
            return <Redirect to={view_url_staff} />
        }
        if (reduxAuth.settings.active_profile.is_new_profile_ux) {
            return <Redirect to={`${view_url_admin}${reduxAuth.settings?.active_profile?.id}/`} />
        }
        return <Redirect to={`${view_url_profile_detail_old}${reduxAuth.settings?.active_profile?.slug}/`} />
    }
    return (
        <IonPage className='login-page-web navbar-pt'>
            <Loader isOpen={isLoading} />
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                <div className='lpw-content'>
                    <div className='lpw-left auth-global-block-web'>
                        <h1 className='lpw-title'>{reduxText[5490]}</h1>
                        {!(location.hash && location.hash.split('/')[1] === 'activate') && (
                            <React.Fragment>
                                <div className='section-border'>
                                    <p className='auth-text'>{reduxText[26]} <Link to={view_url_auth_signup} className='auth-link'>{reduxText[27]}</Link></p>
                                    <GoogleLoginBlock
                                        setIsLoading={setIsLoading}
                                        signIn
                                    />
                                </div>
                                <div className='auth-separation'>
                                    <div className='auth-separation-line'>
                                        <hr />
                                    </div>
                                    <div className='auth-separation-text'>
                                        <p>{reduxText[5488]}</p>
                                    </div>
                                    <div className='auth-separation-line'>
                                        <hr />
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        {location.hash && location.hash.split('/')[1] === 'activate' && (<p className='auth-activation-text'>{reduxText[2831]}</p>)}
                        <TextInput
                            error={loginAuthError?.username}
                            helperText={reduxText[3110]}
                            label={reduxText[31]}
                            name='username'
                            onChange={handleInputChange}
                            // @ts-ignore
                            ref={refUsername}
                            // @ts-ignore
                            value={refUsername.current?.value}
                        />
                        <TextInput
                            error={loginAuthError?.password}
                            helperText={reduxText[253510]}
                            label={reduxText[2535]}
                            name='password'
                            onChange={handleInputChange}
                            passwordEye
                            type='password'
                            // @ts-ignore
                            ref={refPassword}
                            // @ts-ignore
                            value={refPassword.current?.value}
                        />
                        {(loginAuthError?.non_field_errors || loginAuthError?.detail) && (
                            <ErrorHelper error={loginAuthError.non_field_errors || loginAuthError.detail} />
                        )}
                        <Button
                            className='asp-submit-button'
                            disabled={buttonDisabled}
                            edit={false}
                            onClick={onSubmit}
                            text={reduxText[33]}
                        />
                        <Link
                            className='asp-password-reset'
                            to={view_url_auth_password_reset}
                        >
                            {reduxText[4762]}
                        </Link>
                        <div className='asp-terms'>
                            <div dangerouslySetInnerHTML={{ __html: reduxText[5492] }} />
                        </div>
                    </div>
                    <BackgroundImageHelper
                        className='lpw-right'
                        dominant_color={reduxText[600400]}
                        src={reduxText[6004]}
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}
