// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageCropInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    MaterialDetailFormSerializer,
    MaterialDetailFormSerializerError,
    MaterialFormState,
} from 'serializers/web'

// props
type CoverImageMaterialInputProps = {
    errors: MaterialDetailFormSerializerError | undefined
    fields: MaterialDetailFormSerializer
    mother: MaterialFormState['mother']
    onChange: any
}

// main
export const CoverImageMaterialInput: React.FC<CoverImageMaterialInputProps> = React.memo(({
    errors,
    fields,
    mother,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <ImageCropInput
            altButton
            altValue={fields.cover_image_alt}
            doka_output_data={fields.cover_image_doka_output_data || ''}
            error={errors?.cover_image}
            errorAlt={errors?.cover_image_alt}
            label={reduxText[4478]}
            name='cover_image'
            onChange={onChange}
            original={fields.cover_image_original || ''}
            translation={{
                contentType: 'material',
                defaultDetailUrl: mother.detailUrl,
                defaultLanguage: fields.translation_default_language,
                defaultUpdateUrl: mother.updateUrl,
                objectId: fields.id,
                profileId: fields.profile?.id,
            }}
            value={fields.cover_image || ''}
        />
    )
})
