// react components
import React from 'react'

// components
import {
    MainIframeBlock,
} from 'components'

// serializers
import {
    ProjectDetailSerializer,
} from 'serializers/web'

// props
type PdfContentBlockMobileProps = {
    edit: boolean
    object: ProjectDetailSerializer
}

// main
export const PdfContentBlockMobile: React.FC<PdfContentBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    if (!object.pdf_file) return null

    if (edit) {
        return (
            <MainIframeBlock
                url={object.pdf_preview || object.pdf_file}
            />
        )
    }

    return (
        <MainIframeBlock
            url={object.pdf_file}
        />
    )
})
