// react components
import React from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    CheckboxInput,
    ImageCropInput,
    PortfolioUserGroupsFieldInput,
    SelectListInput,
    StylesExtraFieldInput,
    TextAreaInput,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormSetEditingSite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    select_url_main_medium_type_page,
    view_url_portfolio_form,
} from 'data'

// serializers
import {
    PortfolioFormState,
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getMainErrorMessage,
} from 'services'

// props
type MatchParams = {
    id: string
    contentId: string
    detailId: string
    pageSlug: string
    panelValue: string
}

type DetailPagePortfolioPanelProps = {
    formState: PortfolioFormState
    handleInputChange: any
    helpers: reduxFormHelpersState
    setFormState: React.Dispatch<PortfolioFormState>
    setHelpers: React.Dispatch<reduxFormHelpersState>
}

// main
export const DetailPagePortfolioPanel: React.FC<DetailPagePortfolioPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSitepermissions = useSelector((state: defaultReduxState) => state.reduxFormSite.permissions)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const page = formState.page

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, page)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPagePortfolioPanel',
                'onHandleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields({
                formInfoObject: page,
                reduxText,
            })
            if (result === 'ok') {
                handleSubmit()
            } else {
                setHelpers({
                    ...helpers,
                    mainError: reduxText[4798],
                })
                setFormState({
                    ...formState,
                    page: {
                        ...formState.page,
                        errors: result,
                    }
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPagePortfolioPanel',
                'handleRequired',
            ))
        }
    }

    async function handleSubmit() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DetailPagePortfolioPanel handleSubmit')

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
                isSuccess: false,
            })

            const axiosUrl = `${page.updateUrl}${page.fields?.id}/`
            const formData: any = getFormData(page)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetIsSuccessToastOpen(true))
                    setHelpers({
                        ...helpers,
                        buttonDisabled: true,
                        isSaving: false,
                        isSuccess: true,
                        mainError: '',
                    })
                    setFormState({
                        ...formState,
                        page: {
                            ...formState.page,
                            errors: {},
                            fields: {
                                ...page.fields,
                                id: response.data.id,
                                // slug: response.data.slug,  TO_CHECK
                            },
                        }
                    })
                    dispatch(reduxFormSetEditingSite(false))
                    if (response.data.slug === params.pageSlug) {
                        dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                    } else {
                        history.replace(`${view_url_portfolio_form}${params.id}/${params.panelValue}/${params.contentId}/${response.data.slug}/`)
                    }
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                        isSuccess: false,
                        mainError: reduxText[4798],
                    })
                    setFormState({
                        ...formState,
                        page: {
                            ...formState.page,
                            errors: getMainErrorMessage({
                                error,
                                reduxText,
                            }),
                        }
                    })
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'DetailPagePortfolioPanel',
                        dispatch,
                        error,
                        formFields: JSON.stringify(page.fields),
                        formFieldsToIgnore: ['name'],
                        reduxAuth,
                        reference: 'handleSubmit',
                    })
                })
        } catch (error) {
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                isSaving: false,
                isSuccess: false,
            })
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPagePortfolioPanel',
                'handleSubmit',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    // Page
    const errorsInline = page?.errors
    const fieldsInline = page?.fields

    if (!fieldsInline) return null

    const permissionIsDisabledRest = reduxFormSitepermissions?.permissionIsDisabledRest
    const permissionIsDisabledText = reduxFormSitepermissions?.permissionIsDisabledText

    return (
        <React.Fragment>
            {reduxAuth.settings?.user?.is_staff && (
                <div className='pfcw-button-staff'>
                    <Button
                        disabled={helpers.buttonDisabled}
                        edit={false}
                        expand='full'
                        onClick={handleRequired}
                        size='x-small'
                        text={reduxText[6378]}
                    />
                </div>
            )}
            <div className={`pfcw-container-fields mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                {fieldsInline.page_type !== 'd2' && (
                    <TextInput
                        disabled={permissionIsDisabledRest || (fieldsMother?.id === 525 && reduxAuth.settings?.user?.id !== 1) || (fieldsInline.page_type !== 'n')}
                        error={errorsInline?.name}
                        label={reduxText[5559]}
                        name='name'
                        onChange={onHandleInputChange}
                        translation={{
                            contentType: 'page',
                            defaultDetailUrl: page.detailUrl2,
                            defaultLanguage: fieldsInline.translation_default_language,
                            defaultUpdateUrl: page.updateUrl,
                            objectId: fieldsInline.id,
                            profileId: fieldsMother?.profile?.id,
                        }}
                        value={fieldsInline.name || ''}
                    />
                )}
                {fieldsInline.page_type === 'd2' && (
                    <React.Fragment>
                        <TextInput
                            disabled={permissionIsDisabledRest}
                            error={errorsInline?.name}
                            label={reduxText[5559]}
                            name='name'
                            onChange={onHandleInputChange}
                            value={fieldsInline.name || ''}
                        />
                        <SelectListInput
                            apiUrl={select_url_main_medium_type_page}
                            disabled
                            clearable={false}
                            error={errorsInline?.medium_type}
                            label={reduxText[3228]}
                            name='medium_type'
                            onChange={onHandleInputChange}
                            value={fieldsInline.medium_type || ''}
                        />
                    </React.Fragment>
                )}
                {fieldsInline.page_type === 'n' && (
                    <React.Fragment>
                        <TextAreaInput
                            disabled={permissionIsDisabledText}
                            error={errorsInline?.description}
                            label={reduxText[4381]}
                            name='description'
                            onChange={onHandleInputChange}
                            translation={{
                                contentType: 'page',
                                defaultDetailUrl: page.detailUrl2,
                                defaultLanguage: fieldsInline.translation_default_language,
                                defaultUpdateUrl: page.updateUrl,
                                objectId: fieldsInline.id,
                                profileId: fieldsMother?.profile?.id,
                            }}
                            value={fieldsInline.description || ''}
                        />
                        {(reduxAuth.settings?.user?.id === 1 || fieldsInline.cover_image) && (
                            <ImageCropInput
                                altButton
                                altValue={fieldsInline.cover_image_alt}
                                clear
                                disabled={permissionIsDisabledText}
                                doka_output_data={fieldsInline.cover_image_doka_output_data || ''}
                                error={errorsInline?.cover_image}
                                helperText={reduxText[644110]}
                                label={reduxText[6441]}
                                name='cover_image'
                                onChange={onHandleInputChange}
                                original={fieldsInline.cover_image_original || ''}
                                staffOnly={!fieldsInline.cover_image}
                                value={fieldsInline.cover_image || ''}
                            />
                        )}
                        <CheckboxInput
                            disabled={permissionIsDisabledRest}
                            error={errorsInline?.is_home_page}
                            helperText={reduxText[634310]}
                            label={reduxText[6343]}
                            name='is_home_page'
                            onChange={onHandleInputChange}
                            value={fieldsInline.is_home_page}
                        />
                        {fieldsMother?.has_private_space && (
                            <React.Fragment>
                                <CheckboxInput
                                    disabled={permissionIsDisabledRest}
                                    error={errorsInline?.is_private}
                                    label={reduxText[8547]}
                                    name='is_private'
                                    onChange={onHandleInputChange}
                                    value={fieldsInline.is_private}
                                />
                                <PortfolioUserGroupsFieldInput
                                    disabled={permissionIsDisabledRest}
                                    errors={errorsInline}
                                    fields={fieldsInline}
                                    onChange={onHandleInputChange}
                                    profileId={fieldsMother.profile?.id}
                                    show={fieldsInline.is_private && fieldsMother.profile?.id === 183405}
                                />
                            </React.Fragment>
                        )}
                        {/* <CheckboxInput
                            disabled={permissionIsDisabledRest}
                            error={errorsInline?.is_in_navbar}
                            label={reduxText[6345]}
                            name='is_in_navbar'
                            onChange={onHandleInputChange}
                            value={fieldsInline.is_in_navbar}
                        />
                        <CheckboxInput
                            disabled={permissionIsDisabledRest}
                            error={errorsInline?.is_in_footer}
                            label={reduxText[6347]}
                            name='is_in_footer'
                            onChange={onHandleInputChange}
                            value={fieldsInline.is_in_footer}
                        /> */}
                    </React.Fragment>
                )}
                {fieldsInline.name === 'Hub' && (
                    <React.Fragment>
                        {reduxAuth.settings?.user?.id === 1 && (
                            <CheckboxInput
                                disabled={permissionIsDisabledRest}
                                error={errorsInline?.is_private}
                                label={reduxText[8547]}
                                name='is_private'
                                onChange={onHandleInputChange}
                                value={fieldsInline.is_private}
                            />
                        )}
                    </React.Fragment>
                )}
                <TextInput
                    disabled={permissionIsDisabledRest || fieldsInline.is_home_page}
                    error={errorsInline?.seo_title}
                    label={reduxText[6307]}
                    name='seo_title'
                    onChange={onHandleInputChange}
                    placeholder={fieldsInline.name}
                    translation={{
                        contentType: 'page',
                        defaultDetailUrl: page.detailUrl2,
                        defaultLanguage: fieldsInline.translation_default_language,
                        defaultUpdateUrl: page.updateUrl,
                        objectId: fieldsInline.id,
                        profileId: fieldsMother?.profile?.id,
                    }}
                    value={fieldsInline.is_home_page ? (fieldsMother?.seo_title || '') : (fieldsInline.seo_title || '')}
                />
                <TextAreaInput
                    disabled={permissionIsDisabledRest || fieldsInline.is_home_page}
                    error={errorsInline?.seo_description}
                    label={reduxText[6272]}
                    name='seo_description'
                    onChange={onHandleInputChange}
                    placeholder={fieldsInline.description}
                    translation={{
                        contentType: 'page',
                        defaultDetailUrl: page.detailUrl2,
                        defaultLanguage: fieldsInline.translation_default_language,
                        defaultUpdateUrl: page.updateUrl,
                        objectId: fieldsInline.id,
                        profileId: fieldsMother?.profile?.id,
                    }}
                    value={fieldsInline.is_home_page ? (fieldsMother?.description || '') : (fieldsInline.seo_description || '')}
                />
                {(fieldsInline.styles_extra || reduxAuth.settings?.user?.id === 1) && (
                    <StylesExtraFieldInput
                        disabled={permissionIsDisabledRest}
                        errors={errorsInline}
                        fields={fieldsInline}
                        onChange={onHandleInputChange}
                    />
                )}
            </div>
            <div className='pfcw-footer2'>
                <Button
                    disabled={helpers.buttonDisabled}
                    edit={false}
                    expand='full'
                    onClick={handleRequired}
                    text={reduxText[6378]}
                />
            </div>
        </React.Fragment>
    )
})
