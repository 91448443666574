// data
import {
    api_url_event_participant_form_create,
    api_url_event_participant_form_detail,
    api_url_event_participant_form_list,
    api_url_event_participant_form_update,
    reduxAuthState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function EventParticipantFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'participant',
        model: 'participant',

        // Settings
        detailComponent: 'tParticipantDetailBlock',

        // Urls
        activeUrl: '',
        createUrl: getApiUrl(api_url_event_participant_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_event_participant_form_detail, reduxAuth),
        formUrl: '',
        listUrl: getApiUrl(api_url_event_participant_form_list, reduxAuth),
        updateUrl: getApiUrl(api_url_event_participant_form_update, reduxAuth),
        viewUrl: '',

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [],
        requiredForeignKey: [
            'event',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'email',
            'first_name',
            'is_not_adherent',
            'last_name',
            'payment_date',
        ],
        formFile: [],
        formForeignKey: [
            'adherent',
            'adherent_parent',
            'event',
        ],
        formJson: [],
        formManyToMany: [],
    }
}
