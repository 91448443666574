// react components
import React from 'react'
import {
    IonSkeletonText,
} from '@ionic/react'

// main
export const EmptyListSmallBlockMobile: React.FC = () => {
    return (
        <div className='empty-list-small-block-mobile'>
            <div className='emlsbm-cover'>
                <div className='emlsbm-cover-wrap'>
                    <div className='emlsbm-image-wrap'>
                        <IonSkeletonText animated className='emlsbm-image' />
                    </div>
                </div>
            </div>
        </div>
    )
}
