// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    PortfolioUserGroupsFieldInput,
    StylesExtraFieldInput,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    CustomCategoryProjectInput,
    CustomTextProjectInput,
    CustomTextRichProjectInput,
    IsPrivateListedProjectInput,
    IsPrivateProjectInput,
    IsUnlistedProjectInput,
} from 'pages'

// serializers
import {
    ProjectChildFormState,
} from 'serializers/web'

type PortfolioProjectChildPanelProps = {
    formState: ProjectChildFormState
    handleInputChange: any
}

// main
export const PortfolioProjectChildPanel: React.FC<PortfolioProjectChildPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PortfolioProjectChildPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            {!fieldsMother.album && (
                <React.Fragment>
                    <IsUnlistedProjectInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        onChange={onHandleInputChange}
                    />
                    <IsPrivateProjectInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        onChange={onHandleInputChange}
                    />
                    <IsPrivateListedProjectInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        onChange={onHandleInputChange}
                    />
                    <PortfolioUserGroupsFieldInput
                        errors={errorsMother}
                        fields={fieldsMother}
                        onChange={onHandleInputChange}
                        profileId={fieldsMother.profile?.id}
                        show={fieldsMother.is_private && fieldsMother.profile?.id === 183405}
                    />
                </React.Fragment>
            )}
            <CustomCategoryProjectInput
                ccKey={1}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomCategoryProjectInput
                ccKey={2}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomCategoryProjectInput
                ccKey={3}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <StylesExtraFieldInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CustomTextProjectInput
                ctKey={1}
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextProjectInput
                ctKey={2}
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextProjectInput
                ctKey={3}
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextRichProjectInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <CustomTextRichProjectInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
                version={2}
            />
        </React.Fragment>
    )
})
