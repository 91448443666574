// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectAsyncInput,
} from 'components'

// data
import {
    defaultReduxState,
    select_url_product,
} from 'data'

// serializers
import {
    ProductDetailFormSerializer,
    ProductDetailFormSerializerError,
} from 'serializers/web'

// props
type RelatedProductProductInputProps = {
    errors: ProductDetailFormSerializerError | undefined
    fields: ProductDetailFormSerializer
    onChange: any
}

// main
export const RelatedProductProductInput: React.FC<RelatedProductProductInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <SelectAsyncInput
            apiUrl={select_url_product}
            clearable={false}
            error={errors?.related_products}
            label={reduxText[8327]}
            layout='product'
            multi
            name='related_products'
            onChange={onChange}
            optional
            value={fields.related_products || ''}
        />
    )
})
