// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import axios, {
    AxiosRequestConfig,
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    DeleteAlert,
    ErrorHelper,
    IconBlock,
    InfiniteScrollHelperWeb,
    Loader,
    TextInput,
} from 'components'

// data
import {
    api_url_custom_category_form_create,
    api_url_custom_category_form_detail,
    api_url_custom_category_form_list,
    api_url_custom_category_form_update,
    api_url_custom_category_2_form_create,
    api_url_custom_category_2_form_detail,
    api_url_custom_category_2_form_list,
    api_url_custom_category_2_form_update,
    api_url_custom_category_3_form_create,
    api_url_custom_category_3_form_detail,
    api_url_custom_category_3_form_list,
    api_url_custom_category_3_form_update,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    MainIdNameSerializer,
} from 'serializers/web'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getInfiniteListWeb,
} from 'services'

// props
type CustomCategoryFormProps = {
    ccKey: number
    profileId: number
    setIsOpen: React.Dispatch<boolean>
}

// main
export const CustomCategoryForm: React.FC<CustomCategoryFormProps> = React.memo(({
    ccKey,
    profileId,
    setIsOpen,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type errorsType = {
        name?: string
        non_field_errors?: string
    }
    type fieldsType = {
        id?: number
        name?: string
        translation_default_language?: MainIdNameSerializer
    }
    const errorsInitial = {
        name: '',
        non_field_errors: '',
    }
    const fieldsInitial = {
        id: undefined,
        name: undefined,
    }

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState<MainIdNameSerializer[]>([])
    const [nextUrl, setNextUrl] = useState('')

    const [deleteSubMessage, setDeleteSubMessage] = useState<string>()
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [showInlineForm, setShowInlineForm] = useState(true)
    const [toDeleteId, setToDeleteId] = useState<number | undefined>(undefined)

    const api_url_dict: any = {
        1: {
            contentType: 'custom_category',
            create: api_url_custom_category_form_create,
            detail: api_url_custom_category_form_detail,
            list: api_url_custom_category_form_list,
            update: api_url_custom_category_form_update,
        },
        2: {
            contentType: 'custom_category_2',
            create: api_url_custom_category_2_form_create,
            detail: api_url_custom_category_2_form_detail,
            list: api_url_custom_category_2_form_list,
            update: api_url_custom_category_2_form_update,
        },
        3: {
            contentType: 'custom_category_3',
            create: api_url_custom_category_3_form_create,
            detail: api_url_custom_category_3_form_detail,
            list: api_url_custom_category_3_form_list,
            update: api_url_custom_category_3_form_update,
        },
    }

    useEffect(() => {
        onGetListData(getApiUrl(`${api_url_dict[ccKey].list}${profileId}/`, reduxAuth), true)
    }, [])

    async function handleDelete() {
        try {
            setIsDeleting(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'CustomCategoryForm handleDelete')
            const deleteUrl = getApiUrl(`${api_url_dict[ccKey].update}${toDeleteId}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    onGetListData(getApiUrl(`${api_url_dict[ccKey].list}${profileId}/`, reduxAuth), true)
                    resetForm()
                })
                .catch((error) => {
                    setIsDeleting(false)
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'CustomCategoryForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            setIsDeleting(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomCategoryForm',
                'handleDelete',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setFields({ ...fields, [name]: event.value })
            setErrors({ ...errors, [name]: '' })  // should be after setFields to avoid bug on TextArea
            setHelpers({
                ...helpers,
                buttonDisabled: false,
                init: true,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomCategoryForm',
                'handleInputChange',
            ))
        }
    }

    async function handleInlineRetrieve(clickedObject: MainIdNameSerializer) {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'CustomCategoryForm handleInlineRetrieve')
            const getUrl = getApiUrl(`${api_url_dict[ccKey].detail}${clickedObject.id}/`, reduxAuth)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setShowInlineForm(false)
                    clearTimeout(wto2)
                    wto2 = setTimeout(() => {
                        setShowInlineForm(true)
                    }, 100)
                    setErrors(errorsInitial)
                    setFields(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'CustomCategoryForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineRetrieve',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomCategoryForm',
                'handleInlineRetrieve',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteListWeb({
                apiUrl,
                axiosCancelToken,
                changingView,
                component: 'CustomCategoryForm',
                dispatch,
                items,
                reduxAuth,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setHasMore,
                setIsLoading,  // TODO optimise
                setItems,
                setNextUrl,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomCategoryForm',
                'onGteListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomCategoryForm',
                'onSearchNext',
            ))
        }
    }

    async function onSubmit() {
        try {

            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'CustomCategoryForm onSubmit')

            if (!fields.name) {
                setErrors({ ...errors, name: reduxText[2856] })
                return
            }

            setHelpers({
                ...helpers,
                buttonDisabled: true,
                isSaving: true,
            })

            let axiosUrl = getApiUrl(api_url_dict[ccKey].create, reduxAuth)

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            if (fields.id) {
                axiosMethod = 'put'
                axiosUrl = getApiUrl(`${api_url_dict[ccKey].update}${fields.id}/`, reduxAuth)
            }

            const formData: any = new FormData()
            formData.append('name', fields.name)
            formData.append('profile', profileId)

            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    onGetListData(getApiUrl(`${api_url_dict[ccKey].list}${profileId}/`, reduxAuth), true)
                    resetForm()
                    dispatch(reduxFormSetRefresh('refreshCustomCategory'))
                })
                .catch((error) => {
                    setHelpers({
                        ...helpers,
                        buttonDisabled: false,
                        isSaving: false,
                    })
                    if (error.response) {
                        setErrors(error.response && error.response.data)
                    }
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'CustomCategoryForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSubmit',
                        skipNonFieldErrors: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomCategoryForm',
                'onSubmit',
            ))
        }
    }

    let wto: any
    let wto2: any

    function resetForm() {
        try {
            setShowInlineForm(false)
            clearTimeout(wto)
            wto = setTimeout(() => {
                setShowInlineForm(true)
            }, 100)
            setHelpers(reduxFormInitialHelpers)
            setFields(fieldsInitial)
            setErrors(errorsInitial)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'CustomCategoryForm',
                'resetForm',
            ))
        }
    }

    return (
        <Dialog
            className='mo-dialog-class inline-form half'
            classes={{ paper: 'mo-dialog-wrapper' }}
            disableEnforceFocus
            fullWidth
            maxWidth='xl'
            onClose={() => setIsOpen(false)}
            open
        >
            <DialogTitle className='mo-dialog-header'>
                {reduxText[6581]}
                <div className='mo-dialog-header-close-wrap'>
                    <IconBlock
                        className='mo-dialog-header-close'
                        edit={false}
                        iconClass='mo-new-icon-times-solid'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </DialogTitle>
            <DialogContent className='mo-dialog-content no-padding'>
                <div className='mo-dialog-content-left'>
                    <div className={`mo-dialog-content-form-children mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                        {showInlineForm && (
                            <TextInput
                                error={errors?.name}
                                label={reduxText[5559]}
                                name='name'
                                onChange={handleInputChange}
                                translation={{
                                    contentType: api_url_dict[ccKey].contentType,
                                    defaultDetailUrl: getApiUrl(api_url_dict[ccKey].detail, reduxAuth),
                                    defaultLanguage: fields.translation_default_language,
                                    defaultUpdateUrl: getApiUrl(api_url_dict[ccKey].update, reduxAuth),
                                    objectId: fields.id,
                                    profileId: profileId,
                                }}
                                value={fields.name || ''}
                            />
                        )}
                        <ErrorHelper error={errors.non_field_errors} />
                    </div>
                    <div className='mo-dialog-footer'>
                        {helpers.init
                            ? (
                                <React.Fragment>
                                    <Button
                                        edit={false}
                                        expand='full'
                                        fill='outline'
                                        onClick={resetForm}
                                        padding='0 5px'
                                        text={reduxText[4519]}
                                    />
                                    <Button
                                        disabled={helpers.buttonDisabled}
                                        edit={false}
                                        expand='full'
                                        fill='outline'
                                        onClick={onSubmit}
                                        padding='0 5px'
                                        text={reduxText[4520]}
                                    />
                                </React.Fragment>
                            ) : (
                                <Button
                                    edit={false}
                                    fill='outline'
                                    onClick={() => setIsOpen(false)}
                                    text={reduxText[4742]}
                                />
                            )}
                    </div>
                </div>
                <div className={`mo-dialog-content-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                    {items.length > 0 && (
                        <div className='mo-dialog-content-table-container'>
                            <table>
                                <tbody>
                                    {items.map(val => {
                                        return (
                                            <tr className='main-list-row CustomCategoryForm'>
                                                <td>{val.name}</td>
                                                <td className='mlr-action'>
                                                    <IconBlock
                                                        edit={false}
                                                        iconClass='mo-new-icon-pencil-alt-solid'
                                                        onClick={() => handleInlineRetrieve(val)}
                                                    />
                                                    <IconBlock
                                                        edit={false}
                                                        iconClass='mo-new-icon-trash-solid'
                                                        onClick={() => {
                                                            setDeleteSubMessage(val.name)
                                                            setIsDeleteAlertOpen(true)
                                                            setToDeleteId(val.id)
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <InfiniteScrollHelperWeb
                                active={!disableInfiniteScroll}
                                hasMore={hasMore}
                                isLoading={isLoading}
                                items={items}
                                onChange={onSearchNext}
                            />
                        </div>
                    )}
                    <InfiniteScrollHelperWeb
                        active={!disableInfiniteScroll}
                        hasMore={hasMore}
                        isLoading={isLoading}
                        items={items}
                        onChange={onSearchNext}
                    />
                </div>
            </DialogContent>
            {helpers.isSaving && (
                <Loader isOpen />
            )}
            {isDeleteAlertOpen && (
                <DeleteAlert
                    handleDelete={handleDelete}
                    objectName={deleteSubMessage}
                    setIsDeleteAlertOpen={setIsDeleteAlertOpen}
                />
            )}
            {isDeleting && (
                <Loader
                    isOpen
                    message={reduxText[1242]}
                />
            )}
        </Dialog>
    )
})
