// react components
import React, {
    useEffect,
} from 'react'
import {
    Device,
} from '@capacitor/device'
import {
    ScreenOrientation,
} from '@ionic-native/screen-orientation'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxAuthApiRootUrl,
    reduxAuthDeviceInfo,
    reduxTextFetchMainText,
} from 'data'

// services
import {
    reduxAuthFetchSettingsHelper,
    removeParamsFromUrl,
    setCookie,
} from 'services'

// main
export const GlobalHelperMobile: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        removeParamsFromUrl('fbclid')
    }, [])

    useEffect(() => {
        if (reduxAuth.settings?.active_profile?.id) {
            reduxAuthFetchSettingsHelper(reduxAuth, dispatch)
        }
    }, [reduxAuth.settings?.active_profile?.id])

    useEffect(() => {
        const setLanguageCode = async () => {
            const deviceLanguageCode = await Device.getLanguageCode()
            dispatch(reduxAuthApiRootUrl(deviceLanguageCode.value, reduxAuth))
        }
        if (!reduxAuth.authenticated) setLanguageCode()

        const setDeviceInfo = async () => {
            const deviceInfo = await Device.getInfo()
            dispatch(reduxAuthDeviceInfo(deviceInfo))
        }
        setDeviceInfo()
    }, [])

    useEffect(() => {
        if (reduxAuth.settings?.language) {
            // @ts-ignore
            dispatch(reduxTextFetchMainText(reduxAuth, dispatch, reduxAuth.settings.language))
        }
    }, [reduxAuth.settings?.language])

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') return
        ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
    }, [])

    useEffect(() => {
        setCookie('mo_reduxAuthAccess', reduxAuth.access)
    }, [reduxAuth.access])

    useEffect(() => {
        setCookie('mo_reduxAuthApiDynamicRootUrl', reduxAuth.apiRootUrl)
    }, [reduxAuth.apiRootUrl])

    useEffect(() => {
        setCookie('mo_reduxAuthAuthenticated', reduxAuth.authenticated)
    }, [reduxAuth.authenticated])

    useEffect(() => {
        setCookie('mo_reduxAuthAxiosConfig', reduxAuth.axiosConfig)
    }, [reduxAuth.axiosConfig])

    useEffect(() => {
        if (reduxAuth.settings?.theme === 'light') {
            document.body.classList.remove('dark')
            document.body.classList.add('light')
        } else {
            document.body.classList.remove('light')
            document.body.classList.add('dark')
        }
    }, [reduxAuth.settings?.theme])

    useEffect(() => {
        const newReduxAuthSettings: any = {}

        const newActiveProfile: any = {}
        newActiveProfile.id = reduxAuth.settings?.active_profile?.id
        newActiveProfile.active = reduxAuth.settings?.active_profile?.active
        newActiveProfile.extra_data_json = reduxAuth.settings?.active_profile?.extra_data_json
        newActiveProfile.get_absolute_url = reduxAuth.settings?.active_profile?.get_absolute_url
        newActiveProfile.get_image_xs = reduxAuth.settings?.active_profile?.get_image_xs
        newActiveProfile.is_new_profile_ux = reduxAuth.settings?.active_profile?.is_new_profile_ux
        newActiveProfile.name = reduxAuth.settings?.active_profile?.name
        newActiveProfile.slug = reduxAuth.settings?.active_profile?.slug

        const newMainProfile: any = {}
        newMainProfile.id = reduxAuth.settings?.main_profile?.id

        const newUser: any = {}
        newUser.id = reduxAuth.settings?.user?.id
        newUser.is_staff = reduxAuth.settings?.user?.is_staff

        newReduxAuthSettings.id = reduxAuth.settings?.id
        newReduxAuthSettings.active_profile = newActiveProfile
        newReduxAuthSettings.language = reduxAuth.settings?.language
        newReduxAuthSettings.main_profile = newMainProfile
        newReduxAuthSettings.theme = reduxAuth.settings?.theme
        newReduxAuthSettings.user = newUser
        setCookie('mo_reduxAuthSettings', newReduxAuthSettings)
    }, [reduxAuth.settings])

    useEffect(() => {
        Object.entries(localStorage).map(
            x => x[0]
        ).filter(
            x => x.substring(0, 7) === 'persist'
        ).map(
            x => localStorage.removeItem(x)
        )
    }, [])

    return null
}
