// react components
import {
    googleLogout,
} from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import localforage from 'localforage'
import {
    isWindows,
} from 'react-device-detect'

// data
import {
    REDUX_AUTH_AFTER_AUTH_URL_SET,
    REDUX_AUTH_DEVICE_INFO,
    REDUX_AUTH_FETCH_ACTIONS,
    REDUX_AUTH_FETCH_SETTINGS,
    REDUX_AUTH_GOOGLE_AUTH_FULFILLED,
    REDUX_AUTH_GOOGLE_AUTH_REJECTED,
    REDUX_AUTH_LOGIN_FULFILLED,
    REDUX_AUTH_LOGIN_REJECTED,
    REDUX_AUTH_LOGOUT,
    REDUX_AUTH_SET_API_ROOT_URL,
    REDUX_AUTH_SET_IS_FIRST_LOADED,
} from './mo_actions'
import {
    reduxAuthState,
} from './mo_state'

// data
import {
    site_id,
} from 'data/web/mo_constant'

// services
import {
    deleteCookie,
    getCookie,
    setCookie,
} from 'services'

// constants
let apiUrlTemp: string
if (process.env.NODE_ENV !== 'production') {
    apiUrlTemp = 'http://localhost:8000/'
    // apiUrlTemp = 'https://api.justtheweb.io/'
} else {
    apiUrlTemp = 'https://api.justtheweb.io/'
}

const initialState: reduxAuthState = {
    access: getCookie('mo_reduxAuthAccess', 'json') || {},
    afterAuthUrl: '',
    apiPrefix: 'v1',
    apiRootUrl: getCookie('mo_reduxAuthApiDynamicRootUrl', 'string') || `${apiUrlTemp}fr/v1/${site_id}/`,
    authenticated: getCookie('mo_reduxAuthAuthenticated', 'boolean') || false,
    axiosConfig: getCookie('mo_reduxAuthAxiosConfig', 'json') || {},
    deviceInfo: undefined,
    errors: {},
    isFirstLoaded: false,
    isWindows: isWindows ? ' is-windows' : '',
    settings: getCookie('mo_reduxAuthSettings', 'json') || undefined,
    siteId: site_id,
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_AUTH_AFTER_AUTH_URL_SET:
            return {
                ...state,
                afterAuthUrl: action.payload,
            }
        case REDUX_AUTH_DEVICE_INFO:
            return {
                ...state,
                deviceInfo: action.payload,
                isWindows: isWindows ? ' is-windows' : '',
            }
        case REDUX_AUTH_LOGIN_FULFILLED:
            return {
                ...state,
                access: {
                    mo_token_type: 'django',
                    token: action.payload.access,
                    ...jwtDecode<any>(action.payload.access),
                },
                authenticated: true,
                axiosConfig: {
                    headers: {
                        Authorization: `JWT ${action.payload.access}`,
                    },
                },
                settings: action.payload.settings,
                errors: {},
            }
        case REDUX_AUTH_LOGIN_REJECTED:
            return {
                ...state,
                access: {},
                authenticated: false,
                axiosConfig: {},
                settings: {},
                errors: {
                    loginAuthError: action.payload.response?.data,
                    appleAuthError: false,
                    googleAuthError: false,
                },
            }
        case REDUX_AUTH_GOOGLE_AUTH_FULFILLED:
            return {
                ...state,
                access: {
                    mo_token_type: 'google',
                    token: action.payload.access,
                    ...jwtDecode<any>(action.payload.access),
                },
                authenticated: true,
                axiosConfig: {
                    headers: {
                        Authorization: `JWT ${action.payload.access}`,
                    },
                },
                settings: action.payload.settings,
                errors: action.payload.response || { non_field_errors: action.payload.statusText },
                appleAuthError: false,
                googleAuthError: false,
            }
        case REDUX_AUTH_GOOGLE_AUTH_REJECTED:
            return {
                ...state,
                access: {},
                authenticated: false,
                axiosConfig: {},
                settings: {},
                errors: {
                    googleAuthError: action.payload,
                },
            }
        case REDUX_AUTH_LOGOUT:
            if (state.access?.mo_token_type === 'google') {
                googleLogout()
            }
            deleteCookie('mo_reduxAuthAccess')
            deleteCookie('mo_reduxAuthAuthenticated')
            deleteCookie('mo_reduxAuthAxiosConfig')
            deleteCookie('mo_reduxAuthSettings')
            if (action.payload) {
                localStorage.clear()
                localforage.clear().then(() => {
                    window.location.replace('/')
                }).catch((err) => {
                    console.log(err)
                })
            }
            return {
                ...state,
                access: {},
                authenticated: false,
                axiosConfig: {},
                errors: {},
                settings: {
                    language: state.settings?.language,
                },
            }
        case REDUX_AUTH_FETCH_ACTIONS:
            return {
                ...state,
                actions: action.payload,
            }
        case REDUX_AUTH_FETCH_SETTINGS:
            return {
                ...state,
                settings: action.payload,
            }
        case REDUX_AUTH_SET_API_ROOT_URL: {
            if (!state.authenticated) {
                const newReduxAuthSettings: any = {}

                const newActiveProfile: any = {}
                newActiveProfile.id = state.settings?.active_profile?.id
                newActiveProfile.active = state.settings?.active_profile?.active
                newActiveProfile.extra_data_json = state.settings?.active_profile?.extra_data_json
                newActiveProfile.get_absolute_url = state.settings?.active_profile?.get_absolute_url
                newActiveProfile.get_image_xs = state.settings?.active_profile?.get_image_xs
                newActiveProfile.name = state.settings?.active_profile?.name
                newActiveProfile.slug = state.settings?.active_profile?.slug

                const newUser: any = {}
                newUser.id = state.settings?.user?.id
                newUser.is_staff = state.settings?.user?.is_staff

                newReduxAuthSettings.id = state.settings?.id
                newReduxAuthSettings.active_profile = newActiveProfile
                newReduxAuthSettings.language = action.payload
                newReduxAuthSettings.user = newUser
                setCookie('mo_reduxAuthSettings', newReduxAuthSettings)
            }
            return {
                ...state,
                apiRootUrl: `${apiUrlTemp}${action.payload}/${state.apiPrefix}/${state.siteId}/`,
                settings: {
                    ...state.settings,
                    language: action.payload,
                }
            }
        }
        case REDUX_AUTH_SET_IS_FIRST_LOADED:
            return {
                ...state,
                isFirstLoaded: true,
            }
        default:
            return state
    }
}
