// react components
import React from 'react'
import {
    IonAvatar,
} from '@ionic/react'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
    MainCountryFlag,
    MainHashtag,
} from 'components'

// serializers
import {
    CityTempSerializer,
    ProfileListSerializer,
} from 'serializers/web'

// props
type ProfileListSmallBlockMobileProps = {
    edit: boolean
    object: ProfileListSerializer
}

// main
export const ProfileListSmallBlockMobile: React.FC<ProfileListSmallBlockMobileProps> = React.memo(({
    edit,
    object,
}) => {

    const absolute_url = object.get_absolute_url

    // rework country to avoid duplicate flags
    const countryArray: CityTempSerializer[] = []
    if (edit && object.address_json && object.address_json.length > 0) {
        const countryArrayTemp: string[] = []
        if (object.address_json && object.address_json.length > 0) {
            object.address_json.map((val) => {
                if (val.countryCode && !countryArrayTemp.includes(val.countryCode)) {
                    countryArrayTemp.push(val.countryCode)
                    if (countryArray) countryArray.push(val)
                }
                return false
            })
        }
    }

    return (
        <div className='profile-list-small-block-mobile'>
            {!object.active && (
                <InactiveButton
                    edit={edit}
                    model='profile'
                    objectId={object.id!}
                    objectName={object.name!}
                    setDeleted={undefined}
                />
            )}
            <div className='pflsbm-wrap'>
                <div className='pflsbm-profile-pic'>
                    <LinkHelper
                        edit={edit}
                        to={absolute_url}
                    >
                        <IonAvatar className='pflsbm-image-link'>
                            <ImageHelper
                                alt={object.name}
                                className='pflsbm-image'
                                dominant_color={undefined}
                                lazyMobile={false}
                                src={object.get_image_square}
                            />
                        </IonAvatar>
                    </LinkHelper>
                </div>
                <div className='pflsbm-info'>
                    <LinkHelper
                        edit={edit}
                        to={absolute_url}
                    >
                        <p className='mo-list-block-name-mobile'>{object.name}</p>
                    </LinkHelper>
                    <div className='pflsbm-chips'>
                        {object.metiers && (
                            <MainHashtag
                                edit={edit}
                                label={object.metiers}
                            />
                        )}
                    </div>
                    <div className='pflsbm-country'>
                        {!edit && object.countries?.map((val) => (
                            <MainCountryFlag
                                key={val.id}
                                edit={edit}
                                object={val}
                            />
                        ))}
                        {edit && countryArray.map((val, i) => (
                            <MainCountryFlag
                                key={i}
                                edit={edit}
                                object={val}
                            />
                        ))}
                        {!edit && object.cities?.map((val) => (
                            <MainHashtag
                                key={`cities-${val.id}`}
                                edit={edit}
                                label={val.name}
                            />
                        ))}
                        {edit && object.address_json && object.address_json.length > 0 && (
                            <React.Fragment>
                                {object.address_json.map((val, i) => (
                                    <MainHashtag
                                        key={`cities-${i}`}
                                        edit={edit}
                                        label={val.cityName || val.description}
                                    />
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
})
