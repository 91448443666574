// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    CheckboxInputV2,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailObject,
} from 'services'

// props
type DetailBrevoProps = {
    object: any
}

// main
export const DetailBrevo: React.FC<DetailBrevoProps> = React.memo(({
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText).data

    const [items, setItems] = useState<{
        id: number
        name: string
        is_in_list: boolean
    }[]>([])

    useEffect(() => {
        onGetListData()
    }, [
        object.email,
        object.profile?.id,
    ])

    function onGetListData() {
        try {
            getDetailObject({
                apiUrl: getApiUrlV2(`main/helper/brevo/lists_with_contact_status/?profile_id=${object.profile.id}&email=${object.email}`, reduxAuth),
                component: 'DetailBrevo',
                dispatch,
                reduxAuth,
                setDetailObject: setItems,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailBrevo',
                'onGetListData',
            ))
        }
    }

    if (!object.email) {
        return (
            <div className='detail-brevo'>
                <p className='db-no-email'>{reduxText[10122]}</p>
            </div>
        )
    }

    return (
        <div className='detail-brevo'>
            {items.map(val => (
                <DetailBrevoItem
                    item={val}
                    key={val.id}
                    object={object}
                />
            ))}
        </div>
    )
})

// props
type DetailBrevoItemProps = {
    item: {
        id: number
        name: string
        is_in_list: boolean
    }
    object: any
}

const DetailBrevoItem: React.FC<DetailBrevoItemProps> = React.memo(({
    item,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [isChecked, setIsChecked] = useState<boolean>(item.is_in_list)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    async function onCheckboxChange(list_id: number, checked: boolean) {
        try {
            setIsLoading(true)
            setIsChecked(checked)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'DetailBrevo onCheckboxChange')
            const postUrl = getApiUrlV2(`main/helper/brevo/add_remove_email_to_list/`, reduxAuth)
            const formData: any = new FormData()
            formData.append('profile_id', object.profile.id)
            if (checked) {
                formData.append('mode', 'add')
            } else {
                formData.append('mode', 'remove')
            }
            formData.append('list_id', list_id)
            formData.append('email', object.email)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'DetailBrevo',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onCheckboxChange',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailBrevo',
                'onCheckboxChange',
            ))
        }
    }

    return (
        <div className='db-item'>
            <CheckboxInputV2
                disabled={isLoading}
                name='name'
                onChange={(e: any) => onCheckboxChange(item.id, e.value)}
                value={isChecked}
            />
            <p className='db-item-name'>{item.name}</p>
        </div>
    )
})
