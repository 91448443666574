// npm omponents
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    CountViewBlock,
    DescriptionDetailBlock,
    HeaderDetailBlock,
    ImageHelper,
    MainHashtag,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    MixedContentBlock,
} from 'pages'

// serializers
import {
    ProfileTeamDetailSerializerOld,
} from 'serializers/web'

// props
type MemberDetailBlockProps = {
    edit: boolean
    languageId: number | undefined
    object: ProfileTeamDetailSerializerOld
    setLanguageId: React.Dispatch<number> | undefined
}

// main
export const MemberDetailBlock: React.FC<MemberDetailBlockProps> = React.memo(({
    edit,
    languageId,
    object,
    setLanguageId,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className={`member-detail-block-web medium-type-${object.medium_type?.id}${edit ? ' edit' : ''}`}>
            <div className='mdbw-left'>
                {object.medium_type?.id === 33 && (
                    <MixedContentBlock
                        contentType='profileteam'
                        edit={edit}
                        languageId={languageId}
                        // @ts-ignore
                        object={object}
                    />
                )}
                {object.medium_type?.id !== 33 && (
                    <ImageHelper
                        alt={object.name}
                        className='mdbw-cover-image'
                        dominant_color={object.get_dominant_color}
                        src={object.get_image_hq}
                    />
                )}
            </div>
            <div className={`mdbw-right mo-hidden-vertical-scrollbar${reduxAuth.isWindows}`}>
                <HeaderDetailBlock
                    contentType='profileteam'
                    edit={edit}
                    languageId={languageId}
                    object={object}
                    setLanguageId={setLanguageId}
                />
                {object.roles && (
                    <DescriptionDetailBlock
                        title={reduxText[2979]}
                    >
                        <MainHashtag
                            edit={edit}
                            label={object.roles}
                        />
                    </DescriptionDetailBlock>
                )}
                <DescriptionDetailBlock
                    languageId={languageId}
                    linkify
                    text={object.owner_info?.overwrite_custom_text}
                    title={reduxText[4596]}
                />
                {object.owner_info && (
                    <CountViewBlock
                        object={object.owner_info}
                    />
                )}
            </div>
        </div>
    )
})
