// react components
import React from 'react'
import axios from 'axios'
import moment from 'moment'

// data
import {
    reduxFormHelpersState,
    reduxTextState,
} from 'data'

// main
export function getMediaVimeo({
    formInfo,
    formState,
    helpers,
    image_only,
    reduxText,
    setFormState,
    setGetMetaIsLoading,
    setHelpers,
    urltoParse,
}: {
    formInfo: any
    formState: any
    helpers: reduxFormHelpersState
    image_only: boolean
    reduxText: reduxTextState['data']
    setFormState: React.Dispatch<any>
    setGetMetaIsLoading: React.Dispatch<boolean>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    urltoParse: string
}) {

    setGetMetaIsLoading(true)

    let urltoParse1 = urltoParse
    const urltoParse2 = urltoParse.includes('http')
    if (!urltoParse2) {
        urltoParse1 = `http://${urltoParse1}`
    }
    const vimeoId = urltoParse1.split('/')[urltoParse1.split('/').length - 1]
    if (!vimeoId) return
    const axiosHeaders = {
        Authorization: 'Bearer 43c3b1a8e421b60a494967121267c387',
        Accept: 'application/vnd.vimeo.*+json;version=3.4',
    }
    axios({
        headers: axiosHeaders,
        method: 'get',
        url: `https://api.vimeo.com/videos/${vimeoId}`,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            let coverImageIndex = response.data.pictures.sizes.findIndex((object: any) => object.width === 1920)
            if (!coverImageIndex) {
                coverImageIndex = response.data.pictures.sizes[response.data.pictures.sizes.length - 1]
            }
            const coverImage = response.data.pictures.sizes[coverImageIndex].link
            if (image_only) {
                const toReturn = {
                    ...formInfo,
                    errors: {
                        ...formInfo.errors,
                        cover_image: '',
                        cover_image_doka_output_data: '',
                        cover_image_original: '',
                        cover_image_square: '',
                        cover_image_square_doka_output_data: '',
                        image: '',
                        link_url: '',
                        meta_image_url: '',
                    },
                    fields: {
                        ...formInfo.fields,
                        cover_image: coverImage,
                        get_image_hq: coverImage,
                        get_image_lq: coverImage,
                        get_image_mq: coverImage,
                        image: coverImage,
                        link_url: urltoParse,
                        meta_image_url: coverImage,
                    },
                }
                setFormState({
                    ...formState,
                    [formInfo.name]: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '', // TO_CHECK
                })
            } else {
                const toReturn = {
                    ...formInfo,
                    errors: {
                        ...formInfo.errors,
                        cover_image: '',
                        cover_image_doka_output_data: '',
                        cover_image_original: '',
                        cover_image_square: '',
                        cover_image_square_doka_output_data: '',
                        date: '',
                        date_type_choice: '',
                        description: '',
                        duration: '',
                        image: '',
                        link_id: '',
                        link_url: '',
                        meta_image_url: '',
                        name: '',
                    },
                    fields: {
                        ...formInfo.fields,
                        cover_image: coverImage,
                        cover_image_doka_output_data: 'to_delete_original',
                        cover_image_original: '',
                        cover_image_square: coverImage,
                        cover_image_square_doka_output_data: 'to_delete_all',
                        date: moment(response.data.release_time).format('YYYY-MM-DD'),
                        date_type_choice: {
                            id: 'd',
                            name: reduxText[4832],
                        },
                        description: response.data.description,
                        duration: moment.utc(response.data.duration * 1000).format('HH:mm:ss'),
                        get_image_hq: coverImage,
                        get_image_lq: coverImage,
                        get_image_mq: coverImage,
                        image: coverImage,
                        link_id: response.data.link,
                        link_url: urltoParse,
                        meta_image_url: coverImage,
                        name: response.data.name,
                    },
                }
                setFormState({
                    ...formState,
                    [formInfo.name]: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '', // TO_CHECK
                })
            }
            setGetMetaIsLoading(false)
        })
        .catch((error) => {
            setFormState({
                ...formState,
                [formInfo.name]: {
                    ...formInfo,
                    errors: {
                        ...formInfo.errors,
                        link_url: error.response?.statusText || 'Error',
                    },
                    fields: {
                        ...formInfo.fields,
                        link_url: urltoParse,
                    },
                },
            })
            setGetMetaIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log(error)
            if (process.env.NODE_ENV === 'development') console.log(error.response)
        })
}
