// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    Loader,
} from 'components'

// customers
import {
    AgendaDuQuartierQuartierFormEnd,
    AgendaDuQuartierQuartierFormStep1,
    AgendaDuQuartierQuartierFormStep10,
    AgendaDuQuartierQuartierFormStep2,
    AgendaDuQuartierQuartierFormStep3,
    AgendaDuQuartierQuartierFormStep4,
    AgendaDuQuartierQuartierFormStep5,
    AgendaDuQuartierQuartierFormStep6,
    AgendaDuQuartierQuartierFormStep7,
    AgendaDuQuartierQuartierFormStep8,
    AgendaDuQuartierQuartierFormStep9,
} from 'customers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    getApiUrlV2,
    getDetailObject,
    parseQuery,
    reduxAuthFetchSettingsHelper,
} from 'services'

// props
type AgendaDuQuartierQuartierSettingsProps = {
    stripe_invoice_id?: string
    // stripe_invoice_number: string
}

// main
export const AgendaDuQuartierQuartierSettings: React.FC<AgendaDuQuartierQuartierSettingsProps> = React.memo(({
    stripe_invoice_id,
    // stripe_invoice_number,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [step, setStep] = useState<number>(1)

    return (
        <div className='agenda-du-quartier-settings'>
            <div className='adqs-message-wrap'>
                <AgendaDuQuartierQuartierFormEnd />
            </div>
            <p className='adqs-message-wrap-title'>Informations nécessaires</p>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep1
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep2
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep8
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
            <p className='adqs-message-wrap-title second'>Informations complémentaires</p>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep3
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep4
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep5
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep6
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep7
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep9
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
            <div className='adqs-from-wrap'>
                <AgendaDuQuartierQuartierFormStep10
                    inline
                    setStep={setStep}
                    step={step}
                    submitOnly
                />
            </div>
        </div>
    )
})
