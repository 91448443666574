// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxFormSetHoveredBlockIdSite,
    reduxFormSetSectionIdSite,
    reduxModalErrorEventHandler,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    EditButtonPageContent,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    scrollToSection,
} from 'services'

// props
type MatchParams = {
    id: string
    contentId: string
    detailId: string
    pageSlug: string
    panelValue: string
}

type SelectionHelperPageContentProps = {
    object: PortfolioPageContentListSiteSerializer
}

// main
export const SelectionHelperPageContent: React.FC<SelectionHelperPageContentProps> = React.memo(({
    object,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSitenavbarHeight = useSelector((state: defaultReduxState) => state.reduxModalSite.navbarHeight)
    const reduxModalSitenavbarType = useSelector((state: defaultReduxState) => state.reduxModalSite.navbarType)
    const reduxModalSitenavbarWidth = useSelector((state: defaultReduxState) => state.reduxModalSite.navbarWidth)

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const active = [Number(params.contentId), Number(reduxFormSite.sectionId)].includes(object.id!)

    let editUrl = `${view_url_portfolio_form}${params.id}/content-container/${object.id}/`
    if (params.pageSlug) {
        editUrl += `${params.pageSlug}/`
    } else {
        editUrl += `${reduxFormSite.portfolioObject?.home_page_slug}/`
    }
    if (params.detailId) editUrl += `${params.detailId}/`

    function handleCssClassHover(mousedirection: 'enter' | 'leave') {
        try {
            if (mousedirection === 'enter') {
                dispatch(reduxFormSetHoveredBlockIdSite(object.id))
            } else {
                dispatch(reduxFormSetHoveredBlockIdSite(undefined))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectionHelperPageContent',
                'handleCssClassHover',
            ))
        }
    }

    function handleEdit() {
        history.push(editUrl)
        dispatch(reduxFormSetSectionIdSite(object.id))
        if (Number(params.contentId) === object.id) scrollToSection(params.contentId, (reduxModalSitenavbarType === 'top' ? reduxModalSitenavbarHeight : 0))
    }

    return (
        <React.Fragment>
            <div
                className={`selection-helper-page-content-web-left${active ? ' active' : ''}`}
                onMouseEnter={() => handleCssClassHover('enter')}
                onMouseLeave={() => handleCssClassHover('leave')}
                style={{
                    left: `${-60 - (reduxModalSitenavbarType === 'side' ? reduxModalSitenavbarWidth : 0)}px`,
                }}
            >
                {(!reduxFormSite.permissions?.permissionIsDisabledRest && object.id !== 0) && (
                    <div className='shpcwl-line-actions'>
                        <EditButtonPageContent
                            allowDrop
                            isPopoverOpen={isPopoverOpen}
                            object={object}
                            setIsPopoverOpen={setIsPopoverOpen}
                        >
                            <button
                                className='shpcwl-line-icon-link'
                                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                            >
                                <i className='mo-new-icon-ellipsis-h-solid shpcwl-line-icon' />
                            </button>
                        </EditButtonPageContent>
                        <button
                            className='shpcwl-line-icon-link'
                            onClick={() => handleEdit()}
                        >
                            <i className='mo-new-icon-pencil-alt-solid shpcwl-line-icon' />
                        </button>
                    </div>
                )}
                <div className='shpcwl-line' />
            </div>
            <div className={`selection-helper-page-content-web-right${active ? ' active' : ''}`} />
        </React.Fragment>
    )
})
