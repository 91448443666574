// react components
import React from 'react'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    AlwaysOpenOfferInput,
    CityTempOfferInput,
    CoverImageOfferInput,
    CoverImageSquareOfferInput,
    DescriptionOfferInput,
    MetierOfferInput,
    NameOfferInput,
    ProfileOfferInput,
    PublicationDateOfferInput,
    ValidDateOfferInput,
} from 'pages'

// serializers
import {
    OfferFormState,
} from 'serializers/web'

// props
type MainOfferPanelProps = {
    formState: OfferFormState
    handleInputChange: any
}

// main
export const MainOfferPanel: React.FC<MainOfferPanelProps> = React.memo(({
    formState,
    handleInputChange,
}) => {

    const dispatch = useDispatch()

    const mother = formState.mother

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, mother)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainOfferPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    return (
        <React.Fragment>
            <ProfileOfferInput
                disabled={Boolean(fieldsMother.id)}
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CoverImageOfferInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <NameOfferInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <DescriptionOfferInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <MetierOfferInput
                errors={errorsMother}
                fields={fieldsMother}
                mother={mother}
                onChange={onHandleInputChange}
            />
            <AlwaysOpenOfferInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <PublicationDateOfferInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <ValidDateOfferInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CityTempOfferInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
            <CoverImageSquareOfferInput
                errors={errorsMother}
                fields={fieldsMother}
                onChange={onHandleInputChange}
            />
        </React.Fragment>
    )
})
