// data
import {
    api_url_profile_form_active,
    api_url_profile_form_create,
    api_url_profile_form_detail,
    api_url_profile_form_update,
    reduxAuthState,
    view_url_profile_detail_old,
    view_url_profile_form,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function ProfileMotherFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'mother',

        // Settings
        detailComponent: 'ProfileDetailBlockWebOld',
        model: 'profile',

        // Urls
        activeUrl: getApiUrl(api_url_profile_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_profile_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_profile_form_detail, reduxAuth),
        formUrl: view_url_profile_form,
        updateUrl: getApiUrl(api_url_profile_form_update, reduxAuth),
        viewUrl: view_url_profile_detail_old,

        // Fields
        errors: {},
        fields: {},

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'name',
        ],
        requiredForeignKey: [],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'active',
            'add_to_member',
            'background_image_alt',
            'background_image_doka_output_data',
            'biography',
            'birth_date',
            'custom_date',
            'custom_date_2',
            'custom_text',
            'custom_text_rich',
            'death_date',
            'description',
            'image_alt',
            'image_doka_output_data',
            'image_square_doka_output_data',
            'is_multiple',
            'metiers',
            'name',
            'own_profile',
            'phone_number',
            'profile_email',
            'profile_email_sent',
            'social_links_to_add',
        ],
        formFile: [
            'background_image',
            'background_image_original',
            'image',
            'image_original',
            'image_square',
        ],
        formForeignKey: [
            'profile_created_by',
        ],
        formJson: [],
        formJsonNull: [
            'address_json',
        ],
        formManyToMany: [
            'countries_2',
            'custom_categories',
            'custom_categories_2',
            'custom_categories_3',
            'profile_sections',
        ],
    }
}
