// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    SketchPicker,
} from 'react-color'
import reactCSS from 'reactcss'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Checkbox,
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    GradientInputPortfolio,
} from 'pages'

// props
type ColorInputPortfolioProps = {
    canHideInput?: boolean
    error: string | undefined
    helperText: string
    label: string
    labelOriginal: string
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    presetColors?: string[]
    value: string
    value_LG?: string
    value_MD?: string
    value_type: 'LG' | 'MD' | 'SM'
}

// main
export const ColorInputPortfolio: React.FC<ColorInputPortfolioProps> = React.memo(({
    canHideInput,
    error,
    helperText,
    label,
    labelOriginal,
    name,
    onChange,
    optional,
    presetColors,
    value,
    value_LG,
    value_MD,
    value_type,
}) => {

    const dispatch = useDispatch()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setHiddenInput(reduxFormSite.collapseStyles!)
    }, [
        reduxFormSite.collapseStyles,
    ])

    const [color, setColor] = useState<any>(getColorFormatted(value))
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [gradient, setGradient] = useState<any>(value.includes('linear') ? value : '')
    const [hiddenInput, setHiddenInput] = useState(false)
    const [inherit, setInherit] = useState<boolean>((value_type === 'SM' && !value) || (value_type === 'MD' && !value))
    const [isGradient, setIsGradient] = useState<boolean>(value.includes('linear'))
    const [isInherit, setIsInherit] = useState<boolean>(['', 'inherit'].includes(value))

    function getColorFormatted(cssFormat: string) {
        const rgbaArray = (cssFormat.substring(0, cssFormat.length - 1).substring(5)).split(',')
        return {
            r: Number(rgbaArray[0]),
            g: Number(rgbaArray[1]),
            b: Number(rgbaArray[2]),
            a: Number(rgbaArray[3]),
        }
    }

    function handleInherit(checked: boolean) {
        try {
            if (checked) {
                setIsInherit(true)
                setIsGradient(false)
                onChange({
                    name: name,
                    value: 'inherit',
                })
            } else {
                setIsInherit(false)
                setIsGradient(gradient.includes('linear'))
                if (color.g) {
                    onChange({
                        name: name,
                        value: `rgba(${color.r},${color.g},${color.b},${color.a})`,
                    })
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ColorInputPortfolio',
                'handleInherit',
            ))
        }

    }

    function handleColorChange(color: any) {
        try {
            setIsInherit(false)
            setColor(color.rgb)
            onChange({
                name: name,
                value: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ColorInputPortfolio',
                'handleColorChange',
            ))
        }
    }


    function handleGradientChange(color: any) {
        try {
            setIsInherit(false)
            setGradient(color)
            onChange({
                name: name,
                value: color,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ColorInputPortfolio',
                'handleGradientChange',
            ))
        }
    }

    /* eslint-disable */
    function rgb2hex(orig: string) {
        const rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)
        const hex = rgb ?
            '#' +
            // @ts-ignore
            (rgb[1] | 1 << 8).toString(16).slice(1) +
            // @ts-ignore
            (rgb[2] | 1 << 8).toString(16).slice(1) +
            // @ts-ignore
            (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

        return hex;
    }
    /* eslint-enable */

    const styles = reactCSS({
        default: {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: !isInherit ? `rgba(${color.r},${color.g},${color.b},${color.a})` : undefined,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: 2,
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    })

    if (inherit) {
        const labelValue = value_type === 'SM' ? (value_MD || value_LG) : value_LG
        return (
            <div className={`mo-text-input-webnew mo-color-input-web${hiddenInput ? ' hidden-input' : ''}`}>
                <MainFormLabel
                    helperText={helperText}
                    hiddenInput={hiddenInput}
                    label={label}
                    labelOriginal={labelOriginal}
                    name={name}
                    optional={optional}
                    setHiddenInput={canHideInput ? () => setHiddenInput(!hiddenInput) : undefined}
                    value={labelValue ? (labelValue.includes('linear') ? 'gradient' : rgb2hex(labelValue)) : ''}
                    valueIsInherit={inherit}
                />
                {!hiddenInput && (
                    <div
                        className='ciw-wrap-disabled'
                        onClick={() => {
                            setInherit(false)
                            onChange({
                                name: name,
                                value: value_MD || value_LG,
                            })
                        }}
                    >
                        <div className='ciw-inherit-disabled-wrap'>
                            <div
                                className='ciw-inherit-disabled'
                                style={{
                                    background: labelValue,
                                }}
                            />
                        </div>
                        <div className='ciw-inherit'>
                            <Checkbox
                                checked={inherit}
                                onChange={() => {
                                    setInherit(false)
                                    onChange({
                                        name: name,
                                        value: value_MD || value_LG,
                                    })
                                }}
                            />
                            <span className='ciw-inherit-text'>{reduxText[6568]}</span>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    return (
        <div className={`mo-text-input-webnew mo-color-input-web${hiddenInput ? ' hidden-input' : ''}`}>
            <MainFormLabel
                helperText={helperText}
                hiddenInput={hiddenInput}
                isColor
                label={label}
                labelOriginal={labelOriginal}
                name={name}
                optional={optional}
                setHiddenInput={canHideInput ? () => setHiddenInput(!hiddenInput) : undefined}
                value={value ? (value.includes('linear') ? 'gradient' : rgb2hex(value)) : ''}
            />
            {!hiddenInput && (
                <div className='ciw-div'>
                    <div className='ciw-wrap'>
                        {isGradient
                            ? (
                                <GradientInputPortfolio
                                    name={name}
                                    onChange={(e: any) => handleGradientChange(e)}
                                    presetColors={presetColors}
                                    value={value}
                                />
                            ) : (
                                <React.Fragment>
                                    <div style={styles.swatch} onClick={() => {
                                        setDisplayColorPicker(!displayColorPicker)
                                    }}>
                                        <div style={styles.color} />
                                    </div>
                                    {displayColorPicker && (
                                        <div className='ciw-picker'>
                                            <div
                                                className='ciw-colorbox'
                                                onClick={() => {
                                                    setDisplayColorPicker(false)
                                                }}
                                            />
                                            <SketchPicker
                                                color={color}
                                                onChange={(e) => handleColorChange(e)}
                                                presetColors={presetColors ? ['#000000', '#FFFFFF', ...presetColors] : undefined}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            )
                        }
                        <div className='ciw-inherit'>
                            <Checkbox
                                checked={isGradient}
                                onChange={e => setIsGradient(e.target.checked)}
                            />
                            <span className='ciw-inherit-text'>{reduxText[6569]}</span>
                        </div>
                        {value_type === 'LG' && (
                            <div className='ciw-inherit'>
                                <Checkbox
                                    checked={isInherit}
                                    onChange={e => handleInherit(e.target.checked)}
                                />
                                <span className='ciw-inherit-text'>{reduxText[6568]}</span>
                            </div>
                        )}
                        {value_type !== 'LG' && (
                            <div className='ciw-inherit'>
                                <Checkbox
                                    checked={inherit}
                                    onChange={() => {
                                        setInherit(true)
                                        onChange({
                                            name: name,
                                            value: '',
                                        })
                                    }}
                                />
                                <span className='ciw-inherit-text'>{reduxText[6568]}</span>
                            </div>
                        )}
                    </div>
                    <p className='ciw-hex'>{value.includes('linear') ? value : `${value} - ${rgb2hex(value)}`}</p>
                </div>
            )}
            <ErrorHelper error={error} />
        </div>
    )
})
