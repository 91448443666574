export function getShuffleArray(array: any[]) {
    let currentIndex = array.length
    let temporaryValue = ''
    let randomIndex = null
    const newArray = array

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = newArray[currentIndex]
        newArray[currentIndex] = newArray[randomIndex]
        newArray[randomIndex] = temporaryValue
    }

    return newArray
}
