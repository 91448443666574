// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    InactiveButton,
    LinkHelper,
    ListProfileLink,
    MainChipListBlock,
    MainHashtag,
    PrivateButton,
    UnlistedButton,
} from 'components'

// data
import {
    api_url_material_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    searchTabValue,
} from 'data'

// pages
import {
    MaterialActionSheetWeb,
} from 'pages'

// serializers
import {
    MaterialListSerializer,
} from 'serializers/web'

// services
import {
    getApiUrl,
    getDetailObject,
    getModalAbsoluteUrl,
    onClickIsModal,
} from 'services'

// props
type MaterialListSmallBlockWebProps = {
    edit: boolean
    feedId?: number
    isInProfileSlug?: string
    modalKey: number
    object: MaterialListSerializer
}

// main
export const MaterialListSmallBlockWeb: React.FC<MaterialListSmallBlockWebProps> = React.memo(({
    edit,
    feedId,
    isInProfileSlug,
    modalKey,
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [activated, setActivated] = useState<boolean>(object.active || false)
    const [deleted, setDeleted] = useState(false)
    const [detailObject, setDetailObject] = useState<MaterialListSerializer>(object)
    const [showActionPopover, setShowActionPopover] = useState(false)

    function onGetDetailData() {
        try {
            getDetailObject({
                apiUrl: getApiUrl(`${api_url_material_detail}${detailObject.id}/?block_size=small`, reduxAuth),
                component: 'MaterialListSmallBlockWeb',
                dispatch,
                reduxAuth,
                setDetailObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MaterialListSmallBlockWeb',
                'onGetDetailData',
            ))
        }
    }

    return (
        <div
            className='material-list-small-block-web'
            style={{
                display: deleted ? 'none' : undefined,
            }}
        >
            <div className={`mlsbw-cover${showActionPopover ? ' is-open' : ''}`}>
                {!activated && (
                    <InactiveButton
                        edit={edit}
                        model='material'
                        objectId={detailObject.id!}
                        objectName={detailObject.name!}
                        setDeleted={setDeleted}
                    />
                )}
                {detailObject.is_private && (
                    <PrivateButton
                        edit={edit}
                        listed={detailObject.is_private_and_listed_in_portfolio}
                    />
                )}
                {detailObject.is_unlisted && (
                    <UnlistedButton edit={edit} />
                )}
                <LinkHelper
                    className='mlsbw-cover-wrap'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    <div className='mlsbw-image-wrap'>
                        <ImageHelper
                            alt={detailObject.name}
                            className='mlsbw-image'
                            dominant_color={detailObject.get_dominant_color}
                            src={detailObject.get_image_square}
                        />
                    </div>
                </LinkHelper>
                <div className='mlsbw-actions'>
                    <MaterialActionSheetWeb
                        edit={edit}
                        feedId={feedId}
                        isInProfileSlug={isInProfileSlug}
                        object={detailObject}
                        refreshData={onGetDetailData}
                        setActivated={setActivated}
                        setShowActionPopover={setShowActionPopover}
                        showActionPopover={showActionPopover}
                    />
                </div>
            </div>
            <div className='mlsbw-info'>
                {feedId && (
                    <p className='mo-list-feed-name-web'>{reduxText[8716]}</p>
                )}
                <LinkHelper
                    className='mo-list-block-name-web small'
                    edit={edit}
                    onClick={() => onClickIsModal(detailObject.id!, modalKey, dispatch)}
                    title={detailObject.name}
                    to={getModalAbsoluteUrl(detailObject.get_absolute_url!)}
                >
                    {detailObject.name}
                </LinkHelper>
                <div className='mlsbw-price-wrap'>
                    {detailObject.price
                        ? (
                            <p className='mlsbw-price'>{`${detailObject.price} ${detailObject.currency?.symbol || detailObject.currency?.iso_code} / ${detailObject.uom}`}</p>
                        ) : (
                            <p className='mlsbw-price'>{reduxText[4869]}</p>
                        )}
                </div>
                <div className='list-block-footer'>
                    {detailObject.profile && (
                        <ListProfileLink
                            blockSize='small'
                            edit={edit}
                            profile={detailObject.profile}
                        />
                    )}
                </div>
                <div className='mlsbw-tags'>
                    <MainChipListBlock>
                        {detailObject.custom_categories?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.material}
                            />
                        ))}
                        {/* {detailObject.custom_categories_2?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))}
                        {detailObject.custom_categories_3?.map((val) => (
                            <MainHashtag
                                key={val.id}
                                edit={edit}
                                label={val.name}
                                searchTab={searchTabValue.product}
                            />
                        ))} */}
                    </MainChipListBlock>
                </div>
            </div>
        </div>
    )
})
