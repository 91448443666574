// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import Select from 'react-select'

// components
import {
    Checkbox,
    ErrorHelper,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type SelectInputPortfolioProps = {
    canHideInput?: boolean
    clearable?: boolean
    error: string | undefined
    helperText: string
    label: string
    labelOriginal: string
    mixedMedia: boolean
    name: string
    onChange: any
    options?: any
    value: any
    value_LG?: string
    value_MD?: string
    value_type?: 'LG' | 'MD' | 'SM'
}

// main
export const SelectInputPortfolio: React.FC<SelectInputPortfolioProps> = React.memo(({
    canHideInput,
    clearable,
    error,
    helperText,
    label,
    labelOriginal,
    mixedMedia,
    name,
    onChange,
    options,
    value,
    value_LG,
    value_MD,
    value_type,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfoliofonts = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.fonts)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxFormmixedFonts = useSelector((state: defaultReduxState) => state.reduxForm.mixedFonts)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setHiddenInput(reduxFormSite.collapseStyles!)
    }, [
        reduxFormSite.collapseStyles,
    ])

    const [hiddenInput, setHiddenInput] = useState(false)
    const [inherit, setInherit] = useState<boolean>((value_type === 'SM' && !value) || (value_type === 'MD' && !value))
    const [newOptions, setNewOptions] = useState<any[]>(options)
    const [valueState, setValueState] = useState<any>(value)

    useEffect(() => {
        if (name === 'fontFamily') {
            const tempArray: any[] = []
            if (mixedMedia) {
                reduxFormmixedFonts?.map((val) => {
                    tempArray.push({
                        id: val.name,
                        name: val.name,
                    })
                })
            } else {
                reduxCacheSiteportfoliofonts?.map((val) => {
                    tempArray.push({
                        id: val.name,
                        name: val.name,
                    })
                })
            }
            setNewOptions(tempArray)
        } else {
            setNewOptions(options)
        }
    }, [
        options,
        reduxCacheSiteportfoliofonts,
    ])

    function onInputChange(newValue: any) {
        try {
            if (newValue === null && valueState !== '') {
                setValueState('')
                onChange({
                    name: name,
                    value: '',
                })
            } else if (newValue === null && valueState === '') {
                return
            } else if (newValue.id !== valueState) {
                setValueState(newValue.id)
                onChange({
                    name: name,
                    value: newValue.id,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectInputPortfolio',
                'onInputChange',
            ))
        }
    }

    const customStyles = {
        clearIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            border: '1px solid var(--mo-color-ln)',
            borderRadius: '8px',
            ':hover': {
                ...provided[':hover'],
                border: '1px solid var(--mo-color-ln)',
                boxShadow: 'none',
            },
            ':focus': {
                ...provided[':focus'],
                boxShadow: '0 0 0 2px var(--mo-color-black)',
                outline: '1px solid var(--mo-color-black)',
            },
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--mo-color-text1)',
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            borderRadius: '8px',
            boxShadow: '2px 6px 21px -2px rgba(0,0,0,0.75)',
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            boxShadow: '0 0 4px 2px rgba(var(--mo-color-mo-color-rgb), 0.70)',
            border: '1px solid var(--mo-color-mo-color)',
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            background: 'var(--mo-color-bg2)',
            borderBottom: '1px solid var(--mo-color-ln)',
            color: state.isSelected ? 'var(--mo-color-mo-color)' : 'var(--mo-color-text1)',
            ':active': {
                ...provided[':active'],
                backgroundColor: 'var(--mo-color-bg2)',
                color: 'var(--mo-color-mo-color)',
            },
            ':hover': {
                ...provided[':hover'],
                backgroundColor: 'var(--mo-color-mo-color)',
                color: 'var(--mo-color-text1)',
                cursor: 'pointer',
            },
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: 'var(--mo-color-text1)',
        }),
    }

    if (inherit) {
        const labelValue = value_type === 'SM' ? (value_MD || value_LG) : value_LG
        return (
            <div className={`mo-text-input-webnew mo-quadruple-input-web${hiddenInput ? ' hidden-input' : ''}`}>
                <MainFormLabel
                    helperText={helperText}
                    hiddenInput={hiddenInput}
                    label={label}
                    labelOriginal={labelOriginal}
                    name={name}
                    setHiddenInput={canHideInput ? () => setHiddenInput(!hiddenInput) : undefined}
                    value={labelValue}
                    valueIsInherit={inherit}
                />
                {!hiddenInput && (
                    <div
                        className='qiw-input-wrap'
                        onClick={() => {
                            setInherit(false)
                            setValueState('')
                        }}
                    >
                        <input
                            className='mo-text-input-web-inputnew disabled'
                            disabled
                            name={name}
                            type='text'
                            value={labelValue}
                        />
                        <div className='qiw-inherit'>
                            <Checkbox
                                checked={inherit}
                                onChange={() => {
                                    setInherit(false)
                                    setValueState('')
                                }}
                            />
                            <span className='qiw-inherit-text'>{reduxText[6568]}</span>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    return (
        <div className={`mo-text-input-webnew mo-quadruple-input-web${hiddenInput ? ' hidden-input' : ''}`}>
            <MainFormLabel
                helperText={helperText}
                hiddenInput={hiddenInput}
                label={label}
                labelOriginal={labelOriginal}
                name={name}
                setHiddenInput={canHideInput ? () => setHiddenInput(!hiddenInput) : undefined}
                value={value}
            />
            {!hiddenInput && (
                <div className='mo-text-input-wrap'>
                    <div className='mo-text-input-web-input'>
                        <Select
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            isClearable={clearable}
                            menuPlacement='auto'
                            name={name}
                            onChange={(e) => onInputChange(e)}
                            options={newOptions}
                            styles={customStyles}
                            value={{
                                id: valueState,
                                name: valueState,
                            }}
                        />
                    </div>
                    {value_type && value_type !== 'LG' && (
                        <div className='qiw-inherit'>
                            <Checkbox
                                checked={inherit}
                                onChange={() => {
                                    setInherit(true)
                                    setValueState('')
                                    onChange({
                                        name: name,
                                        value: '',
                                    })
                                }}
                            />
                            <span className='qiw-inherit-text'>{reduxText[6568]}</span>
                        </div>
                    )}
                </div>
            )}
            <ErrorHelper error={error} />
        </div>
    )
})
