// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DateTimeInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProjectDetailFormSerializer,
    ProjectDetailFormSerializerError,
} from 'serializers/web'

// props
type DateProjectInputProps = {
    errors: ProjectDetailFormSerializerError | undefined
    fields: ProjectDetailFormSerializer
    hasLabel?: boolean
    noMarginBottom?: boolean
    onChange: any
}

// main
export const DateProjectInput: React.FC<DateProjectInputProps> = React.memo(({
    errors,
    fields,
    hasLabel = true,
    noMarginBottom,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <DateTimeInput
            error={errors?.date}
            format='date'
            formatDate={fields.date_type_choice?.id === 'y' ? 'yyyy' : (fields.date_type_choice?.id === 'd' ? 'dd/MM/yyyy' : 'MM/yyyy')}
            label={hasLabel && reduxText[2943]}
            name='date'
            noMarginBottom
            onChange={onChange}
            value={fields.date || null}
        />
    )
})
