// data
import {
    api_url_adherent_form_active,
    api_url_adherent_form_create,
    api_url_adherent_form_detail,
    api_url_adherent_form_update,
    reduxAuthState,
    view_url_adherent_detail,
    view_url_adherent_form,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function AdherentFormState(reduxAuth: reduxAuthState) {
    return {
        name: 'adherent',
        model: 'adherent',

        // Settings
        detailComponent: 'AdherentDetailBlock',

        // Urls
        activeUrl: getApiUrl(api_url_adherent_form_active, reduxAuth),
        createUrl: getApiUrl(api_url_adherent_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_adherent_form_detail, reduxAuth),
        formUrl: view_url_adherent_form,
        updateUrl: getApiUrl(api_url_adherent_form_update, reduxAuth),
        viewUrl: view_url_adherent_detail,

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAllFields: [],
        requiredAttribute: [
            'last_name',
        ],
        requiredForeignKey: [
            'profile',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'address_additional',
            'adherent_number',
            'custom_date_1',
            'custom_text_1',
            'date_membership_end',
            'date_membership_start',
            'email',
            'first_name',
            'is_parent',
            'last_name',
            'phone_number',
        ],
        formFile: [],
        formForeignKey: [
            'custom_fk_cc_1',
            'parent',
            'profile',
        ],
        formJson: [],
        formJsonNull: [
            'address_json',
        ],
        formManyToMany: [
            'custom_categories',
            'custom_mtm_cc_1',
            'many_to_many_profile_teams',
            'portfolio_user_groups',
        ],
    }
}
