// react components
import React from 'react'
import axios from 'axios'
import moment from 'moment'

// data
import {
    api_url_media_form_youtube,
    reduxAuthState,
    reduxFormHelpersState,
    reduxTextState,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function getMediaYoutubeV2({
    formState,
    helpers,
    image_only,
    prefixImage,
    prefixLink = '',
    reduxAuth,
    reduxText,
    setFormState,
    setGetMetaIsLoading,
    setHelpers,
    urltoParse,
}: {
    formState: any
    helpers: reduxFormHelpersState
    image_only: boolean
    prefixImage: string
    prefixLink?: string
    reduxAuth: reduxAuthState
    reduxText: reduxTextState['data']
    setFormState: React.Dispatch<any>
    setGetMetaIsLoading: React.Dispatch<boolean>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    urltoParse: string
}) {

    setGetMetaIsLoading(true)

    /* eslint-disable */
    const youtubeRegex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g
    /* eslint-enable */
    const str = urltoParse
    let m

    while ((m = youtubeRegex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === youtubeRegex.lastIndex) {
            youtubeRegex.lastIndex++
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
            if (groupIndex === 1) {
                axios({
                    headers: reduxAuth.axiosConfig?.headers,
                    method: 'get',
                    url: `${getApiUrl(api_url_media_form_youtube, reduxAuth)}?youtube_id=${match}`,
                })
                    .then((response) => { // response data form google api youtube
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        const thumbnail = response.data.thumbnail
                        if (image_only) {
                            const toReturn = {
                                ...formState,
                                data: {
                                    ...formState.data,
                                    errors: {
                                        ...formState.data.errors,
                                        [`${prefixImage}image_doka_output_data`]: '',
                                        [`${prefixImage}image_original`]: '',
                                        [`${prefixImage}image_square_doka_output_data`]: '',
                                        [`${prefixImage}image_square`]: '',
                                        [`${prefixImage}image`]: '',
                                        [`${prefixLink}link_url`]: '',
                                        meta_image_url: '',
                                    },
                                    fields: {
                                        ...formState.data.fields,
                                        [`${prefixImage}image`]: thumbnail,
                                        [`${prefixLink}link_url`]: urltoParse,
                                        get_image_hq: thumbnail,
                                        get_image_lq: thumbnail,
                                        get_image_mq: thumbnail,
                                        meta_image_url: thumbnail,
                                    },
                                }
                            }
                            setFormState(toReturn)
                            setHelpers({
                                ...helpers,
                                buttonDisabled: false,
                                init: true,
                                mainError: '',
                            })
                        } else {
                            const toReturn = {
                                ...formState,
                                data: {
                                    ...formState.data,
                                    errors: {
                                        ...formState.data.errors,
                                        [`${prefixImage}image_doka_output_data`]: '',
                                        [`${prefixImage}image_original`]: '',
                                        [`${prefixImage}image_square_doka_output_data`]: '',
                                        [`${prefixImage}image_square`]: '',
                                        [`${prefixImage}image`]: '',
                                        [`${prefixLink}duration`]: '',
                                        [`${prefixLink}link_id`]: '',
                                        [`${prefixLink}link_url`]: '',
                                        date: '',
                                        date_type_choice: '',
                                        description: '',
                                        meta_image_url: '',
                                        name: '',
                                    },
                                    fields: {
                                        ...formState.data.fields,
                                        [`${prefixImage}image_doka_output_data`]: 'to_delete_original',
                                        [`${prefixImage}image_original`]: '',
                                        [`${prefixImage}image_square_doka_output_data`]: 'to_delete_all',
                                        [`${prefixImage}image_square`]: thumbnail,
                                        [`${prefixImage}image`]: thumbnail,
                                        [`${prefixLink}duration`]: moment.utc(moment.duration(response.data.duration).asMilliseconds()).format('HH:mm:ss'),
                                        [`${prefixLink}link_id`]: response.data.link_id,
                                        [`${prefixLink}link_url`]: urltoParse,
                                        date: moment(response.data.date).format('YYYY-MM-DD'),
                                        date_type_choice: {
                                            id: 'd',
                                            name: reduxText[4832],
                                        },
                                        description: response.data.description,
                                        get_image_hq: thumbnail,
                                        get_image_lq: thumbnail,
                                        get_image_mq: thumbnail,
                                        meta_image_url: thumbnail,
                                        name: response.data.name,
                                    },
                                }
                            }
                            setFormState(toReturn)
                            setHelpers({
                                ...helpers,
                                buttonDisabled: false,
                                init: true,
                                mainError: '',
                            })
                        }
                        setGetMetaIsLoading(false)
                    })
                    .catch((error) => {
                        setFormState({
                            ...formState,
                            data: {
                                ...formState.data,
                                errors: {
                                    ...formState.data.errors,
                                    [`${prefixLink}link_url`]: error.response?.statusText || 'Error',
                                },
                                fields: {
                                    ...formState.data.fields,
                                    [`${prefixLink}link_url`]: urltoParse,
                                },
                            },
                        })
                        setGetMetaIsLoading(false)
                        if (process.env.NODE_ENV === 'development') console.log(error)
                        if (process.env.NODE_ENV === 'development') console.log(error.response)
                    })
            }
        })
    }
}
