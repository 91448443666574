// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// props
type ProfileDetailListFilterProps = {
    filterSet: any[]
    hasPermission: boolean | undefined
    listStatus: string
    onChange: (e: any) => void
    profileSlug: string
    sectionId: string
    sectionsObject: any
}

// main
export const ProfileDetailListFilter: React.FC<ProfileDetailListFilterProps> = React.memo(({
    filterSet,
    hasPermission,
    listStatus,
    onChange,
    profileSlug,
    sectionId,
    sectionsObject,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [filters, setFilters] = useState<any>({})

    function onFilterChange(e: any) {
        const newFilters: any = filters
        newFilters[e.name] = e.value
        setFilters(newFilters)
        let filterString = ''
        Object.getOwnPropertyNames(newFilters).map((val) => {
            if (Array.isArray(newFilters[val]) && newFilters[val].length > 0) {
                newFilters[val].map((val2: any) => {
                    filterString += `&${val}=${val2.id}`
                })
            }
            return false
        })
        onChange(filterString)
    }

    const sectionObject = sectionsObject[sectionId]

    if (!sectionObject) return null

    return (
        <div
            id={`profile-detail-list-filter-${profileSlug}-${sectionId}`}
            className='profile-detail-list-filter-web'
        >
            {filterSet.map((val) => {
                if (val.api_url === 'boolean') {
                    return (
                        <SelectListInput
                            key={val.slug}
                            clearable={false}
                            error={undefined}
                            label={reduxText[val.textId]}
                            multi
                            name={val.slug}
                            onChange={(e: any) => onFilterChange(e)}
                            options={[
                                { id: 1, name: reduxText[3696] },
                                { id: 0, name: reduxText[1236] },
                            ]}
                            value=''
                        />
                    )
                }
                if (val.api_url === 'cover_image_status') {
                    if (hasPermission) {
                        return (
                            <SelectListInput
                                clearable={false}
                                error={undefined}
                                label={reduxText[8329]}
                                multi
                                name='cover_image_status'
                                onChange={(e: any) => onFilterChange(e)}
                                options={[
                                    { id: 0, name: 'No cover' },  // TO_TEXT
                                    { id: 1, name: 'Has cover' },  // TO_TEXT
                                ]}
                                value=''
                            />
                        )
                    }
                    return null
                }
                if (val.api_url === 'date_status') {
                    return (
                        <SelectListInput
                            clearable={false}
                            error={undefined}
                            label={reduxText[8680]}
                            multi
                            name='date_status'
                            onChange={(e: any) => onFilterChange(e)}
                            options={[
                                { id: 'past', name: reduxText[4874] },
                                { id: 'upcoming', name: reduxText[6561] },
                            ]}
                            value=''
                        />
                    )
                }
                return (
                    <SelectListInput
                        key={val.slug}
                        apiUrl={`${val.api_url}&profile_slug=${profileSlug}&content_source=${sectionObject.content_source}${sectionObject.id ? `&section_id=${sectionObject.id}` : ''}&list_status=${listStatus}${sectionObject.is_other ? '&is_uncategorized=true' : ''}`}
                        clearable={false}
                        error={undefined}
                        label={reduxText[val.textId]}
                        multi
                        name={val.slug}
                        onChange={(e: any) => onFilterChange(e)}
                        showNone={val.showNone}
                        value=''
                    />
                )
            })}
        </div>
    )
})
