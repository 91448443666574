// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonButtons,
    IonPage,
    IonTitle,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    HeaderChildren,
    LinkHelper,
    Loader,
    RatioZarmingHelper,
} from 'components'

// data
import {
    api_url_stripe_portal_create,
    api_url_profile_subscription_detail,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_profile_detail_old,
    view_url_profile_subscription_list,
} from 'data'

// pages
import {
    NotFoundPage,
} from 'pages'

// serializers
import {
    ProfileSubscriptionDetailSerializer
} from 'serializers/web'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDateWithType,
    getDetailObject,
} from 'services'

// props
type MatchParams = {
    slug: string
}

// main
export const SubscriptionListWeb: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [detailObject, setDetailObject] = useState<ProfileSubscriptionDetailSerializer | undefined>(undefined)
    const [errorStatus, setErrorStatus] = useState<number>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        onGetDetailData()
    }, [
        params.slug,
        reduxAuth.apiRootUrl,
    ])

    function onGetDetailData() {
        try {
            if (params.slug === 'stripe_subscription') {
                history.replace(`${view_url_profile_detail_old}${reduxAuth.settings?.active_profile?.slug}/tabs/${view_url_profile_subscription_list}/`)
            } else if (params.slug) {
                getDetailObject({
                    apiUrl: getApiUrl(`${api_url_profile_subscription_detail}${params.slug}/`, reduxAuth),
                    component: 'SubscriptionListWeb',
                    dispatch,
                    reduxAuth,
                    setDetailObject,
                    setErrorStatus,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SubscriptionListWeb',
                'onGetDetailData',
            ))
        }
    }

    async function getGoToPortal(customerId: string, siteId: number) {
        try {
            setIsLoading(true)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'SubscriptionListWeb getGoToPortal')
            const postUrl = getApiUrl(api_url_stripe_portal_create, reduxAuth)
            const formData: any = new FormData()
            formData.append('customer_id', customerId)
            formData.append('site_id', siteId)
            formData.append('current_path', window.location.href)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    window.location.href = response.data.url
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'SubscriptionListWeb',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getGoToPortal',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SubscriptionListWeb',
                'getGoToPortal',
            ))
        }
    }

    if (errorStatus) {
        return <NotFoundPage statusCode={errorStatus} />
    }
    return (
        <IonPage className='subscription-list-web'>
            <HeaderChildren>
                <div className='hcw-header-children-left'>
                    <IonButtons slot='start'>
                        <LinkHelper
                            className='hcw-profile-pic'
                            edit={false}
                            to={detailObject?.get_absolute_url}
                        >
                            <div className='hcw-image-link'>
                                <RatioZarmingHelper
                                    borderRadius='50%'
                                    dominant_color={detailObject?.get_dominant_color}
                                    edit={false}
                                    fontSize={12}
                                    height={30}
                                    name={detailObject?.name}
                                    notZarma={Boolean(detailObject?.get_image_lq)}
                                    src={detailObject?.get_image_lq}
                                    to={undefined}
                                />
                            </div>
                        </LinkHelper>
                        <LinkHelper
                            className='hcw-profile-name'
                            edit={false}
                            to={detailObject?.get_absolute_url}
                        >
                            <p
                                className='hcw-name mo-clamp-web1'
                                title={detailObject?.name}
                            >
                                {detailObject?.name}
                            </p>
                        </LinkHelper>
                    </IonButtons>
                </div>
                <IonTitle className='hcw-title'>{reduxText[6470]}</IonTitle>
            </HeaderChildren>
            <IonContent className={`mo-hide-ion-content-scrollbar ${reduxAuth.isWindows}`}>
                <div className='slw-content'>
                    {detailObject?.stripe_subscriptions?.map(val => {
                        let invoiceTotal = 0
                        let billingInterval = null
                        return (
                            <div
                                key={val.id}
                                className='slw-subscription-wrap'
                            >
                                {val.plans?.map(val2 => {
                                    invoiceTotal += Number(val2.amount)
                                    billingInterval = val2.interval
                                    return (
                                        <div
                                            key={val2.id}
                                            className='slw-subscription-top'
                                        >
                                            <p className='slw-name'>{val2.name}</p>
                                            <p className={`slw-interval ${val.status}`}>{val.status}</p>
                                        </div>
                                    )
                                })}
                                {/* TO_TEXT */}
                                <p>Billing by {billingInterval} since {getDateWithType(reduxAuth, val.start_date, 'd')}</p>
                                <p>Next invoice on {getDateWithType(reduxAuth, val.current_period_end, 'd')} for € {invoiceTotal}</p>
                                <div className='slw-button-wrap'>
                                    <Button
                                        edit={false}
                                        onClick={() => getGoToPortal(val.customer!, val.site!)}
                                        size='small'
                                        text='Go to stripe portal'
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </IonContent>
            <Loader isOpen={isLoading} />
        </IonPage>
    )
}
