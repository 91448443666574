// react components
import React from 'react'
import {
    IonPopover,
    IonList,
    IonItem,
    IonLabel,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelper,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_event_form,
} from 'data'

// props
type InlineActionSheetWebProps = {
    formInfo: any
    handleInlineActive: any
    handleInlineDelete: any
    handleInlineGoToChild: any
    handleInlineRetrieve: any
    mother: any
    object: any
    setShowActionSheet: any
    showActionSheet: any
}


// main
export const InlineActionSheetWeb: React.FC<InlineActionSheetWebProps> = React.memo(({
    formInfo,
    handleInlineActive,
    handleInlineDelete,
    handleInlineGoToChild,
    handleInlineRetrieve,
    mother,
    object,
    setShowActionSheet,
    showActionSheet,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonPopover
            event={showActionSheet.event}
            isOpen={showActionSheet.open}
            onDidDismiss={() => setShowActionSheet({ open: false, event: undefined })}
        >
            <IonList>
                <IonItem
                    button
                    detail={false}
                    onClick={() => handleInlineRetrieve(object)}
                >
                    <IonLabel>{reduxText[2105]}</IonLabel>
                </IonItem>
                {formInfo.name === 'carteCategory' && (
                    <IonItem
                        button
                        detail={false}
                        onClick={() => handleInlineGoToChild()}
                    >
                        <IonLabel>{reduxText[6374]}</IonLabel>
                    </IonItem>
                )}
                {mother.model === 'event' && formInfo.name === 'schedule' && (
                    <IonItem
                        button
                        detail={false}
                    >
                        <IonLabel>
                            <LinkHelper
                                edit={false}
                                href={`${view_url_event_form}${object?.id}/`}
                            >
                                {reduxText[8516]}
                            </LinkHelper>
                        </IonLabel>
                    </IonItem>
                )}
                {!['adherentContent', 'asset', 'child', 'schedule'].includes(formInfo.name) && (
                    <IonItem
                        button
                        detail={false}
                        onClick={() => handleInlineActive()}
                    >
                        <IonLabel>{object?.active ? reduxText[5296] : reduxText[5297]}</IonLabel>
                    </IonItem>
                )}
                <IonItem
                    button
                    detail={false}
                    onClick={() => handleInlineDelete()}
                >
                    <IonLabel>{reduxText[2983]}</IonLabel>
                </IonItem>
            </IonList>
        </IonPopover>
    )
})
