// react components
import React, {
    useState,
} from 'react'
import {
    useDispatch,
} from 'react-redux'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// props
type SelectionFormItemProps = {
    item: {
        id: number
        name: string
        selection_id: number | false
    }
    onChange: any
}

// main
export const SelectionFormItem: React.FC<SelectionFormItemProps> = React.memo(({
    item,
    onChange,
}) => {

    const dispatch = useDispatch()

    const [isChecked, setIsChecked] = useState<boolean>(item.selection_id === false ? false : true)

    function onCheckboxChange(checked: boolean) {
        try {
            setIsChecked(checked)
            onChange({
                id: item.id,
                checked: checked,
                selection_id: item.selection_id,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectionFormItem',
                'onCheckboxChange',
            ))
        }
    }

    return (
        <div
            className='selection-form-item'
            onClick={() => onCheckboxChange(!isChecked)}
        >
            <div className='sfi-label-wrap'>
                <p>{item.name}</p>
            </div>
            <div className='sfi-input-wrap'>
                <div className='sfi-input-div'>
                    <input
                        checked={isChecked}
                        className='sfi-input'
                        type='checkbox'
                    />
                </div>
            </div>
        </div>
    )
})
