// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ProductDetailFormSerializer,
    ProductDetailFormSerializerError,
} from 'serializers/web'

// props
type SkuProductInputProps = {
    errors: ProductDetailFormSerializerError | undefined
    fields: ProductDetailFormSerializer
    onChange: any
}

// main
export const SkuProductInput: React.FC<SkuProductInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <TextInput
            error={errors?.sku}
            label={reduxText[8769]}
            name='sku'
            onChange={onChange}
            optional
            value={fields.sku || ''}
        />
    )
})
