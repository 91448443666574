// react components
import React from 'react'
import Linkify from 'react-linkify'

// props
type DescriptionDetailBlockProps = {
    children?: React.ReactNode
    isRich?: boolean
    languageId?: number
    linkify?: boolean
    text?: string | undefined
    title?: string | undefined
}

// main
export const DescriptionDetailBlock: React.FC<DescriptionDetailBlockProps> = React.memo(({
    children,
    isRich,
    languageId,
    linkify,
    text,
    title,
}) => {

    if (!text && !children) return null

    return (
        <div className='description-detail-block-web'>
            {title && (
                <p className='ddbw-title'>{title}</p>
            )}
            <div className={`ddbw-text${languageId === 19 ? ' rtl' : ''}`}>
                {(linkify && text) && (
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a target='blank' href={decoratedHref} key={key}>{decoratedText}</a>}>
                        <p className='pre-line'>{text}</p>
                    </Linkify>
                )}
                {(isRich && text) && (
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                )}
                {(!linkify && !isRich && text) && (
                    <p className='pre-line'>{text}</p>
                )}
                {children}
            </div>
        </div>
    )
})
