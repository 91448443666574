// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    ImageHelper,
    LightboxCustom,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    HelpCenterTopicItemDetailSerializer,
} from 'serializers/web'

// props
type HelpCenterTopicItemDetailBlockProps = {
    edit: boolean
    inModal?: boolean
    object: HelpCenterTopicItemDetailSerializer
}

// main
export const HelpCenterTopicItemDetailBlock: React.FC<HelpCenterTopicItemDetailBlockProps> = React.memo(({
    edit,
    inModal,
    object,
}) => {

    const reduxAuthisWindows = useSelector((state: defaultReduxState) => state.reduxAuth.isWindows)
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)

    return (
        <div className={`help-center-topic-item-web${inModal ? ' in-modal' : ''}${edit ? ' edit' : ''}`}>
            <div className='hctiw-left'>
                <div className='hctiw-image-wrap'>
                    <ImageHelper
                        alt={object.name}
                        className='hctiw-image'
                        dominant_color={undefined}
                        onClick={() => setIsLightBoxOpen(true)}
                        src={object.get_image_hq}
                    />
                </div>
            </div>
            <div className={`hctiw-right mo-hidden-vertical-scrollbar${reduxAuthisWindows}`}>
                {object.description && (
                    <div
                        className='hctiw-description'
                        dangerouslySetInnerHTML={{ __html: object.description }}
                    />
                )}
            </div>
            {isLightBoxOpen && object.get_image_hq && (
                <LightboxCustom
                    mainSrc={object.get_image_hq}
                    onCloseRequest={() => setIsLightBoxOpen(false)}
                />
            )}
        </div>
    )
})
