// react components
import React, {
    useRef,
    useState,
} from 'react'
import ReactPlayer from 'react-player'
import moment from 'moment'
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonItem,
    IonLabel,
    IonModal,
    IonThumbnail,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    ScreenOrientation,
} from '@ionic-native/screen-orientation'
import {
    Slider,
    Snackbar,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    ImageHelper,
    LinkHelper,
} from 'components'

// data
import {
    defaultReduxState,
    media_url_platform,
    media_version,
    reduxModalErrorEventHandler,
    reduxModalPlayerOnDismiss,
    reduxModalPlayerOnPlayPause,
    reduxModalPlayerOnReady,
    reduxModalPlayerSetCurrentTrack,
    reduxModalPlayerSetPosition,
} from 'data'

// main
export const EcPlayerMobile: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const contentRef = useRef<ReactPlayer>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalplayer = useSelector((state: defaultReduxState) => state.reduxModal.player)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [duration, setDuration] = useState<number>(0)
    const [errorMessage, showErrorMessage] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [loop] = useState(false)
    const [played, setPlayed] = useState<number>(0)
    const [seeking, setSeeking] = useState(false)
    const [showTrackList, setShowTrackList] = useState(false)

    ScreenOrientation.onChange().subscribe(
        () => {
            if (process.env.NODE_ENV === 'development') console.log('Orientation Changed')
            // if (reduxModalplayer.currentTrack?.id) {
            // 	ScreenOrientation.unlock()
            // } else {
            // 	ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
            // }
            setFullScreen([ScreenOrientation.ORIENTATIONS.LANDSCAPE, ScreenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY, ScreenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY].includes(ScreenOrientation.type))
        }
    )

    function onDuration(duration: number) {
        try {
            setDuration(duration)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerMobile',
                'onDuration',
            ))
        }
    }

    function onError(error: any) {
        if (process.env.NODE_ENV === 'development') console.log(error)
    }

    function onNext(customTrack?: any) {
        // @ts-ignore
        dispatch(reduxModalPlayerSetCurrentTrack(
            customTrack || nextTrack,
            reduxAuth,
            dispatch,
        ))
        if (customTrack) setShowTrackList(false)
    }

    function onNextAlbumSkip() {
        // @ts-ignore
        dispatch(reduxModalPlayerSetCurrentTrack(
            nextAlbumSkipTrack,
            reduxAuth,
            dispatch,
        ))
    }

    function onPlayPause() { // Play, Pause audio and video
        if (reactPlayerActionDisabled) {
            showErrorMessage(true)
        } else {
            dispatch(reduxModalPlayerOnPlayPause(!reduxModalplayer.playing))
        }
    }

    function onPrev() {
        if (!prevTrack) return
        // @ts-ignore
        dispatch(reduxModalPlayerSetCurrentTrack(
            prevTrack,
            reduxAuth,
            dispatch,
        ))
    }

    function onPrevAlbumSkip() {
        // @ts-ignore
        dispatch(reduxModalPlayerSetCurrentTrack(
            prevAlbumSkipTrack,
            reduxAuth,
            dispatch,
        ))
    }

    function onProgress(value: any) { // Player progress data send to redux
        try {
            if (!seeking) {
                setPlayed(value.playedSeconds)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerMobile',
                'onProgress',
            ))
        }
    }

    function onReady() { // Player ready send to redux
        try {
            dispatch(reduxModalPlayerOnReady())
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'EcPlayerMobile',
                'onReady',
            ))
        }
    }

    let wto: any

    function onSeekChange(value: number) { // Player seek on change
        if (seeking) {
            dispatch(reduxModalPlayerOnPlayPause(false))
            setPlayed(value)
            contentRef.current?.seekTo(parseFloat(`${value}`))
            clearTimeout(wto)
            wto = setTimeout(() => {
                setSeeking(false)
                dispatch(reduxModalPlayerOnPlayPause(true))
            }, 1000)
        }
    }

    function onClose() {
        const locationArray = location.pathname.split('/')
        if (locationArray[1] === 'artwork' && locationArray[2] === (reduxModalplayer.currentTrack?.id && reduxModalplayer.currentTrack.id.toString())) {
            dispatch(reduxModalPlayerSetPosition('detail'))
            dispatch(reduxModalPlayerSetPosition('detail'))
        } else {
            dispatch(reduxModalPlayerSetPosition('bottom'))
            dispatch(reduxModalPlayerSetPosition('bottom'))
        }
    }

    function onDismissPlayer() {
        dispatch(reduxModalPlayerOnDismiss())
        dispatch(reduxModalPlayerSetPosition(''))
    }

    // soundcloud config options
    const soundcloudConfig = {
        options: {
            auto_play: true,
        },
    }

    // vimeo config options
    const vimeoConfig = {
        playerOptions: { // https://help.vimeo.com/hc/en-us/articles/360001494447-Using-Player-Parameters
            autoplay: 1,
        },
    }

    // youtube config
    const youtubeConfig = {
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            color: 'white',
            rel: 0,
            showinfo: 0,
        },
    }

    const otherPlayers = [
        3,
        7,
        8,
        9,
        10,
        11,
        13,
        14,
        15,
        16,
        18,
        19,
        21,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
    ]

    const coverNeededBottomPlatform = [
        2,
        3,
        6,
        8,
        9,
        11,
        18,
        21,
    ]

    if (!reduxModalplayer.currentTrack?.id) return null

    const nextTrack = reduxModalplayer.trackArray && reduxModalplayer.trackArray[(reduxModalplayer.currentTrackIndex || 0) + 1]
    const prevTrack = reduxModalplayer.trackArray && reduxModalplayer.trackArray[(reduxModalplayer.currentTrackIndex || 0) - 1]
    const prevAlbumSkipTrack = reduxModalplayer.originalTrackArray && reduxModalplayer.originalTrackArray[(reduxModalplayer.originalTrackIndex || 0) - 1]
    const nextAlbumSkipTrack = reduxModalplayer.originalTrackArray && reduxModalplayer.originalTrackArray[(reduxModalplayer.originalTrackIndex || 0) + 1]
    const reactPlayerActionDisabled = (reduxModalplayer.currentTrack.platform && otherPlayers.includes(reduxModalplayer.currentTrack.platform)) ? true : false

    return (
        <div
            className={`mo-player ${fullScreen ? 'landscape' : reduxModalplayer.playerPosition}`}
            style={{
                bottom: (location.pathname.split('/')[1] === 'form') ? '0px' : undefined,
            }}
        >
            {reduxModalplayer.playerPosition && ['detail', 'full'].includes(reduxModalplayer.playerPosition) && (
                <IonHeader
                    translucent
                    className='mo-player-header'
                >
                    <IonToolbar>
                        <IonButtons slot='start'>
                            {reduxModalplayer.playerPosition === 'detail' && (
                                <IonBackButton
                                    defaultHref='/'
                                    text=''
                                />
                            )}
                            {reduxModalplayer.playerPosition === 'full' && (
                                <Button
                                    edit={false}
                                    onClick={() => onClose()}
                                    fill='clear'
                                    text={reduxText[4742]}
                                />
                            )}
                        </IonButtons>
                        <IonTitle color='primary'>
                            {reduxModalplayer.playerPosition === 'detail' ? reduxModalplayer.currentTrack.name : reduxText[5882]}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
            )}
            {reduxModalplayer.playerPosition && (
                <div
                    className='mo-player-block'
                    onClick={() => reduxModalplayer.playerPosition === 'bottom' ? dispatch(reduxModalPlayerSetPosition('full')) : undefined}
                >
                    {/* Start iframe for Deezer player */}
                    {reduxModalplayer.currentTrack?.platform === 3 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={`https://www.deezer.com/plugins/player?format=classic&autoplay=false&playlist=false&color=ff0000&layout=dark&size=medium&type=tracks&id=${reduxModalplayer.currentTrack?.link_id}&app_id=1`}
                            title='Deezer Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Deezer player */}
                    {/* Start iframe for Giphy player */}
                    {reduxModalplayer.currentTrack?.platform === 7 && (
                        <iframe
                            allowFullScreen
                            className='giphy-embed'
                            frameBorder='0'
                            src={`https://giphy.com/embed/${reduxModalplayer.currentTrack?.link_id}`}
                            title='Giphy Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Giphy player */}
                    {/* Start iframe for Spotify player */}
                    {reduxModalplayer.currentTrack?.platform === 8 && (
                        <iframe
                            allow='encrypted-media'
                            scrolling='no'
                            frameBorder='no'
                            src={(reduxModalplayer.currentTrack?.link_id?.includes('episode') || reduxModalplayer.currentTrack?.link_id?.includes('playlist') || reduxModalplayer.currentTrack?.link_id?.includes('track')) ? `https://open.spotify.com/embed/${reduxModalplayer.currentTrack?.link_id}` : `https://open.spotify.com/embed/track/${reduxModalplayer.currentTrack?.link_id}`}
                            title='Spotify Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Spotify player */}
                    {/* Start iframe for Bandcamp player */}
                    {reduxModalplayer.currentTrack?.platform === 9 && (
                        <iframe
                            id='zarmaaa'
                            seamless
                            src={reduxModalplayer.currentTrack?.link_id && reduxModalplayer.currentTrack.link_id.replace('artwork=small', 'artwork=small')}
                            title='Bandcamp Iframe'
                            style={{
                                border: '0',
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Bandcamp player */}
                    {/* Check */}
                    {reduxModalplayer.currentTrack?.platform === 10 && (
                        <iframe
                            allowFullScreen
                            frameBorder='0'
                            scrolling='no'
                            src={`https://www.arte.tv/player/v3/index.php?json_url=https%3A%2F%2Fapi.arte.tv%2Fapi%2Fplayer%2Fv1%2Fconfig%2Ffr%2F${reduxModalplayer.currentTrack?.link_id}%3Fautostart%3D1%26lifeCycle%3D1&amplang=fr_FR&ampembed=1&ampmute=0`}
                            title='To check Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* Check */}
                    {/* Start iframe for Pippa player */}
                    {reduxModalplayer.currentTrack?.platform === 11 && (
                        <iframe
                            allow='autoplay'
                            frameBorder='0'
                            src={`https://player.pippa.io/${reduxModalplayer.currentTrack?.link_id}?theme=default&cover=1&latest=1`}
                            title='Pippa player Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Pippa player */}
                    {/* Start iframe for Arte Audioblog player */}
                    {reduxModalplayer.currentTrack?.platform === 13 && (
                        <iframe
                            src={`https://audioblog.arteradio.com/embed/${reduxModalplayer.currentTrack?.link_id}`}
                            title='Arte Audioblog Iframe'
                            style={{
                                border: 'none',
                                height: '100%',
                                margin: '0',
                                padding: '0',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Arte Audioblog player */}
                    {/* Start iframe for Arte Radio player */}
                    {reduxModalplayer.currentTrack?.platform === 14 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={`${reduxModalplayer.currentTrack?.link_url}?embed`}
                            title='Arte Radio Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Arte Radio player */}
                    {/* Start iframe for France Culture player */}
                    {reduxModalplayer.currentTrack?.platform === 15 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='France Culture Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for France Culture player */}
                    {/* Start iframe for Apple player */}
                    {reduxModalplayer.currentTrack?.platform === 16 && (
                        <iframe
                            allow='autoplay * encrypted-media *'
                            height='150'
                            sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation'
                            src={`https://embed.${reduxModalplayer.currentTrack?.link_id}`}
                            title='Apple Iframe'
                            style={{
                                background: 'transparent',
                                height: '100%',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Apple player */}
                    {/* Start iframe for France Inter player */}
                    {reduxModalplayer.currentTrack?.platform === 18 && (
                        <iframe
                            frameBorder='no'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='France Inter Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for France Inter player */}
                    {/* Start iframe for France TV player */}
                    {reduxModalplayer.currentTrack?.platform === 19 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='France TV Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for France TV player */}
                    {/* Start iframe for Anchor player */}
                    {reduxModalplayer.currentTrack?.platform === 21 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='Anchor Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Anchor player */}
                    {/* Start iframe for Imagotv player */}
                    {reduxModalplayer.currentTrack?.platform === 23 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='Imagotv Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Imagotv player */}
                    {/* Start iframe for Rumble player */}
                    {reduxModalplayer.currentTrack?.platform === 24 && (
                        <iframe
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            title='Rumble Iframe'
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    )}
                    {/* End iframe for Rumble player */}
                    {/* Start iframe for Hearthis player */}
                    {reduxModalplayer.currentTrack?.platform === 25 && (
                        <iframe
                            allow='autoplay'
                            frameBorder='0'
                            scrolling='no'
                            src={`${reduxModalplayer.currentTrack?.link_id}transparent_black/?hcolor=&color=&style=2&block_size=2&block_space=1&background=1&waveform=0&cover=0&autoplay=0&css=%C2%AB`}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='Hearthis Iframe'
                        />
                    )}
                    {/* End iframe for Hearthis player */}
                    {/* Start iframe for TF1 player */}
                    {reduxModalplayer.currentTrack?.platform === 26 && (
                        <iframe
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            frameBorder='0'
                            src={reduxModalplayer.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='TF1 Iframe'
                        />
                    )}
                    {/* End iframe for TF1 player */}
                    {/* Start iframe for Kuula player */}
                    {reduxModalplayer.currentTrack?.platform === 27 && (
                        <iframe
                            allow='xr-spatial-tracking; gyroscope; accelerometer'
                            allowFullScreen
                            frameBorder='0'
                            scrolling='no'
                            src={reduxModalplayer.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='Kuula Iframe'
                        />
                    )}
                    {/* End iframe for Kuula player */}
                    {/* Start iframe for Matterport player */}
                    {reduxModalplayer.currentTrack?.platform === 28 && (
                        <iframe
                            frameBorder='0'
                            src={reduxModalplayer.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='Matterport Iframe'
                        />
                    )}
                    {/* End iframe for Matterport player */}
                    {/* Start iframe for VideoAsk player */}
                    {reduxModalplayer.currentTrack?.platform === 29 && (
                        <iframe
                            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                            frameBorder='0'
                            src={reduxModalplayer.currentTrack?.link_id}
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            title='VideoAsk Iframe'
                        />
                    )}
                    {/* End iframe for VideoAsk player */}
                    {/* Start iframe for others */}
                    {!otherPlayers.includes(reduxModalplayer.currentTrack?.platform || 0) && reduxModalplayer.currentTrack?.link_url && (
                        <ReactPlayer
                            controls
                            height='100%'
                            loop={loop}
                            onDuration={onDuration}
                            onEnded={nextTrack ? () => onNext() : undefined}
                            onError={(e: any) => onError(e)}
                            onPause={() => dispatch(reduxModalPlayerOnPlayPause(false))}
                            onPlay={() => dispatch(reduxModalPlayerOnPlayPause(true))}
                            onProgress={onProgress}
                            onReady={onReady}
                            // onStart={onStart}
                            playing={reduxModalplayer.playing}
                            ref={contentRef}
                            url={(reduxModalplayer.currentTrack?.platform && [17, 20].includes(reduxModalplayer.currentTrack?.platform)) ? reduxModalplayer.currentTrack?.link_id : reduxModalplayer.currentTrack?.link_url}
                            width='100%'
                            config={{
                                // facebook: { facebookConfig },
                                soundcloud: soundcloudConfig,
                                vimeo: vimeoConfig,
                                youtube: youtubeConfig,
                            }}
                        />
                    )}
                    {/* End iframe for others */}
                    {reduxModalplayer.playerPosition === 'bottom' && reduxModalplayer.currentTrack.platform && coverNeededBottomPlatform.includes(reduxModalplayer.currentTrack.platform) && (
                        <div className='mo-player-cover'>
                            <img
                                alt=''
                                onContextMenu={(e) => e.preventDefault()}
                                src={reduxModalplayer.currentTrack?.get_image_lq || reduxModalplayer.currentTrack?.get_image_square}
                            />
                        </div>
                    )}
                </div>
            )}
            <div
                className='mo-player-platform-wrap'
            >
                <p className='mo-player-powered'>{reduxText[5883]}</p>
                <ImageHelper
                    alt={reduxModalplayer.currentTrack?.name}
                    className='mo-player-platform'
                    dominant_color={undefined}
                    lazyMobile={false}
                    src={`${media_url_platform}${reduxModalplayer.currentTrack?.platform}.svg${media_version}`}
                />
            </div>
            <div
                className='mo-player-info'
                onClick={() => reduxModalplayer.playerPosition === 'bottom' ? dispatch(reduxModalPlayerSetPosition('full')) : undefined}
            >
                <LinkHelper
                    className='mo-player-info-name mo-clamp-mobile1'
                    edit={false}
                    onClick={reduxModalplayer.playerPosition === 'full' ? () => onClose() : undefined}
                    to={reduxModalplayer.playerPosition === 'full' && reduxModalplayer.currentTrack?.get_absolute_url}
                >
                    {reduxModalplayer.currentTrack?.name}
                </LinkHelper>
                <LinkHelper
                    className='mo-player-info-profile mo-clamp-mobile1'
                    edit={false}
                    onClick={reduxModalplayer.playerPosition === 'full' ? () => onClose() : undefined}
                    to={reduxModalplayer.playerPosition === 'full' && reduxModalplayer.currentTrack?.profile?.get_absolute_url}
                >
                    {reduxModalplayer.currentTrack?.profile?.name}
                </LinkHelper>
            </div>
            <div className='mo-player-range'>
                <Slider
                    color='secondary'
                    disabled={reactPlayerActionDisabled}
                    max={duration}
                    // onIonBlur={() => console.log('onIonFocus')}
                    // @ts-ignore
                    onChange={(e) => onSeekChange(Number(e.target.value))}
                    // onIonFocus={() => setSeeking(true)}
                    value={played}
                />
                <div className='mo-player-range-duration'>
                    <span>{moment.utc(played * 1000).format('HH:mm:ss')}</span>
                    <span>{moment.utc(duration * 1000).format('HH:mm:ss')}</span>
                </div>
            </div>
            <div className='mo-player-action'>
                {reduxModalplayer.playerPosition === 'bottom' && (
                    <div className='mo-player-action-bottom'>
                        <div className='mo-player-action-bottom-icons'>
                            <IconBlock
                                className='mo-player-action-icon'
                                edit={false}
                                iconClass={reduxModalplayer.playing ? 'mo-new-icon-pause-solid' : 'mo-new-icon-play-solid'}
                                onClick={onPlayPause}
                            />
                            <IconBlock
                                className='mo-player-action-icon'
                                edit={false}
                                iconClass='mo-new-icon-times-solid'
                                onClick={() => onDismissPlayer()}
                            />
                        </div>
                        <div className='mo-player-platform-wrap-bottom'>
                            <p className='mo-player-powered'>{reduxText[5883]}</p>
                            <ImageHelper
                                alt={reduxModalplayer.currentTrack?.name}
                                className='mo-player-platform'
                                dominant_color={undefined}
                                lazyMobile={false}
                                src={`${media_url_platform}${reduxModalplayer.currentTrack?.platform}.svg${media_version}`}
                            />
                        </div>
                    </div>
                )}
                <div className='mo-player-action-full'>
                    <IconBlock
                        className='mo-player-action-icon-full'
                        edit={false}
                        disabled={(prevAlbumSkipTrack && reduxModalplayer.albumTrackArray && reduxModalplayer.albumTrackArray[(reduxModalplayer.albumTrackIndex || 0) - 1]) ? false : true}
                        iconClass='mo-new-icon-fast-backward-solid'
                        onClick={() => onPrevAlbumSkip()}
                    />
                    <IconBlock
                        className='mo-player-action-icon-full'
                        edit={false}
                        disabled={prevTrack ? false : true}
                        iconClass='mo-new-icon-step-backward-solid'
                        onClick={() => onPrev()}
                    />
                    <IconBlock
                        className='mo-player-action-icon-full mo-player-action-icon-full-main'
                        edit={false}
                        iconClass={reduxModalplayer.playing ? 'mo-new-icon-pause-circle-regular' : 'mo-new-icon-play-circle-solid'}
                        onClick={onPlayPause}
                    />
                    <IconBlock
                        className='mo-player-action-icon-full'
                        disabled={nextTrack ? false : true}
                        edit={false}
                        iconClass='mo-new-icon-step-forward-solid'
                        onClick={() => onNext()}
                    />
                    <IconBlock
                        className='mo-player-action-icon-full'
                        disabled={(nextAlbumSkipTrack && reduxModalplayer.albumTrackArray && reduxModalplayer.albumTrackArray[(reduxModalplayer.albumTrackIndex || 0) + 1]) ? false : true}
                        edit={false}
                        iconClass='mo-new-icon-fast-forward-solid'
                        onClick={() => onNextAlbumSkip()}
                    />
                </div>
            </div>
            {nextTrack && (
                <div
                    className='mo-player-next'
                    onClick={() => setShowTrackList(true)}
                >
                    <span className='mo-player-next-name mo-clamp-mobile1'>{`${reduxText[5884]} ${nextTrack.name}`}</span>
                    <IconBlock
                        className='mo-player-action-icon-full'
                        disabled={false}
                        edit={false}
                        iconClass='mo-new-icon-chevron-up-solid'
                    />
                </div>
            )}
            <IonModal
                isOpen={showTrackList}
                onDidDismiss={() => setShowTrackList(false)}
            >
                <IonHeader translucent>
                    <IonToolbar>
                        <IonTitle>{`${reduxText[5885]} (${reduxModalplayer.trackArray && reduxModalplayer.trackArray.length})`}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent
                    forceOverscroll
                    fullscreen
                >
                    {reduxModalplayer.trackArray && reduxModalplayer.trackArray.map((val) => {
                        return (
                            <IonItem
                                key={val.id}
                                button
                                onClick={() => onNext(val)}
                            >
                                <IonThumbnail>
                                    <ImageHelper
                                        alt={val.name}
                                        className='eci-thumb'
                                        dominant_color={undefined}
                                        src={val.get_image_lq || val.get_image_square}
                                    />
                                </IonThumbnail>
                                <IonLabel>
                                    <h3 className={reduxModalplayer.currentTrack?.id === val.id ? 'mo-color' : ''}>{val.name}</h3>
                                    <p className={reduxModalplayer.currentTrack?.id === val.id ? 'mo-color' : ''}>{val.profile?.name}</p>
                                </IonLabel>
                            </IonItem>
                        )
                    })}
                </IonContent>
                <IonFooter
                    className='button-footer-mobile'
                    translucent
                >
                    <Button
                        edit={false}
                        fill='outline'
                        onClick={() => setShowTrackList(false)}
                        text={reduxText[4742]}
                    />
                </IonFooter>
            </IonModal>
            <Snackbar
                action={
                    <Button
                        edit={false}
                        fill='clear'
                        onClick={() => showErrorMessage(false)}
                        size='small'
                        text={reduxText[4742]}
                    />
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                message={reduxText[4775]}
                onClose={() => showErrorMessage(false)}
                open={errorMessage}
            />
        </div>
    )
}
