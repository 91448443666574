// react components
import React, {
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    HeaderWrapper,
} from 'components'

// data
import {
    contentTypeModelType,
    defaultReduxState,
    media_url,
    reduxAuthAfterAuthUrlSet,
    viewUrl,
    view_url_auth_login,
    view_url_home,
} from 'data'

// services
import {
    setStaffPermission,
} from 'services'

// props
type NotFoundPageProps = {
    header?: boolean
    model?: contentTypeModelType
    objectId?: number
    statusCode?: number
}

// main
export const NotFoundPage: React.FC<NotFoundPageProps> = React.memo(({
    header,
    model,
    objectId,
    statusCode,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [backgroundImage] = useState<string>(`${media_url}global/not-found/dark/${Math.floor(Math.random() * 13)}.jpeg`)

    const statusText: any = {
        403: reduxAuth.authenticated ? reduxText[8013] : reduxText[8014],
        404: reduxText[7928],
    }

    function onSetStaffPermission() {
        if (objectId && model) {
            setStaffPermission({
                component: 'NotFoundPage',
                contentTypeModel: model,
                dispatch,
                objectId: objectId,
                reduxAuth,
                refreshData: () => window.location.reload(),
            })
        }
    }

    return (
        <React.Fragment>
            {header && <HeaderWrapper forced />}
            <IonPage className={`not-found-page-web navbar-pt${!reduxAuth.authenticated ? ' not-authenticated' : ''}`}>
                <IonContent className={`mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}>
                    <div
                        className='nfpw-content'
                        style={{
                            backgroundImage: `url('${backgroundImage}')`
                        }}
                    >
                        <div className='nfpw-wrap'>
                            <p className='nfpw-text1'>{statusCode || 404}</p>
                            <p className='nfpw-text2'>{statusCode ? statusText[statusCode] : reduxText[7928]}</p>
                            {reduxAuth.settings?.user?.is_staff && (
                                (statusCode === 403 && objectId && model)
                                    ? (
                                        <Button
                                            color='white'
                                            edit={false}
                                            onClick={onSetStaffPermission}
                                            text='Set staff permission'
                                        />
                                    ) : (
                                        <Button
                                            color='white'
                                            edit={false}
                                            onClick={!reduxAuth.authenticated ? () => dispatch(reduxAuthAfterAuthUrlSet(location.pathname)) : undefined}
                                            text={!reduxAuth.authenticated ? reduxText[1267] : reduxText[7929]}
                                            to={!reduxAuth.authenticated ? view_url_auth_login : view_url_home}
                                        />
                                    )
                            )}
                            {!reduxAuth.settings?.user?.is_staff && (
                                (statusCode === 403)
                                    ? (
                                        <Button
                                            color='white'
                                            edit={false}
                                            onClick={!reduxAuth.authenticated ? () => dispatch(reduxAuthAfterAuthUrlSet(location.pathname)) : undefined}
                                            text={!reduxAuth.authenticated ? reduxText[1267] : reduxText[7929]}
                                            to={!reduxAuth.authenticated ? view_url_auth_login : view_url_home}
                                        />
                                    ) : (
                                        <a
                                            className='button-web regular white default'
                                            href={viewUrl}
                                        >
                                            {reduxText[7929]}
                                        </a>
                                    )
                            )}
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
})
