// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useParams,
} from 'react-router-dom'

// components
import {
    IconBlock,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalMainFormShow,
    view_url_admin,
    view_url_staff,
} from 'data'

// pages
import {
    AdminMenuItemView,
} from 'pages'

// serializers
import {
    ModuleMenuItemsTypes,
    ProfileDetailAdminSerializerV2,
} from 'serializers/web'

// services
import {
    getModalAbsoluteUrlV2,
    getReduxTextId,
    onClickIsModal,
} from 'services'

// props
type MatchParams = {
    moduleId?: string
    viewId?: string
}

type AdminMenuItemProps = {
    isStaff: boolean
    object: ModuleMenuItemsTypes
    profileObject: ProfileDetailAdminSerializerV2
    refreshData: () => void
    showSettings: boolean
}

// main
export const AdminMenuItem: React.FC<AdminMenuItemProps> = React.memo(({
    isStaff,
    object,
    profileObject,
    refreshData,
    showSettings,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [deleted, setDeleted] = useState<boolean>()
    const [isChildOpen, setIsChildOpen] = useState<boolean>(params.moduleId === `${object.id}`)
    const [isInit, setIsInit] = useState(false)

    useEffect(() => {
        const shoulOpen = params.moduleId === `${object.id}`
        if (isInit && isChildOpen && !shoulOpen) {
            setIsChildOpen(shoulOpen)
        }
        setIsInit(true)
    }, [
        params.moduleId,
    ])

    if (deleted) return null

    return (
        <div className='admin-menu-item'>
            <div className='ami-tab-item-wrap'>
                <NavLink
                    className='ami-tab-item'
                    to={`${isStaff ? view_url_staff : view_url_admin}${profileObject.id}/section/${object.id}/${object.isView ? `${object.views?.[0]?.id}/` : ''}`}
                    onClick={() => setIsChildOpen(!isChildOpen)}
                >
                    {getReduxTextId(object.name, reduxText)}
                </NavLink>
                <i
                    className={`ami-expand mo-new-icon-chevron-down-solid${isChildOpen ? ' open' : ''}${!object.isView && object.views?.length! > 0 ? '' : ' hidden'}`}
                    onClick={() => setIsChildOpen(!isChildOpen)}
                />
                {showSettings && (
                    <>
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-pencil-alt-solid'
                            onClick={() =>
                                dispatch(reduxModalMainFormShow({
                                    contentType: 'setup_module',
                                    cssClass: 'form-container-modal',
                                    formComponent: 'FormModal',
                                    objectId: object.id,
                                    refreshData,
                                    setDeleted,
                                }))
                            }
                        />
                        <IconBlock
                            edit={false}
                            iconClass='mo-new-icon-eye-solid'
                            onClick={() => onClickIsModal(object.id, Math.random(), dispatch)}
                            to={getModalAbsoluteUrlV2({
                                contentType: 'setup_module',
                                objectId: object.id,
                                pageType: 'detail',
                            })}
                        />
                    </>
                )}
            </div>
            {!object.isView && object.views?.length! > 0 && (
                <div className={`ami-children-wrap${isChildOpen ? ' open' : ''}`}>
                    {object.views?.map((val) => (
                        <AdminMenuItemView
                            key={val.id}
                            isStaff={isStaff}
                            level={0}
                            moduleId={object.id}
                            object={val}
                            profileObject={profileObject}
                            showSettings={showSettings}
                            refreshData={refreshData}
                        />
                    ))}
                </div>
            )}
        </div>
    )
})
