// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    SelectAsyncInput,
} from 'components'

// containers
import {
    InlineFormContainerWeb,
} from 'containers'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_adherent,
} from 'data'

// serializers
import {
    InlineFormPanelProps,
    ProductFormState,
} from 'serializers/web'

// props
type FreeContentProductPanelProps = {
    formState: ProductFormState
    setFormState: React.Dispatch<ProductFormState>
}

// main
export const FreeContentProductPanel: React.FC<InlineFormPanelProps & FreeContentProductPanelProps> = React.memo(({
    formState,
    handleInputChange,
    helpers,
    setFormState,
    setHelpers,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mother = formState.mother
    const adherentContent = formState.adherentContent

    function onHandleInputChange(event: any) {
        try {
            handleInputChange(event, adherentContent)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FreeContentProductPanel',
                'onHandleInputChange',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    if (!fieldsMother) return null

    // Inline
    const errorsInline = adherentContent.errors
    const fieldsInline = adherentContent.fields

    if (!fieldsInline) return null

    return (
        <InlineFormContainerWeb
            formInfo={adherentContent}
            formState={formState}
            hasSearch
            helpers={helpers}
            noOrdering
            setFormState={setFormState}
            setHelpers={setHelpers}
        >
            <SelectAsyncInput
                apiUrl={`${select_url_adherent}?profile_id=${fieldsMother.profile?.id}`}
                clearable
                error={errorsInline?.adherent}
                label={reduxText[8552]}
                layout='adherent'
                name='adherent'
                onChange={onHandleInputChange}
                optional
                value={fieldsInline.adherent || ''}
            />
        </InlineFormContainerWeb>
    )
})
