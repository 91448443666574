// react components
import React from 'react'
import {
    IonActionSheet,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// props
type InlineActionSheetMobileProps = {
    formInfo: any
    handleInlineActive: any
    handleInlineDelete: any
    handleInlineGoToChild: any
    handleInlineRetrieve: any
    object: any
    setShowActionSheet: any
    showActionSheet: any
}

// main
export const InlineActionSheetMobile: React.FC<InlineActionSheetMobileProps> = React.memo(({
    formInfo,
    handleInlineActive,
    handleInlineDelete,
    handleInlineGoToChild,
    handleInlineRetrieve,
    object,
    setShowActionSheet,
    showActionSheet,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const buttons = [
        {
            handler: () => handleInlineDelete(),
            role: 'destructive',
            text: reduxText[2983],
        },
        {
            handler: () => handleInlineRetrieve(),
            text: reduxText[2105],
        },
        {
            handler: () => handleInlineActive(),
            text: object?.active ? reduxText[5296] : reduxText[5297],
        },
        {
            role: 'cancel',
            text: reduxText[4519],
        }
    ]

    const buttonsCarteCategory = [
        {
            handler: () => handleInlineDelete(),
            role: 'destructive',
            text: reduxText[2983],
        },
        {
            handler: () => handleInlineRetrieve(),
            text: reduxText[2105],
        },
        {
            handler: () => handleInlineGoToChild(),
            text: reduxText[6374],
        },
        {
            handler: () => handleInlineActive(),
            text: object?.active ? reduxText[5296] : reduxText[5297],
        },
        {
            role: 'cancel',
            text: reduxText[4519],
        }
    ]

    const buttonsSchedule = [
        {
            handler: () => handleInlineDelete(),
            role: 'destructive',
            text: reduxText[2983],
        },
        {
            handler: () => handleInlineRetrieve(),
            text: reduxText[2105],
        },
        {
            role: 'cancel',
            text: reduxText[4519],
        }
    ]

    return (
        <IonActionSheet
            isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            buttons={formInfo.name === 'schedule' ? buttonsSchedule : (formInfo.name === 'carteCategory' ? buttonsCarteCategory : buttons)}
        />
    )
})
