// react components
import React from 'react'
import axios from 'axios'

// data
import {
    reduxFormHelpersState,
} from 'data'

// main
export function mainFormGetMeta({
    formInfo,
    formState,
    helpers,
    noFullRender,
    setFormState,
    setGetMetaIsLoading,
    setHelpers,
    urltoParse,
}: {
    formInfo: any
    formState: any
    helpers: reduxFormHelpersState
    noFullRender?: boolean
    setFormState: React.Dispatch<any>
    setGetMetaIsLoading: React.Dispatch<boolean>
    setHelpers: React.Dispatch<reduxFormHelpersState>
    urltoParse: string
}) {

    setGetMetaIsLoading(true)

    let urltoParse1 = encodeURIComponent(urltoParse.split('?')[0]) // Split url
    const urltoParse2 = urltoParse1.indexOf('http')
    if (urltoParse2 === -1) {
        urltoParse1 = `http://${urltoParse1}`
    }
    let getUrl = ''
    if (noFullRender) {
        getUrl = `https://opengraph.io/api/1.1/site/${urltoParse1}?app_id=5bb9403dc6006e1b00c78cda&accept_lang=auto&cache_ok=false`
    } else {
        getUrl = `https://opengraph.io/api/1.1/site/${urltoParse1}?app_id=5bb9403dc6006e1b00c78cda&full_render=true&accept_lang=auto&cache_ok=false`
    }
    axios({
        method: 'get',
        url: getUrl,
    })
        .then((response) => { // Response form opengraph.oi
            if (process.env.NODE_ENV === 'development') console.log(response)
            if (!noFullRender && response.data?.openGraph?.error) {
                mainFormGetMeta({
                    formInfo,
                    formState,
                    helpers,
                    noFullRender: true,
                    setFormState,
                    setGetMetaIsLoading,
                    setHelpers,
                    urltoParse,
                })
                return
            }
            let imageFile = ''
            if (response.data && response.data.hybridGraph && response.data.hybridGraph.image) {
                imageFile = response.data.hybridGraph.image
            } else if (response.data && response.data.htmlInferred && response.data.htmlInferred.images[0]) {
                imageFile = response.data.htmlInferred.images[0]
            }
            if (response.data && response.data.hybridGraph) { // set state from response data
                const toReturn = {
                    ...formInfo,
                    errors: {
                        ...formInfo.errors,
                        cover_image: '',
                        cover_image_square: '',
                        description: '',
                        meta_image_url: '',
                        name: '',
                    },
                    fields: {
                        ...formInfo.fields,
                        cover_image: imageFile,
                        cover_image_square: imageFile,
                        get_image_lq: imageFile,
                        get_image_mq: imageFile,
                        get_image_hq: imageFile,
                        description: response.data.hybridGraph.description,
                        link_url: urltoParse,
                        meta_image_url: imageFile,
                        name: response.data.hybridGraph.title,
                    },
                }
                setFormState({
                    ...formState,
                    [formInfo.name]: toReturn,
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    mainError: '', // TO_CHECK
                })
            }
            setGetMetaIsLoading(false)
        })
        .catch((error) => {
            setFormState({
                ...formState,
                [formInfo.name]: {
                    ...formInfo,
                    errors: {
                        ...formInfo.errors,
                        link_url: error.response?.statusText || 'Error',
                    },
                    fields: {
                        ...formInfo.fields,
                        link_url: urltoParse,
                    },
                },
            })
            setGetMetaIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log(error)
            if (process.env.NODE_ENV === 'development') console.log(error.response)
        })
}
