// serializers
import {
    CSSPropertySerializer,
} from 'serializers/web'

// main
export const reduxFormInitialHelpers: reduxFormHelpersState = {
    buttonDisabled: true,
    errorStatus: undefined,
    init: false,
    isActionSheetOpen: false,
    isInlineModalOpen: false,
    isLoading: false,
    isSaving: false,
    isSuccess: false,
    mainError: '',
    scrollToTop: false,
}

export type reduxFormHelpersState = {
    buttonDisabled?: boolean
    errorStatus?: number
    init?: boolean
    isActionSheetOpen?: boolean
    isInlineModalOpen?: boolean
    isLoading?: boolean
    isSaving?: boolean
    isSuccess?: boolean
    mainError?: string
    mainErrorTitle?: string
    scrollToTop?: boolean
}

export type reduxFormState = {
    cssProperties: CSSPropertySerializer[]
    isSuccessToastMessage: string | undefined
    isSuccessToastOpen: boolean
    mixedFonts: any[]
    mixedMother: any
    mixedProfileId: number | undefined
    refresh: {
        refreshComment?: number
        refreshCustomCategory?: number
        refreshCustomChoice?: number
        refreshDetail?: number
        refreshFeed?: number
        refreshFeedSection?: number
        refreshForm?: number
        refreshInlineList?: number
        refreshPreview?: number
        refreshProfile?: number
        refreshRoom?: number
    }
    refreshId: {
        refreshComment?: number
        refreshCustomCategory?: number
        refreshCustomChoice?: number
        refreshDetail?: number
        refreshFeed?: number
        refreshFeedSection?: number
        refreshForm?: number
        refreshInlineList?: number
        refreshPreview?: number
        refreshProfile?: number
        refreshRoom?: number
    }
    refreshNoEmpty: boolean | undefined
}
