// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// components
import {
    Alert,
    AupouCurrentLocationHelper,
    Button,
    EditAlert,
    IconBlock,
    Loader,
    TranslationHelper,
} from 'components'

// data
import {
    api_url_copy_style_undo,
    api_url_move_content_undo,
    contentTypeModelType,
    defaultReduxState,
    reduxFormHelpersState,
    reduxFormInitialHelpers,
    reduxFormSetAvailabitySite,
    reduxFormSetIsSuccessToastOpen,
    reduxFormSetNavigationModeSite,
    reduxFormSetRefresh,
    reduxModalDetail2Show,
    reduxModalErrorEventHandler,
    reduxModalSeoData,
    reduxModalSetEditSite,
    reduxModalSiteSetSpecialSizeSite,
    select_url_portfolio_content_source_available,
    view_url_portfolio_form,
} from 'data'

// pages
import {
    AssetFormState,
    ContainerMixedContentPanel,
    LayoutInputPortfolio,
    MixedContentPreview,
    NotFoundPage,
    ProjectMixedMediaContentFormState,
} from 'pages'

// serializers
import {
    ServiceFormState,
} from 'serializers/web'

// services
import {
    BuilderService,
    autoSaveStyles,
    axiosErrorHandler,
    getApiUrl,
    getApiUrlV2,
    getAxiosHeaders,
    getDetailData,
    getDynamicTextOptions,
    getInputValue,
} from 'services'

// props
type MatchParams = {
    id: string
    contentType: contentTypeModelType
    panelValue: string
}

type LocationParams = {
    formRefresh?: number
}

// main
export const MixedMediaForm: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation<LocationParams>()
    const params = useParams<MatchParams>()
    const contentRef = useRef<HTMLIonContentElement>(null)
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [formState, setFormState] = useState<{
        mother: {
            detailUrl: string
            errors?: {
                mixed_media_fonts?: string
            }
            fields?: {
                id?: number
                name?: string
                fonts?: any
                mixed_media_fonts?: any
                translation_languages?: any
                portfolio_main?: any
                profile?: {
                    id: number
                    name: string
                }
            }
        }
        asset: ServiceFormState['asset']
        mixedMediaContent: ServiceFormState['mixedMediaContent']
    }>({
        mother: {
            detailUrl: getApiUrlV2(`${params.contentType.split('_')[0]}/form/${params.contentType.split('_')[1]}/detail/`, reduxAuth),
            fields: undefined,
        },
        asset: AssetFormState(reduxAuth),
        mixedMediaContent: ProjectMixedMediaContentFormState(reduxAuth),
    })
    const [helpers, setHelpers] = useState<reduxFormHelpersState>(reduxFormInitialHelpers)
    const [languageId, setLanguageId] = useState<number>()
    const [refreshFormNumber, setRefreshFormNumber] = useState<number | undefined>(reduxForm.refresh.refreshForm)
    const [tabValue, setTabValue] = useState<string | undefined>('formView')

    const mother = formState.mother

    useEffect(() => {
        try {
            autoSaveStyles({
                component: 'MixedMediaForm',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MixedMediaForm',
                'useEffect',
            ))
        }
    }, [
        params.panelValue,
    ])

    useEffect(() => {
        if (reduxForm.refresh.refreshForm !== refreshFormNumber) {
            setRefreshFormNumber(reduxForm.refresh.refreshForm)
        }
        getDetailData({
            axiosCancelToken,
            component: 'MixedMediaForm',
            dispatch,
            formRefresh: location.state?.formRefresh,
            // @ts-ignore
            formState,
            helpers,
            noEmpty: reduxForm.refresh.refreshForm !== refreshFormNumber,
            objectId: params.id,
            reduxAuth,
            setAxiosCancelToken,
            setFormState,
            setHelpers,
            setInitialState,
        })
    }, [
        location.state?.formRefresh,
        params.id,
        reduxAuth.apiRootUrl,
        reduxAuth.settings?.active_profile?.id,
        reduxForm.refresh.refreshForm,
    ])

    useEffect(() => {
        if (formState.mother.fields?.profile?.id) {
            getAvailabeContentSource()
        }
    }, [
        formState.mother.fields?.profile?.id,
        reduxAuth.apiRootUrl,
    ])

    useEffect(() => {
        dispatch(reduxModalSetEditSite(true))
        if (!reduxFormSite.dynamicTextOptions) {
            getDynamicTextOptions({
                component: 'MixedMediaForm',
                dispatch,
                reduxAuth,
            })
        }
    }, [])

    useEffect(() => {
        if (helpers.scrollToTop) {
            scrollToTop()
            setHelpers({ ...helpers, scrollToTop: false })
        }
    }, [
        helpers.scrollToTop,
    ])

    useEffect(() => {
        dispatch(reduxModalSeoData({
            title: mother.fields?.id ? `Form - ${mother.fields?.name ? `${mother.fields.name} - ` : ''}${mother.fields?.profile?.name} - Just The Web` : `Form - Just The Web`,  // TO_LATER_TEXT
        }))
    }, [
        mother.fields?.id,
        mother.fields?.name,
    ])

    async function getAvailabeContentSource() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MixedMediaForm getAvailabeContentSource')
            const getUrl = getApiUrl(`${select_url_portfolio_content_source_available}?profile_id=${formState.mother.fields?.profile?.id}`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: refreshAxiosHeaders,
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetAvailabitySite(response.data[0].available))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'MixedMediaForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getAvailabeContentSource',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MixedMediaForm',
                'getAvailabeContentSource',
            ))
        }
    }

    function handleInputChange(event: any, formInfo: any) {
        try {
            const mother = formState.mother
            if (event.name === 'styles_original' && mother) {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
            } else {
                setFormState({
                    ...formState,
                    [formInfo.name]: getInputValue({
                        event,
                        formInfo,
                        reduxText,
                    })
                })
                setHelpers({
                    ...helpers,
                    buttonDisabled: false,
                    init: true,
                    mainError: '',
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MixedMediaForm',
                'handleInputChange',
            ))
        }
    }

    function handleFinish() {
        try {
            if (!mother.fields?.id) return
            dispatch(reduxFormSetIsSuccessToastOpen(false))
            autoSaveStyles({
                component: 'MixedMediaForm',
                dispatch,
                reduxAuth,
                reduxFormSite,
            })
            history.push(`/detail/${params.contentType}/${mother.fields.id}/`)
            dispatch(reduxFormSetRefresh('refreshDetail'))
            dispatch(reduxFormSetNavigationModeSite(undefined))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MixedMediaForm',
                'handleFinish',
            ))
        }
    }

    async function handleUndoCopyStyle() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MixedMediaForm handleUndoCopyStyle')
            const previousStyleObject = BuilderService.copyGetPreviousStyleObject()
            const formData = new FormData()
            formData.append('content_type_id', previousStyleObject!.content_type!.toString())
            formData.append('object_id', previousStyleObject!.id!.toString())
            formData.append('styles', previousStyleObject!.layout_edit!.styles!.toString())
            formData.append('styles_original', previousStyleObject!.layout_edit!.styles_original!.toString())
            const postUrl = getApiUrl(api_url_copy_style_undo, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                    BuilderService.copyDeletePreviousStyleObject()

                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'MixedMediaForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleUndoCopyStyle',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MixedMediaForm',
                'handleUndoCopyStyle',
            ))
        }
    }

    async function handleUndoMove() {
        try {
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'MixedMediaForm handleUndoMove')
            const previousMoveObject = BuilderService.moveGetPreviousContentObject()
            const formData = new FormData()
            formData.append('content_type_id', previousMoveObject!.content_type!.toString())
            formData.append('object_id', previousMoveObject!.id!.toString())
            if (previousMoveObject!.parent_content) {
                formData.append('parent_content_id', previousMoveObject!.parent_content!.toString())
            }
            formData.append('position', previousMoveObject!.position!.toString())
            const postUrl = getApiUrl(api_url_move_content_undo, reduxAuth)
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                    BuilderService.moveDeletePreviousContentObject()
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'MixedMediaForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleUndoMove',
                        skipSend404Detail: true,
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MixedMediaForm',
                'handleUndoMove',
            ))
        }
    }

    const scrollToTop = () => {
        try {
            contentRef.current?.scrollToTop()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MixedMediaForm',
                'scrollToTop',
            ))
        }
    }

    function setInitialState() {
        console.log('TODO remove');
    }

    if (helpers.errorStatus) {
        return (
            <NotFoundPage
                header
                // @ts-ignore
                model={params.contentType}
                objectId={Number(params.id)}
                statusCode={helpers.errorStatus}
            />
        )
    }

    return (
        <IonPage className='main-form-container-web'>
            <IonContent
                className={`mfcw-form-content mo-hide-ion-content-scrollbar${reduxAuth.isWindows}`}
                ref={contentRef}
            >
                <IonHeader className={`mo-header-web${process.env.NODE_ENV === 'development' ? ' dev-color' : ''}`}>
                    <IonToolbar>
                        {mother.fields?.id && (
                            <IonButtons slot='start'>
                                <Button
                                    className='mfcw-name-button mo-clamp-web1'
                                    edit={false}
                                    fill='outline'
                                    onClick={() => dispatch(reduxModalDetail2Show({
                                        contentType: params.contentType,
                                        isOpen: true,
                                        objectId: mother.fields?.id!,
                                    }))}
                                    size='small'
                                    text='Assets'  // TO_TEXT
                                />
                                <Button
                                    className='mfcw-name-button mo-clamp-web1'
                                    disabled
                                    edit={false}
                                    fill='clear'
                                    text={mother.fields?.name!}
                                />
                                {params.panelValue && (
                                    <React.Fragment>
                                        <span>{'/'}</span>
                                        <Button
                                            disabled
                                            edit={false}
                                            fill='clear'
                                            text={`${reduxText[6379]} ${params.panelValue}`}
                                        />
                                    </React.Fragment>
                                )}
                                {mother.fields.translation_languages && (
                                    <TranslationHelper
                                        edit={false}
                                        inNav
                                        languageId={languageId}
                                        languages={mother.fields.translation_languages}
                                        setLanguageId={setLanguageId}
                                    />
                                )}
                            </IonButtons>
                        )}
                        <IonTitle color='primary'>
                            <div className='mfcw-nav-mode-wrap'>
                                {BuilderService.copyGetPreviousStyleObject() && (
                                    <Button
                                        edit={false}
                                        onClick={handleUndoCopyStyle}
                                        size='small'
                                        fill='clear'
                                        text={reduxText[8505]}
                                    />
                                )}
                                {BuilderService.moveGetPreviousContentObject() && (
                                    <Button
                                        edit={false}
                                        onClick={handleUndoMove}
                                        size='small'
                                        fill='clear'
                                        text={reduxText[8506]}
                                    />
                                )}
                                <IconBlock
                                    edit={false}
                                    iconClass={tabValue === 'fullscreen' ? 'mo-new-icon-compress-solid' : 'mo-new-icon-expand-solid'}
                                    onClick={() => {
                                        if (tabValue === 'fullscreen') {
                                            setTabValue('')
                                            dispatch(reduxModalSiteSetSpecialSizeSite(undefined))
                                        } else {
                                            setTabValue('fullscreen')
                                            dispatch(reduxModalSiteSetSpecialSizeSite('form-fullscreen'))
                                        }
                                    }}
                                    tooltipText={tabValue === 'fullscreen' ? 'Exit fullscreen' : 'Fullscreen'}
                                />
                                <Button
                                    edit={false}
                                    fill={reduxFormSite.navigationMode === 'navMixed' ? undefined : 'clear'}
                                    onClick={() => dispatch(reduxFormSetNavigationModeSite('navMixed'))}
                                    size='small'
                                    text={reduxText[8191]}
                                />
                                <Button
                                    edit={false}
                                    fill={reduxFormSite.navigationMode === 'edit' ? undefined : 'clear'}
                                    onClick={() => dispatch(reduxFormSetNavigationModeSite('edit'))}
                                    size='small'
                                    text={reduxText[8192]}
                                />
                            </div>
                        </IonTitle>
                        <IonButtons slot='end'>
                            <AupouCurrentLocationHelper />
                            {(mother.fields?.portfolio_main && reduxAuth.settings?.user?.is_staff) && (
                                <IconBlock
                                    className='mfcw-nav-go-to-builder'
                                    edit={false}
                                    iconClass='mo-new-icon-content'
                                    to={`${view_url_portfolio_form}${mother.fields.portfolio_main.id}/page-list/`}
                                    tooltipText='Go to builder'
                                />
                            )}
                            <Button
                                edit={false}
                                onClick={() => handleFinish()}
                                size='small'
                                text={reduxText[6377]}
                            />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {helpers.isLoading
                    ? (
                        <Loader isOpen />
                    ) : (
                        <div className='mfcw-form-panels-wrap'>
                            <div className={`mfcw-form-panels mixed-media-content ${tabValue}`}>
                                <div className='mfcw-form-panels-children mo-hidden-horizontal-scrollbar'>
                                    <ContainerMixedContentPanel
                                        contentType={params.contentType}
                                        // @ts-ignore
                                        formState={formState}
                                        handleInputChange={handleInputChange}
                                        helpers={helpers}
                                        newMixedMediaContent
                                        newMixedMediaContentType={params.contentType}
                                        // @ts-ignore
                                        setFormState={setFormState}
                                        setHelpers={setHelpers}
                                    />
                                </div>
                            </div>
                            {mother.fields && (
                                <div className={`mfcw-form-preview-mixed-media-content ${tabValue}`}>
                                    <div className={`mixed-template-preview-wrap ${reduxModalSite.deviceType}`}>
                                        <div className='mixed-template-preview'>
                                            <MixedContentPreview
                                                contentType={params.contentType}
                                                edit={'preview'}
                                                languageId={languageId}
                                                newMixedMediaContent
                                                newMixedMediaContentType={params.contentType}
                                                // @ts-ignore
                                                mother={mother}
                                                // @ts-ignore
                                                object={mother.fields}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Alert
                                buttons={[{ text: reduxText[5614] }]}
                                header={reduxText[5585]}
                                isOpen={Boolean(helpers.mainError)}
                                message={`${helpers.mainError}${helpers.mainError === 'Network Error' ? reduxText[4577] : ''}`}
                                onDidDismiss={() => setHelpers({
                                    ...helpers,
                                    mainError: '',
                                })}
                            />
                        </div>
                    )
                }
                {(helpers.isSaving) && (
                    <Loader
                        isOpen
                        message={helpers.isSaving ? reduxText[4525] : ''}
                    />
                )}
            </IonContent>
            <EditAlert />
            <LayoutInputPortfolio />
        </IonPage>
    )
}
