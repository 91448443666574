// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Loader,
} from 'components'

// data
import {
    api_url_copy_style,
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    contentTypeData,
} from 'data'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    BuilderService,
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
}

type CopyStyleHelperProps = {
    mixedMedia: boolean
    object: PortfolioPageContentListSiteSerializer
    setIsPopoverOpen: React.Dispatch<boolean>
}

// main
export const CopyStyleHelper: React.FC<CopyStyleHelperProps> = React.memo(({
    mixedMedia,
    object,
    setIsPopoverOpen,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState(false)

    async function handleApplyStyle() {
        try {
            const styleToCopyObject = BuilderService.copyGetStyleObject()
            setIsLoading(true)
            setIsPopoverOpen(false)
            const refreshAxiosHeaders = await getAxiosHeaders(reduxAuth, dispatch, 'CopyStyleHelper handleApplyStyle')

            const postUrl = getApiUrl(api_url_copy_style, reduxAuth)
            const formData = new FormData()
            formData.append('from_content_type_id', styleToCopyObject!.content_type.toString())
            formData.append('from_object_id', styleToCopyObject!.id!.toString())
            formData.append('to_content_type_id', contentTypeData[mixedMedia ? 'mixedmediacontent' : 'pagecontent'].toString())
            formData.append('to_object_id', object.id!.toString())
            BuilderService.copySetPreviousStyleObject(object, contentTypeData[mixedMedia ? 'mixedmediacontent' : 'pagecontent'])
            axios({
                data: formData,
                headers: refreshAxiosHeaders,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    dispatch(reduxFormSetRefresh('refreshPreview', Number(params.id)))
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'CopyStyleHelper',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleApplyStyle',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'CopyStyleHelper',
                'handleApplyStyle',
            ))
        }
    }

    return (
        <React.Fragment>
            <div className='shpcwp-item'>
                <button
                    className='shpcwp-item-button'
                    onClick={handleApplyStyle}
                >
                    <i className='mo-new-icon-paint-roller-solid' />
                    <span className='shpcwp-item-button-text'>{reduxText[7687]}</span>
                </button>
            </div>
            {isLoading && (
                <Loader
                    isOpen
                    message={reduxText[7692]}
                />
            )}
        </React.Fragment>
    )
})
