// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    DateTimeInput,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    EventParticipantDetailFormSerializer,
    EventParticipantDetailFormSerializerError,
} from 'serializers/web'

// props
type PaymentDateEventParticipantInputProps = {
    errors: EventParticipantDetailFormSerializerError | undefined
    fields: EventParticipantDetailFormSerializer
    onChange: any
}

// main
export const PaymentDateEventParticipantInput: React.FC<PaymentDateEventParticipantInputProps> = React.memo(({
    errors,
    fields,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <DateTimeInput
            error={errors?.payment_date}
            format='date'
            formatDate='dd/MM/yyyy'
            label={reduxText[8554]}
            name='payment_date'
            onChange={onChange}
            value={fields.payment_date || null}
        />
    )
})
